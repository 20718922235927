import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';
import HotelDefaultImage from 'agentSyncedImages/fallback_images/product/product_xxl_hotel.png';
import { AccordionItem } from '@wtag/rcl-accordion';
import { stringifyQueryString } from '../../../helpers/qsMethods';
import HotelAccordionHeader from './HotelAccordionHeader';
import resultShape from '../shapes/result';
import HotelAccordionContent from './HotelAccordionContent';
import getHotelAddress from '../helpers/getHotelAddress';
import './styles.scss';

const HotelCompactView = props => {
  const {
    id,
    deal,
    dealGroup,
    address,
    laymanMode,
    source,
    selectedCartItemResultIds,
    name,
    stars,
    currency,
    perNightPrice,
    rating,
    ratingSource,
    picture,
    approvalRequired,
    corporateDeal,
    labels = {},
    payLater,
    policiesReasons = {},
    refundable,
  } = props;

  const [isActiveAccordion, setIsActiveAccordion] = useState(false);

  const statusInformation = {
    approvalRequired,
    corporateDeal,
    labels,
    payLater,
    policiesReasons,
    refundable,
  };
  const link = {
    pathname: `/hotels/details/${id}`,
    search: stringifyQueryString({ deal, dealGroup }),
  };
  const hotelAddress = getHotelAddress(address);
  const alreadySelectedInCart = selectedCartItemResultIds.includes(id);
  const channelListText = `${source.supplier} : ${source.account}`;

  const buttonText = () =>
    alreadySelectedInCart ? (
      <I18nText id="ibe.results.already_selected" />
    ) : (
      <I18nText id="ibe.search_results.hotel.select" />
    );

  const setPlaceholderImage = event => {
    const target = event.target;
    target.src = HotelDefaultImage;
  };

  const onAccordionClick = () => {
    setIsActiveAccordion(prevState => !prevState);
  };

  return (
    <AccordionItem
      className={classNames('hotel-compact-view__accordion-item', {
        'hotel-compact-view__accordion-item--with-extra-spacing': !laymanMode,
      })}
      header={
        <HotelAccordionHeader
          channelListText={channelListText}
          hotelAddress={hotelAddress}
          hotelName={name}
          laymanMode={laymanMode}
          stars={stars}
          link={link}
          buttonText={buttonText}
          alreadySelectedInCart={alreadySelectedInCart}
          currency={currency}
          perNightPrice={perNightPrice}
        />
      }
      id={id}
      isActive={isActiveAccordion}
      onClick={onAccordionClick}
    >
      <HotelAccordionContent
        ratingSource={ratingSource}
        rating={rating}
        picture={picture}
        setPlaceholderImage={setPlaceholderImage}
        laymanMode={laymanMode}
        statusInformation={statusInformation}
        hotelName={name}
        hotelAddress={hotelAddress}
        stars={stars}
      />
    </AccordionItem>
  );
};
HotelCompactView.defaultProps = {
  dealGroup: null,
  deal: null,
};

HotelCompactView.propTypes = {
  ...resultShape,
  dealGroup: PropTypes.string,
  deal: PropTypes.string,
};

export default HotelCompactView;
