import routes from 'agentRoutes';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import httpV3Client from 'agentHTTPV3Client';
import history from '../../lib/history';

import { showError, fetchCart } from '../common/';
import { saveSearch } from '../../lib/saveSearchParameters';
import { form2api } from '../../Search/flight/helpers/mapSearchParams';

export * from '../common/customer';
export * from './airport';
export * from './selectFlightGroup';
export * from './selectFlightResult';
export * from './deselectFlightGroup';
export * from './resetSelectGroup';
export * from './filterFlightSearch';
export * from './airlines';
export * from './fetchServiceDetails';
export * from './aircraft';
export * from './fareBasisInfo';
export * from './setDefaultSearchParams';
export * from './exchangeQuotes';
export * from './fetchFlightSearchParams';
export * from './fetchFlightMatrixes';

export const FETCHING_FLIGHT_SEARCHED = 'FETCHING_FLIGHT_SEARCHED';
function fetchingFlight() {
  return {
    type: FETCHING_FLIGHT_SEARCHED,
  };
}

export const FLIGHT_SEARCH_FETCHED = 'FLIGHT_SEARCH_FETCHED';
function flightSearchResultsFetched(searchId, tripId, data) {
  return {
    type: FLIGHT_SEARCH_FETCHED,
    searchId,
    tripId,
    resultGroups: data.resultGroups,
    stats: data.stats,
    search: data.search,
    availableResults: data.availableResults,
    availableResultsPerSupplier: data.availableResultsPerSupplier,
  };
}

const cancelTokens = {};

export function resetFlightSearch() {
  if (cancelTokens.fetchFlightSearchResults) {
    cancelTokens.fetchFlightSearchResults.cancel('will replace with a new request');
  }
}

export function fetchFlightSearchResults(params) {
  return (dispatch, getState) => {
    const state = getState();
    const { searchId, tripId } = params;

    let myParams = {
      searchId,
      tripId,
      'selected_flight_groups[]': Object.values(state.flights.selectedFlightGroups).map(
        group => group.id,
      ),
    };

    const tripDurationMinutes = {};
    const departureTimes = {};
    const arrivalTimes = {};
    const prices = {};
    const filters = state.flights.searchFilterParamsBySearchId[searchId];

    if (filters) {
      filters.tripSpecific.forEach(content => {
        const filterTripId = content.key;
        tripDurationMinutes[`trip_duration_minutes[${filterTripId}][min]`] =
          content.tripDurationMinutes.min;
        tripDurationMinutes[`trip_duration_minutes[${filterTripId}][max]`] =
          content.tripDurationMinutes.max;

        prices[`prices[${filterTripId}][min]`] = content.prices.min;
        prices[`prices[${filterTripId}][max]`] = content.prices.max;

        departureTimes[`departure_times[${filterTripId}][min]`] = content.departureTimes.min;
        departureTimes[`departure_times[${filterTripId}][max]`] = content.departureTimes.max;

        arrivalTimes[`arrival_times[${filterTripId}][min]`] = content.arrivalTimes.min;
        arrivalTimes[`arrival_times[${filterTripId}][max]`] = content.arrivalTimes.max;
      });

      myParams = {
        ...myParams,
        min_price: filters.price.min,
        max_price: filters.price.max,
        'airlines[]': filters.airlines,
        'stopover_counts[]': filters.stopoverCounts,
        'caterings[]': filters.caterings,
        'baggage[]': filters.baggage,
        'multi_ticket[]': filters.multiTicket,
        ...tripDurationMinutes,
        ...prices,
        ...departureTimes,
        sort_by: filters.sortBy,
        ...arrivalTimes,
      };
    }

    dispatch(fetchingFlight());

    resetFlightSearch();

    cancelTokens.fetchFlightSearchResults = generateSourceToken();

    return httpV3Client
      .get(routes.api.flights.searchResults(myParams, filters && filters.tripSpecific.length), {
        cancelToken: cancelTokens.fetchFlightSearchResults.token,
      })
      .then(({ data }) => {
        dispatch(flightSearchResultsFetched(params.searchId, params.tripId, data));
        return data;
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }
        return dispatch(showError('Fetching flight results for unknown reasons', error));
      });
  };
}

export function fetchFlightSearchResultsByFlightNumbers(id, trips) {
  const url = routes.api.flights.searchResultsFilterByFlightNumbers({ searchId: id });
  return () => httpClient.post(url, { trips }).then(({ data }) => data);
}

export const FLIGHT_SEARCH_CREATED = 'FLIGHT_SEARCH_CREATED';
export function flightSearchCreated(id) {
  return {
    type: FLIGHT_SEARCH_CREATED,
    id,
  };
}

export const CREATE_FLIGHT_SEARCH = 'CREATE_FLIGHT_SEARCH';
export function createFlightSearch(formParams, callbackParams) {
  const identifier = formParams.groupKey || 'singleSearch';

  if (!cancelTokens.createFlightSearch) {
    cancelTokens.createFlightSearch = {};
  }

  if (cancelTokens.createFlightSearch[identifier]) {
    cancelTokens.createFlightSearch[identifier].cancel('Replaced with new search');
  }

  cancelTokens.createFlightSearch[identifier] = generateSourceToken();

  saveSearch('flights', formParams);
  const searchParams = form2api(formParams, callbackParams);
  return dispatch =>
    httpV3Client
      .post(routes.api.flights.createSearch(), searchParams, {
        cancelToken: cancelTokens.createFlightSearch[identifier].token,
      })
      .then(({ data }) => {
        dispatch(flightSearchCreated(data.id));
        return data;
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }
        return dispatch(showError('Search failed for unknown reasons', error));
      });
}

// When enhancing the flight search loading screen we will again fetch data
// function fetchSimilarFlightSearch(formData) {
//   const trips = formData.trips.map(trip => ({
//     origin: trip.origin,
//     destination: trip.destination,
//   }));
//   return () =>
//     httpClient
//       .post(routes.api.flights.similarSearch(), { trips })
//       .then(({ data }) => {
//         if (data.id) {
//           history.push(`/flights/searches/${data.id}/1?cached=true`);
//         }
//       })
//       .catch(() => undefined);
// }

export function searchFlights(params, callbackParams) {
  return dispatch => {
    history.push('/flights/searching');
    dispatch({ type: CREATE_FLIGHT_SEARCH, params });

    return dispatch(createFlightSearch(params, callbackParams)).then(
      data => data && data.id && history.push(`/flights/searches/${data.id}/1`),
    );
  };
}

export const RESET_FLIGHT_SEARCH_FILTERS = 'RESET_FLIGHT_SEARCH_FILTERS';
export function resetFlightSearchFilters(searchId, tripId, stats) {
  return dispatch => {
    dispatch({ type: RESET_FLIGHT_SEARCH_FILTERS, searchId, tripId, stats });
    return dispatch(fetchFlightSearchResults({ searchId, tripId }));
  };
}

export const RESET_FLIGHT_SEARCH_FILTERS_BY_KEY = 'RESET_FLIGHT_SEARCH_FILTERS_BY_KEY';
export function resetFlightSearchFiltersByKey(searchId, tripId, stats) {
  return dispatch => {
    dispatch({ type: RESET_FLIGHT_SEARCH_FILTERS_BY_KEY, searchId, tripId, stats });
    return dispatch(fetchFlightSearchResults({ searchId, tripId }));
  };
}

export const FLIGHT_SERVICES_FETCHED = 'FLIGHT_SERVICES_FETCHED';
function flightServicesFetched(flightServices, id) {
  return {
    id,
    ...flightServices,
    type: FLIGHT_SERVICES_FETCHED,
    searchId: flightServices.search_id,
    data: flightServices,
  };
}

export const FLIGHT_SERVICES_FETCHING_FAILED = 'FLIGHT_SERVICES_FETCHING_FAILED';
function flightServicesFetchingFailed(id, error) {
  return dispatch => {
    dispatch(showError(error.message, error));
    return dispatch({
      type: FLIGHT_SERVICES_FETCHING_FAILED,
      id,
    });
  };
}

export function fetchFlightServicesWithoutHandling(id) {
  return dispatch =>
    httpClient.get(routes.api.flights.resultServices({ resultId: id })).then(({ data }) => {
      dispatch(flightServicesFetched(data, id));
      return data;
    });
}

const flightServicesRequestsRunning = {};
export const FETCHING_FLIGHT_SERVICES = 'FETCHING_FLIGHT_SERVICES';
export function fetchFlightServices(id, callbackParams) {
  if (flightServicesRequestsRunning[id]) {
    return () => flightServicesRequestsRunning[id];
  }

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_FLIGHT_SERVICES, id });

    flightServicesRequestsRunning[id] = dispatch(fetchFlightServicesWithoutHandling(id))
      .then(data => {
        delete flightServicesRequestsRunning[id];
        if (data.priceChanged) {
          return dispatch(fetchCart(getState().common.cart.id, callbackParams)).then(() => data);
        }
        return Promise.resolve(data);
      })
      .catch(error => {
        delete flightServicesRequestsRunning[id];

        let throwError = error;
        if (!error.extraData) {
          const message = 'Fetching flight services failed for unknown reasons';
          throwError = new Error(message);
          throwError.message = message;
        }
        dispatch(flightServicesFetchingFailed(id, throwError));
      });
    return flightServicesRequestsRunning[id];
  };
}

export const ADD_SEAT_TO_CART = 'ADD_SEAT_TO_CART';
export function addSeatToCart(travelerIndex, seatId, callbackParams) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    dispatch({
      type: ADD_SEAT_TO_CART,
      travelerIndex,
      seatId,
    });
    return httpClient
      .post(routes.api.carts.items.addSeat({ cartId }), {
        seat_id: seatId,
        traveler_index: travelerIndex,
      })
      .then(() => dispatch(fetchCart(cartId, callbackParams, { refreshFlightJourneyElement: true })));
  };
}

export const REMOVE_SEATS_FROM_CART = 'REMOVE_SEATS_FROM_CART';
export function removeSeatsFromCart(seatItemIDs, callbackParams) {
  return (dispatch, getState) => {
    dispatch({
      type: REMOVE_SEATS_FROM_CART,
      seatItemIDs,
    });
    const cartId = getState().common.cart.id;
    const deletePromises = seatItemIDs.map(itemId =>
      httpClient.delete(routes.api.carts.items.removeItem({ cartId, itemId, callbackParams })),
    );
    return Promise.all(deletePromises).then(() =>
      dispatch(fetchCart(cartId, callbackParams, { refreshFlightJourneyElement: true })),
    );
  };
}

export const ENSURE_SERVICE_IN_CART = 'ENSURE_SERVICE_IN_CART';
export function ensureServiceInCart(serviceId, quantity, travelerIndices, callbackParams) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    dispatch({
      type: ENSURE_SERVICE_IN_CART,
      serviceId,
      quantity,
      travelerIndices,
    });
    return httpClient
      .put(routes.api.carts.items.ensureService({ cartId, serviceId }), {
        quantity,
        traveler_indices: travelerIndices,
      })
      .then(() =>
        dispatch(fetchCart(cartId, callbackParams, { refreshFlightJourneyElement: true })),
      );
  };
}

export const REMOVE_TRIP = 'REMOVE_TRIP';
export function removeTrip(tripId) {
  return {
    type: REMOVE_TRIP,
    tripId,
  };
}
