import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Radio from '@wtag/rcl-radio';
import classNames from 'classnames';
import Amount from 'sharedWebpack/Amount';
import CreditCardDetails from 'sharedWebpack/CreditCardVaultPayment/CreditCardDetails';
import paymentMethodName from '../../helpers/paymentMethodName';
import paymentGatewayType from '../../helpers/paymentGatewayType';
import paymentMethodLocaleName from '../../helpers/paymentMethodLocaleName';
import getCardChargesByType from '../../helpers/getCardChargesByType';
import {
  IATA_FORM_OF_PAYMENT,
  CASH_FORM_OF_PAYMENT,
  NONE_FORM_OF_PAYMENT,
  WALLET_FORM_OF_PAYMENT,
} from '../../../lib/helpers/fop_options';
import './styles.scss';

const PayAllAtOnceOption = ({
  isSelectedForAll,
  items,
  type,
  onSelect,
  gateWayDetails,
  card,
  laymanMode,
  surcharge,
  isFopLoading,
  walletBalance,
  walletCurrency,
  isDeactivated,
  currency,
}) => {
  const STATIC_SURCHARGE_TYPE = 'static';
  const PERCENTAGE_SURCHARGE_TYPE = 'percentage';
  const iconName = paymentMethodName(type, laymanMode);

  const thirdPartyPaymentMethodName = () => {
    if (gateWayDetails) {
      return (
        <div className="pay-all-at-once__option--credit-card">
          <div className="pay-all-at-once__option--credit-card-powered-by">
            {I18n.t('ibe.payment.powered_by')}
          </div>
          <img
            className="pay-all-at-once__option--gateway-img"
            src={paymentGatewayType(gateWayDetails.gateway)}
            alt={gateWayDetails.gateway}
          />
          {surcharge.type && (
            <div className="pay-all-at-once__option--surcharge">
              {I18n.t('ibe.payment.surcharge')}
              {': '}
              {surcharge.type === STATIC_SURCHARGE_TYPE && (
                <Amount currency={surcharge.currency} value={surcharge.amount} />
              )}
              {surcharge.type === PERCENTAGE_SURCHARGE_TYPE && `${surcharge.amount}%`}
            </div>
          )}
        </div>
      );
    } else if (card) {
      const charges = items.flatMap(item => {
        const { supportedCards } = item.availableFormOfPayment.creditCard;
        return getCardChargesByType(supportedCards, card.cardType);
      });

      return <CreditCardDetails card={card} charges={charges} currency={currency} />;
    }

    return (
      <div className="pay-all-at-once__option--name">
        {paymentMethodLocaleName(type, laymanMode)}
      </div>
    );
  };

  const showPaymentOptions =
    gateWayDetails ||
    card ||
    type === CASH_FORM_OF_PAYMENT ||
    type === WALLET_FORM_OF_PAYMENT ||
    type === NONE_FORM_OF_PAYMENT ||
    (type === IATA_FORM_OF_PAYMENT && !laymanMode);

  const walletContainer = () => (
    <Fragment>
      {I18n.t('admin.components.orders.items_tab.label.wallet')}
      <Fragment>
        <Amount
          className={classNames('pay-all-at-once__option-price', {
            'pay-all-at-once__option-price--enabled': !isDeactivated,
            'pay-all-at-once__option-price--disabled': isDeactivated,
          })}
          value={walletBalance}
          currency={walletCurrency}
          notation="compact"
        />
      </Fragment>
    </Fragment>
  );

  return (
    showPaymentOptions && (
      <div
        className={classNames('pay-all-at-once__option', {
          'pay-all-at-once__option--wallet': type === WALLET_FORM_OF_PAYMENT,
        })}
      >
        <Icon
          className={classNames('pay-all-at-once__option--icon', {
            'pay-all-at-once__option--icon-pay-later': iconName === 'receipt',
            'pay-all-at-once__option--icon--disabled': isDeactivated,
          })}
          name={iconName}
          size="normal"
          color="tertiary"
          showLabel={true}
          iconLabel={
            <Fragment>
              {type === WALLET_FORM_OF_PAYMENT ? walletContainer() : thirdPartyPaymentMethodName()}
            </Fragment>
          }
          showBGColor={true}
        />
        <Radio
          size="huge"
          onChange={onSelect}
          isChecked={isSelectedForAll}
          disabled={isFopLoading || isDeactivated}
          withCheckIcon={true}
        />
      </div>
    )
  );
};

PayAllAtOnceOption.defaultProps = {
  gateWayDetails: null,
  card: null,
  surcharge: {
    type: null,
    amount: null,
    currency: null,
  },
  walletBalance: null,
  walletCurrency: null,
  isDeactivated: false,
};

PayAllAtOnceOption.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      availableFormOfPayment: PropTypes.shape({
        creditCard: PropTypes.shape({
          supportedCards: PropTypes.arrayOf(
            PropTypes.shape({ cardType: PropTypes.string, amount: PropTypes.string }),
          ),
        }),
      }),
    }),
  ).isRequired,
  gateWayDetails: PropTypes.shape({ gateway: PropTypes.string }),
  card: PropTypes.shape({
    cardType: PropTypes.string,
    maskedNumber: PropTypes.string,
    fullname: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
  isSelectedForAll: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  surcharge: PropTypes.shape({
    type: PropTypes.oneOf([null, 'percentage', 'static']),
    amount: PropTypes.string,
    currency: PropTypes.string,
  }),
  walletBalance: PropTypes.number,
  walletCurrency: PropTypes.string,
  isFopLoading: PropTypes.bool.isRequired,
  isDeactivated: PropTypes.bool,
  currency: PropTypes.string.isRequired,
};

export default PayAllAtOnceOption;
