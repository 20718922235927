import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import Button from '@wtag/rcl-button';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';

import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import Form from './Form';

const AddressCard = props => {
  const { address, fetchTraveller, addressTypesOptions, countryOptions, layman, personId } = props;

  const [editing, setEditing] = useState(false);

  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [countryCode, setCountryCode] = useState(null);
  const [countryState, setCountryState] = useState('');
  const [type, setType] = useState(null);
  const [primary, setPrimary] = useState(false);
  const [error, setError] = useState({});
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);

  let addressesAndLocationsUpdateRoutes;

  if (layman) {
    addressesAndLocationsUpdateRoutes = {
      update: routes.public.travellerAddresses.update({
        person_id: personId,
        addressId: address.id,
      }),
      delete: routes.public.travellerAddresses.delete({
        person_id: personId,
        addressId: address.id,
      }),
    };
  } else {
    addressesAndLocationsUpdateRoutes = {
      update: routes.admin.travellerAddresses.update({
        person_id: personId,
        addressId: address.id,
      }),
      delete: routes.admin.travellerAddresses.delete({
        person_id: personId,
        addressId: address.id,
      }),
    };
  }

  const toggleShowConfirmation = () => {
    setIsShowConfirmation(prev => !prev);
  };

  const resetForm = () => {
    setLine1('');
    setLine2('');
    setZipCode('');
    setCity('');
    setCountryCode(null);
    setCountryState('');
    setType(null);
    setPrimary(false);
  };

  const validateForm = () => {
    const formErrors = {};

    if (!line1) {
      formErrors.line1 = I18n.t('components.ibe.validations.presence');
    }

    if (!zipCode) {
      formErrors.zipCode = I18n.t('components.ibe.validations.presence');
    }

    if (!countryCode) {
      formErrors.countryCode = I18n.t('components.ibe.validations.presence');
    }

    if (!city) {
      formErrors.city = I18n.t('components.ibe.validations.presence');
    }

    if (!countryCode) {
      formErrors.countryCode = I18n.t('components.ibe.validations.presence');
    }

    if (!type) {
      formErrors.addressType = I18n.t('components.ibe.validations.presence');
    }

    setError(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const updateAddress = async () => {
    if (!validateForm()) {
      return;
    }

    const travellerParams = {
      traveler_address: {
        id: address.id,
        address_type: type,
        primary,
        line_1: line1,
        line_2: line2,
        zip_code: zipCode,
        city,
        state: countryState,
        country_code: countryCode,
      },
    };

    const { data } = await httpClient.patch(addressesAndLocationsUpdateRoutes.update, {
      ...travellerParams,
    });

    if (data.error === null) {
      fetchTraveller();
      setEditing(false);
      resetForm();
    }
  };

  const deleteAddress = async () => {
    toggleShowConfirmation();

    const { data } = await httpClient.delete(addressesAndLocationsUpdateRoutes.delete);
    if (data.error === null) {
      fetchTraveller();
    }
  };

  const onUpdate = () => {
    updateAddress().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });
  };

  const getAddressInformation = () => (
    <div className="traveller-edit-address">
      <div className="col-grid col-bleed traveller-edit-address__content">
        <div className="traveller-edit-address__header">
          {I18n.t('admin.components.travellers.edit.address.form.label.address_1')}
        </div>
        <div className="traveller-edit-address__value">{address.line1}</div>
      </div>
      <div className="col-grid col-bleed traveller-edit-address__content">
        <div className="traveller-edit-address__header">
          {address.line2 && I18n.t('admin.components.travellers.edit.address.form.label.address_2')}
        </div>
        <div className="traveller-edit-address__value">{address.line2}</div>
      </div>
      <div className="col-grid col-bleed traveller-edit-address__content">
        <div className="traveller-edit-address__header">
          {I18n.t('admin.components.travellers.edit.address.form.label.zip')}
        </div>
        <div className="traveller-edit-address__value">{address.zipCode}</div>
      </div>
      <div className="col-grid col-bleed traveller-edit-address__content">
        <div className="traveller-edit-address__header">
          {I18n.t('admin.components.travellers.edit.address.form.label.city')}
        </div>
        <div className="traveller-edit-address__value">{address.city}</div>
      </div>
      <div className="col-grid col-bleed traveller-edit-address__content">
        <div className="traveller-edit-address__header">
          {I18n.t('components.ibe.traveler_details.form.fields.state')}
        </div>
        <div className="traveller-edit-address__value">{address.state}</div>
      </div>
      <div className="col-grid col-bleed traveller-edit-address__content traveller-edit-address__content--with-bottom-padding">
        <div className="traveller-edit-address__header">
          {I18n.t('admin.components.travellers.edit.address.form.label.country')}
        </div>
        <div className="traveller-edit-address__value">{address.countryName}</div>
      </div>
    </div>
  );

  useEffect(() => {
    setLine1(address.line1);
    setLine2(address.line2);
    setZipCode(address.zipCode);
    setCity(address.city);
    setCountryCode(address.countryCode);
    setCountryState(address.state);
    setType(address.type);
    setPrimary(address.primary);
  }, [address]);

  useEffect(() => {
    setError({});
  }, [editing]);

  return (
    <div className="col-grid col-bleed traveller-edit-address-section traveller-edit-address-section__address-card extra-top-gap">
      <div className="col-grid direction-row col-bleed align-center justify-space-between">
        {editing ? (
          <div className="traveller-edit-address-section__address-card-sub-header">
            {I18n.t('admin.components.travellers.edit.address.form.placeholder.updating_header')}
          </div>
        ) : (
          <div className="col-grid direction-row col-bleed">
            <div className="traveller-edit-address-section__address-card-type-tag">
              <div className="traveller-edit-address__header-gap">
                {I18n.t(address.type, {
                  scope: 'simple_form.options.person.traveler_addresses.address_type',
                })}
              </div>
            </div>
            {address.primary && (
              <EmphasisTag
                text={I18n.t('admin.components.travellers.edit.address.form.label.primary')}
                size="tiny"
              />
            )}
          </div>
        )}
        {!editing && (
          <div className="col-2 col-grid col-bleed direction-row align-center justify-end">
            <IconButton
              icon={<Icon name="edit" />}
              color="tertiary"
              size="small"
              onClick={() => setEditing(true)}
            />
            <IconButton
              icon={<Icon name="delete" />}
              color="tertiary"
              size="small"
              onClick={toggleShowConfirmation}
              disabled={address.primary}
            />
          </div>
        )}
      </div>
      {editing ? (
        <Fragment>
          <Form
            line1={line1}
            setLine1={setLine1}
            line2={line2}
            setLine2={setLine2}
            zipCode={zipCode}
            setZipCode={setZipCode}
            city={city}
            setCity={setCity}
            countryCode={countryCode || null}
            setCountryCode={setCountryCode}
            countryState={countryState}
            setCountryState={setCountryState}
            type={type || null}
            setType={setType}
            primary={primary}
            setPrimary={setPrimary}
            addressTypesOptions={addressTypesOptions}
            countryOptions={countryOptions}
            error={error}
          />
          <div className="traveller-edit-address-section__form-actions">
            <Button
              label={I18n.t('admin.components.shared.action.update')}
              onClick={onUpdate}
              version="v2"
              type="primary"
              size="small"
            />

            <Button
              version="v2"
              size="small"
              label={I18n.t('admin.components.shared.action.cancel')}
              type="default"
              onClick={() => setEditing(false)}
            />
          </div>
        </Fragment>
      ) : (
        <div className="col-grid col-bleed">{getAddressInformation()}</div>
      )}

      {isShowConfirmation && (
        <ConfirmationModal
          confirmationHeader={I18n.t(
            'simple_form.options.person.traveler_addresses.delete_modal.header',
          )}
          subHeader={I18n.t(
            'simple_form.options.person.traveler_addresses.delete_modal.sub_header',
          )}
          isModalOpen={true}
          withAction={true}
          type="danger"
          confirmationText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.positive',
          )}
          rejectionText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.negative',
          )}
          onConfirm={deleteAddress}
          onReject={toggleShowConfirmation}
        />
      )}
    </div>
  );
};

AddressCard.propTypes = {
  address: PropTypes.shape({
    city: PropTypes.string,
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
    state: PropTypes.string,
    id: PropTypes.number,
    line1: PropTypes.string,
    line2: PropTypes.string,
    type: PropTypes.string,
    zipCode: PropTypes.string,
    primary: PropTypes.bool,
  }).isRequired,
  addressTypesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  fetchTraveller: PropTypes.func.isRequired,
  layman: PropTypes.bool.isRequired,
  personId: PropTypes.number.isRequired,
};

export default AddressCard;
