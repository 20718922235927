import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Alert from 'sharedWebpack/Alert';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Card from '@wtag/rcl-card';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

import FrequentFlyerNumberForm from './FrequentFlyerNumberForm';
import FrequentFlyerNumberInformation from './FrequentFlyerNumberInformation';

const FrequentFlyerNumbersSection = props => {
  const { personId, carrierCodes, layman } = props;

  const [error, setError] = useState({});
  const [number, setNumber] = useState('');
  const [carrierCode, setCarrierCode] = useState([]);
  const [frequentFlyerNumbers, setFrequentFlyerNumbers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchFrequentFlyerUrl = layman
    ? routes.public.frequentFlyerNumbers.list()
    : routes.admin.frequentFlyerNumbers.list({ person_id: personId });

  const saveFrequentFlyerUrl = layman
    ? routes.public.frequentFlyerNumbers.create()
    : routes.admin.frequentFlyerNumbers.create({ person_id: personId });

  const fetchFrequentFlyerNumbers = async () => {
    const { data } = await httpClient.get(fetchFrequentFlyerUrl);

    setFrequentFlyerNumbers(data.frequentFlyerNumbers);
    setIsLoading(false);
  };

  const clearFormData = () => {
    setNumber('');
    setCarrierCode([]);
    setError({});
  };

  useEffect(() => {
    fetchFrequentFlyerNumbers();
  }, []);

  const createFrequentFlyerNumber = async () => {
    const frequentFlyerNumberParams = {
      number,
      carrier_code: carrierCode,
      person_id: personId,
    };

    const { data } = await httpClient.post(saveFrequentFlyerUrl, {
      frequent_flyer_number: frequentFlyerNumberParams,
    });

    if (data.error === null) {
      fetchFrequentFlyerNumbers();
      clearFormData();
    }
  };

  const onCreateFrequentFlyerNumbers = () =>
    createFrequentFlyerNumber().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  return (
    <Fragment>
      <Alert
        className="traveller-edit-frequent-flyer-number-section__alert"
        isVisible={error.frequentFlyerNumbers}
        type="danger"
        hideClose={false}
        onClose={() => setError({ ...error, frequentFlyerNumbers: false })}
      >
        {I18n.t('admin.components.travellers.edit.identification_documents.error.invalid')}
      </Alert>

      <div className="traveller-edit-frequent-flyer-number-section">
        <div className="grid grid-gap-12">
          <div className="col-12 traveller-edit-frequent-flyer-number-section__name">
            {I18n.t('public.people.edit.frequent_flyer_number')}
          </div>
          <div className="col-12">
            <div className="grid grid-gap-12">
              <div className="col-lg-10">
                <FrequentFlyerNumberForm
                  carrierCodeTypes={carrierCodes}
                  carrierCode={carrierCode}
                  setCarrierCode={setCarrierCode}
                  number={number}
                  setNumber={setNumber}
                  error={error}
                />
              </div>
              <div className="col-3 col-sm-2 col-lg-2 col-grid justify-end">
                <Button
                  version="v2"
                  size="small"
                  type={layman ? 'primary' : 'tertiary'}
                  label={I18n.t('admin.shared.action.add')}
                  onClick={onCreateFrequentFlyerNumbers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-bleed traveller-edit-frequent-flyer-number">
        {isLoading ? (
          <div className="card traveller-edit-frequent-flyer-number__container card--v2 card--normal-shadow card--null">
            <ContentLoaderPlaceholder numberOfLines={2} isRounded={true} showBackground={false} />
          </div>
        ) : (
          <Fragment>
            {frequentFlyerNumbers && frequentFlyerNumbers.length > 0 ? (
              frequentFlyerNumbers.map(frequentFlyerNumber => (
                <FrequentFlyerNumberInformation
                  key={frequentFlyerNumber.id}
                  personId={personId}
                  frequentFlyerNumber={frequentFlyerNumber}
                  fetchFrequentFlyerNumbers={fetchFrequentFlyerNumbers}
                  carrierCodes={carrierCodes}
                  layman={layman}
                />
              ))
            ) : (
              <Card
                version="v2"
                className="frequent-flyer-numbers"
                color="tertiary"
                emptyCardImageSrc={<Icon name="plane" rotate="45" />}
                emptyCardText={I18n.t(
                  'admin.components.travellers.edit.frequent_flyer_numbers.not_available',
                )}
              />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

FrequentFlyerNumbersSection.defaultProps = {
  layman: false,
};

FrequentFlyerNumbersSection.propTypes = {
  personId: PropTypes.number.isRequired,
  carrierCodes: PropTypes.shape([PropTypes.arrayOf(PropTypes.string)]).isRequired,
  layman: PropTypes.string,
};

export default FrequentFlyerNumbersSection;
