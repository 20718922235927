import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import { Text } from '@wtag/rcl-typography';
import ToolTip from '@wtag/rcl-tooltip';
import { Link } from '@wtag/react-comp-lib';
import humanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import JourneyPath from 'sharedWebpack/ItemWrapper/Flight/jounryPath';
import DateTime from 'sharedWebpack/DateTime';
import AirlineLogo from '../../../SearchResult/flight/AirlineLogoContainer';
import SegmentsInformation from '../SegmentsInformation';
import StopoverSidePanel from '../StopoverSidePanel';
import MatrixContext from '../contexts/MatrixContext';
import getFlightStopsCountLabel from '../../../../helpers/calculateStopoverStops';

const MatrixAccordionItem = props => {
  const {
    trip,
    isOpened,
    setActiveStepIndex,
    fetchAirportDetails,
    airports,
    airlineInformations,
    fetchAirlineInformations,
    windowWidth,
  } = props;
  const [showStopovers, setShowStopovers] = useState(false);
  const {
    temporarySelectedTrips,
    setTtripsWithSelectedFareBrand,
    tripsWithSelectedFareBrand,
    activeTabKey,
    relatedTripsHashMap,
    isFetchingMatrices,
  } = useContext(MatrixContext);
  const tripIndex = trip.id - 1;
  const relatedTripsIdInDescendingOrder =
    relatedTripsHashMap[trip.id] &&
    relatedTripsHashMap[trip.id].firstSelectedTripId &&
    relatedTripsHashMap[trip.id].firstSelectedTripId !== trip.id
      ? [trip.id, ...relatedTripsHashMap[trip.id].relatedTrips].sort((a, b) => a - b)
      : [];
  const windowSizeForMobileView = 991;

  const findAirport = airportCode =>
    (airports && Object.values(airports).find(airport => airport.code === airportCode)) ||
    fetchAirportDetails(airportCode);

  const transitGapCount = getFlightStopsCountLabel(trip.stopovers);

  const toggleShowStopovers = () => {
    setShowStopovers(prevState => !prevState);
  };

  useEffect(() => {
    fetchAirportDetails(trip.originCode);
    fetchAirportDetails(trip.destinationCode);
  }, []);

  useEffect(() => {
    if (trip && isOpened) {
      setTtripsWithSelectedFareBrand(prevTrips =>
        !prevTrips[activeTabKey].includes(trip.id) &&
        temporarySelectedTrips[activeTabKey].includes(trip.id)
          ? {
              ...prevTrips,
              [activeTabKey]: [...prevTrips[activeTabKey], trip.id],
            }
          : prevTrips,
      );
    }
  }, [temporarySelectedTrips, isOpened]);

  return (
    <li className="matrix-accordion__item">
      <div
        className={classNames('matrix-accordion__item-header', {
          'matrix-accordion__item-header--active': isOpened,
          'matrix-accordion__item-header--checked': tripsWithSelectedFareBrand[
            activeTabKey
          ].includes(trip.id),
          'matrix-accordion__item-header--disabled': !isOpened && isFetchingMatrices,
        })}
        role="button"
        tabIndex={0}
        onClick={!isFetchingMatrices ? () => setActiveStepIndex(tripIndex) : () => {}}
        onKeyDown={!isFetchingMatrices ? () => setActiveStepIndex(tripIndex) : () => {}}
      >
        <Icon
          className="matrix-accordion__item-header-check-icon"
          name="check"
          showBGColor={true}
          size="tiny"
        />
        <Text className="matrix-accordion__item-header-title" level={2} weight="bold">
          {I18n.t('components.ibe.results.trip_number', { number: trip.id })}: {trip.originCode} -{' '}
          {trip.destinationCode}
        </Text>
        {activeTabKey !== 'all' &&
          relatedTripsHashMap[trip.id] &&
          relatedTripsHashMap[trip.id].firstSelectedTripId &&
          relatedTripsHashMap[trip.id].firstSelectedTripId !== trip.id && (
            <ToolTip
              title={I18n.t('components.ibe.search_result.related_trip_header', {
                tripsExcludingLastTrip: relatedTripsIdInDescendingOrder
                  .slice(0, relatedTripsIdInDescendingOrder.length - 1)
                  .join(', '),
                lastTrip:
                  relatedTripsIdInDescendingOrder[relatedTripsIdInDescendingOrder.length - 1],
              })}
              content={I18n.t('components.ibe.search_result.related_trip_message', {
                tripsExcludingLastTrip: relatedTripsIdInDescendingOrder
                  .slice(0, relatedTripsIdInDescendingOrder.length - 1)
                  .join(', '),
                lastTrip:
                  relatedTripsIdInDescendingOrder[relatedTripsIdInDescendingOrder.length - 1],
                totalTrips: relatedTripsIdInDescendingOrder.length,
              })}
              type="inverse"
            >
              <Icon
                className="matrix-accordion__item-header-tooltip-icon"
                name="invalidOutline"
                color="neutral"
                size="tiny"
                rotate={180}
              />
            </ToolTip>
          )}
        {activeTabKey !== 'all' && (
          <Icon
            className="matrix-accordion__item-header-arrow"
            name="iconUpChevron"
            size="tiny"
            rotate={isOpened ? 0 : 180}
          />
        )}
      </div>
      <div
        className={classNames('matrix-accordion__item-body', {
          'matrix-accordion__item-body--visible': isOpened,
        })}
      >
        <div className="matrix-accordion__item-body-airline-logos">
          {/* Show unique airline logos */}
          {[...new Set(trip.marketingAirlineLogos)].map(logoCode => (
            <AirlineLogo key={logoCode} carrierCode={logoCode} />
          ))}
        </div>
        <div className="matrix-accordion__item-body-airports">
          <div className="matrix-accordion__item-body-airports-origin">
            <div className="matrix-accordion__item-body-airports-origin-info">
              {airports[trip.originCode] ? (
                <img
                  src={airports[trip.originCode].countryFlag}
                  alt={airports[trip.originCode].countryName}
                />
              ) : null}
              <Text level={2} weight="bold">
                {trip.originCode}
              </Text>
            </div>
            <div className="matrix-accordion__item-body-airports-origin-datetime">
              <Text level={3} weight="bold">
                <DateTime dateTime={trip.departureTime} format="shortWithTime" />
              </Text>
            </div>
          </div>
          {windowWidth > windowSizeForMobileView && (
            <div className="matrix-accordion__item-body-airports-stopover">
              <Text
                className="matrix-accordion__item-body-airports-stopover-info"
                level={2}
                weight="bold"
              >
                {humanReadableDuration(trip.tripDurationInMinutes)}
              </Text>
              {trip.stopovers.length > 0 ? (
                <Link onClick={toggleShowStopovers} size="tiny" modifier="tertiary">
                  <Text level={3} weight="bold">
                    {transitGapCount}
                  </Text>
                </Link>
              ) : (
                <Text level={3} weight="bold">
                  {transitGapCount}
                </Text>
              )}
              <div className="matrix-accordion__item-body-airports-stopover__journey-path">
                <JourneyPath stopovers={trip.stopovers} />
              </div>
            </div>
          )}
          <div className="matrix-accordion__item-body-airports-destination">
            <div className="matrix-accordion__item-body-airports-destination-info">
              {airports[trip.destinationCode] ? (
                <img
                  src={airports[trip.destinationCode].countryFlag}
                  alt={airports[trip.destinationCode].countryName}
                />
              ) : null}
              <Text level={2} weight="bold">
                {trip.destinationCode}
              </Text>
            </div>
            <div className="matrix-accordion__item-body-airports-destination-datetime">
              <Text level={3} weight="bold">
                <DateTime dateTime={trip.arrivalTime} format="shortWithTime" />
              </Text>
            </div>
          </div>
        </div>

        {windowWidth < windowSizeForMobileView && (
          <div className="matrix-accordion-mobile__item-body-airports-origin-datetime">
            <Text level={2} weight="bold">
              {humanReadableDuration(trip.tripDurationInMinutes)}
            </Text>
            {trip.stopovers.length > 0 ? (
              <Link
                onClick={() => setShowStopovers(true)}
                size="tiny"
                modifier="tertiary"
                className="matrix-accordion-mobile__stopover-link"
              >
                <Text level={3} weight="bold">
                  {transitGapCount}
                </Text>
              </Link>
            ) : (
              <Text level={3} weight="bold">
                {transitGapCount}
              </Text>
            )}
          </div>
        )}

        <div className="matrix-accordion__item-body-segments">
          <SegmentsInformation
            group={{
              ...trip,
              id: trip.groupId,
            }}
            airlineInformation={airlineInformations}
            fetchAirlineInformation={fetchAirlineInformations}
            airports={airports}
            fetchAirportDetails={fetchAirportDetails}
          />
        </div>
        <StopoverSidePanel
          stopovers={trip.stopovers}
          airports={airports}
          findAirport={findAirport}
          showStopovers={showStopovers}
          onClick={setShowStopovers}
        />
      </div>
    </li>
  );
};

MatrixAccordionItem.propTypes = {
  trip: PropTypes.shape({
    id: PropTypes.number.isRequired,
    groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    originCode: PropTypes.string.isRequired,
    arrivalTime: PropTypes.string.isRequired,
    departureTime: PropTypes.string.isRequired,
    tripDurationInMinutes: PropTypes.number.isRequired,
    stopovers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    destinationCode: PropTypes.string.isRequired,
    marketingAirlineLogos: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isOpened: PropTypes.bool.isRequired,
  setActiveStepIndex: PropTypes.func.isRequired,
  fetchAirportDetails: PropTypes.func.isRequired,
  airports: PropTypes.shape({}).isRequired,
  airlineInformations: PropTypes.shape({}).isRequired,
  fetchAirlineInformations: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default MatrixAccordionItem;
