export const UPDATE_PRESELECT_TRAVELERS = 'UPDATE_PRESELECT_TRAVELERS';
export function updatePreselectTravelers(preselectTravelers) {
  return {
    type: UPDATE_PRESELECT_TRAVELERS,
    preselectTravelers,
  };
}

export const UPDATE_PRESELECT_DECISION = 'UPDATE_PRESELECT_DECISION';
export function updatePreselectDecision(decision) {
  return {
    type: UPDATE_PRESELECT_DECISION,
    decision,
  };
}
