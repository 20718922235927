import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';
import Amount from 'sharedWebpack/Amount';
import CancellationPolicies from './CancellationPolicies';
import OutOfPolicyJustificationModal from '../../SearchResult/OutOfPolicyJustificationModal';
import RoomPriceMenu from './RoomPriceMenu';
import { NONE } from '../../../helpers/constants/common';
import { DEFAULT, SUCCESS } from '../../lib/helpers/componentStates';

const ICON_PREFERENCE = 'preference';
const ICON_CHECK_CIRCLE_OUTLINE = 'checkCircleOutline';

const Rooms = ({ rooms, requiredRooms, laymanMode, durationOfStaying, addToCart }) => {
  const [policyJustificationModalOpen, setPolicyJustificationModalOpen] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [isSelectingRoom, setIsSelectingRoom] = useState(false);

  const roomsWithCards = rooms.map(room => {
    const {
      id,
      labels,
      grossTotal,
      currency,
      perNightPrice,
      roomTypes,
      cancellationPolicies,
      total,
    } = room;

    const { outOfPolicy } = labels;

    const handleContinueButtonClick = () => {
      if (outOfPolicy) {
        setSelectedRoomId(id);
        setPolicyJustificationModalOpen(true);
      } else {
        setIsSelectingRoom(true);

        addToCart(id, null).then(() => {
          setIsSelectingRoom(false);
        });
      }
    };

    const roomDescription = roomTypes.map(({ type, noOfRooms }) => (
      <Fragment key={type}>
        <div className="hotel-rooms__room-description-type">{type}</div>
        <div className="hotel-rooms__room-description-text">
          {noOfRooms}&nbsp;{type}
        </div>
      </Fragment>
    ));

    const renderMealInfo = roomTypes.map(({ boardType, type }) => {
      if (!boardType) return null;

      const icon = boardType === NONE ? ICON_PREFERENCE : ICON_CHECK_CIRCLE_OUTLINE;
      const color = boardType === NONE ? DEFAULT : SUCCESS;

      return (
        <div key={type} className="d-flex column-gap-8 align-center hotel-rooms__room-meal-info">
          <Icon name={icon} color={color} size="normal" />
          {I18n.t(boardType, {
            scope: 'components.ibe.hotel.details.meal.info',
          })}
        </div>
      );
    });

    return (
      <Card version="v2" size="full" key={id} className="hotel-rooms__room-card">
        <div className="hotel-rooms__room-card-wrapper row-gap-16">
          <div className="hotel-rooms__room-price-wrapper">
            <div className="d-flex align-center column-gap-8">
              <RoomPriceMenu
                room={room}
                amountLabel={grossTotal}
                requiredRooms={requiredRooms}
                laymanMode={laymanMode}
                durationOfStaying={durationOfStaying}
              />
              <span className="hotel-rooms__room-price-staying-duration">
                {I18n.t('components.ibe.search_results.hotel.duration_count', {
                  count: durationOfStaying,
                })}
              </span>
            </div>
            <div className="hotel-rooms__room-price-per-night">
              <Amount currency={currency} value={perNightPrice} />
              <span className="hotel-rooms__room-price-per-night-text">
                {I18n.t('components.ibe.search_results.hotel.per_night_apx')}
              </span>
            </div>
          </div>
          <div className="hotel-rooms__room-description">{roomDescription}</div>
          <div className="d-flex-column row-gap-12">
            {renderMealInfo}
            <CancellationPolicies policies={cancellationPolicies} amount={total} />
          </div>
          <div className="hotel-rooms__room-action">
            <Button
              size="small"
              version="v2"
              label={I18n.t('components.ibe.hotel.details.add_cart')}
              primary={true}
              onClick={handleContinueButtonClick}
              fullWidth={true}
              disabled={isSelectingRoom}
            />
          </div>
        </div>
      </Card>
    );
  });

  return (
    <Fragment>
      <div className="hotel-rooms">{roomsWithCards}</div>
      <OutOfPolicyJustificationModal
        modalOpen={policyJustificationModalOpen}
        setModalOpen={setPolicyJustificationModalOpen}
        onConfirm={justification => addToCart(selectedRoomId, justification)}
      />
    </Fragment>
  );
};

Rooms.propTypes = {
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  durationOfStaying: PropTypes.number.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  requiredRooms: PropTypes.number.isRequired,
  addToCart: PropTypes.func.isRequired,
};

export default Rooms;
