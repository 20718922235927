import getUniqueElements from './getUniqueElements';

const getAirlineText = trips => {
  const airlines = trips
    .flatMap(trip => trip.airlines.map(airline => airline && airline.name))
    .filter(Boolean);

  if (airlines.length > 0) {
    const uniqueAirlines = getUniqueElements(airlines).join(', ');
    return I18n.t('components.ibe.search_results.flight.content.airline', {
      airlines: uniqueAirlines,
    });
  }

  return I18n.t('components.ibe.search_results.flight.content.unknown_airline');
};

export default getAirlineText;
