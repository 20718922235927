import { connect } from 'react-redux';

import { addSeatToCart, removeSeatsFromCart } from '../../../../actions/flight';

import SeatDetails from './SeatDetails';

const mapStateToProps = (state, ownProps) => {
  const { adults, children, infants } = state.common.travelerCount;
  const adultCount = adults.length;
  const childrenCount = children.length;
  const infantCount = infants.length;
  const passengers = [];
  let i = 0;

  for (let loopIndex = 0; loopIndex < adultCount; loopIndex += 1) {
    passengers.push({ value: i, type: 'adult' });
    i += 1;
  }

  for (let loopIndex = 0; loopIndex < childrenCount; loopIndex += 1) {
    passengers.push({ value: i, type: 'child' });
    i += 1;
  }

  for (let loopIndex = 0; loopIndex < infantCount; loopIndex += 1) {
    passengers.push({ value: i, type: 'infant' });
    i += 1;
  }

  const seatItemID = [];
  state.common.cart.items
    .filter(item => item.id === ownProps.mainItemId)
    .forEach(item =>
      item.subItems
        .filter(
          subItem => subItem.type === 'seat' && subItem.bookingAttributes.seatId === ownProps.id,
        )
        .forEach(seat => {
          seatItemID.push(seat.id);
        }),
    );

  return {
    seatItemID,
    passengers,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectSeat: (travelerIndex, callbackParams) =>
    dispatch(addSeatToCart(travelerIndex, ownProps.id, callbackParams)),
  removeSeat: seatItemID => dispatch(removeSeatsFromCart(seatItemID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeatDetails);
