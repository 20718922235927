import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@wtag/rcl-avatar';
import { VerticalMenu, VerticalMenuItem, VerticalMenuGroup } from '@wtag/rcl-vertical-menu';
import Icon from '@wtag/rcl-icon';
import { StringParam, useQueryParams } from 'use-query-params';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import LoyaltyProgramsSection from 'sharedWebpack/Travellers/Edit/LoyaltyProgramsSection';

import EmergencyContactsSection from 'sharedWebpack/EmergencyContactsSection';
import fetchPhoneCountryCodes from 'sharedWebpack/fetchPhoneCountryCodes';
import SocialMediaSection from 'sharedWebpack/SocialMediaSection';
import GeneralSection from '../../../shared/Travellers/Edit/GeneralSection';
import PaymentsAndPreferencesSection from '../../../shared/Travellers/Edit/PaymentsAndPreferencesSection';
import ChangePasswordSection from './ChangePasswordSection';
import AvatarModal from '../../../shared/AvatarModal';
import VisasAndIdentificationsSection from '../../../shared/Travellers/Edit/VisasAndIdentificationsSection';
import FrequentFlyerNumbersSection from '../../../shared/Travellers/Edit/FrequentFlyerNumbersSection';
import TravelPreferencesSection from '../../../shared/Travellers/Edit/TravelPreferencesSection';
import EmailAndPhoneSection from '../../../shared/EmailAndPhoneSection';
import AddressesAndLocationsSection from '../../../shared/Travellers/Edit/AddressesAndLocationsSection';
import NotificationPreference from '../../../shared/Travellers/Edit/NotificationPreference';
import { ICON_MAPPER, tabMapper, groupTabMapper } from './mapper_helper';

import '../../../shared/Travellers/Edit/styles.scss';

const Edit = props => {
  const {
    personId,
    tabPaths,
    salutations,
    titlePrefixes,
    preferredCommunications,
    spokenLanguages,
    correspondenceLanguageOptions,
    carCompanies,
    hotelLoyaltyList,
    paymentTypes,
    documentTypes,
    visaCategories,
    carrierCodes,
    cabinTypes,
    seatTypes,
    mealTypes,
    smokingTypes,
    carTypes,
    carCategories,
    carDrives,
    emailAddressTypes,
    phoneNumberTypes,
    addressTypes,
    media,
  } = props;
  const { general } = tabPaths;
  const [phoneCountryCodes, setPhoneCountryCodes] = useState([]);
  const [traveller, setTraveller] = useState({});

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });
  const { tab } = query;

  const TAB_MAPPER = tabMapper(tabPaths);

  const GROUP_TAB_MAPPER = groupTabMapper(tabPaths);

  const getInitialTab = () => TAB_MAPPER[tab] || general;

  const saveTraveller = async params => {
    const { data } = await httpClient.patch(
      routes.public.people.update({ personId: traveller.id }),
      {
        person: params,
      },
    );
    return data;
  };

  const fetchTraveller = async () => {
    const { data } = await httpClient.get(routes.public.people.show());

    if (data.person) setTraveller(data.person);
  };

  const onCreatePhoneNumber = async params => {
    const phoneNumberParams = {
      ...params,
      person_id: personId,
    };

    const { data } = await httpClient.post(routes.public.phoneNumbers.create(), phoneNumberParams);

    return data;
  };

  const onUpdatePhoneNumber = async params => {
    const phoneNumberParams = {
      ...params,
      person_id: personId,
    };

    const { data } = await httpClient.put(
      routes.public.phoneNumbers.update({ phoneNumberId: params.id }),
      phoneNumberParams,
    );

    return data;
  };

  const onDeletePhoneNumber = async phoneNumberId => {
    const { data } = await httpClient.delete(routes.public.phoneNumbers.delete({ phoneNumberId }));

    return data;
  };

  const onCreateEmailAddress = async params => {
    const emailAddressParams = {
      ...params,
      person_id: personId,
    };

    const { data } = await httpClient.post(
      routes.public.emailAddresses.create(),
      emailAddressParams,
    );

    return data;
  };

  const onUpdateEmailAddress = async params => {
    const emailAddressParams = {
      ...params,
      person_id: personId,
    };

    const { data } = await httpClient.put(
      routes.public.emailAddresses.update({ emailId: params.id }),
      emailAddressParams,
    );

    return data;
  };

  const onDeleteEmailAddress = async emailId => {
    const { data } = await httpClient.delete(routes.public.emailAddresses.delete({ emailId }));

    return data;
  };

  const SECTION_MAPPER = {
    emails_and_phones: (
      <EmailAndPhoneSection
        relatedInstance={traveller}
        fetchRelatedInstance={fetchTraveller}
        emailAddressTypes={emailAddressTypes}
        phoneNumberTypes={phoneNumberTypes}
        phoneCountryCodeOptions={phoneCountryCodes}
        createPhoneNumber={onCreatePhoneNumber}
        deletePhoneNumber={onDeletePhoneNumber}
        updatePhoneNumber={onUpdatePhoneNumber}
        createEmailAddress={onCreateEmailAddress}
        deleteEmailAddress={onDeleteEmailAddress}
        updateEmailAddress={onUpdateEmailAddress}
        layman={true}
      />
    ),
    addresses_and_locations: (
      <AddressesAndLocationsSection
        personId={personId}
        addresses={traveller.addresses}
        fetchTraveller={fetchTraveller}
        addressTypes={addressTypes}
        layman={true}
      />
    ),
    emergency_contacts: (
      <EmergencyContactsSection
        personId={personId}
        phoneCountryCodeOptions={phoneCountryCodes}
        layman={true}
      />
    ),
    visas_and_identifications: (
      <VisasAndIdentificationsSection
        personId={personId}
        documentTypes={documentTypes}
        visaCategories={visaCategories}
        layman={true}
      />
    ),
    travel_arrangers: <div>Placeholder (TBD)</div>, // TODO: Implement edit tab
    frequent_flyer_numbers: (
      <FrequentFlyerNumbersSection personId={personId} carrierCodes={carrierCodes} layman={true} />
    ),
    travel_preferences: (
      <TravelPreferencesSection
        personId={personId}
        fetchTraveller={fetchTraveller}
        bookingPreferences={traveller.bookingPreferences}
        cabinTypes={cabinTypes}
        seatTypes={seatTypes}
        mealTypes={mealTypes}
        smokingTypes={smokingTypes}
        carTypes={carTypes}
        carCategories={carCategories}
        carDrives={carDrives}
        layman={true}
      />
    ),
    payment_methods_and_preferences: (
      <PaymentsAndPreferencesSection
        personId={personId}
        fetchTraveller={fetchTraveller}
        preferredPayment={traveller.preferredPayment}
        paymentTypes={paymentTypes}
        layman={true}
      />
    ),
    loyalty_programs: (
      <LoyaltyProgramsSection
        carCompanies={carCompanies}
        personId={personId}
        hotelLoyaltyList={hotelLoyaltyList}
        layman={true}
      />
    ),
    social: (
      <SocialMediaSection type="person" id={personId} socialMediaOptions={media} layman={true} />
    ),
    manage_traveller: <div>Placeholder (TBD)</div>, // TODO: Implement edit tab
    corporate_travel: <div>Placeholder (TBD)</div>, // TODO: Implement edit tab
    change_password: <ChangePasswordSection />,
    notification_preference: (
      <NotificationPreference onSaveTraveller={saveTraveller} traveller={traveller} />
    ),
  };

  const groupMenuItems = () =>
    Object.keys(GROUP_TAB_MAPPER).map(groupKey => (
      <VerticalMenuGroup
        header={I18n.t(`admin.components.travellers.edit.tabs.group.${groupKey}`)}
        activeLink={getInitialTab()}
      >
        {Object.keys(GROUP_TAB_MAPPER[groupKey]).map(tabKey => (
          <VerticalMenuItem
            route={TAB_MAPPER[tabKey]}
            iconName={ICON_MAPPER[tabKey]}
            onClick={() => setQuery({ tab: tabKey }, 'replaceIn')}
          >
            {I18n.t(`admin.components.travellers.edit.tabs.${tabKey}`)}
          </VerticalMenuItem>
        ))}
      </VerticalMenuGroup>
    ));

  const travellerVerificationStatus = type => {
    let verificationIcon;

    switch (type) {
      case 'verified':
        verificationIcon = (
          <Icon
            name="checkCircle"
            iconLabel={I18n.t('public.components.dashboard.people.verification_status.verified')}
            showLabel={true}
            color="success"
            size="small"
          />
        );
        break;

      case 'unverified':
        verificationIcon = (
          <Icon
            name="checkCircle"
            iconLabel={I18n.t('public.components.dashboard.people.verification_status.unverified')}
            showLabel={true}
            size="small"
          />
        );
        break;

      default:
        verificationIcon = null;
    }

    return verificationIcon;
  };

  const updateAvatar = async formData => {
    const { data } = await httpClient.patch(routes.public.people.profilePicture(), formData);

    return data;
  };

  useEffect(() => {
    const getPhoneCountryCodes = async () => {
      setPhoneCountryCodes(await fetchPhoneCountryCodes());
    };

    getPhoneCountryCodes();
    fetchTraveller();
  }, []);

  const getSection = () =>
    SECTION_MAPPER[tab] || (
      <GeneralSection
        traveller={traveller}
        salutations={salutations}
        titlePrefixes={titlePrefixes}
        preferredCommunications={preferredCommunications}
        spokenLanguages={spokenLanguages}
        correspondenceLanguageOptions={correspondenceLanguageOptions}
        fetchTraveller={fetchTraveller}
        layman={true}
      />
    );

  return (
    <div className="traveller-edit grid justify-center">
      <div className="col-md-4">
        <div className="col-grid align-center traveller-edit-left-panel">
          <div className="traveller-overview-left-panel__avatar">
            {traveller.id ? (
              <div className="traveller-overview-left-panel__avatar">
                <Avatar
                  firstName={traveller.firstName}
                  lastName={traveller.lastName}
                  src={traveller.avatarUrl}
                  size="xl"
                />
                <AvatarModal
                  className="traveller-overview-left-panel__avatar--modal"
                  updateAvatar={updateAvatar}
                  fetchInstance={fetchTraveller}
                />
              </div>
            ) : (
              <div className="traveller-overview-left-panel__avatar">
                <ContentLoaderPlaceholder diameter="xl" shape="circle" showBackground={false} />
              </div>
            )}
          </div>
          <div className="traveller-edit-left-panel__name">{traveller.name}</div>
          {traveller.nickname && (
            <div className="traveller-edit-left-panel__nickname">({traveller.nickname})</div>
          )}
          <div className="traveller-edit-left-panel__status">
            {travellerVerificationStatus(traveller.verificationStatus)}
          </div>
          <VerticalMenu activeLink={getInitialTab()} isFullWidth={true}>
            {groupMenuItems()}
          </VerticalMenu>
        </div>
      </div>
      <div className="col-md-8 col-bleed-x traveller-edit-right-panel">
        <div className="col-grid col-bleed">{getSection()}</div>
      </div>
    </div>
  );
};

Edit.defaultProps = {
  cabinTypes: {},
};

Edit.propTypes = {
  personId: PropTypes.number.isRequired,
  tabPaths: PropTypes.shape({
    addressesAndLocations: PropTypes.string,
    general: PropTypes.string,
    emailsAndPhones: PropTypes.string,
    emergencyContacts: PropTypes.string,
    visasAndIdentifications: PropTypes.string,
    frequentFlyerNumbers: PropTypes.string,
    travelPreferences: PropTypes.string,
    paymentMethodsAndPreferences: PropTypes.string,
    loyaltyPrograms: PropTypes.string,
    social: PropTypes.string,
    corporateTravel: PropTypes.string,
    changePassword: PropTypes.string,
    notificationPreference: PropTypes.string,
  }).isRequired,
  salutations: PropTypes.shape({
    mr: PropTypes.number,
    mrs: PropTypes.number,
    ms: PropTypes.number,
  }).isRequired,
  titlePrefixes: PropTypes.shape({
    dr: PropTypes.number,
    dr_prof: PropTypes.number,
    prof: PropTypes.number,
  }).isRequired,
  preferredCommunications: PropTypes.shape({
    email: PropTypes.number,
    phone: PropTypes.number,
  }).isRequired,
  spokenLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  correspondenceLanguageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  carCompanies: PropTypes.shape({}).isRequired,
  hotelLoyaltyList: PropTypes.shape({}).isRequired,
  paymentTypes: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  documentTypes: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  visaCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  carrierCodes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  cabinTypes: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  seatTypes: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  mealTypes: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  smokingTypes: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  carTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  carDrives: PropTypes.arrayOf(PropTypes.string).isRequired,
  carCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  emailAddressTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  phoneNumberTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  media: PropTypes.arrayOf(PropTypes.string).isRequired,
  addressTypes: PropTypes.shape({
    billing: PropTypes.number,
    business: PropTypes.number,
    contact: PropTypes.number,
    home: PropTypes.number,
    place_of_work: PropTypes.number,
    recipient: PropTypes.number,
    shipping: PropTypes.number,
  }).isRequired,
};

export default withQueryParamsProvider(Edit);
