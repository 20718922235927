import React from 'react';
import PropTypes from 'prop-types';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { SidePanel, CheckBox } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';

import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import './style.scss';

const AddNewFee = ({ feeSuggestions, requestingForFees, selectedFees, selectFee }) => {
  const [query, setQuery] = useQueryParams({
    openFeesPanel: withDefault(StringParam, ''),
  });
  const { openFeesPanel } = query;
  const feesPanelVisible = openFeesPanel === 'fees_panel';
  const showFeesPanel = () => {
    setQuery({ openFeesPanel: 'fees_panel' }, 'replaceIn');
  };
  const hideSidePanel = () => {
    setQuery({ openFeesPanel: '' }, 'replaceIn');
  };
  const feeSelected = fee => selectedFees.findIndex(selectedFee => selectedFee.id === fee.id) > -1;
  const requestNotPossible = fee =>
    requestingForFees.findIndex(feeId => feeId === fee.id) > -1;
  const feeSuggestionsBody = () => (
    <div>
      <div className="payment__fee-services__add-new-title">
        {I18n.t('ibe.payment.add_remove_service_charge')}
      </div>
      {feeSuggestions.map(fee => (
        <div className="payment__fee-services__add-new" key={fee.id}>
          <CheckBox
            label={fee.name}
            name={`fee-${fee.id}`}
            checked={feeSelected(fee)}
            onChange={() => {
              if (!requestNotPossible(fee)) {
                selectFee(fee);
              }
            }}
            disabled={requestNotPossible(fee)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="payment__fee-services__button">
      <Button
        size="small"
        label={I18n.t('shared.actions.add_new')}
        onClick={showFeesPanel}
        version="v2"
      />
      <SidePanel
        title={I18n.t('ibe.payment.form_of_services_and_charges')}
        body={feeSuggestionsBody()}
        isOpened={feesPanelVisible}
        onClick={() => hideSidePanel()}
      />
    </div>
  );
};

AddNewFee.propTypes = {
  feeSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  requestingForFees: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedFees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  selectFee: PropTypes.func.isRequired,
};

export default withQueryParamsProvider(AddNewFee);
