import { combineReducers } from 'redux';
import requestsToAuthenticateByToken from './requestsToAuthenticateByToken';
import bookingRequestsEncodedByToken from './bookingRequestsEncodedByToken';
import bookingComponentRefByToken from './bookingComponentRefByToken';
import threeDsStatusesByToken from './threeDsStatusesByToken';
import stringifiedBookingReq from './stringifiedBookingReq';
import stringifiedThreeDsStatuses from './stringifiedThreeDsStatuses';
import currentAuthenticationRequest from './currentAuthenticationRequest';
import errorAlertByToken from './errorAlertByToken';
import successAlertByToken from './successAlertByToken';
import primaryButtonText from './primaryButtonText';
import primaryButtonDisabled from './primaryButtonDisabled';
import willResetCardAddressForm from './willResetCardAddressForm';
import onPrimaryButtonClick from './onPrimaryButtonClick';
import skipAuthenticationStatusesByToken from './skipAuthenticationStatusesByToken';

const ccvPaymentReducer = combineReducers({
  requestsToAuthenticateByToken,
  bookingRequestsEncodedByToken,
  bookingComponentRefByToken,
  stringifiedBookingReq,
  threeDsStatusesByToken,
  stringifiedThreeDsStatuses,
  skipAuthenticationStatusesByToken,
  currentAuthenticationRequest,
  errorAlertByToken,
  successAlertByToken,
  primaryButtonText,
  primaryButtonDisabled,
  willResetCardAddressForm,
  onPrimaryButtonClick,
});

export default ccvPaymentReducer;
