import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { cartFetched } from './';

export const FEES_FETCHED = 'FEES_FETCHED';
function feesFetched(fees) {
  return { type: FEES_FETCHED, fees };
}

export function fetchFees(callbackParams) {
  return dispatch =>
    httpClient.get(routes.api.fees({ all: true, callbackParams })).then(({ data }) => {
      dispatch(feesFetched(data));
      return data;
    });
}

export const FEE_SUGGESTIONS_FETCHED = 'FEE_SUGGESTIONS_FETCHED';
function feeSuggestionsFetched(fees) {
  return { type: FEE_SUGGESTIONS_FETCHED, fees };
}

export function fetchFeeSuggestions(callbackParams) {
  return dispatch =>
    httpClient.get(routes.api.fees({ callbackParams })).then(({ data }) => {
      dispatch(feeSuggestionsFetched(data));
      return data;
    });
}

export const REMOVE_FEE_FROM_CART = 'REMOVE_FEE_FROM_CART';
export function removeFeeFromCart(id) {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_FEE_FROM_CART, id });
    const cartId = getState().common.cart.id;
    return httpClient
      .delete(routes.api.carts.fees.remove({ cartId, feeId: id }))
      .then(({ data }) => dispatch(cartFetched(data)));
  };
}

export const ADD_FEE_TO_CART = 'ADD_FEE_TO_CART';
export function addFeeToCart(newFee) {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_FEE_TO_CART,
      fee: newFee,
    });
    const cartId = getState().common.cart.id;
    return httpClient
      .post(routes.api.carts.fees.add({ cartId }), {
        fee_id: newFee.id,
      })
      .then(({ data }) => dispatch(cartFetched(data)));
  };
}

export function setFeeQuantity(feeId, quantity) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    return httpClient
      .patch(routes.api.carts.fees.setQuantity({ cartId, feeId }), {
        quantity,
      })
      .then(({ data }) => dispatch(cartFetched(data)));
  };
}
