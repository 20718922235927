import {
  CAR_SEARCH_FETCHED,
  CAR_SEARCH_FILTERS_UPDATED,
  RESET_CAR_SEARCH_FILTERS,
  RESET_CAR_SEARCH_FILTERS_BY_KEY
} from '../../actions/car';

const initialState = () => ({
  price: { min: null, max: null },
  categories: [],
  vendors: [],
  sortBy: null,
});

function setFilterParams(state, action) {
  const newState = Object.assign({}, state);
  const searchId = action.searchId;

  if (!newState[searchId]) {
    newState[searchId] = initialState();
  }

  const { price } = newState[action.searchId];
  let { categories, vendors, sortBy } = newState[action.searchId];

  if (price.min === null) {
    price.min = action.stats.price.min;
  }

  if (price.max === null) {
    price.max = action.stats.price.max;
  }

  if (categories.length === 0) {
    categories = action.stats.categories;
  }

  if (vendors.length === 0) {
    vendors = action.stats.vendors;
  }

  if (sortBy === null) {
    sortBy = 'price_asc';
  }

  newState[action.searchId] = {
    price,
    categories,
    vendors,
    sortBy,
  };
  return newState;
}

function searchFilterParamsByKey(state, action) {
  const initialData = action.stats.initialData

    const price = {
      min : initialData.min,
      max : initialData.max
    }
    const filteredState = {...state[action.searchId], price}
    const newFilterParams = {}
    newFilterParams[action.searchId] = filteredState
    return newFilterParams

}

function updateFilterParams(state, action) {
  const newState = Object.assign({}, state);
  newState[action.searchId] = {
    price: action.filters.price,
    categories: action.filters.categories,
    vendors: action.filters.vendors,
    sortBy: action.filters.sortBy,
  };
  return newState;
}

export default function searchFilterParamsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case CAR_SEARCH_FETCHED:
      return setFilterParams(newState, action);

    case CAR_SEARCH_FILTERS_UPDATED:
      return updateFilterParams(newState, action);

    case RESET_CAR_SEARCH_FILTERS:
      return setFilterParams({}, action);

    case RESET_CAR_SEARCH_FILTERS_BY_KEY:
      return searchFilterParamsByKey(newState, action);  

    default:
      return state;
  }
}
