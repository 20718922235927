import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';
import tripTypeIconMapper from 'sharedWebpack/helpers/tripTypeIconMapper';
import { EmphasisTag } from '@wtag/react-comp-lib';
import getTripType from 'sharedWebpack/IBE/lib/helpers/getTripType';
import { countryShape } from '../../admin/Orders/Show/helpers/shapes';
import CountryWithFlag from '../CountryWithFlag';

const JourneySummaryIndicator = ({
  originCountryInfo,
  destinationCountryInfo,
  trips,
  className,
}) => {
  const {
    code: originCode,
    countryFlag: originFlag,
    countryName: originCountryName,
  } = originCountryInfo;
  const tripType = getTripType(trips);

  const {
    code: destinationCode,
    countryFlag: destinationFlag,
    countryName: destinationCountryName,
  } = destinationCountryInfo;

  return (
    <div className={classNames('d-flex align-center column-gap-8', className)}>
      <CountryWithFlag code={originCode} flag={originFlag} name={originCountryName} />
      <Icon name={tripTypeIconMapper(tripType)} size="small" />
      <CountryWithFlag
        code={destinationCode}
        flag={destinationFlag}
        name={destinationCountryName}
      />
      {tripType && (
        <EmphasisTag
          text={I18n.t(tripType, {
            scope: 'components.ibe.results',
          })}
          type="neutral"
          size="small"
        />
      )}
    </div>
  );
};

JourneySummaryIndicator.defaultProps = {
  className: '',
};

JourneySummaryIndicator.propTypes = {
  originCountryInfo: PropTypes.shape(countryShape).isRequired,
  destinationCountryInfo: PropTypes.shape(countryShape).isRequired,
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      destinationCode: PropTypes.string,
      originCode: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
};

export default JourneySummaryIndicator;
