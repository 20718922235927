import React from 'react';
import RCLModal from '@wtag/rcl-modal';
import PropTypes from 'prop-types';

const Modal = props => {
  const {
    isModalVisible,
    className,
    children,
    title,
    footer,
    onOutsideClick,
    timeout,
    onTimeout,
    alignTitle,
    alignContent,
    alignFooter,
  } = props;

  return (
    <RCLModal
      className={className}
      isVisible={isModalVisible}
      onOutsideClick={onOutsideClick}
      timeout={timeout}
      onTimeout={onTimeout}
    >
      {title && <RCLModal.Title align={alignTitle}>{title}</RCLModal.Title>}
      {children && <RCLModal.Content align={alignContent}>{children}</RCLModal.Content>}
      {footer && <RCLModal.Footer align={alignFooter}>{footer}</RCLModal.Footer>}
    </RCLModal>
  );
};

Modal.defaultProps = {
  isModalVisible: false,
  className: null,
  title: null,
  children: null,
  footer: null,
  onOutsideClick: () => {},
  timeout: null,
  onTimeout: () => {},
  alignTitle: 'left',
  alignContent: 'center',
  alignFooter: 'center',
};

Modal.propTypes = {
  isModalVisible: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onOutsideClick: PropTypes.func,
  timeout: PropTypes.number,
  onTimeout: PropTypes.func,
  alignTitle: PropTypes.string,
  alignContent: PropTypes.string,
  alignFooter: PropTypes.string,
};

export default Modal;
