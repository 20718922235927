import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const PLACES_SEARCHED = 'PLACES_SEARCHED';
function placesSearched(places) {
  return {
    type: PLACES_SEARCHED,
    places,
  };
}

export const SEARCHING_PLACES = 'SEARCHING_PLACES';
export function searchPlaces(query, suppliers = []) {
  if (query.length === 0) {
    return placesSearched([]);
  }

  return dispatch =>
    httpClient
      .get(routes.api.searchPlaces({ q: query, 'suppliers[]': suppliers }))
      .then(({ data }) => dispatch(placesSearched(data)));
}

export const PLACE_DETAILS_FETCHED = 'PLACE_DETAILS_FETCHED';
function placeDetailsFetched(data) {
  return {
    type: PLACE_DETAILS_FETCHED,
    data,
  };
}

export const FETCHING_PLACE_DETAILS = 'FETCHING_PLACE_DETAILS';
export function fetchPlaceDetails(id) {
  return dispatch => {
    dispatch({
      type: FETCHING_PLACE_DETAILS,
      id,
    });

    if (id) {
      return httpClient
          .get(routes.api.fetchPlace({ id }))
          .then(({ data }) => dispatch(placeDetailsFetched(data)));
    }
    return null;
  };
}
