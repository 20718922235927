import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import {
  AEROPLANE_ICON,
  CAR_ICON,
  CRUISE_ICON,
  EQUIPMENT_ICON,
  ETICKET_ICON,
  EVENT_ICON,
  HELP_ICON,
  HOTELROOM_ICON,
  SEAT_ICON,
  TRANSFER_ICON,
  SERVICE_ICON,
} from './productIconNames';

const ProductIcon = ({ productType, ...propsForIcon }) => {
  let iconName;
  let iconLabel;

  switch (productType) {
    case 'flight':
      iconName = AEROPLANE_ICON;
      iconLabel = I18n.t('components.product_icon.flights');
      break;
    case 'hotel':
      iconName = HOTELROOM_ICON;
      iconLabel = I18n.t('components.product_icon.hotels');
      break;
    case 'car':
      iconName = CAR_ICON;
      iconLabel = I18n.t('components.product_icon.cars');
      break;
    case 'cruise':
      iconName = CRUISE_ICON;
      iconLabel = I18n.t('components.product_icon.cruises');
      break;
    case 'event':
      iconName = EVENT_ICON;
      iconLabel = I18n.t('components.product_icon.events');
      break;
    case 'transfer':
      iconName = TRANSFER_ICON;
      iconLabel = I18n.t('components.product_icon.transfers');
      break;
    case 'static_product':
    case 'general':
      iconName = EQUIPMENT_ICON;
      iconLabel = I18n.t('components.product_icon.products');
      break;
    case 'seat':
      iconName = SEAT_ICON;
      iconLabel = I18n.t('components.product_icon.seats');
      break;
    case 'service':
      iconName = SERVICE_ICON;
      iconLabel = I18n.t('components.product_icon.products');
      break;
    case 'eTicket':
      iconName = ETICKET_ICON;
      break;
    default:
      iconName = HELP_ICON;
      iconLabel = 'Unknown Product';
  }

  return (
    <Icon
      key={iconLabel}
      iconLabel={iconLabel}
      name={iconName}
      color="tertiary"
      {...propsForIcon}
    />
  );
};

ProductIcon.propTypes = {
  productType: PropTypes.string.isRequired,
};

export default ProductIcon;
