import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import Amount from 'sharedWebpack/Amount';

const PriceLabel = ({ label, currency, value }) => (
  <div className="refresh-booking__option d-flex justify-space-between align-center">
    <div className="refresh-booking__label">{label}</div>
    <div className="refresh-booking__value">
      <Amount currency={currency} value={value} />
    </div>
  </div>
);

PriceLabel.propTypes = {
  label: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default hot(module)(PriceLabel);
