import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from '@wtag/react-comp-lib';

import './styles.scss';

const SubNav = props => {
  const { colors, activeLink, urls, scope, approvalRequests } = props;
  const {
    dashboardUrl,
    // dealsUrl,
    // quotesUrl,
    tripsUrl,
    approvalRequestsUrl,
    travelArrangersUrl,
    corporateTravelUrl,
    mobileAppUrl,
  } = urls;

  let navItems = [
    {
      key: 'dashboard',
      item: (
        <Link href={dashboardUrl} type="anchor" size="small">
          {I18n.t('public.application.account_navigation.dashboard')}
        </Link>
      ),
    },
    // TODO: will be done when Quotes and Deals will be implemented
    // {
    //   key: 'deals',
    //   item: (
    //     <Link href={dealsUrl} type="anchor" size="small">
    //       {I18n.t('public.application.account_navigation.deals')}
    //     </Link>
    //   ),
    // },
    // {
    //   key: 'quotes',
    //   item: (
    //     <Link href={quotesUrl} type="anchor" size="small">
    //       {I18n.t('public.application.account_navigation.quotes')}
    //     </Link>
    //   ),
    // },
    {
      key: 'trips',
      item: (
        <Link href={tripsUrl} type="anchor" size="small">
          {I18n.t('public.application.account_navigation.trips')}
        </Link>
      ),
    },
    {
      key: 'approval_requests',
      item: (
        <Link href={approvalRequestsUrl} type="anchor" size="small">
          {I18n.t('public.application.account_navigation.approvals')}
        </Link>
      ),
    },
    {
      key: 'travel_arrangers',
      item: (
        <Link href={travelArrangersUrl} type="anchor" size="small">
          {I18n.t('public.application.account_navigation.travel_arrangers')}
        </Link>
      ),
    },
    {
      key: 'corporate_travel',
      item: (
        <Link href={corporateTravelUrl} type="anchor" size="small">
          {I18n.t('public.application.account_navigation.corporate_travel')}
        </Link>
      ),
    },
    {
      key: 'mobile_app',
      item: (
        <Link href={mobileAppUrl} type="anchor" size="small">
          {I18n.t('public.application.account_navigation.mobile_app')}
        </Link>
      ),
    },
  ];

  if (!scope.showCorporateTravel) {
    navItems = navItems.filter(item => item.key !== 'corporate_travel');
  }

  if (!approvalRequests.showApprovalRequest) {
    navItems = navItems.filter(item => item.key !== 'approval_requests');
  }

  return (
    <div className="col-12 col-bleed sub-nav" style={{ backgroundColor: colors.primaryColor }}>
      <nav className="col-12 col-bleed sub-nav__content">
        <ul className="col-12 col-bleed sub-nav__content-link">
          {navItems.map(item => (
            <li
              key={item.key}
              className={classNames('sub-nav__content-link-item', {
                'sub-nav__content-link-item--active': item.key === activeLink,
              })}
              style={{ '--text_color': colors.textColor }}
            >
              {item.item}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

SubNav.defaultProps = {
  activeLink: 'dashboard',
};

SubNav.propTypes = {
  colors: PropTypes.shape({
    primaryColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
  }).isRequired,
  urls: PropTypes.shape({
    dashboardUrl: PropTypes.string,
    dealsUrl: PropTypes.string,
    quotesUrl: PropTypes.string,
    tripsUrl: PropTypes.string,
    approvalRequestsUrl: PropTypes.string,
    travelArrangersUrl: PropTypes.string,
    corporateTravelUrl: PropTypes.string,
    mobileAppUrl: PropTypes.string,
  }).isRequired,
  activeLink: PropTypes.string,
  scope: PropTypes.shape({
    showCorporateTravel: PropTypes.bool,
  }).isRequired,
  approvalRequests: PropTypes.shape({
    showApprovalRequest: PropTypes.bool,
  }).isRequired,
};

export default SubNav;
