import React from 'react';
import PropTypes from 'prop-types';
import { SidePanel } from '@wtag/react-comp-lib';
import Segment from 'sharedWebpack/ItemWrapper/Flight/Segment';

const TripSegmentSidePanel = props => {
  const {
    title,
    group,
    findAirport,
    findAirline,
    showTripSegment,
    aircraftInformation,
    fetchAircraftType,
    onClick,
  } = props;

  const allSegmentsInformation =
    group &&
    group.segments &&
    group.segments.map(segment => (
      <Segment
        key={`${segment.originCode}-${segment.destinationCode}`}
        segment={segment}
        stopovers={group.stopovers}
        findAirport={findAirport}
        findAirline={findAirline}
        loadAircraft={showTripSegment}
        aircraftInformation={aircraftInformation}
        fetchAircraftType={fetchAircraftType}
      />
    ));

  const allSegmentsBody = allSegmentsInformation || null;

  return (
    <SidePanel
      className="flight-segment__sidepanel"
      title={title}
      body={allSegmentsBody}
      isOpened={showTripSegment}
      onClick={onClick}
    />
  );
};

TripSegmentSidePanel.defaultProps = {
  findAirline: () => {},
  aircraftInformation: {},
  fetchAircraftType: () => {},
};

TripSegmentSidePanel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  group: PropTypes.shape({
    segments: PropTypes.arrayOf(
      PropTypes.shape({
        originCode: PropTypes.string,
        destinationCode: PropTypes.string,
      }),
    ),
    stopovers: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  findAirport: PropTypes.func.isRequired,
  findAirline: PropTypes.func,
  showTripSegment: PropTypes.bool.isRequired,
  aircraftInformation: PropTypes.shape(),
  fetchAircraftType: PropTypes.func,
  onClick: PropTypes.func.isRequired,
};

export default TripSegmentSidePanel;
