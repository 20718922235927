const getNameValidationErrorMessage = (attributeName, nameDetails) => {
  if (!nameDetails || Object.keys(nameDetails).length === 0) {
    return null;
  }

  if (attributeName) {
    return I18n.t('components.ibe.traveler_details.attributes.cannot_be_blank', {
      attribute: I18n.t(`components.ibe.traveler_details.form.fields.${attributeName}`),
    });
  }

  const { firstName, middleName, lastName } = nameDetails;

  return firstName.error || middleName.error || lastName.error;
};

export default getNameValidationErrorMessage;
