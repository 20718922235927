import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Link, I18nText } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Icon from '@wtag/rcl-icon';
import { Table, TableHead, TableHeader, TableBody, TableData, TableRow } from '@wtag/rcl-table';

import './styles.scss';

const returnStringOnly = true;

const CustomerTableColumnHeaders = [
  <I18nText id="ibe.traveler_search_table.headers.id" returnStringOnly={returnStringOnly} />,
  <I18nText id="ibe.traveler_search_table.headers.name" returnStringOnly={returnStringOnly} />,
  <I18nText id="ibe.traveler_search_table.headers.email" returnStringOnly={returnStringOnly} />,
  <I18nText
    id="ibe.traveler_search_table.headers.organization"
    returnStringOnly={returnStringOnly}
  />,
];

const CustomerTableRow = ({ customer, onChange }) => (
  <TableRow className="customer-table__row" key={customer.id}>
    <TableData className="customer-table__cell" heading={true} smallScreenConfig={{ order: 2 }}>
      {customer.id}
    </TableData>
    <TableData className="customer-table__cell" smallScreenConfig={{ order: 3 }}>
      <Link onClick={() => onChange(customer)} type="default" modifier="default">
        <Avatar
          firstName={customer.firstName}
          lastName={customer.lastName}
          middleName={customer.middleName || ""}
          src={customer.profilePictureUrl}
          showName={true}
          size="tiny"
        />
      </Link>
    </TableData>
    <TableData className="customer-table__cell" smallScreenConfig={{ order: 4 }}>
      {customer.email ? (
        <div className="col-grid col-bleed direction-row align-center">
          <div className="traveler-search-form__search-traveler-table-data-icon">
            <Icon size="tiny" name="email" />
          </div>
          <div className="traveler-search-form__search-traveler-table-data-email">
            {customer.email}
          </div>
        </div>
      ) : (
        <div className="traveler-search-form__search-traveler-table-empty-data">
          {I18n.t('components.ibe.traveler_details.hints.no_email')}
        </div>
      )}
    </TableData>

    <TableData className="customer-table__cell" smallScreenConfig={{ order: 5 }}>
      {customer.organization ? (
        <div className="col-grid col-bleed direction-row align-center">
          <div className="traveler-search-form__search-traveler-table-data-icon">
            <Icon size="tiny" name="organization" />
          </div>
          <div className="traveler-search-form__search-traveler-table-data-organization">
            {customer.organization}
          </div>
        </div>
      ) : (
        <div className="traveler-search-form__search-traveler-table-empty-data">
          {I18n.t('components.ibe.traveler_details.hints.no_organization')}
        </div>
      )}
    </TableData>

    <TableData className="customer-table__cell" align="right" smallScreenConfig={{ order: 1 }}>
      <Radio size="huge" name="radio" onChange={() => onChange(customer)} />
    </TableData>
  </TableRow>
);

CustomerTableRow.propTypes = {
  customer: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

const CustomerTable = ({ customers, onChange, selectedCustomers }) => {
  const selectedValidCustomer =
    selectedCustomers.length > 0 &&
    selectedCustomers.filter(selectedCustomer => selectedCustomer !== null);
  let nonSelectedCustomers = [];

  nonSelectedCustomers =
    selectedValidCustomer.length > 0
      ? customers.filter(
          customer =>
            selectedValidCustomer.filter(
              selectedCustomer => selectedCustomer.id === customer.data.id,
            ).length === 0,
        )
      : [...customers];

  return (
    <div className="col-12 col-bleed traveler-search-form__search-traveler-table">
      <Table>
        <TableHead>
          <TableRow className="customer-table__row">
            {CustomerTableColumnHeaders.map(heading => (
              <TableHeader>{heading}</TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <div className="traveler-search-form__search-traveler-table--scroll styled-scrollbar">
          <TableBody className="customer-table__body">
            {nonSelectedCustomers.map(customer => (
              <CustomerTableRow
                key={customer.data.id}
                onChange={onChange}
                customer={customer.data}
              />
            ))}
          </TableBody>
        </div>
      </Table>
    </div>
  );
};

CustomerTable.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedCustomers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CustomerTable;
