import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import ClassNames from 'classnames';

import FlightPreferencesForm from './FlightPreferencesForm';

const FlightPreferencesInformation = props => {
  const {
    personId,
    fetchTraveller,
    flightPreferences,
    cabinTypes,
    seatTypes,
    mealTypes,
    layman,
  } = props;

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});
  const [preferredCabin, setPreferredCabin] = useState(null);
  const [preferredSeating, setPreferredSeating] = useState(null);
  const [meal, setMeal] = useState(null);

  const saveTravellerUrl = layman
    ? routes.public.people.update()
    : routes.admin.people.update({ personId });

  const updateFlightPreferences = async () => {
    const flightPreferencesParams = {
      preferred_cabin: preferredCabin,
      preferred_seating: preferredSeating,
      meal,
    };

    const { data } = await httpClient.patch(saveTravellerUrl, {
      person: flightPreferencesParams,
    });

    if (data.error === null) {
      fetchTraveller();
      setEditing(false);
    }
  };

  const onUpdateFlightPreferences = () =>
    updateFlightPreferences().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  useEffect(() => {
    setPreferredCabin(flightPreferences.preferredCabin);
    setPreferredSeating(flightPreferences.preferredSeating);
    setMeal(flightPreferences.meal);
  }, [flightPreferences]);

  return (
    <Fragment>
      <div className="traveller-edit-travel-preferences-section">
        <div className="traveller-edit-travel-preferences-section__title">
          <div className="traveller-edit-travel-preferences-section__header">
            {I18n.t('admin.components.travellers.overview.booking_preference.title.flight')}
          </div>
          {editing ? (
            <div className="traveller-edit-travel-preferences-section__actions">
              <IconButton
                icon={<Icon name="check" />}
                color="tertiary"
                size="small"
                onClick={onUpdateFlightPreferences}
              />
              <IconButton
                icon={<Icon name="close" />}
                color="tertiary"
                size="small"
                onClick={() => setEditing(false)}
              />
            </div>
          ) : (
            <div className="traveller-edit-travel-preferences-section__actions">
              <IconButton
                icon={<Icon name="edit" />}
                color="tertiary"
                size="small"
                onClick={() => setEditing(true)}
              />
            </div>
          )}
        </div>
        {editing ? (
          <FlightPreferencesForm
            preferredCabin={preferredCabin}
            setPreferredCabin={setPreferredCabin}
            preferredSeating={preferredSeating}
            setPreferredSeating={setPreferredSeating}
            meal={meal}
            setMeal={setMeal}
            cabinTypes={cabinTypes}
            seatTypes={seatTypes}
            mealTypes={mealTypes}
            error={error}
            layman={layman}
          />
        ) : (
          <div className="grid grid-gap-12">
            {!layman && (
              <div className="col-12">
                <div className="traveller-edit-travel-preferences__header">
                  {I18n.t('simple_form.labels.person.preferred_cabin')}
                </div>
                <div className="traveller-edit-travel-preferences__value">
                  {flightPreferences.preferredCabin
                    ? I18n.t(flightPreferences.preferredCabin, { scope: 'cabin_classes' })
                    : I18n.t(
                        'admin.components.travellers.overview.booking_preference.not_selected.preferred_cabin',
                      )}
                </div>
              </div>
            )}
            <div
              className={ClassNames('col-12', {
                'traveller-edit-travel-preferences__content': !layman,
              })}
            >
              <div className="traveller-edit-travel-preferences__header">
                {I18n.t('simple_form.labels.person.preferred_seating')}
              </div>
              <div className="traveller-edit-travel-preferences__value">
                {flightPreferences.preferredSeating
                  ? I18n.t(flightPreferences.preferredSeating, {
                      scope: 'simple_form.options.person.preferred_seating',
                    })
                  : I18n.t(
                      'admin.components.travellers.overview.booking_preference.not_selected.preferred_seating',
                    )}
              </div>
            </div>
            <div className="col-12 traveller-edit-travel-preferences__content">
              <div className="traveller-edit-travel-preferences__header">
                {I18n.t('simple_form.labels.person.meal')}
              </div>
              <div className="traveller-edit-travel-preferences__value">
                {flightPreferences.meal
                  ? I18n.t(flightPreferences.meal, {
                      scope: 'simple_form.options.person.meal',
                    })
                  : I18n.t(
                      'admin.components.travellers.overview.booking_preference.not_selected.meal',
                    )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

FlightPreferencesInformation.defaultProps = {
  layman: false,
  flightPreferences: {
    meal: '',
    preferredCabin: '',
    preferredSeating: '',
  },
  error: {},
};

FlightPreferencesInformation.propTypes = {
  layman: PropTypes.bool,
  personId: PropTypes.number.isRequired,
  fetchTraveller: PropTypes.func.isRequired,
  flightPreferences: PropTypes.shape({
    meal: PropTypes.string,
    preferredCabin: PropTypes.string,
    preferredSeating: PropTypes.string,
  }),
  mealTypes: PropTypes.shape({}).isRequired,
  cabinTypes: PropTypes.shape({}).isRequired,
  seatTypes: PropTypes.shape({}).isRequired,
  error: PropTypes.shape({
    preferredCabin: PropTypes.string,
    preferredSeating: PropTypes.string,
    meal: PropTypes.string,
  }),
};

export default FlightPreferencesInformation;
