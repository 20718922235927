import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'sharedWebpack/DateTime';

const TechnicalStop = ({ duration, arrivalLocaltime, airportName, count }) => (
  <div className="flight-segment-card__timeline-content flight-segment-card__timeline-content--technical-stop">
    {duration && (
      <span className="flight-segment__duration flight-segment__duration--technical-stop">
        {duration}
      </span>
    )}
    <span className="flight-segment-technical-stop__counter">
      {I18n.t('components.flight_info.segment.technical_stop', {
        count,
      })}
    </span>
    <div className="flight-segment-technical-stop__airport-details">
      {arrivalLocaltime && <DateTime dateTime={arrivalLocaltime} format="shortWithTime" />}
      {arrivalLocaltime && airportName && (
        <span className="flight-segment__at">{I18n.t('components.flight_info.at')}</span>
      )}
      {airportName}
    </div>
  </div>
);

TechnicalStop.defaultProps = {
  duration: null,
  arrivalLocaltime: null,
  airportName: null,
};

TechnicalStop.propTypes = {
  duration: PropTypes.string,
  arrivalLocaltime: PropTypes.string,
  airportName: PropTypes.string,
  count: PropTypes.string.isRequired,
};

export default TechnicalStop;
