import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { useEffect, useState } from 'react';
import { TYPE_ADULT, TYPE_CHILD, TYPE_INFANT } from '../../../helpers/passengerTypes';
import { DECISION_SELF } from '../../../helpers/decisionTypes';

const usePreselectTraveler = ({
  laymanMode,
  travelerLoggedIn,
  preselectDecision,
  preselectTravelers,
  callbackParams,
  updatePreselectTravelers,
  adults = 1,
  children = 0,
  infants = 0,
  shouldPreselect = true,
}) => {
  const [currentTraveler, setCurrentTraveler] = useState({});
  const preselectTravelersLength = preselectTravelers.length;

  const orderOwnerPresent = !laymanMode && callbackParams && callbackParams.order_id;
  const currentCustomerRetrievable =
    (travelerLoggedIn || orderOwnerPresent) && preselectTravelersLength === 0;

  const fetchCurrentUser = async () => {
    const { data } = await httpClient.get(routes.api.customers.currentCustomer({ callbackParams }));
    setCurrentTraveler(data);
  };

  const getInitialTravelers = (isAdultOptionDisabled = true) => {
    if (preselectDecision === DECISION_SELF && currentTraveler && currentTraveler.id) {
      return [
        {
          traveler: {
            value: currentTraveler,
            label: currentTraveler.infoDropdownTitle,
          },
          type: {
            label: I18n.t('components.ibe.preselect_traveler.label.type.adult'),
            value: TYPE_ADULT,
            isDisabled: isAdultOptionDisabled,
          },
        },
      ];
    }

    return [{}];
  };

  const updatePreselectTravelersStore = travelers => {
    const travelersData =
      travelers && Object.keys(travelers[0]).length ? travelers : getInitialTravelers();

    const travelersWithData = travelersData.filter(
      traveler => traveler.traveler && traveler.traveler.value,
    );
    const travelersWithType = travelersWithData.filter(
      traveler => traveler.type && traveler.type.value,
    );
    updatePreselectTravelers(travelersWithType);

    return travelersWithType;
  };

  const getFilteredTravelers = () => {
    const adultTravelers = preselectTravelers
      .filter(traveler => traveler.type && traveler.type.value === TYPE_ADULT)
      .slice(0, adults);
    const childTravelers = preselectTravelers
      .filter(traveler => traveler.type && traveler.type.value === TYPE_CHILD)
      .slice(0, children);
    const infantTravelers = preselectTravelers
      .filter(traveler => traveler.type && traveler.type.value === TYPE_INFANT)
      .slice(0, infants);
    const filteredTravelers = [...adultTravelers, ...childTravelers, ...infantTravelers];

    return filteredTravelers;
  };

  useEffect(() => {
    if (shouldPreselect) {
      updatePreselectTravelers(getFilteredTravelers());
    }
  }, [adults, children, infants]);

  useEffect(() => {
    if (currentCustomerRetrievable && shouldPreselect) {
      fetchCurrentUser();
    }
  }, [callbackParams, travelerLoggedIn]);

  useEffect(() => {
    if (
      preselectTravelersLength === 0 &&
      currentTraveler &&
      currentTraveler.id &&
      shouldPreselect
    ) {
      updatePreselectTravelersStore();
    }
  }, [preselectDecision, currentTraveler]);

  return {
    currentTraveler,
    setCurrentTraveler,
    updatePreselectTravelersStore,
    getFilteredTravelers,
    getInitialTravelers,
  };
};

export default usePreselectTraveler;
