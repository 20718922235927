import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DateTime from 'sharedWebpack/DateTime';
import {
  DATE_FORMAT_LONG_DAY_SHORT_WITH_TIME,
  DATE_FORMAT_TIME,
  DATE_FORMAT_SHORT_YEAR,
} from 'sharedWebpack/helpers/dateTime';

const CarDateTimeHeader = ({ displayDateTime, locationDetails }) => {
  if (!displayDateTime) {
    return null;
  }

  const { localtime, localtimeInUserTz } = locationDetails;
  const dateTimeValue = localtime || localtimeInUserTz;

  return (
    <Fragment>
      <span className="car__details--datetime-header hidden-md hidden-sm hidden-xs hidden-xxs">
        <DateTime
          dateTime={dateTimeValue}
          format={DATE_FORMAT_LONG_DAY_SHORT_WITH_TIME}
          displayWithoutConversion={true}
        />
      </span>
      <span className="car__details--datetime-header hidden-lg hidden-xlg">
        <DateTime
          dateTime={dateTimeValue}
          format={DATE_FORMAT_TIME}
          displayWithoutConversion={true}
        />
      </span>
      <span className="car__details--datetime-sub-header hidden-lg hidden-xlg">
        <DateTime
          dateTime={dateTimeValue}
          format={DATE_FORMAT_SHORT_YEAR}
          displayWithoutConversion={true}
        />
      </span>
    </Fragment>
  );
};

const LocationDetailsShape = {
  location: PropTypes.string,
  localtime: PropTypes.string,
  localtimeInUserTz: PropTypes.string,
};

CarDateTimeHeader.propTypes = {
  displayDateTime: PropTypes.bool.isRequired,
  locationDetails: PropTypes.shape(LocationDetailsShape).isRequired,
};

const CarLocationAndTime = ({ displayDateTime, label, locationDetails }) => {
  const { localtime, localtimeInUserTz, location } = locationDetails;
  const dateTimeValue = localtime || localtimeInUserTz;

  return (
    <div className="d-flex car__location-details">
      <div className="d-flex-col row-gap-4 car__location-details--label-location">
        <div className="car__location-details--label">{label}</div>
        {displayDateTime && (
          <div className="car__location-details--datetime">
            <DateTime
              dateTime={dateTimeValue}
              format={DATE_FORMAT_SHORT_YEAR}
              displayWithoutConversion={true}
            />
          </div>
        )}
        <div className="car__location-details--location">{location}</div>
      </div>
    </div>
  );
};

CarLocationAndTime.propTypes = {
  displayDateTime: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  locationDetails: PropTypes.shape(LocationDetailsShape).isRequired,
};

const CarLocationDetails = ({ displayDateTimeHeader, pickUpDetails, dropOffDetails }) => (
  <div className="grid grid-gap-12">
    <div className="col-6 col-grid">
      <CarDateTimeHeader displayDateTime={displayDateTimeHeader} locationDetails={pickUpDetails} />
      <CarLocationAndTime
        label={I18n.t('components.ibe.car_results.pick_up')}
        locationDetails={pickUpDetails}
        displayDateTime={!displayDateTimeHeader}
      />
    </div>
    <div className="col-6 col-grid">
      <CarDateTimeHeader displayDateTime={displayDateTimeHeader} locationDetails={dropOffDetails} />
      <CarLocationAndTime
        label={I18n.t('components.ibe.car_results.drop_off')}
        locationDetails={dropOffDetails}
        displayDateTime={!displayDateTimeHeader}
      />
    </div>
  </div>
);

CarLocationDetails.defaultProps = {
  displayDateTimeHeader: false,
};

CarLocationDetails.propTypes = {
  displayDateTimeHeader: PropTypes.bool,
  pickUpDetails: PropTypes.shape(LocationDetailsShape).isRequired,
  dropOffDetails: PropTypes.shape(LocationDetailsShape).isRequired,
};

export default CarLocationDetails;
