import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-styled-carousel';
import OrderCard from '../../Orders/Overview/OrderCard';

const UpcomingTravels = ({ orders }) => {
  const options = {
    showDots: false,
    cardsToShow: 2,
    RightArrow: (
      <button className="hotel-image-gallery__preview-button hotel-image-gallery__preview-button--right" />
    ),
    LeftArrow: (
      <button className="hotel-image-gallery__preview-button hotel-image-gallery__preview-button--left" />
    ),
    padding: null,
    infinite: false,
    autoSlide: false,
  };

  const responsiveOptions = [
    { breakPoint: 991, cardsToShow: 2},
    { breakPoint: 320, cardsToShow: 1}
  ]

  return (
    <div className="upcoming-travels">
      <div className="upcoming-travels__slider">
        <Slider {...options} responsive={responsiveOptions}>
          {orders.map(order => (
            <OrderCard key={order.id} order={order} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

UpcomingTravels.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
};

export default UpcomingTravels;
