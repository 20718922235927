import isAvailable from './isAvailable';

const getWifiInfo = wifiAvailability =>
  isAvailable(wifiAvailability.key)
    ? `\n${I18n.t('components.ibe.search_results.hotel.content.wifi', {
        label: wifiAvailability.label,
      })}`
    : '';

export default getWifiInfo;
