import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';

const Insurance = props => (
  <I18nText id={`ibe.search_results.car.insurance_and_liability.${props.type}`} />
);

Insurance.propTypes = {
  type: PropTypes.string.isRequired,
};
export default Insurance;
