const getHotelAddress = hotelAddress => {
  const { city, country, street } = hotelAddress;
  let address = '';

  if (street || city || country) {
    if (street) {
      address = street;
    }

    if (city) {
      address += address ? `, ${city}` : city;
    }

    if (country) {
      address += address ? `, ${country}` : country;
    }
  }

  return address;
};

export default getHotelAddress;
