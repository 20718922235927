import React, { Fragment } from 'react';
import DateTime from 'sharedWebpack/DateTime';
import PropTypes from 'prop-types';
import HotelItemDetail from './HotelItemDetail';
import './styles.scss';

const HotelRoomInfoCompactView = ({ checkIn, checkOut, nightCount, quantity, name }) => {
  const roomInformation = `${quantity} ${name}`;

  return (
    <Fragment>
      <div className="hotel-room-info-compact">
        <div className="hotel-room-info-compact-details">
          <HotelItemDetail
            title={I18n.t('admin.components.orders.items_tab.placeholder.duration_of_staying')}
            subtitle={
              nightCount &&
              I18n.t('admin.components.orders.items_tab.placeholder.night_count', {
                count: nightCount,
              })
            }
          />
        </div>

        <div className="hotel-room-info-compact-details-rooms">
          <HotelItemDetail
            title={I18n.t('components.ibe.hotel.details.room')}
            subtitle={roomInformation}
          />
        </div>

        <HotelItemDetail
          title={I18n.t('admin.components.orders.items_tab.placeholder.check_in')}
          subtitle={
            <DateTime
              dateTime={checkIn}
              format="shortNumericalWithTime"
              displayWithoutConversion={true}
            />
          }
        />

        <HotelItemDetail
          title={I18n.t('admin.components.orders.items_tab.placeholder.check_out')}
          subtitle={
            <DateTime
              dateTime={checkOut}
              format="shortNumericalWithTime"
              displayWithoutConversion={true}
            />
          }
        />
      </div>
    </Fragment>
  );
};

HotelRoomInfoCompactView.propTypes = {
  checkIn: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired,
  nightCount: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default HotelRoomInfoCompactView;
