import { ACCOUNTING_TYPES_FETCHED } from '../../actions/common';

export default function accountingTypes(state = [], action) {
  switch (action.type) {
    case ACCOUNTING_TYPES_FETCHED:
      return action.accountingTypes;

    default:
      return state;
  }
}
