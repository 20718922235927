import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-styled-carousel';
import { Link } from '@wtag/react-comp-lib';
import HotelDefaultImage from 'agentSyncedImages/fallback_images/product/product_xxl_hotel.png';

const HotelImageGallery = ({ images, travelAppLayout }) => {
  const [focusedImage, setFocusedImage] = useState(images[0]);

  if (images.length <= 0) {
    return null;
  }

  const noPreview = images.length <= 1;

  let responsive = [
    { breakPoint: 1750, cardsToShow: 7 },
    { breakPoint: 1590, cardsToShow: 6 },
    { breakPoint: 1199, cardsToShow: 5 },
    { breakPoint: 991, cardsToShow: 4 },
    { breakPoint: 0, cardsToShow: 3 },
  ];

  if (travelAppLayout) {
    responsive = [
      { breakPoint: 1199, cardsToShow: 5 },
      { breakPoint: 991, cardsToShow: 4 },
      { breakPoint: 0, cardsToShow: 3 },
    ];
  }

  const options = {
    responsive,
    showDots: false,
    RightArrow: (
      <button className="hotel-image-gallery__preview-button hotel-image-gallery__preview-button--right" />
    ),
    LeftArrow: (
      <button className="hotel-image-gallery__preview-button hotel-image-gallery__preview-button--left" />
    ),
    padding: null,
    autoSlide: 3000,
  };

  const setPlaceholderImage = event => {
    const target = event.target;
    target.src = HotelDefaultImage;
  };

  return (
    <div className="hotel-image-gallery">
      <div
        className={classNames('hotel-image-gallery__focus', {
          'hotel-image-gallery__focus--full-height': noPreview,
        })}
      >
        <img
          alt="Focused"
          className="hotel-image-gallery__focus-image"
          onError={setPlaceholderImage}
          src={focusedImage.original || HotelDefaultImage}
        />
      </div>

      {!noPreview && (
        <div className="hotel-image-gallery__preview">
          <Slider {...options}>
            {images.map(image => (
              <Link
                className="hotel-image-gallery__preview-element"
                key={image.original}
                onClick={() => setFocusedImage(image)}
                type="default"
              >
                <img
                  alt="Thumbnail"
                  onError={setPlaceholderImage}
                  className="hotel-image-gallery__preview-element-image"
                  src={image.thumbnail || HotelDefaultImage}
                />
              </Link>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

HotelImageGallery.defaultProps = {
  images: [],
};

HotelImageGallery.propTypes = {
  travelAppLayout: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
  ),
};

export default HotelImageGallery;
