import React from 'react';

const AirportOption = (countryFlag, text, secondaryText) => (
  <div className="airport-option col-grid col-bleed direction-row align-center">
    <div className="airport-option__image">
      <img src={countryFlag} alt={`flag ${text}`} />
    </div>
    <div className="airport-option__text-container">
      <div className="airport-option__text">
        {text}
      </div>
      <div className="airport-option__secondary-text">
        {secondaryText}
      </div>
    </div>
  </div>
);

export default AirportOption;
