import React from 'react';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';
import snakeCaseKeys from 'snakecase-keys';
import { Link } from '@wtag/react-comp-lib';
import staticInformationShape from '../staticInformationShape';

const StaticLinks = ({ staticInformation }) => {
  const staticLinksInformation = snakeCaseKeys(staticInformation);

  const iconNameMapper = {
    deals: 'tag',
    travel_news: 'newspaper',
    airports: 'location',
    airlines: 'plane',
    destination: 'map',
    stories: 'editSquareBox',
    reviews: 'star',
    community: 'people',
  };

  const staticLinkCountI18n = {
    deals: I18n.t('public.components.mid_section.counts.deals', {
      count: staticLinksInformation.deals.total,
    }),
    travel_news: I18n.t('public.components.mid_section.contents.coming_soon'),
    airports: I18n.t('public.components.mid_section.contents.coming_soon'),
    airlines: I18n.t('public.components.mid_section.contents.coming_soon'),
    destination: I18n.t('public.components.mid_section.contents.coming_soon'),
    stories: I18n.t('public.components.mid_section.counts.stories', {
      count: staticLinksInformation.stories.total,
    }),
    reviews: I18n.t('public.components.mid_section.contents.coming_soon'),
    community: I18n.t('public.components.mid_section.contents.coming_soon'),
  };

  const renderStaticLinkCard = (staticLinkObj, staticLinkType) => (
    <div className="col-lg-12 col-md-12 col-6 col-bleed static-links__pair-card">
      <Link href={staticLinkObj.url} type="anchor" modifier="default">
        <div className="static-links__pair-card-content">
          <div
            className={classNames('static-links__pair-card-content-icon', {
              'static-links__pair-card-content-icon--active': !!staticLinkObj.url,
            })}
          >
            <Icon name={iconNameMapper[staticLinkType]} size="normal" showBGColor={true} />
          </div>
          <div className="static-links__pair-card-content-info">
            <div
              className={classNames('static-links__pair-card-content-info-name', {
                'static-links__pair-card-content-info-name--active': !!staticLinkObj.url,
              })}
            >
              {I18n.t(staticLinkType, {
                scope: 'public.components.mid_section.static_links',
              })}
            </div>
            <div className="static-links__pair-card-content-info-count">
              {staticLinkCountI18n[staticLinkType]}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );

  const renderStaticLinksPair = (linkType1, linkType2) => (
    <div className="col-grid col-bleed static-links__pair">
      {renderStaticLinkCard(staticLinksInformation[linkType1], linkType1)}
      {renderStaticLinkCard(staticLinksInformation[linkType2], linkType2)}
    </div>
  );

  return (
    <div className="col-grid col-bleed static-links">
      {renderStaticLinksPair('deals', 'travel_news')}
      {renderStaticLinksPair('airports', 'airlines')}
      {renderStaticLinksPair('destination', 'stories')}
      {renderStaticLinksPair('reviews', 'community')}
    </div>
  );
};

StaticLinks.propTypes = {
  staticInformation: staticInformationShape.isRequired,
};

export default StaticLinks;
