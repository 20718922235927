import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from '@wtag/rcl-link';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';
import Avatar from '@wtag/rcl-avatar';
import { SidePanel } from '@wtag/react-comp-lib';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';

const ETicketsLink = ({ tripId, tripSegments }) => {
  const [query, setQuery] = useQueryParams({
    openedSidePanel: withDefault(StringParam, ''),
  });

  const hideSidePanel = () => {
    setQuery({ openedSidePanel: '' }, 'replaceIn');
  };

  const showSidePanel = () => {
    setQuery({ openedSidePanel: `eticket-${tripId}` }, 'replaceIn');
  };

  const eTicketSidePanelVisible = query.openedSidePanel === `eticket-${tripId}`;

  const tripSegmentWithNumber = (segment, segmentIndex) =>
    I18n.t('components.flight.segment_details', {
      number: String(segmentIndex + 1),
      origin_code: segment.originCode,
      destination_code: segment.destinationCode,
    });

  const tripSegmentEtickets = segment =>
    segment.tickets.map(ticket => {
      const ticketTravler = ticket.traveler || {};
      return (
        <Card key={ticket.number} version="v2">
          <div className="col-grid col-bleed direction-row align-center">
            <Avatar
              firstName={ticketTravler.firstName}
              lastName={ticketTravler.lastName}
              src={ticketTravler.profilePictureUrl}
              size="tiny"
            />
            <div className="flight-item-trip-eticket-seats__name">{ticketTravler.name}</div>
          </div>
          <div className="flight-item-trip-eticket-seats__number">{ticket.number}</div>
        </Card>
      );
    });

  const etickets = () =>
    tripSegments.map((segment, segmentIndex) => {
      if (segment.tickets.length > 0) {
        return (
          <div className="flight-item-trip-eticket-seats">
            <div className="flight-item-trip-eticket-seats__segment">
              {tripSegmentWithNumber(segment, segmentIndex)}
            </div>
            {tripSegmentEtickets(segment)}
          </div>
        );
      }
      return null;
    });

  return (
    <Fragment>
      <Link
        className="flight-item-trip-header__service-status__eticket-link"
        onClick={showSidePanel}
        modifier="tertiary"
        size="tiny"
      >
        <Icon
          className="flight__information-label-icon"
          name="lounge"
          size="tiny"
          color="default"
          showLabel={true}
          iconLabel={I18n.t('components.flight.eticket.title')}
        />
      </Link>
      <SidePanel
        title={I18n.t('components.flight.eticket.numbers')}
        body={etickets()}
        isOpened={eTicketSidePanelVisible}
        onClick={hideSidePanel}
      />
    </Fragment>
  );
};

ETicketsLink.propTypes = {
  tripId: PropTypes.string.isRequired,
  tripSegments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default withQueryParamsProvider(ETicketsLink);
