import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Radio from '@wtag/rcl-radio';

const SeatOption = ({ disabled, passenger, setTravelerIndex, bookedFor, name, selectedSeatNumber }) => {
  const onClick = () => {
    if (!disabled && bookedFor !== passenger.value) {
      setTravelerIndex(passenger.value);
    }
  };

  return (
    <div
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
      onClick={onClick}
      className={classNames('seat-details__list-item', {
        'seat-details__list-item--disabled': disabled,
      })}
    >
      <Radio size="large" name={name()} isChecked={bookedFor === passenger.value} />
      <span className="seat-details__list-item-traveler-title">
        {I18n.t('components.ibe.traveler_details.title', { index: passenger.value + 1 })}
      </span>
      {selectedSeatNumber && (
        <EmphasisTag
          className="seat-details__list-item-traveler-seat"
          text={selectedSeatNumber}
          type="accent"
          size="small"
        />
      )}
      <small className="seat-details__traveler-type">
        <EmphasisTag
          text={I18n.t(passenger.type, { scope: 'passenger.types' })}
          type="disabled"
          size="small"
        />
      </small>
    </div>
  );
};

SeatOption.defaultProps = {
  selectedSeatNumber: null,
  bookedFor: null,
};

SeatOption.propTypes = {
  passenger: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  setTravelerIndex: PropTypes.func.isRequired,
  selectedSeatNumber: PropTypes.string,
  bookedFor: PropTypes.number,
  name: PropTypes.func.isRequired,
};

export default SeatOption;
