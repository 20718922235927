import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import IconButton from 'material-ui/IconButton';
import FlightsIcon from 'material-ui/svg-icons/maps/flight';
import HotelsIcon from 'material-ui/svg-icons/maps/hotel';
import CarsIcon from 'material-ui/svg-icons/maps/directions-car';
import TrainsIcon from 'material-ui/svg-icons/maps/train';
import CruisesIcon from 'material-ui/svg-icons/maps/directions-boat';
import ActivitiesIcon from 'material-ui/svg-icons/places/hot-tub';
import ToursIcon from 'material-ui/svg-icons/action/explore';
import StaticIcon from 'material-ui/svg-icons/maps/local-activity';
import ActionShoppingCart from 'material-ui/svg-icons/action/shopping-cart';
import Button from '@wtag/rcl-button';
import accountTypeAvailable from '../lib/accountTypeAvailable';

class TopBar extends React.Component {
  shouldEnableFor = type => {
    if (this.context.exchange.enabled) return false;
    return !this.context.laymanMode || accountTypeAvailable(this.context.availableChannels, type);
  };

  flightEnabled = () => this.shouldEnableFor('flights');
  hotelEnabled = () => this.shouldEnableFor('hotels');
  carEnabled = () => this.shouldEnableFor('cars');
  trainEnabled = () => this.shouldEnableFor('trains');
  cruiseEnabled = () => this.shouldEnableFor('cruises');
  activityEnabled = () => this.shouldEnableFor('activities');
  tourEnabled = () => this.shouldEnableFor('tours');

  handleDrawer = () => {
    if (!this.props.drawerState) {
      if (this.props.toggleDrawer != null) {
        this.props.toggleDrawer();
      }
    }
  };

  handleReset = () => {
    this.props.resetApp();
    this.handleDrawer();
  };

  render() {
    return (
      <div
        className={classNames({
          'container container--sm-full-width public-booking-app': this.context.laymanMode,
          'container-full': !this.context.laymanMode,
        })}
      >
        <Toolbar className="topbar">
          <ToolbarGroup firstChild={true} className="topbar-left">
            {I18n.t('components.ibe.top_bar.search_title', { title: this.props.title })}
          </ToolbarGroup>
          <ToolbarGroup lastChild={true} className="topbar-right">
            {this.flightEnabled() && (
              <Link to="/flights" onClick={this.handleDrawer}>
                <IconButton tooltip={I18n.t('components.ibe.title.flights')}>
                  <FlightsIcon />
                </IconButton>
              </Link>
            )}
            {this.hotelEnabled() && (
              <Link to="/hotels" onClick={this.handleDrawer}>
                <IconButton tooltip={I18n.t('components.ibe.title.hotels')}>
                  <HotelsIcon />
                </IconButton>
              </Link>
            )}
            {this.carEnabled() && (
              <Link to="/cars" onClick={this.handleDrawer}>
                <IconButton tooltip={I18n.t('components.ibe.title.rental_cars')}>
                  <CarsIcon />
                </IconButton>
              </Link>
            )}
            {this.trainEnabled() && (
              <Link to="/trains">
                <IconButton tooltip={I18n.t('components.ibe.title.trains')}>
                  <TrainsIcon />
                </IconButton>
              </Link>
            )}
            {this.cruiseEnabled() && (
              <Link to="/cruises">
                <IconButton tooltip={I18n.t('components.ibe.title.cruises')}>
                  <CruisesIcon />
                </IconButton>
              </Link>
            )}
            {this.activityEnabled() && (
              <Link to="/activities">
                <IconButton tooltip={I18n.t('components.ibe.title.activities')}>
                  <ActivitiesIcon />
                </IconButton>
              </Link>
            )}
            {this.tourEnabled() && (
              <Link to="/tours">
                <IconButton tooltip={I18n.t('components.ibe.title.tours')}>
                  <ToursIcon />
                </IconButton>
              </Link>
            )}
            {!this.context.exchange.enabled && this.props.hasStaticProducts && (
              <Link to="/static">
                <IconButton tooltip={I18n.t('components.ibe.title.other')}>
                  <StaticIcon />
                </IconButton>
              </Link>
            )}
            {this.props.cartId && (
              <Link to={`/carts/${this.props.cartId}`}>
                <IconButton tooltip={I18n.t('components.ibe.cart_title')}>
                  <ActionShoppingCart />
                </IconButton>
              </Link>
            )}

            <Button
              size="small"
              label={I18n.t('components.ibe.top_bar.reset_app')}
              onClick={this.handleReset}
            />
          </ToolbarGroup>
        </Toolbar>
      </div>
    );
  }
}

TopBar.defaultProps = {
  cartId: null,
  toggleDrawer: null,
};

TopBar.propTypes = {
  drawerState: PropTypes.bool.isRequired,
  resetApp: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  cartId: PropTypes.string,
  hasStaticProducts: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func,
};

TopBar.contextTypes = {
  laymanMode: PropTypes.bool,
  availableChannels: PropTypes.shape(),
  exchange: PropTypes.shape(),
};

export default TopBar;
