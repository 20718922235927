/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ProductIcon from 'sharedWebpack/ProductIcon';
import DateTime from 'sharedWebpack/DateTime';
import Card from '@wtag/rcl-card';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import StackedItems from '@wtag/rcl-stacked-items';
import CarFallbackImage from '../../../../assets/images/synced/fallback_images/product/product_xxl_car.png';
import '../styles.scss';

const Car = ({ data }) => {
  const [isTravellerPopoverVisible, setIsTravellerPopoverVisible] = useState(false);
  return (
    <Fragment>
      <div className="col-12 col-sm-3 col-xlg-2 travel-plan__timeline-item-section-product">
        <div className="travel-plan__timeline-item-section-product-info">
          <div className="travel-plan__timeline-item-section-product-icon">
            <ProductIcon productType={data.type} showBGColor={true} size="medium" />
          </div>
          <div className="travel-plan__timeline-item-section-product-name">
            {I18n.t('admin.components.orders.overview.travel_plan.car.title')}
          </div>
          <div className="travel-plan__timeline-item-section-product-duration">
            {I18n.t('admin.components.orders.overview.travel_plan.car.day_count', {
              day_count: data.totalDays,
            })}
          </div>
          <div className="travel-plan__timeline-item-section-product-travellers">
            {data.travelers && data.travelers.length > 0 && (
              <StackedItems
                itemSize="tiny"
                isPopoverVisible={isTravellerPopoverVisible}
                onClick={() =>
                  setIsTravellerPopoverVisible(
                    prevTravellerPopoverState => !prevTravellerPopoverState,
                  )
                }
                onOutsideClick={() => setIsTravellerPopoverVisible(false)}
              >
                {data.travelers.map(traveler => (
                  <Avatar
                    firstName={traveler.firstName}
                    lastName={traveler.lastName}
                    src={traveler.avatarUrl}
                  />
                ))}
              </StackedItems>
            )}
          </div>
        </div>
        <div className="travel-plan__timeline-item-indicator">
          <div className="travel-plan__timeline-item-indicator-line" />
        </div>
      </div>
      <div className="col-12 col-sm-9 col-xlg-10">
        <div className="grid grid-gap-20">
          <div className="col-12">
            <Card className="travel-plan__timeline-item-section-card" version="v2">
              <div className="travel-plan__timeline-item-section-card-header">
                {data && data.vendorImageUrl && (
                  <img
                    className="travel-plan__timeline-item-section-card-header-vendor-image"
                    src={data.vendorImageUrl}
                    alt={data && data.vendorName}
                  />
                )}
                <div className="travel-plan__timeline-item-section-card-header-details">
                  {data && data.vendorName}
                  {data.category && data.acrissCode && (
                    <EmphasisTag
                      className="travel-plan__timeline-item-section-card-header-details-tag"
                      text={`${I18n.t(data.category, {
                        scope: 'simple_form.options.person.preferred_car_categories',
                      })} ${data.acrissCode}`}
                      size="tiny"
                    />
                  )}
                </div>
              </div>
              <div className="travel-plan__section-divider travel-plan__timeline-item-section-divider" />
              <div className="travel-plan__timeline-item-section-card-body">
                <div className="travel-plan__timeline-item-section-card-body-travel-info">
                  <div className="travel-plan__timeline-item-section-card-body-travel-time">
                    {data.pickUpTime && (
                      <DateTime dateTime={data.pickUpTime} format="shortWithTime" />
                    )}
                    {data.dropOffTime && (
                      <DateTime dateTime={data.dropOffTime} format="shortWithTime" />
                    )}
                  </div>
                  {data.pickUpTime && (
                    <div className="travel-plan__timeline-item-section-card-body--muted-text">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.car.header.pick_up.label',
                      )}
                    </div>
                  )}
                  {data.dropOffTime && (
                    <div className="travel-plan__timeline-item-section-card-body--muted-text">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.car.header.drop_off.label',
                      )}
                    </div>
                  )}
                  {data.pickUpLocation && (
                    <div className="travel-plan__timeline-item-section-card-body-travel-address">
                      {data.pickUpLocation}
                    </div>
                  )}
                  {data.dropOffLocation && (
                    <div className="travel-plan__timeline-item-section-card-body-travel-address">
                      {data.dropOffLocation}
                    </div>
                  )}
                </div>
                <div className="travel-plan__timeline-item-section-card-body-image">
                  {data && data.carImageUrl ? (
                    <img
                      className="travel-plan__timeline__car-card__car-info-image-car"
                      src={data && data.carImageUrl}
                      alt="car"
                    />
                  ) : (
                    <img src={CarFallbackImage} alt="car" />
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Car.propTypes = {
  data: PropTypes.shape({
    pickUpTime: PropTypes.string,
    pickUpLocation: PropTypes.string,
    dropOffTime: PropTypes.string,
    dropOffLocation: PropTypes.string,
    type: PropTypes.string,
    totalDays: PropTypes.number,
    name: PropTypes.string,
    carImageUrl: PropTypes.string,
    category: PropTypes.string,
    acrissCode: PropTypes.string,
    vendorName: PropTypes.string,
    vendorImageUrl: PropTypes.string,
    travelers: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  }).isRequired,
};

export default Car;
