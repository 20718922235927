import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import Flight from '../Flight';
import FlightTimeLine from '../FlightTimeline';

const FlightContent = ({ index, plan, flightPlanMap }) => {
  const { tripId, type, wholeDurationInMinutes, travelers } = plan;
  const flightPlan = flightPlanMap[tripId] || {};
  const hasGroupItem = Object.keys(flightPlan).length > 0;
  const { groupStartIndex, group } = flightPlan;

  if (hasGroupItem && index !== groupStartIndex) {
    return null;
  }

  const getFlightTimelineContent = () => {
    if (!hasGroupItem) {
      return <Flight data={plan} />;
    }

    return group.map(item => <Flight key={uuidv4()} data={item} />);
  };

  const flightTimelineContent = getFlightTimelineContent();

  return (
    <div className="grid grid-gap-20 travel-plan__timeline-item travel-plan__timeline-item--with-background">
      <FlightTimeLine
        type={type}
        wholeDurationInMinutes={wholeDurationInMinutes}
        travelers={travelers}
      />
      <div
        className={classNames('col-12 col-sm-9 col-xlg-10 d-flex direction-column', {
          'row-gap-28': Array.isArray(flightTimelineContent),
        })}
      >
        {flightTimelineContent}
      </div>
    </div>
  );
};

const planShape = PropTypes.shape({
  tripId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  wholeDurationInMinutes: PropTypes.number.isRequired,
  arrivalLocaltime: PropTypes.string,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string.isRequired,
    }),
  ),
});

FlightContent.propTypes = {
  index: PropTypes.number.isRequired,
  plan: planShape.isRequired,
  flightPlanMap: PropTypes.shape({
    [PropTypes.number]: PropTypes.shape({
      startIndex: PropTypes.number.isRequired,
      group: PropTypes.arrayOf(planShape).isRequired,
    }),
  }).isRequired,
};

export default hot(module)(FlightContent);
