const getCarInformation = (name, make) => {
  if (make && name) {
    return `${make} ${name}`;
  }

  if (make) {
    return make;
  }

  return I18n.t('components.ibe.car.details.unspecified');
};

export default getCarInformation;
