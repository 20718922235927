import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import classNames from 'classnames';
import { MULTICITY, ONEWAY, ROUNDTRIP } from '../../../lib/helpers/tripTypes';
import './style.scss';

const TripTypeButton = ({ isActive, onClick, tripType }) => {
  const tripTypeDetails = {
    [ONEWAY]: {
      iconName: 'oneWay',
      label: I18n.t('components.ibe.search_form.flight.label.tooltip.one_way'),
      activeLabel: I18n.t('components.ibe.search_form.flight.label.one_way'),
    },

    [ROUNDTRIP]: {
      iconName: 'roundTrip',
      label: I18n.t('components.ibe.search_form.flight.label.tooltip.round_trip'),
      activeLabel: I18n.t('components.ibe.search_form.flight.label.round_trip'),
    },

    [MULTICITY]: {
      iconName: 'multiCity',
      label: I18n.t('components.ibe.search_form.flight.label.tooltip.multi_city'),
      activeLabel: I18n.t('components.ibe.search_form.flight.label.multi_city'),
    },
  };

  const { iconName, label, activeLabel } = tripTypeDetails[tripType];

  const handleClick = event => {
    event.preventDefault();

    if (!isActive) {
      onClick(event);
    }
  };

  return (
    <IconButton
      className={classNames('trip-type-button', {
        'trip-type-button--active': isActive,
      })}
      icon={<Icon name={iconName} size="normal" />}
      label={isActive ? activeLabel : label}
      color={isActive ? 'tertiary' : 'default'}
      isInverse={isActive}
      onClick={handleClick}
    />
  );
};

TripTypeButton.defaultProps = {
  isActive: false,
};

TripTypeButton.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tripType: PropTypes.string.isRequired,
};

export default TripTypeButton;
