import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import TravellerCard from './TravellerCard';

import '../../styles.scss';

const TravellersTab = ({ orderId, layman }) => {
  const [travellers, setTravellers] = useState([]);
  const [travellerTypes, setTravellerTypes] = useState([]);
  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, ''),
  });

  const { tab } = query;

  const fetchTravellersUrl = layman
    ? routes.public.orders.travellers({ orderId })
    : routes.admin.orderTravellers({ orderId });

  const fetchTravellers = async () => {
    const { data } = await httpClient.get(fetchTravellersUrl);

    setTravellers(data.travellers);
    setTravellerTypes(data.travellerTypes);
  };

  const travellersVisaCheck = () => {
    let count = 0;
    travellers.forEach(traveller => {
      if (traveller.expiredVisaNumbersCount > 0) {
        count += 1;
      }
    });
    return count;
  };

  const adultChildInfo = () =>
    Object.entries(travellerTypes)
      .filter(([type, count]) => type && count > 0)
      .map(([type, count]) => I18n.t(`admin.components.traveller_type.${type}`, { count }))
      .join(' , ');

  useEffect(() => {
    fetchTravellers();
    setQuery({ tab: 'travellers' }, 'replaceIn');
  }, [tab]);

  return (
    <Fragment>
      <Card version="v2">
        {travellers && travellers.length > 0 ? (
          <div className="order-travellers-tab__type-information-card wrap">
            <div className="order-travellers-tab__type-information-card-text">{`${adultChildInfo()} ${I18n.t(
              'admin.components.orders.travellers_tab.type_information.in_order',
            )}`}</div>
            {travellersVisaCheck() > 0 ? (
              <div className="order-travellers-tab__visa-information">
                <Icon
                  name="closeCircle"
                  size="normal"
                  color="danger"
                  className="order-travellers-tab__visa-information-icon"
                />
                <span>
                  {I18n.t('admin.components.orders.left_panel.visa_numbers.invalid', {
                    count: travellersVisaCheck(),
                  })}
                </span>
              </div>
            ) : (
              <div className="order-travellers-tab__visa-information">
                <Icon
                  name="checkCircle"
                  size="normal"
                  color="success"
                  className="order-travellers-tab__visa-information-icon"
                />
                <span>{I18n.t('admin.components.orders.left_panel.visa_numbers.valid')}</span>
              </div>
            )}
          </div>
        ) : (
          I18n.t('admin.components.orders.travellers_tab.information.no_travellers')
        )}
      </Card>
      {travellers.map(traveller => (
        <TravellerCard traveller={traveller} layman={layman} />
      ))}
    </Fragment>
  );
};

TravellersTab.defaultProps = {
  layman: false,
};

TravellersTab.propTypes = {
  orderId: PropTypes.number.isRequired,
  layman: PropTypes.bool,
};

export default withQueryParamsProvider(TravellersTab);
