import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@wtag/rcl-button';
import classNames from 'classnames';
import { filterCarSearch } from '../../../actions/car';
import {
  SORT_BY_PRICE_CHEAPEST,
  SORT_BY_PRICE_HIGHEST,
  SORT_BY_FILTER_KEY,
} from '../helpers/carSortTypes';
import './styles.scss';

const LAST_SEARCH = 'lastSearch';

const CarFilterButton = ({ sortBy, onCarSearchResultSort }) => (
  <div className="car-filter-button__sort-button d-flex">
    <Button
      className={classNames({
        'car-filter-button__sort-button--disabled': sortBy === SORT_BY_PRICE_CHEAPEST,
      })}
      label={I18n.t('ibe.available_sort_options.price_cheapest')}
      size="small"
      disabled={sortBy === SORT_BY_PRICE_CHEAPEST}
      onClick={() => onCarSearchResultSort(SORT_BY_FILTER_KEY, SORT_BY_PRICE_CHEAPEST)}
    />
    <Button
      className={classNames({
        'car-filter-button__sort-button--disabled': sortBy === SORT_BY_PRICE_HIGHEST,
      })}
      disabled={sortBy === SORT_BY_PRICE_HIGHEST}
      label={I18n.t('ibe.available_sort_options.price_highest_text')}
      size="small"
      onClick={() => onCarSearchResultSort(SORT_BY_FILTER_KEY, SORT_BY_PRICE_HIGHEST)}
    />
  </div>
);

CarFilterButton.propTypes = {
  sortBy: PropTypes.string.isRequired,
  onCarSearchResultSort: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let searchParamsId = LAST_SEARCH;
  if (ownProps.match.params.searchId) {
    searchParamsId = ownProps.match.params.searchId;
  }

  const carSearchFilterParams = state.cars.searchFilterParamsBySearchId[searchParamsId];
  const shouldSortByCarSearchFilterParams = searchParamsId !== LAST_SEARCH && carSearchFilterParams;
  const sortBy = shouldSortByCarSearchFilterParams
    ? carSearchFilterParams.sortBy
    : SORT_BY_PRICE_CHEAPEST;

  return {
    sortBy,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCarSearchResultSort: (filter, value) =>
    dispatch(filterCarSearch(filter, value, ownProps.match.params.searchId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarFilterButton);
