import { SUCCESS } from 'sharedWebpack/IBE/lib/helpers/componentStates';

const getBaggageItems = (isServiceAvailable, uniqueBaggagePiecesTextList) => {
  if (isServiceAvailable && Array.isArray(uniqueBaggagePiecesTextList)) {
    return uniqueBaggagePiecesTextList.map(text => ({ status: SUCCESS, title: text }));
  }

  return [];
};

export default getBaggageItems;
