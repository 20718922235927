import formatPrice from './formatPrice';

const getPriceInfo = (fareCalculations, itemCurrency, grossTotalIncludingSubItems) => {
  const perTravelerPrices = fareCalculations
    .map(({ currency, price, passengerType }) =>
      I18n.t(passengerType, {
        scope: 'components.ibe.search_results.flight.content.traveler_modifier',
        price: formatPrice(price, currency),
      }),
    )
    .join(', ');

  return `${formatPrice(grossTotalIncludingSubItems, itemCurrency)} (${perTravelerPrices})`;
};

export default getPriceInfo;
