import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import Button from '@wtag/rcl-button';

class RemoveFromCartButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
      isModalOpen: false,
    };
  }

  onConfirm = () => {
    this.setState(prevState => ({
      ...prevState,
      buttonDisabled: true,
    }));
    this.props.onConfirm(this.props.itemId);
  };

  setModalOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      isModalOpen: true,
    }));
  };
  setModalClose = () => {
    this.setState(prevState => ({
      ...prevState,
      isModalOpen: false,
    }));
  };

  render() {
    const buttonDisabled =
      this.props.isCartLocked || this.context.frozen || this.state.buttonDisabled;

    return (
      <React.Fragment>
        <Button
          version="v2"
          size="small"
          standalone={true}
          label={<I18nText id="ibe.remove_item.remove" returnStringOnly={true} />}
          onClick={() => !buttonDisabled && this.setModalOpen()}
          disabled={buttonDisabled}
        />
        <ConfirmationModal
          confirmationHeader={<I18nText id="ibe.remove_item.header" returnStringOnly={true} />}
          subHeader={<I18nText id="ibe.remove_item.subheader" returnStringOnly={true} />}
          isModalOpen={this.state.isModalOpen}
          onConfirm={() => {
            this.onConfirm();
            this.setModalClose();
          }}
          onReject={() => this.setModalClose()}
          withAction={true}
          confirmationText={<I18nText id="action_with_confirmation.yes" returnStringOnly={true} />}
          rejectionText={<I18nText id="action_with_confirmation.no" returnStringOnly={true} />}
        />
      </React.Fragment>
    );
  }
}

RemoveFromCartButton.contextTypes = {
  frozen: PropTypes.bool.isRequired,
};

RemoveFromCartButton.propTypes = {
  isCartLocked: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
};

export default RemoveFromCartButton;
