import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  I18nText,
  EmphasisTag,
  // BaseModal, Checkbox, // TODO: will be done in Agent side with Add New feature
  LabelHint,
} from '@wtag/react-comp-lib';
import QuantitySelector from '@wtag/rcl-quantity-selector';
// TODO: will be done in Agent side with Add New feature
// import IconButton from '@wtag/rcl-icon-button';
// import Icon from '@wtag/rcl-icon';
import Amount from 'sharedWebpack/Amount';
import feeList from '../lib/feeList';

class Fees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // requestPossible: true, // TODO: will be done in Agent side with Add New feature
    };
  }

  componentDidMount() {
    if (!this.props.fees.length) {
      this.props.fetchFees(this.context.callbackParams);
    }
    if (!this.props.feeSuggestions.length) {
      this.props.fetchFeeSuggestions(this.context.callbackParams);
    }
  }

  // TODO: will be done in Agent side with Add New feature
  // selectFee = fee => {
  //   this.setState({ requestPossible: false });
  //   if (this.feeSelected(fee)) {
  //     this.props.onRemove(fee).then(() => this.setState({ requestPossible: true }));
  //   } else {
  //     this.props.onAdd(fee).then(() => this.setState({ requestPossible: true }));
  //   }
  // };

  feeSelected = fee => this.props.selected.findIndex(selectedFee => selectedFee.id === fee.id) > -1;

  quantity = fee => {
    if (this.context.laymanMode) return fee.quantity;
    return (
      <QuantitySelector
        value={fee.quantity}
        onChange={value => this.props.setQuantity(fee, value)}
      />
    );
  };

  feeTotal = fee => {
    const amount = <Amount currency={fee.currency} value={fee.grossTotal} />;

    if (!fee.grossVat) return <EmphasisTag text={amount} />;

    const vat = <Amount currency={fee.currency} value={fee.grossVat} />;

    return (
      <LabelHint
        hint={
          <span>
            {I18n.t('components.ibe.fees.includes')}
            {` ${vat}  ${I18n.t('components.ibe.fees.vat')}`}
          </span>
        }
      >
        <EmphasisTag text={amount} />
      </LabelHint>
    );
  };

  fees = () => {
    const fees = feeList(
      this.props.fees,
      this.props.selected,
      this.props.intl,
      !this.context.laymanMode,
    );

    return fees.map(fee => (
      <React.Fragment key={fee.id}>
        <div className="col-12 payment-form__grid-padding">
          <div className="col-xlg-6 col-lg-6 col-md-6 col-5 payment-form-item__fee col-bleed-y">
            <div className="payment-form-item__info-data">
              {fee.quantity}x {fee.name}
            </div>
          </div>
          {/* TODO: will be done in Agent side with Add New feature */}
          {/* <div className="col-4 payment-form-item__fee">
            <div className="quantity">
              <span className="payment-form__grid-input">
                <I18nText id="ibe.payment.quantity" /> {this.quantity(fee)}
              </span>
            </div>
          </div> */}
          <div className="col-xlg-6 col-lg-6 col-md-6 col-5  payment-form-item__position payment-form--position col-bleed-y">
            <div className="payment-form__grid-float payment-form-item__info-amount">
              {/* TODO: will be done in Agent side with Add New feature */}
              {/* {this.feeTotal(fee)}
              {!this.context.laymanMode && (
                <IconButton icon={<Icon name="close" />} onClick={() => this.props.onRemove(fee)} />
              )} */}
              <Amount currency={fee.currency} value={fee.grossTotal} />
            </div>
          </div>
        </div>
      </React.Fragment>
    ));
  };

  render() {
    if (!this.props.fees.length) {
      return null;
    }

    const getAllFees =
      this.props.fees.length > 0
        ? feeList(this.props.fees, this.props.selected, this.props.intl, !this.context.laymanMode)
        : [];

    const calcTotalFees =
      getAllFees.length > 0
        ? getAllFees
            .map(item => item && item.grossPrice && parseInt(item.grossPrice, 10))
            .reduce((prev, next) => prev + next)
        : '';

    return (
      <React.Fragment>
        <div className="grid">
          <div className="col-12 col-bleed-x payment-form__padding">
            <div className="col-xlg-6 col-lg-6 col-md-6 col-5 payment-form-services">
              <I18nText id="ibe.taxes_services_charges" returnStringOnly={true} />
            </div>
            <div className="col-xlg-6 col-lg-6 col-md-6 col-5 payment-form-services payment-form--position">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {this.context.laymanMode ? (
                <span className="payment-form-services__price">
                  <Amount currency={this.context.currency} value={calcTotalFees} />
                </span>
              ) : (
                <span className="payment-form-services__price">
                  <Amount currency={this.context.currency} value={calcTotalFees} />
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="payment__services">
          <div className="grid payment__services-margin">{this.fees()}</div>
        </div>

        {/* TODO: will be done in Agent side with Add New feature */}
        {/* {!this.context.laymanMode && (
          <div className="payment__services">
            <div className="grid payment__services-margin">
              <div className="col-12">
                <div className="col-lg-6 payment-form-services" id="payment.form.service">
                  <I18nText id="ibe.form_of_payment.new_service" />
                </div>
                <div className="col-lg-6 payment-form-services__text-right">
                  <BaseModal
                    linkText={I18n.t('components.ibe.fees.base_modal.link_text')}
                    title={I18n.t('components.ibe.fees.base_modal.header')}
                    linkClassName="button"
                    header={I18n.t('components.ibe.fees.base_modal.header')}
                  >
                    {this.props.feeSuggestions.map(fee => (
                      <div className="col-12 payment-form__fee-modal">
                        <Checkbox
                          label={fee.name}
                          name={fee.id}
                          key={fee.id}
                          checked={this.feeSelected(fee)}
                          onChange={() => this.state.requestPossible && this.selectFee(fee)}
                        />
                      </div>
                    ))}
                  </BaseModal>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </React.Fragment>
    );
  }
}

Fees.defaultProps = {
  selected: [],
};

Fees.propTypes = {
  // TODO: will be done in Agent side with Add New feature
  // onAdd: PropTypes.func.isRequired,
  // onRemove: PropTypes.func.isRequired,
  fees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      grossPrice: PropTypes.string.isRequired,
    }),
  ).isRequired,
  feeSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      grossPrice: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      currency: PropTypes.string,
      grossPrice: PropTypes.string.isRequired,
    }),
  ),
  intl: intlShape.isRequired,
  fetchFees: PropTypes.func.isRequired,
  fetchFeeSuggestions: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
};

Fees.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  currency: PropTypes.string,
};

export default injectIntl(Fees);
