import { connect } from 'react-redux';

import AccountingType from './AccountingType';

import { fetchCostCenterOfAccountingType } from '../actions/common';

const mapStateToProps = state => {
  const costCenters = state.common.costCenters;

  return {
    costCenters,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCostCenterOfAccountingType: accountingTypeId =>
    dispatch(fetchCostCenterOfAccountingType(accountingTypeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingType);
