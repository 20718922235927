import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ProductIcon from 'sharedWebpack/ProductIcon';
import Icon from '@wtag/rcl-icon';
import Amount from 'sharedWebpack/Amount';

const getTitle = type => {
  if (type === 'static_product') {
    return I18n.t('components.ibe.summary.static_product');
  }

  if (type === 'additionalCharges') {
    return I18n.t('components.ibe.summary.taxes_services_and_charges');
  }

  return I18n.t(type, { scope: 'components.ibe.summary.title' });
};

function CartSummaryAccordionItemHeader(props) {
  const { totalPrice, currency, type, onClick, isActive } = props;

  return (
    <div
      className="cart-summary__accordion-item-header"
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <Icon
        className="cart-summary__accordion-item-header-indicator-icon"
        name="iconDownChevron"
        size="tiny"
        rotate={isActive ? 180 : 0}
      />
      {type !== 'additionalCharges' ? (
        <ProductIcon
          className="cart-summary__accordion-item-header-product-icon"
          productType={type}
          showBGColor={true}
          color="tertiary"
          size="tiny"
        />
      ) : null}
      <div className="cart-summary__accordion-item-header-name">{getTitle(type)}</div>
      <div className="cart-summary__accordion-item-header-total-price">
        <Amount currency={currency} value={totalPrice} />
      </div>
    </div>
  );
}

CartSummaryAccordionItemHeader.defaultProps = {
  isActive: false,
  onClick: () => {},
};

CartSummaryAccordionItemHeader.propTypes = {
  type: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  totalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(CartSummaryAccordionItemHeader);
