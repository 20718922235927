import CarDefaultImage from 'agentSyncedImages/fallback_images/car-image-unavailable.png';

const getCarImageUrl = imageUrl => {
  if (!imageUrl) {
    return CarDefaultImage;
  }

  return imageUrl;
};

export default getCarImageUrl;
