/* global I18n */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import { hot } from 'react-hot-loader';
import TravelerDetails from './TravelerDetailsContainer';

const TravelerDetailsWrapper = (props, context) => {
  if (props.cartState !== 'open') {
    return (
      <div className="container container--sm-full-width">
        <I18nText id="ibe.traveler_details.book_new_cart" returnStringOnly={true} />
      </div>
    );
  }
  return (
    <Fragment>
      <TravelerDetails
        flightResults={props.flightResults}
        cartId={props.cartId}
        bookingPossible={props.bookingPossible}
        laymanMode={context.laymanMode}
        travelerLoggedIn={context.travelerLoggedIn}
        enableIdDocOptional={context.enableIdDocOptional}
        countryCodes={context.countryCodes}
        totalCartItems={props.totalCartItems}
      />
    </Fragment>
  );
};

TravelerDetailsWrapper.defaultProps = {
  cartState: null,
  totalCartItems: 0,
};

TravelerDetailsWrapper.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  paymentOptions: PropTypes.shape(),
  enableIdDocOptional: PropTypes.bool.isRequired,
  countryCodes: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
};

TravelerDetailsWrapper.propTypes = {
  flightResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cartId: PropTypes.string.isRequired,
  cartState: PropTypes.oneOf(['open', 'paid', 'booked']),
  bookingPossible: PropTypes.bool.isRequired,
  totalCartItems: PropTypes.number,
};

export default hot(module)(TravelerDetailsWrapper);
