import {
  FETCHING_EXCHANGE_QUOTE,
  EXCHANGE_QUOTE_FETCHED,
  EXCHANGE_QUOTE_FETCHING_FAILED,
  CONFIRMING_EXCHANGE_QUOTE,
  EXCHANGE_QUOTE_CONFIRMED,
  EXCHANGE_QUOTE_CONFIRMATION_FAILED,
} from '../../actions/flight';

export default function exchangeQuoteStatus(state = null, action) {
  switch (action.type) {
    case FETCHING_EXCHANGE_QUOTE:
      return 'fetching';

    case EXCHANGE_QUOTE_FETCHED:
      return 'fetched';

    case EXCHANGE_QUOTE_FETCHING_FAILED:
      return 'fetchingFailed';

    case CONFIRMING_EXCHANGE_QUOTE:
      return 'confirming';

    case EXCHANGE_QUOTE_CONFIRMED:
      return 'confirmed';

    case EXCHANGE_QUOTE_CONFIRMATION_FAILED:
      return 'confirmationFailed';

    default:
      return state;
  }
}
