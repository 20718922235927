const getLiabilitiesInfo = liabilities => {
  const liabilitiesInfo = liabilities
    .map(liability =>
      I18n.t(liability.type, {
        scope: 'components.ibe.search_results.car.insurance_and_liability',
      }),
    )
    .join(', ');

  return liabilitiesInfo
    ? `${I18n.t('components.ibe.search_results.car.content.liability', {
        liabilities: liabilitiesInfo,
      })}\n`
    : '';
};

export default getLiabilitiesInfo;
