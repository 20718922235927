import { FARE_BASIS_INFO_FETCHED } from '../../actions/flight';

export default function fareBasisInfoByResultId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case FARE_BASIS_INFO_FETCHED:
      newState[action.resultId] = action.data;
      return newState;

    default:
      return newState;
  }
}
