import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import httpClient from 'agentHTTPClient';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import routes from 'agentRoutes';
import Trip from './Trip';
import RouteInfoWithPrice from './RouteInfoWithPrice';
import './style.scss';

const FlightItem = ({
  item,
  className,
  flightServicesByResultId,
  journeyElementId,
  journeyElementType,
  showMinimalInfo,
  showFlightSeatsStatus,
  fetchedJourneyElement,
  refetchJourneyElement,
  totalTravelerCount,
  payLater,
  laymanMode,
  shouldLockScroll,
  setShouldLockScroll,
  isRouteInfoVisible,
  currency,
  grossAmount,
  supplierReservationIdentifier,
  isPostBooking,
}) => {
  const [flightJourneyElement, setflightJourneyElement] = useState(fetchedJourneyElement);
  const [isLoading, setIsLoading] = useState(false);

  const fetchJourneyElement = async () => {
    setIsLoading(true);
    const { data } = await httpClient.get(
      routes.api.fetchJourneyElement({
        type: journeyElementType,
        id: journeyElementId,
      }),
    );

    setflightJourneyElement(data);
    setIsLoading(false);
  };

  useEffect(() => {
    setflightJourneyElement(fetchedJourneyElement);
  }, [fetchedJourneyElement]);

  useEffect(() => {
    if ((journeyElementId && !fetchedJourneyElement) || refetchJourneyElement) {
      fetchJourneyElement();
    }
  }, [journeyElementId, journeyElementType, refetchJourneyElement]);

  return (
    <div className={classNames('flight-item d-flex direction-column', className)}>
      {isLoading ? (
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={true} />
      ) : (
        flightJourneyElement &&
        flightJourneyElement.trips && (
          <Fragment>
            {isRouteInfoVisible && (
              <RouteInfoWithPrice
                trips={flightJourneyElement.trips}
                currency={currency}
                grossAmount={grossAmount}
                supplierReservationIdentifier={supplierReservationIdentifier}
              />
            )}
            {flightJourneyElement.trips.map((trip, index) => (
              <Trip
                item={item}
                flightServicesByResultId={flightServicesByResultId}
                showFlightSeatsStatus={showFlightSeatsStatus}
                tripList={flightJourneyElement.trips}
                trip={trip}
                key={trip.id}
                index={index}
                flightServices={flightJourneyElement.services}
                showMinimalInfo={showMinimalInfo}
                totalTravelerCount={totalTravelerCount}
                payLater={payLater}
                laymanMode={laymanMode}
                shouldLockScroll={shouldLockScroll}
                setShouldLockScroll={setShouldLockScroll}
                isPostBooking={isPostBooking}
              />
            ))}
          </Fragment>
        )
      )}
    </div>
  );
};

FlightItem.defaultProps = {
  className: null,
  showMinimalInfo: false,
  fetchedJourneyElement: null,
  refetchJourneyElement: false,
  showFlightSeatsStatus: false,
  payLater: false,
  isRouteInfoVisible: false,
  supplierReservationIdentifier: '',
  isPostBooking: false,
};

FlightItem.propTypes = {
  item: PropTypes.shape().isRequired,
  className: PropTypes.string,
  flightServicesByResultId: PropTypes.shape().isRequired,
  flightItem: PropTypes.shape({
    totalAmount: PropTypes.string,
    cancelPenaltyCurrency: PropTypes.string,
    cancelPenaltyAmount: PropTypes.string,
    refundable: PropTypes.bool,
    status: PropTypes.string,
    identifier: PropTypes.string,
    title: PropTypes.string,
    channel: PropTypes.string,
    pnrPath: PropTypes.string,
    groupLabel: PropTypes.string,
    journeyElementId: PropTypes.number,
    journeyElementType: PropTypes.string,
  }).isRequired,
  travellers: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  journeyElementId: PropTypes.number.isRequired,
  journeyElementType: PropTypes.string.isRequired,
  showMinimalInfo: PropTypes.bool,
  fetchedJourneyElement: PropTypes.shape({}),
  refetchJourneyElement: PropTypes.bool,
  showFlightSeatsStatus: PropTypes.bool,
  totalTravelerCount: PropTypes.number.isRequired,
  payLater: PropTypes.bool,
  laymanMode: PropTypes.bool.isRequired,
  shouldLockScroll: PropTypes.bool.isRequired,
  setShouldLockScroll: PropTypes.func.isRequired,
  isRouteInfoVisible: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  grossAmount: PropTypes.string.isRequired,
  supplierReservationIdentifier: PropTypes.string,
  isPostBooking: PropTypes.bool,
};
export default hot(module)(FlightItem);
