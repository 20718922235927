import moment from 'moment';
import { FormatDateTime } from '../../../helpers/dateTime';

const getDate = dateTime => {
  const momentFormat = 'H:mm D MMM YYYY';
  const dateFormat = 'D MMM YYYY';
  const timeFormat = 'H:mm';

  const formattedDate = FormatDateTime({
    dateTime,
    format: 'shortWithTime',
    displayInUTC: true,
    displayWithoutConversion: false,
  });

  const momentizedDate = moment(formattedDate, momentFormat);
  const date = momentizedDate.format(dateFormat);
  const time = momentizedDate.format(timeFormat);

  return { date, time };
};

export default getDate;
