import { useEffect, useState } from 'react';

const usePropState = defaultValue => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return [value, setValue];
};

export default usePropState;
