import { HOTEL_SEARCH_FETCHED, SEARCHING_HOTELS } from '../../actions/hotel';
import { api2form } from '../../Search/hotel/helpers/mapSearchParams';

export default function searchParamsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case SEARCHING_HOTELS:
      newState.lastSearch = action.params;
      return newState;

    case HOTEL_SEARCH_FETCHED:
      newState[action.searchId] = api2form(action.searchParams);
      newState.lastSearch = api2form(action.searchParams);

      return newState;

    default:
      return state;
  }
}
