/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SidePanel } from '@wtag/react-comp-lib';
import FlightLineItem from './FlightLineItem';
import ServiceLineItem from './ServiceLineItem';
import SeatLineItem from './SeatLineItem';
import HotelLineItem from './HotelLineItem';
import CarLineItem from './CarLineItem';
import StaticLineItem from './StaticLineItem';

const availableComponents = {
  flight: FlightLineItem,
  hotel: HotelLineItem,
  static_product: StaticLineItem,
  car: CarLineItem,
  service: ServiceLineItem,
  seat: SeatLineItem,
};

class LineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSidePanel: false,
      sidePanelTitle: null,
      sidePanelBody: null,
    };
  }

  componentDidMount() {
    const item = this.props.item;
    if (item.hasJourneyElement && !this.props.journeyElement) {
      this.props.fetchJourneyElement(item.journeyElementType, item.journeyElementId);
    }
  }

  setSidePanelBody = body => {
    this.setState({ sidePanelBody: body });
  };

  setSidePanelTitle = title => {
    this.setState({ sidePanelTitle: title });
  };

  setOpenSidePanel = () => {
    this.setState({ openSidePanel: true });
  };

  hideSidePanel = () => {
    this.setState({ openSidePanel: false });
  };

  render() {
    let RealLineItem = availableComponents[this.props.item.journeyElementType];

    if (RealLineItem) {
      if (this.props.journeyElement) {
        return (
          <React.Fragment>
            <RealLineItem
              item={this.props.item}
              {...this.props.journeyElement}
              setSidePanelBody={this.setSidePanelBody}
              setSidePanelTitle={this.setSidePanelTitle}
              setOpenSidePanel={this.setOpenSidePanel}
            />
            <SidePanel
              className="deal-item__side-panel"
              title={this.state.sidePanelTitle}
              body={this.state.sidePanelBody}
              isOpened={this.state.openSidePanel}
              onClick={() => this.hideSidePanel()}
            />
          </React.Fragment>
        );
      }
      return null;
    }

    RealLineItem = availableComponents[this.props.item.type];
    if (RealLineItem && this.props.product) {
      return (
        <React.Fragment>
          <RealLineItem
            item={this.props.item}
            {...this.props.product}
            fetchingStaticProducts={this.props.fetchingStaticProducts}
            setSidePanelBody={this.setSidePanelBody}
            setSidePanelTitle={this.setSidePanelTitle}
            setOpenSidePanel={this.setOpenSidePanel}
          />
        </React.Fragment>
      );
    }

    return null;
  }
}

LineItem.defaultProps = {
  journeyElement: null,
  product: null,
  item: {
    journeyElementType: null,
  },
  fetchingStaticProducts: false,
};

LineItem.propTypes = {
  type: PropTypes.string.isRequired,
  fetchJourneyElement: PropTypes.func.isRequired,
  journeyElement: PropTypes.shape(),
  product: PropTypes.shape(),
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    journeyElementType: PropTypes.string,
  }),
  fetchingStaticProducts: PropTypes.bool,
};

export default LineItem;
