export const UPDATE_FLIGHT_SEARCH_FILTERS = 'UPDATE_FLIGHT_SEARCH_FILTERS';
export function filterFlightSearch(filter, value, key, searchId, tripId, isAllChecked) {
  return {
    type: UPDATE_FLIGHT_SEARCH_FILTERS,
    searchId,
    tripId,
    filter,
    value,
    key,
    isAllChecked,
  };
}

export default filterFlightSearch;
