import React from 'react';
import PropTypes from 'prop-types';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';

const CarLoyaltyProgramForm = ({
  carCompanies,
  program,
  setProgram,
  number,
  setNumber,
  carError,
}) => {
  const carLoyaltyProgramsMenu = carCompanies
    ? Object.keys(carCompanies).map(value => ({
        value,
        label: I18n.t(value, {
          scope: 'rental_car_agencies',
        }),
      }))
    : [];

  const findSelectBoxContent = value => {
    if (value) {
      return {
        value,
        label: I18n.t(program, {
          scope: 'rental_car_agencies',
        }),
      };
    }

    return null;
  };

  return (
    <div className="grid">
      <div className="col-sm-6">
        <SelectBox
          size="tiny"
          placeholderText={I18n.t('admin.components.travellers.edit.loyalty_programs.program')}
          label={I18n.t(
            'admin.components.travellers.edit.loyalty_programs.car_loyalty.headers.program',
          )}
          width="full"
          options={carLoyaltyProgramsMenu}
          value={findSelectBoxContent(program)}
          onChange={selectedOption => selectedOption && setProgram(selectedOption.value)}
          errorMsg={carError.carLoyaltyProgram}
          required={true}
          isClearable={false}
        />
      </div>
      <div className="col-sm-6">
        <Input
          size="tiny"
          placeholder={I18n.t(
            'admin.components.travellers.edit.loyalty_programs.car_loyalty.placeholder.number',
          )}
          label={I18n.t('admin.components.travellers.edit.loyalty_programs.number')}
          touched={!!carError.carLoyaltyNumber}
          error={carError.carLoyaltyNumber}
          value={number}
          onChange={value => setNumber(value)}
          required={true}
        />
      </div>
    </div>
  );
};

CarLoyaltyProgramForm.defaultProps = {
  carError: {},
  program: '',
  setProgram: {},
};

CarLoyaltyProgramForm.propTypes = {
  carCompanies: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  program: PropTypes.arrayOf(PropTypes.shape),
  setProgram: PropTypes.func,
  number: PropTypes.string.isRequired,
  setNumber: PropTypes.func.isRequired,
  carError: PropTypes.shape({
    carLoyaltyNumber: PropTypes.string,
    carLoyaltyProgram: PropTypes.string,
  }),
};

export default CarLoyaltyProgramForm;
