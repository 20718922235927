import history from '../../lib/history';
import {
  createFlightSearch,
  fetchFlightSearchResults,
  fetchFlightSearchResultsByFlightNumbers,
  fetchFlightServicesWithoutHandling,
  addFlightToCart,
} from '../../actions/flight';
import {
  createHotelSearch,
  fetchHotelSearchResults,
  fetchHotelSearchResultsByName,
  fetchHotelDetailsWithoutHandling,
  addHotelToCart,
} from '../../actions/hotel';
import { createCarSearch, fetchCarSearchResults, addCarToCart } from '../../actions/car';
import { ensureCart, fetchCart } from './cart';
import { addStaticProductToCart } from './staticProducts';
import deepClone from '../../lib/deepClone';

export const MSEARCH_RESULT_CONFIRMED = 'MSEARCH_RESULT_CONFIRMED';
function multiSearchResultConfirmed(groupKey, cartItem, oldItemId) {
  return {
    type: MSEARCH_RESULT_CONFIRMED,
    groupKey,
    cartItem,
    oldItemId,
  };
}

export const ALL_MSEARCH_RESULTS_CONFIRMED = 'ALL_MSEARCH_RESULTS_CONFIRMED';
export function allResultsConfirmed() {
  return { type: ALL_MSEARCH_RESULTS_CONFIRMED };
}

export function goToNextPage() {
  return (dispatch, getState) => {
    const state = getState();
    const dealId = state.common.deal.id;
    let groups = state.common.deal.groups;
    if (!dealId) {
      groups = state.common.approvalRequest.groups;
    }

    const allConfirmed = Object.keys(state.common.multiSearch.status).every(groupKey => {
      const status = state.common.multiSearch.status[groupKey];
      const groupType = groups.find(group => group.key === groupKey).type;
      return groupType === 'static_product' || status === 'available';
    });

    if (allConfirmed) {
      dispatch(allResultsConfirmed());
      const cartId = getState().common.cart.id;
      history.push(`/carts/${cartId}`);
      return Promise.resolve(allConfirmed);
    }
    if (dealId) {
      history.push(`/deals/${dealId}`);
    } else {
      const approvalRequestId = getState().common.approvalRequest.id;
      history.push(`/approval-requests/${approvalRequestId}`);
    }
    return Promise.resolve(false);
  };
}

export const MSEARCH_RESULT_CONFIRM_FAILED = 'MSEARCH_RESULT_CONFIRM_FAILED';
function multiSearchResultConfirmFailed(groupKey) {
  return {
    type: MSEARCH_RESULT_CONFIRM_FAILED,
    groupKey,
  };
}

function fetchMatchingFlightSearchResults(searchId, result) {
  return (dispatch, getState) => {
    const journeyElement = getState().common.journeyElements.flight[result.journeyElementId];
    const trips = journeyElement.trips.map(trip => ({
      origin: trip.originCode,
      destination: trip.destinationCode,
      segments: trip.segments.map(segment => ({
        marketing_carrier_code: segment.marketingCarrierCode,
        marketing_flight_number: segment.marketingFlightNumber,
      })),
    }));
    dispatch(fetchFlightSearchResults({ searchId, tripId: 1 }));
    return dispatch(fetchFlightSearchResultsByFlightNumbers(searchId, trips));
  };
}

function fetchMatchingHotelSearchResults(searchId, result) {
  return (dispatch, getState) => {
    const journeyElement = getState().common.journeyElements.hotel[result.journeyElementId];
    dispatch(fetchHotelSearchResults({ searchId }));
    return dispatch(fetchHotelSearchResultsByName(searchId, journeyElement.name)).then(
      search => search.results,
    );
  };
}

function fetchMatchingCarSearchResults(searchId, result) {
  return (dispatch, getState) => {
    const journeyElement = getState().common.journeyElements.car[result.journeyElementId];
    return dispatch(
      fetchCarSearchResults({ searchId, acriss_code: journeyElement.acrissCode }),
    ).then(search => search.results);
  };
}

function resultAvailable(searchParams, resultId, fetchCallback, compareCallback, callbackParams) {
  return dispatch =>
    dispatch(fetchCallback(resultId, { callback_params: callbackParams })).then(result => {
      if (compareCallback(searchParams, result)) {
        return {
          id: resultId,
          result,
        };
      }
      throw new Error(`${resultId} is not available`);
    });
}

const NO_RESULT_FOUND_ERROR = 'No result found';
function firstAvailableResult(
  searchParams,
  results,
  fetchCallback,
  compareCallback,
  callbackParams,
) {
  return dispatch => {
    if (!results.length) {
      return Promise.reject(new Error(NO_RESULT_FOUND_ERROR));
    }
    return dispatch(
      resultAvailable(
        searchParams,
        results.shift().id,
        fetchCallback,
        compareCallback,
        callbackParams,
      ),
    ).catch(() =>
      dispatch(
        firstAvailableResult(searchParams, results, fetchCallback, compareCallback, callbackParams),
      ),
    );
  };
}

function flightResultAvailable(searchParams, result) {
  return result.available;
}

function hotelResultAvailable(searchParams, result) {
  return !!result.rooms.find(room => room.id.toString() === searchParams.roomId.toString());
}

function carResultAvailable(searchParams, result) {
  return !!result; // it's available if there is a result
}

export const MSEARCH_RESULT_SELECTED = 'MSEARCH_RESULT_SELECTED';
export function multiSearchResultSelected(groupKey, id) {
  return { type: MSEARCH_RESULT_SELECTED, groupKey, id };
}

export const MSEARCH_RESULT_DESELECTED = 'MSEARCH_RESULT_DESELECTED';
export function multiSearchResultDeSelected(groupKey, id) {
  return { type: MSEARCH_RESULT_DESELECTED, groupKey, id };
}

export const MSEARCH_RESULT_SEARCHING = 'MSEARCH_RESULT_SEARCHING';
export function multiSearchResultSearching(groupKey, selectedResult) {
  return { type: MSEARCH_RESULT_SEARCHING, groupKey, selectedResult };
}

export function selectFlightResultInMultiSearch(groupKey, id, searchId, callbackParams) {
  return (dispatch, getState) => {
    const searchParams = getState().flights.searchParamsBySearchId[searchId];
    return dispatch(ensureCart(callbackParams)).then(cartId =>
      dispatch(addFlightToCart(cartId, id, searchParams, callbackParams)).then(cartItem =>
        dispatch(fetchCart(cartId, callbackParams)).then(() => {
          dispatch(
            multiSearchResultConfirmed(
              groupKey,
              cartItem,
              getState().common.multiSearch.selectedResults[groupKey],
            ),
          );
          dispatch(goToNextPage());
          return { type: 'flight', id };
        }),
      ),
    );
  };
}

export function selectHotelResultInMultiSearch(groupKey, result, roomId, callbackParams) {
  return (dispatch, getState) =>
    dispatch(addHotelToCart(result, roomId, callbackParams)).then(cartItem => {
      dispatch(
        multiSearchResultConfirmed(
          groupKey,
          cartItem,
          getState().common.multiSearch.selectedResults[groupKey],
        ),
      );
      dispatch(goToNextPage());
      return { type: 'hotel', id: result.id };
    });
}

export function selectCarResultInMultiSearch(groupKey, result, callbackParams) {
  return (dispatch, getState) =>
    dispatch(addCarToCart(result, callbackParams)).then(cartItem => {
      dispatch(
        multiSearchResultConfirmed(
          groupKey,
          cartItem,
          getState().common.multiSearch.selectedResults[groupKey],
        ),
      );
      dispatch(goToNextPage());
      return { type: 'car', id: result.id };
    });
}

export const MSEARCH_RESULT_SEARCH_COMPLETE = 'MSEARCH_RESULT_SEARCH_COMPLETE';
function searchAndConfirmFlightResult(data, group, groupKey, selectedResult, callbackParams) {
  const parameters = {
    adults: data.adults,
    children: data.children,
    infants: data.infants,
    trips: group.trips,
    nonStop: group.nonStop,
    onlyFlexibleFlight: group.onlyFlexibleFlight,
    onlyBagIncludedFlight: group.onlyBagIncludedFlight,
    excludeCodeShareFlight: group.excludeCodeShareFlight,
    accountSettings: data.selectedFlightAccounts,
    currency: data.currency,
    groupKey,
  };
  return dispatch =>
    dispatch(createFlightSearch(parameters, callbackParams)).then(search => {
      dispatch({ type: MSEARCH_RESULT_SEARCH_COMPLETE, groupKey, searchId: search.id });
      return dispatch(fetchMatchingFlightSearchResults(search.id, selectedResult)).then(results =>
        dispatch(
          firstAvailableResult(
            { ...parameters },
            results,
            fetchFlightServicesWithoutHandling,
            flightResultAvailable,
            callbackParams,
          ),
        )
          .then(result =>
            dispatch(selectFlightResultInMultiSearch(groupKey, result.id, callbackParams)),
          )
          .catch(error => {
            dispatch(multiSearchResultConfirmFailed(groupKey));
            if (error.message === NO_RESULT_FOUND_ERROR) {
              return console.error(error); // eslint-disable-line no-console
            }

            return Promise.reject(error);
          }),
      );
    });
}

function searchAndConfirmHotelResult(data, group, groupKey, selectedResult, callbackParams) {
  const childrenData = [];
  for (let step = 0; step < data.children; step += 1) {
    childrenData.push({ age: 8 });
  }

  for (let step = 0; step < data.infants; step += 1) {
    childrenData.push({ age: 1 });
  }

  const parameters = {
    latitude: group.latitude,
    longitude: group.longitude,
    placeId: group.placeId,
    checkIn: group.checkIn,
    checkOut: group.checkOut,
    numberOfRooms: group.numberOfRooms,
    adults: data.adults,
    children: childrenData,
    accountSettings: data.selectedHotelAccounts,
    currency: data.currency,
  };

  return dispatch =>
    dispatch(createHotelSearch(parameters, callbackParams)).then(search => {
      dispatch({ type: MSEARCH_RESULT_SEARCH_COMPLETE, groupKey, searchId: search.id });
      const roomId = selectedResult.bookingAttributes.roomId;
      return dispatch(fetchMatchingHotelSearchResults(search.id, selectedResult)).then(results =>
        dispatch(
          firstAvailableResult(
            { ...parameters, roomId },
            results,
            fetchHotelDetailsWithoutHandling,
            hotelResultAvailable,
            callbackParams,
          ),
        )
          .then(result =>
            dispatch(
              selectHotelResultInMultiSearch(groupKey, result.result, roomId, callbackParams),
            ),
          )
          .catch(error => {
            dispatch(multiSearchResultConfirmFailed(groupKey));
            if (error.message === NO_RESULT_FOUND_ERROR) {
              return console.error(error); // eslint-disable-line no-console
            }

            return Promise.reject(error);
          }),
      );
    });
}

function getCarResultFromSearchByResultIdFactory(results) {
  const clonedResults = deepClone(results);
  return resultId => () => Promise.resolve(clonedResults.find(result => result.id === resultId));
}

function searchAndConfirmCarResult(data, group, groupKey, selectedResult, callbackParams) {
  const parameters = {
    ...group,
    pickUpLocationId: group.pickUpLocationId,
    dropOffLocationId: group.dropOffLocationId,
    accountSettings: data.selectedCarAccounts,
    currency: data.currency,
  };

  return dispatch =>
    dispatch(createCarSearch(parameters, callbackParams)).then(search => {
      dispatch({ type: MSEARCH_RESULT_SEARCH_COMPLETE, groupKey, searchId: search.id });
      return dispatch(fetchMatchingCarSearchResults(search.id, selectedResult)).then(results => {
        dispatch(
          firstAvailableResult(
            { ...parameters },
            results,
            getCarResultFromSearchByResultIdFactory(results),
            carResultAvailable,
            callbackParams,
          ),
        )
          .then(foundResult =>
            dispatch(selectCarResultInMultiSearch(groupKey, foundResult, callbackParams)),
          )
          .catch(error => {
            dispatch(multiSearchResultConfirmFailed(groupKey));
            if (error.message === NO_RESULT_FOUND_ERROR) {
              return console.error(error); // eslint-disable-line no-console
            }

            return Promise.reject(error);
          });
      });
    });
}

function searchAndConfirmStaticProduct(group, selectedResult) {
  return (dispatch, getState) => {
    const staticProduct = getState().common.staticProducts.fetchedStaticProducts.find(
      product => product.id === selectedResult.productId,
    );
    return dispatch(addStaticProductToCart(staticProduct, this.context.callbackParams));
  };
}

export const CONFIRM_MSEARCH_RESULTS = 'CONFIRM_MSEARCH_RESULTS';
export function multiSearchResultsSelected(data, itemGroups, callbackParams) {
  return (dispatch, getState) => {
    dispatch({ type: CONFIRM_MSEARCH_RESULTS, data });
    if (data.groups) {
      // if any dynamic results to search & confirm
      return Object.keys(data.groups).map(key => {
        const group = data.groups[key];
        const selectedResultId = getState().common.multiSearch.selectedResults[key];
        const selectedResult = itemGroups[key].options.find(
          option => option.id.toString() === selectedResultId,
        );
        dispatch(multiSearchResultSearching(key, selectedResult));

        switch (group.type) {
          case 'flight':
            return dispatch(
              searchAndConfirmFlightResult(data, group, key, selectedResult, callbackParams),
            );
          case 'hotel':
            return dispatch(
              searchAndConfirmHotelResult(data, group, key, selectedResult, callbackParams),
            );
          case 'car':
            return dispatch(
              searchAndConfirmCarResult(data, group, key, selectedResult, callbackParams),
            );
          case 'static_product':
            return dispatch(searchAndConfirmStaticProduct(group, selectedResult));
          default:
            throw new Error(`Don't know how to handle type ${group.type}`);
        }
      });
    }
    return dispatch(goToNextPage());
  };
}
