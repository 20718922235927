import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import IconButton from '@wtag/rcl-icon-button';
import Tooltip from '@wtag/rcl-tooltip';
import Icon from '@wtag/rcl-icon';
import { Radio, EmphasisTag, Link } from '@wtag/react-comp-lib';
import moment from 'moment';
import DateTime from 'sharedWebpack/DateTime';
import documentTypesBuilder from '../../../../../Search/flight/helpers/documentTypesBuilder';

class IdentificationDocumentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }

  render() {
    const { document, isSelected, setSelection, index, isInvalid, validityError } = this.props;
    const DOCUMENT_TYPES = documentTypesBuilder();
    const documentType = DOCUMENT_TYPES.find(type => type.value === document.documentType).label;
    const isExpired = moment(document.validity).diff(moment().format('YYYY-MM-DD'), 'days') <= 0;
    const isValidationEnabled = isInvalid && isSelected && isExpired;

    return (
      <div className="identification-documents-list__card">
        <div className="col-grid col-bleed direction-row align-center justify-space-between">
          <div className="col-grid col-bleed direction-row align-center wrap">
            <div className="identification-documents-list__card-radio">
              <Radio
                size="huge"
                name="document_card"
                checked={isSelected}
                onChange={() => setSelection(document, index)}
              />
            </div>
            <div className="identification-documents-list__card-type">
              <EmphasisTag type="important" size="tiny" text={documentType} />
            </div>
            <div className="col-grid direction-row col-bleed align-center wrap identification-documents-list__country-details">
              {document.countryName && (
                <Fragment>
                  <div className="identification-documents-list__card-flag">
                    <img src={document.flagUrls.small} alt={document.countryName} />
                  </div>
                  <div className="identification-documents-list__card-country">
                    {document.countryName}
                  </div>
                </Fragment>
              )}
            </div>
            <div className="identification-documents-list__card-number">{document.number}</div>
            {isValidationEnabled && (
              <Tooltip content={validityError} type="danger" position="bottom-left">
                <Icon
                  className="identification-documents-list__card-icon"
                  name="invalid"
                  color="danger"
                  size="small"
                />
              </Tooltip>
            )}
          </div>

          <div className="identification-documents-list__card-edit">
            <IconButton
              icon={<Icon name="edit" size="small" />}
              onClick={() => {
                setSelection(document, index, true);
              }}
              label={I18n.t('shared.actions.edit')}
            />
          </div>
        </div>

        {this.state.expand && (
          <div className="identification-documents-list__card-details">
            <div className="identification-documents-list__card-details-attributes">
              <div className="identification-documents-list__card-details-attributes-header">
                {I18n.t('components.ibe.traveler_details.attributes.document_type')}
              </div>
              <div className="identification-documents-list__card-details-attributes-value">
                {documentType}
              </div>
            </div>

            <div className="identification-documents-list__card-details-attributes">
              <div className="identification-documents-list__card-details-attributes-header">
                {I18n.t('components.ibe.traveler_details.attributes.country')}
              </div>
              <div className="identification-documents-list__card-details-attributes-value">
                {document.countryName}
              </div>
            </div>

            <div className="identification-documents-list__card-details-attributes">
              <div className="identification-documents-list__card-details-attributes-header">
                {I18n.t('components.ibe.traveler_details.attributes.nationality')}
              </div>
              <div className="identification-documents-list__card-details-attributes-value">
                {document.nationalityName}
              </div>
            </div>

            <div className="identification-documents-list__card-details-attributes">
              <div className="identification-documents-list__card-details-attributes-header">
                {I18n.t('components.ibe.traveler_details.attributes.number')}
              </div>
              <div className="identification-documents-list__card-details-attributes-value">
                {document.number}
              </div>
            </div>

            <div className="identification-documents-list__card-details-attributes">
              <div className="identification-documents-list__card-details-attributes-header">
                {I18n.t('components.ibe.traveler_details.attributes.validity')}
              </div>
              {isValidationEnabled ? (
                <div className="col-grid col-bleed direction-row align-center document-validity-container">
                  <div className="identification-documents-list__card-details-attributes-value--invalid">
                    <DateTime dateTime={document.validity} format="short" />
                  </div>
                  <div className="identification-documents-list__card-details-attributes--tooltip">
                    <Tooltip content={validityError} type="danger" position="bottom-left">
                      <Icon name="invalid" color="danger" />
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div className="col-grid col-bleed direction-row align-center document-validity-container">
                  <div className="identification-documents-list__card-details-attributes-value">
                    <DateTime dateTime={document.validity} format="short" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="identification-documents-list__card-action">
          <Link
            className="identification-documents-list__card-action-button"
            onClick={() =>
              this.setState(prevState => ({
                expand: !prevState.expand,
              }))
            }
            type="tiny"
            modifier="tertiary"
          >
            {this.state.expand ? (
              <div className="identification-documents-list__card-action-button-text">
                {I18n.t('components.ibe.traveler_details.buttons.hide')}
                <Icon name="iconUpChevron" size="tiny" color="tertiary" />
              </div>
            ) : (
              <div className="identification-documents-list__card-action-button-text">
                {I18n.t('components.ibe.traveler_details.buttons.show')}
                <Icon name="iconDownChevron" size="tiny" color="tertiary" />
              </div>
            )}
          </Link>
        </div>
      </div>
    );
  }
}

IdentificationDocumentCard.defaultProps = {
  validityError: [],
  isInvalid: false,
};

IdentificationDocumentCard.propTypes = {
  index: PropTypes.number.isRequired,
  document: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired,
  setSelection: PropTypes.func.isRequired,
  validityError: PropTypes.arrayOf([PropTypes.node]),
  isInvalid: PropTypes.bool,
};

export default injectIntl(IdentificationDocumentCard);
