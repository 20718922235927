import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Menu } from '@wtag/rcl-menu';
import Amount from 'sharedWebpack/Amount';
import getPriceDetails from '../../SearchResult/hotel/helpers/getPriceDetails';

const PriceDetails = ({ amount, labelClassName, label, isPriceBold, currency: itemCurrency }) => (
  <div className="hotel-price-menu__breakdown-item d-flex flex-wrap justify-between">
    <span className={labelClassName}>{label}</span>
    <Amount
      className={classNames({
        'hotel-price-menu__breakdown-item--bold': isPriceBold,
      })}
      currency={itemCurrency}
      value={amount}
    />
  </div>
);

const RoomPriceMenu = ({ room, amountLabel, durationOfStaying, laymanMode, requiredRooms }) => {
  const [isPriceMenuVisible, setIsPriceMenuVisible] = useState(false);
  const { currency } = room;
  const priceDetailsArgs = {
    ...room,
    durationOfStaying,
    laymanMode,
    requiredRooms,
  };
  const priceDetails = getPriceDetails(priceDetailsArgs);

  const handlePriceMenuOutsideClick = () => {
    setIsPriceMenuVisible(false);
  };

  const toggleHotelMenuVisibility = () => {
    setIsPriceMenuVisible(prevIsHotelMenuVisibilityState => !prevIsHotelMenuVisibilityState);
  };

  return (
    <Menu
      isVisible={isPriceMenuVisible}
      size="medium"
      popOverDirection="bottom-left"
      onOutsideClick={handlePriceMenuOutsideClick}
      label={
        <div
          className="d-flex align-center flex-gap-8"
          onClick={toggleHotelMenuVisibility}
          role="button"
          tabIndex={0}
          onKeyDown={toggleHotelMenuVisibility}
        >
          <Amount
            className="hotel-price-menu__label-price"
            currency={currency}
            value={amountLabel}
          />
        </div>
      }
    >
      <div className="hotel-price-menu__breakdown d-flex flex-wrap justify-between">
        {priceDetails.map(details => (
          <PriceDetails key={details.label} {...details} />
        ))}
      </div>
    </Menu>
  );
};

PriceDetails.propTypes = {
  amount: PropTypes.string.isRequired,
  labelClassName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isPriceBold: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
};

RoomPriceMenu.propTypes = {
  room: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    grossTaxes: PropTypes.string.isRequired,
    grossTotal: PropTypes.string.isRequired,
    marginBreakdown: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        currency: PropTypes.string.isRequired,
      }),
    ).isRequired,
    netRoomRate: PropTypes.string.isRequired,
    perNightPrice: PropTypes.string.isRequired,
  }).isRequired,
  amountLabel: PropTypes.string.isRequired,
  durationOfStaying: PropTypes.number.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  requiredRooms: PropTypes.number.isRequired,
};

export default RoomPriceMenu;
