import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import { CheckBox } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import LogoChip from 'sharedWebpack/IBE/SearchResult/flight/LogoChip';
import { TYPE_FLIGHT, TYPE_SERVICE, TYPE_SEAT } from 'sharedWebpack/helpers/itemTypes';
import { TripShape, ValuesShape } from './Shapes/index';
import { OLD_STATE, NEW_STATE } from '../IssueBooking/constants';
import FlightNumberTags from '../../AncillariesTab/AncillaryRow/FlightNumberTags';
import FlightPriceChangesContent from './FlightPriceChangesContent';
import AncillaryPriceChangesContent from './AncillaryPriceChangesContent';

class ConfirmAttributeChanges extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      style: 'withNewMargin',
    };
  }

  toggleStyle = () => {
    let newStyle = 'withOldMargin';
    if (this.state.style === 'withOldMargin') {
      newStyle = 'withNewMargin';
    }
    this.setState({ style: newStyle });
  };

  decide = keepOldPrice => {
    const { values } = this.props;

    let grossPrice;
    let netPrice;
    if (keepOldPrice) {
      netPrice = values.before.netPrice;
      grossPrice = values.before.grossPrice;
    } else {
      netPrice = values.after.netPrice;
      grossPrice = values.after[this.state.style].grossPrice;
    }

    this.props.onDecision({
      id: values.id,
      decisions: { gross_price: grossPrice, net_price: netPrice },
    });
  };

  render() {
    const { values, disabled, trips, fareExpired } = this.props;
    const withOldMargin = this.state.style === 'withOldMargin';

    const airportsMap = trips
      .flatMap(trip => trip.airports)
      .reduce((acc, airport) => {
        acc[airport.code] = airport;

        return acc;
      }, {});

    const isJourneyElementAncillaries = [TYPE_SEAT, TYPE_SERVICE].includes(
      values.journeyElementType,
    );

    const journeyDetail = trips.map(trip => {
      const currentOrigin = airportsMap[trip.originCode];
      const currentDestination = airportsMap[trip.destinationCode];
      return (
        <Fragment key={trip.id}>
          {fareExpired &&
            trip.airlines.map(({ logo }) => (
              <div className="issue-booking-modal__airlines">
                <LogoChip logo={logo} />
              </div>
            ))}
          <div className="refresh-booking-flight_info__steps">
            {trip.airports.map((airport, airportIndex) => {
              const nextAirport = trip.airports[airportIndex + 1];
              return (
                <Fragment key={airport.code}>
                  {nextAirport ? (
                    <Fragment>
                      <div className="refresh-booking-flight_info__steps-step">
                        <img
                          className="refresh-booking-flight_info__steps-step__flag"
                          src={airport.countryFlag}
                          alt={airport.code}
                        />
                        <div className="refresh-booking-flight_info__steps-step__code--primary">
                          {airport.code}
                        </div>
                        <Icon size="tiny" name="arrowForwardAlt" />
                      </div>
                      <div className="refresh-booking-flight_info__steps-step">
                        <img
                          className="refresh-booking-flight_info__steps-step__flag"
                          src={nextAirport.countryFlag}
                          alt={airport.code}
                        />
                        <div className="refresh-booking-flight_info__steps-step__code--primary">
                          {nextAirport.code}
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </Fragment>
              );
            })}
          </div>
          <div className="refresh-booking-flight_info__destinations">
            {I18n.t('components.ibe.flight_service_selection.service_header_text', {
              originPlaceName: currentOrigin.placeName,
              originCountryName: currentOrigin.countryName,
              destinationPlaceName: currentDestination.placeName,
              destinationCountryName: currentDestination.countryName,
            })}
          </div>
          {fareExpired && isJourneyElementAncillaries && (
            <div className="issue-booking-modal__flight-codes col-grid col-bleed direction-row">
              <FlightNumberTags flightNumbers={values.operatingFlightCodes} />
            </div>
          )}
        </Fragment>
      );
    });

    const priceChangesContent = state => {
      switch (values.journeyElementType) {
        case TYPE_FLIGHT:
          return (
            <FlightPriceChangesContent
              values={values[state]}
              showOldPrice={state === OLD_STATE}
              style={this.state.style}
            />
          );
        case TYPE_SEAT:
        case TYPE_SERVICE:
          return (
            <AncillaryPriceChangesContent values={values} style={this.state.style} state={state} />
          );
        default:
          return <Fragment />;
      }
    };

    return (
      <Fragment>
        <div className="refresh-booking__price-breakdown-container">
          <div className="refresh-booking__before col-6 col-bleed">
            <div className="refresh-booking__before--tag">
              {fareExpired
                ? I18n.t('admin.order_items.issue_ticket.can_not_issue.fare_expired.old')
                : I18n.t('admin.components.refresh_booking.before')}
            </div>
            <div
              className={classNames('refresh-booking-flight_info', {
                'refresh-booking-flight_info--without-padding': isJourneyElementAncillaries,
              })}
            >
              {journeyDetail}
            </div>
            <div className="refresh-booking__border refresh-booking__border--old">
              {priceChangesContent(OLD_STATE)}
              {!fareExpired && (
                <div className="refresh-booking__action refresh-booking__action--old col-12 col-bleed">
                  <div className="refresh-booking__modifier" />
                  <Button
                    version="v2"
                    primary={true}
                    label={I18n.t('admin.components.refresh_booking.confirm.old_price')}
                    onClick={() => this.decide(true)}
                    disabled={disabled}
                    size="small"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="refresh-booking__after col-6 col-bleed">
            <div className="refresh-booking__after--tag">
              {fareExpired
                ? I18n.t('admin.order_items.issue_ticket.can_not_issue.fare_expired.new')
                : I18n.t('admin.components.refresh_booking.after')}
            </div>
            <div
              className={classNames('refresh-booking-flight_info', {
                'refresh-booking-flight_info--without-padding': isJourneyElementAncillaries,
              })}
            >
              {journeyDetail}
            </div>
            <div className="refresh-booking__border refresh-booking__border--new">
              {priceChangesContent(NEW_STATE)}
              {!fareExpired && (
                <div className="refresh-booking__action col-12 col-bleed">
                  <div className="refresh-booking__modifier col-12 col-bleed">
                    <CheckBox
                      label={I18n.t('admin.components.refresh_booking.confirm.apply_old_margin')}
                      size="small"
                      name="apply-old-margin"
                      checked={withOldMargin}
                      onChange={this.toggleStyle}
                    />
                  </div>
                  <Button
                    version="v2"
                    primary={true}
                    label={I18n.t('admin.components.refresh_booking.confirm.new_price')}
                    disabled={this.state.processing}
                    size="small"
                    onClick={() => this.decide(false)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

ConfirmAttributeChanges.defaultProps = {
  fareExpired: false,
};

ConfirmAttributeChanges.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onDecision: PropTypes.func.isRequired,
  fareExpired: PropTypes.bool,
  values: ValuesShape.isRequired,
  trips: PropTypes.arrayOf(TripShape).isRequired,
};

export default hot(module)(ConfirmAttributeChanges);
