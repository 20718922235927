const isChannelSelected = (params, channelName, availableChannels) => {
  if (
    params.accountSettings &&
    params.accountSettings[channelName] &&
    params.accountSettings[channelName].length > 0
  ) {
    return true;
  }

  if (!params.accountSettings && availableChannels.includes(channelName)) {
    return true;
  }

  return false;
};

export default isChannelSelected;
