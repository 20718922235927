/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import DateTime from 'sharedWebpack/DateTime';
import formatAddress from './formatAddress';
import './styles.scss';

const TravelplanItemHeader = ({ data, firstItem }) => {
  const dateTimeFormat = data.type === 'hotel' ? 'short' : 'shortWithTime';

  const headerData = {
    flight: {
      dateTime: data.departureLocaltime,
      title: I18n.t('admin.components.orders.overview.travel_plan.flight.header.title', {
        origin_city: data.originDetails && data.originDetails.cityName,
        destination_city: data.tripDestinationDetails && data.tripDestinationDetails.cityName,
      }),
      subTitle: I18n.t('admin.components.orders.overview.travel_plan.flight.header.sub_title', {
        code: data.originDetails && data.originDetails.code,
        name: data.originDetails && data.originDetails.name,
        city: data.originDetails && data.originDetails.cityName,
        country: data.originDetails && data.originDetails.countryName,
      }),
      cardMainTitle: I18n.t(
        'admin.components.orders.overview.travel_plan.flight.header.suggestion_title',
      ),
      cardSubTitle: I18n.t(
        'admin.components.orders.overview.travel_plan.flight.header.suggestion_sub_title',
      ),
    },
    car: {
      dateTime: data.pickUpTime ? data.pickUpTime : data.dropOffTime,
      title: data.pickUpTime
        ? I18n.t('admin.components.orders.overview.travel_plan.car.header.pick_up.title', {
            total_days: data.totalDays,
          })
        : I18n.t('admin.components.orders.overview.travel_plan.car.header.drop_off.title', {
            total_days: data.totalDays,
          }),
      subTitle: data.pickUpTime
        ? I18n.t('admin.components.orders.overview.travel_plan.car.header.pick_up.sub_title', {
            location: data.pickUpLocation,
          })
        : I18n.t('admin.components.orders.overview.travel_plan.car.header.drop_off.sub_title', {
            location: data.dropOffLocation,
          }),
      cardMainTitle: data.pickUpTime
        ? I18n.t('admin.components.orders.overview.travel_plan.car.header.pick_up.suggestion_title')
        : I18n.t(
            'admin.components.orders.overview.travel_plan.car.header.drop_off.suggestion_title',
          ),
      cardSubTitle: I18n.t(
        'admin.components.orders.overview.travel_plan.car.header.suggestion_sub_title',
      ),
    },
    cruise: {
      dateTime: data.boardingTime ? data.boardingTime : data.offBoardingTime,
      title: data.boardingTime
        ? I18n.t('admin.components.orders.overview.travel_plan.cruise.header.boarding.title', {
            name: data.shipName,
          })
        : I18n.t('admin.components.orders.overview.travel_plan.cruise.header.off_boarding.title', {
            name: data.shipName,
          }),
      subTitle: data.boardingTime
        ? formatAddress(
            `${data.boardingLocation},${data.boardingAddress &&
              data.boardingAddress.city},${data.boardingAddress && data.boardingAddress.country}`,
          )
        : formatAddress(
            `${data.offBoardingLocation},${data.offBoardingAddress &&
              data.offBoardingAddress.city},${data.offBoardingAddress &&
              data.offBoardingAddress.country}`,
          ),
      cardMainTitle: data.boardingTime
        ? I18n.t(
            'admin.components.orders.overview.travel_plan.cruise.header.boarding.suggestion_title',
          )
        : I18n.t(
            'admin.components.orders.overview.travel_plan.cruise.header.off_boarding.suggestion_title',
          ),
      cardSubTitle: I18n.t(
        'admin.components.orders.overview.travel_plan.cruise.header.suggestion_sub_title',
      ),
    },
    event: {
      dateTime: data.startTime ? data.startTime : data.endTime,
      title: data.startTime
        ? I18n.t('admin.components.orders.overview.travel_plan.event.header.title', {
            name: data.name,
          })
        : I18n.t('admin.components.orders.overview.travel_plan.event.header.title', {
            name: data.name,
          }),
      subTitle: data.startTime
        ? `${I18n.t(
            'admin.components.orders.overview.travel_plan.event.header.start.sub_title',
          )} ${formatAddress(
            `${data.address && data.address.street},${data.address &&
              data.address.city},${data.address && data.address.country}`,
          )}`
        : `${I18n.t(
            'admin.components.orders.overview.travel_plan.event.header.end.sub_title',
          )} ${formatAddress(
            `${data.address && data.address.street},${data.address &&
              data.address.city},${data.address && data.address.country}`,
          )}`,
      cardMainTitle: data.startTime
        ? I18n.t('admin.components.orders.overview.travel_plan.event.header.start.suggestion_title')
        : I18n.t('admin.components.orders.overview.travel_plan.event.header.end.suggestion_title'),
      cardSubTitle: I18n.t(
        'admin.components.orders.overview.travel_plan.event.header.suggestion_sub_title',
      ),
    },
    hotel: {
      dateTime: data.checkIn ? data.checkIn : data.checkOut,
      title: data.checkIn
        ? I18n.t('admin.components.orders.overview.travel_plan.hotel.header.check_in.title', {
            hotel_name: data.hotelName,
            night_count: data.totalNightDuration,
          })
        : I18n.t('admin.components.orders.overview.travel_plan.hotel.header.check_out.title', {
            hotel_name: data.hotelName,
          }),
      subTitle:
        data && data.checkIn
          ? I18n.t('admin.components.orders.overview.travel_plan.hotel.header.check_in.sub_title')
          : I18n.t('admin.components.orders.overview.travel_plan.hotel.header.check_out.sub_title'),
      cardMainTitle: data.checkIn
        ? I18n.t(
            'admin.components.orders.overview.travel_plan.hotel.header.check_in.suggestion_title',
          )
        : I18n.t(
            'admin.components.orders.overview.travel_plan.hotel.header.check_out.suggestion_title',
          ),
      cardSubTitle: I18n.t(
        'admin.components.orders.overview.travel_plan.hotel.header.suggestion_sub_title',
      ),
    },
    transfer: {
      dateTime: data.pickUpTime ? data.pickUpTime : data.dropOffTime,
      title: data.pickUpTime
        ? I18n.t('admin.components.orders.overview.travel_plan.transfer.header.pick_up.title', {
            address: data.pickAddress,
          })
        : I18n.t('admin.components.orders.overview.travel_plan.transfer.header.drop_off.title', {
            address: data.dropOffAddress,
          }),
      subTitle: data.pickAddress ? data.pickAddress : data.dropOffAddress,
      cardMainTitle: data.pickUpTime
        ? I18n.t(
            'admin.components.orders.overview.travel_plan.transfer.header.pick_up.suggestion_title',
          )
        : I18n.t(
            'admin.components.orders.overview.travel_plan.transfer.header.drop_off.suggestion_title',
          ),
      cardSubTitle: I18n.t(
        'admin.components.orders.overview.travel_plan.transfer.header.suggestion_sub_title',
      ),
    },
  };

  return (
    <div className="grid grid-gap-20 travel-plan__timeline-item">
      <div
        className={classNames('col-12 col-sm-3 col-xlg-2', {
          'hidden-xxs hidden-xs': firstItem,
        })}
      >
        <div
          className={classNames('travel-plan__timeline-item-indicator', {
            'travel-plan__timeline-item-indicator--hide-extra-upper-line': firstItem,
          })}
        >
          <div className="travel-plan__timeline-item-indicator-dot" />
          <div className="travel-plan__timeline-item-indicator-line" />
        </div>
      </div>
      <div className="col-12 col-sm-9 col-xlg-10">
        <div className="travel-plan__timeline-item-title">
          <Icon
            className="travel-plan__timeline-item-title-icon"
            name="time"
            color="danger"
            size="tiny"
          />
          <div className="travel-plan__timeline-item-title-text">
            <DateTime
              dateTime={headerData[data.type].dateTime}
              format={dateTimeFormat}
              displayWithoutConversion={true}
            />
            {headerData[data.type].title}
          </div>
        </div>
        <div className="travel-plan__timeline-item-sub-title">{headerData[data.type].subTitle}</div>
        <div className="travel-plan__timeline-item-suggestion-card">
          <Card version="v2" size="full">
            <div className="travel-plan__timeline-item-suggestion-card-info">
              <div className="travel-plan__timeline-item-suggestion-card-info-icon">
                <Icon name="invalidOutline" color="warning" size="small" rotate="180" />
              </div>
              <div className="travel-plan__timeline-item-suggestion-card-title">
                <div className="travel-plan__timeline-item-suggestion-card-main-title">
                  {headerData[data.type].cardMainTitle}
                </div>
                <div className="travel-plan__timeline-item-suggestion-card-sub-title">
                  {headerData[data.type].cardSubTitle}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

TravelplanItemHeader.defaultProps = {
  firstItem: false,
};

TravelplanItemHeader.propTypes = {
  data: PropTypes.shape({
    tripDestinationDetails: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
    departureLocaltime: PropTypes.string,
    arrivalLocaltime: PropTypes.string,
    originDetails: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
  }).isRequired,
  firstItem: PropTypes.bool,
};

export default TravelplanItemHeader;
