import React from 'react';
import PropTypes from 'prop-types';
import HotelDefaultImage from 'agentSyncedImages/fallback_images/product/product_xxl_hotel.png';
import Amount from 'sharedWebpack/Amount';
import { I18nText, EmphasisTag } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Stars from '../components/Stars';
import Rating from '../SearchResult/hotel/Rating';
import MarginTooltip from '../SearchResult/hotel/MarginTooltip';
import HotelName from '../SearchResult/hotel/HotelName';
import Refundable from '../SearchResult/hotel/Refundable';
import ApprovalRequiredLabel from './ApprovalRequiredLabel';
import OutOfPolicyLabel from '../components/OutOfPolicyLabel';
import PreferredLabel from '../components/PreferredLabel';

const HotelLineItem = props => {
  const totalTimeOfStay = new Date(props.checkOut) - new Date(props.checkIn);
  const differenceInDays = totalTimeOfStay / (1000 * 60 * 60 * 24);

  const pricePerNight = props.item.grossTotal / differenceInDays;

  return (
    <div className={props.className}>
      <div className="deal-hotel-details">
        <div className="col-12 col-grid col-bleed hotel-result__wrapper align-center">
          <div className="col-lg-4 col-md-4 col-12 col-bleed hotel-result__image-wrapper">
            {props.pictures[0] ? (
              <div className="deal-hotel-details__image">
                <img src={props.pictures[0]} alt="hotel" />
              </div>
            ) : (
              <div className="deal-hotel-details__image">
                <img src={HotelDefaultImage} alt="hotel" />
              </div>
            )}
          </div>
          <div className="col-lg-5 col-md-5 col-12 col-bleed hotel-result__middle">
            <div className="deal-hotel-details__name">
              <HotelName name={props.name} />
            </div>
            {props && props.stars && props.stars > 0 && <Stars stars={props.stars} />}
            <div className="deal-hotel-details__address">
              <Icon name="mapMarker" size="small" />
              {props.street} {props.zipCode} {props.city} {props.state}
            </div>

            <div className="hotel-result__middle-heading">
              {props && props.rating && props.rating !== null && (
                <Rating
                  rating={props.rating}
                  className="hotel-result__ratings"
                  source={props.ratingSource}
                />
              )}
            </div>

            <div className="hotel-result__middle-tags">
              <div className="hotel-result__middle-tags-label">
                {props.corporateDeal && (
                  <EmphasisTag
                    text={
                      <I18nText
                        id="ibe.search_results.labels.corporate_deal"
                        returnStringOnly={true}
                      />
                    }
                    type="important"
                    size="tiny"
                    className="hotel-result__labels"
                  />
                )}
              </div>
              {props.refundable && (
                <Refundable refundable={props.refundable} className="hotel-result__labels" />
              )}
              {props && props.item && props.item.outOfPolicy && (
                <OutOfPolicyLabel className="hotel-result__labels" />
              )}
              {props.item && props.item.preferred && (
                <PreferredLabel className="hotel-result__labels" />
              )}
              {props.item.approvalRequired && (
                <ApprovalRequiredLabel className="hotel-result__labels" />
              )}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12 col-bleed col-grid hotel-result__info">
            <div className="col-12 col-bleed">
              <div className="hotel-result__info-starts">
                {I18n.t('components.ibe.results.starting_from')}
              </div>
              <div className="hotel-result__info-price">
                <div className="hotel-result__info-price-amount">
                  <Amount currency={props.item.currency} value={props.item.grossTotal} />
                </div>
                <MarginTooltip
                  breakdown={props && props.item && props.item.marginBreakdown}
                  className="hotel-result__info-price-tooltip"
                >
                  <Icon name="invalid" size="small" />
                </MarginTooltip>
              </div>

              <div className="hotel-result__info-texts">
                <div>
                  <I18nText id="ibe.hotel.results.price_explanation" returnStringOnly={true} />
                </div>
                <div>
                  <I18nText id="ibe.hotel.results.price_tax_info" returnStringOnly={true} />
                </div>
              </div>

              {pricePerNight && (
                <div className="hotel-result__info-per-night">
                  <Amount
                    currency={props && props.item && props.item.currency}
                    value={pricePerNight}
                  />{' '}
                  {I18n.t('components.ibe.search_results.hotel.per_night')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HotelLineItem.defaultProps = {
  className: null,
  stars: null,
  rating: null,
  ratingSource: null,
  street: null,
  zipCode: null,
  city: null,
  state: null,
  pictures: [],
  corporateDeal: false,
  refundable: false,
};

HotelLineItem.propTypes = {
  stars: PropTypes.number,
  rating: PropTypes.number,
  ratingSource: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  street: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  checkIn: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired,
  pictures: PropTypes.node,
  corporateDeal: PropTypes.bool,
  refundable: PropTypes.bool,
  item: PropTypes.shape({
    approvalRequired: PropTypes.bool.isRequired,
    preferred: PropTypes.bool.isRequired,
    outOfPolicy: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    grossTotal: PropTypes.string.isRequired,
    perNightPrice: PropTypes.string.isRequired,
    marginBreakdown: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        total: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
      }),
    ),
    channel: PropTypes.shape({
      identifier: PropTypes.string.isRequired,
      account: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default HotelLineItem;
