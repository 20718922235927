import { combineReducers } from 'redux';

import travelerDetailsList from './travelerDetailsList';
import cartItems from './cartItems';
import cartState from './cartState';
import cartId from './cartId';
import fop from './fop';
import text from './cartText';
import fees from './cartFees';
import voucher from './cartVoucher';
import costCenters from './cartCostCenters';
import travelersError from './travelersError';
import visaAdvisoriesByCitizenshipAndDestination from './cartVisaAdvisory';
import cartStatus from './cartStatus';
import {
  AVAILABILITY_CHECKED,
  CART_FETCHED,
  SET_RESO_EMAIL_OPTION,
  SET_RESO_PHONE_OPTION,
  FETCHING_SET_FORM_OF_PAYMENT,
  FETCHING_CART,
  FETCHING_VISA_ADVISORY,
  VISA_ADVISORY_FETCHED,
} from '../../actions/common';
import {
  FETCHING_FLIGHT_SERVICES,
  FLIGHT_SERVICES_FETCHED,
  PROCEED_TO_PAYMENT_PAGE_VIEW,
  SAVING_TRAVELER_DETAILS,
  TRAVELERS_SAVING_FAILED,
  TRAVELER_DETAILS_SAVED,
} from '../../actions/flight';

function currency(state = null, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.currency;

    default:
      return state;
  }
}

function taxes(state = null, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.taxes;

    default:
      return state;
  }
}

function total(state = null, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.total;

    default:
      return state;
  }
}

function totalFees(state = null, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.totalFees;

    default:
      return state;
  }
}

function totalMargin(state = null, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.totalMargin;

    default:
      return state;
  }
}

function title(state = '', action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.title || '';

    default:
      return state;
  }
}

function sharedTitle(state = '', action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.sharedTitle || '';

    default:
      return state;
  }
}

function cartUrl(state = '', action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.cartUrl;

    default:
      return state;
  }
}

function expiredAt(state = null, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.expiredAt;

    default:
      return state;
  }
}

function isShared(state = false, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.isShared;

    default:
      return state;
  }
}

function sharedByCurrentPerson(state = false, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.sharedByCurrentPerson;

    default:
      return state;
  }
}

function totalFeesAndPaymentMethodSurcharges(state = null, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.totalFeesAndPaymentMethodSurcharges;

    default:
      return state;
  }
}

function bookingPossible(state = false, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.bookingPossible;

    default:
      return state;
  }
}

function approvalRequired(state = false, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.requiresApproval;

    default:
      return state;
  }
}

function allocationPerAccountingType(state = [], action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.allocationPerAccountingType;

    default:
      return state;
  }
}

function legalPaperAcceptances(state = { termsAndConditions: null, privacyPolicy: null }, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.legalPaperAcceptances;

    default:
      return state;
  }
}

function validations(state = { internationalFlight: false }, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.validations;

    default:
      return state;
  }
}
function allItemsAvailable(state = false, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.items.every(item => item.available);
    case AVAILABILITY_CHECKED:
      return action.availability;
    default:
      return state;
  }
}

function flightServicesFetched(state = false, action) {
  switch (action.type) {
    case FETCHING_FLIGHT_SERVICES:
      return false;

    case FLIGHT_SERVICES_FETCHED:
      return true;

    default:
      return state;
  }
}
function resoOptions(state = { passEmailToAirline: true, passPhoneToAirline: true }, action) {
  switch (action.type) {
    case CART_FETCHED:
      return {
        passEmailToAirline: action.cart.passEmailToAirline,
        passPhoneToAirline: action.cart.passPhoneToAirline,
      };

    case SET_RESO_EMAIL_OPTION:
      return {
        ...state,
        passEmailToAirline: action.value,
      };

    case SET_RESO_PHONE_OPTION:
      return {
        ...state,
        passPhoneToAirline: action.value,
      };

    default:
      return state;
  }
}

function cartCanBookQuoteItems(state = false, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.canBookQuoteItems;

    default:
      return state;
  }
}

function paymentMethodSurcharge(state = {}, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.paymentMethodSurcharge;

    default:
      return state;
  }
}

function grossTotalsByGroup(state = {}, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.grossTotalsByGroup;

    default:
      return state;
  }
}

function isFetchingFop(state = true, action) {
  switch (action.type) {
    case FETCHING_SET_FORM_OF_PAYMENT:
      return action.isFetchingFop;

    default:
      return state;
  }
}

function hasItemsWithPendingConfirmation(state = false, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.hasItemsWithPendingConfirmation;

    default:
      return state;
  }
}

function hasAutomaticallyAddedStaticProducts(state = false, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.hasAutomaticallyAddedStaticProducts;

    default:
      return state;
  }
}

function totalWithoutVoucherReduction(state = null, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.totalWithoutVoucherReduction;

    default:
      return state;
  }
}

function onlyOneItemFromSearch(state = false, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.onlyOneItemFromSearch;

    default:
      return state;
  }
}

function isCartFetching(state = false, action) {
  switch (action.type) {
    case FETCHING_CART:
      return true;

    case CART_FETCHED:
      return false;

    default:
      return state;
  }
}

function isSavingTravelerDetails(state = false, action) {
  switch (action.type) {
    case SAVING_TRAVELER_DETAILS:
      return true;
    case TRAVELER_DETAILS_SAVED:
      return false;
    case TRAVELERS_SAVING_FAILED:
      return false;
    default:
      return state;
  }
}

function proceedToPaymentPageView(state = false, action) {
  switch (action.type) {
    case PROCEED_TO_PAYMENT_PAGE_VIEW:
      return action.proceedToPaymentPageView;
    default:
      return state;
  }
}

function isFetchingVisaAdvisory(state = false, action) {
  switch (action.type) {
    case FETCHING_VISA_ADVISORY:
      return true;
    case VISA_ADVISORY_FETCHED:
      return false;
    default:
      return state;
  }
}

function availableFormOfPayment(
  state = {
    cash: {
      supported: false,
    },
    iata: {
      supported: false,
    },
    none: {
      supported: false,
      supportedBySupplier: false,
    },
    creditCard: {
      supported: false,
      supportedCards: [],
      requiredFields: {
        threeDsAuthenticationRequired: true,
      },
    },
  },
  action,
) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.availableFormOfPayment;

    default:
      return state;
  }
}

function travelerUpdateAbilities(state = [], action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.people.map(({ data }) => ({
        id: data.id,
        currentUserHasUpdateAbility: data.currentUserHasUpdateAbility,
      }));

    default:
      return state;
  }
}

const commonReducer = combineReducers({
  allItemsAvailable,
  allocationPerAccountingType,
  approvalRequired,
  bookingPossible,
  canBookQuoteItems: cartCanBookQuoteItems,
  costCenters,
  currency,
  fees,
  flightServicesFetched,
  fop,
  grossTotalsByGroup,
  hasItemsWithPendingConfirmation,
  hasAutomaticallyAddedStaticProducts,
  onlyOneItemFromSearch,
  id: cartId,
  isFetchingFop,
  items: cartItems,
  legalPaperAcceptances,
  paymentMethodSurcharge,
  resoOptions,
  state: cartState,
  status: cartStatus,
  taxes,
  text,
  total,
  totalFees,
  totalMargin,
  title,
  sharedTitle,
  cartUrl,
  expiredAt,
  isShared,
  isCartFetching,
  isSavingTravelerDetails,
  isFetchingVisaAdvisory,
  sharedByCurrentPerson,
  totalFeesAndPaymentMethodSurcharges,
  travelers: travelerDetailsList,
  travelersError,
  validations,
  visaAdvisoriesByCitizenshipAndDestination,
  voucher,
  totalWithoutVoucherReduction,
  availableFormOfPayment,
  proceedToPaymentPageView,
  travelerUpdateAbilities,
});

export default commonReducer;
