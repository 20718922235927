import getServicesWithQuantities from './getServicesWithQuantities';
import getSegmentServicesByTrip from './getSegmentServicesByTrip';

const getSegmentServicesByTripWithQuantities = (services, subItems, origin, destination) => {
  if (!Array.isArray(services)) {
    return [];
  }

  const segmentServicesByTrip = getSegmentServicesByTrip(services, origin, destination);

  return getServicesWithQuantities(segmentServicesByTrip, subItems);
};

export default getSegmentServicesByTripWithQuantities;
