import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import SelectBox from '@wtag/rcl-select-box';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

const PaymentsAndPreferencesSection = props => {
  const { personId, paymentTypes, fetchTraveller, preferredPayment, layman } = props;

  const [error, setError] = useState({});
  const [payment, setPayment] = useState(null);
  const [editing, setEditing] = useState(false);

  const saveTravellerUrl = layman
    ? routes.public.people.update({})
    : routes.admin.people.update({ personId });

  const savePaymentPreferences = async () => {
    const PaymentPreferencesParams = {
      preferred_payment: payment,
    };

    const { data } = await httpClient.patch(saveTravellerUrl, {
      person: PaymentPreferencesParams,
    });

    if (data.error === null) {
      fetchTraveller();
      setEditing(false);
    }
  };

  const onSavePaymentPreferences = () =>
    savePaymentPreferences().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  const paymentTypeOptions = paymentTypes
    ? Object.keys(paymentTypes).map(paymentTypeKey => ({
        value: paymentTypes[paymentTypeKey],
        label: I18n.t(paymentTypeKey, {
          scope: 'simple_form.options.person.preferred_payment',
        }),
      }))
    : [];

  const findSelectBoxContent = (options, value) => {
    const selectedOption = options.filter(option => value === option.value)[0];
    if (selectedOption) {
      return {
        value: selectedOption.value,
        label: selectedOption.label,
      };
    }

    return null;
  };

  return (
    <div className="col-grid traveller-edit-payment-and-preferences">
      <div className="traveller-edit-payment-section">
        <div className="traveller-edit-payment-information-section__title">
          <span className="traveller-edit-payment-and-preferences__header">
            {I18n.t('admin.customers.form.payment_preferences')}
          </span>
          {editing ? (
            <span className="traveller-edit-payment-and-preferences__actions">
              <IconButton
                icon={<Icon name="check" />}
                color="tertiary"
                size="small"
                onClick={onSavePaymentPreferences}
              />
              <IconButton
                icon={<Icon name="close" />}
                color="tertiary"
                size="small"
                onClick={() => setEditing(false)}
              />
            </span>
          ) : (
            <span className="traveller-edit-payment-and-preferences__actions">
              <IconButton
                icon={<Icon name="edit" />}
                color="tertiary"
                size="small"
                onClick={() => setEditing(true)}
              />
            </span>
          )}
        </div>
        <div className="grid grid-gap-12">
          {editing ? (
            <div className="col-12 traveller-edit-payment-information__content">
              <SelectBox
                size="tiny"
                placeholderText={I18n.t('admin.components.travellers.edit.payment.placeholder')}
                label={I18n.t('simple_form.labels.person.preferred_payment')}
                width="full"
                options={paymentTypeOptions}
                value={findSelectBoxContent(paymentTypeOptions, payment)}
                onChange={selectedOption => setPayment(selectedOption && selectedOption.value)}
                errorMsg={error.preferredPayment}
              />
            </div>
          ) : (
            <div className="col-12 traveller-edit-payment-information__content">
              <div className="traveller-edit-payment-information__header">
                {I18n.t('simple_form.labels.person.preferred_payment')}
              </div>
              <div className="traveller-edit-payment-information__value">
                {preferredPayment
                  ? I18n.t(preferredPayment, {
                      scope: 'simple_form.options.person.preferred_payment',
                    })
                  : I18n.t('admin.components.travellers.edit.payment.no_payment_info')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PaymentsAndPreferencesSection.defaultProps = {
  preferredPayment: null,
  layman: false,
};

PaymentsAndPreferencesSection.propTypes = {
  personId: PropTypes.number.isRequired,
  fetchTraveller: PropTypes.func.isRequired,
  preferredPayment: PropTypes.string,
  paymentTypes: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  layman: PropTypes.bool,
};

export default PaymentsAndPreferencesSection;
