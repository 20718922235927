import { UPDATE_ERROR_ALERT_TOKEN, CLEAR_ERROR_ALERT_TOKEN } from '../actions';

export default function errorAlertByToken(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case UPDATE_ERROR_ALERT_TOKEN:
      newState[action.ccToken] = true;
      return newState;

    case CLEAR_ERROR_ALERT_TOKEN:
      return {};

    default:
      return newState;
  }
}
