import { CART_STATUS_FETCHED } from '../../actions/common';

export default function cartStatus(status = null, action) {
  switch (action.type) {
    case CART_STATUS_FETCHED:
      return action.status;

    default:
      return status;
  }
}
