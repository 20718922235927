const getCarCityInfo = (pickUpCity, dropOffCity, durationInDays) => {
  const isSameCity = pickUpCity && pickUpCity.toLowerCase() === dropOffCity.toLowerCase();

  const pickUpCityText =
    pickUpCity || I18n.t('components.ibe.search_results.car.content.unknown_city');
  const dropOffCityText =
    dropOffCity || I18n.t('components.ibe.search_results.car.content.unknown_city');

  const city = isSameCity
    ? I18n.t('components.ibe.search_results.car.content.same_city', {
        city: pickUpCityText,
      })
    : I18n.t('components.ibe.search_results.car.content.different_city', {
        pickUpCity: pickUpCityText,
        dropOffCity: dropOffCityText,
      });

  return I18n.t('components.ibe.search_results.car.content.day_count', {
    durationInDays,
    city,
  });
};

export default getCarCityInfo;
