import { TOGGLE_CARD_ADDRESS_FORM_STATE } from '../actions';

export default function willResetCardAddressForm(state = false, action) {
  switch (action.type) {
    case TOGGLE_CARD_ADDRESS_FORM_STATE:
      return action.willResetCardAddressForm;
    default:
      return state;
  }
}
