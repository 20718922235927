import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@wtag/react-comp-lib';
import useViewMode from 'sharedWebpack/useViewMode';
import {
  DESKTOP_TAB_DEVICE,
  DESKTOP_DEVICE,
  LARGE_DESKTOP_DEVICE,
} from 'sharedWebpack/old/libraries/responsiveHelpers';
import './styles.scss';

const FilterToggler = ({ label, onToggleFilterVisibility, onUpdateViewMode }) => {
  const viewMode = useViewMode();
  const isInDesktopView = [DESKTOP_TAB_DEVICE, DESKTOP_DEVICE, LARGE_DESKTOP_DEVICE].includes(
    viewMode,
  );

  useEffect(() => {
    onUpdateViewMode(viewMode);
  }, [viewMode]);

  if (isInDesktopView) {
    return null;
  }

  return (
    <div className="filter-toggler">
      <Link onClick={onToggleFilterVisibility} modifier="tertiary">
        {label}
      </Link>
    </div>
  );
};

FilterToggler.propTypes = {
  label: PropTypes.string.isRequired,
  onToggleFilterVisibility: PropTypes.func.isRequired,
  onUpdateViewMode: PropTypes.func.isRequired,
};

export default FilterToggler;
