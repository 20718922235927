import React from 'react';
import PropTypes from 'prop-types';
import { fetchFlightSearchResults, filterFlightSearch } from 'sharedWebpack/IBE/actions/flight';
import { connect } from 'react-redux';
import Button from '@wtag/rcl-button';
import classNames from 'classnames';
import { SORT_BY_ARRIVAL_EARLIEST, SORT_BY_PRICE_LOWEST } from '../../../helpers/flightSortTypes';
import './styles.scss';

const FilterButton = ({ sortBy, sortFlightSearch }) => (
  <div className="filter-button__sort-button d-flex">
    <Button
      className={classNames({
        'filter-button__sort-button--disabled': sortBy === SORT_BY_PRICE_LOWEST,
      })}
      label={I18n.t('ibe.available_sort_options.price_cheapest')}
      size="small"
      disabled={sortBy === SORT_BY_PRICE_LOWEST}
      onClick={() => sortFlightSearch('sortBy', SORT_BY_PRICE_LOWEST)}
    />
    <Button
      className={classNames({
        'filter-button__sort-button--disabled': sortBy === SORT_BY_ARRIVAL_EARLIEST,
      })}
      disabled={sortBy === SORT_BY_ARRIVAL_EARLIEST}
      label={I18n.t('ibe.available_sort_options.price_fastest')}
      size="small"
      onClick={() => sortFlightSearch('sortBy', SORT_BY_ARRIVAL_EARLIEST)}
    />
  </div>
);

FilterButton.propTypes = {
  sortBy: PropTypes.string.isRequired,
  sortFlightSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let searchParamsId = 'lastSearch';
  if (ownProps.match.params.searchId) {
    searchParamsId = ownProps.match.params.searchId;
  }

  return {
    sortBy:
      searchParamsId !== 'lastSearch' &&
      state.flights &&
      state.flights.searchFilterParamsBySearchId[searchParamsId]
        ? state.flights.searchFilterParamsBySearchId[searchParamsId].sortBy
        : SORT_BY_PRICE_LOWEST,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  sortFlightSearch: (filter, value, key, isAllChecked) => {
    const { searchId, tripId } = ownProps.match.params;
    dispatch(filterFlightSearch(filter, value, key, searchId, tripId, isAllChecked));
    dispatch(fetchFlightSearchResults({ searchId, tripId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
