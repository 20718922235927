import React from 'react';
import PropTypes from 'prop-types';

import IdentificationDocumentCard from './IdentificationDocumentCard';

class IdentificationDocumentLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedIndex: props.fields.id.value };
  }

  injectOnSelect = (element, index, isEditAction = false) => {
    this.props.fields.id.onChange(element.id);
    this.props.fields.type.onChange(element.documentType);
    this.props.fields.number.onChange(element.number);
    this.props.fields.country.onChange(element.country);
    this.props.fields.nationality.onChange(element.nationality);
    this.props.fields.validity.onChange(element.validity);
    this.setState({ selectedIndex: index });

    const updatedSelectedCustomer = {
      ...this.props.selectedCustomer,

      document: {
        id: element.id,
        type: element.documentType,
        number: element.number,
        country: element.country,
        nationality: element.nationality,
        validity: element.validity,
      },
    };
    this.props.onCustomerSelected(this.props.travelerIndex, updatedSelectedCustomer);

    if (isEditAction) {
      this.props.identificationDocumentEditAction();
    }
  };

  isDocumentSelected = (documentId, index) =>
    index === this.state.selectedIndex || documentId === this.props.fields.id.value;

  render() {
    const { fields, identificationDocumentsList } = this.props;

    return (
      <div className="identification-documents-list">
        {identificationDocumentsList.map((document, index) => (
          <IdentificationDocumentCard
            key={document.id}
            index={index}
            document={document}
            setSelection={this.injectOnSelect}
            isSelected={this.isDocumentSelected(document.id, index)}
            isInvalid={fields.validity.invalid}
            validityError={fields.validity.error}
          />
        ))}
      </div>
    );
  }
}

IdentificationDocumentLists.propTypes = {
  identificationDocumentEditAction: PropTypes.func.isRequired,
  fields: PropTypes.shape().isRequired,
  identificationDocumentsList: PropTypes.shape([]).isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({}).isRequired,
  travelerIndex: PropTypes.number.isRequired,
};

export default IdentificationDocumentLists;
