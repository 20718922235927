/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import DateTime from 'sharedWebpack/DateTime';
import Amount from 'sharedWebpack/Amount';
import { I18nText, Link, EmphasisTag } from '@wtag/react-comp-lib';
import Popover from '@wtag/rcl-popover';
import CarDefaultImage from 'agentSyncedImages/fallback_images/car-image-unavailable.png';
import CarName from './CarName';
import Facility from '../SearchResult/car/Facility';
import Insurance from '../SearchResult/car/Insurance';
import MarginBreakdown from '../components/MarginBreakdown';
import FuelType from '../SearchResult/car/FuelType';
import Mileage from '../SearchResult/car/Mileage';
import GenericFacility from '../SearchResult/car/GenericFacility';
import ApprovalRequiredLabel from './ApprovalRequiredLabel';
import OutOfPolicyLabel from '../components/OutOfPolicyLabel';
import PreferredLabel from '../components/PreferredLabel';

const CarLineItem = props => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const insurances = () =>
    props.insurances.map(insurance => (
      <li key={insurance.type} className="car-result-card__facility">
        <Facility
          facility={<Insurance type={insurance.type} />}
          type="insurance"
          amount={insurance.amount}
          currency={insurance.currency}
          modifier={insurance.modifier}
        />
      </li>
    ));

  const liabilities = () =>
    props.liabilities.map(liability => (
      <li key={liability.type} className="car-result-card__facility">
        <Facility
          facility={<Insurance type={liability.type} />}
          type="liabilities"
          amount={liability.amount}
          currency={liability.currency}
          modifier={liability.modifier}
        />
      </li>
    ));

  const facilities = () =>
    props.facilities.map(facility => (
      <li key={facility.type} className="car-result__facility">
        <Facility
          facility={<GenericFacility type={facility.type} />}
          amount={facility.amount}
          currency={facility.currency}
          modifier={facility.modifier}
        />
      </li>
    ));

  const showInsuranceSidePanel = () => {
    props.setSidePanelTitle(
      <I18nText id="ibe.search_results.car.label.responsibility_waiver" returnStringOnly={true} />,
    );
    props.setSidePanelBody(insurances());
    props.setOpenSidePanel();
  };

  const showLiabilitiesSidePanel = () => {
    props.setSidePanelTitle(
      <I18nText id="ibe.search_results.car.label.liabilities" returnStringOnly={true} />,
    );
    props.setSidePanelBody(liabilities());
    props.setOpenSidePanel();
  };

  const showFacilitiesSidePanel = () => {
    props.setSidePanelTitle(
      <I18nText id="ibe.search_results.car.label.facilities" returnStringOnly={true} />,
    );
    props.setSidePanelBody(facilities());
    props.setOpenSidePanel();
  };

  const locationAndTime = (isPickUp, date, hour, location) => (
    <div className="car-result-card__car-location-info">
      <div className="col-grid col-bleed direction-row align-center car-result-card__car-location-info-label">
        <div className="car-result-card__car-location-info-label-icon">
          {isPickUp ? (
            <Icon name="arrowDown" color="default" />
          ) : (
            <Icon name="arrowUp" color="default" />
          )}
        </div>
        {isPickUp
          ? I18n.t('components.ibe.car_results.pick_up')
          : I18n.t('components.ibe.car_results.drop_off')}
      </div>
      <div className="car-result-card__car-location-info-date">
        <DateTime dateTime={date} format="shortWithTime" />
      </div>
      <div className="car-result-card__car-location-info-place">{location}</div>
    </div>
  );

  return (
    <div className={props.className}>
      <div className="deal__car-details deal-car-details">
        <div className="col-grid col-bleed direction-row align-center justify-space-between car-result-card__vendor-line">
          <div className="deal-car-details__vendor-logo deal-car-details__image-center">
            {props.vendorImageUrl && <img src={props.vendorImageUrl} alt="vendor" />}
          </div>
        </div>
        <div className="col-12 col-grid col-bleed direction-row align-start car-result-card__content-line">
          <div className="car-result-card__car-image">
            {props.carImageUrl ? (
              <img src={props.carImageUrl} alt="car" />
            ) : (
              <img src={CarDefaultImage} alt="car" />
            )}
          </div>
          <div className="col-bleed car-result-card__car-info">
            <div>
              <div className="deal-car-details__car-name">
                {props.make} {props.name}
              </div>
              <div className="deal-car-details__car-category">
                <CarName acrissCode={props.acrissCode} category={props.category} />
              </div>
              <div className="deal-car-details__pickup-dropoff">
                <div className="car-result-card__car-location-info-pickup">
                  {locationAndTime(
                    true,
                    props.pickUp.localtime,
                    props.pickUp.localtimeHour,
                    props.pickUp.location,
                  )}
                </div>
                {locationAndTime(
                  false,
                  props.dropOff.localtime,
                  props.dropOff.localtimeHour,
                  props.dropOff.location,
                )}
              </div>
              <div className="car-result-card__car-mileage">
                {props.mileage.length > 0 && <Mileage mileages={props.mileage} />}
              </div>
              {props && props.insurances && props.insurances.length > 0 && (
                <div className="deal-car-details__insurance-panel">
                  <div className="deal-car-details__car-insurance">
                    <div className="car-result-card__car-insurance-icon">
                      <Icon name="checkCircle" size="tiny" color="success" />
                    </div>
                    <I18nText id="ibe.search_results.car.label.responsibility_waiver_available" />
                  </div>

                  <div className="deal-car-details__insurance-side-panel">
                    <Link
                      className="side-panel-link"
                      onClick={() => showInsuranceSidePanel()}
                      modifier="default"
                      size="tiny"
                    >
                      <I18nText
                        id="ibe.search_results.car.label.view_coverage"
                        returnStringOnly={true}
                      />
                    </Link>
                  </div>
                </div>
              )}

              {props && props.facilities && props.facilities.length > 0 && (
                <div className="car-result-card__insurance-side-panel-wrapper">
                  {props && props.liabilities && props.liabilities.length > 0 && (
                    <div className="car-result-card__liabilities-side-panel">
                      <Link
                        className="side-panel-link"
                        onClick={() => showLiabilitiesSidePanel()}
                        modifier="default"
                        size="tiny"
                      >
                        <I18nText
                          id="ibe.search_results.car.label.view_liabilities"
                          returnStringOnly={true}
                        />
                      </Link>
                    </div>
                  )}
                  {props && props.facilities && props.facilities.length > 0 && (
                    <div className="car-result-card__facilities-side-panel">
                      <button className="side-panel-link" onClick={() => showFacilitiesSidePanel()}>
                        <I18nText
                          id="ibe.search_results.car.label.view_facilities"
                          returnStringOnly={true}
                        />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="car-result-card__car-info-price">
              <div className="car-result-card__car-info-price-starts">
                {I18n.t('components.ibe.results.starting_from')}
              </div>
              <div className="car-result-card__car-info-price-total">
                <MarginBreakdown breakdown={props.item.marginBreakdown}>
                  <Amount currency={props.item.currency} value={props.item.grossTotal} />
                </MarginBreakdown>
              </div>
              <div className="car-result-card__car-info-price-explanation">
                <div>{I18n.t('components.ibe.cars_results.price_tax_info')}</div>
                <div className="car-result-card__car-info-price-explanation--rate">
                  <Amount currency={props.item.currency} value={props.ratePerDay} />{' '}
                  {I18n.t('components.ibe.cars_results.price_explanation')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="car-result-card__divider" />
        <div className="align-center justify-space-between car-result-card__facilities-wrapper">
          <div className="grid car-result-card__facilities">
            <div className="col-7 col-grid direction-row align-center car-result-card__labels">
              {props.seatCount !== 0 && props.seatCount && (
                <div className="deal-car-details__labels-item">
                  <EmphasisTag
                    text={I18n.t('components.ibe.deal.car_seats', {
                      count: props.seatCount,
                    })}
                    type="neutral"
                    size="tiny"
                  />
                </div>
              )}
              {props.doors && (
                <div className="deal-car-details__labels-item">
                  <EmphasisTag
                    text={I18n.t('components.ibe.deal.car_doors', {
                      count: props.doors,
                    })}
                    type="neutral"
                    size="tiny"
                  />
                </div>
              )}
              {props.airConditioning && (
                <div className="deal-car-details__labels-item">
                  <EmphasisTag
                    text={<I18nText id="ibe.deal.car.ac" returnStringOnly={true} />}
                    type="neutral"
                    size="tiny"
                  />
                </div>
              )}

              <div className="car-result-card__facilities-item">
                <Popover
                  isVisible={isPopoverVisible}
                  content={
                    <div className="col-grid col-bleed align-start car-result-card__facilities-dropdown">
                      <div className="car-result-card__facilities-dropdown-item">
                        {props.fuelType && <FuelType type={props.fuelType} />}
                      </div>
                      {props.airbag && (
                        <div className="car-result-card__facilities-dropdown-item">
                          {I18n.t('components.ibe.cars_facilities.air_bag')}
                        </div>
                      )}
                    </div>
                  }
                  size="small"
                  direction="bottom-right"
                  onOutsideClick={() => setIsPopoverVisible(false)}
                >
                  <span
                    className="car-result-card__facilities-link"
                    onClick={() => setIsPopoverVisible(prevState => !prevState)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => setIsPopoverVisible(prevState => !prevState)}
                  >
                    {props.airbag
                      ? I18n.t('components.ibe.search_results.car.label.plus_attributes', {
                          number: 2,
                        })
                      : I18n.t('components.ibe.search_results.car.label.plus_attributes', {
                          number: 1,
                        })}
                  </span>
                </Popover>
              </div>
            </div>

            <div className="col-5 col-grid direction-row align-center car-result-card__labels justify-end">
              {props.corporateDeal && (
                <div className="deal-car-details__labels-item">
                  <EmphasisTag
                    text={
                      <I18nText
                        id="ibe.search_results.labels.corporate_deal"
                        returnStringOnly={true}
                      />
                    }
                    type="important"
                    size="tiny"
                  />
                </div>
              )}
              {props.item && props.item.approvalRequired && (
                <div className="deal-car-details__labels-item">
                  <ApprovalRequiredLabel />
                </div>
              )}
              {props.item && props.item.preferred && (
                <div className="deal-car-details__labels-item">
                  <PreferredLabel />
                </div>
              )}
              {props.item && props.item.outOfPolicy && (
                <div className="deal-car-details__labels-item">
                  <OutOfPolicyLabel />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CarLineItem.defaultProps = {
  className: null,
  vendorImageUrl: null,
  carImageUrl: null,
  name: null,
  make: null,
  airConditioning: false,
  insurances: null,
  facilities: [],
  mileage: [],
  liabilities: [],
  corporateDeal: false,
  airbag: null,
  doors: null,
  seatCount: 0,
  ratePerDay: null,
};

CarLineItem.propTypes = {
  acrissCode: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  seatCount: PropTypes.number,
  doors: PropTypes.string,
  ratePerDay: PropTypes.string,
  vendorImageUrl: PropTypes.node,
  carImageUrl: PropTypes.node,
  name: PropTypes.node,
  make: PropTypes.node,
  airConditioning: PropTypes.bool,
  airbag: PropTypes.string,
  corporateDeal: PropTypes.bool,
  setSidePanelTitle: PropTypes.func.isRequired,
  setSidePanelBody: PropTypes.func.isRequired,
  setOpenSidePanel: PropTypes.func.isRequired,
  fuelType: PropTypes.string.isRequired,
  insurances: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
    modifier: PropTypes.string,
    type: PropTypes.string,
  }),
  facilities: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
    modifier: PropTypes.string,
    type: PropTypes.string,
  }),
  mileage: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
    modifier: PropTypes.string,
    type: PropTypes.string,
  }),
  liabilities: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
    modifier: PropTypes.string,
    type: PropTypes.string,
  }),
  pickUp: PropTypes.shape({
    localtime: PropTypes.string.isRequired,
    localtimeHour: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }).isRequired,
  dropOff: PropTypes.shape({
    localtime: PropTypes.string.isRequired,
    localtimeHour: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    approvalRequired: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    preferred: PropTypes.bool.isRequired,
    outOfPolicy: PropTypes.bool.isRequired,
    grossTotal: PropTypes.string.isRequired,
    marginBreakdown: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        total: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
      }),
    ),
    channel: PropTypes.shape({
      identifier: PropTypes.string.isRequired,
      account: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CarLineItem;
