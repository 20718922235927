import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { ensureCart, fetchCart } from '../../actions/common';

export const ADD_CAR_TO_CART = 'ADD_CAR_TO_CART';
export function addCarToCart(details, callbackParams, justification = null) {
  return dispatch =>
    dispatch(ensureCart(callbackParams)).then(cartId => {
      dispatch({
        type: ADD_CAR_TO_CART,
        searchId: details.searchId,
        details,
        callbackParams,
      });
      return httpClient
        .post(routes.api.carts.items.addCar({ cartId }), {
          result_id: details.id,
          callback_params: callbackParams,
          justification,
        })
        .then(({ data }) => {
          dispatch(fetchCart(cartId, callbackParams));
          return data;
        });
    });
}

export const ADD_CAR = 'ADD_CAR';

export function carAdded(isCarAdded) {
  return {
    type: ADD_CAR,
    isCarAdded,
  };
}
