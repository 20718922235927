import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Tooltip from '@wtag/rcl-tooltip';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import AppContext from '../AppContext';
import MessageList from '../messages/MessageList';
import MessageInput from '../messages/MessageInput';
import TypingIndicator from '../TypingIndicator';
import { AGENCY, AGENCY_NOTIFICATIONS } from './categories';
import ActiveRoomEmptyPlaceholder from './ActiveRoomEmptyPlaceholder';

const SCROLLBAR_NEAR_TO_TOP_ELEMENT = 10;
const MAX_MESSAGES_LIST_LENGTH = 10;

export class ActiveRoom extends Component {
  constructor(props) {
    super(props);

    this.messageListHolderRef = React.createRef();
    this.focusRef = React.createRef();

    this.state = {
      messages: [],
      lastSeenMessageTimestamp: null,
      typers: {},
      isPossibleToFetchMore: true,
      isPossibleToFetchQuotedMessagesUpwards: false,
      repliedMessage: null,
      isLoading: true,
      isLoadingFirstTime: true,
      isFetchingRoomUsers: true,
      users: [],
      roomUsersHash: { customers: {}, agency: {} },
      isPossibleToFetchOnUpwards: true,
      quotedMessages: [],
      enableArrowDownButton: false,
      isFocusingToQuotedMessage: false,
      /*
        This state refers that we have already clicked on a quoteMessage and the backend is fetching the QuotedMessageList
       */
      isScrolledToPreviousMessages: false,
    };

    this.sendChatMessage = this.sendChatMessage.bind(this);
    this.fetchMoreHistory = this.fetchMoreHistory.bind(this);
    this.sendTyping = this.sendTyping.bind(this);
  }

  componentDidMount() {
    this.prepareRoomUsers();
    this.props.context.client.then(client => {
      client.receiveTyping(data => {
        const hashIdOfTyper = `${data.roomId}-${data.typerId}-${Number(data.isCustomer)}`;
        const isSender =
          this.props.context.currentUserId === data.typerId &&
          data.isCustomer === this.props.context.laymanMode;

        if (!isSender && data.roomId === this.props.room.uid) {
          const timerId = setTimeout(() => {
            this.removeTyperFromState(hashIdOfTyper);
          }, 2000);

          this.addTyperToState(hashIdOfTyper, data, timerId);
        }
      });
      client.onMessageReceived(this.messageReceiveCallback);
      this.fetchMoreHistory();
    });
  }

  componentDidUpdate(oldProps, oldState) {
    const isScrolled =
      this.messageListHolderRef.current &&
      this.messageListHolderRef.current.scrollHeight ===
        this.messageListHolderRef.current.offsetHeight;

    const isScrollbarInvisible =
      !this.state.isLoadingFirstTime && !this.state.isFetchingRoomUsers && isScrolled;

    const isMessagesChanged = oldState.messages.length !== this.state.messages.length;
    const agencyMessageReadableOnChatOpen =
      oldProps.context.isChatOpen !== this.props.context.isChatOpen &&
      this.props.context.isChatOpen === true &&
      !this.props.context.laymanMode;

    if (this.props.room !== oldProps.room) {
      this.prepareRoomUsers();
    }

    if (oldProps.room.uid !== this.props.room.uid) {
      this.props.context.client.then(client => {
        client.leaveRoom(oldProps.roomId);
        client.joinRoom(this.props.room.uid).then(() => {
          this.fetchMoreHistory(true);
        });
      });
    } else if (
      oldProps.context.isConnecting !== this.props.context.isConnecting &&
      !this.props.context.isConnecting
    ) {
      this.onConnectionChange();
    } else if (isScrollbarInvisible && isMessagesChanged && this.state.isPossibleToFetchMore) {
      /*
        Currently, we get 10 entities of messages on each api call. But after rendering first ten messages
        if the scrollbar is not visible then we need to fetch more messages to make the scrollbar visible
        otherwise user can not load their messages history and
        also we don't want two api call at a time as we use lastMessagesTimestamp to fetch histories
      */
      this.fetchMoreHistory();
    } else if (agencyMessageReadableOnChatOpen) {
      this.props.onRoomOpen(this.props.room);
    }
  }

  componentWillUnmount() {
    this.props.context.client.then(client => {
      client.disableOnMessageReceived(this.messageReceiveCallback);
    });
  }

  // eslint-disable-next-line react/sort-comp
  prepareRoomUsers = () => {
    this.props.context.client.then(client => {
      this.setState({ isFetchingRoomUsers: true });
      if (!this.props.context.laymanMode) {
        const user = this.props.room.users.find(roomUser => roomUser.isCustomer);
        client.getRoomUsers(this.props.room.uid, user).then(roomUsers => {
          this.restructureRoomUsers(roomUsers);
          this.setState({ users: roomUsers });
        });
      } else {
        this.restructureRoomUsers(this.props.room.users);
      }
    });
  };

  onMessageReceived = message => {
    const isMyMessage =
      message.senderId === this.props.context.currentUserId &&
      message.isCustomer === this.props.context.laymanMode;

    if (message.roomId === this.props.room.uid) {
      const hashIdOfSender = `${message.roomId}-${message.senderId}-${Number(message.isCustomer)}`;

      // Receiving only messages that are sent to me
      if (!isMyMessage) {
        this.updateMessages([...this.state.messages, message], () => {
          const isScrollingToQuotedMessageList = this.state.quotedMessages.length !== 0;

          if (!isScrollingToQuotedMessageList) {
            this.scrollMessageHolderToBottom();
          } else {
            this.removeQuotedMessageList();
          }
        });
      }
      this.removeTyperFromState(hashIdOfSender);
    }
  };

  // eslint-disable-next-line react/sort-comp
  fetchQuotedMessagesListDownwards = () => {
    const lastQuotedMessageTimeStamp = new Date(
      this.state.quotedMessages[this.state.quotedMessages.length - 1].timestamp,
    );
    lastQuotedMessageTimeStamp.setSeconds(lastQuotedMessageTimeStamp.getSeconds() + 1);

    const lastMessageTimeStamp = this.state.messages[0].timestamp;
    /*
        fetching the oldest 10 messages between the latest message of the quotedMessagesList and the oldest message from the previously fetch messagesList
    */
    this.props.context.client.then(client => {
      client
        .getChatHistory(this.props.room.uid, lastMessageTimeStamp, lastQuotedMessageTimeStamp)
        .then(messages => {
          if (messages.length > 0) {
            this.setState(
              prevState => ({
                quotedMessages: [...prevState.quotedMessages, ...messages],
                isLoading: false,
              }),
              () => {
                /*
                    Moving the Scrollbar 2% up from the inputField so that it doesn't make another unnecessary event to fetch messages on downwards direction
                    And multiplying by -1 as we are rendering messageList in reverse order
                */
                this.messageListHolderRef.current.scrollTop =
                  (2 / 100) *
                  (this.messageListHolderRef.current &&
                    this.messageListHolderRef.current.scrollHeight * -1);
              },
            );
          } else {
            /*
               scrolling ended with the latest message that's why we are consolidating these arrays
             */
            this.setState(prevState => ({
              messages: [...prevState.quotedMessages, ...prevState.messages],
              /*
                  Generally quotedMessagesList always holds the oldest messages what we have in frontend. As
                  we are consolidating two arrays and 0th index is holding the most oldest message that's why we are setting
                  the oth index message as lastSeenMessageTimestamp
               */
              lastSeenMessageTimestamp: prevState.quotedMessages[0].timestamp,
              quotedMessages: [],
              isLoading: false,
            }));
          }
        });
    });
  };

  fetchQuotedMessageAssociatedList(quotedMessageTimeStamp = null, callback) {
    const lastMessageTimestamp =
      this.state.quotedMessages.length > 0
        ? this.state.quotedMessages[0].timestamp
        : this.state.lastSeenMessageTimestamp;

    this.props.context.client.then(client => {
      client
        .getChatHistory(this.props.room.uid, lastMessageTimestamp, quotedMessageTimeStamp)
        .then(messages => {
          if (
            this.state.quotedMessages.length === 0 &&
            messages.length % MAX_MESSAGES_LIST_LENGTH !== 0
          ) {
            /*
             The distance between the latest messages and the focused quotedMessage is less than 10 Messages
             that's why we are consolidating these messages
            */
            const quotedMessagesList = [...messages, ...this.state.quotedMessages];
            this.setState(prevState => ({
              messages: [...quotedMessagesList, ...prevState.messages],
              quotedMessages: [],
              lastSeenMessageTimestamp: quotedMessagesList[0].timestamp,
              isPossibleToFetchMore: true,
              isLoading: false,
            }));

            callback(messages, false);
          } else {
            callback(messages, true);
          }
        });
    });
  }

  fetchQuotedMessagesUpwards() {
    this.fetchQuotedMessageAssociatedList(null, (messages, isNeededToInsert) => {
      if (isNeededToInsert) {
        this.setState(prevState => ({
          quotedMessages: [...messages, ...prevState.quotedMessages],
          isPossibleToFetchQuotedMessagesUpwards: messages.length === 10,
          isLoading: false,
        }));
      }
    });
  }

  showOrHideArrowDownButton = () => {
    if (this.state.quotedMessages.length > 0) {
      return;
    }
    /*
      multiplying the scrollTop by -1 as we render messagesList in reversing order
    */
    const isScrolledUpMoreThanVisibleArea =
      this.messageListHolderRef.current.scrollTop * -1 >
      this.messageListHolderRef.current.offsetHeight;

    if (isScrolledUpMoreThanVisibleArea && !this.state.enableArrowDownButton) {
      this.setState({ enableArrowDownButton: true });
    } else if (!isScrolledUpMoreThanVisibleArea && this.state.enableArrowDownButton) {
      this.setState({ enableArrowDownButton: false });
    }
  };

  onScrollFetchHistory = () => {
    const totalScrolledArea =
      this.messageListHolderRef.current.scrollTop * -1 +
      this.messageListHolderRef.current.offsetHeight;

    const distanceBetweenTopAndScrolledArea =
      this.messageListHolderRef.current &&
      this.messageListHolderRef.current.scrollHeight - totalScrolledArea;

    this.showOrHideArrowDownButton();

    if (
      distanceBetweenTopAndScrolledArea > SCROLLBAR_NEAR_TO_TOP_ELEMENT &&
      this.state.isScrolledToPreviousMessages
    ) {
      /*
        Otherwise on clicking the arrowDown Icon, an event will be fired to fetch latest messages
        as the scrollbar in Top direction.
      */
      this.setState({ isScrolledToPreviousMessages: false });
    }

    if (
      this.state.isLoading ||
      this.state.isFocusingToQuotedMessage ||
      this.state.isScrolledToPreviousMessages
    ) {
      return;
    }

    const isPossibleToFetchUpwards =
      distanceBetweenTopAndScrolledArea < SCROLLBAR_NEAR_TO_TOP_ELEMENT &&
      this.state.isPossibleToFetchMore;

    if (
      totalScrolledArea - this.messageListHolderRef.current.offsetHeight <
        SCROLLBAR_NEAR_TO_TOP_ELEMENT &&
      this.state.quotedMessages.length > 0
    ) {
      /*
        The scrollbar is near to the bottom and we are scrolling on the quotedMessageList
       */
      this.setState({ isLoading: true });
      this.fetchQuotedMessagesListDownwards();
    } else if (
      this.state.quotedMessages.length > 0 &&
      distanceBetweenTopAndScrolledArea < SCROLLBAR_NEAR_TO_TOP_ELEMENT &&
      this.state.isPossibleToFetchQuotedMessagesUpwards
    ) {
      /*
        The scrollbar is near to the top and we are scrolling on the quotedMessageList
       */
      this.setState({ isLoading: true });
      this.fetchQuotedMessagesUpwards();
    } else if (this.state.quotedMessages.length === 0 && isPossibleToFetchUpwards) {
      this.setState({ isLoading: true });
      this.fetchMoreHistory();
    }
  };

  onClose = () => {
    this.setState({ repliedMessage: null });
  };

  // eslint-disable-next-line react/sort-comp
  sendTyping() {
    this.props.context.client.then(client => client.sendTyping(this.props.room.uid));
  }

  sendChatMessage(messagePayload) {
    this.props.context.client.then(client => {
      client.sendMessage(this.props.room.uid, messagePayload, status => {
        const currentMessagesLength = this.state.messages.length;

        // search for newMessage by id from the approximate index of newMessage to the last index of the message list
        for (let index = messagePayload.messageIndex; index < currentMessagesLength; index += 1) {
          if (this.state.messages[index].id === messagePayload.id) {
            this.setState(
              prevState => {
                const newMessageList = [...prevState.messages];

                // Adding my message to the state
                newMessageList[index] = {
                  ...newMessageList[index],
                  id: status.messageId,
                  isFailedToSend: !status.sent,
                  isSending: false,
                  errors: status.errors,
                };

                return {
                  messages: newMessageList,
                };
              },
              () => {
                const isScrollingToQuotedMessageList = this.state.quotedMessages.length !== 0;

                if (isScrollingToQuotedMessageList) {
                  this.removeQuotedMessageList();
                } else {
                  this.scrollMessageHolderToBottom();
                }
              },
            );

            break;
          }
        }
      });
    });
  }

  fetchMoreHistory(roomChanged = false) {
    const lastSeenTimestamp = roomChanged ? null : this.state.lastSeenMessageTimestamp;
    this.props.context.client.then(client => {
      client.getChatHistory(this.props.room.uid, lastSeenTimestamp).then(messages => {
        const newMessageList = roomChanged ? messages : [...messages, ...this.state.messages];
        const lastSeenMessageTimestamp = (newMessageList[0] && newMessageList[0].timestamp) || null;
        const typingObject = roomChanged ? { userTyping: false } : {};
        this.setState({
          isLoadingFirstTime: false,
          isLoading: false,
          messages: newMessageList,
          lastSeenMessageTimestamp,
          isPossibleToFetchMore: newMessageList.length !== this.state.messages.length,
          ...typingObject,
        });
      });
    });
  }

  onConnectionChange = () => {
    // on client changes we need to make sure, the fetching more histories is possible
    this.setState({ isLoading: false });

    if (!this.state.messages.length) {
      // at the time of disconnection if the user already clicked on a room, we need to make sure it's fetching initial messages on reconnecting
      this.fetchMoreHistory();
    }
  };

  removeTyperFromState = typerHashId => {
    if (this.state.typers[typerHashId]) {
      clearTimeout(this.state.typers[typerHashId].timerId);
    }

    this.setState(prevState => {
      const newState = { ...prevState };

      delete newState.typers[typerHashId];

      return newState;
    });
  };

  addTyperToState = (typerHashId, typerInfo, timerId) => {
    if (this.state.typers[typerHashId]) {
      clearTimeout(this.state.typers[typerHashId].timerId);
    }

    this.setState(prevState => {
      const newState = { ...prevState };

      newState.typers[typerHashId] = {
        id: typerInfo.typerId,
        roomId: typerInfo.roomId,
        isCustomer: typerInfo.isCustomer,
        timerId,
      };

      return newState;
    });
  };

  restructureRoomUsers = users => {
    const roomUsers = { customers: {}, agency: {} };
    users.forEach(user => {
      if (user.isCustomer) {
        roomUsers.customers[user.id] = user;
      } else {
        roomUsers.agency[user.id] = user;
      }
    });
    this.setState({ roomUsersHash: roomUsers, isFetchingRoomUsers: false });
  };

  messageReceiveCallback = message => {
    this.onMessageReceived(message);
    if (message.roomId !== this.props.room.uid || !this.props.context.isChatOpen) {
      return;
    }
    this.props.context.client.then(client => {
      client.removeMentionInRoom(
        message.roomId,
        this.props.context.currentUserId,
        message.id,
        () => {},
      );
      client.removeUnseenInRoom(
        message.roomId,
        this.props.context.currentUserId,
        message.id,
        () => {},
      );
    });
  };

  updateMessages = (messages, callback) => {
    this.setState({ messages }, callback);
  };

  hideTitle = laymanMode => {
    if (laymanMode) {
      return this.props.room.type === AGENCY;
    }

    return this.props.room.type === AGENCY_NOTIFICATIONS;
  };

  roomAvatar = (customerImage, isUserOnline) => {
    if (this.props.context.laymanMode && this.props.room.type === AGENCY) return null;

    return (
      <div className={classnames('chat__avatar', { 'chat__avatar--online': isUserOnline })}>
        <img className="chat__avatar-image" src={customerImage} alt="Avatar" />
      </div>
    );
  };

  replyHandler = repliedMessage => {
    this.setState({ repliedMessage });
  };

  roomUsers = () => {
    if (this.props.context.laymanMode) {
      return this.props.room.users;
    }

    return this.state.users;
  };

  scrollMessageHolderToBottom = () => {
    if (!this.messageListHolderRef.current) {
      return;
    }
    this.messageListHolderRef.current.scrollTop = this.messageListHolderRef.current.scrollHeight;
  };

  removeQuotedMessageList = () => {
    if (this.state.quotedMessages.length > 0) {
      this.setState(
        {
          quotedMessages: [],
          isScrolledToPreviousMessages: true,
        },
        () => {
          this.scrollMessageHolderToBottom();
        },
      );
      return;
    }
    this.scrollMessageHolderToBottom();
  };

  disableFocusingQuotedMessage = () => {
    this.setState({ isFocusingToQuotedMessage: false });
  };

  OnClickFetchQuotedMessageAssociatedList = quotedMessage => {
    this.focusRef.current.scrollIntoView();
    this.fetchQuotedMessageAssociatedList(quotedMessage.timestamp, (messages, isNeededToInsert) => {
      if (isNeededToInsert) {
        this.setState({
          quotedMessages: [...messages],
          isPossibleToFetchQuotedMessagesUpwards: messages.length === 10,
          isLoading: false,
        });
      }
    });
  };

  focusToQuotedMessage = quotedMessage => {
    this.setState({ isFocusingToQuotedMessage: true, quotedMessages: [] }, () => {
      this.OnClickFetchQuotedMessageAssociatedList(quotedMessage);
    });
  };

  addMyMessage = (newMessage, callback) => {
    const messagesAmountBeforeSaving = this.state.messages.length;

    this.setState({ messages: [...this.state.messages, { ...newMessage }] }, () => {
      const newMessageIndex =
        messagesAmountBeforeSaving > 0
          ? messagesAmountBeforeSaving - 1
          : messagesAmountBeforeSaving;

      // sending the approximate index of newMessage
      callback(newMessageIndex);
    });
  };

  render() {
    const { laymanMode } = this.props.context;
    const isUserOnline = this.props.isUserOnline;

    const roomUsers = this.roomUsers();
    const roomBasicInformation = this.props.context.roomBasicInformation(this.props.room);

    const hideTitle = this.hideTitle(laymanMode);

    const firstTyperInfo = Object.values(this.state.typers)[0];
    const typerCount = Object.keys(this.state.typers).length;

    const messageInput = (
      <MessageInput
        room={this.props.room}
        roomUsers={roomUsers}
        sendTyping={this.sendTyping}
        sendChatMessage={this.sendChatMessage}
        readOnly={!this.props.context.connected}
        laymanMode={this.props.context.laymanMode}
        repliedMessage={this.state.repliedMessage}
        addNewMessage={this.addMyMessage}
        onClose={this.onClose}
      />
    );

    return (
      <div className="chat-panel">
        {!hideTitle && (
          <div className="chat-panel__header">
            <Avatar
              firstName={roomBasicInformation.firstName}
              lastName={roomBasicInformation.lastName}
              showName={false}
              src={roomBasicInformation.avatarUrl}
              size="small"
            />
            <div className="col-grid col-bleed direction-row justify-space-between">
              <div className="chat__name-holder">
                <div className="chat__name">{this.props.room.title}</div>
                <span className="chat__activity">
                  {isUserOnline ? I18n.t('chat.online') : I18n.t('chat.offline')}
                </span>
              </div>
              <IconButton
                className="chat__close-icon"
                isIconOnly={true}
                onClick={this.props.toggleChat}
                icon={<Icon name="close" />}
              />
            </div>
          </div>
        )}
        <div className="chat__messages">
          {(this.state.quotedMessages.length > 0 || this.state.enableArrowDownButton) && (
            <div className="chat__messages-reset">
              <IconButton
                isIconOnly={true}
                onClick={this.removeQuotedMessageList}
                icon={<Icon name="arrowDown" showBGColor={true} color="tertiary" />}
              />
            </div>
          )}
          {this.state.isLoadingFirstTime || this.state.isFetchingRoomUsers ? (
            <div className="chat__messages-loader">
              <Spinner size="huge" bgColor="neutral" />
            </div>
          ) : (
            <div
              className="chat-message__holder"
              ref={this.messageListHolderRef}
              onScroll={this.onScrollFetchHistory}
            >
              {this.state.quotedMessages.length > 0 && this.state.isLoading && (
                <div className="chat__messages-loader">
                  <Spinner size="tiny" bgColor="neutral" />
                </div>
              )}
              {typerCount > 0 && (
                <TypingIndicator
                  context={this.props.context}
                  roomUsers={this.state.roomUsersHash}
                  typerInfo={firstTyperInfo}
                  typerCount={typerCount}
                  roomUid={this.props.room.uid}
                />
              )}
              {this.state.messages.length > 0 ? (
                <MessageList
                  messages={
                    this.state.quotedMessages.length > 0
                      ? this.state.quotedMessages
                      : this.state.messages
                  }
                  tenant={this.props.context.tenant}
                  roomUsers={this.state.roomUsersHash}
                  fetchMoreHistory={this.fetchMoreHistory}
                  currentUserId={this.props.context.currentUserId}
                  replyHandler={this.replyHandler}
                  focusToQuotedMessage={this.focusToQuotedMessage}
                  disableFocusingQuotedMessage={this.disableFocusingQuotedMessage}
                  sendChatMessage={this.sendChatMessage}
                />
              ) : (
                <ActiveRoomEmptyPlaceholder
                  type={this.props.room.type}
                  title={this.props.room.title}
                  laymanMode={this.props.context.laymanMode}
                />
              )}

              {(this.state.isLoading ||
                (this.state.quotedMessages.length > 0 &&
                  this.state.isPossibleToFetchQuotedMessagesUpwards)) && (
                <div className="chat__messages-loader">
                  <Spinner size="tiny" bgColor="neutral" />
                </div>
              )}
              <div ref={this.focusRef} />
            </div>
          )}
        </div>

        {!this.state.isFetchingRoomUsers && this.props.context.isAlien && (
          <div className="chat-message__with-tooltip">
            <Tooltip
              content={I18n.t('admin.chat.textarea_disabled')}
              position="top"
              showArrow={false}
              type="dark"
            >
              <div className="chat-editor">{messageInput}</div>
            </Tooltip>
          </div>
        )}

        {!this.state.isFetchingRoomUsers && !this.props.context.isAlien && (
          <div className="chat-editor">{messageInput}</div>
        )}
      </div>
    );
  }
}

ActiveRoom.defaultProps = {
  currentUserId: null,
  toggleChat: () => {},
  onRoomOpen: () => {},
};

ActiveRoom.propTypes = {
  context: PropTypes.shape().isRequired,
  isUserOnline: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number,
  room: PropTypes.shape().isRequired,
  toggleChat: PropTypes.func,
  onRoomOpen: PropTypes.func,
};

const RoomWithContext = props => (
  <AppContext.Consumer>
    {context => <ActiveRoom context={context} {...props} />}
  </AppContext.Consumer>
);

export default RoomWithContext;
