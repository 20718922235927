import React from 'react';
import PropTypes from 'prop-types';
import Category from './Category';

const CarType = ({ category, acrissCode }) => (
  <span className="d-flex wrap row-gap-4 column-gap-4 car__category">
    <Category category={category} />
    <span>({acrissCode})</span>
  </span>
);

CarType.propTypes = {
  category: PropTypes.string.isRequired,
  acrissCode: PropTypes.string.isRequired,
};

export default CarType;
