const findLabelValue = (options, value) => {
  const selectedOption = options.find(option => option.value === value);
  if (selectedOption) {
    return selectedOption;
  }

  return null;
};

export default findLabelValue;
