import moment from 'moment';

const getHotelTimings = hotels => {
  const mergedHotels = hotels.reduce((acc, { hotelName, checkIn, checkOut }) => {
    if (!acc[hotelName]) {
      acc[hotelName] = {};
    }

    if (checkIn) {
      acc[hotelName].checkIn = checkIn;
    }

    if (checkOut) {
      acc[hotelName].checkOut = checkOut;
    }

    return acc;
  }, {});

  const hotelTimings = Object.values(mergedHotels).map(({ checkIn, checkOut }) => ({
    checkIn: moment(checkIn),
    checkOut: moment(checkOut),
  }));

  const compareByTime = (a, b) => a.checkIn.diff(b.checkIn);

  hotelTimings.sort(compareByTime);

  return hotelTimings;
};

export default getHotelTimings;
