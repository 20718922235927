import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import DateTime from 'sharedWebpack/DateTime';
import productIcon from '../../../shared/ProductIcon';

const PayLaterItem = ({ item }) => (
  <div className="item-info">
    {productIcon({ productType: item.type, showBGColor: true, size: 'tiny' })}
    <div className="item-info__airports">
      <div className="item-info__airports__details">{item.itemTitle}</div>
      <div className="item-info__airports__text">
        <I18nText id="ibe.booking.pay_later.quote_create" returnStringOnly={true} />
      </div>
    </div>

    <div className="item-info__trip-details">{item.journeySummaryHuman}</div>
    <div className="item-info__trip-details">
      <DateTime dateTime={item.startsAt} format="short" />
      <div className="item-info__trip-details__divider"> - </div>
      <DateTime dateTime={item.endsAt} format="short" />
    </div>
  </div>
);

PayLaterItem.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    itemTitle: PropTypes.string.isRequired,
    journeySummaryHuman: PropTypes.string.isRequired,
    startsAt: PropTypes.string.isRequired,
    endsAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default PayLaterItem;
