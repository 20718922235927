/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import HumanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import ProductIcon from 'sharedWebpack/ProductIcon';
import DateTime from 'sharedWebpack/DateTime';
import Card from '@wtag/rcl-card';
import Avatar from '@wtag/rcl-avatar';
import StackedItems from '@wtag/rcl-stacked-items';
import formatAddress from '../formatAddress';
import '../styles.scss';

const Event = ({ data }) => {
  const [isTravellerPopoverVisible, setIsTravellerPopoverVisible] = useState(false);

  return (
    <Fragment>
      <div className="col-12 col-sm-3 col-xlg-2 travel-plan__timeline-item-section-product">
        <div className="travel-plan__timeline-item-section-product-info">
          <div className="travel-plan__timeline-item-section-product-icon">
            <ProductIcon productType={data.type} showBGColor={true} size="medium" />
          </div>
          <div className="travel-plan__timeline-item-section-product-name">
            {I18n.t('admin.components.orders.overview.travel_plan.event.title')}
          </div>
          <div className="travel-plan__timeline-item-section-product-duration">
            {HumanReadableDuration(data.durationInMinutes)}
          </div>
          <div className="travel-plan__timeline-item-section-product-travellers">
            {data.travelers && data.travelers.length > 0 && (
              <StackedItems
                itemSize="tiny"
                isPopoverVisible={isTravellerPopoverVisible}
                onClick={() =>
                  setIsTravellerPopoverVisible(
                    prevTravellerPopoverState => !prevTravellerPopoverState,
                  )
                }
                onOutsideClick={() => setIsTravellerPopoverVisible(false)}
              >
                {data.travelers.map(traveler => (
                  <Avatar
                    firstName={traveler.firstName}
                    lastName={traveler.lastName}
                    src={traveler.avatarUrl}
                  />
                ))}
              </StackedItems>
            )}
          </div>
        </div>
        <div className="travel-plan__timeline-item-indicator">
          <div className="travel-plan__timeline-item-indicator-line" />
        </div>
      </div>
      <div className="col-12 col-sm-9 col-xlg-10">
        <div className="grid grid-gap-20">
          <div className="col-12">
            <Card className="travel-plan__timeline-item-section-card" version="v2">
              <div className="travel-plan__timeline-item-section-card-header">
                {data && data.name}
              </div>
              <div className="travel-plan__section-divider travel-plan__timeline-item-section-divider" />
              <div className="travel-plan__timeline-item-section-card-body">
                <div className="travel-plan__timeline-item-section-card-body-travel-info">
                  <div className="travel-plan__timeline-item-section-card-body-travel-time">
                    {data.startTime && (
                      <DateTime dateTime={data.startTime} format="shortWithTime" />
                    )}
                    {data.endTime && <DateTime dateTime={data.endTime} format="shortWithTime" />}
                  </div>
                  {data.startTime && (
                    <div className="travel-plan__timeline-item-section-card-body--muted-text">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.event.header.start.label',
                      )}
                    </div>
                  )}
                  {data.endTime && (
                    <div className="travel-plan__timeline-item-section-card-body--muted-text">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.event.header.end.label',
                      )}
                    </div>
                  )}
                  {data.startTime && (
                    <div className="travel-plan__timeline-item-section-card-body-travel-address">
                      {formatAddress(
                        `${data.address.street},${data.address.city},${data.address.country}`,
                      )}
                    </div>
                  )}
                  {data.endTime && (
                    <div className="travel-plan__timeline-item-section-card-body-travel-address">
                      {formatAddress(
                        `${data.address.street},${data.address.city},${data.address.country}`,
                      )}
                    </div>
                  )}
                </div>
                <div className="travel-plan__timeline-item-section-card-body-image">
                  {/* Event Image */}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Event.propTypes = {
  data: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    type: PropTypes.string,
    durationInMinutes: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
    }),
    travelers: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  }).isRequired,
};

export default Event;
