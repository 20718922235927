import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import '../../style.scss';

export default function FilterTags({ onRemoveFilter, currentFilters }) {
  const [showAllTags, setShowAllTags] = useState(false);

  const handleFilterData = (res, obj, key) => {
    res[key] = obj[key];
    return res;
  };

  Object.filter = (obj, predicate) =>
    Object.keys(obj)
      .filter(key => predicate(obj[key]))
      .reduce((res, key) => handleFilterData(res, obj, key), {});

  const selectedFilters = Object.filter(currentFilters, currentFilter => currentFilter !== null);
  const taggedFilters = Object.keys(selectedFilters);

  const showHideTags = () => {
    setShowAllTags(!showAllTags);
  };

  return (
    <div>
      {taggedFilters.length > 0 && (
        <div className="flight-results__active">
          <div className="flight-results__active--heading">
            {I18n.t('components.ibe.search_results.filters.active_filters')}
          </div>
          <span className="flight-result__active-tag">
            {!showAllTags
              ? taggedFilters.slice(0, 2).map(tag => (
                  <span className="flight-results__tag" key={tag}>
                    <span>
                      {selectedFilters[tag].length > 0 &&
                      typeof selectedFilters[tag] === 'object' ? (
                        <Fragment>{`${tag} : ${selectedFilters[tag].join(', ')}`}</Fragment>
                      ) : (
                        selectedFilters[tag]
                      )}
                    </span>
                    <IconButton
                      icon={<Icon name="close" size="tiny" />}
                      isIconOnly={true}
                      onClick={() => onRemoveFilter(tag)}
                    />
                  </span>
                ))
              : taggedFilters.map(tag => (
                  <span className="flight-results__tag" key={tag}>
                    <span>
                      {selectedFilters[tag].length > 0 &&
                      typeof selectedFilters[tag] === 'object' ? (
                        <Fragment>{`${tag}: ${selectedFilters[tag].join(', ')}`}</Fragment>
                      ) : (
                        selectedFilters[tag]
                      )}
                    </span>
                    <IconButton
                      icon={<Icon name="close" size="tiny" />}
                      isIconOnly={true}
                      onClick={() => onRemoveFilter(tag)}
                    />
                  </span>
                ))}
            {taggedFilters.length > 2 && (
              <span
                role="presentation"
                onClick={showHideTags}
                className="flight-results__show-hide"
              >
                {!showAllTags
                  ? I18n.t('components.ibe.search_results.filters.tagged_items', {
                      items: taggedFilters.length - 2,
                    })
                  : I18n.t('components.ibe.search_results.filters.hide_tags')}
              </span>
            )}
          </span>
          <div className="flight-results__horizontal-line--active-filter">
            <hr />
          </div>
        </div>
      )}
    </div>
  );
}

FilterTags.defaultProps = {};

FilterTags.propTypes = {
  onRemoveFilter: PropTypes.func.isRequired,
  currentFilters: PropTypes.shape().isRequired,
};
