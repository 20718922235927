import PropTypes from 'prop-types';

const requiredFieldsShape = {
  city: PropTypes.bool,
  code: PropTypes.bool,
  country: PropTypes.bool,
  expiryDate: PropTypes.bool,
  name: PropTypes.bool,
  street: PropTypes.bool,
  threeDsAuthenticationRequired: PropTypes.bool,
  type: PropTypes.bool,
  zip: PropTypes.bool,
};

const supportedCardItemShape = {
  amount: PropTypes.string,
  cardName: PropTypes.string,
  cardType: PropTypes.string,
  converted: PropTypes.bool,
  currency: PropTypes.string,
  paymentType: PropTypes.string,
};

const creditCardShape = {
  requiredFields: PropTypes.shape(requiredFieldsShape).isRequired,
  supported: PropTypes.bool.isRequired,
  supportedCards: PropTypes.arrayOf(supportedCardItemShape).isRequired,
};

export default creditCardShape;
