import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from '@wtag/rcl-card';
import { I18nText } from '@wtag/react-comp-lib';
import HotelDefaultImage from 'agentSyncedImages/fallback_images/product/product_xxl_hotel.png';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import { stringifyQueryString } from '../../../helpers/qsMethods';
import ChannelListTag from '../../../helpers/ChannelListTag';
import HotelBookingStatus from '../HotelBookingStatus';
import HotelPriceMenu from '../HotelPriceMenu';
import Rating from '../Rating';
import resultShape from '../shapes/result';
import Stars from '../../../components/Stars';
import getHotelAddress from '../helpers/getHotelAddress';

const HotelStandardView = props => {
  const {
    address,
    deal,
    dealGroup,
    id,
    name,
    picture,
    rating,
    ratingSource,
    selectedCartItemResultIds,
    source = {},
    stars,
    laymanMode,
  } = props;

  const link = {
    pathname: `/hotels/details/${id}`,
    search: stringifyQueryString({ deal, dealGroup }),
  };

  const { supplier, account } = source;
  const hotelAddress = getHotelAddress(address);
  const supplierName = I18n.t(supplier, { scope: 'channels' });
  const supplierChannels = [`${supplierName} :  ${account}`];
  const alreadySelectedInCart = selectedCartItemResultIds.includes(id);

  const buttonText = () => {
    if (alreadySelectedInCart) {
      return <I18nText id="ibe.results.already_selected" />;
    }

    return <I18nText id="ibe.search_results.hotel.select_room" />;
  };

  const setPlaceholderImage = event => {
    const target = event.target;
    target.src = HotelDefaultImage;
  };

  return (
    <Card version="v2" className="hotel-result" size="full">
      <div className="col-12 col-bleed hotel-result__wrapper align-center">
        <div className="hotel-result__top-wrapper">
          <div className="hotel-result__image-wrapper">
            <img
              className="hotel-result__image"
              onError={setPlaceholderImage}
              src={picture || HotelDefaultImage}
              alt="Hotel"
            />
          </div>
          <div className="hotel-result__middle">
            <div className="d-flex flex-wrap hotel-result__middle-wrapper">
              <div className="col-lg-6 col-md-6 col-grid hotel-result__middle-container justify-space-between col-bleed">
                <div className="col-grid col-bleed direction-row hotel-result__middle-name align-start wrap">
                  <div className="col-grid direction-row col-12 col-bleed wrap align-left">
                    <div className="col-12 col-bleed">{name}</div>
                  </div>
                  <Stars wrapperClassName="col-12 col-bleed" showStarsNumber={true} stars={stars} />
                  {hotelAddress && (
                    <div className="col-12 col-grid col-bleed direction-row hotel-result__middle-subtitle">
                      <Icon name="location" size="small" />
                      <span>{hotelAddress}</span>
                    </div>
                  )}
                  {rating && (
                    <div className="hotel-result__middle-heading">
                      <Rating
                        rating={rating}
                        className="hotel-result__ratings"
                        source={ratingSource}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-grid col-bleed hotel-result__details">
                <div className="col-xlg-5 col-lg-6 col-md-5 col-sm-2 col-bleed hotel-result__button">
                  <Link to={link}>
                    <Button
                      size="small"
                      version="v2"
                      label={buttonText()}
                      primary={true}
                      fullWidth={false}
                      disabled={alreadySelectedInCart}
                    />
                  </Link>
                </div>
                <div className="col-12 col-grid col-bleed hotel-result__info-container">
                  <div className="item__pricing">
                    <HotelPriceMenu {...props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HotelBookingStatus statusInformation={props} laymanMode={laymanMode} />
        {!laymanMode && (
          <div className="d-flex justify-end hotel-result__supplier">
            <ChannelListTag channels={supplierChannels} type="accent" />
          </div>
        )}
      </div>
    </Card>
  );
};

HotelStandardView.defaultProps = {
  dealGroup: null,
  deal: null,
};

HotelStandardView.propTypes = {
  ...resultShape,
  dealGroup: PropTypes.string,
  deal: PropTypes.string,
  laymanMode: PropTypes.bool.isRequired,
};

export default HotelStandardView;
