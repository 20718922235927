/* eslint-disable */
import AeroCRS from '-!svg-react-loader!./supplierLogos/aeroCRS.svg';
import Aerticket from '-!svg-react-loader!./supplierLogos/aerticket.svg';
import Amadeus from '-!svg-react-loader!./supplierLogos/amadeus.svg';
import AmericanAirlines from '-!svg-react-loader!./supplierLogos/americanAirlines.svg';
import Apollo from '-!svg-react-loader!./supplierLogos/apollo.svg';
import AutoEurope from '-!svg-react-loader!../car/supplierLogos/autoEurope.svg';
import BritishAir from '-!svg-react-loader!./supplierLogos/britishAir.svg';
import Duffel from '-!svg-react-loader!./supplierLogos/duffel.svg';
import Emirates from '-!svg-react-loader!./supplierLogos/emirates.svg';
import FirstCarRental from '-!svg-react-loader!../car/supplierLogos/firstCarRental.svg';;
import FlySafari from '-!svg-react-loader!./supplierLogos/flySafari.svg';
import Galileo from '-!svg-react-loader!./supplierLogos/galileo.svg';
import Lufthansa from '-!svg-react-loader!./supplierLogos/lufthansa.svg';
import Mango from '-!svg-react-loader!./supplierLogos/mango.svg';
import Pyton from '-!svg-react-loader!./supplierLogos/pyton.svg';
import Sabre from '-!svg-react-loader!./supplierLogos/sabre.svg';
import SingapoorAirlines from '-!svg-react-loader!./supplierLogos/singapoorAirlines.svg';
import Travelfusion from '-!svg-react-loader!./supplierLogos/travelfusion.svg';
import UnitedAirlines from '-!svg-react-loader!./supplierLogos/unitedAirlines.svg';
import Welltravel from '-!svg-react-loader!./supplierLogos/welltravel.svg';
import Worldspan from '-!svg-react-loader!./supplierLogos/worldspan.svg';

const suppliers = {
  amadeus: {
    logo: Amadeus,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: true,
    supportVoidTicket: true,
    supportRefundTicket: true,
    supportChangeBooking: true,
  },
  galileo: {
    logo: Galileo,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: true,
    supportVoidTicket: true,
    supportRefundTicket: false,
    supportChangeBooking: false,
  },
  sabre: {
    logo: Sabre,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: true,
    supportVoidTicket: true,
    supportRefundTicket: false,
    supportChangeBooking: false,
  },
  lh_group: {
    logo: Lufthansa,
    supportInstantTicket: true,
    supportManageBooking: true,
    supportNdcBooking: true,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: false,
    supportVoidTicket: true,
    supportRefundTicket: true,
    supportChangeBooking: true,
  },
  british_airways: {
    logo: BritishAir,
    supportInstantTicket: true,
    supportManageBooking: true,
    supportNdcBooking: true,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: false,
    supportVoidTicket: true,
    supportRefundTicket: true,
    supportChangeBooking: true,
  },
  american_airlines: {
    logo: AmericanAirlines,
    supportManageBooking: true,
    supportNdcBooking: true,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: false,
    supportVoidTicket: true,
    supportRefundTicket: true,
    supportChangeBooking: true,
  },
  pyton: {
    logo: Pyton,
    supportInstantTicket: true,
    supportManageBooking: false,
    supportNdcBooking: false,
    supportCreateQuote: false,
    supportFlexibleCancelation: false,
    supportIssueTicket: false,
    supportVoidTicket: false,
    supportRefundTicket: false,
    supportChangeBooking: false,
  },
  aero_crs: {
    logo: AeroCRS,
    supportInstantTicket: true,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: false,
    supportFlexibleCancelation: true,
    supportIssueTicket: true,
    supportVoidTicket: false,
    supportRefundTicket: false,
    supportChangeBooking: false,
  },
  singapore_airlines: {
    logo: SingapoorAirlines,
    supportManageBooking: true,
    supportNdcBooking: true,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: false,
    supportVoidTicket: true,
    supportRefundTicket: true,
    supportChangeBooking: true,
  },
  fly_safair: {
    logo: FlySafari,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: true,
    supportFlexibleCancelation: false,
    supportIssueTicket: false,
    supportVoidTicket: false,
    supportRefundTicket: false,
    supportChangeBooking: false,
  },
  mango: {
    logo: Mango,
    supportInstantTicket: true,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: false,
    supportVoidTicket: false,
    supportRefundTicket: false,
    supportChangeBooking: false,
  },
  travelfusion: {
    logo: Travelfusion,
    supportFlexibleCancelation: false,
    supportIssueTicket: false,
    supportVoidTicket: false,
    supportRefundTicket: false,
    supportChangeBooking: false,
    supportInstantTicket: true,
    supportFlightBooking: true,
    supportAncillaryServices: true,
  },
  united_airlines: {
    logo: UnitedAirlines,
    supportManageBooking: true,
    supportNdcBooking: true,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: false,
    supportVoidTicket: true,
    supportRefundTicket: true,
    supportChangeBooking: true,
  },
  aer_ticket: {
    logo: Aerticket,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: true,
    supportVoidTicket: true,
    supportRefundTicket: false,
    supportChangeBooking: false,
  },
  duffel: {
    logo: Duffel,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: false,
    supportVoidTicket: false,
    supportRefundTicket: true,
    supportChangeBooking: false,
  },
  emirates: {
    logo: Emirates,
    supportManageBooking: true,
    supportNdcBooking: true,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: false,
    supportVoidTicket: true,
    supportRefundTicket: true,
    supportChangeBooking: true,
  },
  welltravel: {
    logo: Welltravel,
    supportInstantTicket: true,
    supportCreateQuote: false,
    supportFlexibleCancelation: false,
    supportIssueTicket: false,
    supportVoidTicket: false,
    supportRefundTicket: false,
    supportChangeBooking: false,
    supportAncillaryServices: false,
    supportFlightBooking: true,
  },
  worldspan: {
    logo: Worldspan,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: true,
    supportVoidTicket: true,
    supportRefundTicket: true,
    supportChangeBooking: false,
  },
  apollo: {
    logo: Apollo,
    supportManageBooking: true,
    supportNdcBooking: false,
    supportCreateQuote: true,
    supportFlexibleCancelation: true,
    supportIssueTicket: true,
    supportVoidTicket: true,
    supportRefundTicket: true,
    supportChangeBooking: false,
  },
  first_car_rental: {
    logo: FirstCarRental,
  },
  auto_europe: {
    logo: AutoEurope,
  },
};

export default suppliers;
