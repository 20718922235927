/* eslint-disable max-len */
/* eslint-disable no-multi-spaces */
/* eslint-disable key-spacing */

const documentTypeLabelValuePair = id => ({
  value: id,
  label: I18n.t(id, {
    scope: 'components.ibe.traveler_details.identification_document_types',
  }),
});

export default function documentTypesBuilder() {
  return [
    documentTypeLabelValuePair('passport'),
    documentTypeLabelValuePair('identity_card'),
    documentTypeLabelValuePair('permanent_resident_card'),
    documentTypeLabelValuePair('alien_resident_card'),
  ];
}
