import { injectIntl } from 'react-intl';
import { reduxForm, reset } from 'redux-form';

import FriendsShare from './FriendsShare';
import {
  updateConfirmationMailRecipients,
  searchConfirmationMailRecipient,
} from '../../actions/common';
import { validate, duplicateValidation } from '../../lib/validations';

const mapConfirmationMailRecipient = recipients => {
  const mappedRecipient = [];
  if (recipients && recipients.length > 0) {
    recipients.forEach(recipient => {
      if (recipient && recipient.email) {
        mappedRecipient.push({
          name: `${recipient.firstName} ${recipient.lastName}`,
          email: recipient.email,
        });
      }
      return mappedRecipient;
    });
  }
  return mappedRecipient;
};

const validateFields = (values, list) => {
  let recipients = list.map(item => item.email);
  let recipientSearch;
  if (values.recipientSearch && values.recipientSearch.value) {
    recipientSearch = JSON.parse(values.recipientSearch.value).email;
    recipients = recipients.concat(recipientSearch);
  }

  if (values.email) {
    recipients = recipients.concat(values.email);
  }
  const message = I18n.t('components.ibe.validations.duplicate_email');

  const constraints = {
    recipientSearch: { ...duplicateValidation(recipientSearch, recipients, message) },
  };
  return {
    ...validate(values, constraints),
  };
};

const validateAllFields = list => params => validateFields(params, list);

const reduxFormConfig = {
  form: 'friendsShareForm',
  fields: ['recipientSearch'],
};

const mapStateToProps = state => ({
    recipientSearchResults: mapConfirmationMailRecipient(
      state.common.confirmationMail.recipientSearchResults,
    ),
    recipients: state.common.confirmationMail.recipients,
    validate: validateAllFields(state.common.confirmationMail.recipients),
  });

const mapDispatchToProps = dispatch => ({
  updateConfirmationMailRecipients: recipients =>
    dispatch(updateConfirmationMailRecipients(recipients)),
  confirmationMailRecipientSearch: query => dispatch(searchConfirmationMailRecipient(query)),
  resetForm: form => dispatch(reset(form)),
});

const ReduxForm = reduxForm(reduxFormConfig, mapStateToProps, mapDispatchToProps)(FriendsShare);
export default injectIntl(ReduxForm);
