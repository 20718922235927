import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import TravelerInformationCard from './singleTravelerinformations/TravelerInformationCard';
import TravelerHeader from './singleTravelerinformations/TravelerHeader';
import TravelInformationCard from './singleTravelerinformations/TravelInformationCard';
import TravelerInformation from './singleTravelerinformations/formFields/TravelerInformation';
import TravelInformation from './singleTravelerinformations/formFields/TravelInformation';
import SearchTravelerForm from './singleTravelerinformations/SearchTravelerForm';
import namePrefixBuilder from '../Search/flight/helpers/namePrefixBuilder';

class SingleTravelerInformations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newTravelerFormPage: 'search',
      singleTravelerShowPage: 'show',
      isGuestTraveler:
        !!(this.props.selectedCustomer && this.props.selectedCustomer.guestUser) || false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCustomer && this.props.selectedCustomer !== prevProps.selectedCustomer) {
      if (!this.props.selectedCustomer.frequentFlyerNumber) {
        this.autoSelectFrequentFlyerNumber();
      }
    }

    if (!this.props.selectedCustomer && this.props.index === 0 && this.state.isGuestTraveler) {
      this.resetGuestTraveler();
    }
  }

  setNewTravelerFormPage = page => {
    this.setState({ newTravelerFormPage: page });
  };

  setSingleTravelerShowPage = page => {
    this.setState({ singleTravelerShowPage: page });
  };

  setIsGuestTraveler = () => {
    this.setState({ isGuestTraveler: true });
  };

  getFrequentFlyerNumbersList = () => {
    if (this.props.selectedCustomer) {
      return this.props.selectedCustomer.frequentFlyerNumbersList;
    }
    return [];
  };

  resetGuestTraveler = () => {
    this.setState({ isGuestTraveler: false });
  };

  autoSelectFrequentFlyerNumber = () => {
    const {
      fields: { frequentFlyerNumber },
      selectedCustomer: { frequentFlyerNumbersList },
      operatingCarriers,
    } = this.props;

    if (frequentFlyerNumbersList) {
      const descFFNList = frequentFlyerNumbersList.sort(
        (firstFFN, secondFFN) => secondFFN.id - firstFFN.id,
      );

      const matchedFfn = descFFNList.find(ffn => operatingCarriers.includes(ffn.carrierCode));

      if (matchedFfn) {
        frequentFlyerNumber.number.onChange(matchedFfn.number);
        frequentFlyerNumber.carrier.onChange(matchedFfn.carrierCode);

        const updatedSelectedCustomer = {
          ...this.props.selectedCustomer,
          frequentFlyerNumber: { number: matchedFfn.number, carrier: matchedFfn.carrierCode },
        };

        this.props.customerSelected(this.props.index, updatedSelectedCustomer);
      }
    }
  };

  resetTraveler = () => {
    const allFields = this.props.fields;
    Object.keys(allFields).forEach(key => {
      if (
        allFields[key] &&
        key !== 'type' &&
        key !== 'itemChannels' &&
        typeof allFields[key].value !== 'undefined'
      ) {
        allFields[key].onChange('');
      }
    });
    this.setNewTravelerFormPage('search');
    this.props.reset(this.props.index);
    if (this.state.isGuestTraveler) this.resetGuestTraveler();
  };

  clearAll = () => {
    let index = 0;
    this.props.allTravelers.forEach(() => {
      if (
        index === 0 &&
        this.context.laymanMode &&
        this.context.travelerLoggedIn &&
        !this.props.allTravelers[index].guestUser.value
      ) {
        index += 1;
      } else {
        this.props.reset(index);
        index += 1;
      }
    });
  };

  showTravelerResetButton = (travelerIndex, enableComponent) => {
    const allTravelerIds = this.context.callbackParams
      ? this.context.callbackParams.all_traveler_ids
      : [];
    return (
      enableComponent &&
      !this.context.laymanMode &&
      (allTravelerIds.length === 0 || travelerIndex !== 0)
    );
  };

  refetchCurrentCustomer = () => {
    this.props.fetchCurrentCustomer(this.context.callbackParams).then(customer => {
      const mappedCustomer = customer;
      if (this.context.laymanMode && mappedCustomer && !mappedCustomer.email) {
        mappedCustomer.email = this.context.travelArrangerDefaultEmail;
      }
      this.props.customerSelected(0, mappedCustomer);
    });
    this.props.resetForGuest(0);
  };

  modifyUserToGuestUser = checked => {
    this.removeDataFromFields();
    if (!checked) {
      this.setNewTravelerFormPage('loading');
      this.refetchCurrentCustomer();
    } else {
      this.setNewTravelerFormPage('search');
    }
  };

  removeDataFromFields = () => {
    const allFields = this.props.fields;
    Object.keys(allFields).forEach(key => {
      if (
        allFields[key] &&
        key !== 'type' &&
        key !== 'itemChannels' &&
        typeof allFields[key].value !== 'undefined'
      ) {
        allFields[key].onChange('');
      }
    });
    this.props.resetForGuest(0);
    if (allFields.residenceAddress) {
      Object.keys(allFields.residenceAddress).forEach(key => {
        if (
          allFields.residenceAddress[key] &&
          key !== 'type' &&
          typeof allFields.residenceAddress[key].value !== 'undefined'
        ) {
          allFields.residenceAddress[key].onChange('');
        }
      });
    }
    if (allFields.document) {
      Object.keys(allFields.document).forEach(key => {
        if (
          allFields.document[key] &&
          key !== 'type' &&
          typeof allFields.document[key].value !== 'undefined'
        ) {
          allFields.document[key].onChange('');
        }
      });
    }
    if (allFields.frequentFlyerNumber) {
      Object.keys(allFields.frequentFlyerNumber).forEach(key => {
        if (
          allFields.frequentFlyerNumber[key] &&
          key !== 'type' &&
          typeof allFields.frequentFlyerNumber[key].value !== 'undefined'
        ) {
          allFields.frequentFlyerNumber[key].onChange('');
        }
      });
    }
  };

  NAME_PREFIXES = namePrefixBuilder();

  render() {
    const guestUser = this.props.fields.guestUser.value;

    const flightPresent =
      typeof this.props.flightResults !== 'undefined' && this.props.flightResults.length > 0;

    const customerLoggedIn =
      this.props.index === 0 && this.context.laymanMode && this.context.travelerLoggedIn;

    const bookingModeSwitchingPossible = customerLoggedIn && !this.props.toggleDisabled;

    const associateToOrganization =
      this.context.laymanMode &&
      this.context.travelerLoggedIn &&
      (guestUser || this.props.index > 0) &&
      this.props.currentCustomer &&
      this.props.currentCustomer.organization !== null;

    const travelArrangerEmailInUse =
      this.props.fields.email.value === this.context.travelArrangerDefaultEmail;

    const useArrangerEmailSettingsEnabled =
      this.context.laymanMode && this.context.travelArranger.enabled;

    const travellerNotEditable =
      this.context.travelArranger &&
      this.context.travelArranger.customerInformationVisible &&
      !this.context.travelArranger.customerInformationEditable;

    const showHintForTravelerArrangerEmail =
      this.props.index === 0 &&
      travelArrangerEmailInUse &&
      useArrangerEmailSettingsEnabled &&
      travellerNotEditable;

    const isGuestUserAvailable =
      (typeof this.props.fields.guestUser.value === 'string' &&
        this.props.fields.guestUser.value.length > 0) ||
      (typeof this.props.fields.guestUser.value === 'boolean' && this.props.fields.guestUser.value);

    const renderSearchPage =
      this.props.fields.id.value === '' &&
      this.props.fields.newUser.value === '' &&
      (!isGuestUserAvailable || !this.state.isGuestTraveler);

    if (renderSearchPage) {
      const hasRightToSearch = !(this.context.laymanMode && !this.context.travelerLoggedIn);
      let newTravelerFormContent;
      switch (this.state.newTravelerFormPage) {
        case 'search':
          newTravelerFormContent = (
            <Fragment>
              {bookingModeSwitchingPossible ? (
                <TravelerHeader
                  travelerIndex={this.props.index}
                  switchToGuestBooking={value => this.modifyUserToGuestUser(value)}
                  showSwitchingButton={true}
                  guestUser={guestUser}
                  isTravelerInformationLoading={this.props.isTravelerInformationLoading}
                  clearAll={this.clearAll}
                />
              ) : (
                <TravelerHeader
                  travelerIndex={this.props.index}
                  clearAll={this.clearAll}
                  isTravelerInformationLoading={this.props.isTravelerInformationLoading}
                />
              )}
              <SearchTravelerForm
                travelerIndex={this.props.index}
                setNewTravelerFormPage={this.setNewTravelerFormPage}
                hasRightToSearch={hasRightToSearch}
                customerSelected={this.props.customerSelected}
                reset={this.props.reset}
                callbackParams={this.context.callbackParams}
                isTravelerInformationLoading={this.props.isTravelerInformationLoading}
              />
            </Fragment>
          );
          break;
        case 'traveler_information':
          newTravelerFormContent = (
            <TravelerInformation
              namePrefixList={this.NAME_PREFIXES}
              index={this.props.index}
              fields={this.props.fields}
              flightPresent={flightPresent}
              disabled={this.props.disabled}
              reset={this.props.reset}
              templateTraveler={this.props.templateTraveler}
              setNewTravelerFormPage={this.setNewTravelerFormPage}
              phoneNumberValidate={this.props.phoneNumberValidate}
              travelerEmailValidate={this.props.travelerEmailValidate}
              showHintForTravelerArrangerEmail={showHintForTravelerArrangerEmail}
              isAssociatedToOrganization={associateToOrganization}
              identificationDocumentRequired={this.props.identificationDocumentsRequired}
              countryCodes={this.props.countryCodes}
              selectedCustomer={this.props.selectedCustomer}
              editable={this.props.editable}
              travelerUpdateAbilities={this.props.travelerUpdateAbilities}
            />
          );
          break;
        case 'travel_information':
          newTravelerFormContent = (
            <TravelInformation
              index={this.props.index}
              fields={this.props.fields}
              selectedCustomer={this.props.selectedCustomer}
              frequentFlyerNumber={this.props.fields.frequentFlyerNumber}
              disabled={this.props.disabled}
              flightResults={this.props.flightResults}
              mealRequests={this.props.mealRequests}
              reset={this.props.reset}
              setNewTravelerFormPage={this.setNewTravelerFormPage}
              internationalFlight={this.props.internationalFlight}
              enableIdDocOptional={this.props.enableIdDocOptional}
              identificationDocumentRequired={this.props.identificationDocumentsRequired}
              assistanceRequests={this.props.assistanceRequests}
              editable={this.props.editable}
              setIsGuestTraveler={this.setIsGuestTraveler}
            />
          );
          break;
        case 'loading':
          newTravelerFormContent = (
            <Fragment>
              <ContentLoaderPlaceholder numberOfLines={3} className="traveler-show-loader" />
              <ContentLoaderPlaceholder numberOfLines={7} />
            </Fragment>
          );
          break;
        default:
      }

      return <div className="traveler-show">{newTravelerFormContent}</div>;
    } else if (this.props.visible) {
      let travelerCardContent;

      switch (this.state.singleTravelerShowPage) {
        case 'show':
          travelerCardContent = (
            <Fragment>
              {bookingModeSwitchingPossible ? (
                <TravelerHeader
                  travelerIndex={this.props.index}
                  switchToGuestBooking={value => this.modifyUserToGuestUser(value)}
                  showSwitchingButton={true}
                  guestUser={guestUser}
                  selectedCustomer={this.props.selectedCustomer}
                  clearAll={this.clearAll}
                  isTravelerInformationLoading={this.props.isTravelerInformationLoading}
                />
              ) : (
                <TravelerHeader
                  travelerIndex={this.props.index}
                  selectedCustomer={this.props.selectedCustomer}
                  clearAll={this.clearAll}
                  isTravelerInformationLoading={this.props.isTravelerInformationLoading}
                />
              )}
              <TravelerInformationCard
                namePrefixList={this.NAME_PREFIXES}
                fields={this.props.fields}
                editable={this.props.editable}
                setSingleTravelerShowPage={this.setSingleTravelerShowPage}
                showHintForTravelerArrangerEmail={showHintForTravelerArrangerEmail}
                myBooking={customerLoggedIn && !guestUser}
                isCarAdded={this.props.isCarAdded}
                selectedCustomer={this.props.selectedCustomer}
                isTravelerInformationLoading={this.props.isTravelerInformationLoading}
              />
              {flightPresent && (
                <TravelInformationCard
                  fields={this.props.fields}
                  flightResults={this.props.flightResults}
                  setSingleTravelerShowPage={this.setSingleTravelerShowPage}
                  myBooking={customerLoggedIn && !guestUser}
                  enableIdDocOptional={this.props.enableIdDocOptional}
                  frequentFlyerNumbersList={this.getFrequentFlyerNumbersList()}
                  index={this.props.index}
                  selectedCustomer={this.props.selectedCustomer}
                  isTravelerInformationLoading={this.props.isTravelerInformationLoading}
                />
              )}
              {this.showTravelerResetButton(this.props.index, !this.props.disableComponent) &&
                !this.props.isTravelerInformationLoading && (
                  <div className="traveler-show__action-button">
                    <Button
                      version="v2"
                      onClick={this.resetTraveler}
                      size="small"
                      label={I18n.t('components.ibe.traveler_details.buttons.change_traveler')}
                    />
                  </div>
                )}
            </Fragment>
          );
          break;
        case 'edit_traveler_information':
          travelerCardContent = (
            <TravelerInformation
              namePrefixList={this.NAME_PREFIXES}
              index={this.props.index}
              fields={this.props.fields}
              flightPresent={flightPresent}
              templateTraveler={this.props.templateTraveler}
              disabled={this.props.disabled}
              editable={this.props.editable}
              reset={this.props.reset}
              setSingleTravelerShowPage={this.setSingleTravelerShowPage}
              phoneNumberValidate={this.props.phoneNumberValidate}
              travelerEmailValidate={this.props.travelerEmailValidate}
              showHintForTravelerArrangerEmail={showHintForTravelerArrangerEmail}
              isEditForm={true}
              setIsGuestTraveler={this.setIsGuestTraveler}
              identificationDocumentRequired={this.props.identificationDocumentsRequired}
              countryCodes={this.props.countryCodes}
              selectedCustomer={this.props.selectedCustomer}
              travelerUpdateAbilities={this.props.travelerUpdateAbilities}
            />
          );
          break;
        case 'edit_travel_information':
          travelerCardContent = (
            <TravelInformation
              index={this.props.index}
              fields={this.props.fields}
              setSingleTravelerShowPage={this.setSingleTravelerShowPage}
              selectedCustomer={this.props.selectedCustomer}
              frequentFlyerNumber={this.props.fields.frequentFlyerNumber}
              disabled={this.props.disabled}
              flightResults={this.props.flightResults}
              mealRequests={this.props.mealRequests}
              internationalFlight={this.props.internationalFlight}
              isEditForm={true}
              setIsGuestTraveler={this.setIsGuestTraveler}
              reset={this.props.reset}
              enableIdDocOptional={this.props.enableIdDocOptional}
              identificationDocumentRequired={this.props.identificationDocumentsRequired}
              assistanceRequests={this.props.assistanceRequests}
            />
          );
          break;
        default:
      }
      return <div className="traveler-show">{travelerCardContent}</div>;
    }

    return (
      <div className="traveler-show">
        <TravelerHeader travelerIndex={this.props.index} clearAll={this.clearAll} />

        <Card version="v2" size="full" className="traveler-show__hints">
          {I18n.t('components.ibe.traveler_details.hints.not_allowed')}
        </Card>

        {this.showTravelerResetButton(this.props.index, !this.props.disableComponent) && (
          <div className="traveler-show__action-button">
            <Button
              version="v2"
              onClick={this.resetTraveler}
              size="small"
              label={I18n.t('components.ibe.traveler_details.buttons.change_traveler')}
            />
          </div>
        )}
      </div>
    );
  }
}

SingleTravelerInformations.defaultProps = {
  selectedCustomer: {
    frequentFlyerNumbersList: [],
    frequentFlyerNumber: null,
  },
  flightResults: [],
  disabled: false,
  editable: false,
  visible: true,
  currentCustomer: {},
};

SingleTravelerInformations.propTypes = {
  index: PropTypes.number.isRequired,
  templateTraveler: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  selectedCustomer: PropTypes.shape({
    frequentFlyerNumbersList: PropTypes.arrayOf(PropTypes.shape({})),
    frequentFlyerNumber: PropTypes.shape({}),
    guestUser: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
  }),
  flightResults: PropTypes.arrayOf(PropTypes.shape),
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  editable: PropTypes.bool,
  resetForGuest: PropTypes.func.isRequired,
  fetchCurrentCustomer: PropTypes.func.isRequired,
  customerSelected: PropTypes.func.isRequired,
  internationalFlight: PropTypes.bool.isRequired,
  currentCustomer: PropTypes.shape(),
  phoneNumberValidate: PropTypes.func.isRequired,
  travelerEmailValidate: PropTypes.func.isRequired,
  mealRequests: PropTypes.shape({
    availableCodes: PropTypes.arrayOf(PropTypes.string),
    supported: PropTypes.bool,
  }).isRequired,
  toggleDisabled: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  disableComponent: PropTypes.bool.isRequired,
  enableIdDocOptional: PropTypes.bool.isRequired,
  identificationDocumentsRequired: PropTypes.bool.isRequired,
  countryCodes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  operatingCarriers: PropTypes.arrayOf(PropTypes.string).isRequired,
  assistanceRequests: PropTypes.shape().isRequired,
  isCarAdded: PropTypes.bool.isRequired,
  isTravelerInformationLoading: PropTypes.bool.isRequired,
  allTravelers: PropTypes.arrayOf(
    PropTypes.shape({
      guestUser: PropTypes.shape({
        value: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
  travelerUpdateAbilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      currentUseHasUpdateAbility: PropTypes.bool,
    }),
  ).isRequired,
};

SingleTravelerInformations.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  travelArranger: PropTypes.shape({
    customerInformationVisible: PropTypes.bool.isRequired,
    customerInformationEditable: PropTypes.bool.isRequired,
  }).isRequired,
  travelArrangerDefaultEmail: PropTypes.string.isRequired,
};

export default SingleTravelerInformations;
