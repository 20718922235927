import React, { useState, useEffect } from 'react';
import Card from '@wtag/rcl-card';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import { Textarea } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';

const OutOfPolicyJustificationEdit = ({
  itemId,
  justificationText,
  setJustification,
  editable,
  title,
}) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(justificationText);
  }, []);

  return (
    <Card version="v2" size="full" className="item__policy-reason-edit">
      <div className="col-12 col-bleed">
        <div className="col-grid direction-row col-bleed align-center justify-space-between">
          <div className="organization-edit-section__header">{title}</div>
          {editing ? (
            <div className="col-2 col-bleed organization-edit-section__actions">
              <IconButton
                icon={<Icon name="check" />}
                color="tertiary"
                size="small"
                onClick={() => {
                  setJustification(itemId, value);
                  setEditing(false);
                }}
                disabled={!value}
              />
              <IconButton
                icon={<Icon name="close" />}
                color="tertiary"
                size="small"
                onClick={() => {
                  setValue(justificationText);
                  setEditing(false);
                }}
              />
            </div>
          ) : (
            <div className="organization-edit-section__actions">
              {editable && (
                <IconButton
                  icon={<Icon name="edit" />}
                  color="tertiary"
                  size="small"
                  onClick={() => setEditing(true)}
                />
              )}
            </div>
          )}
        </div>

        <div className="item__policy-reason-edit-value">
          {editing ? (
            <Textarea
              placeholder={I18n.t('components.ibe.out_of_policy_reason.placeholder')}
              width="100"
              size="small"
              value={value}
              onChange={event => setValue(event.target.value)}
              error={!value && I18n.t('components.ibe.validations.presence')}
              touched={!value}
            />
          ) : (
            <span>{value}</span>
          )}
        </div>
      </div>
    </Card>
  );
};

OutOfPolicyJustificationEdit.defaultProps = {
  editable: false,
};

OutOfPolicyJustificationEdit.propTypes = {
  itemId: PropTypes.number.isRequired,
  justificationText: PropTypes.string.isRequired,
  setJustification: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default OutOfPolicyJustificationEdit;
