import {
  LOCATION_FETCHED,
 } from '../../actions/common';

export default function locations(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case LOCATION_FETCHED:
      newState[action.id] = action.coordinates;
      return newState;

    default:
      return state;
  }
}
