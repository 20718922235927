import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import SelectBox from '@wtag/rcl-select-box';
import {
  SMALL_MOBILE_DEVICE,
  MOBILE_DEVICE,
  TAB_MOBILE_DEVICE,
  DESKTOP_TAB_DEVICE,
  DESKTOP_DEVICE,
  LARGE_DESKTOP_DEVICE,
} from 'sharedWebpack/old/libraries/responsiveHelpers';
import FilterSection from '../../FilterSection';
import FilterTags from './FilterTags';
import FilterToggler from './FilterToggler';
import PriceFilter from './PriceFilter';
import '../styles.scss';
import StarOptionName from './StarOptionName';
import getAllRefundableOptions from '../helpers/getAllRefundableOptions';

class FilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterResetId: Math.random(),
      currentFilters: {
        refundable: null,
        stars: null,
        total_price: null,
      },
      isAllChecked: {
        stars: false,
        refundable: false,
      },
      isFilterVisible: false,
      isFilterVisibleInMobileView: false,
      viewMode: DESKTOP_DEVICE,
    };
  }

  onTotalPriceChange = (price = null) => {
    this.setState({
      currentFilters: { ...this.state.currentFilters, total_price: price },
    });
  };

  onStarsSelect = values => {
    const { currentFilters } = this.state;

    this.setState({
      currentFilters: { ...currentFilters, stars: values },
    });
  };

  onRefundableSelect = values => {
    const { currentFilters } = this.state;

    this.setState({
      currentFilters: { ...currentFilters, refundable: values },
    });
  };

  onAllRefundableChecked = values => {
    const { isAllChecked } = this.state;

    this.setState({
      isAllChecked: { ...isAllChecked, refundable: values },
    });
  };

  onAllStarsChecked = values => {
    const { isAllChecked } = this.state;

    this.setState({
      isAllChecked: { ...isAllChecked, stars: values },
    });
  };

  resetFilters = event => {
    event.stopPropagation();

    this.props.resetFilters(this.props.stats);
    this.setState({ filterResetId: Math.random() });
    this.setState({
      currentFilters: {
        refundable: null,
        stars: null,
        total_price: null,
      },
    });
    this.setState({
      isAllChecked: {
        stars: false,
        refundable: false,
      },
    });
  };

  resetTrip = key => {
    const newState = this.props.filterParams.price;

    const objectData = {
      changedData: newState,
      initialData: this.props.stats.price,
      key,
    };

    this.props.resetFiltersByKey(objectData);
    this.setState({ filterResetId: Math.random() });
  };

  resetData = section => {
    if (section === 'total_price') {
      this.resetTrip('total_price');
      this.setState({
        filterResetId: Math.random(),
        currentFilters: { ...this.state.currentFilters, [section]: null },
      });
    }

    this.setState({
      currentFilters: { ...this.state.currentFilters, [section]: null },
    });
  };

  selectGroup = group => {
    this.props.selectGroup(group).then(nextStep => this.doNextStep(nextStep));
  };

  resetSelectGroup = group => {
    this.props.resetSelectGroup(group).then(nextStep => this.doNextStep(nextStep));
  };

  toggleFilterVisibility = () => {
    if (this.state.viewMode !== DESKTOP_TAB_DEVICE) {
      return;
    }

    this.setState(prev => ({ isFilterVisible: !prev.isFilterVisible }));
  };

  toggleFilterVisibilityInMobileView = () => {
    this.setState(prev => ({ isFilterVisibleInMobileView: !prev.isFilterVisibleInMobileView }));
  };

  updateViewMode = viewMode => {
    this.setState({ viewMode });
  };

  render() {
    const {
      currentFilters,
      isFilterVisible,
      isFilterVisibleInMobileView,
      viewMode,
      filterResetId,
      isAllChecked,
    } = this.state;
    const { currency, stats, filterResults, filterParams, results } = this.props;
    const { stars, refundable, availableSortOptions, price } = stats;
    const PRICE = 'price';
    const STARS = 'stars';
    const SORT_BY = 'sortBy';
    const REFUNDABLE = 'refundable';

    const isInDesktopView = [DESKTOP_DEVICE, LARGE_DESKTOP_DEVICE].includes(viewMode);
    const isInDesktopTabView = viewMode === DESKTOP_TAB_DEVICE;
    const isInMobileView = [SMALL_MOBILE_DEVICE, MOBILE_DEVICE, TAB_MOBILE_DEVICE].includes(
      viewMode,
    );
    const isDownChevronIconVisible = !isFilterVisible && isInDesktopTabView;
    const shouldShowFilterBody = isFilterVisible || isInMobileView || isInDesktopView;
    const shouldShowFilterSection =
      isInDesktopView || isInDesktopTabView || isFilterVisibleInMobileView;
    const activeFilterCount = Object.values(currentFilters).filter(Boolean).length;
    const filterTogglerLabel = isFilterVisibleInMobileView
      ? I18n.t('components.ibe.search_results.filters.hide_all_filters')
      : I18n.t('components.ibe.search_results.filters.toggler.show', { count: activeFilterCount });

    const currentSortOption = availableSortOptions.find(
      option => option.value === filterParams.sortBy,
    );

    const allStarsOptions = stars.sort().map(star => ({
      name: <StarOptionName starCount={star} />,
      identifier: star,
    }));

    const filters = (
      <div className="flight-results__holder">
        <Card>
          <FilterToggler
            label={filterTogglerLabel}
            onToggleFilterVisibility={this.toggleFilterVisibilityInMobileView}
            onUpdateViewMode={this.updateViewMode}
          />
          {shouldShowFilterSection && (
            <Fragment>
              <div
                onClick={this.toggleFilterVisibility}
                onKeyDown={this.toggleFilterVisibility}
                role="presentation"
                className="hotel-results__title"
              >
                <div className="hotel-results__title--name">
                  {I18n.t('components.ibe.search_results.hotel.filter_title', {
                    count: activeFilterCount,
                  })}
                </div>
                <div className="d-flex column-gap-16">
                  <Button
                    label={I18n.t('components.ibe.search_results.filters.reset_flight')}
                    size="small"
                    onClick={this.resetFilters}
                  />
                  {isDownChevronIconVisible && <Icon name="iconDownChevron" />}
                </div>
              </div>
              {shouldShowFilterBody && (
                <Fragment>
                  <div>
                    <FilterTags
                      currentFilters={currentFilters}
                      onRemoveFilter={section => {
                        this.resetData(section);
                      }}
                    />
                  </div>
                  <div className="flight-results__sort">
                    {I18n.t('components.ibe.search_results.filters.sort_by')}
                    <SelectBox
                      onChange={event => filterResults(SORT_BY, event.value)}
                      key={filterResetId}
                      options={availableSortOptions}
                      value={currentSortOption}
                      width="full"
                      isClearable={false}
                    />
                    <div className="hotel-results__horizontal-line--main">
                      <hr />
                    </div>
                  </div>
                  <div className="results__filters">
                    {results.length > 0 && (
                      <PriceFilter
                        currency={currency}
                        rangeFilterKey={filterResetId}
                        min={price.min}
                        max={price.max}
                        values={filterParams.price}
                        onTotalPriceChange={this.onTotalPriceChange}
                        onFilterResults={filterValues => filterResults(PRICE, filterValues)}
                      />
                    )}

                    {stars && (
                      <FilterSection
                        filterTitle={I18n.t('components.ibe.search_results.filters.stars')}
                        allOptions={allStarsOptions}
                        currentSelection={currentFilters.stars}
                        isAllChecked={isAllChecked.stars}
                        filterResults={filterResults}
                        searchParameter={STARS}
                        selectedOptions={filterParams.stars}
                        onSelect={this.onStarsSelect}
                        onAllChecked={this.onAllStarsChecked}
                      />
                    )}

                    {refundable && (
                      <FilterSection
                        filterTitle={I18n.t('components.ibe.search_results.filters.refundable')}
                        allOptions={getAllRefundableOptions(refundable)}
                        currentSelection={currentFilters.refundable}
                        isAllChecked={isAllChecked.refundable}
                        filterResults={filterResults}
                        searchParameter={REFUNDABLE}
                        selectedOptions={filterParams.refundable}
                        onSelect={this.onRefundableSelect}
                        onAllChecked={this.onAllRefundableChecked}
                        isDividerVisible={false}
                      />
                    )}
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        </Card>
      </div>
    );

    return <div>{filters}</div>;
  }
}

FilterComponent.defaultProps = {
  stats: null,
  filterParams: null,
};

FilterComponent.propTypes = {
  selectedFlightGroups: PropTypes.shape({}).isRequired,
  selectGroup: PropTypes.func.isRequired,
  resetSelectGroup: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  resetFiltersByKey: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    price: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
    refundable: PropTypes.arrayOf(PropTypes.string).isRequired,
    stars: PropTypes.arrayOf(PropTypes.number).isRequired,
    availableSortOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  results: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filterParams: PropTypes.shape({
    price: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
    refundable: PropTypes.arrayOf(PropTypes.string).isRequired,
    stars: PropTypes.arrayOf(PropTypes.number).isRequired,
    sortBy: PropTypes.string.isRequired,
  }),
  params: PropTypes.shape({
    searchId: PropTypes.string,
    tripId: PropTypes.string,
  }).isRequired,
  filterResults: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

export default FilterComponent;
