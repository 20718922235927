import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import moment from 'moment';
import classNames from 'classnames';
import DateRangePicker from '@wtag/rcl-date-range-picker';
import DatePickerWithFocusedStateWrapper from 'sharedWebpack/DatePickerWithFocusedStateWrapper';
import SelectBox from '@wtag/rcl-select-box';
import AirportAutoComplete from '../../../AirportAutoComplete';
import { MULTICITY, ROUNDTRIP } from '../../../../lib/helpers/tripTypes';
import './styles.scss';

const AdvancedFlightFormTrips = props => {
  const {
    tripType,
    onTripRemove,
    tripIcon,
    trip,
    searchInputConfiguration,
    getFilteredAirportSuggestions,
    airportSuggestions,
    setNextTripDate,
    minDate,
    showInvalidDateError,
    cabinClassOptions,
    findLabelValue,
    preferredAllianceOptions,
    allianceOptionFinder,
    bookingClassOptionsByRule,
    handleTripDestinationChange,
    setCustomStartDate,
    setCustomEndDate,
    index,
    handleTripOriginChange,
    customStartDate,
    customEndDate,
    firstTrip,
    secondTrip,
    isAdvancedForm,
    laymanMode,
  } = props;

  const alliancePreferenceValue =
    trip.alliancePreferences && trip.alliancePreferences.value
      ? trip.alliancePreferences.value.map(value => allianceOptionFinder(value))
      : [];

  const bookingClassValue =
    trip.bookingClass && trip.bookingClass.value
      ? trip.bookingClass.value.map(value => ({
          label: value,
          value,
        }))
      : [];

  const onDateRangeChange = date => {
    const { startDate, endDate } = date;
    const formattedStartDate = startDate || null;
    const formattedEndDate = endDate || null;
    setCustomStartDate(formattedStartDate);
    setCustomEndDate(formattedEndDate);

    firstTrip.date.onChange(formattedStartDate);
    if (secondTrip && secondTrip.date) {
      secondTrip.date.onChange(formattedEndDate);
    }
  };

  const onCabinClassChange = selectedOption => {
    if (selectedOption) {
      if (tripType === ROUNDTRIP) {
        [trip, firstTrip, secondTrip].forEach(tripField =>
          tripField.cabinClass.onChange(selectedOption.value),
        );
      } else {
        trip.cabinClass.onChange(selectedOption.value);
      }
    }
  };

  const onDateChange = date => {
    if (!date) {
      trip.date.onChange('');
    } else {
      setCustomEndDate(moment.utc(date).format());
      setNextTripDate(index, moment.utc(date).format());
    }
  };

  const onSelectBoxChange = (selectedOptions, fieldName, tripField) =>
    selectedOptions && selectedOptions.length > 0
      ? tripField[fieldName].onChange(selectedOptions.map(option => option.value))
      : tripField[fieldName].onChange([]);

  const handleMultiSelectBoxChange = (selectedOptions, fieldName) => {
    if (tripType === ROUNDTRIP) {
      [trip, firstTrip, secondTrip].forEach(tripField => {
        onSelectBoxChange(selectedOptions, fieldName, tripField);
      });
    } else {
      onSelectBoxChange(selectedOptions, fieldName, trip);
    }
  };

  const dateRangeError =
    tripType === ROUNDTRIP &&
    ((!(customEndDate || customStartDate) && I18n.t('public.offer_enquiries.form.blank_title')) ||
      firstTrip.date.error ||
      (secondTrip && secondTrip.date && secondTrip.date.error));

  const dateRangeTouched =
    tripType === ROUNDTRIP &&
    ((firstTrip.date && firstTrip.date.touched) ||
      (secondTrip && secondTrip.date && secondTrip.date.touched));

  const dateRangeStartDate =
    tripType === ROUNDTRIP &&
    firstTrip.date &&
    (moment(firstTrip.date.value).isValid() ? customStartDate : null);

  const dateRangeEndDate =
    tripType === ROUNDTRIP &&
    firstTrip.date &&
    (moment(firstTrip.date.value).isValid() &&
    secondTrip &&
    secondTrip.date &&
    moment(secondTrip.date.value).isValid()
      ? customEndDate
      : null);

  return (
    <div
      className={classNames({
        'flight-form__advanced-trip-container--multi': tripType === MULTICITY,
      })}
    >
      <div className="col-grid col-bleed row-gap-12">
        {tripType === MULTICITY && (
          <div className="col-grid col-bleed align-center justify-space-between direction-row advanced-flight-form__trip-number">
            <span className="advanced-flight-form__trip-number-text">
              {I18n.t('components.flight.trip_number', {
                number: index + 1,
              })}
            </span>
            <IconButton
              className="advanced-flight-form__close-button"
              version="v2"
              icon={<Icon name="delete" />}
              color="tertiary"
              onClick={() => onTripRemove(index)}
            />
          </div>
        )}
        <div className="d-flex align-center direction-column justify-center">
          <Icon
            className="advanced-flight-form__icon"
            name={tripIcon}
            color="tertiary"
            showBGColor={true}
            size="medium"
          />
          <div className="d-flex w-100 advanced-flight-form__airport-content">
            <div
              className={classNames(
                'col-bleed advanced-flight-form__common-field advanced-flight-form__code airport-auto-complete--origin',
                {
                  'airport-auto-complete--origin__value': trip.origin.value.length > 0,
                },
              )}
            >
              <AirportAutoComplete
                className="airport-auto-complete--common-field"
                title={I18n.t('components.ibe.search_form.flight.label.origin')}
                error={trip.origin.error}
                currentAirport={trip.origin.value}
                {...trip.origin}
                onChange={code => handleTripOriginChange(index, code)}
                fullWidth={true}
                whitelistedAirports={searchInputConfiguration.whitelistedAirports}
                suggestions={airportSuggestions[trip.origin.value] || []}
                iconName="takeOff"
              />
            </div>

            <div
              className={classNames(
                'col-bleed advanced-flight-form__common-field advanced-flight-form__code airport-auto-complete--destination',
                {
                  'airport-auto-complete--destination__value': trip.destination.value,
                },
              )}
            >
              <AirportAutoComplete
                className="airport-auto-complete--common-field"
                title={I18n.t('components.ibe.search_form.flight.label.destination')}
                error={trip.destination.error}
                currentAirport={trip.destination.value}
                {...trip.destination}
                onChange={code => handleTripDestinationChange(index, code)}
                fullWidth={true}
                whitelistedAirports={searchInputConfiguration.whitelistedAirports}
                suggestions={
                  getFilteredAirportSuggestions(
                    airportSuggestions,
                    trip.origin.value,
                    trip.destination.value,
                  ) || []
                }
                iconName="stopOver"
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-bleed d-flex column-gap-16 advanced-flight-form__fields">
          <div
            className={classNames(
              'col-6 col-bleed advanced-flight-form__common-field advanced-flight-form__date-range',
            )}
          >
            {tripType === ROUNDTRIP ? (
              <DateRangePicker
                onChange={date => onDateRangeChange(date)}
                required={true}
                minDate={minDate(index)}
                startDatePlaceholderText={I18n.t(
                  'components.ibe.search_form.flight.label.departure',
                )}
                endDatePlaceholderText={I18n.t('components.ibe.search_form.flight.label.return')}
                fullWidth={true}
                isBoundToAYear={false}
                startDate={dateRangeStartDate}
                endDate={dateRangeEndDate}
                touched={dateRangeTouched}
                error={dateRangeError}
              />
            ) : (
              <DatePickerWithFocusedStateWrapper
                pastYearsCount={0}
                futureYearsCount={1}
                size="small"
                placeholder={I18n.t('components.ibe.search_form.flight.label.departure')}
                onChange={date => onDateChange(date)}
                fullWidth={true}
                showClearDate={true}
                minDate={minDate(index)}
                hideMonthsAndYearsWithNoActiveDates={true}
                date={trip.date.value}
                locale={moment().utc()}
                initialDate={trip.date.value}
                id={Math.random()}
                onFocusChange={trip.date.onFocus}
                touched={trip.date.touched}
                error={showInvalidDateError(trip.date)}
                hideNavButtons={false}
              />
            )}
          </div>
          <div className="col-6 col-bleed advanced-flight-form__common-field">
            <SelectBox
              placeholderText={I18n.t('components.ibe.search_form.flight.label.cabin_class')}
              options={cabinClassOptions()}
              isClearable={false}
              onChange={onCabinClassChange}
              width="full"
              value={findLabelValue(cabinClassOptions(), trip.cabinClass.value)}
            />
          </div>
        </div>
        <div
          className={classNames(
            'advanced-flight-form__fields col-12 col-bleed d-flex column-gap-16',
            {
              'flight-form__search-filter': laymanMode,
              'flight-form__search-filter--advanced': isAdvancedForm,
            },
          )}
        >
          {searchInputConfiguration.showAlliancePreferences && (
            <div
              className={classNames('col-6 col-bleed advanced-flight-form__common-field', {
                'advanced-flight-form__common-field--padding-right': !searchInputConfiguration.showBookingClass,
              })}
            >
              <SelectBox
                placeholderText={I18n.t(
                  'components.ibe.search_form.flight.label.preferred_alliance',
                )}
                options={preferredAllianceOptions()}
                onChange={selectedOptions =>
                  handleMultiSelectBoxChange(selectedOptions, 'alliancePreferences')
                }
                width="full"
                isMulti={true}
                value={alliancePreferenceValue}
                isClearable={true}
              />
            </div>
          )}
          {searchInputConfiguration.showBookingClass && (
            <div className="col-6 col-bleed advanced-flight-form__common-field">
              <SelectBox
                placeholderText={I18n.t('components.ibe.search_form.flight.label.booking_class')}
                options={bookingClassOptionsByRule[trip.cabinClass.value]}
                onChange={selectedOptions =>
                  handleMultiSelectBoxChange(selectedOptions, 'bookingClass')
                }
                width="full"
                isMulti={true}
                value={bookingClassValue}
                isClearable={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AdvancedFlightFormTrips.defaultProps = {
  customStartDate: null,
  customEndDate: null,
  firstTrip: {},
  secondTrip: {},
  laymanMode: false,
};

AdvancedFlightFormTrips.propTypes = {
  tripType: PropTypes.string.isRequired,
  tripsLength: PropTypes.number.isRequired,
  onTripRemove: PropTypes.func.isRequired,
  tripIcon: PropTypes.string.isRequired,
  trip: PropTypes.shape({
    origin: PropTypes.shape({
      onChange: PropTypes.func,
      error: PropTypes.arrayOf(PropTypes.node),
      value: PropTypes.string,
    }),
    destination: PropTypes.shape({
      onChange: PropTypes.func,
      error: PropTypes.arrayOf(PropTypes.node),
      value: PropTypes.string,
    }),
    date: PropTypes.shape({
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      touched: PropTypes.bool,
      value: PropTypes.string,
    }),
    cabinClass: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.string,
    }),
    alliancePreferences: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.string,
    }),
    bookingClass: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
    }),
  }).isRequired,
  searchInputConfiguration: PropTypes.shape({
    whitelistedAirports: PropTypes.arrayOf().isRequired,
    showAlliancePreferences: PropTypes.bool,
    showBookingClass: PropTypes.bool,
  }).isRequired,
  getFilteredAirportSuggestions: PropTypes.func.isRequired,
  airportSuggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setNextTripDate: PropTypes.func.isRequired,
  minDate: PropTypes.func.isRequired,
  showInvalidDateError: PropTypes.func.isRequired,
  cabinClassOptions: PropTypes.func.isRequired,
  findLabelValue: PropTypes.func.isRequired,
  preferredAllianceOptions: PropTypes.func.isRequired,
  allianceOptionFinder: PropTypes.func.isRequired,
  bookingClassOptionsByRule: PropTypes.shape({}).isRequired,
  handleTripDestinationChange: PropTypes.func.isRequired,
  setCustomStartDate: PropTypes.func.isRequired,
  setCustomEndDate: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  handleTripOriginChange: PropTypes.func.isRequired,
  customStartDate: PropTypes.string,
  customEndDate: PropTypes.string,
  firstTrip: PropTypes.shape({
    date: PropTypes.shape({
      error: PropTypes.arrayOf(PropTypes.node),
      onChange: PropTypes.func,
      touched: PropTypes.bool,
      value: PropTypes.string,
    }),
  }),
  secondTrip: PropTypes.shape({
    date: PropTypes.shape({
      error: PropTypes.arrayOf(PropTypes.node),
      onChange: PropTypes.func,
      touched: PropTypes.bool,
      value: PropTypes.string,
    }),
  }),
  isAdvancedForm: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool,
};

export default AdvancedFlightFormTrips;
