import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DOMPurify from 'dompurify';

const SanitizedHTML = ({ html, className }) => (
  // eslint-disable-next-line react/no-danger
  <span
    className={classnames('sanitized-html', { [`${className}`]: className })}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
  />
);

SanitizedHTML.defaultProps = {
  className: null,
};

SanitizedHTML.propTypes = {
  html: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SanitizedHTML;
