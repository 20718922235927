import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../AppContext';
import RoomWithContext from './ActiveRoom';
import { AGENCY } from './categories';

const ActiveAgencyRoom = props => {
  const { handleRoomClick, isUserOnline, room, rooms, context } = props;

  useEffect(() => {
    if (context.isChatOpen) {
      const agencyRooms = rooms.filter(user => user.type === AGENCY);
      handleRoomClick(agencyRooms[0]);
    }
  }, [context.isChatOpen]);

  return (
    <React.Fragment>
      {room && <RoomWithContext isUserOnline={isUserOnline(room)} room={room} />}
    </React.Fragment>
  );
};

ActiveAgencyRoom.defaultProps = {
  room: {},
};

ActiveAgencyRoom.propTypes = {
  context: PropTypes.shape().isRequired,
  isUserOnline: PropTypes.func.isRequired,
  room: PropTypes.shape(),
  handleRoomClick: PropTypes.func.isRequired,
  rooms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const AgencyRoomWithContext = props => (
  <AppContext.Consumer>
    {context => <ActiveAgencyRoom context={context} {...props} />}
  </AppContext.Consumer>
);

export default AgencyRoomWithContext;
