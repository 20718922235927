import React from 'react';
import PropTypes from 'prop-types';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';

const AccountingForm = props => {
  const {
    accountingType,
    setAccountingType,
    accountingTypeValue,
    setAccountingTypeValue,
    allocatedPercentage,
    setAllocatedPercentage,
    accountingTypes,
    accountingValues,
    error,
  } = props;

  const accountingTypeOptions = Object.keys(accountingTypes).map(accountingTypeKey => ({
    value: accountingTypes[accountingTypeKey].id,
    label: accountingTypes[accountingTypeKey].name,
  }));

  const dynamicAccountingTypeValueOption = () => {
    let dropdown = [];
    if (accountingValues && accountingType) {
      const accountingValue = accountingValues.filter(
        value => value.accountingTypeId === accountingType,
      );
      if (accountingValue) {
        dropdown = accountingValue.map(accounting => ({
          value: accounting.value,
          label: accounting.value,
        }));
      }
    }
    return dropdown;
  };

  const accountingTypeSelectedValue = accountingTypeValue
    ? dynamicAccountingTypeValueOption().find(
        ({ value }) => value === accountingTypeValue.toString(),
      ) || {
        label: accountingTypeValue,
        value: accountingTypeValue,
      }
    : '';

  const findSelectBoxContent = (options, value) => {
    const selectedOption = options.find(option => value === option.value);
    if (selectedOption) {
      return {
        value: selectedOption.value,
        label: selectedOption.label,
      };
    }

    return null;
  };

  const onCreateOption = (value) => {
    accountingValues.push({accountingTypeId: accountingType, value});
    setAccountingTypeValue(value);
  };

  return (
    <div className="col-12 col-bleed order-accounting-form">
      <div className="col-12 col-bleed">
        <div
          className="col-6 col-bleed order-accounting-form__field--with-bottom-padding
        order-accounting-form__field--with-right-padding"
        >
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.orders.accounting_tab.form.placeholders.accounting_type',
            )}
            label={I18n.t('admin.components.orders.accounting_tab.form.labels.accounting_type')}
            width="full"
            options={accountingTypeOptions}
            value={findSelectBoxContent(accountingTypeOptions, accountingType)}
            onChange={selectedOption =>
              setAccountingType(selectedOption ? selectedOption.value : '')
            }
            required={true}
            errorMsg={error.accountingType}
          />
        </div>
        <div className="col-6 col-bleed order-accounting-form__field--with-bottom-padding">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.orders.accounting_tab.form.placeholders.accounting_value',
            )}
            label={I18n.t('admin.components.orders.accounting_tab.form.labels.accounting_value')}
            width="full"
            options={dynamicAccountingTypeValueOption()}
            value={accountingTypeSelectedValue}
            onChange={selectedOption =>
              setAccountingTypeValue(selectedOption ? selectedOption.value : '')
            }
            errorMsg={error.accountingTypeValue}
            isCreatable={true}
            onCreateOption={onCreateOption}
          />
        </div>
        <div className="col-6 col-bleed order-accounting-form__field--with-right-padding">
          <Input
            size="tiny"
            placeholder={I18n.t(
              'admin.components.orders.accounting_tab.form.placeholders.allocation_percentage',
            )}
            label={I18n.t(
              'admin.components.orders.accounting_tab.form.labels.allocation_percentage',
            )}
            onChange={value => setAllocatedPercentage(value)}
            value={allocatedPercentage}
            touched={error.allocatedPercentage}
            required={true}
            error={error.allocatedPercentage}
          />
        </div>
      </div>
    </div>
  );
};

AccountingForm.defaultProps = {
  accountingType: null,
  accountingTypeValue: null,
  allocatedPercentage: null,
  error: PropTypes.shape({
    accountingType: null,
    accountingTypeValue: null,
    allocatedPercentage: null,
  }),
};

AccountingForm.propTypes = {
  accountingType: PropTypes.number,
  setAccountingType: PropTypes.func.isRequired,
  accountingTypeValue: PropTypes.string,
  allocatedPercentage: PropTypes.number,
  setAllocatedPercentage: PropTypes.func.isRequired,
  setAccountingTypeValue: PropTypes.func.isRequired,
  accountingTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  accountingValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  error: PropTypes.shape({
    accountingType: PropTypes.string,
    accountingTypeValue: PropTypes.string,
    allocatedPercentage: PropTypes.string,
  }),
};

export default AccountingForm;
