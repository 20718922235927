import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import Button from '@wtag/rcl-button';
import { I18nText } from '@wtag/react-comp-lib';
import { Link } from 'react-router-dom';
import Card from '@wtag/rcl-card';
import Map from '@wtag/rcl-map';
import { Accordion, AccordionItem } from '@wtag/rcl-accordion';
import HotelDefaultImage from 'agentSyncedImages/fallback_images/product/product_xxl_hotel.png';
import HotelRoomDefaultImage from 'agentSyncedImages/fallback_images/hotel-room-fallback.jpg'; // eslint-disable-line import/extensions
import searchURL from '../../../IBE/lib/helpers/searchURL';
import { parseQueryString } from '../../helpers/qsMethods';
import ImageGallery from './ImageGallery';
import history from '../../lib/history';
import Rooms from './Rooms';
import HotelAmenities from './HotelAmenities';
import HotelTitle from './HotelTitle';
import HotelDescription from './HotelDescription';
import HotelInformation from './HotelInformation';
import LoadingScreen from './LoadingScreen';

import './styles.scss';

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.locationQuery = parseQueryString(props.location.search);
    this.state = {
      isMapAccordionActive: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.locationQuery = parseQueryString(this.props.location.search);
    }
  }

  continue = (roomId, justification) => {
    if (this.locationQuery.dealGroup) {
      return this.props.selectInMultiSearch(
        this.locationQuery.dealGroup,
        this.props.details,
        roomId,
      );
    }
    return this.props.addToCart(this.props.details, roomId, justification).then(cartItem => {
      history.push(`/carts/${cartItem.cartId}`);
    });
  };

  toggleMapAccordion = () => {
    this.setState(prevState => ({
      isMapAccordionActive: !prevState.isMapAccordionActive,
    }));
  };

  render() {
    if (!this.props.dataFetched) {
      return (
        <div className="hotel-details__loading-screen">
          <LoadingScreen />
        </div>
      );
    }

    // geographical centre of all land surfaces on Earth: İskilip, Turkey
    const defaultMapCenter = { latitude: 40.73528, longitude: 34.47389 };

    const {
      searchId,
      hotelInformation,
      hotelFacilities,
      location,
      name,
      checkIn,
      checkOut,
      stars,
      durationOfStaying,
      requiredRooms,
      rooms,
      pictures,
      refundable,
      rating,
      ratingSource,
    } = this.props.details;

    const images = pictures.map(image => ({
      original: image.url,
      thumbnail: image.thumbnailUrl,
    }));
    if (images.length === 0) {
      images.push({
        original: HotelDefaultImage,
        thumbnail: HotelDefaultImage,
      });
      images.push({
        original: HotelRoomDefaultImage,
        thumbnail: HotelRoomDefaultImage,
      });
    }

    const { street, zip, city } = this.props.details.address;
    let address = street;
    if (zip || city) {
      address += ',';
      if (zip) {
        address += ` ${zip}`;
      }
      if (city) {
        address += ` ${city}`;
      }
    }

    const hotelMapCenter = () => {
      if (location) {
        return {
          latitude: location.lat,
          longitude: location.lon,
        };
      }
      return defaultMapCenter;
    };

    let hotelInformationForMap;
    if (location) {
      hotelInformationForMap = [
        {
          title: name,
          checkIn,
          checkOut,
          latitude: location.lat,
          longitude: location.lon,
        },
      ];
    }

    const ACCORDION = 'accordion';
    const CARD = 'card';

    const renderMap = key => (
      <Map
        className="hotel-details__graphic-contents-map-card"
        uniqueKey={`hotel-${name}-${key}`}
        mapCenter={hotelMapCenter()}
        markerSize={{ height: 50, width: 50 }}
        hotels={hotelInformationForMap}
        zoomLevel={location ? 10 : 1}
      />
    );

    return (
      <div className="col-grid col-bleed hotel-details">
        <Card className="hotel-details__all-results-button">
          <Link to={searchURL('hotel', searchId)}>
            <Button
              version="v2"
              size="small"
              label={I18n.t('admin.components.cart.additional_results')}
            />
          </Link>
        </Card>
        <div className="col-grid col-bleed hotel-details__header">
          <div className="col-xlg-12 col-bleed col-12">
            <HotelTitle
              stars={stars}
              name={name}
              address={address}
              rating={rating}
              ratingSource={ratingSource}
            />
          </div>
        </div>
        <div className="col-12 col-grid col-bleed hotel-details__graphic-contents">
          <div className="col-md-6 col-bleed hotel-details__graphic-contents-images">
            <ImageGallery images={images} travelAppLayout={this.context.laymanMode} stars={stars} />
          </div>

          <div className="col-bleed col-lg-6 hidden-sm hidden-xs hidden-xxs hotel-details__graphic-contents-map">
            <Card className="col-12 col-bleed" version="v2" size="full">
              {renderMap(CARD)}
            </Card>
          </div>

          <div className="col-12 col-bleed hidden-lg hidden-md hidden-md-up hidden-xlg hotel-details__graphic-contents-map-accordion">
            <Accordion>
              <AccordionItem
                header={I18n.t('components.ibe.hotel.details.map_header')}
                isActive={this.state.isMapAccordionActive}
                onClick={this.toggleMapAccordion}
              >
                {renderMap(ACCORDION)}
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        <Card version="v2" size="full" className="hotel-details__description">
          <div className="col-12 col-grid col-bleed hotel-details__description-card">
            {hotelInformation && hotelInformation.description && (
              <div className="col-lg-6 col-md-6 col-12 col-bleed hotel-details__description-card-content">
                <HotelDescription
                  hotelDescription={hotelInformation.description}
                  previewCharLimit={800}
                  previewCharLimitInMobileView={150}
                />
              </div>
            )}

            <div className="col-lg-6 col-md-6 col-12 col-bleed hotel-details__description-card-information">
              <HotelInformation
                facilities={hotelFacilities}
                hotelPolicies={hotelInformation.hotelPolicies}
              />
            </div>
          </div>
        </Card>

        <HotelAmenities amenities={hotelFacilities.others} />

        <Card version="v2" size="full" className="col-12 hotel-details__rooms">
          <div className="hotel-details__rooms-title">
            <I18nText id="ibe.hotel.details.rooms" />
          </div>

          <div className="hotel-details__rooms-content">
            <Rooms
              rooms={rooms}
              addToCart={this.continue}
              refundable={refundable}
              durationOfStaying={durationOfStaying}
              requiredRooms={requiredRooms}
              laymanMode={this.context.laymanMode}
            />
          </div>
        </Card>
      </div>
    );
  }
}

Details.defaultProps = {
  details: null,
};

Details.propTypes = {
  dataFetched: PropTypes.bool.isRequired,
  details: requiredIf(
    PropTypes.shape({
      pictures: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          thumbnailUrl: PropTypes.string.isRequired,
        }),
      ),
      id: PropTypes.string,
      searchId: PropTypes.string,
      placeId: PropTypes.number,
      stars: PropTypes.number,
      address: PropTypes.shape({
        street: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
      }),
      name: PropTypes.string,
      hotelInformation: PropTypes.shape({
        beforeDepartureInformation: PropTypes.string,
        checkInInstructions: PropTypes.string,
        checkInTime: PropTypes.string,
        checkOutTime: PropTypes.string,
        propertyInformation: PropTypes.string,
      }),
    }),
    props => props.dataFetched,
  ),
  addToCart: PropTypes.func.isRequired,
  selectInMultiSearch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

Details.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
};

export default Details;
