import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import OrderMap from '../OrderMap';

const OrderMapWrapper = ({ orderId, orderItems, layman }) => {
  const [items, setItems] = useState([]);
  const [flights, setFlights] = useState([]);
  const [cars, setCars] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [events, setEvents] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [cruises, setCruises] = useState([]);

  const fetchItems = async () => {
    const itemUrl = layman
      ? routes.public.orders.items({ id: orderId })
      : routes.admin.items({ id: orderId });

    const { data } = await httpClient.get(itemUrl);
    setItems(data.items);
  };

  const fetchJourneyElement = async (journeyElementId, journeyElementType, setJourneyElement) => {
    const { data } = await httpClient.get(
      routes.api.fetchJourneyElement({ id: journeyElementId, type: journeyElementType }),
    );
    setJourneyElement(prevState => {
      const newCollection = [...prevState];
      newCollection.push(data);
      return newCollection;
    });
  };

  const fetchJourneyElements = () =>
    items.forEach(item => {
      switch (item.journeyElementType) {
        case 'flight':
          return fetchJourneyElement(item.journeyElementId, item.journeyElementType, setFlights);
        case 'car':
          return fetchJourneyElement(item.journeyElementId, item.journeyElementType, setCars);
        case 'hotel':
          return fetchJourneyElement(item.journeyElementId, item.journeyElementType, setHotels);
        case 'event':
          return fetchJourneyElement(item.journeyElementId, item.journeyElementType, setEvents);
        case 'transfer':
          return fetchJourneyElement(item.journeyElementId, item.journeyElementType, setTransfers);
        case 'cruise':
          return fetchJourneyElement(item.journeyElementId, item.journeyElementType, setCruises);
        default:
      }
      return null;
    });

  useEffect(() => {
    if (orderItems.length > 0) {
      setItems(orderItems);
    } else if (orderId) {
      fetchItems();
    }
  }, [orderId, orderItems]);

  useEffect(() => {
    fetchJourneyElements();
  }, [items]);

  return (
    <OrderMap
      flights={flights}
      cars={cars}
      hotels={hotels}
      events={events}
      transfers={transfers}
      curises={cruises}
    />
  );
};

OrderMapWrapper.defaultProps = {
  orderItems: [],
  orderId: null,
  layman: false,
};

OrderMapWrapper.propTypes = {
  orderId: PropTypes.number,
  orderItems: PropTypes.arrayOf(PropTypes.shape({})),
  layman: PropTypes.bool,
};

export default OrderMapWrapper;
