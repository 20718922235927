import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PillTabs } from '@wtag/react-comp-lib';
import routes from 'agentRoutes';
import { Header } from '@wtag/rcl-typography';
import { Tabs as RTabs } from '@wtag/rcl-rtabs';
import MangeRequest from './ManageRequest';
import '../styles.scss';

const ManageTab = ({ scope, fetchUrl, hideColumns }) => {
  const getTabs = useMemo(
    () => [
      {
        key: 0,
        title: I18n.t('public.components.approval_request.tab.pending'),
        getContent: () => (
          <MangeRequest scope={scope} tab="pending" fetchUrl={fetchUrl} hideColumns={hideColumns} />
        ),
      },
      {
        key: 1,
        title: I18n.t('public.components.approval_request.tab.managed'),
        getContent: () => (
          <MangeRequest scope={scope} tab="managed" fetchUrl={fetchUrl} hideColumns={hideColumns} />
        ),
      },
      {
        key: 2,
        title: I18n.t('public.components.approval_request.tab.expired'),
        getContent: () => (
          <MangeRequest scope={scope} tab="expired" fetchUrl={fetchUrl} hideColumns={hideColumns} />
        ),
      },
    ],
    [scope, fetchUrl, hideColumns],
  );

  return (
    <div className="col-12 col-bleed request-list__rtabs request-list__items--with-top-margin">
      <RTabs items={getTabs} />
    </div>
  );
};

ManageTab.defaultProps = {
  hideColumns: {
    requestor: false,
  },
};

ManageTab.propTypes = {
  scope: PropTypes.string.isRequired,
  fetchUrl: PropTypes.string.isRequired,
  hideColumns: PropTypes.shape({
    requestor: PropTypes.bool,
  }),
};

const List = () => {
  const hideColumns = {
    requestor: true,
  };

  const getItems = () => [
    {
      key: 0,
      title: I18n.t('public.components.approval_request.scope.incoming'),
      getContent: () => (
        <ManageTab scope="incoming" fetchUrl={routes.public.approvals.confirmations.list()} />
      ),
    },
    {
      key: 1,
      title: I18n.t('public.components.approval_request.scope.outgoing'),
      getContent: () => (
        <ManageTab
          key="outgoing"
          scope="outgoing"
          fetchUrl={routes.public.approvals.requests.list()}
          hideColumns={hideColumns}
        />
      ),
    },
  ];

  return (
    <div className="col-grid request-list">
      <Header level={4} weight="medium">
        {I18n.t('public.components.approval_request.header')}
      </Header>
      <div className="request-list__items request-list__items--with-top-margin">
        <PillTabs items={getItems()} showMore={false} />
      </div>
    </div>
  );
};

export default List;
