import { CART_FETCHED, SET_VOUCHER_IN_CART, VOUCHER_INVALID, SET_VOUCHER_CONTENT_STATUS, REFRESH_VOUCHER_CONTENT_STATUS } from '../../actions/common';

function voucher(state, action) {
  const newState = Object.assign({
    amount: null,
    currency: null,
    number: null,
    percentage: null,
    reductionAmount: null,
    reductionCurrency: null,
    reductionType: null,
    used: null,
    numberFormField: '',
    valid: null,
    contentInvalid: null,
    contentMessage: '',
  }, state);

  switch (action.type) {
    case CART_FETCHED:
      return {
        ...action.cart.voucher,
        numberFormField: action.cart.voucher && action.cart.voucher.number,
        valid: action.cart.voucher != null,
      }

    case SET_VOUCHER_IN_CART:
      return {
        ...newState,
        numberFormField: action.number,
        valid: null,
      };

    case VOUCHER_INVALID:
      return {
        ...newState,
        valid: false,
      };

    case SET_VOUCHER_CONTENT_STATUS:
      return {
        ...newState,
        contentInvalid: action.status.contentInvalid,
        contentMessage: action.status.message,
      };

    case REFRESH_VOUCHER_CONTENT_STATUS:
      return {
        ...newState,
        contentInvalid: null,
        contentMessage: null,
      };
    default:
      return newState;
  }
}

export default voucher;
