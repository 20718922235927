export * from './alert';
export * from './primaryButton';
export * from './skipAuthenticationStatuses';
export * from './resetButtonAndAlertStates';
export * from './threeDsStatusesByToken';
export * from './bookingRequest';
export * from './authenticationRequest';

export const UPDATE_BOOKING_COMPONENT_REF_BY_TOKEN = 'UPDATE_BOOKING_COMPONENT_REF_BY_TOKEN';
export const TOGGLE_CARD_ADDRESS_FORM_STATE = 'TOGGLE_CARD_ADDRESS_FORM_STATE';

export const toggleCardAddressFormState = willResetCardAddressForm => ({
  type: TOGGLE_CARD_ADDRESS_FORM_STATE,
  willResetCardAddressForm,
});

export const updateBookingComponentRefByToken = newBookingRefState => ({
  type: UPDATE_BOOKING_COMPONENT_REF_BY_TOKEN,
  newBookingRefState,
});
