import routes from 'agentRoutes';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';

import { saveSearch } from '../../lib/saveSearchParameters';
import { form2api } from '../../Search/car/helpers/mapSearchParams';
import { showError } from '../../actions/common';

export const CAR_SEARCH_COMPLETED = 'CAR_SEARCH_COMPLETED';
function carSearchCompleted(id) {
  return {
    type: CAR_SEARCH_COMPLETED,
    id,
  };
}

let currentCarSearchToken;
export function resetCarSearch() {
  if (currentCarSearchToken) {
    currentCarSearchToken.cancel('Explicitly cancelled');
  }
}

export const SEARCHING_CARS = 'SEARCHING_CARS';
export function createCarSearch(formParams, callbackParams) {
  if (currentCarSearchToken) {
    currentCarSearchToken.cancel('Replaced with new search');
  }

  currentCarSearchToken = generateSourceToken();
  saveSearch('cars', formParams);
  const searchParams = form2api(formParams, callbackParams);

  return dispatch => {
    dispatch({ type: SEARCHING_CARS, params: formParams });
    return httpClient
      .post(routes.api.cars.createSearch(), searchParams, {
        cancelToken: currentCarSearchToken.token,
      })
      .then(({ data }) => dispatch(carSearchCompleted(data.id)))
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }
        return dispatch(showError('Search failed for unknown reasons', error));
      });
  };
}

export const CAR_SEARCH_FETCHED = 'CAR_SEARCH_FETCHED';
function carSearchResultsFetched(data) {
  return {
    type: CAR_SEARCH_FETCHED,
    results: data.results,
    stats: data.stats,
    searchParams: data.search,
    availableResults: data.availableResults,
    searchId: data.id,
    availableResultsPerSupplier: data.availableResultsPerSupplier,
  };
}

export const FETCHING_CAR_SEARCH = 'FETCHING_CAR_SEARCH';
export function fetchCarSearchResults(params) {
  return dispatch => {
    dispatch({ type: FETCHING_CAR_SEARCH, searchId: params.searchId });
    return httpClient
      .get(routes.api.cars.searchResults(params))
      .then(({ data }) => dispatch(carSearchResultsFetched(data)));
  };
}
