import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Modal from 'sharedWebpack/Modal';
import FareBasisInfo from '../../Cart/Items/flight/FareBasisInfoContainer';

const FareRulesLink = ({ searchResultId, airlineCodes }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onModalOpen = () => {
    setIsModalVisible(true);
  };

  const onModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <div
        className="flight__fare-rules--link"
        onClick={onModalOpen}
        onKeyDown={onModalOpen}
        role="button"
        tabIndex={0}
      >
        {I18n.t('components.ibe.flight.fare_rules')}
      </div>
      <Modal
        className="flight__fare-rules-modal"
        title={
          <div className="item__labels-buttons-fare-rules--modal-title">
            {I18n.t('components.ibe.flight.fare_rules')}
            <div onClick={onModalClose} onKeyDown={onModalClose} role="presentation">
              <Icon name="close" />
            </div>
          </div>
        }
        isModalVisible={isModalVisible}
        onOutsideClick={onModalClose}
      >
        <FareBasisInfo id={searchResultId} filterSegments={airlineCodes} />
      </Modal>
    </Fragment>
  );
};

FareRulesLink.propTypes = {
  searchResultId: PropTypes.string.isRequired,
  airlineCodes: PropTypes.shape(PropTypes.string).isRequired,
};

export default FareRulesLink;
