import React from 'react';
import PropTypes from 'prop-types';
import JourneySummaryIndicatorWrapper from 'sharedWebpack/JourneySummaryIndicator/JourneySummaryIndicatorWrapper';
import './styles.scss';

const TripSummaryDetails = ({ airports, trips, text }) => (
  <div className="d-flex-col trip-summary-details">
    <div className="d-flex justify-between trip-summary-details__content">
      <JourneySummaryIndicatorWrapper trips={trips} airports={airports} />
    </div>
    {text && <span className="trip-summary-details__text">{text}</span>}
  </div>
);

const AirportsShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const TripShape = PropTypes.shape({
  origin: PropTypes.string,
  originCode: PropTypes.string,
  destinationCode: PropTypes.string,
  destination: PropTypes.string,
});

TripSummaryDetails.defaultProps = {
  text: null,
};

TripSummaryDetails.propTypes = {
  airports: AirportsShape.isRequired,
  trips: PropTypes.arrayOf(TripShape).isRequired,
  text: PropTypes.node,
};

export default TripSummaryDetails;
