import {
  validate,
  presenceValidation,
  greaterThanOrEqualToValidation,
  lessThanOrEqualToValidation,
  totalTravelersValidation,
} from '../../lib/validations';

const travelerConstraints = {
  adults: {
    ...presenceValidation,
    ...greaterThanOrEqualToValidation(I18n.t('components.ibe.validations.common.adult'), 1),
  },
  children: {
    ...presenceValidation,
  },
  infants: {
    ...presenceValidation,
  },
};

function validateTravelerConstraints(params) {
  const extendedTravelerConstraints = { ...travelerConstraints };
  const adultTraveler =
    parseInt(params.adults, 10) +
    params.childrenList.age.filter(age => age !== '' && age > 12).length;
  const myTotalTravelersValidation = totalTravelersValidation(
    adultTraveler,
    parseInt(params.children, 10),
    parseInt(params.infants, 10),
  );

  extendedTravelerConstraints.adults = {
    ...extendedTravelerConstraints.adults,
    ...myTotalTravelersValidation,
  };

  extendedTravelerConstraints.children = {
    ...extendedTravelerConstraints.children,
    ...myTotalTravelersValidation,
  };

  extendedTravelerConstraints.infants = {
    ...extendedTravelerConstraints.infants,
    ...myTotalTravelersValidation,
    ...lessThanOrEqualToValidation(adultTraveler, I18n.t('components.ibe.validations.infant')),
  };

  return validate(params, extendedTravelerConstraints);
}

export { validate, validateTravelerConstraints };
