import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  setFormOfPaymentToCreditCard,
  setFormOfPaymentForAllItems,
  configurePaymentGateway,
  fetchAccountingTypesForCart,
  addCostCenterToCart,
  updateCartCostCenter,
  removeCostCenterFromCart,
  setFormOfPaymentToCash,
  setFormOfPaymentToWelltravelWallet,
  setFormOfPaymentToIATA,
  setFormOfPaymentToNone,
  fetchCartPeople,
  set3rdPartyPaymentMethod,
  fetchCart,
  resetFormOfPayment,
} from '../actions/common';
import Payment from '.';

const channelsWithQueuesAndRemarks = [
  'galileo',
  'apollo',
  'worldspan',
  'amadeus',
  'sabre',
  'lh_group',
  'american_airlines',
];

const mapStateToProps = (state, ownProps) => {
  const cart = state.common.cart;
  const currency = cart.currency;
  const items = cart.items;
  const availableFOPsForAll = cart.availableFormOfPayment;
  const journeyElements = state.common.journeyElements;
  const itemsTotal = cart.total;
  const people = state.common.people;
  const creditCards = Object.assign({}, state.common.newCreditCard);

  const { laymanMode, cardDataAvailableByToken } = ownProps;
  Object.keys(creditCards).forEach(token => {
    if (cardDataAvailableByToken[token] !== true && creditCards[token].storedInCcv !== true) {
      delete creditCards[token];
    }
  });
  const cartId = cart.id;
  const travelers = cart.travelers;
  const costCenters = state.common.costCenters;
  const accountingTypes = state.common.accountingTypes;
  const cartCostCenters = cart.costCenters;
  const staticProducts = state.common.staticProducts;
  const allCCTokens = Object.values(state.common.newCreditCard).map(creditCard => creditCard.token);
  const uniqueCCTokens = [...new Set(allCCTokens)];
  const marginTotal = cart.totalMargin;

  // The three ds authentication is mandatory-
  // when the booking happens in the travel app, or when there is an authentication-required item in the cart
  const threeDsAuthenticationMandatory =
    laymanMode || availableFOPsForAll.creditCard.requiredFields.threeDsAuthenticationRequired;

  return {
    availableFOPsForAll,
    allocationPerAccountingType: state.common.cart.allocationPerAccountingType,
    items,
    uniqueCCTokens,
    flightResultDetailsById: state.flights.resultDetailsById,
    journeyElements,
    itemsTotal,
    channelsWithQueuesAndRemarks,
    creditCards,
    paymentGateway: state.common.paymentGateway,
    cartId,
    threeDsAuthenticationMandatory,
    travelers,
    travelerCount: state.common.travelerCount,
    costCenters,
    accountingTypes,
    cartCostCenters,
    currency,
    staticProducts,
    people,
    marginTotal,
  };
};

const mapDispatchToProps = dispatch => ({
  addCostCenterToCart: costCenter => dispatch(addCostCenterToCart(costCenter)),
  updateCartCostCenter: costCenter => dispatch(updateCartCostCenter(costCenter)),
  removeCostCenterFromCart: (costCenterId, accountingTypeId) =>
    dispatch(removeCostCenterFromCart(costCenterId, accountingTypeId)),
  setFormOfPaymentToCreditCard: (formData, itemId) =>
    dispatch(setFormOfPaymentToCreditCard(formData, itemId)),
  setFormOfPaymentToNone: itemId => dispatch(setFormOfPaymentToNone(itemId)),
  setFormOfPaymentToCash: itemId => dispatch(setFormOfPaymentToCash(itemId)),
  setFormOfPaymentToWelltravelWallet: itemId =>
    dispatch(setFormOfPaymentToWelltravelWallet(itemId)),
  setFormOfPaymentToIATA: itemId => dispatch(setFormOfPaymentToIATA(itemId)),
  setFormOfPaymentForAllItems: (formData, itemsWithFOP) =>
    dispatch(setFormOfPaymentForAllItems(formData, itemsWithFOP)),
  configurePaymentGateway: paymentGateway => dispatch(configurePaymentGateway(paymentGateway)),
  fetchCartPeople: () => dispatch(fetchCartPeople()),
  fetchAccountingTypesForCart: callbackParams =>
    dispatch(fetchAccountingTypesForCart(callbackParams)),
  set3rdPartyPaymentMethod: (method, callbackParams) =>
    dispatch(set3rdPartyPaymentMethod(method, callbackParams)),
  resetCreditCardBillingAddressForm: () => dispatch(reset('card-details')),
  fetchCart: (cartId, callbackParams) => dispatch(fetchCart(cartId, callbackParams)),
  resetFormOfPayment: () => dispatch(resetFormOfPayment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
