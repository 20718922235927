/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchAirlineInformations } from '../../actions/flight';

class AirlineLogo extends React.Component {
  componentDidMount() {
    if (!this.props.airlineInformations[this.props.carrierCode]) {
      this.props.fetchAirlineInformations(this.props.carrierCode);
    }
  }

  render() {
    const carrier = this.props.airlineInformations[this.props.carrierCode];
    const logo = carrier && carrier.logo;
    if (logo) {
      return (
        <div className={this.props.className}>
          <img src={logo} alt={`logo ${this.props.carrierCode}`} />
        </div>
      );
    }
    return null;
  }
}

AirlineLogo.propTypes = {
  airlineInformations: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
    }),
  ),
  carrierCode: PropTypes.string.isRequired,
  fetchAirlineInformations: PropTypes.func.isRequired,
  className: PropTypes.string,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
  airlineInformations: state.flights.airlineInformations,
});
const mapDispatchToProps = dispatch => ({
  fetchAirlineInformations: code => dispatch(fetchAirlineInformations(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AirlineLogo);
