import { STRINGIFY_NEW_BOOKING_REQUEST } from '../actions';

export default function stringifiedBookingReq(state = '', action) {
  switch (action.type) {
    case STRINGIFY_NEW_BOOKING_REQUEST:
      return JSON.stringify(action.stringifiedBookingReq);
    default:
      return state;
  }
}
