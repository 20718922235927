import React from 'react';
import PropTypes from 'prop-types';
import SeatDetails from './SeatDetailsContainer';

const SeatMapSeatPopoverContent = props => {
  const { layman, absentOrNullPrice, isRestrictedSeat, ...otherProps } = props;

  if (isRestrictedSeat) {
    return (
      <div className="flights absent-seat">
        {I18n.t(layman ? 'travel_app' : 'agent', {
          scope: 'components.ibe.seat_map.not_supported_seat_message',
        })}
      </div>
    );
  }
  if (!absentOrNullPrice) {
    return (
      <div className="flights">
        <SeatDetails {...otherProps} />
      </div>
    );
  }

  return (
    <div className="flights absent-seat">
      {I18n.t('components.ibe.seat_map.absent_seat_message')}
    </div>
  );
};

SeatMapSeatPopoverContent.defaultProps = {
  absent: true,
  aisle: false,
  booked: false,
  bookedFor: null,
  chargeable: false,
  childRestricted: false,
  exit: false,
  infantRestricted: false,
  mainItemId: null,
  occupied: false,
  reclineRestricted: false,
  window: false,
  pricing: {
    amount: null,
    currency: null,
  },
  layman: false,
};

SeatMapSeatPopoverContent.propTypes = {
  absent: PropTypes.bool,
  absentOrNullPrice: PropTypes.bool.isRequired,
  aisle: PropTypes.bool,
  booked: PropTypes.bool,
  bookedFor: PropTypes.number,
  chargeable: PropTypes.bool,
  childRestricted: PropTypes.bool,
  exit: PropTypes.bool,
  flight: PropTypes.string.isRequired,
  free: PropTypes.bool.isRequired,
  infantRestricted: PropTypes.bool,
  isRestrictedSeat: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  layman: PropTypes.bool,
  mainItemId: PropTypes.number,
  occupied: PropTypes.bool,
  pricing: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
  }),
  reclineRestricted: PropTypes.bool,
  removeTravelerIndex: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
  selectSeat: PropTypes.func.isRequired,
  setTravelerIndex: PropTypes.func.isRequired,
  window: PropTypes.bool,
};

export default SeatMapSeatPopoverContent;
