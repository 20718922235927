import { fetchAirportDetails } from '../../actions/common';

export const FLIGHT_SET_DEFAULT_SEARCH_PARAMS = 'FLIGHT_SET_DEFAULT_SEARCH_PARAMS';
export function flightSetDefaultSearchParams(params) {
  return dispatch => {
    dispatch({
      type: FLIGHT_SET_DEFAULT_SEARCH_PARAMS,
      params,
    });
    const fetchPromises = [];
    params.trips.forEach(trip => {
      fetchPromises.push(dispatch(fetchAirportDetails(trip.origin)));
      fetchPromises.push(dispatch(fetchAirportDetails(trip.destination)));
    });
    return Promise.all(fetchPromises);
  };
}
