import React, { Fragment } from 'react';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import PropTypes from 'prop-types';

export const SingleTraveler = ({ traveler }) => {
  const { firstName, lastName, avatarUrl, name, type, email } = traveler;
  const travelersName = name || [firstName, lastName].filter(Boolean).join(' ');

  return (
    <div className="public-order-overview-tab__travellers-card">
      <div className="col-grid col-bleed direction-row align-center justify-space-between">
        <div className="col-grid col-bleed direction-row align-center">
          <Avatar firstName={firstName} lastName={lastName} src={avatarUrl} size="tiny" />
          <div className="public-order-overview-tab__travellers-card-name public-order-overview-tab--bold public-order-overview-tab--with-right-padding">
            {travelersName}
            <div className="public-order-overview-tab__travellers-email">{email}</div>
          </div>
        </div>
        <EmphasisTag
          text={I18n.t(type, {
            scope: 'admin.components.orders.overview.traveller_type',
          })}
          size="tiny"
        />
      </div>
    </div>
  );
};

const TravelersList = ({ travelers, numberOfItemsToShow }) => (
  <Fragment>
    {travelers.slice(0, numberOfItemsToShow).map(traveler => (
      <SingleTraveler key={traveler.id} traveler={traveler} />
    ))}
  </Fragment>
);

SingleTraveler.propTypes = {
  traveler: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
    name: PropTypes.string,
    namePrefix: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

TravelersList.propTypes = {
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
      name: PropTypes.string,
      namePrefix: PropTypes.string,
    }).isRequired,
  ).isRequired,
  numberOfItemsToShow: PropTypes.number.isRequired,
};

export default TravelersList;
