import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import memoizeLocationQuery from '../helpers/memoizeLocationQuery';
import Booking from './';
import fetchOnUpdate from '../lib/decorators/fetchOnUpdate';
import {
  createOrder,
  bookCart,
  prepareForExternalPayment,
  fetchCartItemsAvailability,
  fetchCartAllItemsAvailability,
  fetchCart,
  storeCartRecipientData,
  setLegalPaperAcceptance,
  fetchCartStatus,
  retrackBooking,
  fetchFormOfPayment,
  fetchPaymentGatewayInfo,
  fetchLegalPapers,
  fetchFOPandCardDetails,
  fetchCartPeople,
  fetchFees,
  setOutOfPolicyJustification,
  resetPayment,
  fetchJourneyElement,
  removeSubItem,
  resetFormOfPayment,
} from '../actions/common';
import fetchServices from '../actions/common/fetchServices';
import { NONE_FORM_OF_PAYMENT, CREDIT_CARD_FORM_OF_PAYMENT } from '../lib/helpers/fop_options';

const GDSChannels = ['amadeus', 'galileo', 'sabre'];

const mapStateToProps = (state, ownProps) => {
  const cart = state.common.cart;
  const fopWithCreditCard = cart.items.filter(
    item => item.hasJourneyElement && item.formOfPayment.type === CREDIT_CARD_FORM_OF_PAYMENT,
  );
  const allCCTokens = fopWithCreditCard.map(item => item.formOfPayment.cardDetails.token);
  const uniqueCCTokens = [...new Set(allCCTokens)];
  const travelers = cart.travelers;
  const cartItems = cart.items;
  const cartId = cart.id;
  const costCenters = state.common.costCenters;
  const cartCostCenters = cart.costCenters;
  const bookingPossible = state.common.cart.bookingPossible;
  const onlyOneItemFromSearch = state.common.cart.onlyOneItemFromSearch;
  const canBookQuoteItems = state.common.cart.canBookQuoteItems;
  const allItemsAvailable = state.common.cart.allItemsAvailable;
  const flightServicesFetched =
    Object.keys(state.common.journeyElements.flight).length === 0 ||
    state.common.cart.flightServicesFetched;
  const flightServicesNeeded = Object.keys(state.common.journeyElements.flight).length >= 1;
  let canProceedWithPayment = true;
  canProceedWithPayment = cartItems.every(
    item => !item.priceChanged || item.oldGrossTotal === item.grossTotal,
  );

  const itemsWhichPreventOption = cartItems.filter(
    item => item.type !== 'flight' && !item.mainItemId,
  );
  const allFlightsOnGDS = cartItems
    .filter(item => item.type === 'flight')
    .every(item => GDSChannels.indexOf(item.channel.identifier) >= 0);
  const createOptionPossible = itemsWhichPreventOption.length === 0 && allFlightsOnGDS;

  const cartStatus = cart.status;
  const itemsBookingInProgress =
    cartStatus && cartStatus.cartItems.some(cartItem => cartItem.status === 'in_progress');

  const bookingQuoted =
    cartStatus &&
    (cartStatus.forQuoteOnly ||
      cartStatus.cartItems.some(cartItem => cartItem.status === 'failed'));

  const allItemsBookingConfirmed = cartStatus && cartStatus.allItemsConfirmed;

  const itemsBookingDone =
    cart.status &&
    cart.status.cartItems.every(
      cartItem => cartItem.status === 'success' || cartItem.status === 'failed',
    );

  // None selected as form of payment, but not supported from supplier
  const payLaterItemsToBeQuoted = cartItems.filter(
    item =>
      item.formOfPayment &&
      item.formOfPayment.type === NONE_FORM_OF_PAYMENT &&
      item.availableFormOfPayment.none.supportedBySupplier === false,
  );

  // If every reservation item is selected pay later and does not support pay later, we disable the booking.
  const disableBooking =
    payLaterItemsToBeQuoted.length === cartItems.filter(item => item.hasJourneyElement).length;

  return {
    payLaterItemsToBeQuoted,
    disableBooking,
    cart,
    bookingCompleted: state.common.bookingCompleted,
    bookingFailed: state.common.bookingFailed,
    bookingError: state.common.bookingError,
    allItemsBookingConfirmed,
    bookingQuoted,
    bookingPossible,
    onlyOneItemFromSearch,
    allItemsAvailable,
    flightServicesFetched,
    flightServicesNeeded,
    canProceedWithPayment,
    cartFees: cart.fees,
    cartItems,
    totalCartItems: ownProps.totalCartItems,
    uniqueCCTokens,
    cartState: cart.state,
    cartStatus,
    itemsBookingInProgress,
    itemsBookingDone,
    createOptionPossible,
    currency: cart.currency,
    fees: state.common.fees,
    flightResultDetailsById: state.flights.resultDetailsById,
    isBooking: state.common.isBooking,
    paymentGateway: state.common.paymentGateway,
    paymentFailureReason: state.common.paymentFailureReason,
    redirectURL: state.common.redirectURL,
    staticProducts: state.common.staticProducts,
    total: cart.total,
    travelerCount: state.common.travelerCount,
    voucher: state.common.cart.voucher,
    grossTotalsByGroup: cart.grossTotalsByGroup,
    isFetchingFop: cart.isFetchingFop,
    taxes: state.common.cart.taxes,
    cartId,
    travelers,
    costCenters,
    cartCostCenters,
    legalPaperAcceptances: state.common.cart.legalPaperAcceptances,
    allocationPerAccountingType: state.common.cart.allocationPerAccountingType,
    canBookQuoteItems,
    paymentMethodSurcharge: state.common.cart.paymentMethodSurcharge,
    totalWithoutVoucherReduction: cart.totalWithoutVoucherReduction,
  };
};

const mapDispatchToProps = dispatch => ({
  resetPayment: () => dispatch(resetPayment()),
  fetchCart: (id, callbackParams) => dispatch(fetchCart(id, callbackParams)),
  book: (results, callbackParams) => dispatch(bookCart(results, callbackParams)),
  createOrder: (callbackParams, procedure) => dispatch(createOrder(callbackParams, procedure)),
  prepareForExternalPayment: (callbackParams, procedure) =>
    dispatch(prepareForExternalPayment(callbackParams, procedure)),
  fetchCartItemsAvailability: callbackParams =>
    dispatch(fetchCartItemsAvailability(callbackParams)),
  fetchCartAllItemsAvailability: callbackParams =>
    dispatch(fetchCartAllItemsAvailability(callbackParams)),
  fetchJourneyElement: (type, id) => dispatch(fetchJourneyElement(type, id, true)),
  storeCartRecipientData: () => dispatch(storeCartRecipientData()),
  setLegalPaperAcceptance: (paper, value) => dispatch(setLegalPaperAcceptance(paper, value)),
  fetchCartStatus: (cartId, callbackParams) => dispatch(fetchCartStatus(cartId, callbackParams)),
  retrackBooking: cartId => dispatch(retrackBooking(cartId)),
  fetchFormOfPayment: () => dispatch(fetchFormOfPayment()),
  fetchPaymentGatewayInfo: (paygateType, paymentMethod, cartId, redirectTo, baseUrl, iframe) =>
    dispatch(
      fetchPaymentGatewayInfo(paygateType, paymentMethod, cartId, redirectTo, baseUrl, iframe),
    ),
  fetchLegalPapers: callbackParams => dispatch(fetchLegalPapers(callbackParams)),
  removeSubItem: (itemId, callbackParams) => dispatch(removeSubItem(itemId, callbackParams)),
  fetchServices: (cart, callbackParams) => dispatch(fetchServices(cart, callbackParams)),
  fetchCreditCardDetails: () => dispatch(fetchFOPandCardDetails()),
  fetchCartPeople: callbackParams => dispatch(fetchCartPeople(callbackParams)),
  fetchFees: callbackParams => dispatch(fetchFees(callbackParams)),
  setOutOfPolicyJustification: (itemId, reason) =>
    dispatch(setOutOfPolicyJustification(itemId, reason)),
  resetFormOfPayment: () => dispatch(resetFormOfPayment()),
});

const BookingWithFetchOnUpdate = fetchOnUpdate(['id'], (params, props) => {
  const locationQuery = memoizeLocationQuery(props.location.search);
  props.fetchCartStatus(params.id, props.callbackParams).then(response => {
    const itemsBookingInProgress = response.status.cartItems.some(
      cartItem => cartItem.status === 'in_progress',
    );

    if (
      (!props.isBooking && itemsBookingInProgress) ||
      locationQuery.thirdPartyDelayedTransaction === 'true'
    ) {
      // Start tracking progress of booking if its refreshed or redirect back from 3rd party payment
      props.retrackBooking(params.id);
    }
  });

  props.fetchCart(params.id, props.callbackParams).then(() => {
    props.fetchCreditCardDetails().then(() => {
      props.fetchFormOfPayment();
    });
  });
})(Booking);

const BookingWithFetchOnUpdateWithContext = (props, context) => (
  <BookingWithFetchOnUpdate {...props} {...context} />
);

BookingWithFetchOnUpdateWithContext.contextTypes = {
  callbackParams: PropTypes.shape().isRequired,
  laymanMode: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(BookingWithFetchOnUpdateWithContext));
