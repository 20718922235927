/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'react-redux/lib/utils/shallowEqual';

function mapParams(paramKeys, params) {
  return paramKeys.reduce((acc, key) => Object.assign({}, acc, { [key]: params[key] }), {});
}

export default function fetchOnUpdate(paramKeys, fn) {
  return DecoratedComponent =>
    class FetchOnUpdateDecorator extends React.Component {
      static propTypes = {
        match: PropTypes.shape({
          params: PropTypes.shape({}),
        }),
      };

      componentWillMount() {
        fn(mapParams(paramKeys, this.props.match.params), this.props);
      }

      componentDidUpdate(prevProps) {
        const params = mapParams(paramKeys, this.props.match.params);
        const prevParams = mapParams(paramKeys, prevProps.match.params);

        if (!shallowEqual(params, prevParams)) {
          fn(params, this.props);
        }
      }

      render() {
        return <DecoratedComponent {...this.props} />;
      }
    };
}
