import React from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Card from '@wtag/rcl-card';
import { I18nText } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import reduxFormShape from '../../Payment/shapes/reduxForm';

import '../styles.scss';

class EmailShare extends React.Component {
  removeRow = recipient => {
    const index = this.props.recipients.indexOf(recipient);
    const newRecipients = [...this.props.recipients];
    newRecipients.splice(index, 1);
    this.props.updateConfirmationMailRecipients(newRecipients);
    this.props.resetForm('emailShareForm');
  };

  addNewRecipient = (name, email) => {
    const newRecipients = [...this.props.recipients];
    newRecipients.push({ name: name.value, email: email.value });
    this.props.updateConfirmationMailRecipients(newRecipients);
    this.props.resetForm('emailShareForm');
  };

  render() {
    let table = null;
    if (this.props.recipients.length > 0) {
      table = (
        <div className="col-12 col-grid col-bleed email-share-content__table">
          {this.props.recipients.map(recipient => (
            <div className="col-12 col-grid col-bleed email-share-content__recipient-row">
              <Card version="v2" size="full" shadow="dark">
                <div className="col-12 col-bleed email-share-content__recipient-row-content">
                  <div className="col-9 col-bleed email-share-content__recipient-row-content__recipient">
                    {recipient.name} ({recipient.email})
                  </div>
                  <div className="col-3 col-bleed email-share-content__recipient-row-content__delete-icon">
                    <IconButton
                      icon={<Icon name="delete" />}
                      label={<I18nText id="ibe.share_email.delete" />}
                      onClick={() => {
                        this.removeRow(recipient);
                      }}
                    />
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      );
    }
    const {
      fields: { email, name },
    } = this.props;
    return (
      <div className="col-12 col-grid col-bleed email-share-content__card-email-name">
        <div className="col-grid col-bleed email-share-content__text-label">
          <p>
            <I18nText id="ibe.share_email.label" returnStringOnly={true} />
          </p>
        </div>
        <div className="col-12 col-grid col-bleed email-share-content__recipient-card">
          <Card version="v2" size="full" shadow="dark">
            <div className="email-share-content__add-label">
              <I18nText id="ibe.forms.email.label" returnStringOnly={true} />
            </div>
            <div className="email-share-content__add-label-input">
              <Input {...email} touched={true} />
            </div>
            <div className="email-share-content__add-label">
              {I18n.t('components.ibe.confirmation_mail.name')}
            </div>
            <div className="email-share-content__add-label-input">
              <Input {...name} touched={true} />
            </div>
            <div className="email-share-content__add-button">
              <Button
                version="v2"
                size="small"
                label={<I18nText id="ibe.share_email.add" returnStringOnly={true} />}
                onClick={() => this.addNewRecipient(name, email)}
                disabled={!email.valid || email.value === '' || email.value == null || !name.valid}
              />
            </div>
          </Card>
          {table}
        </div>
        <div className="col-12 col-grid col-bleed email-share-content__share-button">
          <Button
            version="v2"
            size="small"
            label={<I18nText id="ibe.share_email.done" returnStringOnly={true} />}
            onClick={this.props.toggleModal}
            disabled={this.props.recipients.length < 1}
          />
        </div>
      </div>
    );
  }
}

EmailShare.propTypes = {
  updateConfirmationMailRecipients: PropTypes.func.isRequired,
  confirmationMailRecipientSearch: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
  recipientSearchResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
  resetForm: PropTypes.func.isRequired,
  ...reduxFormShape,
};

export default EmailShare;
