import { FETCHING_FLIGHT_MATRICES } from '../../actions/flight';

export default function fetchingFlightMatrices(state = [], action) {
  const newState = new Set();

  switch (action.type) {
    case FETCHING_FLIGHT_MATRICES:
      if (action.isFetching) {
        action.resultIds.forEach(id => newState.add(id));
      } else {
        action.resultIds.forEach(id => newState.delete(id));
      }

      return [...newState];

    default:
      return state;
  }
}
