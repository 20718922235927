import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { ensureCart, fetchCart } from './cart';

export const SET_QUEUE_PLACEMENT = 'SET_QUEUE_PLACEMENT';
export function setQueuePlacement(queue, account, resultId, callbackParams) {
  return dispatch =>
    dispatch(ensureCart(callbackParams)).then(cartId => {
      dispatch({
        type: SET_QUEUE_PLACEMENT,
        queue,
        account,
        resultId,
        callbackParams,
      });
      return httpClient
        .post(routes.api.carts.items.addQueuePlacement({ cartId }), {
          queue_placement: { queue, account },
        })
        .then(() => dispatch(fetchCart(cartId, callbackParams)));
    });
}
