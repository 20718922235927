import React, { useCallback, useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from '@wtag/rcl-avatar';
import { EmphasisTag, Link, SidePanel } from '@wtag/react-comp-lib';
import Modal from 'sharedWebpack/Modal';
import { Menu, MenuItem } from '@wtag/rcl-menu';
import Icon from '@wtag/rcl-icon';
import Input from '@wtag/rcl-input';
import { hot } from 'react-hot-loader';
import Button from '@wtag/rcl-button';
import IconButton from '@wtag/rcl-icon-button';
import Tooltip from '@wtag/rcl-tooltip';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import useBodyScrollLock from '../../shared/useBodyScrollLock';
import Chat from '../../shared/Chat';
import './styles.scss';

const TopNav = props => {
  const {
    user,
    currentCurrency,
    isLoggedIn,
    availableLanguagesOptions,
    signInPath,
    signUpPath,
    tenantLogoUrl,
    hasOrganization,
    organizationName,
    organizationAvatarUrl,
    menuUrls,
    rootPath,
    travelArrangerList,
    currentUserWorkingAsTravelArranger,
    loggedInUser,
    chat,
  } = props;

  const {
    dashboardUrl,
    tripsUrl,
    approvalRequestsUrl,
    travelArrangersUrl,
    corporateTravelUrl,
    mobileAppUrl,
    signOutUrl,
  } = menuUrls;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageAccordionVisible, setIsLanguageAccordionVisible] = useState(false);
  const [searchArranger, setSearchArranger] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [showChatTitle, setShowChatTitle] = useState(false);
  const [isTopNavAsSideDrawerVisible, setIsTopNavAsSideDrawerVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const onOutsideClick = () => {
    setIsVisible(false);
  };

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState);
  };

  const onBurgerMenuClickHandler = useCallback(
    () => setIsTopNavAsSideDrawerVisible(prevState => !prevState),
    [],
  );
  const [notificationCount, setNotificationCount] = useState(0);
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();
  const [showTooltip, setShowTooltip] = useState(false);
  const organizationNameRef = useRef(null);
  const topNavContainer = useRef(null);

  const isChatDisabled = !(chat && chat.chatEnabled) || currentUserWorkingAsTravelArranger;

  const setSearchParams = value => setSearchArranger(value);
  const languageAccordionButtonClickHandler = useCallback(() => {
    setIsLanguageAccordionVisible(prevState => !prevState);
  }, []);

  const filteredTravelArrangerList = travelArrangerList.filter(arranger => {
    if (!searchArranger) {
      return true;
    }
    const nameExist = arranger.name && arranger.name.toLowerCase().includes(searchArranger);
    const emailExist = arranger.email && arranger.email.toLowerCase().includes(searchArranger);
    return nameExist || emailExist;
  });

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const switchProfile = async travelArrangerId => {
    await httpClient.post(routes.public.travelArrangers.switch(), {
      id: travelArrangerId,
    });
    window.location.reload();
  };

  const switchBack = async () => {
    await httpClient.delete(routes.public.travelArrangers.switchBack({ id: user.id }));
    window.location.reload();
  };

  const onOpenChat = () => {
    setOpenChat(true);
  };

  const lockBodyScrollOfTravelApp = useCallback(() => {
    const scrollWidth = window.innerWidth - document.body.offsetWidth;
    lockBodyScroll(scrollWidth);
    topNavContainer.current.style.right = `calc(${scrollWidth} / 2 * 1px)`;
  }, []);

  const unlockBodyScrollOfTravelApp = useCallback(() => {
    unlockBodyScroll();
    topNavContainer.current.style.right = '0';
  }, []);

  useEffect(() => {
    if (openChat) {
      lockBodyScrollOfTravelApp();
    } else {
      unlockBodyScrollOfTravelApp();
    }
  }, [openChat]);

  useEffect(() => {
    if (hasOrganization) {
      setShowTooltip(
        organizationNameRef.current.scrollWidth > organizationNameRef.current.offsetWidth,
      );
    }
  }, [organizationNameRef, hasOrganization]);

  const switchAccountButtonRender = () => {
    let content;
    if (currentUserWorkingAsTravelArranger) {
      content = (
        <Button
          size="tiny"
          className="top-nav__switch-account--button hidden-xxs hidden-xs hidden-sm hidden-md"
          onClick={switchBack}
          label={I18n.t('public.layouts.travel_arranger.switch_back')}
          icon={
            <Avatar
              firstName={loggedInUser.firstName}
              lastName={loggedInUser.lastName}
              src={loggedInUser.avatarUrl}
              size="tiny"
            />
          }
        />
      );
    } else if (!currentUserWorkingAsTravelArranger && travelArrangerList.length > 0) {
      content = (
        <Button
          className="top-nav__switch-account--button hidden-xxs hidden-xs hidden-sm hidden-md "
          label={I18n.t('public.layouts.travel_arranger.switch_account')}
          version="v2"
          size="small"
          type="default"
          onClick={() => setIsModalVisible(true)}
        />
      );
    }
    return content;
  };

  const modalTitleContent = (
    <div
      onClick={() => setIsModalVisible(false)}
      onKeyDown={() => setIsModalVisible(false)}
      role="presentation"
      className="top-nav__account-switch-modal-title"
    >
      {I18n.t('public.layouts.travel_arranger.switch_account')}
      <Icon name="close" />
    </div>
  );

  return (
    <Fragment>
      {isLoggedIn && chat && chat.chatEnabled && (
        <SidePanel
          closeIcon={<Icon name="arrowForwardAlt" />}
          title={showChatTitle ? I18n.t('chat.title.travel_app') : null}
          body={
            <Chat
              tenant={chat.tenant}
              currentTenantLogoUrl={chat.currentTenantLogoUrl}
              authToken={chat.authToken}
              currentUserId={chat.currentUserId}
              currentUserName={chat.currentUserName}
              currentUserAvatar={chat.currentUserAvatar}
              backendURL={chat.backendURL}
              laymanMode={chat.laymanMode}
              isChatOpen={openChat}
              onOpenChat={onOpenChat}
              colors={chat.colors}
              setNotificationCount={setNotificationCount}
              setShowChatTitle={setShowChatTitle}
            />
          }
          isOpened={openChat}
          onClick={() => setOpenChat(false)}
        />
      )}
      <div className="top-nav__account-switch-modal">
        <Modal
          className="top-nav__account-switch-modal-container"
          title={modalTitleContent}
          isModalVisible={isModalVisible}
          onOutsideClick={() => setIsModalVisible(false)}
        >
          <div className="top-nav__switch-account-search col-12 col-bleed-x">
            <Input
              size="tiny"
              placeholder={I18n.t('admin.components.travellers.list.search_placeholder')}
              preIcon={<Icon name="search" />}
              value={searchArranger}
              onChange={value => setSearchParams(value)}
            />
          </div>
          <div className="confirm-pending-cancel col-12 col-bleed-x top-nav__switch-account">
            <ul className="top-nav__switch-account--styled-scrollbar">
              {filteredTravelArrangerList.map(travelArranger => (
                <li className="top-nav__switch-account-links">
                  <div className="top-nav__switch-account-links--position">
                    <span>
                      <span className="top-nav__switch-account-name">
                        <Avatar
                          src={travelArranger.avatarUrl}
                          firstName={travelArranger.firstName}
                          middleName={travelArranger.middleName || ''}
                          lastName={travelArranger.lastName}
                          size="tiny"
                          showName={true}
                        />
                      </span>

                      <EmphasisTag text={travelArranger.level} size="tiny" type="neutral" />
                    </span>
                    <span className="top-nav__switch-account-email">{travelArranger.email}</span>
                  </div>

                  <Button
                    className="confirm-pending-cancel__modal-button"
                    label={I18n.t('public.layouts.travel_arranger.switch_action')}
                    version="v2"
                    size="tiny"
                    type="primary"
                    onClick={() => switchProfile(travelArranger.id)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </Modal>
      </div>

      <div ref={topNavContainer} className="top-nav__container">
        <div className="top-nav__content">
          <div className="top-nav__content-left">
            {isLoggedIn && (
              <Fragment>
                <div className="top-nav__book-button">
                  <Link type="button" size="small" modifier="primary" href={rootPath}>
                    {I18n.t('public.components.top_nav.label.book_now')}
                  </Link>
                </div>
              </Fragment>
            )}

            <div className="top-nav__currency-tag">{currentCurrency}</div>

            <div className="top-nav__language">
              <Icon name="globe" />
              <Menu
                className="top-nav__language-update"
                isVisible={isVisible}
                size="small"
                popOverDirection="bottom-left"
                onOutsideClick={onOutsideClick}
                label={
                  <div className="top-nav__language-update-label">
                    {I18n.t(I18n.locale, { scope: 'locales' })}
                    <div
                      className="top-nav__language-update-icon"
                      onClick={toggleVisibility}
                      role="button"
                      tabIndex={0}
                      onKeyPress={toggleVisibility}
                    >
                      <Icon name="caretDown" size="tiny" />
                    </div>
                  </div>
                }
              >
                {availableLanguagesOptions.map(language => (
                  <MenuItem linkUrl={language.url}>{language.label}</MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          <div className="top-nav__content-right">
            <div className="top-nav__logo">
              <a className="top-nav__logo-link" href={rootPath}>
                {hasOrganization ? (
                  <Fragment>
                    {organizationAvatarUrl ? (
                      <Avatar src={organizationAvatarUrl} />
                    ) : (
                      <Icon name="organization" showBGColor={true} color="warning" />
                    )}
                    {showTooltip ? (
                      <Tooltip
                        content={organizationName}
                        type="important"
                        size="small"
                        position="bottom-left"
                      >
                        <span className="top-nav__org-name">{organizationName}</span>
                      </Tooltip>
                    ) : (
                      <span className="top-nav__org-name" ref={organizationNameRef}>
                        {organizationName}
                      </span>
                    )}
                  </Fragment>
                ) : (
                  <div className="top-nav__tenant-logo">
                    <img src={tenantLogoUrl} alt="tenantLogo" />
                  </div>
                )}
              </a>
            </div>

            <div className="top-nav__content-account-panel">
              {isLoggedIn ? (
                <Fragment>
                  <div
                    className={classNames('top-nav__chat-icon', {
                      'top-nav__chat-icon--disabled': isChatDisabled,
                    })}
                  >
                    <Tooltip
                      type="disabled"
                      size="tiny"
                      content={I18n.t('chat.title.travel_app')}
                      position="bottom"
                    >
                      <IconButton
                        icon={<Icon name="chat" />}
                        color="default"
                        onClick={() => setOpenChat(true)}
                        disabled={isChatDisabled}
                        badgeCount={notificationCount}
                      />
                    </Tooltip>
                  </div>
                  <Fragment>{switchAccountButtonRender()}</Fragment>
                  <div className="top-nav__avatar-menu">
                    <div className="menu">
                      <div className="menu__item">
                        <div className="top-nav__avatar" onClick={toggleMenu} role="presentation">
                          <Avatar
                            firstName={user.firstName}
                            lastName={user.lastName}
                            src={user.avatarUrl}
                            size="small"
                          />
                        </div>
                        <ul
                          className={classNames('menu__list', {
                            'menu__list--opened': isMenuOpen,
                          })}
                        >
                          <MenuItem linkUrl={dashboardUrl}>
                            {I18n.t('public.application.account_navigation.dashboard')}
                          </MenuItem>
                          <MenuItem linkUrl={tripsUrl}>
                            {I18n.t('public.application.account_navigation.trips')}
                          </MenuItem>
                          {hasOrganization && (
                            <MenuItem linkUrl={approvalRequestsUrl}>
                              {I18n.t('public.application.account_navigation.approvals')}
                            </MenuItem>
                          )}
                          <MenuItem linkUrl={travelArrangersUrl}>
                            {I18n.t('public.application.account_navigation.travel_arrangers')}
                          </MenuItem>
                          {corporateTravelUrl && (
                            <MenuItem linkUrl={corporateTravelUrl}>
                              {I18n.t('public.application.account_navigation.corporate_travel')}
                            </MenuItem>
                          )}
                          <MenuItem linkUrl={mobileAppUrl}>
                            {I18n.t('public.application.account_navigation.mobile_app')}
                          </MenuItem>
                          <MenuItem linkUrl={signOutUrl}>
                            {I18n.t('public.components.top_nav.label.sign_out')}
                          </MenuItem>
                        </ul>
                      </div>
                    </div>

                    <IconButton
                      className="top-nav__drawer-trigger"
                      icon={
                        <Avatar
                          firstName={user.firstName}
                          lastName={user.lastName}
                          src={user.avatarUrl}
                          size="small"
                        />
                      }
                      isIconOnly={true}
                      onClick={onBurgerMenuClickHandler}
                    />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="top-nav__sign-up-button">
                    <Link href={signUpPath} type="button" size="small" modifier="default">
                      {I18n.t('public.components.top_nav.label.sign_up')}
                    </Link>
                  </div>
                  <div className="top-nav__log-in-button">
                    <Link href={signInPath} type="button" size="small" modifier="primary">
                      {I18n.t('public.components.top_nav.label.log_in')}
                    </Link>
                  </div>

                  <IconButton
                    className="top-nav__drawer-trigger"
                    size="large"
                    icon={<Icon name="hamburgerMenu" />}
                    onClick={onBurgerMenuClickHandler}
                    isIconOnly={true}
                  />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames('top-nav-drawer grid align-content-start hidden-lg hidden-xlg', {
          'top-nav-drawer--visible': isTopNavAsSideDrawerVisible,
        })}
      >
        {isLoggedIn && (
          <Fragment>
            <Link
              className="col-12 top-nav-drawer__item"
              type="text"
              modifier="default"
              href={rootPath}
            >
              {I18n.t('public.components.top_nav.label.book_now')}
            </Link>
            <Link
              className="col-12 top-nav-drawer__item"
              type="anchor"
              modifier="default"
              disabled="true"
            >
              {I18n.t('public.components.top_nav.label.request_quote')}
            </Link>
          </Fragment>
        )}
        <div className="col-12 top-nav-drawer__item">
          {`${I18n.t('public.components.top_nav.label.currency')}: `}
          <EmphasisTag text={currentCurrency} type="neutral" />
        </div>
        <div className="col-12 top-nav-drawer__item">
          <Button
            className="top-nav-drawer__item-button"
            version="v2"
            label={
              <Fragment>
                <span>{`${I18n.t('public.components.top_nav.label.language')}: ${
                  I18n.locale
                }`}</span>
                {availableLanguagesOptions.length > 0 && (
                  <Icon name="iconDownChevron" rotate={isLanguageAccordionVisible ? 180 : 360} />
                )}
              </Fragment>
            }
            onClick={languageAccordionButtonClickHandler}
          />
          {availableLanguagesOptions.length > 0 && (
            <ul
              className={classNames('top-nav-drawer__sub-nav', {
                'top-nav-drawer__sub-nav--visible': isLanguageAccordionVisible,
              })}
            >
              {availableLanguagesOptions.map(language => (
                <Link
                  className="col-12 top-nav-drawer__sub-nav-item"
                  type="text"
                  modifier="default"
                  href={language.url}
                >
                  {language.label}
                </Link>
              ))}
            </ul>
          )}
        </div>
        <div className="top-nav-drawer__divider" />
        {isLoggedIn && (
          <Fragment>
            <Link className="col-12 top-nav-drawer__item" modifier="default" href={dashboardUrl}>
              {I18n.t('public.application.account_navigation.dashboard')}
            </Link>
            <Link className="col-12 top-nav-drawer__item" modifier="default" href={tripsUrl}>
              {I18n.t('public.application.account_navigation.trips')}
            </Link>
            <Link
              className="col-12 top-nav-drawer__item"
              modifier="default"
              href={approvalRequestsUrl}
            >
              {I18n.t('public.application.account_navigation.approvals')}
            </Link>
            <Link
              className="col-12 top-nav-drawer__item"
              modifier="default"
              href={travelArrangersUrl}
            >
              {I18n.t('public.application.account_navigation.travel_arrangers')}
            </Link>
            {corporateTravelUrl && (
              <Link
                className="col-12 top-nav-drawer__item"
                modifier="default"
                href={corporateTravelUrl}
              >
                {I18n.t('public.application.account_navigation.corporate_travel')}
              </Link>
            )}
            <Link className="col-12 top-nav-drawer__item" modifier="default" href={mobileAppUrl}>
              {I18n.t('public.application.account_navigation.mobile_app')}
            </Link>
            <div className="top-nav-drawer__divider" />
          </Fragment>
        )}
        {isLoggedIn ? (
          <Fragment>
            {currentUserWorkingAsTravelArranger ? (
              <Link className="col-12 top-nav-drawer__item" type="text" onClick={switchBack}>
                <Avatar src={loggedInUser.avatarUrl} size="tiny" />{' '}
                {I18n.t('public.layouts.travel_arranger.switch_back')}
              </Link>
            ) : (
              <Link
                className="col-12 top-nav-drawer__item"
                type="text"
                onClick={() => setIsModalVisible(true)}
              >
                {I18n.t('public.layouts.travel_arranger.switch_account')}
              </Link>
            )}
            <Link
              className="col-12 top-nav-drawer__item"
              type="text"
              modifier="default"
              href={signOutUrl}
            >
              {I18n.t('public.components.top_nav.label.sign_out')}
            </Link>
          </Fragment>
        ) : (
          <Fragment>
            <div className="col-12 top-nav-drawer__item">
              <Link href={signUpPath} type="text">
                {I18n.t('public.components.top_nav.label.sign_up')}
              </Link>
            </div>
            <div className="col-12 top-nav-drawer__item">
              <Link href={signInPath} type="text" modifier="default">
                {I18n.t('public.components.top_nav.label.log_in')}
              </Link>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

TopNav.defaultProps = {
  tenantLogoUrl: null,
  organizationName: null,
  organizationAvatarUrl: null,
};

TopNav.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }).isRequired,
  currentCurrency: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  availableLanguagesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  signInPath: PropTypes.string.isRequired,
  signUpPath: PropTypes.string.isRequired,
  tenantLogoUrl: PropTypes.string,
  hasOrganization: PropTypes.bool.isRequired,
  organizationName: PropTypes.string,
  organizationAvatarUrl: PropTypes.string,
  menuUrls: PropTypes.shape({
    dashboardUrl: PropTypes.string,
    dealsUrl: PropTypes.string,
    quotesUrl: PropTypes.string,
    tripsUrl: PropTypes.string,
    approvalRequestsUrl: PropTypes.string,
    travelArrangersUrl: PropTypes.string,
    corporateTravelUrl: PropTypes.string,
    mobileAppUrl: PropTypes.string,
    signOutUrl: PropTypes.string,
  }).isRequired,
  chat: PropTypes.shape({
    authToken: PropTypes.string.isRequired,
    tenant: PropTypes.string.isRequired,
    currentTenantLogoUrl: PropTypes.string.isRequired,
    currentUserId: PropTypes.string.isRequired,
    currentUserName: PropTypes.string.isRequired,
    currentUserAvatar: PropTypes.string.isRequired,
    backendURL: PropTypes.string.isRequired,
    laymanMode: PropTypes.bool.isRequired,
    chatEnabled: PropTypes.bool.isRequired,
    colors: PropTypes.shape({
      primaryColor: PropTypes.string,
      textColor: PropTypes.string,
    }).isRequired,
  }).isRequired,
  rootPath: PropTypes.string.isRequired,
  travelArrangerList: PropTypes.shape().isRequired,
  currentUserWorkingAsTravelArranger: PropTypes.bool.isRequired,
  loggedInUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }).isRequired,
};

export default hot(module)(TopNav);
