import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import httpClient from 'agentHTTPClient';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import routes from 'agentRoutes';
import Trip from './Trip';
import './style.scss';

const FlightItem = ({
  journeyElementId,
  journeyElementType,
  showMinimalInfo,
  showFlightSeatsStatus,
  fetchedJourneyElement,
  refetchJourneyElement,
  totalTravelerCount,
  payLater,
  laymanMode,
}) => {
  const [flightJourneyElement, setflightJourneyElement] = useState(fetchedJourneyElement);
  const [isLoading, setIsLoading] = useState(false);

  const fetchJourneyElement = async () => {
    setIsLoading(true);
    const { data } = await httpClient.get(
      routes.api.fetchJourneyElement({
        type: journeyElementType,
        id: journeyElementId,
      }),
    );

    setflightJourneyElement(data);
    setIsLoading(false);
  };

  useEffect(() => {
    setflightJourneyElement(fetchedJourneyElement);
  }, [fetchedJourneyElement]);

  useEffect(() => {
    if ((journeyElementId && !fetchedJourneyElement) || refetchJourneyElement) {
      fetchJourneyElement();
    }
  }, [journeyElementId, journeyElementType, refetchJourneyElement]);

  return (
    <div className="flight-item">
      {isLoading ? (
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={true} />
      ) : (
        flightJourneyElement &&
        flightJourneyElement.trips &&
        flightJourneyElement.trips.map((trip, index) => (
          <Trip
            showFlightSeatsStatus={showFlightSeatsStatus}
            trip={trip}
            key={trip.id}
            index={index}
            flightServices={flightJourneyElement.services}
            showMinimalInfo={showMinimalInfo}
            totalTravelerCount={totalTravelerCount}
            payLater={payLater}
            laymanMode={laymanMode}
          />
        ))
      )}
    </div>
  );
};

FlightItem.defaultProps = {
  showMinimalInfo: false,
  fetchedJourneyElement: null,
  refetchJourneyElement: false,
  showFlightSeatsStatus: false,
  payLater: false,
};

FlightItem.propTypes = {
  flightItem: PropTypes.shape({
    totalAmount: PropTypes.string,
    cancelPenaltyCurrency: PropTypes.string,
    cancelPenaltyAmount: PropTypes.string,
    refundable: PropTypes.bool,
    status: PropTypes.string,
    identifier: PropTypes.string,
    title: PropTypes.string,
    channel: PropTypes.string,
    pnrPath: PropTypes.string,
    groupLabel: PropTypes.string,
    journeyElementId: PropTypes.number,
    journeyElementType: PropTypes.string,
  }).isRequired,
  travellers: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  journeyElementId: PropTypes.number.isRequired,
  journeyElementType: PropTypes.string.isRequired,
  showMinimalInfo: PropTypes.bool,
  fetchedJourneyElement: PropTypes.shape({}),
  refetchJourneyElement: PropTypes.bool,
  showFlightSeatsStatus: PropTypes.bool,
  totalTravelerCount: PropTypes.number.isRequired,
  payLater: PropTypes.bool,
  laymanMode: PropTypes.bool.isRequired,
};
export default hot(module)(FlightItem);
