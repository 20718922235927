import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { v4 as uuidv4 } from 'uuid';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import './styles.scss';

const googleCallbackName = `autoCompletePlaces_${uuidv4()}`;

const LocationSearchInput = ({
  onAddressChange,
  onLatitudeChange,
  onLongitudeChange,
  address,
  label,
  icon,
  isRequired,
  error,
  placeholder,
  onSelect,
  size,
  selectedLocation,
  ...other
}) => {
  window.googleMapsCallBacks.push(googleCallbackName);
  const [inputValue, setInputValue] = useState('');

  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => {
    const inputProps = getInputProps();
    const { onChange } = inputProps;

    delete inputProps.onChange;

    const onInputChange = value => {
      onChange({ target: { value } });
      setInputValue(value);
    };

    return (
      <div className="location-search-input">
        <div className="location-search-input__input">
          <Input
            label={label}
            size={size}
            value={address}
            required={isRequired}
            placeholder={placeholder}
            preIcon={<Icon name={icon} />}
            error={error}
            touched={!!error}
            onChange={value => {
              onInputChange(value);
            }}
            {...inputProps}
            {...other}
          />
        </div>
        {suggestions.length > 0 && (
          <div className="location-search-input__suggestion">
            {suggestions.map(suggestion => {
              const className = 'location-search-input__suggestion-key';

              return (
                <div {...getSuggestionItemProps(suggestion, { className })}>
                  <div className="d-flex">
                    <Icon name="location" size="normal" />
                    <div className="location-search-input__suggestion-key__container">
                      <div className="location-search-input__suggestion-key__container--header">
                        {suggestion.formattedSuggestion.mainText}
                      </div>
                      <div className="location-search-input__suggestion-key__container--sub-header">
                        {suggestion.formattedSuggestion.secondaryText}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {inputValue.length > 3 && !selectedLocation && suggestions.length === 0 && (
          <div className="location-search-input__suggestion">
            <div className="location-search-input__suggestion-key--loader">
              {I18n.t('components.ibe.search_form.hotel.label.loading')}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleChange = changedAddress => {
    onAddressChange(changedAddress);
    onLatitudeChange(undefined);
    onLongitudeChange(undefined);
  };

  const handleSelect = async selected => {
    const response = await geocodeByAddress(selected);
    const { lat, lng } = await getLatLng(response[0]);
    onLatitudeChange(lat);
    onLongitudeChange(lng);
    onAddressChange(selected);
    onSelect(selected, lat, lng);
  };

  return (
    <PlacesAutocomplete
      onChange={handleChange}
      value={address}
      onSelect={value => handleSelect(value)}
      shouldFetchSuggestions={!!address && address.length > 2}
      googleCallbackName={googleCallbackName}
    >
      {renderFunc}
    </PlacesAutocomplete>
  );
};

LocationSearchInput.defaultProps = {
  placeholder: null,
  isRequired: null,
  error: null,
  size: 'tiny',
  onSelect: () => {},
  selectedLocation: 123,
  icon: 'location',
};

LocationSearchInput.propTypes = {
  onAddressChange: PropTypes.func.isRequired,
  onLatitudeChange: PropTypes.func.isRequired,
  onLongitudeChange: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  onSelect: PropTypes.func,
  size: PropTypes.string,
  selectedLocation: PropTypes.number,
  icon: PropTypes.string,
};

export default LocationSearchInput;
