import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { stopAsyncValidation, startAsyncValidation } from 'redux-form';
import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';

const asyncPhoneNumberValidate = (formValue, formName, dispatch) => {
  if (!formValue) return null;

  dispatch(startAsyncValidation(formName));

  const phoneErrors = formValue.travelers.map(traveler => {
    const number = traveler.phone.value;
    if (number) {
      return httpClient
        .get(routes.validations.phoneNumber({ number }))
        .then(response => {
          if (response.data.message) {
            return {
              phone: <I18nText id="ibe.validations.phone_number" />,
            };
          }
          return { phone: response.data.message };
        })
        .catch(() => {
          const netWorkError = {
            phone: <I18nText id="ibe.async_validations.network_error" />,
          };
          return netWorkError;
        });
    }
    return null;
  });
  return Promise.all(phoneErrors)
    .then(values => {
      const errors = { travelers: values };
      return errors;
    })
    .then(allPhoneErrors => dispatch(stopAsyncValidation(formName, allPhoneErrors)));
};

export default asyncPhoneNumberValidate;
