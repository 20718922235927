import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';

const tagTypeMapper = {
  booked: 'success',
  quote: 'important',
  expired: 'danger',
  confirmed: 'success',
  open: 'warning',
  issued: 'success',
  cancelled: 'disabled',
  refund_quoted: 'success',
  refunded: 'success',
  pending: 'warning',
  voided: 'disabled',
  unknown: 'neutral',
  denied: 'danger',
};
const ItemStateEmphasisTag = ({ className, status, tagSize }) => {
  const statusMapper = {
    booked: I18n.t('admin.components.orders.items_tab.label.booked'),
    confirmed: I18n.t('admin.components.orders.items_tab.label.booked'),
    quote: I18n.t('admin.components.orders.items_tab.label.quote'),
    expired: I18n.t('admin.components.orders.items_tab.label.expired'),
    open: I18n.t('admin.components.orders.items_tab.label.open'),
    issued: I18n.t('admin.components.orders.items_tab.label.booked'),
    cancelled: I18n.t('admin.components.orders.items_tab.label.cancelled'),
    refund_quoted: I18n.t('admin.components.orders.items_tab.label.refund_quoted'),
    refunded: I18n.t('admin.components.orders.items_tab.label.refunded'),
    pending: I18n.t('admin.components.orders.items_tab.label.pending'),
    voided: I18n.t('admin.components.orders.items_tab.label.voided'),
    unknown: I18n.t('admin.components.orders.items_tab.label.unknown'),
    denied: I18n.t('admin.components.orders.items_tab.label.denied'),
  };

  return (
    <EmphasisTag
      className={className}
      text={statusMapper[status]}
      type={tagTypeMapper[status]}
      size={tagSize}
    />
  );
};

ItemStateEmphasisTag.defaultProps = {
  className: '',
  tagSize: 'tiny',
};

ItemStateEmphasisTag.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  tagSize: PropTypes.string,
};

export default ItemStateEmphasisTag;
