import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag, I18nText, Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import { Table, TableBody, TableData, TableHeader, TableHead, TableRow } from '@wtag/rcl-table';
import TablePlaceHolder from 'sharedWebpack/Placeholder/TablePlaceholder';
import PreciseDateWithIcon from 'sharedWebpack/PreciseDateWithIcon';
import EmptyTableContent from 'sharedWebpack/EmptyTableContent';
import Tooltip from '@wtag/rcl-tooltip';
import truncateString from '../../../../shared/truncateString';
import '../../../../admin/Organizations/styles.scss';

const VoucherRow = ({ voucher }) => {
  const traveller = voucher.traveller;
  const title = truncateString(voucher.title, 15);

  const voucherStateType = voucherState => {
    switch (voucherState) {
      case 'used':
        return 'success';
      default:
        return 'disabled';
    }
  };

  return (
    <TableRow key={voucher.id} className="voucher-row">
      <TableData>
        <EmphasisTag number={voucher.id} size="small" radius="oval" />
      </TableData>
      <TableData>
        <div className="voucher-row__title">{title}</div>
      </TableData>
      <TableData>
        <Link href={traveller.url} type="icon-button">
          <Avatar
            size="tiny"
            firstName={traveller.firstName}
            lastName={traveller.lastName}
            src={traveller.avatarUrl}
            tooltip={<Tooltip content={traveller.name} type="inverse" />}
          />
        </Link>
      </TableData>
      <TableData>
        {voucher.departureDate ? (
          <PreciseDateWithIcon date={voucher.departureDate} />
        ) : (
          <div className="voucher-row__no-information">{I18n.t('admin.shared.no_information')}</div>
        )}
      </TableData>
      <TableData>
        {voucher.returnDate ? (
          <PreciseDateWithIcon date={voucher.returnDate} />
        ) : (
          <div className="voucher-row__no-information">{I18n.t('admin.shared.no_information')}</div>
        )}
      </TableData>
      <TableData>
        <EmphasisTag
          text={I18n.t(voucher.state, { scope: 'admin.order_items.voucher_state' })}
          size="tiny"
          radius="oval"
          type={voucherStateType(voucher.state)}
        />
      </TableData>
      <TableData align="center">
        <div className="col-grid col-bleed direction-row justify-center align-center">
          <a href={voucher.urls.pdfDownload}>
            <IconButton icon={<Icon size="tiny" name="download" />} version="v2" color="tertiary" />
          </a>
          <IconButton
            version="v2"
            color="tertiary"
            icon={<Icon name="show" />}
            standalone={true}
            size="small"
            disabled={true}
          />
        </div>
      </TableData>
    </TableRow>
  );
};

VoucherRow.propTypes = {
  voucher: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    traveller: PropTypes.shape({
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      name: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
      url: PropTypes.string.isRequired,
    }).isRequired,
    departureDate: PropTypes.string,
    returnDate: PropTypes.string,
    urls: PropTypes.shape({
      pdfDownload: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const VouchersTable = ({ vouchers, loading, tableColumnForPlaceHolder }) =>
  !loading && !vouchers.length ? (
    <EmptyTableContent />
  ) : (
    <Table fullWidth={true}>
      <TableHead>
        <TableRow>
          <TableHeader>{I18n.t('activerecord.attributes.order_item.id')}</TableHeader>
          <TableHeader>{I18n.t('activerecord.attributes.order_item.title')}</TableHeader>
          <TableHeader>{I18n.t('activerecord.attributes.order_item.traveller')}</TableHeader>
          <TableHeader>{I18n.t('admin.components.vouchers.table.departure')}</TableHeader>
          <TableHeader>{I18n.t('admin.components.vouchers.table.return')}</TableHeader>
          <TableHeader>{I18n.t('activerecord.attributes.order_item.state')}</TableHeader>
          <TableHeader align="center">
            <I18nText id="shared.action" returnStringOnly={true} />
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TablePlaceHolder numberOfColumn={tableColumnForPlaceHolder} numberOfRows={10} />
        ) : (
          vouchers.map(voucher => <VoucherRow key={voucher.id} voucher={voucher} />)
        )}
      </TableBody>
    </Table>
  );

VouchersTable.propTypes = {
  vouchers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  tableColumnForPlaceHolder: PropTypes.number.isRequired,
};

export default VouchersTable;
