import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DateTime from 'sharedWebpack/DateTime';
import { connect } from 'react-redux';
import { fetchAirportDetails } from '../../../actions/flight';
import './style.scss';

const getAirportDetailsText = ({ name, placeName, country }) => {
  let airportDetailsText = '';

  if (name) {
    airportDetailsText = name;

    if (placeName && country) {
      airportDetailsText += `, ${placeName}, ${country}`;
    }
  }

  return airportDetailsText;
};

const AirportStandardDetails = ({ airports, code, localTime, fetchAirportDetail }) => {
  const airport = airports[code];
  const name = airport && airport.name;
  const placeName = airport && airport.placeName;
  const country = airport && airport.countryName;
  const flag = airport && airport.countryFlag;

  useEffect(() => {
    if (!airport) {
      fetchAirportDetail(code);
    }
  }, [airport]);

  const airportDetailsText = getAirportDetailsText({ name, placeName, country });

  return (
    <div className="airport-standard-details">
      <div className="col-grid col-bleed">
        <div className="airport-standard-details__date">
          <DateTime dateTime={localTime} format="onlytime" />
          <DateTime dateTime={localTime} format="short_year" />
        </div>
      </div>
      <div className="col-grid col-bleed direction-row align-center">
        <span className="airport-standard-details__airport-code">{code}</span>
        <img className="airport-standard-details__country-flag" src={flag} alt={country} />
      </div>
      {!!airportDetailsText && (
        <div className="col-grid col-bleed hidden-sm-down">
          <div className="airport-standard-details__airport-name">{airportDetailsText}</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  airports: state.common.airports,
});

const mapDispatchToProps = dispatch => ({
  fetchAirportDetail: code => dispatch(fetchAirportDetails(code)),
});

AirportStandardDetails.defaultProps = {
  airports: {},
};

AirportStandardDetails.propTypes = {
  code: PropTypes.string.isRequired,
  localTime: PropTypes.string.isRequired,
  airports: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      countryName: PropTypes.string.isRequired,
      countryFlag: PropTypes.string.isRequired,
      placeName: PropTypes.string.isRequired,
    }),
  ),
  fetchAirportDetail: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AirportStandardDetails);
