import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'sharedWebpack/Alert';
import Icon from '@wtag/rcl-icon';
import Tooltip from '@wtag/rcl-tooltip';
import visaAdvisoryLabelType from '../../helpers/visaAdvisoryLabelType';

class VisaAdvisories extends React.Component {
  componentDidMount() {
    this.props.fetchVisaAdvisory(this.props.citizenshipCountryCode);
  }

  componentDidUpdate(prevProps) {
    if (this.props.citizenshipCountryCode !== prevProps.citizenshipCountryCode) {
      this.props.fetchVisaAdvisory(this.props.citizenshipCountryCode);
    }
  }

  visaRequirementAllowedStay = allowedStay => {
    if (!allowedStay) {
      return null;
    }

    return I18n.t('components.ibe.visa_advisory.allowed_stay', { count: allowedStay });
  };

  render() {
    let visaAdvisories;
    let isVisaAdvisoryUnavailable;

    if (this.props.visaAdvisoriesByCitizenshipAndDestination[this.props.citizenshipCountryCode]) {
      visaAdvisories = Object.values(
        this.props.visaAdvisoriesByCitizenshipAndDestination[this.props.citizenshipCountryCode],
      );
      isVisaAdvisoryUnavailable = visaAdvisories.every(
        advisory => advisory.citizenshipCountryCode === null,
      );
    }

    return (
      <div className="col-12 col-bleed visa-advisory">
        {visaAdvisories && isVisaAdvisoryUnavailable && (
          <Alert isVisible={true} type="warning" hideClose={true}>
            {I18n.t('components.ibe.visa_advisory.unavailable')}
          </Alert>
        )}
        {visaAdvisories &&
          !isVisaAdvisoryUnavailable &&
          visaAdvisories.map(
            visaAdvisory =>
              visaAdvisory.requirements &&
              visaAdvisory.requirements.map(requirement => (
                <Alert
                  isVisible={true}
                  type={visaAdvisoryLabelType(requirement.visaCategory)}
                  hideClose={true}
                >
                  <div className="visa-advisory__info">
                    <span className="visa-advisory__flag">
                      <img src={visaAdvisory.flagUrls.medium} alt="country-flag" />
                    </span>
                    <span className="visa-advisory__policy">{requirement.visaPolicy}</span>
                    <span className="visa-advisory__legal">
                      <Tooltip
                        className="visa-advisory__tooltip"
                        content={I18n.t('components.ibe.visa_advisory.legal_text')}
                        type="important"
                        position={window.innerWidth < 375 ? 'top-right' : 'top'}
                      >
                        <Icon name="invalidOutline" color="default" />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="visa-advisory__notes">
                    {this.visaRequirementAllowedStay(requirement.allowedStay)}
                    {visaAdvisory && visaAdvisory.notes && (
                      <div className="visa-advisory__notes-tooltip">
                        <Tooltip
                          className="visa-advisory__tooltip"
                          content={visaAdvisory.notes}
                          type="important"
                          position="top"
                        >
                          <Icon name="invalidOutline" color="default" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </Alert>
              )),
          )}
      </div>
    );
  }
}

VisaAdvisories.propTypes = {
  visaAdvisoriesByCitizenshipAndDestination: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.shape({
        citizenshipCountryCode: PropTypes.string,
        destinationCountryCode: PropTypes.string,
        notes: PropTypes.string,
        requirements: PropTypes.arrayOf(
          PropTypes.shape({
            allowedStay: PropTypes.string,
            visaPolicy: PropTypes.string,
            visaCategory: PropTypes.string,
          }),
        ),
        flagUrls: PropTypes.shape({
          small: PropTypes.string,
          medium: PropTypes.string,
          large: PropTypes.string,
        }),
      }),
    ).isRequired,
  ).isRequired,
  citizenshipCountryCode: PropTypes.string.isRequired,
  fetchVisaAdvisory: PropTypes.func.isRequired,
};

export default VisaAdvisories;
