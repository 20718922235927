import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CheckBoxFilter } from '@wtag/react-comp-lib';

const OrderFilterWrapper = props => {
  const { onChange, options, selectedValues, label, placeholderText, onSearch, searchable } = props;

  const [localOptions, setLocalOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchParam, setSearchParam] = useState('');

  const handleSelectedOption = selectedOption => {
    const remainingOptions = [...selectedOptions];

    if (remainingOptions.includes(selectedOption)) {
      const indexOfSelectedOption = selectedOptions.indexOf(selectedOption);
      remainingOptions.splice(indexOfSelectedOption, 1);
    } else {
      remainingOptions.push(selectedOption);
    }

    setSelectedOptions(remainingOptions);
    onChange(remainingOptions.map(remainingOption => remainingOption.value));
  };

  const handleSearch = query => {
    onSearch(query);
    setSearchParam(query);
  };

  useEffect(() => {
    if (options.length > 0) {
      let list = [...options, ...selectedOptions];
      list = list.filter(
        (item, index, array) => array.findIndex(obj => obj.value === item.value) === index,
      );
      setLocalOptions(list);

      const uniqueSelectedValues = selectedValues.filter(
        (item, index, array) => array.indexOf(item) === index,
      );
      setSelectedOptions(
        uniqueSelectedValues.map(
          value => list.filter(option => option.value.toString() === value)[0],
        ),
      );
    } else {
      setLocalOptions(options);
    }
  }, [options, selectedValues]);

  return (
    <CheckBoxFilter
      label={label}
      placeholderText={placeholderText}
      onChange={option => handleSelectedOption(option)}
      options={localOptions}
      isSearchable={searchable}
      searchParam={searchParam}
      onSearch={handleSearch}
      selectedOptions={selectedOptions}
      popoverMenuPosition="bottom-left"
    />
  );
};

OrderFilterWrapper.defaultProps = {
  placeholderText: null,
  onSearch: () => {},
  searchable: true,
};

OrderFilterWrapper.propTypes = {
  placeholderText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  label: PropTypes.node.isRequired,
  searchable: PropTypes.bool,
};

export default OrderFilterWrapper;
