import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';

const ChannelListTag = ({ channels, type, identifier }) => {
  const channelsListText = identifier || channels.join(' | ');

  return <EmphasisTag size="tiny" text={channelsListText} type={type} />;
};

ChannelListTag.defaultProps = {
  type: null,
  identifier: null,
};

ChannelListTag.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string,
  identifier: PropTypes.string,
};

export default ChannelListTag;
