import React from 'react';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import PropTypes from 'prop-types';
import AccountingInformation from './AccountingInformation';

const GroupedAccounting = ({ accounting, scope, fetchAccountings, actionsEnabled, orderId }) => (
  <div className="order-accounting-tab__card">
    <Card version="v2" key={accounting.accountingType.id}>
      <div className="col-grid col-bleed direction-row align-center order-accounting-tab__accounting-type">
        <div className="order-accounting-tab__accounting-type-icon">
          {/* TODO: change placeholder icon when icon available in RCL */}
          <Icon name="invoice" showBGColor={true} color="tertiary" size="normal" />
        </div>
        <div className="col-grid col-bleed-y">
          <div className="order-accounting-tab__accounting-type-title">
            {accounting.accountingType &&
              I18n.t(accounting.accountingType.costcenterableType.toLowerCase(), {
                scope: 'admin.components.orders.accounting_tab.accounting_type_title',
                name: accounting.accountingType.name,
              })}
          </div>
          <div className="order-accounting-tab__subtitle">
            {I18n.t('admin.components.orders.accounting_tab.total_percentage')}
          </div>
        </div>
      </div>
      {accounting.costCenters.map(costCenter => (
        <AccountingInformation
          accounting={costCenter}
          scope={scope}
          fetchAccountings={fetchAccountings}
          actionsEnabled={actionsEnabled}
          orderId={orderId}
        />
      ))}
    </Card>
  </div>
);

GroupedAccounting.defaultProps = {
  accounting: {},
  scope: 'admin',
  actionsEnabled: true,
};

GroupedAccounting.propTypes = {
  accounting: PropTypes.shape({
    accountingType: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      costcenterableType: PropTypes.string,
    }),
    costCenters: PropTypes.arrayOf({
      accountingValue: PropTypes.string,
      accountingValueSource: PropTypes.string,
      allocatedPercentage: PropTypes.string,
      costcenterableType: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
  scope: PropTypes.string,
  fetchAccountings: PropTypes.func.isRequired,
  actionsEnabled: PropTypes.bool,
  orderId: PropTypes.number.isRequired,
};

export default GroupedAccounting;
