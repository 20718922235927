/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { I18nText, Spinner } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import StackedItems from '@wtag/rcl-stacked-items';
import Popover from '@wtag/rcl-popover';
import RclLink from '@wtag/rcl-link';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Amount from 'sharedWebpack/Amount';
import ProductIcon from 'sharedWebpack/ProductIcon';
import TimeSelectBox from 'sharedWebpack/TimeSelectBox';
import Alert from 'sharedWebpack/Alert';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import QuantitySelector from '@wtag/rcl-quantity-selector';
import Icon from '@wtag/rcl-icon';
import DatePickerWithFocusedStateWrapper from 'sharedWebpack/DatePickerWithFocusedStateWrapper';
import { RTabs } from '@wtag/rcl-rtabs';
import SelectBox from '@wtag/rcl-select-box';
import Modal from 'sharedWebpack/Modal';
import { TYPE_STATIC_PRODUCT } from 'sharedWebpack/helpers/itemTypes';
import searchURL from '../lib/helpers/searchURL';
import { stringifyQueryString } from '../helpers/qsMethods';
import SearchAccounts from '../components/SearchAccounts';
import OfferEnquiryForm from './OfferEnquiryForm';
import TopDeals from '../components/TopDeals';
import SelectTravelerCount from './SelectTravelerCount';
import LineItem from './LineItemContainer';
import SearchName from '../components/SearchName';
import Fees from './FeesContainer';
import DealTravelPlan from '../Deal/DealTravelPlan';
import DealLoader from './DealLoader';
import { multiSearchResultsSelected } from '../actions/common';
import { cabinClassOptions } from '../Search/flight/helpers/variousOptionsProvider';
import findLabelValue from '../helpers/findLabelValue';

class MultiSearch extends React.Component {
  constructor(props) {
    super(props);
    this.STATES = ['open', 'searching', 'confirming', 'available'];
    this.state = {
      selectedTabKey: 0,
      flightSelectAll: true,
      hotelSelectAll: true,
      carSelectAll: true,
      copied: false,
      isModalVisible: false,
      enquireModalOpen: false,
      selectedItems: [],
      isHeaderVisible: true,
      isPopoverVisible: false,
      isDealPopoverVisible: false,
    };
  }

  onCopy() {
    this.setState({ copied: true });
  }

  onResultSelect = (groupKey, resultId) => {
    this.props.multiSearchResultSelected(groupKey, resultId.toString());
  };

  onResultDeSelect = (groupKey, resultId) => {
    this.props.multiSearchResultDeSelected(groupKey, resultId.toString());
  };

  onModalClose = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  onModalOpen = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  onEnquireModalClose = () => {
    this.setState({
      enquireModalOpen: false,
    });
  };

  onEnquireModalOpen = () => {
    this.setState({
      enquireModalOpen: true,
      isHeaderVisible: true,
    });
  };

  setSelectedTabKeyOnClick = tabIndex => {
    this.setState({ selectedTabKey: tabIndex });
  };

  updateOptions(value) {
    let currentValues = [...this.state.selectedItems];

    if (currentValues.includes(value)) {
      currentValues = currentValues.filter(item => item !== value);
    } else currentValues.push(value);

    this.setState({ selectedItems: currentValues });
  }

  toggleSelectAll = itemType => {
    if (itemType === 'flight') this.setState({ flightSelectAll: !this.state.flightSelectAll });
    else if (itemType === 'hotel') this.setState({ hotelSelectAll: !this.state.hotelSelectAll });
    else this.setState({ carSelectAll: !this.state.carSelectAll });
  };

  readyToCheckAvailability = () => {
    const numOfGroups = Object.values(this.props.groups).length;
    const numOfResults = Object.values(this.props.selectedResults).length;

    return numOfGroups === numOfResults;
  };

  activeStep = key => {
    const state = this.props.status[key];
    return this.STATES.indexOf(state);
  };

  minDate = lastTripDate => {
    if (lastTripDate) {
      return lastTripDate;
    }
    return moment();
  };

  updateTravelerCountOnSubmit = (values, dispatch, ownProps) => {
    const { fields } = this.props;
    const { travelersType } = this.props.fields;

    let numOfAdultChildren = travelersType.childrenList.age.filter(
      age => age.value !== '' && age.value > 12,
    ).length;
    numOfAdultChildren += parseInt(travelersType.adults.value, 10);

    fields.adults.onChange(numOfAdultChildren);
    fields.infants.onChange(travelersType.infants.value);
    fields.children.onChange(travelersType.children.value);

    const newTravelerCount = {
      adults: numOfAdultChildren,
      children: travelersType.children.value,
      infants: travelersType.infants.value,
    };
    const finalValues = { ...values, ...newTravelerCount };

    return new Promise(resolve => {
      dispatch(multiSearchResultsSelected(finalValues, ownProps.groups, ownProps.callbackParams));
      resolve();
    });
  };

  render() {
    const groups = [];

    const statusValues = Object.keys(this.props.status).map(e => this.props.status[e]);

    function statusPresent(status) {
      return status === this.toString();
    }

    const isUnAvailable = statusValues.some(statusPresent, 'unavailable');

    const isSearching = statusValues.some(statusPresent, 'searching');

    const isConfirming = statusValues.some(statusPresent, 'confirming');

    const numberOfFlights =
      this.props.journeyElements && Object.keys(this.props.journeyElements.flight).length;

    const numberOfHotels =
      this.props.journeyElements && Object.keys(this.props.journeyElements.hotel).length;

    const numberOfCars =
      this.props.journeyElements && Object.keys(this.props.journeyElements.car).length;

    const pageURL = window.location.href;

    const shareDealLink = (
      <CopyToClipboard text={pageURL} onCopy={() => this.setState({ copied: true })}>
        <span>
          <Icon name="share" color="default" size="small" />
        </span>
      </CopyToClipboard>
    );

    const findTimeSelectBoxValue = value => {
      if (value === 'anytime') {
        return { label: <I18nText id="departure_times.anytime" returnStringOnly={true} />, value };
      }

      return { label: value, value };
    };

    const tabs = [
      {
        key: 0,
        title: <I18nText id="ibe.deal.customize" returnStringOnly={true} />,
        getContent: () => (
          <Fragment>
            <div className="groups">{groups}</div>
            <div className="car-result-card__divider" />
            <div className="grid">
              <div className="col-12 fees">
                <Fees />
              </div>
            </div>
            {!this.context.laymanMode && (
              <Card version="v2" size="full" className="deal__accounts-section">
                <div className="content">
                  <div>
                    <div className="col-12 accounts">
                      <h3>
                        <I18nText id="ibe.search_form.accounts.flights" returnStringOnly={true} />
                      </h3>
                      <div>
                        <SearchAccounts
                          itemType="flight"
                          disabled={
                            this.props.disableForm ||
                            (!this.props.allOpen && !this.props.allSelected)
                          }
                          searchAccounts={this.props.searchAccounts.flights}
                          selectedAccounts={this.props.fields.selectedFlightAccounts}
                          selectAll={this.state.flightSelectAll}
                          toggleSelectAll={this.toggleSelectAll}
                        />
                      </div>
                      <h3>
                        <I18nText id="ibe.search_form.accounts.hotels" returnStringOnly={true} />
                      </h3>
                      <div>
                        <SearchAccounts
                          itemType="hotel"
                          disabled={
                            this.props.disableForm ||
                            (!this.props.allOpen && !this.props.allSelected)
                          }
                          searchAccounts={this.props.searchAccounts.hotels}
                          selectedAccounts={this.props.fields.selectedHotelAccounts}
                          selectAll={this.state.hotelSelectAll}
                          toggleSelectAll={this.toggleSelectAll}
                        />
                      </div>
                      <h3>
                        <I18nText id="ibe.search_form.accounts.cars" returnStringOnly={true} />
                      </h3>
                      <div>
                        <SearchAccounts
                          itemType="car"
                          disabled={
                            this.props.disableForm ||
                            (!this.props.allOpen && !this.props.allSelected)
                          }
                          searchAccounts={this.props.searchAccounts.cars}
                          selectedAccounts={this.props.fields.selectedCarAccounts}
                          selectAll={this.state.carSelectAll}
                          toggleSelectAll={this.toggleSelectAll}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </Fragment>
        ),
      },
      {
        key: 1,
        title: <I18nText id="ibe.deal.travel_plan" returnStringOnly={true} />,
        getContent: () => (
          <DealTravelPlan allSelected={this.props.allSelected} cartId={this.props.cartId} />
        ),
      },
    ];

    const isAllItemStatic = Object.values(this.props.groups).every(
      group => group.type === TYPE_STATIC_PRODUCT,
    );
    const filteredTabs = isAllItemStatic ? [tabs[0]] : tabs;

    if (this.props.fields.groups && Object.keys(this.props.groups).length) {
      Object.keys(this.props.fields.groups).forEach(key => {
        const group = this.props.groups[key];

        if (group.options.length < 1) {
          return;
        }
        const disabled =
          this.props.disableForm ||
          (this.props.status[key] !== 'open' && this.props.status[key] !== 'selected');

        let selectedOptionId = this.props.selectedResults[key];
        if (selectedOptionId) selectedOptionId = selectedOptionId.toString();
        const selectedOption = group.options.find(
          option => option.id.toString() === selectedOptionId,
        );

        const getStaticProductGroup =
          this.props.groups &&
          this.props.groups.find &&
          this.props.groups.find(option => option.type === 'static_product');

        const numberOfProducts =
          getStaticProductGroup &&
          getStaticProductGroup.options &&
          getStaticProductGroup.options.length;

        const hasOneItemPerGroup = [
          numberOfFlights,
          numberOfHotels,
          numberOfCars,
          numberOfProducts,
        ].some(itemCount => itemCount === 1);

        const options = group.options.map(option => {
          const isOptionSelected = selectedOptionId === option.id.toString();

          const isSelectedOption = this.state.selectedItems.includes(option.id);

          const disableOtherOptions =
            selectedOption &&
            selectedOption.type === option.type &&
            selectedOptionId !== option.id.toString();

          const loaderActive =
            (this.props.status[key] === 'searching' || this.props.status[key] === 'confirming') &&
            isOptionSelected;

          const selectedDealUnavailable =
            this.props.status[key] === 'unavailable' && isOptionSelected;

          const buttonSelectedText =
            isSelectedOption || selectedDealUnavailable ? (
              <I18nText id="ibe.deal.deselect" returnStringOnly={true} />
            ) : (
              I18n.t('components.ibe.deal_messages.select')
            );

          const label = (
            <div className="deal-option-description" key={option.id}>
              <LineItem type={group.type} item={option} className="deal-option-description__item" />
            </div>
          );

          const checkOtherResults = selectedDealUnavailable && (
            <div className="deal__unavailable-section">
              <div className="deal__unavailable-section-text">
                <I18nText id="ibe.deal.item_unavailable" returnStringOnly={true} />
              </div>
              <div>
                <Link
                  className="deal__unavailable-section-link"
                  to={{
                    pathname: searchURL(group.type, this.props.searchIds[key]),
                    search: stringifyQueryString({
                      deal: this.props.match.params.id,
                      dealGroup: key,
                    }),
                  }}
                >
                  <I18nText id="ibe.deal.item_other" returnStringOnly={true} />
                </Link>
              </div>
            </div>
          );

          const selectOrDeselect = () =>
            isSelectedOption
              ? this.onResultDeSelect(key, option.id)
              : this.onResultSelect(key, option.id);

          return (
            <div className="col-12 deal-option-description__position" key={option.id}>
              {isOptionSelected && hasOneItemPerGroup ? (
                <Fragment>
                  <span className="deal__item-card-selected-tag">
                    <I18nText id="ibe.deal.selected" returnStringOnly={true} />
                  </span>

                  <Card
                    className="deal__item-card deal__item-card--selected"
                    key={option.id}
                    value={option.id.toString()}
                    hasPadding={true}
                    disabled={disabled}
                    type={group.type}
                    version="v2"
                    size="full"
                  >
                    <div>{label}</div>
                    <div className="deal-option-description__buttons">
                      <div className="deal-option-description__buttons--details" />
                      <div className="deal-option-description--selected">
                        <Button
                          className="deal-option-description__buttons--disabled"
                          id={option.id.toString()}
                          version="v2"
                          size="small"
                          label={<I18nText id="ibe.deal.deselect" returnStringOnly={true} />}
                        />
                      </div>
                    </div>
                    {checkOtherResults}
                  </Card>
                </Fragment>
              ) : (
                <Fragment>
                  {(isSelectedOption || loaderActive) && (
                    <span className="deal__item-card-selected-tag">
                      <I18nText id="ibe.deal.selected" returnStringOnly={true} />
                    </span>
                  )}
                  <Card
                    className={classNames('deal__item-card', {
                      'deal__item-card--selected': isSelectedOption || loaderActive,
                    })}
                    key={option.id}
                    value={option.id.toString()}
                    hasPadding={true}
                    disabled={disabled}
                    type={group.type}
                    version="v2"
                    size="full"
                    selectedItems={this.state.selectedItems}
                  >
                    <div>{label}</div>
                    <div className="deal-option-description__buttons">
                      <div className="deal-option-description__buttons--details" />
                      <div
                        className={classNames({
                          'deal-option-description--selected': isSelectedOption || loaderActive,
                          'deal-option-description--select': !isSelectedOption,
                        })}
                      >
                        <Button
                          id={option.id.toString()}
                          version="v2"
                          size="small"
                          type={(!isSelectedOption || !loaderActive) && 'primary'}
                          label={buttonSelectedText}
                          onClick={() => {
                            selectOrDeselect();
                            this.updateOptions(option.id);
                          }}
                          disabled={
                            !isSelectedOption && (disableOtherOptions || selectedDealUnavailable)
                          }
                        />
                      </div>
                    </div>
                    {checkOtherResults}
                  </Card>
                </Fragment>
              )}
            </div>
          );
        });

        const additionalFields = [];
        if (group.type === 'flight') {
          group.searchParams.trips.forEach((trip, index) => {
            const lastTrip = this.props.fields.groups[key].trips[index - 1];
            if (!this.props.fields.groups[key].trips[index]) {
              return;
            }
            additionalFields.push(
              <div className="deal__flight-controls">
                <div className="deal-option-description__flight-route" key={uuidv4}>
                  <div className="grid">
                    <div className="col-6">
                      <div className="grid">
                        <div className="col-xlg-6 col-lg-7 col-md-6 col-sm-6 col-12 cabin-class__dropdown">
                          <div className="cabin-class__label">
                            <I18nText id="ibe.search_form.cabin_class" returnStringOnly={true} />
                          </div>
                          <SelectBox
                            placeholderText={I18n.t(
                              'components.ibe.search_form.flight.label.cabin_class',
                            )}
                            options={cabinClassOptions()}
                            isClearable={false}
                            onChange={selectedCabinClass =>
                              selectedCabinClass &&
                              this.props.fields.groups[key].trips[index].cabinClass.onChange(
                                selectedCabinClass.value,
                              )
                            }
                            width="full"
                            size="tiny"
                            value={findLabelValue(
                              cabinClassOptions(),
                              this.props.fields.groups[key].trips[index].cabinClass.value,
                            )}
                          />
                        </div>
                        <div className="col-xlg-6 col-lg-5 col-md-6 col-sm-6 col-12 col-bleed-x flight-date__picker">
                          <DatePickerWithFocusedStateWrapper
                            label={<I18nText id="ibe.search_form.date" returnStringOnly={true} />}
                            disabled={disabled}
                            minDate={lastTrip ? this.minDate(lastTrip.date.value) : moment()}
                            {...this.props.fields.groups[key].trips[index].date}
                            locale={moment().locale()}
                            hideNavButtons={false}
                            size="tiny"
                            id={Math.random()}
                            date={this.props.fields.groups[key].trips[index].date.value}
                            required={true}
                            fullWidth={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6" />
                  </div>
                </div>
              </div>,
            );
          });
        } else if (group.type === 'hotel') {
          const numberOfRoomsError =
            this.props.fields.groups[key].numberOfRooms &&
            this.props.fields.groups[key].numberOfRooms.error;

          additionalFields.push(
            <div className="deal__hotel-controls">
              <div className="grid" key={key}>
                <div className="col-10">
                  <div className="grid">
                    <div className="col-xlg-4 col-lg-4 col-md-4 col-sm-4 col-12 flight-date__picker">
                      <DatePickerWithFocusedStateWrapper
                        {...this.props.fields.groups[key].checkIn}
                        disabled={disabled}
                        locale={moment().locale()}
                        hideNavButtons={false}
                        size="tiny"
                        id={Math.random()}
                        label={<I18nText id="ibe.search_form.check_in" returnStringOnly={true} />}
                        minDate={moment()}
                        date={moment(
                          this.props.fields.groups[key].checkIn &&
                            this.props.fields.groups[key].checkIn.value,
                        ).format('YYYY-MM-DD')}
                        required={true}
                      />
                    </div>
                    <div className="col-xlg-4 col-lg-4 col-md-4 col-sm-4 col-12 flight-date__picker col-bleed-x">
                      <DatePickerWithFocusedStateWrapper
                        label={<I18nText id="ibe.search_form.check_out" returnStringOnly={true} />}
                        minDate={
                          this.props.fields.groups[key].checkIn &&
                          this.props.fields.groups[key].checkIn.value
                            ? moment(this.props.fields.groups[key].checkIn.value).add('1', 'days')
                            : moment()
                        }
                        {...this.props.fields.groups[key].checkOut}
                        locale={moment().locale()}
                        disabled={disabled}
                        hideNavButtons={false}
                        size="tiny"
                        id={Math.random()}
                        date={
                          this.props.fields.groups[key].checkOut &&
                          this.props.fields.groups[key].checkOut.value
                        }
                        required={true}
                      />
                    </div>
                    <div className="col-xlg-4 col-lg-4 col-md-4 col-sm-5 col-12 deal__hotel-room col-bleed-x">
                      <div className="deal__hotel-room-count">
                        <I18nText id="ibe.search_form.rooms_count" returnStringOnly={true} />
                      </div>
                      <QuantitySelector
                        {...this.props.fields.groups[key].numberOfRooms}
                        disabled={disabled}
                        min={1}
                        size="tiny"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2" />
                {numberOfRoomsError && (
                  <div className="col-12">
                    <Alert
                      className="deal__alert"
                      hideClose={true}
                      type="warning"
                      isVisible={numberOfRoomsError}
                    >
                      {this.props.fields.groups[key].numberOfRooms.error}
                    </Alert>
                  </div>
                )}
              </div>
            </div>,
          );
        } else if (group.type === 'car') {
          const { value, name, error, onChange, touched } = this.props.fields.groups[key].driverAge;
          additionalFields.push(
            <div className="deal__car-controls">
              <div className="grid" key={key}>
                <div className="col-xlg-2 col-lg-2 col-md-4 col-sm-4 col-12 flight-date__picker">
                  <div className="deal-option-description__driver-age">
                    <Input
                      label={<I18nText id="ibe.search_form.driver_age" returnStringOnly={true} />}
                      key={key}
                      name={name}
                      touched={touched}
                      error={error}
                      value={value || ''}
                      onChange={onChange}
                      disabled={disabled}
                      size="tiny"
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-xlg-3 col-lg-3 col-md-4 col-sm-4 col-12 car-date__picker">
                  <DatePickerWithFocusedStateWrapper
                    id={Math.random()}
                    label={<I18nText id="ibe.search_form.pick_up_date" returnStringOnly={true} />}
                    minDate={moment()}
                    {...this.props.fields.groups[key].pickUpDate}
                    date={this.props.fields.groups[key].pickUpDate.value}
                    locale={moment().locale()}
                    disabled={disabled}
                    hideNavButtons={false}
                    size="tiny"
                    required={true}
                  />
                </div>
                <div className="col-xlg-2 col-lg-2 col-md-4 col-sm-4 col-12 flight-date__picker">
                  <div className="date-picker__label">
                    <I18nText id="ibe.search_form.pick_up_time" returnStringOnly={true} />
                  </div>
                  <div>
                    <TimeSelectBox
                      placeholderText={I18n.t('components.ibe.search_form.car.label.pick_up.time')}
                      width="full"
                      onChange={selectedTime =>
                        selectedTime &&
                        this.props.fields.groups[key].pickUpTime.onChange(selectedTime.value)
                      }
                      errorMsg={
                        this.props.fields.groups[key].pickUpTime &&
                        this.props.fields.groups[key].pickUpTime.touched &&
                        this.props.fields.groups[key].pickUpTime.error
                      }
                      value={findTimeSelectBoxValue(
                        this.props.fields.groups[key].pickUpTime &&
                          this.props.fields.groups[key].pickUpTime.value,
                      )}
                      isClearable={false}
                      size="tiny"
                    />
                  </div>
                </div>
                <div className="col-xlg-3 col-lg-3 col-md-4 col-sm-4 col-12 car-date__picker">
                  <DatePickerWithFocusedStateWrapper
                    id={Math.random()}
                    label={<I18nText id="ibe.search_form.drop_off_date" returnStringOnly={true} />}
                    minDate={
                      this.props.fields.groups[key].pickUpDate.value
                        ? moment(this.props.fields.groups[key].pickUpDate.value).add('1', 'days')
                        : moment()
                    }
                    {...this.props.fields.groups[key].dropOffDate}
                    date={this.props.fields.groups[key].dropOffDate.value}
                    locale={moment().locale()}
                    disabled={disabled}
                    hideNavButtons={false}
                    size="tiny"
                    required={true}
                  />
                </div>

                <div className="col-xlg-2 col-lg-2 col-md-4 col-sm-4 col-12 flight-date__picker">
                  <div className="date-picker__label">
                    <I18nText id="ibe.search_form.drop_off_time" returnStringOnly={true} />
                  </div>
                  <div>
                    <TimeSelectBox
                      placeholderText={I18n.t('components.ibe.search_form.car.label.drop_off.time')}
                      width="full"
                      onChange={selectedTime =>
                        selectedTime &&
                        this.props.fields.groups[key].dropOffTime.onChange(selectedTime.value)
                      }
                      errorMsg={
                        this.props.fields.groups[key].dropOffTime &&
                        this.props.fields.groups[key].dropOffTime.touched &&
                        this.props.fields.groups[key].dropOffTime.error
                      }
                      value={findTimeSelectBoxValue(
                        this.props.fields.groups[key].dropOffTime &&
                          this.props.fields.groups[key].dropOffTime.value,
                      )}
                      isClearable={false}
                      size="tiny"
                    />
                  </div>
                </div>
              </div>
            </div>,
          );
        }

        let title = (
          <SearchName
            airports={this.props.airports}
            type={group.type}
            {...group.searchParams}
            key={key}
            isDealItem={this.props.isDealItem}
          />
        );
        if (selectedOption) {
          title = (
            <span className="deal-group-heading">
              <span className="deal-group-heading__title">{title}</span>
            </span>
          );
        }

        const staticProductTitle = (
          <div className="search-name">
            <div className="search-name__primary-content">
              <ProductIcon productType="static_product" showBGColor={true} size="medium" />
              <I18nText id="ibe.search_name.static" />
            </div>
          </div>
        );

        groups.push(
          <div key={key} className="group">
            {(group.type === 'static_product' && staticProductTitle) || title}
            <div>
              {group.type !== 'static_product' && additionalFields}
              <div className="deal-option-description__options grid options">{options}</div>
            </div>
          </div>,
        );
      });
    }

    const {
      title,
      approvalRequestId,
      person,
      adAmount,
      currency,
      text,
      features,
      categories,
      interests,
      enquireOnly,
      favouriteDealCount, // TODO: To be implemented in a follow-up story, Story Number: AG-7200
      favouriteDealPeople, // TODO: To be implemented in a follow-up story, Story Number: AG-7200
      locations, // TODO: To be implemented in a follow-up story, Story Number: AG-7200
      featureImageGrid,
      imageList,
    } = this.props;

    const filterProductTypes =
      this.props.groups &&
      this.props.groups.map &&
      this.props.groups.map(productType => productType.type);

    const showDealFeatureImages = imageList && imageList.map(image => image.original);

    const dealInterests = interests ? interests.map(value => value.name) : [];

    const unavailableDealMessage = isUnAvailable && (
      <I18nText id="ibe.deal.some_not_available" returnStringOnly={true} />
    );

    const addToFavourite = async id => this.props.markFavouriteDeal(id);

    const deleteFromFavourite = async id => this.props.unmarkFavouriteDeal(id);

    const footerContent = isUnAvailable && (
      <Button
        version="v2"
        size="medium"
        type="primary"
        label={<I18nText id="ibe.deal.retry" returnStringOnly={true} />}
        onClick={this.onModalClose}
      />
    );

    return (
      <div
        className={classNames({
          deal__wrapper: !this.context.laymanMode && !approvalRequestId,
          'deal__approval-required': !this.context.laymanMode && approvalRequestId,
        })}
      >
        {(this.props.id || !approvalRequestId) && !this.props.groups.length ? (
          <DealLoader />
        ) : (
          <Fragment>
            <div className="deal__cover">
              {featureImageGrid && (
                <img
                  src={featureImageGrid}
                  alt={title}
                  className="deal__cover-image deal__cover-photo"
                />
              )}
            </div>
            <div
              className={classNames({
                'container container--sm-full-width deal__wrapper--container': !this.context
                  .laymanMode,
                'container container--sm-full-width': this.context.laymanMode,
              })}
            >
              <form
                onSubmit={this.props.handleSubmit(this.updateTravelerCountOnSubmit)}
                className="deal"
              >
                <div
                  className={classNames({
                    'grid deal__wrapper--position': !this.context.laymanMode,
                    grid: this.context.laymanMode,
                  })}
                >
                  <div className="col-8">
                    <div>
                      <div className="deal__header">
                        <div className="grid">
                          <div className="col-12">
                            <div className="deal__info deal-info">
                              {favouriteDealCount > 0 && (
                                <div className="deal__favorite">
                                  <div>
                                    <StackedItems
                                      className="deal__favorite--stacked-item"
                                      itemSize="tiny"
                                      isPopoverVisible={this.state.isDealPopoverVisible}
                                      onClick={() =>
                                        this.setState({
                                          isDealPopoverVisible: !this.state.isDealPopoverVisible,
                                        })
                                      }
                                      onOutsideClick={() =>
                                        this.setState({ isDealPopoverVisible: false })
                                      }
                                    >
                                      {favouriteDealPeople.map(traveler => (
                                        <Avatar
                                          firstName={traveler.firstName}
                                          lastName={traveler.lastName}
                                          src={traveler.avatarUrl}
                                        />
                                      ))}
                                    </StackedItems>
                                  </div>

                                  <div className="deal__favorite-count">
                                    {I18n.t('components.ibe.deal.favorite_count', {
                                      count: favouriteDealCount,
                                    })}
                                  </div>
                                </div>
                              )}

                              <div className="col-12 deal-info--title">{title}</div>
                            </div>
                            <div className="deal__items">
                              {filterProductTypes &&
                                filterProductTypes.map &&
                                filterProductTypes.map(item => (
                                  <ProductIcon
                                    productType={item}
                                    showBGColor={true}
                                    color="success"
                                    size="small"
                                  />
                                ))}
                              <span className="deal__items--count">
                                {approvalRequestId
                                  ? I18n.t('components.ibe.deal.approval_item', {
                                      count: this.props.groups.length,
                                    })
                                  : I18n.t('components.ibe.deal.items', {
                                      count: this.props.groups.length,
                                    })}
                              </span>
                            </div>
                            <div className="deal__body">
                              <div className="deal__description">{text}</div>
                              <br />

                              {showDealFeatureImages && (
                                <div className="deal__feature-img-wrapper">
                                  {showDealFeatureImages.map(image => (
                                    <div className="deal__feature-img-tile">
                                      <img src={image} alt={title} className="deal__feature-img" />
                                    </div>
                                  ))}
                                </div>
                              )}

                              {!enquireOnly && (
                                <div className="deal__select-traveler-count">
                                  <SelectTravelerCount
                                    {...this.props.fields}
                                    disabled={this.props.disableForm}
                                    approvalRequestId={approvalRequestId}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="deal-option-description__spacing">
                      <RTabs
                        items={filteredTabs}
                        selectedTabKey={this.state.selectedTabKey}
                        onChange={this.setSelectedTabKeyOnClick}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className={classNames({
                        'deal__side-panel': !approvalRequestId,
                        'deal__side-panel--approval-required': approvalRequestId,
                      })}
                    >
                      <Card version="v2" size="full" className="deal__side-panel-info-card">
                        <div className="deal__side-panel-actions">
                          <Popover
                            className="deal__side-panel-actions-popover"
                            isVisible={this.state.isPopoverVisible}
                            onOutsideClick={() => this.setState({ isPopoverVisible: false })}
                            size="small"
                            content={
                              this.state.copied && (
                                <div>
                                  <I18nText id="ibe.deal.link_copied" returnStringOnly={true} />
                                </div>
                              )
                            }
                            direction="bottom-right"
                          >
                            <span
                              onClick={() => this.setState({ isPopoverVisible: true })}
                              role="button"
                              tabIndex={0}
                              onKeyPress={() => this.setState({ isPopoverVisible: true })}
                            >
                              {shareDealLink}
                            </span>
                          </Popover>
                          {this.props.favouriteOfCurrentPerson ? (
                            <RclLink
                              type="icon-button"
                              size="small"
                              modifier="danger"
                              onClick={() => deleteFromFavourite(this.props.id)}
                              className="deals-card__details-info-deal-icon-link"
                            >
                              <Icon name="heart" showBGColor={true} size="tiny" color="danger" />
                            </RclLink>
                          ) : (
                            <RclLink
                              type="icon-button"
                              size="small"
                              modifier="default"
                              onClick={() => addToFavourite(this.props.id)}
                              className="deals-card__details-info-deal-icon-link"
                            >
                              <Icon
                                name="heart"
                                size="medium"
                                className="deals-card__details-info-deal-icon-custom"
                              />
                            </RclLink>
                          )}
                        </div>

                        <div className="deal__price">
                          {approvalRequestId ? (
                            <Fragment>
                              {I18n.t('components.ibe.deal_messages.status')}
                              <br />
                              <p className="deal__approved">
                                {I18n.t('components.ibe.deal_messages.approved')}
                              </p>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {I18n.t('components.ibe.deal_messages.starting_price')}
                              <br />
                              <Amount currency={currency} value={adAmount} />
                            </Fragment>
                          )}
                        </div>

                        {locations.length > 0 && (
                          <div className="deal__locations">
                            {locations.map(location => (
                              <div key={location.name} className="deal__locations-list">
                                <div>
                                  <img
                                    src={location.flag}
                                    alt={location.name}
                                    className="deal__country-flag"
                                  />
                                </div>
                                <div>{location.name}</div>
                                <div>{location.countryName}</div>
                              </div>
                            ))}
                          </div>
                        )}

                        {features.length > 0 && (
                          <div>
                            <p className="deal__labels">
                              <I18nText id="ibe.deal.features" returnStringOnly={true} />
                            </p>
                            <div className="deal__features-list">
                              {features.map(feature => (
                                <div key={feature}>{feature}</div>
                              ))}
                            </div>
                          </div>
                        )}
                        {categories.length > 0 && (
                          <div>
                            <p className="deal__labels">
                              <I18nText id="ibe.deal.categories" returnStringOnly={true} />
                            </p>
                            <div className="deal__features-list">
                              {categories.map(category => (
                                <div key={category}>{category}</div>
                              ))}
                            </div>
                          </div>
                        )}

                        {dealInterests.length > 0 && (
                          <div>
                            <p className="deal__labels">
                              <I18nText id="ibe.deal.interests" returnStringOnly={true} />
                            </p>
                            <div className="deal__features-list">
                              {dealInterests.map(interest => (
                                <div key={interest}>{interest}</div>
                              ))}
                            </div>
                          </div>
                        )}

                        {enquireOnly ? (
                          <div className="deal__enquire-now">
                            <div className="deal-customize__warning--text">
                              <Button
                                version="v2"
                                size="small"
                                type="primary"
                                label={I18n.t('components.ibe.deal.enquire_now')}
                                onClick={this.onEnquireModalOpen}
                                submitType="submit"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="deal__check-available">
                            <div className="deal-customize__warning--alert-text">
                              {!this.props.allSelected &&
                              !isSearching &&
                              !isConfirming &&
                              !isUnAvailable ? (
                                <I18nText id="ibe.deal.select_all" returnStringOnly={true} />
                              ) : (
                                unavailableDealMessage
                              )}
                            </div>

                            <div className="deal-customize__warning--text">
                              <Button
                                version="v2"
                                size="small"
                                type="primary"
                                label={I18n.t('components.ibe.deal.check_availability')}
                                onClick={this.onModalOpen}
                                disabled={
                                  !this.readyToCheckAvailability() ||
                                  !this.props.allSelected ||
                                  !this.props.continuePossible
                                }
                                submitType="submit"
                              />
                            </div>
                          </div>
                        )}
                      </Card>
                      <TopDeals showHeader={true} />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <Modal
              isModalVisible={this.state.isModalVisible}
              className="deal__confirm-modal"
              footer={footerContent}
              onOutsideClick={this.onModalClose}
            >
              <div className="deal__confirm-modal-wrapper">
                <div className="deal__confirm-modal-header">
                  {isUnAvailable ? (
                    <Fragment>
                      <Icon name="highPriority" color="danger" showBGColor={true} size="large" />
                      {approvalRequestId ? (
                        <I18nText id="ibe.deal.some_not_available_approval" />
                      ) : (
                        <I18nText id="ibe.deal.some_not_available" />
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Spinner size="small" bgColor="neutral" color="success" />
                      <I18nText id="ibe.deal.checking_header" />
                    </Fragment>
                  )}
                </div>

                <div className="deal__confirm-modal-sub-header">
                  {isUnAvailable ? (
                    <I18nText id="ibe.deal.search_other" returnStringOnly={true} />
                  ) : (
                    <div>
                      {approvalRequestId ? (
                        <I18nText
                          id="ibe.deal.checking_sub_header_approval"
                          returnStringOnly={true}
                        />
                      ) : (
                        <I18nText id="ibe.deal.checking_sub_header" returnStringOnly={true} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Modal>

            <Modal
              isModalVisible={this.state.enquireModalOpen}
              onOutsideClick={this.onEnquireModalClose}
              className={classNames('deal__enquire-modal', {
                'deal__enquire-modal--without-header': !this.state.isHeaderVisible,
              })}
              title={
                this.state.isHeaderVisible && (
                  <div className="deal__enquire-modal--title">
                    <I18nText id="ibe.deal.enquiry_header" />
                    <div
                      onClick={this.onEnquireModalClose}
                      onKeyDown={this.onEnquireModalClose}
                      role="presentation"
                    >
                      <Icon name="close" />
                    </div>
                  </div>
                )
              }
            >
              <OfferEnquiryForm
                dealId={this.props.id}
                personId={person && person.id}
                setIsHeaderVisible={state => this.setState({ isHeaderVisible: state })}
                onEnquireModalClose={this.onEnquireModalClose}
              />
            </Modal>
          </Fragment>
        )}
      </div>
    );
  }
}

MultiSearch.defaultProps = {
  text: null,
  cartId: null,
  approvalRequestId: null,
  features: [],
  categories: [],
  locations: [],
  interests: [],
  imageList: [],
  featureImageList: null,
  featureImageGrid: null,
  adAmount: null,
  favouriteDealCount: null,
  favouriteDealPeople: [],
  person: {
    id: null,
  },
  isDealItem: true,
  enquireOnly: false,
};

MultiSearch.propTypes = {
  airports: PropTypes.shape().isRequired,
  resetApp: PropTypes.func.isRequired,
  cartId: PropTypes.string,
  approvalRequestId: PropTypes.string,
  hasStaticProducts: PropTypes.bool.isRequired,
  status: PropTypes.objectOf(
    PropTypes.oneOf(['open', 'selected', 'searching', 'confirming', 'available', 'unavailable']),
  ).isRequired,
  fields: PropTypes.shape().isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      options: PropTypes.shape([]).isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  interests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
    }),
  ),
  journeyElements: PropTypes.shape({
    hotel: PropTypes.objectOf(
      PropTypes.shape({
        rooms: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  staticProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  adAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  favouriteDealPeople: PropTypes.arrayOf(),
  person: PropTypes.shape({
    id: PropTypes.string,
  }),
  favouriteDealCount: PropTypes.number,
  features: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(PropTypes.string),
  locations: PropTypes.arrayOf(PropTypes.string),
  featureImageList: PropTypes.string,
  featureImageGrid: PropTypes.string,
  favouriteOfCurrentPerson: PropTypes.bool.isRequired,
  imageList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  currency: PropTypes.string.isRequired,
  allOpen: PropTypes.bool.isRequired,
  allLoaded: PropTypes.bool.isRequired,
  disableForm: PropTypes.bool.isRequired,
  continuePossible: PropTypes.bool.isRequired,
  searchAccounts: PropTypes.shape().isRequired,
  searchIds: PropTypes.objectOf(PropTypes.string).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  multiSearchResultSelected: PropTypes.func.isRequired,
  multiSearchResultDeSelected: PropTypes.func.isRequired,
  selectedResults: PropTypes.objectOf(PropTypes.string).isRequired,
  allSelected: PropTypes.bool.isRequired,
  isDealItem: PropTypes.bool,
  enquireOnly: PropTypes.bool,
};

MultiSearch.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  minHoursInFuture: PropTypes.number.isRequired,
};

export default MultiSearch;
