import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import Icon from '@wtag/rcl-icon';
import Amount from 'sharedWebpack/Amount';

const MarginBreakdown = (props, context) => {
  if (context.laymanMode) return props.children;
  if (!props.breakdown) return props.children;
  if (props.breakdown.length < 1) return props.children;

  const label = props.breakdown.map(margin => (
    <div key={margin.id}>
      {margin.name}: <Amount currency={margin.currency} value={margin.total} />
    </div>
  ));

  return (
    <Tooltip content={label} position="bottom-right" type="inverse" showArrow={false}>
      <div className="col-grid col-bleed direction-row align-center">
        {props.children}
        <div className="car-result-card__margin-icon">
          <Icon name="invalidOutline" size="small" />
        </div>
      </div>
    </Tooltip>
  );
};

MarginBreakdown.defaultProps = {
  breakdown: [],
};

MarginBreakdown.propTypes = {
  children: PropTypes.node.isRequired,
  breakdown: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      total: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }),
  ),
};

MarginBreakdown.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
};

export default MarginBreakdown;
