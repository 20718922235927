const visaAdvisoryLabelType = visaCategory => {
  switch (visaCategory) {
    case 'unrestricted':
      return 'success';
    case 'restricted':
      return 'danger';
    default:
      return 'warning';
  }
};

export default visaAdvisoryLabelType;
