import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { Menu, MenuItem } from '@wtag/rcl-menu';
import Tooltip from '@wtag/rcl-tooltip';
import classNames from 'classnames';
import './styles.scss';

const AcceptableFOP = ({ fops, selectedPilTabKey, containerWidth }) => {
  const [isFOPMenuVisible, setIsFOPMenuVisible] = useState(false);
  const COUNT_PLACEHOLDER_WIDTH = 14;
  const ICON_WIDTH = 36;
  const COLUMN_GAP = 8;
  const OFFSET = selectedPilTabKey === 1 ? 32 : 0;
  const isDividerVisible = containerWidth >= 258;
  const dividerContent = isDividerVisible && <div className="acceptable-fop__header--divider" />;
  const FOPsCount = fops.length;
  const fopsToShowCount = Math.floor(
    (containerWidth - COUNT_PLACEHOLDER_WIDTH - OFFSET) / (ICON_WIDTH + COLUMN_GAP),
  );

  const showMenu = () => {
    setIsFOPMenuVisible(true);
  };

  const getMenuLabel = () => {
    if (FOPsCount > fopsToShowCount) {
      const menuLabel = `+${FOPsCount - fopsToShowCount}`;

      return (
        <div
          className="acceptable-fop__menu--label"
          tabIndex={0}
          role="button"
          onClick={showMenu}
          onKeyDown={showMenu}
        >
          {menuLabel}
        </div>
      );
    }

    return null;
  };

  const hideMenu = () => {
    setIsFOPMenuVisible(false);
  };

  return (
    <div className="acceptable-fop">
      <div className="acceptable-fop__header">
        {dividerContent}
        <span className="acceptable-fop__header--text">
          {selectedPilTabKey === 0
            ? I18n.t('admin.components.orders.items_tab.label.pay_once_supported_text')
            : I18n.t('admin.components.orders.items_tab.label.pay_individually_supported_text')}
        </span>
        {dividerContent}
      </div>
      <div className="acceptable-fop__icons">
        {fops.slice(0, fopsToShowCount).map(type => (
          <Tooltip
            key={type}
            content={I18n.t(`ibe.payment.payment_type.${type}`)}
            type="inverse"
            className="acceptable-fop__icons--tooltip"
          >
            <Icon
              className={classNames({ 'acceptable-fop__icons--pay-later': type === 'receipt' })}
              key={type}
              name={type}
              size="normal"
              showBGColor={true}
            />
          </Tooltip>
        ))}

        <Menu
          className={classNames('acceptable-fop__menu', 'acceptable-fop__icons')}
          isVisible={isFOPMenuVisible}
          onOutsideClick={hideMenu}
          popOverDirection="right-bottom"
          label={getMenuLabel()}
        >
          {fops.map(type => (
            <MenuItem key={type}>
              <Icon
                className={classNames({ 'acceptable-fop__icons--pay-later': type === 'receipt' })}
                key={type}
                name={type}
                size="normal"
                showBGColor={true}
              />
              {I18n.t(`ibe.payment.payment_type.${type}`)}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};

AcceptableFOP.defaultProps = {
  selectedPilTabKey: 0,
};

AcceptableFOP.propTypes = {
  fops: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedPilTabKey: PropTypes.number,
  containerWidth: PropTypes.number.isRequired,
};

export default AcceptableFOP;
