export const UPDATE_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN =
  'UPDATE_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN';
export const RESET_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN =
  'RESET_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN';

export const setSkipAuthenticationStatusesByToken = encodedData => ({
  type: UPDATE_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN,
  encodedData,
});

export const resetSkipAuthenticationStatusesByToken = () => ({
  type: RESET_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN,
});
