import React from 'react';
import Tooltip from '@wtag/rcl-tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import './styles.scss';

const FlexiblePaymentTag = props => {
  const { matrixCard } = props;
  return (
    <div className={classNames({ 'matrix-supplier-card__pay-later-tag': matrixCard })}>
      <Tooltip
        className="flexible-payment-tag__tooltip"
        content={I18n.t('components.ibe.results.book_and_hold_hint')}
        position="top"
        type="inverse"
        title={I18n.t('components.ibe.results.book_and_hold')}
      >
        <EmphasisTag
          text={I18n.t('components.ibe.results.book_and_hold')}
          size="tiny"
          type="success"
          icon={<Icon name="invalidOutline" />}
          iconColor="inverse"
        />
      </Tooltip>
    </div>
  );
};

FlexiblePaymentTag.defaultProps = {
  matrixCard: null,
};

FlexiblePaymentTag.propTypes = {
  matrixCard: PropTypes.string,
};

export default FlexiblePaymentTag;
