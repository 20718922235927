import PropTypes from 'prop-types';
import React from 'react';
import Alert from 'sharedWebpack/Alert';

const FlashMessage = ({ text, status }) => (
  <Alert isVisible={!!text} type={status === 200 ? 'success' : 'danger'} hideClose={true}>
    {text}
  </Alert>
);

FlashMessage.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default FlashMessage;
