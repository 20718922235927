import { useState, useEffect } from 'react';

const useTruncateTextTooltip = elementRef => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleShowTooltip = () => {
    let displayState = false;

    if (elementRef && elementRef.current) {
      const { scrollHeight, clientHeight } = elementRef.current;
      displayState = scrollHeight > clientHeight;
    }

    setIsTooltipVisible(displayState);
  };

  useEffect(() => {
    handleShowTooltip();

    window.addEventListener('resize', handleShowTooltip);

    return () => {
      window.removeEventListener('resize', handleShowTooltip);
    };
  }, []);

  return isTooltipVisible;
};

export default useTruncateTextTooltip;
