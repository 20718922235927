import formattedDateTime from './formattedDateTime';
import getSegmentServices from './getSegmentServices';
import { humanReadableDuration } from '../../../HumanReadableDuration';
import getTransitType from '../../../helpers/getTransitType';

const getAirportInformation = (airports, code) => airports.find(info => info && info.code === code);

const formatFlightInfo = (segment, stopovers, services, airports) => {
  const {
    originCode,
    destinationCode,
    arrivalLocaltimeInUserTz,
    departureLocaltimeInUserTz,
    seats,
    baggagePiecesIncluded,
  } = segment;

  const stopover = stopovers.find(
    ({ airportCode, airportChange }) =>
      airportCode === destinationCode ||
      (airportChange && airportChange.arrivalAirportCode === destinationCode),
  );

  const segmentServices = getSegmentServices(services, originCode, destinationCode).map(
    ({ description }) => description,
  );

  const stopoverInfo =
    stopover &&
    `\n\n${I18n.t('components.ibe.search_results.flight.content.stop', {
      duration: humanReadableDuration(stopover.transitDurationsInMinutes),
      type: getTransitType(stopover.transitDurationsInMinutes),
    })}\n`;

  const departure = `\n${I18n.t('components.ibe.search_results.flight.content.departure', {
    city: getAirportInformation(airports, originCode).placeName,
    dateTime: formattedDateTime(departureLocaltimeInUserTz),
  })}`;

  const arrival = `\n${I18n.t('components.ibe.search_results.flight.content.arrival', {
    city: getAirportInformation(airports, destinationCode).placeName,
    dateTime: formattedDateTime(arrivalLocaltimeInUserTz),
  })}`;

  const seatInfo =
    seats.length > 0 &&
    `\n${I18n.t('components.ibe.search_results.flight.content.seat', {
      number: seats.map(({ row, seat }) => `${row}${seat}`).join(', '),
    })}`;

  const baggageInfo =
    baggagePiecesIncluded.count !== null &&
    baggagePiecesIncluded.kgPerPiece !== null &&
    `\n${I18n.t('components.ibe.search_results.flight.content.baggage', {
      baggage: I18n.t('components.ibe.search_result.baggage_with_weight_and_count', {
        count: baggagePiecesIncluded.count,
        weight: baggagePiecesIncluded.kgPerPiece,
      }),
    })}`;

  const segmentServicesInfo =
    segmentServices.length > 0 &&
    `\n${I18n.t('components.ibe.search_results.flight.content.services', {
      services: segmentServices.join(', '),
    })}`;

  return {
    departure,
    arrival,
    seatInfo,
    baggageInfo,
    segmentServicesInfo,
    stopoverInfo,
  };
};

const getTripInfo = (trips, services) => {
  const formattedTripInfo = trips
    .map(({ airports, segments, stopovers }, idx) => {
      const tripTitle =
        trips.length !== 1
          ? `\n---\n${I18n.t('components.ibe.search_results.flight.content.trip_title', {
              number: idx + 1,
            })}`
          : '\n---';

      const segmentInfo = segments.map(segment =>
        formatFlightInfo(segment, stopovers, services, airports),
      );

      return (
        tripTitle +
        segmentInfo
          .map(Object.values)
          .flat()
          .filter(Boolean)
          .join('')
      );
    })
    .join('');

  return formattedTripInfo;
};

export default getTripInfo;
