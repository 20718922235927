import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import Alert from 'sharedWebpack/Alert';
import Button from '@wtag/rcl-button';

const OtherAvailableFlightsWarning = ({ isDisabled, onDeselectAll, children }) => (
  <div className="other">
    <div className="results-table">
      <div className="results-table__title-other">
        <I18nText id="ibe.results.other_flights" returnStringOnly={true} />
      </div>
      <Alert
        className="results-table__title-other-alert"
        isVisible={true}
        type="warning"
        hideClose={true}
      >
        <div className="deselect-container d-flex flex-column-gap-4">
          <span className="deselect-container__alert-message">
            {I18n.t('components.ibe.results.flight_deselect_alert_message')}
          </span>
          <Button
            version="v2"
            className="deselect-container__button results-table__title-other-alert-button"
            size="small"
            type="normal"
            disabled={isDisabled}
            label={I18n.t('components.ibe.results.deselect_all')}
            onClick={onDeselectAll}
          />
        </div>
      </Alert>
      {children}
    </div>
  </div>
);

OtherAvailableFlightsWarning.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default OtherAvailableFlightsWarning;
