const getAirportMap = trips => {
  const airportMap = {};

  trips.forEach(({ airports }) => {
    airports.forEach(airport => {
      if (!airportMap[airport.code]) {
        airportMap[airport.code] = airport;
      }
    });
  });

  return airportMap;
};

export default getAirportMap;
