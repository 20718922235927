import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import { Link } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';
import IconButton from '@wtag/rcl-icon-button';

import FrequentFlyerNumberForm from './FrequentFlyerNumberForm';

const FrequentFlyerNumberInformation = props => {
  const { personId, frequentFlyerNumber, fetchFrequentFlyerNumbers, carrierCodes, layman } = props;

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});
  const [number, setNumber] = useState('');
  const [carrierCode, setCarrierCode] = useState([]);

  const updateFrequentFlyerNumber = async ffnId => {
    const frequentFlyerNumberParams = {
      number,
      carrier_code: carrierCode,
      person_id: personId,
    };

    const updateFrequentFlyerUrl = layman
      ? routes.public.frequentFlyerNumbers.update({ id: ffnId })
      : routes.admin.frequentFlyerNumbers.update({ id: ffnId, person_id: personId });

    const { data } = await httpClient.patch(updateFrequentFlyerUrl, {
      frequent_flyer_number: frequentFlyerNumberParams,
    });
    if (data.error === null) {
      fetchFrequentFlyerNumbers();
      setEditing(false);
    }
  };

  const onUpdateFrequentFlyerNumber = ffnId =>
    updateFrequentFlyerNumber(ffnId).catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  const deleteFrequentFlyerNumber = async ffnId => {
    const deleteFrequentFlyerUrl = layman
      ? routes.public.frequentFlyerNumbers.delete({ id: ffnId })
      : routes.admin.frequentFlyerNumbers.delete({ id: ffnId, person_id: personId });

    const { data } = await httpClient.delete(deleteFrequentFlyerUrl);

    if (data.error === null) {
      fetchFrequentFlyerNumbers();
      setEditing(false);
    }
  };

  const onDeleteFrequentFlyerNumber = ffnId =>
    deleteFrequentFlyerNumber(ffnId).catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  useEffect(() => {
    setNumber(frequentFlyerNumber.number);
    setCarrierCode(frequentFlyerNumber.carrierCode);
  }, [frequentFlyerNumber]);

  return (
    <Card
      className="traveller-edit-frequent-flyer-number__container"
      key={frequentFlyerNumber.id}
      version="v2"
    >
      {editing ? (
        <Fragment>
          <div className="col-grid col-bleed direction-row align-center justify-space-between traveller-edit-frequent-flyer-number-section__update">
            <span>{I18n.t('admin.components.travellers.edit.frequent_flyer_numbers.update')}</span>
            <span className="traveller-edit-frequent-flyer-number__actions">
              <IconButton
                icon={<Icon name="check" />}
                color="tertiary"
                size="small"
                onClick={() => onUpdateFrequentFlyerNumber(frequentFlyerNumber.id)}
              />
              <IconButton
                icon={<Icon name="close" />}
                color="tertiary"
                size="small"
                onClick={() => setEditing(false)}
              />
            </span>
          </div>
          <FrequentFlyerNumberForm
            carrierCodeTypes={carrierCodes}
            carrierCode={carrierCode}
            setCarrierCode={setCarrierCode}
            number={number}
            setNumber={setNumber}
            error={error}
          />
        </Fragment>
      ) : (
        <Fragment>
          <div className="col-grid col-bleed direction-row traveller-edit-frequent-flyer-number">
            <div className="traveller-edit-frequent-flyer-number__summary">
              <span className="traveller-edit-frequent-flyer-number__content--disabled">
                {frequentFlyerNumber.airline}
              </span>
              <span className="traveller-edit-frequent-flyer-number__content">
                {frequentFlyerNumber.number}
              </span>
            </div>
            <div className="traveller-edit-frequent-flyer-number__actions">
              <span className="traveller-edit-frequent-flyer-number__content">
                <Link
                  labelHint={I18n.t('admin.shared.action.edit')}
                  type="icon-button"
                  modifier="tertiary"
                  onClick={() => setEditing(true)}
                >
                  <Icon name="edit" size="small" />
                </Link>
              </span>
              <span className="traveller-edit-frequent-flyer-number__content">
                <Link
                  type="icon-button"
                  modifier="tertiary"
                  onClick={() => onDeleteFrequentFlyerNumber(frequentFlyerNumber.id)}
                >
                  <Icon name="delete" size="small" />
                </Link>
              </span>
            </div>
          </div>
        </Fragment>
      )}
    </Card>
  );
};

FrequentFlyerNumberInformation.defaultProps = {
  frequentFlyerNumber: {},
  layman: false,
};

FrequentFlyerNumberInformation.propTypes = {
  personId: PropTypes.number.isRequired,
  fetchFrequentFlyerNumbers: PropTypes.func.isRequired,
  carrierCodes: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  frequentFlyerNumber: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    airline: PropTypes.string,
    carrierCode: PropTypes.string,
  }),
  layman: PropTypes.string,
};

export default FrequentFlyerNumberInformation;
