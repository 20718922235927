import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import DateTime from 'sharedWebpack/DateTime';
import getCarInformation from 'sharedWebpack/IBE/lib/helpers/getCarInformation';
import getCarImageUrl from 'sharedWebpack/IBE/lib/helpers/getCarImageUrl';
import { DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME } from 'sharedWebpack/helpers/dateTime';
import CarAttributeItem from '../CarAttributeItem';
import CarType from '../CarType';

const CarAccordionItemHeader = ({
  carInformation,
  pickUpDate,
  dropOffDate,
  vendorImageUrl,
  vendorName,
  make,
  name,
  isAccordionActive,
}) => {
  const { category, acrissCode } = carInformation;

  return (
    <div className="d-flex-col car-item__accordion-header w-100">
      <div className="grid grid-gap-12 w-100">
        <div className="col-3 car-item-compact-view__information-container car-item-compact-view__information-container--desktop">
          <span className="car__logo-chip">
            <img src={getCarImageUrl(vendorImageUrl)} alt="car" />
          </span>
        </div>
        <div className="col-xlg-3 col-lg-2 car-item-compact-view__information-container car-item-compact-view__information-container--mobile">
          <span className="car-item-compact-view__vendor">{vendorName}</span>
          <span className="car-item-compact-view__information">
            {getCarInformation(name, make)}
          </span>
        </div>
        <div className="col-lg-9 col-grid direction-row align-center wrap">
          <div className="col-xlg-10 col-bleed d-flex car-item-compact-view__attribute-items">
            <CarAttributeItem
              className="car-item-compact-view__attribute-item"
              header={I18n.t('components.ibe.car_results.car_type')}
              value={<CarType category={category} acrissCode={acrissCode} />}
            />
            <CarAttributeItem
              className="car-item-compact-view__attribute-item"
              header={I18n.t('components.ibe.car_results.pick_up')}
              value={
                <DateTime
                  dateTime={pickUpDate}
                  format={DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME}
                  displayWithoutConversion={true}
                />
              }
            />
            <CarAttributeItem
              className="car-item-compact-view__attribute-item"
              header={I18n.t('components.ibe.car_results.drop_off')}
              value={
                <DateTime
                  dateTime={dropOffDate}
                  format={DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME}
                  displayWithoutConversion={true}
                />
              }
            />
          </div>
          <div className="col-12 col-grid align-center justify-center col-bleed icon--mobile-container">
            <Icon
              className="icon--mobile"
              color="tertiary"
              name="iconUpChevron"
              rotate={isAccordionActive ? 0 : 180}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CarAccordionItemHeader.defaultProps = {
  vendorName: null,
  make: null,
  name: null,
};

CarAccordionItemHeader.propTypes = {
  carInformation: PropTypes.shape({
    category: PropTypes.string,
    acrissCode: PropTypes.string,
  }).isRequired,
  pickUpDate: PropTypes.string.isRequired,
  dropOffDate: PropTypes.string.isRequired,
  vendorImageUrl: PropTypes.string.isRequired,
  vendorName: PropTypes.string,
  make: PropTypes.string,
  name: PropTypes.string,
  isAccordionActive: PropTypes.bool.isRequired,
};

export default CarAccordionItemHeader;
