import { UPDATE_REQUESTS_TO_AUTHENTICATE_BY_TOKEN } from '../actions';

export default function requestsToAuthenticateByToken(state = {}, action) {
  switch (action.type) {
    case UPDATE_REQUESTS_TO_AUTHENTICATE_BY_TOKEN:
      return action.requestsThatNeedAuthentication;
    default:
      return state;
  }
}
