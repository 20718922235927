const SET_PAYMENT_INFORMATION = 'set_payment_information';
const typeMapper = {
  seat: 'post_booking_seat_add',
  // Add other types like emd, exchange
};

const getPostBookingActionType = postBookingContent =>
  !postBookingContent ? SET_PAYMENT_INFORMATION : typeMapper[postBookingContent.type];

export default getPostBookingActionType;
