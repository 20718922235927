import React from 'react';
import PropTypes from 'prop-types';
import { SortingPill } from '@wtag/react-comp-lib';

const WrappedSortingPill = ({
  name,
  thisSortColumn,
  currentSortColumn,
  currentSortDirection,
  onChange,
  size,
}) => (
  <SortingPill
    size={size}
    parameterName={name}
    sortDirection={currentSortColumn === thisSortColumn ? currentSortDirection : null}
    onClick={direction => onChange(thisSortColumn, direction)}
  />
);

WrappedSortingPill.propTypes = {
  name: PropTypes.node.isRequired,
  thisSortColumn: PropTypes.string.isRequired,
  currentSortColumn: PropTypes.string.isRequired,
  currentSortDirection: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
}

export default WrappedSortingPill;
