import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag, Link, SidePanel, I18nText } from '@wtag/react-comp-lib';
import DateTime from 'sharedWebpack/DateTime';
import Icon from '@wtag/rcl-icon';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import CarFallbackImage from '../../../../assets/images/synced/fallback_images/product/product_xxl_car.png';
import Facility from '../../IBE/SearchResult/car/Facility';
import Insurance from '../../IBE/SearchResult/car/Insurance';
import FlexiblePaymentTag from '../../FlexiblePaymentTag';

import './styles.scss';

const Index = props => {
  const {
    journeyElementId,
    journeyElementType,
    fetchedJourneyElement,
    payLater,
    laymanMode,
  } = props;

  const [journeyElement, setJourneyElement] = useState(fetchedJourneyElement);

  const [query, setQuery] = useQueryParams({
    openedSidePanel: withDefault(StringParam, ''),
  });
  const responsiblityWaiverSidePanelVisible = query.openedSidePanel === 'responsiblityWaiver';

  const showresponsiblityWaiverSidePanel = () => {
    setQuery({ openedSidePanel: 'responsiblityWaiver' }, 'replaceIn');
  };

  const hideSidePanel = () => {
    setQuery({ openedSidePanel: '' }, 'replaceIn');
  };

  const fetchJourneyElement = async () => {
    const { data } = await httpClient.get(
      routes.api.fetchJourneyElement({
        id: journeyElementId,
        type: journeyElementType,
      }),
    );

    setJourneyElement(data);
  };

  useEffect(() => {
    if (journeyElementId && !fetchedJourneyElement) {
      fetchJourneyElement();
    }
  }, [journeyElementId, journeyElementType]);

  const locationAndTime = (isPickUp, date, location) => (
    <div className="col-sm-6 col-bleed car-item-details-car-info-location-and-time">
      <div className="car-item-details-car-info-disabled-header">
        <div className="car-item-details-car-info-disabled-header-icon">
          {isPickUp ? (
            <Icon name="arrowDown" color="tertiary" />
          ) : (
            <Icon name="arrowUp" color="tertiary" />
          )}
        </div>
        {isPickUp
          ? I18n.t('admin.components.orders.items_tab.placeholder.pick_up')
          : I18n.t('admin.components.orders.items_tab.placeholder.drop_off')}
      </div>
      <div className="car-item-details-car-info-time">
        <DateTime format="shortWithTime" dateTime={date} />
      </div>
      <div className="car-item-details-car-info-location">{location}</div>
    </div>
  );

  const insurances = () =>
    journeyElement.insurances.map(insurance => (
      <li key={insurance.type} className="car-result-card__facility">
        <Facility
          facility={<Insurance type={insurance.type} />}
          type="insurance"
          amount={insurance.amount}
          currency={insurance.currency}
          modifier={insurance.modifier}
        />
      </li>
    ));

  const viewJourneyElementMakeInformation = (journeyElementMake, journeyElementName) => {
    if (journeyElementName) {
      return `${journeyElementMake} ${journeyElementName}`;
    }

    return journeyElementMake;
  };

  return (
    <div className="col-12 car-item">
      <div className="col-grid col-bleed direction-row align-center car-item-facilities wrap">
        {journeyElement && journeyElement.category && journeyElement.acrissCode && (
          <div className="car-item-facilities-tag">
            <EmphasisTag
              text={`${I18n.t(journeyElement.category, {
                scope: 'simple_form.options.person.preferred_car_categories',
              })} ${journeyElement.acrissCode}`}
              size="tiny"
            />
          </div>
        )}
        {journeyElement && journeyElement.seatCount > 0 && (
          <div className="car-item-facilities-tag">
            <EmphasisTag
              text={I18n.t('admin.components.orders.items_tab.tag.seats', {
                count: journeyElement.seatCount,
              })}
              size="tiny"
            />
          </div>
        )}
        {journeyElement && journeyElement.doors && (
          <div className="car-item-facilities-tag">
            <EmphasisTag
              text={I18n.t('admin.components.orders.items_tab.tag.doors', {
                count: journeyElement.doors,
              })}
              size="tiny"
            />
          </div>
        )}
        {journeyElement && journeyElement.airConditioning && (
          <EmphasisTag
            text={I18n.t('admin.components.orders.items_tab.tag.air_conditioned')}
            size="tiny"
          />
        )}
      </div>
      <div className="col-12 col-grid col-bleed direction-row car-item-details">
        <div className="car-item-details-car-image">
          {journeyElement && journeyElement.carImageUrl ? (
            <img src={journeyElement.carImageUrl} alt="car" />
          ) : (
            <img src={CarFallbackImage} alt="car" />
          )}
        </div>
        <div className="col-10 col-bleed car-item-details-car-info">
          {journeyElement && journeyElement.make && (
            <div className="car-item-details-car-info-model">
              {viewJourneyElementMakeInformation(journeyElement.make, journeyElement.name)}
            </div>
          )}
          <div className="col-12 col-grid direction-row col-bleed align-center justify-space-between">
            {journeyElement &&
              journeyElement.pickUp &&
              locationAndTime(
                true,
                journeyElement.pickUp.localtimeInUserTz,
                journeyElement.pickUp.location,
              )}
            {journeyElement &&
              journeyElement.dropOff &&
              locationAndTime(
                false,
                journeyElement.dropOff.localtimeInUserTz,
                journeyElement.dropOff.location,
              )}
          </div>
        </div>
      </div>
      <div className="col-12 col-grid col-bleed direction-row align-end justify-space-between wrap">
        <div className="col-sm-6 col-grid col-bleed align-center car-item-details-car-info-insurance">
          <div className="col-grid col-bleed direction-row align-center">
            {journeyElement && journeyElement.insurances && journeyElement.insurances.length > 0 ? (
              <Icon name="checkCircle" color="success" size="small" />
            ) : (
              <Icon name="closeCircle" color="danger" size="normal" />
            )}
            {journeyElement && journeyElement.insurances && journeyElement.insurances.length > 0 ? (
              <div className="car-item-details-car-info-insurance-text">
                {I18n.t('admin.components.orders.items_tab.placeholder.responsibility_waiver')}
              </div>
            ) : (
              <div className="car-item-details-car-info-insurance-text">
                {I18n.t('admin.components.orders.items_tab.placeholder.no_responsibility_waiver')}
              </div>
            )}
          </div>
          <div className="col-grid col-bleed direction-row align-center">
            {journeyElement && journeyElement.insurances && journeyElement.insurances.length > 0 && (
              <div className="car-item-details-car-info-insurance-side-panel">
                <Link onClick={showresponsiblityWaiverSidePanel} modifier="default" size="tiny">
                  <div className="car-item-details-car-info-insurance-side-panel-text">
                    <I18nText
                      id="ibe.search_results.car.label.view_coverage"
                      returnStringOnly={true}
                    />
                  </div>
                </Link>
                <SidePanel
                  title={I18n.t('components.ibe.search_results.car.label.responsibility_waiver')}
                  body={insurances()}
                  isOpened={responsiblityWaiverSidePanelVisible}
                  onClick={() => hideSidePanel()}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-6 col-bleed col-grid align-end car-item-details-vendor-image">
          {journeyElement && journeyElement.vendorImageUrl && (
            <img src={journeyElement.vendorImageUrl} alt="carVendor" />
          )}
        </div>
      </div>
      {payLater && !laymanMode && (
        <div className="col-12 col-grid col-bleed direction-row car-item-tags">
          <FlexiblePaymentTag />
        </div>
      )}
    </div>
  );
};

Index.defaultProps = {
  fetchedJourneyElement: null,
  payLater: false,
};

Index.propTypes = {
  journeyElementId: PropTypes.number.isRequired,
  journeyElementType: PropTypes.string.isRequired,
  fetchedJourneyElement: PropTypes.shape({}),
  payLater: PropTypes.bool,
  laymanMode: PropTypes.bool.isRequired,
};

export default withQueryParamsProvider(Index);
