import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@wtag/rcl-menu';
import DateTime from 'sharedWebpack/DateTime';
import ProductIcon from 'sharedWebpack/ProductIcon';

const FlightSearchName = props => {
  const { trips } = props;

  let segmentString = '';

  props.trips.forEach((trip, index) => {
    if (index === 0) {
      segmentString = segmentString.concat(`${trip.origin} - ${trip.destination}`);
      return;
    }

    segmentString = segmentString.concat(`, ${trip.origin} - ${trip.destination}`);
  });

  const travelerCount = props.adults + props.children + props.infants;

  if (travelerCount === 0) {
    return null;
  }

  const getTripType = passedTrips => {
    if (passedTrips.length === 1) {
      return I18n.t('components.ibe.search_form.flight.label.one_way');
    } else if (passedTrips.length === 2) {
      if (passedTrips[0].origin === passedTrips[passedTrips.length - 1].destination) {
        return I18n.t('components.ibe.search_form.flight.label.round_trip');
      }

      return I18n.t('components.ibe.search_form.flight.label.multi_city');
    } else if (passedTrips.length > 2) {
      return I18n.t('components.ibe.search_form.flight.label.multi_city');
    }

    return null;
  };

  const flightData = (
    <div className="col-grid col-bleed direction-row align-center">
      <ProductIcon
        productType="flight"
        showBGColor={true}
        size={props.isDealItem ? 'medium' : 'normal'}
        color={props.isDealItem ? 'success' : 'tertiary'}
      />
      <div className="col-grid col-bleed">
        <div className="search-name__primary">
          {getTripType(trips)}
          {' - '}
          {I18n.t('components.ibe.search_form.label.number_of_travelers', {
            count: travelerCount,
          })}
          {' | '}
          {I18n.t(props.trips[0].cabinClass, {
            scope: 'cabin_classes',
          })}
        </div>
        <div className="search-name__secondary">
          {segmentString} {I18n.t('components.ibe.search_form.label.travel_date_on')}{' '}
          <DateTime dateTime={props.trips[0].date} format="short" />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {props.isDealItem ? (
        <div className="deal__group-item">{flightData}</div>
      ) : (
        <MenuItem onClick={props.onMenuItemClick}>{flightData}</MenuItem>
      )}
    </div>
  );
};

FlightSearchName.defaultProps = {
  adults: null,
  children: null,
  infants: null,
  isDealItem: false,
};

FlightSearchName.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      origin: PropTypes.string.isRequired,
      destination: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      cabinClass: PropTypes.string.isRequired,
    }),
  ).isRequired,
  adults: PropTypes.number,
  children: PropTypes.number,
  infants: PropTypes.number,
  onMenuItemClick: PropTypes.func.isRequired,
  isDealItem: PropTypes.bool,
};

export default FlightSearchName;
