import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import Amount from 'sharedWebpack/Amount';
import { Title, Text } from '@wtag/rcl-typography';
import { EmphasisTag } from '@wtag/react-comp-lib';
import classNames from 'classnames';
import ProductIcon from 'sharedWebpack/ProductIcon';
import Modal from 'sharedWebpack/Modal';
import staticProductShape from '../shapes/staticProduct';

const StaticProductDescription = props => {
  const { product, interests, isModalOpen, closeModal, addToCart, laymanMode } = props;
  const footerContent = (
    <div className="col-grid static-product-card__footer-section">
      <div className="static-product-card__footer-section-actions">
        <div>
          <Button
            className="static-product-card__footer-section-button"
            version="v2"
            type="primary"
            onClick={() => addToCart(product)}
            size="small"
            label={I18n.t('components.ibe.shared.add_to_cart')}
            disabled={product.inStock === 0}
          />
        </div>
        <div className="static-product-card__footer-section-stock">
          {product.inStock
            ? I18n.t('components.ibe.search_form.static.in_stock', {
                in_stock: product.inStock,
              })
            : I18n.t('components.ibe.search_form.static.no_stock')}
        </div>
      </div>

      <div className="static-product-card__footer-section-amount">
        <div>
          <Title
            className={classNames({
              'static-product-card__footer-section-amount--laymanmode-font': laymanMode,
            })}
            weight="medium"
            level={3}
          >
            <Amount
              currency={product.currency}
              value={parseFloat(product.price) + parseFloat(product.vatAmount)}
            />
          </Title>
        </div>
        <div>
          <Text weight="light" level={3}>
            {I18n.t('components.ibe.price.including_vat', {
              vat: product.vatPercentage,
            })}
          </Text>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      className="static-product-card__modal"
      title={
        <Fragment>
          <div className="static-product-card__header">
            <ProductIcon
              productType={product.productType}
              showBGColor={true}
              color="tertiary"
              size="tiny"
            />
            <div>{product.name}</div>
            <EmphasisTag text={product.categoryName} type="success" size="small" />
          </div>
          <div onClick={closeModal} onKeyDown={closeModal} role="presentation">
            <Icon name="close" />
          </div>
        </Fragment>
      }
      isModalVisible={isModalOpen}
      onOutsideClick={closeModal}
      footer={footerContent}
    >
      <div className="grid static-product-card__description">
        <div className="col-xlg-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 col-xxs-12">
          <img
            className="static-product-card__image-description"
            src={product.pictureUrl}
            alt={product.name}
          />
        </div>
        <div className="col-xlg-7 col-lg-7 col-md-7 col-sm-6 col-xs-12 col-xxs-12 static-product-card__description-section">
          {product.locations && product.locations.length > 0 && (
            <div className="static-product-card__location">
              {product.locations.map(location => (
                <div>
                  <Icon name="location" size="small" color="danger" />
                  <div>{location}</div>
                </div>
              ))}
            </div>
          )}
          <div>
            <h3>{I18n.t('components.ibe.search_form.static.description')}</h3>
            <p dangerouslySetInnerHTML={{ __html: product.description }} />
          </div>
          <div>
            <div className="static-product-card__interest">
              {interests &&
                interests.map(interest => (
                  <EmphasisTag text={interest} type="neutral" size="small" />
                ))}
            </div>

            {product.pdfName && (
              <div className="static-product-card__pdf">
                <Icon name="pdf" showBGColor={true} color="tertiary" size="normal" />
                <div>{product.pdfName}</div>
                <a href={product.pdfUrl} download={true}>
                  <Icon name="download" size="normal" color="tertiary" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

StaticProductDescription.defaultProps = {
  addToCart: null,
  closeModal: null,
  interests: null,
};

StaticProductDescription.propTypes = {
  addToCart: PropTypes.func,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  product: staticProductShape.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  interests: PropTypes.arrayOf(PropTypes.string),
};

export default StaticProductDescription;
