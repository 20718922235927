import React, { Fragment } from 'react';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import Badge from '@wtag/rcl-badge';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import { DateTime } from '@wtag/react-comp-lib';
import useViewMode from 'sharedWebpack/useViewMode';
import TripRouteInformation from '../TripRouteInformation';
import TripDetailsItem from '../TripDetailsItem';
import getTripType from '../../lib/helpers/getTripType';
import { ROUNDTRIP } from '../../lib/helpers/tripTypes';
import { DESKTOP_DEVICE } from '../../../old/libraries/responsiveHelpers';
import './styles.scss';

const ShoppingCart = ({ cartId, cartItemsCount }) => (
  <Fragment>
    <Icon name="shoppingCart" size="normal" />
    {cartId && cartItemsCount > 0 && (
      <Badge
        label={cartItemsCount}
        size="small"
        type="active"
        classNameForBadge="search__cart-button"
      />
    )}
  </Fragment>
);

ShoppingCart.defaultProps = {
  cartId: null,
  cartItemsCount: 0,
};

ShoppingCart.propTypes = {
  cartId: PropTypes.string,
  cartItemsCount: PropTypes.number,
};

const SearchHeader = ({
  isCollapsed,
  onModifySearch,
  flightSearchParams,
  expand,
  onExpand,
  cartId,
  cartItemsCount,
  onCartClick,
  showContinueButton,
  onMatrixPageRender,
}) => {
  const { adults = 0, children = 0, infants = 0, trips } = flightSearchParams;
  const adult = adults > 0 && I18n.t('admin.components.traveller_type.adult', { count: adults });
  const child =
    children > 0 && I18n.t('admin.components.traveller_type.child', { count: children });
  const infant =
    infants > 0 && I18n.t('admin.components.traveller_type.infant', { count: infants });
  const traveler = [adult, child, infant].filter(value => value).join(', ');
  const [firstTrip] = trips;
  const lastTrip = trips[trips.length - 1];
  const updatedTrips = [{ originCode: firstTrip.origin, destinationCode: firstTrip.destination }];
  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;

  if (trips.length > 1) {
    updatedTrips.push({
      originCode: lastTrip.origin,
      destinationCode: lastTrip.destination,
    });
  }

  const tripType = getTripType(updatedTrips);
  const iconName = expand ? 'iconUpChevron' : 'iconDownChevron';
  const tripLabel =
    tripType === ROUNDTRIP
      ? I18n.t('components.ibe.search_form.flight.label.return')
      : I18n.t('components.ibe.search_form.flight.label.arrival');
  const modifyButtonLabel = isCollapsed
    ? I18n.t('components.ibe.search_form.label.modify_search')
    : I18n.t('components.ibe.search_form.label.hide_search');

  return (
    <Card className="search-header__card">
      <div className="d-flex search-header__trip-info col-6 col-bleed">
        <TripRouteInformation firstTrip={firstTrip} lastTrip={lastTrip} tripType={tripType} />
        <div className="search-header__trip-details col-6 col-bleed">
          <TripDetailsItem
            title={I18n.t('components.ibe.search_form.flight.label.departure')}
            subtitle={<DateTime dateTime={firstTrip.date} format="short" />}
          />
          {trips.length > 1 && (
            <TripDetailsItem
              title={tripLabel}
              subtitle={<DateTime dateTime={lastTrip.date} format="short" />}
            />
          )}
          <TripDetailsItem
            title={I18n.t('simple_form.labels.order_search.traveler')}
            subtitle={traveler}
          />
        </div>
      </div>
      <div className="search-header__button-content d-flex align-center">
        <Button
          className="search-header__button"
          onClick={() => onModifySearch(!isCollapsed)}
          version="v2"
          size="tiny"
          label={isLargeScreen ? modifyButtonLabel : <Icon name="edit" />}
        />
        {showContinueButton && (
          <Button
            className="search-header__button--continue"
            version="v2"
            size="tiny"
            label={
              isLargeScreen ? (
                I18n.t('components.ibe.search_form.label.continue')
              ) : (
                <Icon name="arrowRight" />
              )
            }
            type="primary"
            onClick={onMatrixPageRender}
          />
        )}
        <IconButton
          className="search-header__icon-button search-header__icon-button--cart"
          icon={<ShoppingCart cartId={cartId} cartItemsCount={cartItemsCount} />}
          label={I18n.t('components.ibe.search_form.label.cart')}
          onClick={onCartClick}
          disabled={!cartId}
          version="v2"
          size="xs"
        />
        <IconButton
          className="search-header__icon-button"
          icon={<Icon name={iconName} showBGColor={true} size="tiny" />}
          size="xs"
          onClick={() => onExpand(prev => !prev)}
        />
      </div>
    </Card>
  );
};

SearchHeader.defaultProps = {
  cartId: null,
  cartItemsCount: 0,
  showContinueButton: false,
  onCartClick: () => {},
  onMatrixPageRender: () => {},
};

SearchHeader.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onModifySearch: PropTypes.func.isRequired,
  flightSearchParams: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        destination: PropTypes.string,
        origin: PropTypes.string,
      }).isRequired,
    ),
  }).isRequired,
  expand: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  cartId: PropTypes.string,
  cartItemsCount: PropTypes.number,
  onCartClick: PropTypes.func,
  showContinueButton: PropTypes.bool,
  onMatrixPageRender: PropTypes.func,
};

export default SearchHeader;
