import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';
import { Link } from '@wtag/react-comp-lib';
import Pagination from 'sharedWebpack/Pagination';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import routes from 'agentRoutes';
import SortingPill from 'sharedWebpack/SortingPill';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import DocumentsTable from 'sharedWebpack/Documents/Table';

const TabContent = ({ orderId, createDocumentUrl, type }) => {
  const [documents, setDocuments] = useState([]);
  const [totalPages, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    q: withDefault(StringParam, ''),
    page: NumberParam,
    sortColumn: StringParam,
    sortDirection: StringParam,
    tab: withDefault(StringParam, ''),
  });
  const { q, page, sortColumn, sortDirection, tab } = query;
  const [localSearchQuery, setLocalSearchQuery] = React.useState(q);

  const setInitialParams = (data, searchQuery) => {
    // We only set the params once when there is nothing in the url
    if (!query.page) {
      setQuery(
        {
          tab: 'documents',
          q: searchQuery,
          page: data.pagination.currentPage,
          sortColumn: data.sort.column,
          sortDirection: data.sort.direction,
        },
        // use replaceIn as we don't want another navigation to be added to the browser history
        'replaceIn',
      );
    }
  };

  const fetchDocuments = args => {
    setIsLoading(true);

    const { searchQuery, newPage, column, direction, apiToken } = args;

    httpClient
      .get(
        routes.public.orderDocuments.list({
          orderId,
          q: searchQuery,
          page: newPage,
          tab: type,
          column,
          direction,
        }),
        {
          cancelToken: apiToken,
        },
      )
      .then(({ data }) => {
        setDocuments(data.attachments);
        setTotalPage(data.pagination.totalPages);
        setInitialParams(data, searchQuery);
        setIsLoading(false);
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        throw error;
      });
  };

  const deleteDocument = async attachmentId => {
    const { data } = await httpClient.delete(
      routes.public.orderDocuments.delete({ orderId, attachmentId }),
    );

    if (data.error === null) {
      const getDocumentsApiToken = generateSourceToken();

      fetchDocuments({
        searchQuery: q,
        newPage: page,
        orderTab: tab,
        column: sortColumn,
        direction: sortDirection,
        apiToken: getDocumentsApiToken.token,
      });
    }
  };

  const setSearchQueryInParams = React.useRef(
    debounce(500, value => setQuery({ q: value, page: 1 })),
  ).current;

  const setSearchQuery = value => {
    setLocalSearchQuery(value);
    setSearchQueryInParams(value);
  };

  const updateSorting = (column, direction) => {
    setQuery({ sortColumn: column, sortDirection: direction });
  };

  useEffect(() => {
    const getDocumentsApiToken = generateSourceToken();

    fetchDocuments({
      searchQuery: q,
      newPage: page,
      orderTab: tab,
      column: sortColumn,
      direction: sortDirection,
      apiToken: getDocumentsApiToken.token,
    });
    setQuery({ page, tab: 'documents' }, 'replaceIn');

    return () => {
      getDocumentsApiToken.cancel();
    };
  }, [q, page, tab, sortColumn, sortDirection]);

  return (
    <Fragment>
      <div className="public-order-documents-tab__header">
        <div className="public-order-documents-tab__sorting-pill">
          <SortingPill
            size="small"
            name={I18n.t('admin.attachments.collection.uploaded_at')}
            thisSortColumn="created_at"
            currentSortColumn={sortColumn}
            currentSortDirection={sortDirection}
            onChange={updateSorting}
          />
        </div>
        <div className="public-order-documents-tab__search">
          <Input
            size="tiny"
            placeholder={I18n.t('activerecord.attributes.attachment.search')}
            preIcon={<Icon name="search" />}
            value={localSearchQuery}
            onChange={setSearchQuery}
          />
        </div>
        {type === 'general' && (
          <Link
            className="public-order-documents-tab__add-new-button"
            href={createDocumentUrl}
            type="button"
            modifier="primary"
            size="small"
          >
            {I18n.t('admin.shared.action.new')}
          </Link>
        )}
      </div>
      <div className="col-grid col-bleed-x public-order-documents-tab__table">
        <DocumentsTable
          documents={documents}
          fetchDocuments={() => fetchDocuments(q, page, sortColumn, sortDirection)}
          layman={true}
          type={type}
          deleteAction={deleteDocument}
          isLoading={isLoading}
        />
      </div>
      <div className="col-grid col-bleed">
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPaginationClick={newPage => setQuery({ page: newPage })}
        />
      </div>
    </Fragment>
  );
};

TabContent.propTypes = {
  type: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  createDocumentUrl: PropTypes.string.isRequired,
};

export default withQueryParamsProvider(TabContent);
