function zoomLevel(lat1, lon1, lat2, lon2) {
  const MAX_ZOOM_LEVEL=10
  const MIN_ZOOM_LEVEL=1
  const EARTH_RADIUS = 6371e3; // metres

  // Converting lat and lon to radians
  const radianLat1 = (lat1 * Math.PI) / 180; 
  const radianLat2 = (lat2 * Math.PI) / 180;
  const deltaLat = ((lat2 - lat1) * Math.PI) / 180;
  const deltaLng = ((lon2 - lon1) * Math.PI) / 180;
  
  // The formula for circle distance in any map is
  // a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
  // c = 2 ⋅ atan2( √a, √(1−a) )
  // d = EARTH_RADIUS ⋅ c
  // where	φ is latitude, λ is longitude, 
  // note that angles need to be in radians to pass to trig functions!
  // Refer to formula https://www.movable-type.co.uk/scripts/latlong.html
  
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(radianLat1) * Math.cos(radianLat2) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // 1 MegaMeter = 1000000 meters, EARTH_RADIUS*c provides answer in meter
  //  we are converting it to MegaMeter for map distance calculations
  const distance = (EARTH_RADIUS * c)/1000000;

  // We are converting the distance in Megameter to adjust to our
  // Zoom support in map
  const level = MAX_ZOOM_LEVEL - Math.trunc(distance);

  // when the distance is 10 or more it means our map needs to zoomed out
  // Completely. And the opposite is also true
  if(level > MIN_ZOOM_LEVEL)
  {
    return level
  }

  return 1
}

const calculateZoomLevel = cordinates => {
  const zoomLevels = [];
  cordinates.forEach(cordinate => {
    zoomLevels.push(
      zoomLevel(
        cordinate.start.latitude,
        cordinate.start.longitude,
        cordinate.stop.latitude,
        cordinate.stop.longitude,
      ),
    );
  });
  return Math.min(...zoomLevels);
};

export default calculateZoomLevel;
