import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import MealRequests from './Requests';

const MealRequestsForm = ({
  mealRequests,
  fields,
  flightResults,
  disabled,
  onCustomerSelected,
  selectedCustomer,
  travelerIndex,
}) => {
  const segmentCodes = [];
  const mealRequestSelection = [];
  if (flightResults && flightResults.length) {
    flightResults.forEach(result => {
      const segments = [];
      result.trips.forEach(trip => {
        trip.segments.forEach(segment => {
          segments.push({
            carrierCode: segment.operatingCarrierCode,
            flightNumber: segment.operatingFlightNumber,
          });
          segmentCodes.push({
            originCode: segment.originCode,
            destinationCode: segment.destinationCode,
          });
        });
      });

      const key = `${result.id}`;
      mealRequestSelection.push(
        <MealRequests
          key={key}
          resultId={result.resultId}
          segments={segments}
          fields={fields}
          disabled={disabled}
          mealRequestOption={mealRequests[result.resultId]}
          travelerIndex={travelerIndex}
          selectedCustomer={selectedCustomer}
          onCustomerSelected={onCustomerSelected}
        />,
      );
    });
  }

  return <Fragment>{mealRequestSelection}</Fragment>;
};

MealRequestsForm.defaultProps = {
  flightResults: [],
};

MealRequestsForm.propTypes = {
  mealRequests: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  flightResults: PropTypes.arrayOf(PropTypes.shape),
  disabled: PropTypes.bool.isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({}).isRequired,
  travelerIndex: PropTypes.number.isRequired,
};

export default injectIntl(MealRequestsForm);
