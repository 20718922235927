import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Popover from '@wtag/rcl-popover';
import './styles.scss';

const BookingLabelWithHint = ({ text, hint, iconName, iconColor }) => {
  const [isVisible, setIsVisible] = useState(false);

  const openPopover = () => {
    setIsVisible(true);
  };

  return (
    <span className="d-flex align-center flight__information-label-item">
      <div className="d-flex align-center flight__information-label-icon">
        <Icon name={iconName} size="tiny" color={iconColor} />
        {text}
        {hint && (
          <Popover
            className="flight__information-labels-popover"
            isVisible={isVisible}
            onOutsideClick={() => {
              setIsVisible(false);
            }}
            size="tiny"
            content={
              <div className="grid grid-gap-12">
                <h3 className="col-12">{text}</h3>
                <p className="col-12">{hint}</p>
              </div>
            }
            direction="bottom"
          >
            <span role="button" onKeyDown={openPopover} onClick={openPopover} tabIndex={-1}>
              <Icon name="info" size="xs" color="default" />
            </span>
          </Popover>
        )}
      </div>
    </span>
  );
};

BookingLabelWithHint.defaultProps = {
  iconColor: 'default',
};

BookingLabelWithHint.propTypes = {
  text: PropTypes.node.isRequired,
  hint: PropTypes.node.isRequired,
  iconName: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
};

export default BookingLabelWithHint;
