import React from 'react';
import moment from 'moment';
import { I18nText } from '@wtag/react-comp-lib';
import {
  dateValidation,
  greaterThanOrEqualToValidation,
  greaterThanAndLessThanOrEqual,
  presenceValidation,
  presenceValidationForGeolocation,
  totalTravelersValidation,
  validate,
} from '../../../lib/validations';
import isChannelSelected from '../../../lib/helpers/isChannelSelected';

const basicConstraints = {
  adults: {
    ...presenceValidation,
  },
  children: {
    ...presenceValidation,
  },
};
const hotelConstraints = {
  checkIn: { ...presenceValidation },
  checkOut: { ...presenceValidation },
  address: { ...presenceValidation },
  numberOfRooms: { ...presenceValidation },
  latitude: { ...presenceValidationForGeolocation },
  longitude: { ...presenceValidationForGeolocation },
};

const childrenDataConstraints = {
  age: {
    ...presenceValidation,
  },
};

const infantAgeConstraint = {
  age: {
    ...presenceValidation,
    numericality: {
      lessThan: 2,
      message: (
        <I18nText id="ibe.validations.hotel.infant_age" />
      ),
    },
  },
};

const childrenAgeConstraint = {
  age: {
    ...presenceValidation,
    numericality: {
      greaterThanOrEqualTo: 2,
      message: (
        <I18nText id="ibe.validations.hotel.children_age" />
      ),
    },
  },
};

function validateChildren(childrenData, infantCount) {
  const childrenCount = childrenData.length - infantCount;
  const errors = [];

  childrenData.forEach((child, index) => {
    let extendedConstraints;
    if (infantCount === 0) {
      extendedConstraints = { ...childrenDataConstraints };
    }
    else if (index < childrenCount) {
      extendedConstraints = { ...childrenAgeConstraint };
    } else {
      extendedConstraints = { ...infantAgeConstraint };
    }
    errors.push(validate(child, extendedConstraints));
  });

  return errors;
}

function maxCheckInCheckOutDate(params, availableChannels) {
  const isGalileoSelected = isChannelSelected(params, 'galileo', availableChannels);
  const isAmadeusSelected = isChannelSelected(params, 'amadeus', availableChannels);
  const isHotelbedsSelected = isChannelSelected(params, 'hotelbeds', availableChannels);
  const isTravelportRoomsAndMoreSelected = isChannelSelected(
    params,
    'travelport_rooms_and_more',
    availableChannels,
  );

  const maxDateForCheckIn = moment().add(
    isGalileoSelected || isTravelportRoomsAndMoreSelected ? 331 : 361,
    'days',
  );

  let maxDateForCheckOut = moment().add(
    isTravelportRoomsAndMoreSelected || isGalileoSelected ? 332 : 361,
    'days',
  );

  const checkOutConstraints = {
    withinThirtyDaysFromCheckIn:
      isHotelbedsSelected &&
      moment(params.checkIn)
        .add(30, 'days')
        .isBefore(maxDateForCheckOut),
    withinNinetyDaysFromCheckIn:
      (isGalileoSelected || isAmadeusSelected) &&
      moment(params.checkIn)
        .add(99, 'days')
        .isBefore(maxDateForCheckOut),
  };

  if (checkOutConstraints.withinThirtyDaysFromCheckIn) {
    maxDateForCheckOut = moment(params.checkIn).add(30, 'days');
  } else {
    maxDateForCheckOut = checkOutConstraints.withinNinetyDaysFromCheckIn
      ? moment(params.checkIn).add(99, 'days')
      : maxDateForCheckOut;
  }

  return {
    maxDateForCheckIn,
    maxDateForCheckOut,
  };
}

function validateHotel(
  params,
  availableChannels,
  minHoursInFuture,
  numberOfAdults,
  from = 'search',
) {
  const { address, ...constraints } = hotelConstraints;

  const finalConstraints = from === 'multisearch' ? constraints : hotelConstraints;
  const maxCheckInDate = maxCheckInCheckOutDate(params, availableChannels).maxDateForCheckIn;
  const maxCheckOutDate = maxCheckInCheckOutDate(params, availableChannels).maxDateForCheckOut;

  const extendedConstraints = {
    ...finalConstraints,
    checkIn: {
      ...hotelConstraints.checkIn,
      ...dateValidation({
        minDate:
          minHoursInFuture === 0
            ? new Date()
            : moment()
                .utc().add(minHoursInFuture, 'hours')
                .endOf('day'),
        maxDate: maxCheckInDate,
        selectedDate: params.checkIn,
      }),
    },
    checkOut: {
      ...hotelConstraints.checkOut,
      ...dateValidation({
        minDate: moment.utc(params.checkIn).endOf('day'),
        maxDate: maxCheckOutDate,
        selectedDate: params.checkOut,
      }),
    },
    numberOfRooms: {
      ...hotelConstraints.numberOfRooms,
      ...greaterThanAndLessThanOrEqual({
        greaterThan: 0,
        lessThanOrEqualTo: numberOfAdults,
        field: I18n.t('components.ibe.validations.hotel.number_of_rooms'),
        fieldWithRespectTo: I18n.t('components.ibe.validations.common.adult'),
        value: params.numberOfRooms,
      }),
    },
  };

  const validationErrors = validate(params, extendedConstraints);

  if (validationErrors.address === undefined) {
    if (validationErrors.latitude !== undefined && validationErrors.longitude !== undefined) {
      if (
        presenceValidationForGeolocation.presence.message === validationErrors.latitude[0] &&
        presenceValidationForGeolocation.presence.message === validationErrors.longitude[0]
      ) {
        validationErrors.address = validationErrors.latitude;
      }
    }
  }

  return validationErrors;
}

function validateBasicConstraints(params) {
  const adults = parseInt(params.adults, 10);
  const children = parseInt(params.children, 10);
  const infants = 0;
  const hotelTotalTravelersValidation = totalTravelersValidation(
    adults,
    children,
    infants,
  );
  const extendedConstraints = {
    ...basicConstraints,
    adults: {
      ...basicConstraints.adults,
      ...greaterThanOrEqualToValidation(I18n.t('components.ibe.validations.common.adult'), 1),
      ...hotelTotalTravelersValidation,
    },
    children: {
      ...basicConstraints.children,
      ...hotelTotalTravelersValidation,
    }
  };
  return validate(params, extendedConstraints);
}

function validateAllSearchParams(availableChannels, minHoursInFuture, infantCount) {
  return params => {
    const errors = {
      ...validateBasicConstraints(params),
      ...validateHotel(params, availableChannels, minHoursInFuture, params.adults),
    };
    errors.childrenData = validateChildren(params.childrenData, infantCount);
    return errors;
  };
}

export { validateAllSearchParams, validateHotel };
