import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import AppContext from '../AppContext';

class TextareaField extends Component {
  constructor(props) {
    super(props);

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.getUserName = this.getUserName.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.props.context.focusMessageInput();
  }

  onChangeHandler(event) {
    this.props.onChange(event.target.value);
  }

  getUserName = id => {
    const user = this.props.data.find(userData => userData.id === id);
    if (!user) return null;
    return `@${user.display}`;
  };

  handleKeyPress(e) {
    if (
      e.key === 'Enter' &&
      e.shiftKey === false &&
      this.props.checkValidMessage(this.props.value)
    ) {
      e.preventDefault();
      this.props.sendChatMessage(e);
    }
  }

  render() {
    return (
      <MentionsInput
        inputRef={this.props.context.messageInputRef}
        className="chat-editor__textarea"
        value={this.props.value}
        onKeyPress={this.handleKeyPress}
        onChange={this.onChangeHandler}
        placeholder={this.props.placeholder}
        disabled={this.props.context.isAlien}
        suggestionsPortalHost={this.props.messageInputFieldRef.current}
      >
        <Mention
          trigger="@"
          markup="@(__id__)"
          displayTransform={id => this.getUserName(id)}
          data={this.props.data}
          appendSpaceOnAdd={true}
        />
      </MentionsInput>
    );
  }
}

TextareaField.defaultProps = {
  value: '',
  placeholder: '',
};

TextareaField.propTypes = {
  context: PropTypes.shape({
    connected: PropTypes.bool,
    currentUserAvatar: PropTypes.string,
    currentUserId: PropTypes.number,
    currentUserName: PropTypes.string,
    laymanMode: PropTypes.bool,
    tenant: PropTypes.string,
    messageInputRef: PropTypes.shape({}),
    focusMessageInput: PropTypes.func,
    isAlien: PropTypes.bool,
  }).isRequired,
  messageInputFieldRef: PropTypes.shape({
    current: PropTypes.shape({}).isRequired,
  }).isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checkValidMessage: PropTypes.func.isRequired,
  sendChatMessage: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const TextareaFieldWithContext = props => (
  <AppContext.Consumer>
    {context => <TextareaField context={context} {...props} />}
  </AppContext.Consumer>
);

export default TextareaFieldWithContext;
