import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@wtag/react-comp-lib';
import useViewMode from 'sharedWebpack/useViewMode';
import {
  TAB_MOBILE_DEVICE,
  MOBILE_DEVICE,
  SMALL_MOBILE_DEVICE,
} from 'sharedWebpack/old/libraries/responsiveHelpers';
import { PREVIEW_CHAR_LIMIT, PREVIEW_CHAR_LIMIT_IN_MOBILE_VIEW } from './HotelDescriptionConstants';

const HotelDescription = ({ hotelDescription, previewCharLimit, previewCharLimitInMobileView }) => {
  const viewMode = useViewMode();
  const [showAll, setShowAll] = useState(false);

  const isMobileView = [TAB_MOBILE_DEVICE, MOBILE_DEVICE, SMALL_MOBILE_DEVICE].includes(viewMode);

  const getPartialDescription = () => {
    /*
    First, it takes any character(specified by previewCharLimit props) from the beginning
    after that it takes one or more character until it reaches white space or end of line.
    For example: "this component is created for hotel description"
    if the previewCharLimit is 5 for the string then from the used regex we will get output "this component"
    rather than "this c" And here $1 refers the matching character from first bracket
    */
    const substringLength = isMobileView ? previewCharLimitInMobileView : previewCharLimit;
    const subStringPattern = new RegExp(`^(.{${substringLength}}[^\\s]*).*`);
    return hotelDescription.replace(subStringPattern, '$1');
  };

  const toggleShowAll = () => {
    setShowAll(value => !value);
  };

  const description = [];
  const subDescription = getPartialDescription();
  const collapseButton = (
    <Link
      className="hotel-description__description--collapse"
      onClick={toggleShowAll}
      type="default"
      modifier="default"
    >
      {showAll
        ? I18n.t('components.ibe.hotel.details.see_less')
        : I18n.t('components.ibe.hotel.details.see_more')}
    </Link>
  );

  if (subDescription.length < hotelDescription.length) {
    description.push(
      showAll ? (
        <span key="description">{hotelDescription} </span>
      ) : (
        <span key="description">{`${subDescription}...`} </span>
      ),
    );
    description.push(collapseButton);
  } else {
    description.push(<span key="description">{hotelDescription}</span>);
  }

  return (
    <div className="hotel-description">
      <div className="hotel-description__title">
        {I18n.t('components.ibe.hotel.details.description')}
      </div>
      <div className="hotel-description__description">{description}</div>
    </div>
  );
};

HotelDescription.defaultProps = {
  previewCharLimit: PREVIEW_CHAR_LIMIT,
  previewCharLimitInMobileView: PREVIEW_CHAR_LIMIT_IN_MOBILE_VIEW,
  hotelDescription: I18n.t('components.ibe.search_results.hotel.details_not_provided'),
};

HotelDescription.propTypes = {
  hotelDescription: PropTypes.string,
  previewCharLimit: PropTypes.number,
  previewCharLimitInMobileView: PropTypes.number,
};

export default HotelDescription;
