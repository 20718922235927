import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SelectedSeatInfo = ({
  selected,
  item,
  bookedSeatMap,
  isFree,
  formattedPrice,
  traveler,
  currency,
}) => (
  <div className="flight-travelers-seat-detail-unit">
    <span className="name">{`${I18n.t('components.ibe.deals.label.traveler')} ${traveler}`}</span>
    {selected ? (
      <span className="seat-number">
        {`(${I18n.t('components.ibe.deals.label.seat')} ${item.row}${item.seat})`}
      </span>
    ) : (
      <span className="disabled">{I18n.t('components.ibe.deals.label.no_seat_selected')}</span>
    )}
    {selected ? (
      <span className={classNames('value', { free: isFree(bookedSeatMap[item.id]) })}>
        {bookedSeatMap[item.id] ? formattedPrice(bookedSeatMap[item.id].price) : ''}
      </span>
    ) : (
      <span className="disabled">{currency} 00.00</span>
    )}
  </div>
);

SelectedSeatInfo.defaultProps = {
  item: {},
};

SelectedSeatInfo.propTypes = {
  item: PropTypes.shape({
    row: PropTypes.string.isRequired,
    seat: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  bookedSeatMap: PropTypes.shape({
    price: PropTypes.number.isRequired,
    travelerIndex: PropTypes.number.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  isFree: PropTypes.func.isRequired,
  traveler: PropTypes.number.isRequired,
  formattedPrice: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

export default SelectedSeatInfo;
