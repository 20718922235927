import moment from 'moment';

const getStayInfo = flights => {
  const sortableFlights = [...flights];
  const stayInfo = {};

  const compareByTime = (a, b) => moment(a.arrivalLocaltime).diff(moment(b.arrivalLocaltime));

  sortableFlights.sort(compareByTime);

  for (let i = 0; i < sortableFlights.length - 1; i += 1) {
    const currentFlight = sortableFlights[i];
    const nextFlight = sortableFlights[i + 1];

    const currentFlightArrivalTime = moment(currentFlight.arrivalLocaltime);
    const nextFlightDepartureTime = moment(nextFlight.departureLocaltime);

    const {
      code: currentFlightDestination,
      cityName: currentFlightCity,
    } = currentFlight.destinationDetails;

    const nextFlightOrigin = nextFlight.originDetails.code;

    if (currentFlightDestination === nextFlightOrigin) {
      stayInfo[currentFlightDestination] = {
        currentFlightCity,
        currentFlightArrivalTime,
        nextFlightDepartureTime,
      };
    }
  }

  return stayInfo;
};

export default getStayInfo;
