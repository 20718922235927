import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@wtag/rcl-avatar';
import Badge from '@wtag/rcl-badge';
import classNames from 'classnames';
import MarkDown from './MarkDown';
import SanitizedHTML from '../SanitizedHTML';
import AppContext from './AppContext';
import { senderBasicInformation } from './SenderInformation';

const Notifications = props => {
  const { message, room, markMessageAsRead, onReply, context, closeToast } = props;

  const layman = context.laymanMode;

  const onRoomClick = () => {
    onReply(room);
  };

  const onMarkMessageAsRead = () => {
    markMessageAsRead(message);
    closeToast();
  };

  const avatarUrl =
    ['automated', 'announcement'].indexOf(message.messageType) !== -1
      ? context.currentTenantLogoUrl
      : message.avatarURL;

  const sender = senderBasicInformation(message.senderName, avatarUrl, message.online);

  const text = MarkDown(layman).convert(message.text);

  const placeholder =
    message.messageMentions.filter(
      mention => mention.userId === context.currentUserId && mention.isCustomer === layman,
    ).length > 0
      ? I18n.t('chat.mentioned_you')
      : I18n.t('chat.sent_message');

  return (
    <div className="chat-notifications">
      <div className="chat-notifications__message-header-placeholder">{placeholder}</div>
      <div className="chat-notifications__content">
        <div className="chat-notifications__avatar">
          <Avatar
            firstName={sender.firstName}
            lastName={sender.lastName}
            showName={false}
            src={sender.avatarUrl}
            badge={<Badge type={sender.isOnline ? 'active' : 'inactive'} size="tiny" />}
            size="tiny"
          />
        </div>
        <div className="chat-notifications__message">
          <div className="chat-notifications__message-header">
            <div className="chat-notifications__message-header-title">{sender.name}</div>
          </div>
          <div className="chat-notifications__message-body">
            <SanitizedHTML html={text} />
          </div>
          <div className="chat-notifications__message-footer">
            {!context.isAlien && (
              <span
                className="chat-notifications__message-footer-as-read"
                onClick={onMarkMessageAsRead}
                onKeyPress={onMarkMessageAsRead}
                role="presentation"
              >
                {I18n.t('chat.mark_as_read')}
              </span>
            )}

            <span
              className={classNames('chat-notifications__message-footer-reply', {
                'chat-notifications__message-footer-reply-non-alien': !context.isAlien,
              })}
              onClick={onRoomClick}
              onKeyPress={onRoomClick}
              role="presentation"
            >
              {I18n.t('chat.reply')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

Notifications.propTypes = {
  closeToast: PropTypes.func.isRequired,
  context: PropTypes.shape().isRequired,
  message: PropTypes.shape().isRequired,
  room: PropTypes.shape().isRequired,
  markMessageAsRead: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
};

const NotificationsWithContext = props => (
  <AppContext.Consumer>
    {context => <Notifications context={context} {...props} />}
  </AppContext.Consumer>
);

export default NotificationsWithContext;
