import {
  RESET_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN,
  UPDATE_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN,
} from '../actions';

const initialState = {};

function getNewAuthenticationStatusesByTokenState(previousState, { ccToken, encodedReq }) {
  const newState = Object.assign({}, previousState);
  if (!newState[ccToken] || !newState[ccToken][encodedReq]) {
    if (!newState[ccToken]) {
      newState[ccToken] = {};
    }
    newState[ccToken][encodedReq] = true;
  }

  return newState;
}

function skipAuthenticationStatusesByToken(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN:
      return getNewAuthenticationStatusesByTokenState(state, action.encodedData);
    case RESET_SKIP_AUTHENTICATION_STATUSES_BY_TOKEN:
      return initialState;
    default:
      return state;
  }
}

export default skipAuthenticationStatusesByToken;
