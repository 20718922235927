import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import './styles.scss';

const HotelItemDetail = ({ title, subtitle }) => {
  const [displayTitleTooltip, setDisplayTitleTooltip] = useState(false);
  const [displaySubtitleTooltip, setDisplaySubtitleTooltip] = useState(false);

  const titleRef = useRef(null);
  const subTitleRef = useRef(null);

  useEffect(() => {
    setDisplayTitleTooltip(
      titleRef && titleRef.current && titleRef.current.scrollWidth > titleRef.current.offsetWidth,
    );
  }, [titleRef]);

  useEffect(() => {
    setDisplaySubtitleTooltip(subTitleRef.current.scrollWidth > subTitleRef.current.offsetWidth);
  }, [subTitleRef]);

  return (
    <div className="d-flex direction-column row-gap-4">
      {displayTitleTooltip ? (
        <Tooltip content={title} size="tiny">
          <span className="hotel-item__detail-title">{title}</span>
        </Tooltip>
      ) : (
        <span className="hotel-item__detail-title" ref={titleRef}>
          {title}
        </span>
      )}
      {displaySubtitleTooltip ? (
        <Tooltip content={subtitle} size="tiny">
          <span className="hotel-item__detail-sub-title">{subtitle}</span>
        </Tooltip>
      ) : (
        <span className="hotel-item__detail-sub-title" ref={subTitleRef}>
          {subtitle}
        </span>
      )}
    </div>
  );
};

HotelItemDetail.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default HotelItemDetail;
