import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Card from '@wtag/rcl-card';
import { I18nText } from '@wtag/react-comp-lib';
import Map from '@wtag/rcl-map';
import getCarLocationDetails from 'sharedWebpack/IBE/Search/car/helpers/getCarLocationDetails';
import AnimationBar from './subComponents/AnimationBar';
import InformationMessage from './subComponents/InformationMessage';
import CarRentalDuration from './subComponents/CarRentalDuration';

const LoadingInformationCard = ({
  searchInformation,
  searchCompleted,
  airports,
  fetchAirportDetails,
  expand,
}) => {
  const {
    airportSearch,
    driverAge,
    dropOffDate,
    dropOffGeoLocationAddress,
    dropOffGeoLocationLatitude,
    dropOffGeoLocationLongitude,
    dropOffLocationAirportCode,
    dropOffTime,
    pickUpDate,
    pickUpGeoLocationAddress,
    pickUpGeoLocationLatitude,
    pickUpGeoLocationLongitude,
    pickUpLocationAirportCode,
    pickUpTime,
  } = searchInformation;

  const locationAttributes = {
    pickUpGeoLocationAddress,
    dropOffGeoLocationAddress,
    pickUpGeoLocationLatitude,
    pickUpGeoLocationLongitude,
    dropOffGeoLocationLatitude,
    dropOffGeoLocationLongitude,
    pickUpLocationAirportCode,
    dropOffLocationAirportCode,
  };

  const locationDetails = getCarLocationDetails(airports, locationAttributes, airportSearch);

  const {
    address,
    dropOffAddress,
    pickUpLatitude,
    pickUpLongitude,
    dropOffLatitude,
    dropOffLongitude,
  } = locationDetails;

  const formatDate = (date, time) =>
    moment(date)
      .add(moment.duration(time))
      .format('lll');

  const formattedPickUpDate = formatDate(pickUpDate, pickUpTime);
  const formattedDropOffDate = formatDate(dropOffDate, dropOffTime);

  const locationData = [
    {
      pickUp: {
        date: formattedPickUpDate,
        latitude: pickUpLatitude,
        longitude: pickUpLongitude,
        title: address,
      },
      dropOff: {
        date: formattedDropOffDate,
        latitude: dropOffLatitude,
        longitude: dropOffLongitude,
        title: dropOffAddress,
      },
    },
  ];

  useEffect(() => {
    if (airportSearch) {
      fetchAirportDetails(pickUpLocationAirportCode);
      fetchAirportDetails(dropOffLocationAirportCode);
    }
  }, []);

  return (
    <div className="col-12 col-bleed loading-information-card">
      <Card version="v2" size="full">
        {expand && (
          <Fragment>
            {!searchCompleted && <AnimationBar />}
            <div className="col-12 col-bleed col-grid align-center loading-information-card__content">
              <div className="col-lg-6 col-md-6 col-12 col-bleed loading-information-card__content-info">
                <div className="loading-information-card__content-info-text">
                  <I18nText id="ibe.search_loading.car.searched_for" />
                </div>
                <div className="loading-information-card__content-info-address">{address}</div>
                <CarRentalDuration
                  pickUpDateTime={formattedPickUpDate}
                  dropOffDateTime={formattedDropOffDate}
                />
                <div className="loading-information-card__content-info-room-details">
                  <I18nText id="ibe.search_loading.car.driver_age" />
                  :&nbsp;
                  {driverAge}
                </div>
                <InformationMessage />
              </div>
              <div className="col-lg-6 col-md-6 col-12 col-bleed loading-information-card__content-map">
                <Map
                  uniqueKey={`car-${pickUpLatitude}-${pickUpLongitude}`}
                  mapCenter={{ latitude: pickUpLatitude, longitude: pickUpLongitude }}
                  markerSize={{ height: 50, width: 50 }}
                  cars={locationData}
                  zoomLevel={4}
                />
              </div>
            </div>
          </Fragment>
        )}
      </Card>
    </div>
  );
};

LoadingInformationCard.propTypes = {
  expand: PropTypes.bool.isRequired,
  fetchAirportDetails: PropTypes.func.isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ).isRequired,
  searchInformation: PropTypes.shape({
    airportSearch: PropTypes.bool,
    driverAge: PropTypes.number,
    dropOffDate: PropTypes.string,
    dropOffGeoLocationAddress: PropTypes.string,
    dropOffGeoLocationLatitude: PropTypes.number,
    dropOffGeoLocationLongitude: PropTypes.number,
    dropOffLocationAirportCode: PropTypes.string,
    dropOffTime: PropTypes.string,
    pickUpDate: PropTypes.string,
    pickUpGeoLocationAddress: PropTypes.string,
    pickUpGeoLocationLatitude: PropTypes.number,
    pickUpGeoLocationLongitude: PropTypes.number,
    pickUpLocationAirportCode: PropTypes.string,
    pickUpTime: PropTypes.string,
  }).isRequired,
  searchCompleted: PropTypes.bool.isRequired,
};

export default LoadingInformationCard;
