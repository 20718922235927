import { connect } from 'react-redux';
import Place from './Place';
import { fetchPlaceDetails } from '../actions/common';

const mapStateToProps = state => ({
  places: state.common.places,
});

const mapDispatchToProps = dispatch => ({
  fetchPlaceDetails: id => dispatch(fetchPlaceDetails(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Place);
