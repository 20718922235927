import { connect } from 'react-redux';
import VisaAdvisories from './VisaAdvisories';
import { fetchVisaAdvisory } from '../../actions/common/visaAdvisory';

const mapStateToProps = state => ({
  visaAdvisoriesByCitizenshipAndDestination:
    state.common.cart.visaAdvisoriesByCitizenshipAndDestination,
});

const mapDispatchToProps = dispatch => ({
  fetchVisaAdvisory: citizenshipCountryCode => dispatch(fetchVisaAdvisory(citizenshipCountryCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VisaAdvisories);
