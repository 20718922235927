import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@wtag/rcl-radio';
import { PREFERRED_OPTION, EXCLUSIVE_OPTION } from './helpers/airlinePreferenceOptions';

const PreferredAirlineSwitcher = ({ onPreferredChange, value }) => {
  const preferenceDescriptionText =
    value === PREFERRED_OPTION
      ? I18n.t('components.ibe.search_form.flight.label.preferred.description')
      : I18n.t('components.ibe.search_form.flight.label.exclusive.description');

  const handlePreferredChange = () => {
    onPreferredChange(PREFERRED_OPTION);
  };

  const handleExclusiveChange = () => {
    onPreferredChange(EXCLUSIVE_OPTION);
  };

  return (
    <div className="col-12 col-bleed">
      <Radio
        label={I18n.t('components.ibe.search_form.flight.label.preferred.heading')}
        size="large"
        onChange={handlePreferredChange}
        isChecked={value === PREFERRED_OPTION}
        withCheckIcon={false}
      />
      <Radio
        label={I18n.t('components.ibe.search_form.flight.label.exclusive.heading')}
        size="large"
        onChange={handleExclusiveChange}
        isChecked={value === EXCLUSIVE_OPTION}
        withCheckIcon={false}
      />
      <span className="airline-selection__preference-text">{preferenceDescriptionText}</span>
    </div>
  );
};

PreferredAirlineSwitcher.propTypes = {
  onPreferredChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([PREFERRED_OPTION, EXCLUSIVE_OPTION]).isRequired,
};

export default PreferredAirlineSwitcher;
