import React from 'react';
import Button from '@wtag/rcl-button';
import PropTypes from 'prop-types';

const FlightSelectButton = ({
  isItemSelected,
  disableSelect,
  onDeselect,
  group,
  disableItem,
  onCallbackSelect,
}) => (
  <Button
    version="v2"
    size="small"
    type={isItemSelected ? 'default' : 'primary'}
    disabled={disableSelect || (isItemSelected && disableItem)}
    label={
      isItemSelected
        ? I18n.t('components.ibe.results.deselect')
        : I18n.t('components.ibe.results.select')
    }
    onClick={() => !disableSelect && (isItemSelected ? onDeselect(group) : onCallbackSelect(group))}
  />
);

FlightSelectButton.propTypes = {
  isItemSelected: PropTypes.bool.isRequired,
  disableSelect: PropTypes.bool.isRequired,
  onDeselect: PropTypes.func.isRequired,
  group: PropTypes.shape({}).isRequired,
  disableItem: PropTypes.bool.isRequired,
  onCallbackSelect: PropTypes.func.isRequired,
};

export default FlightSelectButton;
