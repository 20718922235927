import React from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import DocumentForm from 'sharedWebpack/Documents/Form';

const CreateDocument = ({ orderId, redirectUrl, attachmentTypes }) => {
  const createDocument = async formData => {
    const { data } = await httpClient.post(
      routes.public.orderDocuments.create({ orderId }),
      formData,
    );

    if (data.error === null) {
      window.location.assign(redirectUrl);
    }
  };

  return (
    <React.Fragment>
      <DocumentForm
        orderId={orderId}
        performAssignedAction={value => createDocument(value)}
        attachmentTypes={attachmentTypes}
        redirectUrl={redirectUrl}
        layman={true}
      />
    </React.Fragment>
  );
};

CreateDocument.propTypes = {
  orderId: PropTypes.number.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  attachmentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CreateDocument;
