import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import './styles.scss';

const TripDetailsItem = ({ className, title, subtitle }) => {
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    setIsShowTooltip(
      titleRef.current && titleRef.current.scrollWidth > titleRef.current.offsetWidth,
    );
  }, [titleRef]);

  return (
    <div className={classNames('trip-details d-flex-column justify-center row-gap-4', className)}>
      <span className="trip-details--title">{title}</span>
      {isShowTooltip ? (
        <Tooltip content={subtitle} size="tiny">
          <div className="trip-details--subtitle">{subtitle}</div>
        </Tooltip>
      ) : (
        <div className="trip-details--subtitle" ref={titleRef}>
          {subtitle}
        </div>
      )}
    </div>
  );
};

TripDetailsItem.defaultProps = {
  className: null,
};

TripDetailsItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
};

export default TripDetailsItem;
