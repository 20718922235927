import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import Amount from 'sharedWebpack/Amount';

const MarginTooltip = (props, context) => {
  if (context.laymanMode || !props.breakdown || props.breakdown.length < 1) {
    return null;
  }

  const labels = props.breakdown.map(margin => (
    <div key={margin.id}>
      {margin.name}:
      <Amount currency={margin.currency} value={margin.total} />
    </div>
  ));

  return (
    <div className={props.className}>
      <Tooltip type="inverse" position={props.position} content={labels}>
        {props.children}
      </Tooltip>
    </div>
  );
};

MarginTooltip.defaultProps = {
  breakdown: [],
  position: 'bottom-right',
};

MarginTooltip.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  breakdown: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      total: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }),
  ),
};

MarginTooltip.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
};

export default MarginTooltip;
