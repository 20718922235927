import React from 'react';
import PropTypes from 'prop-types';
import GraphicElement from './GraphicElement';

const JourneyPath = ({ stopovers }) => {
  const stopoverAirportCodes = stopovers.map(stopover => stopover.airportCode);
  const changedAirportCodes = stopovers
    .filter(stopover => stopover.airportChange)
    .map(stopover => stopover.airportChange.departureAirportCode);

  return (
    <GraphicElement
      stopoverAirportCodes={stopoverAirportCodes}
      changedAirportCodes={changedAirportCodes}
    />
  );
};

JourneyPath.defaultProps = {
  stopovers: [],
};

JourneyPath.propTypes = {
  stopovers: PropTypes.arrayOf(
    PropTypes.shape({
      airportCode: PropTypes.string,
    }),
  ),
};

export default JourneyPath;
