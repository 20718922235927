/* eslint-disable react/prop-types */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import StackedItems from '@wtag/rcl-stacked-items';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Icon from '@wtag/rcl-icon';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import routes from 'agentRoutes';
import Amount from 'sharedWebpack/Amount';
import DateTime from 'sharedWebpack/DateTime';
import ProductIcon from 'sharedWebpack/ProductIcon';
import TravelPlan from 'sharedWebpack/TravelPlan';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import Tooltip from '@wtag/rcl-tooltip';
import TravelersList from './TravelersList';
import '../../../../public/Orders/styles.scss';

const OverviewTab = ({
  order,
  cartFees,
  cartItems,
  fromApprovalRequest,
  isCartItemsLoading,
  layman,
  cart,
}) => {
  const [travellers, setTravellers] = useState([]);
  const [items, setItems] = useState([]);
  const [fees, setFees] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isProductPopoverVisible, setIsProductPopoverVisible] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(4);
  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, ''),
  });

  const { tab } = query;

  const feesToShow = () => {
    if (cartFees && cartFees.length > 0) {
      return cartFees;
    }
    return fees;
  };

  const itemsToShow = () => {
    if (cartItems && cartItems.length > 0) {
      return cartItems;
    }
    return items;
  };

  const findTitleForItem = item => {
    if (item.journeyElementId && item.journeyElementType) {
      return I18n.t(item.journeyElementType, {
        scope: 'admin.components.shared.reservation_types',
      });
    } else if (item.productId && item.productType) {
      return I18n.t(item.productType, {
        scope: 'admin.components.shared.product_types',
      });
    }

    return null;
  };

  const showLoaders = () => !(isCartItemsLoading || isDataLoading);

  const orderAccounting = order && order.accounting;
  const paymentMethodSurcharges = (order && order.paymentMethodSurcharges) || [];

  const showMoreText = () => {
    const remainingTravellers = travellers.length - numberOfItemsToShow;
    if (showAll) {
      return (
        <Fragment>
          <div className="public-order-overview-tab__travellers-show-text">
            {I18n.t('admin.components.orders.overview.show_less')}
          </div>
          <Icon name="iconUpChevron" />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className="public-order-overview-tab__travellers-show-text">
          {I18n.t('admin.components.orders.overview.show_travellers', {
            count: remainingTravellers,
          })}
        </div>
        <Icon name="iconDownChevron" />
      </Fragment>
    );
  };

  const fetchTravellers = apiToken => {
    const travellerUrl = layman
      ? routes.public.orders.travellers({ orderId: order.id })
      : routes.admin.orderTravellers({ orderId: order.id });

    httpClient
      .get(travellerUrl, {
        cancelToken: apiToken,
      })
      .then(({ data }) => {
        setTravellers(data.travellers);
        setIsDataLoading(false);
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        throw error;
      });
  };

  const fetchItems = apiToken => {
    const itemUrl = layman
      ? routes.public.orders.items({ id: order.id })
      : routes.admin.items({ id: order.id });

    httpClient
      .get(itemUrl, {
        cancelToken: apiToken,
      })
      .then(({ data }) => {
        setItems(data.items);
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        throw error;
      });
  };

  const fetchFees = apiToken => {
    const feeUrl = layman
      ? routes.public.orders.fees({ id: order.id })
      : routes.admin.orderFees({ id: order.id });

    httpClient
      .get(feeUrl, {
        cancelToken: apiToken,
      })
      .then(({ data }) => {
        setFees(data.fees);
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        throw error;
      });
  };

  const redemptionVouchersContent = () => (
    <div className="public-order-overview-tab__redemption-voucher-tooltip">
      <Tooltip
        content={
          <div>
            <span className="public-order-overview-tab__redemption-voucher-tooltip--title">
              {I18n.t('admin.components.orders.overview.amount_calculation.voucher_code')}
            </span>
            {order.redemptionVouchers.map(voucher => (
              <div className="public-order-overview-tab__redemption-voucher-tooltip-code">
                {voucher.code}
                <span className="public-order-overview-tab__redemption-voucher-tooltip-code--amount">
                  <Amount currency={order.currency} value={voucher.amount} />
                </span>
              </div>
            ))}
          </div>
        }
        type="inverse"
      >
        <Icon name="invalidOutline" size="tiny" />
      </Tooltip>
    </div>
  );

  useEffect(() => {
    let getItemsApiToken;
    let getTravellersApiToken;
    let getFeesApiToken;

    if (order && order.id) {
      getItemsApiToken = generateSourceToken();
      getTravellersApiToken = generateSourceToken();
      getFeesApiToken = generateSourceToken();

      fetchItems(getItemsApiToken.token);
      fetchTravellers(getTravellersApiToken.token);
      fetchFees(getFeesApiToken.token);
    } else if (cart && cart.people) {
      const travelers = cart.people.map(traveler => traveler.data);
      setTravellers(travelers);
    }

    setQuery({ tab: 'overview' }, 'replaceIn');

    return () => {
      if (order && order.id) {
        getItemsApiToken.cancel();
        getTravellersApiToken.cancel();
        getFeesApiToken.cancel();
      }
    };
  }, [order, tab, cart]);

  const checkShowAll = value => {
    const number = value ? travellers.length : 4;
    setNumberOfItemsToShow(number);
    setShowAll(value);
  };

  const servicesAndCharges = () => {
    let invisibleToCustomerFees = 0;
    feesToShow().forEach(fee => {
      if (!fee.visible) {
        invisibleToCustomerFees += parseFloat(fee.grossTotal);
      }
    });
    return invisibleToCustomerFees;
  };

  const travelPlanCard = () => {
    if (order && order.travelPlan && order.travelPlan.length > 0) {
      return <TravelPlan content={order} layman={layman} />;
    } else if (cart && cart.travelPlan && cart.travelPlan.length > 0) {
      return <TravelPlan content={cart} layman={layman} />;
    }
    return (
      <Card
        version="v2"
        color="tertiary"
        className="travel-plan__timeline-empty"
        emptyCardImageSrc={<Icon name="tag" />}
        emptyCardText={I18n.t('admin.components.orders.overview.travel_plan.no_items')}
      />
    );
  };

  return (
    <div className="grid grid-gap-20 public-order-overview-tab">
      {!fromApprovalRequest && (
        <div className="col-12 col-lg-auto">
          <Card version="v2" className="public-order-overview-tab__date">
            <div className="col-grid col-bleed direction-row align-start">
              <Icon name="today" size="medium" />
              <div className="public-order-overview-tab__date-information">
                <div className="public-order-overview-tab__date-information-text public-order-overview-tab--disabled">
                  {I18n.t('admin.components.orders.left_panel.titles.booking_date')}
                </div>
                <div className="public-order-overview-tab public-order-overview-tab--medium-text">
                  {order && order.bookingDate ? (
                    <DateTime dateTime={order.bookingDate} format="short" />
                  ) : (
                    <ContentLoaderPlaceholder
                      numberOfLines={1}
                      showBackground={false}
                      className="public-order-overview-tab__date-placeholder"
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
      <div className="col-12 col-md-6 col-lg-auto">
        <Card version="v2" className="public-order-overview-tab__date">
          <div className="col-grid col-bleed direction-row align-start">
            <Icon name="today" size="medium" color="danger" />
            <div className="public-order-overview-tab__date-information">
              <div className="public-order-overview-tab__date-information-text public-order-overview-tab--disabled">
                {I18n.t('admin.components.orders.left_panel.titles.departure_date')}
              </div>
              <div className="public-order-overview-tab public-order-overview-tab--medium-text">
                {order && order.departureDate ? (
                  <DateTime dateTime={order.departureDate} format="short" />
                ) : (
                  <div className="card--v2__empty-text">
                    {I18n.t('public.components.orders.no_departure')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="col-12 col-md-6 col-lg-auto">
        <Card version="v2" className="public-order-overview-tab__date">
          <div className="col-grid col-bleed direction-row align-start">
            <Icon name="today" size="medium" color="warning" />
            <div className="public-order-overview-tab__date-information">
              <div className="public-order-overview-tab__date-information-text public-order-overview-tab--disabled">
                {I18n.t('admin.components.orders.left_panel.titles.arrival_date')}
              </div>
              <div className="public-order-overview-tab public-order-overview-tab--medium-text">
                {order && order.arrivalDate ? (
                  <DateTime dateTime={order.arrivalDate} format="short" />
                ) : (
                  <div className="card--v2__empty-text">
                    {I18n.t('public.components.orders.no_arrival')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="col-12 public-order-overview-tab__amounts">
        <Card version="v2">
          <div className="grid grid-gap-20">
            <div className="col-12 public-order-overview-tab__amounts-header">
              <div className="public-order-overview-tab public-order-overview-tab--small-text public-order-overview-tab-section-title">
                {I18n.t('admin.components.orders.tabs.overview')}
              </div>
              {order && order.products && (
                <StackedItems
                  isPopoverVisible={isProductPopoverVisible}
                  onClick={() =>
                    setIsProductPopoverVisible(prevProductPopoverState => !prevProductPopoverState)
                  }
                  onOutsideClick={() => setIsProductPopoverVisible(false)}
                >
                  {order.products.map(type => (
                    <ProductIcon
                      key={uuidv4()}
                      productType={type}
                      {...{
                        showBGColor: true,
                        size: 'tiny',
                        className: 'public-order-overview-tab__product-icon',
                      }}
                    />
                  ))}
                </StackedItems>
              )}
            </div>
            <div className="col-md-12 col-lg-6 public-order-overview-tab__travellers">
              {showLoaders() ? (
                <ContentLoaderPlaceholder
                  numberOfLines={4}
                  showBackground={false}
                  className="public-order-overview-tab__date-placeholder"
                />
              ) : (
                <div className="public-order-overview-tab__travellers-info">
                  {travellers && (
                    <div className="public-order-overview-tab__travellers-details">
                      <div className="public-order-overview-tab__travellers-title">
                        {I18n.t('admin.components.orders.overview.trips', {
                          count: travellers.length,
                        })}
                      </div>
                      <TravelersList
                        travelers={travellers}
                        numberOfItemsToShow={numberOfItemsToShow}
                      />
                      {travellers.length > 4 && (
                        <button
                          type="button"
                          className="side-panel-link public-order-overview-tab__travellers-show"
                          onClick={() => checkShowAll(!showAll)}
                        >
                          {showMoreText()}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-md-12 col-lg-6">
              {showLoaders() ? (
                <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
              ) : (
                <div className="public-order-overview-tab__accounting-details">
                  <div className="public-order-overview-tab__travellers-total-price">
                    {I18n.t('admin.components.orders.overview.total_price')}
                  </div>
                  <div className="public-order-overview-tab public-order-overview-tab--large-text public-order-overview-tab-highlighted-price">
                    {order && orderAccounting && (
                      <Amount currency={order.currency} value={order.accounting.totalAmount} />
                    )}
                  </div>
                  {itemsToShow() &&
                    itemsToShow().map(item => (
                      <Fragment key={uuidv4()}>
                        <div className="public-order-overview-tab__accounting-card">
                          <div className="col-grid col-bleed direction-row">
                            <div className="col-grid col-bleed direction-row align-center justify-space-between">
                              <div className="public-order-overview-tab--bold public-order-overview-tab--with-right-padding">
                                {findTitleForItem(item)}: {item.title}
                              </div>
                              <div className="public-order-overview-tab--bold">
                                <Amount currency={item.currency} value={item.grossAmount} />
                              </div>
                            </div>
                          </div>
                        </div>
                        {item.subItem &&
                          item.subItems.map(subItem => (
                            <div
                              key={uuidv4()}
                              className="public-order-overview-tab__accounting-card"
                            >
                              <div className="col-grid col-bleed direction-row">
                                <div className="col-grid col-bleed direction-row align-center justify-space-between">
                                  <div className="public-order-overview-tab__accounting-card--left-margin public-order-overview-tab--bold public-order-overview-tab--with-right-padding">
                                    {' '}
                                    {I18n.t('admin.components.orders.overview.seat', {
                                      number: subItem.title,
                                    })}
                                  </div>
                                  <div className="public-order-overview-tab--bold">
                                    <Amount
                                      currency={subItem.currency}
                                      value={subItem.grossAmount}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Fragment>
                    ))}
                  {fees && (
                    <Fragment>
                      {fees.map(
                        fee =>
                          fee.visible && (
                            <div
                              key={uuidv4()}
                              className="public-order-overview-tab__accounting-card"
                            >
                              <div className="col-grid col-bleed direction-row">
                                <div className="col-grid col-bleed direction-row align-center justify-space-between">
                                  <div className="public-order-overview-tab--bold public-order-overview-tab--with-right-padding">
                                    {fee.name}
                                  </div>
                                  <div className="public-order-overview-tab--bold">
                                    <Amount currency={fee.currency} value={fee.grossTotal} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ),
                      )}
                      {paymentMethodSurcharges.map(paymentMethodSurcharge => (
                        <div
                          className="public-order-overview-tab__accounting-card"
                          key={paymentMethodSurcharge.id}
                        >
                          <div className="col-grid col-bleed direction-row">
                            <div className="col-grid col-bleed direction-row align-center justify-space-between">
                              <div className="public-order-overview-tab--bold public-order-overview-tab--with-right-padding">
                                {paymentMethodSurcharge.title}
                              </div>
                              <div className="public-order-overview-tab--bold">
                                <Amount
                                  currency={paymentMethodSurcharge.currency}
                                  value={paymentMethodSurcharge.amount}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {servicesAndCharges() > 0 && (
                        <div className="public-order-overview-tab__accounting-card">
                          <div className="col-grid col-bleed direction-row">
                            <div className="col-grid col-bleed direction-row align-center justify-space-between">
                              <div className="public-order-overview-tab--bold public-order-overview-tab--with-right-padding">
                                {I18n.t('admin.components.orders.overview.fees')}
                              </div>
                              <div className="public-order-overview-tab--bold">
                                <Amount currency={order.currency} value={servicesAndCharges()} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}

                  <div className="public-order-overview-tab__accounting-card">
                    <div className="col-grid col-bleed direction-row">
                      <div className="col-grid col-bleed direction-row align-center justify-space-between">
                        <div className="public-order-overview-tab--bold public-order-overview-tab--with-right-padding">
                          {I18n.t('admin.components.orders.overview.tax')}
                        </div>
                        <div className="public-order-overview-tab--bold">
                          {order && orderAccounting && (
                            <Amount currency={order.currency} value={order.accounting.taxes} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {order && order.redemptionVouchers && order.redemptionVouchers.length > 0 && (
                    <div className="public-order-overview-tab__redemption-voucher">
                      <div className="public-order-overview-tab__redemption-voucher-item">
                        <div className="public-order-overview-tab__redemption-voucher-item--header">
                          {I18n.t('admin.components.orders.overview.amount_calculation.subtotal')}
                        </div>
                        <div className="public-order-overview-tab__redemption-voucher-item--amount">
                          <Amount currency={order.currency} value={order.accounting.totalAmount} />
                        </div>
                      </div>

                      <div className="public-order-overview-tab__redemption-voucher-item">
                        <div className="public-order-overview-tab__redemption-voucher-item--header">
                          {I18n.t(
                            'admin.components.orders.overview.amount_calculation.voucher_applied',
                          )}
                          {redemptionVouchersContent()}
                        </div>
                        <div className="public-order-overview-tab__redemption-voucher-item--amount">
                          <Amount currency={order.currency} value={order.redemptionVouchersTotal} />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="public-order-overview-tab__accounting-card">
                    <div className="public-order-overview-tab__accounting-card--content">
                      <div className="public-order-overview-tab__total-amount public-order-overview-tab--with-right-padding">
                        {I18n.t('admin.components.orders.overview.amount_calculation.total_amount')}
                      </div>
                      <div className="public-order-overview-tab__total-amount">
                        {order && <Amount currency={order.currency} value={order.total} />}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
      <div className="col-12 order-overview-tab__travel-plan">{travelPlanCard()}</div>
    </div>
  );
};

OverviewTab.defaultProps = {
  isCartItemsLoading: true,
  cartItems: null,
  fromApprovalRequest: false,
  cartFees: [],
  layman: false,
};

OverviewTab.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    currency: PropTypes.string,
    bookingDate: PropTypes.string,
    departureDate: PropTypes.string,
    arrivalDate: PropTypes.string,
    accounting: PropTypes.shape({
      totalAmount: PropTypes.string,
      taxes: PropTypes.string,
      servicesAmount: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        currency: PropTypes.string,
        title: PropTypes.string,
        grossAmount: PropTypes.string,
      }),
    ),
    travellers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        avatarUrl: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    products: PropTypes.arrayOf(PropTypes.string).isRequired,
    paymentMethodSurcharges: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        amount: PropTypes.string,
        currency: PropTypes.string,
      }),
    ),
  }).isRequired,
  isCartItemsLoading: PropTypes.bool,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      grossAmount: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  fromApprovalRequest: PropTypes.bool,
  cartFees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      grossTotal: PropTypes.string,
    }),
  ),
  layman: PropTypes.bool,
};

export default withQueryParamsProvider(OverviewTab);
