import {
  FLIGHT_RESULT_SELECTED,
  CREATE_FLIGHT_SEARCH,
  FETCHING_FLIGHT_SEARCHED,
  FLIGHT_GROUP_SELECTED,
} from '../../actions/flight';

export default function selectedResults(state = {}, action) {
  let newState = {
    ...state,
  };

  const differentPackage = () => {
    const values = Object.values(newState);
    const existingResultType = values.map(selection => selection.resultType);
    const selectedResultType = action.result.resultType;

    if (selectedResultType === 'packaged') {
      return existingResultType.some(resultType => resultType !== selectedResultType);
    }

    return existingResultType.some(resultType => !['semi', 'unpackaged'].includes(resultType));
  };

  switch (action.type) {
    case FLIGHT_RESULT_SELECTED:
      if (differentPackage()) {
        newState = {};
      }
      newState[action.matrix.id] = {
        resultId: action.result.resultId,
        resultType: action.result.resultType,
        packageSize: action.result.packageSize,
        channelId: action.matrix.channelId,
      };
      return newState;

    case FETCHING_FLIGHT_SEARCHED:
      return state;

    case CREATE_FLIGHT_SEARCH:
      return {};

    case FLIGHT_GROUP_SELECTED:
      return {};

    default:
      return state;
  }
}
