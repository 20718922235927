import moment from 'moment';
import {
  validate,
  presenceValidation,
  dateValidation,
  hourMinuteValidation,
  maxLengthValidation,
  timeValidation,
  driverAgeValidation,
} from '../../../lib/validations';
import isChannelSelected from '../../../lib/helpers/isChannelSelected';

const carConstraints = {
  pickUpDate: { ...presenceValidation },
  dropOffDate: { ...presenceValidation },
  pickUpTime: { ...presenceValidation, ...hourMinuteValidation },
  dropOffTime: { ...presenceValidation, ...hourMinuteValidation },
  driverAge: { ...presenceValidation, ...driverAgeValidation(99) },
};

const validateCar = (params, minHoursInFuture, availableChannels) => {
  const finalConstraints = carConstraints;
  const isGalileoSelected = isChannelSelected(params, 'galileo', availableChannels);

  let finalParams = { ...params };

  let locationConstraints = {};
  if (params.airportSearch) {
    locationConstraints = {
      pickUpLocationAirportCode: { ...presenceValidation },
      dropOffLocationAirportCode: { ...presenceValidation },
    };
  } else {
    locationConstraints = {
      pickUpGeoLocationAddress: { ...presenceValidation },
      dropOffGeoLocationAddress: { ...presenceValidation },
    };
  }

  const pickUpDateTime = moment(params.pickUpDate)
    .hours(0)
    .add(moment.duration(params.pickUpTime));

  const dropOffDateTime = moment(params.dropOffDate)
    .hours(0)
    .add(moment.duration(params.dropOffTime));

  const maxPickUpDropOffDate = moment()
    .add(isGalileoSelected ? 330 : 360, 'days')
    .endOf('day');

  if (
    params.pickUpDate !== undefined &&
    pickUpDateTime.isValid() &&
    params.dropOffDate !== undefined &&
    dropOffDateTime.isValid()
  ) {
    finalParams = {
      ...finalParams,
      pickUpDate: pickUpDateTime.toDate(),
      dropOffDate: dropOffDateTime.toDate(),
    };
  }

  const extendedConstraints = {
    ...locationConstraints,
    ...finalConstraints,
    pickUpDate: {
      ...carConstraints.pickUpDate,
      ...dateValidation({
        minDate:
          minHoursInFuture === 0
            ? new Date()
            : moment()
              .utc().add(minHoursInFuture, 'hours')
              .endOf('day'),
        maxDate: maxPickUpDropOffDate,
        selectedDate: pickUpDateTime,
        showDateWithHours: false,
      }),
    },
    dropOffDate: {
      ...carConstraints.dropOffDate,
      ...dateValidation({
        minDate: moment.utc(params.pickUpDate).endOf('day'),
        maxDate: maxPickUpDropOffDate,
        selectedDate: dropOffDateTime,
        showDateWithHours: false,
      }),
    },
    dropOffTime: {
      ...carConstraints.dropOffTime,
      ...timeValidation({
        dropOffTime: params.dropOffTime,
        pickUpDate: params.pickUpDate,
        dropOffDate: params.dropOffDate,
        pickUpTime: params.pickUpTime,
      }),
    },
  };
  return validate(finalParams, extendedConstraints);
};

const validateVendorPreferences = (availableChannels, params) => {
  if (
    params.accountSettings &&
    params.accountSettings.galileo &&
    params.accountSettings.galileo.length === 0
  ) {
    return {};
  }
  if (!params.accountSettings && !availableChannels.includes('galileo')) {
    return {};
  }
  const constraints = {
    codes: {
      ...maxLengthValidation(4),
    },
  };
  return validate(params.vendorPreferences, constraints);
};

const validateAllSearchParams = (availableChannels, minHoursInFuture) => params => {
  const errors = validateCar(params, minHoursInFuture, availableChannels);
  errors.vendorPreferences = validateVendorPreferences(availableChannels, params);
  return errors;
};

export { validateAllSearchParams, validateCar };
