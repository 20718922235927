import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';
import { I18nText, Spinner } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import Tooltip from '@wtag/rcl-tooltip';
import AncillariesUnavailabilityModal from '../AncillariesUnavailabilityModal';

import BookingProgressSpinner from '../BookingProgessSpinner';
import {
  PROCEDURE_MAPPING,
  ORDER_STYLES,
  BOOKING_STYLES,
  PAYMENT_PROVIDER_FORM_MAPPING,
  cartHasItemWithCreditCard,
} from '../helpers/booking';

import './styles.scss';

const SpinnerWithLabel = ({ label }) => (
  <Fragment>
    <Tooltip
      className="progress-spinner"
      content={label}
      gap={2}
      type="inverse"
      showArrow={false}
      position="bottom-right"
    >
      <div className="progress-spinner__tooltip progress-spinner__circular">
        <Spinner size="tiny" bgColor="neutral" />
      </div>
    </Tooltip>
    <div className="d-flex justify-between align-center progress-spinner__label">
      <span className="progress-spinner__label-content">{label}</span>
      <span className="progress-spinner__circular">
        <Spinner size="tiny" bgColor="neutral" />
      </span>
    </div>
  </Fragment>
);

SpinnerWithLabel.propTypes = {
  label: PropTypes.node.isRequired,
};

class LaymanBookButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentGatewayAttributes: null,
      showModal: false,
      unavailableServices: {},
      openUnavailableAncillariesModal: false,
    };
  }

  componentDidMount() {
    if (this.props.paymentGateway) {
      this.fetchPaymentGatewayInfo();
    }
  }

  onModalOpen = () => {
    this.props.storeCartRecipientData();
    this.setState({ modalOpen: true }, () => {
      this.props.setCheckingAvailability(true);
      this.props.setIsBookingButtonClicked(true);
      this.createBooking();
    });
  };

  onModalClose = () => {
    this.setState({ modalOpen: false });
  };

  createBooking = () => {
    this.props.setIsBookingButtonClicked(true);
    this.props.setCheckingAvailability(true);
    this.props.checkCartAvailability().then(response => {
      if (Object.values(response.unavailableServices).length > 0) {
        this.setState({
          unavailableServices: response.unavailableServices,
          openUnavailableAncillariesModal: true,
        });
      } else if (this.props.paymentGateway) {
        this.continueBookingByPaymentGateway();
      } else if (cartHasItemWithCreditCard(this.props.cartItems)) {
        this.props.setCheckingAvailability(false);
        this.continueBookingByCCV();
      } else {
        this.continueBookingByCash();
      }
    });
  };

  continueBookingOnUnavailableAncillaries = itemIds => {
    this.props.removeItems(itemIds).then(() => {
      if (this.props.paymentGateway) {
        this.continueBookingByPaymentGateway();
      } else if (cartHasItemWithCreditCard(this.props.cartItems)) {
        this.continueBookingByCCV();
      } else {
        this.continueBookingByCash();
      }
    });
  };

  continueBookingByCCV = () => {
    if (this.props.bookingPossible) {
      this.prepareAndCreateOrder().then(this.bookAllItemsWithDebounce);
    }
  };

  continueBookingByPaymentGateway = () => {
    if (this.props.bookingPossible && this.props.canProceedWithPayment) {
      this.props.setCheckingAvailability(false);
      this.props.setIsBookingButtonClicked(true);
      this.props
        .storeCartRecipientData()
        .then(() =>
          this.props.prepareForExternalPayment({
            procedure: PROCEDURE_MAPPING[ORDER_STYLES.NEW][BOOKING_STYLES.REAL],
          }),
        )
        .then(() => {
          if (this.context.iframeMode) {
            this.setState({ showModal: true });
          } else {
            this.submitButtonRef.click();
          }
        });
    }
  };

  continueBookingByCash = () => {
    this.props.setCheckingAvailability(false);
    this.props.setIsBookingButtonClicked(true);
    if (this.props.bookingPossible) {
      this.prepareAndCreateOrder().then(() => {
        this.props.startBooking({
          procedure: PROCEDURE_MAPPING[ORDER_STYLES.NEW][BOOKING_STYLES.REAL],
        });
      });
    }
  };

  fetchPaymentGatewayInfo = () => {
    const redirectUrl = this.context.iframeMode
      ? `${window.location.href}/pop-up-return`
      : window.location.href;
    this.props.setCheckingAvailability(true);
    this.props
      .fetchPaymentGatewayInfo(
        this.props.paymentGateway.gateway,
        this.props.paymentGateway.cardType,
        this.props.cartId,
        redirectUrl,
        `${window.location.origin}/api`,
        this.context.iframeMode,
      )
      .then(paymentGatewayAttributes =>
        this.setState({ paymentGatewayAttributes }, () => {
          this.props.setCheckingAvailability(false);
        }),
      );
  };

  payAndBookAfterConfirmation = () => {
    this.submitButtonRef.click();
    this.setState({ showModal: false });
    this.props.retrackBooking(this.props.cartId);
  };

  bookButtonForPaymentPortal = () => {
    if (!this.state.paymentGatewayAttributes) return null;

    const PaymentProviderForm = PAYMENT_PROVIDER_FORM_MAPPING[this.props.paymentGateway.gateway];
    return (
      <React.Fragment>
        <ConfirmationModal
          className="confirmation-modal__book-buttons"
          isModalOpen={this.context.iframeMode && this.state.showModal}
          confirmationHeader={I18n.t('public.shared.payment_gateway.header')}
          subHeader={I18n.t('public.shared.payment_gateway.subheader')}
          confirmationText={I18n.t('public.shared.payment_gateway.proceed')}
          rejectionText={I18n.t('public.shared.payment_gateway.cancel')}
          onConfirm={this.payAndBookAfterConfirmation}
          onReject={() => {
            this.props.setIsBookingButtonClicked(false);
            this.setState({ showModal: false });
          }}
          withAction={true}
          type="warning"
        />
        <PaymentProviderForm
          url={this.props.paymentGateway.url}
          attributes={this.state.paymentGatewayAttributes}
          iframeMode={this.context.iframeMode}
          setButtonRef={submitButton => {
            this.submitButtonRef = submitButton;
          }}
        />
        <div className="book-buttons__button-contents-button">
          <Button
            version="v2"
            size="small"
            label={
              <Fragment>
                {this.props.isBookingButtonClicked && <Spinner size="tiny" bgColor="neutral" />}
                {I18n.t('components.ibe.check_out_progress.buttons.pay_and_book')}
              </Fragment>
            }
            primary={true}
            onClick={this.createBooking}
            disabled={
              this.props.disabled ||
              this.props.checkingAvailability ||
              this.props.isBookingButtonClicked
            }
          />
        </div>
      </React.Fragment>
    );
  };

  bookAllItems = () => {
    this.props.ccTokensThatNeedToBook.forEach(ccToken => {
      this.props.bookingComponentRefByToken[ccToken].current.submitCreditCardAction();
    });

    // We are delaying this by 2 seconds for each bookingComponent we have mounted
    // as from ccv side they wait 2 seconds after submitting booking. So that
    // We don't unmount the component before booking triggers
    setTimeout(() => {
      this.props.startBooking({
        procedure: PROCEDURE_MAPPING[ORDER_STYLES.NEW][BOOKING_STYLES.REAL],
      });
      this.setState({ modalOpen: false });
    }, this.props.ccTokensThatNeedToBook.length * 2000);
  };

  bookAllItemsWithDebounce = debounce(10000, true, this.bookAllItems);

  prepareAndCreateOrder = () =>
    this.props.prepareForBooking({
      procedure: PROCEDURE_MAPPING[ORDER_STYLES.NEW][BOOKING_STYLES.REAL],
    });

  renderBookButton = bookButton => {
    if (!this.props.onlyOneItemFromSearch && this.props.showQuoteButton) {
      return (
        <Tooltip
          content={
            <I18nText id="ibe.book_quote.only_one_item_per_search" returnStringOnly={true} />
          }
          position="top-right"
          type="important"
        >
          {bookButton}
        </Tooltip>
      );
    }
    return bookButton;
  };

  render() {
    const servicesLoadingSpinner = (
      <SpinnerWithLabel
        label={<I18nText id="ibe.bookings.loading_booking_services" returnStringOnly={true} />}
      />
    );

    const authenticatingPaymentSpinner = (
      <SpinnerWithLabel
        label={
          <I18nText
            id="ibe.check_out_progress.texts.authenticating_payments"
            returnStringOnly={true}
          />
        }
      />
    );

    let bookButton = null;
    if (
      !this.context.paymentDisabled &&
      !(this.context.allowPaymentViaInvoice && this.props.paymentGateway === null)
    ) {
      if (this.props.paymentGateway) {
        bookButton = (
          <BookingProgressSpinner
            component={this.bookButtonForPaymentPortal()}
            isProcessing={!this.state.paymentGatewayAttributes}
            noMessage={true}
          />
        );
      }
    } else if (cartHasItemWithCreditCard(this.props.cartItems)) {
      if (this.props.loadingBookingServices) {
        bookButton = servicesLoadingSpinner;
      } else if (this.state.modalOpen) {
        bookButton = authenticatingPaymentSpinner;
      } else {
        bookButton = (
          <div className="book-buttons__button-contents-button">
            <Button
              label={I18n.t('components.ibe.check_out_progress.buttons.book')}
              version="v2"
              size="small"
              type="primary"
              onClick={this.onModalOpen}
              disabled={this.props.disabled}
            />
          </div>
        );
      }
    } else if (this.props.loadingBookingServices) {
      bookButton = servicesLoadingSpinner;
    } else {
      bookButton = (
        <div className="book-buttons__button-contents-button">
          <Button
            version="v2"
            size="small"
            label={
              <Fragment>
                {this.props.isBookingButtonClicked && <Spinner size="tiny" bgColor="neutral" />}
                {I18n.t('components.ibe.check_out_progress.buttons.book')}
              </Fragment>
            }
            onClick={this.createBooking}
            icon={<Icon name="add" />}
            primary={true}
            disabled={
              this.props.disabled ||
              this.props.isBookingButtonClicked ||
              !this.props.checkCartAvailability
            }
          />
        </div>
      );
    }

    const allButtons = (
      <div className="d-flex align-center justify-end book-buttons__button-contents">
        {this.props.showQuoteButton && (
          <div className="book-buttons__button-contents-button">
            <Button
              version="v2"
              size="small"
              label={
                <Fragment>{I18n.t('components.ibe.check_out_progress.buttons.quote')}</Fragment>
              }
              onClick={() => {
                this.props.setIsBookingButtonClicked(true);
                this.props
                  .prepareForBooking({
                    procedure: PROCEDURE_MAPPING[ORDER_STYLES.NEW][BOOKING_STYLES.QUOTE],
                  })
                  .then(() => {
                    this.props.startBooking({
                      procedure: PROCEDURE_MAPPING[ORDER_STYLES.NEW][BOOKING_STYLES.QUOTE],
                    });
                  });
              }}
              icon={<Icon name="addOrder" />}
              disabled={this.props.isBookingButtonClicked && !!this.props.checkCartAvailability}
            />
          </div>
        )}
        {this.renderBookButton(bookButton)}
      </div>
    );
    return (
      <Fragment>
        <AncillariesUnavailabilityModal
          unavailableServices={this.state.unavailableServices}
          modalOpen={this.state.openUnavailableAncillariesModal}
          cartId={this.props.cartId}
          fetchJourneyElement={this.props.fetchJourneyElement}
          onContinueBooking={this.continueBookingOnUnavailableAncillaries}
          removeItems={this.props.removeItems}
        />
        <div className="book-buttons">{allButtons}</div>
      </Fragment>
    );
  }
}

LaymanBookButton.defaultProps = {
  isBookingButtonClicked: false,
  checkingAvailability: false,
};

LaymanBookButton.propTypes = {
  cartId: PropTypes.string.isRequired,
  bookingPossible: PropTypes.bool.isRequired,
  onlyOneItemFromSearch: PropTypes.bool.isRequired,
  canProceedWithPayment: PropTypes.bool.isRequired,
  startBooking: PropTypes.func.isRequired,
  checkCartAvailability: PropTypes.func.isRequired,
  prepareForBooking: PropTypes.func.isRequired,
  retrackBooking: PropTypes.func.isRequired,
  fetchPaymentGatewayInfo: PropTypes.func.isRequired,
  paymentGateway: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  showQuoteButton: PropTypes.bool.isRequired,
  cartItems: PropTypes.shape([]).isRequired,
  ccTokensThatNeedToBook: PropTypes.arrayOf(PropTypes.string).isRequired,
  bookingComponentRefByToken: PropTypes.node.isRequired,
  storeCartRecipientData: PropTypes.func.isRequired,
  prepareForExternalPayment: PropTypes.func.isRequired,
  loadingBookingServices: PropTypes.bool.isRequired,
  fetchJourneyElement: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired,
  setCheckingAvailability: PropTypes.func.isRequired,
  checkingAvailability: PropTypes.bool,
  isBookingButtonClicked: PropTypes.bool,
  setIsBookingButtonClicked: PropTypes.func.isRequired,
};

LaymanBookButton.contextTypes = {
  ccvPublicToken: PropTypes.string.isRequired,
  ccvURL: PropTypes.string.isRequired,
  paymentDisabled: PropTypes.bool.isRequired,
  tenantIdentifier: PropTypes.string.isRequired,
  iframeMode: PropTypes.bool.isRequired,
  allowPaymentViaInvoice: PropTypes.bool.isRequired,
};
export default LaymanBookButton;
