import { WARNING, SUCCESS } from 'sharedWebpack/IBE/lib/helpers/componentStates';

const getDesiredSeatProperties = (seatTitle, route, seatAmountsAndTitles) => {
  const desiredSeat = seatAmountsAndTitles.find(({ itemTitle }) => seatTitle === itemTitle);
  const { grossAmount } = desiredSeat || {};
  const status = Number(grossAmount) > 0 ? WARNING : SUCCESS;
  const title = `${seatTitle} ${route}`;

  return {
    status,
    title,
  };
};

const getSeatList = (segments, seatAmountsAndTitles) =>
  segments.flatMap(({ originCode: origin, destinationCode: destination, seats }) => {
    const route = `(${origin} - ${destination})`;

    return seats.map(({ row, seat }) => {
      const seatTitle = `${row}${seat}`;
      return getDesiredSeatProperties(seatTitle, route, seatAmountsAndTitles);
    });
  });

export default getSeatList;
