const title = prefix =>
  I18n.t(prefix, {
    scope: 'components.ibe.traveler_details.title_prefix',
  });

const PREFIXES = ['mrs', 'ms', 'mr'];

export default function namePrefixBuilder() {
  return PREFIXES.map(prefix => ({
    value: prefix,
    label: title(prefix),
  }));
}
