const generateMergedFee = (intl, fee, feeTitle) => ({
  id: fee.id,
  name: feeTitle || I18n.t('components.ibe.taxes_services_charges'),
  grossPrice: parseFloat(fee.grossAmount),
  grossAmount: parseFloat(fee.grossAmount),
  grossVat: parseFloat(fee.grossVat),
  grossTotal: parseFloat(fee.grossTotal),
  quantity: 1,
  currency: fee.currency,
});

export default function feeList(
  feeCatalogue,
  selectedFees,
  intl,
  showInvisibleFees,
  additionalFeeTitle,
) {
  if (!feeCatalogue.length) {
    return [];
  }
  const mappedFees = [];
  let mergedFee = null;

  const fees = selectedFees.map(fee => {
    const catalogueFee = feeCatalogue.find(currentFee => currentFee.id === fee.id);
    return {
      name: catalogueFee.name,
      currency: catalogueFee.currency,
      visibleToCustomer: catalogueFee.visibleToCustomer,
      grossPrice: catalogueFee.grossPrice,
      ...fee,
    };
  });
  fees.forEach(fee => {
    if (fee.visibleToCustomer || showInvisibleFees) {
      mappedFees.push(fee);
    } else if (!mergedFee) {
      mergedFee = generateMergedFee(intl, fee, additionalFeeTitle);
    } else {
      mergedFee.grossPrice += parseFloat(fee.grossAmount);
      mergedFee.grossAmount += parseFloat(fee.grossAmount);
      mergedFee.grossVat += parseFloat(fee.grossVat);
      mergedFee.grossTotal += parseFloat(fee.grossTotal);
    }
  });
  if (mergedFee) {
    mappedFees.push(mergedFee);
  }

  return mappedFees;
}
