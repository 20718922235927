/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';

import Row from '../Row';

function StaticProduct(props) {
  const { item } = props;

  return (
    <div
      className={classNames('cart-summary__accordion-sub-item', {
        'cart-summary__accordion-sub-item--pending-confirmation': item.pendingConfirmation,
      })}
    >
      <Row
        name={`${parseFloat(item.quantity)} x ${item.itemTitle}`}
        quantity={item.quantity}
        journeyElementType={item.type}
        price={item.overAllGrossAmount}
        unitPrice={item.grossAmount}
        currency={item.currency}
        placeHolder={<Icon name="equipment" />}
        pendingConfirmation={item.pendingConfirmation}
      />
      <Row
        name={I18n.t('components.ibe.summary.taxes_and_charges')}
        price={item.overAllGrossTax}
        currency={item.currency}
        hasChildRow={false}
      />
    </div>
  );
}
StaticProduct.propTypes = {
  item: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    available: PropTypes.bool,
    pendingConfirmation: PropTypes.bool.isRequired,
  }).isRequired,
};
export default StaticProduct;
