export const parseJSON = response =>
  response.text().then(data => {
    try {
      return {
        status: response.status,
        json: JSON.parse(data),
      };
    } catch (error) {
      const throwError = new Error(error);
      throwError.extraData = {
        status: response.status,
        statusText: response.statusText,
        payload: data,
      };
      return Promise.reject(throwError);
    }
  });

export const checkStatus = ({ status, json }) => {
  if (status >= 200 && status < 300) {
    return Promise.resolve(json);
  }
  const error = new Error(json.error);
  error.extraData = { status, json };
  return Promise.reject(error);
};
