import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import { searchAirlinePreference, emptyPreferredAirlines } from '../../actions/flight';
import AirlineList from './AirlineList';

const AirlineSelectionDivider = ({ last = false }) => (
  <div
    className={`airline-selection__bottom-divider ${last &&
      'airline-selection__bottom-divider--last'}`}
  />
);

// Helper function to render the empty list message or loading placeholder
const EmptyAirlinesList = ({ loading }) => {
  if (!loading) {
    return (
      <ContentLoaderPlaceholder
        className="airline-selection__loader"
        numberOfLines={3}
        showBackground={false}
        diameter="tiny"
      />
    );
  }

  return (
    <div className="airline-selection__empty-list">
      {I18n.t('components.ibe.search_form.flight.label.no_airlines')}
    </div>
  );
};

const MIN_SEARCH_QUERY_LENGTH = 2;
const MAX_AIRLINE_LENGTH = 2;
const SEARCH_DELAY_MS = 1000;

const AirlineSelection = props => {
  const {
    onSearchAirlines,
    searchedAirlines,
    preferredAirlines,
    onAirlinesSelect,
    isEmptySearchQuery,
    onClearSearchQuery,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [airlineOptions, setAirlineOptions] = useState([]);
  const [selectedAirlines, setSelectedAirlines] = useState(preferredAirlines);

  const availableAirlines = () => {
    if (!searchedAirlines || !Object.keys(searchedAirlines).length) {
      return preferredAirlines;
    }

    return Object.values(searchedAirlines).map(({ name, code }) => ({
      label: `${name} (${code})`,
      value: code,
      isChecked: false,
    }));
  };

  const searchResult = useMemo(availableAirlines, [searchedAirlines]);

  const updateSelectedAirlines = newAirlines => {
    setSelectedAirlines(newAirlines);
    onAirlinesSelect(newAirlines);
  };

  const handleAddAirlinePreference = option => {
    const { label, value, isChecked } = option;
    const airline = {
      label,
      value,
      isChecked: !isChecked,
    };
    const newAirlines = [...selectedAirlines, airline];

    updateSelectedAirlines(newAirlines);
    setAirlineOptions(prevOptions => prevOptions.filter(prevOption => prevOption.value !== value));
  };

  const handleSelectedAirlineRemove = ({ value }) => {
    const filteredAirlines = selectedAirlines.filter(airline => airline.value !== value);
    updateSelectedAirlines(filteredAirlines);
  };

  const handleResetAll = () => {
    updateSelectedAirlines([]);
    setSearchQuery('');
  };

  const searchCarrier = useCallback(
    input => {
      if (input && input.length >= MIN_SEARCH_QUERY_LENGTH) {
        onClearSearchQuery(false);
        onSearchAirlines(input);
      }
    },
    [onSearchAirlines],
  );

  const numberOfSelectedAirlines = selectedAirlines.length;
  const numberOfAirlineOptions = airlineOptions.length;
  const isAirlineLengthExceeded = numberOfSelectedAirlines > MAX_AIRLINE_LENGTH;

  const uniqueAirlineCodes = new Set(selectedAirlines.map(option => option.value));

  useEffect(() => {
    if (numberOfSelectedAirlines === 0) {
      setAirlineOptions(searchResult);
    } else {
      const filteredOptions = searchResult.filter(option => !uniqueAirlineCodes.has(option.value));
      setAirlineOptions(filteredOptions);
    }
  }, [searchResult]);

  useEffect(() => {
    if (isAirlineLengthExceeded || numberOfAirlineOptions === 0) {
      setSearchQuery('');
    }
  }, [airlineOptions]);

  useEffect(() => {
    const timer = setTimeout(() => {
      searchCarrier(searchQuery);
    }, SEARCH_DELAY_MS);

    if (searchQuery === '') {
      setAirlineOptions([]);
    }

    return () => clearTimeout(timer);
  }, [searchQuery]);

  return (
    <Fragment>
      {numberOfSelectedAirlines > 0 && (
        <AirlineList
          airlines={selectedAirlines}
          onChangeAirline={handleSelectedAirlineRemove}
          disabled={false}
        />
      )}
      <AirlineSelectionDivider />

      <Input
        size="tiny"
        placeholder={I18n.t('components.ibe.search_form.flight.label.search_airline')}
        preIcon={<Icon name="search" />}
        onChange={value => {
          setSearchQuery(value);
        }}
        value={searchQuery}
        disabled={isAirlineLengthExceeded}
      />

      {numberOfAirlineOptions > 0 ? (
        <AirlineList
          airlines={airlineOptions}
          onChangeAirline={handleAddAirlinePreference}
          disabled={isAirlineLengthExceeded}
        />
      ) : (
        searchQuery.length > 0 && <EmptyAirlinesList loading={isEmptySearchQuery} />
      )}

      {numberOfSelectedAirlines > 0 && (
        <div className="airline-selection__bottom">
          <AirlineSelectionDivider last={true} />
          <div
            className="airline-selection__bottom__reset-all-button"
            onClick={handleResetAll}
            onKeyDown={handleResetAll}
            role="button"
            tabIndex={0}
          >
            {I18n.t('components.ibe.search_form.flight.label.reset_all')}
          </div>
        </div>
      )}
    </Fragment>
  );
};

AirlineSelection.propTypes = {
  onSearchAirlines: PropTypes.func.isRequired,
  onAirlinesSelect: PropTypes.func.isRequired,
  searchedAirlines: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
  ).isRequired,
  preferredAirlines: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isChecked: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  isEmptySearchQuery: PropTypes.bool.isRequired,
  onClearSearchQuery: PropTypes.func.isRequired,
};

AirlineSelectionDivider.propTypes = {
  last: PropTypes.bool,
};

AirlineSelectionDivider.defaultProps = {
  last: false,
};

EmptyAirlinesList.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  searchedAirlines: state.flights.airlinePreference.airlines,
  preferredAirlines: state.form.flightSearchForm.airlinePreferences.carriers.value,
  isEmptySearchQuery: state.flights.airlinePreference.isEmpty,
});

const mapDispatchToProps = dispatch => ({
  onSearchAirlines: query => dispatch(searchAirlinePreference(query)),
  onClearSearchQuery: cleared => dispatch(emptyPreferredAirlines(cleared)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AirlineSelection);
