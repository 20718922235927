import React, { useEffect, useState } from 'react';
import debounce from 'throttle-debounce/debounce';
import PropTypes from 'prop-types';

import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import Icon from '@wtag/rcl-icon';
import Input from '@wtag/rcl-input';

import SortingPill from 'sharedWebpack/SortingPill';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import routes from 'agentRoutes';
import Pagination from 'sharedWebpack/Pagination';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';

import Table from './Table';

const VouchersTab = ({ orderId }) => {
  const [vouchers, setVouchers] = useState([]);
  const [totalPages, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const COLUMN_FOR_PLACEHOLDER = 7;

  const [query, setQuery] = useQueryParams({
    q: withDefault(StringParam, ''),
    page: NumberParam,
    sortColumn: StringParam,
    sortDirection: StringParam,
    updatedOn: StringParam,
  });
  const { q, page, sortColumn, sortDirection, updatedOn } = query;
  const [localSearchQuery, setLocalSearchQuery] = React.useState(q);

  const setInitialParams = (data, searchQuery) => {
    // We only set the params onces when there is nothing in the url
    if (!query.page) {
      setQuery(
        {
          q: searchQuery,
          page: data.pagination.currentPage,
          sortColumn: data.sort.column,
          sortDirection: data.sort.direction,
        },
        // use replaceIn as we don't want another navigation to be added to the browser history
        'replaceIn',
      );
    }
  };

  const fetchVouchers = args => {
    setIsLoading(true);

    const { searchQuery, newPage, column, direction, updatedAt, apiToken } = args;

    httpClient
      .get(
        routes.public.orders.vouchers({
          orderId,
          q: searchQuery,
          page: newPage,
          column,
          direction,
          updated_at: updatedAt,
        }),
        {
          cancelToken: apiToken,
        },
      )
      .then(({ data }) => {
        setVouchers(data.vouchers);
        setTotalPage(data.pagination.totalPages);
        setInitialParams(data, searchQuery, updatedAt);
        setIsLoading(false);
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        throw error;
      });
  };

  const setSearchQueryInParams = React.useRef(
    debounce(500, value => setQuery({ q: value, page: 1 })),
  ).current;

  const setSearchQuery = value => {
    // We keep a local state here as we don't want to do an AJAX call for each key the user presses
    // This allows us to keep the UI responsive and fetch new data with a delay
    setLocalSearchQuery(value);
    setSearchQueryInParams(value);
  };

  const updateSorting = (column, direction) => {
    setQuery({ sortColumn: column, sortDirection: direction });
  };

  useEffect(() => {
    const getVouchersApiToken = generateSourceToken();

    fetchVouchers({
      searchQuery: q,
      newPage: page,
      column: sortColumn,
      direction: sortDirection,
      updatedAt: updatedOn,
      apiToken: getVouchersApiToken.token,
    });

    setQuery({tab: 'vouchers' }, 'replaceIn');

    return () => {
      getVouchersApiToken.cancel();
    };
  }, [q, page, sortColumn, sortDirection, updatedOn]);

  return (
    <div className="vouchers-list grid">
      <div className="col-grid direction-row align-center">
        <div className="col-6 col-bleed">
          <span className="vouchers-list__sort">
            <SortingPill
              size="small"
              name={I18n.t('admin.components.vouchers.sort.number')}
              thisSortColumn="id"
              currentSortColumn={sortColumn}
              currentSortDirection={sortDirection}
              onChange={updateSorting}
            />
          </span>
        </div>
        <div className="col-6 col-grid col-bleed direction-row justify-end">
          <span className="vouchers-list__search">
            <Input
              size="tiny"
              placeholder={I18n.t('admin.components.vouchers.search')}
              preIcon={<Icon name="search" />}
              value={localSearchQuery}
              onChange={setSearchQuery}
            />
          </span>
        </div>
      </div>
      <div className="vouchers-list__collection col-bleed-y col-12">
        <Table
          vouchers={vouchers}
          loading={isLoading}
          tableColumnForPlaceHolder={COLUMN_FOR_PLACEHOLDER}
        />
      </div>
      <div className="vouchers-list__pagination col-12 flex-footer">
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPaginationClick={newPage => setQuery({ page: newPage })}
        />
      </div>
    </div>
  );
};

VouchersTab.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default withQueryParamsProvider(VouchersTab);
