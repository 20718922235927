import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import getFilteredAirportSuggestions from '../helpers/getFilteredAirportSuggestions';
import {
  bookingClassOptionsByRule,
  cabinClassOptions,
  preferredAllianceOptions,
} from '../helpers/variousOptionsProvider';
import showInvalidDateError from '../../../helpers/showInvalidDateError';
import findLabelValue from '../../../helpers/findLabelValue';
import { MULTICITY, ROUNDTRIP } from '../../../lib/helpers/tripTypes';
import AdvancedFlightFormTrips from './AdvancedFlightFormTrips';
import usePropState from '../../../../usePropState';
import './styles.scss';

const TRIP_COUNT_TWO = 2;

const AdvancedFlightForm = ({
  trips,
  searchInputConfiguration,
  airportSuggestions,
  actionBars,
  dropdownBar,
  laymanMode,
  tripType,
  onTripRemove,
  isAdvancedForm,
}) => {
  const [firstTrip, secondTrip] = trips;
  const [customStartDate, setCustomStartDate] = usePropState(
    firstTrip ? firstTrip.date.value : null,
  );
  const [customEndDate, setCustomEndDate] = usePropState(secondTrip ? secondTrip.date.value : null);

  const isShowDaterangeError =
    !moment(customStartDate).isValid() || !moment(customEndDate).isValid();

  // For the roundtrip we will show roundtrip icon but for the singleTrip and multiCity we need to show oneWay icon as it will be separated for each trip.
  const tripIcon = tripType === ROUNDTRIP ? 'roundTrip' : 'oneWay';
  // This function will be called when the origin code is updated.
  const handleTripOriginChange = (currentTripIndex, value) => {
    trips[currentTripIndex].origin.onChange(value);

    const nextTrip = trips[currentTripIndex + 1];

    if (!nextTrip) {
      return;
    }

    if (trips.length === TRIP_COUNT_TWO && tripType !== MULTICITY) {
      nextTrip.destination.onChange(value);
    }
  };

  // This function will be called when the destination code is updated.
  const handleTripDestinationChange = (currentTripIndex, value) => {
    trips[currentTripIndex].destination.onChange(value);

    const nextTrip = trips[currentTripIndex + 1];

    if (!nextTrip) {
      return;
    }

    nextTrip.origin.onChange(value);
  };

  // When date of first trip changes it will update nextTrip date with difference of 7 days.
  const setNextTripDate = (currentIndex, value) => {
    trips[currentIndex].date.onChange(value);
    const index = currentIndex + 1;
    if (!trips[index] || trips[index].date.touched) {
      return;
    }

    const nextValue = moment
      .utc(value)
      .add(7, 'days')
      .toISOString();
    trips[index].date.onChange(nextValue);
  };

  // After searching alliances this function will be called.
  const allianceOptionFinder = value =>
    preferredAllianceOptions().find(option => option.value === value);

  const tripDate = index => {
    if (trips[index]) {
      return trips[index].date.value;
    }

    return undefined;
  };

  const lastTripDate = currentIndex => {
    const index = currentIndex - 1;

    return tripDate(index);
  };

  // This functions handles the case where we can not add a date before pervious trip.
  const minDate = currentIndex => {
    if (currentIndex === 0) {
      return moment
        .utc(new Date())
        .add(-1, 'days')
        .format();
    }

    const previousIndex = currentIndex - 1;

    if (trips[previousIndex] && !trips[previousIndex].date.value) {
      return minDate(previousIndex);
    }
    return lastTripDate(currentIndex);
  };

  const showingTrips =
    tripType === ROUNDTRIP && firstTrip ? (
      <AdvancedFlightFormTrips
        tripType={tripType}
        onTripRemove={onTripRemove}
        tripIcon={tripIcon}
        trip={firstTrip}
        searchInputConfiguration={searchInputConfiguration}
        getFilteredAirportSuggestions={getFilteredAirportSuggestions}
        airportSuggestions={airportSuggestions}
        setNextTripDate={setNextTripDate}
        minDate={minDate}
        showInvalidDateError={showInvalidDateError}
        cabinClassOptions={cabinClassOptions}
        findLabelValue={findLabelValue}
        preferredAllianceOptions={preferredAllianceOptions}
        allianceOptionFinder={allianceOptionFinder}
        bookingClassOptionsByRule={bookingClassOptionsByRule}
        handleTripDestinationChange={handleTripDestinationChange}
        setCustomStartDate={setCustomStartDate}
        setCustomEndDate={setCustomEndDate}
        index={0}
        handleTripOriginChange={handleTripOriginChange}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        firstTrip={firstTrip}
        secondTrip={secondTrip}
        isAdvancedForm={isAdvancedForm}
        laymanMode={laymanMode}
        isShowDaterangeError={isShowDaterangeError}
      />
    ) : (
      trips.map((trip, index) => (
        <AdvancedFlightFormTrips
          tripType={tripType}
          onTripRemove={onTripRemove}
          tripIcon={tripIcon}
          trip={trip}
          searchInputConfiguration={searchInputConfiguration}
          getFilteredAirportSuggestions={getFilteredAirportSuggestions}
          airportSuggestions={airportSuggestions}
          setNextTripDate={setNextTripDate}
          minDate={minDate}
          showInvalidDateError={showInvalidDateError}
          cabinClassOptions={cabinClassOptions}
          findLabelValue={findLabelValue}
          preferredAllianceOptions={preferredAllianceOptions}
          allianceOptionFinder={allianceOptionFinder}
          bookingClassOptionsByRule={bookingClassOptionsByRule}
          handleTripDestinationChange={handleTripDestinationChange}
          setCustomStartDate={setCustomStartDate}
          setCustomEndDate={setCustomEndDate}
          index={index}
          handleTripOriginChange={handleTripOriginChange}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          isAdvancedForm={isAdvancedForm}
          laymanMode={laymanMode}
        />
      ))
    );

  return (
    <Fragment>
      <div className="flight-form__holder-advanced--show">
        {dropdownBar}
        {showingTrips}
        {actionBars}
      </div>
    </Fragment>
  );
};

AdvancedFlightForm.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      origin: PropTypes.shape({
        onChange: PropTypes.func,
      }),
      destination: PropTypes.shape({
        onChange: PropTypes.func,
      }),
      date: PropTypes.shape({
        onChange: PropTypes.func,
        touched: PropTypes.bool,
        value: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
  searchInputConfiguration: PropTypes.shape({
    whitelistedAirports: PropTypes.arrayOf().isRequired,
    showAlliancePreferences: PropTypes.bool,
    showBookingClass: PropTypes.bool,
  }).isRequired,
  airportSuggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actionBars: PropTypes.node.isRequired,
  dropdownBar: PropTypes.node.isRequired,
  tripType: PropTypes.string.isRequired,
  onTripRemove: PropTypes.func.isRequired,
  isAdvancedForm: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool.isRequired,
};

export default AdvancedFlightForm;
