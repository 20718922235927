import React, { useEffect, useState, Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Link, SidePanel, Radio } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import Tooltip from '@wtag/rcl-tooltip';
import snakeCaseKeys from 'snakecase-keys';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import {
  addToCollection,
  updateCollection,
  removeFromCollection,
  itemCounter,
} from 'sharedWebpack/MultipleForm/multipleForm';
import PreselectTravelersForm from './PreselectTravelersForm';
import { TYPE_CAR } from '../../../helpers/itemTypes';
import { DECISION_GUEST, DECISION_SELF } from '../../../helpers/decisionTypes';
import usePreselectTraveler from '../../helpers/hooks/usePreselectTraveler';
import './styles.scss';

const PreselectTravelersSidePanel = ({
  laymanMode,
  travelerLoggedIn,
  adults,
  children,
  infants,
  callbackParams,
  updatePreselectTravelers,
  preselectTravelers,
  type,
  preselectDecision,
  updatePreselectDecision,
  resetBookingAppContext,
  updatePreselectedTravelersBookingAppContext,
  setIsPreselectTravellerVisible,
  onAdultChange,
  onChildrenChange,
  onInfantChange,
  selectedTotalNumberOfTravelers,
}) => {
  const {
    currentTraveler,
    updatePreselectTravelersStore,
    getFilteredTravelers,
    getInitialTravelers,
  } = usePreselectTraveler({
    laymanMode,
    travelerLoggedIn,
    preselectDecision,
    preselectTravelers,
    callbackParams,
    updatePreselectTravelers,
    resetBookingAppContext,
    adults,
    children,
    infants,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [travelers, setTravelers] = useState([{}]);
  const MAX_TRAVELER_COUNT = 9;
  const isAddMoreTravelerDisabled = travelers.length >= MAX_TRAVELER_COUNT || type === TYPE_CAR;
  const totalAdults = travelers.filter(
    traveler => traveler.type && traveler.type.value === 'adult',
  );
  const totalChildren = travelers.filter(
    traveler => traveler.type && traveler.type.value === 'child',
  );
  const totalInfants = travelers.filter(
    traveler => traveler.type && traveler.type.value === 'infant',
  );
  const totalNumberOfTravelerOnSidePanel =
    totalAdults.length + totalChildren.length + totalInfants.length;

  const isAdultOptionDisabled = totalAdults.length === adults;
  const isDecisionSelf = preselectDecision === DECISION_SELF;
  const isDecisionGuest = preselectDecision === DECISION_GUEST;
  const preselectTravelersLength = preselectTravelers.length;
  const isDisabled = laymanMode && !travelerLoggedIn;

  const FIRST_TRAVELER_INDEX = 0;

  const showPreselectTraveller = () => {
    setIsVisible(true);
    setIsPreselectTravellerVisible(true);
  };

  const preselectButton = (
    <div className="flight-form__preselect-traveler-button">
      <Link onClick={showPreselectTraveller} disabled={isDisabled}>
        {preselectTravelersLength > 0
          ? I18n.t('components.ibe.preselect_traveler.label.button', {
              count: preselectTravelersLength,
            })
          : I18n.t('components.ibe.preselect_traveler.title')}
      </Link>
    </div>
  );

  const updateBookingAppContextOnPreselectTravlers = async filteredTravelers => {
    const owner = filteredTravelers[0].traveler.value;
    const ownerId = owner.id;
    const { data } = await httpClient.get(
      routes.admin.preselectTravelersProperties({ id: ownerId }),
    );
    const preselectTravelerIds = filteredTravelers.map(
      filteredTraveler => filteredTraveler.traveler.value.id,
    );
    const preselectedTravelersBookingAppContext = {
      preselectTravelerIds,
      ownerId,
      isOwnerPresent: !!owner,
      ownerAge: owner.age,
      allowBookingOfUnapprovedItems: data.allowBookingOfUnapprovedItems,
      citizenshipCountryCode: data.citizenshipCountryCode,
      globalPolicyPreferences: data.globalPolicyPreferences,
      searchAccounts: snakeCaseKeys(data.searchAccounts, { deep: true }),
    };

    updatePreselectedTravelersBookingAppContext(preselectedTravelersBookingAppContext);
  };

  const updatePreselectTravelersStoreWithContext = () => {
    const travelersWithType = updatePreselectTravelersStore(travelers);

    if (travelersWithType.length > 0 && !laymanMode) {
      updateBookingAppContextOnPreselectTravlers(travelersWithType);
    } else {
      resetBookingAppContext();
    }
  };

  const onSidePanelClose = () => {
    updatePreselectTravelersStoreWithContext();
    setIsVisible(false);
    setIsPreselectTravellerVisible(false);
    if (selectedTotalNumberOfTravelers <= totalNumberOfTravelerOnSidePanel) {
      onAdultChange(Math.max(1, totalAdults.length));
      onChildrenChange(totalChildren.length);
      onInfantChange(totalInfants.length);
    }
  };

  const restorePreviouslySelected = () => {
    if (preselectTravelersLength > 0) {
      setTravelers(preselectTravelers);
    } else {
      setTravelers([{}]);
    }
  };

  const initialTravelers = [];

  const travelerTypes = [
    { label: I18n.t('components.ibe.preselect_traveler.label.type.adult'), value: 'adult' },
    { label: I18n.t('components.ibe.preselect_traveler.label.type.child'), value: 'child' },
    { label: I18n.t('components.ibe.preselect_traveler.label.type.infant'), value: 'infant' },
  ];

  const filterPreselectTravelers = () => {
    const filteredTravelers = getFilteredTravelers();

    if (filteredTravelers.length > 0) {
      setTravelers(filteredTravelers);
      updatePreselectTravelers(filteredTravelers);
    } else {
      setTravelers(initialTravelers);
      updatePreselectTravelers([]);
    }
  };

  useEffect(() => {
    setTravelers(getInitialTravelers(isAdultOptionDisabled));
  }, [preselectDecision, currentTraveler]);

  useEffect(() => {
    if (preselectTravelersLength === 0 && currentTraveler && currentTraveler.id) {
      updatePreselectTravelersStoreWithContext();
    }
  }, [travelers]);

  useEffect(() => {
    restorePreviouslySelected();
  }, [type]);

  useEffect(() => {
    filterPreselectTravelers();
    [adults, children, infants].forEach((count, index) => {
      for (let travelerIndex = 0; travelerIndex < count; travelerIndex += 1) {
        initialTravelers.push({
          type: travelerTypes[index],
        });
      }
    });
  }, [adults, children, infants]);

  return (
    <Fragment>
      {isDisabled ? (
        <Tooltip
          classNameForWrapper="flight-form__preselect-traveler-tooltip-wrapper"
          content={I18n.t('components.ibe.preselect_traveler.label.warning_text')}
          type="inverse"
        >
          {preselectButton}
        </Tooltip>
      ) : (
        preselectButton
      )}
      {// TODO: Have to change this createPortal block while adding https://welltravel.atlassian.net/browse/AG-7031
      createPortal(
        <SidePanel
          title={
            <Fragment>
              <div className="preselect-travelers-side-panel__title">
                {I18n.t('components.ibe.preselect_traveler.header')}
              </div>
              <div className="preselect-travelers-side-panel__count">
                {I18n.t('components.ibe.preselect_traveler.label.travelers', {
                  count: travelers.length,
                })}
              </div>
            </Fragment>
          }
          body={
            <div className="preselect-travelers-side-panel__content">
              {laymanMode && (
                <Fragment>
                  <Radio
                    size="large"
                    name={DECISION_GUEST}
                    onChange={event =>
                      event.target.checked
                        ? updatePreselectDecision(DECISION_GUEST)
                        : updatePreselectDecision(DECISION_SELF)
                    }
                    checked={isDecisionGuest}
                    label={I18n.t('components.ibe.preselect_traveler.label.book_for.guest')}
                  />
                  <div className="preselect-travelers-side-panel__decision">
                    <Radio
                      size="large"
                      name={DECISION_SELF}
                      onChange={event =>
                        event.target.checked
                          ? updatePreselectDecision(DECISION_SELF)
                          : updatePreselectDecision(DECISION_GUEST)
                      }
                      checked={isDecisionSelf}
                      label={I18n.t('components.ibe.preselect_traveler.label.book_for.self')}
                    />
                  </div>
                </Fragment>
              )}
              {travelers.map((item, index) => (
                <PreselectTravelersForm
                  onDeleteClick={() => removeFromCollection(setTravelers, index)}
                  traveler={item.traveler}
                  onChangeTraveler={value =>
                    updateCollection(setTravelers, index, 'traveler', value)
                  }
                  onTypeChange={value => updateCollection(setTravelers, index, 'type', value)}
                  type={item.type}
                  count={itemCounter(index)}
                  isDeleteDisabled={index === FIRST_TRAVELER_INDEX}
                  callbackParams={callbackParams}
                  formType={type}
                />
              ))}
              <div>
                <Link
                  onClick={() => addToCollection(setTravelers)}
                  modifier="default"
                  size="tiny"
                  disabled={isAddMoreTravelerDisabled}
                >
                  {I18n.t('components.ibe.preselect_traveler.label.add_more')}
                </Link>
              </div>
              <div className="preselect-travelers-side-panel__add-more">
                <Button
                  version="v2"
                  label={I18n.t('components.ibe.preselect_traveler.label.confirm')}
                  size="small"
                  type="primary"
                  onClick={onSidePanelClose}
                />
              </div>
            </div>
          }
          isOpened={isVisible}
          onClick={onSidePanelClose}
        />,
        document.body,
      )}
    </Fragment>
  );
};

PreselectTravelersSidePanel.defaultProps = {
  adults: 1,
  children: 0,
  infants: 0,
  updatePreselectedTravelersBookingAppContext: () => {},
  resetBookingAppContext: () => {},
  setIsPreselectTravellerVisible: () => {},
  onAdultChange: () => {},
  onChildrenChange: () => {},
  onInfantChange: () => {},
  selectedTotalNumberOfTravelers: 1,
};

PreselectTravelersSidePanel.propTypes = {
  laymanMode: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  adults: PropTypes.number,
  children: PropTypes.number,
  infants: PropTypes.number,
  callbackParams: PropTypes.shape({
    laymanMode: PropTypes.bool,
    order_id: PropTypes.string,
  }).isRequired,
  updatePreselectTravelers: PropTypes.func.isRequired,
  preselectTravelers: PropTypes.arrayOf(
    PropTypes.shape({
      traveler: PropTypes.shape({
        value: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          middleName: PropTypes.string,
          lastName: PropTypes.string.isRequired,
          phoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
          emailAddresses: PropTypes.arrayOf(PropTypes.string).isRequired,
          age: PropTypes.number.isRequired,
        }),
      }),
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  updatePreselectDecision: PropTypes.func.isRequired,
  preselectDecision: PropTypes.string.isRequired,
  updatePreselectedTravelersBookingAppContext: PropTypes.func,
  resetBookingAppContext: PropTypes.func,
  setIsPreselectTravellerVisible: PropTypes.func,
  onAdultChange: PropTypes.func,
  onChildrenChange: PropTypes.func,
  onInfantChange: PropTypes.func,
  selectedTotalNumberOfTravelers: PropTypes.number,
};

export default PreselectTravelersSidePanel;
