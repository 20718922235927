import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import { I18nText } from '@wtag/react-comp-lib';
import TravelerLoginModal from 'sharedWebpack/TravelerLoginModal/index';

import TravelersSearchAutoComplete from '../../TravelersSearchAutoComplete';
import TravelerInformationLoader from '../../TravelerInformationLoader';

const SearchTravelerForm = props => {
  const {
    travelerIndex,
    customerSelected,
    reset,
    hasRightToSearch,
    setNewTravelerFormPage,
    callbackParams,
    isTravelerInformationLoading,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  if (isTravelerInformationLoading) {
    return <TravelerInformationLoader />;
  }

  return (
    <Fragment>
      <TravelerLoginModal modalOpen={isModalVisible} setModalOpen={setIsModalVisible} />
      {hasRightToSearch ? (
        <TravelersSearchAutoComplete
          index={travelerIndex}
          onChange={value => {
            reset(travelerIndex);
            customerSelected(travelerIndex, value);
          }}
          setNewTravelerFormPage={setNewTravelerFormPage}
          disabled={false}
          callbackParams={callbackParams}
        />
      ) : (
        <div className="col-12 direction-col traveler-search-form traveler-search-form__add-new-traveler-placeholder">
          <Icon name="add" size="large" showBGColor={true} />
          <div className="traveler-search-form__add-new-traveler-placeholder-button">
            <Button
              version="v2"
              onClick={() => setIsModalVisible(prev => !prev)}
              size="small"
              label={I18n.t('components.ibe.traveler_details.buttons.login_to_existing_account')}
            />
            <div className="traveler-search-form__add-new-traveler-placeholder-divider">
              <I18nText id="match_traveler.or" returnStringOnly={true} />
            </div>
            <Button
              version="v2"
              onClick={() => setNewTravelerFormPage('traveler_information')}
              size="small"
              label={I18n.t('components.ibe.traveler_details.buttons.add_new_traveler')}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

SearchTravelerForm.propTypes = {
  travelerIndex: PropTypes.number.isRequired,
  setNewTravelerFormPage: PropTypes.func.isRequired,
  hasRightToSearch: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  customerSelected: PropTypes.func.isRequired,
  callbackParams: PropTypes.shape({}).isRequired,
  isTravelerInformationLoading: PropTypes.bool.isRequired,
};

export default SearchTravelerForm;
