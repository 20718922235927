import { CART_CREATED, CART_FETCHED } from '../../actions/common';

export default function cartId(state = null, action) {
  switch (action.type) {
    case CART_CREATED:
      return action.cart.id;

    case CART_FETCHED:
      return action.cart.id;

    default:
      return state;
  }
}
