import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MatrixAccordionItem from './MatrixAccordionItem';
import './matrix-accordion.styles.scss';

const MatrixAccordion = props => {
  const {
    trips,
    activeTripId,
    activeTabKey,
    setActiveStepIndex,
    fetchAirportDetails,
    airports,
    airlineInformations,
    fetchAirlineInformations,
    windowWidth,
  } = props;

  const windowSizeForMobileView = 991;

  return (
    <ul className="matrix-accordion">
      {trips.map((trip, index) => (
        <Fragment key={trip.id}>
          <MatrixAccordionItem
            trip={trip}
            windowWidth={windowWidth}
            isOpened={
              (activeTabKey !== 'all' && trip.id === activeTripId) || activeTabKey === 'all'
            }
            setActiveStepIndex={activeTabKey === 'all' ? () => {} : setActiveStepIndex}
            fetchAirportDetails={fetchAirportDetails}
            airports={airports}
            airlineInformations={airlineInformations}
            fetchAirlineInformations={fetchAirlineInformations}
          />
          {windowWidth > windowSizeForMobileView && index < trips.length - 1 ? (
            <div className="matrix-accordion__divider" />
          ) : null}
        </Fragment>
      ))}
    </ul>
  );
};

MatrixAccordion.propTypes = {
  trips: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeTripId: PropTypes.number.isRequired,
  activeTabKey: PropTypes.string.isRequired,
  setActiveStepIndex: PropTypes.func.isRequired,
  fetchAirportDetails: PropTypes.func.isRequired,
  airports: PropTypes.shape({}).isRequired,
  airlineInformations: PropTypes.shape({}).isRequired,
  fetchAirlineInformations: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default MatrixAccordion;
