import React from 'react';
import PropTypes from 'prop-types';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import { StringParam, useQueryParams } from 'use-query-params';
import { Header } from '@wtag/rcl-typography';
import { PillTabs } from '@wtag/react-comp-lib';
import OrdersList from 'sharedWebpack/Orders/List';
import '../styles.scss';

const CorporateTravel = ({ ordersPath }) => {
  const commonProps = {
    hideArchived: true,
    hideCreateOrder: true,
    personId: null,
    ordersPath,
    displayColumns: {
      id: true,
      title: true,
      destination: true,
      travellers: true,
      products: true,
      state: true,
      total: true,
      bookingDate: true,
      startDate: true,
      actions: true,
    },
    displaySortingPills: {
      id: true,
      destination: false,
      lastUpdated: true,
      bookingDate: true,
      startDate: true,
    },
    layman: true,
  };

  const [query, setQuery] = useQueryParams({
    scope: StringParam,
    page: StringParam,
    sortColumn: StringParam,
    sortDirection: StringParam,
  });
  const { scope } = query;

  const TAB_MAPPER = {
    all_orders: 0,
    upcoming_orders: 1,
    present_orders: 2,
    past_orders: 3,
  };

  const getItems = () => [
    {
      key: 0,
      title: I18n.t('public.components.corporate_trips.tabs.all'),
      getContent: () => <OrdersList key="all" {...commonProps} />,
    },
    {
      key: 1,
      title: I18n.t('public.components.orders.upcoming'),
      getContent: () => (
        <OrdersList
          {...commonProps}
          additionalQueryParams={{ scope: 'upcoming_orders' }}
          key="upcoming"
        />
      ),
    },
    {
      key: 2,
      title: I18n.t('public.components.corporate_trips.tabs.present'),
      getContent: () => (
        <OrdersList
          {...commonProps}
          additionalQueryParams={{ scope: 'present_orders' }}
          key="present_orders"
        />
      ),
    },
    {
      key: 3,
      title: I18n.t('public.components.orders.previous'),
      getContent: () => (
        <OrdersList
          {...commonProps}
          additionalQueryParams={{ scope: 'past_orders' }}
          key="past_orders"
        />
      ),
    },
  ];

  // TODO: Filters on corporate trips page will be done on AG-5630

  return (
    <div className="corporate-trips col-grid">
      <div className="corporate-trips__header">
        <Header weight="medium" level={5}>
          {I18n.t('public.application.account_navigation.corporate_travel')}
        </Header>
      </div>
      <div className="corporate-trips__pill-tabs">
        <PillTabs
          items={getItems()}
          selectedTabKey={TAB_MAPPER[scope] || 0}
          showMore={false}
          onChange={() => setQuery({ ...query, page: 1, sortColumn: 'id', sortDirection: 'down' })}
        />
      </div>
    </div>
  );
};

CorporateTravel.propTypes = {
  ordersPath: PropTypes.string.isRequired,
};

export default withQueryParamsProvider(CorporateTravel);
