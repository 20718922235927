import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import classnames from 'classnames';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import Alert from 'sharedWebpack/Alert';
import DateTime from 'sharedWebpack/DateTime';
import Tooltip from '@wtag/rcl-tooltip';
import Icon from '@wtag/rcl-icon';
import VisaAdvisory from '../../VisaAdvisories/VisaAdvisoriesContainer';
import documentTypesBuilder from '../../../Search/flight/helpers/documentTypesBuilder';
import { customerSelected } from '../../../actions/flight';

class IdentificationDocumentInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryData: {},
      nationalityData: {},
    };

    const { fields } = this.props;

    if (fields.country.value !== '') {
      this.searchAndSetCountry(fields.country.value);
    }
    if (fields.nationality.value !== '') {
      this.searchAndSetNationality(fields.nationality.value);
    }
  }

  componentDidMount() {
    const { fields, selectedCustomer, travelerIndex, onCustomerSelected } = this.props;

    if (
      fields &&
      selectedCustomer &&
      selectedCustomer.document &&
      fields.id.value !== selectedCustomer.document.id
    ) {
      const { id, type, number, country, nationality, validity } = fields;

      const updatedSelectedCustomer = {
        ...selectedCustomer,

        document: {
          id: id.value,
          type: type.value,
          number: number.value,
          country: country.value,
          nationality: nationality.value,
          validity: validity.value,
        },
      };
      onCustomerSelected(travelerIndex, updatedSelectedCustomer);
    }
  }

  countrySearchUrl = code => routes.api.country({ code });

  searchAndSetCountry = code =>
    httpClient
      .get(this.countrySearchUrl(code))
      .then(({ data }) => this.setState({ countryData: data }));

  searchAndSetNationality = code =>
    httpClient
      .get(this.countrySearchUrl(code))
      .then(({ data }) => this.setState({ nationalityData: data }));

  showDocumentInformation = (countryData, number) => {
    if (countryData.name) {
      return (
        <div className="col-grid col-bleed direction-row align-center identification-document-fields__attributes">
          <div className="identification-document-fields__attributes-flag">
            <img src={countryData.imageUrls.small} alt={countryData.name} />
          </div>
          <div className="identification-document-fields__attributes-text">
            <div className="identification-document-fields__attributes-text-country">
              {countryData && countryData.name}
            </div>
            {number && number.value ? (
              <div className="identification-document-fields__attributes-text-number">
                {number.value}
              </div>
            ) : (
              <div
                className={classnames('identification-document-fields__attributes-value', {
                  'identification-document-fields__attributes-value--invalid': number.invalid,
                })}
              >
                {I18n.t('components.ibe.traveler_details.attributes.not_provided', {
                  attribute: I18n.t('components.ibe.traveler_details.attributes.number'),
                })}
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  showDocumentType = (attributeName, type) => {
    const DOCUMENT_TYPES = documentTypesBuilder();
    const label = type.value && DOCUMENT_TYPES.find(element => element.value === type.value).label;
    if (!label) {
      return (
        <div
          className={classnames('identification-document-fields__attributes-value', {
            'identification-document-fields__attributes-value--invalid': type.invalid,
          })}
        >
          {I18n.t('components.ibe.traveler_details.attributes.not_provided', {
            attribute: attributeName,
          })}
        </div>
      );
    } else if (type.invalid) {
      return (
        <div className="col-grid col-bleed direction-row align-center">
          <div className="identification-document-fields__attributes-invalid">{label}</div>
          <div className="identification-document-fields__attributes-tooltip">
            <Tooltip content={type.error} type="danger" position="bottom-left">
              <Icon name="invalid" color="danger" />
            </Tooltip>
          </div>
        </div>
      );
    }

    return <div className="identification-document-fields__attributes-value">{label}</div>;
  };

  showNationality = (attributeName, nationality) => {
    if (!nationality.value) {
      return (
        <div
          className={classnames('identification-document-fields__attributes-value', {
            'identification-document-fields__attributes-value--invalid': nationality.invalid,
          })}
        >
          {I18n.t('components.ibe.traveler_details.attributes.not_provided', {
            attribute: attributeName,
          })}
        </div>
      );
    } else if (nationality.invalid) {
      return (
        <div className="col-grid col-bleed direction-row align-center">
          <div className="identification-document-fields__attributes-invalid">
            {this.state.nationalityData && this.state.nationalityData.name}
          </div>
          <div className="identification-document-fields__attributes-tooltip">
            <Tooltip content={nationality.error} type="danger" position="bottom-left">
              <Icon name="invalid" color="danger" />
            </Tooltip>
          </div>
        </div>
      );
    }

    return (
      <div className="identification-document-fields__attributes-value">
        {this.state.nationalityData && this.state.nationalityData.nationality}
      </div>
    );
  };

  showValidity = (attributeName, validity) => {
    if (!validity.value) {
      return (
        <div
          className={classnames('identification-document-fields__attributes-value', {
            'identification-document-fields__attributes-value--invalid': validity.invalid,
          })}
        >
          {I18n.t('components.ibe.traveler_details.attributes.not_provided', {
            attribute: attributeName,
          })}
        </div>
      );
    } else if (validity.invalid) {
      return (
        <div className="col-grid col-bleed direction-row align-center">
          <div className="identification-document-fields__attributes-invalid">
            <DateTime dateTime={validity.value} format="short" />
          </div>
          <div className="identification-document-fields__attributes-tooltip">
            <Tooltip content={validity.error} type="danger" position="bottom-left">
              <Icon name="invalid" color="danger" />
            </Tooltip>
          </div>
        </div>
      );
    }

    return (
      <div className="identification-document-fields__attributes-value">
        <DateTime dateTime={validity.value} format="short" />
      </div>
    );
  };

  render() {
    const {
      fields: { number, country, type, nationality, validity },
    } = this.props;

    const { countryData } = this.state;

    return (
      <div className="identification-document-fields">
        <div className="identification-document-fields__header">
          {I18n.t('components.ibe.traveler_details.identification_document')}
        </div>
        {country.value ? (
          this.showDocumentInformation(countryData, number)
        ) : (
          <div
            className={classnames('identification-document-fields__attributes', {
              'identification-document-fields__attributes-invalid': country.invalid,
            })}
          >
            {I18n.t('components.ibe.traveler_details.attributes.invalid_document')}
          </div>
        )}

        <div className="identification-document-fields__attributes">
          <div className="identification-document-fields__attributes-header">
            {I18n.t('components.ibe.traveler_details.attributes.document_type')}
          </div>
          {this.showDocumentType(
            I18n.t('components.ibe.traveler_details.attributes.document_type'),
            type,
          )}
        </div>

        <div className="identification-document-fields__attributes">
          <div className="identification-document-fields__attributes-header">
            {I18n.t('components.ibe.traveler_details.attributes.nationality')}
          </div>
          {this.showNationality(
            I18n.t('components.ibe.traveler_details.attributes.nationality'),
            nationality,
          )}
        </div>

        <div className="identification-document-fields__attributes">
          <div className="identification-document-fields__attributes-header">
            {I18n.t('components.ibe.traveler_details.attributes.validity')}
          </div>
          {this.showValidity(
            I18n.t('components.ibe.traveler_details.attributes.validity'),
            validity,
          )}
        </div>

        {this.context.enableVisaAdvisory && (type.value !== 'passport' || !country.value) && (
          <div className="col-12 col-bleed visa-advisory">
            <Alert isVisible={true} type="warning" hideClose={true}>
              <I18nText id="ibe.visa_advisory.fill_in_info" />
              <span className="visa-advisory__legal">
                <Tooltip
                  content={I18n.t('components.ibe.visa_advisory.legal_text')}
                  type="important"
                  position="top-left"
                >
                  <Icon name="invalidOutline" color="default" />
                </Tooltip>
              </span>
            </Alert>
          </div>
        )}
        {this.context.enableVisaAdvisory && type.value === 'passport' && country.value && (
          <VisaAdvisory citizenshipCountryCode={country.value} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCustomerSelected: (index, query) => dispatch(customerSelected(index, query)),
});

IdentificationDocumentInformation.propTypes = {
  fields: PropTypes.shape().isRequired,
  selectedCustomer: PropTypes.shape({
    document: PropTypes.shape({
      country: PropTypes.string,
      id: PropTypes.number,
      nationality: PropTypes.string,
      number: PropTypes.number,
      type: PropTypes.string,
      validity: PropTypes.string,
    }),
  }).isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
  travelerIndex: PropTypes.number.isRequired,
};

IdentificationDocumentInformation.contextTypes = {
  enableVisaAdvisory: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(injectIntl(IdentificationDocumentInformation));
