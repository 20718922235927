import React, { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import Alert from 'sharedWebpack/Alert';
import Button from '@wtag/rcl-button';
import { Tabs as RTabs } from '@wtag/rcl-rtabs';
import { Header } from '@wtag/rcl-typography';
import Input from '@wtag/rcl-input';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import { StringParam, useQueryParams } from 'use-query-params';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { hot } from 'react-hot-loader';
import StateEmphasisTag from 'sharedWebpack/Orders/StateEmphasisTag';
import TravelExpensesTab from 'sharedWebpack/TravelExpensesTab';
import LeftPanel from '../../shared/OrdersLeftPanel';
import OverViewTab from './../../shared/Orders/OverviewTab';
import ItemsTab from './ItemsTab';
import InvoicesTab from '../../../admin/Invoices/InvoicesTab';
import VouchersTab from './VouchersTab';
import AccountingTab from '../../../admin/Orders/Show/AccountingTab';
import DocumentsTab from './DocumentsTab/List';
import SaveAndSendPDF from '../../../admin/Orders/CustomizePDF/SaveAndSendPDF';
import ApprovalRequestBar from '../../../shared/ApprovalRequests/ApprovalRequestBar';

import '../styles.scss';

const Show = ({ orderId, urls, currentPersonId }) => {
  const [order, setOrder] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [title, setTitle] = useState('');
  const [error, setError] = useState({});
  const [editingTitle, setEditingTitle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [arranger, setArranger] = useState({});
  const [confirmationAttributes, setConfirmationAttributes] = useState({
    header: null,
    subHeader: null,
  });
  const [query] = useQueryParams({
    tab: StringParam,
  });
  const { tab } = query;
  const permissions = order.permissions || {};
  const filteredRTabs = useCallback(() => {
    let TAB_MAPPER = {
      overview: 0,
      items: 1,
      invoices: 2,
      travel_expenses: 3,
      vouchers: 4,
      accounting: 5,
      documents: 6,
    };

    let tabs = [
      {
        key: 0,
        title: I18n.t('admin.components.orders.tabs.overview'),
        getContent: () => <OverViewTab order={order} isOrderLoading={isLoading} layman={true} />,
      },
      {
        key: 1,
        title: I18n.t('public.orders.show.travel_items'),
        getContent: () => <ItemsTab orderId={orderId} />,
      },
      {
        key: 2,
        title: I18n.t('public.orders.show.invoices.title'),
        getContent: () => (
          <InvoicesTab
            relatedModelId={orderId}
            route={routes.public.orderInvoices}
            displayColumns={{
              id: true,
              orderId: true,
              travellers: false,
              invoiceDate: true,
              dueDate: true,
              amount: true,
              state: true,
              actions: false,
            }}
          />
        ),
      },
      {
        key: 3,
        title: I18n.t('admin.components.orders.tabs.travel_expenses'),
        getContent: () => <TravelExpensesTab travelExpensesPath={urls.orderTravelExpensesPath} />,
      },
      {
        key: 4,
        title: I18n.t('admin.vouchers.index.title'),
        getContent: () => <VouchersTab orderId={orderId} />,
      },
      {
        key: 5,
        title: I18n.t('admin.components.orders.tabs.accounting'),
        getContent: () => <AccountingTab orderId={orderId} scope="public" />,
      },
      {
        key: 6,
        title: I18n.t('admin.components.orders.tabs.documents'),
        getContent: () => (
          <DocumentsTab createDocumentUrl={urls.newAttachement} orderId={orderId} />
        ),
      },
    ];

    if (order && !order.ownerOrganization) {
      tabs = [
        {
          key: 0,
          title: I18n.t('admin.components.orders.tabs.overview'),
          getContent: () => <OverViewTab order={order} isOrderLoading={isLoading} layman={true} />,
        },
        {
          key: 1,
          title: I18n.t('public.orders.show.travel_items'),
          getContent: () => <ItemsTab orderId={orderId} />,
        },
        {
          key: 2,
          title: I18n.t('public.orders.show.invoices.title'),
          getContent: () => (
            <InvoicesTab
              relatedModelId={orderId}
              route={routes.public.orderInvoices}
              displayColumns={{
                id: true,
                orderId: true,
                travellers: false,
                invoiceDate: true,
                dueDate: true,
                amount: true,
                state: true,
                actions: false,
              }}
            />
          ),
        },
        {
          key: 3,
          title: I18n.t('admin.vouchers.index.title'),
          getContent: () => <VouchersTab orderId={orderId} />,
        },
        {
          key: 4,
          title: I18n.t('admin.components.orders.tabs.documents'),
          getContent: () => (
            <DocumentsTab createDocumentUrl={urls.newAttachement} orderId={orderId} />
          ),
        },
      ];
      TAB_MAPPER = {
        overview: 0,
        items: 1,
        invoices: 2,
        vouchers: 3,
        documents: 4,
      };
    }

    return { tabs, TAB_MAPPER };
  }, [order, orderId, urls]);

  const { tabs, TAB_MAPPER } = filteredRTabs();

  const fetchOrder = async () => {
    const { data } = await httpClient.get(routes.public.orders.show({ id: orderId }));

    setOrder(data.order);
    setTitle(data.order.title);
    setIsLoading(false);
    const travelArranger = data.order.arrangedBy;
    travelArranger.email = data.order.arrangerContact.email;
    travelArranger.phone = data.order.arrangerContact.phone;
    setArranger(travelArranger);
  };

  const showConfirmationsOrError = (data, errorSubheader = '') => {
    if (data.error === null) {
      setConfirmationAttributes({
        header: data.message,
        subHeader: '',
        type: 'success',
      });
    } else {
      setConfirmationAttributes({
        header: data.error.message,
        subHeader: errorSubheader,
        type: 'danger',
      });
    }

    setShowConfirmation(true);
  };

  const bookNow = async () => {
    const { data } = await httpClient.put(urls.bookNow);

    if (data.error === null) {
      window.location.assign(data.redirectUrl);
    } else {
      showConfirmationsOrError(data);
    }
  };

  const startApprovalProcess = async (selectedApproverIds = []) => {
    const { data } = await httpClient.post(urls.createApprovalRequest, {
      optional_approver_ids: selectedApproverIds,
    });

    showConfirmationsOrError(data, I18n.t('approval_process.no_approvers_body'));
    if (data.error === null && data.redirectUrl) window.location.assign(data.redirectUrl);
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const renderExpirationAlertBar = (
    <Alert
      className="public-orders-show__expired"
      isVisible={!!order.offerExpired}
      type="warning"
      hideClose={true}
    >
      {I18n.t('public.orders.warnings.offer_expired', {
        expire_date: order.startDate,
      })}
    </Alert>
  );

  const updateOrderTitle = async () => {
    setError({});
    const { data } = await httpClient.put(urls.updateTitle, { title });

    if (data.error === null) {
      setEditingTitle(false);
      fetchOrder();
    } else {
      setError(data.error);
    }
  };

  const onTitleEditClose = () => {
    setEditingTitle(false);
    setTitle(order.title);
    setError({});
  };

  const showTitleEditField = () => (
    <div className="col-grid direction-row col-bleed align-center">
      <div className="col-8 col-bleed">
        <Input
          size="tiny"
          value={title}
          onChange={value => setTitle(value)}
          isClearable={false}
          touched={!!error.title}
          error={error.title}
        />
      </div>
      <div className="col-2 col-bleed">
        <IconButton
          icon={<Icon name="close" />}
          label={I18n.t('admin.components.travellers.comments.actions.cancel')}
          color="tertiary"
          size="small"
          onClick={onTitleEditClose}
        />
        <IconButton
          icon={<Icon name="check" />}
          label={I18n.t('admin.components.travellers.comments.actions.save')}
          color="tertiary"
          size="small"
          onClick={updateOrderTitle}
        />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="public-orders-show">
        <div className="grid grid-gap-20">
          <div className="col-12 public-orders-show__header">
            <div className="grid grid-gap-20 wrap align-center justify-space-between">
              <div className="col-12 col-md-auto col-grid direction-row align-center wrap">
                {isLoading ? (
                  <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
                ) : (
                  <Fragment>
                    <div className="public-orders-show__state">
                      <StateEmphasisTag currentState={order.currentState} stateSize="tiny" />
                    </div>
                    {editingTitle ? (
                      showTitleEditField()
                    ) : (
                      <Fragment>
                        <Header className="public-orders-show__intro" level={5} weight="medium">
                          {`${order.id} | ${title}`}
                        </Header>
                        <IconButton
                          className="public-orders-show__edit-button"
                          icon={<Icon name="edit" />}
                          label={I18n.t('admin.components.travellers.comments.actions.edit')}
                          color="tertiary"
                          size="small"
                          onClick={() => setEditingTitle(true)}
                          disabled={!permissions.canUpdateTitle}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
              <div className="col-12 col-md-auto col-grid direction-row public-orders-show__actions">
                {permissions.canGeneratePdf && (
                  <Link
                    href={urls.pdf}
                    type="button"
                    modifier="default"
                    size="small"
                    className="public-orders-show__action"
                    disabled={!permissions.canGeneratePdf}
                  >
                    {I18n.t('public.orders.actions.download_pdf')}
                  </Link>
                )}
                {permissions.canSendEmail && (
                  <div className="public-orders-show__action">
                    <SaveAndSendPDF
                      buttonLabel={I18n.t('public.orders.actions.email_pdf')}
                      sendPdfUrl={urls.sendMail}
                      ownerEmail={order.owner && order.owner.email}
                    />
                  </div>
                )}
                {permissions.canConfirmQuote && (
                  <Button
                    label={I18n.t('components.ibe.book_quote.book_now')}
                    size="small"
                    version="v2"
                    onClick={bookNow}
                    type="primary"
                  />
                )}
              </div>
            </div>
            <hr />
          </div>
          <div className="col-12 col-bleed-y">
            <ApprovalRequestBar
              orderID={orderId}
              order={order}
              startApprovalProcess={startApprovalProcess}
              currentPersonId={currentPersonId}
            />
            {renderExpirationAlertBar}
          </div>
          <div className="col-md-4 col-xlg-3">
            <LeftPanel
              owner={order.owner}
              arranger={arranger}
              billingContact={order.billingContact}
              tenant={order.tenant}
              currentPersonId={currentPersonId}
              loading={isLoading}
            />
          </div>
          <div className="col-md-8 col-xlg-9">
            {!isLoading ? (
              <RTabs
                className="public-orders-show__tabs"
                items={tabs}
                selectedTabKey={TAB_MAPPER[tab] || TAB_MAPPER.overview}
              />
            ) : null}
          </div>
        </div>
      </div>
      <ConfirmationModal
        className="order-show__approval-request-modal"
        confirmationHeader={confirmationAttributes.header}
        subHeader={confirmationAttributes.subHeader}
        type={confirmationAttributes.type}
        isModalOpen={showConfirmation}
        isConfirmationOnly={true}
        onConfirm={() => setShowConfirmation(false)}
        confirmationText={I18n.t('admin.order_items.issue_ticket.ok')}
      />
    </Fragment>
  );
};

Show.propTypes = {
  orderId: PropTypes.number.isRequired,
  urls: PropTypes.shape({
    bookNow: PropTypes.string,
    updateTitle: PropTypes.string,
    sendMail: PropTypes.string,
    pdf: PropTypes.string,
    createApprovalRequest: PropTypes.string,
    newAttachement: PropTypes.string,
    orderTravelExpensesPath: PropTypes.string.isRequired,
  }).isRequired,
  currentPersonId: PropTypes.number.isRequired,
};

export default hot(module)(withQueryParamsProvider(Show));
