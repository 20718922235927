import React from 'react';
import PropTypes from 'prop-types';
import getTripOriginDetails from 'sharedWebpack/IBE/helpers/getTripOriginDetails';
import getTripDestinationDetails from 'sharedWebpack/IBE/helpers/getTripDestinationDetails';
import JourneySummaryIndicator from '.';

const JourneySummaryIndicatorWrapper = ({ trips, airports }) => {
  const originDetails = getTripOriginDetails(trips, airports);
  const destinationDetails = getTripDestinationDetails(trips, airports);

  return (
    <JourneySummaryIndicator
      trips={trips}
      originCountryInfo={originDetails}
      destinationCountryInfo={destinationDetails}
    />
  );
};

const AirportsShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

JourneySummaryIndicatorWrapper.propTypes = {
  trips: PropTypes.arrayOf(PropTypes.string).isRequired,
  airports: AirportsShape.isRequired,
};

export default JourneySummaryIndicatorWrapper;
