import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import useViewMode from 'sharedWebpack/useViewMode';
import CountryDetails from '../../../CountryDetails';
import { ROUNDTRIP } from '../../lib/helpers/tripTypes';
import tripTypeIconMapper from '../../../helpers/tripTypeIconMapper';
import { DESKTOP_DEVICE } from '../../../old/libraries/responsiveHelpers';
import './styles.scss';

const TripRouteInformation = ({ firstTrip, lastTrip, tripType }) => {
  const tripDestination = tripType === ROUNDTRIP ? firstTrip.destination : lastTrip.destination;
  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;

  return (
    <div className="trip-information d-flex">
      <CountryDetails code={firstTrip.origin} />
      <Icon name={tripTypeIconMapper(tripType)} size={isLargeScreen ? 'medium' : 'small'} />
      <CountryDetails code={tripDestination} />
    </div>
  );
};

TripRouteInformation.propTypes = {
  firstTrip: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
  }).isRequired,
  lastTrip: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
  }).isRequired,
  tripType: PropTypes.string.isRequired,
};

export default TripRouteInformation;
