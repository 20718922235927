import React from 'react';
import Icon from '@wtag/rcl-icon';
import { SidePanel, I18nText } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import FriendsShareContainer from './FriendsShareContainer';
import EmailShareContainer from './EmailShareContainer';

import '../styles.scss';

const FriendsSharePanel = () => {
  const [query, setQuery] = useQueryParams({
    openedSidePanel: withDefault(StringParam, ''),
  });

  const addFriendsPanelVisible = query.openedSidePanel === 'add_friends';
  const addEmailPanelVisible = query.openedSidePanel === 'add_email';

  const showAddFriendsPanel = () => {
    setQuery({ openedSidePanel: 'add_friends' }, 'replaceIn');
  };

  const showAddEmailPanel = () => {
    setQuery({ openedSidePanel: 'add_email' }, 'replaceIn');
  };

  const hideSidePanel = () => {
    setQuery({ openedSidePanel: '' }, 'replaceIn');
  };

  const togglePannel = () => {
    if (addFriendsPanelVisible) {
      hideSidePanel();
    } else {
      showAddFriendsPanel();
    }
  };

  const togglePannelEmail = () => {
    if (addEmailPanelVisible) {
      hideSidePanel();
    } else {
      showAddEmailPanel();
    }
  };

  return (
    <div className="email-share-content">
      <div className="col-12 col-grid col-bleed email-share-content">
        <div className="col-grid col-bleed direction-row email-share-content__title">
          <Icon name="share" size="medium" color="success" />
          <h1>
            <I18nText id="ibe.share_panel.friends" returnStringOnly={true} />
          </h1>
        </div>
        <div className="col-grid col-bleed email-share-content__title-explanation">
          <p>
            <I18nText id="ibe.share_panel.header_text" returnStringOnly={true} />
          </p>
        </div>
        <div className="col-12 col-grid col-bleed email-share-content__buttons">
          <div className="col-12 col-grid col-bleed email-share-content__friends-button">
            <Button
              label={<I18nText id="ibe.share_panel.friends" returnStringOnly={true} />}
              size="small"
              version="v2"
              onClick={showAddFriendsPanel}
            />
            <SidePanel
              title={<I18nText id="ibe.share_panel.friends" returnStringOnly={true} />}
              body={<FriendsShareContainer toggleModal={togglePannel} />}
              isOpened={addFriendsPanelVisible}
              onClick={() => hideSidePanel()}
            />
          </div>
          <div className="col-12 col-grid col-bleed email-share-content__email-button">
            <Button
              version="v2"
              size="small"
              label={<I18nText id="ibe.share_panel.email" returnStringOnly={true} />}
              onClick={showAddEmailPanel}
            />
            <SidePanel
              title={<I18nText id="ibe.share_panel.email" returnStringOnly={true} />}
              body={<EmailShareContainer toggleModal={togglePannelEmail} />}
              isOpened={addEmailPanelVisible}
              onClick={() => hideSidePanel()}
            />
          </div>
          <div className="col-grid col-bleed email-share-content__explanation">
            <p>
              <I18nText id="ibe.share_panel.text" returnStringOnly={true} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withQueryParamsProvider(FriendsSharePanel);
