import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LabelHint } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import DateTime from 'sharedWebpack/DateTime';
import IconButton from '@wtag/rcl-icon-button';
import Tooltip from '@wtag/rcl-tooltip';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Country from '../../components/Country';
import getNameFormat from '../helpers/getNameFormat';
import getNameValidationErrorMessage from '../helpers/getNameValidationErrorMessage';
import getInvalidNameAttribute from '../helpers/getInvalidNameAttribute';
import TravelerInformationLoader from '../TravelerInformationLoader';

class TravelerInformationCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      isLoading: this.props.isCarAdded,
    };
  }

  componentDidMount() {
    if (this.props.fields.driverAge.value) {
      this.closeLoader();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fields.driverAge.value !== prevProps.fields.driverAge.value) {
      this.closeLoader();
    }
  }

  setModalOpen = isOpen => {
    this.setState({ modalOpen: isOpen });
  };

  closeLoader = () => {
    this.setState({ isLoading: false });
  };

  showFieldValue = (attribute, attributeName) => {
    if (!attribute.value) {
      return (
        <div
          className={classnames('traveler-information-card__container-attribute-value', {
            'traveler-information-card__container-attribute-value--invalid': attribute.invalid,
          })}
        >
          {I18n.t('components.ibe.traveler_details.attributes.not_provided', {
            attribute: attributeName,
          })}
        </div>
      );
    } else if (attribute.invalid) {
      return (
        <div className="col-grid col-bleed direction-row align-center">
          <div className="traveler-information-card__container-attribute-invalid">
            {attribute.value}
          </div>
          <div className="traveler-information-card__container-attribute-tooltip">
            <Tooltip content={attribute.error} type="danger" position="bottom-left">
              <Icon name="invalid" color="danger" />
            </Tooltip>
          </div>
        </div>
      );
    }

    return (
      <div className="traveler-information-card__container-attribute-value">{attribute.value}</div>
    );
  };

  showName = attributeName => {
    const { namePrefix, firstName, middleName, lastName } = this.props.fields;
    const nameDetails = { namePrefix, firstName, middleName, lastName };
    const name = getNameFormat(nameDetails, this.props.namePrefixList);

    if (!name) {
      return (
        <div className="traveler-information-card__container-attribute-invalid">
          {I18n.t('components.ibe.traveler_details.attributes.not_provided', {
            attribute: attributeName,
          })}
        </div>
      );
    } else if (namePrefix.invalid || firstName.invalid || middleName.invalid || lastName.invalid) {
      const invalidAttributeName = getInvalidNameAttribute(nameDetails);
      const errorMessage = getNameValidationErrorMessage(invalidAttributeName, nameDetails);

      return (
        <div className="col-grid col-bleed direction-row align-center">
          <div className="traveler-information-card__container-attribute-invalid">{name}</div>
          <div className="traveler-information-card__container-attribute-tooltip">
            <Tooltip content={errorMessage} type="danger" position="bottom-left">
              <Icon name="invalid" color="danger" />
            </Tooltip>
          </div>
        </div>
      );
    }

    return <div className="traveler-information-card__container-attribute-value">{name}</div>;
  };

  showBirthday = (birthday, attributeName) => {
    if (this.state.isLoading) {
      return <ContentLoaderPlaceholder showBackground={false} numberOfLines={1} />;
    }

    const { value, invalid, error } = birthday;

    if (!value) {
      return (
        <div className="traveler-information-card__container-attribute-invalid">
          {I18n.t('components.ibe.traveler_details.attributes.not_provided', {
            attribute: attributeName,
          })}
        </div>
      );
    } else if (invalid) {
      return (
        <div className="col-grid col-bleed direction-row align-center">
          <div className="traveler-information-card__container-attribute-invalid">
            <DateTime
              dateTime={value}
              format="short"
              displayInUTC={false}
              displayWithoutConversion={false}
            />
          </div>
          <div className="traveler-information-card__container-attribute-tooltip">
            <Tooltip content={error} type="danger" position="bottom-left">
              <Icon name="invalid" color="danger" />
            </Tooltip>
          </div>
        </div>
      );
    }

    return (
      <div className="traveler-information-card__container-attribute-value">
        <DateTime
          dateTime={value}
          format="short"
          displayInUTC={false}
          displayWithoutConversion={false}
        />
      </div>
    );
  };

  render() {
    const {
      fields: {
        birthday,
        email,
        isTravelArrangerEmailUsed,
        travelArrangerEmail,
        phone,
        residenceAddress,
      },
      setSingleTravelerShowPage,
      showHintForTravelerArrangerEmail,
      myBooking,
      editable,
      isTravelerInformationLoading,
    } = this.props;

    if (isTravelerInformationLoading) {
      return <TravelerInformationLoader />;
    }

    const travelerCartEmail =
      isTravelArrangerEmailUsed.value && editable ? travelArrangerEmail : email;

    return (
      <Card version="v2" size="full" className="col-12 traveler-information-card">
        <div className="col-grid col-bleed direction-row justify-space-between traveler-information-card__header">
          <div className="traveler-information-card__header-text">
            {I18n.t('components.ibe.traveler_details.general')}
          </div>
          <div className="traveler-information-card__header-icon">
            {myBooking ? (
              <IconButton
                icon={<Icon name="edit" size="normal" />}
                onClick={() => this.setModalOpen(true)}
                label={I18n.t('shared.actions.edit')}
              />
            ) : (
              <IconButton
                icon={<Icon name="edit" size="small" />}
                label={I18n.t('shared.actions.edit')}
                onClick={() => setSingleTravelerShowPage('edit_traveler_information')}
              />
            )}
            <ConfirmationModal
              isModalOpen={this.state.modalOpen}
              confirmationHeader={I18n.t(
                'components.ibe.traveler_details.confirmations.edit_traveler_profile',
              )}
              subHeader={I18n.t(
                'components.ibe.traveler_details.confirmations.subtext_for_affect_profile',
              )}
              confirmationText={I18n.t('components.ibe.traveler_details.buttons.edit_information')}
              rejectionText={I18n.t('components.ibe.traveler_details.buttons.go_back')}
              onConfirm={() => setSingleTravelerShowPage('edit_traveler_information')}
              onReject={() => this.setModalOpen(false)}
              withAction={true}
              type="danger"
            />
          </div>
        </div>

        <div className="col-12 col-bleed col-grid col-bleed direction-row align-start travel-information-card__body">
          <div className="col-12 col-sm-6 col-bleed traveler-information-card__container">
            <div className="traveler-information-card__container-attribute">
              <div className="traveler-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.attributes.name')}
              </div>
              {this.showName(I18n.t('components.ibe.traveler_details.attributes.name'))}
            </div>

            <div className="traveler-information-card__container-attribute">
              <div className="traveler-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.attributes.birthday')}
              </div>
              {this.showBirthday(
                birthday,
                I18n.t('components.ibe.traveler_details.attributes.birthday'),
              )}
            </div>

            <div className="traveler-information-card__container-attribute">
              <div className="traveler-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.attributes.email')}
                {showHintForTravelerArrangerEmail && (
                  <LabelHint
                    hint={I18n.t('components.ibe.default_travel_arranger_mail.hint_message')}
                    size="tiny"
                    noMargin={true}
                    icon="invalid"
                    className="traveler-information-card__container-attribute-label"
                  />
                )}
              </div>
              {this.showFieldValue(
                travelerCartEmail,
                I18n.t('components.ibe.traveler_details.attributes.email'),
              )}
            </div>

            <div className="traveler-information-card__container-attribute">
              <div className="traveler-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.attributes.phone')}
              </div>
              {this.showFieldValue(
                phone,
                I18n.t('components.ibe.traveler_details.attributes.phone'),
              )}
            </div>
          </div>

          <div className="col-12 col-sm-6 col-bleed traveler-information-card__container">
            <div className="traveler-information-card__container-attribute">
              <div className="traveler-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.attributes.street')}
              </div>
              {this.showFieldValue(
                residenceAddress.street,
                I18n.t('components.ibe.traveler_details.attributes.street'),
              )}
            </div>

            <div className="traveler-information-card__container-attribute">
              <div className="traveler-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.attributes.zip')}
              </div>
              {this.showFieldValue(
                residenceAddress.zip,
                I18n.t('components.ibe.traveler_details.attributes.zip'),
              )}
            </div>

            <div className="traveler-information-card__container-attribute">
              <div className="traveler-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.attributes.city')}
              </div>
              {this.showFieldValue(
                residenceAddress.city,
                I18n.t('components.ibe.traveler_details.attributes.city'),
              )}
            </div>

            <div className="traveler-information-card__container-attribute">
              <div className="traveler-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.attributes.state')}
              </div>
              {this.showFieldValue(
                residenceAddress.state,
                I18n.t('components.ibe.traveler_details.attributes.state'),
              )}
            </div>
            <div className="traveler-information-card__container-attribute">
              <div className="traveler-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.attributes.country')}
              </div>
              {residenceAddress.country.value && residenceAddress.country.invalid ? (
                <div className="col-grid col-bleed direction-row align-center">
                  <div className="traveler-information-card__container-attribute-invalid">
                    <Country code={residenceAddress.country.value} />
                  </div>
                  <div className="traveler-information-card__container-attribute-tooltip">
                    <Tooltip
                      content={residenceAddress.country.error}
                      type="danger"
                      position="bottom-left"
                    >
                      <Icon name="invalid" color="danger" />
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div className="traveler-information-card__container-attribute-value">
                  <Country code={residenceAddress.country.value} />
                </div>
              )}
              {!residenceAddress.country.value && (
                <div
                  className={classnames('traveler-information-card__container-attribute-value', {
                    'traveler-information-card__container-attribute-value--invalid':
                      residenceAddress.country.invalid,
                  })}
                >
                  {I18n.t('components.ibe.traveler_details.attributes.not_provided', {
                    attribute: I18n.t('components.ibe.traveler_details.attributes.country'),
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

TravelerInformationCard.propTypes = {
  fields: PropTypes.shape().isRequired,
  setSingleTravelerShowPage: PropTypes.func.isRequired,
  showHintForTravelerArrangerEmail: PropTypes.bool.isRequired,
  myBooking: PropTypes.bool.isRequired,
  isCarAdded: PropTypes.bool.isRequired,
  namePrefixList: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
  ).isRequired,
  editable: PropTypes.bool.isRequired,
  isTravelerInformationLoading: PropTypes.bool.isRequired,
};

TravelerInformationCard.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  travelArrangerDefaultEmail: PropTypes.string.isRequired,
  travelArranger: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TravelerInformationCard;
