import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';
import Amount from 'sharedWebpack/Amount';
import Availability from '../../components/Availability';
import ShowGrossTotalPrice from '../../../ShowGrossTotalPrice';
import './styles.scss';

const ItemPricing = props => {
  const { available, item, itemStatus, pendingConfirmation } = props;
  const { priceChanged, oldGrossTotal, grossTotal, currency } = item;

  let availability = available;
  if (itemStatus) {
    const { status } = itemStatus;
    availability = null;
    if (status === 'success' || status === 'failed' || status === 'pending') {
      availability = status === 'success' || status === 'pending';
    }
  }
  const showAvailabilityIcon = !pendingConfirmation;

  const availabilityIcon = showAvailabilityIcon && (
    <Availability
      available={availability}
      unavailableHint={
        itemStatus ? (
          <I18nText id="ibe.booking.item_not_confirmed" returnStringOnly={true} />
        ) : (
          I18n.t('components.ibe.cart_unavailable_hint')
        )
      }
    />
  );

  return (
    <Fragment>
      {priceChanged && oldGrossTotal !== grossTotal && (
        <div className="item__pricing-old-value">
          <I18nText id="ibe.cart_item_pricing.previous_price" />
          <Amount currency={currency} value={oldGrossTotal} />
        </div>
      )}
      <div
        className={classNames('item__pricing', {
          'item__pricing--pending-confirmation': pendingConfirmation,
        })}
      >
        <ShowGrossTotalPrice
          availabilityIcon={availabilityIcon}
          currency={currency}
          grossTotal={grossTotal}
        />
      </div>
    </Fragment>
  );
};

ItemPricing.defaultProps = {
  itemStatus: null,
  pendingConfirmation: false,
  travelerCount: {
    adults: [],
    children: [],
    infants: [],
  },
};

ItemPricing.contextTypes = {
  frozen: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool,
};

ItemPricing.propTypes = {
  pendingConfirmation: PropTypes.bool,
  itemStatus: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }),
  item: PropTypes.shape({
    grossTotal: PropTypes.string.isRequired,
    grossAmount: PropTypes.string.isRequired,
    grossTaxes: PropTypes.string,
    grossTotalIncludingSubItems: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    available: PropTypes.bool,
    oldGrossTotal: PropTypes.string,
    priceChanged: PropTypes.bool,
    marginBreakdown: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        total: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  journeyElement: PropTypes.shape({
    id: PropTypes.number,
    rooms: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    fareCalculations: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        passengerType: PropTypes.string,
        price: PropTypes.string,
        legPrice: PropTypes.string,
      }),
    ),
  }).isRequired,
  available: PropTypes.oneOf([Boolean, undefined]).isRequired,
  travelerCount: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ItemPricing;
