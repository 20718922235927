import React from 'react';
import PropTypes from 'prop-types';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';

const FrequentFlyerNumberForm = props => {
  const { carrierCodeTypes, carrierCode, setCarrierCode, number, setNumber, error } = props;

  const carrierCodeOptions = Object.keys(carrierCodeTypes).map(carrierCodeKey => ({
    value: carrierCodeTypes[carrierCodeKey][0],
    label: carrierCodeTypes[carrierCodeKey][1],
  }));

  const findSelectBoxContent = (options, value) => {
    const selectedOption = options.filter(option => value === option.value)[0];
    if (selectedOption) {
      return {
        value: selectedOption.value,
        label: selectedOption.label,
      };
    }

    return null;
  };

  return (
    <div className="grid grid-gap-12">
      <div className="col-sm-6">
        <SelectBox
          size="tiny"
          placeholderText={I18n.t(
            'admin.components.travellers.edit.frequent_flyer_numbers.placeholders.carrier_code',
          )}
          label={I18n.t('activerecord.attributes.frequent_flyer_number.carrier_code')}
          width="full"
          options={carrierCodeOptions}
          value={findSelectBoxContent(carrierCodeOptions, carrierCode)}
          onChange={selectedOption => setCarrierCode(selectedOption && selectedOption.value)}
          errorMsg={error.carrierCode}
          required={true}
        />
      </div>
      <div className="col-sm-6">
        <Input
          size="tiny"
          placeholder={I18n.t(
            'admin.components.travellers.edit.frequent_flyer_numbers.placeholders.number',
          )}
          label={I18n.t('activerecord.attributes.frequent_flyer_number.number')}
          touched={true}
          error={error.number}
          value={number}
          onChange={value => setNumber(value)}
          required={true}
        />
      </div>
    </div>
  );
};

FrequentFlyerNumberForm.defaultProps = {
  error: {},
  carrierCode: [],
  setCarrierCode: {},
};

FrequentFlyerNumberForm.propTypes = {
  carrierCodeTypes: PropTypes.shape([PropTypes.arrayOf(PropTypes.string)]).isRequired,
  carrierCode: PropTypes.arrayOf(PropTypes.shape),
  setCarrierCode: PropTypes.func,
  number: PropTypes.string.isRequired,
  setNumber: PropTypes.func.isRequired,
  error: PropTypes.shape({
    number: PropTypes.string,
    carrierCode: PropTypes.string,
  }),
};

export default FrequentFlyerNumberForm;
