import { combineReducers } from 'redux';

import defaultHotelSearchParams from './defaultHotelSearchParams';
import hotelDetailsByResultId from './hotelDetailsByResultId';
import searchFilterParamsBySearchId from './searchFilterParamsBySearchId';
import searchParamsBySearchId from './searchParamsBySearchId';
import searchResultsBySearchId from './searchResultsBySearchId';
import searchStatsBySearchId from './searchStatsBySearchId';
import totalResultsBySearchId from './totalResultsBySearchId';
import totalResultsPerSupplier from './totalResultsPerSupplier';

const hotelsReducer = combineReducers({
  defaultHotelSearchParams,
  hotelDetailsByResultId,
  searchFilterParamsBySearchId,
  searchParamsBySearchId,
  searchResultsBySearchId,
  searchStatsBySearchId,
  totalResultsBySearchId,
  totalResultsPerSupplier,
});

export default hotelsReducer;
