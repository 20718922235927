import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@wtag/rcl-radio';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';
import CreditCardDetails from 'sharedWebpack/CreditCardVaultPayment/CreditCardDetails';
import paymentMethodLocaleName from '../../helpers/paymentMethodLocaleName';
import paymentMethodName from '../../helpers/paymentMethodName';
import getCardChargesByType from '../../helpers/getCardChargesByType';
import './styles.scss';

const PayIndividuallyOption = props => {
  const {
    fop,
    isChecked,
    laymanMode,
    onPaymentMethodChange,
    isFopLoading,
    supportedCards,
    currency,
  } = props;
  const { type, card } = fop;
  const paymentMethodType = card ? card.cardType : type;
  const iconName = paymentMethodName(paymentMethodType, laymanMode);

  const iconLabel = () => {
    if (card) {
      const charges = getCardChargesByType(supportedCards, card.cardType);

      return <CreditCardDetails card={card} charges={charges} currency={currency} />;
    }

    return (
      <div className="pay-individually-option__name">
        {paymentMethodLocaleName(type, laymanMode)}
      </div>
    );
  };

  return (
    <div className="pay-individually-option">
      <Radio
        name="payment-radio"
        size="huge"
        onChange={onPaymentMethodChange}
        isChecked={isChecked}
        disabled={isFopLoading}
        withCheckIcon={true}
      />
      <Icon
        className={classNames('pay-individually-option--icon', {
          'pay-individually-option--icon-pay-later': iconName === 'receipt',
        })}
        name={iconName}
        size="normal"
        color="tertiary"
        showLabel={true}
        iconLabel={iconLabel()}
        showBGColor={true}
      />
    </div>
  );
};

PayIndividuallyOption.propTypes = {
  currency: PropTypes.string.isRequired,
  fop: PropTypes.shape().isRequired,
  laymanMode: PropTypes.bool.isRequired,
  onPaymentMethodChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isFopLoading: PropTypes.bool.isRequired,
  supportedCards: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      cardType: PropTypes.string,
    }),
  ).isRequired,
};

export default PayIndividuallyOption;
