import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';
import FacilityListItem from '../FacilityListItem';
import FuelType from '../FuelType';
import { FacilitiesItemShape } from '../shapes/facilities';

const FacilityItemsList = ({ className, facilities }) => {
  const { seats, doors, airConditioned, fuelType, airBag } = facilities;

  return (
    <div className={classNames('grid grid-gap-20', className)}>
      <span className="col-12">
        <I18nText id="ibe.search_results.car.label.available_attributes" />
      </span>
      <div className="col-12">
        <div className="grid grid-gap-12">
          {seats && (
            <FacilityListItem
              className="col-6 d-flex"
              label={I18n.t('components.ibe.cars_facilities.seats', {
                seats,
              })}
            />
          )}
          {doors > 0 && (
            <FacilityListItem
              className="col-6 d-flex"
              label={I18n.t('components.ibe.cars_facilities.doors', {
                count: doors,
              })}
            />
          )}
          {airConditioned && (
            <FacilityListItem
              className="col-6 d-flex"
              label={I18n.t('components.ibe.cars_facilities.air_conditioned')}
            />
          )}
          {fuelType && (
            <FacilityListItem className="col-6 d-flex" label={<FuelType type={fuelType} />} />
          )}
          {airBag && (
            <FacilityListItem
              className="col-6 d-flex"
              label={I18n.t('components.ibe.cars_facilities.air_bag')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

FacilityItemsList.defaultProps = {
  className: null,
};

FacilityItemsList.propTypes = {
  className: PropTypes.string,
  facilities: FacilitiesItemShape.isRequired,
};

export default FacilityItemsList;
