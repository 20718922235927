/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Row from '../Row';

function ServicesWithPriceBreakdown(props) {
  const { services } = props;

  return (
    services.length > 0 &&
    services.map(service => (
      <Row
        name={service.itemTitle}
        key={service.id}
        quantity={service.quantity}
        unitPrice={service.grossAmount}
        price={service.grossTotal}
        currency={service.currency}
        journeyElementType="service"
      />
    ))
  );
}

ServicesWithPriceBreakdown.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ServicesWithPriceBreakdown;
