import React, { Fragment, useState } from 'react';
import { Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Modal from 'sharedWebpack/Modal';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import PropTypes from 'prop-types';
import './styles.scss';

const OrdersLeftPanel = ({ owner, arranger, billingContact, tenant, currentPersonId, loading }) => {
  const [copyState, setCopyState] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const copyTextToClipboard = async text => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setCopyState(true);
    setTimeout(() => {
      setCopyState(false);
    }, 1000);
  };

  const modalTitleContent = (
    <div
      onClick={() => setIsModalVisible(false)}
      onKeyDown={() => setIsModalVisible(false)}
      role="presentation"
      className="deals-image__modal__close-icon"
    >
      <Icon name="close" />
    </div>
  );

  const renderModal = () => {
    const copyButton = data => (
      <Link
        type="icon-button"
        modifier="default"
        labelHint={
          copyState
            ? I18n.t('admin.components.orders.left_panel.link_copied')
            : I18n.t('admin.components.orders.left_panel.copy_link')
        }
        labelHintPosition="left"
        onClick={() => copyTextToClipboard(data)}
        disabled={!data}
      >
        <Icon name="remarks" size="small" />
      </Link>
    );

    return (
      <Modal
        className="left-panel__modal"
        isModalVisible={isModalVisible}
        title={modalTitleContent}
        onOutsideClick={() => setIsModalVisible(false)}
      >
        <div className="col-12 col-grid col-bleed align-center">
          <Avatar
            className="left-panel__owner-card-arranger-avatar"
            size="normal"
            firstName={arranger.firstName}
            lastName={arranger.lastName}
            showName={false}
            src={arranger.avatarUrl}
          />
          <span className="left-panel__owner-card-arranger-name">{arranger.name}</span>
          <div className="col-12 col-grid col-bleed direction-row align-center justify-space-between left-panel__owner-card-arranger-modal-field">
            {arranger.email ||
              I18n.t('admin.components.channels.list.table.unavailable_text.email')}
            {copyButton(arranger.email)}
          </div>
          <div className="col-12 col-grid col-bleed direction-row align-center justify-space-between left-panel__owner-card-arranger-modal-field">
            {arranger.phone ||
              I18n.t('admin.components.orders.left_panel.travel_arranger.no_phone')}
            {copyButton(arranger.phone)}
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className="grid grid-gap-20">
      <div className="col-12">
        {loading ? (
          <ContentLoaderPlaceholder numberOfLines={4} showBackground={true} />
        ) : (
          <Card version="v2" className="public-orders-show left-panel__owner-card">
            {arranger && arranger.id && (
              <div className="col-grid col-bleed align-center left-panel__owner-card-arranger">
                <div className="col-grid col-bleed direction-row justify-center left-panel__owner-card-arranger-label">
                  {I18n.t('admin.components.orders.left_panel.travel_arranger.title')}
                </div>
                <Fragment>
                  <Avatar
                    className="left-panel__owner-card-arranger-avatar"
                    size="normal"
                    firstName={arranger.firstName}
                    lastName={arranger.lastName}
                    showName={false}
                    src={arranger.avatarUrl}
                  />
                  <span className="left-panel__owner-card-arranger-name">{arranger.name}</span>
                  <div className="left-panel__owner-card-arranger-actions">
                    <div className="left-panel__owner-card-arranger-actions-wrapper">
                      <div className="left-panel__owner-card-arranger-actions-wrapper-button">
                        <Link
                          type="icon-button"
                          modifier="default"
                          labelHint={I18n.t('admin.components.orders.left_panel.titles.message')}
                        >
                          <Icon name="chat" size="small" />
                        </Link>
                      </div>
                      <div className="left-panel__owner-card-arranger-actions-wrapper-button">
                        <Link
                          type="icon-button"
                          modifier="default"
                          labelHint={I18n.t('admin.components.general_settings.fields.email')}
                          onClick={() => setIsModalVisible(true)}
                          disabled={!arranger.email}
                        >
                          <Icon name="email" size="small" />
                        </Link>
                      </div>
                      <div className="left-panel__owner-card-arranger-actions-wrapper-button">
                        <Link
                          type="icon-button"
                          modifier="default"
                          labelHint={I18n.t('admin.components.general_settings.fields.phone')}
                          onClick={() => setIsModalVisible(true)}
                          disabled={!arranger.phone}
                        >
                          <Icon name="call" size="small" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {renderModal()}
                </Fragment>
              </div>
            )}

            <div className="left-panel__owner-card-owner-section">
              <div className="left-panel__label">
                {I18n.t('admin.components.orders.left_panel.titles.owner')}
              </div>
              <div className="col-grid col-bleed direction-row align-center">
                <Avatar
                  size="tiny"
                  firstName={owner.firstName}
                  lastName={owner.lastName}
                  showName={false}
                  src={owner.avatarUrl}
                />
                <span className="left-panel__owner-card-owner-name">
                  <span>{owner.name}</span>
                  {currentPersonId === owner.id && (
                    <span>
                      {I18n.t('admin.components.orders.left_panel.titles.owner_logged_in')}
                    </span>
                  )}
                </span>
              </div>
              <div className="left-panel__owner-card-owner-email">{owner.email}</div>
            </div>
          </Card>
        )}
      </div>
      <div className="col-12">
        {loading ? (
          <ContentLoaderPlaceholder numberOfLines={2} showBackground={true} />
        ) : (
          <Fragment>
            {(billingContact || tenant) && (
              <Card version="v2" className="public-orders-show left-panel__billing-card">
                {billingContact && (
                  <div className="left-panel__billing-card-billing-contact">
                    <div className="left-panel__label">
                      {I18n.t('activerecord.attributes.order.billing_contact')}
                    </div>
                    <div className="left-panel__billing-card-billing-info">
                      <Avatar
                        size="tiny"
                        firstName={billingContact.name}
                        lastName=""
                        showName={true}
                        src={billingContact.avatarUrl}
                      />
                      <div className="left-panel__owner-card-owner-email">
                        {billingContact.email}
                      </div>
                    </div>
                  </div>
                )}
                {tenant && (
                  <Fragment>
                    <div className="left-panel__billing-card-tenant">
                      <img
                        className="left-panel__billing-card-tenant-logo"
                        src={tenant.logo}
                        alt="tenant-logo"
                      />
                    </div>
                    <div className="left-panel__billing-card-tenant">
                      <div className="left-panel__label left-panel__label--less-padding">
                        {I18n.t('admin.components.orders.left_panel.titles.ordered_with')}
                      </div>
                      <span className="left-panel__billing-card-tenant-name">{tenant.name}</span>
                    </div>
                  </Fragment>
                )}
              </Card>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

OrdersLeftPanel.defaultProps = {
  arranger: null,
  billingContact: null,
  tenant: null,
};

OrdersLeftPanel.propTypes = {
  owner: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  arranger: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  billingContact: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    email: PropTypes.string,
  }),
  tenant: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
  }),
  currentPersonId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default OrdersLeftPanel;
