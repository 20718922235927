import snakeCaseKeys from 'snakecase-keys';
import moment from 'moment';

export function form2api(formParams, callbackParams) {
  let pickUpTime;
  let dropOffTime;
  if (formParams.pickUpTime || formParams.dropOffTime) {
    pickUpTime = moment(
      `${moment(formParams.pickUpDate).format('YYYY/MM/DD')}  ${formParams.pickUpTime}`,
    ).format('YYYY-MM-DDTHH:mm:ss');
    dropOffTime = moment(
      `${moment(formParams.dropOffDate).format('YYYY/MM/DD')}  ${formParams.dropOffTime}`,
    ).format('YYYY-MM-DDTHH:mm:ss');
  } else {
    pickUpTime = formParams.pickUpDate;
    dropOffTime = formParams.dropOffDate;
  }

  let searchLocations = {};
  if (formParams.airportSearch) {
    searchLocations = {
      airport_search: formParams.airportSearch,
      pick_up_location_airport_code: formParams.pickUpLocationAirportCode,
      drop_off_location_airport_code: formParams.dropOffLocationAirportCode,
    };
  } else {
    searchLocations = {
      airport_search: formParams.airportSearch,
      pick_up_geo_location: {
        address: formParams.pickUpGeoLocationAddress,
        latitude: formParams.pickUpGeoLocationLatitude,
        longitude: formParams.pickUpGeoLocationLongitude,
      },
      drop_off_geo_location: {
        address: formParams.dropOffGeoLocationAddress,
        latitude: formParams.dropOffGeoLocationLatitude,
        longitude: formParams.dropOffGeoLocationLongitude,
      },
    };
  }

  const searchParams = {
    ...searchLocations,
    pick_up_time: pickUpTime,
    drop_off_time: dropOffTime,
    driver_age: formParams.driverAge,
    currency: formParams.currency,
    callback_params: callbackParams,
    accounts: formParams.accountSettings,
    preselect_travelers: formParams.preselectTravelers,
  };

  if (
    formParams.vendorPreferences &&
    formParams.vendorPreferences.codes &&
    formParams.vendorPreferences.codes.length > 0
  ) {
    searchParams.vendor_preferences = {
      vendor_code: formParams.vendorPreferences.codes.map(code => code.value),
      type: formParams.vendorPreferences.type,
    };
  }

  return searchParams;
}

export function api2form(apiParams) {
  const newParams = { ...apiParams };

  const pickUpTime = moment(newParams.pickUpTime);
  const dropOffTime = moment(newParams.dropOffTime);

  newParams.pickUpTime = pickUpTime.format('HH:mm');
  newParams.dropOffTime = dropOffTime.format('HH:mm');
  newParams.pickUpDate = pickUpTime.format('YYYY/MM/DD');
  newParams.dropOffDate = dropOffTime.format('YYYY/MM/DD');

  if (newParams.vendorPreferences) {
    newParams.vendorPreferences.codes = newParams.vendorPreferences.vendorCode.map(code => ({
      value: code,
      label: code,
    }));

    delete newParams.vendorPreferences.vendorCode;
  }

  if (newParams.airportSearch) {
    newParams.pickUpLocationAirportCode = newParams.pickUpLocationId;
    newParams.dropOffLocationAirportCode = newParams.dropOffLocationId;
    delete newParams.pickUpLocationId;
    delete newParams.dropOffLocationId;
  } else {
    newParams.pickUpGeoLocationAddress = newParams.pickUpGeoLocation.address;
    newParams.pickUpGeoLocationLatitude = newParams.pickUpGeoLocation.latitude;
    newParams.pickUpGeoLocationLongitude = newParams.pickUpGeoLocation.longitude;
    newParams.dropOffGeoLocationAddress = newParams.dropOffGeoLocation.address;
    newParams.dropOffGeoLocationLatitude = newParams.dropOffGeoLocation.latitude;
    newParams.dropOffGeoLocationLongitude = newParams.dropOffGeoLocation.longitude;

    delete newParams.pickUpGeoLocation;
    delete newParams.dropOffGeoLocation;
  }

  if (newParams.accounts) {
    newParams.accountSettings = snakeCaseKeys(newParams.accounts);
    delete newParams.accounts;
  }

  return newParams;
}
