import { useState } from 'react';
import getAncillaryIconLabel from '../helpers/getAncillaryIconLabel';

const useAncillaryItemWrapper = ({ type, isItemAvailable }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const onPopoverClose = () => setIsPopoverOpen(false);
  const onPopoverOpen = () => setIsPopoverOpen(true);
  const iconLabel = getAncillaryIconLabel(type, isItemAvailable);

  return { isPopoverOpen, onPopoverClose, onPopoverOpen, iconLabel };
};

export default useAncillaryItemWrapper;
