import React from 'react';
import PropTypes from 'prop-types';
import SelectBox from '@wtag/rcl-select-box';

const FlightPreferencesForm = props => {
  const {
    preferredCabin,
    setPreferredCabin,
    preferredSeating,
    setPreferredSeating,
    meal,
    setMeal,
    error,
    mealTypes,
    seatTypes,
    cabinTypes,
    layman,
  } = props;

  const cabinOptions = Object.keys(cabinTypes).map(cabinTypeKey => ({
    value: cabinTypeKey,
    label: I18n.t(cabinTypeKey, { scope: 'cabin_classes' }),
  }));

  const seatOptions = Object.keys(seatTypes).map(seatTypeKey => ({
    value: seatTypeKey,
    label: I18n.t(seatTypeKey, { scope: 'simple_form.options.person.preferred_seating' }),
  }));

  const mealOptions = Object.keys(mealTypes).map(mealTypeKey => ({
    value: mealTypeKey,
    label: I18n.t(mealTypeKey, { scope: 'simple_form.options.person.meal' }),
  }));

  const findSelectBoxContent = (options, value) => {
    const selectedOption = options.filter(option => value === option.value)[0];
    if (selectedOption) {
      return {
        value: selectedOption.value,
        label: selectedOption.label,
      };
    }

    return null;
  };

  return (
    <div className="grid grid-gap-12">
      {!layman && (
        <div className="col-12">
          <SelectBox
            size="tiny"
            label={I18n.t('simple_form.labels.person.preferred_cabin')}
            width="full"
            options={cabinOptions}
            value={findSelectBoxContent(cabinOptions, preferredCabin)}
            onChange={selectedOption => setPreferredCabin(selectedOption && selectedOption.value)}
            errorMsg={error.preferredCabin}
          />
        </div>
      )}
      <div className="col-sm-6">
        <SelectBox
          size="tiny"
          label={I18n.t('simple_form.labels.person.preferred_seating')}
          width="full"
          options={seatOptions}
          value={findSelectBoxContent(seatOptions, preferredSeating)}
          onChange={selectedOption => setPreferredSeating(selectedOption && selectedOption.value)}
          errorMsg={error.preferredSeating}
        />
      </div>
      <div className="col-sm-6">
        <SelectBox
          size="tiny"
          label={I18n.t('simple_form.labels.person.meal')}
          width="full"
          options={mealOptions}
          value={findSelectBoxContent(mealOptions, meal)}
          onChange={selectedOption => setMeal(selectedOption && selectedOption.value)}
          errorMsg={error.meal}
        />
      </div>
    </div>
  );
};

FlightPreferencesForm.defaultProps = {
  layman: false,
  preferredCabin: '',
  preferredSeating: '',
  meal: '',
  error: {},
};

FlightPreferencesForm.propTypes = {
  layman: PropTypes.bool,
  mealTypes: PropTypes.shape({}).isRequired,
  cabinTypes: PropTypes.shape({}).isRequired,
  seatTypes: PropTypes.shape({}).isRequired,
  preferredCabin: PropTypes.number,
  setPreferredCabin: PropTypes.func.isRequired,
  preferredSeating: PropTypes.number,
  setPreferredSeating: PropTypes.func.isRequired,
  meal: PropTypes.string,
  setMeal: PropTypes.func.isRequired,
  error: PropTypes.shape({
    preferredCabin: PropTypes.string,
    preferredSeating: PropTypes.string,
    meal: PropTypes.string,
  }),
};

export default FlightPreferencesForm;
