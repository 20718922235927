import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import Icon from '@wtag/rcl-icon';
import { ContentLoaderPlaceholder } from '@wtag/react-comp-lib';
import useTruncateTextTooltip from 'sharedWebpack/helpers/hooks/useTruncateTextTooltip';

const LocationIconWithLabel = ({ className, loaderClassName, value }) => {
  const iconLabelRef = useRef(null);
  const isTooltipVisible = useTruncateTextTooltip(iconLabelRef);

  if (!value) {
    return (
      <div className={loaderClassName}>
        <ContentLoaderPlaceholder contentWidth="148px" numberOfLines={1} showBackground={false} />
      </div>
    );
  }

  const iconWithLabel = (
    <div className={className}>
      <Icon name="location" color="danger" size="small" />
      <span className="icon__label" ref={iconLabelRef}>
        {value}
      </span>
    </div>
  );

  if (isTooltipVisible) {
    return (
      <Tooltip content={value} size="tiny">
        {iconWithLabel}
      </Tooltip>
    );
  }

  return iconWithLabel;
};

LocationIconWithLabel.defaultProps = {
  className: null,
  loaderClassName: null,
  value: null,
};

LocationIconWithLabel.propTypes = {
  className: PropTypes.string,
  loaderClassName: PropTypes.string,
  value: PropTypes.string,
};

export default LocationIconWithLabel;
