import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { I18nText } from '@wtag/react-comp-lib';
import { DANGER, DEFAULT, SUCCESS } from 'sharedWebpack/IBE/lib/helpers/componentStates';
import LabelWithHint from '../../../components/LabelWithHint';

const RightSidedHotelBookingStatus = ({
  approvalRequired,
  laymanMode,
  outOfPolicy,
  payLater,
  policiesReasons,
  refundable,
}) => {
  const rightSidedHotelBookingStatus = [];

  const {
    approvalRequired: approvalRequiredHintText,
    outOfPolicy: outOfPolicyHintText,
  } = policiesReasons;

  if (refundable !== null) {
    const refundableType = refundable ? DEFAULT : DANGER;
    const refundableText = refundable ? (
      <I18nText id="ibe.cart_item_pricing.refundable" />
    ) : (
      <I18nText id="ibe.cart_item_pricing.non_refundable" />
    );

    rightSidedHotelBookingStatus.push({
      iconName: 'preference',
      iconColor: refundableType,
      label: <LabelWithHint text={refundableText} />,
    });
  }

  if (approvalRequired) {
    rightSidedHotelBookingStatus.push({
      iconName: 'singlePolicy',
      iconColor: DANGER,
      label: (
        <LabelWithHint
          text={I18n.t('components.ibe.search_result.approval_required')}
          hint={approvalRequiredHintText}
        />
      ),
    });
  }

  if (payLater && !laymanMode) {
    rightSidedHotelBookingStatus.push({
      iconName: 'recurringPayment',
      iconColor: SUCCESS,
      label: (
        <LabelWithHint
          text={I18n.t('components.ibe.results.book_and_hold')}
          hint={I18n.t('components.ibe.results.hotel_book_and_hold_hint')}
        />
      ),
    });
  }

  if (outOfPolicy) {
    rightSidedHotelBookingStatus.push({
      iconName: 'singlePolicy',
      iconColor: DANGER,
      label: (
        <LabelWithHint
          text={I18n.t('components.ibe.search_result.labels.out_of_policy')}
          hint={outOfPolicyHintText}
        />
      ),
    });
  }

  return (
    <div className="d-flex align-center wrap hotel-booking-status__information-item">
      {rightSidedHotelBookingStatus.map(({ iconName, iconColor, label }) => (
        <div
          key={iconName}
          className="d-flex align-center hotel-booking-status__information-item-label"
        >
          <div className="d-flex align-center hotel-booking-status__information-item-icon">
            <Icon name={iconName} size="tiny" color={iconColor} />
            <span>{label}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

RightSidedHotelBookingStatus.propTypes = {
  refundable: PropTypes.bool.isRequired,
  approvalRequired: PropTypes.bool.isRequired,
  payLater: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  outOfPolicy: PropTypes.bool.isRequired,
  policiesReasons: PropTypes.shape({
    outOfPolicy: PropTypes.string,
    approvalRequired: PropTypes.string,
  }).isRequired,
};

export default RightSidedHotelBookingStatus;
