export const UPDATE_BOOKING_REQUESTS_ENCODED_BY_TOKEN = 'UPDATE_BOOKING_REQUESTS_ENCODED_BY_TOKEN';
export const STRINGIFY_NEW_BOOKING_REQUEST = 'STRINGIFY_NEW_BOOKING_REQUEST';

export const updateBookingRequestsEncodedByToken = newBookingReqState => ({
  type: UPDATE_BOOKING_REQUESTS_ENCODED_BY_TOKEN,
  newBookingReqState,
});

export const stringifyNewBookingRequest = stringifiedBookingReq => ({
  type: STRINGIFY_NEW_BOOKING_REQUEST,
  stringifiedBookingReq,
});
