import React from 'react';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';
import ProductIcon from 'sharedWebpack/ProductIcon';
import StaticDefaultImage from 'agentSyncedImages/fallback_images/product/product_xxl_static.png';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

const StaticLineItem = props => (
  <div className="deal__static-product">
    {props.fetchingStaticProducts ? (
      <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} />
    ) : (
      <React.Fragment>
        <div className="col-grid col-bleed direction-row align-center justify-space-between">
          <ProductIcon
            productType={props.productType}
            showBGColor={true}
            color="tertiary"
            size="medium"
          />
        </div>
        <div className="col-12 col-grid col-bleed direction-row align-start deal__static-product-details">
          <div className="deal__static-product-details-image">
            <img src={props.pictureUrl || StaticDefaultImage} alt={props.name} />
          </div>
          <div className="col-bleed deal__static-product-info">
            <div>
              <div className="deal__static-product-name">{props.name}</div>
            </div>
            <div className="deal__static-product-price">
              <div className="deal__static-product-price--total">
                <Amount
                  currency={props.currency}
                  value={parseFloat(props.price) + parseFloat(props.vatAmount)}
                />
              </div>
              <div className="deal__static-product-price--vat">
                {I18n.t('components.ibe.price.including_vat', {
                  vat: props.vatPercentage,
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )}
  </div>
);

StaticLineItem.defaultProps = {
  pictureUrl: null,
  fetchingStaticProducts: false,
};

StaticLineItem.propTypes = {
  name: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
  vatPercentage: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  vatAmount: PropTypes.string.isRequired,
  fetchingStaticProducts: PropTypes.bool,
  item: PropTypes.shape({
    quantity: PropTypes.string.isRequired,
  }).isRequired,
};

export default StaticLineItem;
