import { flightSetDefaultSearchParams } from '../../actions/flight/setDefaultSearchParams';
import { carSetDefaultSearchParams } from '../../actions/car/setDefaultSearchParams';
import { hotelSetDefaultSearchParams } from '../../actions/hotel/setDefaultSearchParams';

export function setDefaultSearchParams(type, params) {
  let valueToReturn;
  switch (type) {
    case 'flight':
      valueToReturn = flightSetDefaultSearchParams(params);
      break;
    case 'car':
      valueToReturn = carSetDefaultSearchParams(params);
      break;
    case 'hotel':
      valueToReturn = hotelSetDefaultSearchParams(params);
      break;
    default:
      valueToReturn = null;
  }
  return valueToReturn;
}

export default setDefaultSearchParams;
