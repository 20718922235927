import snakeCaseKeys from 'snakecase-keys';

export function form2api(formParams, callbackParams) {
  const searchParams = {
    trips: formParams.trips.map(trip => ({
      origin: trip.origin,
      destination: trip.destination,
      date: trip.date,
      departure_time: trip.departureTime === 'anytime' ? null : trip.departureTime,
      arrival_time: trip.arrivalTime === '' ? null : trip.arrivalTime,
      cabin_class: trip.cabinClass,
      booking_class: trip.bookingClass,
      alliance_preferences: trip.alliancePreferences,
    })),
    adults: formParams.adults,
    children: formParams.children,
    infants: formParams.infants,
    currency: formParams.currency,
    accounts: formParams.accountSettings,
    flexible: formParams.flexible,
    itinerary_search: formParams.itinerarySearch,
    non_stop: formParams.nonStop,
    only_flexible_flight: formParams.onlyFlexibleFlight,
    only_bag_included_flight: formParams.onlyBagIncludedFlight,
    exclude_codeshare_flights: formParams.excludeCodeShareFlight,
    booking_id: formParams.bookingId,
    callback_params: callbackParams,
    preselect_travelers: formParams.preselectTravelers,
    include_welltravel_results: formParams.includeWelltravelResults,
  };
  if (
    formParams.airlinePreferences &&
    formParams.airlinePreferences.carriers &&
    formParams.airlinePreferences.carriers.length
  ) {
    searchParams.airline_preferences = {
      carriers: formParams.airlinePreferences.carriers.map(carrier => carrier.value),
      type: formParams.airlinePreferences.type,
    };
  }

  return searchParams;
}

export function api2form(apiParams) {
  const { adults, children, infants } = apiParams;
  const totalTravelerCount = adults + children + infants;

  const newParams = {
    ...apiParams,
    trips: apiParams.trips.map(trip => ({
      ...trip,
      departureTime: trip.departureTime ? trip.departureTime : 'anytime',
      identifier: trip.id,
    })),
    totalTravelerCount,
  };
  newParams.excludeCodeShareFlight = apiParams.excludeCodeshareFlights;
  delete newParams.excludeCodeshareFlights;
  delete newParams.includeDuplicates;

  if (newParams.accounts) {
    newParams.accountSettings = snakeCaseKeys(newParams.accounts);
    delete newParams.accounts;
  }

  return newParams;
}
