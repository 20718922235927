import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';
import { Link, PercentageMarker } from '@wtag/react-comp-lib';
import DateTime from 'sharedWebpack/DateTime';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

const AccountingInformation = ({
  accounting,
  fetchAccountings,
  orderId,
  scope,
  actionsEnabled,
}) => {
  const onDeleteAccounting = async accountingId => {
    const { data } = await httpClient.delete(
      routes[scope].orderAccountings.delete({ orderId, accountingId }),
    );
    if (data.error === null) {
      fetchAccountings();
    }
  };

  return (
    <div className="order-accounting-tab__cost-centers">
      <Card version="v2" key={accounting.id}>
        <div className="col-grid col-bleed direction-row align-center order-accounting-tab__header">
          <div className="order-accounting-tab__header-title">
            <div className="order-accounting-tab__allocated-percentage">
              {I18n.t('admin.components.orders.accounting_tab.allocated_percentage', {
                percentage: accounting.allocatedPercentage,
              })}
            </div>
            {accounting.accountingValue ? (
              <div className="order-accounting-tab__value--tertiary">
                {I18n.t('admin.components.orders.accounting_tab.value', {
                  value: accounting.accountingValue,
                })}
              </div>
            ) : (
              <div className="order-accounting-tab__value--warning">
                {I18n.t('admin.components.orders.accounting_tab.no_value')}
              </div>
            )}
          </div>
          {actionsEnabled && (
            <div className="order-accounting-tab__header-action">
              <Link
                labelHint={I18n.t('admin.shared.action.edit')}
                type="icon-button"
                modifier="tertiary"
                href={accounting.urls.edit}
              >
                <Icon name="edit" size="normal" />
              </Link>
              <Link
                labelHint={I18n.t('admin.shared.action.destroy')}
                type="icon-button"
                modifier="tertiary"
                onClick={() => onDeleteAccounting(accounting.id)}
              >
                <Icon name="delete" size="normal" />
              </Link>
            </div>
          )}
        </div>
        <div className="col-12 col-bleed align-center order-accounting-tab__budgets">
          <div className="grid grid-gap-20">
            <div className="col-md-4 col-grid">
              <span className="order-accounting-tab__budget-title">
                {accounting.budget ? (
                  <span>
                    <DateTime dateTime={accounting.budget.startDate} format="short" />
                    {' - '}
                    <DateTime dateTime={accounting.budget.endDate} format="short" />
                  </span>
                ) : (
                  <span className="order-accounting-tab__unavailable-text">
                    {I18n.t('admin.components.orders.accounting_tab.not_available')}
                  </span>
                )}
              </span>
              <span className="order-accounting-tab__subtitle">
                {I18n.t('admin.components.orders.accounting_tab.active_budget')}
              </span>
              {accounting.budget && (
                <div className="col-grid col-bleed-x direction-row align-center">
                  <span>
                    <PercentageMarker value="100" />
                  </span>
                  <div className="col-grid col-bleed-y">
                    {accounting.budget.currency} {accounting.budget.amount}
                    <span className="order-accounting-tab__subtitle">
                      {I18n.t('admin.components.orders.accounting_tab.total_amount')}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4 col-grid">
              {accounting.budget ? (
                <span>
                  <DateTime dateTime={accounting.budget.startDate} format="short" />
                  {' - '}
                  <DateTime dateTime={accounting.budget.endDate} format="short" />
                </span>
              ) : (
                <span className="order-accounting-tab__unavailable-text">
                  {I18n.t('admin.components.orders.accounting_tab.not_available')}
                </span>
              )}
              <span className="order-accounting-tab__subtitle">
                {I18n.t('admin.components.orders.accounting_tab.validity')}
              </span>
              {accounting.budget && (
                <div className="col-grid col-bleed-x direction-row align-center">
                  <span>
                    <PercentageMarker value={accounting.budget.availableAmountPercentage} />
                  </span>
                  <div className="col-grid col-bleed-y">
                    {accounting.budget.currency} {accounting.budget.availableAmount}
                    <span className="order-accounting-tab__subtitle">
                      {I18n.t('admin.components.orders.accounting_tab.available_now')}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-4 col-grid">
              <span>
                {accounting.costcenterableType
                  ? I18n.t(accounting.costcenterableType.toLowerCase(), {
                      scope: 'admin.components.orders.accounting_tab.association_types',
                    })
                  : I18n.t('admin.components.orders.accounting_tab.not_available')}
              </span>
              <span className="order-accounting-tab__subtitle">
                {I18n.t('admin.components.orders.accounting_tab.association_type')}
              </span>
              {accounting.budget && (
                <div className="col-grid col-bleed-x direction-row align-center">
                  <span>
                    <PercentageMarker value={accounting.budget.usedAmountPercentage} />
                  </span>
                  <div className="col-grid col-bleed-y">
                    {accounting.budget.currency} {accounting.budget.usedAmount}
                    <span className="order-accounting-tab__subtitle">
                      {I18n.t('admin.components.orders.accounting_tab.utilized_amount')}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

AccountingInformation.defaultProps = {
  accounting: {
    budget: {},
  },
  scope: 'admin',
  actionsEnabled: true,
};

AccountingInformation.propTypes = {
  orderId: PropTypes.number.isRequired,
  accounting: PropTypes.shape({
    id: PropTypes.string.isRequired,
    allocatedPercentage: PropTypes.number.isRequired,
    costcenterableType: PropTypes.string.isRequired,
    accountingValue: PropTypes.string,
    urls: PropTypes.shape({
      edit: PropTypes.string,
      delete: PropTypes.string,
    }),
    budget: PropTypes.shape({
      amount: PropTypes.string,
      availableAmount: PropTypes.string,
      availableAmountPercentage: PropTypes.number,
      currency: PropTypes.string,
      name: PropTypes.string,
      usedAmount: PropTypes.string,
      usedAmountPercentage: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }),
  fetchAccountings: PropTypes.func.isRequired,
  scope: PropTypes.string,
  actionsEnabled: PropTypes.bool,
};

export default AccountingInformation;
