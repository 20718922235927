import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';

const OrderFilterLabel = ({label}) => (
    <div className="orders-list__filters-label">
      <Icon name="person" size="small" className="orders-list__filters-label-icon" />
      <span>{label}</span>
    </div>
  );

OrderFilterLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default OrderFilterLabel;
