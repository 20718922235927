import React from 'react';
import PropTypes from 'prop-types';
import AirportDetails from './AirportDetails/AirportStandardDetails';
import FlightDurationAndStopOvers from './FlightDurationAndStopOversStandard/FlightDurationAndStopOversStandard';

const FlightDetails = ({
  setSidePanelBody,
  setSidePanelTitle,
  setOpenSidePanel,
  group: {
    destinationTerminal,
    arrivalTime,
    destinationCode,
    tripDurationInMinutes,
    id,
    stopovers,
    segments,
    originTerminal,
    departureTime,
    originCode,
  },
}) => (
  <div className="grid grid-gap-12">
    <div className="col-4 flight-stop-over-start">
      <AirportDetails code={originCode} localTime={departureTime} terminal={originTerminal} />
    </div>
    <div className="col-4 flight-airport-detail">
      <FlightDurationAndStopOvers
        id={id}
        segments={segments}
        stopovers={stopovers}
        durationInMinutes={tripDurationInMinutes}
        setSidePanelBody={setSidePanelBody}
        setSidePanelTitle={setSidePanelTitle}
        setOpenSidePanel={setOpenSidePanel}
      />
    </div>
    <div className="col-4 flight-stop-over-end">
      <AirportDetails
        code={destinationCode}
        localTime={arrivalTime}
        terminal={destinationTerminal}
      />
    </div>
  </div>
);

FlightDetails.defaultProps = {
  setSidePanelBody: () => {},
  setSidePanelTitle: () => {},
  setOpenSidePanel: () => {},
};

FlightDetails.propTypes = {
  setSidePanelBody: PropTypes.func,
  setSidePanelTitle: PropTypes.func,
  setOpenSidePanel: PropTypes.func,
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    originCode: PropTypes.string.isRequired,
    destinationCode: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    stopovers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    arrivalTime: PropTypes.string.isRequired,
    departureTime: PropTypes.string.isRequired,
    originTerminal: PropTypes.string.isRequired,
    destinationTerminal: PropTypes.string.isRequired,
    tripDurationInMinutes: PropTypes.string.isRequired,
  }).isRequired,
};

export default FlightDetails;
