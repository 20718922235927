import { UPDATE_PRIMARY_BUTTON_TEXT } from '../actions';
import { NEXT_BUTTON_LABEL_KEY } from '../helpers/authenticationButtonLabelOptions';

export default function primaryButtonText(state = NEXT_BUTTON_LABEL_KEY, action) {
  switch (action.type) {
    case UPDATE_PRIMARY_BUTTON_TEXT:
      return action.label;

    default:
      return state;
  }
}
