import { COST_CENTERS_FETCHED } from '../../actions/common';

export default function costCenters(state = [], action) {
  switch (action.type) {
    case COST_CENTERS_FETCHED:
      return action.costCenters;

    default:
      return state;
  }
}
