import { NATIONALITIES_FETCHED } from '../../actions/common/nationalities';

function nationalities(state = [], action) {
  switch (action.type) {
    case NATIONALITIES_FETCHED:
      return action.data;

    default:
      return state;
  }
}

export default nationalities;
