import React, { useEffect, useState } from 'react';
import httpClient from 'agentHTTPClient';
import Icon from '@wtag/rcl-icon';
import routes from 'agentRoutes';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import DealsCard from '../../Deals/DealsCard';

const MidSectionContent = () => {
  const [mostPopularDeal, setMostPopularDeal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMostPopularDeal = async () => {
    setIsLoading(true);
    const { data } = await httpClient.get(routes.public.mostPopularDeal());
    setMostPopularDeal(data);
    setIsLoading(false);
  };

  const dealShowPageUrl = id => {
    const url = routes.public.deal;
    return url({
      id,
    });
  };

  const addToFavourite = async id => {
    setIsLoading(true);
    const { data } = await httpClient.put(routes.api.deals.favourite({ dealId: id }));

    if (data.id) {
      fetchMostPopularDeal();
    }
  };

  const deleteFromFavourite = async id => {
    setIsLoading(true);
    const { data } = await httpClient.delete(routes.api.deals.favourite({ dealId: id }));

    if (data.id) {
      fetchMostPopularDeal();
    }
  };

  let dealsCard = (
    <div className="col-12 col-bleed mid-section-content__deal-empty-card">
      <Card
        version="v2"
        emptyCardImageSrc={<Icon name="add" />}
        emptyCardText={I18n.t('public.components.mid_section.contents.no_deals')}
      />
    </div>
  );

  if (isLoading && !mostPopularDeal) {
    dealsCard = <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />;
  } else if (mostPopularDeal && mostPopularDeal.id) {
    dealsCard = (
      <DealsCard
        key={mostPopularDeal.id}
        deleteFromFavourite={value => deleteFromFavourite(value)}
        id={mostPopularDeal.id}
        name={mostPopularDeal.name}
        amount={mostPopularDeal.adAmount}
        currency={mostPopularDeal.currency}
        imageUrl={mostPopularDeal.imageUrl}
        detailsUrl={dealShowPageUrl(mostPopularDeal.id)}
        addToFavourite={value => addToFavourite(value)}
        favourite={mostPopularDeal.favourite}
        teaser={mostPopularDeal.teaser}
        itemTypes={mostPopularDeal.itemTypes}
        fullWidth={true}
        currentUserId={mostPopularDeal.currentUserId}
      />
    );
  }

  useEffect(() => {
    fetchMostPopularDeal();
  }, []);

  return (
    <div className="col-grid col-bleed mid-section-content">
      <div className="col-12 col-bleed mid-section-content__header">
        {I18n.t('public.components.mid_section.contents.deal_of_the_day')}
      </div>

      <div className="col-12 col-bleed mid-section-content__deal">{dealsCard}</div>

      <div className="col-12 col-bleed mid-section-content__destination">
        <div className="col-12 col-bleed mid-section-content__destination-header">
          {I18n.t('public.components.mid_section.contents.destination_of_the_month')}
        </div>
        <div className="col-12 col-bleed mid-section-content__empty-card">
          <Card
            version="v2"
            emptyCardText={I18n.t('public.components.mid_section.contents.coming_soon')}
          />
        </div>
      </div>
    </div>
  );
};

export default MidSectionContent;
