import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'sharedWebpack/TimeAgo';
import Icon from '@wtag/rcl-icon';
import { Table, TableBody, TableData, TableHeader, TableHead, TableRow } from '@wtag/rcl-table';
import { I18nText, Link, EmphasisTag } from '@wtag/react-comp-lib';
import EmptyTableContent from 'sharedWebpack/EmptyTableContent';
import TablePlaceHolder from 'sharedWebpack/Placeholder/TablePlaceholder';

const DocumentsTable = ({ documents, layman, type, deleteAction, isLoading }) =>
  !isLoading && !documents.length ? (
    <EmptyTableContent />
  ) : (
    <Table fullWidth={true}>
      <TableHead>
        <TableRow>
          <TableHeader>{I18n.t('activerecord.attributes.attachment.title')}</TableHeader>
          {!layman && (
            <Fragment>
              <TableHeader>
                {I18n.t('activerecord.attributes.attachment.attachment_type')}
              </TableHeader>
              <TableHeader>
                {I18n.t('activerecord.attributes.attachment.visible_to_travellers')}
              </TableHeader>
            </Fragment>
          )}
          <TableHeader className="public-order-documents-tab__table-description-header">
            {I18n.t('activerecord.attributes.attachment.description')}
          </TableHeader>
          <TableHeader>{I18n.t('activerecord.attributes.attachment.uploaded')}</TableHeader>
          <TableHeader className="public-order-documents-tab__table-action-header" align="center">
            <I18nText id="shared.action" returnStringOnly={true} />
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <TablePlaceHolder
            numberOfColumn={layman ? 4 : 6}
            numberOfRows={10}
            withoutRclTable={true}
          />
        ) : (
          documents.map(doc => (
            <TableRow key={doc.id}>
              <TableData>{doc.title}</TableData>
              {!layman && (
                <Fragment>
                  <TableData>{doc.attachmentType}</TableData>
                  <TableData>
                    <EmphasisTag
                      type={doc.visible ? 'important' : 'disabled'}
                      text={I18n.t(doc.visible, {
                        scope: 'activerecord.attributes.attachment.visible',
                      })}
                      size="tiny"
                    />
                  </TableData>
                </Fragment>
              )}
              <TableData
                unavailableText={I18n.t('admin.components.shared.unavailable')}
                smallScreenConfig={{ order: 1, isFullWidth: true }}
              >
                {doc.description}
              </TableData>
              <TableData>
                <TimeAgo datetime={doc.createdAt} />
              </TableData>
              <TableData align="center">
                <Link
                  href={doc.urls.attachment}
                  type="icon-button"
                  labelHint={I18n.t('admin.shared.action.view_file')}
                  modifier="tertiary"
                  size="small"
                >
                  <Icon name="pdf" />
                </Link>
                {type === 'general' && (
                  <Fragment>
                    <Link
                      href={doc.urls.edit}
                      type="icon-button"
                      labelHint={I18n.t('admin.shared.action.edit')}
                      modifier="tertiary"
                      size="small"
                    >
                      <Icon name="edit" />
                    </Link>
                    <Link
                      onClick={() => deleteAction(doc.id)}
                      type="icon-button"
                      labelHint={I18n.t('admin.shared.action.destroy')}
                      modifier="tertiary"
                      size="small"
                    >
                      <Icon name="delete" />
                    </Link>
                  </Fragment>
                )}
              </TableData>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );

DocumentsTable.defaultProps = {
  documents: [],
  layman: false,
  type: 'general',
};

DocumentsTable.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      visible: PropTypes.bool,
      attachmentType: PropTypes.string,
      order: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
      urls: PropTypes.shape({
        edit: PropTypes.string,
        attachment: PropTypes.string,
      }).isRequired,
    }),
  ),
  layman: PropTypes.bool,
  type: PropTypes.string,
  deleteAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DocumentsTable;
