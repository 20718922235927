import snakeCaseKeys from 'snakecase-keys';

const preparePreselectTravelers = preselectTravelers =>
  preselectTravelers.map(traveler =>
    snakeCaseKeys(
      {
        ...traveler.traveler.value,
        type: traveler.type.value,
        residence_address: traveler.traveler.value.address,
      },
      { deep: true },
    ),
  );

export default preparePreselectTravelers;
