import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { intlShape, injectIntl } from 'react-intl';
import SelectBox from '@wtag/rcl-select-box';
import Input from '@wtag/rcl-input';
import DatePickerWithFocusedStateWrapper from 'sharedWebpack/DatePickerWithFocusedStateWrapper';

import documentTypesBuilder from '../../../../Search/flight/helpers/documentTypesBuilder';
import CountrySelector from '../../../../components/CountrySelector';
import NationalitySelectorContainer from '../../../NationalityContainer';
import findLabelValue from '../../../../helpers/findLabelValue';

const VALIDITY_OPTIONAL_DOCUMENT_TYPES = ['identity_card', 'alien_resident_card'];

class IdentificationDocument extends React.Component {
  constructor(props) {
    super(props);
    this.DOCUMENT_TYPES = documentTypesBuilder();
  }

  onDocumentTypeChange = type => {
    const { fields, selectedCustomer, onCustomerSelected, travelerIndex } = this.props;

    fields.type.onChange(type);

    const updatedSelectedCustomer = {
      ...selectedCustomer,

      document: {
        ...selectedCustomer.document,
        type,
      },
    };

    onCustomerSelected(travelerIndex, updatedSelectedCustomer);
  };

  onDocumentNumberChange = number => {
    const { fields, selectedCustomer, onCustomerSelected, travelerIndex } = this.props;

    fields.number.onChange(number);

    const updatedSelectedCustomer = {
      ...selectedCustomer,

      document: {
        ...selectedCustomer.document,
        number,
      },
    };

    onCustomerSelected(travelerIndex, updatedSelectedCustomer);
  };

  onDocumentValidityChange = validity => {
    const { fields, selectedCustomer, onCustomerSelected, travelerIndex } = this.props;

    fields.validity.onChange(validity);

    const updatedSelectedCustomer = {
      ...selectedCustomer,

      document: {
        ...selectedCustomer.document,
        validity,
      },
    };

    onCustomerSelected(travelerIndex, updatedSelectedCustomer);
  };

  onDocumentCountryChange = country => {
    const { fields, selectedCustomer, onCustomerSelected, travelerIndex } = this.props;

    fields.country.onChange(country);

    const updatedSelectedCustomer = {
      ...selectedCustomer,

      document: {
        ...selectedCustomer.document,
        country,
      },
    };

    onCustomerSelected(travelerIndex, updatedSelectedCustomer);
  };

  onDocumentNationalityChange = nationality => {
    const { fields, selectedCustomer, onCustomerSelected, travelerIndex } = this.props;

    fields.nationality.onChange(nationality);

    const updatedSelectedCustomer = {
      ...selectedCustomer,

      document: {
        ...selectedCustomer.document,
        nationality,
      },
    };

    onCustomerSelected(travelerIndex, updatedSelectedCustomer);
  };

  checkIfValidityRequired = type => {
    if (type.value) {
      return !VALIDITY_OPTIONAL_DOCUMENT_TYPES.includes(type.value);
    }

    return true;
  };

  render() {
    const {
      fields: { type, number, country, nationality, validity },
      disabled,
    } = this.props;

    return (
      <div className="col-12 col-bleed identification-document">
        <div className="identification-document__container">
          <div className="col-12 col-sm-6 col-md-4 col-bleed identification-document__container-field">
            <SelectBox
              {...type}
              options={this.DOCUMENT_TYPES}
              label={I18n.t('components.ibe.traveler_details.form.fields.document_type')}
              isDisabled={disabled}
              onChange={selectedOption =>
                selectedOption && this.onDocumentTypeChange(selectedOption.value)
              }
              errorMsg={type.error}
              size="tiny"
              width="full"
              required={true}
              placeholderText={I18n.t(
                'components.ibe.traveler_details.form.placeholders.document_type',
              )}
              isClearable={false}
              value={findLabelValue(this.DOCUMENT_TYPES, type.value)}
              onBlur={() => {}}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-bleed identification-document__container-field">
            <Input
              {...number}
              size="tiny"
              label={I18n.t('components.ibe.traveler_details.form.fields.number')}
              placeholder="E.g. F156465BN"
              touched={true}
              disabled={disabled}
              required={true}
              onBlur={() => {}}
              onChange={value => this.onDocumentNumberChange(value)}
            />
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-bleed identification-document__container-field">
            <DatePickerWithFocusedStateWrapper
              {...validity}
              label={I18n.t('components.ibe.traveler_details.form.fields.validity')}
              placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.validity')}
              size="tiny"
              fullWidth={true}
              selectMonths={true}
              id={Math.random()}
              futureYearsCount={100}
              pastYearsCount={0}
              minDate={moment()}
              date={validity.value}
              locale={moment().locale()}
              onChange={value =>
                value && this.onDocumentValidityChange(moment(value).format('YYYY-MM-DD'))
              }
              disabled={disabled}
              required={this.checkIfValidityRequired(type)}
              onBlur={() => {}}
              hideNavButtons={false}
            />
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-bleed identification-document__container-field">
            <CountrySelector
              {...country}
              title={I18n.t('components.ibe.traveler_details.form.fields.issuing_country')}
              placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.country')}
              required={true}
              isDisabled={disabled}
              touched={true}
              isClearable={false}
              onBlur={() => {}}
              onDocumentCountryChange={this.onDocumentCountryChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-bleed identification-document__container-field">
            <NationalitySelectorContainer
              {...nationality}
              title={I18n.t('components.ibe.traveler_details.form.fields.nationality')}
              placeholder={I18n.t('components.ibe.traveler_details.form.placeholders.nationality')}
              required={true}
              isDisabled={disabled}
              touched={true}
              isClearable={false}
              onBlur={() => {}}
              onDocumentNationalityChange={this.onDocumentNationalityChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

IdentificationDocument.defaultProps = {
  disabled: false,
  touched: false,
};

IdentificationDocument.propTypes = {
  intl: intlShape.isRequired, // eslint-disable-line react/no-typos
  fields: PropTypes.shape().isRequired,
  identificationDocumentsList: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  onCustomerSelected: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({ document: PropTypes.shape({}) }).isRequired,
  travelerIndex: PropTypes.number.isRequired,
};

export default injectIntl(IdentificationDocument);
