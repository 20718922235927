import React, { useState, useEffect, Fragment } from 'react';
import IconButton from '@wtag/rcl-icon-button';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import SelectBox from '@wtag/rcl-select-box';
import { CheckBox, EmphasisTag } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const EmailAddressCard = ({
  emailAddress,
  emailAddressTypeOptions,
  fetchRelatedInstance,
  relatedInstance,
  updateEmailAddress,
  deleteEmailAddress,
}) => {
  const [emailType, setEmailType] = useState(emailAddress.type);
  const [email, setEmail] = useState(emailAddress.email);
  const [primary, setPrimary] = useState(emailAddress.primary);
  const [editing, setEditing] = useState(false);
  const [showConfirmation, setShowConfirm] = useState(false);
  const [error, setError] = useState({});

  const editEmailAddress = () => {
    const params = {
      email_type: emailType,
      email,
      primary,
      id: emailAddress.id,
    };

    updateEmailAddress(params)
      .then(data => {
        if (data.error === null) {
          setEditing(false);
          fetchRelatedInstance();
        }
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setError(response.data.error);
        }
      });
  };

  const toggleShowConfirmation = () => {
    setShowConfirm(prev => !prev);
  };

  const onDeleteEmailAddress = () => {
    deleteEmailAddress(emailAddress.id).then(data => {
      if (data.error === null) {
        fetchRelatedInstance();
      }
    });
    toggleShowConfirmation();
  };

  const setInitialValues = () => {
    setEmailType(emailAddress.type);
    setEmail(emailAddress.email);
    setPrimary(emailAddress.primary);
    setError({});
    setEditing(false);
  };

  const renderEditForm = () => {
    let form;
    if (editing) {
      form = (
        <div className="grid col-bleed">
          <div className="col-md-6">
            <SelectBox
              label={I18n.t(
                'admin.components.organizations.edit.email_and_phone.email.label.email_type',
              )}
              options={emailAddressTypeOptions}
              isClearable={false}
              size="tiny"
              onChange={emailTypeSelected => setEmailType(emailTypeSelected.value)}
              width="full"
              value={{
                label: I18n.t(emailType, {
                  scope: 'email_addresses.types',
                }),
                value: emailType,
              }}
              errorMsg={error.emailType}
            />
          </div>
          <div className="col-md-6">
            <Input
              size="tiny"
              label={I18n.t(
                'admin.components.organizations.edit.email_and_phone.email.label.email_address',
              )}
              width="full"
              value={email}
              onChange={value => setEmail(value)}
              touched={!!error.email}
              error={error.email}
              required={true}
            />
          </div>
          <div className="col-12">
            <CheckBox
              label={I18n.t(
                'admin.components.organizations.edit.email_and_phone.email.label.primary',
              )}
              checked={primary}
              onChange={event => setPrimary(event.target.checked)}
            />
          </div>

          <div className="edit-section__btn-group-update">
            <Button
              version="v2"
              size="small"
              label={I18n.t('admin.components.shared.action.update')}
              type="primary"
              onClick={editEmailAddress}
            />
            <Button
              version="v2"
              size="small"
              label={I18n.t('admin.components.shared.action.cancel')}
              type="default"
              onClick={setInitialValues}
            />
          </div>
        </div>
      );
    }
    return form;
  };

  useEffect(() => {
    setInitialValues();
  }, [relatedInstance]);

  return (
    <div className="col-12 edit-email-and-phone">
      <div
        className={classNames('grid align-center justify-space-between', {
          'edit-email-and-phone__shadow': !editing,
        })}
      >
        {editing ? (
          <div className="col-6 edit-section__sub-header edit-email-and-phone__confirm-update">
            {I18n.t('admin.components.organizations.edit.email_and_phone.email.edit')}
          </div>
        ) : (
          <div className="col-9 col-bleed direction-row">
            <EmphasisTag
              text={I18n.t(emailType, {
                scope: 'email_addresses.types',
              })}
              size="tiny"
              className="edit-email-and-phone__tag"
            />
            {primary && (
              <EmphasisTag
                text="primary"
                size="tiny"
                type="important"
                className="edit-email-and-phone__tag"
              />
            )}
            <div className="edit-email-and-phone__value">{email}</div>
          </div>
        )}
        <div className="col-3 col-bleed edit-email-and-phone__actions">
          {!editing && (
            <Fragment>
              <IconButton
                icon={<Icon name="edit" />}
                color="tertiary"
                size="small"
                onClick={() => setEditing(true)}
              />
              <IconButton
                icon={<Icon name="delete" />}
                color="tertiary"
                size="small"
                disabled={primary}
                onClick={toggleShowConfirmation}
              />
            </Fragment>
          )}
        </div>
      </div>
      {renderEditForm()}

      {showConfirmation && (
        <ConfirmationModal
          confirmationHeader={I18n.t(
            'admin.components.organizations.edit.email_and_phone.email.delete_modal.header',
          )}
          subHeader={I18n.t(
            'admin.components.organizations.edit.email_and_phone.email.delete_modal.sub_header',
          )}
          isModalOpen={true}
          withAction={true}
          type="danger"
          confirmationText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.positive',
          )}
          rejectionText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.negative',
          )}
          onConfirm={onDeleteEmailAddress}
          onReject={toggleShowConfirmation}
        />
      )}
    </div>
  );
};

EmailAddressCard.propTypes = {
  emailAddress: PropTypes.shape({
    email: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
    primary: PropTypes.bool,
  }).isRequired,
  fetchRelatedInstance: PropTypes.func.isRequired,
  updateEmailAddress: PropTypes.func.isRequired,
  deleteEmailAddress: PropTypes.func.isRequired,
  emailAddressTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  relatedInstance: PropTypes.shape({
    id: PropTypes.number,
    emailAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        type: PropTypes.string,
        id: PropTypes.number,
        primary: PropTypes.bool,
      }),
    ).isRequired,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        countryCode: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.number,
        numberWithoutCountryCode: PropTypes.string,
        type: PropTypes.string,
        primary: PropTypes.bool,
      }),
    ).isRequired,
  }).isRequired,
};

export default EmailAddressCard;
