const getInvalidNameAttribute = nameDetails => {
  if (!nameDetails || Object.keys(nameDetails).length === 0) {
    return null;
  }

  const { namePrefix, firstName, lastName } = nameDetails;

  if (!firstName.value) {
    return 'first_name';
  }

  if (!lastName.value) {
    return 'last_name';
  }

  if (!namePrefix.value) {
    return 'title';
  }

  return null;
};

export default getInvalidNameAttribute;
