const cartOverride = adults => {
  let driverAge;
  if (typeof adults !== 'undefined' && Number.isNaN(parseFloat(adults))) {
    driverAge = adults[0].age;

    if (typeof driverAge !== 'undefined') {
      return { driverAge };
    }
  }

  return {};
};

export default cartOverride;
