import { connect } from 'react-redux';

import { fetchDeal, markFavouriteDeal, unmarkFavouriteDeal } from '../actions/common';

import fetchOnUpdate from '../lib/decorators/fetchOnUpdate';
import MultiSearch from '../ApprovalRequest/MultiSearchContainer';

const mapStateToProps = state => ({
  id: state.common.deal.id,
  title: state.common.deal.name,
  text: state.common.deal.teaser,
  groups: state.common.deal.groups,
  disableForm: false,
  continuePossible: true,
});

const mapDispatchToProps = dispatch => ({
  fetchDeal: id => dispatch(fetchDeal(id)),
  markFavouriteDeal: id => dispatch(markFavouriteDeal(id)),
  unmarkFavouriteDeal: id => dispatch(unmarkFavouriteDeal(id)),
});

const Deal = fetchOnUpdate(['id'], (params, props) => {
  if (props.id !== params.id) {
    props.fetchDeal(params.id);
  }
})(MultiSearch);

export default connect(mapStateToProps, mapDispatchToProps)(Deal);
