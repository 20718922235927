import { FEES_FETCHED } from '../../actions/common';

export default function fees(state = [], action) {
  switch (action.type) {
    case FEES_FETCHED:
      return action.fees;

    default:
      return state;
  }
}
