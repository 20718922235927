import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import { I18nText } from '@wtag/react-comp-lib';
import Alert from 'sharedWebpack/Alert';
import feeList from '../lib/feeList';
import CartItemWrapper from './Items/CartItemWrapper';
import Summary from './Summary';
import CartShare from './CartShare';
import CartDeal from './CartDeal';
import AddButtons from './Items/AddButtons';
import PaymentError from './PaymentMessage/PaymentError';
import EmptyCart from './Items/EmptyCart';
import staticProductShape from '../Search/static/shapes/staticProduct';
import CheckoutProgress from '../components/CheckoutProgress';
import { stringifyQueryString, parseQueryString } from '../helpers/qsMethods';
import history from '../lib/history';
import {
  CART_STATUS_BOOKED,
  CART_STATUS_PAID,
  CART_STATUS_OPEN,
  CART_STATUS_LOCKED,
} from '../actions/common';

import './styles.scss';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldLockScroll: false,
    };
    this.locationQuery = parseQueryString(props.location.search);
    this.orderSummaryRef = createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (!this.props.fees.length) {
      this.props.fetchFees(this.context.callbackParams);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.locationQuery = parseQueryString(this.props.location.search);
    }
  }

  setFirstConfirmableItem = node => {
    if (node) {
      this.firstConfirmableItem = node;
    }
  };

  cartSharingPossible = () => {
    if (this.context.iframeMode) {
      return false;
    }

    if (this.props.expiredAt) {
      return false;
    }

    return true;
  };

  checkoutProgressAttributes = () => {
    const conditionToDisableNextButton =
      !this.props.allItemsAvailable ||
      this.props.items.length <= 0 ||
      this.props.cartState !== CART_STATUS_OPEN ||
      this.props.cartState === CART_STATUS_LOCKED ||
      (!this.props.continuePossible && this.props.canBookQuoteItems) ||
      this.props.hasItemsWithPendingConfirmation ||
      !!this.props.expiredAt;

    const attributes = {
      cartId: this.props.match.params.id,
      currentStep: 'cart',
      cartError: this.props.flightServicesFetched && !this.props.allItemsAvailable,
      nextButtonDisabled: conditionToDisableNextButton,
      totalCartItems: this.props.totalCartItems,
      orderSummaryRef: this.orderSummaryRef,
    };

    if (this.props.forceDeal) {
      attributes.nextButton = (
        <Button
          version="v2"
          size="small"
          label={I18n.t('components.ibe.create_deal')}
          disabled={true}
        />
      );
    }

    if (this.context.offerOnly) {
      attributes.nextButton = (
        <CartDeal
          dealName={this.props.dealName}
          dealTeaser={this.props.dealTeaser}
          setDealName={this.props.setDealName}
          setDealTeaser={this.props.setDealTeaser}
          dealSaveAvailable={this.props.dealSaveAvailable}
          saveDeal={this.props.saveDeal}
          forceDeal={this.props.forceDeal}
        />
      );
    }
    return attributes;
  };

  usedCart = () => (
    <div className="cart-used">
      <Card version="v2">{I18n.t('components.ibe.cart_used')}</Card>
    </div>
  );

  duplicateItemOfSameSearchExist = () => {
    if (this.props.items.length !== 0) {
      const searchIds = this.props.items.map(item => item.search.id);
      return new Set(searchIds).size !== searchIds.length;
    }
    return false;
  };

  viewAlertTextForSharedCart = sharedByCurrentPerson => {
    if (sharedByCurrentPerson) {
      return I18n.t('cart.cart_share.layman_mode.cart_shared_alert.owner');
    }

    return I18n.t('cart.cart_share.layman_mode.cart_shared_alert.non_owner');
  };

  render() {
    const fromSharedCarts = this.locationQuery.fromSharedCarts;
    const isCartBooked =
      this.props.cartState === CART_STATUS_BOOKED || this.props.cartState === CART_STATUS_PAID;
    const isCartLocked = this.props.cartState === CART_STATUS_LOCKED;

    if (isCartBooked && !!fromSharedCarts) {
      history.push({
        pathname: `/orders/${this.props.cartId}/booking`,
        search: stringifyQueryString({ fromSharedCarts }),
      });
    }

    if (this.props.cartState !== CART_STATUS_OPEN && this.props.cartState !== CART_STATUS_LOCKED)
      return this.usedCart();

    return (
      <div>
        <CheckoutProgress {...this.checkoutProgressAttributes()} />
        <div className="container container--sm-full-width cart">
          <Alert
            type="warning-light"
            isIconVisible={false}
            hideClose={true}
            className="cart-items__card-container-alert"
            isVisible={isCartLocked && this.props.isShared && !this.props.expiredAt}
          >
            {I18n.t('components.ibe.cart_locked')}
          </Alert>
          <Alert
            type="warning-light"
            isIconVisible={false}
            hideClose={true}
            className="cart-items__card-container-alert"
            isVisible={
              this.context.laymanMode &&
              this.props.cartState !== CART_STATUS_LOCKED &&
              this.props.isShared &&
              !this.props.expiredAt &&
              !!fromSharedCarts
            }
          >
            {this.viewAlertTextForSharedCart(this.props.sharedByCurrentPerson)}
          </Alert>
          <Alert
            type="danger-light"
            isIconVisible={false}
            hideClose={true}
            className="cart-items__card-container-alert"
            isVisible={!!this.props.expiredAt}
          >
            {I18n.t('cart.cart_share.layman_mode.cart_expired_alert', {
              date: I18n.l('date.formats.date_month_year_short', this.props.expiredAt),
            })}
          </Alert>
          {this.locationQuery.paymentError && (
            <PaymentError messageKey={this.locationQuery.paymentError} />
          )}
          <div className="grid grid-gap-20 cart-items__card-container">
            <div className="col-lg-8 col-grid cart-items__card-container">
              {this.props.hasItemsWithPendingConfirmation && (
                <Alert
                  className="cart-items__card-container-alert"
                  hideClose={true}
                  type="warning-light"
                  isVisible={true}
                >
                  <div className="col-grid direction-row col-bleed">
                    <I18nText id="ibe.automatic_products.added" />
                    <Button
                      className="cart-items__card-container-alert-button"
                      version="v2"
                      size="small"
                      onClick={() => {
                        this.firstConfirmableItem.scrollIntoView({ behavior: 'smooth' });
                      }}
                      label={
                        <I18nText id="ibe.automatic_products.review" returnStringOnly={true} />
                      }
                    />
                  </div>
                </Alert>
              )}
              {this.duplicateItemOfSameSearchExist() && this.props.canBookQuoteItems && (
                <div className="col-12 col-grid col-bleed cart-items__card-container-alert">
                  <Alert type="warning-light" hideClose={true} isVisible={true}>
                    <I18nText
                      id="ibe.book_quote.only_one_item_per_search"
                      returnStringOnly={true}
                    />
                  </Alert>
                </div>
              )}

              {this.props.items.length ? (
                this.props.items.map((item, index) => (
                  <div
                    ref={
                      item.pendingConfirmation && !this.firstConfirmableItem
                        ? this.setFirstConfirmableItem
                        : undefined
                    }
                    key={item.id}
                  >
                    <CartItemWrapper
                      isCartLocked={isCartLocked}
                      item={item}
                      itemTitle={this.props.title}
                      itemNo={index + 1}
                      journeyElement={item.journeyElement}
                      flightResultDetailsById={this.props.flightResultDetailsById}
                      flightServicesByResultId={this.props.flightServicesByResultId}
                      showFlightSeatsStatus={true}
                      showServices={this.props.showServices}
                      removeFromCart={this.props.removeFromCart}
                      confirmAddition={this.props.confirmAddition}
                      travelerCount={this.props.travelerCount}
                      staticProducts={this.props.staticProducts}
                      setStaticProductQuantity={this.props.setQuantity}
                      setOutOfPolicyJustification={this.props.setOutOfPolicyJustification}
                      shouldLockScroll={this.state.shouldLockScroll}
                      setShouldLockScroll={state => this.setState({ shouldLockScroll: state })}
                      travelers={this.props.travelers}
                    />
                  </div>
                ))
              ) : (
                <EmptyCart />
              )}
              <AddButtons
                isCartLocked={isCartLocked}
                hasStaticProducts={this.props.hasStaticProducts}
              />
            </div>
            <div ref={this.orderSummaryRef} className="col-lg-4 col-grid row-gap-20">
              <Card className="cart-side-nav" version="v2">
                <Summary
                  items={this.props.items}
                  staticProducts={this.props.staticProducts}
                  total={this.props.total}
                  taxes={this.props.taxes}
                  voucher={this.props.voucher}
                  travelerCount={this.props.travelerCount}
                  fees={feeList(
                    this.props.fees,
                    this.props.cartFees,
                    this.props.intl,
                    !this.context.laymanMode,
                    I18n.t('components.ibe.summary.additional_taxes_services_and_charges'),
                  )}
                  currency={this.props.currency}
                  paymentMethodSurcharge={this.props.paymentMethodSurcharge}
                  grossTotalsByGroup={this.props.grossTotalsByGroup}
                  isFetchingCart={false}
                  isFetchingFop={false}
                  totalWithoutVoucherReduction={this.props.totalWithoutVoucherReduction}
                />
              </Card>
              {this.cartSharingPossible() && this.props.items.length > 0 && (
                <Card version="v2">
                  <CartShare
                    items={this.props.items}
                    journeyElements={this.props.journeyElements}
                    flightServicesByResultId={this.props.flightServicesByResultId}
                    flightResultDetailsById={this.props.flightResultDetailsById}
                    travelers={this.props.travelers}
                    cartId={this.props.cartId}
                    cartUrl={this.props.cartUrl}
                    title={this.props.title}
                    sharedTitle={this.props.sharedTitle}
                    callbackParams={this.context.callbackParams}
                  />
                </Card>
              )}
              {!this.context.laymanMode && !this.context.offerOnly && (
                <Card version="v2" shadow="dark">
                  <CartDeal
                    dealName={this.props.dealName}
                    dealTeaser={this.props.dealTeaser}
                    setDealName={this.props.setDealName}
                    setDealTeaser={this.props.setDealTeaser}
                    dealSaveAvailable={this.props.dealSaveAvailable}
                    hasAutomaticallyAddedStaticProducts={
                      this.props.hasAutomaticallyAddedStaticProducts
                    }
                    saveDeal={this.props.saveDeal}
                    forceDeal={this.props.forceDeal}
                    items={this.props.items}
                  />
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Cart.defaultProps = {
  canBookQuoteItems: false,
  expiredAt: null,
  flightResultDetailsById: {},
  staticProducts: [],
  cartState: null,
  title: '',
  sharedTitle: '',
  fareBasisInfo: [],
  showServices: true,
  paymentMethodSurcharge: null,
  totalCartItems: 0,
};

Cart.propTypes = {
  journeyElements: PropTypes.shape({
    flight: PropTypes.shape({}),
    hotel: PropTypes.shape({}),
    car: PropTypes.shape({}),
  }).isRequired,
  showServices: PropTypes.bool,
  canBookQuoteItems: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  totalCartItems: PropTypes.number,
  expiredAt: PropTypes.string,
  frozen: PropTypes.bool.isRequired,
  items: PropTypes.shape([]).isRequired,
  isShared: PropTypes.bool.isRequired,
  sharedByCurrentPerson: PropTypes.bool.isRequired,
  flightServicesByResultId: PropTypes.shape([]).isRequired,
  voucher: PropTypes.shape().isRequired,
  intl: intlShape.isRequired, // eslint-disable-line react/no-typos
  taxes: PropTypes.shape([]).isRequired,
  fees: PropTypes.shape([]).isRequired,
  cartFees: PropTypes.shape([]).isRequired,
  total: PropTypes.string.isRequired,
  title: PropTypes.string,
  sharedTitle: PropTypes.string,
  currency: PropTypes.string.isRequired,
  grossTotalsByGroup: PropTypes.string.isRequired,
  isFetchingFop: PropTypes.string.isRequired,
  dealName: PropTypes.string.isRequired,
  dealTeaser: PropTypes.string.isRequired,
  setDealName: PropTypes.func.isRequired,
  fetchFees: PropTypes.func.isRequired,
  setDealTeaser: PropTypes.func.isRequired,
  dealSaveAvailable: PropTypes.bool.isRequired,
  saveDeal: PropTypes.func.isRequired,
  saveApprovalRequest: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  forceDeal: PropTypes.bool.isRequired,
  cartId: PropTypes.string.isRequired,
  cartUrl: PropTypes.string.isRequired,
  forceApprovalRequest: PropTypes.bool.isRequired,
  continuePossible: PropTypes.bool.isRequired,
  addStaticProductToCart: PropTypes.func.isRequired,
  allItemsAvailable: PropTypes.bool.isRequired,
  travelerCount: PropTypes.objectOf(
    PropTypes.shape({
      adults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      infants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ).isRequired,
  flightResultDetailsById: PropTypes.objectOf(
    PropTypes.shape({
      available: PropTypes.oneOf([true, false, null]),
    }),
  ),
  fetchStaticProducts: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
  staticProducts: PropTypes.arrayOf(staticProductShape),
  cartText: PropTypes.string.isRequired,
  setCartText: PropTypes.func.isRequired,
  cartState: PropTypes.oneOf(['open', 'paid', 'booked']),
  hasStaticProducts: PropTypes.bool.isRequired,
  flightServicesFetched: PropTypes.bool.isRequired,
  fareBasisInfo: PropTypes.arrayOf(PropTypes.shape({})),
  hasItemsWithPendingConfirmation: PropTypes.bool.isRequired,
  hasAutomaticallyAddedStaticProducts: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  paymentMethodSurcharge: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.string,
    currency: PropTypes.string,
  }),
  confirmAddition: PropTypes.func.isRequired,
  setOutOfPolicyJustification: PropTypes.func.isRequired,
  totalWithoutVoucherReduction: PropTypes.string.isRequired,
  travelers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Cart.contextTypes = {
  allowBookingOfUnapprovedItems: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  iframeMode: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  offerOnly: PropTypes.bool.isRequired,
  travelerHasOrganization: PropTypes.bool.isRequired,
  frozen: PropTypes.bool.isRequired,
};

export default Cart;
