import React from 'react';
import PropTypes from 'prop-types';
import { Link, I18nText } from '@wtag/react-comp-lib';
import Card from '@wtag/rcl-card';
import { Header } from '@wtag/rcl-typography';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';
import { parseQueryString } from '../../helpers/qsMethods';
import HotelStandardView from './HotelStandardView';
import HotelCompactView from './HotelCompactView';
import { TYPE_COMPACT, TYPE_STANDARD } from '../../../helpers/viewType';
import resultShape from './shapes/result';
import FilterComponent from './Filter';
import EmptyFilterAndResult from '../flight/SearchLoadingScreen/EmptyFilterAndResult';
import './styles.scss';

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfHotelsToShow: this.props.viewMode === TYPE_COMPACT ? 12 : 6,
    };
    this.locationQuery = parseQueryString(this.props.location.search);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.locationQuery = parseQueryString(this.props.location.search);
    }
  }

  setNumberOfHotelsToShow = () => {
    this.setState({ numberOfHotelsToShow: this.state.numberOfHotelsToShow + 6 });
  };

  render() {
    let filters = null;
    let content = null;

    if (this.props.searchYieldedResults) {
      filters = (
        <div className="results__filters">
          <FilterComponent {...this.props} />
        </div>
      );
      if (this.props.results.length) {
        const results = this.props.results.slice(0, this.state.numberOfHotelsToShow).map(result => {
          let ResultComponent;

          switch (this.props.viewMode) {
            case TYPE_STANDARD:
              ResultComponent = HotelStandardView;
              break;
            case TYPE_COMPACT:
              ResultComponent = HotelCompactView;
              break;
            default:
              ResultComponent = HotelStandardView;
          }

          return (
            <ResultComponent
              {...result}
              key={result.id}
              dealGroup={this.locationQuery.dealGroup}
              deal={this.locationQuery.deal}
              selectedCartItemResultIds={this.props.selectedItemResultIds}
              laymanMode={this.props.laymanMode}
            />
          );
        });

        content = <div className="hotel-results__container-results-content">{results}</div>;
      } else {
        content = (
          <Card version="v2" size="full">
            <Header
              className="hotel-results__container-results-content-empty"
              level={4}
              weight="normal"
            >
              <I18nText id="ibe.results.filter_exclude_everything" returnStringOnly={true} />
            </Header>
          </Card>
        );
      }
    } else if (this.props.searchCompleted && !this.props.searchYieldedResults) {
      content = (
        <Card
          version="v2"
          size="full"
          color="tertiary"
          className="hotel-results__container-results-content-empty"
          emptyCardImageSrc={<Icon name="hotelRoom" />}
          emptyCardText={I18n.t('components.ibe.hotel.results.nothing_found.text')}
        />
      );
    }

    return (
      <div className="hotel-results">
        {this.props.searchCompleted ? (
          <div className="grid hotel-results__container">
            <div className="hotel-results__wrapper">
              {filters ? (
                <div className="col-xlg-4 col-lg-4 col-12 col-bleed hotel-results__filters">
                  {filters}
                </div>
              ) : null}
              <div
                className={classNames('hotel-results__container-results', {
                  'col-xlg-8 col-lg-8 col-12 col-bleed': filters,
                  'col-12 hotel-results__container-results--no-spacing': !filters,
                  'col-12 hotel-results__container-results--compact-view':
                    this.props.viewMode === TYPE_COMPACT,
                })}
              >
                <div className="hotel-results__container-results-content">{content}</div>
                {this.props.results.length - this.state.numberOfHotelsToShow > 0 && (
                  <Link
                    className="col-12 col-bleed"
                    onClick={this.setNumberOfHotelsToShow}
                    type="default"
                    modifier="default"
                  >
                    <div className="hotel-results__container-results-content-show-more">
                      {I18n.t('shared.actions.load_more')}
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        ) : (
          <EmptyFilterAndResult />
        )}
      </div>
    );
  }
}

Results.defaultProps = {
  searchYieldedResults: false,
  searchCompleted: false,
};

Results.propTypes = {
  selectedItemResultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  results: PropTypes.arrayOf(PropTypes.shape(resultShape)).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  searchYieldedResults: PropTypes.bool,
  searchCompleted: PropTypes.bool,
  searchInformation: PropTypes.shape({
    address: PropTypes.string.isRequired,
    adults: PropTypes.number.isRequired,
    checkIn: PropTypes.string.isRequired,
    checkOut: PropTypes.string.isRequired,
    children: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    numberOfRooms: PropTypes.number.isRequired,
  }).isRequired,
  viewMode: PropTypes.string.isRequired,
  laymanMode: PropTypes.bool.isRequired,
};

Results.contextTypes = {
  currency: PropTypes.string,
};

export default Results;
