import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import slider1 from '../../../../../apps/public/app/assets/images/public/landing-sliders/slider1.jpg';
import slider2 from '../../../../../apps/public/app/assets/images/public/landing-sliders/slider2.jpg';
import slider3 from '../../../../../apps/public/app/assets/images/public/landing-sliders/slider3.jpg';
import slider4 from '../../../../../apps/public/app/assets/images/public/landing-sliders/slider4.jpg';

import './styles.scss';

const ImageSlider = ({ images }) => {
  const settings = {
    infiniteLoop: true,
    stopOnHover: false,
    autoPlay: true,
    transitionTime: 1000,
    interval: 5000,
    emulateTouch: true,
    showThumbs: false,
    showArrows: false,
    showStatus: false,
  };

  const fallbackImageArray = [];

  fallbackImageArray.push(
    { id: 1, imageUrl: slider1 },
    { id: 2, imageUrl: slider2 },
    { id: 3, imageUrl: slider3 },
    { id: 4, imageUrl: slider4 },
  );

  return (
    <div className="image-slider image-slider__container">
      <Carousel {...settings}>
        {images.length > 0
          ? images.map(image => (
              <div key={image.id}>
                <img src={image.imageUrl} alt="slider-img" className="image-slider__images" />
              </div>
            ))
          : fallbackImageArray.map(image => (
              <div key={image.id}>
                <img src={image.imageUrl} alt="slider-img" className="image-slider__images" />
              </div>
            ))}
      </Carousel>
    </div>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      imageUrl: PropTypes.string,
    }),
  ).isRequired,
};

export default hot(module)(ImageSlider);
