const getCarAttributes = (doors, airConditioning, seatCount, fuelType) => {
  const attributes = {
    door:
      Number(doors) > 0
        ? I18n.t('components.ibe.search_results.car.content.door', {
            count: doors,
          })
        : '',
    airCondition: airConditioning
      ? I18n.t('components.ibe.search_results.car.content.air_condition')
      : '',
    seat:
      Number(seatCount) > 0
        ? I18n.t('components.ibe.search_results.car.content.seat', {
            count: seatCount,
          })
        : '',
    fuel: I18n.t('components.ibe.search_results.car.content.fuel', {
      type: fuelType,
    }),
  };

  return I18n.t('components.ibe.search_results.car.content.attributes', {
    attributes: Object.values(attributes)
      .filter(Boolean)
      .join(', '),
  });
};

export default getCarAttributes;
