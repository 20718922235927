import { useCallback } from 'react';

const useBodyScrollLock = () => {
  const lockBodyScroll = useCallback(scrollWidth => {
    const calculatedScrollWidth = scrollWidth || window.innerWidth - document.body.offsetWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${calculatedScrollWidth}px`;
  }, []);

  const unlockBodyScroll = useCallback(() => {
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = '0';
  }, []);

  return {
    lockBodyScroll,
    unlockBodyScroll,
  };
};

export default useBodyScrollLock;
