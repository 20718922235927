import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import { Text } from '@wtag/rcl-typography';
import { Spinner } from '@wtag/react-comp-lib';
import { TYPE_ADULT } from 'sharedWebpack/helpers/passengerTypes';
import FlightSeatsCountTag from 'sharedWebpack/IBE/SearchResult/flight/FlightSeatCountTag';
import FlightPricePerAdult from 'sharedWebpack/ItemPrice/Flight/FlightPricePerAdult';
import MatrixCard from '../MatrixCard';
import MatrixContext from '../../contexts/MatrixContext';
import './matrix-data-card.styles.scss';

const MatrixDataCard = props => {
  const { channelId, matrixId, data, price, currency, isSelected, onClick, people } = props;
  const {
    matrixDataCardWidth,
    activeTrip,
    isFetchingMatrices,
    fetchingFlightMatrices,
    totalTravelerCount,
    laymanMode,
  } = useContext(MatrixContext);
  const isLoading = fetchingFlightMatrices.includes(data.resultId);

  const numberOfAdult = people && people.adults;
  const numberOfChildren = people && people.children;
  const numberOfInfant = people && people.infants;

  const adultPrice =
    data.fareCalculations && data.fareCalculations.find(a => a.passengerType === TYPE_ADULT).price;

  const handleChildElementClick = event => {
    event.stopPropagation();
  };

  return (
    <MatrixCard
      className={classNames('matrix-data-card', {
        'matrix-data-card--not-laymanmode': !laymanMode,
        'matrix-data-card--selected': isSelected,
        'matrix-data-card--disabled': !price || isFetchingMatrices,
      })}
      matrixId={matrixId}
      width={matrixDataCardWidth}
      isSelected={isSelected}
      onClick={
        !isSelected && !isFetchingMatrices
          ? () => {
              onClick({ id: activeTrip.matrixId, channelId }, data);
            }
          : () => {}
      }
      isClickable={!isSelected}
    >
      <div
        className={classNames('matrix-data-card__spinner', {
          'matrix-data-card__spinner--visible': isLoading,
        })}
      >
        <Spinner bgColor="neutral" />
      </div>
      {price ? (
        <div>
          <FlightSeatsCountTag
            className="matrix-data-card__content"
            seatCount={data.availableSeatCount}
            travellerCount={totalTravelerCount}
          />
          <div className="matrix-data-card__content">
            <div
              className={classNames('matrix-data-card__content-check-circle', {
                'matrix-data-card__content-check-circle--selected': isSelected,
              })}
            >
              <Icon name="check" size="tiny" color="neutral" />
            </div>
            <div className="flight__prices-content d-flex-col justify-between">
              <FlightPricePerAdult
                className="d-flex-col flight__prices__gross-price"
                currency={currency}
                grossAmount={price}
                numberOfAdult={numberOfAdult}
                numberOfChildren={numberOfChildren}
                numberOfInfant={numberOfInfant}
                grossTaxes={data.grossTaxes}
                fareCalculations={data.fareCalculations}
                adultPrice={adultPrice}
                marginBreakdown={data.marginBreakdown}
                onPriceLabelClick={event => event && handleChildElementClick(event)}
                laymanMode={laymanMode}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="matrix-data-card__unavailablity-note">
          <Icon className="matrix-data-card__unavailablity-note-icon" name="warning" size="tiny" />
          <Text level={3} weight="light">
            {I18n.t('components.ibe.results.fair_info_unavailable')}
          </Text>
        </div>
      )}
    </MatrixCard>
  );
};

MatrixDataCard.defaultProps = {
  price: '0.00',
  currency: 'USD',
  isSelected: false,
};

MatrixDataCard.propTypes = {
  price: PropTypes.string,
  currency: PropTypes.string,
  isSelected: PropTypes.bool,
  matrixId: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    availableSeatCount: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)])
      .isRequired,
    resultId: PropTypes.string.isRequired,
    grossTaxes: PropTypes.string.isRequired,
    grossAmount: PropTypes.string.isRequired,
    fareCalculations: PropTypes.shape({
      find: PropTypes.func,
    }),
    marginBreakdown: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        amount: PropTypes.string,
        currency: PropTypes.string,
        fixed_amount: PropTypes.string,
        name: PropTypes.string,
        taxes: PropTypes.string,
        total: PropTypes.string,
      }),
    ),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  people: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default MatrixDataCard;
