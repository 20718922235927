import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { hot } from 'react-hot-loader';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createHashHistory } from 'history';
import Card from '@wtag/rcl-card';
import { Link } from '@wtag/react-comp-lib';
import CommonApp from '../App';
import NotAvailable from '../components/NotAvailable';
import FlightResults from '../SearchResult/flight/ResultsContainer';
import FlightMatrix from '../SearchDetails/flight/MatrixContainer';

import Deal from '../Deal/DealContainer';
import ApprovalRequest from '../ApprovalRequest/ApprovalRequestContainer';
import closeWindow from '../ReviewBooking/closeWindow';

import HotelResults from '../SearchResult/hotel/ResultsContainer';
import HotelDetails from '../SearchDetails/hotel/DetailsContainer';

import CarResults from '../SearchResult/car/ResultsContainer';

import Search from '../Search';

import FinalBookingSteps from './FinalBookingStepsContainer';

import mapBookingAppContext from '../helpers/bookingAppContextMapping';
import mapAvailableChannels from '../helpers/availableChannelsMapping';
import {
  availableChannelsShape,
  initialSearchParamsShape,
  searchInputConfigurationShape,
  callbackParamsShape,
  searchAccountsShape,
  globalPolicyPreferenceShape,
  countryCodeShape,
  exchangeShape,
} from './shapes';
import FilterButton from '../helpers/FilterButton';
import { TYPE_STANDARD } from '../../helpers/viewType';
import './styles.scss';

const hashHistory = createHashHistory();

class BookingApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...mapBookingAppContext(props) };
  }
  getChildContext() {
    return { ...this.state };
  }

  updatePreselectedTravelersBookingAppContext = preselectedTravelersBookingAppContext => {
    const {
      preselectTravelerIds,
      ownerId,
      ownerAge,
      isOwnerPresent,
      allowBookingOfUnapprovedItems,
      citizenshipCountryCode,
      globalPolicyPreferences,
      searchAccounts,
    } = preselectedTravelersBookingAppContext;
    this.setState({
      callbackParams: {
        ...this.state.callbackParams,
        all_traveler_ids: preselectTravelerIds,
        person_id: ownerId,
      },
      currentUserAge: ownerAge,
      travelerLoggedIn: isOwnerPresent,
      allowBookingOfUnapprovedItems,
      citizenshipCountryCode,
      globalPolicyPreferences,
      searchAccounts,
      availableChannels: mapAvailableChannels(searchAccounts, this.state.availableChannels),
    });
  };

  updateViewMode = value => {
    this.setState({ viewMode: value });
  };

  updateIsShowFilters = () => {
    this.setState({ isShowFilters: !this.state.isShowFilters });
  };

  resetBookingAppContext = () => this.setState({ ...mapBookingAppContext(this.props) });

  render() {
    const filterButtonTag = this.state.isShowFilters
      ? I18n.t('components.ibe.search_results.filters.hide_all_filters')
      : I18n.t('components.ibe.search_results.filters.show_all_filters');
    return (
      <div className="booking-app">
        <Provider store={this.props.store}>
          <IntlProvider
            locale={this.props.language}
            messages={this.props.messages}
            defaultLocale="en"
          >
            <Router history={hashHistory} key={Math.random()}>
              <Switch>
                <CommonApp>
                  <Route
                    render={() => (
                      <Switch>
                        <Route
                          path="/flights/searches/:searchId/matrix"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              searchAccounts={this.state.searchAccounts}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                              canExcludeWtsContent={this.props.canExcludeWtsContent}
                            >
                              <FlightMatrix {...props} />
                            </Search>
                          )}
                        />
                        <Route
                          path="/flights/searches/:searchId/:tripId"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              searchAccounts={this.state.searchAccounts}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                              updateViewMode={this.updateViewMode}
                              viewMode={this.state.viewMode}
                              canExcludeWtsContent={this.props.canExcludeWtsContent}
                            >
                              <Card className="booking-app__filter-sm">
                                <FilterButton match={props.match} />
                                <Link
                                  className="booking-app__filter-sm__link"
                                  onClick={this.updateIsShowFilters}
                                  modifier="tertiary"
                                >
                                  {filterButtonTag}
                                </Link>
                              </Card>
                              <FlightResults
                                {...props}
                                viewMode={this.state.viewMode}
                                isShowFilters={this.state.isShowFilters}
                              />
                            </Search>
                          )}
                        />
                        <Route
                          path="/flights/searching"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              searchAccounts={this.state.searchAccounts}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                              canExcludeWtsContent={this.props.canExcludeWtsContent}
                            >
                              <FlightResults {...props} />
                            </Search>
                          )}
                        />
                        <Route
                          path="/flights"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              searchAccounts={this.state.searchAccounts}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                              canExcludeWtsContent={this.props.canExcludeWtsContent}
                            />
                          )}
                        />
                        <Route
                          path="/hotels/details/:resultId"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                            >
                              <HotelDetails {...props} />
                            </Search>
                          )}
                        />
                        <Route
                          path="/hotels/searches/:searchId"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                              updateViewMode={this.updateViewMode}
                              viewMode={this.state.viewMode}
                            >
                              <HotelResults
                                {...props}
                                laymanMode={this.props.laymanMode}
                                updateViewMode={this.updateViewMode}
                                viewMode={this.state.viewMode}
                              />
                            </Search>
                          )}
                        />
                        <Route
                          path="/hotels/searching"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                            >
                              <HotelResults {...props} />
                            </Search>
                          )}
                        />
                        <Route
                          path="/hotels"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                            />
                          )}
                        />
                        <Route
                          path="/cars/searches/:searchId"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                              viewMode={this.state.viewMode}
                              updateViewMode={this.updateViewMode}
                            >
                              <CarResults {...props} viewMode={this.state.viewMode} />
                            </Search>
                          )}
                        />
                        <Route
                          path="/cars/searching"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                            >
                              <CarResults {...props} />
                            </Search>
                          )}
                        />
                        <Route
                          path="/cars"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              travelerLoggedIn={this.props.travelerLoggedIn}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                            />
                          )}
                        />
                        <Route
                          path="/static"
                          component={props => (
                            <Search
                              {...props}
                              affiliatesUrl={this.props.affiliatesUrl}
                              availableChannels={this.state.availableChannels}
                              forceAdvancedSearch={this.props.forceAdvancedSearch}
                              displayTitle={this.props.displayTitle}
                              laymanMode={this.props.laymanMode}
                              iframeMode={this.state.iframeMode}
                              images={this.props.images}
                              setOnSearchStarted={this.props.setOnSearchStarted}
                              setShowBottomComponents={this.props.setShowBottomComponents}
                              updatePreselectedTravelersBookingAppContext={
                                this.updatePreselectedTravelersBookingAppContext
                              }
                              resetBookingAppContext={this.resetBookingAppContext}
                              exchangeEnabled={this.props.exchange.enabled}
                              travelArrangerPresent={this.props.travelArrangerPresent}
                              wtsEnabled={this.props.wtsEnabled}
                              callbackParams={this.props.callbackParams}
                            />
                          )}
                        />
                        <Route path="/trains" component={NotAvailable} />
                        <Route path="/cruises" component={NotAvailable} />
                        <Route path="/activities" component={NotAvailable} />
                        <Route path="/tours" component={NotAvailable} />
                        <Route path="/deals/:id" component={Deal} />
                        <Route path="/approval-requests/:id" component={ApprovalRequest} />
                        <Route path="/orders/:id/booking/pop-up-return" component={closeWindow} />
                        <Route
                          path="/carts"
                          render={routeProps => (
                            <FinalBookingSteps
                              laymanMode={this.props.laymanMode}
                              callbackParams={this.props.callbackParams}
                              {...routeProps}
                            />
                          )}
                        />
                        <Route
                          path="/orders"
                          render={routeProps => (
                            <FinalBookingSteps
                              callbackParams={this.props.callbackParams}
                              laymanMode={this.props.laymanMode}
                              affiliatesBillingUrl={this.props.affiliatesBillingUrl}
                              {...routeProps}
                            />
                          )}
                        />
                        <Redirect from="/" to="/flights" />
                      </Switch>
                    )}
                  />
                </CommonApp>
              </Switch>
            </Router>
          </IntlProvider>
        </Provider>
      </div>
    );
  }
}

BookingApp.propTypes = {
  affiliatesUrl: PropTypes.string,
  affiliatesBillingUrl: PropTypes.string,
  setShowBottomComponents: PropTypes.func.isRequired,
  setOnSearchStarted: PropTypes.func.isRequired,
  allowBookingOfUnapprovedItems: PropTypes.bool,
  availableChannels: PropTypes.shape(availableChannelsShape),
  callbackParams: PropTypes.shape(callbackParamsShape),
  currency: PropTypes.string.isRequired,
  language: PropTypes.string,
  laymanMode: PropTypes.bool,
  forceAdvancedSearch: PropTypes.bool,
  displayTitle: PropTypes.bool,
  creditCardEnabled: PropTypes.bool,
  paymentDisabled: PropTypes.bool,
  allowPaymentViaInvoice: PropTypes.bool,
  paymentOptions: PropTypes.shape(),
  searchAccounts: PropTypes.shape(searchAccountsShape),
  travelArrangerPermissionLevel: PropTypes.oneOf(['booking', 'read', 'write', 'admin']),
  countryCodes: PropTypes.arrayOf(countryCodeShape).isRequired,
  travelArrangerDefaultEmail: PropTypes.string,
  travelArrangerPresent: PropTypes.objectOf(PropTypes.string),
  confirmationMailForTravelApp: PropTypes.bool,
  confirmationMailForAgent: PropTypes.bool,
  store: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape(),
  }).isRequired,
  messages: PropTypes.objectOf(PropTypes.string).isRequired,
  currentUserAge: PropTypes.number,
  travelerLoggedIn: PropTypes.bool,
  travelerHasOrganization: PropTypes.bool,
  citizenshipCountryCode: PropTypes.string,
  enableVisaAdvisory: PropTypes.bool,
  enableIdDocOptional: PropTypes.bool,
  exchange: PropTypes.shape(exchangeShape),
  initialSearchParams: PropTypes.shape(initialSearchParamsShape),
  searchInputConfiguration: PropTypes.shape(searchInputConfigurationShape),
  ccvPublicToken: PropTypes.string.isRequired,
  ccvURL: PropTypes.string.isRequired,
  minHoursInFuture: PropTypes.number.isRequired,
  tenantIdentifier: PropTypes.string.isRequired,
  offerOnly: PropTypes.bool,
  globalPolicyPreferences: PropTypes.shape(globalPolicyPreferenceShape),
  individualAccountingTypeEnabled: PropTypes.bool.isRequired,
  frozen: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      imageUrl: PropTypes.string,
    }),
  ).isRequired,
  wtsEnabled: PropTypes.bool.isRequired,
  viewMode: PropTypes.string,
  isShowFilters: PropTypes.bool,
  canExcludeWtsContent: PropTypes.bool,
};

BookingApp.defaultProps = {
  affiliatesUrl: null,
  affiliatesBillingUrl: null,
  allowBookingOfUnapprovedItems: false,
  availableChannels: null,
  callbackParams: {},
  language: 'en',
  laymanMode: true,
  forceAdvancedSearch: false,
  displayTitle: true,
  creditCardEnabled: true,
  paymentDisabled: false,
  allowPaymentViaInvoice: true,
  paymentOptions: {},
  searchAccounts: {},
  travelArrangerPermissionLevel: null,
  travelArrangerDefaultEmail: null,
  travelArrangerPresent: [],
  confirmationMailForTravelApp: true,
  confirmationMailForAgent: false,
  currentUserAge: null,
  travelerLoggedIn: false,
  travelerHasOrganization: true,
  citizenshipCountryCode: null,
  enableVisaAdvisory: true,
  enableIdDocOptional: true,
  exchange: {
    enabled: false,
  },
  initialSearchParams: {
    flights: null,
  },
  searchInputConfiguration: {
    showDepartureTime: true,
    showPlusMinusThreeDays: true,
    showNonStop: true,
    showAirlinePreference: true,
    showOnlyFlexibleFlight: true,
    showOnlyBagIncludedFlight: true,
    showBookingClass: true,
    showAlliancePreferences: true,
    showExcludeCodeShareFlights: true,
    enableSearchItineraryOnly: false,
    whitelistedAirports: [],
  },
  offerOnly: false,
  globalPolicyPreferences: null,
  frozen: false,
  viewMode: TYPE_STANDARD,
  isShowFilters: true,
  canExcludeWtsContent: false,
};

BookingApp.childContextTypes = {
  allowBookingOfUnapprovedItems: PropTypes.bool,
  availableChannels: PropTypes.shape(availableChannelsShape),
  callbackParams: PropTypes.shape(callbackParamsShape),
  currency: PropTypes.string,
  travelArranger: PropTypes.shape({
    enabled: PropTypes.bool,
    customerInformationVisible: PropTypes.bool,
    customerInformationEditable: PropTypes.bool,
    allowContinueToOrder: PropTypes.bool,
  }),
  countryCodes: PropTypes.arrayOf(countryCodeShape).isRequired,
  travelArrangerDefaultEmail: PropTypes.string.isRequired,
  travelArrangerPresent: PropTypes.objectOf(PropTypes.string).isRequired,
  confirmationMailForTravelApp: PropTypes.bool,
  confirmationMailForAgent: PropTypes.bool,
  laymanMode: PropTypes.bool,
  iframeMode: PropTypes.bool,
  forceAdvancedSearch: PropTypes.bool,
  creditCardEnabled: PropTypes.bool,
  paymentDisabled: PropTypes.bool,
  allowPaymentViaInvoice: PropTypes.bool,
  paymentOptions: PropTypes.objectOf(PropTypes.any),
  searchAccounts: PropTypes.shape(searchAccountsShape),
  currentUserAge: PropTypes.number,
  travelerLoggedIn: PropTypes.bool,
  travelerHasOrganization: PropTypes.bool,
  citizenshipCountryCode: PropTypes.string,
  enableVisaAdvisory: PropTypes.bool,
  enableIdDocOptional: PropTypes.bool,
  exchange: PropTypes.shape(exchangeShape),
  initialSearchParams: PropTypes.shape(initialSearchParamsShape),
  searchInputConfiguration: PropTypes.shape(searchInputConfigurationShape),
  ccvPublicToken: PropTypes.string,
  ccvURL: PropTypes.string,
  minHoursInFuture: PropTypes.number,
  tenantIdentifier: PropTypes.string,
  offerOnly: PropTypes.bool,
  globalPolicyPreferences: PropTypes.shape(globalPolicyPreferenceShape),
  individualAccountingTypeEnabled: PropTypes.bool,
  frozen: PropTypes.bool,
  wtsEnabled: PropTypes.bool,
  viewMode: PropTypes.string,
  isShowFilters: PropTypes.bool,
  canExcludeWtsContent: PropTypes.bool,
};

export default hot(module)(BookingApp);
