import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Stars from '../../components/Stars';
import { RATING_SOURCES, sourceMaxes, sourceNames } from '../../../helpers/hotelRatingData';

const HotelRating = ({ rating, ratingSource }) => {
  const maxRating = sourceMaxes[ratingSource];
  const source = sourceNames[ratingSource];

  return (
    <div className="hotel-title__ratings">
      {I18n.t('components.ibe.search_results.hotel.rating', {
        rating,
        max_rating: maxRating,
        source,
      })}
    </div>
  );
};

HotelRating.defaultProps = {
  rating: null,
  ratingSource: null,
};

HotelRating.propTypes = {
  rating: PropTypes.number,
  ratingSource: PropTypes.oneOf(RATING_SOURCES),
};

const HotelTitle = ({ name, address, stars, rating, ratingSource }) => (
  <Card version="v2" size="full">
    <div className="hotel-title__name">{name}</div>
    <div className="hotel-title__wrapper">
      <Stars stars={stars} />
      {stars > 0 && <div className="hotel-title__vertical-line" />}
      <div className="hotel-title__address">{address}</div>
      {rating && (
        <Fragment>
          <div className="hotel-title__vertical-line" />
          <HotelRating rating={rating} ratingSource={ratingSource} />
        </Fragment>
      )}
    </div>
  </Card>
);

HotelTitle.defaultProps = {
  stars: 0,
  name: '',
  address: '',
  rating: null,
  ratingSource: null,
};

HotelTitle.propTypes = {
  stars: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  rating: PropTypes.number,
  ratingSource: PropTypes.string,
};

export default HotelTitle;
