import React from 'react';
import PropTypes from 'prop-types';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import Card from '@wtag/rcl-card';
import ToolTip from '@wtag/rcl-tooltip';
import Icon from '@wtag/rcl-icon';
import { I18nText } from '@wtag/react-comp-lib';
import CostCenter from './CostCenter';

import AccountingType from './AccountingTypeContainer';

import './style.scss';

const checkIfCostCenterExists = (costCenter, usedCostCenters) => {
  if (usedCostCenters) {
    return usedCostCenters.some(
      cartCostCenter => cartCostCenter.costCenter.value === costCenter.value,
    );
  }
  return false;
};

const addCostCenterToOrder = (costCenter, orderId) => {
  httpClient
    .post(routes.api.orders.costCenters.add({ orderId }), {
      cost_center_id: costCenter.id,
      allocated_percentage: costCenter.allocatedPercentage,
      custom: costCenter.custom,
      accounting_type_id: costCenter.accountingTypeId,
    })
    .then(() => window.location.reload(false));
};

const canAddCostCenter = props =>
  (props.canAddOrderCostCenter || props.addCostCenterToCart) && !!props.accountingTypes.length;

const addCostCenter = (costCenter, canAddOrderCostCenter, orderId, addCostCenterToCart) =>
  canAddOrderCostCenter
    ? addCostCenterToOrder(costCenter, orderId)
    : addCostCenterToCart(costCenter);

const message = entry => (
  <span className="col-12 col-bleed-y cost-centers__total-allocation">
    <Icon name="financial" showBGColor={true} color="tertiary" />
    {I18n.t('components.cost_center.total_allocated_percentage', {
      accounting: entry.accountingType,
      percentage: entry.allocatedPercentage,
    })}
  </span>
);

const sortedUsedCostCenters = usedCostCenters =>
  usedCostCenters.sort((a, b) => a.costCenter.accountingTypeId - b.costCenter.accountingTypeId);

const sortedAllocationPerAccountingTypes = accountingTypes =>
  accountingTypes.sort((a, b) => a.id - b.id);

const CostCenters = props => (
  <Card version="v2">
    <div className="cost-centers">
      <div className="cost-centers__main-head">
        <div className="grid">
          <div className="col-7 col-bleed-y cost-centers__header-wrapper">
            <div className="cost-centers__header">
              {I18n.t('ibe.cost_center.accounting')}
              {!props.individualAccountingTypeEnabled && (
                <ToolTip content={I18n.t('components.ibe.cost_center.individual_disabled')}>
                  <Icon name="info" size="tiny" color="tertiary" />
                </ToolTip>
              )}
            </div>
          </div>
          <div className="col-5 col-bleed-y cost-centers__actions-wrapper">
            <div className="cost-centers__actions">
              {canAddCostCenter(props) && (
                <span>
                  <AccountingType
                    accountingTypes={props.accountingTypes}
                    addCostCenter={costCenter =>
                      addCostCenter(
                        costCenter,
                        props.canAddOrderCostCenter,
                        props.orderId,
                        props.addCostCenterToCart,
                      )
                    }
                    checkIfCostCenterExists={costCenter =>
                      checkIfCostCenterExists(costCenter, props.usedCostCenters)
                    }
                  />
                </span>
              )}
            </div>
          </div>
          {!props.allocationPerAccountingType.length && (
            <div className="col-12 cost-centers__not-found-text">
              <I18nText id="cost_center.not_found" returnStringOnly={true} />
            </div>
          )}
        </div>
      </div>
      <div className="cost-centers__main-section">
        <div className="col-12 col-bleed">
          {sortedAllocationPerAccountingTypes(props.allocationPerAccountingType).map(accounting => (
            <div>
              {message(accounting)}
              {sortedUsedCostCenters(props.usedCostCenters).map(usedCostCenter => {
                if (usedCostCenter.costCenter.accountingTypeId === accounting.id) {
                  return (
                    <CostCenter
                      key={usedCostCenter.costCenter.id}
                      costCenter={usedCostCenter.costCenter}
                      usedCostCenter={usedCostCenter}
                      tenantCurrency={props.tenantCurrency}
                      addCostCenterToCart={props.addCostCenterToCart}
                      updateCartCostCenter={props.updateCartCostCenter}
                      canUpdateOrderCostCenter={props.canUpdateOrderCostCenter}
                      removeCostCenterFromCart={props.removeCostCenterFromCart}
                      canRemoveOrderCostCenter={props.canRemoveOrderCostCenter}
                      reviewBookingPage={props.reviewBookingPage}
                    />
                  );
                }

                return null;
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  </Card>
);

CostCenters.defaultProps = {
  addCostCenterToCart: null,
  updateCartCostCenter: null,
  canUpdateOrderCostCenter: false,
  removeCostCenterFromCart: null,
  canRemoveOrderCostCenter: false,
  canAddOrderCostCenter: false,
  accountingTypes: [],
  orderId: null,
  individualAccountingTypeEnabled: true,
  allocationPerAccountingType: [],
  reviewBookingPage: false,
};

CostCenters.propTypes = {
  usedCostCenters: PropTypes.arrayOf(PropTypes.shape).isRequired,
  accountingTypes: PropTypes.arrayOf(PropTypes.shape),
  tenantCurrency: PropTypes.string.isRequired,
  addCostCenterToCart: PropTypes.func,
  updateCartCostCenter: PropTypes.func,
  canUpdateOrderCostCenter: PropTypes.bool,
  removeCostCenterFromCart: PropTypes.func,
  canRemoveOrderCostCenter: PropTypes.bool,
  individualAccountingTypeEnabled: PropTypes.bool,
  canAddOrderCostCenter: PropTypes.bool,
  orderId: PropTypes.number,
  allocationPerAccountingType: PropTypes.arrayOf(PropTypes.shape({})),
  reviewBookingPage: PropTypes.bool,
};

export default CostCenters;
