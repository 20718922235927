import { CURRENT_CUSTOMER_FETCHED } from '../../actions/common/customer';

export default function currentCustomer(state = {}, action) {
  switch (action.type) {
    case CURRENT_CUSTOMER_FETCHED:
      return action.customer || {};

    default:
      return state;
  }
}
