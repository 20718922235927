import PropTypes from 'prop-types';

export const FacilitiesItemShape = PropTypes.shape({
  seats: PropTypes.number,
  doors: PropTypes.number,
  airConditioned: PropTypes.bool,
  fuelType: PropTypes.string,
  airBag: PropTypes.bool,
});

export const OtherFacilitiesItemShape = PropTypes.shape({
  amount: PropTypes.string,
  currency: PropTypes.string,
  modifier: PropTypes.string,
  type: PropTypes.string,
});
