import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, SidePanel, EmphasisTag, Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import Map from '@wtag/rcl-map';
import ClassNames from 'classnames';

import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import SocialMediaShow from 'sharedWebpack/SocialMediaSection/SocialMediaShow';
import FlagWithToolTip from '../../admin/FlagWithToolTip';
import './styles.scss';

const LeftPanel = props => {
  const { traveller, laymanMode, loading, progressBarColor, setShareLocationError } = props;
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const flagsToShow = 4;

  const [query, setQuery] = useQueryParams({
    openedSidePanel: withDefault(StringParam, ''),
  });

  const emergencySidePanelVisible = query.openedSidePanel === 'emergency';
  const viewPhonesSidePanelVisible = query.openedSidePanel === 'phones';
  const viewAddressSidePanelVisible = query.openedSidePanel === 'address';
  const documentSidePanelVisible = query.openedSidePanel === 'document';

  const showEmergencySidePanel = () => {
    setQuery({ openedSidePanel: 'emergency' }, 'replaceIn');
  };

  const showPhonesSidePanel = () => {
    setQuery({ openedSidePanel: 'phones' }, 'replaceIn');
  };

  const showAddressSidePanel = () => {
    setQuery({ openedSidePanel: 'address' }, 'replaceIn');
  };

  const showDocumentSidePanel = () => {
    setQuery({ openedSidePanel: 'document' }, 'replaceIn');
  };

  const updateLocation = async (lat, lon) => {
    await httpClient.put(
      routes.api.shareLocation({
        latitude: lat,
        longitude: lon,
        lastLocationTime: Date().toLocaleString(),
      }),
    );
  };

  const shareGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;

          setLatitude(lat);
          setLongitude(lon);
          updateLocation(lat, lon);
        },
        error => {
          setShareLocationError(error.message);
        },
      );
    }
  };

  const hideSidePanel = () => {
    setQuery({ openedSidePanel: '' }, 'replaceIn');
  };
  const travellerVerificationStatus = type => {
    let verificationIcon;

    switch (type) {
      case 'verified':
        verificationIcon = (
          <Icon
            name="checkCircle"
            iconLabel={I18n.t('public.components.dashboard.people.verification_status.verified')}
            showLabel={true}
            color="success"
            size="small"
          />
        );
        break;

      case 'unverified':
        verificationIcon = (
          <Icon
            name="checkCircle"
            iconLabel={I18n.t('public.components.dashboard.people.verification_status.unverified')}
            showLabel={true}
            size="small"
          />
        );
        break;

      default:
        verificationIcon = null;
    }

    return verificationIcon;
  };

  const flags =
    traveller.identificationDocuments &&
    traveller.identificationDocuments
      .map(document => {
        const showToolTip = document.showWarning;
        let coloredText;
        let hint;
        let icon;
        if (showToolTip) {
          if (document.showDanger) {
            coloredText = (
              <div className="flag-with-tool-tip__content--danger">
                {I18n.t('admin.components.travellers.overview.left_panel.expired')}
              </div>
            );
            hint = (
              <div className="flag-with-tool-tip__content--hint">
                {I18n.t('admin.components.travellers.overview.left_panel.expired_hint')}
              </div>
            );
            icon = <Icon name="closeCircle" color="danger" size="medium" />;
          } else {
            coloredText = (
              <div className="flag-with-tool-tip__content--warning">
                {I18n.t('admin.components.travellers.overview.left_panel.warning')}
              </div>
            );
            hint = (
              <div className="flag-with-tool-tip__content--hint">
                {I18n.t('admin.components.travellers.overview.left_panel.warning_hint')}
              </div>
            );
            icon = <Icon name="invalid" color="warning" size="medium" />;
          }

          return (
            <FlagWithToolTip
              key={document.id}
              flagUrl={document.flagUrl}
              coloredText={coloredText}
              hint={hint}
              showToolTip={showToolTip}
              nationality={document.nationality}
              icon={icon}
            />
          );
        }

        return (
          <FlagWithToolTip
            key={document.id}
            flagUrl={document.flagUrl}
            showToolTip={showToolTip}
            nationality={document.nationality}
          />
        );
      })
      .slice(0, flagsToShow);

  const addressesBody =
    traveller.addresses &&
    traveller.addresses.map(address => (
      <Card key={address.id} version="v2">
        <div className="col-grid col-bleed">
          <div className="col-grid col-bleed direction-row align-center traveller-overview-left-panel__document-card-info">
            <EmphasisTag
              text={I18n.t(`admin.components.travellers.overview.left_panel.tags.${address.type}`)}
              size="small"
            />
            {address.primary && (
              <div className="traveller-overview-left-panel__document-card-tag">
                <EmphasisTag
                  type="important"
                  text={I18n.t('admin.components.travellers.overview.left_panel.primary')}
                  size="small"
                />
              </div>
            )}
          </div>
          <div className="traveller-overview-left-panel__document-card-info-text">
            {address.line1}, {address.city}, {address.zipCode}
          </div>
        </div>
      </Card>
    ));

  const emailAddressesBody =
    traveller.emailAddresses &&
    traveller.emailAddresses.map(email => (
      <Card key={email.email} version="v2">
        <div className="col-grid col-bleed">
          <div className="col-grid col-bleed direction-row align-center traveller-overview-left-panel__document-card-info">
            <EmphasisTag
              text={I18n.t(`admin.components.travellers.overview.left_panel.tags.${email.type}`)}
              size="small"
            />
            {email.primary && (
              <div className="traveller-overview-left-panel__document-card-tag">
                <EmphasisTag
                  type="important"
                  text={I18n.t('admin.components.travellers.overview.left_panel.primary')}
                  size="small"
                />
              </div>
            )}
          </div>
          <div className="traveller-overview-left-panel__document-card-info-text">
            {email.email}
          </div>
        </div>
      </Card>
    ));

  const phoneNumbersBody =
    traveller.phoneNumbers &&
    traveller.phoneNumbers.map(number => (
      <Card key={number.number} version="v2">
        <div className="col-grid col-bleed">
          <div className="col-grid col-bleed direction-row align-center traveller-overview-left-panel__document-card-info">
            <EmphasisTag
              text={I18n.t(`admin.components.travellers.overview.left_panel.tags.${number.type}`)}
              size="small"
            />
            {number.primary && (
              <div className="traveller-overview-left-panel__document-card-tag">
                <EmphasisTag
                  type="important"
                  text={I18n.t('admin.components.travellers.overview.left_panel.primary')}
                  size="small"
                />
              </div>
            )}
          </div>
          <div className="traveller-overview-left-panel__document-card-info-text">
            {number.number}
          </div>
        </div>
      </Card>
    ));

  const emergencyContactsBody =
    traveller.emergencyContacts &&
    traveller.emergencyContacts.map(contact => (
      <Card key={contact.number} version="v2">
        <div className="col-grid col-bleed direction-row align-center">
          <Icon name="pointOfContact" color="tertiary" size="small" />
          <div className="traveller-overview-left-panel__emergency-card-text traveller-overview-left-panel__emergency-card-text--primary">
            {contact.name}
          </div>
          <div className="traveller-overview-left-panel__emergency-card-text traveller-overview-left-panel__emergency-card-text--disabled">
            {contact.number}
          </div>
        </div>
      </Card>
    ));

  const identificationsBody =
    traveller.identificationDocuments &&
    traveller.identificationDocuments.map(document => {
      let coloredText;
      let hint;
      let icon;

      const showWarning = document.showWarning;

      if (document.showDanger) {
        coloredText = (
          <div className="traveller-overview-left-panel__document-card-text--danger">
            {I18n.t('admin.components.travellers.overview.left_panel.expired')}
          </div>
        );
        hint = (
          <div className="traveller-overview-left-panel__document-card-text--hint">
            {I18n.t('admin.components.travellers.overview.left_panel.expired_hint')}
          </div>
        );
        icon = <Icon name="closeCircle" color="danger" size="medium" />;
      } else if (showWarning) {
        coloredText = (
          <div className="traveller-overview-left-panel__document-card-text--warning">
            {I18n.t('admin.components.travellers.overview.left_panel.warning')}
          </div>
        );
        hint = (
          <div className="traveller-overview-left-panel__document-card-text--hint">
            {I18n.t('admin.components.travellers.overview.left_panel.warning_hint')}
          </div>
        );
        icon = <Icon name="invalid" color="warning" size="medium" />;
      }

      return (
        <Card key={document.id} version="v2">
          <div className="col-grid col-bleed traveller-overview-left-panel__document-card">
            <div className="col-grid col-bleed direction-row align-center traveller-overview-left-panel__document-card-flag">
              <img src={document.flagUrl} alt={document.nationality} />
              <div className="traveller-overview-left-panel__document-card-tag">
                {document.primary && (
                  <EmphasisTag
                    type="important"
                    size="small"
                    text={I18n.t('admin.components.travellers.overview.left_panel.primary')}
                  />
                )}
              </div>
            </div>
            {showWarning && (
              <div className="traveller-overview-left-panel__document-card-info">
                <div className="col-grid col-bleed direction-row align-center">
                  {icon}
                  <div className="traveller-overview-left-panel__document-card-text">
                    {coloredText}
                  </div>
                </div>
                {hint}
              </div>
            )}
            <div className="col-grid col-bleed direction-row">
              {document.nationality}
              <div className="traveller-overview-left-panel__document-card-number">
                {document.number}
              </div>
            </div>
          </div>
        </Card>
      );
    });

  const loadEvents = () => {
    const events = [];
    if (traveller.addressCoordinates) {
      events.push({
        title: I18n.t('admin.components.travellers.overview.left_panel.home_address'),
        latitude: traveller.addressCoordinates.lat,
        longitude: traveller.addressCoordinates.lon,
      });
    }

    if (latitude && longitude) {
      let title = null;

      if (
        traveller.addressCoordinates &&
        parseInt(traveller.addressCoordinates.lat, 10) === parseInt(latitude, 10)
      ) {
        title = I18n.t('admin.components.travellers.overview.left_panel.home_or_last_address');
      } else {
        title = I18n.t('admin.components.travellers.overview.left_panel.last_known_address');
      }

      events.push({
        title,
        latitude,
        longitude,
      });
    }

    return events;
  };

  useEffect(() => {
    setLatitude(parseFloat(traveller.latitude));
    setLongitude(parseFloat(traveller.longitude));
  }, [traveller]);

  return (
    <div className="col-grid align-center traveller-overview-left-panel">
      {/* TODO: Comment out when the feature is available */}
      {/* <div className="col-12 col-grid justify-center direction-row placeholder">
        <Icon name="person" />
        <div className="placeholder__text">
          {I18n.t('admin.components.travellers.overview.placeholder.account_manager')}
        </div>
      </div> */}
      {traveller.id && (
        <div className="traveller-overview-left-panel__avatar">
          {traveller.id && (
            <Avatar
              key={traveller.id}
              firstName={traveller.firstName}
              lastName={traveller.lastName}
              src={traveller.avatarUrl}
              size="xl"
            />
          )}
        </div>
      )}
      {loading && <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />}
      <div className="col-grid col-bleed align-center">
        {laymanMode && (
          <div className="traveller-overview-left-panel__name">
            {traveller.nickName ? `${traveller.name} (${traveller.nickName})` : traveller.name}
          </div>
        )}
        <div
          className={ClassNames('traveller-overview-left-panel__status', {
            'traveller-overview-left-panel__status--margin-top': !laymanMode,
          })}
        >
          {travellerVerificationStatus(traveller.verificationStatus)}
        </div>
      </div>
      {traveller.socialMediaLinks && (
        <div className="col-grid col-bleed align-center">
          <div className="col-grid direction-row align-center traveller-overview-left-panel__links">
            <SocialMediaShow socialMediaLinks={traveller.socialMediaLinks} />
          </div>
        </div>
      )}
      <div className="traveller-overview-left-panel__progress-bar">
        <ProgressBar
          size="small"
          completed={traveller.profileCompletionPercentage}
          completedText={I18n.t('admin.components.travellers.overview.left_panel.complete')}
          bgColor={progressBarColor}
          showText={true}
        />
      </div>
      {traveller && traveller.organization && traveller.organization.id && (
        <div className="col-grid col-bleed-x direction-row align-center traveller-overview-left-panel__organization">
          <Avatar
            firstName={traveller.organization.firstName}
            lastName={traveller.organization.lastName}
            key={traveller.organization.id}
            src={traveller.organization.avatarUrl}
            size="small"
          />
          <div className="traveller-overview-left-panel__organization-name">
            <Link type="anchor" modifier="default" size="small" href={traveller.organizationPath}>
              {traveller.organization.name}
            </Link>
          </div>
        </div>
      )}
      {!laymanMode && !traveller.organization && loading && (
        <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
      )}
      {!laymanMode && (
        <div className="col-12 col-grid justify-center direction-row placeholder">
          <Icon name="person" />
          <div className="placeholder__text">
            {I18n.t('admin.components.travellers.overview.placeholder.travel_agent')}
          </div>
        </div>
      )}
      {traveller.id && (
        <div className="col-grid col-bleed-x traveller-overview-left-panel__passport">
          <div className="traveller-overview-left-panel__passport-header">
            {I18n.t('admin.components.travellers.overview.left_panel.passport')}
          </div>
          <div className="col-grid col-bleed direction-row">{flags}</div>
          <button
            className="side-panel-link traveller-overview-left-panel__passport-link"
            onClick={() => showDocumentSidePanel()}
          >
            {I18n.t('admin.components.travellers.overview.left_panel.links.documents')}
          </button>
          <SidePanel
            title={I18n.t(
              'admin.components.travellers.overview.left_panel.passport_and_identifications',
            )}
            body={identificationsBody}
            isOpened={documentSidePanelVisible}
            onClick={() => hideSidePanel()}
          />
        </div>
      )}
      {loading && <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} />}
      {!laymanMode && traveller.id && (
        <div className="col-grid col-bleed-x traveller-overview-left-panel__communication">
          <div className="traveller-overview-left-panel__communication-header">
            {I18n.t('admin.components.travellers.overview.left_panel.communication_preference')}
          </div>
          <div className="col-grid direction-row align-center col-bleed traveller-overview-left-panel__communication-value">
            {traveller.preferredCommunication === 'email' && (
              <React.Fragment>
                <Icon name="email" color="default" size="small" />
                <div className="traveller-overview-left-panel__communication-value--with-padding">
                  {I18n.t('admin.components.travellers.overview.left_panel.emails_only')}
                </div>
              </React.Fragment>
            )}
            {traveller.preferredCommunication === 'phone' && (
              <React.Fragment>
                <Icon name="call" color="default" size="small" />
                <div className="traveller-overview-left-panel__communication-value--with-padding">
                  {I18n.t('admin.components.travellers.overview.left_panel.phones_only')}
                </div>
              </React.Fragment>
            )}
            {!traveller.preferredCommunication &&
              I18n.t(
                'admin.components.travellers.edit.general.information.placeholder.no_preferred_communication',
              )}
          </div>
        </div>
      )}
      {!laymanMode && (
        <div className="col-grid col-bleed">
          <button className="side-panel-link" onClick={() => showEmergencySidePanel()}>
            {I18n.t('admin.components.travellers.overview.left_panel.links.emergency_contacts')}
          </button>
          <SidePanel
            title={I18n.t('admin.components.travellers.overview.left_panel.emergency_contacts')}
            body={emergencyContactsBody}
            isOpened={emergencySidePanelVisible}
            onClick={() => hideSidePanel()}
          />
        </div>
      )}
      <div className="col-grid col-bleed-x traveller-overview-left-panel__communication traveller-overview-left-panel__communication--contacts-padding">
        <div className="traveller-overview-left-panel__communication-header">
          {I18n.t('admin.components.travellers.overview.left_panel.primary_contacts')}
        </div>
        <div className="col-grid direction-row align-center col-bleed traveller-overview-left-panel__communication-value">
          {traveller.primaryEmailAddress && (
            <Fragment>
              <Icon name="email" color="default" size="small" />
              <div className="traveller-overview-left-panel__communication-value--with-padding traveller-overview-left-panel__email">
                {traveller.primaryEmailAddress.id
                  ? traveller.primaryEmailAddress.email
                  : I18n.t('admin.components.travellers.table.no_emails')}
              </div>
            </Fragment>
          )}
          {loading && <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />}
        </div>
        <div className="col-grid direction-row align-center col-bleed traveller-overview-left-panel__communication-value">
          {traveller.primaryPhoneNumber && (
            <Fragment>
              <Icon name="call" color="default" size="small" />
              <div className="traveller-overview-left-panel__communication-value--with-padding traveller-overview-left-panel__communication--contacts-value-padding">
                {traveller.primaryPhoneNumber.id
                  ? traveller.primaryPhoneNumber.number
                  : I18n.t('admin.components.organizations.edit.email_and_phone.phone.no_phone')}
              </div>
            </Fragment>
          )}
          {loading && <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />}
        </div>
        {!laymanMode && (
          <button className="side-panel-link" onClick={() => showPhonesSidePanel()}>
            {I18n.t('admin.components.travellers.overview.left_panel.links.emails_and_phones')}
          </button>
        )}
        <SidePanel
          body={
            <React.Fragment>
              <div className="traveller-overview-left-panel__document-card-header">
                {I18n.t('admin.components.travellers.overview.left_panel.email_addresses')}
              </div>
              {emailAddressesBody && emailAddressesBody.length > 0 ? (
                emailAddressesBody
              ) : (
                <Card>
                  {I18n.t('admin.components.organizations.edit.email_and_phone.email.no_email')}
                </Card>
              )}
              <div className="traveller-overview-left-panel__document-card-header traveller-overview-left-panel__document-card-header-phone">
                {I18n.t('admin.components.travellers.overview.left_panel.phone_numbers')}
              </div>

              {phoneNumbersBody && phoneNumbersBody.length > 0 ? (
                phoneNumbersBody
              ) : (
                <Card>
                  {I18n.t('admin.components.organizations.edit.email_and_phone.phone.no_phone')}
                </Card>
              )}
            </React.Fragment>
          }
          isOpened={viewPhonesSidePanelVisible}
          onClick={() => hideSidePanel()}
        />
      </div>
      <div className="col-grid col-bleed-x traveller-overview-left-panel__communication">
        <div className="traveller-overview-left-panel__communication-header">
          {I18n.t('admin.components.travellers.overview.left_panel.home_address')}
        </div>
        <div className="col-grid direction-row align-center col-bleed traveller-overview-left-panel__communication-value">
          <Icon name="mapMarker" color="danger" size="small" />
          <div className="traveller-overview-left-panel__communication-value--with-padding">
            {traveller.primaryAddress &&
              traveller.primaryAddress.id &&
              `${traveller.primaryAddress.line1}, ${traveller.primaryAddress.city}`}
          </div>
        </div>
      </div>
      <div className="col-grid col-bleed traveller-overview-left-panel__communication--map-padding">
        {(latitude || traveller.addressCoordinates) && (
          <Map
            uniqueKey="traveller-overview-left-panel__map"
            mapCenter={[
              {
                latitude: traveller.addressCoordinates
                  ? traveller.addressCoordinates.lat
                  : traveller.latitude,
                longitude: traveller.addressCoordinates
                  ? traveller.addressCoordinates.lon
                  : traveller.longitude,
              },
            ]}
            events={loadEvents()}
            zoomLevel={10}
          />
        )}
        {loading && <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />}

        {!laymanMode && (
          <button className="side-panel-link" onClick={() => showAddressSidePanel()}>
            {I18n.t('admin.components.travellers.overview.left_panel.links.addresses')}
          </button>
        )}
        <SidePanel
          title={I18n.t('admin.components.travellers.overview.left_panel.addresses_and_locations')}
          body={addressesBody}
          isOpened={viewAddressSidePanelVisible}
          onClick={() => hideSidePanel()}
        />
      </div>
      {laymanMode && (
        <Fragment>
          <div className="col-grid direction-row align-center col-bleed traveller-overview-left-panel__communication-value">
            <Icon name="send" color="default" size="small" />
            <div className="traveller-overview-left-panel__communication-value--with-padding">
              <button className="side-panel-link" onClick={() => shareGeoLocation()}>
                {I18n.t('admin.components.travellers.overview.left_panel.share_location')}
              </button>
            </div>
          </div>
        </Fragment>
      )}

      {traveller.spokenLanguages && (
        <div className="col-grid col-bleed traveller-overview-left-panel__communication traveller-overview-left-panel__communication--languages-padding">
          <div className="traveller-overview-left-panel__communication-header">
            {I18n.t('admin.components.travellers.overview.left_panel.spoken_languages')}
          </div>
          <div className="col-grid col-bleed traveller-overview-left-panel__communication-value">
            {traveller.spokenLanguages.map(language => (
              <div
                key={language}
                className="col-grid direction-row align-center col-bleed traveller-overview-left-panel__document-card-info"
              >
                <Icon name="globe" color="default" size="small" />
                <div className="traveller-overview-left-panel__communication-value--with-padding">
                  {language}
                </div>
              </div>
            ))}
            {traveller.spokenLanguages.length === 0 &&
              I18n.t(
                'admin.components.travellers.edit.general.information.placeholder.no_spoken_language',
              )}
          </div>
        </div>
      )}
      {laymanMode && traveller.correspondenceLanguage && (
        <div className="col-grid col-bleed traveller-overview-left-panel__communication traveller-overview-left-panel__communication--languages-padding">
          <div className="traveller-overview-left-panel__communication-header">
            {I18n.t('activerecord.attributes.address.correspondence_language')}
          </div>
          <div className="col-grid col-bleed traveller-overview-left-panel__communication-value">
            <div className="col-grid direction-row align-center col-bleed traveller-overview-left-panel__document-card-info">
              <Icon name="globe" color="default" size="small" />
              <div className="traveller-overview-left-panel__communication-value--with-padding">
                {traveller.correspondenceLanguage}
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />}
    </div>
  );
};

LeftPanel.defaultProps = {
  traveller: {},
  laymanMode: false,
  progressBarColor: '#6687B5',
  setShareLocationError: () => {},
};

LeftPanel.propTypes = {
  traveller: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    nickName: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    avatarUrl: PropTypes.string,
    profileCompletionPercentage: PropTypes.number,
    organizationPath: PropTypes.string,
    socialMediaLinks: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
    identificationDocuments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        flagUrl: PropTypes.string,
        nationality: PropTypes.string,
        expiresIn: PropTypes.number,
        showWarning: PropTypes.bool,
        showDanger: PropTypes.bool,
      }),
    ),
    spokenLanguages: PropTypes.arrayOf(PropTypes.string),
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        zipCode: PropTypes.string,
        city: PropTypes.string,
        type: PropTypes.string,
        primary: PropTypes.bool,
      }),
    ),
    primaryAddress: PropTypes.shape({
      id: PropTypes.number,
      line1: PropTypes.string,
      line2: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      type: PropTypes.string,
    }),
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        primary: PropTypes.bool,
        number: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    primaryPhoneNumber: PropTypes.shape({
      id: PropTypes.number,
      number: PropTypes.string,
      type: PropTypes.string,
    }),
    emailAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        primary: PropTypes.bool,
        email: PropTypes.string,
      }),
    ),
    primaryEmailAddress: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      type: PropTypes.string,
    }),
    verificationStatus: PropTypes.string,
    correspondenceLanguage: PropTypes.string,
    preferredCommunication: PropTypes.string,
    organization: PropTypes.shape({
      id: PropTypes.number,
      avatarUrl: PropTypes.string,
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    emergencyContacts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        number: PropTypes.string,
      }),
    ),
    addressCoordinates: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  }),
  laymanMode: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  progressBarColor: PropTypes.string,
  setShareLocationError: PropTypes.func,
};

export default withQueryParamsProvider(LeftPanel);
