import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { fetchJourneyElement } from './journeyElements';

export const CART_STATUS_OPEN = 'open';
export const CART_STATUS_LOCKED = 'locked';
export const CART_STATUS_BOOKED = 'booked';
export const CART_STATUS_PAID = 'paid';

export const CART_STATUS_FETCHED = 'CART_STATUS_FETCHED';
export function cartStatusFetched(status) {
  return {
    type: CART_STATUS_FETCHED,
    status,
  };
}

function updateJourneyElements(cartData, dispatch, getState) {
  const promiseChain = [];
  cartData.cartItems.forEach(item => {
    if (item.status === 'success') {
      const cartItems = getState().common.cart.items;

      if (cartItems.length > 0) {
        const matchedItem = cartItems.find(currentCartItem => currentCartItem.id === item.id);

        promiseChain.push(
          dispatch(
            fetchJourneyElement(matchedItem.journeyElementType, matchedItem.journeyElementId, true),
          ),
        );
      }
    }
  });

  return Promise.all(promiseChain);
}

export function fetchCartStatus(id, callbackParams) {
  return (dispatch, getState) =>
    httpClient
      .get(routes.api.carts.bookingStatus({ cartId: id, callbackParams }))
      .then(({ data }) =>
        updateJourneyElements(data, dispatch, getState).then(() =>
          dispatch(cartStatusFetched(data)),
        ),
      );
}
