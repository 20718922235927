import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchJourneyElement } from '../../actions/common';

const mapStateToProps = (state, props) => {
  let journeyElement;

  const item = props.item;

  const journeyElements = state.common.journeyElements;
  if (journeyElements[item.journeyElementType]) {
    journeyElement = journeyElements[item.journeyElementType][item.journeyElementId.toString()];
  }

  return {
    journeyElement,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchJourneyElement: (type, id) => dispatch(fetchJourneyElement(type, id)),
});

export default function withJourneyElement(RealComponent) {
  const classWithJourneyElement = class extends React.Component {
    static propTypes = {
      item: PropTypes.shape({
        hasJourneyElement: PropTypes.bool.isRequired,
        journeyElementType: PropTypes.string,
        journeyElementId: PropTypes.number,
      }).isRequired,
      fetchJourneyElement: PropTypes.func.isRequired,
      journeyElement: PropTypes.shape(),
    };
    static defaultProps = { journeyElement: undefined };

    componentDidMount() {
      this.fetchJourneyElementIfNeeded();
    }

    componentDidUpdate() {
      this.fetchJourneyElementIfNeeded();
    }

    fetchJourneyElementIfNeeded = () => {
      const item = this.props.item;
      if (item.hasJourneyElement && !this.props.journeyElement) {
        this.props.fetchJourneyElement(item.journeyElementType, item.journeyElementId);
      }
    };

    render() {
      return <RealComponent {...this.props} />;
    }
  };

  return connect(mapStateToProps, mapDispatchToProps)(classWithJourneyElement);
}
