import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@wtag/react-comp-lib';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';
import OrderCard from './OrderCard';
import '../styles.scss';

const Orders = props => {
  const {
    iconColor,
    iconLabel,
    linkText,
    fetchOrders,
    scope,
    perPage,
    relativePath,
    sortedColumn,
    direction,
  } = props;
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onFetchOrders = () => {
    fetchOrders(scope, perPage, sortedColumn, direction).then(data => {
      setOrders(data.orders);
      setIsLoading(false);
    });
  };

  const placeholder = () => (
    <div className="col-grid direction-row">
      <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} />
      <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} />
    </div>
  );

  const EmptyContentPlaceholder = (
    <div className="orders-overview__trips-empty-placeholder">
      <Icon name="add" showBGColor={true} color="tertiary" size="small" />
      <span className="orders-overview__trips-empty-placeholder-label">
        {I18n.t('public.components.orders.not_available')}
      </span>
    </div>
  );

  useEffect(() => {
    onFetchOrders();
  }, []);

  return (
    <div className="orders-overview__trips">
      <Card version="v2">
        <div className="col-grid direction-row col-bleed justify-space-between orders-overview__trips-header wrap">
          <Icon
            name="today"
            showBGColor={true}
            showLabel={true}
            iconLabel={iconLabel}
            color={iconColor}
          />
          <Link
            modifier="default"
            size="small"
            href={relativePath}
            className="orders-overview__trips-header-view-all"
          >
            {linkText}
          </Link>
        </div>
        <div className="orders-overview__card-wrapper">
          {orders.map(order => (
            <div key={order.id}>
              <OrderCard key={order.id} order={order} />
            </div>
          ))}
          {isLoading && placeholder()}
        </div>
        {!isLoading && !orders.length && EmptyContentPlaceholder}
      </Card>
    </div>
  );
};

Orders.propTypes = {
  linkText: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  iconLabel: PropTypes.string.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  relativePath: PropTypes.string.isRequired,
  sortedColumn: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
};

export default Orders;
