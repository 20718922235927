import React, { useEffect } from 'react';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import { ContentLoaderPlaceholder } from '@wtag/react-comp-lib';
import DateTime from 'sharedWebpack/DateTime';
import { DATE_FORMAT_SHORT } from 'sharedWebpack/helpers/dateTime';
import useViewMode from 'sharedWebpack/useViewMode';
import ShoppingCartIconWithBadge from 'sharedWebpack/IBE/components/ShoppingCartIconWithBadge';
import { DESKTOP_DEVICE } from 'sharedWebpack/old/libraries/responsiveHelpers';
import LocationIconWithLabel from './subComponents/LocationIconWithLabel';
import AirportShape from '../../shapes/AirportShape';
import './styles.scss';

const getDateTimeValue = dateTimeValue => {
  if (!dateTimeValue) {
    return null;
  }

  return (
    <DateTime dateTime={dateTimeValue} format={DATE_FORMAT_SHORT} displayWithoutConversion={true} />
  );
};

const CarHeaderAttributeItem = ({ header, value }) => (
  <div className="d-flex-col row-gap-4">
    <div className="car-search-header__attribute-header">{header}</div>
    {value ? (
      <div className="car-search-header__attribute-value">{value}</div>
    ) : (
      <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
    )}
  </div>
);

CarHeaderAttributeItem.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const CarSearchHeader = ({
  isCollapsed,
  onModifySearch,
  searchParams,
  expand,
  onExpand,
  cartId,
  cartItemsCount,
  onCartClick,
  showContinueButton,
  onMatrixPageRender,
  airports,
  onFetchAirportDetails,
}) => {
  const {
    pickUpLocationAirportCode,
    dropOffLocationAirportCode,
    pickUpGeoLocationAddress,
    dropOffGeoLocationAddress,
    pickUpDate,
    dropOffDate,
    driverAge,
    airportSearch,
  } = searchParams;

  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;
  const chevronIconName = expand ? 'iconUpChevron' : 'iconDownChevron';
  const modifyButtonLabelInDesktopView = isCollapsed
    ? I18n.t('components.ibe.search_form.label.modify_search')
    : I18n.t('components.ibe.search_form.label.hide_search');
  const modifyButtonLabelInMobileView = isCollapsed
    ? I18n.t('components.ibe.search_form.label.modify')
    : I18n.t('components.ibe.search_form.label.hide');

  const headerButtonLabel = isLargeScreen
    ? modifyButtonLabelInDesktopView
    : modifyButtonLabelInMobileView;
  const continueButtonLabel = isLargeScreen ? (
    I18n.t('components.ibe.search_form.label.continue')
  ) : (
    <Icon name="arrowRight" />
  );

  let pickUpLocation = pickUpGeoLocationAddress;
  let dropOffLocation = dropOffGeoLocationAddress;

  if (airportSearch) {
    pickUpLocation = airports[pickUpLocationAirportCode]?.name;
    dropOffLocation = airports[dropOffLocationAirportCode]?.name;
  }

  useEffect(() => {
    if (airportSearch) {
      onFetchAirportDetails(pickUpLocationAirportCode);
      onFetchAirportDetails(dropOffLocationAirportCode);
    }
  }, [airportSearch]);

  return (
    <Card className="car-search-header__card">
      <div className="col-grid direction-row col-bleed car-search-header__trip-info justify-between hidden-md hidden-sm hidden-xs hidden-xxs">
        <div className="d-flex column-gap-56">
          <CarHeaderAttributeItem
            header={I18n.t('components.ibe.search_form.car.label.capital_pick_up_location')}
            value={pickUpLocation}
          />
          <CarHeaderAttributeItem
            header={I18n.t('components.ibe.search_form.car.label.capital_drop_off_location')}
            value={dropOffLocation}
          />
        </div>
        <div className="d-flex column-gap-48">
          <CarHeaderAttributeItem
            header={I18n.t('components.ibe.car_results.pick_up')}
            value={getDateTimeValue(pickUpDate)}
          />
          <CarHeaderAttributeItem
            header={I18n.t('components.ibe.car_results.drop_off')}
            value={getDateTimeValue(dropOffDate)}
          />
          <CarHeaderAttributeItem
            header={I18n.t('components.ibe.search_form.car.label.driver_age')}
            value={driverAge}
          />
        </div>
      </div>
      <LocationIconWithLabel
        className="car-search-header__trip-info--mobile"
        loaderClassName="car-search-header__location-icon--loader"
        value={pickUpLocation}
      />
      <div className="car-search-header__button-content d-flex align-center">
        <Button
          className="car-search-header__button"
          onClick={() => onModifySearch(!isCollapsed)}
          version="v2"
          size="tiny"
          label={headerButtonLabel}
        />
        {showContinueButton && (
          <Button
            className="car-search-header__button--continue"
            version="v2"
            size="tiny"
            label={continueButtonLabel}
            type="primary"
            onClick={onMatrixPageRender}
          />
        )}
        <IconButton
          className="car-search-header__icon-button car-search-header__icon-button--cart"
          icon={<ShoppingCartIconWithBadge cartId={cartId} cartItemsCount={cartItemsCount} />}
          label={I18n.t('components.ibe.search_form.label.cart')}
          onClick={onCartClick}
          disabled={!cartId}
          version="v2"
          size="xs"
        />
        <IconButton
          className="car-search-header__icon-button"
          icon={<Icon name={chevronIconName} showBGColor={true} size="tiny" />}
          size="xs"
          onClick={() => onExpand(prev => !prev)}
        />
      </div>
    </Card>
  );
};

CarSearchHeader.defaultProps = {
  cartId: null,
  cartItemsCount: 0,
  showContinueButton: false,
  onCartClick: () => {},
  onMatrixPageRender: () => {},
};

CarSearchHeader.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onModifySearch: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    pickUpGeoLocationAddress: PropTypes.string,
    dropOffGeoLocationAddress: PropTypes.string,
    pickUpDate: PropTypes.string,
    dropOffDate: PropTypes.string,
    driverAge: PropTypes.number,
    airportSearch: PropTypes.bool,
    pickUpLocationAirportCode: PropTypes.string,
    dropOffLocationAirportCode: PropTypes.string,
  }).isRequired,
  expand: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  cartId: PropTypes.string,
  cartItemsCount: PropTypes.number,
  onCartClick: PropTypes.func,
  showContinueButton: PropTypes.bool,
  onMatrixPageRender: PropTypes.func,
  airports: PropTypes.shape({
    [PropTypes.string]: AirportShape,
  }).isRequired,
  onFetchAirportDetails: PropTypes.func.isRequired,
};

export default CarSearchHeader;
