/* global I18n */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18nText, Spinner, Textarea } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import authenticityToken from 'sharedWebpack/old/libraries/authenticityToken';

const OfferEnquiryForm = props => {
  const { dealId, personId, setIsHeaderVisible, onEnquireModalClose } = props;
  const [description, setDescription] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  let information;

  const createEnquiry = async () => {
    setIsFormVisible(false);
    setIsHeaderVisible(false);
    setIsLoading(true);

    const enquiryParams = {
      deal_id: dealId,
      first_name: firstName,
      last_name: lastName,
      email,
      body: description,
      person_id: personId,
    };

    const { data } = await httpClient.post(routes.public.offerEnquiries(), {
      offer_enquiry: enquiryParams,
    });

    setIsLoading(false);

    if (data.error) {
      setError(data.error);
    }
  };

  const handleSubmit = () => {
    setTouched(!description || !firstName || !lastName || !email);

    const isAllDataProvided = personId
      ? description
      : description && firstName && lastName && email;

    if (isAllDataProvided) {
      createEnquiry();
    }
  };

  if (personId) {
    information = <input type="hidden" value={personId} name="offer_enquiry[person_id]" />;
  } else {
    information = (
      <Fragment>
        <div className="col-4">
          <Input
            size="small"
            value={firstName}
            required={true}
            onChange={value => {
              setFirstName(value);
            }}
            type="text"
            label={I18n.t('activerecord.attributes.person.first_name')}
            error={!firstName && I18n.t('public.offer_enquiries.form.blank_title')}
            touched={touched}
            placeholder={I18n.t('simple_form.placeholders.offer_enquiry.first_name')}
            isClearable={true}
            name="offer_enquiry[first_name]"
            id="offer_enquiry_first_name"
          />
        </div>
        <div className="col-4">
          <Input
            size="small"
            value={lastName}
            required={true}
            onChange={value => {
              setLastName(value);
            }}
            type="text"
            label={I18n.t('activerecord.attributes.person.last_name')}
            error={!lastName && I18n.t('public.offer_enquiries.form.blank_title')}
            touched={touched}
            placeholder={I18n.t('simple_form.placeholders.offer_enquiry.last_name')}
            isClearable={true}
            name="offer_enquiry[last_name]"
            id="offer_enquiry_last_name"
          />
        </div>
        <div className="col-4">
          <Input
            size="small"
            value={email}
            required={true}
            onChange={value => {
              setEmail(value);
            }}
            type="text"
            label={I18n.t('activerecord.attributes.address.email')}
            error={!email && I18n.t('public.offer_enquiries.form.blank_title')}
            touched={touched}
            placeholder={I18n.t('simple_form.placeholders.email_addresses.email')}
            isClearable={true}
            name="offer_enquiry[email]"
            id="offer_enquiry_email"
          />
        </div>
      </Fragment>
    );
  }

  const baseModalContent = () => {
    if (isLoading) {
      return (
        <div className="offer-enquiry-form__loader-container">
          <Spinner size="large" bgColor="neutral" color="success" />
          <span className="offer-enquiry-form__message">
            {I18n.t('components.ibe.deal.enquiry_submitting')}
          </span>
        </div>
      );
    } else if (error) {
      return (
        <Fragment>
          <Icon size="huge" name="highPriority" showBGColor={true} color="danger" />
          <span className="offer-enquiry-form__confirmation-message">
            {I18n.t('components.ibe.deal.enquiry_submission', {
              message: 'failed',
            })}
          </span>
          <Button
            className="offer-enquiry-form__action-button"
            size="medium"
            label={<I18nText id="ibe.deal.retry" returnStringOnly={true} />}
            onClick={onEnquireModalClose}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Icon size="huge" name="check" showBGColor={true} color="success" />
        <span className="offer-enquiry-form__confirmation-message">
          {I18n.t('components.ibe.deal.enquiry_submission', {
            message: 'successful',
          })}
        </span>
        <Button
          className="offer-enquiry-form__action-button"
          size="medium"
          label={<I18nText id="ibe.deal.retry" returnStringOnly={true} />}
          onClick={onEnquireModalClose}
        />
      </Fragment>
    );
  };

  return (
    <div className="col-12 col-bleed offer-enquiry-form">
      {isFormVisible ? (
        <div className="grid grid-gap-20">
          <input type="hidden" name="authenticity_token" value={authenticityToken} />
          <input type="hidden" value={dealId} name="offer_enquiry[deal_id]" />
          {information}
          <div className="col-12">
            <Textarea
              label={I18n.t('public.offer_enquiries.form.message')}
              placeholder={I18n.t('public.offer_enquiries.form.placeholder')}
              error={!description && I18n.t('public.offer_enquiries.form.blank_title')}
              size="normal"
              touched={touched}
              value={description}
              required={true}
              onChange={event => {
                setDescription(event.target.value);
              }}
              name="offer_enquiry[body]"
              id="offer_enquiry_body"
            />

            <Button
              version="v2"
              size="small"
              label={I18n.t('public.offer_enquiries.form.send')}
              className="offer-enquiry-form__action-button"
              name="commit"
              onClick={handleSubmit}
            />
          </div>
        </div>
      ) : (
        <div className="offer-enquiry-form__body">{baseModalContent()}</div>
      )}
    </div>
  );
};

OfferEnquiryForm.defaultProps = {
  personId: null,
  setIsHeaderVisible: () => {},
  onEnquireModalClose: () => {},
};

OfferEnquiryForm.propTypes = {
  dealId: PropTypes.node.isRequired,
  personId: PropTypes.number,
  setIsHeaderVisible: PropTypes.func,
  onEnquireModalClose: PropTypes.func,
};

export default OfferEnquiryForm;
