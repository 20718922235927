import React from 'react';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';

const EmptyCart = () => (
  <div className="empty-cart">
    <Card
      version="v2"
      size="large"
      color="tertiary"
      emptyCardImageSrc={<Icon name="shoppingCart" />}
      emptyCardText={I18n.t('components.ibe.cart_empty.text')}
    />
  </div>
);

export default EmptyCart;
