import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const VENDORS_SEARCHED = 'VENDORS_SEARCHED';
function vendorsSearched(vendors) {
  return {
    type: VENDORS_SEARCHED,
    vendors,
  };
}

export function searchVendors(query) {
  return dispatch => {
    const url = routes.api.cars.vendors({ q: query });
    return httpClient.get(url).then(({ data }) => dispatch(vendorsSearched(data)));
  };
}
