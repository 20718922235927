import PropTypes from 'prop-types';

export const byTokenShape = {
  [PropTypes.string]: PropTypes.bool,
};

export const uniqueCCTokenShape = PropTypes.oneOfType([
  PropTypes.instanceOf(null),
  PropTypes.string,
]);

export const bookingRequestsEncodedByTokenShape = {
  [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
};

export const bookingComponentRefByTokenShape = {
  [PropTypes.string]: {
    current: PropTypes.shape({
      submitCreditCardAction: PropTypes.func,
      initiateThreeDSAuthentication: PropTypes.func,
    }).isRequired,
  },
};
