import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import Amount from 'sharedWebpack/Amount';
import { Title, Text } from '@wtag/rcl-typography';
import { EmphasisTag } from '@wtag/react-comp-lib';
import classNames from 'classnames';
import ProductIcon from 'sharedWebpack/ProductIcon';
import staticProductShape from '../shapes/staticProduct';
import StaticProductDescription from './ProductDescription';

const StaticProductCard = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const product = props.details;

  const interests = product.interests ? product.interests.map(value => value.name) : [];

  const onClickProduct = () => {
    props.addToCart(product, props.callbackParams, () => {
      props.setOnSearchStarted(true);
    });
  };

  return (
    <Fragment>
      <Card className="static-product-card" shadow="dark" size="full">
        <div
          className={classNames('static-product-card__image-container', {
            'static-product-card__image-container--laymanmode-height':
              props.laymanMode && !props.advancedForm,
          })}
          onClick={openModal}
          role="presentation"
        >
          <img
            className="static-product-card__image-view"
            src={product.pictureUrl}
            alt={product.name}
          />

          <div className="static-product-card__image-overlay">
            <div className="col-12 static-product-card__price-details">
              {product.inStock === 0 && (
                <div
                  className={classNames({
                    'col-6 col-bleed-x': props.laymanMode,
                    'col-6': !props.laymanMode,
                  })}
                >
                  <EmphasisTag
                    text={I18n.t('components.ibe.search_form.static.out_stock')}
                    type="danger"
                    size="tiny"
                  />
                </div>
              )}

              <div
                className={classNames('col-12', {
                  'col-6': product.inStock === 0,
                })}
              >
                <div className="static-product-card__amount">
                  <Title
                    className={classNames({
                      'static-product-card__amount--laymanmode-font': props.laymanMode,
                    })}
                    weight="medium"
                    level={3}
                  >
                    <Amount
                      currency={product.currency}
                      value={parseFloat(product.price) + parseFloat(product.vatAmount)}
                    />
                  </Title>
                </div>
                {product.vatPercentage && (
                  <div className="static-product-card__tax-percentage">
                    <Text
                      className="static-product-card__tax-percentage--text"
                      weight="light"
                      level={3}
                    >
                      {I18n.t('components.ibe.price.including_vat', {
                        vat: product.vatPercentage,
                      })}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'col-grid col-bleed justify-space-between static-product-card__content',
            {
              'static-product-card__content--laymanmode-height':
                props.laymanMode && !props.advancedForm,
            },
          )}
        >
          <div className="static-product-card__name" onClick={openModal} role="presentation">
            <Text
              className={classNames({
                'static-product-card__name--laymanmode-text-truncate':
                  props.laymanMode && !props.advancedForm,
              })}
              weight="bold"
              level={2}
            >
              {product.name}
            </Text>
          </div>
          <div className="static-product-card__footer">
            <div className="col-grid direction-row justify-space-between align-center col-bleed">
              <div className="col-6 col-bleed">
                <ProductIcon
                  productType={product.productType}
                  showBGColor={true}
                  color="tertiary"
                  size="tiny"
                />
              </div>
              <div className="col-grid col-6 col-bleed align-end">
                <Button
                  className="static-product-card__footer-button"
                  version="v2"
                  type="primary"
                  onClick={onClickProduct}
                  size="small"
                  label={I18n.t('components.ibe.shared.add_to_cart')}
                  disabled={product.inStock === 0}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <StaticProductDescription
        product={product}
        interests={interests}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        addToCart={props.addToCart}
        laymanMode={props.laymanMode}
      />
    </Fragment>
  );
};

StaticProductCard.defaultProps = {
  addToCart: null,
  setOnSearchStarted: () => {},
};

StaticProductCard.propTypes = {
  addToCart: PropTypes.func,
  details: staticProductShape.isRequired,
  advancedForm: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  setOnSearchStarted: PropTypes.func,
  callbackParams: PropTypes.shape({}).isRequired,
};

export default StaticProductCard;
