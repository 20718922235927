import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'timeago-react';

import { register } from 'timeago.js';
import moment from 'moment';
import { DateTime } from '@wtag/react-comp-lib';

// number: the timeago / timein number;
// index: the index of array below;

const localeFunc = (_number, index) =>
  [
    [I18n.t('components.time_ago.index.just_now'), I18n.t('components.time_ago.index.right_now')],
    [
      I18n.t('components.time_ago.index.few_seconds_ago', { time: _number }),
      I18n.t('components.time_ago.index.in_few_seconds', { time: _number }),
    ],
    [
      I18n.t('components.time_ago.index.one_minute_ago'),
      I18n.t('components.time_ago.index.in_one_minute'),
    ],
    [
      I18n.t('components.time_ago.index.few_minutes_ago', { time: _number }),
      I18n.t('components.time_ago.index.in_few_minutes', { time: _number }),
    ],
    [
      I18n.t('components.time_ago.index.one_hour_ago'),
      I18n.t('components.time_ago.index.in_one_hour'),
    ],
    [
      I18n.t('components.time_ago.index.few_hours_ago', { time: _number }),
      I18n.t('components.time_ago.index.in_few_hours', { time: _number }),
    ],
    [
      I18n.t('components.time_ago.index.one_day_ago'),
      I18n.t('components.time_ago.index.in_one_day'),
    ],
    [
      I18n.t('components.time_ago.index.few_days_ago', { time: _number }),
      I18n.t('components.time_ago.index.in_few_days', { time: _number }),
    ],
    [
      I18n.t('components.time_ago.index.one_week_ago'),
      I18n.t('components.time_ago.index.in_one_week'),
    ],
    [
      I18n.t('components.time_ago.index.few_weeks_ago', { time: _number }),
      I18n.t('components.time_ago.index.in_few_weeks', { time: _number }),
    ],
    [
      I18n.t('components.time_ago.index.one_month_ago'),
      I18n.t('components.time_ago.index.in_one_month'),
    ],
    [
      I18n.t('components.time_ago.index.few_months_ago', { time: _number }),
      I18n.t('components.time_ago.index.in_few_months', { time: _number }),
    ],
    [
      I18n.t('components.time_ago.index.one_year_ago'),
      I18n.t('components.time_ago.index.in_one_year'),
    ],
    [
      I18n.t('components.time_ago.index.few_years_ago', { time: _number }),
      I18n.t('components.time_ago.index.in_few_years', { time: _number }),
    ],
  ][index];

register(I18n.locale, localeFunc);

const WrappedTimeAgo = props => {
  const { datetime, withRclDateComponent, withShortFormat } = props;

  const mapLanguageCode = () => {
    const currentLocale = I18n.locale;
    return currentLocale.slice(0, 2);
  };

  const useFormattedDateTime = () => {
    const passedDate = moment(datetime);
    const currentDate = moment();
    const differenceBetweenDates = currentDate.diff(passedDate, 'days');
    let formattedDateTime;

    if (differenceBetweenDates <= 1) {
      const isSimilarDay = moment(datetime).format('L') === moment().format('L');

      if (isSimilarDay && withShortFormat) {
        formattedDateTime = <span>{moment(datetime).format('LT')}</span>;
      } else if (!isSimilarDay && withShortFormat) {
        formattedDateTime = <span>{moment(datetime).format('ddd, LT')}</span>;
      } else {
        formattedDateTime = <TimeAgo className="date-time" datetime={datetime} />;
      }
    } else if (differenceBetweenDates <= 7) {
      formattedDateTime = withShortFormat ? (
        <span>{moment(datetime).format('ddd, LT')}</span>
      ) : (
        <span>{moment(datetime).format('dddd, LT')}</span>
      );
    } else {
      formattedDateTime = <DateTime dateTime={datetime} locales={mapLanguageCode()} />;
    }

    return formattedDateTime;
  };

  const dateField = withRclDateComponent ? (
    useFormattedDateTime()
  ) : (
    <TimeAgo className="date-time" locale={I18n.locale} datetime={datetime} />
  );

  return dateField;
};

WrappedTimeAgo.defaultProps = {
  withRclDateComponent: false,
  withShortFormat: false,
};

WrappedTimeAgo.propTypes = {
  datetime: PropTypes.string.isRequired,
  withRclDateComponent: PropTypes.bool,
  withShortFormat: PropTypes.bool,
};

export default WrappedTimeAgo;
