import React from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import { I18nText } from '@wtag/react-comp-lib';
import SelectBox from '@wtag/rcl-select-box';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import Modal from 'sharedWebpack/Modal';
import './style.scss';

class AccountingType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccountingType: null,
      selectedCostCenter: null,
      customCostCenterList: [],
      allocatedPercentage: 100,
      isModalVisible: false,
    };
  }

  onModalOpen = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  onModalClose = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  onAccountingTypeChange = selectedOption => {
    if (selectedOption) {
      this.setAccountingTypeAndFetchCostCenter(selectedOption.value);
    } else {
      this.setState({ selectedAccountingType: null, selectedCostCenter: null });
    }
  };

  onCostCenterChange = selectedOption => {
    if (selectedOption) {
      this.setCostCenter(selectedOption.value);
    } else {
      this.setState({ selectedCostCenter: null });
    }
  };

  setAccountingTypeAndFetchCostCenter = accountingTypeID => {
    const selectedOption = this.accountingTypeOptions().find(
      accType => accType.value === accountingTypeID,
    );
    this.setState({ selectedAccountingType: selectedOption, selectedCostCenter: null });
    this.props.fetchCostCenterOfAccountingType(accountingTypeID);
  };

  setCostCenter = costCenterValue => {
    const costCenterList = this.costCenterOptions();
    const selectedOption = costCenterList.find(costCenter => costCenter.value === costCenterValue);
    this.setState({ selectedCostCenter: selectedOption });
  };

  setallocatedPercentage = value => {
    let newValue = value;

    if (newValue > 100) {
      newValue = 100;
    }

    if (newValue < 0) {
      newValue = 0;
    }

    this.setState({ allocatedPercentage: newValue });
  };

  handleCreate = inputValue => {
    const customCostCenterList = this.state.customCostCenterList;
    customCostCenterList.unshift({ label: inputValue, value: inputValue });
    this.setState({ customCostCenterList });
    this.setCostCenter(inputValue);
  };

  accountingTypeOptions = () =>
    this.props.accountingTypes.map(accType => ({
      label: accType.name,
      value: accType.id,
    }));

  costCenterOptions = () => {
    const costcenters = this.props.costCenters.map(costCenter => ({
      label: costCenter.value,
      value: costCenter.id,
    }));
    return [...costcenters, ...this.state.customCostCenterList];
  };
  createCustomCostCenter = (options, search) => {
    if (search.length === 0 || options.map(option => option.label).indexOf(search) > -1) {
      return null;
    }
    return { label: search, value: search };
  };

  addCostCenter = () => {
    let customUsed = true;
    let selectedCostCenter = null;
    if (this.state.selectedCostCenter) {
      customUsed = this.state.customCostCenterList.some(
        costCenter => costCenter.value === this.state.selectedCostCenter.value,
      );
      selectedCostCenter = this.state.selectedCostCenter.value;
    }
    this.props.addCostCenter({
      id: selectedCostCenter,
      accountingTypeId: this.state.selectedAccountingType.value,
      custom: customUsed,
      allocatedPercentage: this.state.allocatedPercentage,
    });
    this.setState({
      isModalVisible: false,
      selectedAccountingType: null,
      selectedCostCenter: null,
      customCostCenterList: [],
      allocatedPercentage: 100,
    });
  };

  render() {
    const footerContent = (
      <Button
        size="small"
        version="v2"
        label={<I18nText id="ibe.cost_center.add" returnStringOnly={true} />}
        primary={true}
        onClick={this.addCostCenter}
        disabled={!this.state.selectedAccountingType}
      />
    );

    return (
      <div className="accounting-type">
        <Button
          className="accounting-type__add-new--button"
          label={<I18nText id="ibe.cost_center.add_new" returnStringOnly={true} />}
          version="v2"
          size="small"
          type="primary"
          onClick={this.onModalOpen}
        />
        <Modal
          className="accounting-type__modal"
          isModalVisible={this.state.isModalVisible}
          onOutsideClick={this.onModalClose}
          title={
            <div className="accounting-type__modal--title">
              <I18nText id="ibe.cost_center.title" />
              <div onClick={this.onModalClose} onKeyDown={this.onModalClose} role="presentation">
                <Icon name="close" />
              </div>
            </div>
          }
          footer={footerContent}
          alignFooter="left"
        >
          <div className="col-12 col-bleed accounting-type-form__select-box">
            <SelectBox
              label={<I18nText id="ibe.cost_center.accounting_types" returnStringOnly={true} />}
              options={this.accountingTypeOptions()}
              onChange={selectedOption => this.onAccountingTypeChange(selectedOption)}
              value={this.state.selectedAccountingType}
              width="full"
            />
          </div>
          <div className="col-12 col-bleed accounting-type-form__select-box">
            <SelectBox
              options={this.costCenterOptions().filter(
                option => !this.props.checkIfCostCenterExists({ value: option.label }),
              )}
              label={<I18nText id="ibe.cost_center.select_a_value" returnStringOnly={true} />}
              value={this.state.selectedCostCenter}
              isCreatable={true}
              onValueChange={this.saveCustomCostCenterInState}
              onCreateOption={this.handleCreate}
              onChange={selectedOption => this.onCostCenterChange(selectedOption)}
              isDisabled={!this.state.selectedAccountingType}
              width="full"
            />
          </div>
          <div className="col-12 col-bleed accounting-type-form__input">
            <Input
              onChange={this.setallocatedPercentage}
              type="number"
              label={
                <I18nText id="ibe.cost_center.allocation_percentage" returnStringOnly={true} />
              }
              maxValue={100}
              value={this.state.allocatedPercentage}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

AccountingType.defaultProps = {
  costCenters: [],
  accountingTypes: [],
};
AccountingType.propTypes = {
  costCenters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  accountingTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  fetchCostCenterOfAccountingType: PropTypes.func.isRequired,
  addCostCenter: PropTypes.func.isRequired,
  checkIfCostCenterExists: PropTypes.func.isRequired,
};

export default AccountingType;
