import React, { Fragment } from 'react';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import Badge from '@wtag/rcl-badge';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import useViewMode from 'sharedWebpack/useViewMode';
import TripDetailsItem from '../TripDetailsItem';
import { DESKTOP_DEVICE } from '../../../old/libraries/responsiveHelpers';
import HotelLocation from '../HotelLocation';
import getHotelStayInformation from './getHotelStayInformation';
import './styles.scss';

const ShoppingCart = ({ cartId, cartItemsCount }) => (
  <Fragment>
    <Icon name="shoppingCart" size="normal" />
    {cartId && cartItemsCount > 0 && (
      <Badge
        label={cartItemsCount}
        size="small"
        type="active"
        classNameForBadge="search__cart-button"
      />
    )}
  </Fragment>
);

ShoppingCart.defaultProps = {
  cartId: null,
  cartItemsCount: 0,
};

ShoppingCart.propTypes = {
  cartId: PropTypes.string,
  cartItemsCount: PropTypes.number,
};

const HotelSearchHeader = ({
  isCollapsed,
  onModifySearch,
  hotelSearchParams,
  expand,
  onExpand,
  cartId,
  cartItemsCount,
  onCartClick,
  isHotelDetailsPage,
}) => {
  const {
    adults = 1,
    children = 0,
    infants = 0,
    address,
    checkIn,
    checkOut,
    numberOfRooms,
  } = hotelSearchParams;

  const adult = I18n.t('admin.components.traveller_type.adult', { count: adults });
  const child =
    children > 0 && I18n.t('admin.components.traveller_type.child', { count: children });
  const infant =
    infants > 0 && I18n.t('admin.components.traveller_type.infant', { count: infants });
  const traveler = [adult, child, infant].filter(value => value).join(', ');
  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;
  const modifyButtonLabel = isCollapsed
    ? I18n.t('components.ibe.search_form.label.modify_search')
    : I18n.t('components.ibe.search_form.label.hide_search');
  const iconName = expand ? 'iconUpChevron' : 'iconDownChevron';
  const modifySearchButtonLabel = isLargeScreen ? modifyButtonLabel : <Icon name="edit" />;
  const hotelStayInformation = getHotelStayInformation(
    checkIn,
    checkOut,
    numberOfRooms,
    traveler,
    isHotelDetailsPage,
  );

  return (
    <Card className="hotel-search-header">
      <div className="hotel-search-header__trip-info">
        <HotelLocation address={address} />
        <div className="hotel-search-header__trip-details">
          {hotelStayInformation.map(({ title, subtitle }) => (
            <TripDetailsItem key={title} title={title} subtitle={subtitle} />
          ))}
        </div>
      </div>
      <div className="hotel-search-header__button-content d-flex align-center">
        <Button
          className="hotel-search-header__button"
          onClick={() => onModifySearch(!isCollapsed)}
          version="v2"
          size="small"
          label={modifySearchButtonLabel}
        />
        <IconButton
          className="hotel-search-header__icon-button hotel-search-header__icon-button--cart"
          icon={<ShoppingCart cartId={cartId} cartItemsCount={cartItemsCount} />}
          label={I18n.t('components.ibe.search_form.label.cart')}
          onClick={onCartClick}
          disabled={!cartId}
          version="v2"
          size="xs"
        />
        <IconButton
          className="hotel-search-header__icon-button"
          icon={<Icon name={iconName} showBGColor={true} size="tiny" />}
          size="xs"
          onClick={() => onExpand(previousExpandState => !previousExpandState)}
        />
      </div>
    </Card>
  );
};

HotelSearchHeader.defaultProps = {
  cartId: null,
  cartItemsCount: 0,
  onCartClick: () => {},
  isHotelDetailsPage: false,
};

HotelSearchHeader.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onModifySearch: PropTypes.func.isRequired,
  hotelSearchParams: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        destination: PropTypes.string,
        origin: PropTypes.string,
      }).isRequired,
    ),
    address: PropTypes.string,
    checkIn: PropTypes.string,
    checkOut: PropTypes.string,
    numberOfRooms: PropTypes.number,
  }).isRequired,
  expand: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  cartId: PropTypes.string,
  cartItemsCount: PropTypes.number,
  onCartClick: PropTypes.func,
  isHotelDetailsPage: PropTypes.bool,
};

export default HotelSearchHeader;
