import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import { Radio } from '@wtag/react-comp-lib';

const FrequentFlyerNumberLists = props => {
  const {
    frequentFlyerNumbersList,
    fields,
    frequentFlyerEditAction,
    selectedCustomer,
    travelerIndex,
    onCustomerSelected,
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const injectOnSelect = (flyerNumber, index, isEditAction = false) => {
    fields.number.onChange(flyerNumber.number);
    fields.carrier.onChange(flyerNumber.carrierCode);
    const updatedSelectedCustomer = {
      ...selectedCustomer,
      frequentFlyerNumber: { number: flyerNumber.number, carrier: flyerNumber.carrierCode },
    };

    onCustomerSelected(travelerIndex, updatedSelectedCustomer);
    setSelectedIndex(index);

    if (isEditAction) {
      frequentFlyerEditAction();
    }
  };

  const isFlyerSelected = (carrierCode, number, index) =>
    index === selectedIndex ||
    (carrierCode === fields.carrier.value && number === fields.number.value);

  return (
    <div className="frequent-flyer-numbers-list">
      {frequentFlyerNumbersList.map((flyerNumber, index) => (
        <Card
          key={flyerNumber.carrierCode}
          version="v2"
          size="full"
          className="frequent-flyer-numbers-list__card"
        >
          <div className="col-grid col-bleed direction-row align-center justify-space-between">
            <div className="col-grid col-bleed direction-row align-center wrap">
              <div className="frequent-flyer-numbers-list__card-radio">
                <Radio
                  size="huge"
                  name="flyer_number_card"
                  checked={isFlyerSelected(flyerNumber.carrierCode, flyerNumber.number, index)}
                  onChange={() => injectOnSelect(flyerNumber, index)}
                />
              </div>
              <div className="frequent-flyer-numbers-list__card-logo">
                <img src={flyerNumber.logo} alt={flyerNumber.carrierName} />
              </div>
              <div className="frequent-flyer-numbers-list__card-name">
                {flyerNumber.carrierName}
              </div>
              <div className="frequent-flyer-numbers-list__card-number">{flyerNumber.number}</div>
            </div>
            <div className="frequent-flyer-numbers-list__card-edit">
              <IconButton
                icon={<Icon name="edit" size="small" />}
                onClick={() => {
                  injectOnSelect(flyerNumber, index, true);
                }}
                label={I18n.t('shared.actions.edit')}
              />
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

FrequentFlyerNumberLists.propTypes = {
  onCustomerSelected: PropTypes.func.isRequired,
  frequentFlyerEditAction: PropTypes.func.isRequired,
  fields: PropTypes.shape().isRequired,
  frequentFlyerNumbersList: PropTypes.shape().isRequired,
  selectedCustomer: PropTypes.shape({
    frequentFlyerNumber: PropTypes.shape({
      carrier: PropTypes.string,
      number: PropTypes.string,
    }),
  }).isRequired,
  travelerIndex: PropTypes.number.isRequired,
};

export default FrequentFlyerNumberLists;
