import { connect } from 'react-redux';

import LineItem from './LineItem';
import { fetchJourneyElement } from '../actions/common';

const mapStateToProps = (state, props) => {
  let journeyElement;
  let product;

  const item = props.item;

  const journeyElements = state.common.journeyElements;
  if (journeyElements[item.journeyElementType]) {
    journeyElement = journeyElements[item.journeyElementType][item.journeyElementId.toString()];
  }

  const fetchingStaticProducts = state.common.staticProducts.isFetchingProducts;

  if (item.productId) {
    product = state.common.staticProducts.fetchedStaticProducts
      ? state.common.staticProducts.fetchedStaticProducts.find(
          currentProduct => currentProduct.id === item.productId,
        )
      : null;
  }

  return {
    journeyElement,
    product,
    fetchingStaticProducts,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchJourneyElement: (type, id) => dispatch(fetchJourneyElement(type, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LineItem);
