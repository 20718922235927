import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Tooltip from '@wtag/rcl-tooltip';
import useViewMode from 'sharedWebpack/useViewMode';
import { DESKTOP_DEVICE } from '../../../../../old/libraries/responsiveHelpers';

const FlightSeatIndicator = ({ sectionWithSeat, layman }) => {
  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;

  return (
    <div className="col-grid seat-info no-padding">
      <div className="seat-info__cabin">
        {`${I18n.t('components.flight_seat_map.legends')} `}
        {sectionWithSeat && I18n.t(sectionWithSeat.cabinClass, { scope: 'cabin_classes_seatmap' })}
      </div>
      <p className="seat-info__availability">
        <span className="seat-info__availability-status seat-info__availability-status--available" />
        {I18n.t('components.flight_seat_map.avaibility_info.available')}
      </p>
      <p className="seat-info__availability">
        <span className="seat-info__availability-status seat-info__availability-status--selected" />
        {I18n.t('components.flight_seat_map.avaibility_info.selected')}
      </p>
      <p className="seat-info__availability">
        <span className="seat-info__availability-status seat-info__availability-status--free" />
        {I18n.t('components.flight_seat_map.avaibility_info.free')}
      </p>
      <p className="seat-info__availability">
        <span className="seat-info__availability-status seat-info__availability-status--occupied" />
        {I18n.t('components.flight_seat_map.avaibility_info.occupied')}
      </p>
      <p className="seat-info__availability">
        <span className="seat-info__availability-status seat-info__availability-status--absent" />
        {I18n.t('components.flight_seat_map.avaibility_info.absent')}
      </p>
      <p className="seat-info__availability">
        <span className="seat-info__availability-status seat-info__availability-status--not_supported" />
        <span className="seat-info__availability-gap">
          {I18n.t('components.flight_seat_map.avaibility_info.not_supported')}
        </span>
        <Tooltip
          content={I18n.t(layman ? 'travel_app' : 'agent', {
            scope: 'components.ibe.seat_map.not_supported_seat_message',
          })}
          type="inverse"
          size="small"
          position={isLargeScreen ? 'top-left' : 'top-right'}
        >
          <Icon name="info" size="small" />
        </Tooltip>
      </p>
    </div>
  );
};

FlightSeatIndicator.propTypes = {
  sectionWithSeat: PropTypes.shape({
    cabinClass: PropTypes.string.isRequired,
  }).isRequired,
  layman: PropTypes.bool.isRequired,
};

export default FlightSeatIndicator;
