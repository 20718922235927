import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import HotelDefaultImage from 'agentSyncedImages/fallback_images/product/product_xxl_hotel.png';
import Rating from '../../Rating';
import HotelBookingStatus from '../../HotelBookingStatus';
import Stars from '../../../../components/Stars';
import './styles.scss';

const HotelAccordionContent = ({
  setPlaceholderImage,
  picture,
  rating,
  ratingSource,
  laymanMode,
  statusInformation,
  hotelName,
  hotelAddress,
  stars,
}) => (
  <div className="hotel-accordion-content">
    <div className="d-flex column-gap-12 hotel-accordion-content__body">
      <img
        className="hotel-accordion-content__body-image"
        onError={setPlaceholderImage}
        src={picture || HotelDefaultImage}
        alt="Hotel"
      />
      <div className="d-flex-column row-gap-8 hotel-accordion-content__details">
        <span className="hotel-accordion-content__title">{hotelName}</span>
        <Stars showStarsNumber={true} stars={stars} />
        {!!hotelAddress && (
          <div className="d-flex column-gap-4 hotel-accordion-content__address">
            <Icon name="location" size="small" />
            <span>{hotelAddress}</span>
          </div>
        )}
        {rating && (
          <div>
            <Rating
              rating={rating}
              className="hotel-accordion-content__rating"
              source={ratingSource}
            />
          </div>
        )}
      </div>
    </div>
    <HotelBookingStatus statusInformation={statusInformation} laymanMode={laymanMode} />
  </div>
);

HotelAccordionContent.defaultProps = {
  picture: null,
  rating: null,
  ratingSource: null,
  hotelAddress: '',
};

HotelAccordionContent.propTypes = {
  statusInformation: PropTypes.shape({
    refundable: PropTypes.bool,
    payLater: PropTypes.bool.isRequired,
    labels: PropTypes.shape({
      outOfPolicy: PropTypes.bool,
      preferred: PropTypes.bool,
    }).isRequired,
    approvalRequired: PropTypes.bool.isRequired,
    corporateDeal: PropTypes.bool.isRequired,
    policiesReasons: PropTypes.shape({
      outOfPolicy: PropTypes.string,
      approvalRequired: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  setPlaceholderImage: PropTypes.func.isRequired,
  picture: PropTypes.string,
  rating: PropTypes.number,
  ratingSource: PropTypes.string,
  laymanMode: PropTypes.bool.isRequired,
  hotelName: PropTypes.string.isRequired,
  hotelAddress: PropTypes.string,
  stars: PropTypes.number.isRequired,
};

export default HotelAccordionContent;
