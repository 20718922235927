import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';

import './styles.scss';

const OutOfPolicyLabel = ({ className, reason, type, size }) => {
  const outOfPolicyTag = (
    <EmphasisTag
      text={I18n.t('components.ibe.search_result.labels.out_of_policy')}
      type={type}
      size={size}
      className={className}
      icon={reason && <Icon className="out-of-policy-label__icon" name="invalidOutline" size="tiny" />}
    />
  );

  const content = reason ? (
    <Tooltip content={reason} position="bottom-left" type="inverse" showArrow={false}>
      {outOfPolicyTag}
    </Tooltip>
  ) : (
    outOfPolicyTag
  );

  return content;
};

OutOfPolicyLabel.defaultProps = {
  className: null,
  reason: null,
  type: "warning",
  size: "small",
};

OutOfPolicyLabel.propTypes = {
  className: PropTypes.string,
  reason: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
};

export default OutOfPolicyLabel;
