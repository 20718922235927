import {
  FLIGHT_MATRIXES_FETCHED,
  FLIGHT_GROUP_SELECTED,
} from '../../actions/flight';

export default function matrixesBySearchId(state = {}, action) {
  const newState = {
    ...state,
  };

  switch (action.type) {
    case FLIGHT_MATRIXES_FETCHED:
      newState[action.searchId] = action.data;

      return newState;

    case FLIGHT_GROUP_SELECTED:
      return {};

    default:
      return state;
  }
}

