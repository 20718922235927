import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '@wtag/rcl-typography';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';

import './styles.scss';

const LevelCards = ({ levels, selectedLevel, setSelectedLevel }) => {
  const permissions = ['book', 'read_profile', 'edit_profile', 'view_message', 'send_message'];
  const levelPermissions = {
    booking: ['book'],
    read: ['book', 'read_profile'],
    write: ['book', 'read_profile', 'edit_profile'],
    admin: ['book', 'read_profile', 'edit_profile', 'view_message', 'send_message'],
  };

  return levels.map(level => (
    <div
      role="presentation"
      onClick={() => setSelectedLevel(level)}
      className={classNames('col-sm-9 col-md-6 col-lg-3 send-invitation__level-cards', {
        'send-invitation__level-cards--selected': level === selectedLevel,
      })}
    >
      <Card version="v2" size="full">
        <div>
          <div className="send-invitation__level-cards-header">
            <Header level={5} weight="medium">
              {I18n.t(level, {
                scope: 'public.components.travel_arrangement_settings.send_invitation.level',
              })}
            </Header>
              {I18n.t(level, {
                scope: 'public.components.travel_arrangement_settings.send_invitation.level_types',
              })}
          </div>
          <hr />
          <div className="send-invitation__level-cards-body">
            {permissions.map(permission =>
              levelPermissions[level].find(levelPermission => levelPermission === permission) ? (
                <Icon
                  name="check"
                  color="tertiary"
                  showLabel={true}
                  showBGColor={true}
                  size="tiny"
                  iconLabel={I18n.t(permission, {
                    scope:
                      'public.components.travel_arrangement_settings.send_invitation.permission',
                  })}
                />
              ) : (
                <Icon
                  name="close"
                  color="default"
                  showLabel={true}
                  showBGColor={true}
                  size="tiny"
                  iconLabel={I18n.t(permission, {
                    scope:
                      'public.components.travel_arrangement_settings.send_invitation.permission',
                  })}
                />
              ),
            )}
          </div>
        </div>
      </Card>
    </div>
  ));
};

LevelCards.propTypes = {
  setSelectedLevel: PropTypes.func.isRequired,
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedLevel: PropTypes.number.isRequired,
};

export default LevelCards;
