import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { SidePanel } from '@wtag/react-comp-lib';
import { FormatDateTime, DATE_FORMAT_SHORT_WITH_TIME } from 'sharedWebpack/helpers/dateTime';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';
import AirportChangeSegment from 'sharedWebpack/AirportChangeSegment';

const StopoverSidePanel = props => {
  const { stopovers, airports, findAirport, showStopovers, onClick } = props;

  const [airportChangeMap, setAirportChangeMap] = useState(new Map());

  const viewAirportDetails = airport => {
    let airportDetails = airport.name;

    if (airport.placeName) {
      airportDetails += `, ${airport.placeName}`;
    }

    if (airport.countryName) {
      airportDetails += `, ${airport.countryName}`;
    }

    return airportDetails;
  };

  const stopoversWithAirportChanges = stopovers
    .filter(stopover => stopover.airportChange)
    .map(stopover => stopover.airportChange);

  useEffect(() => {
    const newAirportChangeMap = new Map();

    stopoversWithAirportChanges.forEach(stopover => {
      newAirportChangeMap.set(stopover.departureAirportCode, {
        arrival: stopover.arrivalAirportCode,
        departure: stopover.departureAirportCode,
      });
    });

    setAirportChangeMap(newAirportChangeMap);
  }, [stopovers]);

  return (
    <SidePanel
      title={I18n.t('components.flight.stopovers.title', {
        count: stopovers.length,
      })}
      body={stopovers.map(stopover => {
        const airport = airports[stopover.airportCode];
        const airportCode = airport && airport.code;
        const hasAirportChange = airportChangeMap.has(airportCode);
        const airportChangeData = hasAirportChange && airportChangeMap.get(airportCode);

        const stopoverArrivalLocalTime =
          stopover.arrivalLocaltime || stopover.arrivalLocaltimeInUserTz;

        return (
          <Card className="duration-stopover__card" key={stopover.airportCode} version="v2">
            <div className="col-grid col-bleed direction-row align-center justify-space-between flight-item-trip-stopover">
              <div className="flight-item-trip-stopover__country">
                {airport && (
                  <img
                    className="flight-item-trip-stopover__country__flag"
                    src={airport.countryFlag}
                    alt={airport.countryName}
                  />
                )}
                {stopover.airportCode}
              </div>
            </div>
            {airport && airport.name && (
              <div className="flight-item-trip-stopover__airport-name">
                {viewAirportDetails(airport)}
              </div>
            )}
            <div className="flight-item-trip-stopover__arrival-time">
              <DateTimeLocaleInterpolationWrapper
                localeKey="components.flight.stopover.arrival"
                time={FormatDateTime({
                  dateTime: stopoverArrivalLocalTime,
                  format: DATE_FORMAT_SHORT_WITH_TIME,
                })}
              />
            </div>
            {hasAirportChange && (
              <AirportChangeSegment
                destinationAirportDetails={findAirport(airportChangeData.arrival)}
                changedAirportDetails={findAirport(airportChangeData.departure)}
              />
            )}
          </Card>
        );
      })}
      isOpened={showStopovers}
      onClick={() => onClick(false)}
    />
  );
};

StopoverSidePanel.propTypes = {
  airports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  findAirport: PropTypes.func.isRequired,
  stopovers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showStopovers: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StopoverSidePanel;
