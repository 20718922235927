const assistanceRequestDescription = code =>
  I18n.t(code, {
    scope: 'components.ibe.traveler_details.assistance_requests',
  });

const assistanceRequestsOptionsBuilder = assistanceCodes =>
  assistanceCodes.map(code =>
    Object.assign({ value: code, label: assistanceRequestDescription(code.toLowerCase()) }),
  );

export default assistanceRequestsOptionsBuilder;
