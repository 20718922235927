import PropTypes from 'prop-types';

const BaggageLabel = props => {
  const numberOfBaggageIncluded = () => {
    let count = props.baggageInfo[0].count;
    if (!props.baggageInfo.every(baggage => baggage.count === count)) {
      count = 'mixedCount';
    }
    return count;
  };
  const weightPerBaggageIncluded = () => {
    let weight = props.baggageInfo[0].kgPerPiece;
    if (!props.baggageInfo.every(baggage => baggage.kgPerPiece === weight)) {
      weight = 'mixedWeight';
    }
    return weight;
  };

  const count = numberOfBaggageIncluded();
  const weight = weightPerBaggageIncluded();

  let message = I18n.t('components.ibe.search_result.no_baggage_included');

  if (count === 0) {
    return message;
  }

  if (count === undefined || count === null) {
    if (weight === 'mixedWeight') {
      message = I18n.t('components.ibe.search_result.mixed_weight_unknown_count');
    } else if (weight) {
      message = I18n.t('components.ibe.search_result.given_weight_unknown_count', { weight });
    }
  } else if (weight === undefined || weight === null) {
    if (count === 'mixedCount') {
      message = I18n.t('components.ibe.search_result.unknown_weight_mixed_count');
    } else if (count) {
      message = I18n.t('components.ibe.search_result.unknown_weight_given_count', { count });
    }
  } else if (count === 'mixedCount' && weight === 'mixedWeight') {
    message = I18n.t('components.ibe.search_result.mixed_count_mixed_weight');
  } else if (count === 'mixedCount' && weight !== 'mixedWeight') {
    message = I18n.t('components.ibe.search_result.mixed_count_given_weight', { weight });
  } else if (count !== 'mixedCount' && weight === 'mixedWeight') {
    message = I18n.t('components.ibe.search_result.given_count_mixed_weight', { count });
  } else if (count !== 'mixedCount' && weight !== 'mixedWeight') {
    message = I18n.t('components.ibe.search_result.given_count_given_weight', { weight, count });
  }
  return message;
};

BaggageLabel.propTypes = {
  baggageInfo: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      weight: PropTypes.number,
    }).isRequired,
  ).isRequired,
};

export default BaggageLabel;
