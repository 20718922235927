import remarkTypeBuilder from './remarkTypeBuilder';

const travelportRemarkTypes = [
  'alpha',
  'basic',
  'historical',
  'invoice',
  'itinerary',
  'vendor',
  'confidential',
];
const remarkTypeMapping = {
  galileo: [...travelportRemarkTypes],
  amadeus: ['corporate', 'confidential', 'general', 'quality_control'],
  sabre: [
    'general',
    'historical',
    'invoice',
    'itinerary',
    'alpha',
    'client_address',
    'corporate',
    'delivery_address',
    'group_name',
    'hidden',
  ],
  worldspan: [...travelportRemarkTypes, 'fop_comment'],
  apollo: [...travelportRemarkTypes],
  lh_group: ['general'],
  american_airlines: ['general'],
};

const generalRemarkTypes = channel => remarkTypeBuilder(remarkTypeMapping[channel]);

export default generalRemarkTypes;
