import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MatrixStep from './MatrixStep';
import './matrix-stepper.styles.scss';

const MatrixStepper = props => {
  const { steps, activeStepIndex, footer } = props;

  return (
    <div className="matrix-stepper">
      <div className="matrix-stepper__steps">
        {steps.map((step, index) => (
          <MatrixStep
            key={step.key}
            index={index}
            title={step.title}
            activeStepIndex={activeStepIndex}
            isDone={step.isDone}
            isLastStep={index === steps.length - 1}
          />
        ))}
      </div>
      {footer && (
        <Fragment>
          <div className="matrix-stepper__divider" />
          <div className="matrix-stepper__footer">{footer}</div>
        </Fragment>
      )}
    </div>
  );
};

MatrixStepper.defaultProps = {
  steps: [],
  footer: null,
};

MatrixStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      isDone: PropTypes.bool,
    }),
  ),
  activeStepIndex: PropTypes.number.isRequired,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default MatrixStepper;
