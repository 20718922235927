import { JOURNEY_ELEMENT_FETCHED } from '../../actions/common';
import deepClone from '../../lib/deepClone';

function journeyElements(state, action) {
  let newState;
  if (state === undefined) {
    newState = { flight: {}, hotel: {}, car: {}, seat: {}, service: {} };
  } else {
    newState = deepClone(state);
  }

  switch (action.type) {
    case JOURNEY_ELEMENT_FETCHED:
      newState[action.element.type][action.element.id.toString()] = action.element.data;
      return newState;

    default:
      return newState;
  }
}

export default journeyElements;
