import React from 'react';
import PropTypes from 'prop-types';

import AirlineLogo from './AirlineLogoContainer';

const AirlineLogos = props => {
  const carriers = new Set(props.carriers);

  const logos = Array.from(carriers.values()).map(carrier => (
    <AirlineLogo key={carrier} carrierCode={carrier} />
  ));

  return <div className={props.className}>{logos}</div>;
};

AirlineLogos.defaultProps = {
  className: null,
};

AirlineLogos.propTypes = {
  carriers: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

export default AirlineLogos;
