import { WARNING } from 'sharedWebpack/IBE/lib/helpers/componentStates';
import getBaggageItems from './getBaggageItems';

const getServiceList = (isServiceAvailable, uniqueBaggagePiecesTextList, allItemServices) => {
  const baggageItems = getBaggageItems(isServiceAvailable, uniqueBaggagePiecesTextList);
  const serviceItems = allItemServices.map(({ description }) => ({
    status: WARNING,
    title: description,
  }));

  return [...baggageItems, ...serviceItems];
};

export default getServiceList;
