import {
  CREATE_FLIGHT_SEARCH,
  FLIGHT_GROUP_SELECTED,
  RESET_FLIGHT_GROUP_SELECTION,
  FLIGHT_GROUP_DESELECTED,
  FLIGHT_GROUP_DESELECTED_ALL,
} from '../../actions/flight';

export default function selectedFlightGroups(state = {}, action) {
  const newState = {
    ...state,
  };

  switch (action.type) {
    case FLIGHT_GROUP_SELECTED:
      newState[action.group.tripId] = {
        id: action.group.id,
      };
      return newState;

    case FLIGHT_GROUP_DESELECTED:
      Object.keys(newState).forEach(item => {
        if (newState[item].id === action.groupId.id) delete newState[item];
      });

      return { ...newState };

    case FLIGHT_GROUP_DESELECTED_ALL:
      return {};

    case RESET_FLIGHT_GROUP_SELECTION:
      return {};

    case CREATE_FLIGHT_SEARCH:
      return {};

    default:
      return state;
  }
}
