import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Card from '@wtag/rcl-card';
import { Link } from '@wtag/react-comp-lib';
import { hot } from 'react-hot-loader';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import CurrentTravel from './CurrentTravel';
import UpcomingTravels from './UpcomingTravels';

const TravelOverview = ({ name, bookNowUrl, personId }) => {
  const [currentOrders, setCurrentOrders] = useState([]);
  const [upcomingOrders, setUpcomingOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const noTravelAvailable = () => !(currentOrders.length || upcomingOrders.length);

  const showBookNow = () => (
    <div className="travel-overview__book-now">
      <div className="travel-overview__book-now-brief">
        {I18n.t('public.components.dashboard.travel_overview.no_trip')}
      </div>
      <div className="travel-overview__book-now-title">
        {I18n.t('public.components.dashboard.travel_overview.plan_next_trip')}
      </div>
      <Link type="button" modifier="primary" size="small" href={bookNowUrl}>
        {I18n.t('public.components.top_nav.label.book_now')}
      </Link>
    </div>
  );

  const fetchOrders = scope =>
    httpClient.get(
      routes.public.orders.list({
        scope,
        person_id: personId,
        column: 'travel_starts_at',
        direction: 'up',
      }),
    );

  const onFetchOrders = async () => {
    let response = await fetchOrders('present_orders');
    setCurrentOrders(response.data.orders);

    response = await fetchOrders('upcoming_orders');
    setUpcomingOrders(response.data.orders);
    setIsLoading(false);
  };

  useEffect(() => {
    onFetchOrders();
  }, []);

  return (
    <Card version="v2">
      {isLoading ? (
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} />
      ) : (
        <div className="travel-overview">
          <div className="travel-overview__greeting">
            {I18n.t('public.components.dashboard.travel_overview.welcome', { name })}
          </div>
          {noTravelAvailable() ? (
            showBookNow()
          ) : (
            <div className="travel-overview__travels">
              {currentOrders.length > 0 && <CurrentTravel order={currentOrders[0]} />}
              {upcomingOrders.length > 0 && (
                <Fragment>
                  <div className="travel-overview__travels-upcoming-count">
                    {I18n.t('public.components.dashboard.travel_overview.upcoming_travel_count', {
                      count: upcomingOrders.length,
                    })}
                  </div>
                  <UpcomingTravels orders={upcomingOrders} />
                </Fragment>
              )}
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

TravelOverview.defaultProps = {
  name: '',
};

TravelOverview.propTypes = {
  name: PropTypes.string,
  bookNowUrl: PropTypes.string.isRequired,
  personId: PropTypes.number.isRequired,
};

export default hot(module)(TravelOverview);
