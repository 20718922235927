import React, {Fragment, useEffect, useState} from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import CheckBox from '@wtag/rcl-check-box';
import Amount from 'sharedWebpack/Amount';
import { ContentLoaderPlaceholder } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';
import FilterWrapper from '../FilterWrapper';
import FilterAction from './FilterAction';
import './style.scss';

export default function FilterSection({
  filterTitle,
  allOptions,
  airlinesInformations,
  tenantCurrency,
  filterResults,
  searchParameter,
  selectedOptions,
  onSelect,
  fetchAirlineInformations,
  isDividerVisible,
  currentSelection,
  isAllChecked,
  onAllChecked,
}) {
  const [selectedItems, setSelectedItems] = useState(selectedOptions || []);
  const [checkedItems, setCheckedItems] = useState(isAllChecked ? selectedOptions : []);

  const allOptionsChecked = allOptions.length === checkedItems.length;
  const allOptionsSelected = allOptions.length === selectedItems.length;

  useEffect(() => {
    allOptions.forEach(airline => {
      if (airline.code) {
        fetchAirlineInformations(airline.code);
      }
    });
  }, [allOptions]);

  const mapAllOptionsAsSelected = () =>
    allOptions.map(data => {
      if (typeof data === 'string') return data;
      if (data.identifier === 0) return 0;

      return data.identifier || data.code;
    });

  const handleCheckAll = () => {
    if (allOptions.length > 1) {
      const selectedData = mapAllOptionsAsSelected();

      setCheckedItems(selectedData);
      onAllChecked(true);
      setSelectedItems(selectedData);
    }
  };

  const handleUncheckAll = () => {
    if (selectedItems.length && allOptions.length > 1) {
      setCheckedItems([]);
      onAllChecked(false);
      setSelectedItems([]);
    }
  };

  const handleCheckEachChild = value => {
    const itemPreviouslyChecked = checkedItems.some(item => item === value);

    if (!itemPreviouslyChecked) {
      setCheckedItems(prevState => [...prevState, value]);

      if (allOptionsSelected) {
        setSelectedItems([]);
      }

      setSelectedItems(prevState => [...prevState, value]);
    } else {
      const newCheckedArray = checkedItems.filter(item => item !== value);

      setCheckedItems(newCheckedArray);
      setSelectedItems(newCheckedArray);
    }
  };

  const IsOptionChecked = data =>
    allOptions.length === 1 || checkedItems.some(item => item === data);

  const renderAirLineFilters = (data, index) => {
    const airlineInfo = airlinesInformations && airlinesInformations[data.code];

    const isAirlineChecked = IsOptionChecked(data.code);

    return (
      <div className="checkbox-container">
        {airlineInfo ? (
          <Fragment>
            <CheckBox
              label={airlineInfo.name}
              isChecked={isAirlineChecked}
              disabled={allOptions.length <= 1}
              onChange={() => handleCheckEachChild(data.code)}
            />
            <div className="airlines-price">
              <span> {I18n.t('components.ibe.search_results.filters.start_position')} </span>
              {tenantCurrency && (
                <Amount currency={tenantCurrency} value={allOptions[index].stats.min} />
              )}
            </div>
          </Fragment>
        ) : (
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
        )}
      </div>
    );
  };

  const selectLabel = data => {
    if (searchParameter === 'categories') {
      return I18n.t(data, { scope: 'components.ibe.car_categories' });
    }
    if (searchParameter === 'vendors') {
      return I18n.t(data, { scope: 'components.ibe.vendors' });
    }

    return I18n.t(data, { scope: 'components.ibe.caterings_values' });
  };

  const renderFiltersWithStringTypeData = data => {
    const isItemChecked = IsOptionChecked(data);

    return (
      <div className="checkbox-container">
        {data ? (
          <CheckBox
            label={selectLabel(data)}
            isChecked={isItemChecked}
            disabled={allOptions.length <= 1}
            onChange={() => handleCheckEachChild(data)}
          />
        ) : (
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
        )}
      </div>
    );
  };

  const renderFiltersWithIdentifiers = data => {
    const isItemChecked = IsOptionChecked(data.identifier);

    return (
      <div className="checkbox-container">
        {data ? (
          <CheckBox
            label={data.name}
            isChecked={isItemChecked}
            disabled={allOptions.length <= 1}
            onChange={() => handleCheckEachChild(data.identifier)}
          />
        ) : (
          <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
        )}
      </div>
    );
  };

  const compareSelections = () => {
    if (allOptionsSelected || checkedItems.length === 0) {
      return onSelect(null);
    }
    return onSelect(filterTitle);
  };

  useUpdateEffect(() => {
    filterResults(searchParameter, selectedItems, null, allOptionsChecked);
  }, [selectedItems]);

  useUpdateEffect(() => {
    setSelectedItems(selectedOptions)
  }, [selectedOptions]);

  useEffect(() => {
    onAllChecked(allOptionsChecked);

    compareSelections();
  }, [selectedItems]);

  useEffect(() => {
    if (!currentSelection) {
      if (!allOptionsChecked) {
        setCheckedItems([]);
      }
    } else {
      setCheckedItems(selectedOptions);
    }
  }, [currentSelection]);

  useEffect(() => {
    if (!isAllChecked && !currentSelection) {
      setCheckedItems([]);
    }
  }, [isAllChecked]);

  return (
    <Fragment>
      <FilterWrapper
        isInitiallyCollapsed={true}
        type="secondary"
        title={
          <div className="filter-wrapper__title">
            <Fragment>{filterTitle}</Fragment>
            <Fragment>
              {checkedItems.length > 0 && (
                <span className="flight-filter-checkbox-wrapper__selected">
                  {` ${checkedItems.length}`}
                </span>
              )}
            </Fragment>
          </div>
        }
      >
        <div className="flight-filter-checkbox-wrapper">
          {allOptions.length > 1 && (
            <FilterAction
              handleCheckAll={handleCheckAll}
              handleUncheckAll={handleUncheckAll}
              allOptionsChecked={allOptionsChecked}
              checkedItems={checkedItems}
            />
          )}

          {allOptions &&
            allOptions.map((data, index) => {
              if (typeof data === 'string') return renderFiltersWithStringTypeData(data);
              return data.code
                ? renderAirLineFilters(data, index)
                : renderFiltersWithIdentifiers(data);
            })}
        </div>
      </FilterWrapper>

      {isDividerVisible && (
        <div className="flight-results__horizontal-line">
          <hr />
        </div>
      )}
    </Fragment>
  );
}

FilterSection.defaultProps = {
  airlinesInformations: null,
  tenantCurrency: null,
  isDividerVisible: true,
};

FilterSection.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  allOptions: PropTypes.arrayOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape(),
    PropTypes.bool,
  ]).isRequired,
  airlinesInformations: PropTypes.arrayOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape(),
    PropTypes.bool,
  ]),
  tenantCurrency: PropTypes.string,
  filterResults: PropTypes.func.isRequired,
  searchParameter: PropTypes.string.isRequired,
  selectedOptions: PropTypes.arrayOf([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape(),
    PropTypes.bool,
  ]).isRequired,
  onSelect: PropTypes.func.isRequired,
  onAllChecked: PropTypes.func.isRequired,
  fetchAirlineInformations: PropTypes.func.isRequired,
  isDividerVisible: PropTypes.bool,
  currentSelection: PropTypes.arrayOf(PropTypes.number).isRequired,
  isAllChecked: PropTypes.arrayOf(PropTypes.number).isRequired,
};
