import React from 'react';
import PropTypes from 'prop-types';

const SeatLineItem = props => (
  <div className={props.className}>
    {props.row}
    {props.seat}
  </div>
);

SeatLineItem.defaultProps = {
  className: null,
};

SeatLineItem.propTypes = {
  className: PropTypes.string,
  seat: PropTypes.string.isRequired,
  row: PropTypes.string.isRequired,
};

export default SeatLineItem;
