/* eslint-disable no-param-reassign,no-plusplus,radix */

function createList(mainList, cursor, currentListLevel, listType) {
  const list = document.createElement(listType);

  while (cursor < mainList.length) {
    const item = mainList[cursor];

    // Only root list items has no class name in Quill list. So breaking child loop
    if (item.className === '') break;

    // Quill follow this format to set nested item class name 'ql-indent-1'. So splitting by '-' and getting 3rd item.
    const currentItemLevel = parseInt(item.className.split('-')[2]);

    if (currentItemLevel === currentListLevel) {
      cursor++;
      list.appendChild(item.cloneNode(true));
    } else if (currentItemLevel > currentListLevel) {
      const { cursor: newCursor, list: childLists } = createList(
        mainList,
        cursor,
        currentItemLevel,
        listType,
      );
      cursor = newCursor; // Skipping already traversed items
      list.appendChild(childLists);
    } else {
      // Parent list item found. So, breaking current list loop
      break;
    }
  }

  return { cursor, list };
}

function parseList(element, listType) {
  const root = document.createElement(listType);
  let cursor = 0; // Setting cursor to use it on every function

  while (cursor < element.children.length) {
    const item = element.children[cursor];
    const className = item.className;

    if (className) {
      // Quill follow this format to set nested item class name 'ql-indent-1'. So splitting by '-' and getting 3rd item.
      const currentItemLevel = parseInt(className.split('-')[2]);
      const { cursor: newCursor, list } = createList(
        element.children,
        cursor,
        currentItemLevel,
        listType,
      );
      cursor = newCursor; // Skipping already traversed items
      root.appendChild(list);
    } else {
      root.appendChild(item.cloneNode(true));
      cursor++;
    }
  }

  return root;
}

function parseQuillHtml(text) {
  const html = new DOMParser().parseFromString(text, 'text/html');

  ['ol', 'ul'].forEach(listType => {
    const lists = html.querySelectorAll(listType);
    lists.forEach(list => {
      list.innerHTML = parseList(list, listType).innerHTML;
    });
  });

  return html.body.innerHTML;
}

export default parseQuillHtml;
