import React from 'react';
import PropTypes from 'prop-types';
import StaticProductItem from '../StaticProduct';
import CarItem from './Car';
import HotelItem from '../HotelItem';
import FlightItem from './Flight';
import TransferItem from '../TransferItem';
import CruiseItem from '../CruiseItem';
import EventItem from '../EventItem';
import { TYPE_CAR, TYPE_FLIGHT } from '../../helpers/itemTypes';

const ItemWrapper = ({
  className,
  item,
  trips,
  journeyElementId,
  journeyElementType,
  fetchedJourneyElement,
  productId,
  showMinimalInfo,
  refetchJourneyElement,
  showFlightSeatsStatus,
  totalTravelerCount,
  flightServicesByResultId,
  payLater,
  laymanMode,
  shouldLockScroll,
  setShouldLockScroll,
  displayCarFacilitiesItems,
  isRouteInfoVisible,
  currency,
  grossAmount,
  supplierReservationIdentifier,
  isPostBooking,
}) => {
  const availableItem = {
    flight: FlightItem,
    hotel: HotelItem,
    car: CarItem,
    transfer: TransferItem,
    cruise: CruiseItem,
    event: EventItem,
  };
  const ItemBasedOnType = availableItem[journeyElementType];

  if (journeyElementType === TYPE_FLIGHT && showFlightSeatsStatus) {
    return (
      <FlightItem
        className="d-flex-col"
        item={item}
        trips={trips}
        showFlightSeatsStatus={true}
        flightServicesByResultId={flightServicesByResultId}
        journeyElementId={journeyElementId}
        journeyElementType={TYPE_FLIGHT}
        showMinimalInfo={showMinimalInfo}
        fetchedJourneyElement={fetchedJourneyElement}
        refetchJourneyElement={refetchJourneyElement}
        totalTravelerCount={totalTravelerCount}
        payLater={payLater}
        laymanMode={laymanMode}
        shouldLockScroll={shouldLockScroll}
        setShouldLockScroll={setShouldLockScroll}
      />
    );
  }

  if (journeyElementType === TYPE_CAR) {
    return (
      <CarItem
        journeyElementId={journeyElementId}
        journeyElementType={journeyElementType}
        showMinimalInfo={showMinimalInfo}
        fetchedJourneyElement={fetchedJourneyElement}
        refetchJourneyElement={refetchJourneyElement}
        payLater={payLater}
        laymanMode={laymanMode}
        displayFacilitiesItems={displayCarFacilitiesItems}
      />
    );
  }

  if (journeyElementType in availableItem) {
    return (
      <ItemBasedOnType
        className={className}
        journeyElementId={journeyElementId}
        journeyElementType={journeyElementType}
        showMinimalInfo={showMinimalInfo}
        fetchedJourneyElement={fetchedJourneyElement}
        refetchJourneyElement={refetchJourneyElement}
        payLater={payLater}
        laymanMode={laymanMode}
        isRouteInfoVisible={isRouteInfoVisible}
        currency={currency}
        grossAmount={grossAmount}
        supplierReservationIdentifier={supplierReservationIdentifier}
        item={item}
        isPostBooking={isPostBooking}
      />
    );
  } else if (productId) {
    return <StaticProductItem productId={productId} />;
  }

  return null;
};

ItemWrapper.defaultProps = {
  className: '',
  journeyElementId: null,
  journeyElementType: '',
  productId: '',
  showMinimalInfo: false,
  fetchedJourneyElement: null,
  refetchJourneyElement: false,
  showFlightSeatsStatus: false,
  payLater: false,
  isRouteInfoVisible: false,
  supplierReservationIdentifier: '',
  isPostBooking: false,
};

ItemWrapper.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape().isRequired,
  trips: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  flightServicesByResultId: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  journeyElementId: PropTypes.number,
  journeyElementType: PropTypes.string,
  productId: PropTypes.string,
  showMinimalInfo: PropTypes.bool,
  fetchedJourneyElement: PropTypes.shape({}),
  refetchJourneyElement: PropTypes.bool,
  showFlightSeatsStatus: PropTypes.bool,
  totalTravelerCount: PropTypes.number.isRequired,
  payLater: PropTypes.bool,
  laymanMode: PropTypes.bool.isRequired,
  shouldLockScroll: PropTypes.bool.isRequired,
  setShouldLockScroll: PropTypes.func.isRequired,
  displayCarFacilitiesItems: PropTypes.bool.isRequired,
  isRouteInfoVisible: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  grossAmount: PropTypes.string.isRequired,
  supplierReservationIdentifier: PropTypes.string,
  isPostBooking: PropTypes.bool,
};

export default ItemWrapper;
