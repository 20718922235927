import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';
import Amount from 'sharedWebpack/Amount';
import './styles.scss';

const PriceMenuItem = ({ label, amount, currency, isPriceBold, isFree }) => (
  <div className="price-menu__breakdown-item d-flex flex-wrap justify-between">
    <span>{label}</span>
    {isFree ? (
      <I18nText id="ibe.summary.free" />
    ) : (
      <Amount
        className={classNames({
          'price-menu__breakdown-item--bold': isPriceBold,
        })}
        currency={currency}
        value={amount}
      />
    )}
  </div>
);

PriceMenuItem.defaultProps = {
  isPriceBold: false,
  isFree: false,
};

PriceMenuItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  isPriceBold: PropTypes.bool,
  isFree: PropTypes.bool,
};

export default PriceMenuItem;
