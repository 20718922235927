import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';
import Amount from 'sharedWebpack/Amount';
import { TYPE_ADULT, TYPE_CHILD, TYPE_INFANT } from '../../helpers/passengerTypes';
import parseFloatToNDecimals from '../../helpers/parseFloatToNDecimals';

const PriceBreakdownItem = ({ className, label, priceTag, isPriceTagBold }) => (
  <div className={classNames('d-flex align-center justify-between', className)}>
    <span>{label}</span>
    <span className={classNames({ 'is-bold': isPriceTagBold })}>{priceTag}</span>
  </div>
);

PriceBreakdownItem.defaultProps = {
  className: null,
  isPriceTagBold: false,
};

PriceBreakdownItem.propTypes = {
  className: PropTypes.string,
  isPriceTagBold: PropTypes.bool,
  label: PropTypes.string.isRequired,
  priceTag: PropTypes.node.isRequired,
};

const FlightPriceBreakdownStandard = ({
  fareCalculations,
  grossTaxes,
  marginBreakdown,
  travelerCount,
  grossAmount,
  currency,
  fromResult,
  fareBrandTitle,
  laymanMode,
}) => {
  const getPriceAndCurrencyForTravelerType = type => {
    const selectedFare =
      fareCalculations && fareCalculations.find(fare => fare.passengerType === type);
    const price =
      selectedFare && parseFloat(fromResult ? selectedFare.legPrice : selectedFare.price);
    const currencyForTravelerType = selectedFare && selectedFare.currency;

    return { price, currency: currencyForTravelerType };
  };

  const getPriceTagForTravelerType = type => {
    const { price, currency: currencyForTravelerType } = getPriceAndCurrencyForTravelerType(type);

    if (price === 0) {
      return <I18nText id="ibe.summary.free" returnStringOnly={true} />;
    }

    return <Amount currency={currencyForTravelerType} value={price} />;
  };

  const adultsCount = travelerCount.adults;
  const childrenCount = travelerCount.children;
  const infantsCount = travelerCount.infants;

  const travelerTypesMap = [
    {
      type: TYPE_ADULT,
      count: adultsCount,
    },
    {
      type: TYPE_CHILD,
      count: childrenCount,
    },
    {
      type: TYPE_INFANT,
      count: infantsCount,
    },
  ];

  const getTravelerCountText = () => {
    const travelerCountLabels = travelerTypesMap.map(({ type, count }) =>
      I18n.t(type, { scope: 'traveler.types_with_count', count }),
    );

    return travelerCountLabels.join(', ');
  };

  const travelerTypesWithPriceDetails = travelerTypesMap.map(({ type, count }) => {
    const priceDetails = getPriceAndCurrencyForTravelerType(type);

    return {
      ...priceDetails,
      type,
      count,
    };
  });

  const totalPriceForAllTravelers = travelerTypesWithPriceDetails
    .filter(({ count }) => count)
    .reduce(
      (currentPrice, { count, price }) => currentPrice + count * parseFloatToNDecimals(price),
      0,
    );

  const filteredTravelerTypesWithPriceDetails = travelerTypesWithPriceDetails.filter(
    travelerItem => travelerItem.count !== 0,
  );

  const totalTravelerCount = [adultsCount, childrenCount, infantsCount].reduce(
    (currentCount, currentNumber) => currentCount + currentNumber,
  );

  return (
    <div className="grid grid-gap-12 item__pricing-breakdown-container">
      {fareBrandTitle && (
        <div className="col-12 item__pricing-farebrand-title">{fareBrandTitle}</div>
      )}
      {filteredTravelerTypesWithPriceDetails.map(({ type }) => (
        <div key={type} className="col-12">
          <PriceBreakdownItem
            label={I18n.t(type, {
              scope: 'admin.components.products.options.automation_style.per_traveler',
            })}
            priceTag={getPriceTagForTravelerType(type)}
          />
        </div>
      ))}
      <div className="col-12">
        <PriceBreakdownItem
          label={I18n.t('admin.components.products.options.automation_style.all_traveler', {
            count: totalTravelerCount,
          })}
          priceTag={<Amount currency={currency} value={totalPriceForAllTravelers} />}
          isPriceTagBold={true}
        />
        <div className="item__traveler-type-breakdown-container">
          <span className="item__traveler-type-breakdown">
            {getTravelerCountText(travelerTypesMap)}
          </span>
        </div>
      </div>
      <div className="col-12">
        <PriceBreakdownItem
          label={I18n.t('activerecord.attributes.order_item.taxes_charges')}
          priceTag={
            grossTaxes && grossTaxes > 0 ? (
              <Amount currency={currency} value={grossTaxes} />
            ) : (
              <I18nText id="ibe.summary.free" returnStringOnly={true} />
            )
          }
        />
      </div>
      {!laymanMode && marginBreakdown && marginBreakdown.length > 0 && (
        <div className="col-12">
          {marginBreakdown.map(({ id, name, currency: marginCurrency, total }) => (
            <PriceBreakdownItem
              key={id}
              label={name}
              priceTag={<Amount currency={marginCurrency} value={total} />}
            />
          ))}
        </div>
      )}
      <div className="col-12">
        <PriceBreakdownItem
          label={I18n.t('activerecord.attributes.order_item.total')}
          priceTag={<Amount currency={currency} value={grossAmount} />}
          isPriceTagBold={true}
        />
      </div>
    </div>
  );
};

FlightPriceBreakdownStandard.defaultProps = {
  travelerCount: {
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
  },
  fromResult: false,
  fareBrandTitle: null,
  grossTaxes: null,
  marginBreakdown: null,
};

FlightPriceBreakdownStandard.propTypes = {
  marginBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      currency: PropTypes.string,
      total: PropTypes.string,
    }),
  ),
  grossTaxes: PropTypes.number,
  grossAmount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  travelerCount: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
  }),
  fareCalculations: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.string,
      currency: PropTypes.string,
      passengerType: PropTypes.string,
    }),
  ).isRequired,
  fromResult: PropTypes.bool,
  fareBrandTitle: PropTypes.string,
  laymanMode: PropTypes.bool.isRequired,
};
export default FlightPriceBreakdownStandard;
