import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const CarDetails = ({ className, vendorName, location }) => (
  <div className={classNames('d-flex-col row-gap-8 car-details', className)}>
    <h4 className="car-details__vendor">{vendorName}</h4>
    <div className="car-details__location">{location}</div>
  </div>
);

CarDetails.defaultProps = {
  className: null,
};

CarDetails.propTypes = {
  className: PropTypes.string,
  vendorName: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

export default CarDetails;
