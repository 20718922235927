import { HOTEL_SEARCH_FETCHED } from '../../actions/hotel';

export default function searchStatsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case HOTEL_SEARCH_FETCHED:
      newState[action.searchId] = {
        rating: action.stats.rating,
        price: action.stats.price,
        priceRanges: action.stats.priceRanges,
        stars: action.stats.stars,
        refundable: action.stats.refundable,
        geoBounds: null,
        availableSortOptions: action.stats.availableSortOptions
      };
      if (action.stats.geoBounds) {
        newState[action.searchId].geoBounds = {
          bottomRight: {
            lat: parseFloat(action.stats.geoBounds.bottomRight.lat, 10),
            lon: parseFloat(action.stats.geoBounds.bottomRight.lon, 10),
          },
          topLeft: {
            lat: parseFloat(action.stats.geoBounds.topLeft.lat, 10),
            lon: parseFloat(action.stats.geoBounds.topLeft.lon, 10),
          },
        };
      }
      return newState;

    default:
      return state;
  }
}
