import { combineReducers } from 'redux';

import defaultCarSearchParams from './defaultCarSearchParams';
import searchFilterParamsBySearchId from './searchFilterParamsBySearchId';
import searchParamsBySearchId from './searchParamsBySearchId';
import searchResultsBySearchId from './searchResultsBySearchId';
import searchStatsBySearchId from './searchStatsBySearchId';
import totalResultsBySearchId from './totalResultsBySearchId';
import vendorsByCode from './vendorsByCode';
import totalResultsPerSupplier from './totalResultsPerSupplier';
import addCar from './addCar';

const carsReducer = combineReducers({
  addCar,
  defaultCarSearchParams,
  searchFilterParamsBySearchId,
  searchParamsBySearchId,
  searchResultsBySearchId,
  searchStatsBySearchId,
  totalResultsBySearchId,
  vendorsByCode,
  totalResultsPerSupplier,
});

export default carsReducer;
