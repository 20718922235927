import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import Alert from 'sharedWebpack/Alert';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

import IdentificationInformationForm from './IdentificationInformationForm';
import IdentificationInformation from './IdentificationInformation';

import VisaNumberForm from './VisaNumberForm';
import VisaNumber from './VisaNumber';

const sortItems = (x, y) => Number(y.primary) - Number(x.primary);

const VisasAndIdentificationsSection = props => {
  const { personId, documentTypes, visaCategories, layman } = props;

  const [error, setError] = useState({});
  const [type, setType] = useState('');
  const [country, setCountry] = useState('');
  const [nationality, setNationality] = useState('');
  const [number, setNumber] = useState('');
  const [validity, setValidity] = useState(null);
  const [primary, setPrimary] = useState(false);
  const [identificationDocuments, setIdentificationDocuments] = useState([]);
  const [identificationFileName, setIdentificationFileName] = useState('');
  const [identificationFileType, setIdentificationFileType] = useState('');
  const [identificationFile, setIdentificationFile] = useState(null);
  const [identificationFileUploadPreviewUrl, setIdentificationFileUploadPreviewUrl] = useState(
    null,
  );

  const [visaNumbers, setVisaNumbers] = useState([]);
  const [visaNumberError, setVisaNumberError] = useState({});
  const [visaNumberCategory, setVisaNumberCategory] = useState('');
  const [visaNumberCountry, setVisaNumberCountry] = useState('');
  const [visaNumber, setVisaNumber] = useState('');
  const [visaNumberIssueDate, setVisaNumberIssueDate] = useState(null);
  const [visaNumberExpireDate, setVisaNumberExpireDate] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [visaFile, setVisaFile] = useState(null);
  const [visaFileName, setVisaFileName] = useState('');
  const [visaFileType, setVisaFileType] = useState('');
  const [visaFileUploadPreviewUrl, setVisaFileUploadPreviewUrl] = useState(null);
  const [isVisaLoading, setIsVisaLoading] = useState(true);
  const [isIdentificationDocumentsLoading, setIsIdentificationDocumentsLoading] = useState(true);
  const [isShowIdentificationForm, setIsShowIdentificationForm] = useState(false);
  const [isShowVisaForm, setIsShowVisaForm] = useState(false);
  const hasIdentificationDocument = identificationDocuments.length > 0;
  const hasVisa = visaNumbers.length > 0;

  let identificationFormData;
  let visaFormData;

  if (Array.isArray(identificationDocuments)) {
    identificationDocuments.sort(sortItems);
  }

  const toggleShowIdentificationForm = () => {
    setIsShowIdentificationForm(prev => !prev);
  };

  const toggleShowVisaForm = () => {
    setIsShowVisaForm(prev => !prev);
  };

  const fetchIdentificationDocuments = async () => {
    const fetchIdentificationDocumentsUrl = layman
      ? routes.public.identificationDocuments.list()
      : routes.admin.identificationDocuments.list({ person_id: personId });

    const { data } = await httpClient.get(fetchIdentificationDocumentsUrl);

    setIdentificationDocuments(data.identificationDocuments);
    setIsIdentificationDocumentsLoading(false);
  };

  const fetchVisaNumbers = async () => {
    const fetchVisaNumbersUrl = layman
      ? routes.public.visaNumbers.list()
      : routes.admin.visaNumbers.list({ person_id: personId });

    const { data } = await httpClient.get(fetchVisaNumbersUrl);

    setVisaNumbers(data.visaNumbers);
    setIsVisaLoading(false);
  };

  const fetchCountryOptions = async () => {
    const fetchCountriesUrl = layman
      ? routes.public.people.countryOptions()
      : routes.admin.people.countryOptions();

    const { data } = await httpClient.get(fetchCountriesUrl);
    setCountryOptions(data.countryOptions);
  };

  const fetchNationalityOptions = async () => {
    const fetchCountriesUrl = layman
      ? routes.public.people.nationalityOptions()
      : routes.admin.people.nationalityOptions();

    const { data } = await httpClient.get(fetchCountriesUrl);
    setNationalityOptions(data.nationalityOptions);
  };

  const clearIdentificationFormData = () => {
    setError({});
    setType('');
    setCountry('');
    setNationality('');
    setNumber('');
    setValidity(null);
    setPrimary(false);
    setIdentificationFile(null);
    setIdentificationFileName('');
    setIdentificationFileType('');
    setIdentificationFileUploadPreviewUrl('');
  };

  const clearVisaFormData = () => {
    setVisaNumberError({});
    setVisaNumberCategory('');
    setVisaNumberCountry('');
    setVisaNumber('');
    setVisaNumberIssueDate(null);
    setVisaNumberExpireDate(null);
    setVisaFile(null);
    setVisaFileName('');
    setVisaFileType('');
    setVisaFileUploadPreviewUrl('');
  };

  useEffect(() => {
    fetchIdentificationDocuments();
    fetchVisaNumbers();
    fetchCountryOptions();
    fetchNationalityOptions();
  }, []);

  useEffect(() => {
    setPrimary(identificationDocuments.length === 0);
  }, [identificationDocuments]);

  const singleIdentificationDocumentPresent =
    identificationDocuments && identificationDocuments.length === 1;

  const createIdentification = async identificationParams => {
    const createIdentificationUrl = layman
      ? routes.public.identificationDocuments.create()
      : routes.admin.identificationDocuments.create({ person_id: personId });

    const { data } = await httpClient.post(createIdentificationUrl, identificationParams);

    if (data.error === null) {
      fetchIdentificationDocuments();
      clearIdentificationFormData();
    }
  };

  const onCreateIdentification = () => {
    identificationFormData = new FormData();
    if (identificationFile) {
      identificationFormData.append('identification_document[file]', identificationFile);
    }
    identificationFormData.append('identification_document[document_type]', type);
    identificationFormData.append('identification_document[country]', country);
    identificationFormData.append('identification_document[nationality]', nationality);
    identificationFormData.append('identification_document[number]', number);
    identificationFormData.append('identification_document[validity]', validity);
    identificationFormData.append('identification_document[primary]', primary);
    identificationFormData.append('identification_document[person_id]', personId);

    createIdentification(identificationFormData)
      .then(() => {
        toggleShowIdentificationForm();
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setError(response.data.error);
        }
      });
  };

  const createVisaNumber = async visaParams => {
    const createVisaNumberUrl = layman
      ? routes.public.visaNumbers.create()
      : routes.admin.visaNumbers.create({ person_id: personId });

    const { data } = await httpClient.post(createVisaNumberUrl, visaParams);

    if (data.error === null) {
      fetchVisaNumbers();
      clearVisaFormData();
    }
  };

  const onCreateVisaNumber = () => {
    visaFormData = new FormData();
    if (visaFile) {
      visaFormData.append('visa_number[file]', visaFile);
    }
    visaFormData.append('visa_number[category]', visaNumberCategory);
    visaFormData.append('visa_number[country]', visaNumberCountry);
    visaFormData.append('visa_number[issue_date]', visaNumberIssueDate);
    visaFormData.append('visa_number[number]', visaNumber);
    visaFormData.append('visa_number[expire_date]', visaNumberExpireDate);
    visaFormData.append('visa_number[person_id]', personId);

    createVisaNumber(visaFormData)
      .then(() => {
        toggleShowVisaForm();
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setVisaNumberError(response.data.error);
        }
      });
  };

  const identificationForm = () => (
    <Fragment>
      {hasIdentificationDocument && isShowIdentificationForm && (
        <Fragment>
          <div className="edit-email-and-phone">
            <hr className="edit-email-and-phone__hr" />
          </div>
          <div className="traveller-edit-identification-document-form__sub-header">
            {I18n.t('admin.identification_document.action.add')}
          </div>
        </Fragment>
      )}
      <IdentificationInformationForm
        documentTypes={documentTypes}
        countryOptions={countryOptions}
        nationalityOptions={nationalityOptions}
        type={type}
        country={country}
        nationality={nationality}
        number={number}
        validity={validity}
        primary={primary}
        setType={setType}
        setCountry={setCountry}
        setNationality={setNationality}
        setNumber={setNumber}
        setValidity={setValidity}
        setPrimary={setPrimary}
        primaryCheckDisabled={identificationDocuments.length === 0}
        identificationFileName={identificationFileName}
        setIdentificationFileName={setIdentificationFileName}
        identificationFileType={identificationFileType}
        setIdentificationFileType={setIdentificationFileType}
        setIdentificationFile={setIdentificationFile}
        identificationFileUploadPreviewUrl={identificationFileUploadPreviewUrl}
        setIdentificationFileUploadPreviewUrl={setIdentificationFileUploadPreviewUrl}
        setError={setError}
        error={error}
      />
      <div className="traveller-edit-identification-document-form__actions">
        <Button
          version="v2"
          size="small"
          type="primary"
          label={I18n.t('admin.order_items.travelers.identification_document.button_label')}
          onClick={onCreateIdentification}
        />
        <Button
          version="v2"
          size="small"
          label={I18n.t('admin.components.shared.action.cancel')}
          type="default"
          onClick={toggleShowIdentificationForm}
        />
      </div>
    </Fragment>
  );

  const visaForm = () => (
    <Fragment>
      {hasVisa && isShowVisaForm && (
        <Fragment>
          <div className="edit-email-and-phone">
            <hr className="edit-email-and-phone__hr" />
          </div>
          <div className="traveller-edit-identification-document-form__sub-header">
            {I18n.t('admin.components.orders.left_panel.visa_numbers.action.add')}
          </div>
        </Fragment>
      )}
      <VisaNumberForm
        visaCategories={visaCategories}
        countryOptions={countryOptions}
        number={visaNumber}
        setNumber={setVisaNumber}
        category={visaNumberCategory}
        setCategory={setVisaNumberCategory}
        country={visaNumberCountry}
        setCountry={setVisaNumberCountry}
        issueDate={visaNumberIssueDate}
        setIssueDate={setVisaNumberIssueDate}
        expireDate={visaNumberExpireDate}
        setExpireDate={setVisaNumberExpireDate}
        visaFileName={visaFileName}
        visaFileType={visaFileType}
        visaFileUploadPreviewUrl={visaFileUploadPreviewUrl}
        setVisaFileType={setVisaFileType}
        setVisaFileName={setVisaFileName}
        setVisaFile={setVisaFile}
        setVisaFileUploadPreviewUrl={setVisaFileUploadPreviewUrl}
        setError={setVisaNumberError}
        error={visaNumberError}
      />
      <div className="traveller-edit-identification-document-form__actions">
        <Button
          version="v2"
          size="small"
          type="primary"
          label={I18n.t('admin.order_items.travelers.identification_document.button_label')}
          onClick={onCreateVisaNumber}
          disabled={!!visaNumberError.visaNumbers || !!visaNumberError.visaNumbersFile}
        />
        <Button
          version="v2"
          size="small"
          label={I18n.t('admin.components.shared.action.cancel')}
          type="default"
          onClick={toggleShowVisaForm}
        />
      </div>
    </Fragment>
  );

  useEffect(() => {
    clearIdentificationFormData();
  }, [isShowIdentificationForm]);

  useEffect(() => {
    clearVisaFormData();
  }, [isShowVisaForm]);

  return (
    <div className="traveller-edit-visas-and-identification">
      <Alert
        className="traveller-edit-identification-document-section__alert"
        isVisible={error.identificationDocuments}
        type="danger"
        hideClose={false}
        onClose={() => setError({ ...error, identificationDocuments: false })}
      >
        {I18n.t('admin.components.travellers.edit.identification_documents.error.invalid')}
      </Alert>

      <div className="col-grid traveller-edit-identification-document-section">
        <div className="col-bleed align-center">
          <div className="traveller-edit-identification-document-section__header justify-space-between">
            {I18n.t('admin.identification_document.title')}
            {!isShowIdentificationForm && !isIdentificationDocumentsLoading && (
              <Button
                version="v2"
                size="small"
                label={I18n.t(
                  'admin.components.organizations.edit.email_and_phone.phone.label.add',
                )}
                type="primary"
                onClick={toggleShowIdentificationForm}
              />
            )}
          </div>
        </div>

        {!hasIdentificationDocument && isShowIdentificationForm && identificationForm()}

        <div className="col-12 col-bleed traveller-edit-identification">
          {isIdentificationDocumentsLoading ? (
            <div className="card traveller-edit-identification-document__container card--v2 card--normal-shadow card--null">
              <ContentLoaderPlaceholder numberOfLines={4} isRounded={true} showBackground={false} />
            </div>
          ) : (
            <Fragment>
              {identificationDocuments.map(IDdocument => (
                <IdentificationInformation
                  key={IDdocument.id}
                  personId={personId}
                  IDdocument={IDdocument}
                  fetchIdentificationDocuments={fetchIdentificationDocuments}
                  documentTypes={documentTypes}
                  countryOptions={countryOptions}
                  nationalityOptions={nationalityOptions}
                  documentId={IDdocument.id}
                  layman={layman}
                  singleIdentificationDocumentPresent={singleIdentificationDocumentPresent}
                  identificationFileUploadPreviewUrl={identificationFileUploadPreviewUrl}
                  setIdentificationFileUploadPreviewUrl={setIdentificationFileUploadPreviewUrl}
                />
              ))}

              {hasIdentificationDocument && isShowIdentificationForm && identificationForm()}

              {!hasIdentificationDocument && !isShowIdentificationForm && (
                <div className="col-12 direction-row edit-email-and-phone edit-email-and-phone__placeholder">
                  <Icon
                    name="visaIdentification"
                    size="large"
                    color="tertiary"
                    showBGColor={true}
                  />
                  <div className="edit-email-and-phone__explanation edit-email-and-phone__explanation--with-padding">
                    {I18n.t('admin.components.orders.left_panel.passport.no_passport')}
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>

      <Alert
        className="traveller-edit-identification-document-section__alert"
        isVisible={visaNumberError.visaNumbers}
        type="danger"
        hideClose={false}
        onClose={() => setVisaNumberError({ ...visaNumberError, visaNumbers: false })}
      >
        {I18n.t('admin.components.travellers.edit.visa_numbers.error.invalid')}
      </Alert>

      <div className="traveller-edit-identification-document-section">
        <div className="col-bleed align-center">
          <div className="traveller-edit-identification-document-section__header justify-space-between">
            {I18n.t('admin.components.travellers.edit.visa_numbers.title')}

            {!isShowVisaForm && !isVisaLoading && (
              <Button
                version="v2"
                size="small"
                label={I18n.t(
                  'admin.components.organizations.edit.email_and_phone.phone.label.add',
                )}
                type="primary"
                onClick={toggleShowVisaForm}
              />
            )}
          </div>
        </div>

        {!hasVisa && isShowVisaForm && visaForm()}

        <div className="col-12 col-bleed traveller-edit-identification">
          {isVisaLoading ? (
            <div className="card traveller-edit-identification-document__container card--v2 card--normal-shadow card--null">
              <ContentLoaderPlaceholder numberOfLines={4} isRounded={true} showBackground={false} />
            </div>
          ) : (
            <Fragment>
              {visaNumbers.map(visa => (
                <VisaNumber
                  key={visa.id}
                  personId={personId}
                  visaNumber={visa}
                  fetchVisaNumbers={fetchVisaNumbers}
                  visaCategories={visaCategories}
                  countryOptions={countryOptions}
                  visaNumberID={visa.id}
                  layman={layman}
                />
              ))}

              {hasVisa && isShowVisaForm && visaForm()}

              {!hasVisa && !isShowVisaForm && (
                <div className="col-12 direction-row edit-email-and-phone edit-email-and-phone__placeholder">
                  <Icon
                    name="visaIdentification"
                    size="large"
                    color="tertiary"
                    showBGColor={true}
                  />
                  <div className="edit-email-and-phone__explanation edit-email-and-phone__explanation--with-padding">
                    {I18n.t('admin.components.orders.left_panel.visa_numbers.no_visa')}
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

VisasAndIdentificationsSection.defaultProps = {
  layman: false,
};

VisasAndIdentificationsSection.propTypes = {
  personId: PropTypes.number.isRequired,
  documentTypes: PropTypes.shape().isRequired,
  visaCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  layman: PropTypes.bool,
};

export default VisasAndIdentificationsSection;
