import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import { WARNING, DANGER, SUCCESS, DEFAULT } from '../../../lib/helpers/componentStates';
import './style.scss';

/*

icon types reference to the seat count number,

  DANGER means available seats < travellers
  WARNING means available seats = travellers + 1
  SUCCESS means available seats > travellers + 1
  DEFAULT means available seats status is not present

*/

const SeatCountLabel = ({ count }) => {
  if (count === null) {
    return I18n.t('components.ibe.seat_status.no_seat_information');
  }

  return I18n.t('components.ibe.seat_status.seats_left', { count });
};

SeatCountLabel.defaultProps = {
  count: null,
};

SeatCountLabel.propTypes = {
  count: PropTypes.number,
};

const SeatCountIconWithLabel = ({ className, seatCount, travellerCount, isShowBgColor }) => {
  const hasSeatCountInformation = seatCount !== null;

  const getIconColor = () => {
    if (hasSeatCountInformation) {
      if (seatCount === travellerCount || seatCount < travellerCount) {
        return DANGER;
      }

      if (seatCount === travellerCount + 1) {
        return WARNING;
      }

      return SUCCESS;
    }

    return DEFAULT;
  };

  return (
    <div
      className={classNames('d-flex align-center flight__seat-status', className, {
        [`flight__seat-status__background-color--${getIconColor()}`]: isShowBgColor,
      })}
    >
      <Icon name="seat" size="tiny" color={getIconColor()} />
      <SeatCountLabel count={seatCount} />
    </div>
  );
};

SeatCountIconWithLabel.defaultProps = {
  className: null,
  isShowBgColor: false,
  seatCount: null,
};

SeatCountIconWithLabel.propTypes = {
  className: PropTypes.string,
  seatCount: PropTypes.number,
  travellerCount: PropTypes.number.isRequired,
  isShowBgColor: PropTypes.bool,
};

export default SeatCountIconWithLabel;
