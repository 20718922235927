import React from 'react';
import Amount from 'sharedWebpack/Amount';
import PropTypes from 'prop-types';
import RangeFilter from '../../../../components/RangeFilter';
import './styles.scss';

const PriceFilter = ({
  currency,
  disabled,
  max,
  min,
  onFilterResults,
  onTotalPriceChange,
  rangeFilterKey,
  step,
  values,
}) => {
  const TOTAL_PRICE = I18n.t('components.ibe.search_results.filters.total_price');

  /*
    Determines whether the current filter values represent the extremes of the range. If at the extremes, removes "total_price" from the parent's state `currentFilters.total_price`. If not, adds "total_price" to the parent's state. Finally, updates the `filterResults` with the current filter values.
   */
  const onRangeChange = filterValues => {
    if (
      filterValues.max.toFixed(2) === max.toFixed(2) &&
      filterValues.min.toFixed(2) === min.toFixed(2)
    ) {
      onTotalPriceChange(null);
    } else {
      onTotalPriceChange(TOTAL_PRICE);
    }

    onFilterResults(filterValues);
  };

  const onFormatLabel = value => <Amount currency={currency} value={value} />;

  return (
    <div className="price-filter">
      <span>
        {I18n.t('components.ibe.search_results.filters.total_price_currency', { currency })}
      </span>
      <RangeFilter
        key={rangeFilterKey}
        className="price"
        min={min}
        max={max}
        values={values}
        step={step}
        onChange={onRangeChange}
        formatLabel={onFormatLabel}
        disabled={disabled}
      />
      <hr className="price-filter__separator" />
    </div>
  );
};

PriceFilter.defaultProps = {
  disabled: false,
  step: 0.001,
};

PriceFilter.propTypes = {
  currency: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onFilterResults: PropTypes.func.isRequired,
  onTotalPriceChange: PropTypes.func.isRequired,
  rangeFilterKey: PropTypes.number.isRequired,
  step: PropTypes.number,
  values: PropTypes.shape({
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
  }).isRequired,
};

export default PriceFilter;
