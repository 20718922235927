import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import RclLink from '@wtag/rcl-link';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import { hot } from 'react-hot-loader';
import humanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import { FormatDateTime, DATE_FORMAT_SHORT_WITH_TIME } from 'sharedWebpack/helpers/dateTime';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';
import JourneyPath from 'sharedWebpack/ItemWrapper/Flight/jounryPath';
import AirportChangeIndicator from 'sharedWebpack/AirportChangeIndicator';
import AirportChangeSegment from 'sharedWebpack/AirportChangeSegment';
import './style.scss';
import getFlightStopsCountLabel from '../../../../helpers/calculateStopoverStops';
import { LONG_TRANSIT_TIME } from '../../../../helpers/stopoverConstants';

const FlightDurationAndStopOvers = ({
  fetchAirportDetails,
  stopovers,
  airports,
  durationInMinutes,
  setSidePanelBody,
  setSidePanelTitle,
  setOpenSidePanel,
}) => {
  const [airportChangeMap, setAirportChangeMap] = useState(new Map());
  const transitGapCount = getFlightStopsCountLabel(stopovers);

  const findAirport = airportCode =>
    (airports && airports[airportCode]) || fetchAirportDetails(airportCode);

  const viewAirportDetails = airport => {
    let airportDetails = airport.name;

    if (airport.placeName) {
      airportDetails += `, ${airport.placeName}`;
    }

    if (airport.countryName) {
      airportDetails += `, ${airport.countryName}`;
    }

    return airportDetails;
  };

  const stopoversDetails = () =>
    stopovers.map(stopover => {
      const airport = findAirport(stopover.airportCode);
      const airportCode = airport && airport.code;
      const hasAirportChange = airportChangeMap.has(airportCode);
      const airportChangeData = hasAirportChange && airportChangeMap.get(airportCode);
      const stopoverArrivalLocaltime =
        stopover.arrivalLocaltime || stopover.arrivalLocaltimeInUserTz;

      return (
        <Card className="duration-stopover__card" key={stopover.airport_code} version="v2">
          <div className="col-grid col-bleed direction-row align-center justify-space-between flight-item-trip-stopover">
            <div className="flight-item-trip-stopover__country">
              {airport && (
                <img
                  className="flight-item-trip-stopover__country__flag"
                  src={airport.countryFlag}
                  alt={airport.countryName}
                />
              )}
              {stopover.airportCode}
            </div>
          </div>
          {airport && airport.name && (
            <div className="flight-item-trip-stopover__airport-name">
              {viewAirportDetails(airport)}
            </div>
          )}
          <div className="flight-item-trip-stopover__arrival-time">
            <DateTimeLocaleInterpolationWrapper
              localeKey="components.flight.stopover.arrival"
              time={FormatDateTime({
                dateTime: stopoverArrivalLocaltime,
                format: DATE_FORMAT_SHORT_WITH_TIME,
              })}
            />
          </div>
          {hasAirportChange && (
            <AirportChangeSegment
              destinationAirportDetails={findAirport(airportChangeData.arrival)}
              changedAirportDetails={findAirport(airportChangeData.departure)}
            />
          )}
        </Card>
      );
    });

  const showStopoverSidePanel = () => {
    setSidePanelTitle(
      I18n.t('components.flight.stopovers.title', {
        count: stopovers.length,
      }),
    );
    setSidePanelBody(stopoversDetails());
    setOpenSidePanel();
  };

  const tripTransitDurationInMinutes = stopovers
    .map(stopover => stopover.transitDurationsInMinutes)
    .reduce((a, b) => a + b, 0);

  // eslint-disable-next-line no-return-assign, no-param-reassign
  const transitType = stopovers.reduce((accumulator, currentValue) => {
    // eslint-disable-next-line no-param-reassign
    accumulator =
      accumulator > currentValue.transitDurationsInMinutes
        ? accumulator
        : currentValue.transitDurationsInMinutes;

    return accumulator;
  }, 0);

  const stopoversWithAirportChanges = stopovers
    .filter(stopover => stopover.airportChange)
    .map(stopover => stopover.airportChange);

  const changedAirportCodeText = stopoversWithAirportChanges
    .map(airportItem => airportItem.arrivalAirportCode)
    .join(', ');

  useEffect(() => {
    stopovers.map(stopover => fetchAirportDetails(stopover.airportCode));
  }, []);

  useEffect(() => {
    const newAirportChangeMap = new Map();

    stopoversWithAirportChanges.forEach(stopover => {
      newAirportChangeMap.set(stopover.departureAirportCode, {
        arrival: stopover.arrivalAirportCode,
        departure: stopover.departureAirportCode,
      });
    });

    setAirportChangeMap(newAirportChangeMap);
  }, [stopovers]);

  return (
    <div className="col-grid col-bleed justify-center align-left duration-stopover__content">
      <div className="duration-stopover__duration">
        {I18n.t('components.flight.duration')}:{' '}
        <span>{humanReadableDuration(durationInMinutes)}</span>
      </div>
      {stopovers.length > 0 && (
        <div className="duration-stopover__stopover">
          <RclLink onClick={showStopoverSidePanel} modifier="tertiary" size="tiny">
            {transitGapCount}
          </RclLink>
        </div>
      )}
      {stopovers.length > 0 && (
        <div className="duration-stopover__journey-path">
          <JourneyPath stopovers={stopovers} />
        </div>
      )}
      {stopovers.length > 0 && (
        <React.Fragment>
          <div className="duration-stopover__stopover-codes">
            {stopovers.map(stopOver => stopOver.airportCode).join(', ')} |{' '}
            {humanReadableDuration(tripTransitDurationInMinutes)}
          </div>
          <div className="duration-stopover__transit">
            {transitType >= LONG_TRANSIT_TIME
              ? I18n.t('components.flight.transit.long')
              : I18n.t('components.flight.transit.short')}
          </div>
          {!!changedAirportCodeText && (
            <AirportChangeIndicator
              className="duration-stopover__airport-change-indicator"
              changedAirportCodeText={changedAirportCodeText}
              onClick={showStopoverSidePanel}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

FlightDurationAndStopOvers.propTypes = {
  setSidePanelBody: PropTypes.func.isRequired,
  setSidePanelTitle: PropTypes.func.isRequired,
  setOpenSidePanel: PropTypes.func.isRequired,
  airports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stopovers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  durationInMinutes: PropTypes.string.isRequired,
  fetchAirportDetails: PropTypes.func.isRequired,
};

export default hot(module)(withQueryParamsProvider(FlightDurationAndStopOvers));
