import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AvatarUpload } from '@wtag/react-comp-lib';
import Modal from '@wtag/rcl-modal';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';

const AvatarModal = props => {
  const { className, fetchInstance, updateAvatar } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onModalOpen = () => setIsModalVisible(true);
  const onModalClose = () => setIsModalVisible(false);

  const saveAvatar = async image => {
    const formData = new FormData();
    formData.append('avatar', image);

    updateAvatar(formData).then(data => {
      if (data.error === null) {
        onModalClose();
        fetchInstance();
      }
    });
  };

  const onAvatarSave = image => {
    const file = new File([image], image.name, { type: image.type });
    saveAvatar(file);
  };

  return (
    <Fragment>
      <IconButton
        version="v2"
        isIconOnly={true}
        icon={<Icon name="cameraAlt" color="tertiary" showBGColor={true} />}
        size="small"
        onClick={onModalOpen}
      />
      <Modal isVisible={isModalVisible} onOutsideClick={onModalClose} className={className}>
        <Modal.Content>
          {
            // TODO: We will have to pass a prop to Avatar Upload to use rectangular cropping for organization edit
          }
          <AvatarUpload onAvatarUpload={onAvatarSave} onClose={onModalClose} />
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

AvatarModal.defaultProps = {
  className: null,
};

AvatarModal.propTypes = {
  className: PropTypes.string,
  fetchInstance: PropTypes.func.isRequired,
  updateAvatar: PropTypes.func.isRequired,
};

export default AvatarModal;
