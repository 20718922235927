import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import FlashMessage from 'sharedWebpack/FlashMessage';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import { Header } from '@wtag/rcl-typography';
import Alert from 'sharedWebpack/Alert';
import ToggleWrapper from '../ToggleWrapper';

import './styles.scss';

const NotificationEscalation = props => {
  const {
    saveChatConfiguration,
    escalationPeriod,
    enableChatEmailNotification,
    isAlien,
    isLoading,
  } = props;
  const [enableEscalationPeriod, setEnableEscalationPeriod] = useState(false);
  const [timeOption, setTimeOption] = useState();
  const [customTime, setCustomTime] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertTextOnUpdate, setAlertTextOnUpdate] = useState({ status: 200, message: null });

  const timeOptions = [
    { label: I18n.t('admin.components.settings.personal_settings.time_options.5_min'), value: 5 },
    {
      label: I18n.t('admin.components.settings.personal_settings.time_options.15_min'),
      value: 15,
    },
    {
      label: I18n.t('admin.components.settings.personal_settings.time_options.30_min'),
      value: 30,
    },
    {
      label: I18n.t('admin.components.settings.personal_settings.time_options.2_hour'),
      value: 120,
    },
    {
      label: I18n.t('admin.components.settings.personal_settings.time_options.custom'),
      value: 'custom',
    },
  ];

  const saveChatNotification = params => {
    saveChatConfiguration(params).then(data => {
      if (data.error === null) {
        setAlertTextOnUpdate({
          status: 200,
          message: I18n.t('admin.components.settings.personal_settings.alert.chat_notification'),
        });
      }
    });
  };

  const selectedTime = () => {
    let time;
    if (timeOption) {
      time = timeOption.value === timeOptions[4].value ? customTime : timeOption.value;
    }
    return time;
  };

  const saveUserSettings = async () => {
    let params = {
      chat_email_notification_enabled: enableEscalationPeriod,
    };

    if (enableEscalationPeriod) {
      const escalatedTime = selectedTime();
      params = { ...params, escalation_period: escalatedTime };
      saveChatNotification(params);
    } else {
      setIsModalOpen(!enableEscalationPeriod);
    }
  };

  const toggleSendEmailStatus = () => {
    setIsModalOpen(!setEnableEscalationPeriod);
    setEnableEscalationPeriod(previousState => !previousState);
  };

  const onConfirm = async () => {
    setIsModalOpen(false);
    setEnableEscalationPeriod(false);
    const params = {
      chat_email_notification_enabled: false,
    };

    await saveChatNotification(params);
  };

  const setTime = value => {
    setTimeOption(value);
  };

  const setCustomTimeValue = value => {
    setCustomTime(value);
  };

  const fetchUserSettings = () => {
    const option = timeOptions.find(time => time.value === escalationPeriod);

    if (option) {
      setTimeOption(option);
    } else {
      setTimeOption(timeOptions[4]);
      setCustomTime(escalationPeriod);
    }

    setEnableEscalationPeriod(enableChatEmailNotification);
  };

  useEffect(() => {
    const escalatedTime = selectedTime();
    if (escalatedTime < 15) {
      setShowAlertMessage(true);
    } else {
      setShowAlertMessage(false);
    }
  }, [timeOption, customTime]);

  useEffect(() => {
    fetchUserSettings();
  }, [escalationPeriod, enableChatEmailNotification]);

  return (
    <Fragment>
      <ConfirmationModal
        confirmationHeader={I18n.t(
          'admin.components.settings.personal_settings.confirmation_modal.header.chat',
        )}
        subHeader={I18n.t(
          'admin.components.settings.personal_settings.confirmation_modal.sub_header.chat',
        )}
        confirmationText={I18n.t(
          'admin.components.settings.personal_settings.confirmation_modal.confirmation_text',
        )}
        rejectionText={I18n.t(
          'admin.components.settings.personal_settings.confirmation_modal.rejection_text',
        )}
        onConfirm={() => onConfirm()}
        onReject={() => setIsModalOpen(false)}
        withAction={true}
        type="danger"
        isModalOpen={isModalOpen}
        onConfirmationModalOpen={() => setIsModalOpen(true)}
        onConfirmationModalClose={() => setIsModalOpen(false)}
      />
      <Card version="v2" size="full">
        <div className="notification-escalation">
          {isLoading ? (
            <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} isRounded={false} />
          ) : (
            <Fragment>
              {alertTextOnUpdate.message && (
                <div className="notification-preferences__booking-alert">
                  <FlashMessage
                    status={alertTextOnUpdate.status}
                    text={alertTextOnUpdate.message}
                  />
                </div>
              )}
              <Alert
                className="notification-escalation__alert"
                isVisible={showAlertMessage}
                type="warning"
                hideClose={true}
              >
                {I18n.t('admin.components.settings.personal_settings.reminder_time.alert')}
              </Alert>
              <Alert
                className="notification-escalation__alert"
                isVisible={isAlien}
                type="warning"
                hideClose={true}
              >
                {I18n.t(
                  'admin.components.settings.personal_settings.alert.chat_notification_disabled',
                )}
              </Alert>
              <div className="notification-escalation--top">
                <Header level={5} weight="medium">
                  {I18n.t('admin.components.settings.personal_settings.header')}
                </Header>
                <div className="notification-escalation--top__save-button">
                  <Button
                    version="v2"
                    size="small"
                    type="primary"
                    onClick={() => saveUserSettings()}
                    label={I18n.t('admin.components.settings.personal_settings.saveButton.label')}
                    disabled={isAlien}
                  />
                </div>
              </div>
              <div className="notification-escalation--middle">
                <div className="notification-escalation--middle__sub-header">
                  {I18n.t('admin.components.settings.personal_settings.label.chat')}
                </div>
                <div className="notification-escalation--middle__toggle">
                  <ToggleWrapper
                    size="tiny"
                    onChange={toggleSendEmailStatus}
                    isChecked={enableEscalationPeriod}
                    disabled={isAlien}
                  />
                </div>
              </div>
              <div className="notification-escalation__text">
                {I18n.t('admin.components.settings.personal_settings.explanation_text.chat')}
              </div>
              <div className="notification-escalation__dropdown--menu">
                <div className="grid">
                  <SelectBox
                    className="col-md-6"
                    value={timeOption}
                    onChange={setTime}
                    options={timeOptions}
                    isDisabled={!enableEscalationPeriod}
                    label={I18n.t('admin.components.settings.personal_settings.dropdown_header')}
                    width="full"
                    size="tiny"
                  />
                </div>
                {(timeOption && timeOption.value) === timeOptions[4].value && (
                  <div className="notification-escalation__dropdown--input">
                    <Input
                      label={I18n.t('admin.components.settings.personal_settings.input_header')}
                      size="tiny"
                      type="number"
                      onChange={setCustomTimeValue}
                      value={customTime}
                      disabled={!enableEscalationPeriod}
                    />
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </Card>
    </Fragment>
  );
};

NotificationEscalation.defaultProps = {
  enableChatEmailNotification: false,
  escalationPeriod: null,
};

NotificationEscalation.propTypes = {
  saveChatConfiguration: PropTypes.func.isRequired,
  enableChatEmailNotification: PropTypes.bool,
  escalationPeriod: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  isAlien: PropTypes.bool.isRequired,
};

export default NotificationEscalation;
