import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const FETCHING_EXCHANGE_QUOTE = 'FETCHING_EXCHANGE_QUOTE';
function fetchingExchangeQuote(bookingIdentifier, resultId) {
  return {
    type: FETCHING_EXCHANGE_QUOTE,
    bookingIdentifier,
    resultId,
  };
}

export const EXCHANGE_QUOTE_FETCHED = 'EXCHANGE_QUOTE_FETCHED';
function exchangeQuoteFetched(bookingIdentifier, resultId, data) {
  return {
    type: EXCHANGE_QUOTE_FETCHED,
    bookingIdentifier,
    resultId,
    data,
  };
}

export const EXCHANGE_QUOTE_FETCHING_FAILED = 'EXCHANGE_QUOTE_FETCHING_FAILED';
function exchangeQuoteFetchingFailed(bookingIdentifier, resultId) {
  return {
    type: EXCHANGE_QUOTE_FETCHING_FAILED,
    bookingIdentifier,
    resultId,
  };
}

export function fetchExchangeQuote(bookingIdentifier, resultId) {
  return dispatch => {
    dispatch(fetchingExchangeQuote(bookingIdentifier, resultId));

    return httpClient
      .get(routes.api.flights.exchange.fetchQuote({ booking_id: bookingIdentifier, resultId }))
      .then(({ data }) => {
        dispatch(exchangeQuoteFetched(bookingIdentifier, resultId, data));
        return true;
      })
      .catch(() => {
        dispatch(exchangeQuoteFetchingFailed(bookingIdentifier, resultId));
        return false;
      });
  };
}

export const CONFIRMING_EXCHANGE_QUOTE = 'CONFIRMING_EXCHANGE_QUOTE';
function confirmingExchangeQuote(bookingIdentifier, quoteId) {
  return {
    type: CONFIRMING_EXCHANGE_QUOTE,
    bookingIdentifier,
    quoteId,
  };
}

export const EXCHANGE_QUOTE_CONFIRMED = 'EXCHANGE_QUOTE_CONFIRMED';
function exchangeQuoteConfirmed(bookingIdentifier, quoteId) {
  return {
    type: EXCHANGE_QUOTE_CONFIRMED,
    bookingIdentifier,
    quoteId,
  };
}

export const EXCHANGE_QUOTE_CONFIRMATION_FAILED = 'EXCHANGE_QUOTE_CONFIRMATION_FAILED';
function exchangeQuoteConfirmationFailed(bookingIdentifier, quoteId) {
  return {
    type: EXCHANGE_QUOTE_CONFIRMATION_FAILED,
    bookingIdentifier,
    quoteId,
  };
}

export function confirmExchangeQuote() {
  return (dispatch, getState) => {
    const exchangeQuote = getState().flights.currentExchangeQuote;
    const bookingIdentifier = exchangeQuote.bookingIdentifier;
    const quoteId = exchangeQuote.quoteId;
    const resultId = exchangeQuote.resultId;
    dispatch(confirmingExchangeQuote(bookingIdentifier, quoteId));

    return httpClient
      .post(routes.api.flights.exchange.create({ bookingIdentifier }), {
        quote_id: quoteId,
        result_id: resultId,
      })
      .then(() => {
        dispatch(exchangeQuoteConfirmed(bookingIdentifier, quoteId));
        return true;
      })
      .catch(() => {
        dispatch(exchangeQuoteConfirmationFailed(bookingIdentifier, quoteId));
        return false;
      });
  };
}
