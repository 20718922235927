import 'react-dates/initialize';

import WebpackerReact from 'webpacker-react';

import 'sharedWebpack/application';
import TravelExpensesCreate from 'sharedWebpack/TravelExpensesTab/Create';
import TravelExpensesEdit from 'sharedWebpack/TravelExpensesTab/Edit';

import { Alert } from '@wtag/react-comp-lib';

import Dashboard from '../../public/Dashboard';
import MobileApp from '../../public/MobileApp';

import TopNav from '../../public/TopNav';
import SubNav from '../../public/SubNav';

import OrdersOverview from '../../public/Orders/Overview';
import OrdersList from '../../public/Orders/List';
import OrdersShow from '../../public/Orders/Show';

import TravellersEditPage from '../../public/Travellers/Edit';

import SignUpForm from '../../public/Authentication/People';
import LoginForm from '../../public/Authentication/Sessions';
import PasswordReset from '../../public/Authentication/Passwords';
import EditPasswordForm from '../../public/Authentication/Passwords/Edit';

import MidSection from '../../public/MidSection';

import ApprovalRequestList from '../../public/ApprovalRequest/List';
import ApprovalRequestShow from '../../shared/ApprovalRequests/Show';

import CorporateTravelList from '../../public/CorporateTravel/List';

import TravelArrangersList from '../../public/TravelArrangers/List';
import TravelArrangersCreate from '../../public/TravelArrangers/Create';
import TravelArrangersEdit from '../../public/TravelArrangers/Edit';

import BookingApp from '../../shared/IBE/IBEWrappedComponents';
import DealsCatalogue from '../../public/Deals/DealsCatalogue';
import Testimonial from '../../public/Testimonial';
import TopDeals from '../../public/Deals/TopDeals';

import DocumentsCreate from '../../public/Orders/Show/DocumentsTab/Create';
import DocumentsEdit from '../../public/Orders/Show/DocumentsTab/Edit';

import AccountingCreate from '../../admin/Orders/Show/AccountingTab/Create';
import AccountingEdit from '../../admin/Orders/Show/AccountingTab/Edit';

import StaticPage from '../../public/StaticPage';
import BlogPosts from '../../public/BlogPosts/List';
import Footer from '../../public/Footer';
import TopStories from '../../public/shared/BlogPosts/TopStories';
import ImageSlider from '../../public/shared/ImageSlider';
import FrontPage from '../../public/FrontPage';
import AccessDeniedPage from '../../public/AccessDeniedPage';

WebpackerReact.setup({
  // NavBars
  'public/TopNav': TopNav,
  'public/SubNav': SubNav,

  // Dashboard
  'public/Dashboard': Dashboard,

  // MobileApp
  'public/MobileApp': MobileApp,

  // Travellers
  'public/Travellers/Edit': TravellersEditPage,

  // Authentication
  'public/Authentication/People': SignUpForm,
  'public/Authentication/Sessions': LoginForm,
  'public/Authentication/Passwords': PasswordReset,
  'public/Authentication/Passwords/Edit': EditPasswordForm,

  // Orders
  'public/Orders/List': OrdersList,
  'public/Orders/Overview': OrdersOverview,
  'public/Orders/Show': OrdersShow,
  'public/Orders/Accounting/Create': AccountingCreate,
  'public/Orders/Accounting/Edit': AccountingEdit,

  // Approval Request List
  'public/ApprovalRequest/List': ApprovalRequestList,
  'public/ApprovalRequest/Show': ApprovalRequestShow,

  // Corporate Trips
  'public/CorporateTravel/List': CorporateTravelList,

  // Travel Arrangers
  'public/TravelArrangers/List': TravelArrangersList,
  'public/TravelArrangers/Create': TravelArrangersCreate,
  'public/TravelArrangers/Edit': TravelArrangersEdit,

  // Documents
  'public/Documents/Create': DocumentsCreate,
  'public/Documents/Edit': DocumentsEdit,

  // Travel Expenses
  'public/TravelExpenses/Create': TravelExpensesCreate,
  'public/TravelExpenses/Edit': TravelExpensesEdit,

  // Static Pages
  'public/StaticPage': StaticPage,
  'public/BlogPosts': BlogPosts,
  // MidSection
  'public/MidSection': MidSection,

  // Front Page
  'BlogPosts/TopStories': TopStories,
  'public/shared/ImageSlider': ImageSlider,
  'public/Footer': Footer,
  'public/FrontPage': FrontPage,
  'public/AccessDeniedPage': AccessDeniedPage,
});

WebpackerReact.setup({ BookingApp });
WebpackerReact.setup({ DealsCatalogue });
WebpackerReact.setup({ Testimonial });
WebpackerReact.setup({ TopDeals });
WebpackerReact.setup({ Alert });
WebpackerReact.setup({ StaticPage });
WebpackerReact.setup({ BlogPosts });
