import { connect } from 'react-redux';
import {
  setSkipAuthenticationStatusesByToken,
  updateCurrentAuthenticationRequest,
  updateRequestsToAuthenticateByToken,
  updateBookingRequestsEncodedByToken,
  updateBookingComponentRefByToken,
  stringifyNewBookingRequest,
  resetSkipAuthenticationStatusesByToken,
  resetButtonAndAlertStates,
  onUpdateThreeDsStatusesByToken,
  resetThreeDsStatuseByToken,
  toggleCardAddressFormState,
} from './actions';

const mapStateToProps = state => state.ccvPayment;

const mapDispatchToProps = dispatch => ({
  setSkipAuthenticationStatusesByToken: encodedData =>
    dispatch(setSkipAuthenticationStatusesByToken(encodedData)),
  updateCurrentAuthenticationRequest: encodedRequest =>
    dispatch(updateCurrentAuthenticationRequest(encodedRequest)),
  updateRequestsToAuthenticateByToken: requestsThatNeedAuthentication =>
    dispatch(updateRequestsToAuthenticateByToken(requestsThatNeedAuthentication)),
  updateBookingRequestsEncodedByToken: newBookingReqState =>
    dispatch(updateBookingRequestsEncodedByToken(newBookingReqState)),
  updateBookingComponentRefByToken: newBookingRefState =>
    dispatch(updateBookingComponentRefByToken(newBookingRefState)),
  stringifyNewBookingRequest: newBookingReqState =>
    dispatch(stringifyNewBookingRequest(newBookingReqState)),
  onUpdateThreeDsStatusesByToken: tokenData => dispatch(onUpdateThreeDsStatusesByToken(tokenData)),
  toggleCardAddressFormState: willResetCardAddressForm =>
    dispatch(toggleCardAddressFormState(willResetCardAddressForm)),
  resetThreeDsStatusesByToken: () => dispatch(resetThreeDsStatuseByToken()),
  resetSkipAuthenticationStatusesByToken: () => dispatch(resetSkipAuthenticationStatusesByToken()),
  resetButtonAndAlertStates: () => dispatch(resetButtonAndAlertStates()),
});

const withCCVPaymentHandle = connect(mapStateToProps, mapDispatchToProps);

export default withCCVPaymentHandle;
