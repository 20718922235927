import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import { Text } from '@wtag/rcl-typography';
import './fare-brand-card-row.styles.scss';

const FareBrandCardRow = props => {
  const { facilityDescription, icon } = props;

  return (
    <li
      className={classNames('fare-brand-card-row', {
        'fare-brand-card-row--light-gray': icon.color === 'light-gray',
      })}
    >
      <Icon className="fare-brand-card-row__icon" name={icon.name} color={icon.color} />
      <Text className="fare-brand-card-row__description" level={2} weight="normal">
        {facilityDescription}
      </Text>
    </li>
  );
};

FareBrandCardRow.propTypes = {
  facilityDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};

export default FareBrandCardRow;
