const localStorageEnabled = () => {
  const check = new Date();
  try {
    window.localStorage.setItem(check, check);
    window.localStorage.removeItem(check);
    return true;
  } catch (exception) {
    return false;
  }
};

const localStorageAvailable = localStorageEnabled();

export const localStorageGetItem = key => {
  if (localStorageAvailable) return window.localStorage.getItem(key);
  return null;
};

export const localStorageSetItem = (key, value) => {
  if (localStorageAvailable) return window.localStorage.setItem(key, value);
  return undefined;
};

export const localStorageRemoveItem = key => {
  if (localStorageAvailable) return window.localStorage.removeItem(key);
  return undefined;
};

export default {
  getItem: localStorageGetItem,
  setItem: localStorageSetItem,
  removeItem: localStorageRemoveItem,
};
