import { FormatDateTime, DATE_FORMAT_SHORT } from 'sharedWebpack/helpers/dateTime';
import moment from 'moment';

const getFormattedDate = date =>
  FormatDateTime({
    dateTime: date,
    displayWithoutConversion: true,
    format: DATE_FORMAT_SHORT,
  });

const getHotelStayInformation = (
  checkIn,
  checkOut,
  numberOfRooms,
  traveler,
  isHotelDetailsPage,
) => {
  const checkInTime = getFormattedDate(checkIn);
  const checkOutTime = getFormattedDate(checkOut);
  const numberOfNights = moment(checkOut).diff(moment(checkIn), 'days');
  const durationOfStay = `${checkInTime} - ${checkOutTime}
  (${I18n.t(
    'admin.components.orders.overview.travel_plan.hotel.header.duration_of_stays.number_of_nights',
    {
      count: numberOfNights,
    },
  )})`;

  const hotelStayInformation = [];

  if (isHotelDetailsPage) {
    hotelStayInformation.push({
      title: I18n.t(
        'admin.components.orders.overview.travel_plan.hotel.header.duration_of_stays.label',
        {
          count: numberOfNights,
        },
      ),
      subtitle: durationOfStay,
    });
  } else {
    hotelStayInformation.push(
      {
        title: I18n.t('admin.components.orders.overview.travel_plan.hotel.header.check_in.label'),
        subtitle: checkInTime,
      },
      {
        title: I18n.t('admin.components.orders.overview.travel_plan.hotel.header.check_out.label'),
        subtitle: checkOutTime,
      },
    );
  }

  hotelStayInformation.push(
    {
      title: I18n.t('admin.components.orders.overview.travel_plan.hotel.header.room.label'),
      subtitle: I18n.t('components.ibe.search_form.hotel.label.number_of_rooms', {
        count: numberOfRooms,
      }),
    },
    {
      title: I18n.t('simple_form.labels.order_search.traveler'),
      subtitle: traveler,
    },
  );

  return hotelStayInformation;
};

export default getHotelStayInformation;
