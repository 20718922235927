import React from 'react';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

const DealLoader = () => (
  <div className="grid">
    <div className="col-12">
      <ContentLoaderPlaceholder numberOfLines={3} showBackground={true} />
    </div>
    <div className="col-8">
      <ContentLoaderPlaceholder numberOfLines={10} showBackground={true} />
    </div>
    <div className="col-4">
      <ContentLoaderPlaceholder numberOfLines={10} showBackground={true} />
    </div>
  </div>
);

export default DealLoader;
