import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoizeLocationQuery from '../../helpers/memoizeLocationQuery';
import fetchOnUpdate from '../../lib/decorators/fetchOnUpdate';
import {
  fetchCarSearchResults,
  filterCarSearch,
  resetCarSearchFilters,
  resetCarSearchFiltersByKey,
  addCarToCart,
  carAdded,
} from '../../actions/car';
import { fetchAirportDetails, selectCarResultInMultiSearch } from '../../actions/common';

import Results from './Results';

const mapStateToProps = (state, ownProps) => {
  const locationQuery = memoizeLocationQuery(ownProps.location.search);
  const searchId = ownProps.match.params.searchId;
  const results = state.cars.searchResultsBySearchId[searchId] || [];
  const currency = (results.length && results[0].currency) || '';
  const selectedItemResultIds = state.common.cart.items.map(
    item => item.bookingAttributes.resultId,
  );

  let searchParamsId = 'lastSearch';

  if (searchId && !locationQuery.cached && state.cars.searchParamsBySearchId[searchId]) {
    searchParamsId = searchId;
  }

  const searchInformation = state.cars.searchParamsBySearchId[searchParamsId];
  const airports = state.common.airports;

  return {
    results,
    stats: state.cars.searchStatsBySearchId[searchId],
    filterParams: state.cars.searchFilterParamsBySearchId[searchId],
    searchYieldedResults: state.cars.totalResultsBySearchId[searchId] > 0,
    searchCompleted: !!state.cars.searchResultsBySearchId[searchId],
    currency,
    searchInformation,
    airports,
    selectedItemResultIds,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchResults: searchId => dispatch(fetchCarSearchResults({ searchId })),
  filterResults: (filter, value) =>
    dispatch(filterCarSearch(filter, value, ownProps.match.params.searchId)),
  resetFilters: stats => dispatch(resetCarSearchFilters(ownProps.match.params.searchId, stats)),
  resetFiltersByKey: stats => {
    dispatch(resetCarSearchFiltersByKey(ownProps.match.params.searchId, stats));
  },
  addToCart: (details, justification) =>
    dispatch(addCarToCart(details, ownProps.callbackParams, justification)),
  selectInMultiSearch: (groupKey, result) =>
    dispatch(selectCarResultInMultiSearch(groupKey, result, ownProps.callbackParams)),
  fetchAirportDetails: id => dispatch(fetchAirportDetails(id)),
  onCarSelect: isCarAdded => dispatch(carAdded(isCarAdded)),
});

const ResultsWithFetchOnUpdate = fetchOnUpdate(['searchId'], (params, props) => {
  if (params.searchId) {
    props.fetchResults(params.searchId);
  }
})(Results);

const ConnectedResults = connect(mapStateToProps, mapDispatchToProps)(ResultsWithFetchOnUpdate);

const ResultsWithContext = (props, context) => <ConnectedResults {...props} {...context} />;

ResultsWithContext.contextTypes = {
  callbackParams: PropTypes.shape().isRequired,
};
export default ResultsWithContext;
