import React from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
// eslint-disable-next-line import/no-webpack-loader-syntax
import HeaderIcon from '-!svg-react-loader!../../../../app/assets/images/plan_limit.svg';
import './styles.scss';

const AccessDeniedPage = ({ affiliatesUrl, planUrl }) => (
    <div className="access-denied-page">
      <div className="access-denied-page-header">
        <div className="access-denied-page-header-wrapper">
          <div className="access-denied-page-header-wrapper__title">
            {I18n.t('public.plan_limit.title')}
          </div>
          <HeaderIcon className="access-denied-page-header-wrapper__logo" />
        </div>
      </div>
      <div className="access-denied-page-content">
        <div className="access-denied-page-content-wrapper">
          <div className="access-denied-page-content-wrapper__header">
            {I18n.t('public.plan_limit.header_text')}
          </div>
          <div className="access-denied-page-content-wrapper__text">
            {I18n.t('public.plan_limit.description')}
          </div>
          <Button
            className="access-denied-page-content-wrapper__button"
            version="v2"
            size="small"
            type="danger"
            label={I18n.t('public.plan_limit.view_plans')}
            onClick={() => document.location.assign(planUrl)}
          />
          <a className="access-denied-page-content-wrapper__back-button" href={affiliatesUrl}>
            {I18n.t('public.plan_limit.back_button')}
          </a>
        </div>
      </div>
    </div>
  );

AccessDeniedPage.propTypes = {
  affiliatesUrl: PropTypes.string.isRequired,
  planUrl: PropTypes.string.isRequired,
};
export default AccessDeniedPage;
