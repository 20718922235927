import getTripType from '../../lib/helpers/getTripType';
import getHotelTravelersInfo from './getHotelTravelersInfo';
import toCapitializeForm from './toCapitializeForm';
import getPriceInfo from './getPriceInfo';
import getSelectedServices from './getSelectedServices';
import getTripInfo from './getTripInfo';
import getUniqueElements from './getUniqueElements';
import getAirlineText from './getAirlineText';
import getRoute from './getRoute';

const generateFlightContent = (item, travelers, journeyElement, flightServicesByResultId) => {
  const { trips, fareCalculations } = journeyElement;
  const {
    currency: itemCurrency,
    grossTotalIncludingSubItems,
    available,
    subItems,
    bookingAttributes,
  } = item;
  const travelersInfo = getHotelTravelersInfo(travelers);
  const priceInfo = getPriceInfo(fareCalculations, itemCurrency, grossTotalIncludingSubItems);
  const airlineText = getAirlineText(trips);
  const { originCity, destinationCity } = getRoute(trips);

  const flightTitle = I18n.t('components.ibe.search_results.flight.content.title', {
    originCity,
    destinationCity,
  });

  const tripType = I18n.t(getTripType(trips), {
    scope: 'components.ibe.search_form.flight.label',
  });

  const services = getSelectedServices(
    flightServicesByResultId,
    subItems,
    bookingAttributes.resultId,
  );

  const tripInfo = getTripInfo(trips, services);

  const tripServices = services
    .filter(({ trip, segment }) => !!trip && !segment)
    .map(({ description }) => description);

  const itemServices = services
    .filter(({ trip, segment }) => !trip && !segment)
    .map(({ description }) => description);

  const allServices = [...tripServices, ...itemServices];

  const allServicesInfo =
    allServices.length > 0
      ? `\n${I18n.t('components.ibe.search_results.flight.content.services', {
          services: allServices.join(', '),
        })}`
      : '';

  const classes = trips.map(
    ({ cabinClass, bookingClasses }) =>
      `${toCapitializeForm(cabinClass)} - ${bookingClasses.join(', ')}`,
  );

  const uniqueClasses = getUniqueElements(classes).join(' - ');

  const flightNumbers = trips
    .flatMap(trip => trip.segments.map(segment => segment.operatingFlight))
    .join(', ');

  const flightNumberText = I18n.t('components.ibe.search_results.flight.content.flight_numbers', {
    flightNumbers,
  });

  const status = available
    ? I18n.t('components.ibe.search_results.flight.content.status.available')
    : I18n.t('components.ibe.search_results.flight.content.status.not_available');

  return `${flightTitle}${tripType} - ${uniqueClasses}\n---\n${airlineText}\n${flightNumberText}${allServicesInfo}\n---\n${travelersInfo}${priceInfo}\n${status}${tripInfo}`;
};

export default generateFlightContent;
