import { combineReducers } from 'redux';
import { mapCartGroups, replaceItemInGroup } from '../../helpers/multiSearchMapping';

import { APPROVAL_REQUEST_FETCHED, MSEARCH_RESULT_CONFIRMED } from '../../actions/common';

function id(state = null, action) {
  switch (action.type) {
    case APPROVAL_REQUEST_FETCHED:
      return action.approvalRequest.id;

    default:
      return state;
  }
}

function groups(state = [], action) {
  switch (action.type) {
    case APPROVAL_REQUEST_FETCHED:
      return mapCartGroups(action.approvalRequest.groups);

    case MSEARCH_RESULT_CONFIRMED:
      return replaceItemInGroup(
        state,
        action.groupKey.toString(),
        action.cartItem,
        action.oldItemId,
      );

    default:
      return state;
  }
}

function status(state = null, action) {
  switch (action.type) {
    case APPROVAL_REQUEST_FETCHED:
      return action.approvalRequest.status;

    default:
      return state;
  }
}

const approvalRequest = combineReducers({
  id,
  groups,
  status,
});

export default approvalRequest;
