import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import ClassNames from 'classnames';
import { EmphasisTag, Link } from '@wtag/react-comp-lib';
import DateTime from 'sharedWebpack/DateTime';
import Card from '@wtag/rcl-card';
import { Text } from '@wtag/rcl-typography';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import Button from '@wtag/rcl-button';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import LazyLoad from 'react-lazyload';
import Modal from 'sharedWebpack/Modal';

import VisaNumberForm from './VisaNumberForm';

const VisaNumber = ({
  visaNumber,
  fetchVisaNumbers,
  visaCategories,
  visaNumberID,
  personId,
  countryOptions,
  layman,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState({});

  const [category, setCategory] = useState(null);
  const [country, setCountry] = useState(null);
  const [issueDate, setIssueDate] = useState(null);
  const [number, setNumber] = useState('');
  const [expireDate, setExpireDate] = useState(null);
  const [visaFile, setVisaFile] = useState(null);
  const [visaFileName, setVisaFileName] = useState('');
  const [visaFileType, setVisaFileType] = useState('');
  const [visaFileDocumentUrl, setVisaFileDocumentUrl] = useState('');
  const [viewImageModal, setViewImageModal] = useState(false);
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);

  const toggleShowConfirmation = () => {
    setIsShowConfirmation(prev => !prev);
  };

  const updateVisaNumber = async visaParams => {
    const updateVisaNumberUrl = layman
      ? routes.public.visaNumbers.update({ visaNumberID })
      : routes.admin.visaNumbers.update({ visaNumberID, person_id: personId });

    const { data } = await httpClient.patch(updateVisaNumberUrl, visaParams);

    if (data.error === null) {
      fetchVisaNumbers();
      setIsEditing(false);
    }
  };

  const onUpdateVisaNumber = () => {
    const formData = new FormData();

    if (visaFile) {
      formData.append('visa_number[file]', visaFile);
    } else if (!visaFileName) {
      formData.append('visa_number[remove_file]', true);
    }

    formData.append('visa_number[category]', category);
    formData.append('visa_number[country]', country);
    formData.append('visa_number[issue_date]', issueDate);
    formData.append('visa_number[number]', number);
    formData.append('visa_number[expire_date]', expireDate);
    formData.append('visa_number[person_id]', personId);

    updateVisaNumber(formData).catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });
  };

  const deleteVisaNumber = async () => {
    toggleShowConfirmation();
    setIsEditing(false);
    const deleteVisaNumberUrl = layman
      ? routes.public.visaNumbers.delete({ visaNumberID })
      : routes.admin.visaNumbers.delete({ visaNumberID, person_id: personId });

    const { data } = await httpClient.delete(deleteVisaNumberUrl);

    if (data.error === null) {
      fetchVisaNumbers();
    }
  };

  const onDeleteVisaNumber = () =>
    deleteVisaNumber().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  const openViewImageModal = () => {
    setViewImageModal(true);
  };

  const toggleDocumentFileCallback = visaFileDocumentUrl ? openViewImageModal : undefined;

  const setVisaNumberValues = visaNumberValues => {
    setCategory(visaNumberValues.category);
    setCountry(visaNumberValues.countryCode);
    setExpireDate(visaNumberValues.expireDate);
    setNumber(visaNumberValues.number);
    setIssueDate(visaNumberValues.issueDate);
    setVisaFileName(visaNumberValues.fileName);
    setVisaFileDocumentUrl(visaNumberValues.fileUrl);
    setError({});
    setVisaFile(null);
    setVisaFileType(visaNumberValues.mimeType);
  };

  const downloadFileDocument = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = visaFileDocumentUrl;
    downloadLink.download = visaFileDocumentUrl.split('/').pop();
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    setVisaNumberValues(visaNumber);
  }, [visaNumber]);

  const isVisaDocumentPdfMimeType = !!visaFileType && visaFileType.includes('pdf');

  const renderVisaNumber = () => (
    <Fragment>
      <div className="col-8">
        <div className="grid grid-gap-20">
          <div className="col-12">
            <div className="grid grid-gap-20">
              <div className="col-4 col-grid traveller-edit-identification-document__content">
                <div className="traveller-edit-identification-document__header">
                  {I18n.t('admin.components.travellers.edit.visa_numbers.label.country')}
                </div>
                <div className="traveller-edit-identification-document__value">
                  {visaNumber.country}
                </div>
              </div>
              <div className="col-4 col-grid traveller-edit-identification-document__content">
                <div className="traveller-edit-identification-document__header">
                  {I18n.t('admin.components.travellers.edit.visa_numbers.label.number')}
                </div>
                <div className="traveller-edit-identification-document__value">
                  {visaNumber.number}
                </div>
              </div>
              <div className="col-4 col-grid traveller-edit-identification-document__content">
                <div className="traveller-edit-identification-document__header">
                  {I18n.t('admin.components.travellers.edit.visa_numbers.label.issue_date')}
                </div>
                <div className="traveller-edit-identification-document__value">
                  <DateTime dateTime={visaNumber.issueDate} format="short" />
                </div>
              </div>
              <div className="col-4 col-grid traveller-edit-identification-document__content">
                <div className="traveller-edit-identification-document__header">
                  {I18n.t('admin.components.travellers.edit.visa_numbers.label.expire_date')}
                </div>
                <div className="traveller-edit-identification-document__value">
                  <DateTime dateTime={visaNumber.expireDate} format="short" />
                </div>
              </div>
              <div className="col-4 col-grid traveller-edit-identification-document__content">
                <div className="traveller-edit-identification-document__header">
                  {I18n.t('admin.components.travellers.edit.visa_numbers.label.visa_type')}
                </div>
                <div className="traveller-edit-identification-document__value">
                  {I18n.t(visaNumber.category, {
                    scope: 'simple_form.options.person.visa_numbers.category',
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const renderDocumentVisaFileImageModal = () => {
    const documentImageModalTitle = (
      <div className="col-grid col-bleed direction-row justify-space-between traveller-edit-identification-document__image-preview-modal__header">
        <Text
          className="traveller-edit-identification-document__modal__text"
          level={5}
          weight="bold"
        >
          {I18n.t('admin.visa_number.modal.title')}
        </Text>
        <IconButton
          className="traveller-edit-identification-document__modal__icon-button"
          icon={<Icon name="close" />}
          isIconOnly={true}
          color="tertiary"
          size="large"
          onClick={() => setViewImageModal(false)}
        />
      </div>
    );

    const documentImageModalContent = (
      <div className="grid">
        <div className="col-12 col-bleed">
          <LazyLoad height="100%" once={true}>
            <img className="flex-img" src={visaFileDocumentUrl} alt="visaDocument" loading="lazy" />
          </LazyLoad>
        </div>
      </div>
    );

    const documentImageModal = (
      <Modal
        className="traveller-edit-identification-document__modal"
        isModalVisible={viewImageModal}
        title={documentImageModalTitle}
      >
        {documentImageModalContent}
      </Modal>
    );

    return documentImageModal;
  };

  return (
    <Card
      className="traveller-edit-identification-document__container traveller-edit-identification-document__update-container"
      key={visaNumber.id}
      version="v2"
      showMoreText={I18n.t('admin.components.travellers.edit.address.form.label.show')}
      hideMoreText={I18n.t('admin.components.travellers.edit.address.form.label.hide')}
      cardBodySize={ClassNames({
        medium: visaNumber.isExpired,
        small: !visaNumber.isExpired,
      })}
    >
      {isEditing ? (
        <Fragment>
          <VisaNumberForm
            countryOptions={countryOptions}
            visaCategories={visaCategories}
            number={number}
            setNumber={setNumber}
            category={category}
            setCategory={setCategory}
            country={country}
            setCountry={setCountry}
            issueDate={issueDate}
            setIssueDate={setIssueDate}
            expireDate={expireDate}
            setExpireDate={setExpireDate}
            visaFileName={visaFileName}
            visaFileType={visaFileType}
            visaFileUploadPreviewUrl={visaFileDocumentUrl}
            setError={setError}
            setVisaFileName={setVisaFileName}
            setVisaFileType={setVisaFileType}
            setVisaFile={setVisaFile}
            setVisaFileUploadPreviewUrl={setVisaFileDocumentUrl}
            error={error}
          />
          {visaNumber && (
            <div className="traveller-edit-identification-document-form__actions">
              <Button
                version="v2"
                size="small"
                type="primary"
                label={I18n.t('admin.components.travellers.edit.identification_documents.update')}
                onClick={onUpdateVisaNumber}
                disabled={!!error.visaNumbers || !!error.visaNumbersFile}
              />
              <Button
                version="v2"
                size="small"
                label={I18n.t('admin.components.shared.action.cancel')}
                type="default"
                onClick={() => {
                  setVisaNumberValues(visaNumber);
                  setIsEditing(false);
                }}
              />
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <div className="grid grid-gap-20">
            {visaNumber.isExpired && (
              <div className="col-12 col-grid col-bleed-y traveller-edit-identification-document__warning">
                {I18n.t('admin.components.travellers.edit.visa_numbers.warning')}
              </div>
            )}
            <div className="col-12 col-grid direction-row traveller-edit-identification-document">
              <div className="traveller-edit-identification-document__summary">
                <span className="traveller-edit-identification-document__content">
                  <img src={visaNumber.flagUrl} alt={visaNumber.country} />
                </span>
                <span className="traveller-edit-identification-document__content traveller-edit-identification-document__content--country">
                  {visaNumber.country}
                </span>
                <span className="traveller-edit-identification-document__content traveller-edit-identification-document__content--visa-tag">
                  <EmphasisTag
                    className="emphasis-tag--with-no-left-margin"
                    type="default"
                    size="tiny"
                    text={I18n.t(visaNumber.category, {
                      scope: 'simple_form.options.person.visa_numbers.category',
                    })}
                  />
                </span>
                {visaNumber.isExpired && (
                  <span className="traveller-edit-identification-document__content">
                    <EmphasisTag
                      type="disabled"
                      size="tiny"
                      text={I18n.t(
                        'admin.components.travellers.edit.visa_numbers.label.deactivated',
                      )}
                    />
                  </span>
                )}
              </div>
              <div className="traveller-edit-identification-document__actions">
                <span className="traveller-edit-identification-document__content">
                  <Link
                    labelHint={
                      visaFileDocumentUrl
                        ? I18n.t('admin.shared.action.download')
                        : I18n.t('admin.shared.action.cannot_download')
                    }
                    type="icon-button"
                    modifier="tertiary"
                    disabled={!visaFileDocumentUrl}
                    onClick={downloadFileDocument}
                  >
                    <Icon name="download" size="small" />
                  </Link>
                </span>
                <span className="traveller-edit-identification-document__content">
                  <Link
                    labelHint={I18n.t('admin.shared.action.edit')}
                    type="icon-button"
                    modifier="tertiary"
                    onClick={() => setIsEditing(true)}
                  >
                    <Icon name="edit" size="small" />
                  </Link>
                </span>
                <span className="traveller-edit-identification-document__content">
                  <Link
                    labelHint={I18n.t('admin.shared.action.destroy')}
                    type="icon-button"
                    modifier="tertiary"
                    onClick={toggleShowConfirmation}
                  >
                    <Icon name="delete" size="small" />
                  </Link>
                </span>
              </div>
            </div>
            {isVisaDocumentPdfMimeType ? (
              <div className="col-4 col-grid justify-center">
                <Link
                  className="traveller-edit-identification-document-section__file-preview"
                  href={visaFileDocumentUrl}
                  modifier="tertiary"
                  openNewTab={true}
                >
                  <Icon name="pdf" size="small" />
                  <div className="traveller-edit-identification-document__preview-text">
                    {I18n.t('admin.shared.action.preview_pdf')}
                  </div>
                </Link>
              </div>
            ) : (
              <div
                role="presentation"
                onClick={toggleDocumentFileCallback}
                className="col-4 col-grid justify-center"
              >
                <div className="traveller-edit-identification-document-section__image-container">
                  {visaFileDocumentUrl ? (
                    <LazyLoad height="100%">
                      <div className="traveller-edit-identification-document-section__image-wrapper">
                        <img
                          className="flex-img traveller-edit-identification-document-section__image"
                          src={visaFileDocumentUrl}
                          alt="visaDocument"
                          loading="lazy"
                        />
                        <div className="traveller-edit-identification-document-section__image-overlay" />
                        <div className="traveller-edit-identification-document-section__image-overlay--on-hover" />
                      </div>
                    </LazyLoad>
                  ) : (
                    <div
                      className={ClassNames(
                        'traveller-edit-identification-document-section__file-preview traveller-edit-identification-document-section__file-preview--no-preview',
                        {
                          'traveller-edit-identification-document-section__file-preview--disabled-cursor-pointer': !visaFileDocumentUrl,
                        },
                      )}
                    >
                      <Icon color="tertiary" name="orders" size="small" />
                      <div className="traveller-edit-identification-document__preview-text">
                        {I18n.t('admin.shared.action.unable_to_preview_file')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!!visaFileDocumentUrl &&
              !isVisaDocumentPdfMimeType &&
              renderDocumentVisaFileImageModal()}
            {renderVisaNumber()}
          </div>
        </Fragment>
      )}

      {isShowConfirmation && (
        <ConfirmationModal
          confirmationHeader={I18n.t(
            'admin.components.orders.left_panel.visa_numbers.action.delete.title',
          )}
          subHeader={I18n.t(
            'admin.components.orders.left_panel.visa_numbers.action.delete.subtitle',
          )}
          isModalOpen={true}
          withAction={true}
          type="danger"
          confirmationText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.positive',
          )}
          rejectionText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.negative',
          )}
          onConfirm={onDeleteVisaNumber}
          onReject={toggleShowConfirmation}
        />
      )}
    </Card>
  );
};

VisaNumber.defaultProps = {
  visaNumber: {},
  layman: false,
};

VisaNumber.propTypes = {
  fetchVisaNumbers: PropTypes.func.isRequired,
  visaCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  visaNumber: PropTypes.shape({
    id: PropTypes.number,
    flagUrl: PropTypes.string,
    expireDate: PropTypes.string,
    isExpired: PropTypes.bool,
    category: PropTypes.string,
    country: PropTypes.string,
    countryCode: PropTypes.string,
    number: PropTypes.string,
    issueDate: PropTypes.string,
    fileName: PropTypes.string,
  }),
  visaNumberID: PropTypes.number.isRequired,
  personId: PropTypes.number.isRequired,
  layman: PropTypes.bool,
};

export default VisaNumber;
