import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Card from '@wtag/rcl-card';
import classNames from 'classnames';
import './styles.scss';

const CommentPlaceholder = ({
  numberOfCards,
  numberOfRows,
  removeContentPadding,
  removeBoxShadow,
}) => (
  <Fragment>
    {Array(numberOfCards)
      .fill(Math.random())
      .map((data, arrayIndex) => (
        <Card
          version="v2"
          className={classNames('comment-data-loader', {
            'comment-data-loader--remove-content-padding': removeContentPadding,
            'comment-data-loader--remove-box-shadow': removeBoxShadow,
          })}
          key={(data + arrayIndex).toString()}
        >
          <ContentLoaderPlaceholder
            numberOfLines={numberOfRows}
            showBackground={false}
            isRounded={false}
          />
        </Card>
      ))}
  </Fragment>
);

CommentPlaceholder.defaultProps = {
  numberOfCards: 1,
  numberOfRows: 1,
  removeContentPadding: false,
  removeBoxShadow: true,
};

CommentPlaceholder.propTypes = {
  numberOfCards: PropTypes.number,
  numberOfRows: PropTypes.number,
  removeContentPadding: PropTypes.bool,
  removeBoxShadow: PropTypes.bool,
};

export default CommentPlaceholder;
