/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Modal from 'sharedWebpack/Modal';
import GeneralRemarks from './GeneralRemarksContainer';
import AccountingRemarks from './AccountingRemarksContainer';
import QueuePlacement from './QueuePlacementContainer';
import './style.scss';

class QueuesAndRemarks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queueModalOpen: false,
      generalRemarksModalOpen: false,
      accRemarksModalOpen: false,
    };
  }

  onQueueModalOpen = () => {
    this.setState({
      queueModalOpen: true,
    });
  };

  onGeneralRemarksModalOpen = () => {
    this.setState({
      generalRemarksModalOpen: true,
    });
  };

  onAccRemarksModalOpen = () => {
    this.setState({
      accRemarksModalOpen: true,
    });
  };

  onQueueModalClose = () => {
    this.setState({ queueModalOpen: false });
  };

  onGeneralRemarksModalClose = () => {
    this.setState({ generalRemarksModalOpen: false });
  };

  onAccRemarksModalClose = () => {
    this.setState({ accRemarksModalOpen: false });
  };

  confirmQueueSelection = () => {
    this.setState({ queueModalOpen: !this.state.queueModalOpen });
  };

  confirmGeneralRemarksSelection = () => {
    this.setState({ generalRemarksModalOpen: !this.state.generalRemarksModalOpen });
  };

  confirmAccRemarksSelection = () => {
    this.setState({ accRemarksModalOpen: !this.state.accRemarksModalOpen });
  };

  render() {
    const context = this.context;
    const props = this.props;
    if (context.laymanMode) return null;
    if (props.channelsWithQueuesAndRemarks.includes(props.item.channel.identifier)) {
      return (
        <div className="payment-form-item__info-buttons">
          <Button
            size="small"
            version="v2"
            label={I18n.t('components.ibe.queue_placement.title')}
            onClick={() => this.setState({ queueModalOpen: true })}
          />
          <Modal
            className="payment__queue-modal"
            title={
              <Fragment>
                {I18n.t('components.ibe.queue_placement.title')}
                <div
                  onClick={this.onQueueModalClose}
                  onKeyDown={this.onQueueModalClose}
                  role="presentation"
                >
                  <Icon name="close" />
                </div>
              </Fragment>
            }
            isModalVisible={this.state.queueModalOpen}
            onOutsideClick={this.onQueueModalClose}
            footer={
              <Button
                version="v2"
                size="small"
                label={I18n.t('components.ibe.queue_placement.confirm')}
                id="queuePlacement.button"
                primary={true}
                onClick={this.confirmQueueSelection}
              />
            }
            alignFooter="left"
          >
            <QueuePlacement
              defaultAccount={props.item.channel.account}
              resultId={props.item.bookingAttributes.resultId}
            />
          </Modal>

          <Button
            size="small"
            version="v2"
            onClick={() => this.setState({ generalRemarksModalOpen: true })}
            label={I18n.t('components.ibe.remark_types.general_remarks')}
          />
          <Modal
            className="payment__remarks-modal"
            title={
              <Fragment>
                {I18n.t('components.ibe.remark_types.general_remarks')}
                <div
                  onClick={this.onGeneralRemarksModalClose}
                  onKeyDown={this.onGeneralRemarksModalClose}
                  role="presentation"
                >
                  <Icon name="close" />
                </div>
              </Fragment>
            }
            isModalVisible={this.state.generalRemarksModalOpen}
            onOutsideClick={this.onGeneralRemarksModalClose}
            footer={
              <Button
                version="v2"
                size="small"
                label={I18n.t('components.ibe.remarks.confirm')}
                id="remarks.button"
                primary={true}
                onClick={this.confirmGeneralRemarksSelection}
              />
            }
            alignFooter="left"
          >
            <GeneralRemarks
              channel={props.item.channel.identifier}
              resultId={props.item.bookingAttributes.resultId}
              callbackParams={context.callbackParams}
            />
          </Modal>

          {props.item.journeyElementType === 'flight' && (
            <Fragment>
              <Button
                className={classNames('payment__remarks-button', {
                  'payment__remarks-button--disabled': props.item.journeyElementType !== 'flight',
                })}
                size="small"
                version="v2"
                label={I18n.t('components.ibe.accounting_remarks.title')}
                onClick={() => this.setState({ accRemarksModalOpen: true })}
              />
              <Modal
                className="payment__remarks-modal"
                title={
                  <Fragment>
                    {I18n.t('components.ibe.accounting_remarks.title')}
                    <div
                      onClick={this.onAccRemarksModalClose}
                      onKeyDown={this.onAccRemarksModalClose}
                      role="presentation"
                    >
                      <Icon name="close" />
                    </div>
                  </Fragment>
                }
                isModalVisible={this.state.accRemarksModalOpen}
                onOutsideClick={this.onAccRemarksModalClose}
                footer={
                  <Button
                    version="v2"
                    size="small"
                    label={I18n.t('components.ibe.remarks.confirm')}
                    id="remarks.button"
                    primary={true}
                    onClick={this.confirmAccRemarksSelection}
                  />
                }
                alignFooter="left"
              >
                <AccountingRemarks
                  channel={props.item.channel.identifier}
                  resultId={props.item.bookingAttributes.resultId}
                />
              </Modal>
            </Fragment>
          )}
        </div>
      );
    }

    return null;
  }
}

QueuesAndRemarks.contextTypes = {
  currency: PropTypes.string,
  laymanMode: PropTypes.bool,
};

export default QueuesAndRemarks;
