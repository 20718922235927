import React from 'react';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import Icon from '@wtag/rcl-icon';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

class FrequentFlyerNumberInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carrierData: { logo: null },
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.fields.carrier.value !== '') this.searchAirline(this.props.fields.carrier.value);
  }

  searchAirline = code => {
    this.setState({ isLoading: true });

    const url = routes.api.airline({ code });

    return httpClient.get(url).then(({ data }) => {
      this.setState({ carrierData: data });
      this.setState({ isLoading: false });
    });
  };

  frequentFlyerNumberNotProvided = () =>
    this.props.fields.carrier.value === '' && this.props.fields.number.value === '';

  showCarrierNumber = () => {
    const { number } = this.props.fields;
    const { value, error, invalid } = number;

    if (!value) {
      return (
        <div className="frequent-flyer-fields__attributes-invalid">
          {I18n.t('components.ibe.traveler_details.attributes.not_provided', {
            attribute: I18n.t('components.ibe.traveler_details.attributes.number'),
          })}
        </div>
      );
    } else if (invalid) {
      return (
        <div>
          <div className="col-grid col-bleed direction-row align-center">
            <div className="frequent-flyer-fields__attributes-invalid">{value}</div>
            <div className="frequent-flyer-fields__attributes-tooltip">
              <Tooltip content={error} type="danger" position="bottom-left">
                <Icon name="invalid" color="danger" />
              </Tooltip>
            </div>
          </div>
        </div>
      );
    }

    return <div className="frequent-flyer-fields__attributes-value">{value}</div>;
  };

  showFrequentFlyerContent = () => {
    const { carrierData } = this.state;
    const { logo, code, name } = carrierData;

    if (this.frequentFlyerNumberNotProvided()) {
      return (
        <div className="frequent-flyer-fields__not-provided">
          <span>
            {I18n.t('components.ibe.traveler_details.attributes.not_selected', {
              attribute: I18n.t('components.ibe.traveler_details.attributes.ffn'),
            })}
          </span>
          <span>
            {I18n.t('components.ibe.traveler_details.attributes.available_ffn', {
              count: this.props.frequentFlyerNumbersList.length,
            })}
          </span>
        </div>
      );
    }

    return (
      <div className="col-grid col-bleed direction-row frequent-flyer-fields__attributes">
        {logo && (
          <div className="frequent-flyer-fields__attributes-logo">
            <img src={logo} alt={code} />
          </div>
        )}
        {name && (
          <div className="col-auto col-bleed frequent-flyer-fields__attributes-text">
            <div className="frequent-flyer-fields__attributes-text-carrier">{name}</div>
            {this.showCarrierNumber()}
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="frequent-flyer-fields">
        <div className="frequent-flyer-fields__header">
          {I18n.t('components.ibe.traveler_details.frequent_flyer_program')}
        </div>
        {this.state.isLoading ? (
          <ContentLoaderPlaceholder showBackground={false} numberOfLines={1} />
        ) : (
          this.showFrequentFlyerContent()
        )}
      </div>
    );
  }
}

FrequentFlyerNumberInformation.defaultProps = {
  frequentFlyerNumbersList: [],
};

FrequentFlyerNumberInformation.propTypes = {
  fields: PropTypes.shape().isRequired,
  frequentFlyerNumbersList: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FrequentFlyerNumberInformation;
