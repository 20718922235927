import { UPDATE_PRIMARY_BUTTON_CLICK_CALLBACK } from '../actions';

export default function onPrimaryButtonClick(state = () => {}, action) {
  switch (action.type) {
    case UPDATE_PRIMARY_BUTTON_CLICK_CALLBACK:
      return action.callback;
    default:
      return state;
  }
}
