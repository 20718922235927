import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';
import Amount from 'sharedWebpack/Amount';

const PriceBreakdown = props => {
  const { fareCalculations, travelerCount } = props;

  const priceRow = (count, passengerType) => {
    const [isUnitPriceVisible, setIsUnitPriceVisible] = useState(false);
    const selectedFare = fareCalculations.find(fare => fare.passengerType === passengerType);
    const price = selectedFare && parseFloat(selectedFare.price) * count;

    const onClickHandler = () => setIsUnitPriceVisible(prevState => !prevState);

    const formattedPrice =
      price === 0 ? (
        <I18nText id="ibe.summary.free" returnStringOnly={true} />
      ) : (
        <Amount currency={selectedFare.currency} value={price} />
      );

    return (
      <div className="cart-summary__accordion-item-body-row">
        <div className="cart-summary__accordion-sub-item-price-breackdown">
          <div className="cart-summary__accordion-sub-item-price-breackdown-type">
            {count} x {I18n.t(passengerType, { scope: 'traveler.types' })}
          </div>
          <div
            className="cart-summary__accordion-sub-item-price-breackdown-total-amount"
            role="button"
            tabIndex={0}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
          >
            {formattedPrice}
          </div>
        </div>
        <div
          className={classNames('cart-summary__accordion-sub-item-price-breackdown-unit-prices', {
            'cart-summary__accordion-sub-item-price-breackdown-unit-prices--is-visible': isUnitPriceVisible,
          })}
        >
          <div className="cart-summary__accordion-sub-item-price-breackdown-unit-prices-row">
            {count} x <Amount currency={selectedFare.currency} value={selectedFare.price} />
          </div>
        </div>
      </div>
    );
  };

  const travelerCountByType = type => {
    if (travelerCount && travelerCount[type]) return Number(travelerCount[type].length);

    return 0;
  };

  const showPriceBreakdown = () => {
    const adultsCount = travelerCountByType('adults');
    const childrenCount = travelerCountByType('children');
    const infantsCount = travelerCountByType('infants');

    return (
      <Fragment>
        {adultsCount > 0 && priceRow(adultsCount, 'adult')}
        {childrenCount > 0 && priceRow(childrenCount, 'child')}
        {infantsCount > 0 && priceRow(infantsCount, 'infant')}
      </Fragment>
    );
  };

  return showPriceBreakdown();
};

PriceBreakdown.defaultProps = {
  travelerCount: { adults: [{}], children: [], infants: [] },
};

PriceBreakdown.propTypes = {
  travelerCount: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  fareCalculations: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.string,
      currency: PropTypes.string,
      passengerType: PropTypes.string,
    }),
  ).isRequired,
};
export default PriceBreakdown;
