import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateTime from 'sharedWebpack/DateTime';
import Icon from '@wtag/rcl-icon';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import './styles.scss';

const Index = props => {
  const { journeyElementId, journeyElementType } = props;

  const [journeyElement, setJourneyElement] = useState({});

  const fetchJourneyElement = async () => {
    const { data } = await httpClient.get(
      routes.api.fetchJourneyElement({
        id: journeyElementId,
        type: journeyElementType,
      }),
    );

    setJourneyElement(data);
  };

  useEffect(() => {
    if (journeyElementId) {
      fetchJourneyElement();
    }
  }, [journeyElementId, journeyElementType]);

  const locationAndTime = (locationTimeObj, isPickUp) => (
    <div className="col-6 col-bleed col-grid align-center transfer-item__details-info">
      <div>
        <div className="col-grid col-bleed transfer-item__details-info-header">
          {isPickUp
            ? I18n.t('admin.components.orders.items_tab.placeholder.pick_up')
            : I18n.t('admin.components.orders.items_tab.placeholder.drop_off')}
        </div>
        <div className="col-grid col-bleed direction-row align-center transfer-item__details-info-time">
          <div className="transfer-item__details-info-time-icon">
            <Icon name="time" color="default" size="tiny" />
          </div>
          <div className="transfer-item__details-info-time-date">
            <DateTime format="shortWithTime" dateTime={locationTimeObj.localtimeInUserTz} />
          </div>
        </div>
        <div className="col-grid col-bleed direction-row align-center transfer-item__details-info-location">
          <div className="transfer-item__details-info-location-icon">
            <Icon name="mapMarker" size="tiny" color="danger" />
          </div>
          <div className="transfer-item__details-info-location-address">
            {locationTimeObj.location}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="col-12 transfer-item">
      <div className="col-12 col-grid direction-row justify-space-between transfer-item__details">
        {journeyElement.pickUp && locationAndTime(journeyElement.pickUp, true)}
        {journeyElement.dropOff && locationAndTime(journeyElement.dropOff, false)}
      </div>
    </div>
  );
};

Index.propTypes = {
  journeyElementId: PropTypes.number.isRequired,
  journeyElementType: PropTypes.string.isRequired,
};

export default Index;
