import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import SelectBox from '@wtag/rcl-select-box';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

const HotelPreferencesInformation = props => {
  const { personId, fetchTraveller, hotelPreferences, smokingTypes, layman } = props;

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});
  const [smokingType, setSmokingType] = useState(null);

  const smokingTypeOptions = Object.keys(smokingTypes).map(smokingTypeKey => ({
    value: smokingTypeKey,
    label: I18n.t(smokingTypeKey, { scope: 'simple_form.options.person.smoking_type' }),
  }));

  const findSelectBoxContent = (options, value) => {
    const selectedOption = options.filter(option => value === option.value)[0];
    if (selectedOption) {
      return {
        value: selectedOption.value,
        label: selectedOption.label,
      };
    }

    return null;
  };

  const saveTravellerUrl = layman
    ? routes.public.people.update()
    : routes.admin.people.update({ personId });

  const updateHotelPreferences = async () => {
    const hotelPreferencesParams = {
      smoking_type: smokingType,
    };

    const { data } = await httpClient.patch(saveTravellerUrl, {
      person: hotelPreferencesParams,
    });

    if (data.error === null) {
      fetchTraveller();
      setEditing(false);
    }
  };

  const onUpdateHotelPreferences = () =>
    updateHotelPreferences().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  useEffect(() => {
    setSmokingType(hotelPreferences.smokingType);
  }, [hotelPreferences]);

  return (
    <Fragment>
      {hotelPreferences && (
        <div className="traveller-edit-travel-preferences-section">
          <div className="traveller-edit-travel-preferences-section__title">
            <div className="traveller-edit-travel-preferences-section__header">
              {I18n.t('admin.components.travellers.overview.booking_preference.title.hotel')}
            </div>
            {editing ? (
              <div className="traveller-edit-travel-preferences-section__actions">
                <IconButton
                  icon={<Icon name="check" />}
                  color="tertiary"
                  size="small"
                  onClick={onUpdateHotelPreferences}
                />
                <IconButton
                  icon={<Icon name="close" />}
                  color="tertiary"
                  size="small"
                  onClick={() => setEditing(false)}
                />
              </div>
            ) : (
              <div className="traveller-edit-travel-preferences-section__actions">
                <IconButton
                  icon={<Icon name="edit" />}
                  color="tertiary"
                  size="small"
                  onClick={() => setEditing(true)}
                />
              </div>
            )}
          </div>
          <div className="grid grid-gap-12">
            {editing ? (
              <div className="col-12">
                <SelectBox
                  size="tiny"
                  label={I18n.t('simple_form.labels.person.smoking_type')}
                  width="full"
                  options={smokingTypeOptions}
                  value={findSelectBoxContent(smokingTypeOptions, smokingType)}
                  onChange={selectedOption =>
                    setSmokingType(selectedOption && selectedOption.value)
                  }
                  errorMsg={error.smokingType}
                />
              </div>
            ) : (
              <div className="col-12">
                <div className="traveller-edit-travel-preferences__header">
                  {I18n.t('simple_form.labels.person.smoking_type')}
                </div>
                <div className="traveller-edit-travel-preferences__value">
                  {hotelPreferences.smokingType
                    ? I18n.t(hotelPreferences.smokingType, {
                        scope: 'simple_form.options.person.smoking_type',
                      })
                    : I18n.t(
                        'admin.components.travellers.overview.booking_preference.not_selected.smoking_type',
                      )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

HotelPreferencesInformation.defaultProps = {
  layman: false,
  hotelPreferences: {
    smokingType: '',
  },
};

HotelPreferencesInformation.propTypes = {
  layman: PropTypes.bool,
  personId: PropTypes.number.isRequired,
  fetchTraveller: PropTypes.func.isRequired,
  smokingTypes: PropTypes.shape({}).isRequired,
  hotelPreferences: PropTypes.shape({
    smokingType: PropTypes.string,
  }),
};

export default HotelPreferencesInformation;
