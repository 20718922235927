import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const CountryWithFlag = ({ code, flag, name }) => (
  <div className="d-flex align-center column-gap-8 country-with-flag">
    <img className="country-with-flag__img" src={flag} alt={name} />
    <div className="country-with-flag__code">{code}</div>
  </div>
);

CountryWithFlag.propTypes = {
  code: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default CountryWithFlag;
