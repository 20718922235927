import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function CarName(props) {
  return (
    <div className={classNames('car-name', props.className)}>
      <span className="car-name__category">{props.category}</span>{' '}
      <span className="car-name__acriss-code">({props.acrissCode})</span>
    </div>
  );
}

CarName.defaultProps = {
  className: null,
};

CarName.propTypes = {
  acrissCode: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
};
