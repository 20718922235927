import { connect } from 'react-redux';
import { removeSeatsFromCart } from '../../../../actions/flight';
import FlightSeatMap from './FlightSeatMap';

const mapStateToProps = (state, ownProps) => {
  const seatItemIDs = [];
  state.common.cart.items
    .filter(item => item.id === ownProps.itemId)
    .forEach(item =>
      item.subItems
        .filter(subItem => subItem.type === 'seat')
        .forEach(seat => seatItemIDs.push(seat.id)),
    );

  return { seatItemIDs, cart: state.common.cart };
};

const mapDispatchToProps = dispatch => ({
  removeSeatsFromCart: seatItemIDs => dispatch(removeSeatsFromCart(seatItemIDs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightSeatMap);
