export const LARGE_DESKTOP_DEVICE = 'large-desktop';
export const DESKTOP_DEVICE = 'desktop';
export const DESKTOP_TAB_DEVICE = 'desktop-tab';
export const TAB_MOBILE_DEVICE = 'tab-mobile';
export const MOBILE_DEVICE = 'mobile';
export const SMALL_MOBILE_DEVICE = 'small-mobile';

const viewportIs = viewport => {
  const { innerWidth } = window;

  switch (viewport) {
    case LARGE_DESKTOP_DEVICE:
      return innerWidth > 990;
    case DESKTOP_TAB_DEVICE:
      return innerWidth > 767 && innerWidth < 991;
    case TAB_MOBILE_DEVICE:
      return innerWidth > 479 && innerWidth < 768;
    case MOBILE_DEVICE:
      return innerWidth > 359 && innerWidth < 480;
    case SMALL_MOBILE_DEVICE:
      return innerWidth < 360;
    default:
      return false;
  }
};

export const getViewportName = () => {
  const { innerWidth } = window;

  if (innerWidth < 360) {
    return SMALL_MOBILE_DEVICE;
  }
  if (innerWidth > 359 && innerWidth < 480) {
    return MOBILE_DEVICE;
  }
  if (innerWidth > 479 && innerWidth < 768) {
    return TAB_MOBILE_DEVICE;
  }
  if (innerWidth > 767 && innerWidth < 991) {
    return DESKTOP_TAB_DEVICE;
  }

  return DESKTOP_DEVICE;
};

export default viewportIs;
