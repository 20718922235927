import {
  UPDATE_PRESELECT_TRAVELERS,
  UPDATE_PRESELECT_DECISION,
} from '../../actions/common/preselectTraveler';

const initialState = { travelers: [], decision: 'self' };
function preselectTravelers(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PRESELECT_TRAVELERS:
      return { ...state, travelers: action.preselectTravelers };
    case UPDATE_PRESELECT_DECISION:
      return { ...state, decision: action.decision };
    default:
      return state;
  }
}

export default preselectTravelers;
