export default function searchURL(type, id) {
  switch (type) {
    case 'hotel':
      return `/hotels/searches/${id}`;
    case 'flight':
      return `/flights/searches/${id}/1`;
    case 'car':
      return `/cars/searches/${id}`;
    default:
      return null;
  }
}
