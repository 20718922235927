import React from 'react';
import PropTypes from 'prop-types';
import Map from '@wtag/rcl-map';

const OrderMap = ({ flights, cars, hotels, events, transfers, cruises }) => {
  const mapCenter = { latitude: 40.73528, longitude: 34.47389 }; // geographical centre of all land surfaces on Earth: İskilip, Turkey

  const hotelsCoordinates = hotels.map(hotel => ({
    checkIn: hotel.checkIn,
    checkOut: hotel.checkOut,
    title: hotel.name,
    latitude: hotel.coordinates.latitude,
    longitude: hotel.coordinates.longitude,
  }));

  const carsCoordinates = cars.map(car => ({
    pickUp: {
      date: car.pickUp.localtime,
      latitude: car.pickUp.latitude,
      longitude: car.pickUp.longitude,
      title: car.pickUp.location,
    },
    dropOff: {
      date: car.dropOff.localtime,
      latitude: car.dropOff.latitude,
      longitude: car.dropOff.longitude,
      title: car.dropOff.location,
    },
  }));

  const cruisesCoordinates = cruises.map(cruise => ({
    onBoard: {
      title: cruise.boarding.location,
      latitude: cruise.boarding.latitude,
      longitude: cruise.boarding.longitude,
    },
    offBoard: {
      title: cruise.offBoarding.location,
      latitude: cruise.offBoarding.latitude,
      longitude: cruise.offBoarding.longitude,
    },
  }));

  const eventsCoordinates = events.map(event => ({
    title: event.name,
    latitude: event.coordinates.longitude,
    longitude: event.coordinates.longitude,
  }));

  const transfersCoordinates = transfers.map(transfer => ({
    pickUp: {
      latitude: transfer.pickUp.latitude,
      longitude: transfer.pickUp.longitude,
      title: transfer.pickUp.location,
    },
    dropOff: {
      latitude: transfer.dropOff.latitude,
      longitude: transfer.dropOff.longitude,
      title: transfer.dropOff.location,
    },
  }));

  const flightsCoordinates = flights.map(flight => ({
    start: {
      latitude: flight.originCoordinates.latitude,
      longitude: flight.originCoordinates.longitude,
      title: flight.originCoordinates.originName,
    },
    stop: {
      latitude: flight.destinationCoordinates.latitude,
      longitude: flight.destinationCoordinates.longitude,
      title: flight.destinationCoordinates.destinationName,
    },
  }));

  return (
    <Map
      uniqueKey="multiple-items-map"
      mapCenter={mapCenter}
      cruises={cruisesCoordinates.length > 0 ? cruisesCoordinates : null}
      cars={carsCoordinates.length > 0 ? carsCoordinates : null}
      hotels={hotelsCoordinates.length > 0 ? hotelsCoordinates : null}
      events={eventsCoordinates.length > 0 ? eventsCoordinates : null}
      transfers={transfersCoordinates.length > 0 ? transfersCoordinates : null}
      flights={flightsCoordinates.length > 0 ? flightsCoordinates : null}
      zoomLevel={1}
    />
  );
};

OrderMap.defaultProps = {
  flights: [],
  cars: [],
  hotels: [],
  events: [],
  transfers: [],
  cruises: [],
};

OrderMap.propTypes = {
  hotels: PropTypes.arrayOf(PropTypes.shape()),
  cars: PropTypes.arrayOf(PropTypes.shape()),
  flights: PropTypes.arrayOf(PropTypes.shape()),
  cruises: PropTypes.arrayOf(PropTypes.shape()),
  events: PropTypes.arrayOf(PropTypes.shape()),
  transfers: PropTypes.arrayOf(PropTypes.shape()),
};

export default OrderMap;
