import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  aisle: PropTypes.bool.isRequired,
  chargeable: PropTypes.bool.isRequired,
  reclineRestricted: PropTypes.bool.isRequired,
  absent: PropTypes.bool.isRequired,
  occupied: PropTypes.oneOf([true, false]),
  infantRestricted: PropTypes.bool.isRequired,
  childRestricted: PropTypes.bool.isRequired,
  window: PropTypes.bool.isRequired,
  pricing: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string.isRequired,
  }),
});
