import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import {
  fetchAirlineInformations,
  fetchFlightSearchResults,
  filterFlightSearch,
  resetFlightSearchFilters,
  resetFlightSearchFiltersByKey,
  resetSelectGroup,
  selectFlightGroup,
  deselectGroup,
  deselectAllGroups,
  fetchAirportDetails,
  fetchAircraftType,
} from '../../actions/flight';
import fetchOnUpdate from '../../lib/decorators/fetchOnUpdate';
import ResultsComponent from './ResultsComponent';
import memoizeLocationQuery from '../../helpers/memoizeLocationQuery';

const mapStateToProps = (state, ownProps) => {
  const locationQuery = memoizeLocationQuery(ownProps.location.search);
  const { searchId, tripId } = ownProps.match.params;
  const resultGroups =
    state.flights.searchResultGroupsBySearchId[searchId] &&
    state.flights.searchResultGroupsBySearchId[searchId][tripId];

  const hasResultsToRender =
    !!resultGroups && (resultGroups.matches.length > 0 || resultGroups.others.length > 0);
  const searchParams = state.flights.searchParamsBySearchId[searchId];
  const searchedStats = state.flights.searchStatsBySearchId[searchId];
  const searchedParams = state.flights.searchParamsBySearchId[searchId];
  const lastSearchedParams = state.flights.searchParamsBySearchId.lastSearch;
  const currentSearchParams = searchParams || lastSearchedParams;
  const currentFilterParams = state.flights.searchFilterParamsBySearchId[searchId];
  const numberOfAvailableTrips =
    searchedStats && searchedStats.tripSpecific && searchedStats.tripSpecific.length;
  const numberOfSearchedTrips =
    searchedParams && searchedParams.trips && searchedParams.trips.length;
  const hasResultsForAllTrips = numberOfAvailableTrips === numberOfSearchedTrips;

  const props = {
    airlineInformations: state.flights.airlineInformations,
    aircraftInformation: state.flights.aircraftTypes,
    airports: state.common.airports,
    searchInProgress: state.flights.searchInProgress,
    searchYieldedResults:
      state.flights.totalResultsBySearchId[searchId] > 0 && hasResultsForAllTrips,
    hasResultsToRender,
    people: state.common.people,
    searchCompleted:
      !locationQuery.cached && !!state.flights.searchResultGroupsBySearchId[searchId],
    tripLoaded: !!resultGroups,
    resultGroups,
    stats: searchedStats,
    filterParams: currentFilterParams,
    lastSearchedParams,
    numberOfAvailableTrips,
  };

  if (currentSearchParams && Object.keys(currentSearchParams).length > 0) {
    const { adults, children, infants, totalTravelerCount } = currentSearchParams;
    props.people = { adults, children, infants };
    props.totalTravelerCount = totalTravelerCount;
  } else {
    props.people = { adults: 1, children: 0, infants: 0 };
    props.totalTravelerCount = 1;
  }

  if (searchParams && searchParams.trips) {
    props.trips = searchParams.trips;
  } else {
    props.trips = [];
  }

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const locationQuery = memoizeLocationQuery(ownProps.location.search);

  return {
    selectGroup: group => dispatch(selectFlightGroup(ownProps.match.params.searchId, group)),
    resetSelectGroup: trip =>
      dispatch(
        resetSelectGroup(
          ownProps.match.params.searchId,
          trip,
          locationQuery.deal,
          locationQuery.dealGroup,
          ownProps.callbackParams,
          ownProps.exchange.bookingIdentifier,
        ),
      ),
    deselectGroup: group => dispatch(deselectGroup(group)),
    deselectAllGroups: () => dispatch(deselectAllGroups()),
    fetchAirlineInformations: code => dispatch(fetchAirlineInformations(code)),
    fetchAircraftType: code => dispatch(fetchAircraftType(code)),
    fetchAirportDetails: code => dispatch(fetchAirportDetails(code)),
    filterFlightSearch: (filter, value, key, isAllChecked) => {
      const { searchId, tripId } = ownProps.match.params;
      dispatch(filterFlightSearch(filter, value, key, searchId, tripId, isAllChecked));
      dispatch(fetchFlightSearchResults({ searchId, tripId }));
    },
    resetFilters: stats => {
      dispatch(
        resetFlightSearchFilters(
          ownProps.match.params.searchId,
          ownProps.match.params.tripId,
          stats,
        ),
      );
    },
    resetFiltersByKey: stats => {
      dispatch(
        resetFlightSearchFiltersByKey(
          ownProps.match.params.searchId,
          ownProps.match.params.tripId,
          stats,
        ),
      );
    },
    fetchResults: (searchId, tripId) => dispatch(fetchFlightSearchResults({ searchId, tripId })),
  };
};

const ResultsWithFetchOnUpdate = fetchOnUpdate(['searchId', 'tripId'], (params, props) => {
  if (params.searchId) {
    props.fetchResults(params.searchId, params.tripId || 1);
  }
})(ResultsComponent);

const ConnectedResults = connect(mapStateToProps, mapDispatchToProps)(ResultsWithFetchOnUpdate);

const ResultsWithContext = (props, context) => <ConnectedResults {...props} {...context} />;

ResultsWithContext.contextTypes = {
  currency: PropTypes.string.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  exchange: PropTypes.shape().isRequired,
};

export default ResultsWithContext;
