const mapTravelArranger = travelArrangerPermissionLevel => {
  let enabled;
  let customerInformationVisible;
  let customerInformationEditable;
  let allowContinueToOrder;

  switch (travelArrangerPermissionLevel) {
    case 'booking':
      enabled = true;
      customerInformationVisible = false;
      customerInformationEditable = false;
      allowContinueToOrder = false;
      break;

    case 'read':
      enabled = true;
      customerInformationVisible = true;
      customerInformationEditable = false;
      allowContinueToOrder = true;
      break;

    case 'write':
      enabled = true;
      customerInformationVisible = true;
      customerInformationEditable = true;
      allowContinueToOrder = true;
      break;

    case 'admin':
      enabled = true;
      customerInformationVisible = true;
      customerInformationEditable = true;
      allowContinueToOrder = true;
      break;

    // someone not in travel arranger mode (Agent or customer directly)
    default:
      enabled = false;
      customerInformationVisible = true;
      customerInformationEditable = true;
      allowContinueToOrder = true;
      break;
  }

  return {
    enabled,
    customerInformationVisible,
    customerInformationEditable,
    allowContinueToOrder,
  };
};

export default mapTravelArranger;
