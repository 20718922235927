import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import maskedNumberNormalizer from '../helpers/maskedNumberNormalizer';
import SurchargeAmount from '../SurchargeAmount';
import './styles.scss';

const CreditCardDetails = props => {
  const { card, charges, currency } = props;
  const { fullname, maskedNumber } = card;

  return (
    <Fragment>
      <div className="credit-card-details--name">{fullname}</div>
      <div className="credit-card-details--number">{maskedNumberNormalizer(maskedNumber)}</div>
      {charges.length > 0 && <SurchargeAmount charges={charges} currency={currency} />}
    </Fragment>
  );
};

CreditCardDetails.propTypes = {
  currency: PropTypes.string.isRequired,
  card: PropTypes.shape({
    fullname: PropTypes.string,
    maskedNumber: PropTypes.string,
  }).isRequired,
  charges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]))
    .isRequired,
};

export default CreditCardDetails;
