import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime, EmphasisTag, I18nText, Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import StackedItems from '@wtag/rcl-stacked-items';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import { Table, TableBody, TableData, TableHeader, TableHead, TableRow } from '@wtag/rcl-table';
import Amount from 'sharedWebpack/Amount';
import EmptyTableContent from 'sharedWebpack/EmptyTableContent';
import TaskPriorityCard from 'sharedWebpack/TaskPriorityCard';
import TablePlaceHolder from '../../../shared/Placeholder/TablePlaceholder';
import StateEmphasisTag from '../StateEmphasisTag';

const OrderRow = ({ order, displayColumns, actionsRenderer, setOrderId }) => {
  const {
    id,
    person,
    travellers,
    tasks,
    urls,
    title,
    destination,
    products,
    currentState,
    bookingDate,
    startDate,
    endDate,
    currency,
    total,
  } = order;
  const productIcon = product => {
    switch (product) {
      case 'flight':
        return 'aeroplane';
      case 'hotel':
        return 'hotelRoom';
      case 'car':
        return 'car';
      default:
        return 'bookingClass';
    }
  };
  const [isProductPopoverVisible, setIsProductPopoverVisible] = useState(false);
  const [isTravellerPopoverVisible, setIsTravellerPopoverVisible] = useState(false);

  const travellersStackedItem = () => {
    const uniqueTravellers = travellers.filter(traveller => traveller.id !== person.id);

    const travelerList = [
      {
        id: person.id,
        firstName: person.firstName,
        lastName: I18n.t('admin.components.orders.list.owner_last_name', {
          last_name: person.lastName,
        }),
        avatarUrl: person.avatarUrl,
        url: person.url,
      },
      ...uniqueTravellers,
    ];

    return travelerList.map(traveler => (
      <Avatar
        key={traveler.id}
        firstName={traveler.firstName}
        lastName={traveler.lastName}
        src={traveler.avatarUrl}
        href={traveler.url}
        openNewTab={true}
      />
    ));
  };
  const stackedTravellers = displayColumns.travellers && travellersStackedItem();

  const dateWithoutIcon = date => (
    <div className="orders-table__start-date">
      <DateTime locales={I18n.locale.split('-')[0]} dateTime={date} format="short" />
    </div>
  );

  const toolTipRender = () => (
    <EmphasisTag type="accent" text={tasks && tasks.totalCount} size="small" radius="oval" />
  );

  const togglePopoverVisibility = () => {
    setIsTravellerPopoverVisible(isVisible => !isVisible);
  };

  let actions = null;
  if (actionsRenderer) {
    actions = actionsRenderer(order);
  } else if (displayColumns.actions) {
    actions = (
      <a href={urls.view}>
        <IconButton
          version="v2"
          color="tertiary"
          icon={<Icon name="show" />}
          standalone={true}
          size="small"
          label={I18n.t('admin.components.orders.table.view')}
        />
      </a>
    );
  }

  return (
    <TableRow key={id} className="order-row">
      {displayColumns.id && (
        <TableData smallScreenConfig={{ showInTop: 'left' }}>
          <EmphasisTag number={id} size="small" radius="oval" />
        </TableData>
      )}
      {displayColumns.title && (
        <TableData smallScreenConfig={{ isFullWidth: true }}>
          <div className="orders-table__title">
            {urls ? (
              <Link type="anchor" modifier="default" size="tiny" href={urls.view}>
                {title}
              </Link>
            ) : (
              title
            )}
          </div>
        </TableData>
      )}
      {displayColumns.tasks &&
        (tasks.totalCount > 0 ? (
          <TableData>
            <TaskPriorityCard task={order && tasks} objectToRenderToolTip={toolTipRender()} />
          </TableData>
        ) : (
          <TableData>
            <EmphasisTag type="neutral" text={tasks.totalCount} size="small" radius="oval" />
          </TableData>
        ))}
      {displayColumns.destination && (
        <TableData>
          <div className="orders-table__destination">
            <EmphasisTag text={destination} size="small" radius="oval" align="center" />
          </div>
        </TableData>
      )}
      {displayColumns.travellers && (
        <TableData align="center">
          {stackedTravellers.length > 0 && (
            <Link type="anchor" modifier="default" size="tiny">
              <StackedItems
                popoverClassName="order-row__popover"
                isPopoverVisible={isTravellerPopoverVisible}
                onClick={togglePopoverVisibility}
                onOutsideClick={() => setIsTravellerPopoverVisible(false)}
              >
                {stackedTravellers}
              </StackedItems>
            </Link>
          )}
        </TableData>
      )}
      {displayColumns.products && (
        <TableData
          align="center"
          unavailableText={I18n.t('admin.components.orders.table.no_products')}
        >
          {products.length > 0 && (
            <StackedItems
              className="orders-table__stacked-items"
              itemSize="tiny"
              isPopoverVisible={isProductPopoverVisible}
              onClick={() =>
                setIsProductPopoverVisible(prevProductPopoverState => !prevProductPopoverState)
              }
              onOutsideClick={() => setIsProductPopoverVisible(false)}
            >
              {products.map(product => (
                <Icon
                  key={product}
                  className="orders-table__stacked-icon"
                  name={productIcon(product)}
                  color="tertiary"
                  iconLabel={product}
                  showBGColor={true}
                  showLabel={true}
                />
              ))}
            </StackedItems>
          )}
        </TableData>
      )}
      {displayColumns.state && (
        <TableData>
          <StateEmphasisTag currentState={currentState} />
        </TableData>
      )}
      {displayColumns.bookingDate && (
        <TableData>{bookingDate && dateWithoutIcon(bookingDate)}</TableData>
      )}
      {displayColumns.startDate && (
        <TableData unavailableText={I18n.t('admin.components.orders.table.no_start_date')}>
          {startDate && dateWithoutIcon(startDate)}
        </TableData>
      )}
      {displayColumns.endDate && (
        <TableData unavailableText={I18n.t('admin.components.orders.table.no_end_date')}>
          {endDate && dateWithoutIcon(endDate)}
        </TableData>
      )}
      {displayColumns.total && (
        <TableData>
          <Amount currency={currency} value={total} />
        </TableData>
      )}
      {displayColumns.actions && (
        <TableData align="center" smallScreenConfig={{ showInTop: 'right' }}>
          {actions}
        </TableData>
      )}
      {displayColumns.selectAction && (
        <TableData align="center" smallScreenConfig={{ showInTop: 'right' }}>
          <Link size="small" type="button" modifier="tertiary" onClick={() => setOrderId(id)}>
            {I18n.t('components.ibe.check_out_progress.buttons.select')}
          </Link>
        </TableData>
      )}
    </TableRow>
  );
};

OrderRow.defaultProps = {
  actionsRenderer: null,
  setOrderId: null,
};

OrderRow.propTypes = {
  displayColumns: PropTypes.shape({
    id: PropTypes.bool,
    title: PropTypes.bool,
    tasks: PropTypes.bool,
    destination: PropTypes.bool,
    travellers: PropTypes.bool,
    products: PropTypes.bool,
    state: PropTypes.bool,
    bookingDate: PropTypes.bool,
    startDate: PropTypes.bool,
    endDate: PropTypes.bool,
    total: PropTypes.bool,
    actions: PropTypes.bool,
    selectAction: PropTypes.bool,
  }).isRequired,
  order: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    tasks: PropTypes.shape({
      totalCount: PropTypes.number,
      high: PropTypes.number,
      medium: PropTypes.number,
      low: PropTypes.number,
      badgeType: PropTypes.string,
    }),
    destination: PropTypes.string,
    person: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
      url: PropTypes.string.isRequired,
    }),
    products: PropTypes.arrayOf(PropTypes.string),
    currentState: PropTypes.string,
    bookingDate: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    currency: PropTypes.string,
    total: PropTypes.string,
    travellers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        avatarUrl: PropTypes.string,
      }),
    ),
    urls: PropTypes.shape({
      view: PropTypes.string.isRequired,
    }),
  }).isRequired,
  actionsRenderer: PropTypes.func,
  setOrderId: PropTypes.func,
};

const OrdersTable = ({ orders, displayColumns, actionsRenderer, isLoading, url, setOrderId }) => (
  <Fragment>
    <Table fullWidTableHeader={true}>
      <TableHead>
        <TableRow>
          {displayColumns.id && (
            <TableHeader>{I18n.t('admin.components.orders.table.id')}</TableHeader>
          )}
          {displayColumns.title && (
            <TableHeader>
              <div className="order-row__title">
                {I18n.t('admin.components.orders.table.title')}
              </div>
            </TableHeader>
          )}
          {displayColumns.tasks && (
            <TableHeader>{I18n.t('admin.components.orders.table.tasks.tasks')}</TableHeader>
          )}
          {displayColumns.destination && (
            <TableHeader>{I18n.t('admin.components.orders.table.destination')}</TableHeader>
          )}
          {displayColumns.travellers && (
            <TableHeader align="center">
              {I18n.t('admin.components.orders.table.travellers')}
            </TableHeader>
          )}
          {displayColumns.products && (
            <TableHeader align="center">
              {I18n.t('admin.components.orders.table.products')}
            </TableHeader>
          )}
          {displayColumns.state && (
            <TableHeader>{I18n.t('admin.components.orders.table.state')}</TableHeader>
          )}
          {displayColumns.bookingDate && (
            <TableHeader>{I18n.t('admin.components.orders.table.booked_on_header')}</TableHeader>
          )}
          {displayColumns.startDate && (
            <TableHeader>{I18n.t('admin.components.orders.table.start_date')}</TableHeader>
          )}
          {displayColumns.endDate && (
            <TableHeader>{I18n.t('admin.components.orders.table.end_date')}</TableHeader>
          )}
          {displayColumns.total && (
            <TableHeader>{I18n.t('admin.components.orders.table.total')}</TableHeader>
          )}
          {displayColumns.lastUpdate && (
            <TableHeader>{I18n.t('admin.components.orders.table.last_update')}</TableHeader>
          )}
          {displayColumns.actions && (
            <TableHeader align="center">
              <I18nText id="shared.action" returnStringOnly={true} />
            </TableHeader>
          )}
          {displayColumns.selectAction && (
            <TableHeader align="center">
              <I18nText id="shared.action" returnStringOnly={true} />
            </TableHeader>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <TablePlaceHolder
            numberOfColumn={Object.values(displayColumns).filter(data => data).length}
            numberOfRows={10}
            wiTableHeaderoutRclTable={true}
          />
        ) : (
          orders.map(order => (
            <OrderRow
              key={order.id}
              order={order}
              displayColumns={displayColumns}
              actionsRenderer={actionsRenderer}
              setOrderId={setOrderId}
            />
          ))
        )}
      </TableBody>
    </Table>
    {!isLoading && !orders.length && <EmptyTableContent createNewUrl={url} />}
  </Fragment>
);

OrdersTable.defaultProps = {
  displayColumns: {
    id: true,
    title: true,
    tasks: true,
    destination: true,
    travellers: true,
    products: true,
    state: true,
    total: true,
    lastUpdate: false,
    bookingDate: false,
    startDate: true,
    endDate: true,
    actions: true,
    selectAction: false,
  },
  actionsRenderer: null,
  setOrderId: null,
};

OrdersTable.propTypes = {
  displayColumns: PropTypes.shape({
    id: PropTypes.bool,
    title: PropTypes.bool,
    tasks: PropTypes.bool,
    destination: PropTypes.bool,
    travellers: PropTypes.bool,
    products: PropTypes.bool,
    state: PropTypes.bool,
    total: PropTypes.bool,
    lastUpdate: PropTypes.bool,
    startDate: PropTypes.bool,
    endDate: PropTypes.bool,
    bookingDate: PropTypes.bool,
    actions: PropTypes.bool,
    selectAction: PropTypes.bool,
  }),
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  actionsRenderer: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  setOrderId: PropTypes.func,
};

export default OrdersTable;
