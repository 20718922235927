import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { EmphasisTag } from '@wtag/react-comp-lib';
import AncillaryPopover from '../AncillaryPopover';
import useAncillaryItemWrapper from './useAncillaryItemWrapper';
import MAX_ANCILLARIES_TO_SHOW from '../helpers/constants';
import getAncillaryIconName from '../helpers/getAncillaryIconName';

const AncillaryItemWrapper = ({ ancillaries, isItemAvailable, type }) => {
  const { isPopoverOpen, onPopoverClose, onPopoverOpen, iconLabel } = useAncillaryItemWrapper({
    type,
    isItemAvailable,
  });

  const ancillariesLength = ancillaries.length;

  if (ancillariesLength === 0) {
    return (
      <Icon
        name={getAncillaryIconName(type)}
        color={isItemAvailable ? 'tertiary' : 'default'}
        size="tiny"
        showLabel={true}
        iconLabel={iconLabel}
      />
    );
  }

  const ancillariesWithEmphasisTag = ancillaries.map(({ status, title }) => (
    <EmphasisTag
      key={title}
      text={
        <Icon
          name={getAncillaryIconName(type)}
          size="tiny"
          color={status}
          showLabel={true}
          iconLabel={title}
        />
      }
      type={status}
      size="tiny"
    />
  ));

  const visibleAncillaries = ancillariesWithEmphasisTag.slice(0, MAX_ANCILLARIES_TO_SHOW);

  return (
    <div className="d-flex flex-column-gap-8 flight-services__seats">
      {visibleAncillaries}

      {ancillariesLength > MAX_ANCILLARIES_TO_SHOW && (
        <AncillaryPopover
          type={type}
          content={ancillaries}
          isVisible={isPopoverOpen}
          onPopoverClose={onPopoverClose}
          onClick={onPopoverOpen}
          onKeyDown={onPopoverClose}
        />
      )}
    </div>
  );
};

AncillaryItemWrapper.propTypes = {
  ancillaries: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  isItemAvailable: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default AncillaryItemWrapper;
