/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import withJourneyElement from '../../../lib/decorators/withJourneyElement';
import Row from '../Row';

const countRooms = rooms =>
  rooms.reduce((roomA, roomB) => ({
    x: roomA.quantity + roomB.quantity,
  })).quantity;

function Hotel(props) {
  const { journeyElement, item } = props;

  if (!journeyElement) return null;

  return (
    <div className="cart-summary__accordion-sub-item">
      <Row
        className="cart-summary__accordion-item-body-row--bold"
        name={journeyElement.name}
        price={item.grossTotal}
        currency={item.currency}
      />
      <Row
        name={`${I18n.t('components.ibe.summary.hotel.rooms', {
          count: countRooms(journeyElement.rooms),
        })} ${I18n.t('components.ibe.summary.for')} ${I18n.t('components.ibe.summary.hotel.days', {
          count: journeyElement.durationInDays,
        })}`}
        journeyElementType={item.journeyElementType}
        quantity={countRooms(journeyElement.rooms)}
        duration={journeyElement.durationInDays}
        rooms={journeyElement.rooms}
        price={item.grossAmount}
        currency={item.currency}
        placeHolder={<Icon name="hotelRoom" />}
        conversionInfo={item.conversionInfo}
      />
      <Row
        name={I18n.t('components.ibe.summary.taxes_and_charges')}
        price={item.grossTaxes}
        currency={item.currency}
        hasChildRow={false}
      />
    </div>
  );
}

Hotel.propTypes = {
  item: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    available: PropTypes.bool,
  }).isRequired,
  journeyElement: PropTypes.shape({
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        segments: PropTypes.arrayOf(
          PropTypes.shape({
            aircraftType: PropTypes.string.isRequired,
            destinationCode: PropTypes.string.isRequired,
            operatingCarrierCode: PropTypes.string.isRequired,
            operatingFlightNumber: PropTypes.string.isRequired,
            originCode: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
export default withJourneyElement(Hotel);
