import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag, Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';
import ProductIcon from 'sharedWebpack/ProductIcon';
import IconButton from '@wtag/rcl-icon-button';

const TravellerCard = ({ traveller, layman }) => (
  <Card version="v2" className="order-travellers-tab__card">
    <div className="col-12 col-bleed-y">
      <div className="grid grid-gap-20">
        <div className="col-md-9 col-grid col-bleed direction-row align-center order-travellers-tab__traveller-information wrap">
          <span className="order-travellers-tab__traveller-information-avatar">
            <Link href={traveller.url} modifier="default" size="small" type="anchor">
              <Avatar
                firstName={traveller.firstName}
                middleName={traveller.middleName || ''}
                lastName={traveller.lastName}
                src={traveller.avatarUrl}
                size="tiny"
                showName={true}
              />
            </Link>
          </span>
          <span className="order-travellers-tab__traveller-information-email">
            {traveller.email}
          </span>
          <span className="order-travellers-tab__traveller-information-phone-number">
            {traveller.phoneNumber}
          </span>
        </div>
        <div className="col-md-3 col-grid col-bleed-x direction-row justify-end align-center order-travellers-tab__traveller-information-order-item">
          {traveller.orderItems && traveller.orderItems.length > 0 && (
            <span className="order-travellers-tab__traveller-information-item-number">
              {I18n.t('admin.components.orders.travellers_tab.type_information.items', {
                count: traveller.orderItems.length,
              })}
            </span>
          )}
          <span className="order-travellers-tab__traveller-information-type">
            <EmphasisTag text={traveller.type} type="warning" size="tiny" />
          </span>
          {!layman && (
            <React.Fragment>
              <span className="order-travellers-tab__traveller-information-edit">
                <IconButton
                  version="v2"
                  color="tertiary"
                  icon={<Icon name="edit" size="normal" />}
                  standalone={true}
                  size="tiny"
                  disabled={true}
                />
              </span>
              <span className="order-travellers-tab__traveller-information-edit">
                <IconButton
                  version="v2"
                  color="tertiary"
                  icon={<Icon name="chat" size="normal" />}
                  standalone={true}
                  size="tiny"
                  disabled={true}
                />
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
    <div className="col-12 col-bleed-x">
      <div className="grid grid-gap-20">
        <div className="col-md-4" style={{ paddingLeft: '0px' }}>
          {traveller &&
            traveller.orderItems &&
            traveller.orderItems.length > 0 &&
            traveller.orderItems.map(item => (
              <div className="order-travellers-tab__order-item col-grid col-bleed-y direction-row align-start">
                <ProductIcon
                  productType={item.type}
                  {...{
                    showBGColor: true,
                    size: 'tiny',
                    className: 'order-travellers-tab__order-item-icon',
                  }}
                />
                <div className="order-travellers-tab__order-item-content">
                  <div className="order-travellers-tab__order-item-title">{item.title}</div>
                  <div className="order-travellers-tab__order-item-booking">
                    {item.booking &&
                      `${I18n.t(item.booking.channel, { scope: 'channels' })} ${
                        item.booking.identifier
                      }`}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="col-md-3 col-grid" style={{ paddingLeft: '0px' }}>
          <div className="col-grid col-bleed-y">
            <div className="order-travellers-tab__detail-text">
              {traveller.age
                ? traveller.age
                : I18n.t('admin.components.orders.travellers_tab.information.not_available')}
            </div>
            <div className="order-travellers-tab__detail-header">
              {I18n.t('admin.components.orders.travellers_tab.information.age')}
            </div>
          </div>
        </div>
        <div className="col-md-4 col-grid" style={{ paddingLeft: '0px' }}>
          <div className="col-grid col-bleed-y">
            <div className="order-travellers-tab__detail-text">
              {traveller.primaryDocument
                ? I18n.t(traveller.primaryDocument.documentType, {
                    scope: 'simple_form.options.person.identification_documents.document_type',
                  })
                : I18n.t('admin.components.orders.travellers_tab.information.not_available')}
            </div>
            <div className="order-travellers-tab__detail-header">
              {I18n.t('admin.components.orders.travellers_tab.information.document')}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

TravellerCard.defaultProps = {
  layman: false,
};

TravellerCard.propTypes = {
  traveller: PropTypes.arrayOf({
    age: PropTypes.number,
    avatarUrl: PropTypes.string,
    birthday: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    expiredVisaNumbersCount: PropTypes.number,
    orderItems: PropTypes.arrayOf(
      PropTypes.shape({
        booking: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    phoneNumber: PropTypes.string,
    primaryDocument: PropTypes.shape({
      documentType: PropTypes.string,
    }),
  }).isRequired,
  layman: PropTypes.bool,
};

export default TravellerCard;
