import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Alert from 'sharedWebpack/Alert';
import Icon from '@wtag/rcl-icon';
import Tooltip from '@wtag/rcl-tooltip';
import './style.scss';

/*
 
key properties meaning under flightSeatAlertStatus map,

 'insufficientFlightSeatCount' means available seats = travellers
 'noFlightSeatCount' means available seats status is not present
 'lowOnFlightSeatCount' means available seats = travellers + 1
 'sufficientFlightSeatCount' means available seats > travellers + 1

*/

const flightSeatAlertStatus = {
  insufficientFlightSeatCount: {
    alertType: 'danger-light',
    alertIconType: 'danger',
  },
  noFlightSeatCount: {
    alertType: 'neutral',
    alertIconType: 'neutral',
  },
  lowOnFlightSeatCount: {
    alertType: 'warning-light',
    alertIconType: 'warning',
  },
  sufficientFlightSeatCount: {
    alertType: 'success-light',
    alertIconType: 'success',
  },
};

const FlightSeatCountTag = ({ className, seatCount, travellerCount }) => {
  let alertText;
  let flightSeatStatus;
  let tooltipContent;
  let hasNoFlightSeatCount = false;
  let hasSufficientFlightSeats = false;

  if (seatCount !== null) {
    if (seatCount === travellerCount || seatCount < travellerCount) {
      flightSeatStatus = 'insufficientFlightSeatCount';
      tooltipContent = I18n.t('components.ibe.seat_status.insufficient_seat_details');
    } else if (seatCount === travellerCount + 1) {
      flightSeatStatus = 'lowOnFlightSeatCount';
      tooltipContent = I18n.t('components.ibe.seat_status.low_seat_details');
    } else if (seatCount > travellerCount + 1) {
      flightSeatStatus = 'sufficientFlightSeatCount';
      hasSufficientFlightSeats = true;
    }

    if (seatCount > 0 && hasSufficientFlightSeats) {
      alertText = I18n.t('components.ibe.seat_status.sufficient_seats_left', {
        count: seatCount,
      });
    } else if (seatCount > 0) {
      alertText = I18n.t('components.ibe.seat_status.seats_left', { count: seatCount });
    }
  } else {
    alertText = I18n.t('components.ibe.seat_status.no_seat_information');
    flightSeatStatus = 'noFlightSeatCount';
    hasNoFlightSeatCount = true;
    tooltipContent = I18n.t('components.ibe.seat_status.no_seat_details');
  }

  const flightSeatAlertStatusType = flightSeatAlertStatus[flightSeatStatus];

  if (flightSeatAlertStatusType) {
    return (
      <div
        className={ClassNames(className, 'flight__seats-status', {
          'flight__seats-status--no-information': hasNoFlightSeatCount,
        })}
      >
        <Alert
          isIconVisible={false}
          hideClose={true}
          isVisible={true}
          type={flightSeatAlertStatusType.alertType}
        >
            <span
              className={ClassNames(
                'flight__seats-status__heading',
                `flight__seats-status__heading--${flightSeatAlertStatusType.alertIconType}`,
                { 'flight__seats-status__heading--no-margin': !tooltipContent },
              )}
            >
              {alertText}
            </span>
            {tooltipContent && (
              <Tooltip
                className="flight__seats-status__tooltip"
                position="bottom"
                showArrow={false}
                content={tooltipContent}
                type={flightSeatAlertStatusType.alertType}
              >
                <Icon name="info" size="tiny" color={flightSeatAlertStatusType.alertIconType} />
              </Tooltip>
            )}
        </Alert>
      </div>
    );
  }

  return null;
};

FlightSeatCountTag.defaultProps = {
  className: null,
};

FlightSeatCountTag.propTypes = {
  className: PropTypes.string,
  seatCount: PropTypes.number.isRequired,
  travellerCount: PropTypes.number.isRequired,
};

export default FlightSeatCountTag;
