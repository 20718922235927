import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from 'material-ui/CircularProgress';
import NavigationCheck from 'material-ui/svg-icons/navigation/check';
import Card from '@wtag/rcl-card';

const Progress = props => {
  let spinner = <CircularProgress />;
  if (props.completed) {
    spinner = <NavigationCheck style={{ width: '40px', height: '40px' }} />;
  }

  const content = (
    <Fragment>
      <div className="col-12 progress__content">
        <div className="progress__spinner">{spinner}</div>
        <div className="progress__text">
          <div>
            <strong>{props.title}</strong>
          </div>
          <div>{props.children}</div>
        </div>
      </div>
    </Fragment>
  );

  if (props.withCard) {
    return <Card className="progress" version="v2" size="full">{content}</Card>;
  }

  return <div className="progress">{content}</div>;
};

Progress.defaultProps = {
  completed: false,
  withCard: true,
};

Progress.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  completed: PropTypes.bool,
  withCard: PropTypes.bool,
};

export default Progress;
