import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import addressIdGenerator from '../../../IBE/lib/addressIdGenerator';

export const LOCATION_FETCHED = 'LOCATION_FETCHED';
export function locationFetched(id, data) {
  return {
    type: LOCATION_FETCHED,
    id,
    coordinates: data,
  };
}

const fetchedLocations = {};
export const FETCHING_LOCATION = 'FETCHING_LOCATION';
export function fetchLocation(address) {
  const id = addressIdGenerator(address);

  return dispatch => {
    if (fetchedLocations[id]) {
      return null;
    }
    fetchedLocations[id] = true;

    dispatch({
      type: FETCHING_LOCATION,
      id,
    });

    return httpClient.put(routes.api.fetchLocation({ locationId: id }), address).then(({ data }) =>
      dispatch(locationFetched(id, data))
    );
  };
}
