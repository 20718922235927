import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';

const StaticSearchName = () => (
  <span className="search-name">
    <I18nText id="ibe.search_name.static" returnStringOnly={true} />
  </span>
);

export default StaticSearchName;
