import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import debounce from 'throttle-debounce/debounce';
import Icon from '@wtag/rcl-icon';
import SelectBox from '@wtag/rcl-select-box';
import fetchDataSource from './helper/FetchDataSource';
import { searchAirports } from '../../actions/flight';

const AirportAutoComplete = props => {
  const { className, touched, error, title, currentAirport, iconName } = props;

  const onBlur = event => {
    const value = event.target.value;
    const suggestions = props.allSuggestions;
    const selectedSuggestion = Object.keys(suggestions).find(
      code => suggestions[code].name === value || code === value.toUpperCase(),
    );
    if (value === '') {
      props.onChange(props.value);
      props.onBlur(props.value);
    } else if (selectedSuggestion || value.length === 3) {
      if (props.whitelistedAirports !== undefined && props.whitelistedAirports.length > 0) {
        if (props.whitelistedAirports.includes(value.toUpperCase())) {
          props.onChange(selectedSuggestion || value.toUpperCase());
          props.onBlur(selectedSuggestion || value.toUpperCase());
        } else {
          props.onChange('');
          props.onBlur('');
        }
      } else {
        props.onChange(selectedSuggestion || value.toUpperCase());
        props.onBlur(selectedSuggestion || value.toUpperCase());
      }
    }
  };

  const onSearchAirports = (query, callback) => {
    if (query && query.length >= 3) {
      props.searchAirports(query, callback, props.whitelistedAirports);
    }
  };

  const debouncedSearchAirports = debounce(400, (query, callback) =>
    onSearchAirports(query.trim(), callback),
  );

  const airportSelected = airport => {
    props.onChange(airport);
  };

  const loadOptions = (inputValue, callback) => {
    debouncedSearchAirports(inputValue, callback);
  };

  const dataSource = fetchDataSource(props.suggestions, props.whitelistedAirports);
  const finalResult = dataSource.filter(item => item);
  const currentlySelectedOption = dataSource.find(item => item.value === props.value);

  useEffect(() => {
    if (props.value.length >= 3) {
      onSearchAirports(props.value);
    }
  }, []);

  useEffect(() => {
    if (props.value.length >= 3) {
      onSearchAirports(props.value);
    }
  }, [props.value]);

  return (
    <SelectBox
      isAsync={true}
      loadOptions={loadOptions}
      className={classNames('airport-auto-complete', className, {
        'airport-auto-complete__select-box': !currentAirport,
      })}
      placeholderText={title}
      defaultOptions={finalResult}
      size="small"
      width="full"
      onChange={selectedOption => airportSelected(selectedOption ? selectedOption.value : '')}
      onBlur={onBlur}
      onFocus={props.onFocus}
      isClearable={true}
      required={true}
      errorMsg={touched && error.length > 0 && error}
      touched={touched}
      value={currentlySelectedOption}
      preIcon={<Icon name={iconName} />}
    />
  );
};

AirportAutoComplete.defaultProps = {
  className: null,
  error: [],
  value: '',
  currentAirport: '',
};

AirportAutoComplete.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  touched: PropTypes.bool.isRequired,
  error: PropTypes.arrayOf(PropTypes.node),
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      placeName: PropTypes.string.isRequired,
      countryName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  allSuggestions: PropTypes.shape().isRequired,
  searchAirports: PropTypes.func.isRequired,
  whitelistedAirports: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  value: PropTypes.string,
  currentAirport: PropTypes.string,
  iconName: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  allSuggestions: state.common && state.common.airports,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  searchAirports: (query, callback, whitelistedAirports) =>
    dispatch(searchAirports(query.toUpperCase(), ownProps.value, callback, whitelistedAirports)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AirportAutoComplete);
