import React, { useContext, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Amount from 'sharedWebpack/Amount';
import reportToRollbar from 'sharedWebpack/reportToRollbar';
import { Header, Text } from '@wtag/rcl-typography';
import Icon from '@wtag/rcl-icon';
import { Spinner } from '@wtag/react-comp-lib';
import { TYPE_ADULT } from '../../../../../../shared/helpers/passengerTypes';
import MatrixContext from '../../contexts/MatrixContext';
import MatrixCard from '../MatrixCard';
import FareBrandCardRow from './FareBrandCardRow';
import MatrixFareRulesContainer from '../../MatrixFareRulesContainer';
import FlightSeatCountTag from '../../../../SearchResult/flight/FlightSeatCountTag';
import FlightPricePerAdult from '../../../../../ItemPrice/Flight/FlightPricePerAdult';
import {
  AvailableForAll,
  AvailableForSome,
  Included,
  IncludedButChargeable,
  NotIncluded,
  NoInfoFound,
  getBaggageFacility,
  getHandLuggageFacility,
  getCancellationFacility,
  getChangeFacility,
  getMealFacility,
  getWifiFacility,
  getNoShowFacility,
  getThreeStateFacility,
  getFacilityWithPoints,
} from '../../matrixHelpers';
import './matrix-fare-brand-card.styles.scss';
import calculateAndAddTaxWithPrice from '../../../../helpers/calculateAndAddTaxWithPrice';

const MatrixFareBrandCard = props => {
  const {
    matrixId,
    name,
    minPrice,
    maxPrice,
    currency,
    hasSelectedFareOption,
    resultIds,
    cheapestOption,
    cheapestOptionCalculatedInClientSide,
    minPriceCalculatedInClientSide,
    onClick,
    bookingClasses,
    facilities,
    fareRulePresent,
    isCheapestFareBrand,
    people,
    fareCalculations,
    grossTaxes,
    maxGrossTaxes,
    minGrossTaxes,
  } = props;
  const { isSelected, channelId, data } = cheapestOption || cheapestOptionCalculatedInClientSide;
  const [shouldShowTheFareBrand, setShouldShowTheFareBrand] = useState(true);
  const {
    relatedTripsHashMap,
    activeTripId,
    laymanMode,
    activeTrip,
    activeTabKey,
    matrixesByType,
    isFetchingMatrices,
    fetchingFlightMatrices,
    totalTravelerCount,
  } = useContext(MatrixContext);
  const isLoading = fetchingFlightMatrices.includes(data.resultId);
  const iconColors = {
    [AvailableForAll]: 'success',
    [AvailableForSome]: 'success',
    [Included]: 'success',
    [IncludedButChargeable]: 'warning',
    [NotIncluded]: 'default',
    [NoInfoFound]: 'light-gray',
  };
  const baggageFacility = Object.prototype.hasOwnProperty.call(facilities, 'baggageInformation')
    ? getBaggageFacility(facilities.baggageInformation)
    : NoInfoFound;
  const handLuggageFacility = Object.prototype.hasOwnProperty.call(facilities, 'handLuggage')
    ? getHandLuggageFacility(facilities.handLuggage)
    : NoInfoFound;
  const cancellationFacility = Object.prototype.hasOwnProperty.call(
    facilities,
    'cancellationPenalty',
  )
    ? getCancellationFacility(facilities.cancellationPenalty)
    : NoInfoFound;
  const changeFacility = Object.prototype.hasOwnProperty.call(facilities, 'changePenalty')
    ? getChangeFacility(facilities.changePenalty)
    : NoInfoFound;
  const mealFacility = Object.prototype.hasOwnProperty.call(facilities, 'meal')
    ? getMealFacility(facilities.meal)
    : NoInfoFound;
  const wifiFacility = Object.prototype.hasOwnProperty.call(facilities, 'wifi')
    ? getWifiFacility(facilities.wifi)
    : NoInfoFound;
  const noShowFacility = Object.prototype.hasOwnProperty.call(facilities, 'noShow')
    ? getNoShowFacility(facilities.noShow)
    : NoInfoFound;
  const priorityBoardingFacility = Object.prototype.hasOwnProperty.call(
    facilities,
    'priorityBoarding',
  )
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.priority_boarding'),
        facilities.priorityBoarding,
      )
    : NoInfoFound;
  const co2CompensationFacility = Object.prototype.hasOwnProperty.call(
    facilities,
    'co2Compensation',
  )
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.co2_compensation'),
        facilities.co2Compensation,
      )
    : NoInfoFound;
  const digitalMagazinesFacility = Object.prototype.hasOwnProperty.call(
    facilities,
    'digitalMagazines',
  )
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.digital_magazine'),
        facilities.digitalMagazines,
      )
    : NoInfoFound;
  const exclusiveCheckInFacility = Object.prototype.hasOwnProperty.call(
    facilities,
    'exclusiveCheckIn',
  )
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.exclusive_check_in'),
        facilities.exclusiveCheckIn,
      )
    : NoInfoFound;
  const fastTrackSecurityFacility = Object.prototype.hasOwnProperty.call(
    facilities,
    'fastTrackSecurity',
  )
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.fast_track'),
        facilities.fastTrackSecurity,
      )
    : NoInfoFound;
  const flatbedFacility = Object.prototype.hasOwnProperty.call(facilities, 'flatbed')
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.flatbed'),
        facilities.flatbed,
      )
    : NoInfoFound;
  const loungeAccessFacility = Object.prototype.hasOwnProperty.call(facilities, 'loungeAccess')
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.lounge_access'),
        facilities.loungeAccess,
      )
    : NoInfoFound;
  const mileUpgradeFacility = Object.prototype.hasOwnProperty.call(facilities, 'mileUpgrade')
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.mile_upgrade'),
        facilities.mileUpgrade,
      )
    : NoInfoFound;
  const nameChangeFacility = Object.prototype.hasOwnProperty.call(facilities, 'nameChange')
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.name_change'),
        facilities.nameChange,
      )
    : NoInfoFound;
  const priorityLuggageFacility = Object.prototype.hasOwnProperty.call(
    facilities,
    'priorityLuggage',
  )
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.priority_luggage'),
        facilities.priorityLuggage,
      )
    : NoInfoFound;
  const seatReservationFacility = Object.prototype.hasOwnProperty.call(
    facilities,
    'seatReservation',
  )
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.seat_reservation'),
        facilities.seatReservation,
      )
    : NoInfoFound;
  const welcomeDrinkFacility = Object.prototype.hasOwnProperty.call(facilities, 'welcomeDrink')
    ? getThreeStateFacility(
        I18n.t('components.ibe.search_result.facility.welcome_drink'),
        facilities.welcomeDrink,
      )
    : NoInfoFound;
  const milesStatusFacility = Object.prototype.hasOwnProperty.call(facilities, 'milesStatus')
    ? getFacilityWithPoints(
        I18n.t('components.ibe.search_result.facility.miles_status'),
        facilities.milesStatus,
      )
    : NoInfoFound;
  const milesAwardFacility = Object.prototype.hasOwnProperty.call(facilities, 'milesAward')
    ? getFacilityWithPoints(
        I18n.t('components.ibe.search_result.facility.miles_award'),
        facilities.milesAward,
      )
    : NoInfoFound;

  const numberOfAdult = people && people.adults;
  const numberOfChildren = people && people.children;
  const numberOfInfant = people && people.infants;

  const totalTravelers = numberOfAdult + numberOfChildren + numberOfInfant;

  const newMinPrice =
    Number(minPrice) < Number(minPriceCalculatedInClientSide)
      ? minPrice
      : minPriceCalculatedInClientSide;
  const adultPrice =
    fareCalculations &&
    fareCalculations.find(passenger => passenger.passengerType === TYPE_ADULT).price;

  useEffect(() => {
    if (cheapestOption === null) {
      reportToRollbar(
        new Error(
          `Cheapest fare option missing in "${activeTabKey}" tab for Trip ${activeTripId} and "${name}" fare brand that has suppliers ${JSON.stringify(
            resultIds,
          )}.`,
        ),
        matrixesByType,
      );
    }
  }, []);

  useEffect(() => {
    // This check is for hiding the fare brand card when there are more than one related trips
    // And one trip has selected option
    // When user selects an option in a trip among all the related trip very first time
    // The fare brand card (except the selected one) will be hidden in the other related trips
    // But will remain visible in the first selected trip
    if (
      relatedTripsHashMap[activeTripId] &&
      relatedTripsHashMap[activeTripId].firstSelectedTripId &&
      relatedTripsHashMap[activeTripId].firstSelectedTripId !== activeTripId &&
      !hasSelectedFareOption
    ) {
      setShouldShowTheFareBrand(false);
    } else {
      setShouldShowTheFareBrand(true);
    }
  }, [relatedTripsHashMap, hasSelectedFareOption, activeTripId]);

  const handleChildElementClick = event => {
    event.stopPropagation();
  };

  return shouldShowTheFareBrand ? (
    <MatrixCard
      className={classNames('matrix-fare-brand-card', {
        'matrix-fare-brand-card--laymanmode': laymanMode,
        'matrix-fare-brand-card--selected': laymanMode && isSelected,
        'matrix-fare-brand-card--disabled': isFetchingMatrices,
      })}
      matrixId={matrixId}
      isSelected={isSelected}
      isCheapest={isCheapestFareBrand}
      onClick={
        laymanMode && !isSelected && !isFetchingMatrices
          ? () => {
              onClick({ id: activeTrip.matrixId, channelId }, data);
            }
          : () => {}
      }
      isClickable={laymanMode && !isSelected}
      people={people}
    >
      {laymanMode && (
        <div
          className={classNames('matrix-fare-brand-card__spinner', {
            'matrix-fare-brand-card__spinner--visible': isLoading,
          })}
        >
          <Spinner bgColor="neutral" />
        </div>
      )}
      {laymanMode ? (
        <div className="matrix-fare-brand-card__title matrix-fare-brand-card__title--laymanmode">
          <Text className="matrix-fare-brand-card__title-text" level={2} weight="bold">
            {name}
          </Text>
          <div
            className={classNames('matrix-fare-brand-card__title-check-circle', {
              'matrix-fare-brand-card__title-check-circle--selected': isSelected,
            })}
          >
            <Icon name="check" size="tiny" color="neutral" />
          </div>
        </div>
      ) : (
        <Header className="matrix-fare-brand-card__title" level={5} weight="bold">
          {name}
        </Header>
      )}
      {laymanMode && fareRulePresent && (
        <div
          className="matrix-fare-brand-card__fare-text"
          onClick={event => event && handleChildElementClick(event)}
          aria-hidden="true"
        >
          <MatrixFareRulesContainer
            resultIds={{
              [data.resultId]: {
                channelName: null,
                identifier: null,
              },
            }}
          />
        </div>
      )}
      {laymanMode ? (
        <Fragment>
          <FlightSeatCountTag
            className="matrix-fare-brand-card__seats-status"
            seatCount={data.availableSeatCount}
            travellerCount={totalTravelerCount}
          />
          <div className="d-flex-col justify-between matrix-fare-brand-card__price__item_price">
            <FlightPricePerAdult
              currency={currency}
              grossAmount={data.price}
              laymanMode={laymanMode}
              totalTravelers={totalTravelers}
              numberOfAdult={numberOfAdult}
              numberOfChildren={numberOfChildren}
              numberOfInfant={numberOfInfant}
              grossTaxes={grossTaxes}
              fareCalculations={fareCalculations}
              adultPrice={adultPrice}
              onPriceLabelClick={event => event && handleChildElementClick(event)}
            />
          </div>
        </Fragment>
      ) : (
        <Text className="matrix-fare-brand-card__price" level={2} weight="bold">
          {newMinPrice === maxPrice ? (
            <Amount
              currency={currency}
              value={calculateAndAddTaxWithPrice(
                newMinPrice,
                grossTaxes,
                numberOfAdult,
                numberOfChildren,
              )}
            />
          ) : (
            <Fragment>
              <Amount
                currency={currency}
                value={calculateAndAddTaxWithPrice(
                  newMinPrice,
                  minGrossTaxes,
                  numberOfAdult,
                  numberOfChildren,
                )}
              />{' '}
              -{' '}
              <Amount
                currency={currency}
                value={calculateAndAddTaxWithPrice(
                  maxPrice,
                  maxGrossTaxes,
                  numberOfAdult,
                  numberOfChildren,
                )}
              />
            </Fragment>
          )}
        </Text>
      )}
      {!laymanMode && (
        <Text className="matrix-fare-brand-card__booking-class" level={2} weight="normal">
          {I18n.t('components.ibe.search_result.booking_classes', {
            count: bookingClasses.length,
          })}
          : {bookingClasses.join(', ')}
        </Text>
      )}
      {!laymanMode && fareRulePresent && <MatrixFareRulesContainer resultIds={resultIds} />}
      <ul className="matrix-fare-brand-card__facilities">
        {/* Bag Facility */}
        {baggageFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={baggageFacility.description}
            icon={{
              color: iconColors[baggageFacility.status],
              name: baggageFacility.status === Included ? 'carryOnBag' : 'noCarryOnBag',
            }}
          />
        )}
        {/* Hand Luggage Facility */}
        {handLuggageFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={handLuggageFacility.description}
            icon={{
              color: iconColors[handLuggageFacility.status],
              name: handLuggageFacility.status === Included ? 'handLuggage' : 'noHandLuggage',
            }}
          />
        )}
        {/* Refund Facility */}
        {cancellationFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={cancellationFacility.description}
            icon={{
              color: iconColors[cancellationFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Change Facility */}
        {changeFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={changeFacility.description}
            icon={{
              color: iconColors[changeFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Meal Facility */}
        {mealFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={mealFacility.description}
            icon={{
              color: iconColors[mealFacility.status],
              name: [AvailableForAll, AvailableForSome].includes(mealFacility.status)
                ? 'mealSsr'
                : 'noMeal',
            }}
          />
        )}
        {/* Wifi Facility */}
        {wifiFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={wifiFacility.description}
            icon={{
              color: iconColors[wifiFacility.status],
              name: [AvailableForAll, AvailableForSome].includes(wifiFacility.status)
                ? 'wifi'
                : 'noWifi',
            }}
          />
        )}
        {/* No Show Facility */}
        {noShowFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={noShowFacility.description}
            icon={{
              color: iconColors[noShowFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Priority boarding Facility */}
        {priorityBoardingFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={priorityBoardingFacility.description}
            icon={{
              color: iconColors[priorityBoardingFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Priority luggage Facility */}
        {priorityLuggageFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={priorityLuggageFacility.description}
            icon={{
              color: iconColors[priorityLuggageFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Exclusive check-in Facility */}
        {exclusiveCheckInFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={exclusiveCheckInFacility.description}
            icon={{
              color: iconColors[exclusiveCheckInFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Seat reservation Facility */}
        {seatReservationFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={seatReservationFacility.description}
            icon={{
              color: iconColors[seatReservationFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Lounge access Facility */}
        {loungeAccessFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={loungeAccessFacility.description}
            icon={{
              color: iconColors[loungeAccessFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Digital magazine Facility */}
        {digitalMagazinesFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={digitalMagazinesFacility.description}
            icon={{
              color: iconColors[digitalMagazinesFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Flatbed Facility */}
        {flatbedFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={flatbedFacility.description}
            icon={{
              color: iconColors[flatbedFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Name change Facility */}
        {nameChangeFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={nameChangeFacility.description}
            icon={{
              color: iconColors[nameChangeFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* CO2 compensation Facility */}
        {co2CompensationFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={co2CompensationFacility.description}
            icon={{
              color: iconColors[co2CompensationFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Fast track security Facility */}
        {fastTrackSecurityFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={fastTrackSecurityFacility.description}
            icon={{
              color: iconColors[fastTrackSecurityFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Miles upgrade Facility */}
        {mileUpgradeFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={mileUpgradeFacility.description}
            icon={{
              color: iconColors[mileUpgradeFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Miles award Facility */}
        {milesAwardFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={milesAwardFacility.description}
            icon={{
              color: iconColors[milesAwardFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Miles status Facility */}
        {milesStatusFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={milesStatusFacility.description}
            icon={{
              color: iconColors[milesStatusFacility.status],
              name: 'preference',
            }}
          />
        )}
        {/* Welcome drink Facility */}
        {welcomeDrinkFacility !== NoInfoFound && (
          <FareBrandCardRow
            facilityDescription={welcomeDrinkFacility.description}
            icon={{
              color: iconColors[welcomeDrinkFacility.status],
              name: 'preference',
            }}
          />
        )}
      </ul>
    </MatrixCard>
  ) : null;
};

MatrixFareBrandCard.propTypes = {
  matrixId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minPrice: PropTypes.string.isRequired,
  maxPrice: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  resultIds: PropTypes.shape({}).isRequired,
  cheapestOption: PropTypes.shape({
    isSelected: PropTypes.bool.isRequired,
    channelId: PropTypes.string.isRequired,
    data: PropTypes.shape({
      resultId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  cheapestOptionCalculatedInClientSide: PropTypes.shape({
    isSelected: PropTypes.bool.isRequired,
    channelId: PropTypes.string.isRequired,
    data: PropTypes.shape({
      resultId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  minPriceCalculatedInClientSide: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  hasSelectedFareOption: PropTypes.bool.isRequired,
  bookingClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
  facilities: PropTypes.shape({
    baggageInformation: PropTypes.shape({}),
    handLuggage: PropTypes.shape({}),
    cancellationPenalty: PropTypes.shape({}),
    changePenalty: PropTypes.shape({}),
    meal: PropTypes.string,
    wifi: PropTypes.string,
    noShow: PropTypes.shape({}),
    priorityBoarding: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    co2Compensation: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    digitalMagazines: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    exclusiveCheckIn: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    fastTrackSecurity: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    flatbed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    loungeAccess: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    mileUpgrade: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    nameChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    priorityLuggage: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    seatReservation: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    welcomeDrink: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    milesAward: PropTypes.shape({}),
    milesStatus: PropTypes.shape({}),
  }).isRequired,
  fareRulePresent: PropTypes.bool.isRequired,
  isCheapestFareBrand: PropTypes.bool.isRequired,
  people: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  fareCalculations: PropTypes.shape({ find: PropTypes.func }).isRequired,
  grossTaxes: PropTypes.string.isRequired,
  maxGrossTaxes: PropTypes.string.isRequired,
  minGrossTaxes: PropTypes.string.isRequired,
};

export default MatrixFareBrandCard;
