import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@wtag/rcl-menu';
import './styles.scss';

const MenuLabel = ({ className, label, onClick }) => (
  <div className={className} onClick={onClick} role="button" tabIndex={0} onKeyDown={onClick}>
    {label}
  </div>
);

MenuLabel.defaultProps = {
  className: null,
};

MenuLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

const PriceMenu = ({ label, children }) => {
  const [isPriceMenuVisible, setIsPriceMenuVisible] = useState(false);

  const handlePriceMenuOutsideClick = () => {
    setIsPriceMenuVisible(false);
  };

  const toggleLabelClick = () => {
    setIsPriceMenuVisible(previousState => !previousState);
  };

  return (
    <Menu
      className="price-menu"
      isVisible={isPriceMenuVisible}
      size="medium"
      popOverDirection="bottom-left"
      onOutsideClick={handlePriceMenuOutsideClick}
      label={<MenuLabel className="price-menu__button" label={label} onClick={toggleLabelClick} />}
    >
      <div className="d-flex direction-column row-gap-16">{children}</div>
    </Menu>
  );
};

PriceMenu.defaultProps = {
  children: null,
};

PriceMenu.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default PriceMenu;
