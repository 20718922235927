import React, { useState, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Icon from '@wtag/rcl-icon';
import { Link } from '@wtag/react-comp-lib';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import routes from 'agentRoutes';
import classNames from 'classnames';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import Pagination from 'sharedWebpack/Pagination';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import '../../styles.scss';
import GroupedAccounting from './GroupedAccounting';

const AccountingTab = ({ orderId, scope, actionsEnabled, fromApprovalRequest }) => {
  const [accountings, setAccountings] = useState([]);
  const [urls, setUrls] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    tab: withDefault(StringParam, ''),
  });
  const componentMounted = useRef(false);
  const { page, tab } = query;

  const setInitialParams = data => {
    // We only set the params onces when there is nothing in the url
    if (!query.page) {
      setQuery(
        {
          tab: 'accounting',
          page: data.pagination.currentPage,
        },
        // use replaceIn as we don't want another navigation to be added to the browser history
        'replaceIn',
      );
    }
  };

  const fetchAccountings = (newPage, apiToken) => {
    httpClient
      .get(
        routes[scope].orderAccountings.list({
          orderId,
          page: newPage,
        }),
        {
          cancelToken: apiToken,
        },
      )
      .then(({ data }) => {
        if (componentMounted.current) {
          setAccountings(data.orderCostCenters);
          setUrls(data.urls);
          setTotalPages(data.pagination.totalPages);
          setInitialParams(data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        if (componentMounted.current) {
          setIsLoading(false);
        }

        throw error;
      });
  };

  useEffect(() => {
    const getAccoutingsApiToken = generateSourceToken();

    componentMounted.current = true;
    fetchAccountings(page, getAccoutingsApiToken.token);
    setQuery({ page, tab: 'accounting' }, 'replaceIn');

    return () => {
      getAccoutingsApiToken.cancel();
      componentMounted.current = false;
    };
  }, [page, tab]);

  return (
    <Fragment>
      <div className="col-grid col-bleed align-end">
        {actionsEnabled && (
          <Link type="button" modifier="primary" size="small" href={urls.create}>
            {I18n.t('admin.shared.action.new')}
          </Link>
        )}
      </div>
      {isLoading ? (
        <div
          className={classNames('col-grid', 'col-bleed', 'order-accounting__content-loader', {
            'order-accounting__content-loader--without-padding': fromApprovalRequest,
          })}
        >
          <ContentLoaderPlaceholder numberOfLines={6} />
        </div>
      ) : (
        <Fragment>
          <div
            className={classNames('col-grid', 'col-bleed', 'order-accounting', {
              'order-accounting__card': !fromApprovalRequest,
            })}
          >
            {accountings && accountings.length > 0 ? (
              accountings.map(groupedAccounting => (
                <GroupedAccounting
                  key={uuidv4()}
                  accounting={groupedAccounting}
                  actionsEnabled={actionsEnabled}
                  orderId={orderId}
                  fetchAccountings={fetchAccountings}
                />
              ))
            ) : (
              <Card
                version="v2"
                color="tertiary"
                emptyCardImageSrc={<Icon name="add" />}
                emptyCardText={I18n.t('admin.components.orders.accounting_tab.no_accounting')}
              />
            )}
          </div>
          <div className="col-grid col-bleed">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPaginationClick={newPage => setQuery({ page: newPage })}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

AccountingTab.defaultProps = {
  scope: 'admin',
  actionsEnabled: true,
  fromApprovalRequest: false,
};

AccountingTab.propTypes = {
  orderId: PropTypes.number.isRequired,
  scope: PropTypes.string,
  actionsEnabled: PropTypes.bool,
  fromApprovalRequest: PropTypes.bool,
};

export default withQueryParamsProvider(AccountingTab);
