import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@wtag/rcl-button';
import Tooltip from '@wtag/rcl-tooltip';
import { Text } from '@wtag/rcl-typography';
import MatrixContext from '../contexts/MatrixContext';
import './matrix-tabs.styles.scss';

const MatrixTabs = props => {
  const { items, activeTabKey, onChange, laymanMode } = props;
  const { isFetchingMatrices } = useContext(MatrixContext);
  const activeItem = items.find(item => item.key === activeTabKey);
  const windowSizeForMobileView = 991;

  return (
    <div className="matrix-tabs">
      <div className="matrix-tabs__nav">
        {items.map(item => {
          const isActiveTab = activeTabKey === item.key && !item.disabled;

          return item.disabled ? (
            <Tooltip
              key={item.key}
              content={
                <Fragment>
                  <Text level={2} weight="bold">
                    {item.disablityNote.title}
                  </Text>
                  <Text level={3} weight="normal">
                    {item.disablityNote.body}
                  </Text>
                </Fragment>
              }
              position={window.innerWidth > windowSizeForMobileView ? 'bottom' : 'bottom-left'}
              type="inverse"
            >
              <Button
                className={classNames('matrix-tabs__item', {
                  'matrix-tabs__item--disabled': !laymanMode,
                })}
                label={item.title}
                size="small"
                disabled={item.disabled}
                onClick={() => {
                  onChange(item.key);
                }}
                type={isActiveTab ? 'secondary' : 'default'}
              />
            </Tooltip>
          ) : (
            <Button
              key={item.key}
              className="matrix-tabs__item"
              label={item.title}
              size="small"
              disabled={item.disabled || (!isActiveTab && isFetchingMatrices)}
              onClick={() => {
                onChange(item.key);
              }}
              type={isActiveTab ? 'secondary' : 'default'}
            />
          );
        })}
      </div>
      <div className="matrix-tabs__pane">{activeItem ? activeItem.content : null}</div>
    </div>
  );
};

MatrixTabs.defaultProps = {
  items: [],
  onChange: () => {},
};

MatrixTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      disabled: PropTypes.bool,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      disablityNote: PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      }),
    }),
  ),
  activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  laymanMode: PropTypes.bool.isRequired,
};

export default MatrixTabs;
