import { connect } from 'react-redux';
import LoadingInformationCard from './LoadingInformationCard';
import { fetchAirportDetails } from '../../../../actions/common';

const mapStateToProps = state => ({
  airports: state.common.airports,
});

const mapDispatchToProps = dispatch => ({
  fetchAirportDetails: code => dispatch(fetchAirportDetails(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingInformationCard);
