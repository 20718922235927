import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { AnimatedProgressbar } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Map from '@wtag/rcl-map';
import { FormatDateTime, DATE_FORMAT_SHORT } from 'sharedWebpack/helpers/dateTime';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';

const LoadingInformationCard = ({ searchInformation, searchCompleted }) => {
  const isSearchInformationEmpty = Object.keys(searchInformation).length === 0;

  if (isSearchInformationEmpty) return null;

  const {
    address,
    adults,
    checkIn,
    checkOut,
    children,
    latitude,
    longitude,
    numberOfRooms,
  } = searchInformation;

  const numberOfTravelers = adults + children;
  const animatedProgressBarText = [
    I18n.t('components.ibe.search_loading.search_loading_text.request_hotel'),
    I18n.t('components.ibe.search_loading.search_loading_text.check'),
    I18n.t('components.ibe.search_loading.search_loading_text.fetch'),
    I18n.t('components.ibe.search_loading.search_loading_text.prepare'),
  ];
  const hotelDataForMap = [
    {
      title: address,
      checkIn,
      checkOut,
      latitude,
      longitude,
    },
  ];
  const bookingInformationText = `${I18n.t('components.ibe.search_loading.hotel.travelers_count', {
    count: numberOfTravelers,
  })} | ${I18n.t('components.ibe.search_loading.hotel.rooms_count', {
    count: numberOfRooms,
  })}`;

  const getFormattedDate = (date, localeKey) => (
    <DateTimeLocaleInterpolationWrapper
      localeKey={localeKey}
      time={FormatDateTime({
        dateTime: date,
        displayWithoutConversion: true,
        format: DATE_FORMAT_SHORT,
      })}
    />
  );

  return (
    <div className="col-12 col-bleed loading-information-card">
      <Card version="v2" size="full">
        <div className="col-grid col-bleed direction-row justify-space-between loading-information-card__header" />
        <Fragment>
          {!searchCompleted && (
            <div className="loading-information-card__animation-bar">
              <div className="loading-information-card__animation-bar-text">
                {I18n.t('components.ibe.search_loading.hotel.searching_for_best_options')}
              </div>
              <AnimatedProgressbar
                size="small"
                bgColor="success"
                animationSpeed={350000}
                initialyCompleted={95}
                textMessages={animatedProgressBarText}
                completedMessage="Completed"
              />
            </div>
          )}
          <div className="col-12 col-bleed col-grid align-center loading-information-card__content">
            <div className="col-lg-6 col-md-6 col-12 col-bleed loading-information-card__content-info">
              <div className="loading-information-card__content-info-text">
                {I18n.t('components.ibe.search_loading.hotel.searched_for')}
              </div>
              <div className="loading-information-card__content-info-address">{address}</div>
              <div className="loading-information-card__content-info-duration">
                <div className="loading-information-card__content-info-duration-time">
                  <div className="loading-information-card__content-info-duration-time-text">
                    {getFormattedDate(checkIn, 'components.ibe.search_loading.hotel.check_in')}
                  </div>
                </div>
                <div className="loading-information-card__content-info-duration-time">
                  <div className="loading-information-card__content-info-duration-time-text">
                    {getFormattedDate(checkOut, 'components.ibe.search_loading.hotel.check_out')}
                  </div>
                </div>
              </div>
              <div className="loading-information-card__content-info-room-details">
                {bookingInformationText}
              </div>
              <div className="loading-information-card__content-info-message">
                <div className="loading-information-card__content-info-message-icon">
                  <Icon name="loading" showBGColor={true} size="normal" color="success" />
                </div>
                <div className="loading-information-card__content-info-message-text">
                  <div className="loading-information-card__content-info-message-text-title">
                    {I18n.t('components.ibe.search_loading.search_loading_text.hold_tight')}
                  </div>
                  <div className="loading-information-card__content-info-message-text-data">
                    {I18n.t('components.ibe.search_loading.search_loading_text.superior_search')}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 col-bleed loading-information-card__content-map">
              <Map
                uniqueKey={`hotel-${latitude}-${longitude}`}
                mapCenter={{ latitude, longitude }}
                markerSize={{ height: 50, width: 50 }}
                hotels={hotelDataForMap}
                zoomLevel={4}
              />
            </div>
          </div>
        </Fragment>
      </Card>
    </div>
  );
};

LoadingInformationCard.propTypes = {
  searchInformation: PropTypes.shape({
    address: PropTypes.string.isRequired,
    adults: PropTypes.number.isRequired,
    checkIn: PropTypes.string.isRequired,
    checkOut: PropTypes.string.isRequired,
    children: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    numberOfRooms: PropTypes.number.isRequired,
  }).isRequired,
  searchCompleted: PropTypes.bool.isRequired,
};

export default LoadingInformationCard;
