import routes from 'agentRoutes';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import history from '../../lib/history';

import { form2api } from '../../Search/hotel/helpers/mapSearchParams';
import { saveSearch } from '../../lib/saveSearchParameters';
import { showError } from '../common';

export const HOTEL_SEARCH_FETCHED = 'HOTEL_SEARCH_FETCHED';
function hotelSearchResultsFetched(data) {
  return {
    type: HOTEL_SEARCH_FETCHED,
    results: data.results,
    stats: data.stats,
    searchParams: data.search,
    availableResults: data.availableResults,
    searchId: data.id,
    availableResultsPerSupplier: data.availableResultsPerSupplier,
  };
}

export function fetchHotelSearchResultsByName(searchId, name) {
  const params = { searchId, name };
  return () => httpClient.get(routes.api.hotels.searchResults(params)).then(({ data }) => data);
}

export const FETCHING_HOTEL_SEARCH = 'FETCHING_HOTEL_SEARCH';
export function fetchHotelSearchResults(params) {
  return dispatch =>
    httpClient
      .get(routes.api.hotels.searchResults(params))
      .then(({ data }) => dispatch(hotelSearchResultsFetched(data)));
}

export const HOTEL_SEARCH_COMPLETED = 'HOTEL_SEARCH_COMPLETED';
function hotelSearchCompleted(id) {
  return {
    type: HOTEL_SEARCH_COMPLETED,
    id,
  };
}

let currentHotelSearchToken;
export function resetHotelSearch() {
  if (currentHotelSearchToken) {
    currentHotelSearchToken.cancel('Explicitly cancelled');
  }
}

export function createHotelSearch(formParams, callbackParams) {
  if (currentHotelSearchToken) {
    currentHotelSearchToken.cancel('Replaced with new search');
  }

  currentHotelSearchToken = generateSourceToken();
  saveSearch('hotels', formParams);
  const searchParams = form2api(formParams, callbackParams);

  return dispatch =>
    httpClient
      .post(routes.api.hotels.createSearch(), searchParams, {
        cancelToken: currentHotelSearchToken.token,
      })
      .then(({ data }) => dispatch(hotelSearchCompleted(data.id)))
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }
        return dispatch(showError('Search failed for unknown reasons', error));
      });
}

export const SEARCHING_HOTELS = 'SEARCHING_HOTELS';
export function searchHotels(params, callbackParams) {
  return dispatch => {
    dispatch({ type: SEARCHING_HOTELS, params });
    return dispatch(createHotelSearch(params, callbackParams)).then(
      data => data && data.id && history.push(`/hotels/searches/${data.id}`),
    );
  };
}
