/* global Rollbar */
/* eslint-disable no-console */

function reportToRollbar(error, metaData) {
  if (typeof Rollbar !== 'undefined') {
    Rollbar.error(error, metaData);
  } else if (process.env.NODE_ENV !== 'test') {
    console.log('Rollbar not available', { error, metaData });
  }
}

export default reportToRollbar;
