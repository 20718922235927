import React, { Fragment, useEffect, useState, useRef } from 'react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import PropTypes from 'prop-types';
import Pagination from 'sharedWebpack/Pagination';
import SortingPill from 'sharedWebpack/SortingPill';
import Table from './Table';

const ManageRequest = props => {
  const { scope, tab, fetchUrl, hideColumns } = props;
  const [requests, setRequests] = useState([]);
  const componentMounted = useRef(false);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    tab: StringParam,
    scope: StringParam,
    sortColumn: StringParam,
    sortDirection: StringParam,
    q: withDefault(StringParam, ''),
  });
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const { page, sortColumn, sortDirection } = query;

  const updateSorting = (column, direction) => {
    setIsLoading(true);
    setQuery({ sortColumn: column, sortDirection: direction });
  };

  const fetchRequests = async (column, direction, cancelToken) => {
    setIsLoading(true);

    const params = {
      page,
      tab,
      scope,
      column,
      direction,
    };

    try {
      const { data } = await httpClient.get(fetchUrl, { params, cancelToken });

      if (componentMounted.current) {
        setRequests(data.approvalRequests);
        setTotalPages(data.pagination && data.pagination.totalPages);
        setIsLoading(false);
      }
    } catch (error) {
      if (!isCancelError(error)) throw error;
    }

    return null;
  };

  useEffect(() => {
    const fetchRequestsApiToken = generateSourceToken();
    componentMounted.current = true;

    fetchRequests(sortColumn, sortDirection, fetchRequestsApiToken.token);

    return () => {
      fetchRequestsApiToken.cancel();
      componentMounted.current = false;
    };
  }, [page, sortColumn, sortDirection, scope, tab, fetchUrl]);

  useEffect(() => {
    setTotalPages(1);
    setQuery({ tab, scope, page: 1 }, 'replace');
  }, [tab, scope]);

  return (
    <Fragment>
      <div className="grid justify-space-between">
        <div className="col-sm-9">
          <div className="grid grid-gap-12">
            {!hideColumns.requestor && (
              <div className="col-sm-3 request-list__sorting">
                <SortingPill
                  size="small"
                  name={I18n.t('public.components.approval_request.attributes.requestor')}
                  thisSortColumn="requestor"
                  currentSortColumn={sortColumn}
                  currentSortDirection={sortDirection}
                  onChange={updateSorting}
                />
              </div>
            )}
            <div className="col-sm-3 request-list__sorting">
              <SortingPill
                size="small"
                name={I18n.t('public.components.approval_request.attributes.departure_date')}
                thisSortColumn="starts_at"
                currentSortColumn={sortColumn}
                currentSortDirection={sortDirection}
                onChange={updateSorting}
              />
            </div>
            <div className="col-sm-3 request-list__sorting">
              <SortingPill
                size="small"
                name={I18n.t('public.components.approval_request.attributes.amount')}
                thisSortColumn="amount"
                currentSortColumn={sortColumn}
                currentSortDirection={sortDirection}
                onChange={updateSorting}
              />
            </div>
            <div className="col-sm-3 request-list__sorting">
              <SortingPill
                size="small"
                name={I18n.t('public.components.approval_request.attributes.created')}
                thisSortColumn="created"
                currentSortColumn={sortColumn}
                currentSortDirection={sortDirection}
                onChange={updateSorting}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-bleed request-list__search">
          {
            // TODO: search will be implemented in AG-5634
          }
        </div>
      </div>

      <div className="request-list__collection col-12 col-bleed">
        <Table isLoading={isLoading} requests={requests} hideColumns={hideColumns} />
      </div>
      <div className="col-12 col-bleed-y">
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPaginationClick={newPage => {
            setIsLoading(true);
            setQuery({ page: newPage });
          }}
        />
      </div>
    </Fragment>
  );
};

ManageRequest.defaultProps = {
  hideColumns: {
    requestor: false,
  },
};

ManageRequest.propTypes = {
  scope: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  fetchUrl: PropTypes.string.isRequired,
  hideColumns: PropTypes.shape({
    requestor: PropTypes.bool,
  }),
};

export default withQueryParamsProvider(ManageRequest);
