import React, { useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import RclLink from '@wtag/rcl-link';
import useWindowWidth from 'sharedWebpack/useWindowWidth';
import Stepper from '../../Stepper';
import history from '../../lib/history';

import './styles.scss';

const OrderSummaryLink = ({ scrollToRef, displayLink }) => {
  const handleOnLinkClick = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView();
    }
  };

  if (displayLink) {
    return (
      <RclLink onClick={handleOnLinkClick} modifier="tertiary" size="small">
        {I18n.t('components.ibe.summary.order_summary')}
      </RclLink>
    );
  }

  return null;
};

OrderSummaryLink.defaultProps = {
  displayLink: true,
};

OrderSummaryLink.propTypes = {
  scrollToRef: PropTypes.number.isRequired,
  displayLink: PropTypes.bool,
};

const CheckoutProgress = forwardRef((props, ref) => {
  const {
    nextButtonDisabled,
    nextButton,
    cartId,
    nextStepButtonHint,
    nextStepButtonAction,
    nextStepButtonHasAction,
    cartError,
    currentStep,
    detailsError,
    paymentError,
    bookingRequestReceived,
    disableMoveBackward,
    totalCartItems,
    orderSummaryRef,
  } = props;

  const windowWidth = useWindowWidth();

  const getCartStepLabel = () => {
    if (windowWidth > 991) {
      return I18n.t('components.ibe.check_out_progress.total_cart_items', {
        count: totalCartItems,
      });
    }

    return I18n.t('components.ibe.search_form.label.cart');
  };

  const steps = [
    {
      stepName: getCartStepLabel(),
      stepIconType: 'shoppingCart',
    },
    {
      stepName: I18n.t('components.ibe.check_out_progress.travelers'),
      stepIconType: 'person',
    },
    {
      stepName: I18n.t('components.ibe.check_out_progress.payments'),
      stepIconType: 'paymentMethod',
    },
    {
      stepName: I18n.t('components.ibe.check_out_progress.book'),
      stepIconType: 'book',
    },
  ];

  const stepSequence = ['cart', 'traveler_details', 'payment', 'booking'];

  const errorsInStep = {};

  let step;
  if (cartError) {
    step = stepSequence.indexOf('cart');
    errorsInStep[step] = stepSequence[step];
  }

  if (detailsError) {
    step = stepSequence.indexOf('traveler_details');
    errorsInStep[step] = stepSequence[step];
  }

  if (paymentError) {
    step = stepSequence.indexOf('payment');
    errorsInStep[step] = stepSequence[step];
  }

  const urls = [
    `/carts/${cartId}`,
    `/orders/${cartId}/traveler-details`,
    `/orders/${cartId}/payment`,
    `/orders/${cartId}/booking`,
  ];

  let completedStepIndex;
  if (bookingRequestReceived) {
    completedStepIndex = stepSequence.indexOf('booking');
  }

  const activeIndex = stepSequence.indexOf(currentStep);

  const onNextStep = () => {
    if (nextStepButtonHasAction) {
      nextStepButtonAction();
    } else {
      const changedIndex = activeIndex + 1;
      history.push(urls[changedIndex]);
    }
  };

  const onPreviousStep = () => {
    const changedIndex = activeIndex - 1;
    history.push(urls[changedIndex]);
  };

  useImperativeHandle(ref, () => ({
    moveNextStep: onNextStep,
  }));

  return (
    <React.Fragment>
      <div className="container container--sm-full-width checkout-progress">
        <Stepper
          steps={steps}
          activeStepIndex={activeIndex}
          disableMoveForward={nextButtonDisabled}
          disableMoveBackward={!activeIndex || disableMoveBackward}
          showActionButtons={true}
          showStepTitle={true}
          showFinishButton={false}
          nextButtonLabel={
            <I18nText id="ibe.check_out_progress.buttons.next_step" returnStringOnly={true} />
          }
          previousButtonLabel={
            <I18nText id="ibe.check_out_progress.buttons.previous_step" returnStringOnly={true} />
          }
          onFinish={() => {}}
          onMoveNext={onNextStep}
          onMoveBack={onPreviousStep}
          errors={errorsInStep}
          completedStepIndex={completedStepIndex}
          definedNextButton={nextButton}
          nextStepButtonHint={nextStepButtonHint}
        />
      </div>
      <div className="container container--sm-full-width checkout-progress checkout-progress--mobile">
        <div className="d-flex justify-between">
          <span className="checkout-progress__total-cart-items-header">
            {I18n.t('components.ibe.check_out_progress.total_cart_items', {
              count: totalCartItems,
            })}
          </span>
          <OrderSummaryLink
            scrollToRef={orderSummaryRef}
            displayLink={currentStep === 'cart' || currentStep === 'booking'}
          />
        </div>
      </div>
    </React.Fragment>
  );
});
CheckoutProgress.defaultProps = {
  totalCartItems: 0,
  nextButton: null,
  cartId: null,
  nextButtonDisabled: true,
  cartError: false,
  detailsError: false,
  paymentError: false,
  nextStepButtonHint: null,
  nextStepButtonHasAction: false,
  disableMoveBackward: false,
  bookingRequestReceived: false,
  nextStepButtonAction: () => {},
  orderSummaryRef: null,
};

CheckoutProgress.propTypes = {
  totalCartItems: PropTypes.number,
  nextButtonDisabled: PropTypes.bool,
  nextButton: PropTypes.node,
  cartId: PropTypes.string,
  nextStepButtonHasAction: PropTypes.bool,
  nextStepButtonAction: PropTypes.func,
  nextStepButtonHint: PropTypes.string,
  cartError: PropTypes.bool,
  detailsError: PropTypes.bool,
  disableMoveBackward: PropTypes.bool,
  paymentError: PropTypes.bool,
  bookingRequestReceived: PropTypes.bool,
  currentStep: PropTypes.oneOf(['cart', 'travelerDetails', 'payment', 'booking']).isRequired,
  orderSummaryRef: PropTypes.node,
};

export default CheckoutProgress;
