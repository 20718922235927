import DatePicker from '@wtag/rcl-date-picker';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const DatePickerWithFocusedStateWrapper = props => {
  const { onFocusChange, error, ...otherProps } = props;
  const [isFocused, setIsFocused] = useState(false);

  const toggleFocusChange = value => {
    setIsFocused(value);
    onFocusChange(value);
  };

  const errorMessage = () => {
    if (!Array.isArray(error) || error.length < 2) {
      return <span>{error}</span>;
    }

    return (
      <ul className="validation-error-list">
        {error.map(item => (
          <li className="validation-error-list__item" key={uuidv4()}>
            <span className="validation-error-list__item--message">{item}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <DatePicker
      {...otherProps}
      focused={isFocused && props.focused}
      error={error && errorMessage()}
      onFocusChange={toggleFocusChange}
    />
  );
};

DatePickerWithFocusedStateWrapper.defaultProps = {
  onFocusChange: () => {},
  focused: true,
};

DatePickerWithFocusedStateWrapper.propTypes = {
  error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onFocusChange: PropTypes.func,
  focused: PropTypes.bool,
};

export default DatePickerWithFocusedStateWrapper;
