import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import debounce from 'throttle-debounce/debounce';

import { fetchJourneyElement } from './journeyElements';

export const CART_CREATED = 'CART_CREATED';
function cartCreated(cart, approvalRequestId) {
  return {
    type: CART_CREATED,
    cart,
    approvalRequestId,
  };
}

export function ensureCart(callbackParams) {
  return (dispatch, getState) => {
    const state = getState();
    const existingCartId = state.common.cart.id;

    if (existingCartId) {
      return Promise.resolve(existingCartId);
    }

    const params = {
      approval_request_id: state.common.approvalRequest.id,
      text: state.common.cart.text,
      fee_ids: state.common.cart.fees.map(fee => fee.id),
      callback_params: callbackParams,
    };
    return httpClient.post(routes.api.carts.createCart(), params).then(({ data }) => {
      dispatch(cartCreated(data, state.common.approvalRequest.id));
      return data.id;
    });
  };
}

export const FETCHING_CART = 'FETCHING_CART';
function fetchingCart(id) {
  return {
    type: FETCHING_CART,
    id,
    fetchingCart: true,
  };
}

export const CART_FETCHED = 'CART_FETCHED';
export function cartFetched(cart) {
  return {
    type: CART_FETCHED,
    cart,
  };
}

export function fetchCart(id, callbackParams, extraParams = null) {
  return dispatch => {
    dispatch(fetchingCart(id));
    return httpClient
      .get(routes.api.carts.fetchCart({ cartId: id, callbackParams }))
      .then(({ data }) => {
        dispatch(cartFetched(data));
        const allItems = data.items;
        data.items.forEach(item => allItems.concat(item.subItems));
        const promises = [];
        allItems.forEach(item => {
          if (item.hasJourneyElement) {
            let refreshJourneyElement = false;
            if (extraParams) {
              refreshJourneyElement =
                extraParams.refreshFlightJourneyElement && item.journeyElementType === 'flight';
            }
            promises.push(
              dispatch(
                fetchJourneyElement(
                  item.journeyElementType,
                  item.journeyElementId,
                  refreshJourneyElement,
                ),
              ),
            );
          }
        });
        return Promise.all(promises).then(() => data);
      });
  };
}
export const AVAILABILITY_CHECKED = 'AVAILABILITY_CHECKED';
export function availabilityChecked(availability) {
  return {
    type: AVAILABILITY_CHECKED,
    availability,
    isCheckingAvailability: false,
  };
}
export const CHECKING_AVAILABILITY = 'CHECKING_AVAILABILITY';
export function checkingAvailability(cartId) {
  return {
    type: CHECKING_AVAILABILITY,
    cartId,
    isCheckingAvailability: true,
  };
}

export const ANCILLARY_SERVICES_AVAILABILITY_CHECKED = 'ANCILLARY_SERVICES_AVAILABILITY_CHECKED';
export function ancillaryServicesAvailabilityChecked(unavailableItems) {
  return {
    type: ANCILLARY_SERVICES_AVAILABILITY_CHECKED,
    unavailableItems,
  };
}

export const CHECKING_ANCILLARY_SERVICES_AVAILABILITY = 'CHECKING_ANCILLARY_SERVICES_AVAILABILITY';
export function checkingAncillaryServicesAvailability(cartId) {
  return {
    type: CHECKING_ANCILLARY_SERVICES_AVAILABILITY,
    cartId,
  };
}

function checkAvailabilityThroughApi(cartId) {
  return dispatch => {
    dispatch(checkingAvailability(cartId));
    return httpClient
      .get(routes.api.carts.checkAvailability({ cartId }))
      .then(response => dispatch(availabilityChecked(response.data)));
  };
}

function checkAvailabilityWithAncillaryServices(cartId, callbackParams) {
  return dispatch => {
    dispatch(checkingAncillaryServicesAvailability(cartId));
    return httpClient.get(routes.api.carts.checkAllItemsAvailability({ cartId })).then(response => {
      dispatch(ancillaryServicesAvailabilityChecked(cartId));
      dispatch(availabilityChecked(response.data.allItemsAvailable));
      dispatch(fetchCart(cartId, callbackParams));
      return Promise.resolve({
        unavailableServices: response.data.unavailableServices,
        insufficientWalletBalance: response.data.insufficientWalletBalance,
      });
    });
  };
}

export function checkCartItemsAvailability(callbackParams) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    return dispatch(fetchCart(cartId, callbackParams)).then(fetchedCart => {
      const available = fetchedCart.items.every(item => item.available);
      dispatch(availabilityChecked(available));
      return Promise.resolve(fetchedCart);
    });
  };
}

export function fetchCartItemsAvailability(callbackParams) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    return dispatch(checkAvailabilityThroughApi(cartId)).then(() => {
      dispatch(fetchCart(cartId, callbackParams)).then(fetchedCart => Promise.resolve(fetchedCart));
    });
  };
}

export function fetchCartAllItemsAvailability(callbackParams) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    return dispatch(checkAvailabilityWithAncillaryServices(cartId, callbackParams));
  };
}

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export function removeFromCart(itemId, callbackParams) {
  return (dispatch, getState) => {
    const cart = getState().common.cart;
    const itemResultId = cart.items.find(item => item.id === itemId).bookingAttributes.resultId;

    dispatch({
      type: REMOVE_FROM_CART,
      itemId,
      itemResultId,
    });
    const cartId = cart.id;
    return httpClient
      .delete(routes.api.carts.items.removeItem({ cartId, itemId, callbackParams }))
      .then(() => dispatch(checkCartItemsAvailability()));
  };
}

export const REMOVE_SUB_ITEM = 'REMOVE_SUB_ITEM';
export function removeSubItem(itemId, callbackParams) {
  return (dispatch, getState) => {
    const cart = getState().common.cart;

    dispatch({
      type: REMOVE_SUB_ITEM,
      itemId,
    });

    const cartId = cart.id;
    return httpClient.delete(
      routes.api.carts.items.removeItem({ cartId, itemId, callbackParams, manage_product: false }),
    );
  };
}

export const CART_ITEM_CONFIRMED = 'CART_ITEM_CONFIRMED';
export function confirmCartItemAddition(itemId, callbackParams) {
  return (dispatch, getState) => {
    const cart = getState().common.cart;
    const cartId = cart.id;
    return httpClient.put(routes.api.carts.items.confirm({ cartId, itemId })).then(() => {
      dispatch({ type: CART_ITEM_CONFIRMED, cartId, itemId });
      dispatch(fetchCart(cartId, callbackParams));
    });
  };
}

export const SET_QUANTITY = 'SET_QUANTITY';
export function setQuantity(itemId, quantity, callbackParams) {
  return (dispatch, getState) => {
    dispatch({ type: SET_QUANTITY, itemId, quantity });
    const cartId = getState().common.cart.id;
    return httpClient
      .patch(routes.api.carts.items.update({ cartId, itemId }), {
        quantity,
      })
      .then(() => dispatch(fetchCart(cartId, callbackParams)));
  };
}

const setCartTextWithDebounce = debounce(600, (getState, text) =>
  httpClient
    .patch(routes.api.carts.updateCart({ cartId: getState().common.cart.id }), {
      text,
    })
    .then(({ data }) => data),
);

export const SET_CART_TEXT = 'SET_CART_TEXT';
export function setCartText(text) {
  return (dispatch, getState) => {
    dispatch({ type: SET_CART_TEXT, text });
    return setCartTextWithDebounce(getState, text);
  };
}

export const SET_LEGAL_PAPER_ACCEPTANCE = 'SET_LEGAL_PAPER_ACCEPTANCE';
const PAPER_MAPPING = {
  termsAndConditions: 'accept_terms_and_conditions',
  privacyPolicy: 'accept_privacy_policy',
  airlineTermsAndConditions: 'accept_airline_terms_and_conditions',
};
export function setLegalPaperAcceptance(papers, value) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    const requestBody = {};
    papers.forEach(paper => {
      const apiParam = PAPER_MAPPING[paper];
      requestBody[apiParam] = value;
    });
    dispatch({ type: SET_LEGAL_PAPER_ACCEPTANCE, data: requestBody });
    return httpClient
      .patch(routes.api.carts.updateCart({ cartId }), requestBody)
      .then(({ data }) => data);
  };
}

export const SET_JUSTIFICATION = 'SET_JUSTIFICATION';
export function setOutOfPolicyJustification(itemId, justification, callbackParams) {
  return (dispatch, getState) => {
    dispatch({ type: SET_JUSTIFICATION, itemId, justification });
    const cartId = getState().common.cart.id;
    return httpClient
      .put(routes.api.carts.items.justification({ cartId, itemId }), {
        justification,
      })
      .then(() => dispatch(fetchCart(cartId, callbackParams)));
  };
}
