import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import routes from 'agentRoutes';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import ItemWrapper from 'sharedWebpack/ItemWrapper';
import Amount from 'sharedWebpack/Amount';
import ProductIcon from 'sharedWebpack/ProductIcon';
import ItemStateEmphasisTag from 'sharedWebpack/Orders/ItemStateEmphasisTag';
import CommentPlaceholder from 'sharedWebpack/Placeholder/CommentPlaceholder';
import OutOfPolicyJustificationEdit from '../../../../shared/IBE/Cart/Items/OutOfPolicyJustificationEdit';
import { TYPE_FLIGHT } from '../../../../shared/helpers/itemTypes';

const ItemsTab = ({ orderId }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, ''),
  });

  const { tab } = query;

  const fetchItems = apiToken => {
    httpClient
      .get(
        routes.public.orders.items({
          id: orderId,
        }),
        {
          cancelToken: apiToken,
        },
      )
      .then(({ data }) => {
        setItems(data.items);
        setIsLoading(false);
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        throw error;
      });
  };

  const showItem = () => {
    if (items.length > 0) {
      return items.map(item => (
        <Card key={item.id} version="v2" size="full" className="items-tab__container">
          <div className="items-tab__header">
            <div className="items-tab-title__container">
              <ProductIcon
                productType={item.journeyElementType || item.productType}
                showBGColor={true}
              />
              <span className="items-tab-title">{item.title}</span>
            </div>
            <div
              className={ClassNames('items-tab__header-booking-info', {
                'items-tab__header-booking-info-flight':
                  (item.journeyElementType || item.productType) === TYPE_FLIGHT,
              })}
            >
              <div className="col-grid col-bleed direction-row align-center">
                {!item.productId && item.status && (
                  <div className="items-tab__header-booking-info-status">
                    <ItemStateEmphasisTag status={item.status} size="tiny" />
                  </div>
                )}
                <Amount
                  className="items-tab__value"
                  currency={item.currency}
                  value={item.grossAmount}
                />
              </div>
            </div>
          </div>
          <div>
            {(item.journeyElementId || item.productId) && (
              <div className="items-tab__item">
                <ItemWrapper
                  journeyElementId={item.journeyElementId}
                  journeyElementType={item.journeyElementType}
                  productId={item.productId}
                />
              </div>
            )}
          </div>
          {item.outOfPolicyJustification && (
            <OutOfPolicyJustificationEdit
              itemId={item.id}
              justificationText={item.outOfPolicyJustification}
              title={I18n.t('components.ibe.out_of_policy_reason.message')}
            />
          )}
        </Card>
      ));
    }
    return (
      <Card
        className="travel-plan__timeline-empty"
        version="v2"
        color="tertiary"
        emptyCardImageSrc={<Icon name="tag" />}
        emptyCardText={I18n.t('admin.shared.no_result')}
      />
    );
  };

  useEffect(() => {
    const getItemsApiToken = generateSourceToken();

    fetchItems(getItemsApiToken.token);
    setQuery({ tab: 'items' }, 'replaceIn');

    return () => {
      getItemsApiToken.cancel();
    };
  }, [tab]);

  return (
    <Fragment>
      {isLoading ? (
        <CommentPlaceholder
          numberOfCards={2}
          numberOfRows={1}
          removeContentPadding={true}
          removeBoxShadow={true}
        />
      ) : (
        showItem()
      )}
    </Fragment>
  );
};

ItemsTab.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default withQueryParamsProvider(ItemsTab);
