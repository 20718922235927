import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { SidePanel, Link, EmphasisTag } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';
import RclLink from '@wtag/rcl-link';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import IconButton from '@wtag/rcl-icon-button';
import { hot } from 'react-hot-loader';
import HumanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import AirportChangeIndicator from 'sharedWebpack/AirportChangeIndicator';
import StopoverSidePanel from 'sharedWebpack/IBE/SearchDetails/flight/StopoverSidePanel';
import TripSegmentSidePanel from 'sharedWebpack/IBE/SearchDetails/flight/TripSegmentSidePanel';
import FlightSeatCountTag from '../../IBE/SearchResult/flight/FlightSeatCountTag';
import JourneyPath from './jounryPath';
import AirportInformations from './AirportInformations';
import BaggageLabel from '../../IBE/SearchResult/flight/BaggageLabel';
import FlexiblePaymentTag from '../../FlexiblePaymentTag';
import getFlightStopsCountLabel from '../../helpers/calculateStopoverStops';
import getTransitType from '../../helpers/getTransitType';
import './style.scss';

const Trip = ({
  trip,
  index,
  flightServices,
  showFlightSeatsStatus,
  showMinimalInfo,
  totalTravelerCount,
  payLater,
  laymanMode,
}) => {
  const [query, setQuery] = useQueryParams({
    openedSidePanel: withDefault(StringParam, ''),
  });

  const eTicketSidePanelVisible = query.openedSidePanel === `eticket-${trip.id}`;
  const seatSidePanelVisible = query.openedSidePanel === `seat-${trip.id}`;
  const wifiSidePanelVisible = query.openedSidePanel === `wifi-${trip.id}`;
  const mealSidePanelVisible = query.openedSidePanel === `meal-${trip.id}`;
  const baggageSidePanelVisible = query.openedSidePanel === `baggage-${trip.id}`;
  const stopoverSidePanelVisible = query.openedSidePanel === `stopover-${trip.id}`;
  const segmentSidePanelVisible = query.openedSidePanel === `segment-${trip.id}`;
  const segments = (trip && trip.segments) || [];
  const stopovers = (trip && trip.stopovers) || [];

  const stopoversWithAirportChanges = stopovers
    .filter(stopover => stopover.airportChange)
    .map(stopover => stopover.airportChange);

  const showMarketingCarriersList = Array.from(
    new Set(
      [...new Set(segments.reduce((a, c) => [...a, c.marketingAirlineInfo], []))].map(
        JSON.stringify,
      ),
    ),
  ).map(JSON.parse);

  const showSegmentSidePanel = () => {
    setQuery({ openedSidePanel: `segment-${trip.id}` }, 'replaceIn');
  };

  const showStopoverSidePanel = () => {
    setQuery({ openedSidePanel: `stopover-${trip.id}` }, 'replaceIn');
  };

  const showEticketSidePanel = () => {
    setQuery({ openedSidePanel: `eticket-${trip.id}` }, 'replaceIn');
  };

  const showSeatSidePanel = () => {
    setQuery({ openedSidePanel: `seat-${trip.id}` }, 'replaceIn');
  };

  const showWifiSidePanel = () => {
    setQuery({ openedSidePanel: `wifi-${trip.id}` }, 'replaceIn');
  };

  const showMealSidePanel = () => {
    setQuery({ openedSidePanel: `meal-${trip.id}` }, 'replaceIn');
  };

  const showBaggageSidePanel = () => {
    setQuery({ openedSidePanel: `baggage-${trip.id}` }, 'replaceIn');
  };

  const hideSidePanel = () => {
    setQuery({ openedSidePanel: '' }, 'replaceIn');
  };

  const findAirportInformation = code => trip.airports.find(info => info && info.code === code);
  const findAirline = code => {
    trip.airlines.find(airline => airline && airline.code === code);
  };

  const tripTransitDurationInMinutes = trip.stopovers
    .map(stopover => stopover.transitDurationsInMinutes)
    .reduce((a, b) => a + b, 0);

  const maxTransitDuration = Math.max(
    ...stopovers.map(stopover => stopover.transitDurationsInMinutes),
  );

  const segmentWithNumber = (segment, segmentIndex) =>
    I18n.t('components.flight.segment_details', {
      number: String(segmentIndex + 1),
      origin_code: segment.originCode,
      destination_code: segment.destinationCode,
    });

  const tripWithNumber = () =>
    I18n.t('components.flight.trip_number', {
      number: String(index + 1),
    });

  const eticketsPresent = () => segments.some(segment => segment.tickets.length > 0);

  const segmentEtickets = segment =>
    segment.tickets.map(ticket => {
      const ticketTravler = ticket.traveler || {};
      return (
        <Card key={ticket.number} version="v2">
          <div className="col-grid col-bleed direction-row align-center">
            <Avatar
              firstName={ticketTravler.firstName}
              lastName={ticketTravler.lastName}
              src={ticketTravler.profilePictureUrl}
              size="tiny"
            />
            <div className="flight-item-trip-eticket-seats__name">{ticketTravler.name}</div>
          </div>
          <div className="flight-item-trip-eticket-seats__number">{ticket.number}</div>
        </Card>
      );
    });

  const etickets = () =>
    segments.map((segment, segmentIndex) => {
      if (segment.tickets.length > 0) {
        return (
          <div className="flight-item-trip-eticket-seats">
            <div className="flight-item-trip-eticket-seats__segment">
              {segmentWithNumber(segment, segmentIndex)}
            </div>
            {segmentEtickets(segment)}
          </div>
        );
      }
      return null;
    });

  const seatsPresent = () => segments.some(segment => segment.seats.length > 0);

  const segmentSeat = segment =>
    segment.seats.map(seat => (
      <React.Fragment>
        {seat.traveler && (
          <Card key={seat.id} version="v2">
            <div className="col-grid col-bleed direction-row align-center">
              <Avatar
                firstName={seat.traveler.firstName}
                lastName={seat.traveler.lastName}
                src={seat.traveler.profilePictureUrl}
                size="tiny"
              />
              <div className="flight-item-trip-eticket-seats__name">{seat.traveler.name}</div>
            </div>
            <div className="flight-item-trip-eticket-seats__number">
              {seat.row}
              {seat.seat}
            </div>
          </Card>
        )}
        {!seat.traveler && (
          <Card key={seat.id} version="v2">
            {seat.row}
            {seat.seat}
          </Card>
        )}
      </React.Fragment>
    ));

  const seats = () =>
    segments.map((segment, segmentIndex) => {
      if (segment.seats.length > 0) {
        return (
          <div className="flight-item-trip-eticket-seats">
            <div className="flight-item-trip-eticket-seats__segment">
              {segmentWithNumber(segment, segmentIndex)}
            </div>
            {segmentSeat(segment)}
          </div>
        );
      }
      return null;
    });

  const servicesPresent = () =>
    flightServices.length > 0 ||
    trip.services.length > 0 ||
    segments.some(segment => segment.services.length > 0);

  const serviceCard = service => (
    <div className="flight-item-trip-services__description">{service.description}</div>
  );

  const services = () => {
    let itemLevelServices = null;

    if (flightServices.length > 0) {
      itemLevelServices = (
        <div className="flight-item-trip-services">
          {flightServices.map(service => serviceCard(service))}
        </div>
      );
    }
    let tripLevelServices = null;
    if (trip.services.length > 0) {
      tripLevelServices = (
        <div className="flight-item-trip-services">
          {trip.services.map(service => serviceCard(service))}
        </div>
      );
    }

    const segmentLevelServices = segments.map((segment, segmentIndex) => {
      if (segment.services.length > 0) {
        return (
          <div className="flight-item-trip-services">
            <div className="flight-item-trip-services__title">
              {segmentWithNumber(segment, segmentIndex)}
            </div>
            {segment.services.map(service => serviceCard(service))}
          </div>
        );
      }
      return null;
    });

    return (
      <React.Fragment>
        <div className="flight-item-trip-services__title">{tripWithNumber()}</div>
        {itemLevelServices}
        {tripLevelServices}
        {segmentLevelServices}
      </React.Fragment>
    );
  };
  const wifiPresent = () => segments.some(segment => segment.wifiAvailable);
  const wifi = () =>
    segments.map((segment, segmentIndex) => (
      <div className="flight-item-trip-wifi">
        <div className="flight-item-trip-wifi__segments">
          {segmentWithNumber(segment, segmentIndex)}
        </div>

        {segment.wifiAvailable ? (
          <div className="flight-item-trip-wifi-available">
            <Icon name="wifi" showBGColor={true} size="tiny" />
            {I18n.t('components.flight.wifi.included')}
          </div>
        ) : (
          <div className="flight-item-trip-wifi-available">
            <Icon name="noWifi" size="tiny" showBGColor={true} color="danger" />
            {I18n.t('components.flight.wifi.not_included')}
          </div>
        )}
      </div>
    ));

  const airportChangeText =
    stopoversWithAirportChanges &&
    stopoversWithAirportChanges.map(airportItem => airportItem.arrivalAirportCode).join(', ');
  const transitGapCount = getFlightStopsCountLabel(stopovers);

  const airports = useMemo(() => {
    const stopoverAirports = {};

    stopovers.forEach(stopover => {
      stopoverAirports[stopover.airportCode] = findAirportInformation(stopover.airportCode);
    });

    return stopoverAirports;
  }, [stopovers]);

  return (
    <div className="flight-item-trip">
      <div className="col-12 flight-item-trip-number">
        <div className="grid">
          <div
            className={classNames({
              'col-xs-4 col-md-8': showFlightSeatsStatus,
              'col-12': !showFlightSeatsStatus,
            })}
          >
            {tripWithNumber()}
          </div>
          {showFlightSeatsStatus && (
            <FlightSeatCountTag
              className="col-xs-8 col-md-4 col-grid direction-row-reverse"
              seatCount={trip.availableSeatCount}
              travellerCount={totalTravelerCount}
            />
          )}
        </div>
      </div>
      <div className="col-12 col-grid col-bleed-y align-center direction-row justify-space-between flight-item-trip-header wrap">
        <div className="col-sm-7 col-bleed">
          <div className="flight__airline-logo">
            {showMarketingCarriersList &&
              showMarketingCarriersList.map(carrier => (
                <div>
                  <img src={carrier.logo} alt={carrier.name} />
                </div>
              ))}
          </div>
        </div>
        {!showMinimalInfo && (
          <div className="col-sm-5 col-bleed flight-item-trip-header__service-status">
            <Link
              className="flight-item-trip-header__service-status__eticket-link"
              onClick={showEticketSidePanel}
              modifier="default"
              size="tiny"
              disabled={!eticketsPresent()}
            >
              <Icon name="lounge" /> {I18n.t('components.flight.eticket.title')}
            </Link>
            <SidePanel
              title={I18n.t('components.flight.eticket.numbers')}
              body={etickets()}
              isOpened={eTicketSidePanelVisible}
              onClick={() => hideSidePanel()}
            />
            <IconButton
              icon={
                seatsPresent() ? (
                  <Icon name="seat" showBGColor={true} color="success" />
                ) : (
                  <Icon name="seat" showBGColor={true} color="tertiary" />
                )
              }
              label={I18n.t('components.flight.seat.label')}
              size="large"
              onClick={showSeatSidePanel}
              disabled={!seatsPresent()}
              color={seatsPresent() ? 'success' : 'tertiary'}
              isIconOnly={true}
            />
            <SidePanel
              title={I18n.t('components.flight.seat.title')}
              body={seats()}
              isOpened={seatSidePanelVisible}
              onClick={() => hideSidePanel()}
            />

            <IconButton
              icon={
                wifiPresent() ? (
                  <Icon name="wifi" showBGColor={true} color="success" />
                ) : (
                  <Icon name="wifi" showBGColor={true} color="tertiary" />
                )
              }
              label={I18n.t('components.flight.wifi.label')}
              size="large"
              onClick={showWifiSidePanel}
              disabled={!wifiPresent()}
              color={wifiPresent() ? 'success' : 'tertiary'}
              isIconOnly={true}
            />
            <SidePanel
              title={I18n.t('components.flight.wifi.title')}
              body={wifi()}
              isOpened={wifiSidePanelVisible}
              onClick={() => hideSidePanel()}
            />

            <IconButton
              icon={<Icon name="meal" showBGColor={true} color="tertiary" />}
              label={I18n.t('components.flight.meal.label')}
              size="large"
              onClick={showMealSidePanel}
              disabled={true}
              color="tertiary"
              isIconOnly={true}
            />
            <SidePanel
              title={I18n.t('components.flight.meal.title')}
              body={<React.Fragment>This is Eticket</React.Fragment>}
              isOpened={mealSidePanelVisible}
              onClick={() => hideSidePanel()}
            />

            <IconButton
              icon={
                servicesPresent() ? (
                  <Icon name="baggage" showBGColor={true} color="success" />
                ) : (
                  <Icon name="baggage" showBGColor={true} color="tertiary" />
                )
              }
              label={I18n.t('components.flight.baggage.label')}
              size="large"
              onClick={showBaggageSidePanel}
              disabled={!servicesPresent()}
              color={servicesPresent() ? 'success' : 'tertiary'}
              isIconOnly={true}
            />
            <SidePanel
              title={I18n.t('components.flight.baggage.title')}
              body={services()}
              isOpened={baggageSidePanelVisible}
              onClick={() => hideSidePanel()}
            />
          </div>
        )}
      </div>

      <div className="col-12 col-grid direction-row justify-space-between flight-item-trip-details">
        <div className="col-4 order-sm-1 col-bleed col-grid justify-center ">
          <AirportInformations
            airport={findAirportInformation(trip.originCode)}
            date={trip.startsAtInUserTz}
            timezone="EST"
            terminal={trip.originTerminal}
          />
        </div>

        <div className="col-sm-4 order-sm-2 col-bleed col-grid flight-item-trip-journey-info justify-center align-center">
          <div>
            <div className="flight-item-trip-journey-info__duration">
              {I18n.t('components.flight.duration')}:{' '}
              <span>{HumanReadableDuration(trip.durationInMinutes)}</span>
            </div>
            {stopovers.length > 0 && (
              <div className="flight-item-trip-journey-info__stopover">
                <RclLink onClick={showStopoverSidePanel} modifier="tertiary" size="tiny">
                  {transitGapCount}
                </RclLink>
                <StopoverSidePanel
                  stopovers={stopovers}
                  airports={airports}
                  findAirport={findAirportInformation}
                  showStopovers={stopoverSidePanelVisible}
                  onClick={() => hideSidePanel()}
                />
              </div>
            )}
            <div className="flight-item-trip-journey-info__journey-path">
              <JourneyPath stopovers={stopovers} />
            </div>
            {segments.length > 1 && (
              <React.Fragment>
                <div className="flight-item-trip-journey-info__stopover-codes">
                  {trip.stopovers.map(stopOver => stopOver.airportCode).join(', ')} |{' '}
                  {HumanReadableDuration(tripTransitDurationInMinutes)}
                </div>
                <div className="flight-item-trip-journey-info__transit">
                  {getTransitType(maxTransitDuration)}
                </div>
                {!!airportChangeText && (
                  <AirportChangeIndicator
                    className="flight-item-trip-journey-info__airport-change"
                    changedAirportCodeText={airportChangeText}
                    onClick={showStopoverSidePanel}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="col-4 col-grid order-sm-3 col-bleed justify-center">
          <div className="flight-item-trip-airport-info">
            <AirportInformations
              airport={findAirportInformation(trip.destinationCode)}
              date={trip.endsAtInUserTz}
              timezone="EST"
              terminal={trip.destinationTerminal}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-bleed-y">
        <hr />
      </div>
      <div className="col-12 col-bleed-y col-grid align-center direction-row justify-space-between flight-item-trip-airlines wrap">
        <div className="col-sm-5 col-bleed flight-item-trip-airlines-segments">
          {segments.map(segment => (
            <EmphasisTag
              className="flight-item-trip-airlines-segments--emphasis-tag"
              text={segment.marketingCarrierCode + segment.marketingFlightNumber}
              size="tiny"
              type="neutral"
              icon={<Icon name="aeroplane" />}
            />
          ))}
          <Link onClick={showSegmentSidePanel} modifier="default" size="tiny">
            <span className="flight-item-trip-airlines-segments-itenary">
              {I18n.t('admin.components.orders.items_tab.placeholder.show_itinerary')}
            </span>
          </Link>
          <TripSegmentSidePanel
            title={I18n.t('components.flight_info.header')}
            group={trip}
            findAirport={findAirportInformation}
            findAirline={findAirline}
            showTripSegment={segmentSidePanelVisible}
            onClick={() => hideSidePanel()}
          />
        </div>
        {!showMinimalInfo && (
          <div className="col-sm-7 col-bleed flight-item-trip__meta-info">
            <EmphasisTag
              text={
                <BaggageLabel
                  baggageInfo={segments.map(segment => segment.baggagePiecesIncluded)}
                />
              }
              size="tiny"
              icon={<Icon name="baggage" />}
            />
            {trip.cabinClass && (
              <EmphasisTag text={I18n.t(trip.cabinClass, { scope: 'cabin_classes' })} size="tiny" />
            )}
            {trip.bookingClasses && trip.bookingClasses.length > 0 && (
              <EmphasisTag text={trip.bookingClasses.join(', ')} size="tiny" />
            )}
            {trip.fareType && <EmphasisTag text={trip.fareType} size="tiny" type="success" />}
            {payLater && !laymanMode && <FlexiblePaymentTag />}
          </div>
        )}
      </div>
    </div>
  );
};

Trip.defaultProps = {
  showMinimalInfo: false,
  showFlightSeatsStatus: false,
  payLater: false,
};

Trip.propTypes = {
  trip: PropTypes.shape({
    id: PropTypes.string,
    cabinClass: PropTypes.string,
    bookingClasses: PropTypes.arrayOf(PropTypes.string),
    fareType: PropTypes.string,
    originCode: PropTypes.string,
    startsAtInUserTz: PropTypes.string,
    endsAtInUserTz: PropTypes.string,
    originTerminal: PropTypes.string,
    durationInMinutes: PropTypes.string,
    destinationCode: PropTypes.string,
    destinationTerminal: PropTypes.string,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
      }),
    ),
    airlines: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
      }),
    ),
    airports: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
      }),
    ),
    stopovers: PropTypes.arrayOf(
      PropTypes.shape({
        airport_code: PropTypes.string,
      }),
    ),
    segments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    availableSeatCount: PropTypes.number,
  }).isRequired,
  flightServices: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    }),
  ).isRequired,
  index: PropTypes.number.isRequired,
  showMinimalInfo: PropTypes.bool,
  showFlightSeatsStatus: PropTypes.bool,
  totalTravelerCount: PropTypes.number.isRequired,
  payLater: PropTypes.bool,
  laymanMode: PropTypes.bool.isRequired,
};

export default hot(module)(withQueryParamsProvider(Trip));
