import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Tooltip from '@wtag/rcl-tooltip';
import { EmphasisTag, I18nText, Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Amount from 'sharedWebpack/Amount';
import DateTime from 'sharedWebpack/DateTime';
import { Table, TableBody, TableData, TableHeader, TableHead, TableRow } from '@wtag/rcl-table';
import EmptyTableContent from 'sharedWebpack/EmptyTableContent';
import TablePlaceHolder from '../../../shared/Placeholder/TablePlaceholder';
import '../../Organizations/styles.scss';

const InvoiceRow = ({ invoice, displayColumns }) => (
  <TableRow key={invoice.number}>
    {displayColumns.id && (
      <TableData>
        <div className="organization-invoices-tab__id-tag">
          <EmphasisTag number={invoice.number} size="small" radius="oval" />
        </div>
      </TableData>
    )}
    {displayColumns.orderId && (
      <TableData align="left" className="organization-invoices-tab__order-tag">
        <EmphasisTag number={invoice.orderId} size="small" radius="oval" />
        <div className="organization-invoices-tab__order-title">{invoice.title}</div>
      </TableData>
    )}
    {displayColumns.travellers && (
      <TableData>
        <Link href={invoice.person.url} type="icon-button">
          <Avatar
            size="tiny"
            firstName={invoice.person.firstName}
            lastName={invoice.person.lastName}
            src={invoice.person.avatarUrl}
            tooltip={<Tooltip content={invoice.person.name} type="inverse" />}
          />
        </Link>
      </TableData>
    )}
    {displayColumns.state && (
      <TableData>
        <EmphasisTag
          text={invoice.state}
          size="small"
          radius="oval"
          type={invoice.state === 'Cancelled' ? 'disabled' : 'default'}
        />
      </TableData>
    )}
    {displayColumns.invoiceDate && (
      <TableData>
        <DateTime dateTime={invoice.invoiceDate} format="short" />
      </TableData>
    )}
    {displayColumns.dueDate && (
      <TableData>
        <DateTime dateTime={invoice.dueDate} format="short" />
      </TableData>
    )}
    {displayColumns.amount && (
      <TableData>
        <div className="organization-invoices-tab__amount-tag">
          <EmphasisTag
            text={<Amount currency={invoice.currency} value={invoice.grossAmount} />}
            size="small"
            radius="oval"
            type="important"
          />
        </div>
      </TableData>
    )}
    {displayColumns.actions && (
      <TableData align="center">
        {invoice.urls.view && (
          <Link
            href={invoice.urls.view}
            labelHint={I18n.t('admin.components.invoices.list.table.view_invoice')}
            type="icon-button"
            modifier="tertiary"
          >
            <Icon name="show" size="small" />
          </Link>
        )}
        {invoice.urls.pdf && (
          <Link
            href={invoice.urls.pdf}
            labelHint={I18n.t('admin.components.invoices.list.table.download_pdf')}
            type="icon-button"
            modifier="tertiary"
          >
            <Icon name="pdf" size="small" />
          </Link>
        )}
      </TableData>
    )}
  </TableRow>
);
InvoiceRow.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    orderId: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    person: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    invoiceDate: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    grossAmount: PropTypes.string.isRequired,
    urls: PropTypes.shape({
      view: PropTypes.string.isRequired,
      pdf: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  displayColumns: PropTypes.shape({
    id: PropTypes.bool,
    orderId: PropTypes.bool,
    travellers: PropTypes.bool,
    invoiceDate: PropTypes.bool,
    dueDate: PropTypes.bool,
    amount: PropTypes.bool,
    state: PropTypes.bool,
    actions: PropTypes.bool,
  }).isRequired,
};

const InvoicesTable = ({ invoices, displayColumns, isLoading }) =>
  !isLoading && !invoices.length ? (
    <EmptyTableContent />
  ) : (
    <Table fullWidth={true}>
      <TableHead>
        <TableRow>
          {displayColumns.id && (
            <TableHeader>
              {I18n.t('admin.components.organizations.overview.tabs.invoices.table.invoice_id')}
            </TableHeader>
          )}
          {displayColumns.orderId && (
            <TableHeader>
              {I18n.t('admin.components.organizations.overview.tabs.invoices.table.order_id')}
            </TableHeader>
          )}
          {displayColumns.travellers && (
            <TableHeader>
              {I18n.t('admin.components.organizations.overview.tabs.invoices.table.traveler')}
            </TableHeader>
          )}
          {displayColumns.state && (
            <TableHeader>
              {I18n.t('admin.components.organizations.overview.tabs.invoices.table.state')}
            </TableHeader>
          )}
          {displayColumns.invoiceDate && (
            <TableHeader>
              {I18n.t('admin.components.organizations.overview.tabs.invoices.table.invoice_date')}
            </TableHeader>
          )}
          {displayColumns.dueDate && (
            <TableHeader>
              {I18n.t('admin.components.organizations.overview.tabs.invoices.table.due_date')}
            </TableHeader>
          )}
          {displayColumns.amount && (
            <TableHeader>
              {I18n.t('admin.components.organizations.overview.tabs.invoices.table.gross_amount')}
            </TableHeader>
          )}
          {displayColumns.actions && (
            <TableHeader align="center">
              <I18nText id="shared.action" returnStringOnly={true} />
            </TableHeader>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <TablePlaceHolder
            numberOfColumn={Object.values(displayColumns).filter(data => data).length}
            numberOfRows={10}
            withoutRclTable={true}
          />
        ) : (
          invoices.map(invoice => (
            <InvoiceRow key={invoice.number} invoice={invoice} displayColumns={displayColumns} />
          ))
        )}
      </TableBody>
    </Table>
  );

InvoicesTable.defaultProps = {
  displayColumns: {
    id: true,
    orderId: true,
    travellers: true,
    invoiceDate: true,
    dueDate: true,
    amount: true,
    state: false,
    actions: true,
  },
};

InvoicesTable.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  displayColumns: PropTypes.shape({
    id: PropTypes.bool,
    orderId: PropTypes.bool,
    travellers: PropTypes.bool,
    invoiceDate: PropTypes.bool,
    dueDate: PropTypes.bool,
    amount: PropTypes.bool,
    state: PropTypes.bool,
    actions: PropTypes.bool,
  }),
  isLoading: PropTypes.bool.isRequired,
};

export default InvoicesTable;
