import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@wtag/rcl-popover';
import Link from '@wtag/rcl-link';
import { I18nText } from '@wtag/react-comp-lib';
import FacilityItemsList from '../FacilityItemsList';
import FacilityItemLink from '../FacilityItemLink';
import FacilityTypeList from '../FacilityTypeList';
import GenericFacility from '../GenericFacility';
import { FacilitiesItemShape, OtherFacilitiesItemShape } from '../shapes/facilities';

const FacilityItemLinks = ({ className, facilities, otherFacilities, onShowSidePanel }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const hasFacilities = !!facilities;
  const hasOtherFacilities = otherFacilities && otherFacilities.length > 0;

  if (!hasFacilities && !hasOtherFacilities) {
    return null;
  }

  const toggleFacilitiesVisibility = () => {
    setIsPopoverVisible(prevSetIsPopoverVisible => !prevSetIsPopoverVisible);
  };

  return (
    <div className={className}>
      {hasFacilities && (
        <div className="car__information-label-item">
          <Popover
            className="car__facility-items-list--popover"
            isVisible={isPopoverVisible}
            content={
              <FacilityItemsList className="car__facility-items-list" facilities={facilities} />
            }
            onOutsideClick={() => setIsPopoverVisible(false)}
            size="large"
            direction="bottom-left"
          >
            <Link
              className="car__other-facility-link"
              modifier="tertiary"
              size="tiny"
              onClick={toggleFacilitiesVisibility}
            >
              <I18nText id="ibe.search_results.car.label.attributes" />
            </Link>
          </Popover>
        </div>
      )}
      {hasOtherFacilities && (
        <div className="car__information-label-item">
          <FacilityItemLink
            className="d-flex align-center column-gap-4"
            label={<I18nText id="ibe.search_results.car.label.facilities" />}
            sidePanelTitle={<I18nText id="ibe.search_results.car.label.facilities" />}
            sidePanelBody={
              <FacilityTypeList list={otherFacilities} listItemComponent={GenericFacility} />
            }
            onClick={onShowSidePanel}
          />
        </div>
      )}
    </div>
  );
};

FacilityItemLinks.defaultProps = {
  className: null,
  onShowSidePanel: () => {},
};

FacilityItemLinks.propTypes = {
  className: PropTypes.string,
  facilities: PropTypes.arrayOf(FacilitiesItemShape).isRequired,
  otherFacilities: PropTypes.arrayOf(OtherFacilitiesItemShape).isRequired,
  onShowSidePanel: PropTypes.func,
};

export default FacilityItemLinks;
