import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import SanitizedHTML from '../../shared/SanitizedHTML';
import mobileAppBgSrc from '../../../assets/images/mobile_app_bg.png';
import appStoreImageSrc from '../../../assets/images/app_store.png';
import googlePlayImgSrc from '../../../assets/images/google_play.png';

const MobileApp = props => {
  const { qrCode, tenantLogo, tenantName } = props;

  return (
    <div className="mobile-app">
      <div className="grid">
        <div className="col-grid col-md-6 col-lg-6 col-xlg-7 hidden-xxs hidden-xs hidden-sm col-bleed-y mobile-app__container mobile-app__container--left">
          <img
            className="flex-img col-12 col-bleed"
            src={mobileAppBgSrc}
            alt="welltravel-mobile-app-features"
          />
        </div>
        <div className="col-grid col-sm-12 col-md-6 col-lg-6 col-xlg-4 col-bleed-y direction-row wrap align-content-space-between mobile-app__container mobile-app__container--right">
          <div className="mobile-app__container-header">
            <img
              className="mobile-app__container-header-tenant-img"
              src={tenantLogo}
              alt="tenant"
            />
            <EmphasisTag size="small" type="neutral" text={I18n.t('public.qr_code.tag')} />
          </div>
          <div className="mobile-app__container-qr-code">
            <SanitizedHTML html={qrCode} />
          </div>
          <div className="mobile-app__container-moto">
            {I18n.t('public.qr_code.title', {
              tenantName,
            })}
          </div>
          <ul className="mobile-app__container-installation">
            <li className="mobile-app__container-installation-instruction">
              {I18n.t('public.qr_code.instruction.open_app')}
            </li>
            <li className="mobile-app__container-installation-instruction">
              {I18n.t('public.qr_code.instruction.navigate_to_qr')}
            </li>
            <li className="mobile-app__container-installation-instruction">
              {I18n.t('public.qr_code.instruction.point_this_screen')}
            </li>
          </ul>
          <div className="mobile-app__container-app">
            <a
              href="https://play.google.com/store/apps/details?id=com.welltravel.android.welltravel"
              className="mobile-app__container-app-link"
            >
              <img
                className="flex-img"
                src={googlePlayImgSrc}
                alt="Welltravel-app-google-play"
                target="_blank"
              />
            </a>
            <a
              href="https://itunes.apple.com/us/app/welltravel/id1403762062"
              className="mobile-app__container-app-link"
            >
              <img
                className="flex-img"
                src={appStoreImageSrc}
                alt="Welltravel-app"
                target="_blank"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

MobileApp.defaultProps = {
  tenantLogo: null,
};

MobileApp.propTypes = {
  qrCode: PropTypes.string.isRequired,
  tenantLogo: PropTypes.string,
  tenantName: PropTypes.string.isRequired,
};

export default MobileApp;
