import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import Badge from '@wtag/rcl-badge';
import { RTabsWithURL } from '@wtag/rcl-rtabs';
import LastSearchesContainer from '../../components/LastSearchesContainer';
import SharedCarts from '../../components/SharedCarts';
import history from '../../lib/history';
import './styles.scss';

const STATIC_TAB = 3;
const SearchFormTab = ({
  tabs,
  currentTab,
  setCurrentTab,
  openCorrectForm,
  formType,
  searchAccounts,
  setShowBottomComponents,
  laymanMode,
  callbackParams,
  advancedForm,
  cartId,
  cartItemsCount,
  iframeMode,
  travelerLoggedIn,
  setIsMultiCitySelected,
  setIsFromLastSearch,
}) => {
  const setSelectedTabKeyOnClick = tabIndex => {
    setCurrentTab(tabIndex);
    openCorrectForm(tabIndex);
  };

  const showSharedCarts = () => {
    if (laymanMode) {
      return !iframeMode && travelerLoggedIn;
    }

    return true;
  };

  const onCartClick = () => {
    setShowBottomComponents(false);
    history.push(`/carts/${cartId}`);
  };

  return (
    <div
      className={classNames('search-form-tab', {
        'search-form-tab__travelapp': laymanMode,
      })}
    >
      <RTabsWithURL
        items={tabs}
        selectedTabKey={currentTab}
        onTabChange={setSelectedTabKeyOnClick}
        currentTab={currentTab}
        showInkBar={false}
      />
      <div
        className={classNames('search-form-tab__last-search d-flex', {
          'search-form-tab__last-search--travelapp': laymanMode,
          'search-form-tab__last-search--advanced-form': advancedForm,
        })}
      >
        <LastSearchesContainer
          type={formType}
          searchAccounts={searchAccounts}
          setIsMultiCitySelected={setIsMultiCitySelected}
          setIsFromLastSearch={setIsFromLastSearch}
        />
        {showSharedCarts() && (
          <SharedCarts
            setShowBottomComponents={setShowBottomComponents}
            laymanMode={laymanMode}
            callbackParams={callbackParams}
          />
        )}
        <div
          className={classNames('search-form-tab__border', {
            'search-form-tab__border-vertical': laymanMode,
            'search-form-tab__border-vertical--static':
              currentTab === STATIC_TAB && !showSharedCarts(),
            'search-form-tab__border--static': laymanMode && currentTab === STATIC_TAB,
          })}
        />
        <IconButton
          className="search-form-tab__icon-button"
          icon={
            <Fragment>
              <Icon name="shoppingCart" size="normal" />
              {cartId && cartItemsCount > 0 && (
                <Badge
                  label={cartItemsCount}
                  size="small"
                  type="active"
                  classNameForBadge="search__cart-button"
                />
              )}
            </Fragment>
          }
          label={I18n.t('components.ibe.search_form.label.cart')}
          onClick={onCartClick}
          disabled={!cartId}
          version="v2"
        />
      </div>
    </div>
  );
};

SearchFormTab.defaultProps = {
  cartId: null,
  searchAccounts: {},
  setIsMultiCitySelected: () => {},
  setIsFromLastSearch: false,
};

SearchFormTab.propTypes = {
  tabs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  currentTab: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  openCorrectForm: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
  searchAccounts: PropTypes.shape({}),
  setShowBottomComponents: PropTypes.func.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape({}).isRequired,
  iframeMode: PropTypes.bool.isRequired,
  advancedForm: PropTypes.bool.isRequired,
  cartId: PropTypes.string,
  travelerLoggedIn: PropTypes.bool.isRequired,
  cartItemsCount: PropTypes.number.isRequired,
  setIsMultiCitySelected: PropTypes.func,
  setIsFromLastSearch: PropTypes.bool,
};

export default SearchFormTab;
