import { combineReducers } from 'redux';

import {
  FLIGHT_SEARCH_FETCHED,
  CREATE_FLIGHT_SEARCH,
  FETCHING_FLIGHT_SEARCHED,
} from '../../actions/flight';

import aircraftTypes from './aircraftTypes';
import airlineInformations from './airlineInformations';
import currentExchangeQuote from './currentExchangeQuote';
import defaultFlightSearchParams from './defaultFlightSearchParams';
import exchangeQuoteStatus from './exchangeQuoteStatus';
import fareBasisInfoByResultId from './fareBasisInfoByResultId';
import resultDetailsById from './resultDetailsById';
import searchFilterParamsBySearchId from './searchFilterParamsBySearchId';
import searchParamsBySearchId from './searchParamsBySearchId';
import searchStatsBySearchId from './searchStatsBySearchId';
import selectedResults from './selectedResults';
import servicesByResultId from './servicesByResultId';
import selectedFlightGroups from './selectedFlightGroups';
import matrixesBySearchId from './matrixesBySearchId';
import fetchingFlightMatrices from './fetchingFlightMatrices';
import isFetchingMatrices from './isFetchingMatrices';
import tabKeyWithSelectedFareOption from './tabKeyWithSelectedFareOption';
import flightSeatMap from './flightSeatMap';
import airlinePreference from './airlinePreference';

function searchResultGroupsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case FLIGHT_SEARCH_FETCHED:
      if (!newState[action.searchId]) {
        newState[action.searchId] = {};
      }
      newState[action.searchId][action.tripId] = action.resultGroups;
      return newState;

    case CREATE_FLIGHT_SEARCH:
      return {};

    default:
      return state;
  }
}

function searchInProgress(state = false, action) {
  switch (action.type) {
    case FETCHING_FLIGHT_SEARCHED:
      return true;
    case FLIGHT_SEARCH_FETCHED:
      return false;

    default:
      return state;
  }
}

function totalResultsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case FLIGHT_SEARCH_FETCHED:
      newState[action.searchId] = action.availableResults;
      return newState;

    default:
      return state;
  }
}

function totalResultsPerSupplier(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case FLIGHT_SEARCH_FETCHED:
      newState[action.searchId] = action.availableResultsPerSupplier;
      return newState;

    default:
      return state;
  }
}

const appReducer = combineReducers({
  aircraftTypes,
  airlineInformations,
  currentExchangeQuote,
  defaultFlightSearchParams,
  exchangeQuoteStatus,
  fareBasisInfoByResultId,
  matrixesBySearchId,
  fetchingFlightMatrices,
  isFetchingMatrices,
  flightSeatMap,
  tabKeyWithSelectedFareOption,
  resultDetailsById,
  searchFilterParamsBySearchId,
  searchParamsBySearchId,
  searchResultGroupsBySearchId,
  searchInProgress,
  searchStatsBySearchId,
  selectedFlightGroups,
  selectedResults,
  servicesByResultId,
  totalResultsBySearchId,
  totalResultsPerSupplier,
  airlinePreference,
});

export default appReducer;
