import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import { QuantitySelector, Link } from '@wtag/react-comp-lib';
import { Menu } from '@wtag/rcl-menu';
import Alert from 'sharedWebpack/Alert';
import SelectBox from '@wtag/rcl-select-box';
import Card from '@wtag/rcl-card';
import DateRangePicker from '@wtag/rcl-date-range-picker';
import { itemCounter } from 'sharedWebpack/MultipleForm/multipleForm';
import LocationSearchInput from 'sharedWebpack/LocationSearchInput';
import history from '../../lib/history';
import showInvalidDateError from '../../helpers/showInvalidDateError';
import availableChannelsShape from '../../shapes/availableChannels';
import noChannelSelected from '../../lib/helpers/noChannelSelected';
import SearchAccounts from '../../components/SearchAccounts';
import searchAccountsShape from '../../shapes/searchAccounts';
import PreselectTravelers from '../PreselectTravelers';
import preparePreselectTravelers from '../PreselectTravelers/preparePreselectTravelers';
import useSearchFormContainer from '../../helpers/hooks/useSearchFormContainer';
import addOrRemoveAgeField from '../../helpers/addOrRemoveAgeField';
import MenuLabel from '../helpers/MenuLabel';
import MenuLabelWithPreSelectText from '../MenuLabelWithPreSelectText';
import usePreselectTraveler from '../../helpers/hooks/usePreselectTraveler';

const SearchForm = props => {
  const {
    fields: {
      checkIn,
      checkOut,
      address,
      latitude,
      longitude,
      numberOfRooms,
      adults,
      children,
      childrenData,
      accountSettings,
    },
    submitting,
    handleSubmit,
    cartHasItems,
    laymanMode,
    availableChannels,
    searchHotels,
    defaultSearchParams,
    cartOverrides,
    initializeForm,
    preselectTravelers,
    resetApp,
    searchForm,
    setIsCollapsed,
    formWithoutChildren,
    resetBookingAppContext,
    travelerLoggedIn,
    travelArrangerPresent,
    callbackParams,
    preselectDecision,
    updatePreselectTravelers,
  } = props;
  const MIN_NUMBER_OF_ROOMS = 1;
  const MIN_NUMBER_OF_ADULTS = 1;
  const MAX_NUMBER_OF_ADULTS = 9;
  const isNoChannelSelected = noChannelSelected(
    laymanMode,
    availableChannels,
    accountSettings,
    'hotels',
  );
  const totalNumberOfTravelers = adults.value + children.value;
  const showHotelFormContainer = useSearchFormContainer();

  const [isTravelerVisible, setIsTravelerVisible] = useState(false);
  const [isRoomVisible, setIsRoomVisible] = useState(false);
  const [isPreselectTravellerVisible, setIsPreselectTravellerVisible] = useState(false);
  const preselectTravelersLength = preselectTravelers.length;
  const loggedInAsTravelArranger = travelerLoggedIn && travelArrangerPresent;
  const loggedOutWithEmptyCartAndForm = !travelerLoggedIn && !cartHasItems && formWithoutChildren;
  const isPreSelectTravelerButtonVisible =
    (loggedInAsTravelArranger || loggedOutWithEmptyCartAndForm) && !props.forceAdvancedSearch;

  usePreselectTraveler({
    adults: adults.value,
    children: children.value,
    laymanMode,
    travelerLoggedIn,
    preselectDecision,
    preselectTravelers,
    callbackParams,
    updatePreselectTravelers,
    shouldPreselect: isPreSelectTravelerButtonVisible,
  });

  const onRoomOutSideClick = () => {
    setIsRoomVisible(false);
  };

  const onTraveleOutsideClick = () => {
    setIsTravelerVisible(isPreselectTravellerVisible);
  };

  const onRoomMenuClick = () => setIsRoomVisible(prevState => !prevState);
  const onTravellerMenuClick = () => setIsTravelerVisible(prevState => !prevState);

  const ageOptions = [...Array(18).keys()].map(number => ({
    value: number,
    label: `${number}`,
  }));

  const onChildrenChange = value => {
    const modifiedChildrenData = childrenData;
    const counterDiff = modifiedChildrenData.length - value;
    children.onChange(value);
    addOrRemoveAgeField(counterDiff, modifiedChildrenData);
  };

  const handleFormSubmission = (values, dispatch, ownProps) => {
    history.push('/hotels/searching');
    searchHotels(
      {
        ...values,
        currency: ownProps.currency,
        preselectTravelers: preparePreselectTravelers(preselectTravelers),
      },
      ownProps.callbackParams,
    );
    setIsCollapsed();
  };

  const resetToDefaultSearchParams = () => {
    initializeForm({ ...defaultSearchParams, ...cartOverrides });
  };

  const onDateRangeChange = date => {
    const { startDate, endDate } = date;
    const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : null;
    const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : null;
    checkIn.onChange(formattedStartDate);
    checkOut.onChange(formattedEndDate);
  };

  const travelersMenuError =
    (adults.touched && !adults.valid) || childrenData.some(({ age }) => !age.valid && age.touched);
  const getSelectValueByAge = age =>
    age.value ? { label: age.value, value: age.value } : { label: '0', value: 0 };

  const isQuantitySelectorDisabled = submitting || cartHasItems;

  const onResetButtonClick = () => {
    resetApp();
    resetBookingAppContext();
  };

  useEffect(() => {
    if (!searchForm) {
      resetToDefaultSearchParams();
    }
  }, [searchForm]);

  return (
    <div
      className={classNames('hotel-form__container', {
        'hotel-form__container--show': showHotelFormContainer,
      })}
    >
      <div className="d-flex col-12 col-bleed">
        <div
          className={classNames({
            'col-12 col-bleed': laymanMode,
            'col-7 hotel-form__content': !laymanMode,
          })}
        >
          <div className="search-accounts__header">
            <span>{I18n.t('components.ibe.search_form.hotel.search.title')}</span>
          </div>

          <div className="col-12 col-bleed hotel-form__specifier-container">
            <div className="search-menu__container search-menu__container--rooms">
              <Menu
                className="search-menu__container--rooms-dropdown"
                isVisible={isRoomVisible}
                size="small"
                popOverDirection="bottom-left"
                onOutsideClick={onRoomOutSideClick}
                label={
                  <MenuLabel
                    isError={numberOfRooms.touched && !numberOfRooms.valid}
                    onClick={onRoomMenuClick}
                    label={I18n.t('components.ibe.search_form.hotel.label.number_of_rooms', {
                      count: numberOfRooms.value,
                    })}
                    isVisible={isRoomVisible}
                  />
                }
              >
                <Fragment>
                  <div className="search-menu-item__container">
                    <div className="search-menu-item__specifier">
                      <div className="search-menu-item__label">
                        {I18n.t('components.ibe.search_form.hotel.label.rooms')}
                      </div>
                      <QuantitySelector {...numberOfRooms} minValue={MIN_NUMBER_OF_ROOMS} />
                    </div>
                  </div>
                  <Alert
                    className="search-menu-item__error"
                    hideClose={true}
                    isVisible={numberOfRooms.touched && numberOfRooms.error}
                    type="danger"
                  >
                    {numberOfRooms.error}
                  </Alert>
                </Fragment>
              </Menu>
            </div>
            <div className="search-menu__container search-menu__container--travellers search-menu__container--traveller-amount">
              <Menu
                className="search-menu__container--traveller-amount-dropdown"
                isVisible={isTravelerVisible}
                size="medium"
                popOverDirection="bottom-left"
                onOutsideClick={onTraveleOutsideClick}
                label={
                  <MenuLabel
                    icon={<Icon name="adult" className="search-menu__icon" />}
                    isError={travelersMenuError}
                    onClick={onTravellerMenuClick}
                    label={
                      <MenuLabelWithPreSelectText
                        totalNumberOfTravelers={totalNumberOfTravelers}
                        preselectTravelersLength={preselectTravelersLength}
                      />
                    }
                    isVisible={isTravelerVisible}
                  />
                }
              >
                <Fragment>
                  <div className="search-menu-item__container">
                    <div className="hotel-form__preselect-header">
                      <div className="hotel-form__traveller-title preselect_button">
                        {I18n.t('admin.components.organizations.overview.tabs.traveller.title')}
                      </div>
                      {isPreSelectTravelerButtonVisible && (
                        <PreselectTravelers
                          type="hotel"
                          setIsPreselectTravellerVisible={setIsPreselectTravellerVisible}
                          onAdultChange={adults.onChange}
                          onChildrenChange={children.onChange}
                          selectedTotalNumberOfTravelers={totalNumberOfTravelers}
                        />
                      )}
                    </div>
                    <div className="search-menu-item__specifier search-menu-item__specifier--add-padding-bottom">
                      <div className="search-menu-item__label">
                        {I18n.t('components.ibe.search_form.hotel.label.adults')}

                        <div className="search-hints">
                          {I18n.t('components.ibe.search_form.hotel.label.adults_age_range')}
                        </div>
                      </div>
                      <QuantitySelector
                        {...adults}
                        disabled={isQuantitySelectorDisabled}
                        minValue={MIN_NUMBER_OF_ADULTS}
                        maxValue={MAX_NUMBER_OF_ADULTS}
                      />
                    </div>
                    <div className="search-menu-item__specifier">
                      <div className="search-menu-item__label">
                        {I18n.t('components.ibe.search_form.hotel.label.children')}
                        <div className="search-hints">
                          {I18n.t('components.ibe.search_form.hotel.label.children_age_range')}
                        </div>
                      </div>
                      <QuantitySelector
                        {...children}
                        onChange={onChildrenChange}
                        disabled={isQuantitySelectorDisabled}
                      />
                    </div>
                    <div className="search-traveler-note">
                      {I18n.t('components.ibe.search_form.traveler_note')}
                    </div>
                  </div>
                  {children.value > 0 && (
                    <div className="hotel-form-children-age__container">
                      <div className="hotel-form-children-age__bottom-border" />
                      {childrenData.map(({ age }, index) => (
                        <div className="hotel-form-children-age__selectbox">
                          <SelectBox
                            options={ageOptions}
                            onChange={selectedOption =>
                              selectedOption && age.onChange(selectedOption.value)
                            }
                            value={getSelectValueByAge(age)}
                            size="tiny"
                            width="full"
                            label={I18n.t('components.ibe.search_form.hotel.label.child_age', {
                              count: itemCounter(index),
                            })}
                            errorMsg={age.touched && age.error}
                            isClearable={false}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <Alert
                    className="search-menu-item__error"
                    hideClose={true}
                    isVisible={adults.touched && adults.error}
                    type="danger"
                  >
                    {adults.error}
                  </Alert>
                  <Alert
                    className="search-menu-item__error"
                    hideClose={true}
                    isVisible={cartHasItems}
                    type="warning"
                  >
                    {I18n.t('components.ibe.search_form.error.change_number_of_travelers')}
                  </Alert>
                </Fragment>
              </Menu>
            </div>
          </div>
          <div
            className={classNames('d-flex', {
              'd-flex-column': laymanMode,
            })}
          >
            <div
              className={classNames({
                'col-6 col-bleed hotel-form__location': !laymanMode,
                'col-12 col-bleed-x': laymanMode,
              })}
            >
              <LocationSearchInput
                address={address.value}
                placeholder={I18n.t('components.ibe.search_form.hotel.label.location')}
                fullWidth={true}
                onAddressChange={address.onChange}
                onLatitudeChange={latitude.onChange}
                onLongitudeChange={longitude.onChange}
                touched={address.touched}
                error={address.error}
                size="small"
                selectedLocation={latitude.value}
              />
            </div>
            <div
              className={classNames('col-bleed', {
                'col-6 hotel-form__datepicker': !laymanMode,
                'col-12': laymanMode,
              })}
            >
              <DateRangePicker
                required={true}
                onChange={date => onDateRangeChange(date)}
                startDatePlaceholderText={I18n.t('components.ibe.search_form.hotel.label.check_in')}
                endDatePlaceholderText={I18n.t('components.ibe.search_form.hotel.label.check_out')}
                canSelectPastDates={false}
                fullWidth={true}
                startDate={checkIn.value}
                endDate={checkOut.value}
                error={showInvalidDateError(checkOut) || showInvalidDateError(checkIn)}
                isBoundToAYear={false}
              />
            </div>
          </div>
        </div>
        {!laymanMode && searchForm && (
          <Card className="col-5 flight-form__channel-card">
            <SearchAccounts
              searchAccounts={props.searchAccounts.hotels}
              selectedAccounts={accountSettings}
              disabled={false}
              selectAll={props.selectAll}
              toggleSelectAll={props.toggleSelectAll}
              selectGds={props.selectGds}
              toggleSelectGds={props.toggleSelectGds}
              selectDirect={props.selectDirect}
              toggleSelectDirect={props.toggleSelectDirect}
              accountSettings={props.searchForm.accountSettings}
              isChannelMissingAlertVisible={isNoChannelSelected}
            />
          </Card>
        )}
      </div>

      <div className="hotel-form__search-reset d-flex">
        <Button
          label={I18n.t('components.ibe.search_form.label.search_hotel')}
          size="normal"
          version="v2"
          disabled={!!submitting || isNoChannelSelected}
          onClick={handleSubmit(handleFormSubmission)}
          type="primary"
        />

        <Link className="hotel-form__reset-button d-flex align-center" onClick={onResetButtonClick}>
          {I18n.t('components.ibe.search_form.flight.label.reset')}
        </Link>
      </div>
    </div>
  );
};

SearchForm.defaultProps = {
  laymanMode: false,
  searchAccounts: null,
  selectAll: true,
  setIsCollapsed: () => {},
  resetBookingAppContext: () => {},
};

SearchForm.propTypes = {
  searchHotels: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    checkIn: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool,
      error: PropTypes.arrayOf(PropTypes.shape()),
      value: PropTypes.string,
    }),
    checkOut: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool,
      error: PropTypes.arrayOf(PropTypes.shape()),
      value: PropTypes.string,
    }),
    address: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool,
      error: PropTypes.arrayOf(PropTypes.shape()),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    latitude: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.number.isRequired,
    }),
    longitude: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }),
    children: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    childrenData: PropTypes.arrayOf(
      PropTypes.shape({
        onChange: PropTypes.func.isRequired,
      }),
    ),
    numberOfRooms: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool,
      error: PropTypes.arrayOf(PropTypes.shape()),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      valid: PropTypes.bool,
    }),
    adults: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool,
      error: PropTypes.arrayOf(PropTypes.shape()),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      valid: PropTypes.bool,
    }),
    accountSettings: PropTypes.shape({
      cars: PropTypes.arrayOf(PropTypes.string),
      hotels: PropTypes.arrayOf(PropTypes.string),
      flights: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  forceAdvancedSearch: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cartHasItems: PropTypes.bool.isRequired,
  availableChannels: availableChannelsShape.isRequired, // eslint-disable-line react/no-typos
  laymanMode: PropTypes.bool,
  defaultSearchParams: PropTypes.shape({}).isRequired,
  cartOverrides: PropTypes.shape({}).isRequired,
  initializeForm: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  searchAccounts: searchAccountsShape,
  selectAll: PropTypes.string,
  toggleSelectAll: PropTypes.func.isRequired,
  resetApp: PropTypes.func.isRequired,
  searchForm: PropTypes.shape({
    accountSettings: searchAccountsShape.hotel,
  }).isRequired,
  preselectTravelers: PropTypes.arrayOf(
    PropTypes.shape({
      traveler: PropTypes.shape({
        value: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          middleName: PropTypes.string,
          lastName: PropTypes.string.isRequired,
          phoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
          emailAddresses: PropTypes.arrayOf(PropTypes.string).isRequired,
          age: PropTypes.number.isRequired,
        }),
      }),
    }),
  ).isRequired,
  setIsCollapsed: PropTypes.func,
  formWithoutChildren: PropTypes.bool.isRequired,
  selectGds: PropTypes.bool.isRequired,
  selectDirect: PropTypes.bool.isRequired,
  toggleSelectGds: PropTypes.func.isRequired,
  toggleSelectDirect: PropTypes.func.isRequired,
  resetBookingAppContext: PropTypes.func,
  travelArrangerPresent: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  preselectDecision: PropTypes.string.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  updatePreselectTravelers: PropTypes.func.isRequired,
};

export default SearchForm;
