import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Popover from '@wtag/rcl-popover';
import LogoChip from './LogoChip';
import { airlineCodeItemShape, airlineInformationItemShape } from './shapes';

const AirlinePopup = ({ title, codes, showAirlineCode, airlineInformations }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const togglePopover = event => {
    setIsPopoverVisible(prevState => !prevState);
    event.stopPropagation();
  };

  const getPopupLogoItem = (logo, code) => {
    const popupLogoInformation = airlineInformations[logo];

    if (!popupLogoInformation) {
      return null;
    }

    const airlineCode = showAirlineCode ? code : popupLogoInformation.code;

    return (
      <div key={code} className="d-flex align-center flight__airline-list-item">
        {popupLogoInformation && <LogoChip logo={popupLogoInformation.logo} />}
        <div className="d-flex-col flight__airline-list-item-detail">
          {popupLogoInformation && popupLogoInformation.name && (
            <h5>{popupLogoInformation.name}</h5>
          )}
          {airlineCode && <p>{airlineCode}</p>}
        </div>
      </div>
    );
  };

  return (
    <Popover
      isVisible={isPopoverVisible}
      content={
        <div className="d-flex-col flight__airline-list">
          {codes.map(({ logo, code }) => getPopupLogoItem(logo, code))}
        </div>
      }
      showTip={false}
      direction="bottom"
      onOutsideClick={() => setIsPopoverVisible(false)}
    >
      <span
        className="flight__airline-popup-label"
        role="button"
        tabIndex={0}
        onKeyDown={event => togglePopover(event)}
        onClick={event => togglePopover(event)}
      >
        +{title}
      </span>
    </Popover>
  );
};

AirlinePopup.defaultProps = {
  showAirlineCode: false,
};

AirlinePopup.propTypes = {
  title: PropTypes.string.isRequired,
  codes: PropTypes.arrayOf(PropTypes.shape(airlineCodeItemShape)).isRequired,
  airlineInformations: PropTypes.shape(airlineInformationItemShape).isRequired,
  showAirlineCode: PropTypes.bool,
};

const mapStateToProps = state => ({
  airlineInformations: state.flights.airlineInformations,
});

export default connect(mapStateToProps)(AirlinePopup);
