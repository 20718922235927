import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { reportToRollbar } from '../common';

export const AIRLINE_INFORMATIONS_FETCHED = 'AIRLINE_INFORMATIONS_FETCHED';
export function airlineInformationsFetched(data) {
  return {
    type: AIRLINE_INFORMATIONS_FETCHED,
    data,
  };
}

const fetchedAirlines = {};
export const FETCHING_AIRLINE_INFORMATIONS = 'FETCHING_AIRLINE_INFORMATIONS';
export function fetchAirlineInformations(code) {
  return dispatch => {
    if (fetchedAirlines[code]) {
      return null;
    }
    fetchedAirlines[code] = true;

    dispatch({
      type: FETCHING_AIRLINE_INFORMATIONS,
      code,
    });

    const url = routes.api.airline({ code });
    return httpClient
      .get(url)
      .then(({ data }) => dispatch(airlineInformationsFetched(data)))
      .catch(error => {
        if (error.response.status === 404) {
          return null;
        }
        return dispatch(reportToRollbar("Airline's information couldn't be fetched", error));
      });
  };
}

export const AIRLINES_SEARCHED = 'AIRLINES_SEARCHED';
function airlinesSearched(airlines) {
  return {
    type: AIRLINES_SEARCHED,
    airlines,
  };
}

export function searchAirlines(query) {
  return dispatch => {
    const url = routes.api.airlines({ q: query });
    return httpClient.get(url).then(({ data }) => dispatch(airlinesSearched(data)));
  };
}

export const SEARCHED_AIRLINE_PREFERENCE = 'SEARCHED_AIRLINE_PREFERENCE';

function searchedAirlinePreference(airlines) {
  return {
    type: SEARCHED_AIRLINE_PREFERENCE,
    airlines,
  };
}

export const EMPTY_AIRLINE_PREFERENCE = 'EMPTY_AIRLINE_PREFERENCE';

export function emptyPreferredAirlines(isEmpty) {
  return {
    type: EMPTY_AIRLINE_PREFERENCE,
    isEmpty,
  };
}

export function searchAirlinePreference(query) {
  return dispatch => {
    const url = routes.api.airlines({ q: query });
    return httpClient.get(url).then(({ data }) => {
      if (data.length > 0) {
        dispatch(searchedAirlinePreference(data));
      } else {
        dispatch(emptyPreferredAirlines(true));
      }
    });
  };
}
