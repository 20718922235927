import { EXCHANGE_QUOTE_FETCHED, FETCHING_EXCHANGE_QUOTE } from '../../actions/flight';

export default function currentExchangeQuote(state = null, action) {
  switch (action.type) {
    case EXCHANGE_QUOTE_FETCHED:
      return action.data;

    case FETCHING_EXCHANGE_QUOTE:
      return null;

    default:
      return state;
  }
}
