import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MatrixContext from '../../contexts/MatrixContext';
import MatrixDataCard from '../MatrixDataCard';
import MatrixSupplierCard from '../MatrixSupplierCard';
import MatrixContentSlider from '../MatrixContentSlider';
import './matrix-channel-row.styles.scss';

const MatrixChannelContentRow = props => {
  const { channel, selectFareBrandOption, people } = props;
  const [shouldShowTheRow, setShouldShowTheRow] = useState(true);
  const [filteredFareBrands, setFilteredFareBrands] = useState([]);
  const {
    scrollSliderToLeftBy,
    matrixDataCardWidth,
    relatedTripsHashMap,
    activeTripId,
  } = useContext(MatrixContext);

  useEffect(() => {
    // This check is for hiding the supplier row when there are more than one related trips
    // And one trip has selected option
    // When user selects an option in a trip among all the related trip very first time
    // The supplier row (except the one that has a selected fair option) will be hidden in the other related trips
    // But will remain visible in the first selected trip
    if (
      relatedTripsHashMap[activeTripId] &&
      relatedTripsHashMap[activeTripId].firstSelectedTripId &&
      relatedTripsHashMap[activeTripId].firstSelectedTripId !== activeTripId
    ) {
      setFilteredFareBrands(channel.fareBrands.filter(fareBrand => fareBrand.isSelected));

      if (!channel.hasSelectedFareOption) {
        setShouldShowTheRow(false);
      }
    } else {
      setFilteredFareBrands(channel.fareBrands);
      setShouldShowTheRow(true);
    }
  }, [relatedTripsHashMap, channel, activeTripId]);

  return shouldShowTheRow ? (
    <div className="matrix-channel-row">
      <MatrixSupplierCard
        className={classNames('matrix-data-card', {
          'matrix-data-card--thick-border': scrollSliderToLeftBy % matrixDataCardWidth === 0,
        })}
        channelName={channel.name}
        channelIdentifier={channel.identifier}
        instantTicketSupported={channel.supportInstantTicket}
        payLaterSupported={channel.supportPayLater}
      />
      <MatrixContentSlider
        scrollSliderToLeftBy={scrollSliderToLeftBy}
        hideShowMoreButton={true}
        noHorizontalScroll={true}
      >
        {filteredFareBrands.map(fareBrand => (
          <MatrixDataCard
            key={fareBrand.data.resultId}
            channelId={`${channel.name}:${channel.identifier}`}
            matrixId={fareBrand.matrixId}
            data={fareBrand.data}
            price={fareBrand.price}
            currency={fareBrand.currency}
            isSelected={fareBrand.isSelected}
            onClick={selectFareBrandOption}
            people={people}
            grossTaxes={fareBrand.data.grossTaxes}
            fareCalculations={fareBrand.data.fareCalculations}
            marginBreakdown={fareBrand.data.marginBreakdown}
          />
        ))}
      </MatrixContentSlider>
    </div>
  ) : null;
};

MatrixChannelContentRow.propTypes = {
  channel: PropTypes.shape({
    name: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    hasSelectedFareOption: PropTypes.bool.isRequired,
    supportInstantTicket: PropTypes.bool.isRequired,
    supportPayLater: PropTypes.bool.isRequired,
    fareBrands: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  selectFareBrandOption: PropTypes.func.isRequired,
  people: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default MatrixChannelContentRow;
