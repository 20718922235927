import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import './style.scss';

const AirportChangeSegment = ({
  className,
  isBackgroundTransparent,
  destinationAirportDetails,
  changedAirportDetails,
}) => (
  <div
    className={classNames(
      'airport-change-segment',
      {
        'airport-change-segment--transparent-background': isBackgroundTransparent,
      },
      className,
    )}
  >
    <div className="grid grid-gap-12">
      <div className="col-2 col-bleed-x col-grid justify-center airport-change-segment__icon-content">
        <Icon className="airport-change-segment__icon" name="airportChange" size="large" />
      </div>
      <div className="col-10 col-bleed-x col-grid">
        <div className="col-12 col-bleed airport-change-segment__title">
          {I18n.t('components.flight.stopover.airport_change')}{' '}
          <span className="airport-change-segment__destination">
            {destinationAirportDetails.code}
          </span>
        </div>
        <div className="col-12 col-bleed airport-change-segment__stopover">
          {destinationAirportDetails.name}, {destinationAirportDetails.placeName},{' '}
          {destinationAirportDetails.countryName} ({destinationAirportDetails.code}){' '}
          {I18n.t('components.flight.stopover.airport_change_to')} {changedAirportDetails.name},{' '}
          {changedAirportDetails.placeName}, {changedAirportDetails.countryName} (
          {changedAirportDetails.code})
        </div>
      </div>
    </div>
  </div>
);

AirportChangeSegment.defaultProps = {
  className: null,
  isBackgroundTransparent: false,
};

AirportChangeSegment.propTypes = {
  className: PropTypes.string,
  isBackgroundTransparent: PropTypes.bool,
  destinationAirportDetails: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeName: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
  }).isRequired,
  changedAirportDetails: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeName: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
  }).isRequired,
};

export default AirportChangeSegment;
