import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LeftSidedHotelBookingStatus from './LeftSidedHotelBookingStatus';
import RightSidedHotelBookingStatus from './RightSidedHotelBookingStatus';

const HotelBookingStatus = ({ statusInformation, laymanMode }) => {
  const {
    approvalRequired,
    corporateDeal,
    labels = {},
    payLater,
    policiesReasons = {},
    refundable = null,
  } = statusInformation;

  const { outOfPolicy = false, preferred = false } = labels;

  const enableHotelBookingStatusSection =
    refundable || (payLater && !laymanMode) || outOfPolicy || preferred || approvalRequired;

  if (!enableHotelBookingStatusSection) return null;

  return (
    <Fragment>
      <div className="hotel-booking-status">
        <div
          className={classNames(
            'd-flex align-center justify-between flex-wrap hotel-booking-status__information',
            {
              'hotel-booking-status__information--admin-app': !laymanMode,
            },
          )}
        >
          <div className="d-flex align-center justify-center flex-wrap hotel-booking-status__information-item">
            <LeftSidedHotelBookingStatus corporateDeal={corporateDeal} preferred={preferred} />
          </div>
          <div className="d-flex align-center justify-center wrap hotel-booking-status__information-item hotel-booking-status__information-item--right">
            <RightSidedHotelBookingStatus
              approvalRequired={approvalRequired}
              laymanMode={laymanMode}
              outOfPolicy={outOfPolicy}
              payLater={payLater}
              policiesReasons={policiesReasons}
              refundable={refundable}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

HotelBookingStatus.propTypes = {
  statusInformation: PropTypes.shape({
    refundable: PropTypes.bool,
    payLater: PropTypes.bool.isRequired,
    labels: PropTypes.shape({
      outOfPolicy: PropTypes.bool,
      preferred: PropTypes.bool,
    }).isRequired,
    approvalRequired: PropTypes.bool.isRequired,
    corporateDeal: PropTypes.bool.isRequired,
    policiesReasons: PropTypes.shape({
      outOfPolicy: PropTypes.string,
      approvalRequired: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  laymanMode: PropTypes.bool.isRequired,
};

export default HotelBookingStatus;
