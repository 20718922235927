import React from 'react';
import PropTypes from 'prop-types';

import FlightSearchName from '../Search/flight/FlightSearchName';
import HotelSearchName from '../Search/hotel/HotelSearchName';
import CarSearchName from '../Search/car/CarSearchName';
import StaticSearchName from '../Search/static/StaticSearchName';

const availableComponents = {
  flight: FlightSearchName,
  hotel: HotelSearchName,
  static: StaticSearchName,
  car: CarSearchName,
};

const SearchName = props => {
  const { type, ...other } = props;
  const RealSearchName = availableComponents[type];
  if (!RealSearchName) {
    return null;
  }

  return <RealSearchName {...other} />;
};

SearchName.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SearchName;
