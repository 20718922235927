import React from 'react';
import { I18nText, Amount } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';

const MileageMapper = ({ mileage, index }) => {
  const { amount, currency, modifier, type, packageSize } = mileage;
  const modifierMapper = {
    per_day: I18n.t('components.ibe.search_results.car.mileage.modifiers.per_day'),
    per_week: I18n.t('components.ibe.search_results.car.mileage.modifiers.per_week'),
    per_month: I18n.t('components.ibe.search_results.car.mileage.modifiers.per_month'),
    per_rental: I18n.t('components.ibe.search_results.car.mileage.modifiers.per_rental'),
    per_hour: I18n.t('components.ibe.search_results.car.mileage.modifiers.per_hour'),
    per_minute: I18n.t('components.ibe.search_results.car.mileage.modifiers.per_minute'),
  };

  const mappedModifer = modifierMapper[modifier];
  const mappedAmount = amount ? (
    <Amount value={amount} currency={currency} />
  ) : (
    <I18nText id="ibe.car.facilities.amount_included" returnStringOnly={true} />
  );

  const typeMapper = {
    unlimited: (
      <span className="car-result-card__car-mileage-type">
        {I18n.t('components.ibe.search_results.car.mileage.types.unlimited')}
      </span>
    ),
    free_miles: (
      <span className="car-result-card__car-mileage-type">
        {packageSize} {I18n.t('components.ibe.search_results.car.mileage.types.free_miles')}{' '}
        {mappedModifer}
      </span>
    ),
    free_kilometers: (
      <span className="car-result-card__car-mileage-type">
        {packageSize} {I18n.t('components.ibe.search_results.car.mileage.types.free_kilometers')}{' '}
        {mappedModifer}
      </span>
    ),
    per_mile: (
      <span>
        {mappedAmount} {I18n.t('components.ibe.search_results.car.mileage.types.per_mile')}
      </span>
    ),
    per_kilometer: (
      <span>
        {mappedAmount} {I18n.t('components.ibe.search_results.car.mileage.types.per_kilometer')}
      </span>
    ),
  };

  return index > 0 ? (
    <span>
      {` (`}
      {typeMapper[type]} {I18n.t('components.ibe.search_results.car.mileage.afterward')}
      {`) `}
    </span>
  ) : (
    typeMapper[type]
  );
};

MileageMapper.propTypes = {
  mileage: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
    modifier: PropTypes.string,
    type: PropTypes.string,
    packageSize: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

const Mileage = ({ mileages }) =>
  mileages.map((mileage, index) => <MileageMapper mileage={mileage} index={index} />);

Mileage.propTypes = {
  mileages: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      currency: PropTypes.string,
      modifier: PropTypes.string,
      type: PropTypes.string,
      packageSize: PropTypes.string,
    }),
  ).isRequired,
};

export default Mileage;
