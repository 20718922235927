import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchCountries } from '../actions/common';

class CountryComponent extends React.Component {
  componentDidMount() {
    if (this.props.countries.length < 1) {
      this.props.fetchCountries();
    }
  }

  render() {
    const { countries, code } = this.props;
    const countryCodeInLowerCase = code && code.toLowerCase();
    const countryData = countries.find(
      country => country.code && country.code.toLowerCase() === countryCodeInLowerCase,
    );

    return countryData ? countryData.name : code;
  }
}

CountryComponent.propTypes = {
  code: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fetchCountries: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  countries: state.common.countries,
});

const mapDispatchToProps = dispatch => ({
  fetchCountries: () => dispatch(fetchCountries()),
});

export { CountryComponent };
export default connect(mapStateToProps, mapDispatchToProps)(CountryComponent);
