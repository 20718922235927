import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import WellDevBookingComponent from './WellDevBookingComponent';

const WellDevBookingComponentWrapper = props => {
  const ref = useRef(null);

  useEffect(() => {
    const values = Object.values(props.values.cardholderAddress);
    const allValuesUndefined = values.every(value => value === undefined);
    if (allValuesUndefined) {
      ref.current.discardForm();
    }
  }, [props.values.cardholderAddress]);

  return <WellDevBookingComponent {...props} ref={ref} />;
};

WellDevBookingComponentWrapper.propTypes = {
  values: PropTypes.shape({
    cardholderAddress: PropTypes.shape().isRequired,
  }).isRequired,
};

export default WellDevBookingComponentWrapper;
