import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CarAttributeItem = ({ header, value, className }) => (
  <div className={classNames('d-flex-col justify-center row-gap-4', className)}>
    <span className="car-compact-view__attribute-item--header">{header}</span>
    <span className="car-compact-view__attribute-item--value">{value}</span>
  </div>
);

CarAttributeItem.defaultProps = {
  className: null,
};

CarAttributeItem.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default CarAttributeItem;
