import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag, I18nText } from '@wtag/react-comp-lib';
import ProductIcon from 'sharedWebpack/ProductIcon';
import RemoveItemButton from '../../RemoveItemButton';
import BookingLabelWithHint from '../../../../SearchResult/flight/BookingLabelWithHint';
import { itemShape } from '../../../shapes';
import CopyButton from '../../CopyButton';
import '../../../styles.scss';

const CarItemLabels = (props, context) => {
  const {
    isCartLocked,
    item,
    journeyElement,
    itemNo,
    removeFromCart,
    itemDetails,
    flightServicesByResultId,
    travelers,
  } = props;

  const { journeyElementType, type, id, pendingConfirmation, approvalRequired, available } = item;

  const handleRemoveItemFromCart = cartItemId => {
    removeFromCart(cartItemId);
  };

  return (
    <div className="col-12 col-grid col-bleed item__labels">
      <div className="col-12 col-grid col-bleed align-center direction-row justify-space-between item__labels-price wrap">
        <div className="d-flex-col align-center">
          <div className="d-flex align-center column-gap-8">
            <ProductIcon
              className="item__labels--product-icon"
              productType={journeyElementType || type}
              size="tiny"
              showBGColor={true}
            />
            <div className="d-flex-col item__labels-price-title">
              <div className="d-flex align-center column-gap-8">
                <span>
                  {I18n.t('components.ibe.cart.item', {
                    number: itemNo,
                  })}
                </span>
                {!!removeFromCart && (
                  <RemoveItemButton
                    className="item__labels-remove-button"
                    isCartLocked={isCartLocked}
                    itemId={id}
                    onConfirm={handleRemoveItemFromCart}
                    frozen={context.frozen}
                  />
                )}
                <CopyButton
                  item={item}
                  journeyElement={journeyElement}
                  travelers={travelers}
                  flightServicesByResultId={flightServicesByResultId}
                  isDisabled={!available}
                />
              </div>
            </div>
          </div>
          {pendingConfirmation && (
            <div className="item__labels-price-header--with-instant-ticket">
              <EmphasisTag
                text={<I18nText id="ibe.automatic_products.auto_added" />}
                type="warning"
                size="small"
              />
            </div>
          )}
        </div>
        <div className="d-flex align-center item__booking-status-labels">
          {itemDetails?.instantTicket && (
            <BookingLabelWithHint
              iconName="eTicket"
              iconColor="warning"
              text={<I18nText id="instant_issue" />}
              hint={<I18nText id="instant_ticket.description" />}
            />
          )}
          {approvalRequired && (
            <BookingLabelWithHint
              iconName="singlePolicy"
              iconColor="danger"
              text={<I18nText id="ibe.search_result.approval_required" />}
            />
          )}
          {journeyElement?.refundable && (
            <BookingLabelWithHint
              iconName="checkCircleOutline"
              iconColor="success"
              text={<I18nText id="ibe.cart_item_pricing.refundable" />}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CarItemLabels.defaultProps = {
  journeyElement: null,
  item: {
    cancelPenalty: null,
    changePenalty: null,
  },
  itemDetails: { available: false },
};

CarItemLabels.contextTypes = {
  frozen: PropTypes.bool.isRequired,
};

CarItemLabels.propTypes = {
  isCartLocked: PropTypes.bool.isRequired,
  itemNo: PropTypes.number.isRequired,
  item: PropTypes.shape(itemShape),
  journeyElement: PropTypes.shape({
    name: PropTypes.string,
    vendorName: PropTypes.string,
    destinationCode: PropTypes.string,
    fareRulePresent: PropTypes.bool,
    id: PropTypes.number,
    originCode: PropTypes.string,
    refundable: PropTypes.bool,
  }),
  removeFromCart: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    instantTicket: PropTypes.bool.isRequired,
  }),
  flightServicesByResultId: PropTypes.shape({}).isRequired,
  travelers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CarItemLabels;
