import React from 'react';
import PropTypes from 'prop-types';
import Selectbox from '@wtag/rcl-select-box';
import debounce from 'throttle-debounce/debounce';
import { Textarea } from '@wtag/react-comp-lib';

class Remarks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      remarkType: props.selectedType || props.remarkTypes[0].value,
      typeTouched: false,
    };
  }

  onChangeContent = event => {
    const val = event.target.value.toUpperCase();
    this.setRemarks(this.state.remarkType, val);
    this.setState({ value: val });
  };

  onChangeType = remarkType => {
    this.setRemarks(remarkType, this.state.value);
    this.setState({ remarkType });
  };

  setRemarks = debounce(300, (type, value) =>
    this.props.setRemarks(type, value, this.props.resultId, this.context.callbackParams),
  );

  render() {
    if (!this.props.remarkTypes.length) {
      return null;
    }
    const remarkTypeSelection = (
      <Selectbox
        name="remark-type"
        label={I18n.t('components.ibe.remarks.type')}
        options={this.props.remarkTypes}
        size="small"
        value={this.state.remarkType}
        onChange={this.onChangeType}
        onBlur={() => this.setState({ typeTouched: true })}
        touched={this.state.typeTouched}
        width="full"
      />
    );
    return (
      <div className="remarks col-12 col-bleed">
        <h4>
          <em>{I18n.t('components.ibe.remarks.subtitle')}</em>
        </h4>
        {this.props.remarkTypes.length > 0 && remarkTypeSelection}
        <Textarea
          label={I18n.t('components.ibe.remarks.label')}
          rows={5}
          cols={50}
          onChange={this.onChangeContent}
          value={this.state.value}
        />
      </div>
    );
  }
}

Remarks.defaultProps = {
  value: null,
  selectedType: null,
};

Remarks.propTypes = {
  setRemarks: PropTypes.func.isRequired,
  value: PropTypes.string,
  resultId: PropTypes.string.isRequired,
  remarkTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired,
  ).isRequired,
  selectedType: PropTypes.string,
};

export default Remarks;
