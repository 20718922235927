import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import { Link } from 'react-router-dom';
import Amount from 'sharedWebpack/Amount';
import Button from '@wtag/rcl-button';
import Stars from '../../../../components/Stars';
import '../styles.scss';

const HotelAccordionHeader = ({
  channelListText,
  hotelAddress,
  hotelName,
  laymanMode,
  stars,
  link,
  buttonText,
  alreadySelectedInCart,
  currency,
  perNightPrice,
}) => (
  <Fragment>
    <div className="hotel-compact-view">
      <div className="d-flex flex-start hotel-compact-view__supplier-name">
        <div className="d-flex flex-wrap">
          {!laymanMode && <EmphasisTag size="tiny" text={channelListText} />}
        </div>
      </div>
      <div className="hotel-compact-view__details d-flex">
        <div className="hotel-compact-view__details-left">
          <div>{hotelName}</div>
          <div className="d-flex hotel-compact-view__details-address-stars">
            {stars > 0 && (
              <div className="d-flex column-gap-4">
                <Stars wrapperClassName="col-12 col-bleed" showStarsNumber={true} stars={stars} />

                <div className="hotel-compact-view__vertical-line" />
              </div>
            )}
            <div className="hotel-compact-view__details-left--hotel-address">{hotelAddress}</div>
          </div>
        </div>
        <div className="d-flex flex-gap-12 hotel-compact-view__details-right">
          <div>
            <Amount currency={currency} value={perNightPrice} />
            <div className="hotel-compact-view__details-right-price-label">
              ({I18n.t('components.ibe.search_results.hotel.per_night')})
            </div>
          </div>
          <div className="d-flex align-center justify-between flex-gap-12">
            <Link to={link}>
              <Button
                className="hotel-compact-view__details-right-button"
                size="small"
                version="v2"
                label={buttonText()}
                primary={true}
                fullWidth={true}
                disabled={alreadySelectedInCart}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

HotelAccordionHeader.propTypes = {
  channelListText: PropTypes.string.isRequired,
  hotelAddress: PropTypes.string.isRequired,
  hotelName: PropTypes.string.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  stars: PropTypes.number.isRequired,
  link: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  buttonText: PropTypes.func.isRequired,
  alreadySelectedInCart: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  perNightPrice: PropTypes.string.isRequired,
};

export default HotelAccordionHeader;
