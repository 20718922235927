import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import DateTime from 'sharedWebpack/DateTime';
import { DATE_FORMAT_SHORT_YEAR } from 'sharedWebpack/helpers/dateTime';
import { SUCCESS } from 'sharedWebpack/IBE/lib/helpers/componentStates';
import HotelRoomFallbackImage from '../../../../assets/images/synced/fallback_images/product/product_xxl_hotel.png';
import isAvailable from '../../IBE/Cart/helpers/isAvailable';
import Amenity from '../../IBE/SearchDetails/hotel/Amenity';
import LeftSidedHotelBookingStatus from '../../IBE/SearchResult/hotel/HotelBookingStatus/LeftSidedHotelBookingStatus';
import LabelWithHint from '../../IBE/components/LabelWithHint';

const HotelRoomInfo = props => {
  const { room, payLater, laymanMode, journeyElement, preferred, checkIn, checkOut } = props;

  const { pictures, wifiAvailability, corporateDeal } = journeyElement;

  const isWifiAvailable = isAvailable(wifiAvailability.key);
  const isBreakfastAvailable = isAvailable(room.boardType.key);

  const displayStatusSeparator = (payLater && !laymanMode) || corporateDeal || preferred;

  const setPlaceholderImage = event => {
    const target = event.target;
    target.src = HotelRoomFallbackImage;
  };

  return (
    <div className="hotel-item">
      <div className="hotel-item__details">
        <div className="hotel-item__details-image">
          <img
            onError={setPlaceholderImage}
            src={pictures[0] || HotelRoomFallbackImage}
            alt="hotel"
          />
        </div>
        <div className="hotel-item__info">
          <div className="hotel-item__header-tag">{room.name}</div>
          <div className="d-flex flex-wrap align-center">
            <div className="d-flex-column flex-basis-half">
              <span>{I18n.t('admin.components.orders.items_tab.placeholder.check_in')}</span>
              <span className="hotel-item__date">
                <DateTime
                  dateTime={checkIn}
                  format={DATE_FORMAT_SHORT_YEAR}
                  displayWithoutConversion={true}
                />
              </span>
            </div>
            <div className="d-flex-column">
              <span>{I18n.t('admin.components.orders.items_tab.placeholder.check_out')}</span>
              <span className="hotel-item__date">
                <DateTime
                  dateTime={checkOut}
                  format={DATE_FORMAT_SHORT_YEAR}
                  displayWithoutConversion={true}
                />
              </span>
            </div>
          </div>
          <div className="d-flex align-center column-gap-12 hotel-item__facilities">
            {isWifiAvailable && (
              <div className="hotel-item__amenity">
                <Amenity amenity="wifi" />
              </div>
            )}
            {isBreakfastAvailable && (
              <div className="hotel-item__amenity">
                <Amenity amenity="break_fast" />
              </div>
            )}
          </div>
        </div>
      </div>
      {displayStatusSeparator && <div className="hotel-item__separator" />}
      <div className="d-flex align-center justify-between flex-gap-8 wrap">
        <div className="d-flex align-start">
          <LeftSidedHotelBookingStatus corporateDeal={corporateDeal} preferred={preferred} />
        </div>
        {payLater && !laymanMode && (
          <div className="d-flex align-center hotel-booking-status__information-item-icon">
            <Icon name="recurringPayment" size="tiny" color={SUCCESS} />
            <LabelWithHint
              text={I18n.t('components.ibe.results.book_and_hold')}
              hint={I18n.t('components.ibe.results.hotel_book_and_hold_hint')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

HotelRoomInfo.defaultProps = {
  payLater: false,
  preferred: false,
};

HotelRoomInfo.propTypes = {
  payLater: PropTypes.bool,
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    boardType: PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    }),
  }).isRequired,

  laymanMode: PropTypes.bool.isRequired,
  journeyElement: PropTypes.shape({
    pictures: PropTypes.arrayOf(PropTypes.string).isRequired,
    wifiAvailability: PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    }).isRequired,
    corporateDeal: PropTypes.bool.isRequired,
  }).isRequired,
  preferred: PropTypes.bool,
  checkIn: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired,
};

export default HotelRoomInfo;
