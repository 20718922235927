import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PriceMenu from '../../PriceMenu';
import PriceMenuItem from '../../PriceMenuItem';
import CarPriceWithLabel from '../CarPriceWithLabel';
import './styles.scss';

const CarPriceWithBreakdown = ({ className, breakdownDetails, laymanMode }) => {
  const {
    currency,
    ratePerDay,
    durationInDays,
    grossTaxes,
    marginBreakdown,
    grossTotal,
    car,
  } = breakdownDetails;

  const { category, acrissCode } = car;
  const hasCarDetailsAndRatePerDay = category && acrissCode && ratePerDay;
  const hasDurationInDaysAndGrossTotal = durationInDays && grossTotal;

  return (
    <div className={classNames('car-item-pricing', className)}>
      <div className="car-item-pricing__value car-item-pricing__unit-price-container">
        <PriceMenu
          label={
            <CarPriceWithLabel
              label={I18n.t('components.ibe.results.from')}
              currency={currency}
              price={ratePerDay}
            />
          }
        >
          {hasCarDetailsAndRatePerDay && (
            <PriceMenuItem
              label={
                <Fragment>
                  <span className="car-item-pricing__category">{category}</span>
                  &nbsp;
                  {I18n.t(
                    'admin.components.products.options.automation_style.acriss_code_with_per_day.label',
                    {
                      acriss_code: acrissCode,
                    },
                  )}
                </Fragment>
              }
              currency={currency}
              amount={ratePerDay}
            />
          )}
          {hasDurationInDaysAndGrossTotal && (
            <PriceMenuItem
              label={I18n.t('admin.components.orders.items_tab.tag.for_days', {
                count: durationInDays,
              })}
              currency={currency}
              amount={grossTotal}
              isPriceBold={true}
            />
          )}
          <PriceMenuItem
            label={I18n.t('activerecord.attributes.order_item.taxes_charges')}
            currency={currency}
            amount={grossTaxes}
            isFree={Number(grossTaxes) === 0}
          />
          {!laymanMode &&
            marginBreakdown.map(({ id, name, currency: marginCurrency, total }) => (
              <PriceMenuItem key={id} label={name} currency={marginCurrency} amount={total} />
            ))}
          {grossTotal && (
            <PriceMenuItem
              label={I18n.t('activerecord.attributes.order_item.total')}
              currency={currency}
              amount={grossTotal}
              isPriceBold={true}
            />
          )}
        </PriceMenu>
      </div>
    </div>
  );
};

CarPriceWithBreakdown.defaultProps = {
  className: null,
};

CarPriceWithBreakdown.propTypes = {
  className: PropTypes.string,
  laymanMode: PropTypes.bool.isRequired,
  breakdownDetails: PropTypes.shape({
    currency: PropTypes.string,
    ratePerDay: PropTypes.string,
    durationInDays: PropTypes.number,
    grossTaxes: PropTypes.string,
    marginBreakdown: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        currency: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        total: PropTypes.string,
      }),
    ).isRequired,
    grossTotal: PropTypes.string,
    car: PropTypes.shape({
      category: PropTypes.string,
      acrissCode: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CarPriceWithBreakdown;
