import React from 'react';
import PropTypes from 'prop-types';
import { Amount, QuantitySelector } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import TravelerAssignment from '../TravelerAssignment';
import './styles.scss';

const Service = ({ service, travelers, onChange }) => {
  const {
    limitations,
    quantityInGroup,
    quantity,
    travelerIndices,
    price,
    assignedTravelersOfGroup,
    currency,
    description,
  } = service;
  const { maximumPerGroup, maximumScope } = limitations;
  const TRAVELER = 'traveler';
  const isTravelerAssignmentVisible = quantity > 0 && maximumScope === TRAVELER;

  const maxValue = () => {
    if (maximumPerGroup === null) {
      return travelers.length;
    }

    let multiplier;

    switch (maximumScope) {
      case 'booking':
        multiplier = 1;
        break;
      case 'traveler':
        multiplier = travelers.length;
        break;
      default:
        throw new Error(`Unknown maximumScope ${maximumScope}`);
    }

    return maximumPerGroup * multiplier - (quantityInGroup - quantity);
  };

  const availableTravelers = travelerIndexes => {
    const remainings = Array.from({ length: travelers.length }, (_, i) => i);

    if (!maximumPerGroup) {
      return remainings;
    }

    return remainings.filter(
      remain => travelerIndexes.filter(index => index === remain).length < maximumPerGroup,
    );
  };

  const nextTravelerIndexToUse = (travelerIndexes, alreadyAssigneTravelersList) => {
    const availableTravelersIndices = availableTravelers(alreadyAssigneTravelersList);

    const unusedTravelersIndices = availableTravelersIndices.filter(
      index => !alreadyAssigneTravelersList.includes(index),
    );

    if (unusedTravelersIndices.length === 0) {
      const uniqueToUse = availableTravelersIndices
        .filter(index => !travelerIndexes.includes(index))
        .shift();

      return uniqueToUse || availableTravelersIndices.shift();
    }

    return unusedTravelersIndices.shift();
  };

  const prepareTravlerIndicesForService = (existingIndices, quantityValue) => {
    const travelerIndexes = [...existingIndices];
    const alreadyAssigneTravelersList = [...existingIndices];
    const numberOfTravelersToAdd = quantityValue - travelerIndexes.length;

    for (let count = 0; count < numberOfTravelersToAdd; count += 1) {
      const indexToUse = nextTravelerIndexToUse(travelerIndexes, alreadyAssigneTravelersList);

      travelerIndexes.push(indexToUse);
      alreadyAssigneTravelersList.push(indexToUse);
    }

    return travelerIndexes;
  };

  const handleQuantityChange = value => {
    let quantityValue = value;
    const max = maxValue();

    if (quantityValue > max) {
      quantityValue = max;
    }

    let currentTravelerIndices = [...travelerIndices];

    if (currentTravelerIndices.length > quantityValue) {
      currentTravelerIndices.length = quantityValue;
    } else {
      currentTravelerIndices = prepareTravlerIndicesForService(
        currentTravelerIndices,
        quantityValue,
      );
    }

    onChange(quantityValue, price, currentTravelerIndices);
  };

  const handleTravelerIndicesChange = (checked, travelerIndex) => {
    const currentTravelerIndices = [...travelerIndices];

    if (checked) {
      currentTravelerIndices.push(travelerIndex);
    } else {
      const travelerIndexToRemove = currentTravelerIndices.indexOf(travelerIndex);
      currentTravelerIndices.splice(travelerIndexToRemove, 1);
    }

    onChange(quantity, price, currentTravelerIndices);
  };

  return (
    <div className="service">
      <div className="service__left">
        <Icon className="service__left--icon" name="tag" showBGColor={true} color="tertiary" />
        <div className="service__left--name">{description}</div>
      </div>
      <div className="service__right">
        {isTravelerAssignmentVisible && (
          <TravelerAssignment
            travelers={travelers}
            travelerIndices={travelerIndices}
            assignedTravelersOfGroup={assignedTravelersOfGroup}
            maximumPerGroup={maximumPerGroup}
            onChange={handleTravelerIndicesChange}
            handleQuantityChange={handleQuantityChange}
            max={quantity}
          />
        )}
        <div className="service__right--quantity">
          <Amount
            className="service__right--price"
            currency={currency}
            value={price * (quantity || 1)}
            currencyDisplay="narrowSymbol"
          />
          <QuantitySelector
            onChange={handleQuantityChange}
            value={quantity}
            maxValue={maxValue()}
          />
        </div>
      </div>
    </div>
  );
};

Service.propTypes = {
  service: PropTypes.shape({
    assignedTravelersOfGroup: PropTypes.arrayOf(PropTypes.number).isRequired,
    description: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    travelerIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
    limitations: PropTypes.shape({
      maximumPerGroup: PropTypes.number,
      maximumScope: PropTypes.oneOf(['traveler', 'booking']),
    }).isRequired,
    quantityInGroup: PropTypes.number.isRequired,
  }).isRequired,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      name: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Service;
