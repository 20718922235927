import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LabelHint } from '@wtag/react-comp-lib';

const AccountIdentifierWithToolTip = props => {
  const [isTitleOverflown, setIsTitleOverflown] = useState(false);
  const titleRef = useRef(null);
  const title = props.identifier;

  useEffect(() => {
    setIsTitleOverflown(
      titleRef.current && titleRef.current.scrollWidth > titleRef.current.offsetWidth,
    );
  }, [titleRef]);

  return (
    <Fragment>
      <p className="search-accounts__corporate-code-label" ref={titleRef}>
        {title}
      </p>
      {isTitleOverflown && (
        <LabelHint hint={title} placement="top-left" className="search-accounts__identifier-hint" />
      )}
    </Fragment>
  );
};

AccountIdentifierWithToolTip.propTypes = {
  identifier: PropTypes.string.isRequired,
};

export default AccountIdentifierWithToolTip;
