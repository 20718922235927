import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18nText, EmphasisTag } from '@wtag/react-comp-lib';
import { Accordion, AccordionItem } from '@wtag/rcl-accordion';
import { FormatDateTime } from 'sharedWebpack/helpers/dateTime';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';
import '@wtag/rcl-accordion/dist/styles.min.css';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';

const FareBasisInfo = props => {
  const { info, penalties, segments } = props;
  const [activeAccordionKey, setActiveAccordionKey] = useState([]);
  const onAccordionClick = id => {
    const filteredActiveKeys = activeAccordionKey.includes(id)
      ? activeAccordionKey.filter(item => item !== id)
      : [...activeAccordionKey, id];

    setActiveAccordionKey(filteredActiveKeys);
  };

  const NOT_REFUNDABLE = 'not refundable';

  const checkAmount = (amount, type) => {
    if (amount === '0.0') {
      return `${type} ${I18n.t('components.ibe.fare_basis_info.penalty.not_applicable')}`;
    }

    return `${type} ${I18n.t('components.ibe.fare_basis_info.penalty.applicable')}`;
  };

  const penaltiesInformation = () => {
    const { change, cancel } = penalties;

    return (
      <Fragment>
        {change && change.amount && change.amount.toLowerCase() !== NOT_REFUNDABLE && (
          <EmphasisTag
            size="tiny"
            text={checkAmount(
              change.amount,
              I18n.t('components.ibe.fare_basis_info.penalty.change'),
            )}
            className="fare-basis-info__penalties-tag"
          />
        )}
        {cancel &&
          cancel.amount &&
          cancel.amount.length > 0 &&
          cancel.amount.toLowerCase() !== NOT_REFUNDABLE && (
            <EmphasisTag
              size="tiny"
              text={checkAmount(
                cancel.amount,
                I18n.t('components.ibe.fare_basis_info.penalty.cancel'),
              )}
              className="fare-basis-info__penalties-tag"
            />
          )}
      </Fragment>
    );
  };

  return (
    <div className="col-grid col-bleed">
      <div className="col-12 fare-basis-info__penalties">{penalties && penaltiesInformation()}</div>
      <div className="col-12 fare-basis-info__segments">
        <div className="fare-basis-info__segments-text">
          {I18n.t('components.ibe.fare_basis_info.segment.title')}
        </div>{' '}
        <span className="fare-basis-info__segments-number">
          {I18n.t('components.ibe.fare_basis_info.segment', { count: segments.length })}
        </span>
        {segments.map(segment => (
          <Card version="v2" className="fare-basis-info__segments-card">
            <div className="grid align-end">
              <div className="col-xlg-4 col-lg-4 col-md-6 col-sm-6 col-12 col-grid justify-center align-center">
                <div className="fare-basis-info__segments-airline-logo">
                  <img src={segment.airlineLogo} alt="airlineLogo" />
                </div>
                <div className="col-grid col-bleed direction-row justify-center fare-basis-info__segments-country-flag">
                  <img src={segment.originCountryFlag} alt="originFlag" />
                  <span className="fare-basis-info__segments-code">{segment.originCode}</span>
                  <Icon
                    name="arrowUp"
                    rotate="90"
                    className="fare-basis-info__segments-separator"
                  />
                  <img src={segment.destinationCountryFlag} alt="destinationFlag" />
                  <span className="fare-basis-info__segments-code">{segment.destinationCode}</span>
                </div>
                <div className="fare-basis-info__segments-date">
                  <DateTimeLocaleInterpolationWrapper
                    localeKey="components.ibe.cart_flight_departure_time"
                    time={FormatDateTime({ dateTime: segment.departureLocaltime })}
                  />
                </div>
              </div>

              <div className="col-xlg-2 col-lg-2 col-md-6 col-sm-6 col-12 align-self-center">
                <div className="fare-basis-info__segments-content">
                  {`${segment.marketingCarrierCode}${segment.marketingFlightNumber}` ||
                    I18n.t('components.ibe.not_available.short_form')}
                </div>
                <div className="fare-basis-info__segments-header">
                  {I18n.t('components.ibe.fare_basis_info.flight_number')}
                </div>
              </div>
              <div className="col-xlg-2 col-lg-2 col-md-6 col-sm-6 col-12 align-self-center">
                <div className="fare-basis-info__segments-content">
                  {segment.bookingClass || I18n.t('components.ibe.not_available.short_form')}
                </div>
                <div className="fare-basis-info__segments-header">
                  {I18n.t('components.ibe.fare_basis_info.booking_class')}
                </div>
              </div>
              <div className="col-xlg-2 col-lg-2 col-md-6 col-sm-6 col-12 align-self-center">
                <div className="fare-basis-info__segments-content">
                  {segment.fare.basisCode || I18n.t('components.ibe.not_available.short_form')}
                </div>

                <div className="fare-basis-info__segments-header">
                  {I18n.t('components.ibe.fare_basis_info.code')}
                </div>
              </div>
              <div className="col-xlg-2 col-lg-2 col-md-6 col-sm-6 col-12 align-self-center">
                <div className="fare-basis-info__segments-content">
                  {segment.fare.brand || I18n.t('components.ibe.not_available.short_form')}
                </div>

                <div className="fare-basis-info__segments-header">
                  {I18n.t('components.ibe.fare_basis_info.brand')}
                </div>
              </div>
            </div>
          </Card>
        ))}
        <div className="fare-basis-info__notice">
          <I18nText id="ibe.fare_basis_info.fare_notice.part1" returnStringOnly={true} />
        </div>
        <Accordion>
          {info.rules.map(rule => (
            <AccordionItem
              key={rule.category}
              onClick={onAccordionClick}
              isActive={activeAccordionKey.includes(rule.category)}
              header={I18n.t(rule.category, {
                scope: 'components.ibe.fare_basis_info.category',
              })}
              id={rule.category}
            >
              {rule.text}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

FareBasisInfo.defaultProps = {
  segments: [],
};

FareBasisInfo.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      marketingCarrierCode: PropTypes.string.isRequired,
      marketingFlightNumber: PropTypes.string.isRequired,
      originCountryFlag: PropTypes.string.isRequired,
      originCode: PropTypes.string.isRequired,
      destinationCountryFlag: PropTypes.string.isRequired,
      destinationCode: PropTypes.string.isRequired,
      departureLocaltime: PropTypes.string.isRequired,
      bookingClass: PropTypes.string.isRequired,
      airlineLogo: PropTypes.string.isRequired,
      fare: PropTypes.shape({
        brand: PropTypes.string,
        basisCode: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  info: PropTypes.shape({
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  penalties: PropTypes.shape({
    cancel: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    change: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default FareBasisInfo;
