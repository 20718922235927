import { VENDORS_SEARCHED } from '../../actions/car';

export default function vendorsByCode(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case VENDORS_SEARCHED:
      action.vendors.forEach(vendor => {
        newState[vendor.code] = vendor;
      });
      return newState;

    default:
      return state;
  }
}
