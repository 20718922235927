import getAirportMap from './getAirportMap';

const getRoute = trips => {
  if (!Array.isArray(trips) || trips.length === 0) {
    return null;
  }

  const tripSize = trips.length;
  const firstTrip = trips[0];
  const lastTrip = trips[tripSize - 1];
  const airportMap = getAirportMap(trips);

  const firstTripOriginCity = airportMap[firstTrip.originCode].placeName;
  const firstTripDestinationCity = airportMap[firstTrip.destinationCode].placeName;
  const lastTripDestinationCity = airportMap[lastTrip.destinationCode].placeName;

  if (tripSize === 1) {
    return {
      originCity: firstTripOriginCity,
      destinationCity: firstTripDestinationCity,
    };
  }

  if (tripSize === 2) {
    if (
      firstTrip.originCode === lastTrip.destinationCode &&
      firstTrip.destinationCode === lastTrip.originCode
    ) {
      return { originCity: firstTripOriginCity, destinationCity: firstTripDestinationCity };
    }

    return { originCity: firstTripOriginCity, destinationCity: lastTripDestinationCity };
  }

  return { originCity: firstTripOriginCity, destinationCity: lastTripDestinationCity };
};

export default getRoute;
