import getServicesWithQuantities from './getServicesWithQuantities';
import getItemServices from './getItemServices';

const getItemServicesWithQuantities = (services, subItems) => {
  if (!Array.isArray(services)) {
    return [];
  }

  const itemServices = getItemServices(services);

  return getServicesWithQuantities(itemServices, subItems);
};

export default getItemServicesWithQuantities;
