import { CREATE_FLIGHT_SEARCH, FLIGHT_SEARCH_FETCHED } from '../../actions/flight';
import { SEARCHING_HOTELS } from '../../actions/hotel';
import { SEARCHING_CARS } from '../../actions/car';
import { CONFIRM_MSEARCH_RESULTS, CART_FETCHED } from '../../actions/common';

export default function travelerCount(state = {}, action) {
  switch (action.type) {
    case CREATE_FLIGHT_SEARCH:
      if (!Object.keys(state).length) {
        return {
          adults: action.params.adults,
          children: action.params.children,
          infants: action.params.infants,
        };
      }
      return state;

    case SEARCHING_HOTELS:
      if (!Object.keys(state).length) {
        return {
          adults: action.params.adults,
          children: action.params.children,
          infants: [],
        };
      }
      return state;

    case SEARCHING_CARS:
      if (!Object.keys(state).length) {
        return {
          adults: [{}],
          children: [],
          infants: [],
        };
      }
      return state;

    case FLIGHT_SEARCH_FETCHED:
      if (!Object.keys(state).length) {
        return {
          adults: action.search.adults,
          children: action.search.children,
          infants: action.search.infants,
        };
      }
      return state;

    case CONFIRM_MSEARCH_RESULTS:
      if (!Object.keys(state).length) {
        return {
          adults: action.data.adults,
          children: action.data.children,
          infants: action.data.infants,
        };
      }
      return state;

    case CART_FETCHED:
      return action.cart.travelerTypes;

    default:
      return state;
  }
}
