import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const FETCHING_COUNTRIES = 'FETCHING_COUNTRIES';
function fetchingCountries(id) {
  return {
    type: FETCHING_COUNTRIES,
    id,
  };
}

export const COUNTRIES_FETCHED = 'COUNTRIES_FETCHED';
function countriesFetched(countries) {
  return {
    type: COUNTRIES_FETCHED,
    countries,
  };
}

export function fetchCountries(id) {
  return dispatch => {
    dispatch(fetchingCountries(id));
    return httpClient.get(routes.api.countries()).then(({ data }) => {
      dispatch(countriesFetched(data));
      return data;
    });
  };
}
