import React, { useState, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { Tabs as RTabs } from '@wtag/rcl-rtabs';
import PropTypes from 'prop-types';
import { Header } from '@wtag/rcl-typography';
import DealsList from './DealsList';

import TopDeals from '../TopDeals';
import '../styles.scss';

const DealsCatalogue = ({ dealCategories }) => {
  const [selectedTabKey, setSelectedTabKey] = useState(0);

  const onTabChange = selectedTabIndex => {
    setSelectedTabKey(selectedTabIndex);
  };

  const dealsCatalogueItems = useMemo(() => {
    const additionalItems = dealCategories.map((category, index) => ({
      key: index + 1,
      title: category.label,
      getContent: () => <DealsList category={category.name} />,
    }));

    const allItems = [
      {
        key: 0,
        title: I18n.t('public.homepage.all_deals'),
        getContent: () => <DealsList category="all" />,
      },
      ...additionalItems,
    ];

    return allItems;
  }, [dealCategories]);

  return (
    <div className="grid justify-center deals-catalogue">
      <div className="deals-catalogue__image-holder">
        <img
          className="deals-catalogue__image"
          src="https://images.unsplash.com/42/U7Fc1sy5SCUDIu4tlJY3_NY_by_PhilippHenzler_philmotion.de.jpg?ixlib=rb-0.3.5&s=871b5f24e4c6c00ce17ca667a53a6ffa&auto=format&fit=crop&w=1961&q=80"
          alt="deal"
        />
      </div>
      <div className="col-10 col-bleed">
        <div className="deals-catalogue__top-deals">
          <div className="col-grid align-center deals-catalogue__top-deals-header">
            <Header level={4} weight="medium">
              {I18n.t('public.homepage.top_deals')}
            </Header>
          </div>
          <TopDeals showHeader={false} />
        </div>

        <div className="deals-catalogue-deals-list__rtabs">
          <RTabs
            items={dealsCatalogueItems}
            selectedTabKey={selectedTabKey}
            onChange={onTabChange}
          />
        </div>
      </div>
    </div>
  );
};

DealsCatalogue.propTypes = {
  dealCategories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default hot(module)(DealsCatalogue);
