import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';
import { Link } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import classNames from 'classnames';
import { hot } from 'react-hot-loader';
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import Pagination from 'sharedWebpack/Pagination';
import SortingPill from 'sharedWebpack/SortingPill';
import DateFilterWrapper from 'sharedWebpack/DateFilterWrapper';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import useFilterQueryHandler from 'sharedWebpack/helpers/hooks/useFilterQueryHandler';
import { DEFAULT_PAGE_NUMBER } from 'sharedWebpack/helpers/constants/common';
import OrderFilterLabel from './OrderFilterLabel';
import OrderFilterWrapper from '../../../admin/Filters/OrderFilterWrapper';
import Table from './Table';
import '../styles.scss';

const List = props => {
  const {
    actionsRenderer,
    hideArchived,
    hideCreateOrder,
    personId,
    organizationId,
    displaySortingPills,
    additionalQueryParams,
    ordersPath,
    displayColumns,
    withTopPadding,
    layman,
    urls,
    lessPage,
    setOrderId,
  } = props;

  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const [travelersOptions, setTravelersOptions] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [travelArrangerPersonOptions, setTravelArrangerPersonOptions] = useState([]);
  const [travelArrangerAgentOptions, setTravelArrangerAgentOptions] = useState([]);
  const [orderState, setOrderState] = useState([]);

  const mappedStates = [
    { label: 'Offered', value: 'offered' },
    { label: 'Reserved', value: 'reserved' },
    { label: 'Ordered', value: 'ordered' },
    { label: 'Paid', value: 'paid' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Pending Cancellation', value: 'pending_cancellation' },
  ];

  const [query, setQuery] = useQueryParams({
    q: withDefault(StringParam, ''),
    organizationIDs: withDefault(ArrayParam, []),
    page: NumberParam,
    scope: StringParam,
    sortColumn: StringParam,
    sortDirection: StringParam,
    travelerIds: withDefault(ArrayParam, []),
    travelArrangerPersonIds: withDefault(ArrayParam, []),
    travelArrangerAgentIds: withDefault(ArrayParam, []),
    destinationList: withDefault(ArrayParam, []),
    travelStartsFrom: StringParam,
    travelStartsTo: StringParam,
    travelEndsFrom: StringParam,
    travelEndsTo: StringParam,
    onTravelDate: StringParam,
    currentStates: withDefault(ArrayParam, []),
  });

  const {
    q,
    page,
    sortColumn,
    sortDirection,
    travelerIds,
    travelArrangerPersonIds,
    travelArrangerAgentIds,
    travelStartsFrom,
    travelStartsTo,
    travelEndsFrom,
    travelEndsTo,
    destinationList,
    onTravelDate,
    currentStates,
  } = query;

  const showFilterReset = [
    travelerIds,
    travelArrangerPersonIds,
    travelArrangerAgentIds,
    travelStartsFrom,
    travelStartsFrom,
    travelStartsTo,
    travelEndsFrom,
    travelEndsTo,
    destinationList,
    onTravelDate,
    currentStates,
  ].some(value => (Array.isArray(value) ? value.length > 0 : value));

  const [localSearchQuery, setLocalSearchQuery] = useState(q);
  const componentMounted = useRef(false);
  const handleListFilter = useFilterQueryHandler(setQuery);

  const setInitialParams = (data, searchQuery) => {
    // We only set the params onces when there is nothing in the url
    if (!query.page) {
      setQuery(
        {
          q: searchQuery,
          page: data.pagination.currentPage,
          sortColumn: data.sort.column,
          sortDirection: data.sort.direction,
        },
        // use replaceIn as we don't want another navigation to be added to the browser history
        'replaceIn',
      );
    }
  };

  const associatedTravelerIds = travelerIds.length === 0 && personId ? [personId] : travelerIds;

  const fetchOrders = async ({ searchQuery, newPage, column, direction }) => {
    try {
      const { data } = await httpClient.get(ordersPath, {
        params: {
          q: searchQuery,
          page: newPage,
          column,
          direction,
          person_id: personId,
          scope: additionalQueryParams && additionalQueryParams.scope,
          organization_id: organizationId,
          per_page: lessPage ? 5 : 10,
          traveler: associatedTravelerIds,
          travel_arranger_people: travelArrangerPersonIds,
          travel_arranger_agents: travelArrangerAgentIds,
          destinations: destinationList,
          travel_starts_from: travelStartsFrom,
          travel_starts_to: travelStartsTo,
          travel_ends_from: travelEndsFrom,
          travel_ends_to: travelEndsTo,
          on_travel_date: onTravelDate,
          current_states: currentStates,
        },
      });

      if (componentMounted.current) {
        setIsLoading(false);
        setOrders(data.orders);
        setTotalPage(data.pagination.totalPages);
        setOrderState(mappedStates);
        setInitialParams(data, searchQuery);
      }
    } catch (error) {
      throw error;
    }
  };

  const personOptionsRoute = (params = {}) => {
    if (layman) {
      return routes.public.people.users(params);
    }

    return routes.admin.people.list(params);
  };

  const destinationRoute = (params = {}) => {
    if (layman) {
      return routes.public.orderDestinations.list(params);
    }

    return routes.admin.orderDestinations.list(params);
  };

  const userOptionsRoute = (params = {}) => (layman ? '' : routes.admin.users.list(params));

  const defaultUserMapper = user => ({
    label: user.name,
    value: user.id,
  });

  const getMappedAgentData = (data, mapper = defaultUserMapper) => data.map(mapper);

  const fetchFilterOptions = async () => {
    const travelerAndArrangerIds = [...travelerIds, ...travelArrangerPersonIds].filter(
      (item, index, array) => array.indexOf(item) === index,
    );

    const [personOptionsResponse, destinationOptionsResponse] = await Promise.all([
      httpClient.get(personOptionsRoute({ 'person_ids[]': travelerAndArrangerIds })),
      // httpClient.get(routes.admin.people.list({ 'person_ids[]': travelerAndArrangerIds })),
      httpClient.get(destinationRoute({})),
    ]);

    const mappedData = personOptionsResponse.data.people.map(person => ({
      label: person.name,
      value: person.id,
    }));
    setTravelersOptions(mappedData);
    setTravelArrangerPersonOptions(mappedData);

    if (destinationList.length > 0) {
      setDestinationOptions(destinationList.map(dest => ({ label: dest, value: dest })));
    } else {
      setDestinationOptions(
        destinationOptionsResponse.data.destinations.map(destination => ({
          label: destination,
          value: destination,
        })),
      );
    }

    if (!layman) {
      const userOptionsResponse = await httpClient.get(userOptionsRoute({}));
      const mappedAgentData = getMappedAgentData(userOptionsResponse.data.users);
      setTravelArrangerAgentOptions(mappedAgentData);
    }
  };

  const searchPersonFilterOptions = async (input, type) => {
    const { data } = await httpClient.get(personOptionsRoute({ q: input }));
    const mappedData = data.people.map(person => ({ label: person.name, value: person.id }));

    if (type === 'traveller') {
      setTravelersOptions(mappedData);
    } else {
      setTravelArrangerPersonOptions(mappedData);
    }
  };

  const searchUserFilterOptions = async input => {
    const { data } = await httpClient.get(userOptionsRoute({ q: input }));
    const mappedData = getMappedAgentData(data.users);
    setTravelArrangerAgentOptions(mappedData);
  };

  const debouncedSearchTraveler = debounce(500, input =>
    searchPersonFilterOptions(input, 'traveller'),
  );
  const debouncedSearchTravelArrangerPerson = debounce(500, input =>
    searchPersonFilterOptions(input, 'arranger'),
  );
  const debouncedSearchDestination = debounce(500, async input => {
    const { data } = await httpClient.get(destinationRoute({ q: input }));
    setDestinationOptions(
      data.destinations.map(destination => ({
        label: destination,
        value: destination,
      })),
    );
  });

  const debouncedSearchTravelArrangerAgent = debounce(500, input => searchUserFilterOptions(input));

  const setSearchQueryInParams = React.useRef(
    debounce(500, value => setQuery({ q: value, page: DEFAULT_PAGE_NUMBER })),
  ).current;

  const setSearchQuery = value => {
    setIsLoading(true);
    // We keep a local state here as we don't want to do an AJAX call for each key the user presses
    // This allows us to keep the UI responsive and fetch new data with a delay
    setLocalSearchQuery(value);
    setSearchQueryInParams(value);
  };

  const updateSorting = (column, direction) => {
    setIsLoading(true);
    setQuery({ sortColumn: column, sortDirection: direction });
  };

  useEffect(() => {
    if (typeof additionalQueryParams === 'object') {
      setQuery({ ...additionalQueryParams }, 'replaceIn');
    }
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    componentMounted.current = true;
    fetchOrders({ searchQuery: q, newPage: page, column: sortColumn, direction: sortDirection });
    return () => {
      componentMounted.current = false;
    };
  }, [
    q,
    page,
    sortColumn,
    sortDirection,
    JSON.stringify(travelerIds),
    JSON.stringify(destinationList),
    JSON.stringify(travelArrangerPersonIds),
    JSON.stringify(travelArrangerAgentIds),
    onTravelDate,
    travelStartsFrom,
    travelStartsTo,
    travelEndsFrom,
    travelEndsTo,
    currentStates,
  ]);

  const setDateRangeAndFilter = (dates, filterName) => {
    if (dates.startDate && dates.endDate) {
      if (filterName === 'travel_starts') {
        setQuery({
          travelStartsFrom: dates.startDate.format('YYYY-MM-DD'),
          travelStartsTo: dates.endDate.format('YYYY-MM-DD'),
        });
      } else {
        setQuery({
          travelEndsFrom: dates.startDate.format('YYYY-MM-DD'),
          travelEndsTo: dates.endDate.format('YYYY-MM-DD'),
        });
      }
    }
    setQuery({ page: DEFAULT_PAGE_NUMBER });
  };

  const clearFiltering = () => {
    setQuery({
      travelerIds: undefined,
      travelArrangerPersonIds: undefined,
      travelArrangerAgentIds: undefined,
      destinationList: undefined,
      travelStartsFrom: undefined,
      travelStartsTo: undefined,
      travelEndsFrom: undefined,
      travelEndsTo: undefined,
      onTravelDate: undefined,
      currentStates: undefined,
    });
  };

  return (
    <div
      className={classNames('orders-list grid', {
        'orders-list--with-top-padding': withTopPadding,
      })}
    >
      <div className="col-12 orders-list__search">
        <div className="orders-list__search-container orders-list__filter--wide">
          <Input
            size="tiny"
            placeholder={I18n.t('admin.components.orders.list.search_placeholder')}
            preIcon={<Icon name="search" />}
            value={localSearchQuery}
            onChange={setSearchQuery}
          />
        </div>
      </div>
      <div className="col-12 col-bleed-y">
        <div className={hideArchived && hideCreateOrder ? 'col-12 col-bleed' : 'col-6 col-bleed'}>
          <div className="orders-list__filter-sort">
            {displaySortingPills.id && (
              <div className="orders-list__filter-sort-item">
                <SortingPill
                  size="small"
                  name={I18n.t('admin.components.orders.list.sort.id')}
                  thisSortColumn="id"
                  currentSortColumn={sortColumn}
                  currentSortDirection={sortDirection}
                  onChange={updateSorting}
                />
              </div>
            )}
            {displaySortingPills.destination && (
              <div className="orders-list__filter-sort-item">
                <SortingPill
                  size="small"
                  name={I18n.t('admin.components.orders.list.sort.destination')}
                  thisSortColumn="destination"
                  currentSortColumn={sortColumn}
                  currentSortDirection={sortDirection}
                  onChange={updateSorting}
                />
              </div>
            )}
            {displaySortingPills.lastUpdated && (
              <div className="orders-list__filter-sort-item">
                <SortingPill
                  size="small"
                  name={I18n.t('admin.components.orders.list.sort.last_updated')}
                  thisSortColumn="updated_at"
                  currentSortColumn={sortColumn}
                  currentSortDirection={sortDirection}
                  onChange={updateSorting}
                />
              </div>
            )}

            {displaySortingPills.bookingDate && (
              <div className="orders-list__filter-sort-item">
                <SortingPill
                  size="small"
                  name={I18n.t('admin.components.orders.table.booked_on_header')}
                  thisSortColumn="booked_on"
                  currentSortColumn={sortColumn}
                  currentSortDirection={sortDirection}
                  onChange={updateSorting}
                />
              </div>
            )}

            {displaySortingPills.startDate && (
              <div className="orders-list__filter-sort-item">
                <SortingPill
                  size="small"
                  name={I18n.t('admin.components.orders.table.start_date')}
                  thisSortColumn="travel_starts_at"
                  currentSortColumn={sortColumn}
                  currentSortDirection={sortDirection}
                  onChange={updateSorting}
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-grid direction-row col-bleed justify-space-between orders-list__filters-container">
          <div className="col-grid col-12 direction-row col-bleed orders-list__filters-all">
            <div className="orders-list__filters travelers-filter">
              <OrderFilterWrapper
                selectedValues={travelerIds}
                options={travelersOptions}
                onChange={ids => handleListFilter('travelerIds', ids)}
                onSearch={debouncedSearchTraveler}
                label={
                  <div className="orders-list__filters-label">
                    <div className="orders-list__filters-label-icon">
                      <Icon name="person" size="small" />
                    </div>
                    {I18n.t('public.components.orders.filters.travellers')}
                  </div>
                }
                placeholderText={I18n.t('public.components.orders.filters.placeholder_text')}
              />
            </div>
            <div className="orders-list__filters">
              <OrderFilterWrapper
                selectedValues={destinationList}
                options={destinationOptions}
                onChange={destination => handleListFilter('destinationList', destination)}
                onSearch={debouncedSearchDestination}
                label={
                  <div className="orders-list__filters-label">
                    <div className="orders-list__filters-label-icon">
                      <Icon name="location" size="small" />
                    </div>
                    {I18n.t('public.components.orders.filters.destination')}
                  </div>
                }
                placeholderText={I18n.t('public.components.orders.filters.placeholder_text')}
              />
            </div>
            <div className="orders-list__filters">
              <OrderFilterWrapper
                selectedValues={travelArrangerPersonIds}
                options={travelArrangerPersonOptions}
                onChange={arrangers => handleListFilter('travelArrangerPersonIds', arrangers)}
                onSearch={debouncedSearchTravelArrangerPerson}
                label={
                  <div className="orders-list__filters-label">
                    <div className="orders-list__filters-label-icon">
                      <Icon name="person" size="small" />
                    </div>
                    {I18n.t('public.components.orders.filters.travel_arrangers')}
                  </div>
                }
                placeholderText={I18n.t('public.components.orders.filters.placeholder_text')}
              />
            </div>
            {!layman && (
              <div className="orders-list__filters">
                <OrderFilterWrapper
                  selectedValues={travelArrangerAgentIds}
                  options={travelArrangerAgentOptions}
                  onChange={agents => handleListFilter('travelArrangerAgentIds', agents)}
                  onSearch={debouncedSearchTravelArrangerAgent}
                  label={
                    <OrderFilterLabel
                      label={I18n.t('public.components.orders.filters.booked_by_agent')}
                    />
                  }
                  placeholderText={I18n.t('public.components.orders.filters.placeholder_text')}
                />
              </div>
            )}
            <DateFilterWrapper
              selectedDateLabel={I18n.t('public.components.orders.filters.travel_starts')}
              startDate={travelStartsFrom}
              endDate={travelStartsTo}
              enableDateRangeSelection={true}
              onChange={dates => setDateRangeAndFilter(dates, 'travel_starts')}
            />
            <DateFilterWrapper
              selectedDateLabel={I18n.t('public.components.orders.filters.on_travel')}
              startDate={onTravelDate}
              onChange={date => handleListFilter('onTravelDate', date.format('YYYY-MM-DD'))}
            />
            <DateFilterWrapper
              selectedDateLabel={I18n.t('public.components.orders.filters.travel_ends')}
              startDate={travelEndsFrom}
              endDate={travelEndsTo}
              enableDateRangeSelection={true}
              onChange={dates => setDateRangeAndFilter(dates, 'travel_ends')}
            />

            <div className="orders-list__filters">
              <OrderFilterWrapper
                selectedValues={currentStates}
                options={orderState}
                searchable={false}
                onChange={state => handleListFilter('currentStates', state)}
                label={
                  <div className="orders-list__filters-label">
                    <div className="orders-list__filters-label-icon">
                      <Icon name="orders" size="small" />
                    </div>
                    {I18n.t('public.components.orders.filters.state')}
                  </div>
                }
              />
            </div>

            {showFilterReset && (
              <div className="orders-list__filters">
                <Button
                  version="v2"
                  size="small"
                  label={I18n.t('admin.components.organizations.list.filter.reset')}
                  onClick={clearFiltering}
                />
              </div>
            )}
          </div>
          {(!hideArchived || !hideCreateOrder) && (
            <div className="col-6 align-right orders-list__action">
              {!hideArchived && (
                <Link
                  href={urls.archived}
                  type="button"
                  size="small"
                  modifier="default"
                  className="orders-list__action-button"
                >
                  {I18n.t('admin.components.orders.list.actions.archived')}
                </Link>
              )}
              {!hideCreateOrder && (
                <Link
                  href={urls.new}
                  type="button"
                  size="small"
                  modifier="primary"
                  className="orders-list__action-button"
                >
                  {displayColumns.selectAction
                    ? I18n.t('admin.orders.actions.add_new_order')
                    : I18n.t('admin.components.orders.list.actions.create')}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="orders-list__collection col-12">
        <Table
          orders={orders}
          actionsRenderer={actionsRenderer}
          displayColumns={displayColumns}
          isLoading={isLoading}
          url={urls.new}
          setOrderId={setOrderId}
        />
      </div>
      <div className="orders-list__pagination col-12 col-bleed-y">
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPaginationClick={newPage => {
            setIsLoading(true);
            setQuery({ page: newPage });
          }}
        />
      </div>
    </div>
  );
};

List.defaultProps = {
  actionsRenderer: null,
  hideArchived: false,
  withTopPadding: false,
  hideCreateOrder: false,
  personId: null,
  organizationId: null,
  tableProps: undefined,
  urls: {},
  lessPage: false,
  setOrderId: null,
  displaySortingPills: {
    id: true,
    destination: true,
    lastUpdated: true,
    bookingDate: false,
    startDate: false,
  },
  additionalQueryParams: null,
  displayColumns: {
    id: true,
    title: true,
    destination: true,
    travellers: true,
    products: true,
    state: true,
    netAmount: true,
    startDate: false,
    endDate: false,
    actions: true,
    selectAction: false,
  },
  layman: false,
};

List.propTypes = {
  actionsRenderer: PropTypes.func,
  hideArchived: PropTypes.bool,
  hideCreateOrder: PropTypes.bool,
  personId: PropTypes.number,
  lessPage: PropTypes.bool,
  displaySortingPills: PropTypes.shape({
    id: PropTypes.bool,
    destination: PropTypes.bool,
    lastUpdated: PropTypes.bool,
    bookingDate: PropTypes.bool,
    startDate: PropTypes.bool,
  }),
  tableProps: PropTypes.shape({
    id: PropTypes.bool,
    title: PropTypes.bool,
    destination: PropTypes.bool,
    travellers: PropTypes.bool,
    products: PropTypes.bool,
    state: PropTypes.bool,
    netAmount: PropTypes.bool,
    startDate: PropTypes.bool,
    endDate: PropTypes.bool,
  }),
  additionalQueryParams: PropTypes.shape({
    scope: PropTypes.string,
  }),
  displayColumns: PropTypes.shape({
    id: PropTypes.bool,
    orderId: PropTypes.bool,
    travellers: PropTypes.bool,
    invoiceDate: PropTypes.bool,
    dueDate: PropTypes.bool,
    amount: PropTypes.bool,
    actions: PropTypes.bool,
    selectAction: PropTypes.bool,
  }),
  ordersPath: PropTypes.string.isRequired,
  withTopPadding: PropTypes.bool,
  organizationId: PropTypes.bool,
  urls: PropTypes.string,
  setOrderId: PropTypes.func,
  layman: PropTypes.bool,
};

export default hot(module)(withQueryParamsProvider(List));
