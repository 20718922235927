import React, { useState } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import Button from '@wtag/rcl-button';
import SelectBox from '@wtag/rcl-select-box';

const ExchangeForm = ({
  approvers,
  potentialApprovers,
  requestId,
  setModalOpen,
  fetchRequestDetails,
}) => {
  const [swapId, setSwapId] = useState(null);
  const [swapWithId, setSwapWithId] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const potentialApproversOptions = potentialApprovers.map(approver => ({
    label: approver.name,
    value: approver.id,
  }));

  const approversOptions = approvers.map(approver => ({
    label: approver.name,
    value: approver.id,
  }));

  const exchangeApprover = async () => {
    setDisableButton(true);
    let approverIds = approvers.map(approver => approver.id);
    approverIds = approverIds.filter(approverId => approverId !== swapId);
    approverIds.push(swapWithId);
    const { data } = await httpClient.put(
      routes.public.approvals.requests.exchange({ id: requestId }),
      {
        approver_ids: approverIds,
      },
    );

    if (data.error === null) {
      fetchRequestDetails();
    }
    setModalOpen(false);
  };

  return (
    <React.Fragment>
      <div className="left-panel__exchange-approver-select-box">
        <SelectBox
          size="tiny"
          label={I18n.t('public.components.approvals.requests.exchange_approvers.labels.traveler')}
          placeholderText={I18n.t(
            'public.components.approvals.requests.exchange_approvers.placeholders.exchange',
          )}
          width="full"
          options={approversOptions}
          onChange={selectedOption => {
            setSwapId(selectedOption ? selectedOption.value : null);
          }}
          value={
            approversOptions &&
            approversOptions.length &&
            approversOptions.find(selectedApprover => selectedApprover.value === swapId)
          }
        />
      </div>
      <div className="left-panel__exchange-approver-select-box">
        <SelectBox
          size="tiny"
          label={I18n.t(
            'public.components.approvals.requests.exchange_approvers.labels.new_traveler',
          )}
          placeholderText={I18n.t(
            'public.components.approvals.requests.exchange_approvers.placeholders.exchange_with',
          )}
          width="full"
          options={potentialApproversOptions}
          onChange={selectedOption => {
            setSwapWithId(selectedOption ? selectedOption.value : null);
          }}
          value={
            potentialApproversOptions &&
            potentialApproversOptions.length &&
            potentialApproversOptions.find(
              selectedApprover => selectedApprover.value === swapWithId,
            )
          }
        />
      </div>

      <div>
        <Button
          version="v2"
          size="small"
          type="primary"
          label={I18n.t('public.components.approvals.requests.exchange_approvers.action')}
          onClick={exchangeApprover}
          disabled={disableButton}
        />
      </div>
    </React.Fragment>
  );
};

ExchangeForm.propTypes = {
  approvers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  potentialApprovers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  requestId: PropTypes.number.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  fetchRequestDetails: PropTypes.func.isRequired,
};
export default ExchangeForm;
