import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { ensureCart, fetchCart, showError } from '../common';

export const HOTEL_DETAILS_FETCHED = 'HOTEL_DETAILS_FETCHED';
function hotelDetailsFetched(data) {
  return {
    type: HOTEL_DETAILS_FETCHED,
    details: data,
  };
}

export function fetchHotelDetailsWithoutHandling(id, callbackParams) {
  return dispatch =>
    httpClient.get(routes.api.hotels.result({ resultId: id, callbackParams })).then(({ data }) => {
      dispatch(hotelDetailsFetched(data));
      return data;
    });
}

export const FETCHING_HOTEL_DETAILS = 'FETCHING_HOTEL_DETAILS';
export function fetchHotelDetails(id, callbackParams) {
  return dispatch => {
    dispatch({ type: FETCHING_HOTEL_DETAILS, id, callbackParams });
    return dispatch(fetchHotelDetailsWithoutHandling(id, callbackParams)).catch(error =>
      dispatch(showError('Fetching hotel details failed for unknown reasons', error)),
    );
  };
}

export const ADD_HOTEL_TO_CART = 'ADD_HOTEL_TO_CART';
export function addHotelToCart(details, roomId, callbackParams, justification = null) {
  return dispatch =>
    dispatch(ensureCart(callbackParams)).then(cartId => {
      dispatch({
        type: ADD_HOTEL_TO_CART,
        searchId: details.searchId,
        roomId,
        details,
        callbackParams,
      });
      return httpClient
        .post(routes.api.carts.items.addHotel({ cartId }), {
          result_id: details.id,
          room_id: roomId,
          callback_params: callbackParams,
          justification,
        })
        .then(({ data }) => {
          dispatch(fetchCart(cartId, callbackParams));
          return data;
        });
    });
}
