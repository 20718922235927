import { BOOKING_COMPLETED } from '../../actions/common';

export default function redirectURL(state = null, action) {
  switch (action.type) {
    case BOOKING_COMPLETED:
      return action.url;

    default:
      return state;
  }
}
