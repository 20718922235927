import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import Amount from 'sharedWebpack/Amount';
import Segment from 'sharedWebpack/ItemWrapper/Flight/Segment';
import { EmphasisTag, Link } from '@wtag/react-comp-lib';
import AirlineLogos from '../SearchResult/flight/AirlineLogos';
import BaggageLabel from '../SearchResult/flight/BaggageLabel';
import ApprovalRequiredLabel from './ApprovalRequiredLabel';
import OutOfPolicyLabel from '../components/OutOfPolicyLabel';
import PreferredLabel from '../components/PreferredLabel';
import FlightDurationAndStopOvers from '../SearchResult/flight/FlightDurationAndStopOvers/FlightDurationAndStopOversContainer';
import AirportDetails from '../SearchResult/flight/AirportDetails/AirportDetailsContainer';
import './styles.scss';

const FlightLineItem = props => {
  const trips = props.trips.map(trip => {
    const id = trip.id;
    const stopovers = trip.stopovers;
    const durationInMin = trip.durationInMinutes;
    const originTerminal = trip.originTerminal;
    const destinationTerminal = trip.destinationTerminal;
    const segments = trip.segments;
    const cabinClass = trip.cabinClass;
    const bookingClasses = trip.bookingClasses;
    const fareType = trip.fareType;
    const punctuality = trip.punctuality;
    const overnight = trip.overnight;
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];
    const origin = firstSegment.originCode;
    const departureTime = firstSegment.departureLocaltime;
    const arrivalTime = lastSegment.arrivalLocaltime;
    const destination = lastSegment.destinationCode;
    const key = `${origin}${destination}`;
    const wifiPresent = segments.map(segment => segment.wifiAvailable).includes(true);
    const cateringPresent = segments.map(segment => segment.catering.length > 0);
    const baggagePresent = segments.map(segment => segment.freeBaggageIncluded).includes(true);
    const marketingAirlineCode = segments.map(segment => segment.marketingCarrierCode);

    const findAirportInformation = code => trip.airports.find(info => info && info.code === code);

    const allSegmentDetails = () => {
      const tripSegment = trip.segments;
      return tripSegment.map(segment => (
        <Segment
          key={`${segment.originCode}-${segment.destinationCode}`}
          segment={segment}
          stopovers={stopovers}
          findAirport={findAirportInformation}
        />
      ));
    };

    const showSegmentSidePanel = () => {
      props.setSidePanelTitle(I18n.t('components.flight_info.header'));
      props.setSidePanelBody(allSegmentDetails());
      props.setOpenSidePanel();
    };

    return (
      <div key={key}>
        <div className="grid">
          <div className="col-lg-6 col-md-12">
            <AirlineLogos className="flight__airline-logo" carriers={marketingAirlineCode} />
          </div>
          <div className="col-lg-5 col-md-12 flight__services--section">
            <div className="flight__services">
              <IconButton
                icon={<Icon name="wifi" showBGColor={true} color="success" />}
                label={I18n.t('components.flight.wifi.label')}
                size="large"
                disabled={!wifiPresent}
                color={wifiPresent ? 'success' : 'tertiary'}
              />
              <IconButton
                icon={<Icon name="meal" showBGColor={true} color="success" />}
                label={I18n.t('components.flight.meal.label')}
                size="large"
                disabled={!cateringPresent}
                color={cateringPresent ? 'success' : 'tertiary'}
              />
              <IconButton
                icon={<Icon name="baggage" showBGColor={true} color="success" />}
                label={I18n.t('components.flight.baggage.label')}
                size="large"
                disabled={!baggagePresent}
                color={baggagePresent ? 'success' : 'tertiary'}
              />
            </div>
          </div>
        </div>

        <div className="grid">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <AirportDetails code={origin} localTime={departureTime} terminal={originTerminal} />
          </div>
          <div className="col-lg-3 flight__duration">
            <FlightDurationAndStopOvers
              segments={segments}
              stopovers={stopovers}
              id={id}
              durationInMinutes={durationInMin}
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <AirportDetails
              code={destination}
              localTime={arrivalTime}
              terminal={destinationTerminal}
            />
          </div>
          <div className="col-lg-3 col-md-12 flight__prices">
            <div className="flight__prices__gross-price">
              <Amount currency={props.item.currency} value={props.item.grossTotal} />
            </div>
          </div>
        </div>

        <div className="car-result-card__divider" />

        <div className="grid">
          <div className="col-lg-5 col-md-12 flight__airlines">
            {segments.map(segment => (
              <EmphasisTag
                text={segment.marketingCarrierCode + segment.marketingFlightNumber}
                size="tiny"
                type="neutral"
                icon={<Icon name="aeroplane" />}
              />
            ))}
            <Link onClick={() => showSegmentSidePanel()} modifier="default" size="tiny">
              <span className="flight-item-trip-airlines-segments-itenary">
                {I18n.t('admin.components.orders.items_tab.placeholder.show_itinerary')}
              </span>
            </Link>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="flight__informations justify-end">
              {props.item.preferred && <PreferredLabel />}
              {props.item.outOfPolicy && <OutOfPolicyLabel />}
              {props.item.approvalRequired && <ApprovalRequiredLabel />}
              <EmphasisTag
                text={
                  <BaggageLabel
                    baggageInfo={segments.map(segment => segment.baggagePiecesIncluded)}
                  />
                }
                size="tiny"
                icon={<Icon name="baggage" />}
              />

              {punctuality !== 0 && (
                <EmphasisTag
                  text={
                    <span>
                      <Amount value={punctuality} /> %
                    </span>
                  }
                  size="tiny"
                  icon={<Icon name="punctuality" />}
                />
              )}

              <EmphasisTag
                text={
                  overnight
                    ? I18n.t('components.ibe.results.overnight')
                    : I18n.t('components.ibe.results.daylight')
                }
                size="tiny"
              />
              {cabinClass && (
                <EmphasisTag text={I18n.t(cabinClass, { scope: 'cabin_classes' })} size="tiny" />
              )}
              {bookingClasses && bookingClasses.length > 0 && (
                <EmphasisTag text={trip.bookingClasses.join(', ')} size="tiny" />
              )}
              {fareType && <EmphasisTag text={fareType} size="tiny" type="success" />}
            </div>
          </div>
        </div>
      </div>
    );
  });
  return <div className={props.className}>{trips}</div>;
};

FlightLineItem.defaultProps = {
  className: null,
};

FlightLineItem.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      destinationCode: PropTypes.string.isRequired,
      originCode: PropTypes.string.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
  setSidePanelTitle: PropTypes.func.isRequired,
  setSidePanelBody: PropTypes.func.isRequired,
  setOpenSidePanel: PropTypes.func.isRequired,
  item: PropTypes.shape({
    approvalRequired: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    grossTotal: PropTypes.string.isRequired,
    preferred: PropTypes.bool.isRequired,
    outOfPolicy: PropTypes.bool.isRequired,
    channel: PropTypes.shape({
      identifier: PropTypes.string.isRequired,
      account: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default FlightLineItem;
