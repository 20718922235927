import React, { useState } from 'react';
import Input from '@wtag/rcl-input';
import Alert from 'sharedWebpack/Alert';
import Button from '@wtag/rcl-button';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import PropTypes from 'prop-types';

const EditPasswordForm = ({ userId, rootPath }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const updatePassword = async () => {
    const passwordParams = {
      password,
    };

    const { data } = await httpClient.patch(
      routes.public.authentication.passwords.update({ userId }),
      {
        password_reset: passwordParams,
      },
    );

    if (data.errors === null) window.location.replace(rootPath);
  };

  const submitForm = () => {
    updatePassword().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.errors);
      }
    });
  };

  const closeError = () => setError('');

  return (
    <div className="authentication">
      <div className="authentication-form grid">
        <div className="col-12">
          <Alert
            className="authentication-form__alert"
            hideClose={false}
            isVisible={!!error}
            onClose={closeError}
            type="success"
          >
            {error}
          </Alert>
          <div className="authentication-form__title">
            {I18n.t('public.components.authentication.passwords.edit.title')}
          </div>
          <div className="authentication-form__description--with-bottom-padding">
            {I18n.t('public.components.authentication.passwords.edit.description')}
          </div>
          <div className="col-12 col-bleed authentication-form__field--with-bottom-padding">
            <Input
              required={true}
              type="password"
              size="small"
              placeholder={I18n.t(
                'public.components.authentication.passwords.placeholders.password',
              )}
              label={I18n.t('public.components.authentication.passwords.labels.password')}
              onChange={value => setPassword(value)}
              value={password}
            />
          </div>
          <div className="col-12 col-bleed col-grid direction-row authentication-form__button--with-top-padding">
            <Button
              version="v2"
              size="normal"
              label={I18n.t('public.components.authentication.passwords.edit.reset_password')}
              onClick={submitForm}
              fullWidth={true}
              primary={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EditPasswordForm.propTypes = {
  userId: PropTypes.number.isRequired,
  rootPath: PropTypes.string.isRequired,
};

export default EditPasswordForm;
