const getCarLocationDetails = (airports, locationAttributes, isAirportSearchEnabled) => {
  let address;
  let dropOffAddress;
  let pickUpLatitude;
  let pickUpLongitude;
  let dropOffLatitude;
  let dropOffLongitude;

  if (isAirportSearchEnabled) {
    const { pickUpLocationAirportCode, dropOffLocationAirportCode } = locationAttributes;

    const pickUpAirport = airports[pickUpLocationAirportCode];
    const dropOffAirport = airports[dropOffLocationAirportCode];

    if (pickUpAirport && dropOffAirport) {
      address = `${pickUpAirport.name} (${pickUpAirport.code})`;
      dropOffAddress = `${dropOffAirport.name} (${dropOffAirport.code})`;
      pickUpLatitude = pickUpAirport.latitude;
      pickUpLongitude = pickUpAirport.longitude;
      dropOffLatitude = dropOffAirport.latitude;
      dropOffLongitude = dropOffAirport.longitude;
    }
  } else {
    const {
      pickUpGeoLocationAddress,
      dropOffGeoLocationAddress,
      pickUpGeoLocationLatitude,
      pickUpGeoLocationLongitude,
      dropOffGeoLocationLatitude,
      dropOffGeoLocationLongitude,
    } = locationAttributes;

    address = pickUpGeoLocationAddress;
    dropOffAddress = dropOffGeoLocationAddress;
    pickUpLatitude = pickUpGeoLocationLatitude;
    pickUpLongitude = pickUpGeoLocationLongitude;
    dropOffLatitude = dropOffGeoLocationLatitude;
    dropOffLongitude = dropOffGeoLocationLongitude;
  }

  return {
    address,
    dropOffAddress,
    pickUpLatitude,
    pickUpLongitude,
    dropOffLatitude,
    dropOffLongitude,
  };
};

export default getCarLocationDetails;
