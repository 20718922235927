import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PillTabs } from '@wtag/react-comp-lib';
import PillTabsList from './PillTabsList';
import PillTabsContent from './PillTabsContent';
import OtherAvailableFlightsWarning from './OtherAvailableFlightsWarning';
import LoadMore from './LoadMore';

const disableFlightGroup = (resultGroup, selectedFlightGroupsIdMap, selectedTrip) => {
  const selectedFilter =
    selectedFlightGroupsIdMap[selectedTrip] !== '' &&
    resultGroup.id === selectedFlightGroupsIdMap[selectedTrip];

  return selectedFilter;
};

const ResultTabs = ({
  tripId,
  trips,
  resultGroups,
  searchInProgress,
  selectedFlightGroups,
  numberOfResultsToShow,
  numberOfMatchedResultsToShow,
  renderedResultGroup,
  selectGroup,
  deselectGroup,
  populateResults,
  resetSelectGroup,
  onShowMoreMatchedResults,
  onShowMoreOtherResults,
  onOpenConfirmationModal,
  filterExcludeMatchingTrips,
  tabChange,
  isResultAvailable,
}) => {
  const tabs = [];
  const selectedFlightGroupIds = [];
  const selectedFlightGroupsIdMap = {};
  const selectedTrip = parseInt(tripId, 10);

  trips.forEach((_, index) => {
    selectedFlightGroupsIdMap[index + 1] = '';
  });

  Object.keys(selectedFlightGroups).forEach(value => {
    if (selectedFlightGroups[value].id) {
      selectedFlightGroupIds.push(selectedFlightGroups[value].id);
      selectedFlightGroupsIdMap[value] = selectedFlightGroups[value].id;
    }
  });

  trips.forEach((trip, tripCount) => {
    const selectedResults = [];
    const otherMatchedResults = [];
    const matchedResultLength = resultGroups.matches.length;
    const otherResultLength = resultGroups.others.length;
    const isDisabled = searchInProgress || selectedFlightGroupIds.length === 0;

    let otherResults;
    if (isResultAvailable) {
      populateResults(
        numberOfMatchedResultsToShow,
        resultGroups,
        disableFlightGroup,
        selectedFlightGroupsIdMap,
        selectedTrip,
        selectedResults,
        renderedResultGroup,
        selectGroup,
        deselectGroup,
        otherMatchedResults,
      );
      otherResults = resultGroups.others.slice(0, numberOfResultsToShow).map(resultGroup =>
        renderedResultGroup({
          resultGroup,
          selectCallback: resetSelectGroup,
          selectedTripMap: selectedFlightGroupsIdMap,
          selectedTrip,
          deselectGroup,
          disableCard: true,
        }),
      );
    }

    tabs.push({
      title: (
        <PillTabsList
          tripCount={tripCount}
          trip={trip}
          selectedFlightGroups={selectedFlightGroups}
        />
      ),
      getContent: () =>
        isResultAvailable && (
          <Fragment>
            <PillTabsContent
              tripCount={tripCount}
              searchInProgress={searchInProgress}
              trip={trip}
              selectedFlightGroups={selectedFlightGroups}
            >
              {selectedResults}
            </PillTabsContent>

            {filterExcludeMatchingTrips(selectedResults, otherMatchedResults, otherResults)}

            {otherMatchedResults}

            {matchedResultLength > 0 && matchedResultLength > numberOfMatchedResultsToShow && (
              <LoadMore onLoadMore={onShowMoreMatchedResults} />
            )}

            {otherResults.length > 0 && (
              <Fragment>
                <OtherAvailableFlightsWarning
                  isDisabled={isDisabled}
                  onDeselectAll={onOpenConfirmationModal}
                >
                  {otherResults}
                </OtherAvailableFlightsWarning>
                {otherResultLength > numberOfResultsToShow && (
                  <LoadMore onLoadMore={onShowMoreOtherResults} />
                )}
              </Fragment>
            )}
          </Fragment>
        ),
    });
  });

  return (
    // for pill tabs key is array item index. that's why we are decreasing the value by one to match item index i.e selectedTabKey={parseInt(this.props.match.params.tripId, 10) - 1}
    <PillTabs
      selectedTabKey={selectedTrip - 1}
      controlled={true}
      onChange={value => tabChange(value + 1)}
      items={tabs}
    />
  );
};

ResultTabs.propTypes = {
  tripId: PropTypes.string.isRequired,
  trips: PropTypes.shape([]).isRequired,
  resultGroups: PropTypes.shape({
    matches: PropTypes.shape([]),
    others: PropTypes.shape([]),
  }),
  searchInProgress: PropTypes.bool,
  selectedFlightGroups: PropTypes.shape({}).isRequired,
  numberOfResultsToShow: PropTypes.number,
  numberOfMatchedResultsToShow: PropTypes.number,
  renderedResultGroup: PropTypes.func,
  selectGroup: PropTypes.func,
  deselectGroup: PropTypes.func,
  populateResults: PropTypes.func,
  resetSelectGroup: PropTypes.func,
  onShowMoreMatchedResults: PropTypes.func,
  onShowMoreOtherResults: PropTypes.func,
  onOpenConfirmationModal: PropTypes.func,
  filterExcludeMatchingTrips: PropTypes.func,
  tabChange: PropTypes.func.isRequired,
  isResultAvailable: PropTypes.bool,
};

ResultTabs.defaultProps = {
  resultGroups: {
    matches: [],
    others: [],
  },
  searchInProgress: false,
  numberOfResultsToShow: 0,
  numberOfMatchedResultsToShow: 0,
  renderedResultGroup: () => {},
  selectGroup: () => {},
  deselectGroup: () => {},
  populateResults: () => {},
  resetSelectGroup: () => {},
  onShowMoreMatchedResults: () => {},
  onShowMoreOtherResults: () => {},
  onOpenConfirmationModal: () => {},
  filterExcludeMatchingTrips: () => {},
  isResultAvailable: true,
};

export default ResultTabs;
