import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from '@wtag/react-comp-lib';

const ToggleWrapper = props => (
  <div className="modified-toggle">
    <Toggle {...props} />
  </div>
);

ToggleWrapper.defaultProps = {
  size: 'tiny',
  isChecked: false,
};

ToggleWrapper.propTypes = {
  size: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ToggleWrapper;
