import React from 'react';
import PropTypes from 'prop-types';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import { StringParam, useQueryParams } from 'use-query-params';
import { Header } from '@wtag/rcl-typography';
import { PillTabs } from '@wtag/react-comp-lib';
import OrdersList from '../../../admin/Orders/List';

const List = props => {
  const { personId, ordersPath } = props;

  const displaySortingPills = {
    id: true,
    destination: false,
    lastUpdated: true,
    bookingDate: true,
    startDate: true,
  };

  const [query, setQuery] = useQueryParams({
    scope: StringParam,
    page: StringParam,
    sortColumn: StringParam,
    sortDirection: StringParam,
  });
  const { scope } = query;

  const TAB_MAPPER = {
    upcoming_orders: 0,
    past_orders: 1,
  };

  const getItems = () => [
    {
      key: 0,
      title: I18n.t('public.components.orders.upcoming'),
      getContent: () => (
        <OrdersList
          key="upcoming_orders"
          hideArchived={true}
          hideCreateOrder={true}
          personId={personId}
          ordersPath={ordersPath}
          displaySortingPills={displaySortingPills}
          additionalQueryParams={{ scope: 'upcoming_orders' }}
          displayColumns={{
            id: true,
            title: true,
            destination: true,
            travellers: true,
            products: true,
            state: true,
            total: true,
            bookingDate: true,
            startDate: true,
            actions: true,
          }}
          layman={true}
        />
      ),
    },
    {
      key: 1,
      title: I18n.t('public.components.orders.previous'),
      getContent: () => (
        <OrdersList
          key="past_orders"
          hideArchived={true}
          hideCreateOrder={true}
          personId={personId}
          ordersPath={ordersPath}
          displaySortingPills={displaySortingPills}
          additionalQueryParams={{ scope: 'past_orders' }}
          displayColumns={{
            id: true,
            title: true,
            destination: true,
            travellers: true,
            products: true,
            state: true,
            total: true,
            bookingDate: true,
            startDate: true,
            actions: true,
          }}
          layman={true}
        />
      ),
    },
  ];

  return (
    <div className="public__orders person-orders-tab__pill-tabs col-grid col-bleed-y">
      <div className="public__orders-header">
        <Header className="public__orders-header-text" weight="medium" level={5}>
          {I18n.t('public.components.orders.my_trips')}
        </Header>
      </div>
      <div className="public__orders-pill-tabs">
        <PillTabs
          items={getItems()}
          selectedTabKey={TAB_MAPPER[scope] || 0}
          showMore={false}
          onChange={() => setQuery({ ...query, page: 1, sortColumn: 'id', sortDirection: 'down' })}
        />
      </div>
    </div>
  );
};

List.propTypes = {
  personId: PropTypes.string.isRequired,
  ordersPath: PropTypes.string.isRequired,
};

export default withQueryParamsProvider(List);
