import React, { useState, Fragment } from 'react';
import SelectBox from '@wtag/rcl-select-box';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import { CheckBox } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';
import './styles.scss';

const EmailAddressForm = ({
  emailAddressTypeOptions,
  createEmailAddress,
  fetchData,
  onCloseForm,
}) => {
  const [emailType, setEmailType] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailPrimary, setEmailPrimary] = useState(false);
  const [emailError, setEmailError] = useState({});

  const resetEmailFields = () => {
    setEmailType(null);
    setEmail(null);
    setEmailPrimary(false);
    setEmailError({});
  };

  const onCreateEmailAddress = async () => {
    const params = {
      email_type: emailType,
      email,
      primary: emailPrimary,
    };

    createEmailAddress(params)
      .then(data => {
        if (data.error === null) {
          resetEmailFields();
          fetchData();
        }
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setEmailError(response.data.error);
        }
      });
  };

  return (
    <Fragment>
      <div className="col-12 col-bleed-x">
        <div className="grid">
          <div className="col-md-6 edit-email-and-phone__no-left-padding">
            <SelectBox
              label={I18n.t(
                'admin.components.organizations.edit.email_and_phone.email.label.email_type',
              )}
              options={emailAddressTypeOptions}
              isClearable={false}
              size="tiny"
              onChange={emailTypeSelected => setEmailType(emailTypeSelected.value)}
              width="full"
              value={
                emailType && {
                  label: I18n.t(emailType, {
                    scope: 'email_addresses.types',
                  }),
                  value: emailType,
                }
              }
              errorMsg={emailError.emailType}
              required={true}
            />
          </div>
          <div className="col-md-6">
            <Input
              size="tiny"
              label={I18n.t(
                'admin.components.organizations.edit.email_and_phone.email.label.email_address',
              )}
              placeholder="example@gmail.com"
              width="full"
              value={email || ''}
              onChange={value => setEmail(value)}
              touched={!!emailError.email}
              error={emailError.email}
              required={true}
            />
          </div>
          <div className="col-12 edit-email-and-phone__no-left-padding">
            <CheckBox
              name="edit-email-and-phone__field--checkbox-email"
              label={I18n.t(
                'admin.components.organizations.edit.email_and_phone.email.label.primary',
              )}
              checked={emailPrimary}
              onChange={event => setEmailPrimary(event.target.checked)}
            />
          </div>
        </div>
      </div>
      <div className="edit-section__btn-group">
        <Button
          version="v2"
          size="small"
          label={I18n.t('admin.components.organizations.edit.email_and_phone.email.label.add')}
          type="primary"
          onClick={onCreateEmailAddress}
        />
        <Button
          version="v2"
          size="small"
          label={I18n.t('admin.components.shared.action.cancel')}
          type="default"
          onClick={onCloseForm}
        />
      </div>
    </Fragment>
  );
};

EmailAddressForm.propTypes = {
  emailAddressTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  createEmailAddress: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  onCloseForm: PropTypes.func.isRequired,
};

export default EmailAddressForm;
