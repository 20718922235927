import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AcceptableFOP from '../AcceptableFOP';
import PayIndividuallyOption from './PayIndividuallyOption';
import supportedFopIcons from '../../helpers/supportedFopIcons';
import getFopItems from '../../helpers/getFopItems';
import {
  CASH_FORM_OF_PAYMENT,
  IATA_FORM_OF_PAYMENT,
  NONE_FORM_OF_PAYMENT,
  WALLET_FORM_OF_PAYMENT,
} from '../../../lib/helpers/fop_options';

import './styles.scss';

const PayIndividually = ({
  id,
  availableFormOfPayment,
  creditCards,
  currency,
  laymanMode,
  selectedPilTabKey,
  setFormOfPaymentToCreditCard,
  setFormOfPaymentToCash,
  setFormOfPaymentToWelltravelWallet,
  setFormOfPaymentToIATA,
  setFormOfPaymentToNone,
  selectedCardToken,
  setIsFopLoading,
  isFopLoading,
  setSelectedItemId,
  containerWidth,
  walletBalance,
  grossTotal,
}) => {
  const onPaymentMethodChange = async fop => {
    setSelectedItemId(id);
    setIsFopLoading(true);

    switch (fop.type) {
      case CASH_FORM_OF_PAYMENT:
        await setFormOfPaymentToCash(id);
        break;

      case IATA_FORM_OF_PAYMENT:
        await setFormOfPaymentToIATA(id);
        break;

      case NONE_FORM_OF_PAYMENT:
        await setFormOfPaymentToNone(id);
        break;

      case WALLET_FORM_OF_PAYMENT:
        await setFormOfPaymentToWelltravelWallet(id);
        break;

      default:
        await setFormOfPaymentToCreditCard(fop.card, id);
    }

    setIsFopLoading(false);
    setSelectedItemId('');
  };

  const isSelected = fop => {
    if (selectedCardToken === fop.type || (fop.card && selectedCardToken === fop.card.token))
      return true;

    return false;
  };

  return (
    <Fragment>
      <div className="pay-individually__fop-list">
        {getFopItems(availableFormOfPayment, laymanMode, creditCards).map(fop => (
          <div
            className={classNames({
              'pay-individually--disabled':
                fop.type === WALLET_FORM_OF_PAYMENT &&
                parseFloat(grossTotal) > parseFloat(walletBalance),
            })}
          >
            <PayIndividuallyOption
              key={`${fop.type}-${id}`}
              fop={fop}
              isChecked={isSelected(fop)}
              laymanMode={laymanMode}
              onPaymentMethodChange={() => onPaymentMethodChange(fop)}
              isFopLoading={isFopLoading}
              supportedCards={availableFormOfPayment.creditCard.supportedCards}
              currency={currency}
            />
          </div>
        ))}
      </div>
      <AcceptableFOP
        fops={supportedFopIcons(availableFormOfPayment, laymanMode)}
        selectedPilTabKey={selectedPilTabKey}
        containerWidth={containerWidth}
      />
    </Fragment>
  );
};

PayIndividually.defaultProps = {
  selectedCardToken: null,
  creditCards: null,
  walletBalance: null,
  grossTotal: null,
};

PayIndividually.propTypes = {
  currency: PropTypes.string.isRequired,
  availableFormOfPayment: PropTypes.shape().isRequired,
  creditCards: PropTypes.shape({}),
  laymanMode: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  selectedCardToken: PropTypes.string,
  selectedPilTabKey: PropTypes.number.isRequired,
  setFormOfPaymentToCreditCard: PropTypes.func.isRequired,
  setFormOfPaymentToCash: PropTypes.func.isRequired,
  setFormOfPaymentToWelltravelWallet: PropTypes.func.isRequired,
  setFormOfPaymentToIATA: PropTypes.func.isRequired,
  setFormOfPaymentToNone: PropTypes.func.isRequired,
  setIsFopLoading: PropTypes.func.isRequired,
  isFopLoading: PropTypes.bool.isRequired,
  setSelectedItemId: PropTypes.func.isRequired,
  containerWidth: PropTypes.number.isRequired,
  walletBalance: PropTypes.number,
  grossTotal: PropTypes.number,
};

export default PayIndividually;
