import { combineReducers } from 'redux';

import {
  ERROR_OCCURED,
  CLEAR_ERROR,
  START_BOOKING,
  BOOKING_FAILED,
  BOOKING_COMPLETED,
  CONFIGURE_PAYMENT_GATEWAY,
  REMOVE_PAYMENT_GATEWAY,
  COUNTRIES_FETCHED,
  PAYMENT_FAILED,
  PAYMENT_OPEN,
} from '../../actions/common';
import { CREATE_FLIGHT_SEARCH, FLIGHT_SEARCH_PARAMS_FETCHED } from '../../actions/flight';
import { SEARCHING_HOTELS } from '../../actions/hotel';

import approvalRequest from './approvalRequest';
import bookingProgress from './bookingProgress';
import cart from './cart';
import currentCustomer from './currentCustomer';
import customerSearchResults from './customerSearchResults';
import deal from './deal';
import fees from './fees';
import feeSuggestions from './feeSuggestions';
import journeyElements from './journeyElements';
import locations from './locations';
import multiSearch from './multiSearch';
import redirectURL from './redirectURL';
import selectedCustomers from './selectedCustomers';
import staticProducts from './staticProducts';
import travelerCount from './travelerCount';
import { airports, airportSuggestions } from './airport';
import { places, placeSuggestions } from './place';
import accountingRemarks from './accountingRemarks';
import generalRemarks from './generalRemarks';
import queuePlacement from './queuePlacement';
import newCreditCard from './newCreditCard';
import confirmationMail from './confirmationMail';
import costCenters from './costCenters';
import accountingTypes from './accountingTypes';
import nationalities from './nationalities';
import preselectTravelers from './preselectTravelers';

function error(state = {}, action) {
  switch (action.type) {
    case ERROR_OCCURED:
      return {
        error: action.error,
        message: action.message,
      };

    case CLEAR_ERROR:
      return {};

    default:
      return state;
  }
}

function people(state, action) {
  const initialState = {
    adults: null,
    children: null,
    infants: null,
  };

  switch (action.type) {
    case FLIGHT_SEARCH_PARAMS_FETCHED:
      return {
        adults: action.params.adults,
        children: action.params.children,
        infants: action.params.infants,
      };

    case CREATE_FLIGHT_SEARCH:
      return {
        adults: action.params.adults,
        children: action.params.children,
        infants: action.params.infants,
      };

    case SEARCHING_HOTELS:
      if (state.adults != null) {
        return {
          adults: action.params.adults,
          children: action.params.children,
          infants: null,
        };
      }
      return state;

    default:
      if (state === undefined) {
        return initialState;
      }
      return state;
  }
}
function isBooking(state = false, action) {
  switch (action.type) {
    case START_BOOKING:
      return true;

    case BOOKING_COMPLETED:
      return false;

    case BOOKING_FAILED:
      return false;

    default:
      return state;
  }
}

function bookingCompleted(state = false, action) {
  switch (action.type) {
    case START_BOOKING:
      return false;

    case BOOKING_COMPLETED:
      return true;

    case BOOKING_FAILED:
      return false;

    default:
      return state;
  }
}

function bookingFailed(state = false, action) {
  switch (action.type) {
    case BOOKING_FAILED:
      return true;

    default:
      return state;
  }
}

function bookingError(state = '', action) {
  switch (action.type) {
    case BOOKING_FAILED:
      return action.error;

    default:
      return state;
  }
}

function paymentGateway(state = null, action) {
  switch (action.type) {
    case REMOVE_PAYMENT_GATEWAY:
      return null;
    case CONFIGURE_PAYMENT_GATEWAY:
      return action.paymentGateway;

    default:
      return state;
  }
}

function paymentFailureReason(state = '', action) {
  switch (action.type) {
    case PAYMENT_FAILED:
      return action.error;
    case PAYMENT_OPEN:
      return '';

    default:
      return state;
  }
}

function countries(state = [], action) {
  switch (action.type) {
    case COUNTRIES_FETCHED:
      return action.countries;

    default:
      return state;
  }
}

const commonReducer = combineReducers({
  accountingRemarks,
  airportSuggestions,
  airports,
  approvalRequest,
  bookingCompleted,
  bookingFailed,
  bookingError,
  bookingProgress,
  cart,
  countries,
  currentCustomer,
  customerSearchResults,
  deal,
  error,
  feeSuggestions,
  fees,
  generalRemarks,
  isBooking,
  journeyElements,
  locations,
  multiSearch,
  newCreditCard,
  paymentGateway,
  paymentFailureReason,
  people,
  placeSuggestions,
  places,
  queuePlacement,
  redirectURL,
  selectedCustomers,
  staticProducts,
  travelerCount,
  confirmationMail,
  costCenters,
  accountingTypes,
  nationalities,
  preselectTravelers,
});

export default commonReducer;
