import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectBox from '@wtag/rcl-select-box';

import domOnlyProps from '../lib/domOnlyProps';
import findLabelValue from '../helpers/findLabelValue';
import { fetchCountries } from '../actions/common';

class CountrySelector extends React.Component {
  componentDidMount() {
    if (this.props.countries && this.props.countries.length < 1) {
      this.props.fetchCountries();
    }
  }

  onCountryChange = country => {
    const { onDocumentCountryChange, ...other } = this.props;
    if (onDocumentCountryChange) {
      onDocumentCountryChange(country);
    }
    other.onChange(country);
  };

  render() {
    const countryList =
      this.props.countries &&
      this.props.countries.map(country => ({
        value: country.code,
        label: country.name,
      }));

    const { title, disabled, error, placeholder, required, touched, ...other } = this.props;
    const errorText = (touched && error && error[0]) || undefined;
    return (
      <SelectBox
        errorMsg={errorText}
        options={countryList}
        label={title}
        disabled={disabled}
        {...domOnlyProps(other)}
        onChange={selectedOption => selectedOption && this.onCountryChange(selectedOption.value)}
        value={countryList && findLabelValue(countryList, other.value)}
        size="tiny"
        width="full"
        placeholderText={placeholder}
        required={required}
        touched={touched}
      />
    );
  }
}

CountrySelector.defaultProps = {
  error: null,
  value: null,
  disabled: false,
  required: true,
  placeholder: I18n.t('shared.placeholder.please_select'),
  touched: true,
  onDocumentCountryChange: () => {},
};

CountrySelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fetchCountries: PropTypes.func.isRequired,
  onDocumentCountryChange: PropTypes.func,
};

const mapStateToProps = state => ({
  countries: state.common.countries,
});

const mapDispatchToProps = dispatch => ({
  fetchCountries: () => dispatch(fetchCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector);
