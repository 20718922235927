import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import SeatMap from './SeatMap';
import seatMapShape from '../shapes/seatMap';

const SeatMapList = props => {
  const seatMaps = useMemo(
    () =>
      props.seatMaps.map(seatMap => (
        <SeatMap
          key={props.flightNumber}
          resultId={props.resultId}
          rows={seatMap.rows}
          columns={seatMap.columns}
          aisleColumns={seatMap.aisleColumns}
          title={props.flightNumber}
          flight={props.flightNumber}
        />
      )),
    [props.seatMaps],
  );
  return <div className="seat-map-list">{seatMaps}</div>;
};

SeatMapList.propTypes = {
  resultId: PropTypes.string.isRequired,
  seatMaps: PropTypes.arrayOf(seatMapShape).isRequired,
  flightNumber: PropTypes.string.isRequired,
};

export default SeatMapList;
