/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import QuantitySelector from '@wtag/rcl-quantity-selector';
import SelectBox from '@wtag/rcl-select-box';
import Card from '@wtag/rcl-card';
import Alert from 'sharedWebpack/Alert';
import { itemCounter } from 'sharedWebpack/MultipleForm/multipleForm';
import addOrRemoveAgeField from '../helpers/addOrRemoveAgeField';

class SelectTravelerCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childOptionsCount: 0,
      infantOptionsCount: 0,
    };
  }

  handleTravelerCountLabel = () => {
    const adultCount =
      this.props.travelersType.adults.value === ''
        ? 0
        : parseInt(this.props.travelersType.adults.value, 10);
    const childCount =
      this.props.travelersType.children.value === ''
        ? 0
        : parseInt(this.props.travelersType.children.value, 10);
    const infantCount =
      this.props.travelersType.infants.value === ''
        ? 0
        : parseInt(this.props.travelersType.infants.value, 10);
    return this.props.approvalRequestId
      ? I18n.t('components.ibe.deals.label.approval_item', {
          adultCount,
          childCount,
          infantCount,
        })
      : I18n.t('components.ibe.deals.label.total_traveler_count', {
          adultCount,
          childCount,
          infantCount,
        });
  };

  handleAdults = value =>
    Promise.all([this.props.travelersType.adults.onChange(value)]).then(() =>
      this.handleTravelerCountLabel(),
    );

  handleChildrenCount = () => {
    if (this.props.travelersType.childrenList.age) {
      const childCount = this.props.travelersType.childrenList.age.filter(age =>
        age.value !== '' ? age.value >= 2 && age.value <= 11 : 2,
      ).length;
      this.props.travelersType.children.onChange(childCount);
    }
  };

  handleInfantCount = () => {
    if (this.props.travelersType.infantList.age) {
      const infantCount = this.props.travelersType.infantList.age.filter(age =>
        age.value !== '' ? age.value >= 0 && age.value < 2 : 1,
      ).length;

      this.props.travelersType.infants.onChange(infantCount);
    }
  };

  renderChildrenAgeFields = value => {
    const childrenData = this.props.travelersType.childrenList.age;
    this.setState({ childOptionsCount: value });
    const counterDiff = childrenData.length - value;

    addOrRemoveAgeField(counterDiff, childrenData);

    return Promise.all([this.props.travelersType.childrenList.count.onChange(value)]).then(() => {
      this.props.travelersType.children.onChange(value);
      this.handleTravelerCountLabel();
      this.handleChildrenCount();
    });
  };

  renderInfantAgeFields = value => {
    const infantData = this.props.travelersType.infantList.age;
    this.setState({ infantOptionsCount: value });
    const counterDiff = infantData.length - value;

    addOrRemoveAgeField(counterDiff, infantData);

    return Promise.all([this.props.travelersType.infantList.count.onChange(value)]).then(() => {
      this.props.travelersType.infants.onChange(value);
      this.handleTravelerCountLabel();
      this.handleInfantCount();
    });
  };

  render() {
    const { travelersType, disabled } = this.props;

    const CHILD_AGE_OPTIONS = [...Array(12).keys()].map(age => ({
      value: age,
      label: `${age}`,
    }));

    const INFANT_AGE_OPTIONS = [...Array(2).keys()].map(age => ({
      value: age,
      label: `${age}`,
    }));

    const appendChildrenData = travelersType.childrenList.age.map((child, index) => (
      <div
        key={Math.random()}
        className="col-4 select-traveler-count__traveler-number--fields select-traveler-count__children-age--fields"
      >
        <SelectBox
          options={CHILD_AGE_OPTIONS}
          {...child}
          disabled={disabled}
          onChange={selectedOption => selectedOption && child.onChange(selectedOption.value)}
          value={
            child.value ? { label: child.value, value: child.value } : { label: '0', value: 0 }
          }
          size="tiny"
          width="full"
          label={I18n.t('components.ibe.search_form.hotel.label.child_age', {
            count: itemCounter(index),
          })}
          errorMsg={child.touched && child.error}
        />
      </div>
    ));

    const appendInfantData = travelersType.infantList.age.map((infant, index) => (
      <div
        key={Math.random()}
        className="col-4 select-traveler-count__traveler-number--fields select-traveler-count__children-age--fields"
      >
        <SelectBox
          options={INFANT_AGE_OPTIONS}
          {...infant}
          disabled={disabled}
          onChange={selectedOption => selectedOption && infant.onChange(selectedOption.value)}
          value={
            infant.value ? { label: infant.value, value: infant.value } : { label: '0', value: 0 }
          }
          size="tiny"
          width="full"
          label={I18n.t('components.ibe.search_form.hotel.label.infant_age', {
            count: itemCounter(index),
          })}
          errorMsg={infant.touched && infant.error}
        />
      </div>
    ));

    const travelersFieldError =
      (travelersType.adults && travelersType.adults.error) ||
      (travelersType.children && travelersType.children.error) ||
      (travelersType.infants && travelersType.infants.error);

    if (
      travelersType.childrenList.count.value &&
      travelersType.childrenList.count.value !== this.state.childOptionsCount
    ) {
      this.renderChildrenAgeFields(travelersType.childrenList.count.value);
    }

    if (
      travelersType.infantList.count.value &&
      travelersType.infantList.count.value !== this.state.infantOptionsCount
    ) {
      this.renderInfantAgeFields(travelersType.infantList.count.value);
    }

    return (
      <div>
        <div className="select-traveler-count__heading">
          <I18nText id="ibe.deals.label.travelers" returnStringOnly={true} />
        </div>
        <div className="select-traveler-count__details">{this.handleTravelerCountLabel()}</div>
        <div className="grid">
          <div className="col-4 select-traveler-count__adult">
            <Card version="v2">
              <div className="select-traveler-count__traveller-type">
                <I18nText id="ibe.deals.label.adults" />{' '}
                <I18nText id="ibe.deals.label.adults_age" />
              </div>

              <QuantitySelector
                {...travelersType.adults}
                min={1}
                max={9}
                onChange={this.handleAdults}
                disabled={disabled}
                size="tiny"
              />
            </Card>
          </div>
          <div className="col-4 select-traveler-count__child">
            <Card version="v2">
              <div className="select-traveler-count__traveller-type">
                <I18nText id="ibe.deals.label.children" />{' '}
                <I18nText id="ibe.deals.label.children_age" />
              </div>

              <div>
                <QuantitySelector
                  {...travelersType.children}
                  min={0}
                  max={9}
                  onChange={this.renderChildrenAgeFields}
                  disabled={disabled}
                  size="tiny"
                />
              </div>
            </Card>
          </div>

          <div className="col-4 select-traveler-count__infant">
            <Card version="v2">
              <div className="select-traveler-count__traveller-type">
                <I18nText id="ibe.deals.label.infant" />{' '}
                <I18nText id="ibe.deals.label.infant_age" />
              </div>

              <div>
                <QuantitySelector
                  {...travelersType.infants}
                  min={0}
                  max={9}
                  onChange={this.renderInfantAgeFields}
                  disabled={disabled}
                  size="tiny"
                />
              </div>
            </Card>
          </div>
        </div>

        {travelersType.childrenList.count.value > 0 && (
          <div className="grid">{appendChildrenData}</div>
        )}

        {travelersType.infantList.count.value > 0 && <div className="grid">{appendInfantData}</div>}
        {travelersFieldError &&
          travelersFieldError.map(error => (
            <li key={error}>
              <Alert
                className="select-traveler-count__alert"
                hideClose={true}
                type="warning"
                isVisible={!!error}
              >
                {error}
              </Alert>
            </li>
          ))}
      </div>
    );
  }
}

SelectTravelerCount.defaultProps = {
  touched: false,
  error: null,
  disabled: false,
};

SelectTravelerCount.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.string,
  travelersType: PropTypes.shape({
    adults: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    childrenList: PropTypes.shape({
      age: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      count: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        onChange: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
    infantList: PropTypes.shape({
      age: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      count: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        onChange: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
    infants: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onChange: PropTypes.func.isRequired,
    }),
    children: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onChange: PropTypes.func.isRequired,
    }),
  }).isRequired,
  disabled: PropTypes.bool,
};

export default SelectTravelerCount;
