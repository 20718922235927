import { RESET_THREE_DS_STATUSES_BY_TOKEN, UPDATE_THREE_DS_STATUSES_BY_TOKEN } from '../actions';

const initialState = {};
function updateThreeDsStatusesByToken(state, { ccToken, encodedRequest, hasPassed }) {
  const newState = Object.assign({}, state);
  if (!newState[ccToken]) {
    newState[ccToken] = {};
  }
  newState[ccToken][encodedRequest] = hasPassed;

  return newState;
}

export default function threeDsStatusesByToken(state = initialState, action) {
  switch (action.type) {
    case UPDATE_THREE_DS_STATUSES_BY_TOKEN:
      return updateThreeDsStatusesByToken(state, action.encodedData);
    case RESET_THREE_DS_STATUSES_BY_TOKEN:
      return initialState;
    default:
      return state;
  }
}
