export const UPDATE_TO_BE_AUTHENTICATED_REQUEST = 'UPDATE_TO_BE_AUTHENTICATED_REQUEST';
export const UPDATE_REQUESTS_TO_AUTHENTICATE_BY_TOKEN = 'UPDATE_REQUESTS_TO_AUTHENTICATE_BY_TOKEN';

export const updateCurrentAuthenticationRequest = encodedRequest => ({
  type: UPDATE_TO_BE_AUTHENTICATED_REQUEST,
  encodedRequest,
});

export const updateRequestsToAuthenticateByToken = requestsThatNeedAuthentication => ({
  type: UPDATE_REQUESTS_TO_AUTHENTICATE_BY_TOKEN,
  requestsThatNeedAuthentication,
});
