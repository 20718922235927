import React from 'react';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';
import './styles.scss';

const ShowGrossTotalPrice = ({ availabilityIcon, currency, grossTotal }) => (
  <div className="d-flex column-gap-8 align-center">
    {availabilityIcon && availabilityIcon}
    <Amount className="gross-total-price" currency={currency} value={grossTotal} />
  </div>
);

ShowGrossTotalPrice.defaultProps = {
  availabilityIcon: null,
};

ShowGrossTotalPrice.propTypes = {
  availabilityIcon: PropTypes.node,
  currency: PropTypes.string.isRequired,
  grossTotal: PropTypes.string.isRequired,
};

export default ShowGrossTotalPrice;
