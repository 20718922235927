import React from 'react';
import Amount from 'sharedWebpack/Amount';

const formattedAmount = (price, currency) =>
  parseFloat(price) === 0 ? (
    I18n.t('components.ibe.cart_summary_price_free')
  ) : (
    <Amount value={price} currency={currency} />
  );

export default formattedAmount;
