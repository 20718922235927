import { selectFlightGroup } from './index';

export const RESET_FLIGHT_GROUP_SELECTION = 'RESET_FLIGHT_GROUP_SELECTION';
export function resetGroupSelection() {
  return {
    type: RESET_FLIGHT_GROUP_SELECTION,
  };
}

export function resetSelectGroup(searchId, group) {
  return dispatch => {
    dispatch(resetGroupSelection());
    return dispatch(selectFlightGroup(searchId, group));
  };
}
