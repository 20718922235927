/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import formattedAmount from './formattedAmount';

function Car(props) {
  const { category, acrissCode, price, duration, currency } = props;

  return (
    <div className="cart-summary__accordion-sub-item-details-category">
      <div className="cart-summary__accordion-sub-item-details-category-car">
        <span>
          {category} {acrissCode}
        </span>
        <span>
          {formattedAmount(price / duration, currency)}{' '}
          {I18n.t('components.ibe.summary.per_day_avg')}
        </span>
      </div>
    </div>
  );
}

Car.defaultProps = {
  category: null,
  acrissCode: null,
  duration: 1,
};

Car.propTypes = {
  currency: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  category: PropTypes.string,
  acrissCode: PropTypes.string,
  duration: PropTypes.number,
};

export default Car;
