import { FEE_SUGGESTIONS_FETCHED } from '../../actions/common';

export default function fees(state = [], action) {
  switch (action.type) {
    case FEE_SUGGESTIONS_FETCHED:
      return action.fees;

    default:
      return state;
  }
}
