import React from 'react';
import PropTypes from 'prop-types';
import getCarImageUrl from 'sharedWebpack/IBE/lib/helpers/getCarImageUrl';
import CarDefaultImage from 'agentSyncedImages/fallback_images/car-image-unavailable.png';

const CarImage = ({ className, url }) => {
  const setPlaceholderImage = ({ target }) => {
    const targetElement = target;
    targetElement.src = CarDefaultImage;
  };

  return (
    <img className={className} src={getCarImageUrl(url)} onError={setPlaceholderImage} alt="car" />
  );
};

CarImage.defaultProps = {
  className: null,
  url: null,
};

CarImage.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
};

export default CarImage;
