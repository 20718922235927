import React, { useState, Fragment } from 'react';
import { Menu } from '@wtag/rcl-menu';
import Icon from '@wtag/rcl-icon';
import Amount from 'sharedWebpack/Amount';
import PropTypes from 'prop-types';

const ItemMargin = ({ marginBreakdown, currency, totalMargin, iconSize }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const onOutsideClick = () => {
    setIsMenuVisible(false);
  };

  const onToggleMenuClick = () => {
    setIsMenuVisible(prevState => !prevState);
  };

  return (
    <div className="margin__menu">
      <Menu
        isVisible={isMenuVisible}
        onOutsideClick={onOutsideClick}
        label={
          <div
            className="margin__menu-label"
            aria-hidden="true"
            onKeyDown={onToggleMenuClick}
            role="button"
            onClick={onToggleMenuClick}
          >
            {I18n.t('activerecord.attributes.order_item.margin')}
            <Amount currency={currency} value={totalMargin} />
            <Icon name="info" size={iconSize} color="tertiary" />
          </div>
        }
      >
        {marginBreakdown.map(margin => (
          <div key={margin.id} className="margin__price-breakdown margin__item-body-row">
            {margin.name} <Amount currency={margin.currency} value={margin.total} />
          </div>
        ))}
        <div className="margin__price-breakdown margin__item-body-row">
          <Fragment>{I18n.t('activerecord.attributes.order_item.total')}</Fragment>
          <Amount currency={currency} value={totalMargin} />
        </div>
      </Menu>
    </div>
  );
};

ItemMargin.defaultProps = {
  iconSize: 'small',
};

ItemMargin.propTypes = {
  marginBreakdown: PropTypes.shape({
    map: PropTypes.shape({}),
    reduce: PropTypes.shape({}),
  }).isRequired,
  currency: PropTypes.string.isRequired,
  totalMargin: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
};

export default ItemMargin;
