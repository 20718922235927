import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Alert from 'sharedWebpack/Alert';
import { EmphasisTag, LabelHint } from '@wtag/react-comp-lib';
import CheckBox from '@wtag/rcl-check-box';
import searchAccountsShape from '../../shapes/searchAccounts';
import searchAccountValues from '../../helpers/searchAccountValues';
import channelName from '../../helpers/channelName';
import FilterButtons from './FilterButtons';
import suppliers from '../../SearchDetails/flight/suppliers';
import AccountIdentifierWithToolTip from './AccountIdentifierWithToolTip';
import './styles.scss';

function findIndexInSelectedAccounts(selectedAccounts, account) {
  if (!selectedAccounts) {
    return null;
  }
  return selectedAccounts.findIndex(
    selectedAccount => selectedAccount.identifier === account.identifier,
  );
}

function findAccountInSelectedAccounts(selectedAccounts, account) {
  return selectedAccounts[findIndexInSelectedAccounts(selectedAccounts, account)];
}

function accountPresent(selectedAccounts, account) {
  return findIndexInSelectedAccounts(selectedAccounts, account) >= 0;
}

function removeAccount(selectedAccounts, account) {
  const index = findIndexInSelectedAccounts(selectedAccounts, account);
  const newAccounts = [...selectedAccounts];
  newAccounts.splice(index, 1);
  return newAccounts;
}

function findAccountCodes(account, settings, checked, field, props, handleOnChangeCode) {
  const codes = (account.codes || []).map(code => {
    let codeChecked = false;
    if (settings && settings.codes.indexOf(code.code) >= 0) {
      codeChecked = true;
    }
    let airlineCodes;
    if (code.airlines.length) {
      airlineCodes = code.airlines.join(', ');
    }

    return (
      <li key={code.code}>
        <div className="d-flex search-accounts__corporate-codes">
          <CheckBox
            size="small"
            isChecked={codeChecked}
            disabled={props.disabled || !checked || props.exchangeEnabled}
            name={`corporate-code-${code.code}`}
            onChange={event =>
              handleOnChangeCode(field, account, code.code, event.target.checked, account.allowance)
            }
          />
          <div className="search-accounts__corporate-code-label align-center"> {code.code}</div>
          {code.organization_name && (
            <div className="search-accounts__organization-tag">
              <EmphasisTag text={code.organization_name} type="tertiary" size="tiny" />
            </div>
          )}
          <LabelHint
            hint={airlineCodes}
            placement="top-right"
            className="search-accounts__corporate-label-hint"
          />
        </div>
      </li>
    );
  });
  return codes;
}

const DEFAULT_SELECTED_CHANNEL_LENGTH = 0;

class SearchAccounts extends React.Component {
  gdsChannels = ['amadeus', 'galileo', 'sabre'];

  handleToggleSelectAll = (event, isChecked) => {
    this.props.toggleSelectAll(event.target.id);
    if (isChecked !== this.props.selectGds) {
      this.props.toggleSelectGds(event.target.id);
    }
    if (isChecked !== this.props.selectDirect) {
      this.props.toggleSelectDirect(event.target.id);
    }

    const values = searchAccountValues(this.props.searchAccounts);
    if (values && Object.keys(values).length > 0) {
      Object.keys(this.props.selectedAccounts).forEach(channel => {
        let newValue = [];
        if (isChecked) {
          newValue = values[channel];
        }
        this.props.selectedAccounts[channel].onChange(newValue);
      });
    }
  };

  handleToggleSelectGds = (event, isChecked) => {
    this.props.toggleSelectGds(event.target.id);
    const values = searchAccountValues(this.props.searchAccounts);
    if (values && Object.keys(values).length > 0) {
      Object.keys(this.props.selectedAccounts).forEach(channel => {
        if (this.gdsChannels.includes(channel)) {
          let newValue = [];
          if (isChecked) {
            newValue = values[channel];
          }
          this.props.selectedAccounts[channel].onChange(newValue);
        }
      });
    }
  };

  handleToggleSelectDirect = (event, isChecked) => {
    this.props.toggleSelectDirect(event.target.id);
    const values = searchAccountValues(this.props.searchAccounts);
    if (values && Object.keys(values).length > 0) {
      Object.keys(this.props.selectedAccounts).forEach(channel => {
        if (!this.gdsChannels.includes(channel)) {
          let newValue = [];
          if (isChecked) {
            newValue = values[channel];
          }
          this.props.selectedAccounts[channel].onChange(newValue);
        }
      });
    }
  };

  handleOnChangeAccount = (field, account, checked, isGds) => {
    if (checked) {
      if (!accountPresent(field.value, account)) {
        let newField = [];
        if (field.value) {
          newField = [...field.value];
        }
        field.onChange(newField.concat({ identifier: account.identifier, codes: [] }));
      }
    } else {
      const newField = removeAccount(field.value, account);
      field.onChange(newField);

      if (isGds && this.props.selectGds) {
        this.props.toggleSelectGds();
      } else if (!isGds && this.props.selectDirect) {
        this.props.toggleSelectDirect();
      }

      if (this.props.selectAll) {
        this.props.toggleSelectAll(this.props.itemType);
      }
    }
  };

  handleOnChangeCode = (field, account, code, checked, allowance) => {
    const oldSelectedAccount = findAccountInSelectedAccounts(field.value, account);
    const selectedAccount = {
      identifier: oldSelectedAccount.identifier,
      codes: [...oldSelectedAccount.codes],
    };
    if (checked) {
      selectedAccount.codes = selectedAccount.codes.concat(code);
    } else {
      const index = selectedAccount.codes.indexOf(code);
      selectedAccount.codes.splice(index, 1);
    }
    if (selectedAccount.codes.length > allowance) {
      selectedAccount.codes.shift();
    }
    const newField = removeAccount(field.value, account);
    field.onChange(newField.concat(selectedAccount));
  };

  render() {
    if (this.context.laymanMode) {
      return null;
    }

    const {
      searchAccounts,
      selectedAccounts,
      isChannelMissingAlertVisible,
      accountSettings,
      disabled,
      exchangeEnabled,
    } = this.props;

    const directChannels = [];
    const gdsChannels = [];

    const { totalGDSCredentialsLength, totalNonGDSCredentialsLength } = Object.entries(
      searchAccounts,
    ).reduce(
      (acc, [key, airline]) => {
        if (this.gdsChannels.includes(key)) {
          acc.totalGDSCredentialsLength += airline.credentials.length;
        } else {
          acc.totalNonGDSCredentialsLength += airline.credentials.length;
        }
        return acc;
      },
      { totalGDSCredentialsLength: 0, totalNonGDSCredentialsLength: 0 },
    );

    const nonGdsAccounts = Object.keys(searchAccounts).filter(
      key => !this.gdsChannels.includes(key),
    );

    const gdsAccounts = Object.keys(searchAccounts).filter(key => this.gdsChannels.includes(key));

    const selectedChannelValues = accountSettings && Object.values(accountSettings);

    const mapIdentifiers = (entries, property) =>
      entries.flatMap(entry => entry[property] && entry[property].map(item => item.identifier));

    const allSelectedChanels =
      selectedChannelValues && mapIdentifiers(selectedChannelValues, 'value');
    const allInitialChanels =
      selectedChannelValues && mapIdentifiers(selectedChannelValues, 'initial');

    const mapAccounts = (accounts, channels, key) =>
      accounts &&
      accounts.flatMap(account => {
        const selectedChannel = channels[account];
        return selectedChannel && selectedChannel[key]
          ? selectedChannel[key].map(item => item.identifier)
          : [];
      });

    const selectedGDSChanels =
      accountSettings && mapAccounts(gdsAccounts, accountSettings, 'value');
    const selectedNonGDSChanels =
      accountSettings && mapAccounts(nonGdsAccounts, accountSettings, 'value');

    const selectedChannels =
      accountSettings &&
      Object.keys(accountSettings).reduce(
        (acc, key) => {
          const isGDS = this.gdsChannels.includes(key);
          const isSelected =
            accountSettings && accountSettings[key].value && accountSettings[key].value.length > 0;

          if (isGDS) {
            acc.totalGDS += isSelected ? 1 : 0;
          } else {
            acc.totalNonGDS += isSelected ? 1 : 0;
          }

          return acc;
        },
        { totalGDS: 0, totalNonGDS: 0 },
      );

    gdsAccounts.forEach(channel => {
      const accounts = [];
      const SupplierLogo = suppliers[channel] && suppliers[channel].logo;
      if (searchAccounts[channel].credentials && searchAccounts[channel].credentials.length) {
        searchAccounts[channel].credentials.forEach(account => {
          const field = selectedAccounts[channel];
          if (!field) {
            return;
          }
          const settings = findAccountInSelectedAccounts(field.value, account);
          let checked = true;
          if (!settings) {
            checked = false;
          }
          const codes = findAccountCodes(
            account,
            settings,
            checked,
            field,
            this.props,
            this.handleOnChangeCode,
          );
          accounts.push(
            <li key={account.identifier} className="search-accounts__account">
              <div className="d-flex search-accounts__corporate-codes">
                <CheckBox
                  size="small"
                  isChecked={checked}
                  disabled={disabled || exchangeEnabled}
                  name={
                    this.props.itemType === 'select-all'
                      ? `account-${channel}-${account.identifier}`
                      : `account-${channel}-${account.identifier}-${this.props.itemType}`
                  }
                  onChange={event =>
                    this.handleOnChangeAccount(field, account, event.target.checked, true)
                  }
                />
                <AccountIdentifierWithToolTip identifier={account.identifier} />
              </div>

              <div className="search-accounts__codes">
                <ul>{codes}</ul>
              </div>
            </li>,
          );
        });
      }
      // gds channels design here
      if (accounts.length) {
        gdsChannels.push(
          <li key={channel} className="search-accounts__account-section">
            <div className="search-accounts__card-header">
              {channelName(channel)}
              {SupplierLogo && <SupplierLogo className="search-accounts__channel-logo" />}
            </div>

            <div className="search-accounts__card-body">{accounts}</div>
          </li>,
        );
      }
    });

    nonGdsAccounts.forEach(channel => {
      const accounts = [];
      const SupplierLogo = suppliers[channel] && suppliers[channel].logo;
      if (searchAccounts[channel].credentials && searchAccounts[channel].credentials.length) {
        searchAccounts[channel].credentials.forEach(account => {
          const field = selectedAccounts[channel];
          if (!field) {
            return;
          }
          const settings = findAccountInSelectedAccounts(field.value, account);
          let checked = true;
          if (!settings) {
            checked = false;
          }
          const codes = findAccountCodes(
            account,
            settings,
            checked,
            field,
            this.props,
            this.handleOnChangeCode,
          );
          accounts.push(
            <li key={account.identifier} className="search-accounts__account">
              <div className="d-flex search-accounts__corporate-codes">
                <CheckBox
                  size="small"
                  isChecked={checked}
                  disabled={disabled || exchangeEnabled}
                  name={
                    this.props.itemType === 'select-all'
                      ? `account-${channel}-${account.identifier}`
                      : `account-${channel}-${account.identifier}-${this.props.itemType}`
                  }
                  onChange={event =>
                    this.handleOnChangeAccount(field, account, event.target.checked, false)
                  }
                />
                <AccountIdentifierWithToolTip identifier={account.identifier} />
              </div>
              <div className="search-accounts__codes">
                <ul>{codes}</ul>
              </div>
            </li>,
          );
        });
      }
      if (accounts.length) {
        directChannels.push(
          <li key={channel} className="search-accounts__account-section">
            <div className="search-accounts__card-header">
              <div className="d-flex-row__justify-between flex-wrap">
                <div className="search-accounts__title">{channelName(channel)}</div>
                {searchAccounts[channel].ndc && (
                  <div className="search-accounts__ndc-tag">
                    <EmphasisTag
                      text={I18n.t('components.ibe.search_form.search_accounts.ndc')}
                      type="accent"
                      size="tiny"
                    />
                  </div>
                )}
              </div>

              {SupplierLogo && <SupplierLogo className="search-accounts__channel-logo" />}
            </div>
            <div className="search-accounts__card-body">{accounts}</div>
          </li>,
        );
      }
    });

    const emptyCard = text => <div className="search-accounts__empty-card flex-1">{text}</div>;

    return (
      <div
        className={ClassNames('search-accounts', {
          'search-accounts--disabled': exchangeEnabled,
        })}
      >
        <div className="search-accounts__header">
          <span>{I18n.t('components.ibe.search_form.search_accounts.channels')}</span>
          {allSelectedChanels && (
            <div>
              <FilterButtons
                onAllSelectReset={this.handleToggleSelectAll}
                isAllSelectDisabled={allSelectedChanels.length === allInitialChanels.length}
                isResetDisabled={allSelectedChanels.length === 0}
              />
            </div>
          )}
        </div>
        <Alert
          hideClose={true}
          isVisible={isChannelMissingAlertVisible}
          type="danger-light"
          isIconVisible={true}
          className="search-accounts__alert"
          title={I18n.t('components.ibe.search_form.error.no_channel_selected')}
        />
        <div className="col-12 col-bleed search-accounts__card-container">
          <div className="col-md-6 col-lg-6 col-bleed search-accounts__padding--gds">
            <div className="search-accounts__sub-header">
              {I18n.t('components.ibe.search_form.search_accounts.gds')}
              <EmphasisTag
                className="search-accounts__sub-header--circle"
                number={
                  selectedChannels ? selectedChannels.totalGDS : DEFAULT_SELECTED_CHANNEL_LENGTH
                }
                size="small"
                radius="circular"
                type="default"
              />
            </div>
            {gdsChannels.length > 0 && (
              <FilterButtons
                onAllSelectReset={this.handleToggleSelectGds}
                isAllSelectDisabled={selectedGDSChanels.length === totalGDSCredentialsLength}
                isResetDisabled={selectedGDSChanels.length === 0}
              />
            )}
          </div>
          <div className="col-md-6 col-lg-6 col-bleed search-accounts__padding--direct">
            <div className="search-accounts__sub-header">
              {I18n.t('components.ibe.search_form.search_accounts.direct_connect')}
              <EmphasisTag
                className="search-accounts__sub-header--circle"
                number={
                  selectedChannels ? selectedChannels.totalNonGDS : DEFAULT_SELECTED_CHANNEL_LENGTH
                }
                size="small"
                radius="circular"
                type="default"
              />
            </div>
            {directChannels.length > 0 && (
              <FilterButtons
                onAllSelectReset={this.handleToggleSelectDirect}
                isAllSelectDisabled={selectedNonGDSChanels.length === totalNonGDSCredentialsLength}
                isResetDisabled={selectedNonGDSChanels.length === 0}
              />
            )}
          </div>
        </div>
        <div className="d-flex col-12 col-bleed">
          <div className="flex-1-col col-md-6 col-lg-6 col-bleed search-accounts__padding--gds">
            {gdsChannels.length > 0
              ? gdsChannels
              : emptyCard(I18n.t('components.ibe.search_form.search_accounts.empty_gds'))}
          </div>
          <div className="flex-1-col col-md-6 col-lg-6 col-bleed search-accounts__padding--direct">
            {directChannels.length > 0
              ? directChannels
              : emptyCard(
                  I18n.t('components.ibe.search_form.search_accounts.empty_direct_connect'),
                )}
          </div>
        </div>
      </div>
    );
  }
}

SearchAccounts.defaultProps = {
  selectAll: true,
  selectedAccounts: {},
  itemType: 'select-all',
  exchangeEnabled: false,
  isChannelMissingAlertVisible: false,
  accountSettings: {},
};

SearchAccounts.propTypes = {
  exchangeEnabled: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  searchAccounts: searchAccountsShape.isRequired,
  selectedAccounts: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            identifier: PropTypes.string.isRequired,
            codes: PropTypes.arrayOf(PropTypes.string).isRequired,
          }),
        ),
        PropTypes.string,
      ]).isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  ),
  selectAll: PropTypes.bool,
  selectGds: PropTypes.bool.isRequired,
  selectDirect: PropTypes.bool.isRequired,
  toggleSelectGds: PropTypes.func.isRequired,
  toggleSelectDirect: PropTypes.func.isRequired,
  itemType: PropTypes.string,
  toggleSelectAll: PropTypes.func.isRequired,
  accountSettings: PropTypes.shape(
    PropTypes.shape({
      initial: PropTypes.arrayOf(
        PropTypes.shape({
          identifier: PropTypes.string.isRequired,
          codes: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ),
      value: PropTypes.arrayOf(
        PropTypes.shape({
          identifier: PropTypes.string.isRequired,
          codes: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ),
      _isFieldValue: PropTypes.bool,
      touched: PropTypes.bool,
    }),
  ),
  isChannelMissingAlertVisible: PropTypes.bool,
};

SearchAccounts.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
};

export default SearchAccounts;
