import { TYPE_SEAT, TYPE_SERVICE } from 'sharedWebpack/helpers/itemTypes';

const getAncillaryIconLabel = (type, isItemAvailable) => {
  switch (type) {
    case TYPE_SERVICE:
      return isItemAvailable
        ? I18n.t('components.ibe.cart_item.flight.ancillary.included')
        : I18n.t('components.ibe.cart_item.flight.ancillary.not_available');

    case TYPE_SEAT:
      return isItemAvailable
        ? I18n.t('components.flight_seat_map.seat_reservation_status.available')
        : I18n.t('components.flight_seat_map.seat_reservation_status.not_available');

    default:
      return I18n.t('components.flight_seat_map.seat_reservation_status.not_available');
  }
};

export default getAncillaryIconLabel;
