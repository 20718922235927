import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import {
  identityCardValidityValidation,
  presenceValidation,
  subStringValidation,
  duplicateValidation,
  validate,
} from '../validations';
import { identificationNumberValidation } from './commonBookingValidations';

const frequentFlyerNumberFormatValidation = {
  format: {
    pattern: '[A-Z\\d]+|^$',
    message: <I18nText id="ibe.validations.frequent_flyer_number" />,
  },
};

export const validateFreqeuntFlyerNumber = params => {
  const checkAllUndefined = Object.values(params.frequentFlyerNumber).every(
    value => typeof value === 'undefined',
  );
  if (checkAllUndefined) {
    return null;
  }

  const message = <I18nText id="ibe.validations.code_in_frequent_flyer_number" />;

  const customValidation = subStringValidation(
    params.frequentFlyerNumber.number,
    params.frequentFlyerNumber.carrier,
    message,
  );

  let frequentFlyerNumberLengthValidation = {};
  if (params.frequentFlyerNumber.number) {
    frequentFlyerNumberLengthValidation = {
      length: {
        minimum: 6,
        message: I18n.t('components.ibe.validations.flight.frequent_flyer_number_length', {
          count: 6,
        }),
      },
    };
  }

  const commonConstraints = {
    ...frequentFlyerNumberFormatValidation,
    ...frequentFlyerNumberLengthValidation,
    ...customValidation,
  };

  let extendedConstraints;
  if (params.frequentFlyerNumber.carrier === '' && params.frequentFlyerNumber.number === '') {
    extendedConstraints = { carrier: {}, number: { ...commonConstraints } };
  } else {
    extendedConstraints = {
      carrier: { ...presenceValidation },
      number: { ...presenceValidation, ...commonConstraints },
    };
  }

  return validate(params.frequentFlyerNumber, extendedConstraints);
};

const fullName = params =>
  `${params.namePrefix || ''}${params.firstName || ''}${params.middleName || ''}${params.lastName ||
    ''}`;

const ChannelNotSupportingDuplicateName = [
  'apollo',
  'galileo',
  'lh_group',
  'sabre',
  'worldspan',
  'travelport_rooms_and_more',
  'expedia',
  'totalstay',
  'hotelbeds',
  'arabian_adventures',
  'fly_safair',
  'mango_direct',
  'auto_europe',
  'travelfusion',
  'first_car_rental',
  'welltravel',
];

const duplicateNameNotAllowed = (itemChannels, operatingCarriers) => {
  const channelIdentifiers = itemChannels.map(item => item.identifier);
  return (
    channelIdentifiers.some(channel => ChannelNotSupportingDuplicateName.includes(channel)) ||
    (channelIdentifiers.includes('amadeus') && operatingCarriers.includes('FA'))
  );
};

export const validateDuplicateName = (params, travelers, operatingCarriers, itemChannels) => {
  if (typeof params.firstName === 'undefined' || !params.cartHasFlight || travelers.length < 1) {
    return null;
  }

  const fullNameList = travelers.map(traveler => fullName(traveler));

  let customValidation;
  if (duplicateNameNotAllowed(itemChannels, operatingCarriers)) {
    const message = <I18nText id="ibe.validations.duplicate_name" />;
    customValidation = duplicateValidation(fullName(params), fullNameList, message);
  }

  const extendedConstraints = {
    firstName: { ...customValidation },
    middleName: { ...customValidation },
    lastName: { ...customValidation },
  };

  return validate(params, extendedConstraints);
};

const commonIdentificationDocumentValidation = (document, internationalFlight, laymanMode) => {
  const commonDocumentConstraints = {
    country: { ...presenceValidation },
    number: { ...presenceValidation, ...identificationNumberValidation },
    nationality: { ...presenceValidation },
    type: { ...presenceValidation },
  };

  let extendedConstraints = {};
  let typeConstraints = {};

  if (internationalFlight && laymanMode) {
    const inclusionConstraint = {
      inclusion: {
        within: ['passport'],
        message: <I18nText id="ibe.validations.passport_only" />,
      },
    };
    typeConstraints = {
      type: { ...inclusionConstraint, ...presenceValidation },
    };
  }

  // These are the other type specific general validations
  switch (document.type) {
    case 'alien_resident_card':
      extendedConstraints = {
        validity: {
          ...identityCardValidityValidation,
        },
      };
      break;

    case 'identity_card':
      extendedConstraints = {
        validity: {
          ...identityCardValidityValidation,
        },
      };
      break;

    default:
      extendedConstraints = {
        validity: {
          ...identityCardValidityValidation,
          ...presenceValidation,
        },
      };

      break;
  }
  return validate(document, {
    ...commonDocumentConstraints,
    ...extendedConstraints,
    ...typeConstraints,
  });
};

export const validateIdentificationDocument = (
  params,
  itemChannels,
  laymanMode,
  internationalFlight,
  enableIdDocOptional,
  identificationDocumentsRequired,
) => {
  const checkAllUndefined = Object.values(params.document).every(
    value => typeof value === 'undefined',
  );

  // Validate whenever identification is required
  if (identificationDocumentsRequired) {
    return commonIdentificationDocumentValidation(params.document, internationalFlight, laymanMode);
  }

  // This block is written so that we don't get errors on the initial empty form
  if (checkAllUndefined) {
    if (laymanMode) {
      if (enableIdDocOptional || !internationalFlight) {
        return null;
      }
    } else {
      return null;
    }
  }

  // This block skips all validations regarding Id Docs if it is optional in settings
  if (laymanMode && enableIdDocOptional) {
    return null;
  }

  return commonIdentificationDocumentValidation(params.document, internationalFlight, laymanMode);
};
