import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NumberParam, useQueryParams } from 'use-query-params';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import CommentPlaceholder from 'sharedWebpack/Placeholder/CommentPlaceholder';
import httpClient from 'agentHTTPClient';
import Pagination from 'sharedWebpack/Pagination';
import routes from 'agentRoutes';
import StaticCard from './StaticCard';

import './../styles.scss';

const BlogPosts = () => {
  const [blogs, setBlogs] = useState();
  const [topBlogs, setTopBlogs] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
  });

  const { page } = query;

  const setInitialParams = data => {
    // We only set the params onces when there is nothing in the url
    if (!query.page) {
      setQuery(
        {
          page: data.pagination.currentPage,
        },
        // use replaceIn as we don't want another navigation to be added to the browser history
        'replaceIn',
      );
    }
  };

  const fetchBlogPosts = async () => {
    const { data } = await httpClient.get(routes.public.blogPosts.list({ page, per_page: 16 }));
    setTopBlogs(data.blogPosts.topPosts);
    setBlogs(data.blogPosts.blogPosts);
    setInitialParams(data.blogPosts);
    setTotalPages(data.blogPosts.pagination.totalPages);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBlogPosts();
  }, [page]);

  const withLoaders = (blogNumber, component, loading, row) => {
    if (loading) {
      return (
        <CommentPlaceholder
          numberOfCards={1}
          numberOfRows={row}
          removeContentPadding={true}
          removeBoxShadow={true}
        />
      );
    } else if (topBlogs[blogNumber]) {
      return component;
    }

    return null;
  };

  return (
    <div className="container">
      <div className="title-header">
        <div className="title-header__text">{I18n.t('public.static_pages.top_stories')}</div>
        <div className="title-header__line" />
      </div>
      <div className="col-grid col-bleed direction-row ">
        <div className="col-7 with-right-padding col-bleed">
          {withLoaders(0, <StaticCard post={topBlogs[0]} />, isLoading, 9)}
        </div>
        <div className="col-5 col-grid col-bleed justify-space-between">
          {withLoaders(1, <StaticCard orientation="vertical" post={topBlogs[1]} />, isLoading, 3)}
          {withLoaders(2, <StaticCard orientation="vertical" post={topBlogs[2]} />, isLoading, 3)}
        </div>
      </div>
      <div className="title-header">
        <div className="title-header__line" />
      </div>
      {isLoading ? (
        <CommentPlaceholder
          numberOfCards={1}
          numberOfRows={20}
          removeContentPadding={true}
          removeBoxShadow={true}
        />
      ) : (
        <div className="col-grid col-bleed direction-row blog-card__wrapper ">
          {blogs.map(post => (
            <StaticCard orientation="horizontal-small" post={post} />
          ))}
        </div>
      )}
      <div className="blog-card__paginate">
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPaginationClick={newPage => {
            setIsLoading(true);
            setQuery({ page: newPage });
          }}
        />
      </div>
    </div>
  );
};

BlogPosts.propTypes = {
  colors: PropTypes.shape({
    primaryColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default withQueryParamsProvider(BlogPosts);
