import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import Link from '@wtag/rcl-link';
import { Text } from '@wtag/rcl-typography';
import LazyLoad from 'react-lazyload';
import Modal from 'sharedWebpack/Modal';
import classNames from 'classnames';

import './styles.scss';

const ImageUploadPreviewModal = ({
  fileName,
  fileUploadPreviewModalTitle,
  fileUploadPreviewUrl,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fileUploadPreviewTitle = (
    <div className="choose-file__image-preview-modal__header col-grid col-bleed direction-row justify-space-between">
      {fileUploadPreviewModalTitle && (
        <Text className="choose-file__image-preview-modal__text" level={5} weight="bold">
          {fileUploadPreviewModalTitle}
        </Text>
      )}
      <IconButton
        className="choose-file__image-preview-modal__icon-button"
        icon={<Icon name="close" />}
        isIconOnly={true}
        color="tertiary"
        size="large"
        onClick={() => setIsModalVisible(false)}
      />
    </div>
  );

  const fileUploadPreviewContent = (
    <div className="grid">
      <div className="col-12 col-bleed">
        <LazyLoad height="100%" once={true}>
          <img className="flex-img" src={fileUploadPreviewUrl} alt="fileUploadPreview" />
        </LazyLoad>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div
        role="presentation"
        onClick={() => setIsModalVisible(true)}
        className="choose-file__label choose-file__file-name"
      >
        {' '}
        {fileName}{' '}
      </div>
      <Modal
        className="choose-file__image-preview-modal"
        isModalVisible={isModalVisible}
        title={fileUploadPreviewTitle}
      >
        {fileUploadPreviewContent}
      </Modal>
    </React.Fragment>
  );
};

ImageUploadPreviewModal.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileUploadPreviewModalTitle: PropTypes.string.isRequired,
  fileUploadPreviewUrl: PropTypes.string.isRequired,
};

const ChooseFile = ({
  selectFile,
  errorMsg,
  fileName,
  fileType,
  required,
  removeFile,
  showFileName,
  showFileUploadPreviewUrl,
  fileUploadPreviewUrl,
  fileUploadPreviewModalTitle,
  showRemoveFileButton,
  ...propsForButton
}) => {
  const inputFile = useRef(null);

  const openFileWindow = () => {
    inputFile.current.click();
  };

  const getFileLabel = () => {
    if (fileName && showFileUploadPreviewUrl && fileUploadPreviewUrl && fileType) {
      if (fileType.match(/(png|jpg|jpeg|gif|webp)/i)) {
        return (
          <ImageUploadPreviewModal
            fileName={fileName}
            fileUploadPreviewUrl={fileUploadPreviewUrl}
            fileUploadPreviewModalTitle={fileUploadPreviewModalTitle}
          />
        );
      }
      return (
        <Link href={fileUploadPreviewUrl} modifier="tertiary" openNewTab={true} size="tiny">
          <div className="choose-file__label">{fileName}</div>
        </Link>
      );
    }

    if (fileName) {
      return <div className="choose-file__label">{fileName}</div>;
    }

    if (errorMsg) {
      return <div className="choose-file__label choose-file__label-error">{errorMsg}</div>;
    }

    return (
      <div className="choose-file__label choose-file__label-empty">
        {I18n.t('admin.shared.action.no_file')}
      </div>
    );
  };

  const fileLabel = getFileLabel();
  const hasFileData = showFileName && fileName;

  return (
    <div
      className={classNames('choose-file', {
        'choose-file__required': required,
      })}
    >
      <input
        type="file"
        id="file"
        ref={inputFile}
        hidden={true}
        onChange={event => {
          if (event.target.files.length !== 0) {
            selectFile(event.target.files[0]);
          }
        }}
      />
      <Button
        label={I18n.t('admin.shared.action.choose_file')}
        size="small"
        version="v2"
        onClick={openFileWindow}
        {...propsForButton}
      />
      {showFileName && fileLabel}
      {showRemoveFileButton && hasFileData && (
        <IconButton
          onClick={removeFile}
          size="tiny"
          isIconOnly={true}
          color={errorMsg ? 'danger' : 'tertiary'}
          icon={<Icon name="closeCircle" />}
        />
      )}
    </div>
  );
};

ChooseFile.defaultProps = {
  errorMsg: '',
  fileName: '',
  fileType: '',
  fileUploadPreviewUrl: '',
  fileUploadPreviewModalTitle: '',
  showFileName: true,
  showFileUploadPreviewUrl: false,
  required: false,
  showRemoveFileButton: false,
};

ChooseFile.propTypes = {
  selectFile: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  fileUploadPreviewUrl: PropTypes.string,
  fileUploadPreviewModalTitle: PropTypes.string,
  removeFile: PropTypes.func.isRequired,
  showFileName: PropTypes.bool,
  showFileUploadPreviewUrl: PropTypes.bool,
  required: PropTypes.bool,
  showRemoveFileButton: PropTypes.bool,
};

export default ChooseFile;
