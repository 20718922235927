import PropTypes from 'prop-types';

const ValuesShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  before: PropTypes.shape({
    grossPrice: PropTypes.string.isRequired,
    netPrice: PropTypes.string.isRequired,
    margin: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  after: PropTypes.shape({
    netPrice: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    withNewMargin: PropTypes.shape({
      grossPrice: PropTypes.string.isRequired,
      margin: PropTypes.string.isRequired,
    }).isRequired,
    withOldMargin: PropTypes.shape({
      grossPrice: PropTypes.string.isRequired,
      margin: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  journeyElementType: PropTypes.string.isRequired,
  ancillariesTitle: PropTypes.string.isRequired,
  operatingFlightCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export default ValuesShape;
