/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { Spinner } from '@wtag/react-comp-lib';
import Tooltip from '@wtag/rcl-tooltip';
import useViewMode from 'sharedWebpack/useViewMode';
import { DESKTOP_DEVICE } from '../../../old/libraries/responsiveHelpers';
import './styles.scss';

const Availability = props => {
  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;

  switch (props.available) {
    case true:
      return <Icon name="doubleCheck" color="success" size="normal" />;
    case false:
      return (
        <Tooltip
          position={isLargeScreen ? 'top-right' : 'top-left'}
          showArrow={false}
          content={props.unavailableHint}
          type="inverse"
        >
          <Icon name="invalid" size="tiny" color="tertiary" />
        </Tooltip>
      );
    default:
      return (
        <div className="availability__spinner">
          <Spinner size="xs" bgColor="neutral" />
        </div>
      );
  }
};

Availability.propTypes = {
  available: PropTypes.oneOf([true, false, null]),
  unavailableHint: PropTypes.node.isRequired,
};

export default Availability;
