import React, { useEffect, useState } from 'react';
import Alert from 'sharedWebpack/Alert';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import Card from '@wtag/rcl-card';
import PropTypes from 'prop-types';
import LeftPanel from 'sharedWebpack/TravellerLeftPanel';
import Heading from '../shared/Heading';
import PendingTravelArrangers from './PendingTravelArrangers';
import Deals from './Deals';
import PendingApprovalRequests from './PendingApprovalRequests';
import TravelOverview from './TravelOverview';

import './styles.scss';

const Dashboard = props => {
  const { editUrl, colors, urls } = props;
  const [traveller, setTraveller] = useState({});
  const [travelArrangers, setTravelArrangers] = useState([]);
  const [travelArrangersUrls, setTravelArrangersUrls] = useState({});
  const [approvalRequests, setApprovalRequests] = useState([]);
  const [approvalRequestsUrls, setApprovalRequestsUrls] = useState({});
  const [topDeals, setTopDeals] = useState([]);
  const [favouriteDeals, setFavouriteDeals] = useState([]);
  const [friendsFavouriteDeals, setFriendsFavouriteDeals] = useState([]);
  const [dealsUrls, setDealsUrls] = useState({});
  const [isLoadingPerson, setIsLoadingPerson] = useState(false);
  const [isLoadingTravelArrangers, setIsLoadingTravelArrangers] = useState(false);
  const [isLoadingApprovalRequests, setIsLoadingApprovalRequests] = useState(false);
  const [isLoadingTopDeals, setIsLoadingTopDeals] = useState(false);
  const [isLoadingFavouriteDeals, setIsLoadingFavouriteDeals] = useState(false);
  const [isLoadingFriendsFavouriteDeals, setIsLoadingFriendsFavouriteDeals] = useState(false);
  const [shareLocationError, setShareLocationError] = useState(null);

  const fetchTraveller = async () => {
    setIsLoadingPerson(true);
    const { data } = await httpClient.get(routes.public.people.show({}));

    setTraveller(data.person);
    setIsLoadingPerson(false);
  };

  const fetchPendingTravelArrangers = async () => {
    setIsLoadingTravelArrangers(true);
    const { data } = await httpClient.get(routes.public.travelArrangers.list({ state: 'pending' }));
    setTravelArrangers(data.travelArrangementsClientSettings);
    setTravelArrangersUrls(data.urls);
    setIsLoadingTravelArrangers(false);
  };

  const fetchPendingApprovalRequests = async () => {
    setIsLoadingApprovalRequests(true);
    const { data } = await httpClient.get(
      routes.public.approvals.requests.list({ tab: 'pending' }),
    );
    setApprovalRequests(data.approvalRequests);
    setApprovalRequestsUrls(data.urls);
    setIsLoadingApprovalRequests(false);
  };

  const fetchTopDeals = async () => {
    setIsLoadingTopDeals(true);
    const { data } = await httpClient.get(routes.public.topDeals());
    setTopDeals(data.deals);
    setDealsUrls(data.urls);
    setIsLoadingTopDeals(false);
  };

  const fetchFavouriteDeals = async () => {
    setIsLoadingFavouriteDeals(true);
    const { data } = await httpClient.get(routes.public.fetchFavouriteDeals());
    setFavouriteDeals(data.deals);
    setIsLoadingFavouriteDeals(false);
  };

  const fetchFriendsFavouriteDeals = async () => {
    setIsLoadingFriendsFavouriteDeals(true);
    const { data } = await httpClient.get(routes.public.fetchFriendsFavouriteDeals());
    setFriendsFavouriteDeals(data.deals);
    setIsLoadingFriendsFavouriteDeals(false);
  };

  useEffect(() => {
    fetchTraveller();
    fetchPendingTravelArrangers();
    fetchPendingApprovalRequests();
    fetchTopDeals();
    fetchFavouriteDeals();
    fetchFriendsFavouriteDeals();
  }, []);

  return (
    <div className="dashboard">
      <Heading
        currentNavigation="dashboard"
        buttonUrl={editUrl}
        buttonText={I18n.t('public.people.edit.title')}
      />
      <div className="dashboard__traveller-details">
        <Alert
          className="col-grid direction-row dashboard__traveller-details__alert"
          isVisible={!!shareLocationError}
          type="warning"
          hideClose={false}
        >
          {I18n.t('admin.components.travellers.overview.left_panel.share_location_error')}
        </Alert>
        <div className="col-lg-3 col-md-4">
          <div className="grid align-center">
            <div className="col-12 col-bleed">
              {traveller.associatedToOrganization && (
                <Card version="v2" className="dashboard__traveller-details-tenant">
                  <img
                    className="dashboard__traveller-details-tenant-logo"
                    src={traveller.tenantLogo}
                    alt="logo"
                  />
                </Card>
              )}
              <div className="col-12 col-bleed">
                <LeftPanel
                  traveller={traveller}
                  laymanMode={true}
                  loading={isLoadingPerson}
                  progressBarColor={colors.primaryColor}
                  setShareLocationError={setShareLocationError}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-8 col-bleed dashboard__contents">
          <div className="col-12 col-bleed dashboard__contents-card">
            <TravelOverview
              name={traveller.firstName}
              personId={traveller.id}
              bookNowUrl={urls.bookNow}
            />
          </div>
          {traveller && traveller.associatedToOrganization && (
            <div className="col-12 col-bleed dashboard__contents-card">
              <div className="col-lg-5 col-bleed dashboard__contents-card-pending-arrangers">
                <PendingTravelArrangers
                  travelArrangers={travelArrangers}
                  fetchTravelArrangers={fetchPendingTravelArrangers}
                  loading={isLoadingTravelArrangers}
                  urls={travelArrangersUrls}
                />
              </div>
              <div className="col-lg-7 col-bleed dashboard__contents-card-pending-approvals">
                <PendingApprovalRequests
                  approvalRequests={approvalRequests}
                  loading={isLoadingApprovalRequests}
                  urls={approvalRequestsUrls}
                />
              </div>
            </div>
          )}
          <div className="col-12 col-bleed dashboard__contents-card-deals">
            <Deals
              headerText={I18n.t('public.components.dashboard.deals.deals_of_the_day')}
              deals={topDeals}
              loading={isLoadingTopDeals}
              urls={dealsUrls}
              fetchTopDeals={fetchTopDeals}
              fetchFavouritesDeals={fetchFavouriteDeals}
              buttonText={I18n.t('public.components.dashboard.deals.explore_more')}
            />
          </div>
          <div className="col-12 col-bleed dashboard__contents-card-deals">
            <Deals
              headerText={I18n.t('public.components.dashboard.deals.favourite_deals')}
              deals={favouriteDeals}
              loading={isLoadingFavouriteDeals}
              urls={dealsUrls}
              fetchTopDeals={fetchTopDeals}
              fetchFavouritesDeals={fetchFavouriteDeals}
              buttonText={I18n.t('public.components.dashboard.deals.view_all_favourite')}
            />
          </div>
          <div className="col-12 col-bleed dashboard__contents-card-deals">
            <Deals
              headerText={I18n.t('public.components.dashboard.deals.friends_favourite_deals')}
              deals={friendsFavouriteDeals}
              loading={isLoadingFriendsFavouriteDeals}
              urls={dealsUrls}
              fetchTopDeals={fetchTopDeals}
              fetchFavouritesDeals={fetchFavouriteDeals}
              fetchFriendsFavouriteDeals={fetchFriendsFavouriteDeals}
              buttonText={I18n.t('public.components.dashboard.deals.explore_more')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  colors: PropTypes.shape({
    primaryColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
  }).isRequired,
  editUrl: PropTypes.string.isRequired,
  urls: PropTypes.shape().isRequired,
};

export default Dashboard;
