import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { hot } from 'react-hot-loader';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import EmergencyContactForm from './EmergencyContactForm';
import EmergencyContact from './EmergencyContact';

const EmergencyContactsSection = props => {
  const { personId, phoneCountryCodeOptions, layman } = props;

  const [error, setError] = useState({});
  const [phoneCountryCode, setPhoneCountryCode] = useState(null);
  const [number, setNumber] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowForm, setIsShowForm] = useState(false);
  const hasAddress = emergencyContacts.length > 0;

  let emergencyContactsRoutes;
  if (layman) {
    emergencyContactsRoutes = {
      fetch: routes.public.emergencyContacts.list({}),
      create: routes.public.emergencyContacts.create({}),
    };
  } else {
    emergencyContactsRoutes = {
      fetch: routes.admin.people.emergencyContacts.list({ personId }),
      create: routes.admin.people.emergencyContacts.create({ personId }),
    };
  }

  const fetchEmergencyContacts = async () => {
    setIsLoading(true);
    const { data } = await httpClient.get(emergencyContactsRoutes.fetch);
    setEmergencyContacts(data.emergencyContacts);
    setIsLoading(false);
  };

  const createEmergencyContact = async () => {
    const emergencyContactParams = {
      phone_country_code: phoneCountryCode,
      number,
      first_name: firstName,
      last_name: lastName,
    };

    const { data } = await httpClient.post(emergencyContactsRoutes.create, {
      emergency_contact: emergencyContactParams,
    });

    if (data.error === null) {
      setPhoneCountryCode(null);
      setNumber('');
      setFirstName('');
      setLastName('');
      fetchEmergencyContacts();
      setError({});
    }
  };

  const onCreateEmergencyContacts = () => {
    createEmergencyContact()
      .then(() => {
        setIsShowForm(false);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setError(response.data.error);
        }
      });
  };

  const emergencyForm = () => (
    <Fragment>
      {hasAddress && (
        <div className="traveller-edit-address-section__form-header">
          {I18n.t('admin.components.travellers.edit.emergency_contacts.add')}
        </div>
      )}
      <EmergencyContactForm
        phoneCountryCode={phoneCountryCode}
        number={number}
        firstName={firstName}
        lastName={lastName}
        error={error}
        phoneCountryCodeOptions={phoneCountryCodeOptions}
        setPhoneCountryCode={setPhoneCountryCode}
        setNumber={setNumber}
        setFirstName={setFirstName}
        setLastName={setLastName}
      />
      <div className="traveller-edit-emergency-contacts-section__form-actions">
        <Button
          version="v2"
          size="small"
          type="primary"
          label={I18n.t('admin.shared.action.add')}
          onClick={onCreateEmergencyContacts}
        />
        <Button
          version="v2"
          size="small"
          label={I18n.t('admin.components.shared.action.cancel')}
          type="default"
          onClick={() => setIsShowForm(false)}
        />
      </div>
    </Fragment>
  );

  useEffect(() => {
    fetchEmergencyContacts();
  }, []);

  useEffect(() => {
    setError({});
  }, [isShowForm]);

  return (
    <div className="traveller-edit-emergency-contacts-section">
      <div className="traveller-edit-emergency-contacts-section__header traveller-edit-emergency-contacts-section__header--extra-margin justify-space-between">
        {I18n.t('admin.components.travellers.edit.tabs.emergency_contacts')}

        {!isShowForm && !isLoading && (
          <Button
            version="v2"
            size="small"
            label={I18n.t('admin.components.organizations.edit.email_and_phone.phone.label.add')}
            type="primary"
            onClick={() => setIsShowForm(true)}
          />
        )}
      </div>

      {isShowForm && !hasAddress && emergencyForm()}

      <div className="col-12 col-bleed traveller-edit-emergency-contact">
        {isLoading ? (
          <div className="col-grid align-center traveller-edit-emergency-contacts-section traveller-edit-emergency-contacts-section__contact-card">
            <ContentLoaderPlaceholder numberOfLines={3} isRounded={true} showBackground={false} />
          </div>
        ) : (
          <Fragment>
            {emergencyContacts.map(emergencyContact => (
              <EmergencyContact
                key={emergencyContact.id}
                personId={personId}
                fetchEmergencyContacts={fetchEmergencyContacts}
                emergencyContact={emergencyContact}
                phoneCountryCodeOptions={phoneCountryCodeOptions}
                layman={layman}
              />
            ))}
          </Fragment>
        )}

        {isShowForm && hasAddress && (
          <Fragment>
            <div className="col-bleed edit-email-and-phone__mb">
              <hr className="edit-email-and-phone__hr" />
            </div>
            {emergencyForm()}
          </Fragment>
        )}

        {!hasAddress && !isLoading && !isShowForm && (
          <div className="col-12 direction-row edit-email-and-phone edit-email-and-phone__placeholder">
            <Icon name="emergency" size="large" color="tertiary" showBGColor={true} />
            <div className="edit-email-and-phone__explanation edit-email-and-phone__explanation--with-padding">
              {I18n.t('admin.components.orders.left_panel.emergency_contacts.no_contacts')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

EmergencyContactsSection.defaultProps = {
  layman: false,
};

EmergencyContactsSection.propTypes = {
  personId: PropTypes.number.isRequired,
  phoneCountryCodeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  layman: PropTypes.bool,
};

export default hot(module)(EmergencyContactsSection);
