import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@wtag/rcl-typography';
import { I18nText } from '@wtag/react-comp-lib';
import MatrixCard from '../MatrixCard';
import MatrixContext from '../../contexts/MatrixContext';
import './matrix-legend-card.styles.scss';

const legends = [
  {
    color: 'success',
    description: (
      <I18nText id="ibe.search_result.farebrand_legends.included" returnStringOnly={true} />
    ),
  },
  {
    color: 'warning',
    description: (
      <I18nText id="ibe.search_result.farebrand_legends.fee_needed" returnStringOnly={true} />
    ),
  },
  {
    color: 'gray',
    description: (
      <I18nText id="ibe.search_result.farebrand_legends.not_available" returnStringOnly={true} />
    ),
  },
  {
    color: 'light-gray',
    description: (
      <I18nText id="ibe.search_result.farebrand_legends.no_info" returnStringOnly={true} />
    ),
  },
];

const MatrixLegendCard = ({ windowWidth }) => {
  const { matrixRowHeaderCardWidth } = useContext(MatrixContext);
  const matrixCardMobileWidth = 246;
  const windowSizeForMobileView = 991;

  return (
    <MatrixCard
      className="matrix-legend-card"
      width={
        windowWidth > windowSizeForMobileView ? matrixRowHeaderCardWidth : matrixCardMobileWidth
      }
      isClickable={false}
    >
      <Text className="matrix-legend-card__title" level={2} weight="bold">
        <I18nText id="ibe.search_result.farebrand_legends.title" returnStringOnly={true} />
      </Text>
      <ul className="matrix-legend-card__list">
        {legends.map(legend => (
          <li
            key={legend.color}
            className={`matrix-legend-card__list-item matrix-legend-card--${legend.color}`}
          >
            <span className="matrix-legend-card__list-item-color-tag" />
            <Text level={3} weight="normal">
              {legend.description}
            </Text>
          </li>
        ))}
      </ul>
    </MatrixCard>
  );
};

MatrixLegendCard.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

export default MatrixLegendCard;
