import React from 'react';
import PropTypes from 'prop-types';

const GeneralInformation = props => {
  const { traveller, layman } = props;

  return (
    <div className="col-12 col-bleed traveller-edit-general-information">
      <div className="col-12 col-bleed traveller-edit-general-information__field traveller-edit-general-information__field-name">
        <div className="traveller-edit-general-information__field--label traveller-edit-general-information__field--with-padding">
          {I18n.t('admin.components.travellers.edit.general.information.label.name')}
        </div>
        <div className="traveller-edit-general-information__field--value">{traveller.name}</div>
      </div>
      <div className="col-12 col-bleed traveller-edit-general-information__field">
        <div className="traveller-edit-general-information__field--label traveller-edit-general-information__field--with-padding">
          {I18n.t('admin.components.travellers.edit.general.information.label.dob')}
        </div>
        <div className="traveller-edit-general-information__field--value">
          {traveller.birthday
            ? traveller.birthday
            : I18n.t('admin.components.travellers.edit.general.information.placeholder.no_dob')}
        </div>
      </div>
      <div className="col-12 col-bleed traveller-edit-general-information__field">
        <div className="traveller-edit-general-information__field--label traveller-edit-general-information__field--with-padding">
          {I18n.t(
            'admin.components.travellers.edit.general.information.label.preferred_communication',
          )}
        </div>
        <div className="traveller-edit-general-information__field--value traveller-edit-general-information__field--with-padding">
          {traveller.preferredCommunication
            ? traveller.preferredCommunication
            : I18n.t(
                'admin.components.travellers.edit.general.information.placeholder.no_preferred_communication',
              )}
        </div>
        {traveller.preferredCommunication && (
          <div className="traveller-edit-general-information__field--explanation">
            {traveller.preferredCommunication === 'email'
              ? I18n.t(
                  'admin.components.travellers.edit.general.information.placeholder.email_preferred',
                )
              : I18n.t(
                  'admin.components.travellers.edit.general.information.placeholder.phone_preferred',
                )}
          </div>
        )}
      </div>
      <div className="col-12 col-bleed traveller-edit-general-information__field">
        <div className="traveller-edit-general-information__field--label traveller-edit-general-information__field--with-padding">
          {I18n.t('admin.components.travellers.edit.general.information.label.personal_id')}
        </div>
        <div className="traveller-edit-general-information__field--value traveller-edit-general-information__field--with-padding">
          {traveller.personalId
            ? traveller.personalId
            : I18n.t(
                'admin.components.travellers.edit.general.information.placeholder.no_personal_id',
              )}
        </div>
        <div className="traveller-edit-general-information__field--explanation">
          {I18n.t(
            'admin.components.travellers.edit.general.information.placeholder.personal_id_explanation',
          )}
        </div>
      </div>
      <div className="col-12 col-bleed traveller-edit-general-information__field">
        <div className="traveller-edit-general-information__field--label traveller-edit-general-information__field--with-padding">
          {I18n.t('admin.components.travellers.edit.general.information.label.short_code')}
        </div>
        <div className="traveller-edit-general-information__field--value traveller-edit-general-information__field--with-padding">
          {traveller.shortCode
            ? traveller.shortCode
            : I18n.t(
                'admin.components.travellers.edit.general.information.placeholder.no_short_code',
              )}
        </div>
        <div className="traveller-edit-general-information__field--explanation">
          {I18n.t(
            'admin.components.travellers.edit.general.information.placeholder.short_code_explanation',
          )}
        </div>
        <div className="col-12 col-bleed traveller-edit-general-information__field">
          <div className="traveller-edit-general-information__field--label traveller-edit-general-information__field--with-padding">
            {I18n.t(
              'admin.components.travellers.edit.general.information.label.correspondence_language',
            )}
          </div>
          <div className="traveller-edit-general-information__field--value">
          {traveller.correspondenceLanguage
            ? traveller.correspondenceLanguage
            : I18n.t(
                'admin.components.travellers.edit.general.information.placeholder.no_correspondence_language',
              )}
          </div>
        </div>
        {!layman && (
          <div className="col-12 col-bleed traveller-edit-general-information__field">
            <div className="traveller-edit-general-information__field--label traveller-edit-general-information__field--with-padding">
              {I18n.t('admin.components.travellers.edit.general.information.label.spoken_language')}
            </div>
            {(traveller.spokenLanguages && traveller.spokenLanguages.length > 0) ?
              traveller.spokenLanguages.map(language => (
                <div className="traveller-edit-general-information__field--value traveller-edit-general-information__field--with-padding">
                  {language}
                </div>
              )) : 
              <div className="traveller-edit-general-information__field--value traveller-edit-general-information__field--with-padding">
                {I18n.t('admin.components.travellers.edit.general.information.placeholder.no_spoken_language')}
              </div>
            }
          </div>
        )}
      </div>
    </div>
  );
};

GeneralInformation.defaultProps = {
  traveller: {},
  layman: false,
};

GeneralInformation.propTypes = {
  traveller: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    birthday: PropTypes.string,
    shortCode: PropTypes.string,
    personalId: PropTypes.string,
    correspondenceLanguage: PropTypes.string,
    profileCompletionPercentage: PropTypes.number,
    socialMediaLinks: PropTypes.shape({
      facebook: PropTypes.shape({
        url: PropTypes.string,
      }),
      twitter: PropTypes.shape({
        url: PropTypes.string,
      }),
      youtube: PropTypes.shape({
        url: PropTypes.string,
      }),
      linkedin: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    identificationDocuments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        flagUrl: PropTypes.string,
        nationality: PropTypes.string,
        expiresIn: PropTypes.number,
        showWarning: PropTypes.bool,
        showDanger: PropTypes.bool,
      }),
    ),
    spokenLanguages: PropTypes.arrayOf(PropTypes.string),
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        zipCode: PropTypes.string,
        city: PropTypes.string,
        type: PropTypes.string,
        primary: PropTypes.bool,
      }),
    ),
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        primary: PropTypes.bool,
        number: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    emailAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        primary: PropTypes.bool,
        email: PropTypes.string,
      }),
    ),
    preferredCommunication: PropTypes.string,
    organization: PropTypes.shape({
      id: PropTypes.number,
      avatarUrl: PropTypes.string,
      name: PropTypes.string,
    }),
    emergencyContacts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        number: PropTypes.string,
      }),
    ),
  }),
  layman: PropTypes.bool,
};

export default GeneralInformation;
