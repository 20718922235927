import PropTypes from 'prop-types';

const TripShape = PropTypes.shape({
  id: PropTypes.number,
  destinationCode: PropTypes.string.isRequired,
  originCode: PropTypes.string.isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      countryFlag: PropTypes.string.isRequired,
      countryName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      placeName: PropTypes.string.isRequired,
    }),
  ),
  airlines: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
});

export default TripShape;
