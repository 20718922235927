const customStyles = {
  base: {
    background: '#fff',
    border: '1px solid #dbe2f1',
    'border-radius': '3px',
    'box-shadow': '0 1px 3px rgb(75 115 167 / 16%)',
    'box-sizing': 'border-box',
    color: '#182146',
    'font-size': '14px',
    height: '35px',
    padding: '10px 12px',
    width: '100%',
    'margin-bottom': '20px',
  },
  base_select: {
    border: '1px solid #dbe2f1',
    'border-radius': '3px',
    'box-shadow': '0 1px 3px rgb(75 115 167 / 16%)',
    'box-sizing': 'border-box',
    color: '#182146',
    'font-size': '14px',
    height: '35px',
    padding: '8px 15px',
    width: '100%',
    'margin-bottom': '20px',
  },
  submit_button: {
    'align-items': 'center',
    background: '#c44a47',
    'border-radius': '3px',
    border: '1px solid #e2e8f1',
    'box-sizing': 'border-box',
    color: '#fff',
    cursor: 'pointer',
    display: 'inline-flex',
    'font-family': 'Roboto,sans-serif',
    'font-size': '14px',
    'font-weight': '400',
    'justify-content': 'center',
    'line-height': '18px',
    padding: '6px 12px',
    'text-align': 'center',
    'text-decoration': 'none',
    'white-space': 'nowrap',
  },
  credit_card_form__row: {
    'align-items': 'center',
    'justify-content': 'space-between',
    display: 'flex',
  },
  credit_card_form__field_left: {
    'padding-right': '6px',
  },
  credit_card_form__field_right: {
    'padding-left': '6px',
  },
  three_ds_iframe: {
    width: '350px',
    height: '500px',
    'border-radius': '3px',
    border: '1px solid #E2E8F1',
    margin: '6px auto auto',
  },
  invalid: {
    'border-color': '#ff5555',
    'margin-top': '6px',
  },
  three_ds_container: {
    display: 'block',
  },
};

export default customStyles;
