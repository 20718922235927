import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText, Link, QuantitySelector } from '@wtag/react-comp-lib';
import { Menu, MenuItem } from '@wtag/rcl-menu';
import DateRangePicker from '@wtag/rcl-date-range-picker';
import Alert from 'sharedWebpack/Alert';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import SelectBox from '@wtag/rcl-select-box';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import LocationSearchInput from 'sharedWebpack/LocationSearchInput';
import TimeSelectBox from 'sharedWebpack/TimeSelectBox';

import availableChannelsShape from '../../shapes/availableChannels';
import noChannelSelected from '../../lib/helpers/noChannelSelected';
import showInvalidDateError from '../../helpers/showInvalidDateError';
import history from '../../lib/history';
import { showError } from '../../actions/common';
import AirportAutoComplete from '../AirportAutoComplete';
import VendorSelectBox from './VendorSelectBox';
import SearchAccounts from '../../components/SearchAccounts';
import searchAccountsShape from '../../shapes/searchAccounts';
import PreselectTravelers from '../PreselectTravelers';
import preparePreselectTravelers from '../PreselectTravelers/preparePreselectTravelers';
import useSearchFormContainer from '../../helpers/hooks/useSearchFormContainer';
import AirportShape from '../shapes/AirportShape';
import MenuLabel from '../helpers/MenuLabel';
import MenuLabelWithPreSelectText from '../MenuLabelWithPreSelectText';
import usePreselectTraveler from '../../helpers/hooks/usePreselectTraveler';

const SearchForm = props => {
  const {
    fields: {
      airportSearch,
      pickUpLocationAirportCode,
      dropOffLocationAirportCode,
      pickUpGeoLocationAddress,
      pickUpGeoLocationLatitude,
      pickUpGeoLocationLongitude,
      dropOffGeoLocationAddress,
      dropOffGeoLocationLatitude,
      dropOffGeoLocationLongitude,
      pickUpDate,
      dropOffDate,
      pickUpTime,
      dropOffTime,
      driverAge,
      vendorPreferences,
      accountSettings,
    },
    submitting,
    handleSubmit,
    laymanMode,
    availableChannels,
    cartHasCar,
    createCarSearch,
    defaultSearchParams,
    initializeForm,
    globalPolicyPreferences,
    resetApp,
    searchForm,
    preselectTravelers,
    setIsCollapsed,
    cartHasItems,
    formWithoutChildren,
    resetBookingAppContext,
    travelerLoggedIn,
    travelArrangerPresent,
    airportSuggestions,
    callbackParams,
    preselectDecision,
    updatePreselectTravelers,
  } = props;
  const [isLocationMenuVisible, setIsLocationMenuVisible] = useState(false);
  const [isVendorMenuVisible, setIsVendorMenuVisible] = useState(false);
  const [isDriverMenuVisible, setIsDriverMenuVisible] = useState(false);
  const [isPreselectTravellerVisible, setIsPreselectTravellerVisible] = useState(false);
  const preselectTravelersLength = preselectTravelers.length;

  const loggedInAsTravelArranger = travelerLoggedIn && travelArrangerPresent;
  const loggedOutWithEmptyCartAndForm = !travelerLoggedIn && !cartHasItems && formWithoutChildren;
  const isPreSelectTravelerButtonVisible =
    (loggedInAsTravelArranger || loggedOutWithEmptyCartAndForm) && !props.forceAdvancedSearch;

  usePreselectTraveler({
    adults: 1,
    laymanMode,
    travelerLoggedIn,
    preselectDecision,
    preselectTravelers,
    callbackParams,
    updatePreselectTravelers,
    shouldPreselect: isPreSelectTravelerButtonVisible,
  });

  const MAX_DRIVER_COUNT = 1;

  const onLocationMenuOutsideClick = () => {
    setIsLocationMenuVisible(isPreselectTravellerVisible);
  };

  const onVendorMenuOutsideClick = () => {
    setIsVendorMenuVisible(false);
  };

  const onDriverMenuOutsideClick = () => {
    setIsDriverMenuVisible(isPreselectTravellerVisible);
  };

  const onLocationMenuClick = () => setIsLocationMenuVisible(prevState => !prevState);
  const onVendorMenuClick = () => setIsVendorMenuVisible(prevState => !prevState);
  const onDriverMenuClick = () => setIsDriverMenuVisible(prevState => !prevState);

  const showCarFormContainer = useSearchFormContainer();

  const filterTypeOptions = [
    {
      value: 'preferred',
      label: I18n.t('components.ibe.search_form.car.label.vendor_preferences_type.preferred'),
      isDisabled: laymanMode
        ? availableChannels.cars.includes('galileo')
        : accountSettings && accountSettings.galileo && accountSettings.galileo.value.length > 0,
    },
    {
      value: 'exclusive',
      label: I18n.t('components.ibe.search_form.car.label.vendor_preferences_type.exclusive'),
    },
  ];

  const disableVendorPreference = globalPolicyPreferences.carRentalCompanies.length > 0;

  const isNoChannelSelected = noChannelSelected(
    laymanMode,
    availableChannels,
    accountSettings,
    'cars',
  );

  const onPickUpLocationSelect = (address, latitude, longitude) => {
    if (!dropOffGeoLocationAddress.touched) {
      dropOffGeoLocationAddress.onChange(address);
      dropOffGeoLocationLatitude.onChange(latitude);
      dropOffGeoLocationLongitude.onChange(longitude);
    }
  };

  const onPickUpAirportChange = airport => {
    pickUpLocationAirportCode.onChange(airport);
    if (!dropOffLocationAirportCode.touched) {
      dropOffLocationAirportCode.onChange(airport);
    }
  };

  const handleFormSubmission = (values, dispatch, ownProps) => {
    history.push('/cars/searching');
    createCarSearch(
      {
        ...values,
        currency: ownProps.currency,
        preselectTravelers: preparePreselectTravelers(preselectTravelers),
      },
      ownProps.callbackParams,
    )
      .then(data => data && data.id && history.push(`/cars/searches/${data.id}`))
      .catch(error => dispatch(showError('Search failed for unknown reasons', error)));
    setIsCollapsed();
  };

  const resetToDefaultSearchParams = () => {
    initializeForm(defaultSearchParams);
  };

  const findTimeSelectBoxValue = value => {
    if (value === 'anytime') {
      return { label: <I18nText id="departure_times.anytime" returnStringOnly={true} />, value };
    }

    return { label: value, value };
  };

  const updateDriverAgeFromPreselectedTraveler = () => {
    const driver = preselectTravelers[0];
    if (driver.traveler.value.age) {
      driverAge.onChange(driver.traveler.value.age);
    }
  };

  const onDateRangeChange = date => {
    const { startDate, endDate } = date;
    const formattedStartDate = startDate ? startDate.format('YYYY-MM-DD') : null;
    const formattedEndDate = endDate ? endDate.format('YYYY-MM-DD') : null;
    pickUpDate.onChange(formattedStartDate);
    dropOffDate.onChange(formattedEndDate);
  };

  const onSearchByLocationClick = () => {
    airportSearch.onChange(false);
    setIsLocationMenuVisible(false);
  };

  const onSearchByAirportClick = () => {
    airportSearch.onChange(true);
    setIsLocationMenuVisible(false);
  };

  const onResetButtonClick = () => {
    resetApp();
    resetBookingAppContext();
  };

  useEffect(() => {
    const vendorCodes = globalPolicyPreferences.carRentalCompanies;
    if (vendorCodes.length > 0) {
      vendorPreferences.codes.onChange(vendorCodes);
      vendorPreferences.type.onChange('exclusive');
    }
  }, []);

  useEffect(() => {
    if (!searchForm) {
      resetToDefaultSearchParams();
    }
  }, [searchForm]);

  useEffect(() => {
    if (!driverAge.touched && preselectTravelers.length > 0) {
      updateDriverAgeFromPreselectedTraveler();
    }
  }, [preselectTravelers]);

  return (
    <div
      className={classNames('car-form__container', {
        'car-form__container--show': showCarFormContainer,
      })}
    >
      <div className="d-flex col-12 col-bleed">
        <div
          className={classNames({
            'col-12 col-bleed': laymanMode,
            'col-7 hotel-form__content': !laymanMode,
          })}
        >
          <span className="search-accounts__header">
            {I18n.t('components.ibe.search_form.car.label.title')}
          </span>
          <div className="col-12 col-bleed-x car-form__specifier-container wrap">
            <div className="search-menu__container search-menu__container--location">
              <Menu
                className="search-menu__container--location-dropdown"
                isVisible={isLocationMenuVisible}
                size="small"
                popOverDirection="bottom-left"
                onOutsideClick={onLocationMenuOutsideClick}
                label={
                  <MenuLabel
                    onClick={onLocationMenuClick}
                    label={
                      airportSearch.value
                        ? I18n.t('components.ibe.search_form.car.label.search_by.airport')
                        : I18n.t('components.ibe.search_form.car.label.search_by.location')
                    }
                    isVisible={isLocationMenuVisible}
                  />
                }
              >
                <MenuItem onClick={onSearchByLocationClick}>
                  {I18n.t('components.ibe.search_form.car.label.search_by.location')}
                </MenuItem>
                <MenuItem onClick={onSearchByAirportClick}>
                  {I18n.t('components.ibe.search_form.car.label.search_by.airport')}
                </MenuItem>
              </Menu>
            </div>
            <div className="search-menu__container search-menu__container--driver">
              <Menu
                className="search-menu__container--traveller-amount-dropdown"
                isVisible={isDriverMenuVisible}
                size="medium"
                popOverDirection="bottom-left"
                onOutsideClick={onDriverMenuOutsideClick}
                label={
                  <MenuLabel
                    icon={<Icon name="adult" className="search-menu__icon" />}
                    isError={driverAge.touched && !driverAge.valid}
                    onClick={onDriverMenuClick}
                    label={
                      <MenuLabelWithPreSelectText
                        totalNumberOfTravelers={1}
                        preselectTravelersLength={preselectTravelersLength}
                        label={I18n.t('components.ibe.search_form.car.label.driver_with_age', {
                          count: MAX_DRIVER_COUNT,
                          age: driverAge.value,
                        })}
                      />
                    }
                    isVisible={isDriverMenuVisible}
                  />
                }
              >
                <Fragment>
                  <div className="search-menu-item__container">
                    <div className="car-form__preselect-header">
                      <div className="car-form__traveller-title preselect_button">
                        {I18n.t('components.ibe.search_form.car.label.driver')}
                      </div>
                      {isPreSelectTravelerButtonVisible && (
                        <PreselectTravelers
                          type="car"
                          setIsPreselectTravellerVisible={setIsPreselectTravellerVisible}
                        />
                      )}
                    </div>
                    <div className="search-menu-item__specifier">
                      <div className="search-menu-item__label">
                        {I18n.t('components.ibe.search_form.car.label.adults')}
                      </div>
                      <QuantitySelector
                        disabled={true}
                        minValue={MAX_DRIVER_COUNT}
                        maxValue={MAX_DRIVER_COUNT}
                      />
                    </div>
                    <div className="search-traveler-note">
                      <Input
                        {...driverAge}
                        placeholder={I18n.t('components.ibe.search_form.car.label.driver_age')}
                        size="tiny"
                        type="number"
                        disabled={cartHasCar}
                        label={I18n.t('components.ibe.search_form.car.label.driver_age')}
                        isClearable={false}
                      />
                    </div>
                  </div>
                  <Alert
                    className="search-menu-item__error"
                    hideClose={true}
                    isVisible={cartHasItems}
                    type="warning"
                  >
                    {I18n.t('components.ibe.search_form.error.change_number_of_travelers')}
                  </Alert>
                </Fragment>
              </Menu>
            </div>
            <div className="search-menu__container search-menu__container--vendors">
              <Menu
                className="search-menu__container--vendors-dropdown"
                isVisible={isVendorMenuVisible}
                size="small"
                popOverDirection="bottom-center"
                onOutsideClick={onVendorMenuOutsideClick}
                label={
                  <MenuLabel
                    onClick={onVendorMenuClick}
                    label={I18n.t('components.ibe.search_form.car.label.vendor_preferences')}
                    isVisible={isVendorMenuVisible}
                  />
                }
              >
                <div className="search-menu-item__container">
                  <div className="search-menu-item__specifier">
                    <div className="col-12 col-bleed">
                      <div className="search-menu-item__specifier--add-padding-bottom">
                        <VendorSelectBox
                          value={vendorPreferences.codes.value}
                          onChange={value => vendorPreferences.codes.onChange(value || [])}
                          errorMsg={
                            vendorPreferences.codes.error && vendorPreferences.codes.error[0]
                          }
                          isMulti={true}
                          width="full"
                          size="tiny"
                          placeholderText={I18n.t(
                            'components.ibe.search_form.car.label.choose_vendor',
                          )}
                          isDisabled={disableVendorPreference}
                        />
                      </div>
                      <SelectBox
                        width="full"
                        size="tiny"
                        value={filterTypeOptions.find(
                          filter => filter.value === vendorPreferences.type.value,
                        )}
                        isDisabled={
                          disableVendorPreference || vendorPreferences.codes.value.length <= 0
                        }
                        options={filterTypeOptions}
                        onChange={selectedOption =>
                          vendorPreferences.type.onChange(
                            selectedOption ? selectedOption.value : '',
                          )
                        }
                        placeholderText={I18n.t('components.ibe.search_form.car.label.filter_type')}
                        isClearable={false}
                      />
                    </div>
                  </div>
                </div>
              </Menu>
            </div>
          </div>
          <div className="col-12 col-bleed">
            {airportSearch.value ? (
              <div className="car-form__pick-drop">
                <div className="col-12 col-sm-6 col-bleed">
                  <AirportAutoComplete
                    title={I18n.t('components.ibe.search_form.car.label.pick_up.airport')}
                    error={pickUpLocationAirportCode.touched && pickUpLocationAirportCode.error}
                    currentAirport={pickUpLocationAirportCode.value}
                    {...pickUpLocationAirportCode}
                    onChange={onPickUpAirportChange}
                    fullWidth={true}
                    size="tiny"
                    suggestions={airportSuggestions[pickUpLocationAirportCode.value] || []}
                    iconName="arrowUp"
                  />
                </div>
                <div className="col-12 col-sm-6 col-bleed">
                  <AirportAutoComplete
                    title={I18n.t('components.ibe.search_form.car.label.drop_off.airport')}
                    error={dropOffLocationAirportCode.error}
                    currentAirport={dropOffLocationAirportCode.value}
                    {...dropOffLocationAirportCode}
                    fullWidth={true}
                    suggestions={airportSuggestions[dropOffLocationAirportCode.value] || []}
                    iconName="arrowDown"
                  />
                </div>
              </div>
            ) : (
              <div className="car-form__pick-drop">
                <div
                  className={classNames('col-12 col-sm-6 col-bleed', {
                    'car-form__pick-drop--admin-input': !laymanMode,
                  })}
                >
                  <LocationSearchInput
                    address={pickUpGeoLocationAddress.value}
                    placeholder={I18n.t('components.ibe.search_form.car.label.pick_up.location')}
                    fullWidth={true}
                    onAddressChange={pickUpGeoLocationAddress.onChange}
                    onLatitudeChange={pickUpGeoLocationLatitude.onChange}
                    onLongitudeChange={pickUpGeoLocationLongitude.onChange}
                    onSelect={onPickUpLocationSelect}
                    size="small"
                    touched={pickUpGeoLocationAddress.touched}
                    error={pickUpGeoLocationAddress.error}
                    icon="arrowUp"
                    selectedLocation={pickUpGeoLocationLatitude.value}
                  />
                </div>
                <div
                  className={classNames('col-12 col-sm-6 col-bleed', {
                    'car-form__pick-drop--admin-input': !laymanMode,
                  })}
                >
                  <LocationSearchInput
                    address={dropOffGeoLocationAddress.value}
                    placeholder={I18n.t('components.ibe.search_form.car.label.drop_off.location')}
                    fullWidth={true}
                    onAddressChange={dropOffGeoLocationAddress.onChange}
                    onLatitudeChange={dropOffGeoLocationLatitude.onChange}
                    onLongitudeChange={dropOffGeoLocationLongitude.onChange}
                    size="small"
                    touched={dropOffGeoLocationAddress.touched}
                    error={dropOffGeoLocationAddress.error}
                    icon="arrowDown"
                    selectedLocation={dropOffGeoLocationLatitude.value}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="car-form__date-time-container">
            <div
              className={classNames(
                'col-12 col-md-6 col-bleed car-form__date-time-container-date-wrapper',
                {
                  'car-form__date-time-container-admin-wrapper--date-picker': !laymanMode,
                },
              )}
            >
              <DateRangePicker
                required={true}
                onChange={date => onDateRangeChange(date)}
                startDatePlaceholderText={I18n.t(
                  'components.ibe.search_form.car.label.pick_up.date',
                )}
                endDatePlaceholderText={I18n.t(
                  'components.ibe.search_form.car.label.drop_off.date',
                )}
                canSelectPastDates={false}
                fullWidth={true}
                startDate={pickUpDate.value}
                endDate={dropOffDate.value}
                error={showInvalidDateError(pickUpDate) || showInvalidDateError(dropOffDate)}
                isBoundToAYear={false}
              />
            </div>

            <div className="col-md-6 col-12 col-bleed car-form__date-time-container-time-wrapper">
              <div
                className={classNames(
                  'col-6 col-bleed car-form__date-time-container-time-wrapper--pick',
                  {
                    'car-form__date-time-container-admin-wrapper--time-box': !laymanMode,
                  },
                )}
              >
                <TimeSelectBox
                  placeholderText={I18n.t('components.ibe.search_form.car.label.pick_up.time')}
                  width="full"
                  onChange={selectedOption =>
                    selectedOption && pickUpTime.onChange(selectedOption.value)
                  }
                  errorMsg={pickUpTime.touched && pickUpTime.error}
                  value={findTimeSelectBoxValue(pickUpTime.value)}
                  isClearable={false}
                  size="small"
                />
              </div>
              <div
                className={classNames(
                  'col-6 col-bleed car-form__date-time-container-time-wrapper--drop',
                  {
                    'car-form__date-time-container-admin-wrapper--time-box': !laymanMode,
                  },
                )}
              >
                <TimeSelectBox
                  placeholderText={I18n.t('components.ibe.search_form.car.label.drop_off.time')}
                  width="full"
                  onChange={selectedOption =>
                    selectedOption && dropOffTime.onChange(selectedOption.value)
                  }
                  errorMsg={dropOffTime.touched && dropOffTime.error}
                  value={findTimeSelectBoxValue(dropOffTime.value)}
                  isClearable={false}
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>
        {!laymanMode && searchForm && (
          <Card className="col-5 flight-form__channel-card">
            <SearchAccounts
              searchAccounts={props.searchAccounts.cars}
              selectedAccounts={accountSettings}
              disabled={false}
              selectAll={props.selectAll}
              toggleSelectAll={props.toggleSelectAll}
              selectGds={props.selectGds}
              toggleSelectGds={props.toggleSelectGds}
              selectDirect={props.selectDirect}
              toggleSelectDirect={props.toggleSelectDirect}
              accountSettings={props.searchForm.accountSettings}
              isChannelMissingAlertVisible={isNoChannelSelected}
            />
          </Card>
        )}
      </div>

      <div
        className={classNames('d-flex', {
          'car-form__field--add-margin-top': !laymanMode,
        })}
      >
        <div className="car-form__search-button">
          <Button
            label={I18n.t('components.ibe.search_form.label.search_car')}
            size="normal"
            version="v2"
            disabled={!!submitting || isNoChannelSelected}
            onClick={handleSubmit(handleFormSubmission)}
            type="primary"
          />
        </div>
        <Link className="hotel-form__reset-button d-flex align-center" onClick={onResetButtonClick}>
          {I18n.t('components.ibe.search_form.flight.label.reset')}
        </Link>
      </div>
    </div>
  );
};

SearchForm.defaultProps = {
  laymanMode: false,
  searchAccounts: null,
  selectAll: true,
  setIsCollapsed: () => {},
  resetBookingAppContext: () => {},
};

SearchForm.propTypes = {
  forceAdvancedSearch: PropTypes.bool.isRequired,
  createCarSearch: PropTypes.func.isRequired,
  cartHasCar: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    airportSearch: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    pickUpLocationAirportCode: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool.isRequired,
      error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    pickUpTime: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool.isRequired,
      error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    dropOffTime: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool.isRequired,
      error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    driverAge: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool.isRequired,
      error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      value: PropTypes.string.isRequired,
      valid: PropTypes.bool,
    }).isRequired,
    pickUpDate: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool.isRequired,
      error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    pickUpGeoLocationAddress: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool.isRequired,
      error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    pickUpGeoLocationLatitude: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    pickUpGeoLocationLongitude: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    dropOffDate: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool.isRequired,
      error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    dropOffLocationAirportCode: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      touched: PropTypes.bool.isRequired,
      error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
    dropOffGeoLocationAddress: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      touched: PropTypes.bool.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      error: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
    dropOffGeoLocationLatitude: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    dropOffGeoLocationLongitude: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    vendorPreferences: PropTypes.shape({
      codes: {
        onChange: PropTypes.func.isRequired,
      },
      type: {
        onChange: PropTypes.func.isRequired,
      },
    }),
    accountSettings: PropTypes.shape(),
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  availableChannels: availableChannelsShape.isRequired, // eslint-disable-line react/no-typos
  laymanMode: PropTypes.bool,
  defaultSearchParams: PropTypes.shape({}).isRequired,
  initializeForm: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  globalPolicyPreferences: PropTypes.shape({
    airlines: PropTypes.arrayOf(PropTypes.number).isRequired,
    carRentalCompanies: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  searchAccounts: searchAccountsShape,
  selectAll: PropTypes.string,
  toggleSelectAll: PropTypes.func.isRequired,
  preselectTravelers: PropTypes.arrayOf(
    PropTypes.shape({
      traveler: PropTypes.shape({
        value: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          middleName: PropTypes.string,
          lastName: PropTypes.string.isRequired,
          phoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
          emailAddresses: PropTypes.arrayOf(PropTypes.string).isRequired,
          age: PropTypes.number.isRequired,
        }),
      }),
    }),
  ).isRequired,
  resetApp: PropTypes.func.isRequired,
  searchForm: PropTypes.shape({
    accountSettings: searchAccountsShape.cars,
  }).isRequired,
  setIsCollapsed: PropTypes.func,
  cartHasItems: PropTypes.bool.isRequired,
  formWithoutChildren: PropTypes.bool.isRequired,
  selectGds: PropTypes.bool.isRequired,
  selectDirect: PropTypes.bool.isRequired,
  toggleSelectGds: PropTypes.func.isRequired,
  toggleSelectDirect: PropTypes.func.isRequired,
  resetBookingAppContext: PropTypes.func,
  travelArrangerPresent: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  airportSuggestions: PropTypes.shape(AirportShape).isRequired,
  callbackParams: PropTypes.shape().isRequired,
  preselectDecision: PropTypes.string.isRequired,
  updatePreselectTravelers: PropTypes.func.isRequired,
};

export default SearchForm;
