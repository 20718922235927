import { connect } from 'react-redux';

import SelectTravelers from './SelectTravelers';

import { customerSelected, resetTravelerDetails, saveTravelerDetails } from '../actions/common';

const mapStateToProps = state => ({
  selectedCustomers: state.common.selectedCustomers,
});

const mapDispatchToProps = dispatch => ({
  customerSelected: (index, query) => dispatch(customerSelected(index, query)),
  resetTraveler: index => {
    dispatch(resetTravelerDetails(index));
  },
  saveTravelerDetails: (index, data) => dispatch(saveTravelerDetails(index, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectTravelers);
