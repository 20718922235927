import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import getCarInformation from 'sharedWebpack/IBE/lib/helpers/getCarInformation';
import CarType from '../CarType';
import CarLocationDetails from '../CarLocationDetails';
import './styles.scss';

const CarStandardDetails = ({ className, carInformation, pickUpDetails, dropOffDetails }) => {
  const { make, name, category, acrissCode } = carInformation;
  const carInformationTitle = getCarInformation(name, make);

  return (
    <div className={classNames('grid grid-gap-12 w-100', className)}>
      <div className="col-12 hidden-md hidden-sm hidden-xs hidden-xxs">
        <h3 className="car__model">{carInformationTitle}</h3>
        <CarType category={category} acrissCode={acrissCode} />
      </div>
      <div className="col-12 col-grid row-gap-4 hidden-xlg hidden-lg car__category-container--mobile">
        <span>{carInformationTitle}</span>
        <CarType category={category} acrissCode={acrissCode} />
      </div>
      <div className="col-12">
        <CarLocationDetails pickUpDetails={pickUpDetails} dropOffDetails={dropOffDetails} />
      </div>
    </div>
  );
};

const LocationDetailsShape = {
  location: PropTypes.string,
  localtime: PropTypes.string,
};

CarStandardDetails.defaultProps = {
  className: null,
};

CarStandardDetails.propTypes = {
  className: PropTypes.string,
  carInformation: PropTypes.string.isRequired,
  pickUpDetails: PropTypes.shape(LocationDetailsShape).isRequired,
  dropOffDetails: PropTypes.shape(LocationDetailsShape).isRequired,
};

export default CarStandardDetails;
