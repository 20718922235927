import getServices from './getServices';

const getSelectedServices = (flightServicesByResultId, subItems, resultId) => {
  const allServices = flightServicesByResultId
    ? getServices(flightServicesByResultId[resultId])
    : [];

  return allServices
    .map(service => {
      const item = subItems.find(subItem => subItem.bookingAttributes.serviceId === service.id);

      return item && Number(item.quantity) > 0 ? service : null;
    })
    .filter(service => service !== null);
};

export default getSelectedServices;
