import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MenuLabelWithPreSelectText = ({
  totalNumberOfTravelers,
  preselectTravelersLength,
  className,
  label,
}) => (
  <Fragment>
    {label ? (
      <span>{label}</span>
    ) : (
      <span>
        {I18n.t('components.ibe.search_form.label.number_of_travelers', {
          count: totalNumberOfTravelers,
        })}
      </span>
    )}

    {preselectTravelersLength > 0 && (
      <span className={classnames('search-menu__menu-pre-select', className)}>
        {I18n.t('components.ibe.preselect_traveler.label.menu_label', {
          count: preselectTravelersLength,
        })}
      </span>
    )}
  </Fragment>
);

MenuLabelWithPreSelectText.defaultProps = {
  className: '',
  label: '',
};

MenuLabelWithPreSelectText.propTypes = {
  totalNumberOfTravelers: PropTypes.number.isRequired,
  preselectTravelersLength: PropTypes.number.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default MenuLabelWithPreSelectText;
