import React, { Fragment, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import HumanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import AirportChangeSegment from 'sharedWebpack/AirportChangeSegment';
import SegmentCard from './SegmentCard';
import { MAX_STOPOVER_TIME, LONG_TRANSIT_TIME } from '../../../helpers/stopoverConstants';

import './styles.scss';

const Segment = ({
  className,
  segment,
  stopovers,
  findAirport,
  findAirline,
  loadAircraft,
  fetchAircraftType,
  aircraftInformation,
}) => {
  const segmentStopover = stopovers.find(stopover => stopover.airportCode === segment.originCode);
  const segmentStopoverWithAirportChange = segmentStopover && segmentStopover.airportChange;
  const segmentStopoverAirport = segmentStopover && findAirport(segmentStopover.airportCode);

  const findFromAircrafts = aircraftCode =>
    aircraftInformation &&
    Object.values(aircraftInformation).find(aircraft => aircraft && aircraft.code === aircraftCode);

  const findChangedAirportDetails = (arrivalAirportCode, departureAirportCode) => {
    const arrival = findAirport(arrivalAirportCode);
    const departure = findAirport(departureAirportCode);

    return {
      arrival,
      departure,
    };
  };

  const changedAirportDetails =
    segmentStopoverWithAirportChange &&
    findChangedAirportDetails(
      segmentStopoverWithAirportChange.arrivalAirportCode,
      segmentStopoverWithAirportChange.departureAirportCode,
    );

  const buildEvents = () => {
    const events = [];
    events.push({
      airport: findAirport(segment.originCode),
      localTime: segment.departureTime || segment.departureLocaltime,
      terminal: segment.originTerminal,
    });
    if (segment.technicalStops && segment.technicalStops.count > 0) {
      const technicalStops = segment.technicalStops.stopDetails.map((technicalStop, index) => ({
        ...technicalStop,
        isTechnicalStop: true,
        count: index + 1,
        airport: findAirport(technicalStop.airportCode),
      }));
      events.push(...technicalStops);
    }
    events.push({
      airport: findAirport(segment.destinationCode),
      localTime: segment.arrivalTime || segment.arrivalLocaltime,
      terminal: segment.destinationTerminal,
      isDestination: true,
    });

    return events;
  };

  const StopoverLabel = ({ transitDuration }) => {
    if (transitDuration >= MAX_STOPOVER_TIME) {
      return I18n.t('components.flight_info.segment.stopover');
    }

    return I18n.t('components.flight_info.segment.stop');
  };

  useEffect(() => {
    if (loadAircraft) {
      const result = findFromAircrafts(segment.aircraftType);
      if (!result) {
        fetchAircraftType(segment.aircraftType);
      }
    }
  }, [loadAircraft]);

  return (
    <Fragment>
      {segmentStopover && (
        <div className={classNames('flight-segment-stopover', className)}>
          <div className="flight-segment-stopover__duration-content-container">
            <div>
              <div className="flight-segment-stopover__duration-content">
                <span className="flight-segment__duration">
                  {HumanReadableDuration(segmentStopover.transitDurationsInMinutes)}
                </span>
                <span className="flight-segment-stopover__header">
                  <StopoverLabel transitDuration={segmentStopover.transitDurationsInMinutes} />
                </span>
              </div>
              {segmentStopoverAirport && (
                <div className="flight-segment-stopover__details">
                  {segmentStopoverAirport.name}
                </div>
              )}
            </div>
            <div className="flight-segment-stopover__tag-content">
              <EmphasisTag
                text={
                  segmentStopover.transitDurationsInMinutes >= LONG_TRANSIT_TIME
                    ? I18n.t('components.flight.transit.long')
                    : I18n.t('components.flight.transit.short')
                }
                size="tiny"
                type="warning"
              />
            </div>
          </div>
          {changedAirportDetails &&
            changedAirportDetails.arrival &&
            changedAirportDetails.departure && (
              <AirportChangeSegment
                isBackgroundTransparent={true}
                destinationAirportDetails={changedAirportDetails.arrival}
                changedAirportDetails={changedAirportDetails.departure}
              />
            )}
        </div>
      )}
      <SegmentCard
        events={buildEvents()}
        operatingAirline={findAirline(segment.operatingCarrierCode) || segment.operatingAirlineInfo}
        issuedAirline={findAirline(segment.marketingCarrierCode) || segment.marketingAirlineInfo}
        segment={segment}
        findFromfetchedAircrafts={findFromAircrafts}
      />
    </Fragment>
  );
};

Segment.defaultProps = {
  className: null,
  aircraftInformation: {},
  loadAircraft: false,
  findAirline: () => {},
  fetchAircraftType: () => {},
};

Segment.propTypes = {
  className: PropTypes.node,
  segment: PropTypes.shape({
    flightDurationInMinutes: PropTypes.string.isRequired,
    marketingCarrierCode: PropTypes.string.isRequired,
    operatingCarrierCode: PropTypes.string.isRequired,
    originCode: PropTypes.string.isRequired,
    arrivalTime: PropTypes.string.isRequired,
    arrivalLocaltime: PropTypes.string.isRequired,
    departureTime: PropTypes.string.isRequired,
    departureLocaltime: PropTypes.string.isRequired,
    originTerminal: PropTypes.string.isRequired,
    technicalStops: PropTypes.shape({
      count: PropTypes.number.isRequired,
      stopDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    destinationCode: PropTypes.string.isRequired,
    destinationTerminal: PropTypes.string.isRequired,
    operatingAirlineInfo: PropTypes.shape({}).isRequired,
    marketingAirlineInfo: PropTypes.shape({}).isRequired,
    marketingFlightNumber: PropTypes.string.isRequired,
    aircraftType: PropTypes.string.isRequired,
  }).isRequired,
  stopovers: PropTypes.arrayOf(
    PropTypes.shape({
      airportCode: PropTypes.string.isRequired,
      arrivalLocaltime: PropTypes.string.isRequired,
      transitDurationsInMinutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  findAirport: PropTypes.func.isRequired,
  findAirline: PropTypes.func,
  loadAircraft: PropTypes.bool,
  fetchAircraftType: PropTypes.func,
  aircraftInformation: PropTypes.objectOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      imageUrls: {
        small: PropTypes.string,
        medium: PropTypes.string,
        large: PropTypes.string,
      },
    }),
  ),
};

export default Segment;
