import React from 'react';
import PropTypes from 'prop-types';

const Category = props => (
  <React.Fragment>
    {I18n.t(`components.ibe.car_categories.${props.category}`)}
  </React.Fragment>
);

Category.propTypes = {
  category: PropTypes.string.isRequired,
};

export default Category;
