import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const APPROVAL_REQUEST_SAVED = 'APPROVAL_REQUEST_SAVED';
function approvalRequestSaved(data) {
  return {
    type: APPROVAL_REQUEST_SAVED,
    id: data.id,
  };
}

export const SAVING_APPROVAL_REQUEST = 'SAVING_APPROVAL_REQUEST';
function savingApprovalRequest(approvalRequest) {
  return {
    type: SAVING_APPROVAL_REQUEST,
    approvalRequest,
  };
}

export function saveApprovalRequest(callbackParams, optionalApproverIds = []) {
  return (dispatch, getState) => {
    const state = getState();
    const cart = state.common.cart;
    const selectedCustomers = state.common.selectedCustomers;

    const approvalRequest = {
      cart_id: cart.id,
      callback_params: {
        ...callbackParams,
        person_id: selectedCustomers && selectedCustomers[0] && selectedCustomers[0].id,
        all_traveler_ids: selectedCustomers.map(customer => customer.id),
      },
      optional_approver_ids: optionalApproverIds,
    };
    dispatch(savingApprovalRequest(approvalRequest));

    return httpClient
      .post(routes.api.approvalRequests.create(), approvalRequest)
      .then(({ data }) => {
        dispatch(approvalRequestSaved(data));
        window.location.href = routes.api.approvalRequests.redirectUrl(
          data,
          !!callbackParams.layman,
        );
      });
  };
}

export const FETCHING_APPROVAL_REQUEST = 'FETCHING_APPROVAL_REQUEST';
function fetchingApprovalRequest(id) {
  return {
    type: FETCHING_APPROVAL_REQUEST,
    id,
  };
}

export const APPROVAL_REQUEST_FETCHED = 'APPROVAL_REQUEST_FETCHED';
function approvalRequestFetched(approvalRequest) {
  return {
    type: APPROVAL_REQUEST_FETCHED,
    approvalRequest,
  };
}

export function fetchApprovalRequest(id, callbackParams) {
  const approvalRequestParams = {
    callbackParams,
    approvalRequestId: id,
  };
  return dispatch => {
    dispatch(fetchingApprovalRequest(id));
    return httpClient
      .get(routes.api.approvalRequests.fetch(approvalRequestParams))
      .then(({ data }) => {
        dispatch(approvalRequestFetched(data));
        return data;
      });
  };
}
