import { combineReducers } from 'redux';
import { mapCartGroups, replaceItemInGroup } from '../../helpers/multiSearchMapping';

import {
  DEAL_FETCHED,
  FETCHING_DEAL,
  SET_DEAL_NAME,
  SET_DEAL_TEASER,
  MSEARCH_RESULT_CONFIRMED,
} from '../../actions/common';

function groups(state = [], action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return mapCartGroups(action.deal.groups);

    case MSEARCH_RESULT_CONFIRMED:
      return replaceItemInGroup(
        state,
        action.groupKey.toString(),
        action.cartItem,
        action.oldItemId,
      );

    default:
      return state;
  }
}

function name(state = '', action) {
  switch (action.type) {
    case SET_DEAL_NAME:
      return action.name;

    case DEAL_FETCHED:
      return action.deal.name;

    default:
      return state;
  }
}

function teaser(state = '', action) {
  switch (action.type) {
    case SET_DEAL_TEASER:
      return action.teaser;

    case DEAL_FETCHED:
      return action.deal.teaser;

    default:
      return state;
  }
}

function id(state = null, action) {
  switch (action.type) {
    case FETCHING_DEAL:
      return action.id;

    default:
      return state;
  }
}

function person(state = {}, action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.person;

    default:
      return state;
  }
}

function adAmount(state = 0, action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.adAmount;

    default:
      return state;
  }
}

function featureImage(state = '', action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.featureImage;

    default:
      return state;
  }
}

function features(state = [], action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.features;

    default:
      return state;
  }
}

function categories(state = [], action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.categories;

    default:
      return state;
  }
}

function interests(state = [], action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.interests;

    default:
      return state;
  }
}

function enquireOnly(state = false, action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.enquireOnly;

    default:
      return state;
  }
}

function locations(state = [], action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.locations;

    default:
      return state;
  }
}

function imageList(state = [], action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.imageList;

    default:
      return state;
  }
}

function favouriteDealCount(state = 0, action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.favouriteDealCount;

    default:
      return state;
  }
}

function favouriteDealPeople(state = [], action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.favouriteDealPeople;

    default:
      return state;
  }
}

function favouriteOfCurrentPerson(state = false, action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.favouriteOfCurrentPerson;

    default:
      return state;
  }
}

function cartId(state = null, action) {
  switch (action.type) {
    case DEAL_FETCHED:
      return action.deal.cartId;

    default:
      return state;
  }
}

const deal = combineReducers({
  id,
  person,
  name,
  groups,
  teaser,
  adAmount,
  featureImage,
  features,
  imageList,
  categories,
  locations,
  interests,
  enquireOnly,
  favouriteDealCount,
  favouriteDealPeople,
  favouriteOfCurrentPerson,
  cartId,
});

export default deal;
