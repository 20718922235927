/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import formattedAmount from './formattedAmount';

function Service(props) {
  const { currency, quantity, unitPrice } = props;

  return (
    <div className="cart-summary__accordion-sub-item-details-category">
      <span>
        {parseInt(quantity, 10)} x {formattedAmount(unitPrice, currency)}
      </span>
    </div>
  );
}

Service.defaultProps = {
  unitPrice: '0.0',
};

Service.propTypes = {
  currency: PropTypes.string.isRequired,
  unitPrice: PropTypes.string,
};

export default Service;
