import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

const FareBrandList = ({ className, children }) => (
  <div className={ClassNames('flight__prices__fare-packages', className)}>{children}</div>
);

FareBrandList.defaultProps = {
  className: null,
};

FareBrandList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FareBrandList;
