import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckBox from '@wtag/rcl-check-box';

const AirlineList = ({ airlines, onChangeAirline, disabled }) => (
  <ul className="filter__options filter__options--styled-scrollbar">
    {airlines.map(option => {
      const { label, value, isChecked } = option;

      return (
        <li key={value} className={classNames('filter__options-option')}>
          <CheckBox
            label={label}
            name={value}
            size="small"
            isChecked={isChecked}
            disabled={disabled}
            onChange={() => {
              onChangeAirline(option);
            }}
          />
        </li>
      );
    })}
  </ul>
);

AirlineList.propTypes = {
  airlines: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isChecked: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onChangeAirline: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AirlineList;
