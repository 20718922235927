import React from 'react';
import PropTypes from 'prop-types';
import HumanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import DateTime from 'sharedWebpack/DateTime';
import AirportChangeSegment from 'sharedWebpack/AirportChangeSegment';
import Card from '@wtag/rcl-card';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import {
  MAX_STOPOVER_TIME,
  LAYOVER_TYPE_STOP,
  LAYOVER_TYPE_STOPOVER,
} from '../../helpers/stopoverConstants';
import '../styles.scss';

const Flight = ({ data }) => {
  const {
    changedAirport,
    travelers,
    originDetails,
    destinationDetails,
    cabinClass,
    bookingClass,
    fareType,
    departureLocaltime,
    destinationTerminal,
    airlineLogoUrl,
    originTerminal,
    wifiAvailable,
    layoverAirport,
    airlineCarrierCode,
    flightDurationInMinutes,
    arrivalLocaltime,
  } = data;
  const hasChangedAirport = changedAirport && Object.keys(changedAirport).length > 0;

  const getFlightLayoverTypeLabel = layoverType => {
    const { code, name, cityName, countryName } = destinationDetails;
    return I18n.t('admin.components.orders.overview.travel_plan.flight.stop', {
      label: layoverType,
      code,
      airport_name: name,
      city: cityName,
      country: countryName,
    });
  };

  const getStopOrStopover = () => {
    if (layoverAirport.durationInMinutes >= MAX_STOPOVER_TIME) {
      return getFlightLayoverTypeLabel(LAYOVER_TYPE_STOPOVER);
    }

    return getFlightLayoverTypeLabel(LAYOVER_TYPE_STOP);
  };

  return (
    <div className="grid grid-gap-20">
      <div className="col-12">
        <Card className="travel-plan__timeline-flight-section-card" version="v2">
          <div className="travel-plan__timeline-flight-section-card-header">
            <img
              className="travel-plan__timeline-flight-section-card-header-flag"
              src={originDetails?.countryFlag}
              alt={originDetails?.countryName}
            />
            <img
              className="travel-plan__timeline-flight-section-card-header-flag"
              src={destinationDetails && destinationDetails.countryFlag}
              alt={destinationDetails && destinationDetails.countryName}
            />
            <div className="travel-plan__timeline-flight-section-card-header-airport-info">
              {`${originDetails && originDetails.code} - ${destinationDetails &&
                destinationDetails.code}`}
            </div>
            <div className="travel-plan__timeline-flight-section-card-header-tags">
              {data && cabinClass && (
                <EmphasisTag
                  className="travel-plan__timeline-flight-section-card-header-tags-item"
                  text={I18n.t(cabinClass, { scope: 'cabin_classes' })}
                  size="tiny"
                />
              )}
              {data && bookingClass && (
                <EmphasisTag
                  className="travel-plan__timeline-flight-section-card-header-tags-item"
                  text={bookingClass}
                  size="tiny"
                />
              )}
              {fareType && (
                <EmphasisTag
                  className="travel-plan__timeline-flight-section-card-header-tags-item"
                  text={fareType}
                  size="tiny"
                  type="success"
                />
              )}
            </div>
            <div className="travel-plan__timeline-flight-section-card-header-service">
              <Icon
                className={`travel-plan__timeline-flight-section-card-header-service-icon ${travelers &&
                  !travelers.ticketNumber &&
                  'travel-plan__timeline-flight-section-card-header-service-icon--disabled'}`}
                name="eTicket"
                showBGColor={true}
                color={travelers && travelers.ticketNumber ? 'success' : 'tertiary'}
                iconLabel={I18n.t('components.flight.seat.label')}
                size="small"
                showTooltip={true}
              />
              <Icon
                className={`travel-plan__timeline-flight-section-card-header-service-icon ${travelers &&
                  !travelers.seatNumber &&
                  'travel-plan__timeline-flight-section-card-header-service-icon--disabled'}`}
                name="seat"
                showBGColor={true}
                color={travelers && travelers.seatNumber ? 'success' : 'tertiary'}
                iconLabel={I18n.t('components.flight.seat.label')}
                size="small"
                showTooltip={true}
              />
              <Icon
                className={`travel-plan__timeline-flight-section-card-header-service-icon ${travelers &&
                  !wifiAvailable &&
                  'travel-plan__timeline-flight-section-card-header-service-icon--disabled'}`}
                name="wifi"
                showBGColor={true}
                color={travelers && wifiAvailable ? 'success' : 'tertiary'}
                iconLabel={I18n.t('components.flight.wifi.label')}
                size="small"
                showTooltip={true}
              />
              <Icon
                className="travel-plan__timeline-flight-section-card-header-service-icon travel-plan__timeline-flight-section-card-header-service-icon--disabled"
                name="meal"
                showBGColor={true}
                color="tertiary"
                iconLabel={I18n.t('components.flight.meal.label')}
                size="small"
                showTooltip={true}
              />
              <Icon
                className={`travel-plan__timeline-flight-section-card-header-service-icon ${travelers &&
                  !travelers.services &&
                  'travel-plan__timeline-flight-section-card-header-service-icon--disabled'}`}
                name="baggage"
                showBGColor={true}
                color={travelers && travelers.services ? 'success' : 'tertiary'}
                iconLabel={I18n.t('components.flight.baggage.label')}
                size="small"
                showTooltip={true}
              />
            </div>
          </div>
          <div className="travel-plan__section-divider travel-plan__timeline-flight-section-divider" />
          <div className="travel-plan__timeline-flight-section-card-body">
            <div className="travel-plan__timeline-flight-section-card-body-flight-info">
              <div className="travel-plan__timeline-flight-section-card-body-departure-info">
                <div className="travel-plan__timeline-item-indicator travel-plan__timeline-item-indicator travel-plan__timeline-flight-section-card-body-departure-info-indicator">
                  <div className="travel-plan__timeline-item-indicator-dot" />
                  <div className="travel-plan__timeline-item-indicator-line" />
                </div>
                <div className="travel-plan__timeline-flight-section-card-body-departure">
                  <div className="travel-plan__timeline-flight-section-card-body-departure-time">
                    <DateTime dateTime={departureLocaltime} format="shortWithTime" />
                  </div>
                  {originTerminal && (
                    <div className="travel-plan__timeline-flight-section-card-body-departure-terminal">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.flight.header.terminal',
                        {
                          terminal: originTerminal,
                        },
                      )}
                    </div>
                  )}
                  <div className="travel-plan__timeline-flight-section-card-body-departure-airport">
                    {`${originDetails && originDetails.code} - ${originDetails &&
                      originDetails.name}, ${originDetails &&
                      originDetails.cityName}, ${originDetails && originDetails.countryName}`}
                  </div>
                </div>
              </div>
              <div className="travel-plan__timeline-flight-section-card-body-arrival-info">
                <div className="travel-plan__timeline-item-indicator travel-plan__timeline-flight-section-card-body-arrival-info-indicator">
                  <div className="travel-plan__timeline-item-indicator-dot" />
                </div>
                <div className="travel-plan__timeline-flight-section-card-body-arrival">
                  <div className="travel-plan__timeline-flight-section-card-body-arrival-time">
                    <DateTime dateTime={arrivalLocaltime} format="shortWithTime" />
                  </div>
                  {destinationTerminal && (
                    <div className="travel-plan__timeline-flight-section-card-body-arrival-terminal">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.flight.header.terminal',
                        {
                          terminal: destinationTerminal,
                        },
                      )}
                    </div>
                  )}
                  <div className="travel-plan__timeline-flight-section-card-body-arrival-airport">
                    {`${destinationDetails && destinationDetails.code} - ${destinationDetails &&
                      destinationDetails.name}, ${destinationDetails &&
                      destinationDetails.cityName}, ${destinationDetails &&
                      destinationDetails.countryName}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="travel-plan__timeline-flight-section-card-body-image">
              {/* Airline Image/Flight Image */}
            </div>
          </div>
          <div className="travel-plan__section-divider travel-plan__timeline-flight-section-divider" />
          <div className="travel-plan__timeline-flight-section-card-footer">
            <img
              className="travel-plan__timeline-flight-section-card-footer-logo"
              src={airlineLogoUrl && airlineLogoUrl}
              alt="airline"
            />
            {airlineCarrierCode && (
              <EmphasisTag
                className="travel-plan__timeline-flight-section-card-footer-carrier"
                text={airlineCarrierCode}
                size="tiny"
                type="neutral"
              />
            )}
            <div className="travel-plan__timeline-flight-section-card-footer-duration">
              <span className="travel-plan__timeline-flight-section-card-footer-duration-title">
                {I18n.t('components.flight.duration')}
              </span>
              <span className="travel-plan__timeline-flight-section-card-footer-duration-value">
                {HumanReadableDuration(flightDurationInMinutes)}
              </span>
            </div>
          </div>
        </Card>
      </div>
      {layoverAirport && (
        <div className="col-12 travel-plan__timeline-layover-card">
          <div className="grid grid-gap-20">
            <div className="col-12">
              <Card version="v2">
                <div className="travel-plan__timeline-layover-card__content">
                  <div className="travel-plan__timeline-layover-card-info">
                    <div className="travel-plan__timeline-layover-card-info-time">
                      {HumanReadableDuration(layoverAirport.durationInMinutes)}
                    </div>
                    <div className="travel-plan__timeline-layover-card-info-airport-name">
                      {getStopOrStopover()}
                    </div>
                  </div>
                  {hasChangedAirport && (
                    <AirportChangeSegment
                      className="travel-plan__timeline-layover-card-info--airport-change"
                      destinationAirportDetails={{
                        ...destinationDetails,
                        placeName: destinationDetails.cityName,
                      }}
                      changedAirportDetails={{
                        ...changedAirport,
                        placeName: layoverAirport.cityName,
                      }}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Flight.propTypes = {
  data: PropTypes.shape({
    changedAirport: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }).isRequired,
    tripOriginDetails: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
    tripDestinationDetails: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
    departureLocaltime: PropTypes.string,
    arrivalLocaltime: PropTypes.string,
    originTerminal: PropTypes.string,
    destinationTerminal: PropTypes.string,
    cabinClass: PropTypes.string,
    wifiAvailable: PropTypes.string,
    flightDurationInMinutes: PropTypes.string,
    bookingClass: PropTypes.string,
    fareType: PropTypes.string,
    originDetails: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
    destinationDetails: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
    airlineCarrierCode: PropTypes.string,
    airlineLogoUrl: PropTypes.string,
    layoverAirport: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      durationInMinutes: PropTypes.string,
    }),
    baggagePiecesIncluded: PropTypes.shape({
      count: PropTypes.string,
      kgPerPiece: PropTypes.string,
    }),
    travelers: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        avatarUrl: PropTypes.string,
        seatNumber: PropTypes.string,
        ticketNumber: PropTypes.string,
        services: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default Flight;
