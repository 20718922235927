/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Row from '../Row';

function Payment(props) {
  const { item } = props;

  return (
    <div className="cart-summary__accordion-sub-item">
      <Row
        name={item.title}
        price={item.amount}
        currency={item.currency}
        type={item.type}
        hasChildRow={false}
      />
    </div>
  );
}

Payment.propTypes = {
  item: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    grossAmount: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
    grossPrice: PropTypes.string.isRequired,
  }).isRequired,
};
export default Payment;
