import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem } from '@wtag/rcl-accordion';
import { EmphasisTag } from '@wtag/react-comp-lib';
import JourneySummaryIndicator from 'sharedWebpack/JourneySummaryIndicator';
import EmptyServicePlaceholder from '../EmptyServicePlaceholder';
import Service from '../Service';
import { SUCCESS, DISABLED } from '../../../../../lib/helpers/componentStates';
import './styles.scss';

const ItemServices = ({ services, originDetails, destinationDetails, onChange, travelers }) => {
  const [isAccordionActive, setIsAccordionActive] = useState(false);
  const serviceCount = services.length;

  const toggleAccordion = () => {
    setIsAccordionActive(prev => !prev);
  };

  return (
    <div className="item-services">
      <div className="item-services__title">
        {I18n.t('components.ibe.flight_service_selection.item_services')}
      </div>
      <Accordion>
        <AccordionItem
          isActive={isAccordionActive}
          onClick={toggleAccordion}
          header={
            <div className="item-services__header">
              <div className="item-services__header--left">
                <JourneySummaryIndicator
                  originCountryInfo={originDetails}
                  destinationCountryInfo={destinationDetails}
                />
                <div>{I18n.t('components.ibe.flight_service_selection.add_to_whole_item')}</div>
              </div>
              <div className="item-services__header--right">
                <EmphasisTag
                  text={I18n.t('components.ibe.flight_service_selection.item_services_count', {
                    count: serviceCount,
                  })}
                  type={serviceCount > 0 ? SUCCESS : DISABLED}
                  size="tiny"
                />
              </div>
            </div>
          }
        >
          <div className="item-services__subheader">
            {I18n.t('components.ibe.flight_service_selection.available_item_services')}
          </div>
          <div className="item-services__container">
            {serviceCount === 0 ? (
              <EmptyServicePlaceholder
                text={I18n.t('components.ibe.flight_service_selection.item_services_count', {
                  count: serviceCount,
                })}
              />
            ) : (
              <div className="item-services__content">
                {services.map(service => (
                  <Service
                    key={service.id}
                    onChange={(quantity, price, travelerIndices) =>
                      onChange(service.id, quantity, price, travelerIndices)
                    }
                    travelers={travelers}
                    service={service}
                  />
                ))}
              </div>
            )}
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

ItemServices.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      price: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      travelerIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
  ).isRequired,
  originDetails: PropTypes.shape({
    code: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
    countryFlag: PropTypes.string.isRequired,
  }).isRequired,
  destinationDetails: PropTypes.shape({
    code: PropTypes.string,
    countryName: PropTypes.string,
    countryFlag: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      name: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

export default ItemServices;
