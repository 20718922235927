import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function CartSummaryAccordionItemBody(props) {
  const { children, isVisible } = props;

  return (
    <div
      className={classNames('cart-summary__accordion-item-body', {
        'cart-summary__accordion-item-body--visible': isVisible,
      })}
    >
      {children}
    </div>
  );
}

CartSummaryAccordionItemBody.defaultProps = {
  isVisible: false,
};

CartSummaryAccordionItemBody.propTypes = {
  children: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
};

export default memo(CartSummaryAccordionItemBody);
