import React, { useEffect, useState, useMemo } from 'react';
import Icon from '@wtag/rcl-icon';
import { hot } from 'react-hot-loader';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { v4 as uuidv4 } from 'uuid';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import useViewMode from 'sharedWebpack/useViewMode';
import {
  DESKTOP_TAB_DEVICE,
  TAB_MOBILE_DEVICE,
  MOBILE_DEVICE,
  SMALL_MOBILE_DEVICE,
} from 'sharedWebpack/old/libraries/responsiveHelpers';
import FeedItem from './Item';

const LIVE_FEED_POLLING_DELAY = 20 * 1000; // 20 seconds
const TAB_VIEW_ITEM_COUNT = 3;
const MOBILE_VIEW_ITEM_COUNT = 2;
const LARGE_SCREEN_VIEW_ITEM_COUNT = 5;

const LiveFeed = () => {
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const continuePolling = React.useRef(true);
  const viewMode = useViewMode();

  const numberOfItemsToShowBasedOnViewportWidth = () => {
    if ([DESKTOP_TAB_DEVICE, TAB_MOBILE_DEVICE].includes(viewMode)) {
      return TAB_VIEW_ITEM_COUNT;
    }

    if ([MOBILE_DEVICE, SMALL_MOBILE_DEVICE].includes(viewMode)) {
      return MOBILE_VIEW_ITEM_COUNT;
    }

    return LARGE_SCREEN_VIEW_ITEM_COUNT;
  };

  const waitAndPoll = () =>
    setTimeout(() => {
      fetchLiveFeed(); // eslint-disable-line no-use-before-define
    }, LIVE_FEED_POLLING_DELAY);

  const fetchLiveFeed = async () => {
    if (!continuePolling.current) return;

    const { data } = await httpClient.get(routes.api.liveFeed());
    setBookings(data);
    setIsLoading(false);
    waitAndPoll();
  };

  useEffect(() => {
    fetchLiveFeed();

    return () => {
      continuePolling.current = false;
    };
  }, []);

  const bookingItems = useMemo(
    () =>
      bookings
        .slice(0, numberOfItemsToShowBasedOnViewportWidth())
        .map(booking => (
          <FeedItem
            key={uuidv4()}
            time={booking.time}
            type={booking.type}
            details={booking.details}
            initial={booking.initial}
          />
        )),
    [bookings],
  );

  return (
    <div className="col-grid col-bleed live-feed">
      <div className="col-12 col-bleed col-grid direction-row justify-space-between live-feed__header">
        <div className="live-feed__header-icon">
          <Icon
            name="liveTv"
            color="danger"
            size="small"
            iconLabel={I18n.t('public.components.mid_section.live_feed.title')}
            showLabel={true}
          />
        </div>
      </div>

      <div className="col-12 col-bleed live-feed__content styled-scrollbar">
        {isLoading ? (
          <ContentLoaderPlaceholder numberOfLines={10} showBackground={false} />
        ) : (
          !bookings.length && (
            <div className="live-feed__content-empty">
              {I18n.t('public.components.mid_section.live_feed.empty_feed')}
            </div>
          )
        )}
        {bookingItems}
      </div>
    </div>
  );
};

export default hot(module)(LiveFeed);
