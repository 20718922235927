import { FLIGHT_SEARCH_FETCHED, FLIGHT_SEARCH_CREATED } from '../../actions/flight';

const initialState = () => ({
  price: { min: null, max: null },
  stopCounts: [],
  airline: [],
  catering: [],
  baggage: [0, 1],
  tripSpecific: [],
  multiTicket: [0, 1],
  availableSortOptions: []
});

export default function searchStatsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case FLIGHT_SEARCH_FETCHED:
      newState[action.searchId] = {
        price: action.stats.price,
        stopoverCount: action.stats.stopoverCount,
        airline: action.stats.airline,
        catering: action.stats.catering,
        baggage: action.stats.baggage,
        multiTicket: action.stats.multiTicket,
        tripSpecific: action.stats.tripSpecific,
        availableSortOptions: action.stats.availableSortOptions
      };
      return newState;

    case FLIGHT_SEARCH_CREATED:
      newState[action.searchId] = initialState();
      return newState;

    default:
      return state;
  }
}
