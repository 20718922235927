import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { hot } from 'react-hot-loader';
import { v4 as uuidv4 } from 'uuid';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import { EmphasisTag, Textarea } from '@wtag/react-comp-lib';
import Modal from 'sharedWebpack/Modal';
import { VerticalMenu, VerticalMenuItem } from '@wtag/rcl-vertical-menu';
import TravellersTab from 'sharedWebpack/Orders/Show/TravellersTab';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';
import { StringParam, useQueryParams } from 'use-query-params';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import { FormatDateTime, DATE_FORMAT_SHORT } from '../../helpers/dateTime';
import Heading from '../../../public/shared/Heading';
import LeftPanel from './LeftPanel';
import ItemsTab from './ItemsTab';
import OverviewTab from '../../../public/shared/Orders/OverviewTab';
import AccountingTab from '../../../admin/Orders/Show/AccountingTab';
import './styles.scss';

const ApprovalRequestShow = ({ orderId, cartId, requestId, confirmationId, fetchUrl, layman }) => {
  const [confirmations, setConfirmations] = useState([]);
  const [executiveConfirmations, setExecutiveConfirmations] = useState([]);
  const [owner, setOwner] = useState(null);
  const [arranger, setArranger] = useState(null);
  const [billingContact, setBillingContact] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [ordersLeftPanelLoading, setOrdersLeftPanelLoading] = useState(true);
  const [approversLoading, setApproversLoading] = useState(true);
  const [request, setRequest] = useState({});
  const [fetchingRequest, setFetchingRequest] = useState(true);
  const [approverSwappable, setApproverSwappable] = useState(false);
  const [approvers, setApprovers] = useState([]);
  const [potentialApprovers, setPotentialApprovers] = useState([]);
  const [minApprovals, setMinApprovals] = useState([]);
  const [order, setOrder] = useState();
  const [cart, setCart] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const [isCartItemsLoading, setIsCartItemsLoading] = useState(true);
  const [fees, setFees] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [query] = useQueryParams({
    tab: StringParam,
  });
  const { tab } = query;

  let isApprovalRequest = false;
  let hasOrder = false;
  let scope = 'admin';

  if (requestId) {
    isApprovalRequest = true;
  }

  if (orderId) {
    hasOrder = true;
  }

  if (layman) {
    scope = 'public';
  }

  const verticalMenuItems = useMemo(() => {
    const items = [
      {
        key: 0,
        route: 'overview',
        name: I18n.t('public.components.approvals.tabs.overview'),
        tabContent: (
          <OverviewTab
            order={order}
            cartFees={fees}
            isOrderLoading={ordersLeftPanelLoading}
            cartItems={cartItems}
            isCartItemsLoading={isCartItemsLoading}
            fromApprovalRequest={true}
            layman={layman}
            cart={cart}
          />
        ),
      },
      {
        key: 1,
        route: 'items',
        name: I18n.t('public.components.approvals.tabs.items'),
        tabContent: (
          <ItemsTab
            requestId={requestId}
            confirmationId={confirmationId}
            scope={scope}
            layman={layman}
          />
        ),
      },
    ];

    if (orderId) {
      items.push(
        {
          key: 2,
          route: 'travellers',
          name: I18n.t('public.components.approvals.tabs.travellers'),
          tabContent: <TravellersTab orderId={orderId} layman={layman} />,
        },
        {
          key: 3,
          route: 'accounting',
          name: I18n.t('public.components.approvals.tabs.accounting'),
          tabContent: (
            <AccountingTab
              orderId={orderId}
              scope={scope}
              actionsEnabled={false}
              fromApprovalRequest={true}
            />
          ),
        },
      );
    }

    return items;
  }, [
    order,
    orderId,
    fees,
    ordersLeftPanelLoading,
    cartItems,
    isCartItemsLoading,
    requestId,
    confirmationId,
    cart,
  ]);
  const initialActiveTabIndex = useMemo(() => {
    const selectedTabItem = verticalMenuItems.find(item => item.route === tab);

    if (selectedTabItem) {
      return selectedTabItem.key;
    }

    return 0;
  }, [verticalMenuItems, tab]);
  const [activeTabLink, setActiveTabLink] = useState(tab || verticalMenuItems[0].route);
  const [selectedTabIndex, setSelectedTabIndex] = useState(initialActiveTabIndex);

  const getVerticalMenuItems = useCallback(
    () =>
      verticalMenuItems.map(item => (
        <VerticalMenuItem
          key={uuidv4()}
          route={item.route}
          onClick={() => {
            setActiveTabLink(item.route);
            setSelectedTabIndex(item.key);
          }}
        >
          {item.name}
        </VerticalMenuItem>
      )),
    [verticalMenuItems],
  );

  const fetchOrderUrl = layman
    ? routes.public.orders.show({ id: orderId })
    : routes.admin.order({ id: orderId });

  const fetchOrder = async () => {
    const { data } = await httpClient.get(fetchOrderUrl);
    setOrder(data.order);
    setArranger(data.order.arrangedBy);
    setBillingContact(data.order.billingContact);
    setTenant(data.order.tenant);
    setOrdersLeftPanelLoading(false);
    setApproversLoading(false);
  };

  const fetchCart = async () => {
    const { data } = await httpClient.get(
      routes.api.carts.fetchCart({ cartId, show_travel_plan: true }),
    );

    const buildOrderFormatFromCart = {
      currency: data.currency,
      accounting: {
        totalAmount: data.total,
        servicesAmount: data.totalFees,
        taxes: data.taxes,
      },
      departureDate: data.startsAt,
      arrivalDate: data.endsAt,
      products: data.items.map(item => item.type),
    };

    const buildItemFormatFromCart = data.items.map(item => ({
      title: item.itemTitle,
      grossAmount: item.grossAmount,
      currency: item.currency,
      journeyElementType: item.journeyElementType,
    }));

    setOrder(buildOrderFormatFromCart);
    setCartItems(buildItemFormatFromCart);
    setFees(data.fees);
    setOrdersLeftPanelLoading(false);
    setIsCartItemsLoading(false);
    setApproversLoading(false);
    setCart(data);
  };

  const fetchOverviewDetails = () => {
    if (hasOrder) {
      fetchOrder();
    } else {
      fetchCart();
    }
  };

  const fetchRequestDetails = async () => {
    setFetchingRequest(true);
    const { data } = await httpClient.get(fetchUrl);
    setRequest(data.request);
    setConfirmations(data.request.confirmations);
    setExecutiveConfirmations(data.request.executiveConfirmations);
    setApproverSwappable(data.request.approverSwappable);
    setPotentialApprovers(data.request.potentialApprovers);
    setApprovers(data.request.approvers);
    setMinApprovals(data.request.minimumApprovals);
    setOwner(data.request.owner);
    const specificConfirmation = data.request.confirmations.find(
      confirmation => confirmation.id === confirmationId,
    );
    setDeclineReason(specificConfirmation ? specificConfirmation.declineReason : '');
    setFetchingRequest(false);
  };

  const approveRequest = async () => {
    setDisableButton(true);
    const { data } = await httpClient.put(
      routes[scope].approvals.confirmations.approve({ id: confirmationId }),
    );

    if (data.error === null) {
      fetchRequestDetails();
    }
  };

  const denyRequest = async () => {
    setDisableButton(true);
    const { data } = await httpClient.put(
      routes[scope].approvals.confirmations.deny({
        id: confirmationId,
      }),
      {
        confirmation: {
          reason: null,
          decline_reason: declineReason,
        },
      },
    );

    if (data.error === null) {
      fetchRequestDetails();
    }
  };

  const cancelRequest = async () => {
    const { data } = await httpClient.delete(
      routes[scope].approvals.requests.cancel({ requestId }),
    );
    if (data.error === null) {
      window.location.assign(request.urls.requests);
    }
  };

  const modalTitleContent = (
    <div
      onClick={() => setIsModalVisible(false)}
      onKeyDown={() => setIsModalVisible(false)}
      role="presentation"
      className="top-nav__account-switch-modal-title"
    >
      <div>{I18n.t('public.components.approvals.confirmations.decline_reason')}</div>
      <div>
        <Icon name="close" />
      </div>
    </div>
  );

  const confirmationSection = () => (
    <div className="request__heading-status">
      {request.bookingDate && (
        <div className="request__heading-date">
          <DateTimeLocaleInterpolationWrapper
            localeKey="admin.components.orders.table.booked_on"
            time={FormatDateTime({
              dateTime: request.bookingDate,
              format: DATE_FORMAT_SHORT,
            })}
          />
        </div>
      )}
      <div className="request__heading-approve-button">
        <Button
          label={I18n.t('public.components.approvals.confirmations.actions.approve')}
          type="default"
          version="v2"
          size="normal"
          onClick={approveRequest}
          disabled={disableButton}
        />
      </div>
      <div className="request__heading-decline-button">
        <Button
          className="confirm-pending-cancel__button"
          label={I18n.t('public.components.approvals.confirmations.actions.deny')}
          version="v2"
          size="normal"
          type="danger"
          onClick={() => setIsModalVisible(true)}
        />
        <Modal
          className="request__heading-modal-wrapper"
          isModalVisible={isModalVisible}
          title={modalTitleContent}
          onOutsideClick={() => setIsModalVisible(false)}
        >
          <div className="request__heading-modal-textarea">
            <Textarea
              label={I18n.t('public.components.approvals.confirmations.reason')}
              placeholder={I18n.t('public.components.approvals.confirmations.placeholder.reason')}
              value={declineReason}
              onChange={event => setDeclineReason(event.target.value)}
            />
          </div>
          <div className="confirm-pending-cancel__modal__button">
            <Button
              version="v2"
              size="small"
              label={I18n.t('public.components.approvals.confirmations.decline_request')}
              onClick={denyRequest}
              disabled={disableButton}
              type="primary"
            />
          </div>
        </Modal>
      </div>
    </div>
  );

  const requestHeadingStatusTagLength = text =>
    text.length > 13
      ? 'request__heading-button request__heading-button--cancel--long'
      : 'request__heading-button request__heading-button--cancel--short';

  const requestsSection = (type, text, iconName) => (
    <div className="request__heading-status">
      <EmphasisTag
        className="request__heading-status-tag"
        type={type}
        text={text}
        icon={<Icon name={iconName} color="default" size="small" />}
      />

      {request.bookingPossible && (
        <div className="request__heading-button">
          <Button
            label={I18n.t('components.ibe.book_quote.book_now')}
            type="primary"
            version="v2"
            size="normal"
            onClick={() => window.location.assign(request.urls.booking)}
          />
        </div>
      )}
      <div
        className={classnames(
          'request__heading-button request__heading-button--cancel',
          requestHeadingStatusTagLength(text),
        )}
      >
        <Button
          label={I18n.t('shared.actions.cancel')}
          version="v2"
          size="normal"
          onClick={cancelRequest}
          disabled={!request.cancellable}
        />
      </div>
    </div>
  );

  const checkStatus = () => {
    if (confirmationId) {
      const allConfirmations = [...confirmations, ...executiveConfirmations];
      const confirmation = allConfirmations.find(obj => obj.id === confirmationId);
      return confirmation.status;
    }

    return request.status;
  };

  const renderStatus = () => {
    const status = checkStatus();
    let iconName;
    let type;
    let text;
    switch (status) {
      case 'pending':
        iconName = 'loading';
        type = 'warning';
        text = I18n.t('public.components.approvals.requests.status.pending');
        break;
      case 'approved':
        iconName = 'preference';
        type = 'success';
        text = I18n.t('public.components.approvals.requests.status.approved');
        break;
      case 'denied':
        iconName = 'closeCircle';
        type = 'danger';
        text = I18n.t('public.components.approvals.requests.status.denied');
        break;

      default:
        break;
    }

    if (confirmationId) {
      text = I18n.t(status, {
        scope: 'public.approvals.confirmations.show.status',
      });
      if (status === 'pending') {
        return confirmationSection();
      }

      return (
        <EmphasisTag
          className="request__heading-status-tag"
          type={type}
          text={text}
          icon={<Icon name={iconName} color="default" size="small" />}
        />
      );
    }

    return requestsSection(type, text, iconName);
  };

  const leftHeaderText = () => {
    if (isApprovalRequest) {
      return `Your ${I18n.t('public.application.account_navigation.approvals')} | ${request.id} `;
    }
    return `${I18n.t('public.application.account_navigation.approvals')} | ${request.id} (${
      owner.name
    })`;
  };

  useEffect(() => {
    fetchRequestDetails();
    fetchOverviewDetails();
  }, []);

  return (
    <div className="requests">
      {approversLoading || fetchingRequest ? (
        <ContentLoaderPlaceholder
          className="request__heading--content-loader"
          numberOfLines={2}
          showBackground={true}
        />
      ) : (
        <Heading
          currentNavigation="approvals"
          id={requestId}
          rightNode={<div className="request__heading">{!fetchingRequest && renderStatus()}</div>}
          leftNode={leftHeaderText()}
        />
      )}
      <div className="col-12 col-bleed request__left-panel">
        <LeftPanel
          requestId={requestId}
          confirmations={confirmations}
          executiveConfirmations={executiveConfirmations}
          owner={owner}
          arranger={arranger}
          billingContact={billingContact}
          tenant={tenant}
          ordersLeftPanelLoading={ordersLeftPanelLoading}
          approversLoading={approversLoading}
          approvers={approvers}
          potentialApprovers={potentialApprovers}
          approverSwappable={approverSwappable}
          fetchRequestDetails={fetchRequestDetails}
          minApprovals={minApprovals}
          isApprovalRequest={isApprovalRequest}
          fetchingRequest={fetchingRequest}
          layman={layman}
        />
      </div>
      <div className="col-12 col-bleed-x request__vertical-menu">
        <div className="grid grid-gap-20">
          <div className="col-12 col-md-3">
            <VerticalMenu activeLink={activeTabLink} isFullWidth={true}>
              {getVerticalMenuItems()}
            </VerticalMenu>
          </div>
          <div className="col-12 col-md-9">{verticalMenuItems[selectedTabIndex].tabContent}</div>
        </div>
      </div>
    </div>
  );
};

ApprovalRequestShow.defaultProps = {
  requestId: null,
  confirmationId: null,
  orderId: null,
  cartId: null,
  layman: false,
};

ApprovalRequestShow.propTypes = {
  requestId: PropTypes.number,
  confirmationId: PropTypes.string,
  orderId: PropTypes.number,
  cartId: PropTypes.string,
  layman: PropTypes.bool,
  fetchUrl: PropTypes.string.isRequired,
};

export default hot(module)(withQueryParamsProvider(ApprovalRequestShow));
