import { AIRPORTS_SEARCHED, AIRPORT_DETAILS_FETCHED } from '../../actions/common';

export function airports(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case AIRPORTS_SEARCHED:
      action.airports.forEach(airport => {
        newState[airport.code] = airport;
      });
      return newState;

    case AIRPORT_DETAILS_FETCHED:
      newState[action.data.code] = action.data;
      return newState;

    default:
      return state;
  }
}

export function airportSuggestions(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case AIRPORTS_SEARCHED:
      newState[action.id] = action.airports;
      return newState;

    default:
      return state;
  }
}
