import { connect } from 'react-redux';
import TravelerDetailsWrapper from './TravelerDetailsWrapper';
import { fetchCart, showError } from '../actions/common';
import fetchOnUpdate from '../lib/decorators/fetchOnUpdate';
import fetchServices from '../actions/common/fetchServices';

const mapStateToProps = (state, ownProps) => {
  const cart = state.common.cart.items;

  const services = [];
  const flightResults = [];

  cart.forEach(item => {
    if (!item.hasJourneyElement) return;
    const result = state.common.journeyElements[item.type][item.journeyElementId];
    let combinedItem;
    if (result) {
      combinedItem = {
        ...result,
        type: item.type,
        resultId: item.bookingAttributes.resultId,
        channel: item.channel,
      };
    }

    if (item.type === 'flight') {
      if (combinedItem) flightResults.push(combinedItem);
      const resultServices = state.flights.servicesByResultId[item.bookingAttributes.resultId];
      if (resultServices) {
        services.push({
          item,
          resultId: item.bookingAttributes.resultId,
          services: resultServices,
        });
      }
    }
  });

  return {
    cartId: ownProps.match.params.id,
    flightResults,
    cartState: state.common.cart.state,
    bookingPossible: state.common.cart.allItemsAvailable,
    totalCartItems: ownProps.totalCartItems,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCart: (cartId, callbackParams) => dispatch(fetchCart(cartId, callbackParams)),
  showError: message => dispatch(showError(message, new Error(message))),
  fetchServices: (cart, callbackParams) => dispatch(fetchServices(cart, callbackParams)),
});

const OrderWithFetchOnUpdate = fetchOnUpdate(['id'], (params, props) => {
  props.fetchCart(params.id, props.callbackParams).then(cart => {
    props.fetchServices(cart, props.callbackParams);
  });
})(TravelerDetailsWrapper);

export default connect(mapStateToProps, mapDispatchToProps)(OrderWithFetchOnUpdate);
