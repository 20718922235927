import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@wtag/rcl-icon-button';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import IdentificationDocumentInformation from './IdentificationDocumentInformation/index';
import FrequentFlyerNumberInformation from './FrequentFlyerNumberInformation';
import MealRequestsInformation from './MealRequestsInformation';
import TravelerInformationLoader from '../TravelerInformationLoader';

class TravelInformationCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  setModalOpen = isOpen => {
    this.setState({ modalOpen: isOpen });
  };

  render() {
    const {
      fields: { frequentFlyerNumber, document, assistanceRequests },
      flightResults,
      setSingleTravelerShowPage,
      myBooking,
      frequentFlyerNumbersList,
      selectedCustomer,
      index,
      isTravelerInformationLoading,
    } = this.props;

    if (isTravelerInformationLoading) {
      return <TravelerInformationLoader />;
    }

    const showAssistanceRequests = attributeName => {
      if (assistanceRequests.value === '' || assistanceRequests.value.length <= 0) {
        return (
          <div className="travel-information-card__container-attribute-not-available">
            {I18n.t('components.ibe.traveler_details.attributes.not_selected', {
              attribute: attributeName,
            })}
          </div>
        );
      }
      return assistanceRequests.value.map(request => (
        <div className="travel-information-card__container-attribute-request">
          &#42;
          <div className="travel-information-card__container-attribute-request-name">
            {I18n.t(request.toLowerCase(), {
              scope: 'components.ibe.traveler_details.assistance_requests',
            })}
          </div>
        </div>
      ));
    };

    return (
      <Card version="v2" size="full" className="col-12 travel-information-card">
        <div className="col-grid col-bleed direction-row justify-space-between travel-information-card__header">
          <div className="travel-information-card__header-text">
            {I18n.t('components.ibe.traveler_details.travel_information')}
          </div>
          <div className="travel-information-card__header-icon">
            {myBooking ? (
              <IconButton
                icon={<Icon name="edit" size="normal" />}
                onClick={() => this.setModalOpen(true)}
                label={I18n.t('shared.actions.edit')}
              />
            ) : (
              <IconButton
                icon={<Icon name="edit" size="normal" />}
                label={I18n.t('shared.actions.edit')}
                onClick={() => setSingleTravelerShowPage('edit_travel_information')}
              />
            )}
            <ConfirmationModal
              isModalOpen={this.state.modalOpen}
              confirmationHeader={I18n.t(
                'components.ibe.traveler_details.confirmations.edit_traveler_profile',
              )}
              subHeader={I18n.t(
                'components.ibe.traveler_details.confirmations.subtext_for_affect_profile',
              )}
              confirmationText={I18n.t('components.ibe.traveler_details.buttons.edit_information')}
              rejectionText={I18n.t('components.ibe.traveler_details.buttons.go_back')}
              onConfirm={() => setSingleTravelerShowPage('edit_travel_information')}
              onReject={() => this.setModalOpen(false)}
              withAction={true}
              type="danger"
            />
          </div>
        </div>

        <div className="col-12 col-bleed col-grid col-bleed direction-row align-start travel-information-card__body">
          <div className="col-12 col-sm-6 col-bleed travel-information-card__container">
            <IdentificationDocumentInformation
              fields={document}
              enableIdDocOptional={this.props.enableIdDocOptional}
              travelerIndex={index}
              selectedCustomer={selectedCustomer}
            />
          </div>

          <div className="col-12 col-sm-6 col-bleed travel-information-card__container">
            <FrequentFlyerNumberInformation
              fields={frequentFlyerNumber}
              frequentFlyerNumbersList={frequentFlyerNumbersList}
              travelerIndex={index}
              selectedCustomer={selectedCustomer}
            />
            <MealRequestsInformation
              fields={this.props.fields}
              flightResults={flightResults}
              selectedCustomer={selectedCustomer}
            />
            <div className="travel-information-card__container-attribute">
              <div className="travel-information-card__container-attribute-header">
                {I18n.t('components.ibe.traveler_details.assistance_request')}
              </div>
              {showAssistanceRequests(I18n.t('components.ibe.traveler_details.assistance_request'))}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

TravelInformationCard.propTypes = {
  fields: PropTypes.shape({
    frequentFlyerNumber: PropTypes.shape({}),
    document: PropTypes.shape({}),
    assistanceRequests: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  setSingleTravelerShowPage: PropTypes.func.isRequired,
  flightResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  myBooking: PropTypes.bool.isRequired,
  enableIdDocOptional: PropTypes.bool.isRequired,
  frequentFlyerNumbersList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.number.isRequired,
  selectedCustomer: PropTypes.shape({}).isRequired,
  isTravelerInformationLoading: PropTypes.bool.isRequired,
};

export default TravelInformationCard;
