import calculateNights from 'sharedWebpack/helpers/calculateNights';
import getDate from './getDate';
import getHotelTravelersInfo from './getHotelTravelersInfo';
import toCapitializeForm from './toCapitializeForm';
import getWifiInfo from './getWifiInfo';
import getMealInfo from './getMealInfo';
import formatPrice from './formatPrice';

const generateHotelContent = (item, travelers, journeyElement) => {
  const { itemTitle, startsAt, endsAt, grossTotalIncludingSubItems, currency } = item;
  const { city, street, rooms, wifiAvailability, boardType, perDayPrice } = journeyElement;
  const nightCount = calculateNights(startsAt, endsAt);
  const { date: startDate, time: startTime } = getDate(startsAt);
  const { date: endDate, time: endTime } = getDate(endsAt);
  const wifiInfo = getWifiInfo(wifiAvailability);
  const mealInfo = getMealInfo(boardType);
  const travelersInfo = getHotelTravelersInfo(travelers);
  const capitializedCityName = toCapitializeForm(city);
  const roomInfo = `\n${I18n.t('components.ibe.search_results.hotel.content.room', {
    allRoomName: rooms.map(room => room.name).join(', '),
  })}`;
  const priceInfo = `${I18n.t('components.ibe.search_results.hotel.content.price_info', {
    totalPrice: formatPrice(grossTotalIncludingSubItems, currency),
    perDayPrice: formatPrice(perDayPrice, currency),
  })}\n---`;
  const nightCountWithCity = `${I18n.t('components.ibe.search_results.hotel.content.night_count', {
    nightCount,
    cityName: capitializedCityName,
  })}\n---\n`;
  const address = `\n${I18n.t('components.ibe.search_results.hotel.content.address', {
    street,
    city: capitializedCityName,
  })}\n---\n`;
  const checkIn = `\n${I18n.t('components.ibe.search_results.hotel.content.check_in', {
    startDate,
    startTime,
  })}`;
  const checkOut = `\n${I18n.t('components.ibe.search_results.hotel.content.check_out', {
    endDate,
    endTime,
  })}`;

  return `${nightCountWithCity}${itemTitle}.${address}${travelersInfo}${priceInfo}${checkIn}${checkOut}${roomInfo}${wifiInfo}${mealInfo}`;
};

export default generateHotelContent;
