import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { checkStatus, parseJSON } from '../../lib/http_helpers';
import { reportToRollbar, fetchCartStatus } from './';
import {
  IATA_FORM_OF_PAYMENT,
  CASH_FORM_OF_PAYMENT,
  NONE_FORM_OF_PAYMENT,
  WALLET_FORM_OF_PAYMENT,
} from '../../lib/helpers/fop_options';

export const START_BOOKING = 'START_BOOKING';
function startBooking(results, userProcedureConfig) {
  return { type: START_BOOKING, results, userProcedureConfig };
}

export const BOOKING_COMPLETED = 'BOOKING_COMPLETED';
function bookingCompleted(url) {
  return { type: BOOKING_COMPLETED, url };
}

export const BOOKING_FAILED = 'BOOKING_FAILED';
function bookingFailed(error) {
  return { type: BOOKING_FAILED, error };
}

export const PAYMENT_FAILED = 'PAYMENT_FAILED';
function paymentFailureReason(error) {
  return { type: PAYMENT_FAILED, error };
}

function procedureConfig(userConfig, createdOrderId) {
  const config = {};

  config.procedure = 'attach_to_order';
  switch (userConfig.procedure) {
    case 'createOrder':
      config.procedure = 'create_order';
      config.order_title = userConfig.orderTitle
      if (createdOrderId) {
        config.procedure = 'attach_to_order';
        config.order_id = createdOrderId;
      }
      break;

    case 'attachToOrder':
      config.procedure = 'attach_to_order';
      config.order_id = userConfig.orderId;
      break;

    case 'createOption':
      config.procedure = 'create_option';
      config.order_title = userConfig.orderTitle
      if (createdOrderId) {
        config.procedure = 'attach_to_order_as_option';
        config.order_id = createdOrderId;
      }
      break;

    case 'attachToOrderAsOption':
      config.procedure = 'attach_to_order_as_option';
      config.order_id = userConfig.orderId;
      break;

    case 'createOrderAsQuote':
      config.procedure = 'create_order_with_quote';
      config.order_title = userConfig.orderTitle
      if (createdOrderId) {
        config.procedure = 'attach_to_order_as_quote';
        config.order_id = createdOrderId;
      }
      break;

    case 'attachToOrderAsQuote':
      config.procedure = 'attach_to_order_as_quote';
      config.order_id = userConfig.orderId;
      break;

    default:
      throw new Error(`Unknown procedure ${userConfig.procedure}`);
  }

  return config;
}

const MAIN_ITEM_TYPES = ['flight', 'hotel', 'car'];

const tryToCompleteBooking = (cartId, dispatch) =>
  dispatch(fetchCartStatus(cartId)).then(data => {
    const response = data.status;
    if (response.bookingCompleted || response.forQuoteOnly) {
      return dispatch(bookingCompleted(response.orderUrl));
    } else if (response.paymentStatus === 'failed') {
      return dispatch(paymentFailureReason(response.paymentFailureReason));
    }
    // We are setting a 5s time so that we can check
    // again after 5 second what is the status of the cart
    return setTimeout(tryToCompleteBooking, 5000, cartId, dispatch);
  });

export const retrackBooking = cartId => dispatch => {
  tryToCompleteBooking(cartId, dispatch);
};

export const PAYMENT_OPEN = 'PAYMENT_OPEN';
export const resetPayment = () => ({ type: PAYMENT_OPEN });

export const START_CART_PREPARATION = 'START_CART_PREPARATION';
function startCartPreparation(params) {
  return { type: START_CART_PREPARATION, params };
}

export const CART_PREPARED = 'CART_PREPARED';
function cartPrepared() {
  return { type: CART_PREPARED };
}
export function prepareForExternalPayment(callbackParams, userProcedureConfig) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    const procedureConfiguration = procedureConfig(userProcedureConfig);

    const cartPreparationParmas = {
      ...procedureConfiguration,
      callback_params: callbackParams,
    };
    dispatch(startCartPreparation(cartPreparationParmas));

    return httpClient
      .post(routes.api.carts.prepareCartForExternalPayment({ cartId }), cartPreparationParmas)
      .then(dispatch(cartPrepared()));
  };
}

export const START_ORDER_CREATION = 'START_ORDER_CREATION';
function startOrderCreation(params) {
  return { type: START_ORDER_CREATION, params };
}

export const ORDER_CREATED = 'ORDER_CREATED';
function orderCreated(orderId) {
  return { type: ORDER_CREATED, orderId };
}

export function createOrder(callbackParams, userProcedureConfig) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    const procedureConfiguration = procedureConfig(userProcedureConfig);

    const orderCreationsParmas = {
      ...procedureConfiguration,
      callback_params: callbackParams,
      cart_id: cartId,
    };
    dispatch(startOrderCreation(orderCreationsParmas));
    return httpClient
      .post(routes.api.orders.create(), orderCreationsParmas)
      .then(response => dispatch(orderCreated(response.data.orderId)));
  };
}

export function bookCart(userProcedureConfig) {
  return (dispatch, getState) => {
    const state = getState();
    const cart = state.common.cart;
    const items = cart.items;
    const hasNoCreditCardItem = items.every(
      item =>
        item.formOfPayment.type === CASH_FORM_OF_PAYMENT ||
        item.formOfPayment.type === IATA_FORM_OF_PAYMENT ||
        item.formOfPayment.type === WALLET_FORM_OF_PAYMENT,
    );
    const itemsToBook = items.filter(
      item =>
        MAIN_ITEM_TYPES.includes(item.type) &&
        (item.formOfPayment.type === CASH_FORM_OF_PAYMENT ||
          item.formOfPayment.type === IATA_FORM_OF_PAYMENT ||
          item.formOfPayment.type === NONE_FORM_OF_PAYMENT ||
          item.formOfPayment.type === WALLET_FORM_OF_PAYMENT),
    );
    const cartId = cart.id;

    const results = Object.assign([], itemsToBook);

    const hasNoCashItem = !results.length;

    if (hasNoCashItem && hasNoCreditCardItem) {
      throw new Error('No bookable result in cart');
    }

    const bookParams = {
      cart_item_ids: itemsToBook.map(item => item.id),
    };

    dispatch(startBooking(itemsToBook, userProcedureConfig));
    return httpClient
      .put(routes.api.carts.book({ cartId }), bookParams)
      .then(() => tryToCompleteBooking(cartId, dispatch))
      .catch(error => {
        reportToRollbar(error.response.data.error, error.response);
        dispatch(bookingFailed(error.response.data.error));
      });
  };
}

export const FETCHING_PAYMENT_GATEWAY_INFO = 'FETCHING_PAYMENT_GATEWAY_INFO';
function fetchingPaymentGatewayInfo() {
  return { type: FETCHING_PAYMENT_GATEWAY_INFO };
}

export const PAYMENT_GATEWAY_INFO_FETCHED = 'PAYMENT_GATEWAY_INFO_FETCHED';
function paymentGatewayInfoFetched(paymentGatewayInfo) {
  return { type: PAYMENT_GATEWAY_INFO_FETCHED, paymentGatewayInfo };
}

export function fetchPaymentGatewayInfo(
  paygateType,
  paymentMethod,
  cartId,
  redirectTo,
  baseUrl,
  iframe,
) {
  return dispatch => {
    dispatch(fetchingPaymentGatewayInfo());
    return fetch(
      routes.api.paymentGateway.fetch({
        paygateType,
        cartId,
        redirectTo,
        baseUrl,
        paymentMethod,
        popup: iframe,
      }),
    )
      .then(parseJSON)
      .then(checkStatus)
      .then(paymentGatewayInfo => {
        dispatch(paymentGatewayInfoFetched(paymentGatewayInfo));
        return paymentGatewayInfo;
      });
  };
}
