import { SEARCHED_AIRLINE_PREFERENCE, EMPTY_AIRLINE_PREFERENCE } from '../../actions/flight';

const initialState = {
  airlines: [],
  isEmpty: false,
};

export default function airlinePreference(state = initialState, action) {
  switch (action.type) {
    case SEARCHED_AIRLINE_PREFERENCE:
      return {
        ...state,
        airlines: action.airlines,
      };
    case EMPTY_AIRLINE_PREFERENCE:
      return {
        ...state,
        isEmpty: action.isEmpty,
      };
    default:
      return state;
  }
}
