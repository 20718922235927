import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { DEFAULT } from 'sharedWebpack/IBE/lib/helpers/componentStates';

const LeftSidedHotelBookingStatus = ({ corporateDeal, preferred }) => {
  const leftSidedHotelBookingStatus = [];

  if (corporateDeal) {
    leftSidedHotelBookingStatus.push({
      iconName: 'bookingClass',
      iconColor: DEFAULT,
      iconLabel: I18n.t('components.ibe.search_result.corporate_deal'),
    });
  }

  if (preferred) {
    leftSidedHotelBookingStatus.push({
      iconName: 'bookingClass',
      iconColor: DEFAULT,
      iconLabel: I18n.t('components.ibe.search_result.preferred'),
    });
  }

  return (
    <div className="d-flex align-center flex-wrap hotel-booking-status__information-item">
      {leftSidedHotelBookingStatus.map(({ iconName, iconColor, iconLabel }) => (
        <div className="hotel-booking-status__information-item-label" key={iconName}>
          <Icon
            className="hotel-booking-status__information-item-icon"
            color={iconColor}
            iconLabel={iconLabel}
            name={iconName}
            showLabel={true}
            size="tiny"
          />
        </div>
      ))}
    </div>
  );
};

LeftSidedHotelBookingStatus.propTypes = {
  corporateDeal: PropTypes.bool.isRequired,
  preferred: PropTypes.bool.isRequired,
};

export default LeftSidedHotelBookingStatus;
