function searchAccountValues(searchAccounts) {
  const accountSettings = {};
  if (!searchAccounts) {
    return accountSettings;
  }

  Object.keys(searchAccounts).forEach(channel => {
    accountSettings[channel] = searchAccounts[channel].credentials.map(account => ({
      identifier: account.identifier,
      codes: (account.codes || []).slice(0, account.allowance).map(code => code.code),
    }));
  });

  return accountSettings;
}

export default searchAccountValues;
