/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import Range from 'rc-slider/lib/Range';

import 'rc-slider/assets/index.css';

class RangeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [
        props.values && props.min !== props.values.min ? props.values.min : this.min(),
        props.values && props.max !== props.values.max ? props.values.max : this.max(),
      ],
    };
  }

  onChange = values => {
    this.props.onChange({
      min: values[0],
      max: values[1],
    });
  };

  valueChanged = values => {
    this.setState({
      values,
    });
  };

  min = () => this.props.min;
  max = () => this.props.max;

  render() {
    return (
      <div className="range-filter">
        <div className="range-filter__label">
          {this.props.formatLabel(this.state.values[0])} -{' '}
          {this.props.formatLabel(this.state.values[1])}
        </div>
        <div className="range-filter__slider">
          <Range
            tipFormatter={this.props.formatLabel}
            min={this.min()}
            max={this.max()}
            step={this.props.step}
            allowCross={false}
            value={this.state.values}
            onChange={this.valueChanged}
            onAfterChange={this.onChange}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    );
  }
}

RangeFilter.defaultProps = {
  values: null,
};

RangeFilter.propTypes = {
  disabled: PropTypes.bool.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  formatLabel: PropTypes.func.isRequired,
  step: PropTypes.number,
  values: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
};

RangeFilter.defaultProps = {
  step: 10,
};

export default RangeFilter;
