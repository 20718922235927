export const ICON_MAPPER = {
  profile: 'person',
  emails_and_phones: 'email',
  addresses_and_locations: 'location',
  emergency_contacts: 'emergency',
  organization: 'people',
  visas_and_identifications: 'visaIdentification',
  travel_arrangers: 'travelArranger',
  frequent_flyer_numbers: 'aeroplane',
  travel_preferences: 'travelPreference',
  payment_methods_and_preferences: 'paymentMethod',
  loyalty_programs: 'loyalty',
  social: 'social',
  manage_traveller: 'manage',
  notification_preference: 'notification',
  change_password: 'person',
};

export const tabMapper = tabPaths => {
  const {
    addressesAndLocations,
    general,
    changePassword,
    emailsAndPhones,
    emergencyContacts,
    visasAndIdentifications,
    frequentFlyerNumbers,
    travelPreferences,
    paymentMethodsAndPreferences,
    loyaltyPrograms,
    social,
    notificationPreference,
  } = tabPaths;

  return {
    profile: general,
    emails_and_phones: emailsAndPhones,
    addresses_and_locations: addressesAndLocations,
    emergency_contacts: emergencyContacts,
    visas_and_identifications: visasAndIdentifications,
    frequent_flyer_numbers: frequentFlyerNumbers,
    travel_preferences: travelPreferences,
    payment_methods_and_preferences: paymentMethodsAndPreferences,
    loyalty_programs: loyaltyPrograms,
    social,
    change_password: changePassword,
    notification_preference: notificationPreference,
  };
};

export const groupTabMapper = tabPaths => {
  const {
    addressesAndLocations,
    changePassword,
    general,
    emailsAndPhones,
    emergencyContacts,
    visasAndIdentifications,
    frequentFlyerNumbers,
    travelPreferences,
    paymentMethodsAndPreferences,
    loyaltyPrograms,
    social,
    notificationPreference,
  } = tabPaths;

  return {
    general: {
      profile: general,
      social,
      change_password: changePassword,
    },
    contact_information: {
      emails_and_phones: emailsAndPhones,
      addresses_and_locations: addressesAndLocations,
      emergency_contacts: emergencyContacts,
    },
    documents: {
      visas_and_identifications: visasAndIdentifications,
    },
    ffn_loyalty_programs: {
      frequent_flyer_numbers: frequentFlyerNumbers,
      loyalty_programs: loyaltyPrograms,
    },
    preferences: {
      travel_preferences: travelPreferences,
      notification_preference: notificationPreference,
      payment_methods_and_preferences: paymentMethodsAndPreferences,
    },
  };
};
