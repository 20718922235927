import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import { Link } from '@wtag/react-comp-lib';

import './styles.scss';

const EmptyTableContent = props => {
  const { iconSize, iconName, iconColor, emptyText, fullHeight, showIconBackGround, createNewUrl } =
    props;

  const icon = (
    <Icon name={iconName} showBGColor={showIconBackGround} color={iconColor} size={iconSize} />
  );

  return (
    <div
      className={classNames('empty-table', {
        'empty-table--with-full-height': fullHeight,
      })}
    >
      <div className="empty-table__icon">
        {createNewUrl ? (
          <Link href={createNewUrl} type="icon-button" modifier="tertiary">
            {icon}
          </Link>
        ) : (
          icon
        )}
      </div>
      <div className="empty-table__text">{emptyText || I18n.t('admin.shared.no_result')}</div>
    </div>
  );
};

EmptyTableContent.defaultProps = {
  iconSize: 'medium',
  iconName: 'add',
  iconColor: 'tertiary',
  emptyText: null,
  fullHeight: false,
  showIconBackGround: true,
  createNewUrl: null,
};

EmptyTableContent.propTypes = {
  iconSize: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  emptyText: PropTypes.string,
  fullHeight: PropTypes.bool,
  showIconBackGround: PropTypes.bool,
  createNewUrl: PropTypes.string,
};
export default EmptyTableContent;
