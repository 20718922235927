import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { Link } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import EmergencyContactForm from './EmergencyContactForm';

const EmergencyContact = props => {
  const {
    personId,
    emergencyContact,
    phoneCountryCodeOptions,
    fetchEmergencyContacts,
    layman,
  } = props;

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});

  const [phoneCountryCode, setPhoneCountryCode] = useState(null);
  const [number, setNumber] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);

  let emergencyContactsRoutes;

  const toggleShowConfirmation = () => {
    setIsShowConfirmation(prev => !prev);
  };

  const setRoutes = emergencyContactId => {
    if (layman) {
      emergencyContactsRoutes = {
        update: routes.public.emergencyContacts.update({ id: emergencyContactId }),
        delete: routes.public.emergencyContacts.delete({ id: emergencyContactId }),
      };
    } else {
      emergencyContactsRoutes = {
        update: routes.admin.people.emergencyContacts.update({ personId, emergencyContactId }),
        delete: routes.admin.people.emergencyContacts.delete({ personId, emergencyContactId }),
      };
    }
  };

  const updateEmergencyContacts = async emergencyContactId => {
    setRoutes(emergencyContactId);
    const emergencyContactParams = {
      phone_country_code: phoneCountryCode,
      number,
      first_name: firstName,
      last_name: lastName,
    };
    const { data } = await httpClient.patch(emergencyContactsRoutes.update, {
      emergency_contact: emergencyContactParams,
    });

    if (data.error === null) {
      fetchEmergencyContacts();
      setEditing(false);
    }
  };

  const onUpdateEmergencyContacts = emergencyContactId => {
    updateEmergencyContacts(emergencyContactId).catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });
  };

  const deleteEmergencyContact = async emergencyContactId => {
    setRoutes(emergencyContactId);

    const { data } = await httpClient.delete(emergencyContactsRoutes.delete);
    if (data.error === null) {
      fetchEmergencyContacts();
      setEditing(false);
    }
  };

  const onDeleteEmergencyContact = emergencyContactId => {
    deleteEmergencyContact(emergencyContactId).catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });
    toggleShowConfirmation();
  };

  useEffect(() => {
    setPhoneCountryCode(emergencyContact.phoneCountryCode);
    setNumber(emergencyContact.number);
    setFirstName(emergencyContact.firstName);
    setLastName(emergencyContact.lastName);
  }, [emergencyContact]);

  useEffect(() => {
    setError({});
  }, [editing]);

  return (
    <div className="col-grid traveller-edit-emergency-contacts-section traveller-edit-emergency-contacts-section__contact-card">
      {editing ? (
        <React.Fragment>
          <div className="col-grid col-bleed direction-row align-center traveller-edit-emergency-contacts-section__address-card-sub-header">
            {I18n.t('admin.components.travellers.edit.emergency_contacts.update')}
          </div>
          <EmergencyContactForm
            phoneCountryCode={phoneCountryCode}
            number={number || ''}
            firstName={firstName}
            lastName={lastName}
            phoneCountryCodeOptions={phoneCountryCodeOptions}
            setPhoneCountryCode={setPhoneCountryCode}
            setNumber={setNumber}
            setFirstName={setFirstName}
            setLastName={setLastName}
            error={error}
            setError={setError}
          />
          <div className="traveller-edit-emergency-contacts-section__form-actions">
            <Button
              version="v2"
              size="small"
              label={I18n.t('admin.components.shared.action.cancel')}
              type="default"
              onClick={() => setEditing(false)}
            />
            <Button
              version="v2"
              size="small"
              type="primary"
              label={I18n.t('admin.shared.action.update')}
              onClick={() => onUpdateEmergencyContacts(emergencyContact.id)}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="col-grid col-bleed direction-row traveller-edit-emergency-contact-name-and-number">
            <div className="traveller-edit-emergency-contact__summary">
              <div className="col-12 col-bleed traveller-edit-emergency-contact-name-and-number">
                <p className="traveller-edit-emergency-contact-name-and-number__name">
                  {`${emergencyContact.firstName} ${emergencyContact.lastName}`}
                </p>
                <div className="traveler-edit-emergency-contact__number">
                  {`+${emergencyContact.phoneCountryCode}${emergencyContact.number}`}
                </div>
              </div>
            </div>

            <div className="traveller-edit-emergency-contact-section__actions">
              <div className="actions">
                <span className="traveller-edit-emergency-contacts__content">
                  <Link
                    labelHint={I18n.t('admin.shared.action.edit')}
                    type="icon-button"
                    modifier="tertiary"
                    onClick={() => setEditing(true)}
                  >
                    <Icon name="edit" size="small" />
                  </Link>
                </span>
                <span className="traveller-edit-emergency-contacts__content">
                  <Link
                    labelHint={I18n.t('admin.shared.action.destroy')}
                    type="icon-button"
                    modifier="tertiary"
                    onClick={toggleShowConfirmation}
                  >
                    <Icon name="delete" size="small" />
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      {isShowConfirmation && (
        <ConfirmationModal
          confirmationHeader={I18n.t(
            'admin.components.orders.left_panel.emergency_contacts.delete_modal.header',
          )}
          subHeader={I18n.t(
            'admin.components.orders.left_panel.emergency_contacts.delete_modal.sub_header',
          )}
          isModalOpen={true}
          withAction={true}
          type="danger"
          confirmationText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.positive',
          )}
          rejectionText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.negative',
          )}
          onConfirm={() => onDeleteEmergencyContact(emergencyContact.id)}
          onReject={toggleShowConfirmation}
        />
      )}
    </div>
  );
};

EmergencyContact.defaultProps = {
  emergencyContact: {},
  layman: false,
};

EmergencyContact.propTypes = {
  personId: PropTypes.number.isRequired,
  emergencyContact: PropTypes.shape({
    id: PropTypes.number,
    phoneCountryCode: PropTypes.string,
    number: PropTypes.string,
    firstName: PropTypes.bool,
    lastName: PropTypes.string,
  }),
  phoneCountryCodeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  fetchEmergencyContacts: PropTypes.func.isRequired,
  layman: PropTypes.bool,
};

export default EmergencyContact;
