import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Amount from 'sharedWebpack/Amount';

const FareBrandItem = ({ id, className, label, currency, price }) => (
  <div className={ClassNames(className, 'flight__prices__fare-package-item')} key={id}>
    <div className="flight__prices__fare-package-item--farebrand-name">{label}</div>
    <Amount className="flight__prices--bold" currency={currency} value={price} />
  </div>
);

FareBrandItem.defaultProps = {
  className: null,
};

FareBrandItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default FareBrandItem;
