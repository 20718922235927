import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  car: PropTypes.shape({
    categoryDescription: PropTypes.string.isRequired,
    condition: PropTypes.string.isRequired,
    imageUrl: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
  }).isRequired,
  facilities: PropTypes.shape({
    seats: PropTypes.number,
    doors: PropTypes.string,
    airConditioned: PropTypes.bool,
    airBag: PropTypes.bool,
  }).isRequired,
  grossTotal: PropTypes.string.isRequired,
  marginAmount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  ratePerDay: PropTypes.string.isRequired,
  approvalRequired: PropTypes.bool.isRequired,
  corporate_deal: PropTypes.bool,
  labels: PropTypes.shape({
    preferred: PropTypes.bool.isRequired,
    outOfPolicy: PropTypes.bool.isRequired,
  }).isRequired,
});
