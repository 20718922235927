/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import moment from 'moment';
import Amount from 'sharedWebpack/Amount';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';
import { FormatDateTime } from 'sharedWebpack/helpers/dateTime';
import { DATE_FORMAT_SHORT } from '../../../helpers/dateTime';

const isToday = date => moment(date).diff(Date.now(), 'days') === 0;

const CancellationPolicies = ({ policies, amount, currency }) => {
  const [firstPolicy] = policies;
  const firstPolicyStartDate = firstPolicy && firstPolicy.startDate;
  const hasNoPolicies = policies.length === 0;
  const singlePolicyToday = policies.length === 1 && isToday(firstPolicyStartDate);
  const singlePolicyTodayMatchesFee = singlePolicyToday && firstPolicy.fee === amount;
  const policyElements = [];

  if (hasNoPolicies || singlePolicyTodayMatchesFee) {
    return (
      <div className="hotel-rooms__room-cancellation">
        <Icon name="preference" size="normal" />
        <span className="hotel-rooms__room-cancellation-policies">
          {I18n.t('components.ibe.cart_item_pricing.non_refundable')}
        </span>
      </div>
    );
  }

  const getLocaleKeyAndDate = (index, startDate, endDate) => {
    if (index === 0) {
      return {
        localeKey: 'components.ibe.hotel.room.cancellation_policy.until_with_date_time',
        date: isToday(startDate) ? endDate : startDate,
      };
    }

    return {
      localeKey: 'components.ibe.hotel.room.cancellation_policy.starts_with_date_time',
      date: startDate,
    };
  };

  const getCancellationFee = (fee, converted) => {
    if (parseInt(fee, 10) === 0) {
      return I18n.t('components.ibe.hotel.room.cancellation_policy.free');
    } else if (converted) {
      return I18n.t('components.ibe.apx');
    }

    return <Amount currency={currency} value={fee} />;
  };

  policies.forEach(({ converted, endDate, fee, startDate }, index) => {
    const { localeKey, date } = getLocaleKeyAndDate(index, startDate, endDate);
    const cancellationFee = getCancellationFee(fee, converted);

    const formattedDateTime = FormatDateTime({
      dateTime: date,
      displayWithoutConversion: true,
      format: DATE_FORMAT_SHORT,
    });

    policyElements.push(
      <li key={startDate}>
        <DateTimeLocaleInterpolationWrapper localeKey={localeKey} time={formattedDateTime} />
        <span>{cancellationFee}</span>
      </li>,
    );
  });

  return (
    <div className="hotel-rooms__room-cancellation">
      <Icon name="cancelled" size="normal" color="danger" />
      <div className="hotel-rooms__room-cancellation-policies">
        <span>{I18n.t('components.ibe.hotel.room.cancellation_policy.title')}</span>
        <ul>{policyElements}</ul>
      </div>
    </div>
  );
};

CancellationPolicies.defaultProps = {
  amount: null,
  policies: [],
};

CancellationPolicies.propTypes = {
  amount: PropTypes.string,
  policies: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      fee: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }),
  ),
};

export default CancellationPolicies;
