export const sourceNames = {
  Mobil: 'Mobil Travel Guide Star Ratings',
  aaa: 'AAA Diamond Rating',
  northstar_crown: 'Northstar Crown Rating',
};

export const sourceMaxes = {
  aaa: 5,
  northstar_crown: 5,
  travelbox: 10,
  Mobil: 5,
};

export const RATING_SOURCES = ['travelbox', 'aaa', 'ntm', 'Mobil'];
