import moment from 'moment';

export const DATE_FORMAT_SHORT = 'short';
export const DATE_FORMAT_LONG = 'long';
export const DATE_FORMAT_LONG_WITH_TIME = 'longWithTime';
export const DATE_FORMAT_SHORT_WITH_TIME = 'shortWithTime';
export const DATE_FORMAT_LONG_DAY_SHORT_WITH_TIME = 'longWithDayShort';
export const DATE_FORMAT_MERIDIEM = 'meridiem';
export const DATE_FORMAT_TIME = 'onlytime';
export const DATE_FORMAT_SHORT_YEAR = 'short_year';
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const DATE_FORMAT_DAY_MONTH_YEAR = 'DD MMM, Y';
export const DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME = 'shortNumericalWithTime';

const validDateFormats = [
  DATE_FORMAT_LONG,
  DATE_FORMAT_SHORT,
  DATE_FORMAT_SHORT_WITH_TIME,
  DATE_FORMAT_LONG_WITH_TIME,
  DATE_FORMAT_LONG_DAY_SHORT_WITH_TIME,
  DATE_FORMAT_MERIDIEM,
  DATE_FORMAT_TIME,
  DATE_FORMAT_SHORT_YEAR,
  DATE_FORMAT_YEAR_MONTH_DAY,
  DATE_FORMAT_DAY_MONTH_YEAR,
  DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME,
];

export const FormatDateTime = ({ dateTime, format, displayInUTC, displayWithoutConversion }) => {
  if (!dateTime) {
    return '';
  }

  let dateTimeFormat;

  const givenFormat = validDateFormats.includes(format) ? format : DATE_FORMAT_SHORT;

  switch (givenFormat) {
    case DATE_FORMAT_TIME:
      dateTimeFormat = 'HH:mm'; // eg. 14:30
      break;
    case DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME:
      dateTimeFormat = 'HH:mm DD/MM'; // eg. 10:10 25/12
      break;
    case DATE_FORMAT_SHORT_YEAR:
      dateTimeFormat = 'ddd, DD MMM YY'; // eg. SUN , 04 Jun 23
      break;
    case DATE_FORMAT_SHORT:
      dateTimeFormat = 'DD MMM Y'; // eg. 04 Jun 2020
      break;
    case DATE_FORMAT_DAY_MONTH_YEAR:
      dateTimeFormat = 'DD MMM, Y'; // eg. 04 Jun, 2020
      break;
    case DATE_FORMAT_YEAR_MONTH_DAY:
      dateTimeFormat = 'YYYY-MM-DD'; // eg. 2023-10-01
      break;
    case DATE_FORMAT_LONG:
      dateTimeFormat = 'dddd, DD MMMM Y'; // eg. Thursday, 04 June 2020
      break;
    case DATE_FORMAT_SHORT_WITH_TIME:
      dateTimeFormat = 'HH:mm DD MMM Y'; // eg. 14:30 04 Jun 2020
      break;
    case DATE_FORMAT_LONG_WITH_TIME:
      dateTimeFormat = 'HH:mm dddd, DD MMMM Y'; // eg. 14:30 Thursday, 04 June 2020
      break;
    case DATE_FORMAT_LONG_DAY_SHORT_WITH_TIME:
      dateTimeFormat = 'HH:mm ddd, DD MMMM YY'; // eg. 14:30 Thu, 04 June 20
      break;
    case DATE_FORMAT_MERIDIEM:
      dateTimeFormat = 'LT'; // eg. 2:40 PM
      break;
    default:
      break;
  }

  if (displayWithoutConversion) {
    return moment
      .parseZone(dateTime)
      .locale(I18n.locale)
      .format(dateTimeFormat);
  } else if (displayInUTC) {
    return moment(dateTime)
      .utc()
      .locale(I18n.locale)
      .format(dateTimeFormat);
  }

  return moment(dateTime)
    .locale(I18n.locale)
    .format(dateTimeFormat);
};

export default FormatDateTime;
