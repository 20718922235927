import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import { Header } from '@wtag/rcl-typography';
import ToggleWrapper from '../../../ToggleWrapper';

const ExpiryFormHeader = props => {
  const { checked, onChange, onSave, headerName, headerText, placeholder } = props;

  return (
    <Fragment>
      <div className="approval-request-reminder__container-header col-grid col-bleed direction-row justify-space-between align-center">
        <Header level={5} weight="medium">
          {headerName}
        </Header>
        <Button
          label={I18n.t('components.approval_request_reminder.action.save')}
          onClick={onSave}
          version="v2"
          type="primary"
          size="small"
        />
      </div>
      <div className="approval-request-reminder__container-enable-reminder">
        <div className="col-grid col-bleed direction-row justify-space-between">
          <div className="approval-request-reminder__container-enable-reminder-header">
            {headerText}
          </div>
          <div className="approval-request-reminder__container-enable-reminder-toggle">
            <ToggleWrapper size="tiny" onChange={onChange} isChecked={checked} />
          </div>
        </div>
        <div className="approval-request-reminder__container-enable-reminder-placeholder">
          {placeholder}
        </div>
      </div>
    </Fragment>
  );
};

ExpiryFormHeader.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  headerName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default ExpiryFormHeader;
