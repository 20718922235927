import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@wtag/rcl-menu';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';
import ProductIcon from 'sharedWebpack/ProductIcon';
import { FormatDateTime, DATE_FORMAT_SHORT } from 'sharedWebpack/helpers/dateTime';
import Place from '../../components/PlaceContainer';

const HotelSearchName = props => {
  const numberOfRooms = props.numberOfRooms;
  const travelerCount = props.adults + props.children;

  let place;
  if (props.placeId) {
    place = <Place id={props.placeId} />;
  } else {
    place = props.address;
  }

  const hotelData = (
    <div className="col-grid col-bleed direction-row align-center">
      <ProductIcon
        productType="hotel"
        showBGColor={true}
        size={props.isDealItem ? 'medium' : 'normal'}
        color={props.isDealItem ? 'success' : 'tertiary'}
      />
      <div className="col-grid col-bleed">
        <div className="search-name__primary">
          {I18n.t('components.ibe.search_form.label.number_of_rooms', { count: numberOfRooms })}
          {' | '}
          {I18n.t('components.ibe.search_form.label.number_of_travelers', {
            count: travelerCount,
          })}
        </div>
        <div className="search-name__secondary">
          {place}&nbsp;
          <DateTimeLocaleInterpolationWrapper
            localeKey="components.ibe.search_form.label.travel_date_from"
            time={FormatDateTime({ dateTime: props.checkIn, format: DATE_FORMAT_SHORT })}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {props.isDealItem ? (
        <div className="deal__group-item">{hotelData}</div>
      ) : (
        <MenuItem onClick={props.onMenuItemClick}>{hotelData}</MenuItem>
      )}
    </div>
  );
};

HotelSearchName.defaultProps = {
  address: '',
  placeId: null,
  isDealItem: false,
};

HotelSearchName.propTypes = {
  placeId: PropTypes.number,
  address: PropTypes.string,
  checkIn: PropTypes.string.isRequired,
  numberOfRooms: PropTypes.number.isRequired,
  adults: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  isDealItem: PropTypes.bool,
};

export default HotelSearchName;
