export const NEW_CC_FORM_QUERY = 'cc_panel';
export const QUERY_UPDATE_TYPE = 'replaceIn';

export const generateCCPanelKey = index => `${NEW_CC_FORM_QUERY}_${index}`;

export const ccPanelVisible = (ccToken, index, queryParamName) => {
  if (ccToken === null) {
    return queryParamName === NEW_CC_FORM_QUERY;
  }

  return queryParamName === generateCCPanelKey(index);
};

export const getCCTokensThatNeedToBook = bookingRequestsEncodedByToken =>
  Object.keys(bookingRequestsEncodedByToken).filter(
    key => bookingRequestsEncodedByToken[key] && bookingRequestsEncodedByToken[key].length > 0,
  );

export const checkIfNeededtoRunChecks = requestsToAuthenticateByToken =>
  Object.values(requestsToAuthenticateByToken).some(arrayValue => arrayValue.length > 0);

export const setRequestsThatNeedAuthentication = (
  bookingRequestsEncodedByToken,
  threeDsStatusesByToken,
  updateRequestsToAuthenticateByToken,
) => {
  const requestsThatNeedAuthentication = {};
  // we loop through the keys of booking requests encoded by token here,
  // because we only need to run the three ds check for those credit cards that
  // are being used to pay items.
  Object.keys(bookingRequestsEncodedByToken).forEach(ccToken => {
    bookingRequestsEncodedByToken[ccToken].forEach(request => {
      if (!threeDsStatusesByToken[ccToken] || threeDsStatusesByToken[ccToken][request] !== true) {
        if (!Array.isArray(requestsThatNeedAuthentication[ccToken])) {
          // this will run for first time for any token.
          requestsThatNeedAuthentication[ccToken] = [];
        }
        requestsThatNeedAuthentication[ccToken].push(request);
      }
    });
  });
  updateRequestsToAuthenticateByToken(requestsThatNeedAuthentication);
};

export const triggerPerRequestThreeDs = ({
  requestsToAuthenticateByToken,
  updateCurrentAuthenticationRequest,
  bookingComponentRefByToken,
  usableUniqueCCTokens,
  showCreditCardPanel,
}) => {
  // we kick off with the first request from first token
  // The second and others will be triggered when the first one passes and so on
  // First one's status kicks off second 3ds, second one's status kicks off third 3ds and so on.
  const firstToken = Object.keys(requestsToAuthenticateByToken).find(
    ccToken =>
      requestsToAuthenticateByToken[ccToken] && requestsToAuthenticateByToken[ccToken].length > 0,
  );
  const encodedReq = requestsToAuthenticateByToken[firstToken][0];
  updateCurrentAuthenticationRequest(encodedReq);
  bookingComponentRefByToken[firstToken].current.initiateThreeDSAuthentication(encodedReq);
  const firstTokenIndex = usableUniqueCCTokens.indexOf(firstToken);
  const queryForFirstToken = generateCCPanelKey(firstTokenIndex);
  showCreditCardPanel(queryForFirstToken);
};

export const skipThreeDsAuthenticationPerRequests = ({
  bookingRequestsEncodedByToken,
  skipAuthenticationStatusesByToken,
  bookingComponentRefByToken,
  setSkipAuthenticationStatusesByToken,
}) => {
  Object.keys(bookingRequestsEncodedByToken).forEach(
    ccToken =>
      bookingRequestsEncodedByToken[ccToken] &&
      bookingRequestsEncodedByToken[ccToken].forEach(encodedReq => {
        if (
          !skipAuthenticationStatusesByToken[ccToken] ||
          skipAuthenticationStatusesByToken[ccToken][encodedReq] !== true
        ) {
          bookingComponentRefByToken[ccToken].current.initiateThreeDSAuthentication(
            encodedReq,
            true,
          );

          setSkipAuthenticationStatusesByToken({ ccToken, encodedReq });
        }
      }),
  );
};

export const threeDsAuthenticationSkippedForAllItems = (
  bookingRequestsEncodedByToken,
  skipAuthenticationStatusesByToken,
) => {
  const usedCreditCardTokens = Object.keys(bookingRequestsEncodedByToken).filter(
    ccToken => bookingRequestsEncodedByToken[ccToken].length > 0,
  );

  if (!usedCreditCardTokens.length > 0) {
    return false;
  }

  return usedCreditCardTokens.every(ccToken => {
    const ccTokenForSkipAuthentication = skipAuthenticationStatusesByToken[ccToken];

    if (!ccTokenForSkipAuthentication) {
      return false;
    }

    const ccTokenForBookingRequests = bookingRequestsEncodedByToken[ccToken];

    return ccTokenForBookingRequests.every(requestEncodedByToken => {
      const encodedTokenForSkipAuthentication =
        skipAuthenticationStatusesByToken[ccToken][requestEncodedByToken];

      return encodedTokenForSkipAuthentication;
    });
  });
};

export const needToRunThreeDsAuthentication = (
  isThreeDsAuthenticationSkippedForAllItems,
  requestsToAuthenticateByToken,
) => {
  if (isThreeDsAuthenticationSkippedForAllItems) {
    return false;
  }

  return Object.values(requestsToAuthenticateByToken).some(arrayValue => arrayValue.length > 0);
};

export const getThreeDsStatusValues = (
  threeDsStatusesByToken,
  ccTokensThatNeedToBook,
  bookingRequestsEncodedByToken,
) => {
  const threeDsStatusValues = [];

  Object.keys(threeDsStatusesByToken).forEach(uniqueToken => {
    if (ccTokensThatNeedToBook.includes(uniqueToken)) {
      Object.keys(threeDsStatusesByToken[uniqueToken]).forEach(key => {
        if (bookingRequestsEncodedByToken[uniqueToken].includes(key)) {
          threeDsStatusValues.push(threeDsStatusesByToken[uniqueToken][key]);
        }
      });
    }
  });

  return threeDsStatusValues;
};
