import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';

const FlightNumberTags = ({ flightNumbers }) => (
  <div className="d-flex align-center flex-gap-8 flex-wrap justify-end">
    {flightNumbers.map(flightNumber => (
      <EmphasisTag
        className="ancillary-row__flight-number"
        key={flightNumber}
        text={flightNumber}
        size="small"
        type="neutral"
        icon={<Icon name="aeroplane" />}
      />
    ))}
  </div>
);

FlightNumberTags.defaultProps = {
  flightNumbers: [],
};

FlightNumberTags.propTypes = {
  flightNumbers: PropTypes.arrayOf(PropTypes.string),
};

export default FlightNumberTags;
