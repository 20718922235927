import React, { Fragment, useState } from 'react';
import '@wtag/rcl-accordion/dist/styles.min.css';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem } from '@wtag/rcl-accordion';
import { I18nText } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Modal from 'sharedWebpack/Modal';
import HeaderElement from './HeaderElement';

const LegalPapers = props => {
  const [activeAccordionKey, setActiveAccordionKey] = useState([]);
  const onAccordionClick = id => {
    const filteredActiveKeys = activeAccordionKey.includes(id)
      ? activeAccordionKey.filter(item => item !== id)
      : [...activeAccordionKey, id];

    setActiveAccordionKey(filteredActiveKeys);
  };

  const accordionItems = () => {
    const items = [];
    if (props.legalPapers.length > 0) {
      items.push(<HeaderElement key="agency-header" textId="ibe.legal_papers.header.agency" />);
      items.push(
        props.legalPapers.map(legalPaper => (
          <AccordionItem
            key={legalPaper.type}
            onClick={onAccordionClick}
            isActive={activeAccordionKey.includes(legalPaper.type)}
            header={legalPaper.type}
            id={legalPaper.type}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: legalPaper.paper }} />
          </AccordionItem>
        )),
      );
    }

    if (props.airlineTermsAndConditions.length > 0) {
      items.push(<HeaderElement key="channel-header" textId="ibe.legal_papers.header.airline" />);
      items.push(
        props.airlineTermsAndConditions.map(legalPaper => (
          <AccordionItem
            key={legalPaper.type}
            onClick={onAccordionClick}
            isActive={activeAccordionKey.includes(legalPaper.type)}
            header={legalPaper.type}
            id={legalPaper.type}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: legalPaper.paper }} />
          </AccordionItem>
        )),
      );
    }
    return items;
  };
  return (
    <div className="col-grid col-bleed direction-row legal-paper-confirmation">
      <div className="legal-paper-confirmation__text">
        <I18nText id="ibe.legal_papers.text" returnStringOnly={true} />:{' '}
      </div>
      <div>
        <span
          className="legal-papers__link-text--modify-bold"
          onClick={() => props.onLegalPapersModalOpen(true)}
          role="button"
          tabIndex={0}
          onKeyDown={() => props.onLegalPapersModalOpen(true)}
        >
          <I18nText id="ibe.legal_papers.terms_and_conditions" returnStringOnly={true} />
        </span>
        <Modal
          className="legal-paper-confirmation__modal"
          title={
            <Fragment>
              <I18nText id="ibe.legal_papers.terms_and_conditions" />
              <div
                onClick={() => props.onLegalPapersModalOpen(false)}
                onKeyDown={() => props.onLegalPapersModalOpen(false)}
                role="presentation"
              >
                <Icon name="close" />
              </div>
            </Fragment>
          }
          isModalVisible={props.legalPapersModalOpen}
          onOutsideClick={() => props.onLegalPapersModalOpen(false)}
        >
          <div className="legal-papers__accordion col-12 col-bleed">
            <Accordion>{accordionItems()}</Accordion>
          </div>
        </Modal>
      </div>
    </div>
  );
};

LegalPapers.propTypes = {
  legalPapers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  airlineTermsAndConditions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onLegalPapersModalOpen: PropTypes.func.isRequired,
  legalPapersModalOpen: PropTypes.bool.isRequired,
};
export default LegalPapers;
