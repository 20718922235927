import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LogoChip = ({ className, logo }) => (
  <div className={classNames('flight__logo-chip', className)}>
    <img src={logo} alt={logo} />
  </div>
);

LogoChip.defaultProps = {
  className: null,
};

LogoChip.propTypes = {
  logo: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default LogoChip;
