import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LogoChip from 'sharedWebpack/IBE/SearchResult/flight/LogoChip';
import AirlinesPopup from 'sharedWebpack/IBE/SearchResult/flight/AirlinesPopup';
import humanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import DateTime from 'sharedWebpack/DateTime';
import TripDetailsItem from 'sharedWebpack/IBE/Search/TripDetailsItem';
import getFlightStopsCountLabel from 'sharedWebpack/helpers/calculateStopoverStops';
import getTotalStopoverDuration from '../../../../helpers/getTotalStopoverDuration';
import { DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME } from '../../../../helpers/dateTime';
import './styles.scss';

const SHOW_AT_MOST_ITEMS_COUNT = 1;

const getUniqueAirlineLogos = airlineLogos => {
  if (!Array.isArray(airlineLogos)) {
    return [];
  }

  const uniqueAirlineLogos = {};

  airlineLogos.forEach(airlineLogoItem => {
    const currentAirlineLogoItem = airlineLogoItem.logo;
    if (currentAirlineLogoItem) {
      uniqueAirlineLogos[currentAirlineLogoItem] = airlineLogoItem;
    }
  });

  return Object.values(uniqueAirlineLogos);
};

const TripDetailsHeader = ({ tripDetails, airlineLogos }) => {
  if (!tripDetails && !airlineLogos) {
    return null;
  }

  const {
    originCode,
    destinationCode,
    startsAtInUserTz,
    endsAtInUserTz,
    stopovers,
    durationInMinutes,
  } = tripDetails;
  const uniqueAirlineLogos = getUniqueAirlineLogos(airlineLogos);
  const stopoverCountLabel = getFlightStopsCountLabel(stopovers);
  const visibleAirlineLogos = uniqueAirlineLogos.slice(0, SHOW_AT_MOST_ITEMS_COUNT);
  const hiddenAirlineLogosCount = uniqueAirlineLogos.length - SHOW_AT_MOST_ITEMS_COUNT;
  const hasHiddenAirlineLogos = hiddenAirlineLogosCount > 0;
  const airlineCodes = uniqueAirlineLogos.map(({ code, name }) => ({ logo: code, code: name }));
  const totalStopoverDuration = getTotalStopoverDuration(stopovers);
  const stopoverDurationSubtitle =
    totalStopoverDuration === 0
      ? I18n.t('admin.components.settings.search_input.label.direct')
      : humanReadableDuration(totalStopoverDuration);

  return (
    <Fragment>
      <div className="align-center trip-details-header trip-details-header--lg">
        <div className="d-flex justify-between align-center trip-details__logo-content">
          {visibleAirlineLogos.map(({ logo }) => (
            <LogoChip key={logo} logo={logo} />
          ))}
          {hasHiddenAirlineLogos && visibleAirlineLogos.length > 0 && (
            <AirlinesPopup title={hiddenAirlineLogosCount} codes={airlineCodes} />
          )}
        </div>
        <div className="d-flex align-center trip-details__items">
          <TripDetailsItem
            title={originCode}
            subtitle={
              <DateTime
                dateTime={startsAtInUserTz}
                format={DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME}
              />
            }
          />
          <TripDetailsItem
            title={destinationCode}
            subtitle={
              <DateTime dateTime={endsAtInUserTz} format={DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME} />
            }
          />
          <TripDetailsItem
            className="trip-details--duration"
            title={I18n.t('components.flight.duration')}
            subtitle={<h4>{humanReadableDuration(durationInMinutes)}</h4>}
          />
          <TripDetailsItem title={stopoverCountLabel} subtitle={stopoverDurationSubtitle} />
        </div>
      </div>
      <div className="align-stretch direction-column trip-details-header trip-details-header--md">
        <div className="d-flex justify-between">
          <div className="d-flex justify-between align-center trip-details__logo-content">
            {visibleAirlineLogos.map(({ logo }) => (
              <LogoChip key={logo} logo={logo} />
            ))}
            {hasHiddenAirlineLogos && visibleAirlineLogos.length > 0 && (
              <AirlinesPopup title={hiddenAirlineLogosCount} codes={airlineCodes} />
            )}
          </div>
          <TripDetailsItem
            className="trip-details--duration"
            title={I18n.t('components.flight.duration')}
            subtitle={<h4>{humanReadableDuration(durationInMinutes)}</h4>}
          />
        </div>
        <div className="d-flex justify-between trip-details__items">
          <TripDetailsItem
            title={originCode}
            subtitle={
              <DateTime
                dateTime={startsAtInUserTz}
                format={DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME}
              />
            }
          />
          <TripDetailsItem
            title={destinationCode}
            subtitle={
              <DateTime dateTime={endsAtInUserTz} format={DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME} />
            }
          />
          <TripDetailsItem title={stopoverCountLabel} subtitle={stopoverDurationSubtitle} />
        </div>
      </div>
    </Fragment>
  );
};

TripDetailsHeader.propTypes = {
  tripDetails: PropTypes.shape({
    originCode: PropTypes.string,
    destinationCode: PropTypes.string,
    startsAtInUserTz: PropTypes.string,
    endsAtInUserTz: PropTypes.string,
    stopovers: PropTypes.arrayOf(
      PropTypes.shape({
        transitDurationInMinutes: PropTypes.number,
      }),
    ),
    stopoverCount: PropTypes.number,
    durationInMinutes: PropTypes.number,
  }).isRequired,
  airlineLogos: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default TripDetailsHeader;
