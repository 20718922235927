import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';

import SelectBox from '@wtag/rcl-select-box';

import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

const searchOptions = async (type, filterParams, query, perPage) => {
  const { data } = await httpClient.get(
    routes.admin.dropdownOptions.list({ type, filterParams, q: query, per_page: perPage }),
  );
  return data.options;
};

const fetchOption = async (type, id) => {
  const { data } = await httpClient.get(routes.admin.dropdownOptions.show({ type, id }));
  return data.option;
};

const onSearchOptionsFactory = ({ type, filterParams, setIsLoading, callback }) =>
  debounce(500, async query => {
    if (query) {
      setIsLoading(true);
      const options = await searchOptions(type, filterParams, query);
      setIsLoading(false);
      callback(options);
    }
  });

const DropDown = ({
  type,
  onInitialFetch,
  value,
  onChange,
  optionsFilter,
  filterParams,
  isDisabled,
  ...otherProps
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSearchOptions = onSearchOptionsFactory({
    type,
    filterParams,
    setIsLoading,
    callback: setOptions,
  });

  const fetchInitialOptions = async () => {
    setIsLoading(true);
    const fetchedOptions = await searchOptions(type, filterParams, '', 5);
    setIsLoading(false);
    setOptions(fetchedOptions);
    return fetchedOptions;
  };

  useEffect(() => {
    fetchInitialOptions().then(onInitialFetch);
  }, [JSON.stringify(filterParams)]);

  let selectBoxValue;
  if (value && value.value) {
    selectBoxValue = value;
  } else {
    selectBoxValue = null;
  }

  useEffect(() => {
    if (value && !value.value) {
      setIsLoading(true);
      fetchOption(type, value)
        .then(onChange)
        .then(() => setIsLoading(false));
    }
  }, [JSON.stringify(value)]);

  return (
    <SelectBox
      onInputChange={onSearchOptions}
      options={optionsFilter(options)}
      onChange={onChange}
      value={selectBoxValue}
      isDisabled={isLoading || isDisabled}
      {...otherProps}
    />
  );
};

DropDown.defaultProps = {
  onInitialFetch: () => {},
  optionsFilter: options => options,
  filterParams: {},
  value: null,
  isDisabled: false,
};

const valuePropTypes = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
DropDown.propTypes = {
  type: PropTypes.string.isRequired,
  onInitialFetch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  optionsFilter: PropTypes.func,
  filterParams: PropTypes.shape(),
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: valuePropTypes,
    }),
    valuePropTypes,
  ]),
};

export default DropDown;
