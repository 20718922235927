import React from 'react';
import PropTypes from 'prop-types';
import LoadingInformationCard from './LoadingInformationCard/LoadingInformationCardContainer';

const SearchLoadingScreen = ({ searchInformation, searchCompleted, expand }) => (
  <div className="col-grid col-bleed">
    {searchInformation.trips && (
      <LoadingInformationCard
        searchInformation={searchInformation}
        searchCompleted={searchCompleted}
        expand={expand}
      />
    )}
  </div>
);
SearchLoadingScreen.propTypes = {
  searchInformation: PropTypes.shape({
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        origin: PropTypes.string.isRequired,
        destination: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        cabinClass: PropTypes.string.isRequired,
      }),
    ).isRequired,
    adults: PropTypes.number.isRequired,
    children: PropTypes.number.isRequired,
    infants: PropTypes.number.isRequired,
  }).isRequired,
  searchCompleted: PropTypes.bool.isRequired,
  expand: PropTypes.bool.isRequired,
};

export default SearchLoadingScreen;
