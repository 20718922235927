import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CheckBox } from '@wtag/react-comp-lib';
import assistanceRequestsBuilder from '../../../../Search/flight/helpers/assistanceRequestsBuilder';

const AssistanceRequests = ({
  fields,
  assistanceRequests,
  selectedCustomer,
  onCustomerSelected,
  travelerIndex,
}) => {
  const assistanceRequestChecked = type => fields.assistanceRequests.value.indexOf(type) >= 0;

  const toggleAssistanceRequest = (type, isInputChecked) => {
    let requests = fields.assistanceRequests.value;
    if (!requests.push) {
      requests = [];
    }
    if (isInputChecked) {
      requests.push(type);
    } else {
      const index = requests.indexOf(type);
      requests.splice(index, 1);
    }
    fields.assistanceRequests.onChange(requests);

    const updatedSelectedCustomer = {
      ...selectedCustomer,
      assistanceRequests: requests,
    };

    onCustomerSelected(travelerIndex, updatedSelectedCustomer);
  };

  let displayableAssistancRequests = [];
  let assistanceRequestsAvailableForAllItems = false;

  // Retrieve all assistance requests and check if they are supported for all items or not
  assistanceRequestsAvailableForAllItems = Object.values(assistanceRequests)
    .map(item => item.supported)
    .every(supported => supported);

  if (assistanceRequestsAvailableForAllItems) {
    // This will be dynamic in terms of each items in the cart in upcoming project/ fix
    const assistanceCodes = ['WCHR', 'WCHS', 'WCHC', 'DEAF', 'BLND', 'DPNA'];
    displayableAssistancRequests = assistanceRequestsBuilder(assistanceCodes);
  }

  return (
    <div className="col-12 col-bleed assistance-request">
      <div className="col-grid col-bleed direction-row wrap align-center assistance-request__checkbox-holder">
        {assistanceRequestsAvailableForAllItems ? (
          displayableAssistancRequests.map((request, index) => {
            const paddingClass = classNames(
              'col-12',
              index % 2 === 0 ? 'col-bleed' : 'col-bleed-x',
            );
            return (
              <div key={request.value} className={paddingClass}>
                <CheckBox
                  label={request.label}
                  name={request.value}
                  checked={assistanceRequestChecked(request.value)}
                  onChange={() =>
                    toggleAssistanceRequest(
                      request.value,
                      fields.assistanceRequests.value.indexOf(request.value) < 0,
                    )
                  }
                />
              </div>
            );
          })
        ) : (
          <Fragment>{I18n.t('components.assistance_requests.not_supported')}</Fragment>
        )}
      </div>
    </div>
  );
};

AssistanceRequests.propTypes = {
  assistanceRequests: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({}).isRequired,
  travelerIndex: PropTypes.number.isRequired,
};

export default AssistanceRequests;
