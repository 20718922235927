export const ERROR_OCCURED = 'ERROR_OCCURED';
function errorOccured(message, error, extraData) {
  return {
    type: ERROR_OCCURED,
    message,
    error,
    extraData,
  };
}

export const CLEAR_ERROR = 'CLEAR_ERROR';
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

const REPORT_TO_ROLLBAR = 'REPORT_TO_ROLLBAR';
export function reportToRollbar(message, error) {
  const extraData = error.extraData;
  if (typeof Rollbar !== 'undefined') {
    Rollbar.error(message, error, extraData); // eslint-disable-line no-undef
  } else if (process.env.NODE_ENV !== 'test') {
    console.log('Rollbar not available'); // eslint-disable-line no-console
    console.log(message, error, extraData); // eslint-disable-line no-console
  }
  return { type: REPORT_TO_ROLLBAR };
}

export function showError(message, error) {
  if (error) {
    reportToRollbar(message, error);
  }

  return errorOccured(message, error);
}
