import React from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';

import './styles.scss';

const FilterWrapper = props => {
  const { title, children, isInitiallyCollapsed, type } = props;

  const className = {
    primary: 'filter-wrapper--primary',
    secondary: 'filter-wrapper--secondary',
  };

  return (
    <Card
      className={className[type]}
      version="v2"
      size="full"
      hasPadding={false}
      isCollapsible={true}
      isInitiallyCollapsed={isInitiallyCollapsed}
      title={title}
    >
      {children}
    </Card>
  );
};

FilterWrapper.defaultProps = {
  isInitiallyCollapsed: true,
  type: 'primary',
};

FilterWrapper.propTypes = {
  title: PropTypes.node.isRequired,
  isInitiallyCollapsed: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node.isRequired,
};

export default FilterWrapper;
