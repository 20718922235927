import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';

const HeaderElement = props => (
  <div className="legal-papers__header">
    <I18nText id={props.textId} returnStringOnly={true} />
  </div>
);

HeaderElement.propTypes = {
  textId: PropTypes.string.isRequired,
};
export default HeaderElement;
