/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Row from '../Row';

function Voucher(props) {
  const { item, totalWithoutVoucherReduction } = props;

  return (
    <Fragment>
      <div className="cart-summary__accordion-sub-item">
        <Row
          name={I18n.t('components.ibe.summary.subtotal')}
          price={totalWithoutVoucherReduction}
          currency={item.reductionCurrency}
          hasChildRow={false}
        />
      </div>
      <div className="cart-summary__accordion-sub-item">
        <Row
          name={I18n.t('components.ibe.summary.applied_voucher')}
          price={item.reductionAmount}
          currency={item.reductionCurrency}
          type={item.type}
          showPriceInGreen={true}
          number={item.number}
        />
      </div>
    </Fragment>
  );
}

Voucher.propTypes = {
  item: PropTypes.shape({
    amount: PropTypes.string,
    contentInvalid: PropTypes.bool,
    contentMessage: PropTypes.string,
    currency: PropTypes.string,
    number: PropTypes.string,
    numberFormField: PropTypes.string,
    percentage: PropTypes.string,
    reductionAmount: PropTypes.string,
    reductionCurrency: PropTypes.string,
    reductionType: PropTypes.string,
    used: PropTypes.bool,
    valid: PropTypes.bool,
  }).isRequired,
  totalWithoutVoucherReduction: PropTypes.string.isRequired,
};
export default Voucher;
