import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import {
  resetApp,
  multiSearchResultSelected,
  multiSearchResultDeSelected,
} from '../actions/common';
import MultiSearch from './MultiSearch';

import { validate } from '../lib/validations';
import { validateTrips } from '../Search/flight/helpers/validate';
import { validateHotel } from '../Search/hotel/helpers/validate';
import { validateCar } from '../Search/car/helpers/validate';
import { validateTravelerConstraints } from './helpers/dealValidate';
import deepClone from '../lib/deepClone';
import searchAccountValues from '../helpers/searchAccountValues';

const validateFields = (values, context) => {
  const errors = validate(values);
  errors.travelersType = validateTravelerConstraints(values.travelersType);

  if (values.groups) {
    errors.groups = {};

    Object.keys(values.groups).forEach(key => {
      const group = values.groups[key];
      errors.groups[key] = {};

      switch (group.type) {
        case 'flight':
          errors.groups[key].trips = validateTrips(
            group.trips,
            context.minHoursInFuture,
            context.searchInputConfiguration.showDepartureTime,
          );
          break;
        case 'hotel':
          errors.groups[key] = validateHotel(
            group,
            context.availableChannels.hotels,
            context.minHoursInFuture,
            values.travelersType.adults,
            'multisearch',
          );
          break;
        case 'car':
          errors.groups[key] = validateCar(
            group,
            context.minHoursInFuture,
            context.availableChannels.cars,
          );
          break;
        default:
      }
    });
  }

  return errors;
};

const initialFields = [
  'adults',
  'children',
  'infants',
  'currency',
  'travelersType.adults',
  'travelersType.children',
  'travelersType.infants',
  'travelersType.infantList.count',
  'travelersType.infantList.age[]',
  'travelersType.childrenList.count',
  'travelersType.childrenList.age[]',
];

const formConfig = {
  form: 'multiSearch',
  fields: [],
  validate: validateFields,
  destroyOnUnmount: false,
};

const people = groups => {
  if (groups) {
    const values = Object.values(groups);
    const firstFlightGroupIndex = values.findIndex(group => group.type === 'flight');
    if (firstFlightGroupIndex >= 0) {
      const params = values[firstFlightGroupIndex].searchParams;
      return {
        adults: params.adults === undefined ? 1 : params.adults,
        children: params.children,
        infants: params.infants,
        travelersType: {
          adults: params.adults === undefined ? 1 : params.adults,
          children: params.children,
          infants: params.infants,
          childrenList: {
            count: params.children,
          },
          infantList: {
            count: params.infants,
          },
        },
      };
    }
    if (values[0] && values[0].searchParams) {
      const params = values[0].searchParams;
      return {
        adults: params.adults === undefined ? 1 : params.adults,
        children: (params.children && params.children.length) || 0,
        infants: (params.infants && params.infants.length) || 0,
        travelersType: {
          adults: params.adults === undefined ? 1 : params.adults,
          children: (params.children && params.children.length) || 0,
          infants: (params.infants && params.infants.length) || 0,
          childrenList: {
            count: (params.children && params.children.length) || 0,
          },
          infantList: {
            count: (params.infants && params.infants.length) || 0,
          },
        },
      };
    }
  }
  return { adults: 0, children: 0, infants: 0 };
};

const mapStateToProps = (state, props) => {
  const { multiSearch, deal } = state.common;
  const { searchIds } = multiSearch;
  const {
    adAmount,
    features,
    featureImage,
    imageList,
    interests,
    categories,
    locations,
    favouriteDealCount,
    favouriteDealPeople,
    favouriteOfCurrentPerson,
    enquireOnly,
    person,
    cartId,
  } = deal;
  const { list: featureImageList, grid: featureImageGrid } = featureImage;
  const fields = deepClone(initialFields);
  const groups = props.groups;
  let allConfirmed = false;
  let allOpen = true;
  let allSelected = true;
  let allLoaded = false;

  const initialValues = {
    currency: props.currency,
    minHoursInFuture: props.minHoursInFuture,
    groups: {},
    selectedFlightAccounts: searchAccountValues(props.searchAccounts.flights),
    selectedHotelAccounts: searchAccountValues(props.searchAccounts.hotels),
    selectedCarAccounts: searchAccountValues(props.searchAccounts.cars),
    ...people(groups),
  };
  const status = {};
  Object.keys(groups).forEach(key => {
    const value = state.common.multiSearch.status[key] || 'open';
    status[key] = value;
  });

  allConfirmed = Object.values(status).every(groupStatus => groupStatus === 'available');
  allOpen = Object.values(status).every(groupStatus => groupStatus === 'open');
  allSelected = Object.values(status).every(groupStatus => groupStatus === 'selected');
  allLoaded = Object.keys(state.common.multiSearch.status).every(groupKey => {
    const groupStatus = state.common.multiSearch.status[groupKey];
    const groupType = groups.find(group => group.key === groupKey).type;
    return (
      groupType === 'static_product' || groupStatus === 'available' || groupStatus === 'unavailable'
    );
  });

  if (props.searchAccounts && props.searchAccounts.flights) {
    Object.keys(props.searchAccounts.flights).forEach(key =>
      fields.push(`selectedFlightAccounts.${key}`),
    );
  }
  if (props.searchAccounts && props.searchAccounts.hotels) {
    Object.keys(props.searchAccounts.hotels).forEach(key =>
      fields.push(`selectedHotelAccounts.${key}`),
    );
  }
  if (props.searchAccounts && props.searchAccounts.cars) {
    Object.keys(props.searchAccounts.cars).forEach(key =>
      fields.push(`selectedCarAccounts.${key}`),
    );
  }

  Object.keys(groups).forEach(key => {
    const group = groups[key];

    const searchParams = group.searchParams;
    if (group.type === 'hotel' && group.searchParams.geoLocation) {
      searchParams.address = group.searchParams.geoLocation.address;
      searchParams.latitude = group.searchParams.geoLocation.latitude;
      searchParams.longitude = group.searchParams.geoLocation.longitude;
      initialValues.groups[key] = { ...searchParams, type: group.type };
    } else if (group.type === 'car') {
      searchParams.pickUpDate = group.searchParams.pickUpDate;
      searchParams.dropOffDate = group.searchParams.dropOffDate;
      searchParams.pickUpTime = group.searchParams.pickUpTime;
      searchParams.dropOffTime = group.searchParams.dropOffTime;
      initialValues.groups[key] = { ...searchParams, type: group.type };
    } else if (group.type === 'static_product') {
      initialValues.groups[key] = { ...searchParams, type: group.type };
    } else {
      initialValues.groups[key] = { ...group.searchParams, type: group.type };
    }

    fields.push(`groups.${key}.type`);

    switch (group.type) {
      case 'flight':
        fields.push(`groups.${key}.trips[].date`);
        fields.push(`groups.${key}.trips[].departureTime`);
        fields.push(`groups.${key}.trips[].bookingClass`);
        fields.push(`groups.${key}.trips[].alliancePreferences`);
        fields.push(`groups.${key}.trips[].cabinClass`);
        fields.push(`groups.${key}.trips[].origin`);
        fields.push(`groups.${key}.trips[].destination`);
        fields.push(`groups.${key}.nonStop`);
        fields.push(`groups.${key}.onlyFlexibleFlight`);
        fields.push(`groups.${key}.onlyBagIncludedFlight`);
        fields.push(`groups.${key}.excludeCodeShareFlight`);
        break;
      case 'hotel':
        fields.push(`groups.${key}.checkIn`);
        fields.push(`groups.${key}.checkOut`);
        fields.push(`groups.${key}.placeId`);
        fields.push(`groups.${key}.address`);
        fields.push(`groups.${key}.latitude`);
        fields.push(`groups.${key}.longitude`);
        fields.push(`groups.${key}.numberOfRooms`);
        fields.push(`groups.${key}.roomId`);
        break;
      case 'car':
        fields.push(`groups.${key}.airportSearch`);

        fields.push(`groups.${key}.pickUpLocationAirportCode`);
        fields.push(`groups.${key}.pickUpGeoLocationAddress`);
        fields.push(`groups.${key}.pickUpGeoLocationLatitude`);
        fields.push(`groups.${key}.pickUpGeoLocationLongitude`);
        fields.push(`groups.${key}.pickUpDate`);
        fields.push(`groups.${key}.pickUpTime`);

        fields.push(`groups.${key}.dropOffLocationAirportCode`);
        fields.push(`groups.${key}.dropOffGeoLocationAddress`);
        fields.push(`groups.${key}.dropOffGeoLocationLatitude`);
        fields.push(`groups.${key}.dropOffGeoLocationLongitude`);
        fields.push(`groups.${key}.dropOffDate`);
        fields.push(`groups.${key}.dropOffTime`);

        fields.push(`groups.${key}.driverAge`);
        break;
      case 'static_product':
        fields.push(`groups.${key}.quantity`);
        break;
      default:
    }
  });

  return {
    airports: state.common.airports,
    allConfirmed,
    allOpen,
    allSelected,
    allLoaded,
    continuePossible: props.continuePossible,
    disableForm: props.disableForm,
    fields,
    groups,
    adAmount,
    featureImageGrid,
    featureImageList,
    features,
    interests,
    categories,
    locations,
    favouriteDealPeople,
    favouriteDealCount,
    favouriteOfCurrentPerson,
    imageList,
    enquireOnly,
    person,
    initialValues,
    name: props.title,
    searchIds,
    status,
    text: props.text,
    staticProducts: state.common.staticProducts,
    journeyElements: state.common.journeyElements,
    cartId,
    approvalRequestId: state.common.approvalRequest && state.common.approvalRequest.id,
    selectedResults: multiSearch.selectedResults,
    hasStaticProducts:
      state.common.staticProducts && Object.keys(state.common.staticProducts).length > 0,
  };
};

const mapDispatchToProps = dispatch => ({
  resetApp: () => dispatch(resetApp()),
  multiSearchResultSelected: (groupKey, resultId) =>
    dispatch(multiSearchResultSelected(groupKey, resultId)),
  multiSearchResultDeSelected: (groupKey, resultId) =>
    dispatch(multiSearchResultDeSelected(groupKey, resultId)),
});

const MultiSearchWithForm = reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(MultiSearch);

const MultiSearchWithContext = (props, context) => <MultiSearchWithForm {...props} {...context} />;

MultiSearchWithContext.contextTypes = {
  searchAccounts: PropTypes.shape().isRequired,
  currency: PropTypes.string.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  minHoursInFuture: PropTypes.number.isRequired,
  searchInputConfiguration: PropTypes.shape().isRequired,
  availableChannels: PropTypes.shape().isRequired,
};

export default MultiSearchWithContext;
