// the purpose of this function is to return true when the availabilty check is still running for flight items.
// while the availabilty check is completed for all the items,
// this function returns false as the item details are ready and the copy content button will be enabled

import { TYPE_FLIGHT } from '../../../helpers/itemTypes';

const isCopyContentDisabled = (items, flightResultDetailsById) => {
  const availability = items.map(item => {
    const itemDetails =
      item.bookingAttributes &&
      flightResultDetailsById &&
      flightResultDetailsById[item.bookingAttributes.resultId];

    // returns true only if it's a flight and details are missing, false otherwise
    return item.journeyElementType === TYPE_FLIGHT && !itemDetails;
  });

  // if in the availability array atleast one value is true that means
  // we have atleast one flight item which doesn't have details yet
  // hence this returns true and we'll disable the copy content until all the flight item details are available
  return availability.some(available => available);
};

export default isCopyContentDisabled;
