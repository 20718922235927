import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@wtag/rcl-button';
import { LabelHint } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Step from './components/Step';

import './styles.scss';

const Stepper = props => {
  const {
    className,
    steps,
    activeStepIndex,
    onFinish,
    onMoveNext,
    onMoveBack,
    nextButtonLabel,
    previousButtonLabel,
    finishButtonLabel,
    disableMoveForward,
    disableMoveBackward,
    errors,
    definedNextButton,
    nextStepButtonHint,
    showFinishButton,
    completedStepIndex,
  } = props;

  const renderNextButton = () => {
    if (definedNextButton) {
      return definedNextButton;
    }

    if (activeStepIndex === steps.length - 1 && showFinishButton) {
      return (
        <Button
          className="stepper__footer-actions-button stepper__footer-actions-button--finish"
          version="v2"
          type="tertiary"
          size="small"
          label={finishButtonLabel}
          disabled={disableMoveForward || activeStepIndex === steps.length}
          onClick={onFinish}
          icon={<Icon name="check" />}
        />
      );
    }

    if (nextStepButtonHint) {
      return (
        <LabelHint hint={nextStepButtonHint} minWidthAuto={true}>
          <Button
            className="stepper__footer-actions-button stepper__footer-actions-button--next"
            version="v2"
            type="tertiary"
            size="small"
            label={nextButtonLabel}
            disabled={disableMoveForward || activeStepIndex === steps.length}
            onClick={onMoveNext}
            icon={<Icon name="arrowForwardAlt" />}
          />
        </LabelHint>
      );
    }

    return (
      <Button
        className="stepper__footer-actions-button stepper__footer-actions-button--next"
        version="v2"
        type="tertiary"
        size="small"
        label={nextButtonLabel}
        disabled={disableMoveForward || activeStepIndex === steps.length}
        onClick={onMoveNext}
        icon={<Icon name="arrowForwardAlt" />}
      />
    );
  };

  const indexHasError = index => Object.keys(errors).indexOf(index.toString()) >= 0;
  const isDone = index => index < activeStepIndex || completedStepIndex === activeStepIndex;
  const isPreviousButtonDisabled =
    disableMoveBackward || activeStepIndex === 0 || activeStepIndex === steps.length;

  return (
    <div className={classNames('d-flex align-center justify-between stepper', className)}>
      <div className="d-flex align-center column-gap-16 flex-grow stepper__content">
        <Button
          className="stepper__footer-actions-button stepper__footer-actions-button--pre"
          version="v2"
          size="small"
          label={previousButtonLabel}
          disabled={isPreviousButtonDisabled}
          onClick={onMoveBack}
          icon={<Icon name="arrowBackAlt" />}
        />
        <div className="d-flex align-center flex-grow justify-between stepper__body">
          {steps.map((step, index) => (
            <Step
              key={step.stepName}
              stepName={step.stepName}
              stepIconType={step.stepIconType}
              stepIndex={index}
              isActive={index === activeStepIndex}
              isDone={isDone(index)}
              hasError={indexHasError(index)}
            />
          ))}
        </div>
      </div>
      {renderNextButton()}
    </div>
  );
};

Stepper.defaultProps = {
  className: null,
  finishButtonLabel: 'Finish',
  showFinishButton: true,
  nextButtonLabel: 'Next Step',
  previousButtonLabel: 'Previous Step',
  disableMoveForward: false,
  disableMoveBackward: false,
  onFinish: () => {},
  onMoveBack: () => {},
  onMoveNext: () => {},
  definedNextButton: null,
  nextStepButtonHint: null,
  errors: {},
  completedStepIndex: null,
};

Stepper.propTypes = {
  definedNextButton: PropTypes.node,
  className: PropTypes.string,
  errors: PropTypes.shape(),
  nextStepButtonHint: PropTypes.string,
  showFinishButton: PropTypes.bool,
  finishButtonLabel: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      stepHint: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  nextButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  previousButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disableMoveForward: PropTypes.bool,
  disableMoveBackward: PropTypes.bool,
  onFinish: PropTypes.func,
  onMoveBack: PropTypes.func,
  onMoveNext: PropTypes.func,
  completedStepIndex: PropTypes.number,
};

export default Stepper;
