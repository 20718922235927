import React, { useState } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import Form from '../Form';

const Create = ({
  availableCurrencies,
  orderId,
  travellerId,
  scope,
  urls,
  forOrderType,
  forTravellerType,
}) => {
  const [date, setDate] = useState('');

  const createTravelExpense = async formData => {
    const { data } = await httpClient.post(urls.createTravelExpensePath, formData);

    if (data.error === null) {
      window.location.replace(urls.index);
    }
  };

  return (
    <div className="col-grid align-center">
      <div className="col-10">
        <Form
          availableCurrencies={availableCurrencies}
          urls={urls}
          submitText={I18n.t('admin.components.travel_expenses.actions.submit')}
          submitAction={value => createTravelExpense(value)}
          date={date}
          setDate={setDate}
          orderId={orderId}
          travellerId={travellerId}
          scope={scope}
          forOrderType={forOrderType}
          forTravellerType={forTravellerType}
        />
      </div>
    </div>
  );
};

Create.defaultProps = {
  orderId: null,
  scope: 'public',
  forOrderType: false,
  forTravellerType: false,
};

Create.propTypes = {
  availableCurrencies: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  travellerId: PropTypes.number.isRequired,
  orderId: PropTypes.number,
  scope: PropTypes.oneOf(['admin', 'public']),
  forOrderType: PropTypes.bool,
  forTravellerType: PropTypes.bool,
  urls: PropTypes.shape({
    index: PropTypes.string.isRequired,
    createTravelExpensePath: PropTypes.string.isRequired,
  }).isRequired,
};

export default Create;
