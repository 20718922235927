import moment from 'moment';

const getFlightsInCart = cart => cart.items.filter(item => item.type === 'flight');

export const startFromCart = state => {
  const flightInCart = getFlightsInCart(state.common.cart);
  if (flightInCart.length === 0) {
    return null;
  }
  const arrival =
    state.common.journeyElements.flight[flightInCart[0].journeyElementId] &&
    state.common.journeyElements.flight[flightInCart[0].journeyElementId].trips &&
    state.common.journeyElements.flight[flightInCart[0].journeyElementId].trips[0].segments[0]
      .arrivalLocaltime;
  return moment(arrival)
    .milliseconds(0)
    .toDate();
};

export const endFromCart = state => {
  const flightInCart = getFlightsInCart(state.common.cart);
  if (flightInCart.length === 0) {
    return null;
  }

  const data = state.common.journeyElements.flight[flightInCart[0].journeyElementId];
  if (data && data.trips.length === 1) {
    return null;
  }

  const tripsLastIndex = data && data.trips.length - 1;
  const departure =
    tripsLastIndex >= 0 && data.trips[tripsLastIndex].segments[0].departureLocaltime;
  return moment(departure)
    .milliseconds(0)
    .toDate();
};
