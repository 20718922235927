import React from 'react';
import PropTypes from 'prop-types';
import { I18nText, Link } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import { Table, TableBody, TableData, TableHeader, TableHead, TableRow } from '@wtag/rcl-table';
import EmptyTableContent from 'sharedWebpack/EmptyTableContent';
import TablePlaceHolder from 'sharedWebpack/Placeholder/TablePlaceholder';
import DateTime from 'sharedWebpack/DateTime';

const TravelExpenseRow = ({ travelExpense, deleteAction }) => (
  <TableRow key={travelExpense.id} className="queue-management-list__pnr-row">
    <TableData>{travelExpense.id}</TableData>
    <TableData>
      <DateTime dateTime={travelExpense.date} format="short" />
    </TableData>
    <TableData>{travelExpense.name}</TableData>
    <TableData>{travelExpense.netAmount}</TableData>
    <TableData>{travelExpense.vat}</TableData>
    <TableData>{travelExpense.totalAmount}</TableData>
    <TableData align="center">
      <Link
        href={travelExpense.urls.attachment}
        labelHint={I18n.t('admin.shared.action.view_file')}
        type="icon-button"
        modifier="tertiary"
        disabled={false}
      >
        <Icon name="orders" size="normal" />
      </Link>
      <Link
        href={travelExpense.urls.edit}
        labelHint={I18n.t('admin.shared.action.edit')}
        type="icon-button"
        modifier="tertiary"
      >
        <Icon name="edit" size="normal" />
      </Link>
      <Link
        labelHint={I18n.t('admin.shared.action.destroy')}
        type="icon-button"
        modifier="tertiary"
        onClick={() => deleteAction(travelExpense.urls.delete)}
      >
        <Icon name="delete" size="normal" />
      </Link>
    </TableData>
  </TableRow>
);

TravelExpenseRow.propTypes = {
  travelExpense: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    netAmount: PropTypes.string.isRequired,
    vat: PropTypes.string.isRequired,
    totalAmount: PropTypes.string.isRequired,
    urls: PropTypes.shape({
      attachment: PropTypes.string.isRequired,
      edit: PropTypes.string.isRequired,
      delete: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  deleteAction: PropTypes.func.isRequired,
};

const TravelExpenseTable = ({ travelExpenses, deleteAction, isLoading }) =>
  !isLoading && !travelExpenses.length ? (
    <EmptyTableContent iconName="tag" />
  ) : (
    <Table fullWidth={true}>
      <TableHead>
        <TableRow>
          <TableHeader>{I18n.t('admin.components.travel_expenses.table.id')}</TableHeader>
          <TableHeader>{I18n.t('admin.components.travel_expenses.date')}</TableHeader>
          <TableHeader>{I18n.t('admin.components.travel_expenses.table.name')}</TableHeader>
          <TableHeader>{I18n.t('admin.components.travel_expenses.table.net_amount')}</TableHeader>
          <TableHeader>{I18n.t('admin.components.travel_expenses.table.vat')}</TableHeader>
          <TableHeader>{I18n.t('admin.components.travel_expenses.table.total_amount')}</TableHeader>
          <TableHeader align="center">
            <I18nText id="shared.action" returnStringOnly={true} />
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <TablePlaceHolder numberOfColumn={7} numberOfRows={10} withoutRclTable={true} />
        ) : (
          travelExpenses.map(expense => (
            <TravelExpenseRow
              key={expense.id}
              travelExpense={expense}
              deleteAction={deleteAction}
            />
          ))
        )}
      </TableBody>
    </Table>
  );

TravelExpenseTable.propTypes = {
  travelExpenses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      netAmount: PropTypes.string.isRequired,
      vat: PropTypes.string.isRequired,
      totalAmount: PropTypes.string.isRequired,
      urls: PropTypes.shape({
        attachment: PropTypes.string.isRequired,
        edit: PropTypes.string.isRequired,
        delete: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  deleteAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TravelExpenseTable;
