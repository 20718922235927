import DOMPurify from 'dompurify';

function sanitizeURL(url, filterParams = null) {
  const sanitizedURL = DOMPurify.sanitize(url);

  if (filterParams) {
    return `${sanitizedURL}?${filterParams}`;
  }
  
  return sanitizedURL;
}

export default sanitizeURL;
