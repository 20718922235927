import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import Loader from './Loader';

const PillTabsContent = ({ tripCount, searchInProgress, trip, selectedFlightGroups, children }) => (
  <Fragment>
    {searchInProgress && <Loader />}
    <div className="results-table">
      <div className="results-table__title">
        {selectedFlightGroups[tripCount + 1] ? (
          <I18nText id="ibe.results.flight_selected" returnStringOnly={true} />
        ) : (
          <I18nText id="ibe.results.flight_select" returnStringOnly={true} />
        )}
        <span>{` ${trip.origin} - ${trip.destination}`}</span>
      </div>
      {children}
    </div>
  </Fragment>
);

PillTabsContent.propTypes = {
  tripCount: PropTypes.number.isRequired,
  searchInProgress: PropTypes.bool.isRequired,
  trip: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
  }).isRequired,
  selectedFlightGroups: PropTypes.shape({}).isRequired,
  children: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default PillTabsContent;
