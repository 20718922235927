import React from 'react';
import Icon from '@wtag/rcl-icon';
import { I18nText } from '@wtag/react-comp-lib';

const InformationMessage = () => (
  <div className="loading-information-card__content-info-message">
    <div className="loading-information-card__content-info-message-icon">
      <Icon name="loading" showBGColor={true} size="normal" color="success" />
    </div>
    <div className="loading-information-card__content-info-message-text">
      <div className="loading-information-card__content-info-message-text-title">
        <I18nText id="ibe.search_loading.search_loading_text.hold_tight" />
      </div>
      <div className="loading-information-card__content-info-message-text-data">
        <I18nText id="ibe.search_loading.search_loading_text.superior_search" />
      </div>
    </div>
  </div>
);

export default InformationMessage;
