/* eslint-disable import/no-webpack-loader-syntax */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import RclLink from '@wtag/rcl-link';
import Modal from 'sharedWebpack/Modal';
import FareBasisInfo from './FareBasisInfoContainer';

class FlightServicesFareRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  onModalOpen = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  onModalClose = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    return (
      <Fragment>
        {this.props.displayAsLink ? (
          <RclLink
            onClick={this.onModalOpen}
            modifier="tertiary"
            size="tiny"
            disabled={!this.props.fareRulePresent}
          >
            {I18n.t('components.ibe.flight.fare_rules')}
          </RclLink>
        ) : (
          <Button
            version="v2"
            size="small"
            label={I18n.t('components.ibe.flight.fare_rules')}
            onClick={this.onModalOpen}
            disabled={!this.props.fareRulePresent}
          />
        )}
        <Modal
          className="flight__fare-rules-modal"
          title={
            <div className="item__labels-buttons-fare-rules--modal-title">
              {I18n.t('components.ibe.flight.fare_rules')}
              <div onClick={this.onModalClose} onKeyDown={this.onModalClose} role="presentation">
                <Icon name="close" />
              </div>
            </div>
          }
          isModalVisible={this.state.isModalVisible}
          onOutsideClick={this.onModalClose}
        >
          <FareBasisInfo id={this.props.item.bookingAttributes.resultId} />
        </Modal>
      </Fragment>
    );
  }
}

FlightServicesFareRules.defaultProps = {
  resultId: null,
  displayAsLink: false,
};

FlightServicesFareRules.propTypes = {
  displayAsLink: PropTypes.bool,
  item: PropTypes.shape().isRequired,
  resultId: PropTypes.string,
  fareRulePresent: PropTypes.bool.isRequired,
};
export default FlightServicesFareRules;
