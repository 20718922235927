import { useState } from 'react';

const useCarFacilitiesItemWithSidePanel = () => {
  const [isFacilitiesSidePanelOpen, setIsFacilitiesSidePanelOpen] = useState(false);
  const [facilitiesSidePanelTitle, setFacilitiesSidePanelTitle] = useState(null);
  const [facilitiesSidePanelBody, setFacilitiesSidePanelBody] = useState(null);

  const handleOpenFacilitiesSidePanel = (currentSidePanelTitle, currentSidePanelBody) => {
    setFacilitiesSidePanelTitle(currentSidePanelTitle);
    setFacilitiesSidePanelBody(currentSidePanelBody);
    setIsFacilitiesSidePanelOpen(true);
  };

  const handleCloseFacilitiesSidePanel = () => setIsFacilitiesSidePanelOpen(false);

  return {
    isFacilitiesSidePanelOpen,
    facilitiesSidePanelTitle,
    facilitiesSidePanelBody,
    handleOpenFacilitiesSidePanel,
    handleCloseFacilitiesSidePanel,
  };
};

export default useCarFacilitiesItemWithSidePanel;
