import React from 'react';
import PropTypes from 'prop-types';
import BookingLabelWithHint from '../../flight/BookingLabelWithHint';
import FacilityItemLinks from '../FacilityItemLinks';

const SearchCarInfo = ({
  className,
  group,
  laymanMode,
  facilities,
  otherFacilities,
  onShowSidePanel,
}) => {
  const { payLater, labels, approvalRequired, policiesReasons, corporateDeal } = group;
  const hasOtherFacilities = otherFacilities && otherFacilities.length > 0;

  const getBookingStatusLabels = () => {
    const bookingStatusLabels = [];

    if (approvalRequired) {
      bookingStatusLabels.push({
        iconName: 'singlePolicy',
        iconColor: 'danger',
        text: I18n.t('components.ibe.search_result.approval_required'),
        hint: policiesReasons.approvalRequired,
      });
    }

    if (payLater && !laymanMode) {
      bookingStatusLabels.push({
        iconName: 'recurringPayment',
        iconColor: 'success',
        text: I18n.t('components.ibe.results.book_and_hold'),
        hint: I18n.t('components.ibe.results.book_and_hold_hint'),
      });
    }

    if (labels) {
      const { preferred, outOfPolicy } = labels;

      if (preferred) {
        bookingStatusLabels.push({
          iconName: 'singlePolicy',
          iconColor: 'danger',
          text: I18n.t('components.ibe.search_result.labels.preferred'),
          hint: policiesReasons.preferred,
        });
      }

      if (outOfPolicy) {
        bookingStatusLabels.push({
          iconName: 'singlePolicy',
          iconColor: 'danger',
          text: I18n.t('components.ibe.search_result.labels.out_of_policy'),
          hint: policiesReasons.outOfPolicy,
        });
      }

      if (corporateDeal) {
        bookingStatusLabels.push({
          iconName: 'tag',
          text: I18n.t('components.ibe.search_results.labels.corporate_deal'),
        });
      }
    }

    return bookingStatusLabels;
  };

  const bookingStatusLabels = getBookingStatusLabels();

  if (bookingStatusLabels.length === 0 && !facilities && !hasOtherFacilities) {
    return null;
  }

  return (
    <div className={className}>
      <div className="d-flex align-center justify-between wrap car__information-labels-container">
        <FacilityItemLinks
          className="d-flex align-center wrap car__information-labels"
          facilities={facilities}
          otherFacilities={otherFacilities}
          onShowSidePanel={onShowSidePanel}
        />
        {bookingStatusLabels.length > 0 && (
          <div className="d-flex align-center wrap car__information-labels car__information-labels">
            {bookingStatusLabels.map(({ iconName, iconColor, text, hint }) => (
              <BookingLabelWithHint
                iconName={iconName}
                iconColor={iconColor}
                text={text}
                hint={hint}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

SearchCarInfo.defaultProps = {
  className: null,
  laymanMode: false,
  onShowSidePanel: () => {},
};

SearchCarInfo.propTypes = {
  className: PropTypes.string,
  group: PropTypes.shape({
    cabinClasses: PropTypes.string.isRequired,
    bookingClasses: PropTypes.string.isRequired,
    overnight: PropTypes.bool.isRequired,
    policiesReasons: PropTypes.shape({
      preferred: PropTypes.string,
      outOfPolicy: PropTypes.string,
      approvalRequired: PropTypes.string,
    }),
    payLater: PropTypes.bool.isRequired,
    approvalRequired: PropTypes.bool.isRequired,
    labels: PropTypes.shape({
      preferred: PropTypes.bool,
      outOfPolicy: PropTypes.bool,
    }),
    corporateDeal: PropTypes.bool,
  }).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  otherFacilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  laymanMode: PropTypes.bool,
  onShowSidePanel: PropTypes.func,
};

export default SearchCarInfo;
