import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import moment from 'moment';
import { Toast } from '@wtag/react-comp-lib';
import { Tabs } from '@wtag/rcl-rtabs';
import socket from './socket';
import AppContext from './AppContext';

import RoomList from './rooms/RoomList';
import RoomWithContext from './rooms/ActiveRoom';
import ActiveAgencyRoom from './rooms/ActiveAgencyRoom';
import Notifications from './Notifications';
import AgencyNotificationsRoomWithContext from './rooms/AgencyNotificationsRoom';
import { AGENCY_NOTIFICATIONS } from './rooms/categories';
import './styles.scss';

const activeRoomSessionStorageKey = (isCustomer, currentUserId) =>
  `${isCustomer}-${currentUserId}-activeRoom`;

const NOTIFICATIONS_TAB_INDEX = 1;

class App extends Component {
  constructor(props) {
    super(props);
    this.toastRef = React.createRef();
    this.roomsIdentifier = `chat-rooms-${props.laymanMode}-${props.currentUserId}`;
    localStorage.removeItem(this.roomsIdentifier);
    this.toggleReconnectingStatus = this.toggleReconnectingStatus.bind(this);

    const client = socket(props.backendURL, this.toggleReconnectingStatus, props.authToken)
      .authenticate(props.authToken)
      .then(response => {
        this.setState({ connected: true });
        this.fetchRooms(response);

        return response;
      });

    this.state = {
      client,
      connected: false,
      rooms: [],
      activeChatRoom: null,
      showNotifications: false,
      globalUnreadCount: 0,
      globalMentionCount: 0,
      toggleChatPopup: false,
      selectedTabKey: 0,
      isLoading: true,
      isConnecting: false,
    };
    this.messageInputRef = React.createRef(null);
  }

  componentDidMount() {
    this.state.client.then(socketClient => {
      socketClient.onMessageReceived(message => {
        this.onNewMessageReceived(message);
      });
      socketClient.userStatusReceived(statusUpdate => {
        this.markUserOnlineIfNeeded(statusUpdate);
      });
    });

    const activeRoom = sessionStorage.getItem(
      activeRoomSessionStorageKey(this.props.laymanMode, this.props.currentUserId),
    );

    if (activeRoom) {
      this.updateActiveChatRoom(activeRoom);
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const notificationCountChanged =
      oldState.globalUnreadCount !== this.state.globalUnreadCount ||
      oldState.globalMentionCount !== this.state.globalMentionCount;

    if (this.props.isChatOpen) {
      this.focusMessageInput();
    }

    if (notificationCountChanged) {
      this.updateNotificationCount();
    }

    if (
      oldProps.focusToNotificationsTab !== this.props.focusToNotificationsTab &&
      this.props.focusToNotificationsTab &&
      !(this.state.activeChatRoom && this.state.activeChatRoom.type === AGENCY_NOTIFICATIONS)
    ) {
      this.toggleChat();
    }
  }

  onNewMessageReceived = message => {
    const isSender =
      message.senderId === this.props.currentUserId && message.isCustomer === this.props.laymanMode;

    const insideActiveRoom =
      this.state.activeChatRoom && message.roomId === this.state.activeChatRoom.uid;

    this.addNewMessage(message, insideActiveRoom, isSender);

    if (this.toastRef.current && !isSender && (!insideActiveRoom || !this.props.isChatOpen)) {
      const room = this.findRoom(message.roomId);
      this.toastRef.current.notify(
        <Notifications
          message={message}
          room={room}
          layman={this.props.laymanMode}
          markMessageAsRead={this.markMessageAsRead}
          onReply={this.onClickReplyButton}
        />,
      );
    }
  };

  onClickReplyButton = room => {
    if (!this.props.isChatOpen) {
      this.props.onOpenChat();
    }

    if (room.type === AGENCY_NOTIFICATIONS) {
      this.setState({ selectedTabKey: NOTIFICATIONS_TAB_INDEX });
    }

    this.openActiveChatRoom(room);
  };

  setSelectedTabKeyOnClick = tabIndex => {
    if (this.props.focusToNotificationsTab) {
      this.props.setFocusToNotificationsTab(false);
      this.setState({ selectedTabKey: tabIndex });
      return;
    }
    if (this.state.selectedTabKey !== tabIndex) {
      this.toggleChat();
      this.setState({ selectedTabKey: tabIndex });
    }
  };

  getNotifications = rooms => {
    let unreadCount = 0;
    let mentionCount = 0;
    rooms.forEach(room => {
      unreadCount += room.messageUnseen.length;
      mentionCount += room.messageMentions.length;
    });
    this.setState({
      globalUnreadCount: unreadCount,
      globalMentionCount: mentionCount,
    });
  };

  toggleReconnectingStatus = connecting => {
    this.setState({ isConnecting: connecting });

    if (!connecting) {
      this.state.client.then(client => {
        this.fetchRooms(client);
      });
    }
  };

  markMessageAsRead = message => {
    this.state.client.then(client => {
      client.markMessageSeen(message.id, this.props.currentUserId, this.props.laymanMode, () => {});

      message.messageMentions.forEach(mention => {
        client.removeMention(mention.id, () => {});
      });

      this.fetchRooms(client);
    });
  };

  updateActiveChatRoom(activeRoom) {
    this.setState({ activeChatRoom: JSON.parse(activeRoom) });
  }

  fetchRooms = response => {
    const startGettingRoomsAt = new Date();
    response.getChatRooms().then(rooms => {
      const gotAllRoomsAt = new Date();
      console.info(`Got room response in ${(gotAllRoomsAt - startGettingRoomsAt) / 1000}s`);
      const allRooms = this.flatRooms(rooms);
      allRooms.map(room => response.joinRoom(room.uid));
      this.getNotifications(allRooms);
      this.setState({ rooms: allRooms, showNotifications: true, isLoading: false });
    });
  };

  toggleChatPopup = () => {
    this.setState({
      toggleChatPopup: !this.state.toggleChatPopup,
    });
  };

  toggleChat = () => {
    sessionStorage.clear();
    this.setState({
      activeChatRoom: null,
      toggleChatPopup: false,
    });
  };

  updateTravelerOnlineStatus = (statusUpdate, chatRoomIndex, chatRoomUserIndex) => {
    const rooms = this.state.rooms.slice();
    const users = rooms[chatRoomIndex].users.slice();
    users[chatRoomUserIndex].isOnline = statusUpdate.online;
    rooms[chatRoomIndex].users = users;
    this.setState({
      rooms,
    });
  };

  markUserOnlineIfNeeded = statusUpdate => {
    const rooms = this.state.rooms.slice();
    rooms.forEach((room, index) => {
      if (room.uid === statusUpdate.roomUid) {
        const chatRoomUserIndex = room.users.findIndex(
          user => user.id === statusUpdate.userId && user.isCustomer === statusUpdate.isCustomer,
        );
        const chatRoomUser = room.users[chatRoomUserIndex];

        if (!chatRoomUser) {
          return;
        }

        if (statusUpdate.online !== chatRoomUser.isOnline) {
          this.updateTravelerOnlineStatus(statusUpdate, index, chatRoomUserIndex);
        }
      }
    });
    // Commented out because too many console.info in browser
    //  else {
    //   console.info(
    //     `Ignoring userStatus for room: ${
    //       statusUpdate.roomUid
    //     } because customer's status is unchanged`,
    //   );
    // }
  };

  openActiveChatRoom = room => {
    if (this.props.laymanMode && room.type === 'agency') {
      this.setState({ selectedTabKey: 0 });
    }
    this.markRoomAsRead(room);
    this.handleRoomClick(room);
  };

  markMessgeAsUnRead = (message, room, insideActiveRoom, isSender) => {
    const newRoom = Object.assign({}, room);
    const activeRoomCurrentlyOpen = insideActiveRoom && this.props.isChatOpen;

    if (!activeRoomCurrentlyOpen && !isSender) {
      const messageAttributes = { id: null, messageId: message.id, roomUid: message.roomId };
      const ownMentions = message.messageMentions
        .filter(
          mention =>
            mention.userId === this.props.currentUserId &&
            mention.isCustomer === this.props.laymanMode,
        )
        .map(mention => ({ ...messageAttributes, userId: mention.userId }));

      if (ownMentions.length > 0) {
        const messageMentions = [...newRoom.messageMentions, ...ownMentions];
        newRoom.messageMentions = messageMentions;
        this.setState({
          globalMentionCount: messageMentions.length,
        });
      } else {
        const messagesUnseen = [...newRoom.messageUnseen, { ...message, ...messageAttributes }];
        newRoom.messageUnseen = messagesUnseen;
        this.setState({
          globalUnreadCount: this.state.globalUnreadCount + 1,
        });
      }
    }
    return newRoom;
  };

  addNewMessage = (message, insideActiveRoom, isSender) => {
    const rooms = this.state.rooms.slice();
    const roomIndex = rooms.findIndex(room => room.uid === message.roomId);
    if (!rooms[roomIndex]) return;

    let newRoom = Object.assign({}, rooms[roomIndex]);
    newRoom.lastMessageTimestamp = String(moment(message.timestamp).valueOf());
    newRoom.lastMessage = message.text;

    newRoom = this.markMessgeAsUnRead(message, newRoom, insideActiveRoom, isSender);
    rooms[roomIndex] = newRoom;
    this.setState({ rooms });
  };

  findRoomIndex = (rooms, room) => {
    let expectedRoomIndex;
    rooms.forEach((currentRoom, index) => {
      if (currentRoom.uid === room.uid) {
        expectedRoomIndex = index;
      }
    });

    return expectedRoomIndex;
  };

  markRoomAsRead = room => {
    room.messageMentions.forEach(mentionMessage => {
      this.state.client.then(client => {
        if (mentionMessage.id) {
          client.removeMention(mentionMessage.id, () => {});
        } else {
          client.removeMentionInRoom(
            mentionMessage.roomUid,
            this.props.currentUserId,
            mentionMessage.messageId,
            () => {},
          );
        }
      });
    });

    room.messageUnseen.forEach(unseenMessage => {
      this.state.client.then(client => {
        if (unseenMessage.id) {
          client.removeUnseen(unseenMessage.id, () => {});
        } else {
          client.removeUnseenInRoom(
            unseenMessage.roomUid,
            this.props.currentUserId,
            unseenMessage.messageId,
            () => {},
          );
        }
      });
    });
  };

  handleRoomClick = room => {
    sessionStorage.setItem(
      activeRoomSessionStorageKey(this.props.laymanMode, this.props.currentUserId),
      JSON.stringify({
        lastMessageTimestamp: room.lastMessageTimestamp,
        title: room.title,
        users: room.users,
        type: room.type,
        uid: room.uid,
      }),
    );
    const rooms = this.state.rooms.slice();
    const roomIndex = this.findRoomIndex(rooms, room);
    const notificationToRemoveForUnread = rooms[roomIndex].messageUnseen.length;
    const notificationToRemoveForMention = rooms[roomIndex].messageMentions.length;
    rooms[roomIndex].messageUnseen = [];
    rooms[roomIndex].messageMentions = [];
    this.setState({
      rooms,
      activeChatRoom: rooms[roomIndex],
      globalUnreadCount: this.state.globalUnreadCount - notificationToRemoveForUnread,
      globalMentionCount: this.state.globalMentionCount - notificationToRemoveForMention,
    });
  };

  openAgencyChatRoom = room => {
    if (!(this.state.rooms.length > 0)) {
      return;
    }
    const updatedActiveRoom = this.findRoom(room.uid);
    this.openActiveChatRoom(updatedActiveRoom);
  };

  findRoom = roomId => this.state.rooms.find(room => room.uid === roomId);

  flatRooms = roomsCategories =>
    roomsCategories.map(roomCategory => roomCategory.rooms).reduce(this.roomMap, []);

  roomMap = (x, y) => x.concat(y);

  isUserOnline = room => {
    if (room.type === 'agencyNotifications') {
      // Check if any agent is online
      const agent = room.users.find(user => user.id !== this.props.currentUserId);
      return agent && agent.isOnline;
    }

    const receiver = room.users.find(user => {
      if (this.props.laymanMode) {
        return user.isCustomer && user.id !== this.props.currentUserId;
      }

      return user.isCustomer;
    });
    return receiver && receiver.isOnline;
  };

  showGlobalNotifications = () => {
    const count = this.state.globalMentionCount + this.state.globalUnreadCount;
    if (count <= 0) return null;
    let counterText = '...';
    if (this.props.laymanMode) {
      counterText = count > 9 ? '9+' : count;
    } else if (this.state.globalMentionCount > 0) {
      counterText = this.state.globalMentionCount > 9 ? '9+' : this.state.globalMentionCount;
    }
    return <span className="chat__notification">{counterText}</span>;
  };

  roomAvatarUrl = room => {
    if (room.type === AGENCY_NOTIFICATIONS) {
      return this.props.currentTenantLogoUrl;
    }
    const customer = room.users.find(user => {
      if (this.props.laymanMode) {
        return user.isCustomer && this.props.currentUserId !== user.id;
      }

      return user.isCustomer;
    });

    return customer && customer.avatarURL;
  };

  roomBasicInformation = room => {
    const titleInArrayFormat = room.title.split(' ');

    return {
      firstName: titleInArrayFormat[0],
      lastName: titleInArrayFormat[titleInArrayFormat.length - 1],
      avatarUrl: this.roomAvatarUrl(room),
    };
  };

  focusChatRoom = () => {
    if (!this.props.laymanMode) {
      return this.state.activeChatRoom.type !== AGENCY_NOTIFICATIONS;
    }

    return this.state.activeChatRoom.type !== 'agency';
  };

  focusMessageInput = () => {
    if (this.messageInputRef.current) {
      this.messageInputRef.current.focus();
    }
  };

  showChatTitle = () => {
    this.props.setShowChatTitle(!this.state.activeChatRoom ? true : !this.focusChatRoom());
  };

  updateNotificationCount = () => {
    this.props.setNotificationCount(this.state.globalUnreadCount + this.state.globalMentionCount);
  };

  selectedTabKey = () => {
    if (this.props.focusToNotificationsTab) {
      return NOTIFICATIONS_TAB_INDEX;
    }

    return this.state.selectedTabKey;
  };

  render() {
    const rooms = this.state.rooms;
    const { colors } = this.props;

    const getTabsForAgent = () => [
      {
        key: 0,
        title: I18n.t('chat.travellers'),
        getContent: () => (
          <RoomList
            activeRoom={this.state.activeChatRoom}
            rooms={rooms}
            handleRoomClick={this.openActiveChatRoom}
            isUserOnline={this.isUserOnline}
            toggleChat={this.toggleChat}
            showNotifications={this.state.showNotifications}
            isLoading={this.state.isLoading}
          />
        ),
      },
      {
        key: 1,
        title: I18n.t('chat.notifications'),
        getContent: () =>
          this.state.rooms.length > 0 ? (
            <AgencyNotificationsRoomWithContext
              rooms={this.state.rooms}
              isUserOnline={this.isUserOnline}
              handleRoomClick={this.openActiveChatRoom}
              room={this.state.activeChatRoom}
            />
          ) : null,
      },
    ];

    const getTabsForLayman = () => [
      {
        key: 0,
        title: I18n.t('chat.agency'),
        getContent: () =>
          this.state.rooms.length > 0 ? (
            <ActiveAgencyRoom
              rooms={this.state.rooms}
              isUserOnline={this.isUserOnline}
              handleRoomClick={this.openActiveChatRoom}
              room={this.state.activeChatRoom}
            />
          ) : null,
      },
      {
        key: 1,
        title: I18n.t('chat.contacts'),
        getContent: () => (
          <RoomList
            activeRoom={this.state.activeChatRoom}
            rooms={rooms}
            handleRoomClick={this.openActiveChatRoom}
            isUserOnline={this.isUserOnline}
            toggleChat={this.toggleChat}
            showNotifications={this.state.showNotifications}
          />
        ),
      },
    ];

    return (
      <AppContext.Provider
        value={{
          client: this.state.client,
          tenant: this.props.tenant,
          currentTenantLogoUrl: this.props.currentTenantLogoUrl,
          connected: this.state.connected,
          isConnecting: this.state.isConnecting,
          laymanMode: this.props.laymanMode,
          currentUserId: this.props.currentUserId,
          currentUserAvatar: this.props.currentUserAvatar,
          currentUserName: this.props.currentUserName,
          isAlien: this.props.isAlien,
          roomBasicInformation: this.roomBasicInformation,
          isChatOpen: this.props.isChatOpen,
          colors,
          messageInputRef: this.messageInputRef,
          focusMessageInput: this.focusMessageInput,
        }}
      >
        <Fragment>
          {createPortal(
            <Toast
              className={ClassNames('chat__toast', {
                'chat__toast--no-sidepanel': !this.props.isChatOpen,
              })}
              ref={this.toastRef}
              position={window.innerWidth > 900 ? 'bottom-center' : 'bottom-right'}
              autoClose={10000000}
              pauseOnHover={true}
              draggable={true}
            />,
            document.body,
          )}
          {this.showChatTitle()}
          {this.state.activeChatRoom && this.focusChatRoom() ? (
            <RoomWithContext
              toggleChat={this.toggleChat}
              isUserOnline={this.isUserOnline(this.state.activeChatRoom)}
              room={this.state.activeChatRoom}
              onRoomOpen={this.openAgencyChatRoom}
            />
          ) : (
            <Tabs
              controlled={true}
              items={this.props.laymanMode ? getTabsForLayman() : getTabsForAgent()}
              selectedTabKey={this.selectedTabKey()}
              onChange={this.setSelectedTabKeyOnClick}
            />
          )}
        </Fragment>
      </AppContext.Provider>
    );
  }
}

App.defaultProps = {
  isChatOpen: false,
  colors: {
    primaryColor: '#ebebeb',
    textColor: '#000000',
  },
  setShowChatTitle: () => {},
};

App.propTypes = {
  tenant: PropTypes.string.isRequired,
  currentTenantLogoUrl: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  authToken: PropTypes.string.isRequired,
  backendURL: PropTypes.string.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  isAlien: PropTypes.bool.isRequired,
  currentUserAvatar: PropTypes.string.isRequired,
  currentUserName: PropTypes.string.isRequired,
  isChatOpen: PropTypes.bool,
  colors: PropTypes.shape({
    primaryColor: PropTypes.string,
    textColor: PropTypes.string,
  }),
  onOpenChat: PropTypes.func.isRequired,
  setNotificationCount: PropTypes.func.isRequired,
  setShowChatTitle: PropTypes.func,
  focusToNotificationsTab: PropTypes.bool.isRequired,
  setFocusToNotificationsTab: PropTypes.func.isRequired,
};

export default App;
