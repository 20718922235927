import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { I18nText } from '@wtag/react-comp-lib';
import Popover from '@wtag/rcl-popover';
import JourneySummaryIndicatorWrapper from 'sharedWebpack/JourneySummaryIndicator/JourneySummaryIndicatorWrapper';
import Amount from 'sharedWebpack/Amount';
import FlightSeatSummary from './FlightSeatSummary';

const FlightBookedSeatSummary = ({
  trips,
  segmentMap,
  airportMap,
  seatMaps,
  bookedSeatMap,
  currency,
}) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const getTotalSeatFee = () => {
    let total = 0;

    seatMaps.forEach(seatMap => {
      segmentMap[`${seatMap.origin}-${seatMap.destination}`].seats.forEach(item => {
        if (bookedSeatMap[item.id] && bookedSeatMap[item.id].price) {
          total += Number(bookedSeatMap[item.id].price);
        }
      });
    });

    return total;
  };

  return (
    <div className="loading-information-card__header-info-address">
      <span>
        <JourneySummaryIndicatorWrapper trips={trips} airports={airportMap} />
      </span>
      <div className="flight-address-summary-seat-selected">
        <div className="price-section-title">
          <I18nText id="flight_seat_map.total_seat_fee" returnStringOnly={true} />
        </div>
        <div className="price-section-total">
          <Amount currency={currency} value={getTotalSeatFee()} />
        </div>

        <Popover
          className="flight-seat-summary__popover"
          isVisible={isPopoverVisible}
          onOutsideClick={() => setIsPopoverVisible(false)}
          content={seatMaps.map(seatMap => (
            <FlightSeatSummary
              key={seatMap.flightNumber}
              bookedSeatMap={bookedSeatMap}
              airportMap={airportMap}
              origin={seatMap.origin}
              segmentMap={segmentMap}
              destination={seatMap.destination}
              currency={currency}
            />
          ))}
          direction="bottom-right"
          size="medium"
        >
          <span
            onClick={() => setIsPopoverVisible(prevState => !prevState)}
            role="button"
            tabIndex={0}
            onKeyPress={() => setIsPopoverVisible(prevState => !prevState)}
          >
            <Icon name="neutralOutline" />
          </span>
        </Popover>
      </div>
    </div>
  );
};

FlightBookedSeatSummary.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      originCode: PropTypes.string.isRequired,
      destinationCode: PropTypes.string.isRequired,
      segments: PropTypes.arrayOf(
        PropTypes.shape({
          aircraftType: PropTypes.string.isRequired,
          destinationCode: PropTypes.string.isRequired,
          operatingCarrierCode: PropTypes.string.isRequired,
          operatingFlightNumber: PropTypes.string.isRequired,
          originCode: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  segment: PropTypes.shape({
    originCode: PropTypes.string.isRequired,
    operatingCarrierCode: PropTypes.string.isRequired,
    destinationCode: PropTypes.string.isRequired,
    departureLocaltime: PropTypes.string.isRequired,
  }).isRequired,
  seatMaps: PropTypes.arrayOf(
    PropTypes.shape({
      origin: PropTypes.string.isRequired,
      destination: PropTypes.string.isRequired,
      flightNumber: PropTypes.string.isRequired,
    }),
  ).isRequired,
  airportMap: PropTypes.shape({}).isRequired,
  seatMap: PropTypes.shape({ flightNumber: PropTypes.string.isRequired }).isRequired,
  bookedSeatMap: PropTypes.shape({
    travelerIndex: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
  segmentMap: PropTypes.shape({}).isRequired,
  currency: PropTypes.string.isRequired,
};

export default FlightBookedSeatSummary;
