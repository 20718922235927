import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import toSnakeCase from 'to-snake-case';
import Avatar from '@wtag/rcl-avatar';
import DateTime from 'sharedWebpack/DateTime';
import Tooltip from '@wtag/rcl-tooltip';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import SanitizedHTML from 'sharedWebpack/SanitizedHTML';
import AppContext from '../AppContext';
import { quoteMessageHeaderText } from '../SenderInformation';
import MarkDown from '../MarkDown';

const ChatMessage = forwardRef((props, ref) => {
  const {
    context,
    sender,
    senderId,
    senderName,
    currentUserId,
    markdown,
    timestamp,
    text,
    laymanMode,
    isCustomer,
    id,
    replyHandler,
    quotedMessage,
    roomUsers,
    tenant,
    focusToQuotedMessage,
    sendChatMessage,
    messageIndex,
    isSending,
    isFailedToSend,
    errors,
  } = props;
  const isMyQuoteMessage =
    quotedMessage.isCustomer === laymanMode &&
    parseInt(quotedMessage.senderId, 10) === currentUserId;
  const isMyMessage = currentUserId === senderId && laymanMode === isCustomer;
  const replyMessage = {
    senderId,
    senderName,
    text,
    messageId: id,
    isMyMessage,
    isCustomer,
  };
  const showQuotedReply = !!quotedMessage.id;
  const senderInfo = {
    id: senderId,
    isCustomer,
  };
  const currentUser = {
    id: currentUserId,
    isCustomer: laymanMode,
  };
  const quoteMessageTitleText =
    showQuotedReply &&
    quoteMessageHeaderText({
      roomUsers,
      quotedMessage,
      tenant,
      currentUserId,
      sender: senderInfo,
      currentUser,
    });

  const onQuoteButtonClickHandler = () => {
    replyHandler(replyMessage);
    context.focusMessageInput();
  };
  const onFocusToQuotedMessage = () => {
    focusToQuotedMessage(quotedMessage);
  };

  const sendMessageHandler = () => {
    const messagePayload = {
      message: text,
      quotedMessage,
      messageIndex,
    };

    sendChatMessage(messagePayload);
  };

  return (
    /* eslint-disable-next-line no-return-assign,no-param-reassign */
    <div ref={el => (ref.current[id] = el)}>
      {showQuotedReply && (
        <div
          className={classNames('chat-message--quotedMessage', {
            'chat-message--quotedMessage--my-quote-message': isMyQuoteMessage,
            'chat-message--quotedMessage--my-message': isMyMessage,
            'chat-message--quotedMessage--received-message': !isMyMessage,
          })}
          onClick={onFocusToQuotedMessage}
          onKeyPress={onFocusToQuotedMessage}
          role="presentation"
        >
          <div className="chat-message--quotedMessage-as-owner">
            <Icon className="chat-message--quotedMessage-as-owner-reply-icon" name="reply" />
            {quoteMessageTitleText}
          </div>
          <div className="chat-message--quotedMessage--text">
            <SanitizedHTML html={MarkDown(laymanMode).convert(quotedMessage.text)} />
          </div>
        </div>
      )}

      <div
        className={classNames('chat-message', {
          'chat-message--my-message': isMyMessage,
          'chat-message--received-message': !isMyMessage,
        })}
      >
        <div key="sender" className="chat-message__avatar">
          <Avatar
            firstName={sender.firstName}
            lastName={sender.lastName}
            src={sender.avatarUrl}
            size="tiny"
          />
        </div>
        {!isSending && (
          <div className="chat-message__quote">
            <IconButton
              icon={
                isFailedToSend ? (
                  <Icon name="refresh" showBGColor={true} />
                ) : (
                  <Icon name="reply" showBGColor={true} />
                )
              }
              label={isFailedToSend ? I18n.t('chat.retry') : I18n.t('chat.reply')}
              size="tiny"
              isIconOnly={true}
              onClick={isFailedToSend ? sendMessageHandler : onQuoteButtonClickHandler}
            />
          </div>
        )}
        <div className="chat-message__body">
          <div className="chat-message__body-text">
            {!isMyMessage && <div className="chat-message__body-text-author">{sender.name}</div>}
            <SanitizedHTML html={markdown.convert(text)} />
          </div>
          {isSending && (
            <div className="chat-message__body-message-status">{I18n.t('chat.sending')}</div>
          )}
          {isFailedToSend && (
            <div className="chat-message__body-message-status chat-message__body-message-status--failed">
              {errors && errors.length > 0 ? (
                <Tooltip
                  content={I18n.t(toSnakeCase(errors[0].key), {
                    maxLength: errors[0].characterLimit,
                    scope: 'chat.errors',
                  })}
                  type="danger"
                >
                  <Icon
                    className="chat-message__body-message-status-failed-icon"
                    name="invalidOutline"
                  />
                </Tooltip>
              ) : (
                <Icon
                  className="chat-message__body-message-status-failed-icon"
                  name="invalidOutline"
                />
              )}{' '}
              {I18n.t('chat.failed_to_send')}
            </div>
          )}
          {!isFailedToSend && !isSending ? (
            <div className="chat-message__body-time">
              <DateTime dateTime={timestamp} format="shortWithTime" displayInUTC={false} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
});

ChatMessage.defaultProps = {
  isSending: false,
  isFailedToSend: false,
  errors: [],
};

ChatMessage.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, characterLimit: PropTypes.string }),
  ),
  context: PropTypes.shape({
    connected: PropTypes.bool,
    currentUserAvatar: PropTypes.string,
    currentUserId: PropTypes.number,
    currentUserName: PropTypes.string,
    laymanMode: PropTypes.bool,
    tenant: PropTypes.string,
    messageInputRef: PropTypes.shape({}),
    focusMessageInput: PropTypes.func,
  }).isRequired,
  markdown: PropTypes.shape().isRequired,
  sender: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isCustomer: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
  timestamp: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  senderId: PropTypes.number.isRequired,
  senderName: PropTypes.string.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  roomUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      tenant: PropTypes.string,
    }),
  ).isRequired,
  replyHandler: PropTypes.func.isRequired,
  quotedMessage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    senderId: PropTypes.string.isRequired,
    isCustomer: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  tenant: PropTypes.string.isRequired,
  focusToQuotedMessage: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  isFailedToSend: PropTypes.bool,
  sendChatMessage: PropTypes.func.isRequired,
  messageIndex: PropTypes.number.isRequired,
};

const ChatMessageWithContext = forwardRef((props, ref) => (
  <AppContext.Consumer>
    {context => <ChatMessage context={context} {...props} ref={ref} />}
  </AppContext.Consumer>
));

export default ChatMessageWithContext;
