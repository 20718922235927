export const IATA_FORM_OF_PAYMENT = 'iata';
export const CASH_FORM_OF_PAYMENT = 'cash';
export const WALLET_FORM_OF_PAYMENT = 'welltravel_wallet';
export const WALLET_BALANCE = 'walletBalance';
export const CREDIT_CARD_FORM_OF_PAYMENT = 'credit_card';
export const NONE_FORM_OF_PAYMENT = 'none';
export const FORM_OF_PAYMENT_MULTIPLE = 'multipleFOP';
export const ADDITIONAL_CHARGES = 'additionalCharges';

export const FOP_ICON_MAPPER = {
  cash: 'cash',
  credit_card: 'mastercard',
  iata: 'iata',
};

export default FOP_ICON_MAPPER;
