import {
  UPDATE_PRIMARY_BUTTON_TEXT,
  TOGGLE_PRIMARY_BUTTON_DISABLED,
  updateOnPrimaryButtonClickCallback,
} from './primaryButton';
import { NEXT_BUTTON_LABEL_KEY } from '../helpers/authenticationButtonLabelOptions';

export const CLEAR_ERROR_ALERT_TOKEN = 'CLEAR_ERROR_ALERT_TOKEN';
export const CLEAR_SUCCESS_ALERT_TOKEN = 'CLEAR_SUCCESS_ALERT_TOKEN';

export const resetButtonAndAlertStates = () => dispatch => {
  dispatch({ type: CLEAR_ERROR_ALERT_TOKEN });
  dispatch({ type: CLEAR_SUCCESS_ALERT_TOKEN });
  dispatch({ type: UPDATE_PRIMARY_BUTTON_TEXT, label: NEXT_BUTTON_LABEL_KEY });
  dispatch({ type: TOGGLE_PRIMARY_BUTTON_DISABLED, isDisabled: true });
  dispatch(updateOnPrimaryButtonClickCallback(() => {}));
};
