import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { Header, Text } from '@wtag/rcl-typography';
import Button from '@wtag/rcl-button';
import { Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Icon from '@wtag/rcl-icon';
import LevelCards from '../LevelCards';

import '../styles.scss';

const Edit = ({ urls, levels, travelArrangerSettingId }) => {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [arranger, setArranger] = useState(null);

  const onUpdateLevel = async () => {
    const params = {
      travel_arranger_invitations: {
        level: selectedLevel,
      },
    };
    const { data } = await httpClient.patch(
      routes.public.travelArrangerSettings.arrangers.updateLevel({ travelArrangerSettingId }),
      params,
    );
    if (data.error === null) {
      window.location.replace(urls.list);
    }
  };

  const fetchArranger = async () => {
    const { data } = await httpClient.get(
      routes.public.travelArrangerSettings.arrangers.show({ travelArrangerSettingId }),
    );
    setArranger(data.travelArrangerSetting.arranger);
    setSelectedLevel(data.travelArrangerSetting.level);
  };

  useEffect(() => {
    fetchArranger();
  }, []);

  return (
    <div className="container travel-arranger__edit">
      <div className="col-12 col-bleed-x col-grid direction-row justify-space-between">
        <Header level={5} weight="medium">
          {I18n.t('public.components.travel_arrangement_settings.send_invitation.update_invite')}
        </Header>
        <div className="container__button-wrapper">
          <Link href={urls.list} type="button" size="small" modifier="default">
            <Icon name="close" className="link__icon" />
            <span className="link__label">
              {I18n.t('public.components.travel_arrangement_settings.send_invitation.label.cancel')}
            </span>
          </Link>
          <span className="send-invitation__actions send-invitation__actions--with-margin-left">
            <Button
              label={
                <span className="send-invitation__actions--button-label">
                  {I18n.t(
                    'public.components.travel_arrangement_settings.send_invitation.label.update',
                  )}
                </span>
              }
              size="small"
              version="v2"
              onClick={onUpdateLevel}
              padding="left"
              icon={<Icon className="send-invitation__actions--button-icon" name="check" />}
            />
          </span>
        </div>
      </div>
      <div className="col-12 col-grid align-center send-invitation__container">
        <div className="col-12">
          <Text level={2} weight="medium">
            {I18n.t(
              'public.components.travel_arrangement_settings.send_invitation.explanation.email',
            )}
          </Text>
        </div>
        <br />
        <div className="col-12 col-bleed-y send-invitation__avatar">
          {arranger && (
            <Avatar
              size="tiny"
              showName={true}
              firstName={arranger.firstName}
              middleName={arranger.middleName || ''}
              lastName={arranger.lastName}
              src={arranger.avatarUrl}
              descriptionText={arranger.email}
            />
          )}
        </div>
        <br />
        <div className="col-12">
          <Text level={3} weight="medium">
            {I18n.t(
              'public.components.travel_arrangement_settings.send_invitation.explanation.modify',
            )}
          </Text>
        </div>
        <div className="grid justify-center">
          <LevelCards
            levels={levels}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
          />
        </div>
        <div className="col-12">
          {selectedLevel && (
            <Text level={3} weight="light" className="send-invitation__explanation">
              <span className="send-invitation__explanation--with-padding-right">
                {I18n.t(selectedLevel, {
                  scope: 'public.components.travel_arrangement_settings.send_invitation.level',
                })}
              </span>
              {I18n.t(selectedLevel, {
                scope: 'public.travel_arrangers.level_description',
              })}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

Edit.propTypes = {
  urls: PropTypes.shape({
    list: PropTypes.string.isRequired,
  }).isRequired,
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
  travelArrangerSettingId: PropTypes.number.isRequired,
};

export default Edit;
