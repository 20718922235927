import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';
import Card from '@wtag/rcl-card';
import accountTypeAvailable from '../../lib/accountTypeAvailable';
import AddOnService from './AddOnService';
import './styles.scss';

class AddButtons extends React.Component {
  shouldEnableFor = type =>
    !this.context.laymanMode || accountTypeAvailable(this.context.availableChannels, type);

  flightEnabled = () => this.shouldEnableFor('flights');
  hotelEnabled = () => this.shouldEnableFor('hotels');
  carEnabled = () => this.shouldEnableFor('cars');

  render() {
    return (
      <div
        className={classNames('col-12 col-grid col-bleed add-buttons', {
          'add-buttons--disabled': this.props.isCartLocked,
        })}
      >
        <Card
          version="v2"
          size="full"
          title={<I18nText id="ibe.cart_buttons.header" returnStringOnly={true} />}
        >
          <div className="add-on-service-wrapper">
            {this.flightEnabled() && (
              <AddOnService
                linkTo="/flights"
                iconName="aeroplane"
                title={I18n.t('components.ibe.cart_buttons.flight')}
              />
            )}
            {this.hotelEnabled() && (
              <AddOnService
                linkTo="/hotels"
                iconName="hotelRoom"
                title={I18n.t('components.ibe.cart_buttons.hotel')}
              />
            )}
            {this.carEnabled() && (
              <AddOnService
                linkTo="/cars"
                iconName="car"
                title={I18n.t('components.ibe.cart_buttons.car')}
              />
            )}
            {this.props.hasStaticProducts && (
              <AddOnService
                linkTo="/static"
                iconName="equipment"
                title={I18n.t('components.ibe.cart_buttons.others')}
              />
            )}
          </div>
        </Card>
      </div>
    );
  }
}
AddButtons.defaultProps = {
  hasStaticProducts: false,
  isCartLocked: false,
};
AddButtons.propTypes = {
  hasStaticProducts: PropTypes.bool,
  isCartLocked: PropTypes.bool,
};
AddButtons.contextTypes = {
  laymanMode: PropTypes.bool,
  availableChannels: PropTypes.shape(),
};

export default AddButtons;
