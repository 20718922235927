import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AccordionItem } from '@wtag/rcl-accordion';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import HotelRoomInfo from './HotelRoomInfo';
import HotelRoomInfoCompactView from './HotelRoomInfoCompactView';

import './styles.scss';

const HotelItem = props => {
  const {
    journeyElementId,
    journeyElementType,
    fetchedJourneyElement,
    payLater,
    laymanMode,
    item,
  } = props;

  const [journeyElement, setJourneyElement] = useState(fetchedJourneyElement);

  const [activeAccordionItem, setActiveAccordionItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAccordionItemClick = () => {
    setActiveAccordionItem(previousState => !previousState);
  };

  const fetchJourneyElement = async () => {
    setIsLoading(true);
    const { data } = await httpClient.get(
      routes.api.fetchJourneyElement({
        id: journeyElementId,
        type: journeyElementType,
      }),
    );

    setJourneyElement(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (journeyElementId && !fetchedJourneyElement) {
      fetchJourneyElement();
    }
  }, [journeyElementId, journeyElementType]);

  return (
    <Fragment>
      {isLoading ? (
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={true} />
      ) : (
        journeyElement &&
        journeyElement.id &&
        journeyElement.rooms.map(room => {
          const { quantity, name } = room;
          const { checkIn, checkOut, durationInDays } = journeyElement;

          return (
            <AccordionItem
              className="hotel-item__accordion"
              onClick={handleAccordionItemClick}
              isActive={activeAccordionItem}
              header={
                <HotelRoomInfoCompactView
                  quantity={quantity}
                  checkIn={checkIn}
                  checkOut={checkOut}
                  nightCount={durationInDays}
                  name={name}
                />
              }
            >
              <HotelRoomInfo
                key={room.id}
                duration={durationInDays}
                room={room}
                payLater={payLater}
                laymanMode={laymanMode}
                journeyElement={journeyElement}
                preferred={item && item.preferred}
                checkIn={checkIn}
                checkOut={checkOut}
              />
            </AccordionItem>
          );
        })
      )}
    </Fragment>
  );
};
HotelItem.defaultProps = {
  fetchedJourneyElement: null,
  payLater: false,
  item: null,
};

HotelItem.propTypes = {
  journeyElementId: PropTypes.number.isRequired,
  journeyElementType: PropTypes.string.isRequired,
  fetchedJourneyElement: PropTypes.shape({}),
  payLater: PropTypes.bool,
  laymanMode: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    startsAt: PropTypes.string.isRequired,
    endsAt: PropTypes.string.isRequired,
    preferred: PropTypes.bool.isRequired,
  }),
};

export default HotelItem;
