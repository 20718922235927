const getMileageInfo = mileage => {
  const mileageInfo = mileage
    .map(({ amount, currency: mileageCurrency, modifier, packageSize, type }, index) => {
      const mappedModifer = I18n.t(modifier, {
        scope: 'components.ibe.search_results.car.mileage.modifiers',
      });

      const mappedAmount = amount
        ? `${mileageCurrency} ${amount}`
        : I18n.t('components.ibe.car.facilities.amount_included');

      const typeMapper = {
        unlimited: I18n.t('components.ibe.search_results.car.mileage.types.unlimited'),
        free_miles: `${packageSize} ${I18n.t(
          'components.ibe.search_results.car.mileage.types.free_miles',
        )} ${mappedModifer}`,
        free_kilometers: `${packageSize} ${I18n.t(
          'components.ibe.search_results.car.mileage.types.free_kilometers',
        )} ${mappedModifer}`,
        per_mile: `${mappedAmount} ${I18n.t(
          'components.ibe.search_results.car.mileage.types.per_mile',
        )}`,
        per_kilometer: `${mappedAmount} ${I18n.t(
          'components.ibe.search_results.car.mileage.types.per_kilometer',
        )}`,
      };

      return index > 0
        ? `(${typeMapper[type]} ${I18n.t('components.ibe.search_results.car.mileage.afterward')})`
        : typeMapper[type];
    })
    .join(', ');

  return mileageInfo;
};

export default getMileageInfo;
