const TYPE_YES = 'yes';
const TYPE_NO = 'no';

const getAllRefundableOptions = (refundable = []) =>
  refundable.map(type => {
    let name = I18n.t('components.ibe.results.filters.refundable.unknown');

    if (type === TYPE_YES) {
      name = I18n.t('components.ibe.results.filters.refundable.available');
    } else if (type === TYPE_NO) {
      name = I18n.t('components.ibe.results.filters.refundable.unavailable');
    }

    return {
      name,
      identifier: type,
    };
  });

export default getAllRefundableOptions;
