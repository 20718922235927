const cabinClassOptions = () => [
  { label: I18n.t('cabin_classes.economy'), value: 'economy' },
  { label: I18n.t('cabin_classes.premium_economy'), value: 'premium_economy' },
  { label: I18n.t('cabin_classes.business'), value: 'business' },
  { label: I18n.t('cabin_classes.first'), value: 'first' },
];

const bookingClassOptionsByRule = {
  first: [
    { label: 'A', value: 'A' },
    { label: 'F', value: 'F' },
  ],
  business: [
    { label: 'J', value: 'J' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'I', value: 'I' },
    { label: 'Z', value: 'Z' },
  ],
  premium_economy: [
    { label: 'W', value: 'W' },
    { label: 'E', value: 'E' },
  ],
  economy: [
    { label: 'B', value: 'B' },
    { label: 'E', value: 'E' },
    { label: 'H', value: 'H' },
    { label: 'K', value: 'K' },
    { label: 'L', value: 'L' },
    { label: 'M', value: 'M' },
    { label: 'N', value: 'N' },
    { label: 'O', value: 'O' },
    { label: 'Q', value: 'Q' },
    { label: 'S', value: 'S' },
    { label: 'T', value: 'T' },
    { label: 'U', value: 'U' },
    { label: 'V', value: 'V' },
    { label: 'W', value: 'W' },
    { label: 'X', value: 'X' },
    { label: 'Y', value: 'Y' },
  ],
};

const preferredAllianceOptions = () => [
  { label: I18n.t('alliance_preference.star_alliance'), value: 'star_alliance' },
  { label: I18n.t('alliance_preference.sky_team'), value: 'sky_team' },
  { label: I18n.t('alliance_preference.one_world'), value: 'one_world' },
];

const filterTypeOptions = () => [
  { value: 'preferred', label: I18n.t('components.ibe.search_form.flight.label.preferred') },
  { value: 'exclusive', label: I18n.t('components.ibe.search_form.flight.label.exclusive') },
];

export {
  filterTypeOptions,
  preferredAllianceOptions,
  bookingClassOptionsByRule,
  cabinClassOptions,
};
