import React from 'react';
import { AnimatedProgressbar, I18nText } from '@wtag/react-comp-lib';

const AnimationBar = () => (
  <div className="loading-information-card__animation-bar">
    <div className="loading-information-card__animation-bar-text">
      <I18nText id="ibe.search_loading.hotel.searching_for_best_options" />
    </div>
    <AnimatedProgressbar
      size="small"
      bgColor="success"
      initialyCompleted={95}
      animationSpeed={250000}
      textMessages={[
        I18n.t('components.ibe.search_loading.search_loading_text.request_car'),
        I18n.t('components.ibe.search_loading.search_loading_text.check'),
        I18n.t('components.ibe.search_loading.search_loading_text.fetch'),
        I18n.t('components.ibe.search_loading.search_loading_text.prepare'),
      ]}
      completedMessage="Completed"
    />
  </div>
);

export default AnimationBar;
