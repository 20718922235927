import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import { TableData, TableRow } from '@wtag/rcl-table';
import classNames from 'classnames';
import './styles.scss';

const TablePlaceholder = ({
  numberOfColumn,
  numberOfRows,
  withoutRclTable,
  removeTopPadding,
  removeContentPadding,
  removeBoxShadow,
}) => (
  <Fragment>
    {!withoutRclTable && (
      <TableRow
        className={classNames('table-data-loader', {
          'table-data-loader--remove-top-padding': removeTopPadding,
          'table-data-loader--remove-content-padding': removeContentPadding,
          'table-data-loader--remove-box-shadow': removeBoxShadow,
        })}
      >
        {Array(numberOfColumn)
          .fill(0)
          .map((data, arrayIndex) => (
            <TableData key={(data + arrayIndex).toString()}>
              <ContentLoaderPlaceholder
                numberOfLines={numberOfRows}
                showBackground={false}
                isRounded={false}
              />
            </TableData>
          ))}
      </TableRow>
    )}
    {withoutRclTable && (
      <TableRow
        className={classNames('table-data-loader', {
          'table-data-loader--remove-top-padding': removeTopPadding,
          'table-data-loader--remove-content-padding': removeContentPadding,
          'table-data-loader--remove-box-shadow': removeBoxShadow,
        })}
      >
        {Array(numberOfColumn)
          .fill(0)
          .map((data, arrayIndex) => (
            <TableData key={(data + arrayIndex).toString()}>
              <ContentLoaderPlaceholder
                numberOfLines={numberOfRows}
                showBackground={false}
                isRounded={false}
              />
            </TableData>
          ))}
      </TableRow>
    )}
  </Fragment>
);

TablePlaceholder.defaultProps = {
  numberOfColumn: 1,
  numberOfRows: 1,
  withoutRclTable: false,
  removeTopPadding: true,
  removeContentPadding: true,
  removeBoxShadow: true,
};

TablePlaceholder.propTypes = {
  numberOfColumn: PropTypes.number,
  numberOfRows: PropTypes.number,
  withoutRclTable: PropTypes.bool,
  removeTopPadding: PropTypes.bool,
  removeContentPadding: PropTypes.bool,
  removeBoxShadow: PropTypes.bool,
};

export default TablePlaceholder;
