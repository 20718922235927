import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import Icon from '@wtag/rcl-icon';
import Tooltip from '@wtag/rcl-tooltip';

import './styles.scss';

const CustomToolbar = React.forwardRef(({ error, hideToolbars, disabled }, ref) => (
  <div ref={ref} id="editor-toolbar" className="ql-toolbar">
    {!hideToolbars.bold && <button className="ql-bold" type="button" disabled={disabled} />}
    {!hideToolbars.italic && <button className="ql-italic" type="button" disabled={disabled} />}
    {!hideToolbars.underline && (
      <button className="ql-underline" type="button" disabled={disabled} />
    )}
    {!hideToolbars.strike && <button className="ql-strike" type="button" disabled={disabled} />}
    {!hideToolbars.link && <button className="ql-link" type="button" disabled={disabled} />}
    {!hideToolbars.orderedList && (
      <button className="ql-list" value="ordered" type="button" disabled={disabled} />
    )}
    {!hideToolbars.bulletList && (
      <button className="ql-list" value="bullet" type="button" disabled={disabled} />
    )}
    <span className="ql-toolbar-error">
      {error && (
        <Tooltip
          className="texeditor-ql-toolbar-error-tooltip"
          content={error}
          position="bottom-right"
          type="danger"
          size="small"
        >
          <Icon name="invalid" color="danger" />
        </Tooltip>
      )}
    </span>
  </div>
));

CustomToolbar.defaultProps = {
  error: null,
  disabled: false,
  hideToolbars: {
    bold: false,
    italic: false,
    underline: false,
    strike: false,
    link: false,
    orderedList: false,
    bulletList: false,
  },
};

CustomToolbar.propTypes = {
  error: PropTypes.string,
  disabled: PropTypes.bool,
  hideToolbars: PropTypes.shape({
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    strike: PropTypes.bool,
    link: PropTypes.bool,
    orderedList: PropTypes.bool,
    bulletList: PropTypes.bool,
  }),
};

const TextEditor = ({
  value,
  onChange,
  placeholder,
  label,
  error,
  isRequired,
  hideToolbars,
  disabled,
  preview,
}) => {
  const toolbar = useRef(null);
  const [container, setContainer] = useState(null);

  useEffect(() => {
    if (!toolbar.current) {
      return;
    }

    setContainer(`#${toolbar.current.id}`);
  }, [toolbar]);

  return (
    <div className="text-editor">
      <div className={classNames('text-editor__label', { 'required-field': isRequired })}>
        {label}
      </div>
      <div
        className={classNames('text-editor__container', {
          'text-editor__container--error': error,
          'is-disabled': disabled,
          'is-preview': preview,
        })}
      >
        <CustomToolbar
          ref={toolbar}
          error={error}
          hideToolbars={hideToolbars}
          disabled={disabled}
        />

        {container ? (
          <ReactQuill
            modules={{
              toolbar: preview ? false : { container },
              syntax: false,
            }}
            theme="snow"
            formats={['bold', 'italic', 'strike', 'underline', 'link', 'list', 'bullet']}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={disabled || preview}
          />
        ) : null}
      </div>
    </div>
  );
};

TextEditor.defaultProps = {
  placeholder: null,
  isRequired: null,
  error: null,
  hideToolbars: {},
  disabled: false,
  preview: false,
};

TextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  preview: PropTypes.bool,
  error: PropTypes.string,
  hideToolbars: PropTypes.shape(),
};

export default TextEditor;
