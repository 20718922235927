import { useEffect, useState, useContext, useMemo } from 'react';
import MatrixContext from '../../contexts/MatrixContext';

const TIMER_DELAY_MILI_SECONDS = 100;

const useSliderScroll = (noHorizontalScroll, scrollSliderToLeftBy, sliderContainerRef) => {
  const [horizontalScrollPosition, setHorizontalScrollPosition] = useState(scrollSliderToLeftBy);
  const { setScrollSliderToLeftBy } = useContext(MatrixContext);
  const shouldRegisterScrollHandler = useMemo(() => !noHorizontalScroll && sliderContainerRef, [
    noHorizontalScroll,
    sliderContainerRef,
  ]);

  useEffect(() => {
    const horizontalScrollListener = event => {
      setHorizontalScrollPosition(event.target.scrollLeft);
    };

    if (shouldRegisterScrollHandler) {
      sliderContainerRef.addEventListener('scroll', horizontalScrollListener);
    }

    return () => {
      if (shouldRegisterScrollHandler) {
        sliderContainerRef.removeEventListener('scroll', horizontalScrollListener);
      }
    };
  }, [shouldRegisterScrollHandler]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (setScrollSliderToLeftBy) {
        setScrollSliderToLeftBy(horizontalScrollPosition);
      }
    }, TIMER_DELAY_MILI_SECONDS);

    return () => clearTimeout(timer);
  }, [horizontalScrollPosition]);

  return {
    horizontalScrollPosition,
    setHorizontalScrollPosition,
  };
};

export default useSliderScroll;
