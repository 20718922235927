import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckBox, Spinner } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import SelectBox from '@wtag/rcl-select-box';
import Card from '@wtag/rcl-card';
import ChooseFile from 'sharedWebpack/ChooseFile';
import '../styles.scss';

const DocumentForm = props => {
  const {
    attachmentId,
    orderId,
    performAssignedAction,
    attachmentTypes,
    redirectUrl,
    layman,
    fetchAction,
  } = props;
  const attachmentOptions = attachmentTypes.map(attachmentOption => ({
    label: I18n.t(attachmentOption, { scope: 'simple_form.options.attachment.attachment_type' }),
    value: attachmentOption,
  }));

  const [title, setTitle] = useState('');
  const [visible, setVisible] = useState(false);
  const [attachmentType, setAttachmentType] = useState(attachmentOptions[0]);
  const [description, setDescription] = useState('');
  const [error, setError] = useState({});
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const selectFile = selectedFile => {
    setFileName(selectedFile.name);
    setFile(selectedFile);
  };

  const fetchDocument = () => {
    setIsLoading(true);
    fetchAction().then(data => {
      const attachment = data.attachment;

      setTitle(attachment.title);
      setFileName(attachment.fileName);
      setDescription(attachment.description);
      setAttachmentType({
        label: I18n.t(attachment.attachmentType, {
          scope: 'simple_form.options.attachment.attachment_type',
        }),
        value: attachment.attachmentType,
      });
      setVisible(attachment.visible);
      setIsLoading(false);
    });
  };

  const onSave = () => {
    setError({});
    const formData = new FormData();
    if (file) {
      formData.append('attachment[file]', file);
    }
    if (description != null) {
      formData.append('attachment[description]', description);
    }
    formData.append('attachment[title]', title);
    formData.append('attachment[attachment_type]', attachmentType.value);
    formData.append('attachment[order_id]', orderId);
    formData.append('attachment[visible_to_customer]', visible);

    performAssignedAction(formData).catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });
  };

  React.useEffect(() => {
    if (attachmentId) {
      fetchDocument();
    }
  }, []);

  return (
    <div className="offset-xlg-2 offset-lg-1 col-xlg-8 col-lg-10 document-form">
      <div className="document-form__header">
        {attachmentId
          ? I18n.t('admin.components.attachments.update_document')
          : I18n.t('admin.components.attachments.create_new_document')}
      </div>
      <Card version="v2" size="full">
        <div className="document-form grid justify-center">
          {isLoading ? (
            <div className="document-form__spinner">
              <Spinner size="huge" bgColor="neutral" />
            </div>
          ) : (
            <div className="col-12">
              <div className="document-form__fields">
                <Input
                  label={I18n.t('admin.components.attachments.title')}
                  placeholder={I18n.t('admin.components.attachments.placeholder.title')}
                  onChange={value => setTitle(value)}
                  value={title}
                  size="tiny"
                  required={true}
                  error={error.title}
                  touched={!!error.title}
                />
              </div>
              <div className="document-form__fields">
                <ChooseFile
                  errorMsg={error.file}
                  fileName={fileName}
                  selectFile={value => selectFile(value)}
                />
              </div>
              <div className="document-form__fields document-form__fields-description">
                <Input
                  label={I18n.t('admin.components.attachments.description')}
                  placeholder={I18n.t('admin.components.attachments.placeholder.description')}
                  onChange={value => setDescription(value)}
                  value={description}
                  size="tiny"
                />
              </div>
              <div className="document-form__fields">
                <div className="col-12 col-grid align-end col-bleed direction-row document-form__fields-row">
                  <div className="col-6 col-bleed document-form__fields-row-select-box">
                    <SelectBox
                      label={I18n.t('admin.components.attachments.attachment_type')}
                      options={attachmentOptions}
                      size="tiny"
                      width="full"
                      onChange={selectedOption => setAttachmentType(selectedOption)}
                      value={attachmentType}
                      isClearable={false}
                      required={true}
                    />
                  </div>
                  <div className="col-6 col-bleed document-form__fields-row-checkbox">
                    {!layman && (
                      <CheckBox
                        name="done"
                        dark={true}
                        onChange={() => setVisible(value => !value)}
                        checked={visible}
                        label={I18n.t('admin.components.attachments.visible_to_traveller')}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="document-form__action-buttons">
                {attachmentId ? (
                  <Button
                    label={I18n.t('admin.components.attachments.update_document')}
                    version="v2"
                    type="primary"
                    size="small"
                    onClick={onSave}
                  />
                ) : (
                  <Button
                    label={I18n.t('admin.components.attachments.add_document')}
                    version="v2"
                    type="primary"
                    onClick={onSave}
                    size="small"
                  />
                )}
                <Button
                  onClick={() => window.location.assign(redirectUrl)}
                  label={I18n.t('admin.shared.action.cancel')}
                  version="v2"
                  size="small"
                />
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

DocumentForm.defaultProps = {
  attachmentId: null,
  layman: false,
  fetchAction: () => {},
};

DocumentForm.propTypes = {
  performAssignedAction: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  attachmentId: PropTypes.number,
  attachmentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  redirectUrl: PropTypes.string.isRequired,
  layman: PropTypes.bool,
  fetchAction: PropTypes.func,
};

export default DocumentForm;
