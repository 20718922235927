import React from 'react';
import PropTypes from 'prop-types';

class Place extends React.Component {
  componentDidMount() {
    if (!this.place()) {
      this.props.fetchPlaceDetails(this.props.id);
    }
  }

  place = () => this.props.places[this.props.id];

  render() {
    let name = this.props.id;
    if (this.place()) {
      name = (
        <span className="place-name">
          {this.place().name}, {this.place().countryName}
        </span>
      );
    }

    return <span className="place">{name}</span>;
  }
}

Place.defaultProps = {
  places: null,
};

Place.propTypes = {
  id: PropTypes.number.isRequired,

  places: PropTypes.shape(),
  fetchPlaceDetails: PropTypes.func.isRequired,
};

export default Place;
