import { connect } from 'react-redux';
import { addSeatToCart } from '../../../../actions/flight';

import SeatMapSeat from './SeatMapSeat';

const mapStateToProps = (state, props) => {
  const seatsInCart = [];
  state.common.cart.items.forEach(item =>
    item.subItems
      .filter(subItem => subItem.type === 'seat')
      .forEach(seat =>
        seatsInCart.push({
          id: seat.bookingAttributes.seatId,
          mainItemId: seat.mainItemId,
          travelerIndex: seat.bookingAttributes.travelerIndex,
          itemTitle: seat.itemTitle,
        }),
      ),
  );
  const seatInfo = seatsInCart.find(seat => seat.id === props.id);

  return {
    booked: !!seatInfo,
    bookedFor: seatInfo && seatInfo.travelerIndex,
    mainItemId: seatInfo && seatInfo.mainItemId,
    seatsInCart,
    isUpdating:
      state.flights.flightSeatMap.isUpdating && state.flights.flightSeatMap.seatId !== props.id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectSeat: (travelerIndex, callbackParams) =>
    dispatch(addSeatToCart(travelerIndex, ownProps.id, callbackParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeatMapSeat);
