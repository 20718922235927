import React from 'react';
import PropTypes from 'prop-types';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import moment from 'moment';
import ChooseFile from 'sharedWebpack/ChooseFile';
import DatePickerWithFocusedStateWrapper from 'sharedWebpack/DatePickerWithFocusedStateWrapper';

const VisaNumberForm = props => {
  const {
    countryOptions,
    visaCategories,
    number,
    setNumber,
    category,
    setCategory,
    country,
    setCountry,
    issueDate,
    setIssueDate,
    expireDate,
    setExpireDate,
    visaFileName,
    visaFileType,
    visaFileUploadPreviewUrl,
    setError,
    setVisaFileName,
    setVisaFileType,
    setVisaFile,
    setVisaFileUploadPreviewUrl,
    error,
  } = props;

  const visaCategoryOptions = visaCategories.map(visaCategory => ({
    value: visaCategory,
    label: I18n.t(visaCategory, {
      scope: 'simple_form.options.person.visa_numbers.category',
    }),
  }));

  const findSelectBoxContent = (options, value) => {
    const selectedOption = options.filter(option => value === option.value)[0];
    if (selectedOption) {
      return {
        value: selectedOption.value,
        label: selectedOption.label,
      };
    }
    return null;
  };

  const handleVisaCategory = selectedOption => {
    if (error) {
      setError({ visaNumbersFile: false });
    }
    setCategory(selectedOption ? selectedOption.value : '');
  };

  const handleSelectedFileStates = selectedFile => {
    setVisaFileType(selectedFile.type);
    setVisaFileName(selectedFile.name);
    setVisaFile(selectedFile);
    setVisaFileUploadPreviewUrl(URL.createObjectURL(selectedFile));
  };

  const clearSelectedFileStates = () => {
    setVisaFileName('');
    setVisaFileType('');
    setVisaFile(null);
    setVisaFileUploadPreviewUrl(null);
  };

  const supportedFileTypes = /(image|application)\/(png|jpg|jpeg|gif|webp|pdf)/i;
  const selectFile = selectedFile => {
    if (selectedFile) {
      if (!selectedFile.type.match(supportedFileTypes)) {
        setError({
          visaNumbersFile: I18n.t('admin.visa_number.file_type_error_log'),
        });
        clearSelectedFileStates();
      } else {
        if (error) {
          setError({ visaNumbersFile: false });
        }
        handleSelectedFileStates(selectedFile);
      }
    }
  };

  const removeFile = () => {
    if (error) {
      setError({ identificationDocumentsFile: false });
    }
    clearSelectedFileStates();
  };

  return (
    <div className="col-12 col-bleed traveller-edit-identification-document-form traveller-edit-identification-document-form--with-no-top-bottom-margin">
      {country && (
        <div className="traveller-edit-identification-document-form__sub-header">
          {I18n.t('admin.components.orders.left_panel.visa_numbers.action.edit')}
        </div>
      )}
      <div className="grid">
        <div className="col-lg-4 col-sm-6">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.travellers.edit.visa_numbers.placeholders.country',
            )}
            label={I18n.t('admin.components.travellers.edit.visa_numbers.label.country')}
            width="full"
            required={true}
            options={countryOptions}
            value={findSelectBoxContent(countryOptions, country)}
            onChange={selectedOption => setCountry(selectedOption ? selectedOption.value : '')}
            errorMsg={error.country}
            isClearable={false}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            size="tiny"
            placeholder={I18n.t(
              'admin.components.travellers.edit.visa_numbers.placeholders.number',
            )}
            label={I18n.t('admin.components.travellers.edit.visa_numbers.label.number')}
            required={true}
            touched={!!error}
            error={error.number}
            value={number}
            onChange={value => setNumber(value)}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <DatePickerWithFocusedStateWrapper
            placeholder={I18n.t(
              'admin.components.travellers.edit.visa_numbers.placeholders.issue_date',
            )}
            label={I18n.t('admin.components.travellers.edit.visa_numbers.label.issue_date')}
            size="tiny"
            fullWidth={true}
            required={true}
            date={issueDate}
            locale={moment().locale()}
            id={Math.random()}
            onChange={value => value && setIssueDate(moment(value).format('YYYY-MM-DD'))}
            futureYearsCount={0}
            pastYearsCount={100}
            maxDate={moment()}
            error={error.issueDate}
            enablePastDates={true}
            disableFutureDates={true}
            hideNavButtons={false}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <DatePickerWithFocusedStateWrapper
            placeholder={I18n.t(
              'admin.components.travellers.edit.visa_numbers.placeholders.expire_date',
            )}
            label={I18n.t('admin.components.travellers.edit.visa_numbers.label.expire_date')}
            size="tiny"
            fullWidth={true}
            required={true}
            id={Math.random()}
            date={expireDate}
            locale={moment().locale()}
            onChange={value => value && setExpireDate(moment(value).format('YYYY-MM-DD'))}
            error={error.expireDate}
            minDate={moment()}
            futureYearsCount={100}
            pastYearsCount={0}
            enablePastDates={false}
            disableFutureDates={false}
            touched={!!error.expireDate}
            hideNavButtons={false}
          />
        </div>
        <div className="col-lg-4">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.travellers.edit.visa_numbers.placeholders.visa_type',
            )}
            label={I18n.t('admin.components.travellers.edit.visa_numbers.label.visa_type')}
            width="full"
            required={true}
            options={visaCategoryOptions}
            value={findSelectBoxContent(visaCategoryOptions, category)}
            onChange={handleVisaCategory}
            errorMsg={error.category}
            isClearable={false}
          />
        </div>
        <div className="col-12 traveller-edit-identification-document-form__field traveller-edit-identification-document-form__field--with-top-bottom-margin">
          <ChooseFile
            errorMsg={error.visaNumbers || error.visaNumbersFile}
            showFileUploadPreviewUrl={true}
            fileUploadPreviewModalTitle={I18n.t('admin.visa_number.modal.title')}
            fileUploadPreviewUrl={visaFileUploadPreviewUrl}
            fileName={visaFileName}
            fileType={visaFileType}
            selectFile={value => selectFile(value)}
            removeFile={removeFile}
            showRemoveFileButton={true}
          />
        </div>
      </div>
    </div>
  );
};

VisaNumberForm.defaultProps = {
  error: {},
  visaFileName: null,
  visaFileType: '',
  visaFileUploadPreviewUrl: '',
};

VisaNumberForm.propTypes = {
  visaCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  issueDate: PropTypes.string.isRequired,
  category: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  expireDate: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  setExpireDate: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  setIssueDate: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setVisaFileType: PropTypes.func.isRequired,
  setVisaFileUploadPreviewUrl: PropTypes.func.isRequired,
  visaFileName: PropTypes.string,
  visaFileType: PropTypes.string,
  visaFileUploadPreviewUrl: PropTypes.string,
  setVisaFileName: PropTypes.func.isRequired,
  setVisaFile: PropTypes.func.isRequired,
  error: PropTypes.shape({
    country: PropTypes.string,
    category: PropTypes.string,
    expireDate: PropTypes.string,
    number: PropTypes.string,
    issueDate: PropTypes.string,
    visaNumbers: PropTypes.string,
    visaNumbersFile: PropTypes.string,
  }),
};

export default VisaNumberForm;
