import moment from 'moment';
import deepClone from '../lib/deepClone';

export function mapCartGroups(cartGroups) {
  return Object.keys(cartGroups).map((key, index) => {
    const group = deepClone(cartGroups[key]);
    group.key = index.toString();
    if (group.type !== 'car') return group;

    const {
      pickUpGeoLocation,
      dropOffGeoLocation,
      pickUpLocationId,
      dropOffLocationId,
      pickUpTime,
      dropOffTime,
    } = group.searchParams;

    if (pickUpTime) {
      group.searchParams.pickUpDate = moment(pickUpTime).format('YYYY-MM-DD');
      group.searchParams.pickUpTime = moment(pickUpTime).format('HH:mm');
    }

    if (dropOffTime) {
      group.searchParams.dropOffDate = moment(dropOffTime).format('YYYY-MM-DD');
      group.searchParams.dropOffTime = moment(dropOffTime).format('HH:mm');
    }

    if (pickUpGeoLocation) {
      delete group.searchParams.pickUpGeoLocation;
      group.searchParams.pickUpGeoLocationLatitude = pickUpGeoLocation.latitude;
      group.searchParams.pickUpGeoLocationLongitude = pickUpGeoLocation.longitude;
      group.searchParams.pickUpGeoLocationAddress = pickUpGeoLocation.address;
    } else {
      delete group.searchParams.pickUpLocationId;
      group.searchParams.pickUpLocationAirportCode = pickUpLocationId;
    }

    if (dropOffGeoLocation) {
      delete group.searchParams.dropOffGeoLocation;
      group.searchParams.dropOffGeoLocationLatitude = dropOffGeoLocation.latitude;
      group.searchParams.dropOffGeoLocationLongitude = dropOffGeoLocation.longitude;
      group.searchParams.dropOffGeoLocationAddress = dropOffGeoLocation.address;
    } else {
      delete group.searchParams.dropOffLocationId;
      group.searchParams.dropOffLocationAirportCode = dropOffLocationId;
    }

    return group;
  });
}

export function replaceItemInGroup(oldGroups, groupKey, item, oldItemId) {
  const newGroups = deepClone(oldGroups);
  if (!newGroups.length) return newGroups;

  const groupToModify = newGroups.find(group => group.key === groupKey);
  const options = groupToModify.options;

  const indexToReplace = options.findIndex(option => option.id.toString() === oldItemId.toString());
  options[indexToReplace] = item;

  return newGroups;
}
