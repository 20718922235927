import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@wtag/react-comp-lib';
import { Header } from '@wtag/rcl-typography';
import Icon from '@wtag/rcl-icon';
import Modal from 'sharedWebpack/Modal';
import history from '../lib/history';

const AncillariesUnavailabilityModal = ({
  unavailableServices,
  modalOpen,
  cartId,
  fetchJourneyElement,
  onContinueBooking,
  removeItems,
}) => {
  const [disableButton, setDisableButton] = useState(false);

  const unavailableAncillaryServices = Object.values(unavailableServices);

  const ancillaryServicesIds = () => {
    const subItemIds = new Set();
    unavailableAncillaryServices.forEach(ancillaryServicesBySegment => {
      ancillaryServicesBySegment.items.forEach(subItem => subItemIds.add(subItem.subItemId));
    });

    return [...subItemIds];
  };

  const removeUnavailableAncillaryServices = () => {
    setDisableButton(true);
    removeItems(ancillaryServicesIds())
      .then(() => {
        const journeyElementIDs = new Set();
        const promises = [];
        unavailableAncillaryServices.forEach(unavailableServicesBySegment =>
          journeyElementIDs.add(unavailableServicesBySegment.flightId),
        );
        journeyElementIDs.forEach(journeyElementID => {
          promises.push(fetchJourneyElement('flight', journeyElementID));
        });
        return Promise.all(promises);
      })
      .then(() => {
        history.push(`/carts/${cartId}`);
      });
  };

  const onContinue = () => {
    setDisableButton(true);
    onContinueBooking(ancillaryServicesIds());
  };

  return (
    <Modal
      className="ancillaries-unavailable-modal"
      isModalVisible={modalOpen}
      onOutsideClick={() => {}}
    >
      <div className="ancillaries-unavailable-modal__wrapper">
      <div className="ancillaries-unavailable-modal__body">
        <Icon
          name="warning"
          color="warning"
          showBGColor={true}
          size="large"
          fill="#FDB432"
          className="ancillaries-unavailable-modal__body-warning-icon"
        />
        <div className="ancillaries-unavailable-modal__body-title">
          <Header level={5} weight="bold">
            {I18n.t('components.unavailable_ancillary_services.header')}
          </Header>
        </div>
        <div className="ancillaries-unavailable-modal__body-text">
          {I18n.t('components.unavailable_ancillary_services.description')}
        </div>
        {unavailableAncillaryServices &&
          unavailableAncillaryServices.map(ancillaryServices => (
            <div
              className="ancillaries-unavailable-modal__body-trip"
              key={`segment-${ancillaryServices.id}`}
            >
              <div className="ancillaries-unavailable-modal__body-trip--title">
                <span className="ancillaries-unavailable-modal__body-trip--title-segment">
                  <img
                    className="ancillaries-unavailable-modal__body-trip--title-segment-country-flag"
                    src={ancillaryServices.originCountryFlag.small}
                    alt={ancillaryServices.originCode}
                  />
                  <span>{ancillaryServices.originCode}</span>
                  <Icon name="arrowUp" rotate={90} />
                  <img
                    className="ancillaries-unavailable-modal__body-trip--title-segment-country-flag"
                    src={ancillaryServices.destinationCountryFlag.small}
                    alt={ancillaryServices.destinationCode}
                  />
                  <span>{ancillaryServices.destinationCode}</span>
                </span>
                <span className="ancillaries-unavailable-modal__body-trip--title-code">
                  <Icon name="aeroplane" size="tiny" fill="#969AAB" />
                  {ancillaryServices.operatingFlight}
                </span>
              </div>
              {ancillaryServices.items.map(ancillaryService => (
                <div
                  className="ancillaries-unavailable-modal__body-trip--ancillaries"
                  key={`sum-item-${ancillaryService.subItemId}`}
                >
                  <span className="ancillaries-unavailable-modal__body-trip--ancillaries-title">
                    {ancillaryService.type === 'seat' ? (
                      <Icon
                        color="tertiary"
                        iconLabel={I18n.t('components.unavailable_ancillary_services.seat.label', {
                          seat_no: ancillaryService.seat,
                        })}
                        name="seat"
                        size="tiny"
                        showBGColor={true}
                        showLabel={true}
                      />
                    ) : (
                      <Icon
                        color="tertiary"
                        iconLabel={ancillaryService.description}
                        name="tag"
                        size="tiny"
                        showBGColor={true}
                        showLabel={true}
                      />
                    )}
                  </span>
                  {ancillaryService.type === 'seat' && (
                    <span className="ancillaries-unavailable-modal__body-trip--ancillaries-element">
                      {ancillaryService.wing
                        ? I18n.t('components.unavailable_ancillary_services.seat.wing')
                        : I18n.t('components.unavailable_ancillary_services.seat.standard')}
                    </span>
                  )}
                </div>
              ))}
            </div>
          ))}
        <div className="ancillaries-unavailable-modal__body-action">
          <Link
            type="button"
            modifier="primary"
            size="small"
            onClick={onContinue}
            disabled={disableButton}
          >
            {I18n.t('components.unavailable_ancillary_services.action.continue_anyway')}
          </Link>
          <Link
            type="button"
            modifier="default"
            size="small"
            disabled={disableButton}
            onClick={removeUnavailableAncillaryServices}
          >
            {I18n.t('components.unavailable_ancillary_services.action.select_another')}
          </Link>
        </div>
      </div>
      </div>
    </Modal>
  );
};

AncillariesUnavailabilityModal.defaultProps = {
  unavailableServices: {},
};

AncillariesUnavailabilityModal.propTypes = {
  unavailableServices: PropTypes.shape(),
  modalOpen: PropTypes.bool.isRequired,
  cartId: PropTypes.number.isRequired,
  fetchJourneyElement: PropTypes.func.isRequired,
  onContinueBooking: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired,
};

export default AncillariesUnavailabilityModal;
