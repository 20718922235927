import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import MatrixContext from '../../contexts/MatrixContext';
import MatrixLegendCard from '../MatrixLegendCard';
import MatrixFareBrandCard from '../MatrixFareBrandCard';
import MatrixContentSlider from '../MatrixContentSlider';
import './matrix-fair-brand-row.styles.scss';

const MatrixFareBrandsContentRow = props => {
  const { fareBrands, selectFareBrandOption, windowWidth, people } = props;
  const {
    matrixDataCardWidth,
    setMaxSliderScrollLength,
    scrollSliderToLeftBy,
    setScrollSliderToLeftBy,
    matrixRowHeaderCardWidth,
    maxSliderScrollLength,
  } = useContext(MatrixContext);
  const windowSizeForMobileView = 991;

  const onFareBrandSliderLoad = ({ clientWidth, scrollWidth }) => {
    setMaxSliderScrollLength(scrollWidth - clientWidth);
  };

  const matrixLegendCardWidth =
    windowWidth > windowSizeForMobileView
      ? { maxWidth: `calc(100% - ${matrixRowHeaderCardWidth}px)` }
      : { maxWidth: '100%' };

  return (
    <div className="matrix-fair-brand-row">
      <MatrixLegendCard windowWidth={windowWidth} />
      <div className="matrix-fair-brand-row__slider" style={matrixLegendCardWidth}>
        {!(scrollSliderToLeftBy <= 0 && scrollSliderToLeftBy >= maxSliderScrollLength) && (
          <Button
            className="matrix-fair-brand-row__slider-nav-button matrix-fair-brand-row__slider-nav-button--left"
            onClick={() => setScrollSliderToLeftBy(scrollSliderToLeftBy - matrixDataCardWidth)}
            disabled={scrollSliderToLeftBy <= 0}
            label={<Icon name="iconDownChevron" rotate={90} />}
          />
        )}
        <MatrixContentSlider
          onLoad={onFareBrandSliderLoad}
          scrollSliderToLeftBy={scrollSliderToLeftBy}
          // 2 keys catering and refundable are not in use. That's why [minus (-) 2]
          // Hide show more button when there are less than or equal 5 facilities
          hideShowMoreButton={Object.keys(fareBrands[0].fareBrandFacilities).length - 2 <= 5}
        >
          {fareBrands.map(fareBrand => (
            <MatrixFareBrandCard
              key={Object.keys(fareBrand.resultIds).join('-')}
              width={matrixDataCardWidth}
              matrixId={fareBrand.matrixId}
              name={fareBrand.name}
              cheapestOption={fareBrand.cheapestOption}
              minPrice={fareBrand.minPrice}
              cheapestOptionCalculatedInClientSide={fareBrand.cheapestOptionCalculatedInClientSide}
              minPriceCalculatedInClientSide={fareBrand.minPriceCalculatedInClientSide}
              maxPrice={fareBrand.maxPrice}
              currency={fareBrand.currency}
              hasSelectedFareOption={fareBrand.hasSelectedFareOption}
              resultIds={fareBrand.resultIds}
              bookingClasses={fareBrand.bookingClasses}
              facilities={fareBrand.fareBrandFacilities}
              fareRulePresent={fareBrand.fareRulePresent}
              isCheapestFareBrand={fareBrand.isCheapestFareBrand}
              onClick={selectFareBrandOption}
              people={people}
              grossTaxes={fareBrand.grossTaxes}
              fareCalculations={fareBrand.fareCalculations}
              maxGrossTaxes={fareBrand.maxGrossTaxes}
              minGrossTaxes={fareBrand.minGrossTaxes}
            />
          ))}
        </MatrixContentSlider>
        {!(scrollSliderToLeftBy <= 0 && scrollSliderToLeftBy >= maxSliderScrollLength) && (
          <Button
            className="matrix-fair-brand-row__slider-nav-button matrix-fair-brand-row__slider-nav-button--right"
            onClick={() => setScrollSliderToLeftBy(scrollSliderToLeftBy + matrixDataCardWidth)}
            disabled={scrollSliderToLeftBy >= maxSliderScrollLength}
            label={<Icon name="iconDownChevron" rotate={-90} />}
          />
        )}
      </div>
    </div>
  );
};

MatrixFareBrandsContentRow.propTypes = {
  fareBrands: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      fareBrandFacilities: PropTypes.shape({}).isRequired,
    }),
  ).isRequired,
  selectFareBrandOption: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  people: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default MatrixFareBrandsContentRow;
