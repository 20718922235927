/* global I18n */

import history from '../../lib/history';
import { resetFetchedAirports } from '../flight/airport';
import { resetFlightSearch } from '../flight';
import { resetHotelSearch } from '../hotel/search';
import { resetCarSearch } from '../car';
import { fetchStaticProducts } from './staticProducts';

export * from '../flight/airport';
export * from './approvalRequests';
export * from './book';
export * from './cart';
export * from './countries';
export * from './confirmationMail';
export * from './costCenters';
export * from './accountingTypes';
export * from './customer';
export * from './deal';
export * from './errors';
export * from './fees';
export * from './fop';
export * from './journeyElements';
export * from './legalPapers';
export * from './locations';
export * from './multiSearch';
export * from './place';
export * from './queuePlacement';
export * from './remarks';
export * from './setDefaultSearchParams';
export * from './staticProducts';
export * from './vouchers';
export * from './visaAdvisory';
export * from './cartStatus';
export * from './set3rdPartyPaymentMethod';

export const RESET_APP = 'RESET_APP';
export function resetApp(path) {
  resetFetchedAirports();
  resetFlightSearch();
  resetCarSearch();
  resetHotelSearch();
  const defaultPath = '/';

  return dispatch => {
    if (path) {
      history.push(path);
    } else {
      history.push(defaultPath);
    }
    dispatch({ type: RESET_APP });
    dispatch(fetchStaticProducts());
  };
}
