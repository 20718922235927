import { connect } from 'react-redux';
import { setDefaultSearchParams, fetchAirportDetails, fetchPlaceDetails } from '../actions/common';
import LastSearches from './LastSearches';
import localStorage from '../lib/localStorage';

const getUniqueFlightResults = results => {
  const uniqueResults = [];
  const trips = [];

  results.forEach(result => {
    const uniqueString = [
      result.trips.map(trip => trip.origin).join('|'),
      result.trips.map(trip => trip.destination).join('|'),
      result.trips.map(trip => trip.date).join('|'),
    ].join('|');
    if (result.trips.length > 0 && !trips.includes(uniqueString)) {
      uniqueResults.push(result);
      trips.push(uniqueString);
    }
  });

  return uniqueResults;
};

const getUniqueHotelResults = results => {
  const uniqueResults = [];
  const hotels = [];

  results.forEach(result => {
    const uniqueString = [result.address, result.checkIn, result.longitude, result.latitude].join(
      '|',
    );

    if (!hotels.includes(uniqueString)) {
      uniqueResults.push(result);
      hotels.push(uniqueString);
    }
  });

  return uniqueResults;
};

const getUniqueCarResults = (results, suggestions) => {
  const uniqueResults = [];
  const cars = [];

  results
    .filter(result => result.airportSearch === true || result.airportSearch === false)
    .forEach(result => {
      const uniqueString = [
        result.airportSearch,
        result.pickUpLocationAirportCode,
        result.pickUpGeoLocationAddress,
        result.dropOffLocationAirportCode,
        result.dropOffGeoLocationAddress,
        result.driverAge,
        result.pickUpDate || result.pickUpTime,
        result.vendorPreferences,
      ].join('|');

      if (!cars.includes(uniqueString)) {
        const copiedResult = Object.assign({}, result);
        if (copiedResult.airportSearch) {
          const airport = suggestions[copiedResult.dropOffLocationAirportCode];
          if (typeof airport !== 'undefined' && airport !== null) {
            copiedResult.name = airport.name;
            copiedResult.placeName = airport.placeName;
            copiedResult.countryName = airport.countryName;
          }
        }
        uniqueResults.push(copiedResult);
        cars.push(uniqueString);
      }
    });

  return uniqueResults;
};

const mapStateToProps = state => {
  const params = JSON.parse(localStorage.getItem('lastSearches'));

  if (params) {
    if (params.flights) params.flights = getUniqueFlightResults(params.flights);
    if (params.hotels) params.hotels = getUniqueHotelResults(params.hotels);
    if (params.cars) params.cars = getUniqueCarResults(params.cars, state.common.airports);
  }
  return {
    params,
  };
};

const mapDispatchToProps = dispatch => ({
  setDefaultSearchParams: (type, params) => dispatch(setDefaultSearchParams(type, params)),
  fetchAirportDetails: id => dispatch(fetchAirportDetails(id)),
  fetchPlaceDetails: id => dispatch(fetchPlaceDetails(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LastSearches);
