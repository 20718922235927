import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Snackbar from 'material-ui/Snackbar';

import { clearError, fetchStaticProducts } from './actions/common';

class App extends React.Component {
  componentDidMount() {
    this.props.fetchStaticProducts();
  }

  render() {
    let notification;
    if (this.props.errorPresent) {
      notification = (
        <Snackbar
          open={this.props.errorPresent}
          message={this.props.error}
          onRequestClose={this.props.clearError}
        />
      );
    }

    return (
      <div>
        {this.props.children && React.cloneElement(this.props.children, {error: this.props.error, errorPresent: this.props.errorPresent})}
        {notification}
      </div>
    );
  }
}

App.defaultProps = {
  error: null,
};

App.propTypes = {
  children: PropTypes.node.isRequired,
  errorPresent: PropTypes.bool.isRequired,
  error: PropTypes.string,
  clearError: PropTypes.func.isRequired,
  fetchStaticProducts: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  error: state.common.error.message,
  errorPresent: 'message' in state.common.error,
});

const mapDispatchToProps = dispatch => ({
  clearError: () => dispatch(clearError()),
  fetchStaticProducts: () => dispatch(fetchStaticProducts()),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(App));
