import { combineReducers } from 'redux';
import { STATIC_PRODUCTS_FETCHED, FETCHING_STATIC_PRODUCTS } from '../../actions/common';

function isFetchingProducts(state = true, action) {
  switch (action.type) {
    case FETCHING_STATIC_PRODUCTS:
      return action.isFetchingStaticProducts;

    default:
      return state;
  }
}

function fetchedStaticProducts(state = [], action) {
  const newState = new Map(state.map(product => [product.id, product]));

  switch (action.type) {
    case STATIC_PRODUCTS_FETCHED:
      action.products.forEach(product => newState.set(product.id, product));
      return Array.from(newState.values());

    default:
      return state;
  }
}

const commonReducer = combineReducers({
  isFetchingProducts,
  fetchedStaticProducts,
});

export default commonReducer;
