import {
  FLIGHT_SEARCH_FETCHED,
  CREATE_FLIGHT_SEARCH,
} from '../../actions/flight';
import { api2form } from '../../Search/flight/helpers/mapSearchParams';

export default function searchParamsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  const createSearchFlightActionParams = params => {
    const { adults, children, infants } = params;
    const totalTravelerCount = adults + children + infants;

    return { ...params, totalTravelerCount };
  };

  switch (action.type) {
    case CREATE_FLIGHT_SEARCH:
      newState.lastSearch = createSearchFlightActionParams(action.params);
      return newState;

    case FLIGHT_SEARCH_FETCHED:
      newState[action.searchId] = api2form(action.search);
      return newState;

    default:
      return state;
  }
}
