import PropTypes from 'prop-types';

const groupShape = PropTypes.shape({
  identifier: PropTypes.string.isRequired,
  codes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      airlines: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
  ndc: PropTypes.bool.isRequired,
});

export { groupShape };
export default PropTypes.shape({
  flights: PropTypes.objectOf(PropTypes.arrayOf(groupShape)),
  hotels: PropTypes.objectOf(PropTypes.arrayOf(groupShape)),
  cars: PropTypes.objectOf(PropTypes.arrayOf(groupShape)),
});
