import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import { I18nText } from '@wtag/react-comp-lib';
import TravelPlan from 'sharedWebpack/TravelPlan';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

const DealTravelPlan = ({ cartId, allSelected }) => {
  const [cart, setCart] = useState({});
  const [isCartItemsLoading, setIsCartItemsLoading] = useState(true);

  const fetchCart = async () => {
    const { data } = await httpClient.get(
      routes.api.carts.fetchCart({ cartId, show_travel_plan: true }),
    );

    setIsCartItemsLoading(false);
    setCart(data);
  };

  useEffect(() => {
    fetchCart();
  }, []);

  return (
    <div>
      {allSelected && !isCartItemsLoading && Object.keys(cart).length > 0 ? (
        <TravelPlan content={cart} layman={true} />
      ) : (
        <ContentLoaderPlaceholder numberOfLines={10} showBackground={true} />
      )}
      {!isCartItemsLoading && !Object.keys(cart).length && (
        <Card version="v2" size="full">
          <div className="deal__travel-plan">
            <div className="deal__travel-plan-icon">
              <Icon name="highPriority" color="default" showBGColor={true} size="medium" />
            </div>
            <div className="deal__travel-plan-description">
              <div className="deal__travel-plan-description--header">
                <I18nText id="ibe.deals.travel_plan.no_plan" returnStringOnly={true} />
              </div>
              <div className="deal__travel-plan-description--sub-header">
                <I18nText id="ibe.deals.travel_plan.select" returnStringOnly={true} />
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

DealTravelPlan.defaultProps = {
  allSelected: false,
};

DealTravelPlan.propTypes = {
  cartId: PropTypes.string.isRequired,
  allSelected: PropTypes.bool,
};

export default DealTravelPlan;
