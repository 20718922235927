import { TOGGLE_PRIMARY_BUTTON_DISABLED } from '../actions';

export default function primaryButtonDisabled(state = false, action) {
  switch (action.type) {
    case TOGGLE_PRIMARY_BUTTON_DISABLED:
      return action.isDisabled;

    default:
      return state;
  }
}
