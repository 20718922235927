import { SET_ACCOUNTING_REMARKS } from '../../actions/common';

export default function accountingRemarks(state, action) {
  const initialState = {};
  const newState = Object.assign({}, state);

  switch (action.type) {
    case SET_ACCOUNTING_REMARKS:
      newState[action.resultId] = {
        type: action.remarksType,
        content: action.remarks,
      };
      return newState;

    default:
      if (state === undefined) {
        return initialState;
      }
      return state;
  }
}
