import { connect } from 'react-redux';
import QueuePlacement from './QueuePlacement';
import { setQueuePlacement } from '../../../actions/common';

const mapStateToProps = (state, ownProps) => {
  const queuePlacement = state.common.queuePlacement[ownProps.resultId];

  if (queuePlacement) {
    return {
      account: queuePlacement.account || ownProps.defaultAccount,
      queue: queuePlacement.queue,
    };
  }
  return {
    account: ownProps.defaultAccount,
    queue: null,
  };
};

const mapDispatchToProps = dispatch => ({
  setQueuePlacement: (queue, account, resultId, callbackParams) =>
    dispatch(setQueuePlacement(queue, account, resultId, callbackParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueuePlacement);
