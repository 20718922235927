import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@wtag/rcl-icon-button';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import { EmphasisTag, CheckBox } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import DateTime from 'sharedWebpack/DateTime';
import { NumberParam, useQueryParams } from 'use-query-params';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import routes from 'agentRoutes';
import EmptyTableContent from 'sharedWebpack/EmptyTableContent';
import Pagination from 'sharedWebpack/Pagination';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';

const InvitationTable = ({ type, state }) => {
  const [invitations, setInvitations] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
  });
  const { page } = query;
  const isClient = type === 'clients';

  const prepareInvitations = rawInvitations =>
    rawInvitations.map(rawInvitation => ({ ...rawInvitation, checked: false }));

  const fetchTravelArrangers = args => {
    const { newPage, newState, apiToken } = args;

    httpClient
      .get(routes.public.travelArrangerSettings[type].list({ state: newState, page: newPage }), {
        cancelToken: apiToken,
      })
      .then(({ data }) => {
        setInvitations(prepareInvitations(data.travelArrangerSettings));
        setTotalPages(data.pagination.totalPages);
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        throw error;
      });
  };

  const updatePagination = newPage => {
    setQuery({ page: newPage });
  };

  const acceptMultiple = async invitationIDs => {
    const travelArrangersParam = {
      travel_arrangement_setting_ids: invitationIDs,
    };

    const { data } = await httpClient.put(
      routes.public.travelArrangers.accept({}),
      travelArrangersParam,
    );

    if (data.error === null) {
      fetchTravelArrangers({
        newPage: page,
        newState: state,
      });
    }
  };

  const declineMultiple = async invitationIDs => {
    const travelArrangersParam = {
      travel_arrangement_setting_ids: invitationIDs,
    };

    const { data } = await httpClient.put(
      routes.public.travelArrangers.decline({}),
      travelArrangersParam,
    );

    if (data.error === null) {
      fetchTravelArrangers({
        newPage: page,
        newState: state,
      });
    }
  };

  const onAcceptMultiple = () => {
    if (invitations.length > 0) {
      const selectedInvitations = invitations.filter(({ checked }) => checked === true);
      const invitationIDs = selectedInvitations.map(invitation => invitation.id);
      acceptMultiple(invitationIDs);
    }
  };

  const onDeclineMultiple = () => {
    if (invitations.length > 0) {
      const selectedInvitations = invitations.filter(({ checked }) => checked === true);
      const invitationIDs = selectedInvitations.map(invitation => invitation.id);
      declineMultiple(invitationIDs);
    }
  };

  const onDeleteInvitation = async travelArrangerSettingID => {
    const { data } = await httpClient.delete(
      routes.public.travelArrangerSettings.arrangers.deactivate({ travelArrangerSettingID }),
    );
    if (data.error === null) {
      fetchTravelArrangers({
        newPage: page,
        newState: state,
      });
    }
  };

  const onSelectMultipleInvitations = value => {
    setInvitations(prevState => {
      const copiedInvitations = [...prevState];
      const modifiedInvitation = copiedInvitations.map(invitation => ({
        ...invitation,
        checked: value,
      }));

      return modifiedInvitation;
    });
  };

  const onSelectSingleInvitation = (invitationId, value) => {
    setInvitations(prevState => {
      const copiedInvitations = [...prevState];
      const modifiedInvitation = copiedInvitations.map(invitation => {
        if (invitationId === invitation.id) {
          return { ...invitation, checked: value };
        }

        return invitation;
      });

      return modifiedInvitation;
    });
  };

  useEffect(() => {
    const getTravelArrangerSettingsApiToken = generateSourceToken();

    fetchTravelArrangers({
      newPage: page,
      newState: state,
      apiToken: getTravelArrangerSettingsApiToken.token,
    });

    return () => {
      getTravelArrangerSettingsApiToken.cancel();
    };
  }, [page, state, type]);

  return (
    <div className="col-grid col-bleed invitation-table">
      {isClient && (
        <div className="invitation-table__actions">
          <span>
            <CheckBox
              name="select-all"
              onChange={event => onSelectMultipleInvitations(event.target.checked)}
              checked={
                invitations.length > 0 &&
                invitations.every(invitation => invitation.checked === true)
              }
              label={I18n.t('public.components.travel_arrangement_settings.label.select_all')}
            />
          </span>
          <span className="invitation-table__actions-with-padding">
            <Button
              label={I18n.t('public.components.travel_arrangement_settings.label.decline_selected')}
              size="small"
              version="v2"
              disabled={!invitations.some(invitation => invitation.checked === true)}
              onClick={() => onDeclineMultiple()}
            />
          </span>
          <span>
            <Button
              label={I18n.t(
                'public.components.travel_arrangement_settings.label.approved_selected',
              )}
              size="small"
              version="v2"
              disabled={!invitations.some(invitation => invitation.checked === true)}
              onClick={() => onAcceptMultiple()}
            />
          </span>
        </div>
      )}
      <div className="col-12 col-bleed-x">
        {invitations.map(invitation => (
          <div className="invitation-table__card" key={invitation.id}>
            <Card version="v2" size="full">
              <div className="invitation-table__card-content grid justify-space-between align-center">
                <div className="col-auto invitation-table__left-panel">
                  {isClient && (
                    <CheckBox
                      className="invitation-table__left-panel-checkbox"
                      name={`select-invite-${invitation.id}`}
                      checked={invitation.checked}
                      onChange={event =>
                        onSelectSingleInvitation(invitation.id, event.target.checked)
                      }
                    />
                  )}
                  <Avatar
                    className="invitation-table__left-panel-avatar"
                    size="tiny"
                    showName={true}
                    firstName={invitation.person.firstName}
                    middleName={invitation.person.middleName || ''}
                    lastName={invitation.person.lastName}
                    src={invitation.person.avatarUrl}
                    descriptionText={
                      <React.Fragment>
                        {isClient
                          ? I18n.t(
                              'public.components.travel_arrangement_settings.invitation.received_text',
                            )
                          : I18n.t(
                              'public.components.travel_arrangement_settings.invitation.sent_text',
                            )}
                        <EmphasisTag
                          className="invitation-table__left-panel-tag"
                          size="tiny"
                          type="neutral"
                          text={I18n.t(invitation.level, {
                            scope: 'activerecord.attributes.travel_arranger_settings.levels',
                          })}
                        />
                      </React.Fragment>
                    }
                  />
                </div>

                <div className="col-auto invitation-table__right-panel">
                  <div className="invitation-table__right-panel-datetime">
                    <DateTime dateTime={invitation.createdAt} format="shortWithTime" />
                  </div>
                  {isClient ? (
                    <div className="invitation-table__right-panel-icons">
                      <IconButton
                        icon={<Icon name="check" />}
                        color="tertiary"
                        size="small"
                        onClick={() => acceptMultiple([invitation.id])}
                      />
                      <IconButton
                        icon={<Icon name="close" />}
                        color="tertiary"
                        size="small"
                        onClick={() => declineMultiple([invitation.id])}
                      />
                    </div>
                  ) : (
                    <IconButton
                      icon={<Icon name="delete" />}
                      color="tertiary"
                      size="small"
                      onClick={() => onDeleteInvitation(invitation.id)}
                    />
                  )}
                </div>
              </div>
            </Card>
          </div>
        ))}
        {invitations.length === 0 && <EmptyTableContent />}
      </div>
      <Pagination currentPage={page} totalPages={totalPages} onPaginationClick={updatePagination} />
    </div>
  );
};

InvitationTable.propTypes = {
  type: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

export default withQueryParamsProvider(InvitationTable);
