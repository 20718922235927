/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import formattedAmount from './formattedAmount';

function Seat(props) {
  const { currency, seatList, segmentsWithSeatId } = props;
  const countryIndicatorHandler = value =>
    value ? (
      `(${value.originCode} - ${value.destinationCode})`
    ) : (
      <ContentLoaderPlaceholder
        numberOfLines={1}
        contentHeight="14px"
        contentWidth="60px"
        showBackground={false}
      />
    );

  return (
    <div className="cart-summary__accordion-sub-item-details-category">
      {seatList.map(seat => (
        <div className="cart-summary__accordion-sub-item-details-category-seat">
          <span>{seat.itemTitle}</span>
          <span className="cart-summary__accordion-sub-item-details-category-seat-indicator">
            {countryIndicatorHandler(segmentsWithSeatId[seat.journeyElementId])}
          </span>
          <span>{formattedAmount(seat.grossTotal, currency)}</span>
        </div>
      ))}
    </div>
  );
}

Seat.defaultProps = {
  seatList: [],
  segmentsWithSeatId: null,
};

Seat.propTypes = {
  currency: PropTypes.string.isRequired,
  seatList: PropTypes.arrayOf(PropTypes.shape()),
  segmentsWithSeatId: PropTypes.shape(),
};

export default Seat;
