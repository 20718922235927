import moment from 'moment';

const calculateNights = (arrival, departure) => {
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const startMoment = moment(arrival, format);
  const endMoment = moment(departure, format);

  const nights = endMoment.diff(startMoment, 'days');

  const isMultiNightWithinSameDay = startMoment.hours() > endMoment.hours();

  return isMultiNightWithinSameDay ? nights + 1 : nights;
};

export default calculateNights;
