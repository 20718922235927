import { connect } from 'react-redux';
import { fetchNationalities } from '../actions/common/nationalities';
import NationalitySelectorContainer from './NationalitySelectorContainer';

const mapStateToProps = state => ({
  nationalities: state.common.nationalities,
});

const mapDispatchToProps = dispatch => ({
  fetchNationalities: () => dispatch(fetchNationalities()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NationalitySelectorContainer);
