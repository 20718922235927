import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import {
  IATA_FORM_OF_PAYMENT,
  CASH_FORM_OF_PAYMENT,
  NONE_FORM_OF_PAYMENT,
  WALLET_FORM_OF_PAYMENT,
} from '../../lib/helpers/fop_options';

import { cartFetched } from '../common';

export const FETCHING_SET_FORM_OF_PAYMENT = 'FETCHING_SET_FORM_OF_PAYMENT';
export function fetchingFop(isFetchingFop) {
  return {
    type: FETCHING_SET_FORM_OF_PAYMENT,
    isFetchingFop,
  };
}

export const FETCHED_SET_FORM_OF_PAYMENT = 'FETCHED_SET_FORM_OF_PAYMENT';
export function fetchedSetFormOfPayment(cardDetails, itemId, checkCardType) {
  return {
    type: FETCHED_SET_FORM_OF_PAYMENT,
    cardDetails,
    itemId,
    checkCardType,
  };
}

export const FETCHED_NEW_CARD_DETAILS = 'FETCHED_NEW_CARD_DETAILS';
export function fetchedNewCardDetails(cardDetails) {
  return {
    type: FETCHED_NEW_CARD_DETAILS,
    cardDetails,
  };
}

function setFormOfPayment(formOfPayment, itemId) {
  const checkCardType = formOfPayment.type;
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    return httpClient
      .put(routes.api.carts.items.setFormOfPayment.add({ cartId, itemId }), {
        form_of_payment: formOfPayment,
      })
      .then(({ data }) => {
        const fopInformation = data.items.find(item => item.id === itemId).formOfPayment
          .cardDetails;

        dispatch(fetchedSetFormOfPayment(fopInformation, itemId, checkCardType));
        return dispatch(cartFetched(data));
      });
  };
}

export function resetFormOfPayment() {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    return httpClient.delete(routes.api.carts.resetFormOfPayment({ cartId })).then(({ data }) => {
      dispatch(cartFetched(data));
    });
  };
}

export function setFormOfPaymentToCreditCard(cardDetails, itemId) {
  return dispatch => {
    const params = { type: 'credit_card', token: cardDetails.token };
    return dispatch(setFormOfPayment(params, itemId));
  };
}

export function setFormOfPaymentToCash(itemId) {
  return dispatch => {
    const params = { type: CASH_FORM_OF_PAYMENT };
    return dispatch(setFormOfPayment(params, itemId));
  };
}

export function setFormOfPaymentToWelltravelWallet(itemId) {
  return dispatch => {
    const params = { type: WALLET_FORM_OF_PAYMENT };
    return dispatch(setFormOfPayment(params, itemId));
  };
}

export function setFormOfPaymentToIATA(itemId) {
  return dispatch => {
    const params = { type: IATA_FORM_OF_PAYMENT };
    return dispatch(setFormOfPayment(params, itemId));
  };
}

export function setFormOfPaymentToNone(itemId) {
  return dispatch => {
    const params = { type: NONE_FORM_OF_PAYMENT };
    return dispatch(setFormOfPayment(params, itemId));
  };
}

export function setFormOfPaymentForAllItems(cardDetails, paymentTypes) {
  return dispatch => {
    let creditCardPromises;
    if (cardDetails) {
      creditCardPromises = paymentTypes.map(paymentType =>
        dispatch(setFormOfPaymentToCreditCard(cardDetails, paymentType.itemId, paymentType)),
      );
    } else {
      creditCardPromises = paymentTypes.map(paymentType => {
        if (paymentType.cardType === IATA_FORM_OF_PAYMENT) {
          return dispatch(setFormOfPaymentToIATA(paymentType.itemId, paymentType));
        }

        if (paymentType.cardType === NONE_FORM_OF_PAYMENT) {
          return dispatch(setFormOfPaymentToNone(paymentType.itemId, paymentType));
        }

        if (paymentType.cardType === WALLET_FORM_OF_PAYMENT) {
          return dispatch(setFormOfPaymentToWelltravelWallet(paymentType.itemId, paymentType));
        }
        return dispatch(setFormOfPaymentToCash(paymentType.itemId, paymentType));
      });
    }

    return Promise.all(creditCardPromises);
  };
}

export function saveCreditCardInBackend(
  cartId,
  cardDetails,
  billingAddress,
  callbackParams,
  callback,
) {
  return dispatch => {
    const token = cardDetails.token;
    return httpClient
      .post(routes.api.maskedCreditCards.create(), {
        card_details: {
          fullname: cardDetails.fullname,
          card_type: cardDetails.type,
          masked_number: cardDetails.masked_number,
          stored_in_ccv: cardDetails.cardSaved,
          cart_id: cartId,
          token,
        },
        billing_address: {
          street: billingAddress.street,
          city: billingAddress.city,
          state: billingAddress.state,
          zip_code: billingAddress.zip,
          country_code: billingAddress.country,
          email: billingAddress.email,
          phone_number: billingAddress.phoneCountryCode + billingAddress.phoneNumber,
        },
        callback_params: callbackParams,
      })
      .then(({ data }) => {
        callback(data);
        return dispatch(fetchedNewCardDetails(data));
      });
  };
}

function fetchedFOPandCardDetails(cardDetails, itemId, checkCardType) {
  return dispatch => {
    dispatch(fetchedNewCardDetails(cardDetails));
    dispatch(fetchedSetFormOfPayment(cardDetails, itemId, checkCardType));
  };
}

export function fetchFOPandCardDetails() {
  return (dispatch, getState) => {
    const cart = getState().common.cart;
    const cartItems = cart.items;

    const creditCardPromises = cartItems.map(item =>
      httpClient
        .get(routes.api.carts.items.setFormOfPayment.fetch({ cartId: cart.id, itemId: item.id }))
        .then(({ data }) => {
          if (data) {
            if (item.formOfPayment.type) {
              dispatch(fetchedFOPandCardDetails(data, item.id, item.formOfPayment.type));
            } else {
              dispatch(fetchedFOPandCardDetails(data, item.id, CASH_FORM_OF_PAYMENT));
            }
          }
        }),
    );
    return Promise.all(creditCardPromises);
  };
}

const ITEM_WITHOUT_FOP = 'static_product';

export function fetchFormOfPayment() {
  return (dispatch, getState) => {
    dispatch(fetchingFop(true));

    const cart = getState().common.cart;
    const cartItems = cart.items.filter(item => item.type !== ITEM_WITHOUT_FOP);

    const creditCardPromises = cartItems.map(item =>
      httpClient
        .get(routes.api.carts.items.setFormOfPayment.fetch({ cartId: cart.id, itemId: item.id }))
        .then(({ data }) => {
          if (item.formOfPayment.type) {
            dispatch(fetchedSetFormOfPayment(data, item.id, item.formOfPayment.type));
          } else {
            dispatch(fetchedSetFormOfPayment(data, item.id, CASH_FORM_OF_PAYMENT));
          }
        }),
    );
    return Promise.all(creditCardPromises).then(() => dispatch(fetchingFop(false)));
  };
}

export const CONFIGURE_PAYMENT_GATEWAY = 'CONFIGURE_PAYMENT_GATEWAY';
export function configurePaymentGateway(paymentGateway) {
  return dispatch => dispatch({ type: CONFIGURE_PAYMENT_GATEWAY, paymentGateway });
}
