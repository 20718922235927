import {
  START_BOOKING,
} from '../../actions/common';

export default function bookingProgress(state = [], action) {
  const newState = Object.assign([], state);

  switch (action.type) {
    case START_BOOKING:
      action.results.forEach(result => {
        newState.push({
          ...result,
          status: 'open',
          errorMessage: null,
        });
      });
      return newState;

    default:
      return state;
  }
}
