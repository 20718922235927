import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import { EmphasisTag } from '@wtag/react-comp-lib';
import HumanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import DateTime from 'sharedWebpack/DateTime';
import TechnicalStop from './TechnicalStop';
import BaggageLabel from '../../../IBE/SearchResult/flight/BaggageLabel';

const SegmentCard = ({
  events,
  operatingAirline,
  issuedAirline,
  segment,
  findFromfetchedAircrafts,
}) => {
  let aircraftDetails;
  if (segment.aircraftDetails) {
    aircraftDetails = segment.aircraftDetails;
  } else {
    aircraftDetails = findFromfetchedAircrafts(segment.aircraftType);
  }

  const marketingFlight =
    segment.marketingFlight || segment.marketingCarrierCode + segment.marketingFlightNumber;

  return (
    <Card version="v2" shadow="dark" transparent={true} className="flight-segment-card">
      <div className="flight-segment-card__header">
        <div className="flight-segment__duration--header">
          {HumanReadableDuration(segment.flightDurationInMinutes)}
        </div>
        <EmphasisTag
          text={marketingFlight}
          size="small"
          type="neutral"
          icon={<Icon name="aeroplane" />}
        />
      </div>
      <div className="flight-segment__info">
        {segment.baggagePiecesIncluded && (
          <EmphasisTag
            text={<BaggageLabel baggageInfo={[segment.baggagePiecesIncluded]} />}
            size="tiny"
            icon={<Icon name="baggage" />}
          />
        )}
        <EmphasisTag
          text={
            segment.overnight
              ? I18n.t('components.ibe.results.overnight')
              : I18n.t('components.ibe.results.daylight')
          }
          size="tiny"
        />
        {segment.cabinClass && (
          <EmphasisTag text={I18n.t(segment.cabinClass, { scope: 'cabin_classes' })} size="tiny" />
        )}
        {segment.bookingClass && <EmphasisTag text={segment.bookingClass} size="tiny" />}
        {segment.fareType && <EmphasisTag text={segment.fareType} size="tiny" type="success" />}
        {aircraftDetails && aircraftDetails.name && (
          <EmphasisTag text={aircraftDetails.name} size="tiny" />
        )}
      </div>
      {events.map((event, index) => (
        <Fragment key={event.localTime}>
          <div className="flight-segment-card__timeline">
            <div
              className={classNames('flight-segment-card__timeline-line', {
                'flight-segment-card__timeline-line--last': event.isDestination,
                'flight-segment-card__timeline-line--solid': event.isTechnicalStop,
              })}
            >
              <div className="flight-segment-card__timeline-line-dot" />
              <div className="flight-segment-card__timeline-line-dash" />
            </div>
            {event.isTechnicalStop ? (
              <TechnicalStop
                duration={HumanReadableDuration(event.durationInMinutes)}
                arrivalLocaltime={event.arrivalLocaltime}
                airportName={event.airport && event.airport.name}
                count={event.count}
              />
            ) : (
              <div
                className={classNames('flight-segment-card__timeline-content', {
                  'flight-segment-card__timeline-content--last': event.isDestination,
                  'flight-segment-card__timeline-content--first': !event.isDestination,
                })}
              >
                <div className="flight-segment-card__country">
                  <img
                    className="flight-segment-card__country--flag"
                    src={event.airport && event.airport.countryFlag}
                    alt={event.airport && event.airport.countryName}
                  />
                  <div className="flight-segment-card__country--airport-code">
                    {event.airport && event.airport.code}
                  </div>
                </div>
                <div className="flight-segment-card__airport-name">
                  {event.airport && (
                    <Fragment>
                      {event.airport.name}
                      {`, ${event.airport.placeName}`}
                      {`, ${event.airport.countryName}`}
                    </Fragment>
                  )}
                </div>
                <span className="flight-segment__duration">
                  <DateTime dateTime={event.localTime} format="shortWithTime" />
                </span>
                {event.terminal && (
                  <div className="flight-segment-card__terminal">
                    {I18n.t('components.flight.terminal', {
                      number: event.terminal,
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
          {event.isTechnicalStop && events[index + 1].isTechnicalStop && (
            <div className="flight-segment-card__timeline flight-segment-card__timeline--line-only">
              <div className="flight-segment-card__timeline-line">
                <div className="flight-segment-card__timeline-line-dash" />
              </div>
            </div>
          )}
        </Fragment>
      ))}
      <div className="col-grid col-bleed direction-row align-center justify-space-between flight-segment-card__logos">
        <div className="flight-item-trip__segment-details__logo--issued">
          <p className="flight-item-trip__segment-details__label">
            {I18n.t('components.flight_info.segment.issued_by')}
          </p>
          {issuedAirline.logo && (
            <div className="flight__airline-logo">
              <img src={issuedAirline.logo} alt={`logo-${issuedAirline.code}`} />
            </div>
          )}
        </div>
        {issuedAirline.code !== operatingAirline.code && (
          <div className="flight-item-trip__segment-details__logo--operated">
            <p className="flight-item-trip__segment-details__label">
              {I18n.t('components.flight_info.segment.operated_by')}
            </p>
            {operatingAirline.logo && (
              <div className="flight__airline-logo">
                <img src={operatingAirline.logo} alt={`logo-${operatingAirline.code}`} />
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

SegmentCard.defaultProps = {
  operatingAirline: {},
  issuedAirline: {},
};

SegmentCard.propTypes = {
  operatingAirline: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }),
  issuedAirline: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      isTechnicalStop: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  segment: PropTypes.shape({
    flightDurationInMinutes: PropTypes.string.isRequired,
    marketingCarrierCode: PropTypes.string,
    marketingFlightNumber: PropTypes.string,
    marketingFlight: PropTypes.string,
    baggagePiecesIncluded: PropTypes.shape({
      count: PropTypes.string,
      kgPerPiece: PropTypes.string,
    }),
    overnight: PropTypes.bool,
    cabinClass: PropTypes.string,
    bookingClass: PropTypes.string,
    fareType: PropTypes.string,
    aircraftType: PropTypes.string,
    aircraftDetails: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  findFromfetchedAircrafts: PropTypes.func.isRequired,
};

export default SegmentCard;
