import React, { useEffect, useState } from 'react';
import httpClient from 'agentHTTPClient';
import PropTypes from 'prop-types';
import Form from '../Form';

const Edit = ({
  availableCurrencies,
  orderId,
  travellerId,
  scope,
  urls,
  forOrderType,
  forTravellerType,
}) => {
  const [travelExpense, setTravelExpense] = useState({});
  const [date, setDate] = useState('');

  const fetchTravelExpense = async () => {
    const { data } = await httpClient.get(urls.travelExpensePath);
    setTravelExpense(data.travelExpense);
    setDate(data.travelExpense.date);
  };

  const updateTravelExpense = async params => {
    const { data } = await httpClient.put(urls.travelExpensePath, params);

    if (data.error === null) {
      window.location.replace(urls.index);
    }
  };

  useEffect(() => {
    fetchTravelExpense();
  }, []);

  return (
    <div className="col-grid align-center">
      <div className="col-10">
        {date && (
          <Form
            availableCurrencies={availableCurrencies}
            urls={urls}
            isEditing={true}
            submitText={I18n.t('admin.components.travel_expenses.actions.update')}
            submitAction={value => updateTravelExpense(value)}
            travelExpense={travelExpense}
            date={date}
            setDate={setDate}
            orderId={orderId}
            travellerId={travellerId}
            scope={scope}
            forOrderType={forOrderType}
            forTravellerType={forTravellerType}
          />
        )}
      </div>
    </div>
  );
};

Edit.defaultProps = {
  orderId: null,
  scope: 'public',
  forOrderType: false,
  forTravellerType: false,
};

Edit.propTypes = {
  availableCurrencies: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  travellerId: PropTypes.number.isRequired,
  orderId: PropTypes.number,
  scope: PropTypes.oneOf(['admin', 'public']),
  forOrderType: PropTypes.bool,
  forTravellerType: PropTypes.bool,
  urls: PropTypes.shape({
    index: PropTypes.string.isRequired,
    travelExpensePath: PropTypes.string.isRequired,
  }).isRequired,
};

export default Edit;
