import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { SidePanel } from '@wtag/react-comp-lib';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import { AccordionItem } from '@wtag/rcl-accordion';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import CarImage from 'sharedWebpack/IBE/SearchResult/car/CarImage';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import CarLocationDetails from 'sharedWebpack/IBE/SearchResult/car/CarLocationDetails';
import InsurancesAndLiabilities from 'sharedWebpack/IBE/SearchResult/car/InsurancesAndLiabilities';
import getCarInformation from 'sharedWebpack/IBE/lib/helpers/getCarInformation';
import { journeyElementShape } from 'sharedWebpack/IBE/Cart/shapes';
import getCarImageUrl from 'sharedWebpack/IBE/lib/helpers/getCarImageUrl';
import CarAccordionItemHeader from './CarAccordionItemHeader';
import CarFacilitiesItems from './CarFacilitiesItems';
import CarFacilitiesItemWithSidePanel from './CarFacilitiesItemWithSidePanel';
import './styles.scss';

const RESPONSIBILITY_WAIVER = 'responsiblityWaiver';
const QUERY_UPDATE_TYPE = 'replaceIn';

const Index = props => {
  const {
    journeyElementId,
    journeyElementType,
    fetchedJourneyElement,
    displayFacilitiesItems,
  } = props;

  const [journeyElement, setJourneyElement] = useState(fetchedJourneyElement || {});
  const [isAccordionActive, setIsAccordionActive] = useState(false);
  const [sidePanelTitle, setSidePanelTitle] = useState(null);
  const [sidePanelBody, setSidePanelBody] = useState(null);

  const handleAccordionClick = () => {
    setIsAccordionActive(previousState => !previousState);
  };

  const [query, setQuery] = useQueryParams({
    openedSidePanel: withDefault(StringParam, ''),
  });
  const isSidePanelVisible = query.openedSidePanel === RESPONSIBILITY_WAIVER;

  const showSidePanel = (currentSidePanelTitle, currentSidePanelBody) => {
    setQuery({ openedSidePanel: RESPONSIBILITY_WAIVER }, QUERY_UPDATE_TYPE);
    setSidePanelTitle(currentSidePanelTitle);
    setSidePanelBody(currentSidePanelBody);
  };

  const hideSidePanel = () => {
    setQuery({ openedSidePanel: '' }, QUERY_UPDATE_TYPE);
  };

  const fetchJourneyElement = async () => {
    try {
      const { data } = await httpClient.get(
        routes.api.fetchJourneyElement({
          id: journeyElementId,
          type: journeyElementType,
        }),
      );
      setJourneyElement(data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (journeyElementId && !fetchedJourneyElement) {
      fetchJourneyElement();
    }
  }, [journeyElementId, journeyElementType]);

  if (Object.keys(journeyElement).length === 0) {
    return <ContentLoaderPlaceholder numberOfLines={2} isRounded={true} />;
  }

  const {
    carImageUrl,
    pickUp,
    dropOff,
    insurances,
    liabilities,
    make,
    name,
    category,
    acrissCode,
    seatCount,
    doors,
    airConditioning,
    fuelType,
    vendorImageUrl,
    vendorName,
    facilities,
  } = journeyElement;

  const carFacilitiesItems = { seatCount, doors, airConditioning, fuelType };
  const hasCarFacilitiesItems = Object.keys(carFacilitiesItems).length > 0;

  return (
    <AccordionItem
      className="car-item-compact-view"
      id={journeyElementId}
      header={
        <CarAccordionItemHeader
          carInformation={{ category, acrissCode }}
          pickUpDate={pickUp.localtimeInUserTz}
          dropOffDate={dropOff.localtimeInUserTz}
          vendorImageUrl={vendorImageUrl}
          vendorName={vendorName}
          make={make}
          name={name}
          isAccordionActive={isAccordionActive}
        />
      }
      onClick={handleAccordionClick}
      isActive={isAccordionActive}
    >
      <div className="grid grid-gap-20">
        <div className="col-12 car-item__content">
          <div className="grid grid-gap-12">
            <div className="col-md-4 car-item__image">
              <CarImage className="flex-img" url={carImageUrl} />
            </div>
            <div className="col-lg-8 d-flex-col car-item__details">
              <h4 className="col-12 col-bleed car-item__information">
                {getCarInformation(name, make)}
              </h4>
              <div className="car-item__vendor-image">
                <span className="car__logo-chip">
                  <img src={getCarImageUrl(vendorImageUrl)} alt="car" />
                </span>
              </div>
              <div className="d-flex-col w-100 car-item__details-content">
                <div className="col-12 col-bleed">
                  <CarLocationDetails pickUpDetails={pickUp} dropOffDetails={dropOff} />
                </div>
                <div className="col-12 col-bleed d-flex car-item__insurances">
                  <InsurancesAndLiabilities
                    insurances={insurances}
                    liability={liabilities}
                    onShowSidePanel={showSidePanel}
                  />
                  <SidePanel
                    title={sidePanelTitle}
                    body={sidePanelBody}
                    isOpened={isSidePanelVisible}
                    onClick={hideSidePanel}
                  />
                </div>
                <CarFacilitiesItemWithSidePanel facilities={facilities} />
              </div>
              {displayFacilitiesItems && hasCarFacilitiesItems && (
                <div className="col-12 col-bleed d-flex">
                  <CarFacilitiesItems list={carFacilitiesItems} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AccordionItem>
  );
};

Index.defaultProps = {
  journeyElementId: null,
  fetchedJourneyElement: null,
  displayFacilitiesItems: false,
};

Index.propTypes = {
  journeyElementId: PropTypes.number,
  journeyElementType: PropTypes.string.isRequired,
  fetchedJourneyElement: PropTypes.shape(journeyElementShape),
  displayFacilitiesItems: PropTypes.bool,
};

export default withQueryParamsProvider(Index);
