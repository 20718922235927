import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved, import/extensions
import AnimatedTypingIcon from '-!svg-react-loader!./animated-typing-Icon.svg';
import Avatar from '@wtag/rcl-avatar';
import reportToRollbar from '../reportToRollbar';
import { senderBasicInformation } from './SenderInformation';

const TypingIndicator = props => {
  const { typerInfo, roomUsers, typerCount, context, roomUid } = props;

  const messageTyperInfo = () => {
    const user = typerInfo.isCustomer
      ? roomUsers.customers[typerInfo.id]
      : roomUsers.agency[typerInfo.id];

    if (user) {
      return senderBasicInformation(user.name, user.avatarURL, user.isOnline);
    }

    reportToRollbar(
      `TypingIndicator could not find the user (id: ${typerInfo.id}, isCustomer: ${typerInfo.isCustomer}) inside ActiveRoom ${roomUid}`,
    );
    return senderBasicInformation(context.tenant, context.currentTenantLogoUrl, false);
  };

  const typerBasicInfo = messageTyperInfo();

  return (
    <div className="chat__messages-container">
      <div className="chat-message chat-message--received-message">
        <div key="sender" className="chat-message__avatar">
          <Avatar
            firstName={typerBasicInfo.firstName}
            lastName={typerBasicInfo.lastName}
            src={typerBasicInfo.avatarUrl}
            size="tiny"
          />
        </div>
        <div className="chat-message__body">
          <div className="chat-message__typing-container">
            <AnimatedTypingIcon />
          </div>
          {typerCount > 1 && (
            <div className="chat-message__typing-container-text">
              {I18n.t('chat.more_people_typing_text', { count: typerCount - 1 })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TypingIndicator.propTypes = {
  context: PropTypes.shape({
    connected: PropTypes.bool,
    currentUserAvatar: PropTypes.string,
    currentTenantLogoUrl: PropTypes.string,
    currentUserId: PropTypes.number,
    currentUserName: PropTypes.string,
    laymanMode: PropTypes.bool,
    tenant: PropTypes.string,
    messageInputRef: PropTypes.shape({}),
    focusMessageInput: PropTypes.func,
    isAlien: PropTypes.bool,
  }).isRequired,
  typerInfo: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    roomId: PropTypes.string.isRequired,
    isCustomer: PropTypes.bool.isRequired,
  }).isRequired,
  typerCount: PropTypes.number.isRequired,
  roomUsers: PropTypes.shape({
    agency: PropTypes.shape({}),
    customers: PropTypes.shape({}),
  }).isRequired,
  roomUid: PropTypes.string.isRequired,
};

export default TypingIndicator;
