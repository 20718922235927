import React, { Fragment } from 'react';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';

const PendingTravelArrangers = props => {
  const { travelArrangers, fetchTravelArrangers, loading, urls } = props;

  const acceptTravelArranger = async id => {
    const travelArrangersParam = {
      travel_arrangement_setting_ids: [id],
    };

    const { data } = await httpClient.put(
      routes.public.travelArrangers.accept({}),
      travelArrangersParam,
    );

    if (data.error === null) {
      fetchTravelArrangers();
    }
  };

  const declineTravelArranger = async id => {
    const travelArrangersParam = {
      travel_arrangement_setting_ids: [id],
    };

    const { data } = await httpClient.put(
      routes.public.travelArrangers.decline({}),
      travelArrangersParam,
    );

    if (data.error === null) {
      fetchTravelArrangers();
    }
  };

  const maxArrangersToShow = 4;
  const notShownArrangers = travelArrangers.length - maxArrangersToShow;

  let pendingTravelArrangersContent = (
    <Card
      version="v2"
      className="pending-card pending-card__arrangers"
      title={I18n.t('public.components.dashboard.travel_arrangers.pending_arrangers')}
      emptyCardImageSrc={<Icon name="person" />}
      emptyCardText={I18n.t('public.components.dashboard.travel_arrangers.no_pending')}
    />
  );

  if (loading) {
    pendingTravelArrangersContent = (
      <Card version="v2" className="pending-card pending-card__arrangers">
        <div className="pending-card__arrangers-header">
          {I18n.t('public.components.dashboard.travel_arrangers.pending_arrangers')}
        </div>
        <div className="pending-card__arrangers-content">
          <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
        </div>
      </Card>
    );
  } else if (travelArrangers.length > 0) {
    pendingTravelArrangersContent = (
      <Card version="v2" className="pending-card pending-card__arrangers">
        <div className="pending-card__arrangers-header">
          {I18n.t('public.components.dashboard.travel_arrangers.pending_arrangers')}
        </div>
        {travelArrangers.slice(0, maxArrangersToShow).map(travelArranger => (
          <div
            key={travelArranger.id}
            className="col-grid col-bleed direction-row align-center justify-space-between pending-card__arrangers-item"
          >
            <div className="pending-card__arrangers-item-avatar">
              <Avatar
                size="tiny"
                firstName={travelArranger.firstName}
                middleName={travelArranger.middleName || ''}
                lastName={travelArranger.lastName}
                showName={true}
                src={travelArranger.avatarUrl}
              />
            </div>
            <div className="pending-card__arrangers-item-actions">
              <Link
                onClick={() => acceptTravelArranger(travelArranger.clientSettingId)}
                size="tiny"
                type="icon-button"
                labelHint={I18n.t('public.components.dashboard.travel_arrangers.accept')}
              >
                <Icon name="check" size="small" />
              </Link>
              <Link
                onClick={() => declineTravelArranger(travelArranger.clientSettingId)}
                size="tiny"
                type="icon-button"
                labelHint={I18n.t('public.components.dashboard.travel_arrangers.decline')}
              >
                <Icon name="close" size="small" />
              </Link>
            </div>
          </div>
        ))}
        {notShownArrangers > 0 && (
          <Link href={urls.index} size="tiny" type="anchor">
            <div className="pending-card__arrangers-footer">
              {I18n.t('public.shared.action.view_more', {
                count: notShownArrangers,
              })}
            </div>
          </Link>
        )}
      </Card>
    );
  }

  return <Fragment>{pendingTravelArrangersContent}</Fragment>;
};

PendingTravelArrangers.propTypes = {
  travelArrangers: PropTypes.arrayOf(
    PropTypes.shape({
      clientSettingId: PropTypes.number,
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      name: PropTypes.string,
      nickName: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  ).isRequired,
  fetchTravelArrangers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  urls: PropTypes.shape({
    index: PropTypes.string,
  }).isRequired,
};

export default PendingTravelArrangers;
