const formatPrice = (price, currency) => {
  const formattedPrice = new Intl.NumberFormat('de-CH', {
    currency,
    notation: 'standard',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(price);

  return `${currency.toUpperCase()} ${formattedPrice}`;
};

export default formatPrice;
