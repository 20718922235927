import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import mealRequestsBuilder from '../../../Search/flight/helpers/mealRequestsBuilder';

const MealRequestsInformation = props => {
  const {
    fields: { mealRequests },
    flightResults,
  } = props;

  const segmentCodes = [];

  if (flightResults && flightResults.length) {
    flightResults.forEach(result => {
      const segments = [];
      result.trips.forEach(trip => {
        trip.segments.forEach(segment => {
          segments.push({
            carrierCode: segment.operatingCarrierCode,
            flightNumber: segment.operatingFlightNumber,
          });
          segmentCodes.push({
            originCode: segment.originCode,
            destinationCode: segment.destinationCode,
          });
        });
      });
    });
  }

  const showMealRequest = () => {
    const mealRequestWithSegments = mealRequests.value.map((meal, index) => {
      const mealCodeArray = meal.code.split();
      const mealRequestsData = mealRequestsBuilder(mealCodeArray);

      return (
        <div key={meal} className="meal-requests-fields__attributes-data">
          <div className="meal-requests-fields__attributes-data-segment">
            {`${segmentCodes[index].originCode} - ${segmentCodes[index].destinationCode}`}
          </div>
          <div className="meal-requests-fields__attributes-data-values">
            {mealRequestsData &&
              mealRequestsData.map(mealOptions => (
                <div className="meal-requests-fields__attributes-data-values-meal">
                  {`${mealOptions.label} (${mealOptions.value})`}
                </div>
              ))}
          </div>
        </div>
      );
    });

    return mealRequestWithSegments;
  };

  const mealRequestNotAvailable =
    mealRequests.value === '' ||
    mealRequests.value.length <= 0 ||
    mealRequests.value.every(meal => meal.code === null);

  return (
    <div className="meal-requests-fields">
      <div className="meal-requests-fields__header">
        {I18n.t('components.ibe.traveler_details.dietary_meal_requests')}
      </div>

      {mealRequestNotAvailable ? (
        <div className="meal-requests-fields__not-available">
          {I18n.t('components.ibe.traveler_details.attributes.not_selected', {
            attribute: I18n.t('components.ibe.traveler_details.attributes.meal_request'),
          })}
        </div>
      ) : (
        <div className="meal-requests-fields__attributes">{showMealRequest()}</div>
      )}
    </div>
  );
};

MealRequestsInformation.propTypes = {
  fields: PropTypes.shape().isRequired,
  flightResults: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

MealRequestsInformation.contextTypes = {
  enableVisaAdvisory: PropTypes.bool,
};

export default injectIntl(MealRequestsInformation);
