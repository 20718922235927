import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { ensureCart, fetchCart } from './cart';

export const FETCHING_STATIC_PRODUCTS = 'FETCHING_STATIC_PRODUCTS';
function fetchingProducts(isFetchingStaticProducts) {
  return {
    type: FETCHING_STATIC_PRODUCTS,
    isFetchingStaticProducts,
  };
}

export const STATIC_PRODUCTS_FETCHED = 'STATIC_PRODUCTS_FETCHED';
function staticProductsFetched(products) {
  return {
    type: STATIC_PRODUCTS_FETCHED,
    products,
  };
}

function fetchStaticProductsWithoutHandling(locations) {
  return httpClient.get(routes.api.fetchStaticProducts({ 'locations[]': [...locations] }));
}

export function fetchStaticProducts() {
  return (dispatch, getState) => {
    dispatch(fetchingProducts(true));
    const locations = new Set();
    const state = getState();
    state.common.cart.items.forEach(item => {
      if (item.type !== 'flight') {
        return;
      }
      const journeyElement = state.common.journeyElements.flight[item.journeyElementId];
      if (!journeyElement) {
        return;
      }
      journeyElement.trips.forEach(trip => {
        locations.add(trip.originCode);
        locations.add(trip.destinationCode);
      });
    });
    return fetchStaticProductsWithoutHandling(locations)
      .then(({ data }) => {
        dispatch(staticProductsFetched(data));
        dispatch(fetchingProducts(false));
        return data;
      })
      .catch(error => {
        if (error.request.timeout === 0 && error.code === 'ECONNABORTED') {
          fetchStaticProductsWithoutHandling(locations).then(({ data }) => {
            dispatch(staticProductsFetched(data));
            dispatch(fetchingProducts(false));
            return data;
          });
        } else {
          throw error;
        }
      });
  };
}

export const ADD_STATIC_PRODUCT_TO_CART = 'ADD_STATIC_PRODUCT_TO_CART';
export function addStaticProductToCart(product, callbackParams) {
  return dispatch =>
    dispatch(ensureCart(callbackParams)).then(cartId => {
      dispatch({ type: ADD_STATIC_PRODUCT_TO_CART, product, callbackParams });
      return httpClient
        .post(routes.api.carts.items.addStaticProduct({ cartId }), {
          product_id: product.id,
        })
        .then(() => dispatch(fetchCart(cartId, callbackParams)));
    });
}
