import getCarTravelersInfo from './getCarTravelersInfo';
import getInsuranceInfo from './getInsuranceInfo';
import getLiabilitiesInfo from './getLiabilitiesInfo';
import getMileageInfo from './getMileageInfo';
import getModelName from './getModelName';
import formattedDateTime from './formattedDateTime';
import getCarAttributes from './getCarAttributes';
import getCarCityInfo from './getCarCityInfo';
import toCapitializeForm from './toCapitializeForm';
import formatPrice from './formatPrice';

const generateCarContent = (item, travelers, journeyElement) => {
  const { grossTotalIncludingSubItems, currency } = item;
  const {
    durationInDays,
    pickUp,
    dropOff,
    vendorName,
    make,
    name,
    category,
    acrissCode,
    doors,
    airConditioning,
    seatCount,
    fuelType,
    ratePerDay,
    insurances,
    liabilities,
    mileage,
  } = journeyElement;
  const attributes = getCarAttributes(doors, airConditioning, seatCount, fuelType);
  const cityInfo = getCarCityInfo(pickUp.city, dropOff.city, durationInDays);
  const modelName = getModelName(make, name);
  const insuranceInfo = getInsuranceInfo(insurances);
  const liabilitiesInfo = getLiabilitiesInfo(liabilities);
  const mileageInfo = getMileageInfo(mileage);
  const travelersInfo = getCarTravelersInfo(travelers);
  const pickUpDate = formattedDateTime(pickUp.localtimeInUserTz);
  const dropOffDate = formattedDateTime(dropOff.localtimeInUserTz);
  const pickUpInfo = I18n.t('components.ibe.search_results.car.content.pick_up', {
    date: pickUpDate,
    location: pickUp.location,
  });
  const dropOffInfo = I18n.t('components.ibe.search_results.car.content.drop_off', {
    date: dropOffDate,
    location: dropOff.location,
  });
  const carType = I18n.t('components.ibe.search_results.car.content.type', {
    category: toCapitializeForm(category),
    acrissCode,
  });
  const vendorInfo = I18n.t('components.ibe.search_results.car.content.provider', {
    name: vendorName,
  });
  const priceInfo = I18n.t('components.ibe.search_results.hotel.content.price_info', {
    totalPrice: formatPrice(grossTotalIncludingSubItems, currency),
    perDayPrice: formatPrice(ratePerDay, currency),
  });

  return `${cityInfo}\n---\n${vendorInfo}\n${modelName}\n${carType}\n${attributes}\n---\n${travelersInfo}${priceInfo}\n---\n${pickUpInfo}\n${dropOffInfo}\n${insuranceInfo}${liabilitiesInfo}${mileageInfo}`;
};

export default generateCarContent;
