import routes from 'agentRoutes';
import httpV3Client from 'agentHTTPV3Client';

export const FLIGHT_SEARCH_PARAMS_FETCHED = 'FLIGHT_SEARCH_PARAMS_FETCHED';
export function fetchFlightSearchParams(params) {
  return dispatch =>
    httpV3Client.get(routes.api.flights.searchParams(params)).then(({ data }) => {
      dispatch({
        type: FLIGHT_SEARCH_PARAMS_FETCHED,
        searchId: params.searchId,
        params: data,
      });
      return data;
    });
}
