import React from 'react';
import PropTypes from 'prop-types';

import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';

const HotelLoyaltyProgramForm = ({
  hotelLoyaltyList,
  program,
  setProgram,
  number,
  setNumber,
  hotelError,
}) => {
  const HotelLoyaltyProgramsMenu = hotelLoyaltyList
    ? Object.keys(hotelLoyaltyList).map(value => ({
        value,
        label: I18n.t(value, {
          scope: 'simple_form.options.person.hotel_loyalty_programs.hotel_guest_program',
        }),
      }))
    : [];

  const findSelectBoxContent = value => {
    if (value) {
      return {
        value,
        label: I18n.t(program, {
          scope: 'simple_form.options.person.hotel_loyalty_programs.hotel_guest_program',
        }),
      };
    }

    return null;
  };

  return (
    <div className="grid">
      <div className="col-sm-6">
        <SelectBox
          size="tiny"
          placeholderText={I18n.t('admin.components.travellers.edit.loyalty_programs.program')}
          label={I18n.t(
            'admin.components.travellers.edit.loyalty_programs.hotel_loyalty.headers.program',
          )}
          width="full"
          options={HotelLoyaltyProgramsMenu}
          value={findSelectBoxContent(program)}
          onChange={selectedOption => selectedOption && setProgram(selectedOption.value)}
          errorMsg={hotelError.hotelGuestProgram}
          required={true}
          isClearable={false}
        />
      </div>
      <div className="col-sm-6">
        <Input
          size="tiny"
          placeholder={I18n.t(
            'admin.components.travellers.edit.loyalty_programs.hotel_loyalty.placeholder.number',
          )}
          label="Number"
          touched={!!hotelError.hotelGuestNumber}
          error={hotelError.hotelGuestNumber}
          value={number}
          onChange={value => setNumber(value)}
          required={true}
        />
      </div>
    </div>
  );
};

HotelLoyaltyProgramForm.defaultProps = {
  hotelError: {},
  program: '',
  setProgram: {},
};

HotelLoyaltyProgramForm.propTypes = {
  hotelLoyaltyList: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  program: PropTypes.arrayOf(PropTypes.shape),
  setProgram: PropTypes.func,
  number: PropTypes.string.isRequired,
  setNumber: PropTypes.func.isRequired,
  hotelError: PropTypes.shape({
    hotelGuestNumber: PropTypes.string,
    hotelGuestProgram: PropTypes.string,
  }),
};

export default HotelLoyaltyProgramForm;
