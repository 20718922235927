import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import CarPreferencesForm from './CarPreferencesForm';

const CarPreferencesInformation = props => {
  const {
    personId,
    fetchTraveller,
    carPreferences,
    carTypes,
    carDrives,
    carCategories,
    layman,
  } = props;

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});
  const [preferredCarTypes, setPreferredCarTypes] = useState([]);
  const [preferredCarCategories, setPreferredCarCategories] = useState([]);
  const [preferredCarDrives, setPreferredCarDrives] = useState([]);

  const commaForMultipleElements = index => (index ? ', ' : '');

  const saveTravellerUrl = layman
    ? routes.public.people.update()
    : routes.admin.people.update({ personId });

  const updateCarPreferences = async () => {
    const carPreferencesParams = {
      preferred_car_types: preferredCarTypes,
      preferred_car_categories: preferredCarCategories,
      preferred_car_drives: preferredCarDrives,
    };

    const { data } = await httpClient.patch(saveTravellerUrl, {
      person: carPreferencesParams,
    });

    if (data.error === null) {
      fetchTraveller();
      setEditing(false);
    }
  };

  const onUpdateCarPreferences = () =>
    updateCarPreferences().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  useEffect(() => {
    setPreferredCarTypes(carPreferences.preferredCarTypes);
    setPreferredCarCategories(carPreferences.preferredCarCategories);
    setPreferredCarDrives(carPreferences.preferredCarDrives);
  }, [carPreferences]);

  return (
    <Fragment>
      {carPreferences && (
        <div className="traveller-edit-travel-preferences-section">
          <div className="traveller-edit-travel-preferences-section__title">
            <div className="traveller-edit-travel-preferences-section__header">
              {I18n.t('admin.components.travellers.overview.booking_preference.title.car')}
            </div>
            {editing ? (
              <div className="traveller-edit-travel-preferences-section__actions">
                <IconButton
                  icon={<Icon name="check" />}
                  color="tertiary"
                  size="small"
                  onClick={onUpdateCarPreferences}
                />
                <IconButton
                  icon={<Icon name="close" />}
                  color="tertiary"
                  size="small"
                  onClick={() => setEditing(false)}
                />
              </div>
            ) : (
              <div className="traveller-edit-travel-preferences-section__actions">
                <IconButton
                  icon={<Icon name="edit" />}
                  color="tertiary"
                  size="small"
                  onClick={() => setEditing(true)}
                />
              </div>
            )}
          </div>
          {editing ? (
            <CarPreferencesForm
              preferredCarTypes={preferredCarTypes}
              setPreferredCarTypes={setPreferredCarTypes}
              preferredCarCategories={preferredCarCategories}
              setPreferredCarCategories={setPreferredCarCategories}
              preferredCarDrives={preferredCarDrives}
              setPreferredCarDrives={setPreferredCarDrives}
              carTypes={carTypes}
              carCategories={carCategories}
              carDrives={carDrives}
              error={error}
            />
          ) : (
            <div className="grid grid-gap-12">
              <div className="col-12">
                <div className="traveller-edit-travel-preferences__header">
                  {I18n.t('simple_form.labels.person.preferred_car_types')}
                </div>
                <div className="traveller-edit-travel-preferences__value">
                  {carPreferences.preferredCarTypes.length > 0
                    ? preferredCarTypes &&
                      preferredCarTypes.map((preferredCarType, index) => (
                        <span>
                          {commaForMultipleElements(index) +
                            I18n.t(preferredCarType, {
                              scope: 'simple_form.options.person.preferred_car_types',
                            })}
                        </span>
                      ))
                    : I18n.t(
                        'admin.components.travellers.overview.booking_preference.not_selected.preferred_car_types',
                      )}
                </div>
              </div>
              <div className="col-12 traveller-edit-travel-preferences__content">
                <div className="traveller-edit-travel-preferences__header">
                  {I18n.t('simple_form.labels.person.preferred_car_categories')}
                </div>
                <div className="traveller-edit-travel-preferences__value">
                  {carPreferences.preferredCarCategories.length > 0
                    ? preferredCarCategories &&
                      preferredCarCategories.map((preferredCarCategory, index) => (
                        <span>
                          {commaForMultipleElements(index) +
                            I18n.t(preferredCarCategory, {
                              scope: 'simple_form.options.person.preferred_car_categories',
                            })}
                        </span>
                      ))
                    : I18n.t(
                        'admin.components.travellers.overview.booking_preference.not_selected.preferred_car_categories',
                      )}
                </div>
              </div>
              <div className="col-12 traveller-edit-travel-preferences__content">
                <div className="traveller-edit-travel-preferences__header">
                  {I18n.t('simple_form.labels.person.preferred_car_drives')}
                </div>
                <div className="traveller-edit-travel-preferences__value">
                  {carPreferences.preferredCarDrives.length > 0
                    ? preferredCarDrives &&
                      preferredCarDrives.map((preferredCarDrive, index) => (
                        <span>
                          {commaForMultipleElements(index) +
                            I18n.t(preferredCarDrive, {
                              scope: 'simple_form.options.person.preferred_car_drives',
                            })}
                        </span>
                      ))
                    : I18n.t(
                        'admin.components.travellers.overview.booking_preference.not_selected.preferred_car_drives',
                      )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

CarPreferencesInformation.defaultProps = {
  layman: false,
  carPreferences: {
    preferredCarTypes: [],
    preferredCarCategories: [],
    preferredCarDrives: [],
  },
};

CarPreferencesInformation.propTypes = {
  layman: PropTypes.bool,
  personId: PropTypes.number.isRequired,
  fetchTraveller: PropTypes.func.isRequired,
  carTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  carDrives: PropTypes.arrayOf(PropTypes.string).isRequired,
  carCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  carPreferences: PropTypes.shape({
    preferredCarTypes: PropTypes.arrayOf(PropTypes.string),
    preferredCarCategories: PropTypes.arrayOf(PropTypes.string),
    preferredCarDrives: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default CarPreferencesInformation;
