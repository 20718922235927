import React, { useState } from 'react';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

const ChangePasswordSection = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState({});

  const resetFields = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setError({});
  };

  const updatePassword = async () => {
    const passwordParams = {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: confirmNewPassword,
    };

    const { data } = await httpClient.put(routes.public.people.updatePassword(), {
      person: passwordParams,
    });

    if (data.error === null) {
      resetFields();
    }
  };

  const onUpdatePassword = () =>
    updatePassword().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  return (
    <React.Fragment>
      <div className="col-grid traveller-edit-change-password-section traveller-edit-change-password-section--full-padding">
        <div className="traveller-edit-change-password-section__header">
          {I18n.t('public.components.edit_account.change_password.header')}
        </div>
        <div className="col-12 col-grid direction-row align-center col-bleed traveller-edit-manage-traveller-information-form traveller-edit-manage-traveller-information-form__field">
          <div className="grid">
            <div className="col-sm-6">
              <Input
                size="tiny"
                width="full"
                label={I18n.t('public.components.edit_account.change_password.label.old_password')}
                placeholder={I18n.t(
                  'public.components.edit_account.change_password.placeholder.old_password',
                )}
                value={oldPassword}
                type="password"
                onChange={value => setOldPassword(value)}
                touched={true}
                error={error.oldPassword}
              />
            </div>
            <div className="col-sm-6">
              <Input
                size="tiny"
                width="full"
                label={I18n.t('public.components.edit_account.change_password.label.new_password')}
                placeholder={I18n.t(
                  'public.components.edit_account.change_password.placeholder.new_password',
                )}
                value={newPassword}
                type="password"
                onChange={value => setNewPassword(value)}
                touched={true}
                error={error.password}
              />
            </div>
            <div className="col-sm-6">
              <Input
                size="tiny"
                width="full"
                label={I18n.t(
                  'public.components.edit_account.change_password.label.confirm_new_password',
                )}
                placeholder={I18n.t(
                  'public.components.edit_account.change_password.placeholder.confirm_new_password',
                )}
                value={confirmNewPassword}
                type="password"
                onChange={value => setConfirmNewPassword(value)}
                touched={true}
                error={error.passwordConfirmation}
              />
            </div>
          </div>
        </div>
        <div className="traveller-edit-change-password-section__actions">
          <Button
            label={I18n.t('public.components.edit_account.change_password.label.update_password')}
            type="primary"
            disabled={false}
            version="v2"
            size="small"
            onClick={onUpdatePassword}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangePasswordSection;
