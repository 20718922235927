import React from 'react';
import Icon from '@wtag/rcl-icon';
import routes from 'agentRoutes';
import { Link } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';
import calculateNights from 'sharedWebpack/helpers/calculateNights';
import { FormatDateTime, DATE_FORMAT_SHORT } from '../../helpers/dateTime';
import './styles.scss';

const BookHotel = ({ stayingPlaceInfo, dates }) => {
  const { currentFlightCity, currentFlightArrivalTime, nextFlightDepartureTime } = stayingPlaceInfo;
  const nightCount = calculateNights(currentFlightArrivalTime, nextFlightDepartureTime);

  const formattedDateTime = date =>
    FormatDateTime({
      dateTime: date,
      format: DATE_FORMAT_SHORT,
      displayWithoutConversion: true,
    });

  return (
    <div className="d-flex align-center book-hotel">
      <Icon color="tertiary" name="hotelRoom" size="large" showBGColor={true} />
      <div className="d-flex-col align-start justify-center book-hotel__hint-text">
        <span className="book-hotel__hint-text--header">
          {I18n.t('admin.components.orders.overview.travel_plan.hotel.book_hotel.header', {
            night_count: nightCount,
            city_name: currentFlightCity,
          })}
        </span>
        <div className="book-hotel__hint-text--subheader">
          {dates.map(({ checkIn, checkOut }) => (
            <div>
              {I18n.t('admin.components.orders.overview.travel_plan.hotel.book_hotel.subheader', {
                check_in: formattedDateTime(checkIn),
                check_out: formattedDateTime(checkOut),
              })}
            </div>
          ))}
        </div>
      </div>
      <Link modifier="primary" size="small" href={routes.admin.hotelPage()} type="button">
        {I18n.t('admin.components.orders.overview.travel_plan.hotel.book_hotel.label')}
      </Link>
    </div>
  );
};

BookHotel.defaultProps = {
  dates: [],
};

BookHotel.propTypes = {
  stayingPlaceInfo: PropTypes.shape({
    currentFlightCity: PropTypes.string,
    currentFlightArrivalTime: PropTypes.shape({}),
    nextFlightDepartureTime: PropTypes.shape({}),
  }).isRequired,
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      checkeIn: PropTypes.shape({}),
      checkeOut: PropTypes.shape({}),
    }),
  ),
};

export default BookHotel;
