import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { SidePanel, I18nText } from '@wtag/react-comp-lib';
import ChangeApproversSidePanelBody from '../../../ApprovalRequests/ChangeApproversSidePanelBody';

const RequestApproval = ({ cartId, sendApprovalRequest, disabled }) => {
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [mandatoryApprovers, setMandatoryApprovers] = useState([]);
  const [optionalApprovers, setOptionalApprovers] = useState([]);
  const [potentialApprovers, setPotentialApprovers] = useState([]);
  const [selectedOptionalApprovers, setSelectedOptionalApprovers] = useState([]);
  const [minimumApproverCount, setMinimumApproverCount] = useState(1);

  const hideSidePanel = () => {
    setOpenSidePanel(false);
  };
  const fetchCartApprovers = async () => {
    const { data } = await httpClient.get(routes.api.carts.expectedApprovers({ cartId }));

    setMandatoryApprovers(data.approvers.mandatory);
    setOptionalApprovers(data.approvers.optional);
    setPotentialApprovers(data.approvers.potential);
    setSelectedOptionalApprovers(data.approvers.optional);
    setMinimumApproverCount(data.minimumApprovals);
  };

  useEffect(() => {
    fetchCartApprovers();
  }, []);

  const selectedApproverIds = selectedOptionalApprovers.map(approver => approver.id);
  const optionalApproverIds = optionalApprovers.map(approver => approver.id);
  const optionalApproverUnchanged = () => {
    if (selectedApproverIds.length !== optionalApproverIds.length) {
      return false;
    }

    const newApproverIds = selectedApproverIds.filter(
      selectedApproverId => !optionalApproverIds.includes(selectedApproverId),
    );

    return newApproverIds.length === 0;
  };

  const startApprovalProcess = () => {
    let newOptionalApproverIds = null;
    if (!optionalApproverUnchanged()) {
      newOptionalApproverIds = selectedApproverIds;
    }
    sendApprovalRequest(newOptionalApproverIds);
  };
  return (
    <div>
      <SidePanel
        title={I18n.t('public.orders.show.approval.start_process')}
        body={
          <ChangeApproversSidePanelBody
            mandatoryApprovers={mandatoryApprovers}
            optionalApprovers={optionalApprovers}
            potentialApprovers={potentialApprovers}
            minimumApproverCount={minimumApproverCount}
            selectedOptionalApprovers={selectedOptionalApprovers}
            setSelectedOptionalApprovers={setSelectedOptionalApprovers}
            startApprovalProcess={startApprovalProcess}
            hideSidePanel={hideSidePanel}
          />
        }
        isOpened={openSidePanel}
        onClick={() => hideSidePanel()}
      />
      <Button
        version="v2"
        size="small"
        className="approval-request-button"
        label={
          <I18nText id="ibe.check_out_progress.buttons.approval_request" returnStringOnly={true} />
        }
        onClick={() => setOpenSidePanel(true)}
        disabled={disabled}
      />
    </div>
  );
};
RequestApproval.propTypes = {
  cartId: PropTypes.number.isRequired,
  sendApprovalRequest: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default RequestApproval;
