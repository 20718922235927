import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';

const RemoveItemButton = ({ isCartLocked, itemId, onConfirm, frozen, className }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOnConfirm = () => {
    setIsButtonDisabled(true);
    onConfirm(itemId);
    closeModal();
  };

  const buttonDisabled = isCartLocked || frozen || isButtonDisabled;

  return (
    <Fragment>
      <IconButton
        className={className}
        isIconOnly={true}
        icon={<Icon name="delete" />}
        color="tertiary"
        size="tiny"
        padding="none"
        onClick={() => !buttonDisabled && openModal()}
      />
      <ConfirmationModal
        confirmationHeader={<I18nText id="ibe.remove_item.header" returnStringOnly={true} />}
        subHeader={<I18nText id="ibe.remove_item.subheader" returnStringOnly={true} />}
        isModalOpen={isModalOpen}
        onConfirm={handleOnConfirm}
        onReject={closeModal}
        withAction={true}
        confirmationText={<I18nText id="action_with_confirmation.yes" returnStringOnly={true} />}
        rejectionText={<I18nText id="action_with_confirmation.no" returnStringOnly={true} />}
      />
    </Fragment>
  );
};

RemoveItemButton.defaultProps = { className: null };

RemoveItemButton.propTypes = {
  isCartLocked: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  frozen: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default RemoveItemButton;
