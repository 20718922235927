import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import DateTime from 'sharedWebpack/DateTime';
import getCarInformation from 'sharedWebpack/IBE/lib/helpers/getCarInformation';
import getCarChannelIdentifier from 'sharedWebpack/IBE/lib/helpers/getCarChannelIdentifier';
import WELLTRAVEL from 'sharedWebpack/IBE/lib/helpers/welltravelChannel';
import ChannelListTag from 'sharedWebpack/IBE/helpers/ChannelListTag';
import useViewMode from 'sharedWebpack/useViewMode';
import { DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME } from 'sharedWebpack/helpers/dateTime';
import { DESKTOP_DEVICE } from 'sharedWebpack/old/libraries/responsiveHelpers';
import CarPriceWithLabel from '../CarPriceWithLabel';
import CarAttributeItem from '../CarAttributeItem';
import CarType from '../CarType';

const CarAccordionItemHeader = ({
  isAlreadySelectedInCart,
  onContinueButtonClick,
  carInformation,
  pickUpDate,
  dropOffDate,
  vendorName,
  priceDetails,
  source,
  laymanMode,
  isAccordionActive,
}) => {
  const { make, name, category, acrissCode } = carInformation;
  const { currency, ratePerDay } = priceDetails;

  const isWellTravelChannel = source.supplier === WELLTRAVEL;
  const channelIdentifier = getCarChannelIdentifier(source);
  const isChannelTagDisplayed = !laymanMode && !!channelIdentifier;
  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;

  const buttonText = () => {
    if (isAlreadySelectedInCart) {
      return <I18nText id="ibe.results.already_selected" />;
    }

    return <I18nText id="ibe.car_results.add_to_cart" />;
  };

  const getPerDayLabelText = () => {
    const labelText = I18n.t('admin.components.products.options.automation_style.per_day.label');

    if (isLargeScreen) {
      return `(${labelText})`;
    }

    return labelText;
  };

  const perDayLabelText = getPerDayLabelText();

  return (
    <div className="d-flex-col car-compact-view__accordion-header">
      {isChannelTagDisplayed && (
        <div className="car-compact-view__channel-list">
          <ChannelListTag
            identifier={channelIdentifier}
            type={isWellTravelChannel ? 'accent' : null}
          />
        </div>
      )}
      <div className="grid grid-gap-12 w-100">
        <div className="col-xlg-3 col-lg-2 col-grid row-gap-4 justify-center car-compact-view__information-container">
          <span className="car-compact-view__vendor">{vendorName}</span>
          <span className="car-compact-view__information">{getCarInformation(name, make)}</span>
        </div>
        <div className="d-flex col-xlg-9 col-lg-10 align-center wrap">
          <div
            className={classNames(
              'd-flex col-bleed align-center car-compact-view__attribute-items',
              {
                'col-xlg-8 col-lg-6': !laymanMode,
                'col-lg-7 car-compact-view__attribute-items--layman-mode': laymanMode,
              },
            )}
          >
            <CarAttributeItem
              className="car-compact-view__attribute-item"
              header={I18n.t('components.ibe.car_results.pick_up')}
              value={
                <DateTime
                  dateTime={pickUpDate}
                  format={DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME}
                  displayWithoutConversion={true}
                />
              }
            />
            <CarAttributeItem
              className="car-compact-view__attribute-item"
              header={I18n.t('components.ibe.car_results.drop_off')}
              value={
                <DateTime
                  dateTime={dropOffDate}
                  format={DATE_FORMAT_NUMERICAL_SHORT_WITH_TIME}
                  displayWithoutConversion={true}
                />
              }
            />
            <CarAttributeItem
              className="car-compact-view__attribute-item"
              header={I18n.t('components.ibe.car_results.car_type')}
              value={<CarType category={category} acrissCode={acrissCode} />}
            />
          </div>
          <div
            className={classNames(
              'd-flex col-bleed align-center column-gap-16 car-compact-view__action',
              {
                'col-xlg-4 col-lg-6': !laymanMode,
                'col-lg-5': laymanMode,
              },
            )}
          >
            <span className="d-flex direction-column row-gap-4 car-compact-view__price">
              <CarPriceWithLabel currency={currency} price={ratePerDay} />
              <span className="car-compact-view__per-day-label">
                <CarType category={category} acrissCode={acrissCode} />
                {perDayLabelText}
              </span>
            </span>
            <Icon
              className="icon--mobile"
              color="tertiary"
              name="iconUpChevron"
              rotate={isAccordionActive ? 0 : 180}
            />
            <Button
              className="car-compact-view__button"
              size="small"
              label={buttonText()}
              primary={true}
              version="v2"
              onClick={onContinueButtonClick}
              disabled={isAlreadySelectedInCart}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CarAccordionItemHeader.propTypes = {
  isAlreadySelectedInCart: PropTypes.bool.isRequired,
  onContinueButtonClick: PropTypes.func.isRequired,
  carInformation: PropTypes.shape({
    make: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    acrissCode: PropTypes.string,
  }).isRequired,
  pickUpDate: PropTypes.string.isRequired,
  dropOffDate: PropTypes.string.isRequired,
  vendorName: PropTypes.string.isRequired,
  priceDetails: PropTypes.shape({
    currency: PropTypes.string,
    ratePerDay: PropTypes.string,
  }).isRequired,
  source: PropTypes.shape({
    supplier: PropTypes.string,
  }).isRequired,
  laymanMode: PropTypes.bool.isRequired,
  isAccordionActive: PropTypes.bool.isRequired,
};

export default CarAccordionItemHeader;
