import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import classNames from 'classnames';
import routes from 'agentRoutes';
import DateTime from 'sharedWebpack/DateTime';
import Icon from '@wtag/rcl-icon';
import SanitizedHTML from 'sharedWebpack/SanitizedHTML';

const DateWithIcon = date => (
  <div className="date-with-icon">
    <div className="date-with-icon__icon">
      <Icon name="time" size="small" />
    </div>

    <DateTime dateTime={date} format="short" />
  </div>
);

const StaticCard = ({ orientation, className, post }) => (
  <div className={classNames(`blog-card blog-card--${orientation}`, className)}>
    <div className="blog-card__cover ">
      <img
        className="flex-img blog-card__cover-image"
        src={post.featureImage}
        alt="post_feature_image"
      />
      <div className="blog-card__header">
        <div className={classNames('circle', { 'circle--large': orientation === 'horizontal' })}>
          <Avatar
            size={orientation === 'horizontal' ? 'small' : 'tiny'}
            firstName={post.authorDetails.firstName}
            lastName={post.authorDetails.lastName}
            src={post.authorDetails.avatarUrl}
          />
        </div>
      </div>

      {orientation === 'horizontal-small' && (
        <div className="blog-card__date-with-icon">
          <DateWithIcon data={post.updatedAt} />
        </div>
      )}
    </div>
    <div className="blog-card__footer">
      {orientation === 'vertical' && <DateWithIcon data={post.updatedAt} />}

      <Link
        type="anchor"
        modifier="default"
        className="blog-card__footer-title"
        size="tiny"
        href={routes.public.blogPosts.show({ id: post.id })}
      >
        <div className="blog-card__footer-title">{post.name}</div>
      </Link>

      {orientation !== 'horizontal-small' && (
        <div className="blog-card__footer-details">
          <SanitizedHTML html={post.contentText} />
        </div>
      )}

      {orientation === 'horizontal' && <DateWithIcon data={post.updatedAt} />}
    </div>
  </div>
);

StaticCard.defaultProps = {
  orientation: 'horizontal',
};

StaticCard.propTypes = {
  orientation: PropTypes.string,
  className: PropTypes.string.isRequired,
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    featureImage: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    authorDetails: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default StaticCard;
