import React, { useState } from 'react';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import Alert from 'sharedWebpack/Alert';
import ReCaptchaV2 from 'react-google-recaptcha';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import PropTypes from 'prop-types';

import '../styles.scss';

const SignUpForm = ({ recaptchaSiteKey, signInPath }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const [errors, setErrors] = useState({});
  const [showVerificationEmailAlert, setShowVerificationEmailAlert] = useState(false);

  const signUpUser = async () => {
    const signUpParams = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    };

    const { data } = await httpClient.post(routes.public.authentication.people.create(), {
      sign_up: signUpParams,
      recaptcha_token: recaptchaToken,
    });

    if (data.errors === null && data.verificationEmailSent) {
      setShowVerificationEmailAlert(true);
    } else {
      window.location.assign(signInPath);
    }
  };

  const submitForm = event => {
    event.preventDefault();
    signUpUser().catch(({ response }) => {
      setErrors({});
      if (response.status === 422) {
        setErrors(response.data.errors);
        window.grecaptcha.reset();
      }
    });
  };

  const closeAlertText = () => {
    setErrors(prevErrors => ({ ...prevErrors, recaptcha: '' }));
  };

  return (
    <form onSubmit={submitForm}>
      <div className="authentication">
        <div className="authentication-form grid">
          <div className="col-12">
            <Alert
              className="authentication-form__alert"
              hideClose={false}
              isVisible={showVerificationEmailAlert}
              onClose={() => setShowVerificationEmailAlert(false)}
              type="success"
            >
              {I18n.t(
                'public.components.authentication.people.new.verification_email_alert_message',
              )}
            </Alert>
            <Alert
              className="authentication-form__alert"
              hideClose={false}
              isVisible={errors.recaptcha}
              onClose={closeAlertText}
              type="warning"
            >
              {errors.recaptcha}
            </Alert>
            <div className="authentication-form__title">
              {I18n.t('public.components.authentication.people.new.title')}
            </div>
            <div className="authentication-form__description--with-bottom-padding">
              {I18n.t('public.components.authentication.people.new.description')}
            </div>
            <div className="col-12 col-bleed authentication-form__field--with-bottom-padding">
              <Input
                required={true}
                size="small"
                placeholder={I18n.t(
                  'public.components.authentication.people.placeholders.first_name',
                )}
                label={I18n.t('public.components.authentication.people.labels.first_name')}
                onChange={value => setFirstName(value)}
                value={firstName}
                touched={!!errors.firstName}
                error={errors.firstName}
              />
            </div>
            <div className="col-12 col-bleed authentication-form__field--with-bottom-padding">
              <Input
                required={true}
                size="small"
                placeholder={I18n.t(
                  'public.components.authentication.people.placeholders.last_name',
                )}
                label={I18n.t('public.components.authentication.people.labels.last_name')}
                onChange={value => setLastName(value)}
                value={lastName}
                touched={!!errors.lastName}
                error={errors.lastName}
              />
            </div>
            <div className="col-12 col-bleed authentication-form__field--with-bottom-padding">
              <Input
                required={true}
                type="email"
                size="small"
                placeholder={I18n.t('public.components.authentication.people.placeholders.email')}
                label={I18n.t('public.components.authentication.people.labels.email')}
                onChange={value => setEmail(value)}
                value={email}
                touched={!!errors.email}
                error={errors.email}
              />
            </div>
            <div className="col-12 col-bleed authentication-form__field--with-bottom-padding">
              <Input
                required={true}
                type="password"
                size="small"
                placeholder={I18n.t(
                  'public.components.authentication.people.placeholders.password',
                )}
                label={I18n.t('public.components.authentication.people.labels.password')}
                onChange={value => setPassword(value)}
                value={password}
                touched={!!errors.password}
                error={errors.password}
              />
            </div>
            <div className="authentication-form__description--with-padding captcha">
              <ReCaptchaV2
                sitekey={recaptchaSiteKey}
                onChange={value => setRecaptchaToken(value)}
              />
            </div>
            <div className="authentication-form__description--with-padding">
              {I18n.t('public.components.authentication.people.new.footer')}
            </div>
            <div className="col-12 col-bleed col-grid direction-row authentication-form__button--with-top-padding">
              <Button
                submitType="submit"
                version="v2"
                size="normal"
                primary={true}
                label={I18n.t('public.components.authentication.people.form.sign_up')}
                onClick={submitForm}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

SignUpForm.propTypes = {
  recaptchaSiteKey: PropTypes.string.isRequired,
  signInPath: PropTypes.string.isRequired,
};

export default SignUpForm;
