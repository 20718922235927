import React from 'react';
import PropTypes from 'prop-types';

const FlightJourneyIndicator = ({ origin, destination }) => (
  <span className="flight-journey-indicator">
    <span className="flight-journey-indicator__origin-destination">
      {origin} <span className="flight-journey-indicator--seperator">-</span> {destination}
    </span>
  </span>
);

FlightJourneyIndicator.propTypes = {
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default FlightJourneyIndicator;
