import PropTypes from 'prop-types';

export default PropTypes.shape({
  deals: PropTypes.shape({
    total: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  stories: PropTypes.shape({
    total: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  airports: PropTypes.shape({
    total: PropTypes.number.isRequired,
    url: PropTypes.string,
  }).isRequired,
  airlines: PropTypes.shape({
    total: PropTypes.number.isRequired,
    url: PropTypes.string,
  }).isRequired,
  destination: PropTypes.shape({
    total: PropTypes.number.isRequired,
    url: PropTypes.string,
  }).isRequired,
  reviews: PropTypes.shape({
    total: PropTypes.number.isRequired,
    url: PropTypes.string,
  }).isRequired,
  community: PropTypes.shape({
    total: PropTypes.number.isRequired,
    url: PropTypes.string,
  }).isRequired,
  travelNews: PropTypes.shape({
    total: PropTypes.number.isRequired,
    url: PropTypes.string,
  }).isRequired,
});
