import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import Input from '@wtag/rcl-input';
import Tooltip from '@wtag/rcl-tooltip';
import { I18nText, Spinner } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import OrderList from 'sharedWebpack/Orders/List';
import Modal from 'sharedWebpack/Modal';
import { validate, orderTitleValidation } from 'sharedWebpack/IBE/lib/validations';
import routes from 'agentRoutes';
import history from '../../lib/history';
import AncillariesUnavailabilityModal from '../AncillariesUnavailabilityModal';

import {
  PROCEDURE_MAPPING,
  ORDER_STYLES,
  BOOKING_STYLES,
  cartHasItemWithCreditCard,
} from '../helpers/booking';
import './styles.scss';
import PayLaterItem from '../PayLaterItem';

const displayColumns = {
  id: true,
  title: true,
  destination: true,
  travellers: true,
  products: true,
  state: true,
  total: true,
  startDate: true,
  endDate: true,
  actions: false,
  selectAction: true,
};

const urlsPath = {
  new: routes.admin.newOrder(),
};

const displaySortingPills = {
  id: true,
  destination: true,
  lastUpdated: false,
  startDate: true,
};

class AgentBookButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      orderStyle: null,
      orderId: null,
      bookingStyle: null,
      loadIframe: false,
      bookingStepCount: 1,
      unavailableServices: {},
      openUnavailableAncillariesModal: false,
      insufficientWalletBalance: false,
      isCreateQuote: false,
      orderTitle: this.props.cartTitle,
      error: {},
    };
  }

  onModalOpen = (isCreateQuote = false) => {
    const existingOrderId = this.context.callbackParams.order_id;

    this.setState({
      modalOpen: true,
      orderStyle: existingOrderId && ORDER_STYLES.EXISTING,
      orderId: existingOrderId,
      bookingStyle: null,
      loadIframe: false,
      isCreateQuote,
    });
  };

  onModalClose = () => {
    this.setState({
      modalOpen: false,
      bookingStepCount: 1,
      isCreateQuote: false,
    });
  };

  onConfirmInsufficientWalletBalanceModal = () => {
    this.props.resetFormOfPayment().then(() => {
      this.setState({ insufficientWalletBalance: false });
      history.push(`/orders/${this.props.cartId}/payment`);
    });
  };

  onOrderTitleChange = value => {
    const error = validate({ orderTitle: value }, orderTitleValidation);
    this.setState({ error, orderTitle: value });
  };

  setLoadIframe = bookingStyle => {
    if (cartHasItemWithCreditCard(this.props.cartItems)) {
      this.setState({ loadIframe: true, bookingStyle, modalOpen: false }, () => {
        this.createBooking();
      });
    } else {
      this.props.setIsBookingButtonClicked(true);
      this.setState({ bookingStyle, modalOpen: false }, () => {
        this.createBooking();
      });
    }
  };

  handleReject = () => {
    this.props.setIsBookingButtonClicked(false);
    this.setState({
      bookingStyle: null,
      loadIframe: false,
      insufficientWalletBalance: false,
    });
  };

  createBooking = () => {
    this.props.checkCartAvailability().then(response => {
      if (response.insufficientWalletBalance) {
        this.setState({ insufficientWalletBalance: response.insufficientWalletBalance });
      } else if (Object.values(response.unavailableServices).length > 0) {
        this.setState({
          unavailableServices: response.unavailableServices,
          openUnavailableAncillariesModal: true,
        });
      } else if (cartHasItemWithCreditCard(this.props.cartItems)) {
        this.continueBookingByCCV();
      } else {
        this.continueBookingByCash();
      }
    });
  };

  continueBookingByCCV = () => {
    if (this.props.bookingPossible) {
      this.chooseOrderCreationStyle(this.state.bookingStyle).then(this.bookAllItemsWithDebounce);
    }
  };

  continueBookingByCash = () => {
    if (this.props.bookingPossible) {
      this.chooseOrderCreationStyle(this.state.bookingStyle).then(() =>
        this.chooseBookingStyle(this.state.bookingStyle),
      );
    }
  };

  bookAllItems = () => {
    this.props.ccTokensThatNeedToBook.forEach(ccToken => {
      this.props.bookingComponentRefByToken[ccToken].current.submitCreditCardAction();
    });
    // We are delaying this by 2 seconds for each bookingComponent we have mounted
    // as from ccv side they wait 2 seconds after submitting booking. So that
    // We don't unmount the component before booking triggers
    setTimeout(() => {
      this.chooseBookingStyle(this.state.bookingStyle);
      this.setState({ loadIframe: false });
    }, this.props.ccTokensThatNeedToBook.length * 2000);
  };

  bookAllItemsWithDebounce = debounce(10000, true, this.bookAllItems);

  handleOrderStyleSelection = orderStyle => {
    this.setState({ orderStyle, bookingStepCount: this.state.bookingStepCount + 1 });
  };

  chooseOrder = order => {
    this.setState({ orderId: order.id });
  };

  chooseOrderCreationStyle = orderCreationStyle => {
    const procedure = PROCEDURE_MAPPING[this.state.orderStyle][orderCreationStyle];
    const { orderId, orderTitle } = this.state;

    return this.props.prepareForBooking({
      procedure,
      orderId,
      orderTitle,
    });
  };

  chooseBookingStyle = bookingStyle => {
    const procedure = PROCEDURE_MAPPING[this.state.orderStyle][bookingStyle];
    this.props.startBooking({ procedure, orderId: this.state.orderId });
    this.setState({ modalOpen: false });
  };

  continueBookingOnUnavailableAncillaries = itemIds => {
    this.props.removeItems(itemIds).then(() => {
      if (cartHasItemWithCreditCard(this.props.cartItems)) {
        this.continueBookingByCCV();
      } else {
        this.continueBookingByCash();
      }
    });
  };

  render() {
    let content;

    const isCreateQuoteEnable = !this.props.onlyOneItemFromSearch && this.props.showQuoteButton;

    const createOrder = () => {
      this.handleOrderStyleSelection(ORDER_STYLES.NEW);
    };

    const createQuote = (orderStyle = ORDER_STYLES.NEW) => {
      this.props.setIsBookingButtonClicked(true);
      this.setState({ orderStyle, modalOpen: true }, () => {
        this.chooseOrderCreationStyle(BOOKING_STYLES.QUOTE).then(() => {
          this.chooseBookingStyle(BOOKING_STYLES.QUOTE);
        });
      });
    };

    const isBookingDisabled = Object.keys(this.state.error).length > 0;

    const setOrderListOrderId = id => {
      if (this.state.isCreateQuote && this.state.bookingStepCount === 2) {
        this.setState({ orderId: id, orderStyle: ORDER_STYLES.EXISTING }, () => {
          createQuote(ORDER_STYLES.EXISTING);
        });
      } else {
        this.setState({ orderId: id, bookingStepCount: this.state.bookingStepCount + 1 });
      }
    };

    const createNewOrder = () =>
      this.state.isCreateQuote ? createQuote(ORDER_STYLES.NEW) : createOrder();

    const header = I18n.t('components.ibe.check_out_progress.step_count', {
      count: this.state.bookingStepCount,
    });
    if (!this.state.orderStyle && !this.state.orderId) {
      content = (
        <Fragment>
          <div className="grid">
            <div className="col-12 book-buttons__icon">
              <Icon name="add" showBGColor={true} size="large" />
            </div>
            <div className="col-12">
              <I18nText id="ibe.check_out_progress.texts.new_order_text" returnStringOnly={true} />
            </div>
            <div className="col-12 book-button__modal-button">
              <Button
                version="v2"
                size="small"
                label={I18n.t('components.ibe.check_out_progress.buttons.create_new_order')}
                primary={true}
                onClick={() => createNewOrder()}
              />
            </div>
          </div>

          <div className="grid">
            <div className="col-12 book-buttons__icon">
              <Icon name="addOrder" showBGColor={true} size="large" />
            </div>
            <div className="col-12">
              <I18nText
                id="ibe.check_out_progress.texts.select_order_choice"
                returnStringOnly={true}
              />
            </div>
            <div className="col-12 book-button__modal-button">
              <Button
                version="v2"
                size="small"
                label={I18n.t('components.ibe.check_out_progress.buttons.add_to_existing_order')}
                primary={true}
                onClick={() => this.handleOrderStyleSelection(ORDER_STYLES.EXISTING)}
              />
            </div>
          </div>
        </Fragment>
      );
    } else if (this.state.orderStyle === ORDER_STYLES.EXISTING && !this.state.orderId) {
      content = (
        <OrderList
          ordersPath={routes.admin.orders()}
          displayColumns={displayColumns}
          displaySortingPills={displaySortingPills}
          hideArchived={true}
          lessPage={true}
          urls={urlsPath}
          setOrderId={id => setOrderListOrderId(id)}
        />
      );
    } else {
      content = (
        <Fragment>
          <div className="grid">
            {!this.state.orderId && (
              <div className="col-12 col-bleed">
                <Input
                  label={I18n.t('components.ibe.order.title')}
                  placeholder={I18n.t('components.ibe.order.placeholder.title')}
                  onChange={this.onOrderTitleChange}
                  value={this.state.orderTitle}
                  size="tiny"
                  required={true}
                  error={this.state.error?.orderTitle}
                  touched={true}
                />
              </div>
            )}
            <div className="col-4 col-bleed">
              <div className="col-12 book-buttons__icon">
                <Icon name="listBulleted" showBGColor={true} size="large" />
              </div>
              <div className="col-12 book-buttons__modal-text">
                <I18nText
                  id="ibe.check_out_progress.texts.payment_choice_text"
                  returnStringOnly={true}
                />
              </div>
              <div className="col-12 book-button__modal-button">
                <Button
                  version="v2"
                  size="small"
                  label={I18n.t('components.ibe.check_out_progress.buttons.quote')}
                  primary={true}
                  disabled={isBookingDisabled}
                  onClick={() => createQuote()}
                />
              </div>
            </div>

            <div className="col-4 col-bleed">
              <div className="col-12 book-buttons__icon">
                <Icon name="listNumbered" showBGColor={true} size="large" />
              </div>
              <div className="col-12 book-buttons__modal-text">
                <I18nText
                  id="ibe.check_out_progress.texts.create_option_text"
                  returnStringOnly={true}
                />
              </div>
              <div className="col-12 book-button__modal-button">
                <Button
                  version="v2"
                  size="small"
                  label={I18n.t('components.ibe.check_out_progress.buttons.create_option')}
                  primary={true}
                  onClick={() => this.setLoadIframe(BOOKING_STYLES.OPTION)}
                  disabled={
                    isBookingDisabled ||
                    !this.props.createOptionPossible ||
                    this.props.disableBooking
                  }
                />
              </div>
              {!this.props.createOptionPossible && (
                <div className="col-12">
                  <I18nText
                    id="ibe.check_out_progress.texts.non_refundable_card_text"
                    returnStringOnly={true}
                  />
                </div>
              )}
            </div>

            <div className="col-4 col-bleed">
              <div className="col-12 book-buttons__icon">
                <Icon name="orders" showBGColor={true} size="large" />
              </div>
              <div className="col-12 book-buttons__modal-text">
                <I18nText
                  id="ibe.check_out_progress.texts.firm_booking_text"
                  returnStringOnly={true}
                />
              </div>
              <div className="col-12 book-button__modal-button">
                <Button
                  version="v2"
                  size="small"
                  label={
                    this.state.loadIframe || this.props.disabled ? (
                      <div className="col-grid col-bleed direction-row align-center">
                        <span>
                          {I18n.t('components.ibe.check_out_progress.buttons.create_booking')}
                        </span>
                        <div className="book-buttons__wrapper-component">
                          <Spinner size="tiny" bgColor="neutral" />
                        </div>
                      </div>
                    ) : (
                      I18n.t('components.ibe.check_out_progress.buttons.create_booking')
                    )
                  }
                  disabled={
                    isBookingDisabled ||
                    this.state.loadIframe ||
                    this.props.disabled ||
                    this.props.disableBooking
                  }
                  primary={true}
                  onClick={() => {
                    this.setLoadIframe(BOOKING_STYLES.REAL);
                  }}
                />
              </div>
              {this.state.loadIframe && (
                <div className="col-12">
                  <I18nText
                    id="ibe.check_out_progress.texts.authenticating_payments"
                    returnStringOnly={true}
                  />
                </div>
              )}
            </div>

            {this.props.payLaterItemsToBeQuoted.length > 0 && (
              <div className="col-12 pay-later-info">
                <div className="pay-later-info__border">
                  <div className="pay-later-info__warning-text-container">
                    <div className="pay-later-info__warning-text-container__data">
                      {this.props.disableBooking && (
                        <I18nText
                          id="ibe.booking.pay_later.all_not_supported"
                          returnStringOnly={true}
                        />
                      )}

                      {!this.props.disableBooking &&
                        this.props.payLaterItemsToBeQuoted.length > 0 && (
                          <I18nText
                            id="ibe.booking.pay_later.some_not_supported"
                            returnStringOnly={true}
                          />
                        )}
                    </div>
                  </div>

                  {!this.props.disableBooking && (
                    <div className="pay-later-info__items grid grid-gap-20">
                      {this.props.payLaterItemsToBeQuoted.map(item => (
                        <div className="col-4 ">
                          <PayLaterItem item={item} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Fragment>
      );
    }

    const step1ModalContent = () => (
      <Modal
        className="booking-progress-modal"
        title={
          <Fragment>
            {header}
            <div onClick={this.onModalClose} onKeyDown={this.onModalClose} role="presentation">
              <Icon name="close" />
            </div>
          </Fragment>
        }
        isModalVisible={this.state.modalOpen}
        onOutsideClick={this.onModalClose}
        alignContent="left"
      >
        {content}
      </Modal>
    );

    const bookNowButton = (
      <Button
        label={
          <Fragment>
            {I18n.t('components.ibe.check_out_progress.buttons.book')}
            {this.props.isBookingButtonClicked && <Spinner size="tiny" bgColor="neutral" />}
          </Fragment>
        }
        version="v2"
        size="small"
        primary={!isCreateQuoteEnable}
        onClick={() => this.onModalOpen()}
        disabled={this.props.disabled || isCreateQuoteEnable || this.props.isBookingButtonClicked}
        className="book-buttons__button-contents-button"
      />
    );

    return (
      <div className="book-buttons">
        <AncillariesUnavailabilityModal
          unavailableServices={this.state.unavailableServices}
          modalOpen={this.state.openUnavailableAncillariesModal}
          cartId={this.props.cartId}
          fetchJourneyElement={this.props.fetchJourneyElement}
          onContinueBooking={this.continueBookingOnUnavailableAncillaries}
          removeItems={this.props.removeItems}
        />
        <ConfirmationModal
          confirmationHeader={
            <I18nText
              id="ibe.check_out_progress.insufficient_wallet_balance"
              returnStringOnly={true}
            />
          }
          subHeader={
            <I18nText
              id="ibe.check_out_progress.texts.insufficient_wallet_balance"
              returnStringOnly={true}
            />
          }
          type="danger"
          withAction={true}
          confirmationText={I18n.t('admin.shared.action.confirm')}
          rejectionText={I18n.t('admin.shared.action.close')}
          onConfirm={this.onConfirmInsufficientWalletBalanceModal}
          onReject={this.handleReject}
          isModalOpen={this.state.insufficientWalletBalance}
        />
        <div className="d-flex align-center justify-end book-buttons__button-contents">
          <div className="book-buttons__button-contents-button">
            <Button
              version="v2"
              size="small"
              primary={isCreateQuoteEnable}
              label={I18n.t('components.ibe.check_out_progress.buttons.quote')}
              onClick={() => this.onModalOpen(true)}
              disabled={this.state.loadIframe || this.props.isBookingButtonClicked}
              icon={<Icon name="addOrder" />}
            >
              {step1ModalContent()}
            </Button>
          </div>
          {isCreateQuoteEnable ? (
            <Tooltip
              className="book-buttons__tooltip"
              content={
                <I18nText id="ibe.book_quote.only_one_item_per_search" returnStringOnly={true} />
              }
              position="bottom-right"
              type="inverse"
              showArrow={false}
            >
              {bookNowButton}
            </Tooltip>
          ) : (
            bookNowButton
          )}
          {step1ModalContent()}
        </div>
      </div>
    );
  }
}

AgentBookButton.defaultProps = {
  isBookingButtonClicked: false,
};

AgentBookButton.propTypes = {
  cartItems: PropTypes.shape([]).isRequired,
  payLaterItemsToBeQuoted: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onlyOneItemFromSearch: PropTypes.bool.isRequired,
  cartTitle: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
  bookingPossible: PropTypes.bool.isRequired,
  checkCartAvailability: PropTypes.func.isRequired,
  ccTokensThatNeedToBook: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool.isRequired,
  startBooking: PropTypes.func.isRequired,
  prepareForBooking: PropTypes.func.isRequired,
  createOptionPossible: PropTypes.bool.isRequired,
  isBookingButtonClicked: PropTypes.bool,
  setIsBookingButtonClicked: PropTypes.func.isRequired,
  bookingComponentRefByToken: PropTypes.node.isRequired,
  disableBooking: PropTypes.bool.isRequired,
  fetchJourneyElement: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired,
  resetFormOfPayment: PropTypes.func.isRequired,
  showQuoteButton: PropTypes.bool.isRequired,
};

AgentBookButton.contextTypes = {
  callbackParams: PropTypes.shape({
    order_id: PropTypes.string,
  }).isRequired,
};

export default AgentBookButton;
