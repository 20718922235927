import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Card from '@wtag/rcl-card';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import CommentPlaceholder from '../../../Placeholder/CommentPlaceholder';
import CarLoyaltyProgramForm from './CarLoyaltyProgramForm';
import CarLoyaltyProgramCard from './CarLoyaltyProgramCard';
import HotelLoyaltyProgramForm from './HotelLoyaltyProgramForm';
import HotelLoyaltyProgramCard from './HotelLoyaltyProgramCard';

const LoyaltyProgramsSection = ({ personId, carCompanies, hotelLoyaltyList, layman }) => {
  const [carError, setCarError] = useState({});
  const [carLoyaltyNumber, setCarLoyaltyNumber] = useState('');
  const [carLoyaltyProgram, setCarLoyaltyProgram] = useState('');
  const [carLoyaltyPrograms, setCarLoyaltyPrograms] = useState([]);
  const [hotelError, setHotelError] = useState({});
  const [hotelGuestNumber, setHotelGuestNumber] = useState('');
  const [hotelGuestProgram, setHotelGuestProgram] = useState('');
  const [hotelLoyaltyPrograms, setHotelLoyaltyPrograms] = useState([]);
  const [isLoadingCar, setIsLoadingCar] = useState(true);
  const [isLoadingHotel, setIsLoadingHotel] = useState(true);

  let loyaltyRoutes;

  if (layman) {
    loyaltyRoutes = {
      car: {
        list: routes.public.carLoyaltyPrograms.list({}),
        create: routes.public.carLoyaltyPrograms.create({}),
        update: routes.public.carLoyaltyPrograms.update.bind(),
        delete: routes.public.carLoyaltyPrograms.delete.bind(),
      },
      hotel: {
        list: routes.public.hotelLoyaltyPrograms.list({}),
        create: routes.public.hotelLoyaltyPrograms.create({}),
        update: routes.public.hotelLoyaltyPrograms.update.bind(),
        delete: routes.public.hotelLoyaltyPrograms.delete.bind(),
      },
    };
  } else {
    loyaltyRoutes = {
      car: {
        list: routes.admin.people.carLoyaltyPrograms.list({ personId }),
        create: routes.admin.people.carLoyaltyPrograms.create({ personId }),
        update: routes.admin.people.carLoyaltyPrograms.update.bind(),
        delete: routes.admin.people.carLoyaltyPrograms.delete.bind(),
      },
      hotel: {
        list: routes.admin.people.hotelLoyaltyPrograms.list({ personId }),
        create: routes.admin.people.hotelLoyaltyPrograms.create({ personId }),
        update: routes.admin.people.hotelLoyaltyPrograms.update.bind(),
        delete: routes.admin.people.hotelLoyaltyPrograms.delete.bind(),
      },
    };
  }

  const fetchCarLoyaltyPrograms = async () => {
    const { data } = await httpClient.get(loyaltyRoutes.car.list);
    setIsLoadingCar(false);
    setCarLoyaltyPrograms(data.carLoyaltyPrograms);
  };

  const fetchHotelLoyaltyPrograms = async () => {
    const { data } = await httpClient.get(loyaltyRoutes.hotel.list);
    setIsLoadingHotel(false);
    setHotelLoyaltyPrograms(data.hotelLoyaltyPrograms);
  };

  useEffect(() => {
    fetchCarLoyaltyPrograms();
    fetchHotelLoyaltyPrograms();
  }, []);

  const createCarLoyaltyProgram = async () => {
    const carLoyaltyProgramParams = {
      car_loyalty_number: carLoyaltyNumber,
      car_loyalty_program: carLoyaltyProgram,
      person_id: personId,
    };

    const { data } = await httpClient.post(loyaltyRoutes.car.create, {
      car_loyalty_program: carLoyaltyProgramParams,
    });

    if (data.error === null) {
      fetchCarLoyaltyPrograms();
    }
  };

  const onCreateCarLoyaltyPrograms = () =>
    createCarLoyaltyProgram().catch(({ response }) => {
      if (response.status === 422) {
        setCarError(response.data.error);
      }
    });

  const createHotelLoyaltyProgram = async () => {
    const hotelLoyaltyProgramParams = {
      hotel_guest_number: hotelGuestNumber,
      hotel_guest_program: hotelGuestProgram,
      person_id: personId,
    };

    const { data } = await httpClient.post(loyaltyRoutes.hotel.create, {
      hotel_loyalty_program: hotelLoyaltyProgramParams,
    });

    if (data.error === null) {
      fetchHotelLoyaltyPrograms();
    }
  };

  const onCreateHotelLoyaltyPrograms = () =>
    createHotelLoyaltyProgram().catch(({ response }) => {
      if (response.status === 422) {
        setHotelError(response.data.error);
      }
    });

  const renderPlaceHolder = (isLoading, emptyCard) => {
    if (isLoading) {
      return (
        <CommentPlaceholder
          numberOfCards={2}
          numberOfRows={2}
          removeContentPadding={true}
          removeBoxShadow={true}
        />
      );
    }
    return emptyCard;
  };

  return (
    <Fragment>
      <div className="col-grid align-center traveller-edit-loyalty-program-section">
        <div className="col-grid direction-row col-bleed align-end justify-space-between">
          <div className="traveller-edit-loyalty-program-section__header">
            {I18n.t('admin.components.travellers.edit.loyalty_programs.hotel_loyalty.title')}
          </div>
        </div>
        <div className="col-12 col-bleed traveller-edit-loyalty-program-form">
          <div className="grid">
            <div className="col-lg-10">
              <HotelLoyaltyProgramForm
                hotelLoyaltyList={hotelLoyaltyList}
                setNumber={setHotelGuestNumber}
                number={hotelGuestNumber}
                setProgram={setHotelGuestProgram}
                program={hotelGuestProgram}
                hotelError={hotelError}
              />
            </div>

            <div className="col-3 col-sm-2 col-lg-2 col-grid justify-center traveller-edit-loyalty-program-form__message traveller-edit-loyalty-program-form__message--darker">
              <Button
                version="v2"
                size="small"
                type={layman ? 'primary' : 'tertiary'}
                label={I18n.t('admin.shared.action.add')}
                onClick={onCreateHotelLoyaltyPrograms}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-bleed">
        {hotelLoyaltyPrograms && hotelLoyaltyPrograms.length > 0
          ? hotelLoyaltyPrograms.map(loyaltyProgram => (
              <HotelLoyaltyProgramCard
                key={loyaltyProgram.id}
                personId={personId}
                hotelLoyaltyProgram={loyaltyProgram}
                fetchHotelLoyaltyPrograms={fetchHotelLoyaltyPrograms}
                hotelLoyaltyList={hotelLoyaltyList}
                hotelRoutes={loyaltyRoutes.hotel}
              />
            ))
          : renderPlaceHolder(
              isLoadingHotel,
              <Card
                version="v2"
                className="loyalty-program traveller-edit-loyalty-program__empty-card"
                color="tertiary"
                emptyCardImageSrc={<Icon name="hotelRoom" />}
                emptyCardText={I18n.t(
                  'admin.components.travellers.edit.loyalty_programs.hotel_loyalty.unavailable',
                )}
              />,
            )}
      </div>
      <div className="col-grid align-center traveller-edit-loyalty-program-section">
        <div className="col-grid direction-row col-bleed align-end justify-space-between">
          <div className="traveller-edit-loyalty-program-section__header">
            {I18n.t('admin.components.travellers.edit.loyalty_programs.car_loyalty.title')}
          </div>
        </div>
        <div className="col-12 col-bleed traveller-edit-loyalty-program-form">
          <div className="grid">
            <div className="col-lg-10">
              <CarLoyaltyProgramForm
                carCompanies={carCompanies}
                number={carLoyaltyNumber}
                setNumber={setCarLoyaltyNumber}
                program={carLoyaltyProgram}
                setProgram={setCarLoyaltyProgram}
                carError={carError}
              />
            </div>

            <div className="col-3 col-sm-2 col-lg-2 col-grid justify-center traveller-edit-loyalty-program-form__message traveller-edit-loyalty-programs-form__message--darker">
              <Button
                version="v2"
                size="small"
                type={layman ? 'primary' : 'tertiary'}
                label={I18n.t('admin.shared.action.add')}
                onClick={onCreateCarLoyaltyPrograms}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-bleed">
        {carLoyaltyPrograms && carLoyaltyPrograms.length > 0
          ? carLoyaltyPrograms.map(loyaltyProgram => (
              <CarLoyaltyProgramCard
                key={loyaltyProgram.id}
                personId={personId}
                carLoyaltyProgram={loyaltyProgram}
                fetchCarLoyaltyPrograms={fetchCarLoyaltyPrograms}
                carCompanies={carCompanies}
                carRoutes={loyaltyRoutes.car}
              />
            ))
          : renderPlaceHolder(
              isLoadingCar,
              <Card
                version="v2"
                className="loyalty-program traveller-edit-loyalty-program__empty-card"
                color="tertiary"
                emptyCardImageSrc={<Icon name="car" />}
                emptyCardText={I18n.t(
                  'admin.components.travellers.edit.loyalty_programs.car_loyalty.unavailable',
                )}
              />,
            )}
      </div>
    </Fragment>
  );
};

LoyaltyProgramsSection.defaultProps = {
  layman: false,
};

LoyaltyProgramsSection.propTypes = {
  personId: PropTypes.number.isRequired,
  carCompanies: PropTypes.shape({}).isRequired,
  hotelLoyaltyList: PropTypes.shape({}).isRequired,
  layman: PropTypes.bool,
};

export default LoyaltyProgramsSection;
