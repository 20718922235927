import React, { useState, useEffect } from 'react';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu, MenuItem } from '@wtag/rcl-menu';
import { Spinner } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Link from '@wtag/rcl-link';
import DateTime from 'sharedWebpack/DateTime';
import history from '../lib/history';
import { CART_STATUS_BOOKED, CART_STATUS_PAID } from '../actions/common';
import MenuLabel from '../Search/helpers/MenuLabel';
import '../Search/styles.scss';

const cartStatusMap = {
  booked: 'cartOrdered',
  expired: 'cartInvalid',
  locked: 'cartShare',
  open: 'cartShare',
  paid: 'cartOrdered',
};

const SharedCarts = ({ laymanMode, setShowBottomComponents, callbackParams }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carts, setCarts] = useState([]);

  const fetchSharedCarts = () => {
    setIsLoading(true);

    httpClient
      .get(routes.api.carts.all({ per_page: laymanMode ? 1 : 6, callbackParams }))
      .then(response => {
        setCarts(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        throw error;
      });
  };

  const generateRelativePath = (cart, isFromSharedCarts) => {
    setShowBottomComponents(false);

    const isCartBooked = cart.state === CART_STATUS_BOOKED || cart.state === CART_STATUS_PAID;
    let search = null;

    if (isFromSharedCarts) {
      search = '?fromSharedCarts=true';
    }

    if (isCartBooked && !isFromSharedCarts) {
      const path = routes.admin.order({
        format: 'html',
        id: cart.orderId,
      });

      window.location.assign(path);
    } else if (isCartBooked) {
      history.push({ pathname: `/orders/${cart.id}/booking`, search });
    } else {
      history.push({ pathname: `/carts/${cart.id}`, search });
    }
  };

  const onDropDownClick = () => {
    setIsVisible(previousIsVisibleState => !previousIsVisibleState);
  };

  const getCartShareStatusIcon = cartItem => {
    if (cartItem.state === CART_STATUS_BOOKED || cartItem.state === CART_STATUS_PAID) {
      return cartStatusMap[cartItem.state];
    }

    if (cartItem.expiredAt) {
      return cartStatusMap.expired;
    }

    return cartStatusMap[cartItem.state];
  };

  const viewAllCartsLink = () => {
    if (!laymanMode && carts.length > 5) {
      const cartsShareListPath = routes.admin.carts.list({ format: 'html' });

      return (
        <MenuItem className="shared-cart__show-more">
          <Link href={cartsShareListPath} modifier="tertiary" type="anchor" size="tiny">
            {I18n.t('components.ibe.search_form.view_shared_carts')}
          </Link>
        </MenuItem>
      );
    }

    return null;
  };

  const contentLoader = (
    <div className="col-grid col-bleed align-center">
      <Spinner size="large" bgColor="neutral" />
    </div>
  );

  const sharedCartsComponent =
    carts.length > 0 ? (
      <React.Fragment>
        {carts.slice(0, 5).map(cart => (
          <MenuItem key={cart.id}>
            <Link
              onClick={() => {
                generateRelativePath(cart, laymanMode);
              }}
              modifier="tertiary"
              size="tiny"
            >
              <div className="col-grid col-bleed direction-row align-center">
                <Icon
                  name={getCartShareStatusIcon(cart)}
                  showBGColor={true}
                  size="normal"
                  color="tertiary"
                />
                <div>
                  <div className="shared-cart__title">{cart.sharedTitle}</div>
                  {cart.sharedAt && (
                    <div className="shared-cart__starts-at">
                      <DateTime dateTime={cart.sharedAt} format="shortWithTime" />
                    </div>
                  )}
                </div>
              </div>
            </Link>
          </MenuItem>
        ))}
        {viewAllCartsLink()}
      </React.Fragment>
    ) : (
      <div
        className={classNames('shared-cart--empty', {
          'shared-cart--empty-laymanmode': laymanMode,
        })}
      >
        <Icon name="shoppingCart" size="large" showBGColor={true} color="tertiary" />
        <div className="shared-cart--empty-description">
          {I18n.t('components.ibe.search_form.empty_shared_carts')}
        </div>
      </div>
    );

  const content = isLoading ? contentLoader : sharedCartsComponent;

  useEffect(() => {
    fetchSharedCarts();
  }, []);

  return (
    <div className="search-menu__container search-menu__container--shared-carts">
      <Menu
        className="search-menu__menu shared-cart__menu"
        isVisible={isVisible}
        size="medium"
        popOverDirection="bottom-right"
        onOutsideClick={() => setIsVisible(false)}
        label={
          <MenuLabel
            onClick={onDropDownClick}
            label={I18n.t('components.ibe.search_form.shared_carts')}
          />
        }
      >
        {content}
      </Menu>
    </div>
  );
};

SharedCarts.propTypes = {
  laymanMode: PropTypes.bool.isRequired,
  setShowBottomComponents: PropTypes.func.isRequired,
  callbackParams: PropTypes.shape().isRequired,
};

export default SharedCarts;
