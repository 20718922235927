import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';
import { DateFilter, Link, DropDownFilter } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import moment from 'moment';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import Pagination from 'sharedWebpack/Pagination';
import SortingPill from 'sharedWebpack/SortingPill';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';

import Table from './Table';
import ExportModal from './ExportModal';
import './styles.scss';

const TravelExpensesTab = ({ travelExpensesPath }) => {
  const [travelExpenses, setTravelExpenses] = useState([]);
  const [totalExpensesAmount, setTotalExpensesAmount] = useState('');
  const [totalPages, setTotalPage] = useState(1);
  const [urls, setUrls] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [query, setQuery] = useQueryParams({
    q: withDefault(StringParam, ''),
    page: NumberParam,
    sortColumn: StringParam,
    sortDirection: StringParam,
    startDate: withDefault(StringParam, ''),
    endDate: withDefault(StringParam, ''),
    scope: withDefault(StringParam, ''),
    tab: StringParam,
  });
  const componentMounted = useRef(false);
  const { q, page, sortColumn, sortDirection, startDate, endDate, scope } = query;
  const [localSearchQuery, setLocalSearchQuery] = useState(q);

  const ordersTypeOptions = [
    {
      label: I18n.t('admin.components.travel_expenses.related_to_order'),
      value: 'related_to_order',
    },
    {
      label: I18n.t('admin.components.travel_expenses.unrelated_to_order'),
      value: 'unrelated_to_order',
    },
  ];

  const [selectedOrdersRelation, setSelectedOrdersRelation] = useState(null);

  const setInitialParams = (data, searchQuery) => {
    // We only set the params once when there is nothing in the url
    if (!query.page) {
      setQuery(
        {
          tab: 'travel_expenses',
          q: searchQuery,
          page: data.pagination.currentPage,
          sortColumn: data.sort.column,
          sortDirection: data.sort.direction,
        },
        // use replaceIn as we don't want another navigation to be added to the browser history
        'replaceIn',
      );
    }
  };

  const fetchTravelExpenses = args => {
    const { searchQuery, newPage, column, direction, startingDate, endingDate, apiToken } = args;

    if (componentMounted.current) {
      setIsLoading(true);
    }

    httpClient
      .get(travelExpensesPath, {
        params: {
          q: searchQuery,
          page: newPage,
          column,
          direction,
          scope,
          start_date: startingDate,
          end_date: endingDate,
        },
        cancelToken: apiToken,
      })
      .then(({ data }) => {
        if (componentMounted.current) {
          setTravelExpenses(data.travelExpenses);
          setTotalExpensesAmount(data.totalTravelExpenses);
          setTotalPage(data.pagination.totalPages);
          setUrls(data.urls);
          setInitialParams(data, searchQuery);
          setIsLoading(false);
        }
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        if (componentMounted.current) {
          setIsLoading(false);
        }

        throw error;
      });
  };

  const setSearchQueryInParams = React.useRef(
    debounce(500, value => setQuery({ q: value, page: 1 })),
  ).current;

  const setSearchQuery = value => {
    setLocalSearchQuery(value);
    setSearchQueryInParams(value);
  };

  const updateSorting = (column, direction) => {
    setQuery({ sortColumn: column, sortDirection: direction });
  };

  const deleteTravelExpense = async deleteUrl => {
    await httpClient.delete(deleteUrl);

    fetchTravelExpenses(q, page, sortColumn, sortDirection);
  };

  const setDateRange = dates => {
    if (dates.startDate && dates.endDate) {
      setQuery({
        startDate: dates.startDate.format('YYYY-MM-DD'),
        endDate: dates.endDate.format('YYYY-MM-DD'),
      });

      fetchTravelExpenses({
        searchQuery: q,
        newPage: page,
        column: sortColumn,
        direction: sortDirection,
        startingDate: dates.startDate.format('YYYY-MM-DD'),
        endingDate: dates.endDate.format('YYYY-MM-DD'),
      });
    }
  };

  const filterByOrderRelation = data => {
    setSelectedOrdersRelation(data);
    setQuery({ scope: data });
    fetchTravelExpenses({
      searchQuery: q,
      newPage: page,
      column: sortColumn,
      direction: sortDirection,
      scope: data,
    });
  };

  const clearFiltering = () => {
    setQuery({ startDate: '', endDate: '', scope: '' });
    setSelectedOrdersRelation(null);

    fetchTravelExpenses({
      searchQuery: q,
      newPage: page,
      column: sortColumn,
      direction: sortDirection,
      startingDate: '',
      endingDate: '',
    });
  };

  useEffect(() => {
    const getTravelExpenseApiToken = generateSourceToken();
    componentMounted.current = true;
    fetchTravelExpenses({
      searchQuery: q,
      newPage: page,
      column: sortColumn,
      direction: sortDirection,
      startingDate: startDate,
      endingDate: endDate,
      scope,
      apiToken: getTravelExpenseApiToken.token,
    });

    setQuery({ tab: 'travel_expenses' }, 'replaceIn');

    return () => {
      getTravelExpenseApiToken.cancel();
      componentMounted.current = false;
    };
  }, [q, travelExpensesPath, page, sortColumn, sortDirection, scope]);

  const isResetVisible = (startDate && endDate) || scope || selectedOrdersRelation;

  return (
    <div className="grid grid-gap-20">
      <div className="col-12">
        <div className="grid grid-gap-20">
          <div className="col-lg-8 travel-expenses-tab__sort-search">
            <div className="travel-expenses-tab__sort">
              <SortingPill
                name={I18n.t('admin.components.travel_expenses.table.id')}
                size="small"
                thisSortColumn="id"
                currentSortColumn={sortColumn}
                currentSortDirection={sortDirection}
                onChange={updateSorting}
              />
            </div>
            <div className="travel-expenses-tab__sort">
              <SortingPill
                name={I18n.t('admin.components.travel_expenses.table.name')}
                size="small"
                thisSortColumn="name"
                currentSortColumn={sortColumn}
                currentSortDirection={sortDirection}
                onChange={updateSorting}
              />
            </div>
            <div className="travel-expenses-tab__sort">
              <SortingPill
                name={I18n.t('admin.components.travel_expenses.date')}
                size="small"
                thisSortColumn="date"
                currentSortColumn={sortColumn}
                currentSortDirection={sortDirection}
                onChange={updateSorting}
              />
            </div>
            <div className="travel-expenses-tab__sort">
              <SortingPill
                name={I18n.t('admin.components.travel_expenses.amount')}
                size="small"
                thisSortColumn="amount"
                currentSortColumn={sortColumn}
                currentSortDirection={sortDirection}
                onChange={updateSorting}
              />
            </div>
            <div className="travel-expenses-tab__sort travel-expenses-tab__sort-filter">
              <DateFilter
                label={
                  <div className="travel-expenses-tab__filter">
                    <div className="orders-list__filters-label-icon">
                      <Icon name="calendar" size="small" />
                    </div>
                    {I18n.t('admin.components.travel_expenses.date_range')}
                  </div>
                }
                startDate={startDate && moment(startDate)}
                endDate={endDate && moment(endDate)}
                enablePastDates={true}
                disableFutureDates={false}
                enableDateRangeSelection={true}
                onChange={dates => setDateRange(dates)}
              />
            </div>
            <div className="travel-expenses-tab__sort">
              <DropDownFilter
                label={
                  <div className="travel-expenses-tab__filter">
                    <div className="orders-list__filters-label-icon">
                      <Icon name="orders" size="normal" />
                    </div>
                    {I18n.t('admin.components.tasks.order')}
                  </div>
                }
                options={ordersTypeOptions}
                onChange={filterByOrderRelation}
                value={selectedOrdersRelation}
                selected={selectedOrdersRelation}
                isSearchable={false}
                popoverMenuPosition="bottom-left"
              />
            </div>
            {isResetVisible && (
              <div className="travel-expenses-tab__sort travel-expenses-tab__sort-reset">
                <Button
                  version="v2"
                  size="small"
                  type="tertiary"
                  label={I18n.t('admin.components.organizations.list.filter.reset')}
                  onClick={clearFiltering}
                />
              </div>
            )}

            <div className="travel-expenses-tab__search">
              <Input
                size="tiny"
                placeholder={I18n.t('admin.components.travel_expenses.search')}
                preIcon={<Icon name="search" />}
                value={localSearchQuery}
                onChange={setSearchQuery}
              />
            </div>
          </div>
          <div className="col-lg-4 travel-expenses-tab__actions">
            <div className="travel-expenses-tab__actions-export">
              {urls.export && (
                <React.Fragment>
                  <span
                    className="button button--v2 button--small travellers-list__action-item"
                    onClick={() => setIsModalVisible(true)}
                    onKeyDown={() => setIsModalVisible(true)}
                    role="presentation"
                  >
                    {I18n.t('admin.components.travel_expenses.export_report')}
                  </span>
                  <ExportModal
                    urls={urls.export}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    queryParams={query}
                  />
                </React.Fragment>
              )}
            </div>
            <Link
              className="travel-expenses-tab__actions-add"
              type="button"
              modifier="primary"
              size="small"
              href={urls.create}
            >
              {I18n.t('admin.shared.action.new')}
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 travel-expenses-tab__total-expense-row">
        <div className="col-grid direction-row wrap align-center justify-space-between travel-expenses-tab__total-expense">
          <span className="travel-expenses-tab__total-expense-label">
            {I18n.t('admin.components.travel_expenses.total_expenses')}
          </span>
          <span className="travel-expenses-tab__total-expense-value">{totalExpensesAmount}</span>
        </div>
      </div>
      <div className="travel-expenses-tab__collection col-12">
        <Table
          travelExpenses={travelExpenses}
          deleteAction={deleteTravelExpense}
          isLoading={isLoading}
        />
      </div>
      <div className="travel-expenses-tab__pagination col-12 flex-footer">
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPaginationClick={newPage => setQuery({ page: newPage })}
        />
      </div>
    </div>
  );
};

TravelExpensesTab.propTypes = {
  travelExpensesPath: PropTypes.string.isRequired,
};

export default withQueryParamsProvider(TravelExpensesTab);
