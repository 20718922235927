import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import classNames from 'classnames';

const CarAttributeItem = ({ header, value, className }) => {
  const attributeRef = useRef(null);

  const attributeContent = (
    <div className={classNames('d-flex-col justify-center row-gap-8', className)}>
      <Tooltip content={value} size="tiny">
        <span className="car-item-compact-view__attribute-item--header">{header}</span>
        <span ref={attributeRef} className="car-item-compact-view__attribute-item--value">
          {value}
        </span>
      </Tooltip>
    </div>
  );

  return attributeContent;
};

CarAttributeItem.defaultProps = {
  className: null,
};

CarAttributeItem.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

export default CarAttributeItem;
