import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EmphasisTag, I18nText } from '@wtag/react-comp-lib';
import Radio from '@wtag/rcl-radio';
import Amount from 'sharedWebpack/Amount';
import { TYPE_INFANT } from 'sharedWebpack/helpers/passengerTypes';
import SeatOption from './SeatOption';

class SeatDetails extends React.Component {
  onClickHandler = () => {
    if (typeof this.props.bookedFor === 'number' && this.props.bookedFor >= 0) {
      this.props.removeTravelerIndex(this.removeSeatHandler);
    }
  };

  getSegmentId = id => {
    // Ex. id = '12345-LH4789-60A', splitting on '-' and taking the second element at index 1 which is 'LH4789' refers to the current flight segment.
    const segmentId = id.split('-')[1];

    return segmentId;
  };

  disableSeat = passengerType => {
    switch (passengerType) {
      case 'infant':
        return this.props.infantRestricted;
      case 'child':
        return this.props.childRestricted;
      default:
        return false;
    }
  };

  seatTags = () => {
    const tags = [];
    const emphasisMap = {
      aisle: {
        exist: this.props.aisle,
        type: 'warning',
        text: I18n.t('components.ibe.seat_map.aisle_seat'),
      },
      exit: {
        exist: this.props.exit,
        type: 'important',
        text: I18n.t('components.ibe.seat_map.exit_row'),
      },
      window: {
        exist: this.props.window,
        type: 'warning',
        text: I18n.t('components.ibe.seat_map.window_seat'),
      },
    };

    Object.keys(emphasisMap).forEach(key => {
      if (emphasisMap[key].exist)
        tags.push(
          <EmphasisTag
            key={key}
            text={emphasisMap[key].text}
            size="tiny"
            type={emphasisMap[key].type}
          />,
        );
    });

    return tags.length ? <div className="restriction-message">{tags}</div> : null;
  };

  restrictedMessages = passengerType => {
    switch (passengerType) {
      case 'infant':
        return this.props.infantRestricted ? (
          <div className="restriction-message">
            {I18n.t('components.ibe.seat_map.infant_restriction')}
          </div>
        ) : null;
      case 'child':
        return this.props.childRestricted ? (
          <div className="restriction-message">
            {I18n.t('components.ibe.seat_map.child_restriction')}
          </div>
        ) : null;
      default:
        return null;
    }
  };

  seatOptions = passenger => {
    const { flight, seatsInCart } = this.props;
    const disabled = this.disableSeat(passenger.type);

    const existingSeat = seatsInCart.find(
      seat => seat.travelerIndex === passenger.value && this.getSegmentId(seat.id) === flight,
    );

    const selectedSeatNumber = existingSeat ? existingSeat.itemTitle : null;

    return (
      <li key={passenger.value}>
        <SeatOption
          setTravelerIndex={this.props.setTravelerIndex}
          disabled={disabled}
          passenger={passenger}
          bookedFor={this.props.bookedFor}
          name={this.name}
          selectedSeatNumber={selectedSeatNumber}
        />
      </li>
    );
  };

  name = () => `${this.props.row}${this.props.name.toUpperCase()}`;

  removeSeatHandler = () => this.props.seatItemID && this.props.removeSeat(this.props.seatItemID);

  render() {
    let seatType = I18n.t('components.ibe.seat_map.free');
    let price;
    let assignSeatForm;
    const name = `${this.props.row}${this.props.name.toUpperCase()}`;
    const passengersWthoutInfant = this.props.passengers.filter(({ type }) => type !== TYPE_INFANT);

    if (this.props.exit) {
      seatType = I18n.t('components.ibe.seat_map.extra_legspace');
    }
    if (this.props.occupied) {
      seatType = I18n.t('components.ibe.seat_map.occupied');
    }
    if (this.props.chargeable && this.props.pricing) {
      price = <Amount currency={this.props.pricing.currency} value={this.props.pricing.amount} />;
    } else {
      price = <I18nText id="ibe.deals.label.free" returnStringOnly={true} />;
    }

    if (!this.props.occupied) {
      assignSeatForm = (
        <ul className="seat-details__options">
          <li>
            <div
              className="seat-details__list-item seat-details__list-item--no-seat"
              onKeyPress={this.onClickHandler}
              role="button"
              tabIndex="0"
              onClick={this.onClickHandler}
            >
              <Radio
                size="large"
                name={I18n.t('components.ibe.cart_item.no_seat')}
                isChecked={this.props.bookedFor === null}
              />
              <span className="seat-details__list-item-traveler-title">
                {I18n.t('components.ibe.cart_item.no_seat')}
              </span>
            </div>
          </li>
          {passengersWthoutInfant.map(passenger => this.seatOptions(passenger))}
        </ul>
      );
    } else if (this.props.occupied) {
      assignSeatForm = (
        <div className="message">{I18n.t('components.ibe.seat_map.occupied_message')}</div>
      );
    }

    return (
      <div className="col-grid col-bleed seat-details">
        <div className="col-12 col-bleed seat-details__header">
          <div className="seat-details__seat-number">
            {I18n.t('components.ibe.seat_map.seat', { name })}
            <div className="seat-details__seat-type">{seatType}</div>
          </div>

          <div className={classNames('seat-details__price', { free: this.props.free })}>
            {price}
          </div>
        </div>
        {this.seatTags()}
        {this.props.passengers.map(passenger => this.restrictedMessages(passenger.type))}
        {this.props.pricing.amount !== null && (
          <div className="seat-details__body col-12">{assignSeatForm}</div>
        )}
      </div>
    );
  }
}

SeatDetails.defaultProps = {
  pricing: { amount: 0, currency: null },
  infantRestricted: false,
  childRestricted: false,
  bookedFor: null,
};

SeatDetails.propTypes = {
  row: PropTypes.string.isRequired,
  exit: PropTypes.bool.isRequired,
  aisle: PropTypes.bool.isRequired,
  window: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  bookedFor: PropTypes.number,
  chargeable: PropTypes.bool.isRequired,
  occupied: PropTypes.oneOf([true, false]).isRequired,
  free: PropTypes.oneOf([true, false]).isRequired,
  pricing: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  infantRestricted: PropTypes.bool,
  childRestricted: PropTypes.bool,
  passengers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  seatsInCart: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      mainItemId: PropTypes.number.isRequired,
      travelerIndex: PropTypes.number.isRequired,
      itemTitle: PropTypes.string.isRequired,
    }),
  ).isRequired,
  flight: PropTypes.string.isRequired,
  seatItemID: PropTypes.arrayOf(PropTypes.number).isRequired,
  removeSeat: PropTypes.func.isRequired,
  setTravelerIndex: PropTypes.func.isRequired,
  removeTravelerIndex: PropTypes.func.isRequired,
};

SeatDetails.contextTypes = {
  muiTheme: PropTypes.shape().isRequired,
};

export default SeatDetails;
