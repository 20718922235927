import React from 'react';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import './matrix-loader.styles.scss';

const LoadingScreen = () => (
  <div className="grid grid-gap-20 matrix-loader">
    <div className="col-12">
      <Card version="v2">
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} isRounded={true} />
      </Card>
    </div>
    <div className="col-12">
      <Card className="matrix-loader__rtabs" version="v2">
        <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} isRounded={true} />
      </Card>
    </div>
    <div className="col-12 col-lg-4 col-xlg-3">
      <Card version="v2">
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} isRounded={true} />
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} isRounded={true} />
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} isRounded={true} />
      </Card>
    </div>
    <div className="col-12 col-lg-8 col-xlg-9">
      <Card version="v2" size="full">
        <div className="grid grid-gap-20">
          <div className="col-12 col-lg-4">
            <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} isRounded={true} />
          </div>
          <div className="col-12 col-lg-4">
            <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} isRounded={true} />
          </div>
          <div className="col-12 col-lg-4">
            <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} isRounded={true} />
          </div>
          <div className="col-12 col-lg-4">
            <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} isRounded={true} />
          </div>
          <div className="col-12 col-lg-4">
            <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} isRounded={true} />
          </div>
          <div className="col-12 col-lg-4">
            <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} isRounded={true} />
          </div>
          <div className="col-12 col-lg-4">
            <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} isRounded={true} />
          </div>
          <div className="col-12 col-lg-4">
            <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} isRounded={true} />
          </div>
          <div className="col-12 col-lg-4">
            <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} isRounded={true} />
          </div>
        </div>
      </Card>
    </div>
  </div>
);

export default LoadingScreen;
