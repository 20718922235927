import React from 'react';
import PropTypes from 'prop-types';
import reportToRollbar from '../reportToRollbar';

class RootErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    reportToRollbar(error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

RootErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RootErrorBoundary;
