export const UPDATE_PRIMARY_BUTTON_TEXT = 'UPDATE_PRIMARY_BUTTON_TEXT';
export const TOGGLE_PRIMARY_BUTTON_DISABLED = 'TOGGLE_PRIMARY_BUTTON_DISABLED';
export const UPDATE_PRIMARY_BUTTON_CLICK_CALLBACK = 'UPDATE_ON_PRIMARY_BUTTON_CLICK_CALLBACK';

export const updatePrimaryButtonText = label => ({
  type: UPDATE_PRIMARY_BUTTON_TEXT,
  label,
});

export const togglePrimaryButtonDisabled = isDisabled => ({
  type: TOGGLE_PRIMARY_BUTTON_DISABLED,
  isDisabled,
});

export const updateOnPrimaryButtonClickCallback = callback => ({
  type: UPDATE_PRIMARY_BUTTON_CLICK_CALLBACK,
  callback,
});
