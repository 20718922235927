import React from 'react';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import Alert from 'sharedWebpack/Alert';
import Amount from 'sharedWebpack/Amount';
import ProductIcon from 'sharedWebpack/ProductIcon';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import ItemMargin from 'sharedWebpack/Margin/ItemMargin';
import TotalMargin from 'sharedWebpack/Margin/TotalMargin';
import classNames from 'classnames';
import CheckoutProgress from '../components/CheckoutProgress';
import CostCenters from '../CostCenters';
import PaymentSidebar from './PaymentSidebar';
import RedemptionVoucher from './Voucher/VoucherContainer';
import ItemDetails from './ItemDetails';
import FeesAndServices from './FeesAndServices/FeesAndServicesContainer';
import history from '../lib/history';
import InstantTicketWrapper from '../../InstantTicketWrapper';
import InstantTicketCard from './InstantTicketCard';
import { CREDIT_CARD_FORM_OF_PAYMENT } from '../lib/helpers/fop_options';
import { DANGER, SUCCESS, WARNING } from '../lib/helpers/walletConditions';
import isLargeScreen from '../helpers/isLargeScreen';
import ShowGrossTotalPrice from '../../ShowGrossTotalPrice';
import './styles.scss';

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acknowledgedToInstantTicket: false,
      isModalOpen: false,
      isThreeDsConfirmModalOpen: false,
      isSkipThreeDsConfirmModalOpen: false,
      showLostCardDataAlert: false,
      isFopLoading: false,
      isLoading: false,
      selectedItemId: '',
      welltravelWalletDetails: {},
    };
    this.checkoutProgressRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.travelers[0] &&
      this.props.travelers[0].id &&
      (this.context.travelerLoggedIn || !this.context.laymanMode)
    ) {
      this.props.fetchAccountingTypesForCart(this.context.callbackParams);
    } else if (this.props.cartId && (this.context.travelerLoggedIn || !this.context.laymanMode)) {
      this.props.fetchCartPeople(this.context.callbackParams);
      this.props.fetchAccountingTypesForCart(this.context.callbackParams);
    }

    if (this.shouldResetPaymentMethod()) {
      this.props.resetFormOfPayment();
    }

    this.fetchWellTravelWallet();
  }

  componentDidUpdate() {
    if (this.props.cartId === null) {
      history.push(`/carts/${this.props.match.params.id}`);
    } else if (this.props.cartId && !this.state.showLostCardDataAlert && !this.state.isFopLoading) {
      let setAlert = false;
      const setFopPromises = [];
      this.props.items.forEach(cartItem => {
        let fopPromise;

        const conditionToReset =
          cartItem.formOfPayment.type === CREDIT_CARD_FORM_OF_PAYMENT &&
          this.props.cardDataAvailableByToken[cartItem.formOfPayment.cardDetails.token] !== true &&
          cartItem.formOfPayment.cardDetails.storedInCcv !== true;

        if (conditionToReset) {
          this.setState({ isFopLoading: true });
          if (this.context.laymanMode) {
            fopPromise = Promise.resolve(this.props.setFormOfPaymentToCash(cartItem.id));
          } else {
            fopPromise = Promise.resolve(this.props.setFormOfPaymentToNone(cartItem.id));
          }

          if (fopPromise) setFopPromises.push(fopPromise);
          setAlert = true;
        }
      });

      if (setAlert) {
        Promise.all(setFopPromises).then(() => {
          this.setState({ isFopLoading: false });
        });

        /* eslint-disable-next-line react/no-did-update-set-state */
        this.setState({ showLostCardDataAlert: true });
      }
    }
  }

  people = this.props.travelerCount;
  numberOfAdult = this.people && this.people.adults && this.people.adults.length;
  numberOfChildren = this.people && this.people.children && this.people.children.length;
  numberOfInfant = this.people && this.people.infants && this.people.infants.length;
  totalNumberOfTravelers = this.numberOfAdult + this.numberOfChildren + this.numberOfInfant;

  shouldResetPaymentMethod = () => {
    if (!this.props.cartId) {
      return false;
    }

    if (!this.context.paymentDisabled) {
      return true;
    }

    return this.props.items.some(({ formOfPayment }) => formOfPayment.cardDetails);
  };

  fetchWellTravelWallet = async () => {
    const { cartId } = this.props;
    const { laymanMode } = this.context;

    if (!cartId || laymanMode) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      const { data } = await httpClient.get(routes.api.carts.cartWalletDetails({ cartId }));
      this.setState({ welltravelWalletDetails: data });
    } catch (error) {
      throw error;
    } finally {
      this.setState({ isLoading: false });
    }
  };

  havingAnyInstantTicket = () =>
    this.props.flightResultDetailsById &&
    Object.values(this.props.flightResultDetailsById).some(
      itemDetails => itemDetails.instantTicket,
    );

  triggerCreditCardItemsThreeDs = () => {
    this.setState({ isThreeDsConfirmModalOpen: false });
    this.props.triggerCreditCardItemsThreeDs();
  };

  triggerCreditCardItemsSkipThreeDs = () => {
    this.setState({ isSkipThreeDsConfirmModalOpen: false });
    this.props.skipAuthenticationForAllItems();
  };

  nextStepButtonClicked = () => {
    if (this.havingAnyInstantTicket() && !this.state.acknowledgedToInstantTicket) {
      this.setState({ isModalOpen: true });
    }
  };

  threeDsAuthenticationMessage = haveFailedThreeDsStatuses => {
    if (this.props.threeDsAuthenticationSkipped) {
      return I18n.t('ibe.payment.skip_three_ds_message.authenticate_info');
    } else if (haveFailedThreeDsStatuses) {
      return I18n.t('ibe.payment.three_ds_message.test_failed');
    }

    return I18n.t('ibe.payment.three_ds_message.authenticate_info');
  };

  fopAlertType = haveFailedThreeDsStatuses => {
    if (!haveFailedThreeDsStatuses && !this.props.threeDsAuthenticationSkipped) {
      return 'warning-light';
    } else if (this.props.needToRunThreeDsChecks && haveFailedThreeDsStatuses) {
      return 'danger-light';
    }

    return 'neutral';
  };

  acknowledgedToInstantTicket = () => {
    this.setState({ acknowledgedToInstantTicket: true }, () => {
      this.checkoutProgressRef.current.moveNextStep();
    });
    this.setState({ isModalOpen: false });
  };

  isAllowedInstantTicket = item =>
    item.bookingAttributes &&
    this.props.flightResultDetailsById &&
    this.props.flightResultDetailsById[item.bookingAttributes.resultId] &&
    this.props.flightResultDetailsById[item.bookingAttributes.resultId].instantTicket;

  itemMarginWrapper = item => (
    <ItemMargin
      marginBreakdown={item.marginBreakdown}
      currency={item.currency}
      totalMargin={item.totalMargin}
      iconSize="tiny"
    />
  );

  disableNextButton = () => {
    const { items, travelers, needToRunThreeDsChecks } = { ...this.props };

    if (items.length === 0 || travelers.length === 0 || needToRunThreeDsChecks) {
      return true;
    }

    const allItemHasFOP = items
      .filter(item => item.hasJourneyElement)
      .every(item => item.formOfPayment && item.formOfPayment.type);

    return !allItemHasFOP;
  };

  render() {
    const havingAnyInstantTicket = this.havingAnyInstantTicket();
    const nextButtonDisabled = this.disableNextButton();
    const instantTicketAction = havingAnyInstantTicket && !this.state.acknowledgedToInstantTicket;
    const creditCardItemActionNeeded = this.props.needToRunThreeDsChecks;
    const threeDsStatusValues = [];
    Object.keys(this.props.threeDsStatusesByToken).forEach(uniqueToken => {
      if (this.props.ccTokensThatNeedToBook.includes(uniqueToken)) {
        Object.keys(this.props.threeDsStatusesByToken[uniqueToken]).forEach(key => {
          if (this.props.bookingRequestsEncodedByToken[uniqueToken].includes(key)) {
            threeDsStatusValues.push(this.props.threeDsStatusesByToken[uniqueToken][key]);
          }
        });
      }
    });

    const openCreditCardAlertContainer =
      creditCardItemActionNeeded || this.props.threeDsAuthenticationSkipped;

    const haveFailedThreeDsStatuses =
      threeDsStatusValues.length > 0 ? threeDsStatusValues.some(value => value === false) : false;
    const havePassedAllThreeDsStatuses =
      threeDsStatusValues.length > 0 ? threeDsStatusValues.every(value => value === true) : false;

    const itemsNetTotal = this.props.items
      .filter(item => item.hasJourneyElement)
      .map(item => item.netTotal);

    const refreshBalance = async () => {
      this.setState({ isLoading: true });
      this.setState({ isFopLoading: true });

      await this.props.resetFormOfPayment().then(() => {
        this.setState({ isFopLoading: false });
      });

      await this.props.fetchCart(this.props.cartId).then(() => {
        this.setState({ isLoading: false });
      });

      this.fetchWellTravelWallet();
    };
    const walletBalanceForAllItem =
      this.props.availableFOPsForAll.welltravelWallet &&
      this.props.availableFOPsForAll.welltravelWallet.supported &&
      this.state.welltravelWalletDetails &&
      Number(this.state.welltravelWalletDetails.balance) >= Number(this.props.itemsTotal);

    const walletBalanceForSomeItem =
      this.props.availableFOPsForAll.welltravelWallet &&
      this.props.availableFOPsForAll.welltravelWallet.supported &&
      this.state.welltravelWalletDetails &&
      itemsNetTotal.some(
        netTotal => Number(this.state.welltravelWalletDetails.balance) >= Number(netTotal),
      );

    const walletType = () => {
      if (walletBalanceForAllItem) return SUCCESS;
      else if (walletBalanceForSomeItem) return WARNING;
      return DANGER;
    };

    const { laymanMode } = this.context;

    return (
      <div className="payment-form">
        <CheckoutProgress
          cartId={this.props.match.params.id}
          nextButtonDisabled={nextButtonDisabled}
          paymentError={nextButtonDisabled}
          nextStepButtonAction={this.nextStepButtonClicked}
          nextStepButtonHasAction={instantTicketAction}
          ref={this.checkoutProgressRef}
          currentStep="payment"
          totalCartItems={this.props.totalCartItems}
        />
        {havingAnyInstantTicket && (
          <div className="payment-form__instant-ticket-confirmation">
            <ConfirmationModal
              className="payment-form__instant-ticket-confirmation--modal"
              isModalOpen={this.state.isModalOpen}
              confirmationHeader={I18n.t('ibe.payment.confirmation_message.instant_ticket.header')}
              subHeader={
                <InstantTicketCard
                  items={this.props.items}
                  flightResultDetailsById={this.props.flightResultDetailsById}
                />
              }
              confirmationText={I18n.t('ibe.payment.confirmation_message.instant_ticket.agree')}
              rejectionText={I18n.t('ibe.payment.confirmation_message.instant_ticket.not_agree')}
              onConfirm={this.acknowledgedToInstantTicket}
              onReject={() => this.setState({ isModalOpen: false })}
              withAction={true}
              type="warning"
            />
          </div>
        )}
        <div className="payment-form__three-ds-confirmation">
          <ConfirmationModal
            isModalOpen={this.props.showCardAddedConfirmation}
            confirmationHeader={I18n.t(
              'ibe.payment.three_ds_message.card_added_confirmation_header',
            )}
            subHeader={I18n.t('ibe.payment.three_ds_message.card_added_sub_header')}
            timeout={3000}
            onTimeout={() => {
              if (this.props.showCardAddedConfirmation) {
                this.props.setShowCardAddedConfirmation(false);
              }
            }}
            withAction={false}
            type="success"
          />
        </div>
        <div className="payment-form__three-ds-confirmation">
          <ConfirmationModal
            isModalOpen={
              havePassedAllThreeDsStatuses &&
              !this.props.needToRunThreeDsChecks &&
              this.props.showTestPassedConfirmation
            }
            confirmationHeader={I18n.t('ibe.payment.three_ds_message.test_passed_header')}
            subHeader={I18n.t('ibe.payment.three_ds_message.test_passed')}
            timeout={3000}
            onTimeout={() => {
              if (this.props.showTestPassedConfirmation) {
                this.props.setShowTestPassedConfirmation(false);
              }
            }}
            withAction={false}
            type="success"
          />
        </div>
        <div className="container container--sm-full-width">
          <div className="col-lg-4 col-bleed payment__sidebar-wrapper">
            <PaymentSidebar
              setQuery={this.props.setQuery}
              callbackParams={this.context.callbackParams}
              setFormOfPaymentForAllItems={this.props.setFormOfPaymentForAllItems}
              set3rdPartyPaymentMethod={this.props.set3rdPartyPaymentMethod}
              setFormOfPaymentToCash={this.props.setFormOfPaymentToCash}
              setFormOfPaymentToWelltravelWallet={this.props.setFormOfPaymentToWelltravelWallet}
              setFormOfPaymentToIATA={this.props.setFormOfPaymentToIATA}
              setFormOfPaymentToNone={this.props.setFormOfPaymentToNone}
              setFormOfPaymentToCreditCard={this.props.setFormOfPaymentToCreditCard}
              availableFOPsForAll={this.props.availableFOPsForAll}
              welltravelWalletDetails={this.state.welltravelWalletDetails}
              creditCards={this.props.creditCards}
              paymentDisabled={this.context.paymentDisabled}
              allowPaymentViaInvoice={this.context.allowPaymentViaInvoice}
              paymentOptions={this.context.paymentOptions}
              configurePaymentGateway={this.props.configurePaymentGateway}
              paymentGateway={this.props.paymentGateway}
              laymanMode={this.context.laymanMode}
              currency={this.context.currency}
              maximumNumberOfCardsAdded={this.props.maximumNumberOfCardsAdded}
              triggerResetCardAddressForm={this.props.triggerResetCardAddressForm}
              setTriggerResetCardAddressForm={this.props.setTriggerResetCardAddressForm}
              resetCreditCardBillingAddressForm={this.props.resetCreditCardBillingAddressForm}
              items={this.props.items.filter(item => item.hasJourneyElement)}
              resetFormOfPayment={this.props.resetFormOfPayment}
              isFopLoading={this.state.isFopLoading}
              setIsFopLoading={state => this.setState({ isFopLoading: state })}
              selectedItemId={this.state.selectedItemId}
              setSelectedItemId={id => this.setState({ selectedItemId: id })}
              cardDataAvailableByToken={this.props.cardDataAvailableByToken}
              walletType={walletType()}
              refreshBalance={refreshBalance}
              isLoading={this.state.isLoading}
              affiliatesBillingUrl={this.props.affiliatesBillingUrl}
            />
          </div>
          <div className="col-lg-8 col-bleed payment__item-list-wrapper">
            {this.state.showLostCardDataAlert && (
              <Alert
                className="payment-authenticate-alert"
                hideClose={false}
                isVisible={!this.state.isFopLoading && this.state.showLostCardDataAlert}
                onClose={() => this.setState({ showLostCardDataAlert: false })}
                type="warning-light"
              >
                <span>
                  {this.context.laymanMode
                    ? I18n.t('ibe.payment.three_ds_message.card_data_lost.invoice')
                    : I18n.t('ibe.payment.three_ds_message.card_data_lost.pay_later')}
                </span>
              </Alert>
            )}
            {havePassedAllThreeDsStatuses && !this.props.needToRunThreeDsChecks && (
              <Alert
                className="payment-authenticate-alert"
                hideClose={true}
                isVisible={true}
                type="success-light"
              >
                <span>{I18n.t('ibe.payment.three_ds_message.test_passed')}</span>
              </Alert>
            )}
            {openCreditCardAlertContainer && (
              <Alert
                className="payment-authenticate-alert"
                hideClose={true}
                isVisible={openCreditCardAlertContainer}
                type={this.fopAlertType(haveFailedThreeDsStatuses)}
                isIconVisible={true}
              >
                <div className="d-flex align-center justify-between flex-grow column-gap-12">
                  <span>{this.threeDsAuthenticationMessage()}</span>
                  <Button
                    version="v2"
                    size="small"
                    type="default"
                    label={
                      haveFailedThreeDsStatuses
                        ? I18n.t('ibe.payment.three_ds_message.try_again')
                        : I18n.t('ibe.payment.three_ds_message.authenticate_label')
                    }
                    onClick={() => this.setState({ isThreeDsConfirmModalOpen: true })}
                  />
                  {!this.props.threeDsAuthenticationMandatory &&
                    !this.props.threeDsAuthenticationSkipped && (
                      <Button
                        version="v2"
                        size="small"
                        type="default"
                        label={I18n.t('ibe.payment.skip_three_ds_message.button')}
                        onClick={() => this.setState({ isSkipThreeDsConfirmModalOpen: true })}
                      />
                    )}
                </div>
              </Alert>
            )}
            <ConfirmationModal
              isModalOpen={this.state.isThreeDsConfirmModalOpen}
              confirmationHeader={I18n.t(
                'ibe.payment.three_ds_message.authenticate_confirmation_header',
              )}
              subHeader={I18n.t('ibe.payment.three_ds_message.authenticate_confirmation_info')}
              confirmationText={I18n.t('ibe.payment.three_ds_message.agree')}
              rejectionText={I18n.t('ibe.payment.three_ds_message.disagree')}
              onConfirm={this.triggerCreditCardItemsThreeDs}
              onReject={() => this.setState({ isThreeDsConfirmModalOpen: false })}
              withAction={true}
              type="warning"
            />
            <ConfirmationModal
              isModalOpen={this.state.isSkipThreeDsConfirmModalOpen}
              confirmationHeader={I18n.t('ibe.payment.skip_three_ds_message.confirmation_header')}
              subHeader={I18n.t('ibe.payment.skip_three_ds_message.confirmation_sub_header')}
              confirmationText={I18n.t('ibe.payment.three_ds_message.agree')}
              rejectionText={I18n.t('ibe.payment.three_ds_message.disagree')}
              onConfirm={this.triggerCreditCardItemsSkipThreeDs}
              onReject={() => this.setState({ isSkipThreeDsConfirmModalOpen: false })}
              withAction={true}
              type="warning"
            />
            <Card version="v2">
              <div className="payment-due">
                <div className="col-6 col-bleed payment-due__title">
                  {I18n.t('ibe.payment.total_payment_due')}
                </div>
                <div className="col-6 col-bleed payment__margin">
                  <div className="payment-due__amount">
                    <Amount currency={this.context.currency} value={this.props.itemsTotal} />
                  </div>
                  {!laymanMode && (
                    <TotalMargin
                      items={this.props.items.filter(item => item.hasJourneyElement)}
                      journeyElements={this.props.journeyElements}
                      currency={this.context.currency}
                      marginTotal={this.props.marginTotal}
                    />
                  )}
                </div>
              </div>
              <RedemptionVoucher />
              {this.props.items.map(item => (
                <div className="payment__item" key={item.id}>
                  <div className="payment__item-name col-6">
                    <div className="payment__item-name-labels">
                      <ProductIcon
                        productType={item.journeyElementType || item.type}
                        showBGColor={true}
                        showLabel={true}
                        size="small"
                      />
                      {this.isAllowedInstantTicket(item) && (
                        <div className="payment__item-name-labels--instant-ticket">
                          <InstantTicketWrapper fop={item.formOfPayment} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="payment__item-price col-6">
                    <ShowGrossTotalPrice currency={item.currency} grossTotal={item.grossTotal} />
                    {!laymanMode && this.itemMarginWrapper(item)}
                  </div>
                  <ItemDetails
                    item={item}
                    channelsWithQueuesAndRemarks={this.props.channelsWithQueuesAndRemarks}
                    laymanMode={this.context.laymanMode}
                    cardType={this.props.paymentGateway && this.props.paymentGateway.cardType}
                    paymentDisabled={this.context.paymentDisabled}
                    paymentOptions={this.context.paymentOptions}
                    isFopLoading={this.state.isFopLoading}
                    selectedItemId={this.state.selectedItemId}
                  />
                </div>
              ))}
              <FeesAndServices />
            </Card>
          </div>
          {this.props.accountingTypes.length > 0 && (
            <React.Fragment>
              <div
                className={classNames(' col-lg-8 col-bleed-x', {
                  'offset-4': isLargeScreen,
                })}
              >
                <CostCenters
                  accountingTypes={this.props.accountingTypes}
                  fetchCostCenterOfAccountingType={this.props.fetchCostCenterOfAccountingType}
                  usedCostCenters={this.props.cartCostCenters}
                  tenantCurrency={this.context.currency}
                  addCostCenterToCart={this.props.addCostCenterToCart}
                  updateCartCostCenter={this.props.updateCartCostCenter}
                  removeCostCenterFromCart={this.props.removeCostCenterFromCart}
                  individualAccountingTypeEnabled={this.context.individualAccountingTypeEnabled}
                  allocationPerAccountingType={this.props.allocationPerAccountingType}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

Payment.contextTypes = {
  currency: PropTypes.string,
  paymentOptions: PropTypes.shape(),
  paymentDisabled: PropTypes.bool,
  allowPaymentViaInvoice: PropTypes.bool,
  laymanMode: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  individualAccountingTypeEnabled: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
};

Payment.defaultProps = {
  itemsTotal: null,
  creditCards: null,
  paymentGateway: null,
  allocationPerAccountingType: [],
  flightResultDetailsById: null,
  cartId: null,
  totalCartItems: 0,
};

Payment.propTypes = {
  showCardAddedConfirmation: PropTypes.bool.isRequired,
  setShowCardAddedConfirmation: PropTypes.func.isRequired,
  needToRunThreeDsChecks: PropTypes.bool.isRequired,
  threeDsStatusesByToken: PropTypes.shape().isRequired,
  triggerCreditCardItemsThreeDs: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: {
      id: PropTypes.string,
    },
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cardDataAvailableByToken: PropTypes.shape().isRequired,
  bookingRequestsEncodedByToken: PropTypes.shape().isRequired,
  itemsTotal: PropTypes.string,
  totalCartItems: PropTypes.number,
  channelsWithQueuesAndRemarks: PropTypes.arrayOf(PropTypes.string).isRequired,
  threeDsAuthenticationMandatory: PropTypes.bool.isRequired,
  threeDsAuthenticationSkipped: PropTypes.bool.isRequired,
  skipAuthenticationForAllItems: PropTypes.func.isRequired,
  creditCards: PropTypes.shape({}),
  setFormOfPaymentToCash: PropTypes.func.isRequired,
  setFormOfPaymentToWelltravelWallet: PropTypes.func.isRequired,
  setFormOfPaymentToCreditCard: PropTypes.func.isRequired,
  setFormOfPaymentToIATA: PropTypes.func.isRequired,
  setFormOfPaymentToNone: PropTypes.func.isRequired,
  setFormOfPaymentForAllItems: PropTypes.func.isRequired,
  set3rdPartyPaymentMethod: PropTypes.func.isRequired,
  cartId: PropTypes.string,
  configurePaymentGateway: PropTypes.func.isRequired,
  paymentGateway: PropTypes.shape(),
  travelers: PropTypes.arrayOf(PropTypes.shape).isRequired,
  cartCostCenters: PropTypes.arrayOf(PropTypes.shape).isRequired,
  addCostCenterToCart: PropTypes.func.isRequired,
  updateCartCostCenter: PropTypes.func.isRequired,
  fetchAccountingTypesForCart: PropTypes.func.isRequired,
  fetchCostCenterOfAccountingType: PropTypes.func.isRequired,
  removeCostCenterFromCart: PropTypes.func.isRequired,
  fetchCartPeople: PropTypes.func.isRequired,
  accountingTypes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  allocationPerAccountingType: PropTypes.arrayOf(PropTypes.shape),
  flightResultDetailsById: PropTypes.shape(),
  maximumNumberOfCardsAdded: PropTypes.bool.isRequired,
  ccTokensThatNeedToBook: PropTypes.arrayOf(PropTypes.string).isRequired,
  showTestPassedConfirmation: PropTypes.bool.isRequired,
  setShowTestPassedConfirmation: PropTypes.func.isRequired,
  triggerResetCardAddressForm: PropTypes.bool.isRequired,
  travelerCount: PropTypes.objectOf(
    PropTypes.shape({
      adults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      infants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ).isRequired,
  setTriggerResetCardAddressForm: PropTypes.func.isRequired,
  resetCreditCardBillingAddressForm: PropTypes.func.isRequired,
  availableFOPsForAll: PropTypes.shape().isRequired,
  resetFormOfPayment: PropTypes.func.isRequired,
  people: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  journeyElements: PropTypes.shape({ flight: PropTypes.shape({}).isRequired }).isRequired,
  setQuery: PropTypes.func.isRequired,
  fetchCart: PropTypes.func.isRequired,
  affiliatesBillingUrl: PropTypes.string.isRequired,
  marginTotal: PropTypes.string.isRequired,
};

export default Payment;
