/* global I18n */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import SanitizedHTML from 'sharedWebpack/SanitizedHTML';
import filterHtmlTags from 'sharedWebpack/filterHtmlTags';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import TextareaField from './TextareaField';
import AppContext from '../AppContext';
import MarkDown from '../MarkDown';

class MessageInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChatMessage: '',
    };

    this.updateMessage = this.updateMessage.bind(this);
    this.sendChatMessage = this.sendChatMessage.bind(this);
    this.getMentionsList = this.getMentionsList.bind(this);
    this.checkValidMessage = this.checkValidMessage.bind(this);
    this.messageInputFieldRef = React.createRef();
  }

  getMentionsList() {
    const mentionsList = this.props.roomUsers
      .filter(this.noLoggedInUserInList)
      .map(user => ({ id: `${user.name}+${user.id}+${user.isCustomer}`, display: user.name }));
    mentionsList.push({ display: 'channel', id: 'channel+0' });
    return mentionsList;
  }

  getQuoteMessageDetails = repliedMessage => {
    const quotedMessage = {
      id: null,
      isCustomer: null,
      senderId: null,
      text: null,
      timestamp: null,
    };

    if (repliedMessage) {
      quotedMessage.id = repliedMessage.messageId;
      quotedMessage.isCustomer = repliedMessage.isCustomer;
      quotedMessage.senderId = repliedMessage.senderId;
      quotedMessage.text = repliedMessage.text;
    }

    return quotedMessage;
  };

  updateMessage(message) {
    this.setState({ currentChatMessage: message });
    this.props.sendTyping();
  }

  sendChatMessage(event) {
    const quotedMessage = this.getQuoteMessageDetails(this.props.repliedMessage);

    if (event) {
      event.preventDefault();
    }

    if (this.checkValidMessage(this.state.currentChatMessage)) {
      const newMessageId = uuidv4();
      const messagePayload = {
        id: newMessageId,
        message: this.state.currentChatMessage,
        quotedMessage: this.props.repliedMessage,
      };
      const newMessage = {
        id: newMessageId,
        text: this.state.currentChatMessage,
        quotedMessage,
        quotedMessageText: quotedMessage.text,
        quotedMessageId: quotedMessage.id,
        messageMentions: [],
        isCustomer: this.props.context.laymanMode,
        senderId: this.props.context.currentUserId,
        isSending: true,
        isFailedToSend: false,
        timestamp: new Date(),
        tenant: this.props.context.tenant,
      };

      this.props.addNewMessage(newMessage, messageIndex => {
        messagePayload.messageIndex = messageIndex;
        this.props.sendChatMessage(messagePayload);
      });
    }
    this.setState({ currentChatMessage: '' });
    this.props.onClose();
  }

  checkValidMessage = message => /\S/.test(message);

  noLoggedInUserInList = user => {
    if (user.id === this.props.context.currentUserId) {
      if (this.props.laymanMode) {
        return !user.isCustomer;
      }
      return user.isCustomer;
    }
    return true;
  };

  render() {
    let senderName;
    let text;

    if (this.props.repliedMessage) {
      senderName = this.props.repliedMessage.senderName;
      text = MarkDown(this.props.laymanMode).convert(
        filterHtmlTags(this.props.repliedMessage.text),
      );
    }

    return (
      <div className="chat-message-input" onSubmit={this.sendChatMessage}>
        {this.props.repliedMessage && (
          <div
            className={classNames('chat-message-input__replied', {
              'chat-message-input__replied--isMyMessage': this.props.repliedMessage.isMyMessage,
              'chat-message-input__replied--isSenderMessage': !this.props.repliedMessage
                .isMyMessage,
            })}
          >
            <div className="chat-message-input__replied-message-container">
              <div className="chat-message-input__replied--headerText">
                {I18n.t('admin.components.chat.replying_header')}{' '}
                <span className="chat-message-input__replied--senderName">
                  {this.props.repliedMessage.isMyMessage
                    ? I18n.t('admin.components.chat.yourself')
                    : senderName}
                </span>
              </div>
              <div className="chat-message-input__replied--text">
                <SanitizedHTML html={text} />
              </div>
            </div>
            <IconButton
              icon={<Icon name="close" />}
              isIconOnly={true}
              onClick={() => this.props.onClose()}
            />
          </div>
        )}

        <div ref={this.messageInputFieldRef} className="chat-message-input__field">
          <TextareaField
            messageInputFieldRef={this.messageInputFieldRef}
            data={this.getMentionsList()}
            value={this.state.currentChatMessage}
            placeholder={I18n.t('chat.input_placeholder')}
            onChange={this.updateMessage}
            readOnly={this.props.readOnly}
            checkValidMessage={this.checkValidMessage}
            sendChatMessage={this.sendChatMessage}
          />
          <div className="chat-message-input__submit">
            <IconButton
              className="chat-editor__send-button"
              size="small"
              isIconOnly={true}
              icon={<Icon name="sendMessage" size="small" showBGColor={true} color="tertiary" />}
              disabled={!this.checkValidMessage(this.state.currentChatMessage)}
              onClick={this.sendChatMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}

MessageInput.propTypes = {
  sendChatMessage: PropTypes.func.isRequired,
  sendTyping: PropTypes.func.isRequired,
  roomUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      tenant: PropTypes.string,
    }),
  ).isRequired,
  context: PropTypes.shape().isRequired,
  readOnly: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  repliedMessage: PropTypes.shape({
    messageId: PropTypes.number.isRequired,
    senderId: PropTypes.number.isRequired,
    senderName: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isMyMessage: PropTypes.bool.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  addNewMessage: PropTypes.func.isRequired,
};

const MessageInputWithContext = props => (
  <AppContext.Consumer>
    {context => <MessageInput context={context} {...props} />}
  </AppContext.Consumer>
);

export default MessageInputWithContext;
