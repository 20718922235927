import {
  REMOVE_COST_CENTER_FROM_CART,
  CART_FETCHED,
} from '../../actions/common';

export default function costCenters(state = [], action) {
  const newState = new Map();

  const addCostCenters = costCentersToAdd => costCentersToAdd.forEach(costCenter => newState.set(costCenter.id, costCenter));

  addCostCenters(state);

  switch (action.type) {
    case REMOVE_COST_CENTER_FROM_CART:
      newState.delete(action.id);
      break;

    case CART_FETCHED:
      newState.clear();
      addCostCenters(action.cart.costCenters);
      break;

    default:
      break;
  }

  const returnedState = [];
  newState.forEach((costCenter, id) => returnedState.push({ id, ...costCenter }));

  return returnedState;
}
