import { VISA_ADVISORY_FETCHED } from '../../actions/common/visaAdvisory';

function visaAdvisoriesByCitizenshipAndDestination(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case VISA_ADVISORY_FETCHED: {
      let visaAdvisoryWithKeyUpperCased = {};
      Object.keys(action.data).forEach(key => {
        const keyToUpperCase = key.toUpperCase();
        visaAdvisoryWithKeyUpperCased = {
          [keyToUpperCase]: action.data[key],
          ...visaAdvisoryWithKeyUpperCased,
        };
      });
      newState[action.citizenshipCountryCode] = visaAdvisoryWithKeyUpperCased;

      return newState;
    }
    default:
      return state;
  }
}

export default visaAdvisoriesByCitizenshipAndDestination;
