import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Header } from '@wtag/rcl-typography';
import Button from '@wtag/rcl-button';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import Popover from '@wtag/rcl-popover';
import Card from '@wtag/rcl-card';
import { Link } from 'react-router-dom';
import useWindowWidth from 'sharedWebpack/useWindowWidth';
import searchURL from '../../../IBE/lib/helpers/searchURL';
import { parseQueryString } from '../../helpers/qsMethods';
import MatrixStepper from './MatrixStepper';
import StepperControlBar from './StepperControlBar';
import MatrixAccordion from './MatrixAccordion';
import MatrixTabs from './MatrixTabs';
import MatrixContent from './MatrixContent';
import MatrixContext from './contexts/MatrixContext';
import ExchangeQuote from './ExchangeQuote';
import RefreshBooking from '../../../../admin/Orders/Show/ItemsTab/RefreshBooking';
import OutOfPolicyJustificationModal from '../../SearchResult/OutOfPolicyJustificationModal';
import { TYPE_ADULT } from '../../../../shared/helpers/passengerTypes';
import './style.scss';
import {
  STEP_CHOOSE_NEXT_RESULT,
  READY_FOR_CART,
  STEP_GO_TO_CART,
  STEP_SHOW_EXCHANGE_QUOTE,
} from '../../actions/flight';
import history from '../../lib/history';
import LoadingScreen from './LoadingScreen';
import suppliers from './suppliers';
import { mapMealAvailbility, mapWifiAvailbility, mapFareBrands } from './matrixHelpers';

const Matrix = ({
  location,
  currency,
  match,
  matrixesByType,
  selectedResults,
  fetchMatrixes,
  fetchSearchParams,
  selectResult,
  addSelectedFlightsToCart,
  airports,
  airlineInformations,
  laymanMode,
  exchangeQuote,
  exchange,
  exchangeQuoteStatus,
  totalTravelerCount,
  fetchExchangeQuote,
  confirmExchangeQuote,
  fetchAirportDetails,
  fetchAirlineInformations,
  fetchingFlightMatrices,
  isFetchingMatrices,
  aircraftInformation,
  fetchAircraftType,
  // the tabKeyWithSelectedFareOption prop holds the tabKey for fare option that was selected at the very last moment
  // before moving to cart page or search result page
  tabKeyWithSelectedFareOption,
  people,
}) => {
  const { searchId } = match.params;
  const [isFareBrandSelected, setIsFareBrandSelected] = useState(false);
  const [tripLocations, setTripLocations] = useState([]);
  const [individualTrips, setIndividualTrips] = useState('');
  const [showTrips, setShowTrips] = useState(false);
  const [trips, setTrips] = useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const [relatedTripsHashMap, setRelatedTripsHashMap] = useState(null);
  const [activeTripId, setActiveTripId] = useState(0);
  const [activeTrip, setActiveTrip] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('');
  const [maxSliderScrollLength, setMaxSliderScrollLength] = useState(0);
  const [scrollSliderToLeftBy, setScrollSliderToLeftBy] = useState(0);

  // This state will hold the ids of trips that have selected fare brand options
  // But it won't decide which trip will show the green tick mark
  // Visibility of the green tick mark will be decided on tripsWithSelectedFareBrand state
  const [temporarySelectedTrips, setTemporarySelectedTrips] = useState({
    all: [],
    customize: [],
  });
  const [tripsWithSelectedFareBrand, setTtripsWithSelectedFareBrand] = useState({
    all: [],
    customize: [],
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const defaultMatrixData = {
    matrixRowHeaderCardWidth: 220,
    matrixDataCardWidth: 280,
  };
  const [canContinueToCart, setCanContinueToCart] = useState(false);
  const [showExchangeQuote, setShowExchangeQuote] = useState(false);
  const [showRefreshBooking, setShowRefreshBooking] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  // The auto selection of fare brand options will work if this state is true
  const [autoSelectionMode, setAutoSelectionMode] = useState(true);
  const [outOfPolicyItems, setOutOfPolicyItems] = useState({
    all: {},
    customize: {},
  });
  const [policyJustificationModalOpen, setPolicyJustificationModalOpen] = useState(false);
  const [isContinuToCartDisabled, setIsContinuToCartDisabled] = useState(false);

  const flightGroupsByTripId = {};
  const flightGroupsInURL = useMemo(() => parseQueryString(location.search).flightGroups, [
    location.search,
  ]);
  const allTripsHaveSelectedFareBrandOption = trips.every(
    trip =>
      tabKeyWithSelectedFareOption &&
      tripsWithSelectedFareBrand[tabKeyWithSelectedFareOption].includes(trip.id),
  );
  const windowSizeForMobileView = 991;
  const steps =
    activeTabKey === 'all'
      ? [
          {
            key: 1,
            title: I18n.t('components.ibe.results.select_an_option'),
            isDone: trips.every(trip => tripsWithSelectedFareBrand[activeTabKey].includes(trip.id)),
          },
          {
            key: 2,
            title: I18n.t('components.ibe.results.continue_to_cart'),
            isDone: false,
          },
        ]
      : [
          ...trips.map(trip => ({
            ...trip,
            key: trip.id,
            title: I18n.t('components.ibe.results.trip_number', { number: trip.id }),
            isDone: tripsWithSelectedFareBrand[activeTabKey].includes(trip.id),
          })),
          {
            key: trips.length + 1,
            title: I18n.t('components.ibe.results.continue_to_cart'),
            isDone: false,
          },
        ];

  const legendItems = [
    {
      name: I18n.t('components.ibe.search_result.farebrand_legends.included'),
      category: 'included',
    },
    {
      name: I18n.t('components.ibe.search_result.farebrand_legends.fee_needed'),
      category: 'available',
    },
    {
      name: I18n.t('components.ibe.search_result.farebrand_legends.not_available'),
      category: 'no_available',
    },
    {
      name: I18n.t('components.ibe.search_result.farebrand_legends.no_info'),
      category: 'no_info',
    },
  ];

  const windowWidth = useWindowWidth();

  useEffect(() => {
    const isSelected =
      activeTrip &&
      activeTrip.fareBrands.length > 0 &&
      activeTrip.fareBrands.some(
        fareBrand => fareBrand.cheapestOption && fareBrand.cheapestOption.isSelected === true,
      );
    setIsFareBrandSelected(isSelected);
  }, [activeTrip]);

  if (flightGroupsInURL) {
    flightGroupsInURL.forEach((flightGroup, index) => {
      flightGroupsByTripId[index + 1] = flightGroup;
    });
  }

  const confirmExchangeQuoteAndRefresh = () =>
    confirmExchangeQuote().then(success => {
      if (success) setShowRefreshBooking(true);
    });

  const doNextStep = nextStep => {
    switch (nextStep.step) {
      case STEP_GO_TO_CART:
        history.push(`/carts/${nextStep.cartId}`);
        break;

      case READY_FOR_CART:
        setCanContinueToCart(true);
        break;

      case STEP_CHOOSE_NEXT_RESULT:
        setCanContinueToCart(false);
        break;

      case STEP_SHOW_EXCHANGE_QUOTE:
        fetchExchangeQuote(exchange.bookingIdentifier, nextStep.resultId);
        setShowExchangeQuote(true);
        break;

      default:
        throw new Error(`Unknown nextStep '${nextStep}'`);
    }
  };

  const continueToCart = justification => addSelectedFlightsToCart(justification).then(doNextStep);

  const selectFareBrandOption = (matrix, result) => {
    // sending activeTabKey for keeping record of the tabKey of last selected fare option
    selectResult(searchId, matrix, result, activeTabKey).then(doNextStep);
  };

  const outOfPolicyItemCount =
    outOfPolicyItems[tabKeyWithSelectedFareOption] &&
    Object.keys(outOfPolicyItems[tabKeyWithSelectedFareOption]).length;

  const handleContinueButtonClick = async () => {
    setIsContinuToCartDisabled(true);
    if (!outOfPolicyItemCount && allTripsHaveSelectedFareBrandOption && canContinueToCart) {
      await continueToCart(null);
    } else {
      await setPolicyJustificationModalOpen(true);
    }
    setIsContinuToCartDisabled(false);
  };

  const matrixAccordion = (
    <MatrixAccordion
      trips={trips}
      activeTripId={activeTripId}
      activeTabKey={activeTabKey}
      setActiveStepIndex={setActiveStepIndex}
      fetchAirportDetails={fetchAirportDetails}
      airports={airports}
      airlineInformations={airlineInformations}
      fetchAirlineInformations={fetchAirlineInformations}
      windowWidth={windowWidth}
    />
  );
  const allTripsAvailable =
    matrixesByType &&
    matrixesByType.all.length > 0 &&
    matrixesByType.all.every(matrix => matrix.content.length > 0);
  const customizeTripsAvailable =
    matrixesByType &&
    matrixesByType.customize.length > 0 &&
    matrixesByType.customize.every(matrix => matrix.content.length > 0);
  const noSuitablePackageFound = !allTripsAvailable && !customizeTripsAvailable;

  const stepperController = ({ withStyles }) =>
    matrixesByType && steps.length > 0 ? (
      <StepperControlBar
        setActiveStepIndex={setActiveStepIndex}
        activeStepIndex={activeStepIndex}
        activeTripId={steps[activeStepIndex] ? steps[activeStepIndex].id : 0}
        totalTrips={trips.length}
        activeTabKey={activeTabKey}
        searchId={searchId}
        allResultLink={
          <Link to={searchURL('flight', searchId)}>
            <Button
              className="matrix-stepper-control-bar__result-link"
              version="v2"
              size="small"
              label={I18n.t('components.ibe.results.back_to_result')}
            />
          </Link>
        }
        currency={currency}
        continueToCartButton={
          <Fragment>
            <ExchangeQuote
              open={showExchangeQuote}
              currentPrice={exchange.currentPrice}
              quote={exchangeQuote}
              confirmExchangeQuote={confirmExchangeQuoteAndRefresh}
              status={exchangeQuoteStatus}
              onModalClose={() => setShowExchangeQuote(false)}
            />
            <Button
              version="v2"
              size="small"
              type="primary"
              disabled={
                !allTripsHaveSelectedFareBrandOption ||
                !canContinueToCart ||
                isFetchingMatrices ||
                isContinuToCartDisabled
              }
              label={
                windowWidth > windowSizeForMobileView ? (
                  I18n.t('components.ibe.results.continue_to_cart')
                ) : (
                  <Icon size="normal" name="arrowForwardAlt" />
                )
              }
              onClick={handleContinueButtonClick}
            />
            {showRefreshBooking && (
              <RefreshBooking
                useButtonToStart={false}
                id={exchange.bookingId}
                redirectTo={exchange.returnTo}
              />
            )}
          </Fragment>
        }
        price={totalPrice}
        withStyles={withStyles}
        windowWidth={windowWidth}
      />
    ) : null;
  const tabs = [
    {
      key: 'all',
      title: I18n.t('components.ibe.results.all_trips'),
      disabled: !allTripsAvailable,
      disablityNote: {
        title: I18n.t('components.ibe.results.all_trips_disability_note.title'),
        body: I18n.t('components.ibe.results.all_trips_disability_note.body'),
      },
      content: (
        <div className="grid grid-gap-20">
          <div className="col-12 col-lg-4 col-xlg-3">{matrixesByType && matrixAccordion}</div>
          <div className="col-12 col-lg-8 col-xlg-9">
            <div className="grid grid-gap-20">
              {matrixesByType && (
                <div className="col-12">
                  <MatrixContent
                    selectFareBrandOption={selectFareBrandOption}
                    windowWidth={windowWidth}
                    people={people}
                  />
                </div>
              )}
              {!laymanMode && (
                <div className="col-12">{stepperController({ withStyles: true })}</div>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'customize',
      title: I18n.t('components.ibe.results.trip_individually'),
      disabled: !customizeTripsAvailable,
      disablityNote: {
        title: I18n.t('components.ibe.results.individual_trips_disability_note.title'),
        body: I18n.t('components.ibe.results.individual_trips_disability_note.body'),
      },
      content: (
        <div className="grid grid-gap-20">
          <div className="col-12 col-lg-4 col-xlg-3">{matrixesByType && matrixAccordion}</div>
          <div className="col-12 col-lg-8 col-xlg-9">
            <div className="grid grid-gap-20">
              {matrixesByType && (
                <div className="col-12">
                  <MatrixContent
                    selectFareBrandOption={selectFareBrandOption}
                    windowWidth={windowWidth}
                    people={people}
                  />
                </div>
              )}
              {!laymanMode && (
                <div className="col-12">{stepperController({ withStyles: true })}</div>
              )}
            </div>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const originCodes = trips.map(trip => trip.originCode);
    const destinationCodes = trips.map(trip => trip.destinationCode);

    const mergedArray = originCodes.concat(destinationCodes);

    const uniqueLocations = mergedArray.filter((item, i, ar) => ar.indexOf(item) === i);

    setTripLocations(uniqueLocations);

    if (activeTripId !== 0) {
      const selectedTrip = trips.find(trip => trip.id === activeTripId);

      const selectedTripTitle = `Trip ${activeTripId}: ${selectedTrip.originCode} - ${selectedTrip.destinationCode}`;

      setIndividualTrips(selectedTripTitle);
    }
  }, [trips, activeTripId]);

  const sliderScrollHandler = newScrollOffset => {
    if (newScrollOffset > maxSliderScrollLength) {
      setScrollSliderToLeftBy(maxSliderScrollLength);
    } else if (newScrollOffset < 0) {
      setScrollSliderToLeftBy(0);
    } else {
      setScrollSliderToLeftBy(newScrollOffset);
    }
  };

  const handleTrip = () => {
    setShowTrips(!showTrips);
  };

  useEffect(() => {
    if (activeTrip) {
      setActiveTripId(activeTrip.id);
    }
  }, [activeTrip]);

  useEffect(() => {
    // Don't remove this api call as it's needed
    // for filling up the redux state state.flights.searchParamsBySearchId
    // otherwise continue to cart button won't be enabled
    fetchSearchParams(searchId);
  }, [searchId]);

  useEffect(() => {
    // Here we're calculating the amount of selected results for all the trips
    // If the totalSelectedResults is equal to total trips
    // it means every trips have a selected fare option
    const totalSelectedResults =
      Object.keys(selectedResults).length > 0
        ? Object.values(selectedResults).reduce((accumulator, result) => ({
            packageSize: accumulator.packageSize + result.packageSize,
          })).packageSize
        : 0;

    if (totalSelectedResults === trips.length) {
      // and If the totalSelectedResults is equal to total trips
      // we can create the cart
      setCanContinueToCart(true);
    }

    // here we are not using selectedResults as the dependency as we don't need to set canContinueToCart to true from here
    // Cause it'll be done by doNextStep() function
    // This useEffect is only for the first time mounting of Matrix component
    // If a user go to any page from the matrix page, then this useEffect checks if the user selected fare options
    // for all trips before leaving the page or not
    // As trips is an empty array during mounting, we used trip as dependency to do a proper checking
  }, [trips]);

  useEffect(() => {
    if (!isFetchingMatrices) {
      fetchMatrixes(
        searchId,
        flightGroupsByTripId,
        Object.values(selectedResults).map(result => result.resultId),
      );
    }
  }, []);

  useEffect(() => {
    const selectedResultIds = Object.values(selectedResults).filter(result => !!result.resultId);

    if (selectedResultIds.length > 0) {
      fetchMatrixes(
        searchId,
        flightGroupsByTripId,
        Object.values(selectedResults).map(result => result.resultId),
      );
    }
  }, [searchId, JSON.stringify(flightGroupsByTripId), JSON.stringify(selectedResults)]);

  useEffect(() => {
    if (matrixesByType && !activeTabKey) {
      const firstEnabledTab = tabs.find(item => !item.disabled);

      // Checking if there is any tab registered in the redux which is the tab of last selected fare option
      // and the tab is not disabled
      // if so then set that tab as active tab
      // It indicates user has selected one or more fare options and went back to the search result page
      // or to the cart page without any reloading the browser
      if (
        tabKeyWithSelectedFareOption &&
        tabs.find(tab => tab.key === tabKeyWithSelectedFareOption && !tab.disabled)
      ) {
        setActiveTabKey(tabKeyWithSelectedFareOption);
      } else if (firstEnabledTab) {
        // otherwise set first enabled tab as active tab
        setActiveTabKey(firstEnabledTab.key);
      }
    }
  }, [matrixesByType, activeTabKey, tabKeyWithSelectedFareOption]);

  useEffect(() => {
    // if the tabKeyWithSelectedFareOption has a valid value and temporarySelectedTrips has also some selected trips
    // and tripsWithSelectedFareBrand has no selected trips
    // It indicates user has selected one or more fare options and went back to the search result page
    // or to the cart page without any reloading the browser
    // then copy temporarySelectedTrips to tripsWithSelectedFareBrand
    // so that user can see his previously fair options and trips as selected without
    // opening individual trip accordion item
    if (
      tabKeyWithSelectedFareOption &&
      Object.values(temporarySelectedTrips).some(value => value.length > 0) &&
      Object.values(tripsWithSelectedFareBrand).every(value => value.length <= 0)
    ) {
      setTtripsWithSelectedFareBrand(temporarySelectedTrips);
    }
  }, [tabKeyWithSelectedFareOption, temporarySelectedTrips, tripsWithSelectedFareBrand]);

  useEffect(() => {
    if (matrixesByType && matrixesByType[tabKeyWithSelectedFareOption]) {
      let total = 0.0;

      matrixesByType[tabKeyWithSelectedFareOption].forEach(trip => {
        const id = trip.id.split(':').join('_');

        // Those trips have related Trips that means for 'semi' catagory
        // We only take the price of only the first selected trip among the related trips
        // As all the related trips have the same price and should be counted once
        if (
          relatedTripsHashMap &&
          relatedTripsHashMap[id] &&
          relatedTripsHashMap[id].relatedTrips.length > 0
        ) {
          if (relatedTripsHashMap[id].firstSelectedTripId === parseInt(id, 10)) {
            total += parseFloat(trip.headers.selectedTotalPrice.totalPrice || 0);
          }
        } else {
          // Those trips don't have any related trips that means for 'unpackaged' or 'packaged' catagory
          // We will take the price of every trips
          total += parseFloat(trip.headers.selectedTotalPrice.totalPrice || 0);
        }
      });

      setTotalPrice(total);
    }
  }, [matrixesByType, tripsWithSelectedFareBrand, tabKeyWithSelectedFareOption]);

  useEffect(() => {
    if (matrixesByType && matrixesByType[activeTabKey]) {
      const newTrips = [];
      const selectedTripIds = new Set();

      let maxGrossTaxes = 0;
      let minGrossTaxes = Infinity;

      matrixesByType[activeTabKey].forEach(matrix => {
        const tripIds = matrix.id.split(':');
        const fareBrands = [];
        let totalFareOptions = 0;
        const channels = matrix.content.map(channel => {
          const [name, identifier] = channel.channel.split(':');
          const channelInfo = {
            name,
            identifier,
            supportInstantTicket: channel.channelInformations.instantTicketSupported,
            supportPayLater: channel.channelInformations.payLaterSupported,
            hasSelectedFareOption: channel.options.some(option => option && option.isSelected),
            fareBrands: channel.options.map((option, index) => {
              maxGrossTaxes =
                option && option.data.grossTaxes > maxGrossTaxes
                  ? option.data.grossTaxes
                  : maxGrossTaxes;
              minGrossTaxes =
                option && option.data.grossTaxes < minGrossTaxes
                  ? option.data.grossTaxes
                  : minGrossTaxes;
              // Collecting fare brands in an array
              if (!fareBrands[index] && option) {
                fareBrands[index] = {
                  matrixId: matrix.id,
                  name:
                    option.data.fareBrand === 'none'
                      ? I18n.t('components.ibe.results.unknown_fare_brand')
                      : option.data.fareBrand,
                  fareBrandFacilities: {
                    ...option.data.fareBrandFacilities,
                    meal: mapMealAvailbility(option.data.meal),
                    wifi: mapWifiAvailbility(option.data.wifi),
                  },
                  fareCalculations: option.data.fareCalculations,
                  grossTaxes: option.data.grossTaxes || '',
                  maxGrossTaxes,
                  minGrossTaxes,
                  fareRulePresent: false,
                  currency: option.data.currencyCode || '',
                  hasSelectedFareOption: false,
                  bookingClasses: [],
                  resultIds: {},
                  isCheapestFareBrand: false,
                  minPrice: undefined,
                  maxPrice: '0.00',
                  minPriceCalculatedInClientSide: 'Infinity',
                  cheapestOption: null,
                  cheapestOptionCalculatedInClientSide: null,
                };
              }

              // If an option is selected for a trip, user can't deselect
              // Only can change the option
              if (option && option.isSelected) {
                option.data.tripIds.forEach(tripId => selectedTripIds.add(parseInt(tripId, 10)));
              }

              return {
                matrixId: matrix.id,
                data: option ? option.data : {},
                price: option ? option.data.price : '',
                currency: option ? option.data.currencyCode : '',
                isSelected: option ? option.isSelected : false,
              };
            }),
          };

          return channelInfo;
        });

        // Calculating availability of selected fare options, Min and Max price for fare brands
        matrix.content.forEach(channel => {
          channel.options.forEach((option, index) => {
            if (option && option.isCheapestOption) {
              fareBrands[index].minPrice =
                option.data.fareCalculations &&
                option.data.fareCalculations.find(a => a.passengerType === TYPE_ADULT).price;
              fareBrands[index].cheapestOption = {
                ...option,
                channelId: channel.channel,
              };
              fareBrands[index].fareCalculations = option.data.fareCalculations;
              fareBrands[index].marginBreakdown = option.data.marginBreakdown;
              fareBrands[index].grossTaxes = option.data.grossTaxes;
              fareBrands[index].maxGrossTaxes = maxGrossTaxes;
              fareBrands[index].minGrossTaxes = minGrossTaxes;
              fareBrands[index].cheapestOptionCalculatedInClientSide = {
                ...option,
                channelId: channel.channel,
              };
            } else if (
              option &&
              parseFloat(
                option.data.fareCalculations &&
                  option.data.fareCalculations.find(a => a.passengerType === TYPE_ADULT).price,
              ) < parseFloat(fareBrands[index].minPriceCalculatedInClientSide)
            ) {
              fareBrands[index].minPriceCalculatedInClientSide =
                option.data.fareCalculations &&
                option.data.fareCalculations.find(a => a.passengerType === TYPE_ADULT).price;
              fareBrands[index].cheapestOptionCalculatedInClientSide = {
                ...option,
                channelId: channel.channel,
              };
            }

            if (
              option &&
              parseFloat(
                option.data.fareCalculations &&
                  option.data.fareCalculations.find(a => a.passengerType === TYPE_ADULT).price,
              ) > parseFloat(fareBrands[index].maxPrice)
            ) {
              fareBrands[index].maxPrice =
                option.data.fareCalculations &&
                option.data.fareCalculations.find(a => a.passengerType === TYPE_ADULT).price;
            }

            if (option && !fareBrands[index].hasSelectedFareOption && option.isSelected) {
              fareBrands[index].hasSelectedFareOption = true;
            }

            if (option) {
              const prevClasses = fareBrands[index].bookingClasses;

              fareBrands[index].bookingClasses = [
                ...new Set(...prevClasses, ...option.data.bookingClasses),
              ];
            }

            if (option && option.data.resultId) {
              const [channelName, identifier] = channel.channel.split(':');

              fareBrands[index].resultIds[option.data.resultId] = {
                channelName,
                identifier,
              };
            }

            if (option && option.data.fareRulePresent && !fareBrands[index].fareRulePresent) {
              fareBrands[index].fareRulePresent = option.data.fareRulePresent;
            }
          });
        });

        // As from the api side we always receive the cheapest farebrand in the first position
        fareBrands[0].isCheapestFareBrand = true;

        // Counting total valid fare otions for auto selection
        channels.forEach(channel =>
          channel.fareBrands.forEach(fareBrand => {
            if (fareBrand.price) {
              totalFareOptions += 1;
            }
          }),
        );

        if (matrix.headers.tripsData) {
          matrix.headers.tripsData.forEach((trip, index) => {
            newTrips.push({
              ...trip,
              id: parseInt(tripIds[index], 10),
              matrixId: matrix.id,
              groupId: trip.id,
              fareBrands: mapFareBrands(fareBrands),
              channels,
              hasOneFareBrandOption: totalFareOptions === 1,
              isOutOfPolicy: matrix.headers.labels.outOfPolicy,
            });
          });
        }
      });

      setTemporarySelectedTrips(prevState => ({
        ...prevState,
        [activeTabKey]: [...selectedTripIds],
      }));
      setTrips(newTrips);
    }
  }, [matrixesByType, activeTabKey]);

  useEffect(() => {
    if (!relatedTripsHashMap && matrixesByType) {
      const newRelatedTripsMap = {};

      matrixesByType.availablePackageInformation.forEach(packageInfo => {
        if (packageInfo.style === 'packaged') {
          newRelatedTripsMap[packageInfo.tripIds.join('_')] = {
            firstSelectedTripId: undefined,
            relatedTrips: [],
          };
        } else if (packageInfo.style === 'semi' || packageInfo.style === 'unpackaged') {
          packageInfo.tripIds.forEach(tripId => {
            newRelatedTripsMap[tripId] = {
              firstSelectedTripId: undefined,
              relatedTrips: packageInfo.tripIds.filter(id => id !== tripId),
            };
          });
        }
      });

      setRelatedTripsHashMap(newRelatedTripsMap);
    }
  }, [matrixesByType]);

  useEffect(() => {
    const newState = { ...outOfPolicyItems };

    trips.forEach(trip => {
      if (trip.isOutOfPolicy) {
        newState[activeTabKey][trip.matrixId] = true;
      }
    });
    setOutOfPolicyItems(newState);
  }, [trips]);

  useEffect(() => {
    setActiveStepIndex(0);
    setScrollSliderToLeftBy(0);
  }, [activeTabKey]);

  useEffect(() => {
    setActiveTrip(trips[activeStepIndex]);
  }, [activeStepIndex, trips]);

  useEffect(() => {
    setScrollSliderToLeftBy(0);
  }, [activeTripId]);

  return (
    <MatrixContext.Provider
      value={{
        ...defaultMatrixData,
        suppliers,
        trips,
        relatedTripsHashMap,
        activeTripId,
        activeTabKey,
        maxSliderScrollLength,
        scrollSliderToLeftBy,
        activeTrip,
        temporarySelectedTrips,
        tripsWithSelectedFareBrand,
        keyOfTabWithSelectedFareBrand: tabKeyWithSelectedFareOption,
        totalPrice,
        laymanMode,
        autoSelectionMode,
        isFetchingMatrices,
        fetchingFlightMatrices,
        matrixesByType,
        totalTravelerCount,
        setTrips,
        setRelatedTripsHashMap,
        setActiveTrip,
        setActiveTripId,
        setActiveTabKey,
        setMaxSliderScrollLength,
        setScrollSliderToLeftBy: sliderScrollHandler,
        setTemporarySelectedTrips,
        setTtripsWithSelectedFareBrand,
        setTotalPrice,
        setAutoSelectionMode,
        aircraftInformation,
        fetchAircraftType,
      }}
    >
      {windowWidth < windowSizeForMobileView && !laymanMode && (
        <div
          className={classNames('grid grid-gap-20 direction-column matrix-page', {
            'matrix-page--laymanmode': laymanMode,
          })}
        >
          <div className="col-12">
            <Header level={5} weight="bold">
              {`${I18n.t('components.ibe.results.customise_your_flight')} -
        ${I18n.t('components.ibe.results.select_fare_brand')}`}
            </Header>
          </div>
          {matrixesByType && noSuitablePackageFound && (
            <div className="col-12 matrix-page__no-combination-found-card">
              <Card
                version="v2"
                color="tertiary"
                className="matrix__empty"
                emptyCardImageSrc={<Icon name="plane" />}
                emptyCardText={
                  <Fragment>
                    {I18n.t('components.ibe.results.error_on_combining')}
                    <div className="matrix-page__no-combination-found-card-result-link-wrapper">
                      <Link to={searchURL('flight', searchId)}>
                        <Button
                          className="matrix-stepper-control-bar__result-link"
                          version="v2"
                          size="small"
                          label={I18n.t('components.ibe.results.back_to_result')}
                        />
                      </Link>
                    </div>
                  </Fragment>
                }
              />
            </div>
          )}
          {!matrixesByType && (
            <div className="col-12">
              <LoadingScreen />
            </div>
          )}
          {matrixesByType && !noSuitablePackageFound && (
            <Fragment>
              <div className="col-12">
                <MatrixStepper
                  steps={steps}
                  activeStepIndex={activeStepIndex}
                  footer={stepperController({ withStyles: false })}
                />
              </div>
              <div className="ueue col-12">
                <MatrixTabs
                  className="matrix__tab"
                  laymanMode={laymanMode}
                  items={tabs}
                  activeTabKey={activeTabKey}
                  onChange={setActiveTabKey}
                />
              </div>
            </Fragment>
          )}
        </div>
      )}

      {windowWidth > windowSizeForMobileView && (
        <div
          className={classNames('grid grid-gap-20 direction-column matrix-page', {
            'matrix-page--laymanmode': laymanMode,
          })}
        >
          <div className="col-12">
            <Header level={5} weight="bold">
              {`${I18n.t('components.ibe.results.customise_your_flight')} -
          ${I18n.t('components.ibe.results.select_fare_brand')}`}
            </Header>
          </div>
          {matrixesByType && noSuitablePackageFound && (
            <div className="col-12 matrix-page__no-combination-found-card">
              <Card
                version="v2"
                color="tertiary"
                className="matrix__empty"
                emptyCardImageSrc={<Icon name="plane" />}
                emptyCardText={
                  <Fragment>
                    {I18n.t('components.ibe.results.error_on_combining')}
                    <div className="matrix-page__no-combination-found-card-result-link-wrapper">
                      <Link to={searchURL('flight', searchId)}>
                        <Button
                          className="matrix-stepper-control-bar__result-link"
                          version="v2"
                          size="small"
                          label={I18n.t('components.ibe.results.back_to_result')}
                        />
                      </Link>
                    </div>
                  </Fragment>
                }
              />
            </div>
          )}
          {!matrixesByType && (
            <div className="col-12">
              <LoadingScreen />
            </div>
          )}
          {matrixesByType && !noSuitablePackageFound && (
            <Fragment>
              <div className="col-12">
                <MatrixStepper
                  steps={steps}
                  activeStepIndex={activeStepIndex}
                  footer={stepperController({ withStyles: false })}
                />
              </div>
              <div className="col-12">
                <MatrixTabs
                  className="matrix__tab"
                  laymanMode={laymanMode}
                  items={tabs}
                  activeTabKey={activeTabKey}
                  onChange={setActiveTabKey}
                />
              </div>
            </Fragment>
          )}
        </div>
      )}

      {windowWidth < windowSizeForMobileView && laymanMode && (
        <div>
          <React.Fragment>
            <div className="matrix-mobile-wrapper">
              {matrixesByType && noSuitablePackageFound && (
                <div className="col-12">
                  <Card
                    version="v2"
                    color="tertiary"
                    className="matrix__empty"
                    emptyCardImageSrc={<Icon name="plane" />}
                    emptyCardText={I18n.t('components.ibe.results.error_on_combining')}
                  />
                </div>
              )}
              {!matrixesByType && (
                <div className="col-12">
                  <LoadingScreen />
                </div>
              )}
              {matrixesByType && !noSuitablePackageFound && (
                <div>
                  <div className="col-12">
                    <MatrixStepper
                      steps={steps}
                      activeStepIndex={activeStepIndex}
                      footer={stepperController({ withStyles: false })}
                    />
                  </div>
                  <div className="col-12 matrix-mobile-wrapper__tabs">
                    <MatrixTabs
                      className="matrix__tab"
                      items={tabs}
                      activeTabKey={activeTabKey}
                      onChange={setActiveTabKey}
                    />
                  </div>
                </div>
              )}
              <div className="col-grid col-lg-12 col-md-12 col-bleed-x col-bleed-y direction-row matrix__legend-wrapper">
                <Card version="v2" size="full">
                  <div className="matrix__legend">
                    <span> {I18n.t('components.flight_seat_map.legends')}</span>
                    <div className="flight-filter__icon">
                      {legendItems.map(legend => (
                        <span
                          className={classNames(
                            `matrix__legend--tooltip-option matrix__legend--tooltip-option--${legend.category}`,
                          )}
                        />
                      ))}

                      <Popover
                        isVisible={isPopoverVisible}
                        size="small"
                        content={
                          <div className="matrix__legend--tooltip">
                            <div className="matrix__legend--tooltip-title">
                              {I18n.t('components.flight_seat_map.legends')}
                            </div>
                            <div className="matrix__legend--tooltip-option--wrapper">
                              {legendItems.map(legend => (
                                <div className="matrix__legend--tooltip-option--wrapper-item">
                                  <span
                                    className={classNames(
                                      `matrix__legend--tooltip-option matrix__legend--tooltip-option--${legend.category}`,
                                    )}
                                  />
                                  <span>{legend.name}</span>{' '}
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                        position="bottom-right"
                        type="inverse"
                        showArrow={false}
                        onOutsideClick={() => setIsPopoverVisible(false)}
                      >
                        <IconButton
                          icon={<Icon name="invalidOutline" />}
                          isIconOnly={true}
                          color="default"
                          onClick={() => setIsPopoverVisible(!isPopoverVisible)}
                        />
                      </Popover>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="col-grid direction-row matrix__wrapper">
                <Card version="v2" size="full">
                  <div className="matrix__header">
                    <Icon
                      name="check"
                      showBGColor={true}
                      className={classNames({
                        'matrix__header-icon--selected': isFareBrandSelected,
                        'matrix__header-icon--default': !isFareBrandSelected,
                      })}
                      size="tiny"
                    />
                    {activeTabKey === 'all' ? (
                      <div className="matrix__header--title matrix__header--title__all">
                        {tripLocations.map((tripLocation, index) => (
                          <span> {index === 0 ? `${tripLocation}` : ` - ${tripLocation}`} </span>
                        ))}
                      </div>
                    ) : (
                      <div className="matrix__header--title">{individualTrips}</div>
                    )}
                  </div>
                  {showTrips && (
                    <div>
                      <MatrixAccordion
                        trips={trips}
                        activeTripId={activeTripId}
                        activeTabKey={activeTabKey}
                        setActiveStepIndex={setActiveStepIndex}
                        fetchAirportDetails={fetchAirportDetails}
                        airports={airports}
                        airlineInformations={airlineInformations}
                        fetchAirlineInformations={fetchAirlineInformations}
                        aircraftInformation={aircraftInformation}
                        fetchAircraftType={fetchAircraftType}
                      />
                    </div>
                  )}
                  <div className="matrix__trip-details--show">
                    <span role="presentation" onClick={handleTrip}>
                      {showTrips
                        ? I18n.t('components.ibe.search_result.farebrand_trip_hide')
                        : I18n.t('components.ibe.search_result.farebrand_trip_show')}
                    </span>
                  </div>
                  {matrixesByType && (
                    <MatrixContent
                      windowWidth={windowWidth}
                      selectFareBrandOption={selectFareBrandOption}
                      people={people}
                    />
                  )}
                </Card>
              </div>
            </div>
          </React.Fragment>
        </div>
      )}

      <OutOfPolicyJustificationModal
        modalOpen={policyJustificationModalOpen}
        setModalOpen={setPolicyJustificationModalOpen}
        onConfirm={continueToCart}
        multipleApplicability={outOfPolicyItemCount > 1}
      />
    </MatrixContext.Provider>
  );
};

Matrix.defaultProps = {
  exchange: {},
  exchangeQuote: {},
  exchangeQuoteStatus: null,
};

Matrix.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      searchId: PropTypes.string,
    }),
  }).isRequired,
  confirmExchangeQuote: PropTypes.func.isRequired,
  exchange: PropTypes.shape({
    enabled: PropTypes.bool,
    bookingId: PropTypes.number,
    bookingIdentifier: PropTypes.string,
    returnTo: PropTypes.string,
    currentPrice: PropTypes.shape({
      net: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
    }),
    currentSegments: PropTypes.arrayOf(
      PropTypes.shape({
        operatingCarrierCode: PropTypes.string.isRequired,
        operatingFlightNumber: PropTypes.string.isRequired,
      }),
    ),
  }),
  exchangeQuote: PropTypes.shape(),
  exchangeQuoteStatus: PropTypes.string,
  fetchExchangeQuote: PropTypes.func.isRequired,
  selectedResults: PropTypes.shape({}).isRequired,
  fetchMatrixes: PropTypes.func.isRequired,
  selectResult: PropTypes.func.isRequired,
  fetchAirportDetails: PropTypes.func.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  addSelectedFlightsToCart: PropTypes.func.isRequired,
  matrixesByType: PropTypes.shape({
    all: PropTypes.arrayOf(PropTypes.shape({})),
    availablePackageInformation: PropTypes.arrayOf(PropTypes.shape({})),
    customize: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  airports: PropTypes.objectOf(
    PropTypes.shape({
      placeName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
  airlineInformations: PropTypes.shape({}).isRequired,
  fetchAirlineInformations: PropTypes.func.isRequired,
  aircraftInformation: PropTypes.objectOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      imageUrls: {
        small: PropTypes.string,
        medium: PropTypes.string,
        large: PropTypes.string,
      },
    }),
  ).isRequired,
  fetchAircraftType: PropTypes.func.isRequired,
  fetchingFlightMatrices: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFetchingMatrices: PropTypes.bool.isRequired,
  fetchSearchParams: PropTypes.func.isRequired,
  tabKeyWithSelectedFareOption: PropTypes.string.isRequired,
  totalTravelerCount: PropTypes.number.isRequired,
  people: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
  }).isRequired,
};

export default Matrix;
