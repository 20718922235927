import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import ClassNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import Button from '@wtag/rcl-button';
import Card from '@wtag/rcl-card';
import { Text } from '@wtag/rcl-typography';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import { EmphasisTag, Link, DateTime } from '@wtag/react-comp-lib';
import LazyLoad from 'react-lazyload';
import Modal from 'sharedWebpack/Modal';

import IdentificationInformationForm from './IdentificationInformationForm';

const IdentificationInformation = ({
  IDdocument,
  personId,
  fetchIdentificationDocuments,
  documentTypes,
  countryOptions,
  nationalityOptions,
  documentId,
  layman,
  singleIdentificationDocumentPresent,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState({});

  const [type, setType] = useState(null);
  const [country, setCountry] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [number, setNumber] = useState('');
  const [validity, setValidity] = useState(null);
  const [primary, setPrimary] = useState(false);
  const [identificationFileName, setIdentificationFileName] = useState('');
  const [identificationFileType, setIdentificationFileType] = useState('');
  const [identificationFile, setIdentificationFile] = useState(null);
  const [identificationFileUploadPreviewUrl, setIdentificationFileUploadPreviewUrl] = useState(
    null,
  );
  const [viewImageModal, setViewImageModal] = useState(false);
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);

  const toggleShowConfirmation = () => {
    setIsShowConfirmation(prev => !prev);
  };

  const updateIdentification = async identificationParams => {
    const updateIdentificationUrl = layman
      ? routes.public.identificationDocuments.update({ documentId })
      : routes.admin.identificationDocuments.update({ documentId, person_id: personId });

    const { data } = await httpClient.patch(updateIdentificationUrl, identificationParams);

    if (data.error === null) {
      fetchIdentificationDocuments();
    }
  };

  const onUpdateIdentification = async () => {
    const formData = new FormData();

    if (identificationFile) {
      formData.append('identification_document[file]', identificationFile);
    } else if (!identificationFileName) {
      formData.append('identification_document[remove_file]', true);
    }

    formData.append('identification_document[document_type]', type);
    formData.append('identification_document[country]', country);
    formData.append('identification_document[nationality]', nationality);
    formData.append('identification_document[number]', number);
    formData.append('identification_document[validity]', validity);
    formData.append('identification_document[primary]', primary);
    formData.append('identification_document[person_id]', personId);

    updateIdentification(formData)
      .then(() => {
        setIsEditing(false);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setError(response.data.error);
        }
      });
  };

  const deleteIdentification = async () => {
    setIsEditing(false);

    const deleteIdentificationUrl = layman
      ? routes.public.identificationDocuments.delete({ documentId })
      : routes.admin.identificationDocuments.delete({ documentId, person_id: personId });

    const { data } = await httpClient.delete(deleteIdentificationUrl);

    if (data.error === null) {
      fetchIdentificationDocuments();
    }
  };

  const onDeleteIdentification = () => {
    toggleShowConfirmation();

    deleteIdentification().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });
  };

  const setIdentificationDocumentValues = IDdocumentValues => {
    setType(IDdocumentValues.type);
    setCountry(IDdocumentValues.countryCode);
    setNationality(IDdocumentValues.nationalityCode);
    setNumber(IDdocumentValues.number);
    setValidity(IDdocumentValues.validity);
    setPrimary(IDdocumentValues.primary);
    setError({});
    setIdentificationFile(null);
    setIdentificationFileName(IDdocumentValues.fileName);
    setIdentificationFileType(IDdocumentValues.mimeType);
    setIdentificationFileUploadPreviewUrl(IDdocumentValues.fileUrl);
  };

  const openViewImageModal = () => {
    setViewImageModal(true);
  };

  const downloadFileDocument = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = identificationFileUploadPreviewUrl;
    downloadLink.download = identificationFileUploadPreviewUrl.split('/').pop();
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    setIdentificationDocumentValues(IDdocument);
  }, [IDdocument]);

  const toggleDocumentFileCallback = identificationFileUploadPreviewUrl
    ? openViewImageModal
    : undefined;

  const isIdentificationPdfMimeType =
    !!identificationFileType && identificationFileType.includes('pdf');

  const getDocumentInformation = doc => (
    <div className="col-12 col-md-8 order-md-2">
      <div className="grid grid-gap-20">
        <div className="col-12">
          <div className="grid grid-gap-20">
            <div className="col-4 col-grid traveller-edit-identification-document__content">
              <div className="traveller-edit-identification-document__header">
                {I18n.t('public.people.edit.document_type')}
              </div>
              <div className="traveller-edit-identification-document__value">
                {I18n.t(doc.type, {
                  scope: 'simple_form.options.person.identification_documents.document_type',
                })}
              </div>
            </div>
            <div className="col-4 col-grid traveller-edit-identification-document__content">
              <div className="traveller-edit-identification-document__header">
                {I18n.t('public.people.edit.country')}
              </div>
              <div className="traveller-edit-identification-document__value">{doc.country}</div>
            </div>
            <div className="col-4 col-grid traveller-edit-identification-document__content">
              <div className="traveller-edit-identification-document__header">
                {I18n.t('public.people.edit.nationality')}
              </div>
              <div className="traveller-edit-identification-document__value">{doc.nationality}</div>
            </div>
            <div className="col-4 col-grid traveller-edit-identification-document__content">
              <div className="traveller-edit-identification-document__header">
                {I18n.t('public.people.edit.number')}
              </div>
              <div className="traveller-edit-identification-document__value">{doc.number}</div>
            </div>
            <div className="col-4 col-grid traveller-edit-identification-document__content">
              <div className="traveller-edit-identification-document__header">
                {I18n.t('public.people.edit.validity')}
              </div>
              <div className="traveller-edit-identification-document__value">
                {doc.validity ? (
                  <DateTime dateTime={doc.validity} format="short" />
                ) : (
                  I18n.t('admin.components.travellers.edit.identification_documents.not_provided')
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const documentImageModalTitle = (
    <div className="col-grid col-bleed direction-row justify-space-between traveller-edit-identification-document__image-preview-modal__header">
      <Text className="traveller-edit-identification-document__modal__text" level={5} weight="bold">
        {I18n.t('admin.identification_document.modal.title')}
      </Text>
      <IconButton
        className="traveller-edit-identification-document__modal__icon-button"
        icon={<Icon name="close" />}
        isIconOnly={true}
        color="tertiary"
        size="large"
        onClick={() => setViewImageModal(false)}
      />
    </div>
  );

  const documentImageModalContent = (
    <div className="grid">
      <div className="col-12 col-bleed">
        <LazyLoad height="100%" once={true}>
          <img
            className="flex-img"
            src={identificationFileUploadPreviewUrl}
            alt="identificationDocumentFile"
            loading="lazy"
          />
        </LazyLoad>
      </div>
    </div>
  );

  const documentImageModal = (
    <Modal
      className="traveller-edit-identification-document__modal"
      isModalVisible={viewImageModal}
      title={documentImageModalTitle}
    >
      {documentImageModalContent}
    </Modal>
  );

  return (
    <Card
      className="traveller-edit-identification-document__container traveller-edit-identification-document__update-container"
      key={IDdocument.id}
      version="v2"
      showMoreText={I18n.t('admin.components.travellers.edit.address.form.label.show')}
      hideMoreText={I18n.t('admin.components.travellers.edit.address.form.label.hide')}
      cardBodySize={ClassNames({
        medium: IDdocument.showDanger,
        small: !IDdocument.showDanger,
      })}
    >
      {isEditing ? (
        <Fragment>
          <IdentificationInformationForm
            documentId={documentId}
            documentTypes={documentTypes}
            countryOptions={countryOptions}
            nationalityOptions={nationalityOptions}
            type={type}
            country={country}
            nationality={nationality}
            number={number}
            validity={validity}
            primary={primary}
            primaryCheckDisabled={singleIdentificationDocumentPresent}
            setType={setType}
            setCountry={setCountry}
            setNationality={setNationality}
            setNumber={setNumber}
            setValidity={setValidity}
            setPrimary={setPrimary}
            identificationFileName={identificationFileName}
            setIdentificationFileName={setIdentificationFileName}
            identificationFileType={identificationFileType}
            setIdentificationFileType={setIdentificationFileType}
            setIdentificationFile={setIdentificationFile}
            identificationFileUploadPreviewUrl={identificationFileUploadPreviewUrl}
            setIdentificationFileUploadPreviewUrl={setIdentificationFileUploadPreviewUrl}
            setError={setError}
            error={error}
          />
          {documentId && (
            <div className="traveller-edit-identification-document-form__actions">
              <Button
                version="v2"
                size="small"
                type="primary"
                label={I18n.t('admin.components.travellers.edit.identification_documents.update')}
                onClick={onUpdateIdentification}
                disabled={!!error.identificationDocumentsFile}
              />
              <Button
                version="v2"
                size="small"
                label={I18n.t('admin.components.shared.action.cancel')}
                type="default"
                onClick={() => {
                  setIsEditing(false);
                  setIdentificationDocumentValues(IDdocument);
                }}
              />
            </div>
          )}
        </Fragment>
      ) : (
        <div className="grid grid-gap-20">
          {IDdocument.showDanger && (
            <div className="col-12 col-grid col-bleed-y traveller-edit-identification-document__warning">
              {I18n.t(IDdocument.type, {
                scope: 'admin.components.travellers.edit.identification_documents.warnings.expired',
              })}
            </div>
          )}

          <div className="col-12 col-grid direction-row traveller-edit-identification-document">
            <div className="traveller-edit-identification-document__summary">
              <span className="traveller-edit-identification-document__content">
                <img src={IDdocument.flagUrl} alt={IDdocument.nationalityCode} />
              </span>
              <span className="traveller-edit-identification-document__content traveller-edit-identification-document__content--country">
                {IDdocument.nationality}
              </span>
              {IDdocument.primary && (
                <span className="traveller-edit-identification-document__content traveller-edit-identification-document__content--primary-tag">
                  <EmphasisTag
                    type="important"
                    size="tiny"
                    text={I18n.t('admin.components.travellers.overview.left_panel.primary')}
                  />
                </span>
              )}
            </div>
            <div className="traveller-edit-identification-document__actions">
              <span className="traveller-edit-identification-document__content">
                <Link
                  labelHint={
                    identificationFileUploadPreviewUrl
                      ? I18n.t('admin.shared.action.download')
                      : I18n.t('admin.shared.action.cannot_download')
                  }
                  type="icon-button"
                  modifier="tertiary"
                  disabled={!identificationFileUploadPreviewUrl}
                  onClick={downloadFileDocument}
                >
                  <Icon name="download" size="small" />
                </Link>
              </span>
              <span className="traveller-edit-identification-document__content">
                <Link
                  labelHint={I18n.t('admin.shared.action.edit')}
                  type="icon-button"
                  modifier="tertiary"
                  onClick={() => setIsEditing(true)}
                >
                  <Icon name="edit" size="small" />
                </Link>
              </span>
              <span className="traveller-edit-identification-document__content">
                <Link
                  labelHint={
                    IDdocument.primary
                      ? I18n.t('admin.shared.action.cannot_delete')
                      : I18n.t('admin.shared.action.destroy')
                  }
                  type="icon-button"
                  modifier="tertiary"
                  disabled={IDdocument.primary}
                  onClick={toggleShowConfirmation}
                >
                  <Icon name="delete" size="small" />
                </Link>
              </span>
            </div>
          </div>

          {isIdentificationPdfMimeType ? (
            <div className="col-4 col-grid justify-center">
              <Link
                className="traveller-edit-identification-document-section__file-preview"
                href={identificationFileUploadPreviewUrl}
                modifier="tertiary"
                openNewTab={true}
              >
                <Icon name="pdf" size="small" />
                <div className="traveller-edit-identification-document__preview-text">
                  {I18n.t('admin.shared.action.preview_pdf')}
                </div>
              </Link>
            </div>
          ) : (
            <div
              role="presentation"
              onClick={toggleDocumentFileCallback}
              className="col-12 col-md-4 col-grid justify-center order-2"
            >
              <div className="traveller-edit-identification-document-section__image-container">
                {identificationFileUploadPreviewUrl ? (
                  <LazyLoad height="100%">
                    <div className="traveller-edit-identification-document-section__image-wrapper">
                      <img
                        className="flex-img traveller-edit-identification-document-section__image"
                        src={identificationFileUploadPreviewUrl}
                        alt="identificationDocumentFile"
                        loading="lazy"
                      />
                      <div className="traveller-edit-identification-document-section__image-overlay" />
                      <div className="traveller-edit-identification-document-section__image-overlay--on-hover" />
                    </div>
                  </LazyLoad>
                ) : (
                  <div
                    className={ClassNames(
                      'traveller-edit-identification-document-section__file-preview traveller-edit-identification-document-section__file-preview--no-preview',
                      {
                        'traveller-edit-identification-document-section__file-preview--disabled-cursor-pointer': !identificationFileUploadPreviewUrl,
                      },
                    )}
                  >
                    <Icon color="tertiary" name="orders" size="small" />
                    <div className="traveller-edit-identification-document__preview-text">
                      {I18n.t('admin.shared.action.unable_to_preview_file')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {documentImageModal}
          {getDocumentInformation(IDdocument)}
        </div>
      )}

      {isShowConfirmation && (
        <ConfirmationModal
          confirmationHeader={I18n.t('admin.identification_document.action.delete.title')}
          subHeader={I18n.t('admin.identification_document.action.delete.subtitle')}
          isModalOpen={true}
          withAction={true}
          type="danger"
          confirmationText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.positive',
          )}
          rejectionText={I18n.t(
            'admin.components.organizations.edit.email_and_phone.confirmation.negative',
          )}
          onConfirm={onDeleteIdentification}
          onReject={toggleShowConfirmation}
        />
      )}
    </Card>
  );
};

IdentificationInformation.defaultProps = {
  IDdocument: {},
  layman: false,
};

IdentificationInformation.propTypes = {
  personId: PropTypes.number.isRequired,
  fetchIdentificationDocuments: PropTypes.func.isRequired,
  documentTypes: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  nationalityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  IDdocument: PropTypes.shape({
    id: PropTypes.number,
    flagUrl: PropTypes.string,
    nationality: PropTypes.string,
    showDanger: PropTypes.bool,
    type: PropTypes.string,
    countryCode: PropTypes.string,
    nationalityCode: PropTypes.string,
    number: PropTypes.string,
    validity: PropTypes.string,
    primary: PropTypes.bool,
    fileName: PropTypes.string,
  }),
  layman: PropTypes.bool,
  documentId: PropTypes.number.isRequired,
  singleIdentificationDocumentPresent: PropTypes.bool.isRequired,
};

export default IdentificationInformation;
