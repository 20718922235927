import { HOTEL_SET_DEFAULT_SEARCH_PARAMS, SEARCHING_HOTELS } from '../../actions/hotel';

export default function defaultHotelSearchParams(state = {}, action) {
  switch (action.type) {
    case SEARCHING_HOTELS:
      return {};

    case HOTEL_SET_DEFAULT_SEARCH_PARAMS:
      return action.params;

    default:
      return state;
  }
}
