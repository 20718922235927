import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import { startFromCart, endFromCart } from '../../lib/datesFromCart';

import { validateAllSearchParams } from './helpers/validate';
import cartOverride from './helpers/cartOverride';
import deepClone from '../../lib/deepClone';
import SearchForm from './SearchForm';
import searchAccountValues from '../../helpers/searchAccountValues';
import { createCarSearch } from '../../actions/car';
import { resetApp } from '../../actions/common';

const initialFields = [
  'pickUpDate',
  'dropOffDate',
  'pickUpTime',
  'dropOffTime',
  'airportSearch',
  'pickUpLocationAirportCode',
  'pickUpGeoLocationAddress',
  'pickUpGeoLocationLatitude',
  'pickUpGeoLocationLongitude',
  'dropOffLocationAirportCode',
  'dropOffGeoLocationAddress',
  'dropOffGeoLocationLatitude',
  'dropOffGeoLocationLongitude',
  'driverAge',
  'vendorPreferences.codes',
  'vendorPreferences.type',
];

const reduxFormConfig = {
  form: 'carSearchForm',
  destroyOnUnmount: false,
  fields: [],
};

const mapStateToProps = (state, ownProps) => {
  const { adults } = state.common.travelerCount;
  const fields = deepClone(initialFields);
  const cartHasCar = state.common.cart.items.some(item => item.type === 'car');
  if (ownProps.searchAccounts && ownProps.searchAccounts.cars) {
    Object.keys(ownProps.searchAccounts.cars).forEach(key => fields.push(`accountSettings.${key}`));
  }
  const startDateFromCart = startFromCart(state);
  const endDateFromCart = endFromCart(state);
  let vendorsPreferences = [];

  if (ownProps.globalPolicyPreferences.carRentalCompanies.length > 0) {
    vendorsPreferences = ownProps.globalPolicyPreferences.carRentalCompanies;
  }

  const defaultSearchParams = {
    accountSettings: searchAccountValues(ownProps.searchAccounts.cars),
    airportSearch: false,
    driverAge: ownProps.currentUserAge || 32,
    pickUpDate:
      startDateFromCart ||
      state.cars.defaultCarSearchParams.pickUpDate ||
      moment()
        .add(7, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
    dropOffDate: endDateFromCart || state.cars.defaultCarSearchParams.dropOffDate,
    pickUpTime: '12:00',
    dropOffTime: '11:00',
    vendorPreferences: state.cars.defaultCarSearchParams.vendorPreferences || {
      codes: vendorsPreferences,
      type: 'exclusive',
    },
  };

  const searchForm = state.form.carSearchForm;

  const preselectTravelers = state.common.preselectTravelers.travelers;
  const cartHasItems = state.common.cart.items.length > 0;

  return {
    fields,
    cartHasCar,
    initialValues: {
      ...defaultSearchParams,
      ...ownProps.searchParams,
      ...state.cars.defaultCarSearchParams,
      ...cartOverride(adults),
    },
    validate: validateAllSearchParams(ownProps.availableChannels.cars, ownProps.minHoursInFuture),
    defaultSearchParams,
    preselectTravelers,
    searchForm,
    cartHasItems,
    travelerLoggedIn: ownProps.travelerLoggedIn,
    airportSuggestions: state.common.airportSuggestions,
  };
};

const mapDispatchToProps = dispatch => ({
  createCarSearch: (params, callbacks) => dispatch(createCarSearch(params, callbacks)),
  resetApp: () => dispatch(resetApp('/cars')),
});

const ReduxForm = reduxForm(reduxFormConfig, mapStateToProps, mapDispatchToProps)(SearchForm);

const SearchFormWithContext = (props, context) => <ReduxForm {...props} {...context} />;

SearchFormWithContext.contextTypes = {
  availableChannels: PropTypes.shape(),
  searchAccounts: PropTypes.shape(),
  laymanMode: PropTypes.bool,
  currency: PropTypes.string,
  callbackParams: PropTypes.shape(),
  minHoursInFuture: PropTypes.number.isRequired,
  currentUserAge: PropTypes.number.isRequired,
  globalPolicyPreferences: PropTypes.shape({
    airlines: PropTypes.arrayOf(PropTypes.number).isRequired,
    carRentalCompanies: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default SearchFormWithContext;
