import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { ensureCart, fetchCart, selectFlightResultInMultiSearch } from '../common';

export const FLIGHT_SEARCH_RESULT_SELECTED = 'FLIGHT_SEARCH_RESULT_SELECTED';
function flightSearchResultSelected(result, searchId) {
  return {
    type: FLIGHT_SEARCH_RESULT_SELECTED,
    result,
    searchId,
  };
}

export const ADD_FLIGHT_TO_CART = 'ADD_FLIGHT_TO_CART';
export function addFlightToCart(cartId, id, searchParams, callbackParams, justification = null) {
  return dispatch => {
    dispatch({ type: ADD_FLIGHT_TO_CART, id, searchParams });
    return httpClient
      .post(routes.api.carts.items.addFlight({ cartId }), {
        result_id: id,
        callback_params: callbackParams,
        justification,
      })
      .then(({ data }) => data);
  };
}

export const STEP_SHOW_EXCHANGE_QUOTE = 'STEP_SHOW_EXCHANGE_QUOTE';
export const STEP_GO_TO_CART = 'STEP_GO_TO_CART';
export const STEP_CHOOSE_NEXT_RESULT = 'STEP_CHOOSE_NEXT_RESULT';

export const FARE_OPTION_SELECTED = 'FARE_OPTION_SELECTED';
export const FLIGHT_RESULT_SELECTED = 'FLIGHT_RESULT_SELECTED';
export const READY_FOR_CART = 'READY_FOR_CART';

export function selectFlightResult(
  searchId,
  matrix,
  result,
  tabKeyOfSelectedFairOption,
  exchangeBookingId,
) {
  return (dispatch, getState) => {
    dispatch({
      type: FLIGHT_RESULT_SELECTED,
      result,
      matrix,
    });
    dispatch({
      type: FARE_OPTION_SELECTED,
      tabKeyOfSelectedFairOption,
    });
    const state = getState();

    const expectedSelectionCount =
      state.flights.searchParamsBySearchId[searchId] &&
      state.flights.searchParamsBySearchId[searchId].trips
        ? state.flights.searchParamsBySearchId[searchId].trips.length
        : 0;
    const selectedResults = state.flights.selectedResults;

    let selectedTripCount = 0;
    Object.values(selectedResults).forEach(selectedResult => {
      selectedTripCount += selectedResult.packageSize;
    });
    const selectionComplete = selectedTripCount === expectedSelectionCount;

    if (selectionComplete) {
      if (exchangeBookingId) {
        // we can assume that we only have one specific result ID when going through the
        // exchange workflow as we update one specific booking
        return Promise.resolve({ step: STEP_SHOW_EXCHANGE_QUOTE, resultId: result.resultId });
      }

      dispatch(flightSearchResultSelected(result, searchId));
      return Promise.resolve({ step: READY_FOR_CART, result });
    }
    return Promise.resolve({ step: STEP_CHOOSE_NEXT_RESULT });
  };
}

export function addFlightsFromResultToCart(
  searchId,
  deal,
  dealGroup,
  callbackParams,
  justification,
) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedResults = state.flights.selectedResults;

    const resultIds = Object.values(selectedResults).map(selectedResult => selectedResult.resultId);

    return dispatch(ensureCart(callbackParams)).then(cartId =>
      Promise.all(
        resultIds.map(id => {
          if (deal && dealGroup) {
            return dispatch(
              selectFlightResultInMultiSearch(dealGroup, id, searchId, callbackParams),
            );
          }
          const searchParams = state.flights.searchParamsBySearchId[searchId];
          return dispatch(addFlightToCart(cartId, id, searchParams, callbackParams, justification));
        }),
      ).then(() => {
        if (!deal || !dealGroup) {
          dispatch(fetchCart(cartId, callbackParams));
          return Promise.resolve({ step: STEP_GO_TO_CART, cartId });
        }
        return Promise.resolve(null);
      }),
    );
  };
}
