import { connect } from 'react-redux';
import {
  fetchFees,
  addFeeToCart,
  removeFeeFromCart,
  setFeeQuantity,
  fetchFeeSuggestions,
} from '../../actions/common';

import FeesAndServices from './';

const mapStateToProps = state => ({
  fees: state.common.fees,
  feeSuggestions: state.common.feeSuggestions,
  selected: state.common.cart.fees,
  totalFeesAndPaymentMethodSurcharges: state.common.cart.totalFeesAndPaymentMethodSurcharges,
  paymentMethodSurcharge: state.common.cart.paymentMethodSurcharge,
});

const mapDispatchToProps = dispatch => ({
  fetchFees: callbackParams => dispatch(fetchFees(callbackParams)),
  fetchFeeSuggestions: callbackParams => dispatch(fetchFeeSuggestions(callbackParams)),
  onAdd: fee => dispatch(addFeeToCart(fee)),
  onRemove: fee => dispatch(removeFeeFromCart(fee.id)),
  setQuantity: (fee, quantity) => dispatch(setFeeQuantity(fee.id, quantity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeesAndServices);
