import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Amount from 'sharedWebpack/Amount';
import FareRulesLink from './FareRulesLinks';
import BaggageLabel from './BaggageLabel';
import ETicketsLink from './ETicketsLink';
import LabelWithHint from '../../components/LabelWithHint';

const BookingClassStatusLabels = ({ getBookingStatusLabels }) =>
  getBookingStatusLabels.map(({ iconName, iconColor, label }) => (
    <span className="d-flex align-center flight__information-label-item">
      <div className="d-flex align-center flight__information-label-icon">
        <Icon name={iconName} size="tiny" color={iconColor} />
        {label}
      </div>
    </span>
  ));

const BookingClassLabels = ({ getBookingClassLabels }) =>
  getBookingClassLabels.map(({ iconName, iconColor, iconLabel }) => (
    <span className="flight__information-label-item">
      <Icon
        className="flight__information-label-icon"
        name={iconName}
        size="tiny"
        color={iconColor}
        showLabel={true}
        iconLabel={iconLabel}
      />
    </span>
  ));

const SearchFlightInfo = ({
  className,
  group,
  laymanMode,
  eTicketsPresent,
  tripId,
  tripSegments,
}) => {
  const {
    cabinClasses,
    bookingClasses,
    fareRulePresent,
    resultId,
    airlineCodes,
    payLater,
    instantTicket,
    labels,
    multiTicket,
    approvalRequired,
    policiesReasons,
    fareTypes,
    segments,
    punctuality,
    overnight,
  } = group;
  const getBookingClassLabels = () => {
    const bookingClassLabels = [];

    if (cabinClasses) {
      bookingClassLabels.push({
        iconName: 'bookingClass',
        iconColor: 'default',
        iconLabel: I18n.t(cabinClasses, { scope: 'cabin_classes' }),
      });
    }

    if (bookingClasses) {
      bookingClassLabels.push({
        iconName: 'bookingClass',
        iconColor: 'default',
        iconLabel: bookingClasses,
      });
    }

    bookingClassLabels.push({
      iconName: overnight ? 'dayOrNight' : 'dayLight',
      iconColor: 'default',
      iconLabel: overnight
        ? I18n.t('components.ibe.results.overnight')
        : I18n.t('components.ibe.results.daylight'),
    });

    return bookingClassLabels;
  };

  const getBookingStatusLabels = () => {
    const bookingStatusLabels = [];

    if (instantTicket) {
      bookingStatusLabels.push({
        iconName: 'eTicket',
        iconColor: 'warning',
        label: (
          <LabelWithHint
            text={I18n.t('components.instant_issue')}
            hint={I18n.t('components.instant_ticket.description')}
          />
        ),
      });
    }

    if (multiTicket) {
      bookingStatusLabels.push({
        iconName: 'eTicket',
        iconColor: 'warning',
        label: (
          <LabelWithHint
            text={I18n.t('components.ibe.results.multi_tickets')}
            hint={I18n.t('components.ibe.results.multi_tickets_hint')}
          />
        ),
      });
    }

    if (Array.isArray(segments)) {
      bookingStatusLabels.push({
        iconName: 'baggage',
        iconColor: 'default',
        label: (
          <BaggageLabel baggageInfo={segments.map(segment => segment.baggagePiecesIncluded)} />
        ),
      });
    }

    if (punctuality && punctuality !== 0) {
      bookingStatusLabels.push({
        iconName: 'punctuality',
        iconColor: 'default',
        label: (
          <Fragment>
            <Amount value={punctuality} /> %
          </Fragment>
        ),
      });
    }

    if (approvalRequired) {
      bookingStatusLabels.push({
        iconName: 'singlePolicy',
        iconColor: 'danger',
        label: (
          <LabelWithHint
            text={I18n.t('components.ibe.search_result.approval_required')}
            hint={policiesReasons.approvalRequired}
          />
        ),
      });
    }

    if (payLater && !laymanMode) {
      bookingStatusLabels.push({
        iconName: 'recurringPayment',
        iconColor: 'success',
        label: (
          <LabelWithHint
            text={I18n.t('components.ibe.results.book_and_hold')}
            hint={I18n.t('components.ibe.results.book_and_hold_hint')}
          />
        ),
      });
    }

    if (fareTypes) {
      bookingStatusLabels.push({
        iconName: 'checkCircleOutline',
        iconColor: 'success',
        label: fareTypes,
      });
    }

    if (labels) {
      const { preferred, outOfPolicy } = labels;

      if (preferred) {
        bookingStatusLabels.push({
          iconName: 'singlePolicy',
          iconColor: 'danger',
          label: (
            <LabelWithHint
              text={I18n.t('components.ibe.search_result.labels.preferred')}
              hint={policiesReasons.preferred}
            />
          ),
        });
      }

      if (outOfPolicy) {
        bookingStatusLabels.push({
          iconName: 'singlePolicy',
          iconColor: 'danger',
          label: (
            <LabelWithHint
              text={I18n.t('components.ibe.search_result.labels.out_of_policy')}
              hint={policiesReasons.outOfPolicy}
            />
          ),
        });
      }
    }

    return bookingStatusLabels;
  };

  return (
    <div className={className}>
      <div className="d-flex align-center justify-between wrap flight__information-labels-container">
        <div className="d-flex align-center justify-center wrap flight__information-labels">
          <BookingClassLabels getBookingClassLabels={getBookingClassLabels()} />
          {eTicketsPresent && (
            <span className="flight__information-label-item flight__information-label-item--etickets-link">
              <ETicketsLink tripId={tripId} tripSegments={tripSegments} />
            </span>
          )}
          {fareRulePresent && (
            <span className="flight__information-label-item">
              <FareRulesLink searchResultId={resultId} airlineCodes={airlineCodes} />
            </span>
          )}
        </div>
        <div className="d-flex align-center justify-center wrap flight__information-labels">
          <BookingClassStatusLabels getBookingStatusLabels={getBookingStatusLabels()} />
        </div>
      </div>
    </div>
  );
};

SearchFlightInfo.defaultProps = {
  className: null,
  laymanMode: false,
  eTicketsPresent: false,
};

SearchFlightInfo.propTypes = {
  className: PropTypes.string,
  group: PropTypes.shape({
    resultId: PropTypes.string.isRequired,
    cabinClasses: PropTypes.string.isRequired,
    bookingClasses: PropTypes.string.isRequired,
    overnight: PropTypes.bool.isRequired,
    multiTicket: PropTypes.bool.isRequired,
    policiesReasons: PropTypes.shape({
      preferred: PropTypes.string,
      outOfPolicy: PropTypes.string,
      approvalRequired: PropTypes.string,
    }),
    fareRulePresent: PropTypes.bool,
    payLater: PropTypes.bool.isRequired,
    segments: PropTypes.arrayOf(
      PropTypes.shape({
        baggagePiecesIncluded: PropTypes.shape({
          count: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.number]),
          kgPerPiece: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.number]),
        }),
      }),
    ).isRequired,
    instantTicket: PropTypes.bool.isRequired,
    approvalRequired: PropTypes.bool.isRequired,
    punctuality: PropTypes.number.isRequired,
    fareTypes: PropTypes.string.isRequired,
    airlineCodes: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.shape({
      preferred: PropTypes.bool,
      outOfPolicy: PropTypes.bool,
    }),
  }).isRequired,
  laymanMode: PropTypes.bool,
  eTicketsPresent: PropTypes.bool,
  tripId: PropTypes.string.isRequired,
  tripSegments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

BookingClassStatusLabels.PropTypes = {
  getBookingStatusLabels: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string,
      iconColor: PropTypes.string,
      label: PropTypes.node,
    }),
  ).isRequired,
};

BookingClassLabels.PropTypes = {
  getBookingClassLabels: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string,
      iconColor: PropTypes.string,
      iconLabel: PropTypes.node,
    }),
  ).isRequired,
};

export default SearchFlightInfo;
