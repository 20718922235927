import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import { I18nText } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';

import searchURL from '../../../IBE/lib/helpers/searchURL';
import RemoveFromCartButton from './RemoveFromCartButton';

class ItemRemoveAndSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const {
      isCartLocked,
      available,
      removeFromCart,
      showConfirmButton,
      confirmAddition,
      item,
      leftSide,
      itemStatus,
    } = this.props;

    const availability = available;
    const notAvailable = !availability;

    let goToSearch = (
      <Button
        version="v2"
        size="small"
        label={I18n.t('admin.components.cart.additional_results')}
        disabled={this.context.frozen}
      />
    );

    if (!this.context.frozen) {
      goToSearch = <Link to={searchURL(item.type, item.search.identifier)}>{goToSearch}</Link>;
    }

    const confirmAdditionAndShowModal = () => {
      confirmAddition(item.id).then(() => this.setState({ isModalOpen: true }));
    };

    return (
      <React.Fragment>
        <ConfirmationModal
          isModalOpen={this.state.isModalOpen}
          withAction={false}
          confirmationHeader={
            <I18nText id="ibe.automatic_products.success.header" returnStringOnly={true} />
          }
          subHeader={<I18nText id="ibe.automatic_products.success.text" returnStringOnly={true} />}
          timeout={3000}
          onTimeout={() => this.setState({ isModalOpen: false })}
          type="success"
        />
        <div className="col-12 col-bleed-x col-grid direction-row wrap justify-space-between">
          {leftSide && (
            <div className="col-grid col-md-6 col-bleed item__left-side">{leftSide}</div>
          )}
          <div
            className={classNames('item__actions', 'col-grid', 'col-bleed', {
              'col-md-4 col-sm-12': !!leftSide,
              'col-12 item__actions--without-leftside': !leftSide,
            })}
          >
            <div
              className={classNames({
                'item__actions--available': availability,
                'item__actions--not-available': notAvailable,
              })}
            >
              {item.search && item.search.identifier && !itemStatus && goToSearch}
            </div>
            {showConfirmButton && (
              <div className="item__actions-confirm-button">
                <Button
                  version="v2"
                  size="small"
                  type="primary"
                  label={
                    <I18nText
                      id="ibe.automatic_products.confirm_addition"
                      returnStringOnly={true}
                    />
                  }
                  onClick={confirmAdditionAndShowModal}
                />
              </div>
            )}
            <div className="item__actions-remove-button">
              <RemoveFromCartButton
                isCartLocked={isCartLocked}
                onConfirm={removeFromCart}
                itemId={item.id}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ItemRemoveAndSearch.defaultProps = {
  isCartLocked: false,
  journeyElement: null,
  leftSide: null,
  showConfirmButton: false,
};

ItemRemoveAndSearch.contextTypes = {
  frozen: PropTypes.bool.isRequired,
};

ItemRemoveAndSearch.propTypes = {
  available: PropTypes.oneOf([Boolean, undefined]).isRequired,
  leftSide: PropTypes.node,
  isCartLocked: PropTypes.bool,
  item: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    available: PropTypes.bool,
    id: PropTypes.number,
    type: PropTypes.string,
    search: PropTypes.shape({
      identifier: PropTypes.string,
    }),
  }).isRequired,
  itemStatus: PropTypes.shape({
    status: PropTypes.bool,
  }).isRequired,
  journeyElement: PropTypes.shape({
    destinationCode: PropTypes.string,
    fareRulePresent: PropTypes.bool,
    id: PropTypes.number,
    originCode: PropTypes.string,
    refundable: PropTypes.bool,
    services: PropTypes.arrayOf(),
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        segments: PropTypes.arrayOf(
          PropTypes.shape({
            aircraftType: PropTypes.string.isRequired,
            destinationCode: PropTypes.string.isRequired,
            operatingCarrierCode: PropTypes.string.isRequired,
            operatingFlightNumber: PropTypes.string.isRequired,
            originCode: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }),
  removeFromCart: PropTypes.func.isRequired,
  confirmAddition: PropTypes.func.isRequired,
  showConfirmButton: PropTypes.bool,
};

export default ItemRemoveAndSearch;
