const getFilteredAirportSuggestions = (airportSuggestions, origin, destination) => {
  const suggestions = airportSuggestions[destination];

  if (!Array.isArray(suggestions)) {
    return [];
  }

  return suggestions.filter(item => item.code !== origin);
};

export default getFilteredAirportSuggestions;
