import { FETCHED_SET_FORM_OF_PAYMENT } from '../../actions/common/fop';
import { REMOVE_FROM_CART } from '../../actions/common';

export default function formOfPayment(state = {}, action) {
  const newState = Object.assign({}, state);

  const setFormOfPayment = (resultId, cardDetails, checkCardType) => {
    newState[resultId] = {
      fop: cardDetails ? 'credit_card' : checkCardType,
      cardDetails,
    };
  };

  const removeFormOfPayment = resultId => {
    delete newState[resultId];
  };

  switch (action.type) {
    case FETCHED_SET_FORM_OF_PAYMENT:
      setFormOfPayment(action.itemId, action.cardDetails, action.checkCardType);
      return newState;
    case REMOVE_FROM_CART:
      removeFormOfPayment(action.itemResultId);
      return newState;
    default:
      return state;
  }
}
