import React from 'react';
import PropTypes from 'prop-types';
import Stars from '../../../../components/Stars';

const StarOptionName = ({ starCount }) => {
  if (starCount === -1) {
    return <span>{I18n.t('components.ibe.results.filters.stars.unknown')}</span>;
  }

  if (starCount === 0) {
    return <span>{I18n.t('components.ibe.results.filters.stars.none')}</span>;
  }

  return <Stars stars={starCount} />;
};

StarOptionName.propTypes = {
  starCount: PropTypes.number.isRequired,
};

export default StarOptionName;
