const getTotalStopoverDuration = stopovers => {
  const initialDurationCount = 0;

  if (!Array.isArray(stopovers)) {
    return initialDurationCount;
  }

  return stopovers.reduce(
    (currentTotalDurationInMinutes, { transitDurationsInMinutes }) =>
      currentTotalDurationInMinutes + transitDurationsInMinutes,
    initialDurationCount,
  );
};

export default getTotalStopoverDuration;
