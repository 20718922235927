import PropTypes from 'prop-types';

const pricingItemShape = {
  amount: PropTypes.string,
  currency: PropTypes.string,
  fixedAmount: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
  taxes: PropTypes.number,
  total: PropTypes.string,
};

export const airlineCodeItemShape = {
  code: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export const airlineInformationItemShape = {
  name: PropTypes.string,
  code: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export const segmentItemShape = {
  aircraftType: PropTypes.string,
  airlineCode: PropTypes.string,
  bookingClass: PropTypes.string,
  cabinClass: PropTypes.string,
  departureTime: PropTypes.string,
  destinationCode: PropTypes.string,
  destinationTerminal: PropTypes.string,
  fareType: PropTypes.string,
  flightDurationInMinutes: PropTypes.number,
  operatingCarrierCode: PropTypes.string,
  operatingFlight: PropTypes.string,
  originCode: PropTypes.string,
  originTerminal: PropTypes.string,
  overnight: PropTypes.bool,
  technicalStops: PropTypes.shape({
    count: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.number]),
    stopDetails: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

export const stopoverItemShape = {
  airportCode: PropTypes.string,
  arrivalLocalTime: PropTypes.string,
  transitDurationsInMinutes: PropTypes.number,
};

export const priceItemShape = {
  currencyCode: PropTypes.string,
  grossLegPrice: PropTypes.string,
  grossTotalPrice: PropTypes.string,
  legMarginBreakdown: PropTypes.arrayOf(PropTypes.shape(pricingItemShape)),
  marginAmount: PropTypes.string,
  netLegPrice: PropTypes.string,
  pricing: PropTypes.shape({
    conversionInfoAttributes: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.string]),
    currency: PropTypes.string,
    grossFlightPrice: PropTypes.string,
    grossLegTaxes: PropTypes.string,
    grossTaxes: PropTypes.string,
    grossTotal: PropTypes.string,
    marginAmount: PropTypes.string,
    marginBreakdown: PropTypes.arrayOf(PropTypes.shape(pricingItemShape)),
    netFlightPrice: PropTypes.string,
    netTaxes: PropTypes.string,
    netTotal: PropTypes.string,
  }),
};

export const airportItemShape = {
  [PropTypes.string]: PropTypes.shape({
    code: PropTypes.string,
    countryFlag: PropTypes.string,
    countryName: PropTypes.string,
    name: PropTypes.string,
    placeName: PropTypes.string,
  }),
};

export const airlineDataShape = {
  [PropTypes.string]: PropTypes.shape({
    code: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
  }),
};
