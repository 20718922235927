import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';

const MenuLabel = ({ onClick, label, isError, icon, isVisible }) => (
  <div
    className={classNames('search-menu__menu-label', {
      'search-menu__label--error': isError,
      'search-menu__menu-label--active': isVisible,
    })}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyPress={onClick}
  >
    {icon}
    <span>{label}</span>
    <Icon name="iconDownChevron" size="tiny" />
  </div>
);

MenuLabel.defaultProps = {
  isError: false,
  icon: null,
  isVisible: false,
};

MenuLabel.propTypes = {
  isError: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  isVisible: PropTypes.bool,
};

export default MenuLabel;
