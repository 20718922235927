import { fetchFlightServices } from '../flight';
import { fetchHotelDetails } from '../hotel';
import { fetchCarSearchResults } from '../car';
import { checkCartItemsAvailability } from './cart';

export const FETCHING_SERVICES = 'FETCHING_SERVICES';
function fetchingServices(cart) {
  return { type: FETCHING_SERVICES, cart };
}
export default function fetchServices(cart, callbackParams) {
  return dispatch => {
    dispatch(fetchingServices(cart));
    const cartPromises = cart.items.map(item => {
      switch (item.type) {
        case 'flight':
          return dispatch(fetchFlightServices(item.bookingAttributes.resultId, callbackParams));
        case 'hotel':
          dispatch(fetchHotelDetails(item.bookingAttributes.resultId, callbackParams));
          return Promise.resolve();
        case 'car':
          dispatch(fetchCarSearchResults({ searchId: item.search.identifier }));
          return Promise.resolve();
        default:
          return Promise.resolve();
      }
    });
    return Promise.all(cartPromises).then(() => {
      dispatch(checkCartItemsAvailability(callbackParams));
    });
  };
}
