import { useEffect, useState } from 'react';

const useSearchFormContainer = () => {
  const [showSearchFormContainer, setShowFormContainer] = useState(false);

  useEffect(() => {
    setShowFormContainer(true);
  }, []);

  return showSearchFormContainer;
};

export default useSearchFormContainer;
