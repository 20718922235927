import React from 'react';
import PropTypes from 'prop-types';

import withJourneyElement from '../../lib/decorators/withJourneyElement';
import FlightItem from './Items/Flight';
import HotelItem from './Items/Hotel';
import CarItem from './Items/Car';
import StaticProduct from './Items/StaticProduct';
import Fee from './Items/Fee';
import Payment from './Items/Payment';
import './styles.scss';

const CartItemWrapper = props => {
  const { item } = props;
  const availableComponents = {
    flight: FlightItem,
    hotel: HotelItem,
    car: CarItem,
    static_product: StaticProduct,
    fee: Fee,
    payment: Payment,
  };
  const SummaryItem = availableComponents[item.type];

  return item.type ? (
    <SummaryItem
      key={item.id}
      item={{ ...item }}
      staticProducts={props.staticProducts}
      travelerCount={props.travelerCount}
    />
  ) : null;
};

CartItemWrapper.defaultProps = {
  staticProducts: [],
};

CartItemWrapper.propTypes = {
  travelerCount: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    available: PropTypes.bool,
    journeyElementType: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    productId: PropTypes.number,
  }).isRequired,
  journeyElement: PropTypes.shape({
    name: PropTypes.string.isRequired,
    vendorName: PropTypes.string.isRequired,
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        originCode: PropTypes.string.isRequired,
        segments: PropTypes.arrayOf(
          PropTypes.shape({
            aircraftType: PropTypes.string.isRequired,
            destinationCode: PropTypes.string.isRequired,
            operatingCarrierCode: PropTypes.string.isRequired,
            operatingFlightNumber: PropTypes.string.isRequired,
            originCode: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  staticProducts: PropTypes.arrayOf(PropTypes.shape({})),
};
export default withJourneyElement(CartItemWrapper);
