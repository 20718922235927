import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@wtag/rcl-popover';
import CheckBox from '@wtag/rcl-check-box';
import Icon from '@wtag/rcl-icon';
import './styles.scss';

const TravelerAssignment = ({
  travelers,
  travelerIndices,
  onChange,
  handleQuantityChange,
  maximumPerGroup,
  assignedTravelersOfGroup,
  max,
}) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const togglePopoverVisibility = () => {
    setIsPopoverVisible(prevState => !prevState);
  };

  const popoverPlaceholders = travelerIndices.map(index =>
    I18n.t('components.ibe.flight_service_selection.traveler.short', {
      index: index + 1,
    }),
  );

  const isTravelerSelectionDisabled = index => {
    const isTravelerExists = travelerIndices.indexOf(index) < 0;
    const isLimitReached = travelerIndices.length >= max;
    const isMaximumPerGroupReached =
      maximumPerGroup &&
      assignedTravelersOfGroup.filter(assignedIndex => assignedIndex === index).length >=
        maximumPerGroup;

    return isTravelerExists && (isLimitReached || isMaximumPerGroupReached);
  };

  return (
    <Popover
      className="traveler-assignment-popover"
      isVisible={isPopoverVisible}
      onOutsideClick={() => {
        setIsPopoverVisible(false);
        handleQuantityChange(travelerIndices.length);
      }}
      direction="bottom"
      content={
        <div className="traveler-assignment-popover__content">
          <div className="traveler-assignment-popover__content--header">
            {I18n.t('components.ibe.flight_service_selection.select_travelers')}
          </div>
          <div className="traveler-assignment-popover__content--travelers">
            {travelers.map(({ index, name }) => (
              <CheckBox
                key={index}
                onChange={event => onChange(event.target.checked, index)}
                name={`traveler-${index}`}
                isChecked={travelerIndices.indexOf(index) >= 0}
                disabled={isTravelerSelectionDisabled(index)}
                label={name}
              />
            ))}
          </div>
        </div>
      }
    >
      <div
        className="traveler-assignment-popover__placeholder"
        role="button"
        tabIndex={0}
        onClick={togglePopoverVisibility}
        onKeyDown={togglePopoverVisibility}
      >
        <div className="traveler-assignment-popover__traveler-name">
          {popoverPlaceholders.join(', ')}
        </div>
        <Icon name="iconDownChevron" size="tiny" />
      </div>
    </Popover>
  );
};

TravelerAssignment.defaultProps = {
  maximumPerGroup: null,
};

TravelerAssignment.propTypes = {
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      name: PropTypes.node.isRequired,
    }),
  ).isRequired,
  travelerIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
  max: PropTypes.number.isRequired,
  maximumPerGroup: PropTypes.number,
  assignedTravelersOfGroup: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  handleQuantityChange: PropTypes.func.isRequired,
};

export default TravelerAssignment;
