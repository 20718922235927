const getInsuranceInfo = insurances => {
  const insuranceInfo = insurances
    .map(insurance =>
      I18n.t(insurance.type, {
        scope: 'components.ibe.search_results.car.insurance_and_liability',
      }),
    )
    .join(', ');

  return insuranceInfo
    ? `${I18n.t('components.ibe.search_results.car.content.insurance', {
        insurances: insuranceInfo,
      })}\n`
    : '';
};

export default getInsuranceInfo;
