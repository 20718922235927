import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Card from '@wtag/rcl-card';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import { LabelHint } from '@wtag/react-comp-lib';
import IconButton from '@wtag/rcl-icon-button';
import Amount from 'sharedWebpack/Amount';
import AddNewFee from './AddNewFee';
import feeList from '../../lib/feeList';

import './style.scss';

class FeesAndServices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestingForFees: [],
    };
  }

  componentDidMount() {
    if (!this.props.fees.length) {
      this.props.fetchFees(this.context.callbackParams);
    }
    if (!this.props.feeSuggestions.length) {
      this.props.fetchFeeSuggestions(this.context.callbackParams);
    }
  }

  setSelectedFee = fee => {
    const requestingForFees = new Set([...this.state.requestingForFees]);
    requestingForFees.add(fee.id);
    this.setState({ requestingForFees: [...requestingForFees] });
  };

  deleteSelectedFee = fee => {
    const requestingForFees = new Set([...this.state.requestingForFees]);
    requestingForFees.delete(fee.id);
    this.setState({ requestingForFees: [...requestingForFees] });
  };

  feeSelected = fee => this.props.selected.findIndex(selectedFee => selectedFee.id === fee.id) > -1;

  selectFee = fee => {
    this.setSelectedFee(fee);

    if (this.feeSelected(fee)) {
      this.props.onRemove(fee).then(() => this.deleteSelectedFee(fee));
    } else {
      this.props.onAdd(fee).then(() => this.deleteSelectedFee(fee));
    }
  };

  feeTotal = fee => {
    const grossAmount = (
      <span>
        <Amount currency={fee.currency} value={fee.grossTotal} />
      </span>
    );

    if (!fee.grossVat) return grossAmount;

    const vat = <Amount currency={fee.currency} value={fee.grossVat} />;

    return (
      <LabelHint
        hint={
          <Fragment>
            {I18n.t('ibe.payment.including')} {vat} {I18n.t('ibe.payment.vat')}
          </Fragment>
        }
      >
        {grossAmount}
      </LabelHint>
    );
  };

  quantityWithField = (fee, disable) => (
    <Input
      type="number"
      size="tiny"
      value={fee.quantity}
      isClearable={false}
      onChange={value => {
        if (!this.context.laymanMode) {
          this.props.setQuantity(fee, value);
        }
      }}
      disabled={this.context.laymanMode || disable}
    />
  );

  allAvailableFees = () => {
    const fees = feeList(
      this.props.fees,
      this.props.selected,
      this.props.intl,
      !this.context.laymanMode,
    );

    const mappedFees = fees.map(fee => (
      <Card version="v2" key={fee.id}>
        <div className="col-sm-4 col-bleed-y payment__fee-services-fee-name">{fee.name}</div>
        <div className="col-sm-3 col-bleed-y payment__fee-services-fee-quantity">
          {this.quantityWithField(fee, false)}
        </div>
        <div className="col-sm-3 col-bleed-y payment__fee-services-value">{this.feeTotal(fee)}</div>
        <div className="col-sm-2 col-bleed-y payment__fee-services-cross-button">
          {!this.context.laymanMode && (
            <IconButton icon={<Icon name="close" />} onClick={() => this.props.onRemove(fee)} />
          )}
        </div>
      </Card>
    ));

    if (!this.props.paymentMethodSurcharge || !this.props.paymentMethodSurcharge.title) {
      return mappedFees;
    }

    const paymentMethodSurcharge = (
      <Card version="v2" key="payment-surcharge">
        <div className="col-4 col-bleed-y payment__fee-services-fee-name">
          {this.props.paymentMethodSurcharge.title}
        </div>
        <div className="col-3 col-bleed-y">
          {this.quantityWithField(
            {
              quantity: 1,
            },
            true,
          )}
        </div>
        <div className="col-3 col-bleed-y payment__fee-services-value">
          <Amount
            currency={this.props.paymentMethodSurcharge.currency}
            value={this.props.paymentMethodSurcharge.amount}
          />
        </div>
      </Card>
    );

    return [...mappedFees, paymentMethodSurcharge];
  };

  render() {
    const totalFees = this.props.totalFeesAndPaymentMethodSurcharges
      ? this.props.totalFeesAndPaymentMethodSurcharges
      : '0.0';

    return (
      <div className="payment__item">
        <div className="payment__fee-services-name col-6">
          {I18n.t('ibe.payment.services_and_charges')}
        </div>
        <div className="payment__fee-services-price col-6">
          <Amount currency={this.context.currency} value={totalFees} />
        </div>
        {this.allAvailableFees().length ? (
          <div className="payment__fee-services-all">{this.allAvailableFees()}</div>
        ) : (
          <div className="payment__fee-services-empty">
            {I18n.t('ibe.payment.no_services_and_charges')}
          </div>
        )}
        {!this.context.laymanMode && (
          <AddNewFee
            feeSuggestions={this.props.feeSuggestions}
            selectedFees={this.props.selected}
            requestingForFees={this.state.requestingForFees}
            selectFee={this.selectFee}
          />
        )}
      </div>
    );
  }
}

FeesAndServices.defaultProps = {
  totalFeesAndPaymentMethodSurcharges: '',
  selected: [],
  paymentMethodSurcharge: null,
};

FeesAndServices.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  fees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      grossPrice: PropTypes.string.isRequired,
    }),
  ).isRequired,
  feeSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      grossPrice: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      currency: PropTypes.string,
      grossPrice: PropTypes.string.isRequired,
    }),
  ),
  fetchFees: PropTypes.func.isRequired,
  fetchFeeSuggestions: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
  totalFeesAndPaymentMethodSurcharges: PropTypes.string,
  intl: intlShape.isRequired,
  paymentMethodSurcharge: PropTypes.shape({
    title: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }),
};

FeesAndServices.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  currency: PropTypes.string,
};

export default injectIntl(FeesAndServices);
