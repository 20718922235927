const getNameFormat = (nameDetails, namePrefixList) => {
  if (
    !nameDetails ||
    Object.keys(nameDetails).length === 0 ||
    !namePrefixList ||
    Object.keys(namePrefixList).length === 0
  ) {
    return null;
  }

  const { namePrefix, firstName, middleName, lastName } = nameDetails;

  const nameParts = [firstName, middleName, lastName]
    .filter(({ value }) => value)
    .reduce((parts, { value }) => {
      parts.push(value);
      return parts;
    }, []);

  const prefix = namePrefixList.find(element => element.value === namePrefix.value);

  if (prefix) {
    nameParts.unshift(prefix.label);
  }

  return nameParts.join(' ');
};

export default getNameFormat;
