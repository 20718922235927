import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import classNames from 'classnames';
import Card from '@wtag/rcl-card';
import Tooltip from '@wtag/rcl-tooltip';
import { CheckBox, I18nText, Link, ContentLoaderPlaceholder } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import dividerContent from '../../dividerContent';

const TravelersDetails = props => {
  const { index, selectedTravelerIndex, setActiveTraveler, details } = props;
  const memoizedUuid = useMemo(() => uuidv4(), []);

  return (
    <li
      key={memoizedUuid}
      className={classNames(
        'col-grid col-bleed direction-row justify-space-between select-travelers__group',
        {
          'select-travelers__group--active': index === selectedTravelerIndex,
        },
      )}
    >
      <Link
        className="col-11 col-bleed"
        onClick={() => setActiveTraveler(index)}
        type="default"
        modifier="default"
      >
        <div className="select-travelers__group-item">
          {details.imageDetails}
          <div>
            {details.name} {details.type}
          </div>
        </div>
      </Link>
      {details.status}
    </li>
  );
};

TravelersDetails.propTypes = {
  setActiveTraveler: PropTypes.func.isRequired,
  selectedTravelerIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  details: PropTypes.string.isRequired,
};

class SelectTravelers extends React.Component {
  showCustomerSearch = () => !(this.context.laymanMode && !this.context.travelerLoggedIn);

  travelerInfo = (traveler, travelerIndex) => {
    const newUserPresent = traveler.firstName.value !== '' || traveler.newUser.value !== '';
    if (traveler.id.value !== '' || newUserPresent) {
      return {
        name: <div className="select-travelers__group-item-name">{traveler.firstName.value}</div>,
        imageDetails: (
          <Avatar
            firstName={traveler.firstName.value}
            lastName={traveler.lastName.value}
            src={
              this.props.selectedCustomers[travelerIndex]
                ? this.props.selectedCustomers[travelerIndex].profilePictureUrl
                : null
            }
            size="tiny"
            className="select-travelers__group--icon"
          />
        ),
        type: <span>({I18n.t(traveler.type.value, { scope: 'passenger.types' })})</span>,
        status: this.props.travelerHasErrors(traveler) ? (
          <div className="select-travelers__group--invalid-status">
            <Tooltip
              content={I18n.t('components.ibe.traveler_details.hints.traveler_error')}
              type="danger"
              position="bottom-left"
            >
              <Icon name="invalid" size="tiny" color="danger" showBGColor={true} />
            </Tooltip>
          </div>
        ) : (
          <div className="select-travelers__group--valid-status">
            <Icon name="checkCircle" size="tiny" color="success" showBGColor={true} />
          </div>
        ),
      };
    }
    return {
      name: I18n.t('components.ibe.traveler_details.traveler', {
        value: travelerIndex + 1,
      }),
      imageDetails: (
        <div className="select-travelers__group--icon">
          <Icon name="person" size="tiny" showBGColor={true} />
        </div>
      ),
      type: <span>({I18n.t(traveler.type.value, { scope: 'passenger.types' })})</span>,
    };
  };

  resetTraveler = index => {
    const allFields = this.props.fields[index];
    Object.keys(allFields).forEach(key => {
      if (
        allFields[key] &&
        key !== 'type' &&
        key !== 'itemChannels' &&
        typeof allFields[key].value !== 'undefined'
      ) {
        allFields[key].onChange('');
      }
    });
    this.props.resetTraveler(index);
  };

  handleContactInformationPass = isChecked => {
    this.props.setResoOptionEmail(isChecked);
    this.props.setResoOptionPhone(isChecked);
  };

  customerSelected = (selectedEntry, index) => {
    this.props.customerSelected(index, selectedEntry);
  };

  clearAll = () => {
    let index = 0;
    this.props.fields.forEach(() => {
      if (
        index === 0 &&
        this.context.laymanMode &&
        this.context.travelerLoggedIn && !this.props.fields[index].guestUser.value
      ) {
        index += 1;
      } else {
        this.resetTraveler(index);
        index += 1;
      }
    });
  };

  render() {
    return (
      <div>
        <Card version="v2" size="full">
          <div className="select-travelers__header">
            <div className="col-grid col-bleed direction-row select-travelers__header-select">
              <div className="select-travelers__header-select-traveler">
                {I18n.t('components.ibe.traveler_details.traveler', {
                  value: this.props.selectedTravelerIndex + 1,
                })}
              </div>
              <div className="select-travelers__header-select-total">
                {I18n.t('components.ibe.traveler_details.out_of_traveler', {
                  count: this.props.fields.length,
                })}
              </div>
            </div>
            <div className="select-travelers__header-hint">
              {I18n.t('components.ibe.traveler_details.to_be_selected_traveler', {
                count: this.props.fields.length,
              })}
            </div>
          </div>

          <ul>
            {this.props.fields.map((traveler, index) => (
              <TravelersDetails
                key={traveler.id}
                details={this.travelerInfo(traveler, index)}
                index={index}
                setActiveTraveler={this.props.setActiveTraveler}
                selectedTravelerIndex={this.props.selectedTravelerIndex}
              />
            ))}

            {this.props.flightPresent && (
              <li>
                <div className="traveler-information__horizontal-line">{dividerContent()}</div>
                <div className="select-travelers__header-hint traveler-information__share-traveler-information-label">
                  {I18n.t('components.ibe.cart.share_traveler_information')}
                </div>
                <span className="col-12 col-bleed traveler-information__container-field-checkbox">
                  {this.props.isTravelerDataLoading ? (
                    <ContentLoaderPlaceholder
                      className="traveler-information__pass-contact-checkbox--loader"
                      numberOfLines={1}
                      showBackground={false}
                    />
                  ) : (
                    <CheckBox
                      className="d-flex align-center column-gap-8 traveler-information__pass-contact-checkbox"
                      label={
                        <I18nText
                          id="ibe.cart.pass_traveler_contact_to_airline"
                          returnStringOnly={true}
                        />
                      }
                      checked={this.props.passEmailToAirline && this.props.passPhoneToAirline}
                      onChange={event => this.handleContactInformationPass(event.target.checked)}
                      size="small"
                    />
                  )}
                </span>
              </li>
            )}
            <li>
              {this.props.fields.length > 1 && !this.props.disableComponent && (
                <div className="select-travelers__action-button">
                  <Button
                    version="v2"
                    onClick={this.clearAll}
                    size="small"
                    label={I18n.t('components.ibe.traveler_details.buttons.reset_all')}
                  />
                </div>
              )}
            </li>
          </ul>
        </Card>
      </div>
    );
  }
}

SelectTravelers.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
};

SelectTravelers.defaultProps = {
  selectedCustomers: [],
  setResoOptionEmail: () => {},
  setResoOptionPhone: () => {},
};

SelectTravelers.propTypes = {
  setActiveTraveler: PropTypes.func.isRequired,
  selectedTravelerIndex: PropTypes.number.isRequired,
  resetTraveler: PropTypes.func.isRequired,
  customerSelected: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedCustomers: PropTypes.arrayOf(PropTypes.shape),
  travelerHasErrors: PropTypes.func.isRequired,
  disableComponent: PropTypes.bool.isRequired,
  setResoOptionEmail: PropTypes.func,
  setResoOptionPhone: PropTypes.func,
  passEmailToAirline: PropTypes.bool.isRequired,
  passPhoneToAirline: PropTypes.bool.isRequired,
  flightPresent: PropTypes.bool.isRequired,
  isTravelerDataLoading: PropTypes.bool.isRequired,
};

export default SelectTravelers;
