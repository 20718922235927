import { UPDATE_TO_BE_AUTHENTICATED_REQUEST } from '../actions';

export default function currentAuthenticationRequest(state = null, action) {
  switch (action.type) {
    case UPDATE_TO_BE_AUTHENTICATED_REQUEST:
      return action.encodedRequest;
    default:
      return state;
  }
}
