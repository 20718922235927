import { UPDATE_BOOKING_REQUESTS_ENCODED_BY_TOKEN } from '../actions';

export default function bookingRequestsEncodedByToken(state = {}, action) {
  switch (action.type) {
    case UPDATE_BOOKING_REQUESTS_ENCODED_BY_TOKEN:
      return action.newBookingReqState;
    default:
      return state;
  }
}
