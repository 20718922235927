import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Tooltip from '@wtag/rcl-tooltip';
import { Link } from 'react-router-dom';
import searchURL from '../../lib/helpers/searchURL';
import './styles.scss';

const SearchedOptions = ({
  formType,
  searchParamsId,
  totalOptionsFound,
  totalOptionsPerSupplier,
  laymanMode,
}) => {
  const isShowTooltip = !laymanMode && totalOptionsPerSupplier.length > 0;

  return (
    <div className="searched-options d-flex">
      <span className="searched-options-link">
        <Link to={searchURL(formType, searchParamsId)}>{totalOptionsFound}</Link>
      </span>
      {isShowTooltip && (
        <span className="d-flex align-center">
          <Tooltip content={totalOptionsPerSupplier} position="bottom-left" type="inverse">
            <Icon name="invalidOutline" showBGColor={false} size="tiny" />
          </Tooltip>
        </span>
      )}
    </div>
  );
};

SearchedOptions.propTypes = {
  formType: PropTypes.string.isRequired,
  searchParamsId: PropTypes.string.isRequired,
  totalOptionsFound: PropTypes.string.isRequired,
  totalOptionsPerSupplier: PropTypes.arrayOf(PropTypes.node).isRequired,
  laymanMode: PropTypes.bool.isRequired,
};

export default SearchedOptions;
