import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import moment from 'moment';
import Amount from 'sharedWebpack/Amount';
import humanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import DateTime from 'sharedWebpack/DateTime';
import SeatCountIconWithLabel from '../SeatCountIconWithLabel';
import FlightSelectButton from '../FlightSelectButton';
import TripDetailsItem from '../../../Search/TripDetailsItem';
import AirlinesPopup from '../AirlinesPopup';
import { stopoverItemShape } from '../shapes';
import AirlineSummary from '../AirlineSummary';
import './style.scss';

const SHOW_AT_MOST_ITEMS_COUNT = 3;
const SHOW_AT_MOST_ITEMS_COUNT_MD = 2;
const NO_DURATION = 0;

const FlightCompactAccordionItemHeader = ({
  visibleCodes,
  totalTravelerCount,
  totalFilteredFareBrands,
  currency,
  lowestFareBrand,
  adultPrice,
  lowestFareBrandTitle,
  isItemSelected,
  disableSelect,
  onDeselect,
  group,
  disableItem,
  selectCallback,
  laymanMode,
}) => {
  const {
    destinationCode,
    channelTitle,
    availableSeatCount,
    originCode,
    departureTime,
    tripDurationInMinutes,
    marketingAirlineLogos,
    arrivalTime,
    stopovers,
  } = group;

  const codes = visibleCodes.slice(0, SHOW_AT_MOST_ITEMS_COUNT);
  const hiddenCodes = visibleCodes.length - SHOW_AT_MOST_ITEMS_COUNT;
  const hasHiddenCodes = hiddenCodes > 0;
  const marketingAirlineCodes = marketingAirlineLogos.join(', ');
  const departureDate = moment.utc(departureTime).format('DD/MM');
  const codesMd = visibleCodes.slice(0, SHOW_AT_MOST_ITEMS_COUNT_MD);
  const hiddenCodesMd = visibleCodes.length - SHOW_AT_MOST_ITEMS_COUNT_MD;
  const hasHiddenCodesMd = hiddenCodesMd > 0;
  const stopOverAirportCodes = stopovers.map(stopover => stopover.airportCode).join(', ');
  const totalStopoverDuration = stopovers.reduce(
    (total, stopover) => total + stopover.transitDurationsInMinutes,
    0,
  );
  const arrivalDate = moment.utc(arrivalTime).format('DD/MM');
  const channelTitleText = channelTitle.join(' | ');
  const stopoverCount = stopovers.length;
  const isTotalStopoverMoreThanADay = totalStopoverDuration > 1440;

  return (
    <Fragment>
      <div className="flight-compact--lg">
        <div className="d-flex justify-between flight-compact__code">
          <div className="d-flex flex-wrap flex-column-gap-4 row-gap-4">
            {!laymanMode && <EmphasisTag size="tiny" text={channelTitleText} />}
            <EmphasisTag
              size="tiny"
              text={
                <div className="d-flex">
                  <AirlineSummary codes={codes} />
                  {hasHiddenCodes && codes.length > 0 && (
                    <AirlinesPopup title={hiddenCodes} showAirlineCode={true} codes={codes} />
                  )}
                </div>
              }
            />
          </div>
          <div className="d-flex flex-wrap flex-column-gap-8 flex-row-gap-4 justify-end">
            <SeatCountIconWithLabel
              className="flight-compact__seat-count"
              seatCount={availableSeatCount}
              travellerCount={totalTravelerCount}
              isShowBgColor={true}
            />
            <EmphasisTag
              size="tiny"
              text={I18n.t('components.ibe.results.options', {
                count: totalFilteredFareBrands,
              })}
            />
          </div>
        </div>
        <div className="grid col-bleed align-center flight-compact__details">
          <div className="col-lg-8 col-bleed">
            <div className="d-flex align-center">
              <span className="flight-compact__airline-code">{marketingAirlineCodes}</span>
              <div className="flight-compact__vertical-line" />
              <div className="d-flex justify-between flex-column-gap-4 flight-compact__trip-details--lg">
                <TripDetailsItem
                  title={originCode}
                  subtitle={
                    <Fragment>
                      <DateTime dateTime={departureTime} format="onlytime" />
                      <span className="flight-compact__date-time">{departureDate}</span>
                    </Fragment>
                  }
                />
                <TripDetailsItem
                  title={destinationCode}
                  subtitle={
                    <span>
                      <DateTime dateTime={arrivalTime} format="onlytime" />
                      <span className="flight-compact__date-time">{arrivalDate}</span>
                    </span>
                  }
                />
                <TripDetailsItem
                  title={I18n.t('components.flight.duration')}
                  subtitle={humanReadableDuration(tripDurationInMinutes)}
                />
                <TripDetailsItem
                  title={I18n.t(
                    `components.flight.stopovers.count.${
                      isTotalStopoverMoreThanADay ? 'only__stopover_count' : 'only__stop_count'
                    }`,
                    {
                      stopoverCount,
                    },
                  )}
                  subtitle={
                    <Fragment>
                      {totalStopoverDuration === NO_DURATION ? (
                        I18n.t('admin.components.settings.search_input.label.direct')
                      ) : (
                        <Fragment>
                          {humanReadableDuration(totalStopoverDuration)}
                          <span className="flight-compact__trip-details__code">
                            {stopOverAirportCodes}
                          </span>
                        </Fragment>
                      )}
                    </Fragment>
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-4 col-bleed">
            <div className="d-flex direction-row justify-end">
              <div className="align-end d-flex direction-column">
                <Amount currency={currency} value={lowestFareBrand || adultPrice} />
                <div className="flight__prices--farebrand-label">
                  {lowestFareBrandTitle}&nbsp;
                  <span className="flight__prices--adult-label">
                    ({I18n.t('admin.components.products.options.automation_style.per_adult.label')})
                  </span>
                </div>
              </div>
              <div className="d-flex-column flight-compact__select-content justify-end">
                <span className="align-self-end">
                  <FlightSelectButton
                    isItemSelected={isItemSelected}
                    disableSelect={disableSelect}
                    onDeselect={onDeselect}
                    group={group}
                    disableItem={disableItem}
                    onCallbackSelect={selectCallback}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flight-compact--md">
        <div className="d-flex justify-between">
          <span className="flight-compact__airline-code">{marketingAirlineCodes}</span>
          <div className="d-flex align-center">
            <AirlineSummary codes={codesMd} />
            {hasHiddenCodesMd && codesMd.length > 0 && (
              <AirlinesPopup title={hiddenCodesMd} showAirlineCode={true} codes={codesMd} />
            )}
          </div>
        </div>
        <div className="d-flex align-center  justify-between flight-compact__trip-details--md">
          <TripDetailsItem
            title={originCode}
            subtitle={
              <span>
                <DateTime dateTime={departureTime} format="onlytime" />
                <span className="flight-compact__date-time">{departureDate}</span>
              </span>
            }
          />
          <TripDetailsItem
            title={I18n.t('components.flight.duration')}
            subtitle={
              <span className="flight-compact__date-time">
                <h4>{humanReadableDuration(tripDurationInMinutes)}</h4>
                <span className="flight-compact__stopover">
                  (
                  {I18n.t('components.ibe.results.filters.stopover_counts.values', {
                    count: stopoverCount,
                  })}
                  )
                </span>
              </span>
            }
          />
          <TripDetailsItem
            title={destinationCode}
            subtitle={
              <span>
                <DateTime dateTime={arrivalTime} format="onlytime" />
                <span className="flight-compact__date-time">{arrivalDate}</span>
              </span>
            }
          />
        </div>
        <div className="d-flex justify-between">
          <div className="d-flex direction-column align-start row-gap-8">
            <div className="align-start d-flex direction-column">
              <Amount
                className="flight-compact__amount"
                currency={currency}
                value={lowestFareBrand || adultPrice}
              />
              <div className="flight__prices--farebrand-label">
                {lowestFareBrandTitle}&nbsp;
                <span className="flight__prices--adult-label">
                  ({I18n.t('admin.components.products.options.automation_style.per_adult.label')})
                </span>
              </div>
            </div>
            <span className="flight-compact__options">
              {I18n.t('components.ibe.results.options', {
                count: totalFilteredFareBrands,
              })}
            </span>
          </div>
          <div className="d-flex direction-column align-end row-gap-8">
            <SeatCountIconWithLabel
              className="flight-compact__seat-count"
              seatCount={availableSeatCount}
              travellerCount={totalTravelerCount}
              isShowBgColor={true}
            />
            <FlightSelectButton
              isItemSelected={isItemSelected}
              disableSelect={disableSelect}
              onDeselect={onDeselect}
              group={group}
              disableItem={disableItem}
              onCallbackSelect={selectCallback}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FlightCompactAccordionItemHeader.defaultProps = { lowestFareBrand: null };

FlightCompactAccordionItemHeader.propTypes = {
  group: PropTypes.shape({
    availableSeatCount: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.number]),
    originCode: PropTypes.string.isRequired,
    destinationCode: PropTypes.string.isRequired,
    stopovers: PropTypes.arrayOf(stopoverItemShape).isRequired,
    arrivalTime: PropTypes.string.isRequired,
    departureTime: PropTypes.string.isRequired,
    tripDurationInMinutes: PropTypes.string.isRequired,
    channelTitle: PropTypes.string,
    marketingAirlineLogos: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  totalTravelerCount: PropTypes.number.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  lowestFareBrandTitle: PropTypes.string.isRequired,
  disableSelect: PropTypes.bool.isRequired,
  onDeselect: PropTypes.func.isRequired,
  disableItem: PropTypes.bool.isRequired,
  selectCallback: PropTypes.func.isRequired,
  totalFilteredFareBrands: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  lowestFareBrand: PropTypes.string,
  adultPrice: PropTypes.string.isRequired,
  visibleCodes: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string })).isRequired,
  laymanMode: PropTypes.bool.isRequired,
};

export default FlightCompactAccordionItemHeader;
