import React from 'react';
import PropTypes from 'prop-types';
import { I18nText, EmphasisTag } from '@wtag/react-comp-lib';

const Refundable = ({ refundable, className }) => {
  let refundableType = 'danger';
  let refundableText = (
    <I18nText id="ibe.cart_item_pricing.non_refundable" returnStringOnly={true} />
  );

  if (refundable) {
    refundableType = 'success';
    refundableText = <I18nText id="ibe.cart_item_pricing.refundable" returnStringOnly={true} />;
  }

  return (
    <EmphasisTag text={refundableText} size="tiny" type={refundableType} className={className} />
  );
};

Refundable.defaultProps = {
  className: null,
};

Refundable.propTypes = {
  refundable: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Refundable;
