import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';

const LoadMore = ({ onLoadMore }) => (
  <div className="col-12 col-bleed">
    <div
      role="presentation"
      className="col-grid direction-row col-bleed align-center justify-center car-result-card__show-more"
      onClick={onLoadMore}
    >
      <I18nText id="ibe.search_results.car.label.load_more" returnStringOnly={true} />
    </div>
  </div>
);

LoadMore.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
};

export default LoadMore;
