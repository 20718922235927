import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Avatar from '@wtag/rcl-avatar';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Card from '@wtag/rcl-card';

const TravelerHeader = props => {
  const {
    switchToGuestBooking,
    showSwitchingButton,
    selectedCustomer,
    travelerIndex,
    guestUser,
    isTravelerInformationLoading,
    clearAll,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalType, setConfirmationModalType] = useState('my_booking');

  const openConfirmationModalForAction = action => {
    setConfirmationModalType(action);
    setModalOpen(true);
  };

  const onConfirmAction = async isGuestBooking => {
    await clearAll();
    setModalOpen(false);
    switchToGuestBooking(isGuestBooking);
  };

  const confirmationModal = type => {
    let selectedConfirmationModal = null;
    switch (type) {
      case 'my_booking':
        selectedConfirmationModal = (
          <ConfirmationModal
            isModalOpen={modalOpen}
            confirmationHeader={I18n.t('components.ibe.traveler_details.confirmations.erase_guest')}
            subHeader={I18n.t('components.ibe.traveler_details.confirmations.subtext_for_erase')}
            confirmationText={I18n.t('components.ibe.traveler_details.buttons.switch')}
            rejectionText={I18n.t('components.action_with_confirmation.no')}
            onConfirm={() => onConfirmAction(false)}
            onReject={() => setModalOpen(false)}
            withAction={true}
            type="danger"
          />
        );
        break;
      case 'guest_booking':
        selectedConfirmationModal = (
          <ConfirmationModal
            isModalOpen={modalOpen}
            confirmationHeader={I18n.t(
              'components.ibe.traveler_details.confirmations.switching_to_guest',
            )}
            subHeader={I18n.t('components.ibe.traveler_details.confirmations.subtext_for_guest')}
            confirmationText={I18n.t('components.ibe.traveler_details.buttons.switch')}
            rejectionText={I18n.t('components.action_with_confirmation.no')}
            onConfirm={() => onConfirmAction(true)}
            onReject={() => setModalOpen(false)}
            withAction={true}
            type="success"
          />
        );
        break;
      default:
    }
    return selectedConfirmationModal;
  };

  const hasFirstName = selectedCustomer && selectedCustomer.firstName;

  return (
    <React.Fragment>
      {confirmationModal(confirmationModalType)}
      <Card version="v2" size="full" className="traveler-header">
        <div className="col-grid col-bleed direction-row justify-space-between align-center wrap">
          <div className="col-grid col-bleed col-12 col-md-auto direction-row align-center">
            {isTravelerInformationLoading ? (
              <ContentLoaderPlaceholder
                numberOfLines={1}
                contentWidth="80px"
                isRounded={true}
                showBackground={false}
                shape="circle"
              />
            ) : (
              <Fragment>
                {hasFirstName ? (
                  <div className="traveler-header-icon">
                    <Avatar
                      firstName={selectedCustomer.firstName}
                      lastName={selectedCustomer.lastName}
                      src={selectedCustomer.profilePictureUrl}
                      size="large"
                    />
                  </div>
                ) : (
                  <div className="traveler-header-icon">
                    <Icon name="person" size="large" showBGColor={true} />
                  </div>
                )}
                <div className="traveler-header-index">
                  {I18n.t('components.ibe.traveler_details.traveler', {
                    value: travelerIndex + 1,
                  })}
                </div>
              </Fragment>
            )}
            {isTravelerInformationLoading ? (
              <Fragment>
                &nbsp; &nbsp;
                <ContentLoaderPlaceholder
                  numberOfLines={1}
                  showBackground={false}
                  isRounded={true}
                  contentWidth="40%"
                />
              </Fragment>
            ) : (
              <Fragment>
                {hasFirstName && (
                  <div className="traveler-header-name">
                    {`${selectedCustomer.firstName} ${selectedCustomer.middleName || ''} ${selectedCustomer.lastName}`}
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {showSwitchingButton && !isTravelerInformationLoading && (
            <div className="traveler-header__action-button col-grid col-12 col-md-auto col-bleed-x direction-row justify-end">
              {guestUser ? (
                <Button
                  version="v2"
                  type="primary"
                  label={I18n.t('components.ibe.traveler_details.buttons.switch_to_my')}
                  size="small"
                  onClick={() => openConfirmationModalForAction('my_booking')}
                />
              ) : (
                <Button
                  version="v2"
                  label={I18n.t('components.ibe.traveler_details.buttons.switch_to_guest')}
                  size="small"
                  onClick={() => openConfirmationModalForAction('guest_booking')}
                />
              )}
            </div>
          )}
        </div>
      </Card>
    </React.Fragment>
  );
};

TravelerHeader.defaultProps = {
  switchToGuestBooking: () => {},
  showSwitchingButton: false,
  guestUser: false,
  selectedCustomer: {
    firstName: '',
    lastName: '',
    middleName: '',
    profilePictureUrl: '',
  },
  clearAll: () => {},
};

TravelerHeader.propTypes = {
  travelerIndex: PropTypes.number.isRequired,
  switchToGuestBooking: PropTypes.func,
  showSwitchingButton: PropTypes.bool,
  selectedCustomer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    profilePictureUrl: PropTypes.string,
  }),
  guestUser: PropTypes.bool,
  isTravelerInformationLoading: PropTypes.bool.isRequired,
  clearAll: PropTypes.func,
};

export default TravelerHeader;
