import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import moment from 'moment';
import Card from '@wtag/rcl-card';
import HumanReadable from '../../../shared/HumanReadableDuration';
import '../styles.scss';

const CurrentOrderRow = ({ order }) => {
  const orderEndsAt = moment(order.endDate);
  const differenceBetweenTime = orderEndsAt.diff(moment(), 'minutes');

  return (
    <div className="col-grid direction-row wrap col-bleed align-center justify-space-between orders-overview__current-trips-header">
      <Icon
        name="plane"
        showBGColor={true}
        showLabel={true}
        iconLabel={
          <span className="orders-overview__current-trips-header-label">
            {I18n.t('public.components.orders.currently_on_travel')}
          </span>
        }
        color="tertiary"
      />
      <span className="orders-overview__current-trips-header-destination">
        {order.origin && order.destination
          ? `${order.origin}-${order.destination}`
          : I18n.t('public.components.orders.no_destination')}
      </span>
      <span className="orders-overview__current-trips-header-action-field-time">
        {HumanReadable(differenceBetweenTime)} {I18n.t('public.components.orders.left')}
      </span>
      <Link
        className="orders-overview__current-trips-header-action-field-view-link"
        modifier="tertiary"
        size="small"
        href={order.urls.view}
        type="button"
      >
        <span className="orders-overview__current-trips-header-action-field-view-link-text">
          {I18n.t('public.components.orders.actions.view_trip')}
        </span>
        <Icon
          className="orders-overview__current-trips-header-action-field-view-link-icon"
          name="show"
        />
      </Link>
    </div>
  );
};

CurrentOrderRow.propTypes = {
  order: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
    urls: PropTypes.shape({
      view: PropTypes.string.isRequired,
    }),
    endDate: PropTypes.string.isRequired,
  }).isRequired,
};

const CurrentOrders = props => {
  const { fetchOrders, scope } = props;
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onFetchOrders = () => {
    fetchOrders(scope).then(data => {
      setOrders(data.orders);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    onFetchOrders();
  }, []);

  return (
    <div className="grid grid-gap-x-40">
      <div className="col-12">
        <div className="orders-overview__current-trips">
          <Card version="v2">
            {isLoading ? (
              <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            ) : (
              orders.map(order => <CurrentOrderRow id={order.id} order={order} />)
            )}

            {!isLoading && !orders.length && (
              <Icon
                name="plane"
                showBGColor={true}
                showLabel={true}
                iconLabel={I18n.t('public.components.orders.no_travels')}
              />
            )}
          </Card>
          <hr className="orders-overview__current-trips-border-bottom" />
        </div>
      </div>
    </div>
  );
};

CurrentOrders.propTypes = {
  fetchOrders: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
};

export default CurrentOrders;
