import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import WellDevBookingComponentWrapper from './WellDevBookingComponentWrapper';
import { saveCreditCardInBackend } from '../../IBE/actions/common';
import {
  validate,
  presenceValidation,
  transliterateValidation,
  emailValidation,
  maxLengthValidation,
} from '../../IBE/lib/validations';
import {
  stateCommonValidation,
  cityCommonValidation,
  phoneNumberCommonValidation,
  streetCommonValidation,
  zipCodeFormatValidation,
} from '../../IBE/lib/validations/commonBookingValidations';
import getPostBookingActionType from '../helpers/getPostBookingActionType';

const addressConstraints = {
  street: {
    ...presenceValidation,
    ...transliterateValidation,
    ...streetCommonValidation,
  },
  city: {
    ...presenceValidation,
    ...transliterateValidation,
    ...cityCommonValidation,
  },
  state: { ...transliterateValidation, ...stateCommonValidation },
  zip: { ...presenceValidation, ...zipCodeFormatValidation },
  country: { ...presenceValidation },
  email: { ...presenceValidation, ...emailValidation },
  phoneNumber: { ...presenceValidation, ...phoneNumberCommonValidation },
};

const validateFields = values => {
  const maxLengthValidationMessage = maxLengthValidation(50);
  const maxLengthValidationMessageForZip = maxLengthValidation(16);

  const addressConstraintsWithLengthValidation = {
    ...addressConstraints,
    street: { ...addressConstraints.street, ...maxLengthValidationMessage },
    city: { ...addressConstraints.city, ...maxLengthValidationMessage },
    zip: { ...addressConstraints.zip, ...maxLengthValidationMessageForZip },
  };

  return {
    cardholderAddress: validate(values.cardholderAddress, addressConstraintsWithLengthValidation),
  };
};

const reduxFormConfig = {
  form: 'card-details',
  fields: [
    'cardholderAddress.street',
    'cardholderAddress.city',
    'cardholderAddress.state',
    'cardholderAddress.zip',
    'cardholderAddress.country',
    'cardholderAddress.email',
    'cardholderAddress.phoneCountryCode',
    'cardholderAddress.phoneNumber',
  ],
  validate: validateFields,
};

const commonAddressStructure = (
  street,
  city,
  zip,
  state,
  country,
  email,
  phoneCountryCode,
  phoneNumber,
) => ({
  city,
  zip,
  street,
  state,
  country,
  email,
  phoneCountryCode,
  phoneNumber,
});

const CREATE_BOOKING = 'create_booking';

const mapStateToProps = (state, ownProps) => {
  const cart = state.common.cart;
  const cartId = cart.id;
  const uniqueFopList = new Set();

  const supportedCards = cart.items
    .filter(item => item.hasJourneyElement && item.availableFormOfPayment)
    .map(item => item.availableFormOfPayment.creditCard.supportedCards)
    .flat();

  supportedCards.forEach(creditCard => {
    uniqueFopList.add(creditCard.cardType);
  });

  const commonCreditCardTypes = cart.availableFormOfPayment.creditCard.supportedCards.map(
    card => card.cardType,
  );
  const currentTab = ownProps.currentTab;
  const availableCreditCardTypes = [...uniqueFopList];
  const itemChannels = cart.items.map(item => item.channel);
  const cartItems = cart.items;
  let ownersBillingAddress = {};

  if (state.form.multiTraveler) {
    const firstTraveler = state.form.multiTraveler.travelers[0];
    const { namePrefix, firstName, lastName } = firstTraveler;
    const fullName = `${namePrefix.value} ${firstName.value} ${lastName.value}`;
    const actionType = CREATE_BOOKING;
    const { residenceAddress, email, splitPrimaryPhoneNumber } = firstTraveler;

    if (residenceAddress) {
      const { city, state: addressState, country, street, zip } = firstTraveler.residenceAddress;

      ownersBillingAddress = {
        street: street && street.value,
        city: city && city.value,
        zip: zip && zip.value,
        state: addressState && addressState.value,
        country: country && country.value,
      };
    }

    ownersBillingAddress = {
      ...ownersBillingAddress,
      email: email && email.value,
      phoneNumber:
        splitPrimaryPhoneNumber &&
        splitPrimaryPhoneNumber.number &&
        splitPrimaryPhoneNumber.number.value,
      phoneCountryCode:
        splitPrimaryPhoneNumber &&
        splitPrimaryPhoneNumber.countryCode &&
        splitPrimaryPhoneNumber.countryCode.value &&
        splitPrimaryPhoneNumber.countryCode.value[1],
    };

    return {
      fullName,
      itemChannels,
      cartId,
      cartItems,
      actionType,
      ownersBillingAddress,
      availableCreditCardTypes,
      commonCreditCardTypes,
      currentTab,
    };
  } else if (ownProps.forPostBooking) {
    // We are sending cartId null as we don't want the card to be saved in backend
    // We don't want to save the card because this block is for post booking and in Agent side
    // We don't want to store credit card information(nonsensitive) on Agent side.
    // So we don't need the cartId.
    const ownerAddress = ownProps.ownerAddress;

    if (ownProps.ownerAddress) {
      ownersBillingAddress = commonAddressStructure(
        ownerAddress.line1 + ownerAddress.line2,
        ownerAddress.city,
        ownerAddress.zipCode,
        ownerAddress.state,
        ownerAddress.countryCode,
        ownerAddress.email,
        ownerAddress.phoneCountryCode,
        ownerAddress.phoneNumber,
      );
    }

    return {
      fullName: ownProps.ownerName,
      itemChannels: ownProps.itemChannels,
      cartId: null,
      orderId: ownProps.orderId,
      orderItems: ownProps.orderItems,
      ownersBillingAddress,
      cartItems: null,
      callbackParams: {
        layman: false,
        order_id: ownProps.orderId,
        person_id: null,
        all_traveler_ids: [],
      },
      actionType: getPostBookingActionType(ownProps.postBookingContent),
      availableCreditCardTypes,
      commonCreditCardTypes,
      currentTab,
    };
  }

  return {
    itemChannels,
    cartId,
    cartItems,
    ownersBillingAddress,
    availableCreditCardTypes,
    commonCreditCardTypes,
    currentTab,
  };
};

const mapDispatchToProps = dispatch => ({
  saveCreditCardInBackend: (cartId, cardDetails, billingAddress, callbackParams, callback) =>
    dispatch(
      saveCreditCardInBackend(cartId, cardDetails, billingAddress, callbackParams, callback),
    ),
});

const ReduxForm = reduxForm(
  reduxFormConfig,
  mapStateToProps,
  mapDispatchToProps,
)(WellDevBookingComponentWrapper);
export default injectIntl(ReduxForm);
