import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { ensureCart, fetchCart } from './cart';

export const SET_GENERAL_REMARKS = 'SET_GENERAL_REMARKS';
export function setGeneralRemarks(remarksType, remarks, resultId, callbackParams) {
  return dispatch =>
    dispatch(ensureCart(callbackParams)).then(cartId => {
      dispatch({
        type: SET_GENERAL_REMARKS,
        remarksType,
        remarks,
        resultId,
        callbackParams,
      });
      const remarksData = { type: remarksType, content: remarks };
      return httpClient
        .post(routes.api.carts.items.setRemark({ cartId, remarksData }), {
          remarks: remarksData,
        })
        .then(() => dispatch(fetchCart(cartId, callbackParams)));
    });
}

export const SET_ACCOUNTING_REMARKS = 'SET_ACCOUNTING_REMARKS';
export function setAccountingRemarks(remarksType, remarks, resultId, callbackParams) {
  return dispatch =>
    dispatch(ensureCart(callbackParams)).then(cartId => {
      dispatch({
        type: SET_ACCOUNTING_REMARKS,
        remarksType,
        remarks,
        resultId,
        callbackParams,
      });
      const accountingRemarksData = { type: remarksType, content: remarks };
      return httpClient
        .post(routes.api.carts.items.setRemark({ cartId, accountingRemarksData }), {
          accounting_remarks: accountingRemarksData,
        })
        .then(() => dispatch(fetchCart(cartId, callbackParams)));
    });
}
