import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';
import './style.scss';

const AirportChangeIndicator = ({ className, changedAirportCodeText }) => (
  <div className={classNames('d-flex airport-change-indicator', className)}>
    <Icon name="airportChange" size="normal" />
    <span className="airport-change-indicator__title">
      {I18n.t('components.flight.stopover.airport_change')}
    </span>
    {I18n.t('components.flight.stopover.at')}
    <span className="airport-change-indicator__stopover">{changedAirportCodeText}</span>
  </div>
);

AirportChangeIndicator.defaultProps = {
  className: null,
};

AirportChangeIndicator.propTypes = {
  className: PropTypes.node,
  changedAirportCodeText: PropTypes.string.isRequired,
};

export default AirportChangeIndicator;
