import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import { Link } from '@wtag/react-comp-lib';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import AccountingForm from '../Form';
import '../../../styles.scss';

const AccountingEdit = ({ accountingId, orderId, accountingTypes, accountingValues, scope }) => {
  const [accountingType, setAccountingType] = useState(null);
  const [accountingTypeValue, setAccountingTypeValue] = useState(null);
  const [allocatedPercentage, setAllocatedPercentage] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  const fetchAccounting = async () =>
    httpClient.get(
      routes[scope].orderAccountings.edit({
        orderId,
        accountingId,
      }),
    );

  const updateAccounting = async () => {
    const accountingParams = {
      accounting_type_id: accountingType,
      accounting_value: accountingTypeValue,
      allocated_percentage: allocatedPercentage,
      accounting_value_source: 1,
    };

    const { data } = await httpClient.put(
      routes[scope].orderAccountings.update({ orderId, accountingId }),
      {
        order_cost_center: accountingParams,
      },
    );

    if (data.error === null) {
      window.location.replace(url);
    }
  };

  const onUpdateAccounting = () =>
    updateAccounting().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  useEffect(() => {
    fetchAccounting().then(response => {
      setAccountingType(response.data.orderCostCenter.accountingType);
      setAccountingTypeValue(response.data.orderCostCenter.accountingTypeValue);
      setAllocatedPercentage(response.data.orderCostCenter.allocatedPercentage);
      setUrl(response.data.orderCostCenter.indexUrl);
    });
  }, []);

  return (
    <div className="col-grid align-center order-accounting__edit-section">
      {accountingType && (
        <div className="col-10">
          <div className="order-accounting__title">
            {I18n.t('admin.components.orders.accounting_tab.edit.update')}
          </div>
          <div className="col-grid col-bleed-x">
            <Card version="v2">
              <AccountingForm
                accountingType={accountingType}
                setAccountingType={setAccountingType}
                accountingTypeValue={accountingTypeValue}
                setAccountingTypeValue={setAccountingTypeValue}
                allocatedPercentage={allocatedPercentage}
                setAllocatedPercentage={setAllocatedPercentage}
                accountingTypes={accountingTypes}
                accountingValues={accountingValues}
                error={error}
              />
            </Card>
          </div>
          <Button
            version="v2"
            size="small"
            type="primary"
            label={I18n.t('admin.components.orders.accounting_tab.edit.update')}
            className="order-accounting-tab__submit"
            onClick={onUpdateAccounting}
          />
          <Link type="button" modifier="default" size="small" href={url}>
            {I18n.t('admin.shared.action.cancel')}
          </Link>
        </div>
      )}
    </div>
  );
};

AccountingEdit.defaultProps = {
  accounting: {},
  scope: 'admin',
};

AccountingEdit.propTypes = {
  orderId: PropTypes.number.isRequired,
  accountingId: PropTypes.number.isRequired,
  accounting: PropTypes.shape({
    id: PropTypes.number,
    accountingType: PropTypes.string,
    accountingTypeValue: PropTypes.string,
    allocatedPercentage: PropTypes.number,
    urls: PropTypes.shape({
      index: PropTypes.string,
    }),
  }),
  accountingTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  accountingValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  scope: PropTypes.string,
};

export default AccountingEdit;
