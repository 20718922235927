export const STEP_GO_TO_MATRIX_SELECTION = 'STEP_GO_TO_MATRIX_SELECTION';
export const STEP_CHOOSE_NEXT_TRIP = 'STEP_CHOOSE_NEXT_TRIP';

export const FLIGHT_GROUP_SELECTED = 'FLIGHT_GROUP_SELECTED';
export function selectFlightGroup(searchId, group) {
  return (dispatch, getState) => {
    dispatch({
      type: FLIGHT_GROUP_SELECTED,
      searchId,
      group,
    });

    const state = getState();
    const expectedTrips = state.flights.searchParamsBySearchId[searchId].trips.length;
    const selectFlightGroupCount = Object.values(state.flights.selectedFlightGroups).length;

    const flightGroupSelectionComplete = selectFlightGroupCount === expectedTrips;

    if (!flightGroupSelectionComplete) {
      return Promise.resolve({ step: STEP_CHOOSE_NEXT_TRIP });
    }

    return Promise.resolve({
      step: STEP_GO_TO_MATRIX_SELECTION,
      selectedFlightGroups: getState().flights.selectedFlightGroups,
    });
  };
}
