import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProductFallbackImage from 'agentSyncedImages/fallback_images/product/product_xxl_static.png';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import './styles.scss';

const PRODUCT_TYPE_INSURANCE = 'insurance';

const StaticProductItem = props => {
  const { productId } = props;

  const [product, setProduct] = useState({});

  const fetchProduct = async () => {
    const { data } = await httpClient.get(routes.api.fetchAStaticProduct({ id: productId }));

    setProduct(data);
  };

  useEffect(() => {
    if (productId) {
      fetchProduct();
    }
  }, [productId]);

  const isInsurance = product.productType === PRODUCT_TYPE_INSURANCE;
  if (isInsurance) {
    return (
      <div className="col-12 col-bleed product-item__details-product-info-description--single">
        {product.description}
      </div>
    );
  }

  return (
    <div className="col-12 product-item">
      <div className="col-12 col-bleed direction-row product-item__details">
        <div className="product-item__details-product-image">
          <img src={product.pictureUrl ? product.pictureUrl : ProductFallbackImage} alt="product" />
        </div>
        <div className="product-item__details-product-info">
          <div className="product-item__details-product-info-stock">
            {product.inStock} {I18n.t('admin.components.orders.items_tab.tag.quantity')}
          </div>
          <div className="product-item__details-product-info-description">
            {product.description}
          </div>
        </div>
      </div>
    </div>
  );
};

StaticProductItem.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default StaticProductItem;
