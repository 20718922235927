import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Tooltip from '@wtag/rcl-tooltip';

const PreferredLabel = ({ className, reason }) => {
  const preferredLabelTag = (
    <EmphasisTag
      text={I18n.t('components.ibe.search_result.labels.preferred')}
      size="tiny"
      className={className}
    />
  );

  const content = reason ? (
    <Tooltip content={reason} position="top-left" type="dark">
      {preferredLabelTag}
    </Tooltip>
  ) : (
    preferredLabelTag
  );

  return content;
};

PreferredLabel.defaultProps = {
  className: null,
  reason: null,
};

PreferredLabel.propTypes = {
  className: PropTypes.string,
  reason: PropTypes.string,
};

export default PreferredLabel;
