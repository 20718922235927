import {
  FLIGHT_SERVICES_FETCHED,
  FLIGHT_SERVICES_FETCHING_FAILED,
} from '../../actions/flight';

export default function resultDetailsById(state, action) {
  const newState = Object.assign({}, state);
  const data = action.data;
  const resultId = action.id;

  switch (action.type) {
    case FLIGHT_SERVICES_FETCHED:
      newState[resultId] = {
        ...newState[resultId],
        available: data.available,
        priceChanged: data.priceChanged,
        instantTicket: data.instantTicket,
      };
      return newState;

    case FLIGHT_SERVICES_FETCHING_FAILED:
      newState[resultId] = {
        available: false,
        trips: [],
        searchId: '',
      };
      return newState;

    default:
      return newState;
  }
}
