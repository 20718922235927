import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import autocompleteFilter from '../../lib/autocompleteFilter';
import fetchDataSource from '../../Search/AirportAutoComplete/helper/FetchDataSource';

const TOP_AIRPORT_RESULT_COUNT = 10;

export const AIRPORTS_SEARCHED = 'AIRPORTS_SEARCHED';
function airportsSearched(airports, id) {
  return {
    type: AIRPORTS_SEARCHED,
    id,
    airports,
  };
}

export const SEARCHING_AIRPORTS = 'SEARCHING_AIRPORTS';
export function searchAirports(query, id, callback, whitelistedAirports) {
  const city = query.split(',')[0];
  const country = query.split(',')[1];
  return dispatch => {
    const url = routes.api.airports({ q: city, top: TOP_AIRPORT_RESULT_COUNT });
    return httpClient.get(url).then(({ data }) => {
      const filteredActiveAirports = data.filter(airport => !airport.deactivated);
      if (country === undefined) {
        if (typeof callback === 'function') {
          const fetchedDataSource = fetchDataSource(filteredActiveAirports, whitelistedAirports);
          callback(fetchedDataSource);
        }
        return dispatch(airportsSearched(filteredActiveAirports, id));
      }
      const filteredAirports = filteredActiveAirports.filter(airport =>
        autocompleteFilter(country.trim(), airport.countryName),
      );
      if (typeof callback === 'function') {
        const fetchedDataSource = fetchDataSource(filteredActiveAirports, whitelistedAirports);
        callback(fetchedDataSource);
      }
      return dispatch(airportsSearched(filteredAirports, id));
    });
  };
}

export const AIRPORT_DETAILS_FETCHED = 'AIRPORT_DETAILS_FETCHED';
function airportDetailsFetched(data) {
  return {
    type: AIRPORT_DETAILS_FETCHED,
    data,
  };
}

function fetchAirportDetailsWithoutHandling(id) {
  return httpClient.get(routes.api.airport({ code: id }));
}

let fetchedAirports = {};
export const FETCHING_AIRPORT_DETAILS = 'FETCHING_AIRPORT_DETAILS';

export const resetFetchedAirports = () => {
  fetchedAirports = {};
};

export function fetchAirportDetails(id) {
  return dispatch => {
    if (fetchedAirports[id]) {
      return null;
    }
    fetchedAirports[id] = true;

    dispatch({
      type: FETCHING_AIRPORT_DETAILS,
      id,
    });

    return fetchAirportDetailsWithoutHandling(id)
      .then(({ data }) => dispatch(airportDetailsFetched(data)))
      .catch(error => {
        if (error.response.status === 404) {
          return null;
        } else if (error.request.timeout === 0 && error.code === 'ECONNABORTED') {
          fetchAirportDetailsWithoutHandling(id).then(({ data }) =>
            dispatch(airportDetailsFetched(data)),
          );
        } else {
          throw error;
        }
        return null;
      });
  };
}
