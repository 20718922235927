import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

import TotalPriceContainer from './SubComponents/TotalPriceContainer';
import CartSummaryAccordionItemHeader from './SubComponents/CartSummaryAccordionItemHeader';
import CartSummaryAccordionItemBody from './SubComponents/CartSummaryAccordionItemBody';
import CartItemWrapper from './CartItemWrapper';
import Voucher from './Items/Voucher';
import './styles.scss';

const findItemType = item => {
  if (item.type === 'flight') {
    return 'flight';
  } else if (item.type === 'hotel') {
    return 'hotel';
  } else if (item.type === 'car') {
    return 'car';
  }
  return 'staticProduct';
};

function Summary(props) {
  const {
    currency,
    total: totalExpense,
    items,
    grossTotalsByGroup,
    isFetchingCart,
    isFetchingFop,
    fees,
    paymentMethodSurcharge,
    voucher,
    totalWithoutVoucherReduction,
  } = props;
  const [openedAccordionProductType, setOpenedAccordionProductType] = useState([
    ...new Set(items.filter(item => item.pendingConfirmation).map(item => findItemType(item))),
  ]);

  const getAdditionalCharges = () => {
    const feesWithType = fees.map(fee => ({ ...fee, type: 'fee' }));
    const charges = [...feesWithType];

    if (paymentMethodSurcharge.amount) {
      charges.push({ ...paymentMethodSurcharge, type: 'payment' });
    }

    return charges;
  };

  const getTotalAdditionalCharges = parseFloat(grossTotalsByGroup.feesAndPaymentMethodSurcharges);

  const typeBasedItems = {
    flight: {
      items: [],
      grossTotal: grossTotalsByGroup.flights,
    },
    hotel: {
      items: [],
      grossTotal: grossTotalsByGroup.hotels,
    },
    car: {
      items: [],
      grossTotal: grossTotalsByGroup.cars,
    },
    staticProduct: {
      items: [],
      grossTotal: grossTotalsByGroup.staticProducts,
    },
    additionalCharges: {
      items: getAdditionalCharges(),
      grossTotal: getTotalAdditionalCharges,
    },
  };

  items.forEach(item => {
    typeBasedItems[findItemType(item)].items.push(item);
  });

  const onAccordionHeaderClickHandler = productTypeOfItemThatWasClicked => {
    if (!openedAccordionProductType.includes(productTypeOfItemThatWasClicked)) {
      setOpenedAccordionProductType([
        ...openedAccordionProductType,
        productTypeOfItemThatWasClicked,
      ]);
    } else {
      setOpenedAccordionProductType(
        openedAccordionProductType.filter(
          productType => productType !== productTypeOfItemThatWasClicked,
        ),
      );
    }
  };

  return (
    <div className="cart-summary">
      <div className="cart-summary__title">
        <I18nText id="ibe.summary.order_summary" returnStringOnly={true} />
      </div>
      <div className="cart-summary__details">
        <div
          className={classNames('cart-summary__loader', {
            'cart-summary__loader--visible': isFetchingCart || isFetchingFop,
          })}
        >
          <ContentLoaderPlaceholder numberOfLines={3} isRounded={false} showBackground={false} />
        </div>
        <TotalPriceContainer currency={currency} totalPrice={totalExpense} />
        {Object.keys(typeBasedItems).map(
          type =>
            typeBasedItems[type].items.length > 0 && (
              <div
                className={classNames('cart-summary__item', {
                  'cart-summary__item--is-expanded': openedAccordionProductType.includes(type),
                })}
              >
                <CartSummaryAccordionItemHeader
                  totalPrice={typeBasedItems[type].grossTotal}
                  type={type !== 'staticProduct' ? type : 'static_product'}
                  currency={currency}
                  isActive={openedAccordionProductType.includes(type)}
                  onClick={() => onAccordionHeaderClickHandler(type)}
                />

                <CartSummaryAccordionItemBody isVisible={openedAccordionProductType.includes(type)}>
                  {typeBasedItems[type].items.length > 0 &&
                    typeBasedItems[type].items.map((item, index) => (
                      <CartItemWrapper
                        key={item.id}
                        itemNo={index + 1}
                        item={{ ...item }}
                        staticProducts={props.staticProducts}
                        travelerCount={props.travelerCount}
                      />
                    ))}
                </CartSummaryAccordionItemBody>
              </div>
            ),
        )}
        {voucher && voucher.number && (
          <div className="cart-summary__item">
            <div className="cart-summary__accordion-item-body cart-summary__item--is-expanded cart-summary__accordion-item-body--visible cart-summary__voucher">
              <Voucher
                key={voucher.number}
                item={{ ...voucher, type: 'voucher' }}
                totalWithoutVoucherReduction={totalWithoutVoucherReduction}
              />
            </div>
          </div>
        )}
        {openedAccordionProductType.length > 0 && (
          <TotalPriceContainer currency={currency} totalPrice={totalExpense} />
        )}
      </div>
    </div>
  );
}

Summary.defaultProps = {
  items: [],
  paymentMethodSurcharge: null,
  fees: [],
  voucher: null,
  totalWithoutVoucherReduction: null,
};

Summary.propTypes = {
  travelerCount: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  total: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  staticProducts: PropTypes.arrayOf(PropTypes.shape).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  fees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  paymentMethodSurcharge: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.string,
    currency: PropTypes.string,
  }),
  voucher: PropTypes.shape({
    amount: PropTypes.string,
    contentInvalid: PropTypes.bool,
    contentMessage: PropTypes.string,
    currency: PropTypes.string,
    number: PropTypes.string,
    numberFormField: PropTypes.string,
    percentage: PropTypes.string,
    reductionAmount: PropTypes.string,
    reductionCurrency: PropTypes.string,
    reductionType: PropTypes.string,
    used: PropTypes.bool,
    valid: PropTypes.bool,
  }),
  grossTotalsByGroup: PropTypes.string.isRequired,
  isFetchingCart: PropTypes.string.isRequired,
  isFetchingFop: PropTypes.string.isRequired,
  totalWithoutVoucherReduction: PropTypes.string,
};

export default Summary;
