import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { searchCustomerWithoutHandling } from './';

export const UPDATE_CONFIRMATION_MAIL_RECIPIENTS = 'UPDATE_CONFIRMATION_MAIL_RECIPIENTS';
export function updateConfirmationMailRecipients(recipients) {
  return { type: UPDATE_CONFIRMATION_MAIL_RECIPIENTS, recipients };
}

export const CONFIRMATION_MAIL_RECIPIENT_SEARCH_COMPLETE =
  'CONFIRMATION_MAIL_RECIPIENT_SEARCH_COMPLETE';
function confirmationMailRecipientSearchComplete(response) {
  const customersWithMail = response.data.filter(customer => !!customer.email);
  return {
    type: CONFIRMATION_MAIL_RECIPIENT_SEARCH_COMPLETE,
    customersWithMail,
  };
}

export function searchConfirmationMailRecipient(query) {
  return dispatch => {
    if (query.length > 0) {
      return searchCustomerWithoutHandling(query).then(response =>
        dispatch(confirmationMailRecipientSearchComplete(response)),
      );
    }
    return null;
  };
}

export const CONFIRMATION_MAIL_DATA_SET = 'CONFIRMATION_MAIL_DATA_SET';
export function storeCartRecipientData() {
  return (dispatch, getState) => {
    const cartRecipientsData = getState().common.confirmationMail.recipients;
    const cartId = getState().common.cart.id;
    return httpClient
      .post(routes.api.carts.emailRecipients.add({ cartId }), {
        recipients: cartRecipientsData,
      })
      .then(() => dispatch({ type: CONFIRMATION_MAIL_DATA_SET }));
  };
}
