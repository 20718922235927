import React from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import CurrentOrders from './CurrentOrders';
import Orders from './Orders';
import '../styles.scss';

const Overview = props => {
  const { ordersPath, upcomingOrdersPath, previousOrdersPath, personId } = props;

  const fetchOrders = async (scope, perPage, sortedColumn, direction) => {
    const { data } = await httpClient.get(ordersPath, {
      params: {
        scope,
        direction,
        per_page: perPage,
        column: sortedColumn,
        person_id: personId,
      },
    });
    return data;
  };

  return (
    <div className="orders-overview">
      <div className="grid grid-gap-x-40">
        <div className="col-12">
          <div className="orders-overview__header">
            <span className="orders-overview__header-label">
              {I18n.t('public.components.orders.my_trips')}
            </span>
            <hr className="orders-overview__header-botom-border" />
          </div>
        </div>
      </div>
      <CurrentOrders fetchOrders={fetchOrders} scope="present_orders" />
      <div className="grid grid-gap-x-40">
        <div className="col-12">
          <div className="orders-overview__upcoming-trips">
            <Orders
              iconColor="tertiary"
              iconLabel={I18n.t('public.components.orders.collection.upcoming_trips')}
              linkText={I18n.t('public.components.orders.actions.view_all_upcoming')}
              fetchOrders={fetchOrders}
              relativePath={upcomingOrdersPath}
              scope="upcoming_orders"
              sortedColumn="travel_starts_at"
              direction="up"
              perPage={2}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-gap-x-40">
        <div className="col-12">
          <div className="orders-overview__previous-trips">
            <Orders
              iconLabel={I18n.t('public.components.orders.collection.previous_trips')}
              linkText={I18n.t('public.components.orders.actions.view_all_previous')}
              fetchOrders={fetchOrders}
              relativePath={previousOrdersPath}
              scope="past_orders"
              sortedColumn="travel_starts_at"
              direction="down"
              perPage={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Overview.propTypes = {
  ordersPath: PropTypes.string.isRequired,
  upcomingOrdersPath: PropTypes.string.isRequired,
  previousOrdersPath: PropTypes.string.isRequired,
  personId: PropTypes.number.isRequired,
};

export default Overview;
