import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import PriceLabel from './PriceLabel';
import { ValuesShape } from './Shapes/index';

const FlightPriceChangesContent = ({ values, showOldPrice, style }) => {
  const { netPrice, currency } = values;
  const selectedValues = showOldPrice ? values : values[style];
  const { margin, grossPrice } = selectedValues;

  const priceLabelData = [
    { label: I18n.t('activerecord.attributes.order_item.net_price'), value: netPrice },
    { label: I18n.t('activerecord.attributes.order_item.margin_price'), value: margin },
    { label: I18n.t('activerecord.attributes.order_item.gross_price'), value: grossPrice },
  ];

  return priceLabelData.map(({ label, value }) => (
    <PriceLabel label={label} currency={currency} value={value} />
  ));
};

FlightPriceChangesContent.propTypes = {
  showOldPrice: PropTypes.bool.isRequired,
  values: ValuesShape.isRequired,
  style: PropTypes.string.isRequired,
};

export default hot(module)(FlightPriceChangesContent);
