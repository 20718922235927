import { CART_FETCHED } from '../../actions/common';

export default function cart(state = [], action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.items.sort((item1, item2) => {
        if (item1.type === 'static') {
          return 1;
        } else if (item2.type === 'static') {
          return -1;
        }
        return 0;
      });

    default:
      return state;
  }
}
