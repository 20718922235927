import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateFilter } from '@wtag/rcl-filter';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';

const DateFilterWrapper = props => {
  const {
    isVisible,
    selectedDateLabel,
    startDate,
    endDate,
    enablePastDates,
    enableDateRangeSelection,
    disableFutureDates,
    onChange,
  } = props;

  const [isFilterVisible, setIsFilterVisible] = useState(isVisible);

  const DEFAULT_FORMAT = 'DD/MM/YY';
  const formatLabelDate = (date, dateFormat = DEFAULT_FORMAT) => moment(date).format(dateFormat);

  const generateLabel = () => {
    if (startDate && endDate) {
      return `${formatLabelDate(startDate)}-${formatLabelDate(endDate)}`;
    } else if (startDate) {
      return formatLabelDate(startDate);
    }

    return selectedDateLabel;
  };

  return (
    <div className="orders-list__filters">
      <DateFilter
        isVisible={isFilterVisible}
        selectedDateLabel={selectedDateLabel}
        startDate={startDate && moment(startDate)}
        endDate={endDate && moment(endDate)}
        enablePastDates={enablePastDates}
        enableDateRangeSelection={enableDateRangeSelection}
        disableFutureDates={disableFutureDates}
        locale={I18n.locale}
        onChange={onChange}
        onOutsideClick={() => {
          setIsFilterVisible(false);
        }}
      >
        <Button
          label={
            <div className="orders-list__filters-label">
              <Icon className="orders-list__filters-label-icon" name="calendar" size="small" />
              <span className="orders-list__filters-label-text">{generateLabel()}</span>
            </div>
          }
          size="small"
          onClick={() => {
            setIsFilterVisible(previousIsVisibleState => !previousIsVisibleState);
          }}
        />
      </DateFilter>
    </div>
  );
};

DateFilterWrapper.defaultProps = {
  isVisible: false,
  selectedDateLabel: null,
  startDate: null,
  endDate: null,
  enablePastDates: true,
  enableDateRangeSelection: false,
  disableFutureDates: true,
  onChange: () => {},
};

DateFilterWrapper.propTypes = {
  isVisible: PropTypes.bool,
  selectedDateLabel: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  enablePastDates: PropTypes.bool,
  enableDateRangeSelection: PropTypes.bool,
  disableFutureDates: PropTypes.bool,
  onChange: PropTypes.func,
};

export default DateFilterWrapper;
