import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';

import './styles.scss';

const FlagWithToolTip = props => {
  const { flagUrl, showToolTip, nationality, icon, coloredText, hint } = props;

  const toolTipContent = (
    <div className="flag-with-tool-tip__content">
      <div className="col-grid col-bleed direction-row align-center">
        <img className="flag-with-tool-tip__image" src={flagUrl} alt={nationality} />
        <div className="flag-with-tool-tip__name">{nationality}</div>
      </div>
      {coloredText}
      {hint}
    </div>
  );

  return (
    <div className="flag-with-tool-tip">
      <img src={flagUrl} alt={nationality} />
      {showToolTip && (
        <div className="flag-with-tool-tip__tool-tip">
          <Tooltip content={toolTipContent} position="top" type="inverse" arrowWidth={0} gap={5}>
            {icon}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

FlagWithToolTip.defaultProps = {
  coloredText: null,
  hint: null,
  icon: null,
};

FlagWithToolTip.propTypes = {
  flagUrl: PropTypes.string.isRequired,
  coloredText: PropTypes.node,
  hint: PropTypes.node,
  showToolTip: PropTypes.bool.isRequired,
  nationality: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

export default FlagWithToolTip;
