import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Menu } from '@wtag/rcl-menu';
import Icon from '@wtag/rcl-icon';
import { TYPE_FLIGHT, TYPE_HOTEL } from '../../helpers/itemTypes';
import SearchName from './SearchName';

const LastSearchMenuLabel = ({ onLastSearchClick, isVisible }) => (
  <div
    className={classNames('search-menu__menu-label', {
      'search-menu__menu-label--active': isVisible,
    })}
    onClick={onLastSearchClick}
    role="button"
    tabIndex={0}
    onKeyPress={onLastSearchClick}
  >
    {I18n.t('components.ibe.search_form.previous_searches')}
    <div className="search-menu__menu-label--icon">
      <Icon name="iconDownChevron" size="tiny" />
    </div>
  </div>
);

class LastSearches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  componentDidUpdate() {
    if (this.props.params && this.props.params[`${this.props.type}s`]) {
      if (this.props.type === 'flight') {
        this.props.params.flights.forEach(flight =>
          this.props.fetchAirportDetails(flight.trips[0].origin),
        );
      }
      if (this.props.type === 'car') {
        this.props.params.cars.forEach(car => {
          if (car.airportSearch) {
            this.props.fetchAirportDetails(car.pickUpLocationAirportCode);
          }
        });
      }
      if (this.props.type === 'hotel') {
        this.props.params.hotels.forEach(hotel => {
          if (hotel.placeId) {
            this.props.fetchPlaceDetails(hotel.placeId);
          }
        });
      }
    }
  }

  setDefaultSearchParams = params => {
    const { carRentalCompanies, airlines } = this.context.globalPolicyPreferences;
    const modifiedParams = this.removeDeletedCorporateCodes(params);
    if (this.props.type === 'hotel') {
      const childrenData = [];
      for (let step = 0; step < params.children; step += 1) {
        childrenData.push({});
      }
      modifiedParams.childrenData = childrenData;
      this.props.setDefaultSearchParams(this.props.type, modifiedParams);
    } else if (this.props.type === 'car') {
      if (carRentalCompanies.length > 0) {
        modifiedParams.vendorPreferences = { codes: carRentalCompanies, type: 'exclusive' };
      }
      if (!params.pickUpDate || !params.dropOffDate) {
        modifiedParams.pickUpDate = params.pickUpTime;
        modifiedParams.dropOffDate = params.dropOffTime;
      }
      this.props.setDefaultSearchParams(this.props.type, modifiedParams);
    } else {
      if (airlines.length > 0) {
        modifiedParams.airlinePreferences = { carriers: airlines, type: 'exclusive' };
      }
      this.props.setDefaultSearchParams(this.props.type, modifiedParams);
    }
  };

  getAvailableCorporateCodes = (channel, credential) => {
    const availableCorporateCodes = [];
    const searchAccounts = this.props.searchAccounts;
    searchAccounts[`${this.props.type}s`][channel].credentials.forEach(entry => {
      const { identifier, codes } = entry;
      codes.forEach(corporateCode => {
        if (credential.identifier === identifier && credential.codes.includes(corporateCode.code)) {
          availableCorporateCodes.push(corporateCode.code);
        }
      });
    });

    return availableCorporateCodes;
  };

  removeDeletedCorporateCodes = params => {
    const filteredParams = params;
    if (this.props.type === 'flight') {
      if (filteredParams.accountSettings) {
        Object.entries(filteredParams.accountSettings).forEach(entry => {
          const [channel, credentials] = entry;
          const channelAvailable =
            this.props.searchAccounts[`${this.props.type}s`] &&
            this.props.searchAccounts[`${this.props.type}s`][channel];
          const channelNotAvailable =
            this.props.searchAccounts[`${this.props.type}s`] &&
            !this.props.searchAccounts[`${this.props.type}s`][channel];

          if (channelNotAvailable) {
            delete filteredParams.accountSettings[channel];
          } else if (channelAvailable && credentials.length > 0) {
            credentials.forEach(credential => {
              const filteredCredential = credential;
              filteredCredential.codes = this.getAvailableCorporateCodes(channel, credential);
            });
          }
        });
      }
    }

    return filteredParams;
  };

  render() {
    const { params, type, setIsFromLastSearch, setIsMultiCitySelected } = this.props;

    const onOutsideClick = () => {
      this.setState({ isVisible: false });
    };

    const onLastSearchClick = () => this.setState({ isVisible: !this.state.isVisible });

    const allTravelerIds =
      (this.context.callbackParams && this.context.callbackParams.all_traveler_ids) || [];
    if (params && params[`${type}s`] && params[`${type}s`].length > 0) {
      const display = params[`${type}s`].map(param => {
        const modifiedParam = param;
        if (allTravelerIds.length > 0) {
          if (type === TYPE_FLIGHT) {
            modifiedParam.adults = allTravelerIds.length;
          } else if (type === TYPE_HOTEL) {
            modifiedParam.adults = allTravelerIds.length;
            modifiedParam.numberOfRooms = allTravelerIds.length;
          }
        }
        return (
          <SearchName
            type={type}
            {...modifiedParam}
            onMenuItemClick={() => {
              setIsFromLastSearch(true);
              setIsMultiCitySelected(false);
              this.setDefaultSearchParams(modifiedParam);
              this.setState({ isVisible: false });
            }}
          />
        );
      });

      return (
        <Fragment>
          <div className="search-menu__container search-menu__container--previous-search">
            <Menu
              className="search-menu__menu"
              isVisible={this.state.isVisible}
              size="medium"
              popOverDirection="bottom-right"
              onOutsideClick={onOutsideClick}
              label={
                <LastSearchMenuLabel
                  onLastSearchClick={onLastSearchClick}
                  isVisible={this.state.isVisible}
                />
              }
            >
              {display}
            </Menu>
          </div>
        </Fragment>
      );
    }
    return null;
  }
}

LastSearches.defaultProps = {
  setIsMultiCitySelected: () => {},
  setIsFromLastSearch: () => {},
};

LastSearches.propTypes = {
  params: PropTypes.shape({
    flights: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ),
    cars: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ),
    hotels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  searchAccounts: PropTypes.shape().isRequired,
  setDefaultSearchParams: PropTypes.func.isRequired,
  fetchAirportDetails: PropTypes.func.isRequired,
  fetchPlaceDetails: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setIsMultiCitySelected: PropTypes.func,
  setIsFromLastSearch: PropTypes.func,
};

LastSearches.contextTypes = {
  callbackParams: PropTypes.shape().isRequired,
  globalPolicyPreferences: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

LastSearchMenuLabel.propTypes = {
  onLastSearchClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default LastSearches;
