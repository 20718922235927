export const mapCustomerData = (searchResult = {}) => {
  if (!searchResult.customers) {
    return [];
  }
  return searchResult.customers.map(customer => ({
    text: `${customer.firstName} ${customer.lastName}`,
    value: `${customer.firstName} ${customer.lastName}`,
    data: customer,
  }));
};

export const mapConfirmationMailRecipient = recipients => {
  if (!recipients) {
    return [];
  }
  return recipients.map(recipient => ({
    name: `${recipient.firstName} ${recipient.lastName}`,
    email: recipient.email,
  }));
};

const mapAddress = (address, traveler) => {
  if (!address || !address.street) {
    return null;
  }
  return {
    name: `${traveler.firstName} ${traveler.lastName}`,
    ...address,
  };
};

const hasContent = object =>
  Object.values(object).filter(value => value !== null && value !== undefined).length !== 0;

export const mapTravelerData = traveler => {
  const {
    namePrefix: name_prefix, // eslint-disable-line camelcase
    firstName: first_name, // eslint-disable-line camelcase
    middleName,
    lastName: last_name, // eslint-disable-line camelcase
    phone: phoneNumber,
    profilePicUrl: profile_picture_url, // eslint-disable-line camelcase
    residenceAddress,
    destinationAddress,
    assistanceRequests,
    frequentFlyerNumber,
    mealRequests,
    document: doc,
    email,
    adjustProfileData: adjust_profile_data, // eslint-disable-line camelcase
    associateOrganization: associate_organization, // eslint-disable-line camelcase
    isTravelArrangerEmailUsed,
    travelArrangerEmail,
    ...details
  } = traveler;

  const mappedResidenceAddress = mapAddress(residenceAddress, traveler);
  const mappedDestinationAddress = mapAddress(destinationAddress, traveler);

  const mappedMealRequests = [];
  if (mealRequests) {
    mealRequests.forEach(request => {
      if (request && request.code && request.code.length >= 1) {
        mappedMealRequests.push({
          carrier: request.carrier,
          code: request.code,
          flight_number: request.flightNumber,
          result_id: request.resultId,
        });
      }
    });
  }

  const mappedTraveler = {
    name_prefix,
    first_name,
    last_name,
    adjust_profile_data,
    ...details,
  };
  if (mappedMealRequests.length) {
    mappedTraveler.meal_requests = mappedMealRequests;
  }
  if (mappedResidenceAddress && hasContent(mappedResidenceAddress)) {
    mappedTraveler.residence_address = mappedResidenceAddress;
  }
  if (mappedDestinationAddress && hasContent(mappedDestinationAddress)) {
    mappedTraveler.destination_address = mappedDestinationAddress;
  }
  if (doc && hasContent(doc) && doc.number) {
    mappedTraveler.document = doc;
  }
  if (assistanceRequests) {
    mappedTraveler.assistance_requests = assistanceRequests;
  }
  if (frequentFlyerNumber && hasContent(frequentFlyerNumber)) {
    mappedTraveler.frequent_flyer_number = frequentFlyerNumber;
  }
  if (middleName && middleName.length >= 1) {
    mappedTraveler.middle_name = middleName;
  }
  if (phoneNumber && phoneNumber.length >= 1) {
    mappedTraveler.phone = phoneNumber;
  }
  if (email && email.length > 1) {
    mappedTraveler.email = email;
  }
  if (isTravelArrangerEmailUsed && travelArrangerEmail && travelArrangerEmail.length > 1) {
    mappedTraveler.travel_arranger_email = travelArrangerEmail;
    mappedTraveler.travel_arranger_email_used = isTravelArrangerEmailUsed;
  }

  return mappedTraveler;
};

export const customerInitialValueMapper = (state, ownProps) => {
  const index = ownProps.index;
  let initialValues;
  let frequentFlyerNumbers = [];
  const customers = state.common.cart.travelers;
  const selectedCustomer = state.common.selectedCustomers[index];
  if (selectedCustomer) {
    initialValues = {
      ...selectedCustomer,
      residenceAddress: selectedCustomer.residenceAddress,
    };
    frequentFlyerNumbers = selectedCustomer.frequentFlyerNumbers;
  }
  const savedTravelerData = state.common.cart.travelers[index];
  if (savedTravelerData) {
    initialValues = savedTravelerData;
  }

  return {
    initialValues,
    customers,
    frequentFlyerNumbers,
    selectedCustomer,
  };
};

const deepCloneFromForm = formData => {
  const data = {};
  Object.entries(formData).forEach(dataArray => {
    if (dataArray[1] && typeof dataArray[1].value !== 'undefined') {
      data[dataArray[0]] = dataArray[1].value;
    }
  });
  if (formData.splitPrimaryPhoneNumber) {
    data.splitPrimaryPhoneNumber = {};
    Object.entries(formData.splitPrimaryPhoneNumber).forEach(dataArray => {
      if (dataArray[1] && typeof dataArray[1].value !== 'undefined') {
        data.splitPrimaryPhoneNumber[dataArray[0]] = dataArray[1].value;
      }
    });
  }
  if (formData.residenceAddress) {
    data.residenceAddress = {};
    Object.entries(formData.residenceAddress).forEach(dataArray => {
      if (dataArray[1] && typeof dataArray[1].value !== 'undefined') {
        data.residenceAddress[dataArray[0]] = dataArray[1].value;
      }
    });
  }
  if (formData.document) {
    data.document = {};
    Object.entries(formData.document).forEach(dataArray => {
      if (dataArray[1] && typeof dataArray[1].value !== 'undefined') {
        data.document[dataArray[0]] = dataArray[1].value;
      }
    });
  }
  if (formData.frequentFlyerNumber) {
    data.frequentFlyerNumber = {};
    Object.entries(formData.frequentFlyerNumber).forEach(dataArray => {
      if (dataArray[1] && typeof dataArray[1].value !== 'undefined') {
        data.frequentFlyerNumber[dataArray[0]] = dataArray[1].value;
      }
    });
  }
  return data;
};

const formInitialValue = (selectedCustomer, formCustomer, type, childAge, driverAge) => {
  let travelerData;
  if (
    formCustomer &&
    formCustomer.id &&
    formCustomer.id.value !== '' &&
    formCustomer.newUser.value !== ''
  ) {
    travelerData = deepCloneFromForm(formCustomer);
  }
  let initialValues;
  if (selectedCustomer) {
    initialValues = {
      ...selectedCustomer,
      residenceAddress: selectedCustomer.address,
      adjustProfileData: selectedCustomer.adjustProfileData || false,
      associateOrganization: selectedCustomer.associateOrganization || false,
      isTravelArrangerEmailUsed: selectedCustomer.isTravelArrangerEmailUsed || false,
    };
  }
  return { type, driverAge, childAge, ...initialValues, ...travelerData };
};

export const getOperatingCarriers = common => {
  const elementIds = [];
  const operatingCarriers = [];
  if (typeof common.cart !== 'undefined' && typeof common.cart.items !== 'undefined') {
    common.cart.items.forEach(item => {
      if (item.journeyElementType === 'flight') {
        elementIds.push(item.journeyElementId);
      }
    });
  }
  if (
    typeof common.journeyElements !== 'undefined' &&
    typeof common.journeyElements.flight !== 'undefined'
  ) {
    elementIds.forEach(elementId => {
      if (typeof common.journeyElements.flight[elementId] !== 'undefined') {
        return common.journeyElements.flight[elementId].trips.forEach(trip =>
          trip.segments.forEach(segment => operatingCarriers.push(segment.operatingCarrierCode)),
        );
      }
      return null;
    });
    return operatingCarriers;
  }
  return null;
};

export const travelerInitialValueMapper = (state, ownProps) => {
  const { adults, children, infants } = state.common.travelerCount;
  const intialTravelerValues = [];
  const currentTravelerValues = [];
  const customers = state.common.selectedCustomers;
  let formData;
  if (state.form && state.form.multiTraveler) {
    formData = state.form.multiTraveler.travelers;
  }
  let i = 0;
  const adultCounter = adults.length;
  for (let loopIndex = 0; loopIndex < adultCounter; loopIndex += 1) {
    let travelerFormData;
    let driverAge;
    const adult = state.common.travelerCount.adults[0];
    if (typeof adult === 'object' && loopIndex === 0) {
      driverAge = adult.age;
    } else {
      driverAge = undefined;
    }
    if (formData) travelerFormData = formData[i];
    intialTravelerValues[i] = formInitialValue(
      customers[i],
      undefined,
      'adult',
      undefined,
      driverAge,
    );
    currentTravelerValues[i] = formInitialValue(
      undefined,
      travelerFormData,
      'adult',
      undefined,
      driverAge,
    );
    i += 1;
  }
  const childrenCounter = children.length;
  for (let loopIndex = 0; loopIndex < childrenCounter; loopIndex += 1) {
    let travelerFormData;
    let childAge;
    const child = state.common.travelerCount.children[loopIndex];
    if (typeof child === 'object') {
      childAge = child.age;
    } else {
      childAge = undefined;
    }
    if (formData) travelerFormData = formData[i];
    intialTravelerValues[i] = formInitialValue(customers[i], undefined, 'child', childAge);
    currentTravelerValues[i] = formInitialValue(undefined, travelerFormData, 'child', childAge);
    i += 1;
  }
  const infantCounter = infants.length;
  for (let loopIndex = 0; loopIndex < infantCounter; loopIndex += 1) {
    let travelerFormData;
    if (formData) travelerFormData = formData[i];
    intialTravelerValues[i] = formInitialValue(customers[i], undefined, 'infant');
    currentTravelerValues[i] = formInitialValue(undefined, travelerFormData, 'infant');
    i += 1;
  }

  if (intialTravelerValues.length > 0) {
    if (
      ownProps.laymanMode &&
      ownProps.travelerLoggedIn &&
      intialTravelerValues[0].id !== state.common.currentCustomer.id &&
      typeof intialTravelerValues[0].newUser === 'undefined'
    ) {
      intialTravelerValues[0].guestUser = true;
    } else {
      intialTravelerValues[0].guestUser = false;
    }
  }

  const initialValues = { travelers: intialTravelerValues };
  const currentValues = { travelers: currentTravelerValues };

  initialValues.cartHasFlight =
    typeof ownProps.flightResults !== 'undefined' && ownProps.flightResults.length > 0;
  currentValues.cartHasFlight =
    typeof ownProps.flightResults !== 'undefined' && ownProps.flightResults.length > 0;

  return { initialValues, currentValues };
};
