import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';
import calculateSurcharge from '../helpers/calculateSurcharge';
import isSurchargeUnknown from '../helpers/isSurchargeUnknown';
import isAdditionalChargeApplicable from '../helpers/isAdditionalChargeApplicable';
import './styles.scss';

const SurchargeAmount = props => {
  const { charges, currency } = props;
  const surchargeUnknown = isSurchargeUnknown(charges);
  const additionalChargeApplicable = isAdditionalChargeApplicable(charges);
  const surcharge = calculateSurcharge(charges);

  return (
    <div className="credit-card--surcharge">
      {surchargeUnknown ? (
        I18n.t('components.ibe.payment.charge_may_apply')
      ) : (
        <Fragment>
          {I18n.t('components.ibe.payment.charge')}
          <Amount currency={currency} value={surcharge} />
          {additionalChargeApplicable && I18n.t('components.ibe.payment.and_more')}
        </Fragment>
      )}
    </div>
  );
};

SurchargeAmount.propTypes = {
  currency: PropTypes.string.isRequired,
  charges: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.any])).isRequired,
};

export default SurchargeAmount;
