import { CUSTOMER_SELECTED, RESET_TRAVELER_DETAILS } from '../../actions/common/customer';

export default function selectedCustomers(state = [], action) {
  const newState = Object.assign([], state);

  switch (action.type) {
    case CUSTOMER_SELECTED:
      newState[action.index] = action.customer;
      return newState;

    case RESET_TRAVELER_DETAILS:
      newState[action.index] = null;
      return newState;

    default:
      return state;
  }
}
