import { emailValidation, emailWithoutSpecialCharacters, validate } from '../validations';

const validateEmailForHotel = (params, itemChannels) => {
  let customEmailValidation;
  const channels = itemChannels.map(item => item.identifier);
  if (channels.includes('travelport_rooms_and_more')) {
    customEmailValidation = emailWithoutSpecialCharacters;
  }
  const extendedConstraints = {
    email: {
      ...emailValidation,
      ...customEmailValidation,
    },
    travelArrangerEmail: {
      ...emailValidation,
      ...customEmailValidation,
    },
  };

  return validate(params, extendedConstraints);
};

export default validateEmailForHotel;
