import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Tooltip from '@wtag/rcl-tooltip';

import './styles.scss';

const TaskPriorityCard = ({ task, objectToRenderToolTip }) => (
  <div className="tasks">
    <Tooltip
      content={
        <React.Fragment>
          <div className="tasks-card">
            <div className="tasks-card-title">
              {I18n.t('admin.components.orders.table.tasks.overview')}
            </div>
            <div className="tasks-card-priority">
              <div className="tasks-card-priority-row">
                <EmphasisTag
                  type="danger"
                  text={I18n.t('admin.components.orders.table.tasks.high')}
                  size="small"
                  radius="oval"
                />
                {task && task.high}
              </div>
              <div className="tasks-card-priority-row">
                <EmphasisTag
                  type="warning"
                  text={I18n.t('admin.components.orders.table.tasks.medium')}
                  size="small"
                  radius="oval"
                />
                {task && task.medium}
              </div>
              <div className="tasks-card-priority-row">
                <EmphasisTag
                  type="disabled"
                  text={I18n.t('admin.components.orders.table.tasks.low')}
                  size="small"
                  radius="oval"
                />
                {task && task.low}
              </div>
            </div>
          </div>
        </React.Fragment>
      }
      type="inverse"
      position="top"
      showArrow={false}
    >
      {objectToRenderToolTip}
    </Tooltip>
  </div>
);

TaskPriorityCard.propTypes = {
  task: PropTypes.shape({
    totalCount: PropTypes.number,
    high: PropTypes.number,
    medium: PropTypes.number,
    low: PropTypes.number,
  }).isRequired,
  objectToRenderToolTip: PropTypes.string.isRequired,
};

export default TaskPriorityCard;
