import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';

import './styles.scss';

const Stars = ({ wrapperClassName, stars, starIcon, showStarsNumber }) => {
  if (stars <= 0) {
    return null;
  }

  const starIcons = Array.from({ length: stars }, (_, icon) =>
    starIcon ? (
      React.cloneElement(starIcon, { key: icon })
    ) : (
      <Icon key={icon} name="star" size="tiny" color="warning" className="star-icons__star" />
    ),
  );

  if (stars > Math.floor(stars)) {
    starIcons.pop();
    starIcons.push(<Icon key={Math.floor(stars)} name="starHalf" size="tiny" color="warning" />);
  }

  return (
    <div className="d-flex no-wrap">
      <span className={classNames('star-icons', wrapperClassName)}>{starIcons}</span>
      {showStarsNumber && (
        <span className="star-label align-self-center">
          (
          {I18n.t('components.ibe.search_results.hotel.hotel_rating_count', {
            starCount: stars,
          })}
          )
        </span>
      )}
    </div>
  );
};

Stars.defaultProps = {
  wrapperClassName: null,
  stars: 0,
  starIcon: null,
  showStarsNumber: false,
};

Stars.propTypes = {
  wrapperClassName: PropTypes.string,
  stars: PropTypes.number,
  starIcon: PropTypes.element,
  showStarsNumber: PropTypes.bool,
};

export default Stars;
