import { fetchCarSearchResults } from './index';

export const CAR_SEARCH_FILTERS_UPDATED = 'CAR_SEARCH_FILTERS_UPDATED';
export function carSearchFiltersUpdated(filters, searchId) {
  return {
    type: CAR_SEARCH_FILTERS_UPDATED,
    filters,
    searchId,
  };
}

export const RESET_CAR_SEARCH_FILTERS = 'RESET_CAR_SEARCH_FILTERS';
export function resetCarSearchFilters(searchId, stats) {
  return dispatch => {
    dispatch({ type: RESET_CAR_SEARCH_FILTERS, searchId, stats });
    return dispatch(fetchCarSearchResults({ searchId }));
  };
}

export const RESET_CAR_SEARCH_FILTERS_BY_KEY = 'RESET_CAR_SEARCH_FILTERS_BY_KEY';
export function resetCarSearchFiltersByKey(searchId,stats) {
  return dispatch => {
    dispatch({ type: RESET_CAR_SEARCH_FILTERS_BY_KEY, searchId,stats });
    return dispatch(fetchCarSearchResults({ searchId }))
  };
  
}

export function filterCarSearch(filter, value, searchId) {
  return (dispatch, getState) => {
    const state = getState();
    const data = {
      ...state.cars.searchFilterParamsBySearchId[searchId],
      [filter]: value,
    };

    dispatch(carSearchFiltersUpdated(data, searchId));

    const params = {
      searchId,
      'price[max]': data.price.max,
      'price[min]': data.price.min,
      'categories[]': data.categories,
      'vendors[]': data.vendors,
      'sort_by': data.sortBy,
    };

    return dispatch(fetchCarSearchResults(params));
  };
}

export default filterCarSearch;
