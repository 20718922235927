import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { CheckBox, Spinner } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import Alert from 'sharedWebpack/Alert';
import TimeSelectBox from 'sharedWebpack/TimeSelectBox';
import ExpiryFormHeader from '../ExpiryFormHeader';

const ApprovalRequestReminder = props => {
  const { traveller, saveReminder, isLoading } = props;
  const [enableApprovalRequestReminder, setEnableApprovalRequestReminder] = useState(false);
  const [reminderDays, setReminderDays] = useState([]);
  const [reminderTime, setReminderTime] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const monthOptions = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const convertTimeInHuman = time => {
    let convertedTime = time;
    if (convertedTime < 0) {
      convertedTime += 24 * 60;
    }

    const hours = Math.floor(convertedTime / 60) % 24;
    const minutes = Math.floor(convertedTime % 60);
    const hoursInHuman = hours > 9 ? hours : `0${hours}`;
    const minutesInHuman = minutes > 9 ? minutes : `0${minutes}`;
    return `${hoursInHuman}:${minutesInHuman}`;
  };

  const timesInMinutes = time => {
    const splitTime = time.split(':');
    const minutes = parseInt(splitTime[0], 10) * 60 + parseInt(splitTime[1], 10);
    return minutes;
  };

  const convertTimeIntoUtc = time => {
    const differenceBetweenTime = new Date().getTimezoneOffset();

    const userInputInUtc = timesInMinutes(time) + differenceBetweenTime;

    return convertTimeInHuman(userInputInUtc);
  };

  const convertUtcTimeIntoLocalTime = time => {
    const differenceBetweenTime = new Date().getTimezoneOffset();
    const userInputInUtc = timesInMinutes(time) - differenceBetweenTime;

    return convertTimeInHuman(userInputInUtc);
  };

  const onUpdateApprovalRequestReminder = async () => {
    const params = {
      approval_request_reminder_days: reminderDays,
      approval_request_reminder_time: reminderTime && convertTimeIntoUtc(reminderTime.value),
      approval_request_reminder_active: enableApprovalRequestReminder,
    };

    await saveReminder(params)
      .then(data => {
        if (data.error === null) {
          setErrors({});
        }
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setErrors(response.data.error);
        }
      });
  };

  const onEnableReminder = () => {
    setIsModalOpen(enableApprovalRequestReminder);
    setEnableApprovalRequestReminder(previousState => !previousState);
  };

  const onChangeDay = (checked, day) => {
    setReminderDays(prevState => {
      let updatedDays = [...prevState];
      if (checked) {
        updatedDays.push(day);
      } else {
        updatedDays = updatedDays.filter(existingDay => existingDay !== day);
      }
      return updatedDays;
    });
  };

  const onDisablingReminder = () => {
    setEnableApprovalRequestReminder(false);
    setReminderDays([]);
    setReminderTime(null);
    setIsModalOpen(false);
  };

  const setInitialValue = () => {
    setEnableApprovalRequestReminder(traveller.approvalRequestReminderActive);
    setReminderDays(traveller.approvalRequestReminderDays);
    if (traveller.approvalRequestReminderTime) {
      const savedTimeInLocal = convertUtcTimeIntoLocalTime(traveller.approvalRequestReminderTime);
      setReminderTime({ label: savedTimeInLocal, value: savedTimeInLocal });
    } else {
      setReminderTime(null);
    }
  };

  useEffect(() => {
    if (traveller.id) {
      setInitialValue();
    }
  }, [traveller]);

  return (
    <div className="approval-request-reminder">
      <ConfirmationModal
        confirmationHeader={I18n.t('components.approval_request_reminder.confirmation.header')}
        subHeader={I18n.t('components.approval_request_reminder.confirmation.text')}
        confirmationText={I18n.t(
          'components.approval_request_reminder.confirmation.action.confirm',
        )}
        rejectionText={I18n.t('components.approval_request_reminder.confirmation.action.reject')}
        onConfirm={onDisablingReminder}
        onReject={onEnableReminder}
        withAction={true}
        type="danger"
        isModalOpen={isModalOpen}
        onConfirmationModalOpen={() => setIsModalOpen(true)}
        onConfirmationModalClose={() => setIsModalOpen(false)}
      />
      <Card version="v2" className="approval-request-reminder__container">
        {isLoading ? (
          <div className="approval-request-reminder__container-loader">
            <Spinner size="huge" bgColor="neutral" />
          </div>
        ) : (
          <Fragment>
            <ExpiryFormHeader
              onChange={onEnableReminder}
              checked={enableApprovalRequestReminder}
              onSave={onUpdateApprovalRequestReminder}
              headerName={I18n.t('components.approval_request_reminder.header')}
              headerText={I18n.t('components.approval_request_reminder.reminder.header')}
              placeholder={I18n.t('components.approval_request_reminder.reminder.placeholder')}
            />
            {enableApprovalRequestReminder && (
              <Fragment>
                <div className="approval-request-reminder__container-day">
                  <p className="approval-request-reminder__container-day-label">
                    {I18n.t('components.approval_request_reminder.remind_me_on')}
                  </p>
                  <div className="col-grid col-bleed-x direction-row col-12 wrap">
                    {monthOptions.map((month, index) => (
                      <div className="approval-request-reminder__container-day-checkbox">
                        <CheckBox
                          name={month}
                          key={month}
                          label={I18n.t(month, {
                            scope: 'components.approval_request_reminder.remind_day',
                          })}
                          checked={!!reminderDays.find(day => day === index + 1)}
                          onChange={event => onChangeDay(event.target.checked, index + 1)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <Alert
                  className="approval-request-reminder__container-alert"
                  isVisible={!!errors.approvalRequestReminderDays}
                  type="danger"
                  hideClose={true}
                >
                  {errors.approvalRequestReminderDays}
                </Alert>
                <div className="approval-request-reminder__container-time">
                  <div className="grid">
                    <TimeSelectBox
                      className="col-md-6"
                      label={I18n.t('components.approval_request_reminder.remind_me_at')}
                      value={reminderTime}
                      onChange={setReminderTime}
                      addAnytimeOption={false}
                      touched={!!errors.approvalRequestReminderTime}
                      errorMsg={errors.approvalRequestReminderTime}
                      width="full"
                      size="tiny"
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </Card>
    </div>
  );
};

ApprovalRequestReminder.defaultProps = {
  traveller: {},
};

ApprovalRequestReminder.propTypes = {
  traveller: PropTypes.shape({
    id: PropTypes.string.isRequired,
    approvalRequestReminderActive: PropTypes.bool.isRequired,
    approvalRequestReminderDays: PropTypes.arrayOf(PropTypes.number).isRequired,
    approvalRequestReminderTime: PropTypes.string.isRequired,
  }),
  saveReminder: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ApprovalRequestReminder;
