import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import GeneralInformation from './GeneralInformation';
import GeneralInformationForm from './GeneralInformationForm';

import '../styles.scss';

const GeneralSection = props => {
  const {
    traveller,
    salutations,
    titlePrefixes,
    preferredCommunications,
    spokenLanguages,
    correspondenceLanguageOptions,
    fetchTraveller,
    layman,
  } = props;

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});

  const [salutation, setSalutation] = useState(null);
  const [titlePrefix, setTitlePrefix] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickName, setNickName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [preferredCommunication, setPreferredCommunication] = useState(null);
  const [personalId, setPersonalId] = useState('');
  const [shortCode, setShortCode] = useState('');
  const [correspondenceLanguage, setCorrespondenceLanguage] = useState(null);
  const [spokenLanguage, setSpokenLanguage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const saveTravellerUrl = layman
    ? routes.public.people.update()
    : routes.admin.people.update({ personId: traveller.id });

  const saveTraveller = async () => {
    const travellerParams = {
      salutation,
      title_prefix: titlePrefix,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      nickname: nickName,
      birthday: dateOfBirth,
      preferred_communication: preferredCommunication,
      personal_id: personalId,
      short_code: shortCode,
      correspondence_language: correspondenceLanguage,
      spoken_languages: spokenLanguage && spokenLanguage.map(value => value.toLowerCase()),
    };

    const { data } = await httpClient.patch(saveTravellerUrl, {
      person: travellerParams,
    });
    if (data.error === null) {
      setIsLoading(true);

      if (data.redirectUrl) {
        window.location.assign(data.redirectUrl);
      } else {
        await fetchTraveller();
        setEditing(false);
      }
    }

    setIsLoading(false);
  };

  const onSaveTraveller = () =>
    saveTraveller().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  useEffect(() => {
    setSalutation(salutations[traveller.salutation]);
    setTitlePrefix(titlePrefixes[traveller.titlePrefix]);
    setFirstName(traveller.firstName);
    setMiddleName(traveller.middleName);
    setLastName(traveller.lastName);
    setNickName(traveller.nickname);
    setDateOfBirth(traveller.birthday);
    setPreferredCommunication(preferredCommunications[traveller.preferredCommunication]);
    setPersonalId(traveller.personalId);
    setShortCode(traveller.shortCode);
    const currentCorLang = correspondenceLanguageOptions.filter(
      corLan => corLan.label === traveller.correspondenceLanguage,
    );
    if (currentCorLang.length > 0) {
      setCorrespondenceLanguage(currentCorLang[0].value);
    }
    setSpokenLanguage(traveller.spokenLanguages);

    if (traveller && traveller.id) {
      setIsLoading(false);
    }
  }, [traveller]);

  return (
    <Fragment>
      {isLoading ? (
        <div className="col-grid traveller-edit-general-section">
          <ContentLoaderPlaceholder numberOfLines={12} isRounded={true} showBackground={false} />
        </div>
      ) : (
        <div className="col-grid align-center traveller-edit-general-section">
          <div className="col-grid direction-row col-bleed align-center justify-space-between">
            <div className="traveller-edit-general-section__header">
              {I18n.t('admin.components.travellers.edit.general.header')}
            </div>
            {editing ? (
              <div className="col-2 col-grid col-bleed direction-row align-center justify-end">
                <IconButton
                  icon={<Icon name="check" />}
                  color="tertiary"
                  size="small"
                  onClick={onSaveTraveller}
                />
                <IconButton
                  icon={<Icon name="close" />}
                  color="tertiary"
                  size="small"
                  onClick={() => setEditing(false)}
                />
              </div>
            ) : (
              <div className="traveller-edit-general-section__actions">
                <IconButton
                  icon={<Icon name="edit" />}
                  color="tertiary"
                  size="small"
                  onClick={() => setEditing(true)}
                />
              </div>
            )}
          </div>
          {editing ? (
            <GeneralInformationForm
              error={error}
              correspondenceLanguageOptions={correspondenceLanguageOptions}
              spokenLanguages={spokenLanguages}
              salutations={salutations}
              titlePrefixes={titlePrefixes}
              preferredCommunications={preferredCommunications}
              salutation={salutation}
              setSalutation={setSalutation}
              titlePrefix={titlePrefix}
              setTitlePrefix={setTitlePrefix}
              firstName={firstName}
              setFirstName={setFirstName}
              middleName={middleName}
              setMiddleName={setMiddleName}
              lastName={lastName}
              setLastName={setLastName}
              nickName={nickName}
              setNickName={setNickName}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              preferredCommunication={preferredCommunication}
              setPreferredCommunication={setPreferredCommunication}
              personalId={personalId}
              setPersonalId={setPersonalId}
              shortCode={shortCode}
              setShortCode={setShortCode}
              correspondenceLanguage={correspondenceLanguage}
              setCorrespondenceLanguage={setCorrespondenceLanguage}
              spokenLanguage={spokenLanguage}
              setSpokenLanguage={setSpokenLanguage}
              layman={layman}
            />
          ) : (
            <GeneralInformation traveller={traveller} layman={layman} />
          )}
        </div>
      )}
    </Fragment>
  );
};

GeneralSection.defaultProps = {
  traveller: {},
  layman: false,
};

GeneralSection.propTypes = {
  traveller: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    nickname: PropTypes.string,
    salutation: PropTypes.string,
    titlePrefix: PropTypes.string,
    birthday: PropTypes.string,
    shortCode: PropTypes.string,
    personalId: PropTypes.string,
    correspondenceLanguage: PropTypes.string,
    avatarUrl: PropTypes.string,
    profileCompletionPercentage: PropTypes.number,
    socialMediaLinks: PropTypes.shape({
      facebook: PropTypes.shape({
        url: PropTypes.string,
      }),
      twitter: PropTypes.shape({
        url: PropTypes.string,
      }),
      youtube: PropTypes.shape({
        url: PropTypes.string,
      }),
      linkedin: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    identificationDocuments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        flagUrl: PropTypes.string,
        nationality: PropTypes.string,
        expiresIn: PropTypes.number,
        showWarning: PropTypes.bool,
        showDanger: PropTypes.bool,
      }),
    ),
    spokenLanguages: PropTypes.arrayOf(PropTypes.string),
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        zipCode: PropTypes.string,
        city: PropTypes.string,
        type: PropTypes.string,
        primary: PropTypes.bool,
      }),
    ),
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        primary: PropTypes.bool,
        number: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    emailAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        primary: PropTypes.bool,
        email: PropTypes.string,
      }),
    ),
    preferredCommunication: PropTypes.string,
    organization: PropTypes.shape({
      id: PropTypes.number,
      avatarUrl: PropTypes.string,
      name: PropTypes.string,
    }),
    emergencyContacts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        number: PropTypes.string,
      }),
    ),
  }),
  salutations: PropTypes.shape({
    mr: PropTypes.number,
    mrs: PropTypes.number,
    ms: PropTypes.number,
  }).isRequired,
  titlePrefixes: PropTypes.shape({
    dr: PropTypes.number,
    dr_prof: PropTypes.number,
    prof: PropTypes.number,
  }).isRequired,
  preferredCommunications: PropTypes.shape({
    email: PropTypes.number,
    phone: PropTypes.number,
  }).isRequired,
  spokenLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  correspondenceLanguageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  fetchTraveller: PropTypes.func.isRequired,
  layman: PropTypes.bool,
};

export default GeneralSection;
