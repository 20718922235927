import React from 'react';
import PropTypes from 'prop-types';
import Stars from '../components/Stars';
import ItemPricing from '../Cart/Items/ItemPricing';
import './styles.scss';

const HotelSummaryDetails = ({
  street,
  city,
  countryCode,
  stars,
  item,
  journeyElement,
  status,
}) => {
  const { itemTitle, journeyElementType, available, pendingConfirmation, type } = item;
  const hotelAddress = [street, city, countryCode].filter(value => value).join(', ');
  const isStarsAvailable = stars && Number(stars) > 0;
  const isHorizontalBarVisible = isStarsAvailable && hotelAddress.length > 0;

  return (
    <div className="d-flex direction-column w-100">
      <div className="d-flex justify-between flex-gap-12 align-center">
        <div className="hotel-summary-details__info">
          <div className="hotel-summary-details__title">{itemTitle}</div>
          <div className="d-flex flex-gap-8 flex-wrap">
            {isStarsAvailable && (
              <div className="hotel-summary-details__star">
                <Stars showStarsNumber={true} stars={parseFloat(stars)} />
              </div>
            )}
            {isHorizontalBarVisible && <div className="hotel-summary-details__horizontal" />}
            <span className="hotel-summary-details__address">{hotelAddress}</span>
          </div>
        </div>
        <div className="item__wrapper-hotel-price hotel-cart-item-header-lg">
          <ItemPricing
            type={journeyElementType || type}
            available={available}
            item={item}
            journeyElement={journeyElement}
            itemStatus={status}
            pendingConfirmation={pendingConfirmation}
          />
        </div>
      </div>
    </div>
  );
};

HotelSummaryDetails.defaultProps = {
  street: '',
  city: '',
  countryCode: '',
  stars: '',
  itemDetails: {},
};

HotelSummaryDetails.propTypes = {
  itemTitle: PropTypes.string.isRequired,
  street: PropTypes.string,
  city: PropTypes.string,
  countryCode: PropTypes.string,
  stars: PropTypes.string,
  item: PropTypes.shape({
    itemTitle: PropTypes.string,
    type: PropTypes.string,
    journeyElementType: PropTypes.string,
    available: PropTypes.bool,
    pendingConfirmation: PropTypes.bool.isRequired,
    approvalRequired: PropTypes.bool.isRequired,
  }).isRequired,
  itemDetails: PropTypes.shape({
    instantTicket: PropTypes.bool.isRequired,
  }),
  journeyElement: PropTypes.shape({
    refundable: PropTypes.bool,
    durationInDays: PropTypes.number,
    rooms: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  status: PropTypes.shape({}).isRequired,
};

export default HotelSummaryDetails;
