const mapAvailableChannels = (searchAccounts, initialAvailableChannels) => {
  let modifiedAvailableChannels = initialAvailableChannels;
  if (!modifiedAvailableChannels || Object.keys(modifiedAvailableChannels).length === 0) {
    modifiedAvailableChannels = {
      flights: Object.keys(searchAccounts.flights).filter(
        channel => searchAccounts.flights[channel].credentials.length > 0,
      ),
      hotels: Object.keys(searchAccounts.hotels).filter(
        channel => searchAccounts.hotels[channel].credentials.length > 0,
      ),
      cars: Object.keys(searchAccounts.cars).filter(
        channel => searchAccounts.cars[channel].credentials.length > 0,
      ),
    };
  }
  return modifiedAvailableChannels;
};

export default mapAvailableChannels;
