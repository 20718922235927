import React, { useState } from 'react';
import Card from '@wtag/rcl-card';
import { I18nText } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';
import useViewMode from 'sharedWebpack/useViewMode';
import {
  TAB_MOBILE_DEVICE,
  MOBILE_DEVICE,
  SMALL_MOBILE_DEVICE,
} from 'sharedWebpack/old/libraries/responsiveHelpers';
import Amenity from '../Amenity';
import { LIMIT_IN_MOBILE_VIEW, LIMIT_IN_TAB_VIEW } from './constants';
import './style.scss';

const AmenitiesToggleButton = ({ onClick, label }) => (
  <div
    className="hotel-amenities__toggle-button"
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex="0"
  >
    {label}
  </div>
);

const HotelAmenities = ({ amenities, limitInMobileView, limitInTabView }) => {
  const viewMode = useViewMode();
  const [isShowAll, setIsShowAll] = useState(false);

  const amenitiesCount = amenities.length;
  const isMobileView = [SMALL_MOBILE_DEVICE, MOBILE_DEVICE].includes(viewMode);
  const isTabMobileView = viewMode === TAB_MOBILE_DEVICE;

  if (amenitiesCount === 0) {
    return null;
  }

  const shouldShowPartialAmenities =
    !isShowAll &&
    (isMobileView || isTabMobileView) &&
    (amenitiesCount > limitInMobileView || amenitiesCount > limitInTabView);

  const numberOfAmenitiesToDisplay = isMobileView ? limitInMobileView : limitInTabView;
  const numberOfAmenitiesToHide = amenitiesCount - numberOfAmenitiesToDisplay;
  const amenitiesToShow = shouldShowPartialAmenities
    ? amenities.slice(0, numberOfAmenitiesToDisplay)
    : amenities;

  const toggleIsShowAll = () => {
    setIsShowAll(prevState => !prevState);
  };

  const showAmenitiesToggleButton = isShowAll ? (
    <AmenitiesToggleButton
      onClick={toggleIsShowAll}
      label={I18n.t('components.ibe.hotel.details.amenities.hide', {
        count: numberOfAmenitiesToHide,
      })}
    />
  ) : (
    <AmenitiesToggleButton
      onClick={toggleIsShowAll}
      label={I18n.t('components.ibe.hotel.details.amenities.see_more', {
        count: numberOfAmenitiesToHide,
      })}
    />
  );

  const shouldShowToggleButton = () => {
    if (isMobileView) {
      return amenitiesCount > limitInMobileView;
    } else if (isTabMobileView) {
      return amenitiesCount > limitInTabView;
    }

    return false;
  };

  return (
    <Card version="v2" size="full" className="col-12 hotel-amenities">
      <div className="hotel-amenities__title">
        <I18nText id="ibe.hotel.details.amenities.title" />
      </div>
      <ul className="hotel-amenities__list">
        {amenitiesToShow.map(amenity => (
          <li key={amenity}>
            <Amenity amenity={amenity} />
          </li>
        ))}
      </ul>
      {shouldShowToggleButton() && showAmenitiesToggleButton}
    </Card>
  );
};

AmenitiesToggleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
};

HotelAmenities.defaultProps = {
  limitInMobileView: LIMIT_IN_MOBILE_VIEW,
  limitInTabView: LIMIT_IN_TAB_VIEW,
};

HotelAmenities.propTypes = {
  amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
  limitInMobileView: PropTypes.number,
  limitInTabView: PropTypes.number,
};

export default HotelAmenities;
