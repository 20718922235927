import PropTypes from 'prop-types';

const AirportShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  placeName: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
});

export default AirportShape;
