import React from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import { I18nText } from '@wtag/react-comp-lib';
import Card from '@wtag/rcl-card';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import SelectBox from '@wtag/rcl-select-box';
import debounce from 'throttle-debounce/debounce';
import reduxFormShape from '../../Payment/shapes/reduxForm';

import '../styles.scss';

class FriendsShare extends React.Component {
  addRecipientFromSearch = item => {
    const newRecipients = [...this.props.recipients];
    newRecipients.push(JSON.parse(item.value));
    this.props.updateConfirmationMailRecipients(newRecipients);
    this.props.resetForm('friendsShareForm');
  };

  removeRow = recipient => {
    const index = this.props.recipients.indexOf(recipient);
    const newRecipients = [...this.props.recipients];
    newRecipients.splice(index, 1);
    this.props.updateConfirmationMailRecipients(newRecipients);
    this.props.resetForm('friendsShareForm');
  };

  handleQuery = query => {
    this.debouncedSearchRecipients(query);
  };

  searchRecipients = query => {
    this.props.confirmationMailRecipientSearch(query);
  };

  debouncedSearchRecipients = debounce(300, query => this.searchRecipients(query));

  dataSource = () =>
    this.props.recipientSearchResults.map(recipient => ({
      label: `${recipient.name} (${recipient.email})`,
      value: JSON.stringify(recipient),
    }));

  render() {
    let table = null;
    if (this.props.recipients.length > 0) {
      table = (
        <div className="col-12 col-grid col-bleed friends-share-content__table">
          {this.props.recipients.map(recipient => (
            <div className="col-12 col-grid col-bleed friends-share-content__recipient-row">
              <Card version="v2" size="full" shadow="dark">
                <div className="col-12 col-bleed friends-share-content__recipient-row-content">
                  <div className="col-9 col-bleed friends-share-content__recipient-row-content__recipient">
                    {recipient.name} ({recipient.email})
                  </div>
                  <div className="col-3 col-bleed friends-share-content__recipient-row-content__delete-icon">
                    <IconButton
                      icon={<Icon name="delete" />}
                      onClick={() => {
                        this.removeRow(recipient);
                      }}
                    />
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      );
    }
    const {
      fields: { recipientSearch },
      errors,
    } = this.props;

    return (
      <div className="col-12 col-grid col-bleed friends-share-content__card-wrapper">
        <div className="col-grid col-bleed email-share-content__text-label">
          <p>
            <I18nText id="ibe.share_friends.label" returnStringOnly={true} />
          </p>
        </div>
        <div className="col-12 col-grid col-bleed friends-share-content__recipient-card">
          <Card version="v2" size="full" shadow="dark">
            <div className="email-share-content__add-label">
              <I18nText id="ibe.share_friends.input_label" returnStringOnly={true} />
            </div>
            <div className="friends-share-content__add-label-input">
              <SelectBox
                onInputChange={this.handleQuery}
                options={this.dataSource()}
                {...recipientSearch}
                touched={true}
                placeholderText={I18n.t(
                  'components.confirmation_mail.placeholder.search_recipient',
                )}
                errorMsg={errors.recipientSearch && errors.recipientSearch.join()}
                preIcon={<Icon name="search" />}
                width="full"
              />
            </div>
            <div className="friends-share-content__add-button">
              <Button
                version="v2"
                size="small"
                label={I18n.t('components.ibe.confirmation_mail.button.add')}
                onClick={() => this.addRecipientFromSearch(recipientSearch.value)}
                disabled={
                  !recipientSearch.valid ||
                  recipientSearch.value === '' ||
                  recipientSearch.value == null
                }
              />
            </div>
          </Card>
          {table}
        </div>
        <div className="col-12 col-grid col-bleed friends-share-content__share-button">
          <Button
            version="v2"
            size="small"
            label={<I18nText id="ibe.share_friends.done" returnStringOnly={true} />}
            onClick={this.props.toggleModal}
            disabled={this.props.recipients.length < 1}
          />
        </div>
      </div>
    );
  }
}

FriendsShare.propTypes = {
  updateConfirmationMailRecipients: PropTypes.func.isRequired,
  confirmationMailRecipientSearch: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
  recipientSearchResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
  resetForm: PropTypes.func.isRequired,
  ...reduxFormShape,
};

export default FriendsShare;
