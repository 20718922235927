import React from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import DocumentForm from 'sharedWebpack/Documents/Form';

const EditDocument = ({ orderId, redirectUrl, attachmentTypes, attachmentId }) => {
  const updateDocument = async formData => {
    const { data } = await httpClient.patch(
      routes.public.orderDocuments.update({ orderId, attachmentId }),
      formData,
    );

    if (data.error === null) {
      window.location.assign(redirectUrl);
    }
  };

  const fetchDocument = async () => {
    const { data } = await httpClient.get(
      routes.public.orderDocuments.show({
        orderId,
        attachmentId,
      }),
    );
    return data;
  };

  return (
    <React.Fragment>
      <DocumentForm
        orderId={orderId}
        performAssignedAction={value => updateDocument(value)}
        attachmentTypes={attachmentTypes}
        redirectUrl={redirectUrl}
        attachmentId={attachmentId}
        layman={true}
        fetchAction={fetchDocument}
      />
    </React.Fragment>
  );
};

EditDocument.propTypes = {
  orderId: PropTypes.number.isRequired,
  attachmentId: PropTypes.number.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  attachmentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EditDocument;
