import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';

function TotalPriceContainer(props) {
  const { totalPrice, currency } = props;

  return (
    <div className="cart-summary__total-price-container">
      <div className="cart-summary__total-price-container-label">
        {I18n.t('components.ibe.cart_summary_total')}
      </div>
      <div className="cart-summary__total-price-container-value">
        <Amount currency={currency} value={totalPrice} />
      </div>
    </div>
  );
}

TotalPriceContainer.propTypes = {
  currency: PropTypes.string.isRequired,
  totalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default memo(TotalPriceContainer);
