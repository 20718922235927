import React from 'react';
import RCLAlert from '@wtag/rcl-alert';
import PropTypes from 'prop-types';

const AlertComponentWrapper = props => {
  const {
    children,
    isVisible,
    className,
    hideClose,
    title,
    type,
    onClose,
    isBackgroundVisible,
    isIconVisible,
  } = props;

  return (
    <RCLAlert
      className={className}
      isVisible={isVisible}
      hideClose={hideClose}
      title={title}
      type={type}
      onClose={onClose}
      isBackgroundVisible={isBackgroundVisible}
      isIconVisible={isIconVisible}
    >
      {children}
    </RCLAlert>
  );
};

AlertComponentWrapper.defaultProps = {
  isVisible: false,
  children: null,
  className: null,
  hideClose: false,
  title: null,
  type: 'neutral',
  isBackgroundVisible: true,
  isIconVisible: false,
  onClose: () => {},
};

AlertComponentWrapper.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  hideClose: PropTypes.bool,
  isBackgroundVisible: PropTypes.bool,
  isIconVisible: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  type: PropTypes.oneOf([
    'warning',
    'warning-light',
    'danger',
    'danger-light',
    'success',
    'success-light',
    'neutral',
  ]),
  onClose: PropTypes.func,
};

export default AlertComponentWrapper;
