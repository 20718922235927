import React from 'react';
import PropTypes from 'prop-types';
import { I18nText, EmphasisTag, Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Icon from '@wtag/rcl-icon';
import { Table, TableHead, TableHeader, TableBody, TableData, TableRow } from '@wtag/rcl-table';
import Button from '@wtag/rcl-button';
import EmptyTableContent from 'sharedWebpack/EmptyTableContent';
import TablePlaceHolder from 'sharedWebpack/Placeholder/TablePlaceholder';
import DateTime from 'sharedWebpack/DateTime';

const TravelArrangersTable = ({
  travelArrangers,
  displayColumns,
  isLoading,
  state,
  type,
  onDeactivate,
  onReactivate,
  setConfirmationName,
  setIsModalOpen,
}) => {
  const renderActions = travelArranger => {
    let actions;
    switch (state) {
      case 'active':
        switch (type) {
          case 'arrangers':
            actions = (
              <div className="travel-arranger__actions">
                <Link
                  href={travelArranger.urls ? travelArranger.urls.edit : ''}
                  labelHint="edit"
                  type="icon-button"
                  modifier="tertiary"
                >
                  <Icon name="edit" size="normal" />
                </Link>
                <Button
                  label={
                    <span>
                      {I18n.t('public.components.travel_arrangement_settings.label.deactivate')}
                    </span>
                  }
                  size="small"
                  version="v2"
                  onClick={() => onDeactivate(travelArranger.id)}
                  className="travel-arranger__actions--with-margin-left"
                  icon={
                    <Icon
                      fill="#4B73A7"
                      className="travel-arranger__actions--deactivate-icon"
                      name="archive"
                    />
                  }
                />
              </div>
            );
            break;
          case 'clients':
            actions = (
              <Button
                label={
                  <span>
                    {I18n.t('public.components.travel_arrangement_settings.label.deactivate')}
                  </span>
                }
                size="small"
                version="v2"
                onClick={() => onDeactivate(travelArranger.id)}
                className="travel-arranger__actions--with-margin-left"
                icon={
                  <Icon
                    fill="#4B73A7"
                    className="travel-arranger__actions--deactivate-icon"
                    name="archive"
                  />
                }
              />
            );
            break;
          default:
        }
        break;
      case 'inactive':
        switch (type) {
          case 'arrangers':
            actions = (
              <Button
                label={I18n.t(
                  'public.components.travel_arrangement_settings.label.request_reactivation',
                )}
                size="small"
                version="v2"
                onClick={() =>
                  onReactivate(travelArranger.id).then(() => {
                    setConfirmationName(travelArranger.person.name);
                    setIsModalOpen(true);
                  })
                }
              />
            );
            break;
          case 'clients':
            break;
          default:
        }
        break;
      default:
    }

    return actions;
  };

  const deactivatedClientsState = () => state === 'inactive' && type === 'clients';

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>
              {I18n.t('public.components.travel_arrangement_settings.label.travellers')}
            </TableHeader>
            <TableHeader>
              {I18n.t('public.components.travel_arrangement_settings.label.email')}
            </TableHeader>
            <TableHeader>
              {I18n.t('public.components.travel_arrangement_settings.label.permission_level')}
            </TableHeader>
            {displayColumns.invitedOn && (
              <TableHeader>
                {I18n.t('public.components.travel_arrangement_settings.label.invited_on')}
              </TableHeader>
            )}
            {displayColumns.requestedOn && (
              <TableHeader>
                {I18n.t('public.components.travel_arrangement_settings.label.requested_on')}
              </TableHeader>
            )}
            {displayColumns.deactivatedOn && (
              <TableHeader>
                {I18n.t('public.components.travel_arrangement_settings.label.deactivated_on')}
              </TableHeader>
            )}
            {!deactivatedClientsState() && (
              <TableHeader align="center">
                <I18nText id="shared.action" returnStringOnly={true} />
              </TableHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TablePlaceHolder numberOfColumn={5} numberOfRows={10} withoutRclTable={true} />
          ) : (
            travelArrangers.map(travelArranger => (
              <TableRow key={travelArranger.id}>
                <TableData smallScreenConfig={{ order: 1 }}>
                  <Avatar
                    size="tiny"
                    firstName={travelArranger.person.firstName}
                    middleName={travelArranger.person.middleName || ''}
                    lastName={travelArranger.person.lastName}
                    src={travelArranger.person.avatarUrl}
                    showName={true}
                  />
                </TableData>
                <TableData smallScreenConfig={{ order: 2 }}>
                  <span className="travel-arranger__email">
                    {' '}
                    {travelArranger.person.email ||
                      I18n.t(
                        'public.components.travel_arrangement_settings.owner_email_status',
                      )}{' '}
                  </span>
                </TableData>
                <TableData smallScreenConfig={{ order: 4 }}>
                  <EmphasisTag
                    size="tiny"
                    text={I18n.t(travelArranger.level, {
                      scope: 'activerecord.attributes.travel_arranger_settings.levels',
                    })}
                    type="neutral"
                  />
                </TableData>
                {displayColumns.invitedOn && (
                  <TableData smallScreenConfig={{ order: 5 }}>
                    <DateTime dateTime={travelArranger.createdAt} format="short" />
                  </TableData>
                )}
                {displayColumns.requestedOn && (
                  <TableData smallScreenConfig={{ order: 5 }}>
                    <DateTime dateTime={travelArranger.createdAt} format="short" />
                  </TableData>
                )}
                {displayColumns.deactivatedOn && (
                  <TableData smallScreenConfig={{ order: 5 }}>
                    <DateTime dateTime={travelArranger.updatedAt} format="short" />
                  </TableData>
                )}
                <TableData
                  smallScreenConfig={{ order: 3 }}
                  className="traveller-arranger-tab-list__actions"
                  align="center"
                >
                  {renderActions(travelArranger)}
                </TableData>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {!isLoading && travelArrangers.length === 0 && <EmptyTableContent />}
    </React.Fragment>
  );
};

TravelArrangersTable.defaultProps = {
  travelArrangers: [],
  displayColumns: {
    invitedOn: false,
    requestedOn: false,
    deactivatedOn: false,
  },
};

TravelArrangersTable.propTypes = {
  travelArrangers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      level: PropTypes.string,
      state: PropTypes.string,
      person: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        avatarUrl: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  ),
  displayColumns: PropTypes.shape({
    invitedOn: PropTypes.bool,
    requestedOn: PropTypes.bool,
    deactivatedOn: PropTypes.bool,
  }),
  isLoading: PropTypes.bool.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onReactivate: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setConfirmationName: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export default TravelArrangersTable;
