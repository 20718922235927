import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';

const PreciseDateWithIcon = ({ date }) => {
  const today = new Date();
  const month = today.getMonth();
  const day = today.getDate();
  const year = today.getFullYear();
  const givenDate = new Date(date);

  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const preciseDate = givenDate.toLocaleString('default', options);

  let iconWithDate = <Icon iconLabel={preciseDate} name="calendar" showLabel={true} />;

  if (year === givenDate.getFullYear() && month === givenDate.getMonth()) {
    const dayDiff = givenDate.getDate() - day;

    if (dayDiff === 0) {
      iconWithDate = (
        <Icon
          iconLabel={I18n.t('activerecord.attributes.order_item.today')}
          name="calendar"
          showLabel={true}
          color="danger"
        />
      );
    } else if (dayDiff > 0) {
      iconWithDate = (
        <Icon
          iconLabel={I18n.t('activerecord.attributes.order_item.in_days', { day: dayDiff })}
          name="calendar"
          showLabel={true}
          color="warning"
        />
      );
    }
  }

  return <React.Fragment>{iconWithDate}</React.Fragment>;
};

PreciseDateWithIcon.propTypes = {
  date: PropTypes.string.isRequired,
};

export default PreciseDateWithIcon;
