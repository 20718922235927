import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { stopAsyncValidation, startAsyncValidation } from 'redux-form';
import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';

const asyncTravelerEmailValidate = (formValue, formName, dispatch) => {
  if (!formValue) return null;

  dispatch(startAsyncValidation(formName));

  const emailErrors = formValue.travelers.map(traveler => {
    const email = traveler.email.value;
    const travelerId = (traveler.id && traveler.id.value) || null;

    if (email) {
      return httpClient
        .get(routes.validations.travelerEmail({ email, traveler_id: travelerId }))
        .then(() => ({ email: null }))
        .catch(({ response }) => {
          if (response.status === 422) {
            return { email: response.data.error };
          }

          return {
            email: <I18nText id="ibe.async_validations.network_error" />,
          };
        });
    }
    return null;
  });
  return Promise.all(emailErrors)
    .then(values => {
      const errors = { travelers: values };
      return errors;
    })
    .then(allEmailErrors => dispatch(stopAsyncValidation(formName, allEmailErrors)));
};

export default asyncTravelerEmailValidate;
