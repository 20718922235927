/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';
import classNames from 'classnames';
import { I18nText } from '@wtag/react-comp-lib';

import ChildRow from './SubComponents/ChildRow';

function Row(props) {
  const [isItemDetailsVisible, setIsItemDetailsVisible] = useState(false);
  const amount = (price, currency) =>
    parseFloat(price) === 0 ? (
      I18n.t('components.ibe.cart_summary_price_free')
    ) : (
      <Amount value={price} currency={currency} />
    );

  const onAmountClickHandler = () => setIsItemDetailsVisible(prevState => !prevState);
  const VOUCHER_TYPE = 'voucher';
  const itemTypeVoucher = props.type === VOUCHER_TYPE;

  return (
    <div className={classNames('cart-summary__accordion-item-body-row', props.className)}>
      <div
        className={classNames('cart-summary__accordion-item-body-row-title', {
          'cart-summary__accordion-item-body-row-title--warning': itemTypeVoucher,
        })}
      >
        <div>{props.name}</div>

        {props.pendingConfirmation && (
          <div className="cart-summary__accordion-item-body-row-title--secondary">
            <I18nText id="ibe.automatic_products.pending_confirmation" returnStringOnly={true} />
          </div>
        )}
      </div>
      <div
        className={classNames('cart-summary__accordion-item-body-row-amount', {
          'cart-summary__accordion-item-body-row-amount--no-underline': !props.hasChildRow,
          'cart-summary__accordion-item-body-row-amount--green': props.showPriceInGreen,
        })}
        role="button"
        tabIndex={0}
        onClick={onAmountClickHandler}
        onKeyDown={onAmountClickHandler}
      >
        {amount(props.price, props.currency)}
      </div>
      {props.hasChildRow && (
        <ChildRow
          isVisible={isItemDetailsVisible}
          journeyElementType={props.journeyElementType}
          type={props.type}
          category={props.category}
          acrissCode={props.acrissCode}
          price={props.price}
          duration={props.duration}
          currency={props.currency}
          rooms={props.rooms}
          quantity={props.quantity}
          unitPrice={props.unitPrice}
          seatList={props.seatList}
          segmentsWithSeatId={props.segmentsWithSeatId}
          number={props.number}
        />
      )}
    </div>
  );
}

Row.defaultProps = {
  conversionInfo: null,
  unitPrice: '0.0',
  hasChildRow: true,
  showPriceInGreen: false,
  pendingConfirmation: false,
  number: null,
};

Row.propTypes = {
  name: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  unitPrice: PropTypes.string,
  hasChildRow: PropTypes.bool,
  conversionInfo: PropTypes.shape({
    sourceAmount: PropTypes.string.isRequired,
    sourceCurrency: PropTypes.string.isRequired,
    sourceTaxes: PropTypes.string.isRequired,
    sourceExchangeRate: PropTypes.string.isRequired,
    targetCurrency: PropTypes.string.isRequired,
  }),
  journeyElementType: PropTypes.string.isRequired,
  showPriceInGreen: PropTypes.bool,
  pendingConfirmation: PropTypes.bool,
  number: PropTypes.string,
};
export default Row;
