import { UPDATE_STRINGIFIED_THREE_DS_STATUSES } from '../actions';

export default function stringifiedThreeDsStatuses(state = '', action) {
  switch (action.type) {
    case UPDATE_STRINGIFIED_THREE_DS_STATUSES:
      return JSON.stringify(action.newState);
    default:
      return state;
  }
}
