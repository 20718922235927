import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import useViewMode from 'sharedWebpack/useViewMode';
import { DESKTOP_DEVICE } from 'sharedWebpack/old/libraries/responsiveHelpers';
import FacilityTypeList from '../FacilityTypeList';
import Insurance from '../Insurance';
import FacilityItemLink from '../FacilityItemLink';

const InsurancesAndLiabilities = ({ insurances, liability, onShowSidePanel }) => {
  const hasInsurances = insurances && insurances.length > 0;
  const hasLiability = liability && liability.length > 0;

  if (!hasInsurances && !hasLiability) {
    return null;
  }

  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;
  const coverageLinkLabel = isLargeScreen ? (
    <I18nText id="ibe.search_results.car.label.view_coverage" />
  ) : (
    <I18nText id="ibe.search_results.car.label.coverage" />
  );
  const liabilitiesLinkLabel = isLargeScreen ? (
    <I18nText id="ibe.search_results.car.label.view_liabilities" />
  ) : (
    <I18nText id="ibe.search_results.car.label.liabilities" />
  );

  return (
    <Fragment>
      {hasInsurances && (
        <FacilityItemLink
          className="d-flex align-center column-gap-4"
          showIcon={true}
          iconLabel={<I18nText id="ibe.search_results.car.label.responsibility_waiver_available" />}
          label={coverageLinkLabel}
          sidePanelTitle={<I18nText id="ibe.search_results.car.label.responsibility_waiver" />}
          sidePanelBody={
            <FacilityTypeList type="insurance" list={insurances} listItemComponent={Insurance} />
          }
          onClick={onShowSidePanel}
        />
      )}
      {hasLiability && (
        <FacilityItemLink
          className="d-flex align-center column-gap-4"
          label={liabilitiesLinkLabel}
          sidePanelTitle={<I18nText id="ibe.search_results.car.label.liabilities" />}
          sidePanelBody={
            <FacilityTypeList type="liabilities" list={liability} listItemComponent={Insurance} />
          }
          onClick={onShowSidePanel}
        />
      )}
    </Fragment>
  );
};

const FacilityItemShape = PropTypes.shape({
  amount: PropTypes.string,
  currency: PropTypes.string,
  modifier: PropTypes.string,
  type: PropTypes.string,
});

InsurancesAndLiabilities.defaultProps = {
  onShowSidePanel: () => {},
};

InsurancesAndLiabilities.propTypes = {
  insurances: PropTypes.arrayOf(FacilityItemShape).isRequired,
  liability: PropTypes.arrayOf(FacilityItemShape).isRequired,
  onShowSidePanel: PropTypes.func,
};

export default InsurancesAndLiabilities;
