import { CAR_SET_DEFAULT_SEARCH_PARAMS, SEARCHING_CARS } from '../../actions/car';

export default function defaultCarSearchParams(state = {}, action) {
  switch (action.type) {
    case SEARCHING_CARS:
      return {};

    case CAR_SET_DEFAULT_SEARCH_PARAMS:
      return action.params;

    default:
      return state;
  }
}
