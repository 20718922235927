import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import { NumberParam, useQueryParams, StringParam } from 'use-query-params';
import debounce from 'throttle-debounce/debounce';
import routes from 'agentRoutes';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import Pagination from 'sharedWebpack/Pagination';
import SortingPill from 'sharedWebpack/SortingPill';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import Table from './Table';

const TabContent = ({ type, state }) => {
  const [travelArrangerSettings, setTravelArrangerSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationName, setConfirmationName] = useState('');
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    page: NumberParam,
    sortColumn: StringParam,
    sortDirection: StringParam,
  });
  const { q, page, sortColumn, sortDirection } = query;
  const [localSearchQuery, setLocalSearchQuery] = React.useState(q);

  const setSearchQueryInParams = React.useRef(
    debounce(500, value => setQuery({ q: value, page: 1 })),
  ).current;

  const setSearchQuery = value => {
    // We keep a local state here as we don't want to do an AJAX call for each key the user presses
    // This allows us to keep the UI responsive and fetch new data with a delay
    setLocalSearchQuery(value);
    setSearchQueryInParams(value);
  };

  const displayColumns = {
    arrangers: {
      active: {
        invitedOn: true,
      },
      inactive: {
        deactivatedOn: true,
      },
    },
    clients: {
      active: {
        requestedOn: true,
      },
      inactive: {
        deactivatedOn: true,
      },
    },
  };

  const fetchTravelArrangers = args => {
    setIsLoading(true);

    const { newPage, newState, apiToken } = args;
    httpClient
      .get(
        routes.public.travelArrangerSettings[type].list({
          state: newState,
          page: newPage,
          column: sortColumn,
          direction: sortDirection,
          q,
        }),
        {
          cancelToken: apiToken,
        },
      )
      .then(({ data }) => {
        setTravelArrangerSettings(data.travelArrangerSettings);
        setTotalPages(data.pagination.totalPages);
        setIsLoading(false);
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }
        throw error;
      });
  };

  const onReactivate = async travelArrangerSettingID => {
    const { data } = await httpClient.patch(
      routes.public.travelArrangerSettings[type].activate({ travelArrangerSettingID }),
    );
    if (data.error === null) {
      fetchTravelArrangers({
        newPage: page,
        newState: state,
      });
    }

    return data;
  };

  const onDeactivate = async travelArrangerSettingID => {
    const { data } = await httpClient.delete(
      routes.public.travelArrangerSettings[type].deactivate({ travelArrangerSettingID }),
    );
    if (data.error === null) {
      fetchTravelArrangers({
        newPage: page,
        newState: state,
      });
    }
  };

  const updateSorting = (column, direction) => {
    setIsLoading(true);
    setQuery({ sortColumn: column, sortDirection: direction });
  };

  const updatePagination = newPage => {
    setQuery({ page: newPage });
  };

  useEffect(() => {
    const getTravelArrangerSettingsApiToken = generateSourceToken();

    fetchTravelArrangers({
      newPage: page,
      newState: state,
      apiToken: getTravelArrangerSettingsApiToken.token,
    });

    return () => {
      getTravelArrangerSettingsApiToken.cancel();
    };
  }, [q, page, type, state, sortColumn, sortDirection]);

  const renderSortingPillAccordingToType = useCallback(() => {
    if (type === 'arrangers' || 'clients') {
      let label;
      let columnName;

      if (type === 'arrangers') {
        label = I18n.t('travel_arrangers.sort.invitation_date');
        columnName = 'created_at';

        if (state === 'inactive') {
          label = I18n.t('travel_arrangers.sort.deactivation_date');
          columnName = 'updated_at';
        }
      } else {
        label = I18n.t('travel_arrangers.sort.requested_date');
        columnName = 'created_at';

        if (state === 'inactive') {
          label = I18n.t('travel_arrangers.sort.deactivation_date');
          columnName = 'updated_at';
        }
      }

      return (
        <div className="travel-arranger__search-and-sorting--sort">
          <SortingPill
            size="small"
            name={label}
            thisSortColumn={columnName}
            currentSortColumn={sortColumn}
            currentSortDirection={sortDirection}
            onChange={updateSorting}
          />
        </div>
      );
    }
    return null;
  }, [type, state, sortColumn, sortDirection]);

  return (
    <div className="col-grid col-bleed travel-arranger__table-list">
      <div className="col-12 col-grid direction-row justify-space-between col-bleed wrap">
        <div className="col-md-8 col-bleed travel-arranger__search-and-sorting">
          <div className="travel-arranger__search-and-sorting--sort">
            <SortingPill
              size="small"
              name={I18n.t('travel_arrangers.sort.travelers')}
              thisSortColumn={type === 'arrangers' ? 'arranger' : 'client'}
              currentSortColumn={sortColumn}
              currentSortDirection={sortDirection}
              onChange={updateSorting}
            />
          </div>
          <div className="travel-arranger__search-and-sorting--sort">
            <SortingPill
              size="small"
              name={I18n.t('travel_arrangers.sort.level')}
              thisSortColumn="level"
              currentSortColumn={sortColumn}
              currentSortDirection={sortDirection}
              onChange={updateSorting}
            />
          </div>

          {renderSortingPillAccordingToType()}
        </div>

        <div className="col-sm-4 col-bleed travel-arranger__search-and-sorting--search ">
          <Input
            size="tiny"
            placeholder={I18n.t('travel_arrangers.search.placeholder')}
            preIcon={<Icon name="search" />}
            value={localSearchQuery}
            onChange={setSearchQuery}
          />
        </div>
      </div>
      <div className="col-12 col-bleed-x">
        <ConfirmationModal
          confirmationHeader={I18n.t(
            'public.components.travel_arrangement_settings.confirmation.reactivate.header',
          )}
          subHeader={I18n.t(
            'public.components.travel_arrangement_settings.confirmation.reactivate.sub_header',
            {
              name: confirmationName,
            },
          )}
          onTimeout={() => setIsModalOpen(false)}
          withAction={false}
          type="success"
          isModalOpen={isModalOpen}
          onConfirmationModalOpen={() => setIsModalOpen(true)}
          onConfirmationModalClose={() => setIsModalOpen(false)}
          timeout={5000}
        />
        <Table
          className="travel-arrangers__table"
          travelArrangers={travelArrangerSettings}
          isLoading={isLoading}
          displayColumns={displayColumns[type][state]}
          type={type}
          state={state}
          onReactivate={onReactivate}
          onDeactivate={onDeactivate}
          setConfirmationName={setConfirmationName}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
      <Pagination currentPage={page} totalPages={totalPages} onPaginationClick={updatePagination} />
    </div>
  );
};

TabContent.propTypes = {
  type: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

export default withQueryParamsProvider(TabContent);
