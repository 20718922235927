import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@wtag/rcl-icon';

import './styles.scss';

const ShowMore = props => {
  const { numberOfItemIsShowed, onShowMoreClick, numberOfItemsShouldBeIncreased } = props;

  return (
    <div className="col-12 col-bleed show-more">
      <div
        role="presentation"
        className="col-grid direction-row col-bleed align-center justify-center show-more__container"
        onClick={() => {
          onShowMoreClick(numberOfItemIsShowed + numberOfItemsShouldBeIncreased);
        }}
      >
        {I18n.t('admin.shared.action.show_more')}
        <div className="show-more__container-icon">
          <Icon name="iconDownChevron" size="tiny" />
        </div>
      </div>
    </div>
  );
};
ShowMore.defaultProps = {
  numberOfItemsShouldBeIncreased: 4
};

ShowMore.propTypes = {
  numberOfItemIsShowed: PropTypes.number.isRequired,
  onShowMoreClick: PropTypes.func.isRequired,
  numberOfItemsShouldBeIncreased: PropTypes.number,
};
export default ShowMore;
