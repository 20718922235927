import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import httpClient, { generateSourceToken, isCancelError } from 'agentHTTPClient';
import Pagination from 'sharedWebpack/Pagination';
import SortingPill from 'sharedWebpack/SortingPill';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import Table from './Table';

const InvoicesTab = ({ relatedModelId, route, displayColumns }) => {
  const [invoices, setInvoices] = useState([]);
  const [totalPages, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    q: withDefault(StringParam, ''),
    page: NumberParam,
    sortColumn: StringParam,
    sortDirection: StringParam,
  });
  const { q, page, sortColumn, sortDirection, tab } = query;
  const [localSearchQuery, setLocalSearchQuery] = React.useState(q);
  const componentMounted = useRef(false);

  const setInitialParams = (data, searchQuery) => {
    // We only set the params onces when there is nothing in the url
    if (!query.page) {
      setQuery(
        {
          tab: 'invoices',
          q: searchQuery,
          page: data.pagination.currentPage,
          sortColumn: data.sort.column,
          sortDirection: data.sort.direction,
        },
        // use replaceIn as we don't want another navigation to be added to the browser history
        'replaceIn',
      );
    }
  };

  const fetchOrders = args => {
    const { searchQuery, newPage, orderTab, column, direction, apiToken } = args;

    httpClient
      .get(
        route({
          q: searchQuery,
          page: newPage,
          column,
          tab: orderTab,
          direction,
          relatedModelId,
        }),
        {
          cancelToken: apiToken,
        },
      )
      .then(({ data }) => {
        if (componentMounted.current) {
          setIsLoading(false);
          setInvoices(data.invoices);
          setTotalPage(data.pagination.totalPages);
          setInitialParams(data, searchQuery);
        }
      })
      .catch(error => {
        if (isCancelError(error)) {
          return null;
        }

        throw error;
      });
  };

  const setSearchQueryInParams = useRef(debounce(500, value => setQuery({ q: value, page: 1 })))
    .current;

  const setSearchQuery = useCallback(value => {
    setIsLoading(true);
    // We keep a local state here as we don't want to do an AJAX call for each key the user presses
    // This allows us to keep the UI responsive and fetch new data with a delay
    setLocalSearchQuery(value);
    setSearchQueryInParams(value);
  }, []);

  const updateSorting = useCallback((column, direction) => {
    setIsLoading(true);
    setQuery({ sortColumn: column, sortDirection: direction });
  }, []);

  useEffect(() => {
    const getInvoiceApiToken = generateSourceToken();
    componentMounted.current = true;
    fetchOrders({
      searchQuery: q,
      newPage: page,
      orderTab: tab,
      column: sortColumn,
      direction: sortDirection,
      apiToken: getInvoiceApiToken.token,
    });
    setQuery({ page, tab: 'invoices' }, 'replaceIn');

    return () => {
      getInvoiceApiToken.cancel();
      componentMounted.current = false;
    };
  }, [q, page, tab, sortColumn, sortDirection]);

  return (
    <div className="organization-invoices-tab grid grid-gap-20 direction-row justify-space-between">
      <div className="col-xlg-8 order-2 order-xlg-1 organization-invoices-tab__sort-container">
        <div className="organization-invoices-tab__sort">
          <SortingPill
            size="small"
            name={I18n.t('admin.components.organizations.overview.tabs.invoices.table.invoice_id')}
            thisSortColumn="id"
            currentSortColumn={sortColumn}
            currentSortDirection={sortDirection}
            onChange={updateSorting}
          />
        </div>
        <div className="organization-invoices-tab__sort">
          <SortingPill
            size="small"
            name={I18n.t('admin.components.organizations.overview.tabs.invoices.table.order_id')}
            thisSortColumn="order_id"
            currentSortColumn={sortColumn}
            currentSortDirection={sortDirection}
            onChange={updateSorting}
          />
        </div>
        <div className="organization-invoices-tab__sort">
          <SortingPill
            size="small"
            name={I18n.t(
              'admin.components.organizations.overview.tabs.invoices.table.invoice_date',
            )}
            thisSortColumn="date"
            currentSortColumn={sortColumn}
            currentSortDirection={sortDirection}
            onChange={updateSorting}
          />
        </div>
        <div className="organization-invoices-tab__sort">
          <SortingPill
            size="small"
            name={I18n.t('admin.components.organizations.overview.tabs.invoices.table.due_date')}
            thisSortColumn="due_date"
            currentSortColumn={sortColumn}
            currentSortDirection={sortDirection}
            onChange={updateSorting}
          />
        </div>
      </div>
      <div className="col-xlg-4 order-1 order-xlg-2 organization-invoices-tab__search-container">
        <Input
          size="tiny"
          placeholder={I18n.t('admin.components.organizations.overview.tabs.invoices.search')}
          preIcon={<Icon name="search" />}
          value={localSearchQuery}
          onChange={setSearchQuery}
        />
      </div>
      <div className="order-3 organization-invoices-tab__collection col-12">
        <Table invoices={invoices} displayColumns={displayColumns} isLoading={isLoading} />
      </div>
      <div className="order-4 organization-invoices-tab__pagination col-12">
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPaginationClick={newPage => {
            setIsLoading(true);
            setQuery({ page: newPage });
          }}
        />
      </div>
    </div>
  );
};

InvoicesTab.defaultProps = {
  displayColumns: {
    id: true,
    orderId: true,
    travellers: true,
    invoiceDate: true,
    dueDate: true,
    amount: true,
    actions: true,
  },
};

InvoicesTab.propTypes = {
  relatedModelId: PropTypes.number.isRequired,
  route: PropTypes.func.isRequired,
  displayColumns: PropTypes.shape({
    id: PropTypes.bool,
    orderId: PropTypes.bool,
    travellers: PropTypes.bool,
    invoiceDate: PropTypes.bool,
    dueDate: PropTypes.bool,
    amount: PropTypes.bool,
    actions: PropTypes.bool,
  }),
};

export default withQueryParamsProvider(InvoicesTab);
