import React, { useState, useEffect, Fragment, useRef } from 'react';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import PropTypes from 'prop-types';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import fetchPhoneCountryCodes from 'sharedWebpack/fetchPhoneCountryCodes';
import EmailAddressCard from './EmailAddressCard';
import PhoneNumberCard from './PhoneNumberCard';
import EmailAddressForm from './EmailAddressForm';
import PhoneNumberForm from './PhoneNumberForm';

import './styles.scss';

const sortItems = (x, y) => Number(y.primary) - Number(x.primary);

const EmailAndPhoneSection = ({
  relatedInstance,
  fetchRelatedInstance,
  emailAddressTypes,
  phoneNumberTypes,
  createPhoneNumber,
  updatePhoneNumber,
  deletePhoneNumber,
  createEmailAddress,
  deleteEmailAddress,
  updateEmailAddress,
}) => {
  const emailAddressSection = useRef(null);
  const phoneNumberSection = useRef(null);
  const [phoneCountryCodes, setPhoneCountryCodes] = useState([]);
  const [showEmailAddress, setShowEmailAddress] = useState(false);
  const [showNumberAddress, setNumberAddress] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { id, emailAddresses, phoneNumbers } = relatedInstance;
  const hasEmailAndPhoneNumber = id && !isFetching;
  const hasEmailAdresses = hasEmailAndPhoneNumber && emailAddresses && emailAddresses.length > 0;
  const hasPhoneNumbers = hasEmailAndPhoneNumber && phoneNumbers && phoneNumbers.length > 0;

  if (Array.isArray(emailAddresses) && Array.isArray(phoneNumbers)) {
    phoneNumbers.sort(sortItems);
    emailAddresses.sort(sortItems);
  }

  const fetchData = () => {
    setShowEmailAddress(false);
    setNumberAddress(false);
    setIsFetching(true);
    fetchRelatedInstance();
  };

  const toggleShowEmailAdd = () => {
    setShowEmailAddress(prev => !prev);

    if (
      showEmailAddress === false &&
      emailAddresses.length > 3 &&
      emailAddressSection.current.lastChild
    ) {
      emailAddressSection.current.lastChild.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  const toggleNumberAdd = () => {
    setNumberAddress(prev => !prev);

    if (
      showNumberAddress === false &&
      phoneNumbers.length > 3 &&
      phoneNumberSection.current.lastChild
    ) {
      phoneNumberSection.current.lastChild.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const emailAddressTypeOptions = emailAddressTypes.map(value => ({
    value,
    label: I18n.t(value, {
      scope: 'email_addresses.types',
    }),
  }));

  const phoneNumberTypeOptions = phoneNumberTypes.map(value => ({
    value,
    label: I18n.t(value, {
      scope: 'phone_numbers.types',
    }),
  }));

  useEffect(() => {
    const getPhoneCountryCodes = async () => {
      setPhoneCountryCodes(await fetchPhoneCountryCodes());
    };

    getPhoneCountryCodes();
  }, []);

  useEffect(() => {
    setIsFetching(false);
  }, [relatedInstance]);

  return (
    <Fragment>
      <div className="col-grid col-bleed align-center edit-section" ref={emailAddressSection}>
        <div className="col-12 edit-email-and-phone">
          <div className="col-grid direction-row col-bleed align-center justify-space-between">
            <div className="edit-section__header">
              {I18n.t('admin.components.organizations.edit.email_and_phone.email.header')}

              {hasEmailAndPhoneNumber && !showEmailAddress && (
                <Button
                  version="v2"
                  size="small"
                  label={I18n.t(
                    'admin.components.organizations.edit.email_and_phone.phone.label.add',
                  )}
                  type="primary"
                  onClick={toggleShowEmailAdd}
                />
              )}
            </div>
          </div>

          {showEmailAddress && !hasEmailAdresses && (
            <EmailAddressForm
              fetchData={fetchData}
              createEmailAddress={createEmailAddress}
              emailAddressTypeOptions={emailAddressTypeOptions}
              onCloseForm={toggleShowEmailAdd}
            />
          )}
        </div>
        {hasEmailAndPhoneNumber ? (
          <Fragment>
            {hasEmailAdresses &&
              emailAddresses.map(emailAddress => (
                <EmailAddressCard
                  key={emailAddress.id}
                  emailAddress={emailAddress}
                  emailAddressTypeOptions={emailAddressTypeOptions}
                  fetchRelatedInstance={fetchData}
                  relatedInstance={relatedInstance}
                  updateEmailAddress={updateEmailAddress}
                  deleteEmailAddress={deleteEmailAddress}
                />
              ))}
            {hasEmailAdresses && showEmailAddress && (
              <Fragment>
                <div className="col-12 edit-email-and-phone">
                  <hr className="edit-email-and-phone__hr" />
                </div>
                <div className="col-12 edit-email-and-phone">
                  <div className="edit-email-and-phone__header">
                    {I18n.t('admin.components.organizations.edit.email_and_phone.email.add')}
                  </div>
                  <EmailAddressForm
                    fetchData={fetchData}
                    createEmailAddress={createEmailAddress}
                    emailAddressTypeOptions={emailAddressTypeOptions}
                    onCloseForm={toggleShowEmailAdd}
                  />
                </div>
              </Fragment>
            )}

            {!hasEmailAdresses && !showEmailAddress && (
              <div className="col-12 direction-row edit-email-and-phone edit-email-and-phone__placeholder">
                <Icon name="email" size="large" color="tertiary" showBGColor={true} />
                <div className="edit-email-and-phone__explanation edit-email-and-phone__explanation--with-padding">
                  {I18n.t('admin.components.organizations.edit.email_and_phone.email.no_email')}
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="col-12 edit-email-and-phone">
            <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
          </div>
        )}
      </div>
      <div className="col-grid col-bleed align-center edit-section" ref={phoneNumberSection}>
        <div className="col-12 edit-email-and-phone edit-email-and-phone">
          <div className="col-grid direction-row col-bleed align-center justify-space-between">
            <div className="edit-section__header">
              {I18n.t('admin.components.organizations.edit.email_and_phone.phone.header')}

              {hasEmailAndPhoneNumber && !showNumberAddress && (
                <Button
                  version="v2"
                  size="small"
                  label={I18n.t(
                    'admin.components.organizations.edit.email_and_phone.phone.label.add',
                  )}
                  type="primary"
                  onClick={toggleNumberAdd}
                />
              )}
            </div>
          </div>

          {showNumberAddress && !hasPhoneNumbers && (
            <PhoneNumberForm
              phoneNumberTypeOptions={phoneNumberTypeOptions}
              phoneCountryCodes={phoneCountryCodes}
              fetchData={fetchData}
              onCloseForm={toggleNumberAdd}
              createPhoneNumber={createPhoneNumber}
            />
          )}
        </div>
        {hasEmailAndPhoneNumber ? (
          <Fragment>
            {hasPhoneNumbers &&
              phoneNumbers.map(phoneNumber => (
                <PhoneNumberCard
                  key={phoneNumber.id}
                  phoneNumber={phoneNumber}
                  countryCodeOptions={phoneCountryCodes}
                  phoneNumberTypeOptions={phoneNumberTypeOptions}
                  fetchRelatedInstance={fetchData}
                  relatedInstance={relatedInstance}
                  updatePhoneNumber={updatePhoneNumber}
                  deletePhoneNumber={deletePhoneNumber}
                />
              ))}
            {hasPhoneNumbers && showNumberAddress && (
              <Fragment>
                <div className="col-12 edit-email-and-phone">
                  <hr className="edit-email-and-phone__hr" />
                </div>
                <div className="col-12 edit-email-and-phone">
                  <div className="edit-email-and-phone__header">
                    {I18n.t('admin.components.organizations.edit.email_and_phone.phone.add')}
                  </div>
                  <PhoneNumberForm
                    phoneNumberTypeOptions={phoneNumberTypeOptions}
                    phoneCountryCodes={phoneCountryCodes}
                    fetchData={fetchData}
                    onCloseForm={toggleNumberAdd}
                    createPhoneNumber={createPhoneNumber}
                  />
                </div>
              </Fragment>
            )}

            {!hasPhoneNumbers && !showNumberAddress && (
              <div className="col-12 direction-row edit-email-and-phone edit-email-and-phone__placeholder">
                <Icon name="call" size="large" color="tertiary" showBGColor={true} />
                <div className="edit-email-and-phone__explanation edit-email-and-phone__explanation--with-padding">
                  {I18n.t('admin.components.organizations.edit.email_and_phone.phone.no_phone')}
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="col-12 edit-email-and-phone">
            <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

EmailAndPhoneSection.propTypes = {
  relatedInstance: PropTypes.shape({
    id: PropTypes.number,
    emailAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        type: PropTypes.string,
        id: PropTypes.number,
        primary: PropTypes.bool,
      }),
    ),
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        countryCode: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.number,
        numberWithoutCountryCode: PropTypes.string,
        type: PropTypes.string,
        primary: PropTypes.bool,
      }),
    ),
  }).isRequired,
  fetchRelatedInstance: PropTypes.func.isRequired,
  emailAddressTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  phoneNumberTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  createPhoneNumber: PropTypes.func.isRequired,
  updatePhoneNumber: PropTypes.func.isRequired,
  deletePhoneNumber: PropTypes.func.isRequired,
  createEmailAddress: PropTypes.func.isRequired,
  deleteEmailAddress: PropTypes.func.isRequired,
  updateEmailAddress: PropTypes.func.isRequired,
};

export default EmailAndPhoneSection;
