import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import DateTime from 'sharedWebpack/DateTime';
import { DATE_FORMAT_SHORT_WITH_TIME } from 'sharedWebpack/helpers/dateTime';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import JourneySummaryIndicator from 'sharedWebpack/JourneySummaryIndicator';

const FlightInfoSummary = ({ segment, airlineMap, airportMap, flightNumber }) => {
  const { destinationCode, departureLocaltimeInUserTz, originCode, operatingCarrierCode } = segment;
  const originDetails = airportMap[originCode];
  const destinationDetails = airportMap[destinationCode];

  return (
    <Fragment>
      {airlineMap[operatingCarrierCode] ? (
        <div className="airplane-logo">
          <img
            className="flight-item-trip-airport-info__country-flag"
            src={airlineMap[operatingCarrierCode].logo}
            alt={airlineMap[operatingCarrierCode].name}
          />
        </div>
      ) : (
        <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
      )}
      {airportMap[originCode] && airportMap[destinationCode] ? (
        <Fragment>
          <JourneySummaryIndicator
            originCountryInfo={originDetails}
            destinationCountryInfo={destinationDetails}
          />

          <div className="flight-item-trip-airport-info-trip-place-summary">
            {I18n.t('components.ibe.flight_service_selection.service_header_text', {
              originPlaceName: originDetails.placeName,
              originCountryName: originDetails.countryName,
              destinationPlaceName: destinationDetails.placeName,
              destinationCountryName: destinationDetails.countryName,
            })}
          </div>
        </Fragment>
      ) : (
        <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} />
      )}
      {departureLocaltimeInUserTz ? (
        <div className="flight-item-trip-airport-info-trip-flight">
          <DateTime
            format={DATE_FORMAT_SHORT_WITH_TIME}
            dateTime={segment.departureLocaltimeInUserTz}
          />
          <span className="highlight">
            <I18nText id="ibe.search_form.flight.label.departure" returnStringOnly={true} />
          </span>
        </div>
      ) : (
        <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
      )}
      {flightNumber ? (
        <div className="flight-item-trip-airport-info-trip-flight">
          {flightNumber}
          <span className="highlight">
            <I18nText id="ibe.fare_basis_info.flight_number" returnStringOnly={true} />
          </span>
        </div>
      ) : (
        <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
      )}
    </Fragment>
  );
};

FlightInfoSummary.propTypes = {
  segment: PropTypes.shape({
    originCode: PropTypes.string.isRequired,
    operatingCarrierCode: PropTypes.string.isRequired,
    destinationCode: PropTypes.string.isRequired,
    departureLocaltimeInUserTz: PropTypes.string.isRequired,
  }).isRequired,
  airlineMap: PropTypes.shape({}).isRequired,
  airportMap: PropTypes.shape({}).isRequired,
  flightNumber: PropTypes.string.isRequired,
};

export default FlightInfoSummary;
