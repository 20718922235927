import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@wtag/rcl-icon';

const PillTabsList = ({ tripCount, trip, selectedFlightGroups }) => {
  const selectedTripGroup = selectedFlightGroups[tripCount + 1];

  return (
    <div className="pill-tabs__title">
      <span>
        {I18n.t('components.ibe.results.trip_title', {
          count: tripCount + 1,
          origin: trip.origin,
          destination: trip.destination,
        })}
      </span>
      <span
        className={classnames('icon-holder', {
          'icon-holder--selected': selectedTripGroup,
        })}
      >
        {selectedTripGroup && <Icon name="check" size="tiny" />}
      </span>
    </div>
  );
};

PillTabsList.propTypes = {
  tripCount: PropTypes.number.isRequired,
  trip: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
  }).isRequired,
  selectedFlightGroups: PropTypes.shape({}).isRequired,
};

export default PillTabsList;
