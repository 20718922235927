import {
  HOTEL_SEARCH_FETCHED,
  HOTEL_SEARCH_FILTERS_UPDATED,
  RESET_HOTEL_SEARCH_FILTERS,
  RESET_HOTEL_SEARCH_FILTERS_BY_KEY
} from '../../actions/hotel';

const initialState = () => ({
  rating: { min: null, max: null },
  price: { min: null, max: null },
  priceRanges: [],
  stars: [],
  refundable: [],
  sortBy: null,
});

function setFilterParams(state, action) {
  const newState = Object.assign({}, state);
  const searchId = action.searchId;

  if (!newState[searchId]) {
    newState[searchId] = initialState();
  }

  const { rating, price } = newState[action.searchId];
  let { priceRanges, stars, refundable, sortBy } = newState[action.searchId];

  if (rating.min === null) {
    rating.min = action.stats.rating.min;
  }

  if (rating.max === null) {
    rating.max = action.stats.rating.max;
  }

  if (price.min === null) {
    price.min = action.stats.price.min;
  }

  if (price.max === null) {
    price.max = action.stats.price.max;
  }

  if (!priceRanges || priceRanges.length === 0) {
    priceRanges = action.stats.priceRanges;
  }

  if (!stars.length) {
    stars = action.stats.stars;
  }

  if (!refundable.length) {
    refundable = action.stats.refundable;
  }

  if (sortBy === null) {
    sortBy = 'price_asc';
  }

  newState[action.searchId] = {
    price,
    priceRanges,
    rating,
    refundable,
    stars,
    sortBy,
  };
  return newState;
}

function searchFilterParamsByKey(state, action) {
  const initialData = action.stats.initialData

    const price = {
      min : initialData.min,
      max : initialData.max
    }
    const filteredState = {...state[action.searchId], price}
    const newFilterParams = {}
    newFilterParams[action.searchId] = filteredState
    return newFilterParams

}

function updateFilterParams(state, action) {
  const newState = Object.assign({}, state);
  newState[action.searchId] = {
    price: action.filters.price,
    priceRanges: action.filters.priceRanges,
    rating: action.filters.rating,
    refundable: action.filters.refundable,
    stars: action.filters.stars,
    sortBy: action.filters.sortBy,
  };
  return newState;
}

export default function searchFilterParamsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case HOTEL_SEARCH_FETCHED:
      return setFilterParams(newState, action);

    case HOTEL_SEARCH_FILTERS_UPDATED:
      return updateFilterParams(newState, action);

    case RESET_HOTEL_SEARCH_FILTERS:
      return setFilterParams({}, action);
    
    case RESET_HOTEL_SEARCH_FILTERS_BY_KEY:
      return searchFilterParamsByKey(newState, action);  

    default:
      return state;
  }
}
