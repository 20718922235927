import React from 'react';
import PropTypes from 'prop-types';
import { SidePanel, I18nText } from '@wtag/react-comp-lib';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';
import CarStandardView from './CarStandardView';
import CarCompactView from './CarCompactView';
import EmptyFilterAndResult from '../flight/SearchLoadingScreen/EmptyFilterAndResult';
import resultShape from './shapes/result';
import history from '../../lib/history';
import { parseQueryString } from '../../helpers/qsMethods';

import '../hotel/styles.scss';
import './styles.scss';
import FilterComponent from './Filter';
import { TYPE_STANDARD } from '../../../helpers/viewType';

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSidePanel: false,
      sidePanelTitle: null,
      sidePanelBody: null,
      numberOfResultsToShow: 10,
    };
    this.locationQuery = parseQueryString(props.location.search);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.locationQuery = parseQueryString(this.props.location.search);
    }
  }

  onShowMoreClick = () => {
    this.setState({ numberOfResultsToShow: this.state.numberOfResultsToShow + 10 });
  };

  setSidePanelBody = body => {
    this.setState({ sidePanelBody: body });
  };

  setSidePanelTitle = title => {
    this.setState({ sidePanelTitle: title });
  };

  setOpenSidePanel = () => {
    this.setState({ openSidePanel: true });
  };

  hideSidePanel = () => {
    this.setState({ openSidePanel: false });
  };

  render() {
    let filters = null;
    let content = null;

    if (this.props.searchYieldedResults) {
      filters = (
        <div className="results__filters">
          <FilterComponent {...this.props} />
        </div>
      );

      if (this.props.results.length) {
        const continueAction = (details, justification) => {
          if (this.locationQuery.dealGroup) {
            return this.props.selectInMultiSearch(this.locationQuery.dealGroup, details);
          }
          return this.props.addToCart(details, justification).then(cartItem => {
            history.push(`/carts/${cartItem.cartId}`);
          });
        };

        const CarView = this.props.viewMode === TYPE_STANDARD ? CarStandardView : CarCompactView;
        const results = this.props.results
          .slice(0, this.state.numberOfResultsToShow)
          .map(result => (
            <CarView
              details={result}
              key={result.id}
              onSelect={continueAction}
              isAlreadySelectedInCart={this.props.selectedItemResultIds.includes(result.id)}
              setSidePanelBody={this.setSidePanelBody}
              setSidePanelTitle={this.setSidePanelTitle}
              setOpenSidePanel={this.setOpenSidePanel}
              onCarSelect={this.props.onCarSelect}
            />
          ));
        content = (
          <div className="results__results">
            {results}
            <SidePanel
              title={this.state.sidePanelTitle}
              body={this.state.sidePanelBody}
              isOpened={this.state.openSidePanel}
              onClick={() => this.hideSidePanel()}
            />
            {this.props.results.length > this.state.numberOfResultsToShow && (
              <div className="col-12 col-bleed">
                <div
                  role="presentation"
                  className="col-grid direction-row col-bleed align-center justify-center car-result-card__show-more"
                  onClick={this.onShowMoreClick}
                >
                  <I18nText id="ibe.search_results.car.label.load_more" returnStringOnly={true} />
                </div>
              </div>
            )}
          </div>
        );
      } else {
        content = (
          <div className="results__results">
            <h1>
              <I18nText id="ibe.results.filter_exclude_everything" returnStringOnly={true} />
            </h1>
          </div>
        );
      }
    } else if (this.props.searchCompleted && !this.props.searchYieldedResults) {
      content = (
        <Card
          version="v2"
          size="full"
          color="tertiary"
          emptyCardImageSrc={<Icon name="car" />}
          emptyCardText={I18n.t('components.ibe.car_results.nothing_found.text')}
        />
      );
    }

    return (
      <div className="results car-results">
        {this.props.searchCompleted ? (
          <div className="grid car-results__container">
            <div className="col-12">
              {filters ? (
                <div className="col-xlg-4 col-lg-4 col-12 col-bleed car-results__filters">
                  {filters}
                </div>
              ) : null}
              <div className={classNames({ 'col-xlg-8 col-lg-8 col-12 col-bleed': filters })}>
                <div className="results__results">{content}</div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyFilterAndResult />
        )}
      </div>
    );
  }
}

Results.defaultProps = {
  searchYieldedResults: false,
  searchCompleted: false,
};

Results.propTypes = {
  fetchAirportDetails: PropTypes.func.isRequired,
  selectedItemResultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  results: PropTypes.arrayOf(resultShape).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  selectInMultiSearch: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  searchYieldedResults: PropTypes.bool,
  searchCompleted: PropTypes.bool,
  airports: PropTypes.shape().isRequired,
  onCarSelect: PropTypes.func.isRequired,
  searchInformation: PropTypes.shape({
    airportSearch: PropTypes.bool,
    driverAge: PropTypes.number,
    dropOffDate: PropTypes.string,
    dropOffGeoLocationAddress: PropTypes.string,
    dropOffGeoLocationLatitude: PropTypes.number,
    dropOffGeoLocationLongitude: PropTypes.number,
    dropOffLocationAirportCode: PropTypes.string,
    dropOffTime: PropTypes.string,
    pickUpDate: PropTypes.string,
    pickUpGeoLocationAddress: PropTypes.string,
    pickUpGeoLocationLatitude: PropTypes.number,
    pickUpGeoLocationLongitude: PropTypes.number,
    pickUpLocationAirportCode: PropTypes.string,
    pickUpTime: PropTypes.string,
  }).isRequired,
  viewMode: PropTypes.string.isRequired,
};

export default Results;
