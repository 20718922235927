import { FormatDateTime } from '../../../helpers/dateTime';

const formattedDateTime = date =>
  FormatDateTime({
    dateTime: date,
    format: 'shortWithTime',
    displayInUTC: true,
    displayWithoutConversion: false,
  });

export default formattedDateTime;
