import React from 'react';
import Amount from 'sharedWebpack/Amount';

import PropTypes from 'prop-types';

import '../styles.scss';

const TotalMarginBreakdown = ({ marginBreakdown, journeyElement, journeyElementType }) => {
  const marginBreakdowns =
    marginBreakdown &&
    marginBreakdown.length > 0 &&
    marginBreakdown.map(margin => (
      <div key={margin.id} className="margin__price-breakdown">
        {margin.name} <Amount currency={margin.currency} value={margin.total} />
      </div>
    ));
  return (
    <div className="margin__item-body-row">
      <div className="margin__trip-title">
        {journeyElementType === 'flight'
          ? `${journeyElement.originCode} - ${journeyElement.destinationCode}`
          : journeyElement.vendorName || journeyElement.name}
      </div>
      {marginBreakdowns}
    </div>
  );
};

TotalMarginBreakdown.propTypes = {
  marginBreakdown: PropTypes.shape({
    map: PropTypes.shape({}),
    length: PropTypes.number,
  }).isRequired,
  journeyElement: PropTypes.shape({
    originCode: PropTypes.string,
    destinationCode: PropTypes.string,
    vendorName: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  journeyElementType: PropTypes.string.isRequired,
};

export default TotalMarginBreakdown;
