import React from 'react';
import PropTypes from 'prop-types';
import { I18nText, Textarea } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import Tooltip from '@wtag/rcl-tooltip';
import Modal from 'sharedWebpack/Modal';
import './styles.scss';

class CartDeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  onModalOpen = () => {
    this.setState({ isModalVisible: true });
  };

  onModalClose = () => {
    this.setState({ isModalVisible: false });
  };

  setName = value => {
    this.props.setDealName(value);
  };

  setTeaser = event => {
    this.props.setDealTeaser(event.target.value);
  };

  createDeal = () => {
    this.props.saveDeal(this.context.callbackParams);
  };

  buttonType = () => {
    if (this.props.forceDeal) {
      return 'danger';
    }
    return 'primary';
  };

  render() {
    let renderOptions = {
      dealButton: this.props.hasAutomaticallyAddedStaticProducts ? (
        <Tooltip
          title={<I18nText id="ibe.cart_deal.invalid.header" returnStringOnly={true} />}
          content={<I18nText id="ibe.cart_deal.invalid.message" returnStringOnly={true} />}
          type="inverse"
          size="tiny"
          position="bottom-right"
        >
          <Button
            version="v2"
            type="primary"
            label={<I18nText id="ibe.cart_deal.deal.save" returnStringOnly={true} />}
            onClick={this.onModalOpen}
            disabled={true}
          />
        </Tooltip>
      ) : (
        <Button
          version="v2"
          type="primary"
          label={<I18nText id="ibe.cart_deal.deal.save" returnStringOnly={true} />}
          onClick={this.onModalOpen}
          disabled={!this.props.items || this.props.items.length < 1}
        />
      ),
      dealHeader: <I18nText id="ibe.cart_deal.deal.header" />,
    };
    if (this.context.offerOnly) {
      renderOptions = {
        dealButton: (
          <Button
            version="v2"
            type="primary"
            size="small"
            label={<I18nText id="ibe.cart_deal.offer.label" returnStringOnly={true} />}
            onClick={this.onModalOpen}
          />
        ),
        dealHeader: <I18nText id="ibe.cart_deal.offer.header" />,
      };
    }

    const { dealButton, dealHeader } = renderOptions;

    return (
      <div className="cart-deal">
        {!this.context.offerOnly ? (
          <div className="cart-deal__title">
            <h1>
              <I18nText id="ibe.cart_deal.deal.header" returnStringOnly={true} />
            </h1>
            <p>
              <I18nText id="ibe.cart_deal.deal.explanation" returnStringOnly={true} />
            </p>
          </div>
        ) : (
          <div className="cart-deal__title">
            <h1>
              <I18nText id="ibe.cart_deal.offer.header" returnStringOnly={true} />
            </h1>
          </div>
        )}

        <div className="cart-deal__deal-button">
          {dealButton}
          <Modal
            className="cart-deal__modal"
            title={
              <div className="cart-deal__modal--title">
                {dealHeader}
                <div onClick={this.onModalClose} onKeyDown={this.onModalClose} role="presentation">
                  <Icon name="close" />
                </div>
              </div>
            }
            footer={
              <Button
                version="v2"
                size="small"
                label={I18n.t('components.ibe.deal_messages.create')}
                onClick={this.createDeal}
                disabled={!this.props.dealSaveAvailable}
                type={this.buttonType()}
              />
            }
            isModalVisible={this.state.isModalVisible}
            onOutsideClick={this.onModalClose}
          >
            <div className="col-12 cart-deal__modal">
              <div className="cart-deal__modal__name-teaser">
                <Input
                  label={I18n.t('components.ibe.deal_messages.name')}
                  value={this.props.dealName}
                  onChange={this.setName}
                  touched={this.props.dealName !== ''}
                  size="tiny"
                  isClearable={true}
                />
                <Textarea
                  value={this.props.dealTeaser}
                  onChange={this.setTeaser}
                  label={I18n.t('components.ibe.deal_messages.teaser')}
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

CartDeal.propTypes = {
  dealName: PropTypes.string.isRequired,
  dealTeaser: PropTypes.string.isRequired,
  setDealName: PropTypes.func.isRequired,
  setDealTeaser: PropTypes.func.isRequired,
  dealSaveAvailable: PropTypes.bool.isRequired,
  saveDeal: PropTypes.func.isRequired,
  forceDeal: PropTypes.bool.isRequired,
  hasAutomaticallyAddedStaticProducts: PropTypes.bool.isRequired,
  items: PropTypes.shape([]).isRequired,
};

CartDeal.contextTypes = {
  callbackParams: PropTypes.shape().isRequired,
  offerOnly: PropTypes.bool.isRequired,
};

export default CartDeal;
