import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { Spinner } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import ExpiryFormHeader from '../ExpiryFormHeader';

const DocumentExpiryReminder = props => {
  const { traveller, saveReminder, isLoading } = props;
  const [documentExpiryReminderEnabled, setDocumentExpiryReminderEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onUpdateApprovalRequestReminder = async () => {
    const params = {
      document_expiry_reminder_enabled: documentExpiryReminderEnabled,
    };

    await saveReminder(params);
  };

  const onEnableReminder = () => {
    setIsModalOpen(documentExpiryReminderEnabled);
    setDocumentExpiryReminderEnabled(prevState => !prevState);
  };

  const onDisablingReminder = () => {
    setDocumentExpiryReminderEnabled(false);
    setIsModalOpen(false);
  };

  const setInitialValue = () => {
    setDocumentExpiryReminderEnabled(traveller.documentExpiryReminderEnabled);
  };

  useEffect(() => {
    if (traveller.id) {
      setInitialValue();
    }
  }, [traveller]);

  return (
    <div className="document-expiry-reminder">
      <ConfirmationModal
        confirmationHeader={I18n.t('components.personal_documents.confirmation.header')}
        subHeader={I18n.t('components.personal_documents.confirmation.text')}
        confirmationText={I18n.t('components.personal_documents.confirmation.action.confirm')}
        rejectionText={I18n.t('components.personal_documents.confirmation.action.reject')}
        onConfirm={onDisablingReminder}
        onReject={onEnableReminder}
        withAction={true}
        type="danger"
        isModalOpen={isModalOpen}
        onConfirmationModalOpen={() => setIsModalOpen(true)}
        onConfirmationModalClose={() => setIsModalOpen(false)}
      />
      <Card version="v2" className="document-expiry-reminder__container">
        {isLoading ? (
          <div className="document-expiry-reminder__container-loader">
            <Spinner size="huge" bgColor="neutral" />
          </div>
        ) : (
          <ExpiryFormHeader
            onChange={onEnableReminder}
            onSave={onUpdateApprovalRequestReminder}
            checked={documentExpiryReminderEnabled}
            placeholder={I18n.t('components.personal_documents.reminder.placeholder')}
            headerText={I18n.t('components.personal_documents.reminder.header')}
            headerName={I18n.t('components.personal_documents.header')}
          />
        )}
      </Card>
    </div>
  );
};

DocumentExpiryReminder.defaultProps = {
  traveller: {},
};

DocumentExpiryReminder.propTypes = {
  traveller: PropTypes.shape({
    id: PropTypes.string.isRequired,
    documentExpiryReminderEnabled: PropTypes.bool.isRequired,
  }),
  saveReminder: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DocumentExpiryReminder;
