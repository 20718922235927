import moment from 'moment';

const calculateDateDurations = (startDate, endDate, unit = 'years') => {
  const startDateTime = moment(startDate);
  const endDateTime = moment(endDate);

  if (!startDateTime.isValid() || !endDateTime.isValid()) return null;

  return endDateTime.diff(startDateTime, unit);
};

export default calculateDateDurations;
