import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import { Spinner } from '@wtag/react-comp-lib';
import prepareCartContent from '../../helpers/prepareCartContent';
import copyTextToClipboard from '../../helpers/copyTextToClipboard';
import itemShape from '../../../../../admin/Orders/Show/ItemsTab/SetPaymentInformationSidePanel/shapes';
import './styles.scss';

const CopyButton = ({ item, journeyElement, travelers, flightServicesByResultId, isDisabled }) => {
  const [isContentCopying, setIsContentCopying] = useState(false);
  const [contentCopiedToClipboard, setContentCopiedToClipboard] = useState(false);
  const disclaimerText = `\n---\n${I18n.t('components.ibe.search_results.disclaimer_text')}`;

  const onCopyItemContent = () => {
    setIsContentCopying(true);

    let content = prepareCartContent(item, journeyElement, travelers, flightServicesByResultId);

    content += disclaimerText;

    copyTextToClipboard(content);
  };

  useEffect(() => {
    let timeoutId;

    if (isContentCopying) {
      timeoutId = setTimeout(() => {
        setIsContentCopying(false);
        setContentCopiedToClipboard(true);
      }, 1000);
    } else if (contentCopiedToClipboard) {
      timeoutId = setTimeout(() => {
        setContentCopiedToClipboard(false);
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [isContentCopying, contentCopiedToClipboard]);

  return (
    <Fragment>
      {isContentCopying && (
        <div className="item-labels__copy-spinner">
          <Spinner size="xs" bgColor="neutral" />
        </div>
      )}
      {contentCopiedToClipboard && (
        <Icon className="item-labels__copy-icon--checked" name="checkCircle" />
      )}
      {!isContentCopying && !contentCopiedToClipboard && (
        <IconButton
          className="item-labels__copy-button"
          isIconOnly={true}
          icon={<Icon name="copy" size="tiny" />}
          color="tertiary"
          onClick={onCopyItemContent}
          disabled={isDisabled}
          label={I18n.t('components.ibe.cart.item_copy')}
        />
      )}
    </Fragment>
  );
};

CopyButton.defaultProps = {
  isDisabled: false,
};

CopyButton.propTypes = {
  isDisabled: PropTypes.bool,
  item: PropTypes.shape(itemShape).isRequired,
  journeyElement: PropTypes.shape({}).isRequired,
  travelers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flightServicesByResultId: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CopyButton;
