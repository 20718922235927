import { updateErrorAlertByToken, updateSuccessAlertByToken } from './alert';
import {
  updatePrimaryButtonText,
  togglePrimaryButtonDisabled,
  updateOnPrimaryButtonClickCallback,
} from './primaryButton';
import { resetButtonAndAlertStates } from './resetButtonAndAlertStates';
import { updateCurrentAuthenticationRequest } from '.';
import { generateCCPanelKey } from '../helpers/ccvPayment';
import {
  NEXT_BUTTON_LABEL_KEY,
  TRY_AGAIN_BUTTON_LABEL_KEY,
  AUTHENTICATE_NEXT_CARD_BUTTON_LABEL_KEY,
} from '../helpers/authenticationButtonLabelOptions';

export const UPDATE_THREE_DS_STATUSES_BY_TOKEN = 'UPDATE_THREE_DS_STATUSES_BY_TOKEN';
export const RESET_THREE_DS_STATUSES_BY_TOKEN = 'RESET_THREE_DS_STATUSES_BY_TOKEN';
export const UPDATE_STRINGIFIED_THREE_DS_STATUSES = 'UPDATE_STRINGIFIED_THREE_DS_STATUSES';

export const resetThreeDsStatuseByToken = () => ({
  type: RESET_THREE_DS_STATUSES_BY_TOKEN,
});

export const updateThreeDsStatusesByToken = encodedData => ({
  type: UPDATE_THREE_DS_STATUSES_BY_TOKEN,
  encodedData,
});

export const updateStringifiedThreeDsStatuses = newState => ({
  type: UPDATE_STRINGIFIED_THREE_DS_STATUSES,
  newState,
});

export const onUpdateThreeDsStatusesByToken = ({
  usableUniqueCCTokens,
  requestsToAuthenticateByToken,
  bookingComponentRefByToken,
  indexOfCurrentStatus,
  showCreditCardPanel,
  encodedData,
  triggerCreditCardItemsThreeDs,
  onThreeDsAuthenticationCompletion,
}) => (dispatch, getState) => {
  dispatch(updateThreeDsStatusesByToken(encodedData));

  const { ccToken, hasPassed } = encodedData;
  const isLastReqOfCurrentToken =
    indexOfCurrentStatus + 1 === requestsToAuthenticateByToken[ccToken].length;

  if (isLastReqOfCurrentToken || !hasPassed) {
    if (!hasPassed) {
      // if it does not pass we set the error alert to be shown in the component,
      // stop running checks and present the user with retry option
      dispatch(updateErrorAlertByToken(ccToken));
      dispatch(updatePrimaryButtonText(TRY_AGAIN_BUTTON_LABEL_KEY));
      dispatch(togglePrimaryButtonDisabled(false));
      dispatch(
        updateOnPrimaryButtonClickCallback(() => {
          triggerCreditCardItemsThreeDs();
        }),
      );
    }

    if (isLastReqOfCurrentToken && hasPassed) {
      dispatch(updateSuccessAlertByToken(ccToken));
      // if it is the last req of the current iframe and it passed,
      // we want to move to the next iframe. For that we need to look into
      // requestsToAuthenticateByToken. We will delete the current ccToken index
      // because that has already run its course otherwise we would not get inside this block
      const copiedObject = Object.assign({}, requestsToAuthenticateByToken);
      // remove the current token property as we just finished running reqs from this
      delete copiedObject[ccToken];
      const nextToken = Object.keys(copiedObject).find(
        token => copiedObject[token] && copiedObject[token].length > 0,
      );
      // even though we get the nextToken from looking into requestsToAuthenticateByToken
      // we will get the side panel index query by finding the index of that token
      // from uniqueCCTokens. To keep things simple to understand,
      // 1: we will find side panels by the index of tokens inside the uniqueCCTokens.
      // exposing the token in the query would be easier but we don't want to expose this
      // information in the browser url
      // 2: we will get the next token from requestsToAuthenticateByToken
      // 3: and we can just get the ref from bookingComponentRefByToken
      const nextTokenIndex = usableUniqueCCTokens.indexOf(nextToken);
      const nextTokenExists = nextTokenIndex > -1;

      if (nextTokenExists) {
        const queryForNextToken = generateCCPanelKey(nextTokenIndex);
        dispatch(updatePrimaryButtonText(AUTHENTICATE_NEXT_CARD_BUTTON_LABEL_KEY));
        dispatch(togglePrimaryButtonDisabled(false));
        dispatch(
          updateOnPrimaryButtonClickCallback(() => {
            dispatch(
              updateCurrentAuthenticationRequest(requestsToAuthenticateByToken[nextToken][0]),
            );
            showCreditCardPanel(queryForNextToken);
            bookingComponentRefByToken[nextToken].current.initiateThreeDSAuthentication(
              requestsToAuthenticateByToken[nextToken][0],
            );
            dispatch(resetButtonAndAlertStates());
          }),
        );
      } else {
        // we will enter this block when we have run out of iframes
        // that need to run three ds checks. So we close the close the panel
        // and call it a day
        dispatch(resetButtonAndAlertStates());
        onThreeDsAuthenticationCompletion();
      }
    }
  } else if (indexOfCurrentStatus + 1 < requestsToAuthenticateByToken[ccToken].length) {
    // we enter this block when we are not finished running three ds checks
    // through all the requests of the current iframe
    dispatch(updateSuccessAlertByToken(ccToken));
    dispatch(updatePrimaryButtonText(NEXT_BUTTON_LABEL_KEY));
    dispatch(togglePrimaryButtonDisabled(false));
    dispatch(
      updateOnPrimaryButtonClickCallback(() => {
        dispatch(
          updateCurrentAuthenticationRequest(
            requestsToAuthenticateByToken[ccToken][indexOfCurrentStatus + 1],
          ),
        );
        bookingComponentRefByToken[ccToken].current.initiateThreeDSAuthentication(
          requestsToAuthenticateByToken[ccToken][indexOfCurrentStatus + 1],
        );
        dispatch(resetButtonAndAlertStates());
      }),
    );
  }

  const currentThreeDsStatusesByToken = getState().ccvPayment.threeDsStatusesByToken;

  dispatch(updateStringifiedThreeDsStatuses(currentThreeDsStatusesByToken));
};
