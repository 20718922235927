import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { AGENCY, AGENCY_NOTIFICATIONS } from './categories';

const ActiveRoomEmptyPlaceholder = props => {
  const { type, title, laymanMode } = props;
  let emptyChatRoomPlaceholderData;

  const channelName = laymanMode && type === AGENCY ? 'agencyChannel' : type;
  const iconName = type === AGENCY_NOTIFICATIONS ? 'notification' : 'emptyChat';

  switch (channelName) {
    case 'agencyChannel':
      emptyChatRoomPlaceholderData = {
        header: I18n.t('chat.empty_card.agency.header'),
        message: I18n.t('chat.empty_card.agency.text'),
      };
      break;
    case AGENCY_NOTIFICATIONS:
      emptyChatRoomPlaceholderData = {
        header: I18n.t('chat.empty_card.notifications.header'),
        message: '',
      };
      break;
    default:
      emptyChatRoomPlaceholderData = {
        header: I18n.t('chat.empty_card.travel_arranger.header'),
        message: I18n.t('chat.empty_card.travel_arranger.text', {
          user: title,
        }),
      };
  }

  const { header, message } = emptyChatRoomPlaceholderData;

  return (
    <div className="chat__empty-card">
      <Icon
        className={`chat__empty-card--${iconName}`}
        name={iconName}
        size="xxl"
        showBGColor={true}
        color="tertiary"
      />
      <div className="chat__empty-card-placeholder">
        <div className="chat__empty-card-placeholder-header">{header}</div>
        <div className="chat__empty-card-placeholder-text">{message}</div>
      </div>
    </div>
  );
};

ActiveRoomEmptyPlaceholder.propTypes = {
  laymanMode: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ActiveRoomEmptyPlaceholder;
