import { ONEWAY, ROUNDTRIP, MULTICITY } from './tripTypes';

const getTripType = trips => {
  if (!Array.isArray(trips)) {
    return null;
  }

  if (trips.length === 1) {
    return ONEWAY;
  }

  if (
    trips.length === 2 &&
    trips[0].originCode === trips[1].destinationCode &&
    trips[1].originCode === trips[0].destinationCode
  ) {
    return ROUNDTRIP;
  }

  return MULTICITY;
};

export default getTripType;
