const messages = id =>
  I18n.t(id, {
    scope: 'components.ibe.traveler_details.meal',
  });

export default function mealRequestsBuilder(mealCodes) {
  const mealRequestOptions = [];
  if (mealCodes && mealCodes.length > 0) {
    mealCodes.forEach(code => {
      mealRequestOptions.push({ value: code, label: messages(code) });
    });
  }

  return mealRequestOptions;
}
