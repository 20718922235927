import React from 'react';
import PropTypes from 'prop-types';
import { PhoneNumber } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';

const EmergencyContactForm = props => {
  const {
    phoneCountryCodeOptions,
    phoneCountryCode,
    number,
    firstName,
    lastName,
    setPhoneCountryCode,
    setNumber,
    setFirstName,
    setLastName,
    error,
  } = props;

  const countryCodeOptions = phoneCountryCodeOptions.map(({ label, value }) => ({
    value,
    label,
  }));

  return (
    <div className="col-12 traveller-edit-emergency-contact-form">
      <div className="grid">
        <div className="col-sm-6">
          <Input
            size="tiny"
            placeholder={I18n.t(
              'admin.components.travellers.edit.emergency_contacts.placeholders.first_name',
            )}
            label={I18n.t('admin.components.travellers.edit.emergency_contacts.labels.first_name')}
            touched={true}
            error={error.firstName}
            value={firstName}
            onChange={value => setFirstName(value)}
            required={true}
          />
        </div>
        <div className="col-sm-6">
          <Input
            size="tiny"
            placeholder={I18n.t(
              'admin.components.travellers.edit.emergency_contacts.placeholders.last_name',
            )}
            label={I18n.t('admin.components.travellers.edit.emergency_contacts.labels.last_name')}
            touched={true}
            error={error.lastName}
            value={lastName}
            onChange={value => setLastName(value)}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <PhoneNumber
            options={countryCodeOptions}
            size="tiny"
            label={
              <span className="required-field">
                {I18n.t(
                  'admin.components.organizations.edit.email_and_phone.phone.label.phone_number',
                )}
              </span>
            }
            onChange={input => setNumber(input)}
            onCountryChange={input => setPhoneCountryCode(input.value)}
            inputValue={number || ''}
            selectedCountry={
              (phoneCountryCode &&
                countryCodeOptions.find(countryCode => countryCode.value === phoneCountryCode)) ||
              null
            }
            error={error.number}
            isInputTouched={error.number !== null}
            countryPlaceholder="(+41)"
          />
        </div>
      </div>
    </div>
  );
};

EmergencyContactForm.defaultProps = {
  error: {},
  phoneCountryCode: '',
  number: '',
  lastName: '',
  firstName: '',
};

EmergencyContactForm.propTypes = {
  phoneCountryCode: PropTypes.string,
  number: PropTypes.string,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  setPhoneCountryCode: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  phoneCountryCodeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  error: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneCountryCodeOptions: PropTypes.string,
    number: PropTypes.string,
  }),
};

export default EmergencyContactForm;
