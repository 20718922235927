import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Badge from '@wtag/rcl-badge';

const ShoppingCartIconWithBadge = ({ cartId, cartItemsCount }) => (
  <Fragment>
    <Icon name="shoppingCart" size="normal" />
    {cartId && cartItemsCount > 0 && (
      <Badge
        label={cartItemsCount}
        size="small"
        type="active"
        classNameForBadge="search__cart-button"
      />
    )}
  </Fragment>
);

ShoppingCartIconWithBadge.defaultProps = {
  cartId: null,
  cartItemsCount: 0,
};

ShoppingCartIconWithBadge.propTypes = {
  cartId: PropTypes.string,
  cartItemsCount: PropTypes.number,
};

export default ShoppingCartIconWithBadge;
