export const FLIGHT_GROUP_DESELECTED = 'FLIGHT_GROUP_DESELECTED';
export const FLIGHT_GROUP_DESELECTED_ALL = 'FLIGHT_GROUP_DESELECTED_ALL';

export function deselectGroup(groupId) {
  return dispatch => {
    dispatch({
      type: FLIGHT_GROUP_DESELECTED,
      groupId,
    });
  };
}

export function deselectAllGroups() {
  return dispatch => {
    dispatch({
      type: FLIGHT_GROUP_DESELECTED_ALL,
    });
  };
}
