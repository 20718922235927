import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag, Link } from '@wtag/react-comp-lib';
import { FormatDateTime, DATE_FORMAT_SHORT } from 'sharedWebpack/helpers/dateTime';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';
import Icon from '@wtag/rcl-icon';
import { Header } from '@wtag/rcl-typography';
import OrderMapWrapper from 'sharedWebpack/OrderMapWrapper';

const CurrentTravel = ({ order }) => (
  <div className="col-grid col-bleed direction-row current-travel wrap">
    <div className="col-lg-4 col-bleed current-travel-map">
      <OrderMapWrapper orderId={order.id} layman={true} />
    </div>
    <div className="col-lg-8 col-bleed current-travel-details">
      <EmphasisTag
        text={I18n.t('public.components.orders.currently_on_travel')}
        size="tiny"
        type="success"
        icon={<Icon name="aeroplane" />}
        iconColor="inverse"
      />

      <div className="current-travel-details-title">
        <Header level={5} weight="medium">
          {order.title}
        </Header>
      </div>

      <div className="col-grid col-bleed direction-row current-travel-details-time-link wrap">
        <div className="col-lg-6 col-bleed current-travel-details-time">
          <div className="col-grid col-bleed direction-row align-center">
            <span className="current-travel-details-indicator-dot" />
            <div className="current-travel-details-time-start">
              <DateTimeLocaleInterpolationWrapper
                localeKey="public.components.dashboard.travel_overview.started_at"
                time={FormatDateTime({ dateTime: order.startDate, format: DATE_FORMAT_SHORT })}
              />
            </div>
          </div>
          <div className="current-travel-details-vertical-line" />
          <div className="col-grid col-bleed direction-row align-center">
            <span className="current-travel-details-indicator-dot current-travel-details-indicator-dot--end" />
            <div className="current-travel-details-time-end">
              <DateTimeLocaleInterpolationWrapper
                localeKey="public.components.dashboard.travel_overview.ends_at"
                time={FormatDateTime({ dateTime: order.endDate, format: DATE_FORMAT_SHORT })}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-grid col-bleed align-end justify-end current-travel-details-link">
          <Link type="button" modifier="default" size="small" href={order.urls.view}>
            <div className="current-travel-details-link-text">
              {I18n.t('public.components.dashboard.travel_overview.order_id', { id: order.id })}
            </div>
            <div className="current-travel-details-link-icon">
              <Icon name="externalLink" size="tiny" />
            </div>
            <div className="current-travel-details-link-text current-travel-details-link-text--bold">
              {I18n.t('public.components.orders.actions.view_order')}
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

CurrentTravel.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    urls: PropTypes.shape({
      view: PropTypes.string.isRequired,
    }),
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
};

export default CurrentTravel;
