const getServicesWithQuantities = (services, subItems = []) => {
  if (!Array.isArray(services)) {
    return [];
  }

  const servicesWithQuantities = services.map(service => {
    const targetedSubItem = subItems.find(
      subItem => subItem.bookingAttributes.serviceId === service.id,
    );
    const quantity = targetedSubItem ? Number(targetedSubItem.quantity) : 0;

    return {
      ...service,
      quantity,
    };
  });

  return servicesWithQuantities.filter(item => item.quantity > 0);
};

export default getServicesWithQuantities;
