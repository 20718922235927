import React, { useEffect, useState } from 'react';
import Card from '@wtag/rcl-card';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import PropTypes from 'prop-types';
import Deal from './DealsCard';

const TopDeals = ({ showHeader }) => {
  const [topDeals, setTopDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTopDeals = async () => {
    setIsLoading(true);
    const { data } = await httpClient.get(routes.public.topDeals());
    setTopDeals(data.deals);
    setIsLoading(false);
  };

  const addToFavourite = async id => {
    const { data } = await httpClient.put(routes.api.deals.favourite({ dealId: id }));

    if (data.id) {
      fetchTopDeals();
    }
  };

  const deleteFromFavourite = async id => {
    const { data } = await httpClient.delete(routes.api.deals.favourite({ dealId: id }));

    if (data.id) {
      fetchTopDeals();
    }
  };

  useEffect(() => {
    fetchTopDeals();
  }, []);

  const dealShowPageUrl = id => {
    const url = routes.public.deal;
    return url({
      id,
    });
  };

  return (
    <div className="top-deals">
      {showHeader && (
        <div className="col-grid col-bleed-x top-deals__header">
          {I18n.t('public.homepage.top_deals')}
        </div>
      )}
      {isLoading && !topDeals.length > 0 && (
        <div className="col-grid col-bleed align-center top-deals__content-loader">
          <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} />
        </div>
      )}
      {!isLoading && !topDeals.length > 0 && (
        <Card
          version="v2"
          emptyCardText={I18n.t('public.components.mid_section.deal.no_top_deal')}
        />
      )}
      {topDeals.length > 0 && (
        <div className="col-bleed">
          {topDeals.map(deal => (
            <div className="col-12 col-bleed top-deals__card-information">
              <Deal
                key={deal.id}
                deleteFromFavourite={value => deleteFromFavourite(value)}
                id={deal.id}
                name={deal.name}
                amount={deal.adAmount}
                currency={deal.currency}
                imageUrl={deal.imageUrl}
                detailsUrl={dealShowPageUrl(deal.id)}
                addToFavourite={value => addToFavourite(value)}
                favourite={deal.favourite}
                teaser={deal.teaser}
                itemTypes={deal.itemTypes}
                currentUserId={deal.currentUserId}
                favouriteCount={deal.favouriteCount}
                scope={{ showFavouriteCount: true }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

TopDeals.defaultProps = {
  showHeader: true,
};

TopDeals.propTypes = {
  showHeader: PropTypes.bool,
};

export default TopDeals;
