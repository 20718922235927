import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';

const AirlineSummary = ({ codes }) =>
  codes.map(item => (
    <div
      key={item.code}
      className="d-flex align-center flight__airline-summary--segment flex-column-gap-4"
    >
      <Icon name="aeroplane" />
      <span>{item.code}</span>
    </div>
  ));

AirlineSummary.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string })).isRequired,
};

export default AirlineSummary;
