/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import formattedAmount from './formattedAmount';

function Hotel(props) {
  const { price, duration, currency, rooms } = props;

  return (
    <div className="cart-summary__accordion-sub-item-details-category">
      {rooms.map(room => (
        <div className="cart-summary__accordion-sub-item-details-category-hotel">
          <span>{room.name}</span>
          <span>
            {formattedAmount(price / duration, currency)}{' '}
            {I18n.t('components.ibe.summary.per_day_avg')}
          </span>
        </div>
      ))}
    </div>
  );
}

Hotel.defaultProps = {
  duration: 1,
  rooms: [],
};

Hotel.propTypes = {
  currency: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  duration: PropTypes.number,
  rooms: PropTypes.arrayOf(PropTypes.shape()),
};

export default Hotel;
