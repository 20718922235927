import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

const MoreFareBrandsLabel = ({ className, count }) => (
  <span className={ClassNames('flight__prices__fare-packages--more-options', className)}>
    {I18n.t('components.ibe.results.more_options', {
      count,
    })}
  </span>
);

MoreFareBrandsLabel.defaultProps = {
  className: null,
};

MoreFareBrandsLabel.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
};

export default MoreFareBrandsLabel;
