import React, { Fragment } from 'react';
import Popover from '@wtag/rcl-popover';
import { EmphasisTag } from '@wtag/react-comp-lib';
import { TYPE_SEAT } from 'sharedWebpack/helpers/itemTypes';
import Icon from '@wtag/rcl-icon';
import PropTypes from 'prop-types';
import getAncillaryIconName from '../helpers/getAncillaryIconName';
import MAX_ANCILLARIES_TO_SHOW from '../helpers/constants';

const AncillaryPopover = ({ isVisible, type, content, onPopoverClose, onClick, onKeyDown }) => {
  const totalItems = content.length;
  const hiddenElementsCount = totalItems - MAX_ANCILLARIES_TO_SHOW;
  const hiddenElementsText = `+ ${hiddenElementsCount}`;

  const popoverContent = content.map(({ status, title }) => (
    <EmphasisTag
      className="ancillary-popover-item"
      key={title}
      text={
        <Icon
          className="ancillary-popover-item__icon"
          name={getAncillaryIconName(type)}
          size="tiny"
          color={status}
          showLabel={true}
          iconLabel={title}
        />
      }
      type={status}
      size="tiny"
    />
  ));

  const header =
    type === TYPE_SEAT
      ? I18n.t('components.ibe.cart_item.flight.seats.popup_title', { count: totalItems })
      : I18n.t('components.ibe.cart_item.flight.ancillary.popup_title', { count: totalItems });

  return (
    <Popover
      className="ancillary-popover"
      isVisible={isVisible}
      direction="bottom"
      size="medium"
      content={
        <Fragment>
          <h4 className="ancillary-popover__title">{header}</h4>
          {popoverContent}
        </Fragment>
      }
      onOutsideClick={onPopoverClose}
    >
      {hiddenElementsCount > 0 && (
        <span
          role="button"
          className="flight-item__number-of-visible-tags"
          tabIndex={0}
          onClick={onClick}
          onKeyDown={onKeyDown}
        >
          {hiddenElementsText}
        </span>
      )}
    </Popover>
  );
};

AncillaryPopover.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  onPopoverClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

export default AncillaryPopover;
