import React, { useState, useMemo, Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Header } from '@wtag/rcl-typography';
import { Link } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Card from '@wtag/rcl-card';

import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import debounce from 'throttle-debounce/debounce';
import ShowMore from 'sharedWebpack/ShowMore';

import StaticProductCard from './StaticProductCard';
import staticProductShape from '../shapes/staticProduct';
import useSearchFormContainer from '../../../helpers/hooks/useSearchFormContainer';

const THREE_PRODUCT_LENGTH = 3;

const StaticProducts = props => {
  const {
    products,
    addToCart,
    setAdvancedForm,
    advancedForm,
    laymanMode,
    isFetchingProducts,
    setOnSearchStarted,
    callbackParams,
  } = props;

  const [query, setQuery] = useState('');
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(4);
  const [isLoading, setIsLoading] = useState(true);
  const [productsList, setProductsList] = useState([]);
  const showStaticProductFormContainer = useSearchFormContainer();
  const [showAdvancedForm, setShowAdvancedForm] = useState(false);
  const [showSimpleForm, setShowSimpleForm] = useState(true);

  useEffect(() => {
    if (advancedForm) {
      setTimeout(() => {
        setShowAdvancedForm(true);
        setShowSimpleForm(false);
      }, 320);
    } else if (!advancedForm) {
      setTimeout(() => {
        setShowAdvancedForm(false);
        setShowSimpleForm(true);
      }, 320);
    }
  });

  const fetchProducts = async () => {
    const { data } = await httpClient.get(routes.api.fetchStaticProducts({ q: query }));

    setProductsList(data);
    setIsLoading(false);
  };

  const setSearchQueryInParams = useRef(debounce(500, value => setQuery(value))).current;

  const setSearchQuery = value => {
    setLocalSearchQuery(value);
    setSearchQueryInParams(value);
  };

  useEffect(() => {
    if (query) {
      fetchProducts();
    } else {
      setProductsList(products);
    }
  }, [query]);

  useEffect(() => {
    setIsLoading(isFetchingProducts);
    setProductsList(products);
  }, [products, isFetchingProducts]);

  const inputSection = advancedForm && (
    <div className="grid grid-gap-20 static-products__static-form static-products__static-form--with-padding-top-bottom-only">
      <div className="col-grid col-xlg-8 col-lg-8 col-md-8 col-sm-6 col-xs-12 col-xxs-12 col-bleed-y justify-center align-start">
        <Header weight="medium" level={5}>
          {I18n.t('components.ibe.search_form.static.available', {
            available: productsList.length,
          })}
        </Header>
      </div>

      <div className="col-grid col-xlg-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 col-xxs-12 justify-center">
        <Input
          isClearable={true}
          value={localSearchQuery}
          onChange={setSearchQuery}
          size="tiny"
          postIcon={!query && <Icon name="search" size="small" />}
          placeholder={I18n.t('components.ibe.search_form.static.search_placeholder')}
        />
      </div>
    </div>
  );

  const actionBars = laymanMode && (
    <div
      className={classNames('grid col-12 grid-gap-20 static-products__footer', {
        'grid col-12 grid-gap-20 static-products__footer--right': props.advancedForm,
      })}
    >
      {!props.advancedForm && productsList.length > 0 && (
        <div className="static-products__footer-count">
          {I18n.t('components.ibe.search_form.static.available', {
            available: productsList.length,
          })}
        </div>
      )}

      <div
        className={classNames('static-products__action-bars', {
          'static-products__action-bars--simple': !props.advancedForm,
        })}
      >
        {productsList.length > 2 && (
          <Link
            onClick={() => setAdvancedForm(prevState => !prevState)}
            type="default"
            modifier="default"
          >
            <div className="static-products__footer-link">
              <div className="static-products__advanced-search-button-text">
                {advancedForm
                  ? I18n.t('components.ibe.search_form.static.hide')
                  : I18n.t('components.ibe.search_form.static.see')}
              </div>
              <Icon name={advancedForm ? 'arrowBack' : 'arrowForward'} size="tiny" />
            </div>
          </Link>
        )}
      </div>
    </div>
  );

  const staticProductsList = useMemo(
    () =>
      laymanMode && !advancedForm
        ? productsList
            .slice(0, 2)
            .map(product => (
              <StaticProductCard
                key={product.id}
                details={product}
                addToCart={addToCart}
                advancedForm={advancedForm}
                laymanMode={laymanMode}
                setOnSearchStarted={setOnSearchStarted}
                callbackParams={callbackParams}
              />
            ))
        : productsList
            .slice(0, numberOfItemsToShow)
            .map(product => (
              <StaticProductCard
                key={product.id}
                details={product}
                addToCart={addToCart}
                advancedForm={advancedForm}
                laymanMode={laymanMode}
                setOnSearchStarted={setOnSearchStarted}
                callbackParams={callbackParams}
              />
            )),
    [productsList, numberOfItemsToShow, advancedForm],
  );

  const noProducts = (
    <div className="col-grid col-bleed">
      <Card
        version="v2"
        className="empty-card-placeholder"
        color="tertiary"
        emptyCardImageSrc={<Icon name="equipment" />}
        emptyCardText={I18n.t('components.ibe.search_form.static.no_products')}
      />
    </div>
  );

  return (
    <Fragment>
      {isLoading ? (
        <ContentLoaderPlaceholder numberOfLines={3} isRounded={true} showBackground={true} />
      ) : (
        <Fragment>
          {inputSection}
          <div
            className={classNames('static-products__form', {
              'static-products__form--show': showStaticProductFormContainer,
            })}
          >
            <div
              className={classNames('static-products__container', {
                'static-products__card': advancedForm,
                'static-products__card--show': showAdvancedForm,
                'static-products__card-simple': !advancedForm && !showSimpleForm,
                'static-products__card-simple--show': !advancedForm && showSimpleForm,
                'static-products__container--layman': laymanMode,
                'static-products__card-advanced':
                  advancedForm && laymanMode && productsList.length >= THREE_PRODUCT_LENGTH,
              })}
            >
              {productsList.length > 0 ? staticProductsList : noProducts}
            </div>

            {actionBars}
          </div>
        </Fragment>
      )}

      {!isLoading && advancedForm && productsList.length > numberOfItemsToShow && (
        <div className="history__footer-show-more">
          <ShowMore
            numberOfItemIsShowed={numberOfItemsToShow}
            onShowMoreClick={setNumberOfItemsToShow}
          />
        </div>
      )}
    </Fragment>
  );
};

StaticProducts.propTypes = {
  products: PropTypes.arrayOf(staticProductShape).isRequired,
  addToCart: PropTypes.func.isRequired,
  setAdvancedForm: PropTypes.func.isRequired,
  advancedForm: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  isFetchingProducts: PropTypes.bool.isRequired,
  setOnSearchStarted: PropTypes.func.isRequired,
  callbackParams: PropTypes.shape({}).isRequired,
};

export default StaticProducts;
