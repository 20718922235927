import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import routes from 'agentRoutes';
import classNames from 'classnames';
import httpClient from 'agentHTTPClient';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Card from '@wtag/rcl-card';
import Amount from 'sharedWebpack/Amount';
import DateTime from 'sharedWebpack/DateTime';
import Modal from 'sharedWebpack/Modal';
import { PercentageMarker, EmphasisTag } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';

class CostCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allocatedPercentage: props.usedCostCenter.allocatedPercentage,
      value: props.usedCostCenter.costCenter.value,
      isModalVisible: false,
      deleteButtonDisabled: false,
    };
  }

  onModalOpen = () => {
    this.setState({
      isModalVisible: true,
      allocatedPercentage: this.props.usedCostCenter.allocatedPercentage,
      value: this.props.usedCostCenter.costCenter.value,
    });
  };

  onModalClose = () => {
    this.setState({ isModalVisible: false });
  };

  setAllocatedPercentage = givenAllocatedPercentage => {
    let allocatedPercentage = givenAllocatedPercentage;

    if (allocatedPercentage > 100) {
      allocatedPercentage = 100;
    }
    if (allocatedPercentage < 0) {
      allocatedPercentage = 0;
    }

    this.setState({ allocatedPercentage });
  };

  removeCostCenter = () => {
    this.setState({ deleteButtonDisabled: true });
    const costCenterID = this.props.costCenter.id || this.props.costCenter.value;
    if (this.props.removeCostCenterFromCart) {
      this.props.removeCostCenterFromCart(costCenterID, this.props.costCenter.accountingTypeId);
    }

    if (this.props.canRemoveOrderCostCenter) {
      httpClient
        .delete(routes.api.orders.costCenters.remove({ orderId: this.props.costCenter.orderId }), {
          cost_center_id: this.props.costCenter.id || this.props.costCenter.value,
          accounting_type_id: this.props.costCenter.accountingTypeId,
        })
        .then(() => window.location.reload(false));
    }
  };

  updateCostCenter = () => {
    const newCostCenter = this.props.costCenter;
    newCostCenter.percentage = this.state.allocatedPercentage
      ? this.state.allocatedPercentage
      : this.props.usedCostCenter.allocatedPercentage;
    const existingValue = newCostCenter.id || newCostCenter.value;

    newCostCenter.prevId = existingValue;
    if (newCostCenter.prevId !== this.state.value) {
      newCostCenter.id = this.state.value;
      newCostCenter.custom = true;
    } else {
      newCostCenter.id = existingValue;
    }
    if (this.props.updateCartCostCenter) {
      this.props.updateCartCostCenter(newCostCenter);
    }
    if (this.props.canUpdateOrderCostCenter) {
      httpClient
        .patch(routes.api.orders.costCenters.update({ orderId: this.props.costCenter.orderId }), {
          cost_center_id: newCostCenter.id,
          custom: newCostCenter.custom,
          allocated_percentage: newCostCenter.percentage,
          accounting_type_id: newCostCenter.accountingTypeId,
          prev_cost_center_id: newCostCenter.prevId,
        })
        .then(() => window.location.reload(false));
    }

    this.onModalClose();
  };

  render() {
    const hasRightToUpdate = this.props.updateCartCostCenter || this.props.canUpdateOrderCostCenter;
    const hasRightToDelete =
      this.props.removeCostCenterFromCart || this.props.canRemoveOrderCostCenter;

    return (
      <div className="cost-centers__body">
        <Card version="v2" shadow="dark">
          <div className="cost-centers__sub-header">
            <div className="col-12 col-bleed col-grid direction-row align-center justify-space-between">
              <div className="cost-centers__details">
                <div className="cost-centers__details-percentage">
                  {I18n.t('components.cost_center.allocated_percentage_value', {
                    percentage: this.props.usedCostCenter.allocatedPercentage,
                  })}
                </div>
                <div className="cost-centers__details-wrapper">
                  <div className="cost-centers__details-wrapper-title">
                    {this.props.costCenter.accountingType} ({this.props.costCenter.typeHuman})
                  </div>
                  <div className="cost-centers__details-wrapper-value">
                    {this.props.costCenter.value ? (
                      <EmphasisTag
                        type="warning"
                        text={I18n.t('components.cost_center.value', {
                          value: this.props.costCenter.value,
                        })}
                        size="small"
                      />
                    ) : (
                      I18n.t('components.cost_center.provide_value')
                    )}
                  </div>
                </div>
              </div>
              <div className="cost-centers__btn-container">
                {(hasRightToUpdate || hasRightToDelete) && (
                  <div className="col-12 col-bleed align-self-center">
                    <div className="cost-centers__actions">
                      <div className="modal-wrapper">
                        {hasRightToUpdate && (
                          <Fragment>
                            <IconButton
                              icon={<Icon name="edit" />}
                              size="tiny"
                              onClick={this.onModalOpen}
                            />
                            <Modal
                              className="cost-centers__modal"
                              title={
                                <div className="cost-centers__modal--title">
                                  {I18n.t('components.shared.edit')}
                                  <div
                                    onClick={this.onModalClose}
                                    onKeyDown={this.onModalClose}
                                    role="presentation"
                                  >
                                    <Icon name="close" />
                                  </div>
                                </div>
                              }
                              isModalVisible={this.state.isModalVisible}
                              onOutsideClick={this.onModalClose}
                              footer={
                                <Button
                                  version="v2"
                                  size="small"
                                  label={I18n.t('components.shared.update')}
                                  primary={true}
                                  onClick={this.updateCostCenter}
                                />
                              }
                              alignFooter="left"
                            >
                              <div className="cost-centers__form col-12 col-bleed">
                                <Input
                                  label={I18n.t('components.cost_center.allocated_percentage')}
                                  value={this.state.allocatedPercentage}
                                  onChange={value => this.setAllocatedPercentage(value)}
                                />
                                <Input
                                  label={I18n.t('components.cost_center.accounting_value')}
                                  value={this.state.value}
                                  onChange={value => this.setState({ value })}
                                />
                              </div>
                            </Modal>
                          </Fragment>
                        )}
                        {hasRightToDelete && (
                          <IconButton
                            icon={<Icon name="delete" />}
                            size="small"
                            onClick={this.removeCostCenter}
                            disabled={this.state.deleteButtonDisabled}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {this.props.costCenter.budget && (
            <div className="budget">
              <div
                className={classNames(
                  'col-12 col-grid col-bleed direction-row justify-space-between budget__information-group',
                  {
                    'budget__information-group--booking-page': this.props.reviewBookingPage,
                  },
                )}
              >
                <div
                  className={classNames('budget__information-group-content', {
                    'budget__information-group-content--booking-page': this.props.reviewBookingPage,
                  })}
                >
                  <div className="budget__header">
                    {I18n.t('components.cost_center.active_budget')}
                  </div>
                  <div className="budget__value">
                    {this.props.costCenter.budget.name} ({this.props.costCenter.typeHuman})
                  </div>
                </div>
                <div
                  className={classNames('budget__information-group-content', {
                    'budget__information-group-content--booking-page': this.props.reviewBookingPage,
                  })}
                >
                  <div className="budget__header">{I18n.t('components.cost_center.validity')}</div>
                  <div className="budget__value">
                    <DateTime
                      dateTime={this.props.costCenter.budget.currentPeriodStart}
                      format="short"
                    />
                    <span> - </span>
                    <DateTime
                      dateTime={this.props.costCenter.budget.currentPeriodEnd}
                      format="short"
                    />
                  </div>
                </div>

                <div
                  className={classNames('budget__information-group-content', {
                    'budget__information-group-content--booking-page': this.props.reviewBookingPage,
                  })}
                >
                  <div className="budget__header">
                    {I18n.t('components.cost_center.association_type')}
                  </div>
                  <div className="budget__value">{this.props.costCenter.typeHuman}</div>
                </div>
              </div>
              <div
                className={classNames(
                  'col-12 col-grid col-bleed direction-row justify-space-between budget__information-group',
                  {
                    'budget__information-group--booking-page': this.props.reviewBookingPage,
                  },
                )}
              >
                <div
                  className={classNames('budget__information-group-content', {
                    'budget__information-group-content--booking-page': this.props.reviewBookingPage,
                  })}
                >
                  <div className="budget__total">
                    <PercentageMarker value={100} size="medium" />
                    <div className="budget__details">
                      <div className="budget__value">
                        <Amount
                          currency={this.props.tenantCurrency}
                          value={this.props.costCenter.budget.convertedAmount}
                        />
                      </div>
                      <div className="budget__header">
                        {I18n.t('components.cost_center.total_amount')}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames('budget__information-group-content', {
                    'budget__information-group-content--booking-page': this.props.reviewBookingPage,
                  })}
                >
                  <div className="budget__available">
                    <PercentageMarker
                      value={this.props.usedCostCenter.availablePercentage}
                      size="medium"
                    />
                    <div className="budget__details">
                      <div className="budget__value">
                        <Amount
                          currency={this.props.tenantCurrency}
                          value={this.props.costCenter.budget.availableAmount}
                        />
                      </div>
                      <div className="budget__header">
                        {I18n.t('components.cost_center.available')}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames('budget__information-group-content', {
                    'budget__information-group-content--booking-page': this.props.reviewBookingPage,
                  })}
                >
                  <div className="budget__after-booking">
                    <PercentageMarker
                      value={this.props.usedCostCenter.afterBookingPercentage}
                      size="medium"
                    />
                    <div className="budget__details">
                      <div className="budget__value">
                        <Amount
                          currency={this.props.tenantCurrency}
                          value={this.props.usedCostCenter.afterBookingAmount}
                        />
                      </div>
                      <div className="budget__header">
                        {I18n.t('components.cost_center.after_booking')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

CostCenter.defaultProps = {
  updateCartCostCenter: null,
  canUpdateOrderCostCenter: false,
  removeCostCenterFromCart: null,
  canRemoveOrderCostCenter: false,
};

CostCenter.propTypes = {
  updateCartCostCenter: PropTypes.func,
  canUpdateOrderCostCenter: PropTypes.bool,
  removeCostCenterFromCart: PropTypes.func,
  canRemoveOrderCostCenter: PropTypes.bool,
  tenantCurrency: PropTypes.string.isRequired,
  reviewBookingPage: PropTypes.bool.isRequired,
  usedCostCenter: PropTypes.shape().isRequired,
  costCenter: PropTypes.shape({
    id: PropTypes.number.isRequired,
    orderId: PropTypes.number,
    prevId: PropTypes.number,
    custom: PropTypes.bool,
    accountingType: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    typeHuman: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    accountingTypeId: PropTypes.number.isRequired,
    budget: PropTypes.shape({
      availableAmount: PropTypes.string.isRequired,
      convertedAmount: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      currentPeriodStart: PropTypes.string.isRequired,
      currentPeriodEnd: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default CostCenter;
