import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';

const Amenity = props => {
  if (!props.amenity) {
    return null;
  }
  return (
    <div className="hotel-amenity">
      <div className="hotel-amenity__icon">
        <Icon name="checkCircleOutline" size="tiny" color="success" />
      </div>
      <div className="hotel-amenity__text">
        {I18n.t(`components.ibe.amenities.${props.amenity}`)}
      </div>
    </div>
  );
};

Amenity.propTypes = {
  amenity: PropTypes.string.isRequired,
};

export default Amenity;
