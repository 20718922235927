import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@wtag/rcl-modal';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';

const confirmationIconMap = {
  success: 'check',
  danger: 'highPriority',
  neutral: 'neutralOutline',
  warning: 'warning',
};

const ConfirmationModal = props => {
  const {
    className,
    isModalOpen,
    confirmationHeader,
    subHeader,
    confirmationText,
    rejectionText,
    onConfirm,
    onReject,
    withAction,
    isConfirmationOnly,
    type,
    onOutsideClick,
    timeout,
    onTimeout,
  } = props;

  const iconType = type === 'neutral' ? 'tertiary' : type;

  return (
    <Modal
      isVisible={isModalOpen}
      className={classNames('confirmation-modal', `confirmation-modal--${type}`, className)}
      onOutsideClick={onOutsideClick}
      timeout={timeout}
      onTimeout={onTimeout}
    >
      {iconType && (
        <Modal.Title>
          <Icon
            name={confirmationIconMap[type]}
            size="medium"
            color={iconType}
            showBGColor={true}
          />
        </Modal.Title>
      )}
      {confirmationHeader && <Modal.Title>{confirmationHeader}</Modal.Title>}
      {subHeader && <Modal.Content>{subHeader}</Modal.Content>}
      {withAction && (
        <Modal.Footer>
          {isConfirmationOnly ? (
            <Button
              version="v2"
              size="small"
              type="primary"
              standalone={true}
              label={confirmationText}
              onClick={onConfirm}
            />
          ) : (
            <React.Fragment>
              <Button
                version="v2"
                size="small"
                type="primary"
                standalone={true}
                label={confirmationText}
                onClick={onConfirm}
              />
              <Button
                version="v2"
                size="small"
                type="default"
                standalone={true}
                label={rejectionText}
                onClick={onReject}
              />
            </React.Fragment>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  className: null,
  isModalOpen: false,
  confirmationHeader: null,
  subHeader: null,
  confirmationText: 'Yes',
  rejectionText: 'No',
  onConfirm: () => {},
  onReject: () => {},
  onOutsideClick: () => {},
  timeout: null,
  onTimeout: () => {},
  withAction: true,
  isConfirmationOnly: false,
  type: 'neutral',
};

ConfirmationModal.propTypes = {
  className: PropTypes.string,
  isModalOpen: PropTypes.bool,
  confirmationHeader: PropTypes.node,
  subHeader: PropTypes.node,
  confirmationText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rejectionText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  onOutsideClick: PropTypes.func,
  timeout: PropTypes.number,
  onTimeout: PropTypes.func,
  withAction: PropTypes.bool,
  isConfirmationOnly: PropTypes.bool,
  type: PropTypes.string,
};

export default ConfirmationModal;
