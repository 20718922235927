import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@wtag/rcl-card';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import useViewMode from 'sharedWebpack/useViewMode';
import { TYPE_COMPACT, TYPE_STANDARD } from 'sharedWebpack/helpers/viewType';
import { DESKTOP_DEVICE } from 'sharedWebpack/old/libraries/responsiveHelpers';
import { TYPE_FLIGHT, TYPE_HOTEL, TYPE_CAR } from 'sharedWebpack/helpers/itemTypes';
import SearchedOptions from '../../SearchedOptions';
import CarFilterButton from '../CarFilterButton';
import './styles.scss';

const CarSearchSubHeader = ({
  totalResultsCount = 0,
  match,
  searchParamsId,
  laymanMode,
  totalOptionsPerSupplier,
  isSearchCompleted,
  viewMode,
  onUpdateViewMode,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const tabToLastSearchMapper = [TYPE_FLIGHT, TYPE_HOTEL, TYPE_CAR];
  const currentPath = `/${match.path.split('/')[1]}`;
  const tabToFormMapper = ['/flights', '/hotels', '/cars', '/static'];
  const totalOptionsFoundText = I18n.t('components.ibe.search_form.label.total_results', {
    count: totalResultsCount,
  });
  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;
  const iconButtonSize = isLargeScreen ? 'xs' : 'xxs';

  useEffect(() => {
    const currentTabIndex = tabToFormMapper.indexOf(currentPath);
    setCurrentTab(currentTabIndex);
  }, []);

  const formType = tabToLastSearchMapper[currentTab];

  return (
    <Card className="car-search-sub-header__card">
      {isSearchCompleted ? (
        <div className="d-flex align-center justify-between">
          <div className="car-search-sub-header d-flex align-center">
            {totalOptionsPerSupplier && (
              <SearchedOptions
                formType={formType}
                searchParamsId={searchParamsId}
                totalOptionsFound={totalOptionsFoundText}
                totalOptionsPerSupplier={totalOptionsPerSupplier}
                laymanMode={laymanMode}
              />
            )}
            <div className="align-center justify-between flex-column-gap-8 car-search-sub-header__sort-content">
              <div className="car-search-sub-header__vertical-line" />
              <span className="car-search-sub-header__results">
                {I18n.t('components.ibe.search_results.filters.results_by')}
              </span>
              <CarFilterButton match={match} />
            </div>
          </div>
          <div className="d-flex flex-column-gap-4">
            <span className="d-flex align-center car-search-sub-header__layout">
              {I18n.t('components.ibe.search_results.filters.layout')}
            </span>
            <IconButton
              className={classNames('car-search-sub-header__icon-button', {
                'search-sub-header__sort-button--disabled': viewMode === TYPE_STANDARD,
              })}
              icon={<Icon name="standardView" />}
              color="tertiary"
              size={iconButtonSize}
              disabled={viewMode === TYPE_STANDARD}
              onClick={() => onUpdateViewMode(TYPE_STANDARD)}
              label={I18n.t('components.ibe.search_result.standard')}
            />
            <IconButton
              className={classNames('car-search-sub-header__icon-button', {
                'search-sub-header__sort-button--disabled': viewMode === TYPE_COMPACT,
              })}
              icon={<Icon name="compactView" />}
              color="tertiary"
              disabled={viewMode === TYPE_COMPACT}
              size={iconButtonSize}
              onClick={() => onUpdateViewMode(TYPE_COMPACT)}
              label={I18n.t('components.ibe.search_result.compact')}
            />
          </div>
        </div>
      ) : (
        <span className="search-bar__searching">
          {I18n.t('components.ibe.search_form.label.searching')}
        </span>
      )}
    </Card>
  );
};

CarSearchSubHeader.propTypes = {
  totalResultsCount: PropTypes.number.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  searchParamsId: PropTypes.string.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  totalOptionsPerSupplier: PropTypes.arrayOf(PropTypes.node).isRequired,
  isSearchCompleted: PropTypes.bool.isRequired,
  viewMode: PropTypes.string.isRequired,
  onUpdateViewMode: PropTypes.func.isRequired,
};

export default CarSearchSubHeader;
