import paymentMethodName from './paymentMethodName';
import { CASH_FORM_OF_PAYMENT } from '../../lib/helpers/fop_options';

const supportedFopIcons = (availableFops, laymanMode) => {
  const uniqueIconList = new Set();
  const cardFop = 'creditCard';

  Object.keys(availableFops).forEach(fop => {
    if (availableFops[fop].supported && fop === cardFop) {
      availableFops.creditCard.supportedCards.forEach(creditCard => {
        uniqueIconList.add(paymentMethodName(creditCard.cardType, laymanMode));
      });
    } else if (availableFops[fop].supported && !laymanMode) {
      uniqueIconList.add(paymentMethodName(fop, laymanMode));
    } else if (laymanMode && fop === CASH_FORM_OF_PAYMENT) {
      uniqueIconList.add(paymentMethodName(CASH_FORM_OF_PAYMENT, laymanMode));
    }
  });

  return [...uniqueIconList];
};

export default supportedFopIcons;
