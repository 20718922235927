const addOrRemoveAgeField = (counter, data) => {
  for (let step = 0; step < Math.abs(counter); step += 1) {
    if (counter > 0) {
      data.removeField();
    } else if (counter < 0) {
      data.addField({});
    }
  }
};

export default addOrRemoveAgeField;
