import { useState, useEffect } from 'react';
import { getViewportName } from './old/libraries/responsiveHelpers';

const useViewMode = () => {
  const [viewMode, setViewMode] = useState('');

  const handleResize = () => {
    const viewPortName = getViewportName();
    setViewMode(viewPortName);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return viewMode;
};

export default useViewMode;
