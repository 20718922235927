import moment from 'moment';
import {
  validate,
  originDestinationValidation,
  presenceValidation,
  dateValidation,
  hourMinuteValidation,
  greaterThanOrEqualToValidation,
  lessThanOrEqualToValidation,
  totalTravelersValidation,
  maxLengthValidation,
} from '../../../lib/validations';

const travelerConstraints = {
  adults: {
    ...presenceValidation,
  },
  children: {
    ...presenceValidation,
  },
  infants: {
    ...presenceValidation,
  },
};

const tripConstraints = {
  origin: {
    ...presenceValidation,
  },
  destination: {
    ...presenceValidation,
  },
  date: {
    ...presenceValidation,
  },
  departureTime: {
    ...hourMinuteValidation,
  },
  cabinClass: {
    ...presenceValidation,
  },
};

function validateTravelerConstraints(params) {
  const extendedTravelerConstraints = { ...travelerConstraints };
  const adultTraveler = parseInt(params.adults, 10);
  const childTraveler = parseInt(params.children, 10);
  const infantTraveler = parseInt(params.infants, 10);
  const flightTotalTravelersValidation = totalTravelersValidation(
    adultTraveler,
    childTraveler,
    infantTraveler,
  );

  extendedTravelerConstraints.adults = {
    ...extendedTravelerConstraints.adults,
    ...flightTotalTravelersValidation,
    ...greaterThanOrEqualToValidation(I18n.t('components.ibe.validations.common.adult'), 1),
  };

  extendedTravelerConstraints.children = {
    ...extendedTravelerConstraints.children,
    ...flightTotalTravelersValidation,
  };

  extendedTravelerConstraints.infants = {
    ...extendedTravelerConstraints.infants,
    ...flightTotalTravelersValidation,
  };

  if (infantTraveler > adultTraveler) {
    extendedTravelerConstraints.infants = {
      ...extendedTravelerConstraints.infants,
      ...lessThanOrEqualToValidation(adultTraveler, I18n.t('components.ibe.validations.infant')),
    };
  }
  return validate(params, extendedTravelerConstraints);
}

function validateTrips(trips, minHoursInFuture, showDepartureTime) {
  const errors = [];
  trips.forEach((trip, index) => {
    let finalTrip = { ...trip };
    const extendedConstraints = { ...tripConstraints };
    if (trip.origin === trip.destination) {
      extendedConstraints.origin = {
        ...extendedConstraints.origin,
        ...originDestinationValidation,
      };
      extendedConstraints.destination = {
        ...extendedConstraints.destination,
        ...originDestinationValidation,
      };
    }
    if (index !== 0 && trips && trips[index - 1]) {
      extendedConstraints.date = {
        ...extendedConstraints.date,
        ...dateValidation({
          minDate: trips[index - 1].date,
          maxDate: moment().add(361, 'days'),
          selectedDate: trips[index].date,
          showDateWithHours: showDepartureTime,
        }),
      };
    } else {
      const selectedDate = moment(trip.date)
        .hours(0)
        .add(moment.duration(trip.departureTime));
      const maxDate = moment().add(361, 'days');
      let minDate;

      if (minHoursInFuture > 0) {
        minDate = moment().add(minHoursInFuture, 'hours');
      } else {
        minDate = new Date();
        minDate.setDate(minDate.getDate() - 1);
      }

      if (finalTrip.date !== undefined && selectedDate.isValid()) {
        finalTrip = { ...finalTrip, date: selectedDate.toDate() };
      }
      extendedConstraints.date = {
        ...extendedConstraints.date,
        ...dateValidation({
          minDate,
          maxDate,
          selectedDate,
          showDateWithHours: showDepartureTime,
        }),
      };
    }
    errors.push(validate(finalTrip, extendedConstraints));
  });
  return errors;
}

function validateAirlinePreferences(availableChannels, params) {
  if (
    params.accountSettings &&
    params.accountSettings.amadeus &&
    params.accountSettings.amadeus.length === 0
  ) {
    return {};
  }
  if (!params.accountSettings && availableChannels.indexOf('amadeus') < 0) {
    return {};
  }
  const constraints = {
    carriers: {
      ...maxLengthValidation(3),
    },
  };
  return validate(params.airlinePreferences, constraints);
}

function validateAllSearchParams(
  availableChannels,
  exchangeMode,
  minHoursInFuture,
  showDepartureTime,
) {
  return params => {
    let errors = {};
    if (!exchangeMode) {
      errors = validateTravelerConstraints(params);
    }
    errors.trips = validateTrips(params.trips, minHoursInFuture, showDepartureTime);
    errors.airlinePreferences = validateAirlinePreferences(availableChannels, params);
    return errors;
  };
}

export { validateAllSearchParams, validateTrips, validateTravelerConstraints };
