import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';

const StateEmphasisTag = ({ currentState, stateSize }) => {
  const stateEmphasisTagType = state => {
    switch (state) {
      case 'issued':
        return 'success';
      case 'open':
        return 'warning';
      case 'ordered':
        return 'important';
      case 'cancelled':
        return 'danger';
      case 'pending':
        return 'neutral';
      case 'refunded':
        return 'accent';
      case 'offered':
        return 'accent';
      case 'paid':
        return 'success';
      case 'reserved':
        return 'warning';
      case 'archived':
        return 'danger';
      case 'voided':
        return 'accent';
      default:
        return 'disabled';
    }
  };

  return (
    <EmphasisTag
      text={I18n.t(currentState, {
        scope: 'shared.order.state',
      })}
      type={stateEmphasisTagType(currentState)}
      size={stateSize}
    />
  );
};

StateEmphasisTag.defaultProps = {
  stateSize: 'small',
};
StateEmphasisTag.propTypes = {
  currentState: PropTypes.string.isRequired,
  stateSize: PropTypes.string,
};

export default StateEmphasisTag;
