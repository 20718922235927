import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import moment from 'moment';

import deepClone from '../../lib/deepClone';
import { validateAllSearchParams } from './helpers/validate';
import generateCartOverrides from '../../helpers/generateCartOverrides';

import searchAccountValues from '../../helpers/searchAccountValues';
import availableChannelsShape from '../../shapes/availableChannels';

import SearchForm from './SearchForm';

const initialFields = [
  'trips[].origin',
  'trips[].destination',
  'trips[].date',
  'trips[].departureTime',
  'trips[].cabinClass',
  'trips[].bookingClass',
  'trips[].alliancePreferences',
  'flexible',
  'airlinePreferences.carriers',
  'airlinePreferences.type',
  'nonStop',
  'onlyFlexibleFlight',
  'onlyBagIncludedFlight',
  'excludeCodeShareFlight',
];

const reduxFormConfig = {
  form: 'flightSearchForm',
  destroyOnUnmount: false,
  fields: [],
};

const mapStateToProps = (state, ownProps) => {
  const searchForm = state.form.flightSearchForm;
  const cartOverrides = generateCartOverrides(
    state.common.cart.items.length,
    state.common.travelerCount,
    'flight',
  );
  const cartHasItems = state.common.cart.items.length > 0;
  const allTravelerIds =
    ownProps.callbackParams && ownProps.callbackParams.all_traveler_ids
      ? ownProps.callbackParams.all_traveler_ids
      : [];
  const fields = deepClone(initialFields);
  if (ownProps.searchAccounts && ownProps.searchAccounts.flights) {
    Object.keys(ownProps.searchAccounts.flights).forEach(key =>
      fields.push(`accountSettings.${key}`),
    );
  }

  fields.push(`welltravelChannel`);
  fields.push('adults');
  fields.push('children');
  fields.push('infants');
  fields.push('isShowTravelfusionAlert');

  if (ownProps.exchange.enabled) {
    fields.push('bookingId');
  } else {
    fields.push('itinerarySearch');
  }

  const defaultSearchParams = {
    trips: [
      {
        date: moment()
          .add(7, 'days')
          .startOf('day')
          .format(),
        cabinClass: 'economy',
        bookingClass: [],
        alliancePreferences: [],
        departureTime: 'anytime',
      },
      {
        date: moment()
          .add(13, 'days')
          .startOf('day')
          .format(),
        cabinClass: 'economy',
        bookingClass: [],
        alliancePreferences: [],
        departureTime: 'anytime',
      },
    ],
    adults: allTravelerIds.length > 0 ? allTravelerIds.length : 1,
    children: 0,
    infants: 0,
    isShowTravelfusionAlert: false,
    airlinePreferences: {
      type: 'preferred',
      carriers: [],
    },
    nonStop: false,
    itinerarySearch: ownProps.searchInputConfiguration.enableSearchItineraryOnly,
    welltravelChannel: !ownProps.laymanMode,

    ...ownProps.initialSearchParams.flights,
  };

  const accountSettings = searchAccountValues(ownProps.searchAccounts.flights);

  const initialValues = {
    accountSettings,
    ...defaultSearchParams,
    ...ownProps.searchParams,
    ...state.flights.defaultFlightSearchParams,
    ...cartOverrides,
  };

  const preselectTravelers = state.common.preselectTravelers.travelers;

  return {
    fields,
    initialValues,
    cartOverrides,
    accountSettings,
    defaultSearchParams,
    searchForm,
    cartHasItems,
    airportSuggestionList: Object.keys(state.common.airports),
    validate: validateAllSearchParams(
      ownProps.availableChannels.flights,
      ownProps.exchange.enabled,
      ownProps.minHoursInFuture,
      ownProps.searchInputConfiguration.showDepartureTime,
    ),
    preselectTravelers,
    travelerLoggedIn: ownProps.travelerLoggedIn,
    airportSuggestions: state.common.airportSuggestions,
  };
};

const ReduxForm = reduxForm(reduxFormConfig, mapStateToProps)(SearchForm);

const SearchFormWithContext = (props, context) => <ReduxForm {...props} {...context} />;

SearchFormWithContext.contextTypes = {
  availableChannels: availableChannelsShape,
  searchAccounts: PropTypes.shape().isRequired,
  laymanMode: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  exchange: PropTypes.shape().isRequired,
  initialSearchParams: PropTypes.shape().isRequired,
  searchInputConfiguration: PropTypes.shape({
    showDepartureTime: PropTypes.bool,
    showPlusMinusThreeDays: PropTypes.bool,
    showNonStop: PropTypes.bool,
    showAirlinePreference: PropTypes.bool,
    showOnlyFlexibleFlight: PropTypes.bool,
    showBookingClass: PropTypes.bool,
    showAlliancePreferences: PropTypes.bool,
    showOnlyBagIncludedFlight: PropTypes.bool,
    showExcludeCodeShareFlights: PropTypes.bool,
    enableSearchItineraryOnly: PropTypes.bool,
    whitelistedAirports: PropTypes.arrayOf(PropTypes.string),
  }),
  minHoursInFuture: PropTypes.number,
  globalPolicyPreferences: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default SearchFormWithContext;
