import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import Icon from '@wtag/rcl-icon';
import { TYPE_SEAT } from 'sharedWebpack/helpers/itemTypes';
import journeyElementTypeIcons from '../helpers/journeyElementTypeIcons';
import PriceLabel from './PriceLabel';
import { OLD_STATE } from '../IssueBooking/constants';
import { ValuesShape } from './Shapes/index';

const AncillaryPriceChangesContent = ({ values, state, style }) => {
  const { journeyElementType, ancillariesTitle } = values;
  const showOldPrice = state === OLD_STATE;
  const valuesByState = values[state];
  const grossPrice = showOldPrice ? valuesByState.grossPrice : valuesByState[style].grossPrice;
  const isSeatType = journeyElementType === TYPE_SEAT;
  const iconLabel = isSeatType
    ? I18n.t('admin.components.orders.overview.seat', {
        number: ancillariesTitle,
      })
    : ancillariesTitle;

  return (
    <Fragment>
      <Icon
        className="refresh-booking__ancillary-title"
        name={journeyElementTypeIcons[journeyElementType]}
        color="tertiary"
        size="tiny"
        showLabel={true}
        iconLabel={iconLabel}
        showBGColor={true}
      />
      <PriceLabel
        label={I18n.t('activerecord.attributes.order_item.gross_price')}
        currency={valuesByState.currency}
        value={grossPrice}
      />
    </Fragment>
  );
};

AncillaryPriceChangesContent.propTypes = {
  state: PropTypes.string.isRequired,
  values: ValuesShape.isRequired,
  style: PropTypes.string.isRequired,
};

export default hot(module)(AncillaryPriceChangesContent);
