import PropTypes from 'prop-types';

const nullOrStringPropType = PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.string]);

export const formOfPaymentShape = {
  deleteable: PropTypes.bool,
  details: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  updateable: PropTypes.bool,
};

const paymentMethodShape = {
  [PropTypes.string]: PropTypes.bool,
  creditCards: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.number]: PropTypes.string,
    }),
  ),
  threeDsAuthenticationRequired: PropTypes.bool,
  wellTravelWallet: PropTypes.bool,
};

const itemShape = {
  account: PropTypes.string,
  bookingHasMultipleIssuedItems: PropTypes.bool,
  bookingHasMultipleItem: PropTypes.bool,
  bookingHasMultipleOpenItems: PropTypes.bool,
  bookingId: PropTypes.number,
  cancelPenaltyAmount: PropTypes.string,
  cancelPenaltyCurrency: PropTypes.string,
  cancellationScheduledFor: PropTypes.string,
  cartId: PropTypes.string,
  cartItemId: PropTypes.number,
  channel: PropTypes.string,
  currency: PropTypes.string,
  flightTypeItem: PropTypes.bool,
  fopRequiredBefore: PropTypes.string,
  formOfPaymentIds: PropTypes.arrayOf(PropTypes.number),
  gdsChannels: PropTypes.arrayOf(PropTypes.string),
  grossAmount: PropTypes.string,
  grossTaxes: PropTypes.string,
  grossTotalMinorUnit: PropTypes.string,
  groupLabel: PropTypes.string,
  hasBooking: PropTypes.bool,
  humanizedNetPrice: PropTypes.string,
  id: PropTypes.number,
  identifier: PropTypes.string,
  inQuoteStatus: PropTypes.bool,
  inRefundedStatus: PropTypes.bool,
  journeyElementId: PropTypes.number,
  journeyElementType: PropTypes.string,
  marginBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      id: PropTypes.number,
      marginId: PropTypes.number,
      name: PropTypes.string,
      taxes: PropTypes.string,
      total: PropTypes.string,
    }),
  ),
  netPrice: PropTypes.string,
  offline: PropTypes.bool,
  orderId: PropTypes.number,
  outOfPolicyJustification: nullOrStringPropType,
  paymentAddStillPossible: PropTypes.bool,
  paymentBy: PropTypes.string,
  paymentMethods: PropTypes.shape(paymentMethodShape),
  paymentStatus: PropTypes.string,
  permissions: PropTypes.shape({
    canConfirm: PropTypes.bool,
    canDestroy: PropTypes.bool,
    canDownloadVoucher: PropTypes.bool,
    canExchangeBooking: PropTypes.bool,
    canIssueEmdAfterTicketIssue: PropTypes.bool,
    canIssueItem: PropTypes.bool,
    canIssueTicket: PropTypes.bool,
    canQuoteRefundBooking: PropTypes.bool,
    canQuoteRefundItem: PropTypes.bool,
    canRefundBooking: PropTypes.bool,
    canRefundItem: PropTypes.bool,
    canVoidTicket: PropTypes.bool,
    cannotCancel: PropTypes.bool,
    cannotEdit: PropTypes.bool,
    cannotUpdateBooking: PropTypes.bool,
  }).isRequired,
  productId: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.number, PropTypes.string]),
  productType: nullOrStringPropType,
  refundAmount: PropTypes.string,
  refundPossibleUntil: nullOrStringPropType,
  refundable: PropTypes.bool,
  reservationItemId: PropTypes.string,
  scheduledForCancel: PropTypes.bool,
  showUnconfirmChangesWarning: PropTypes.bool,
  status: PropTypes.string,
  supplierInfo: PropTypes.shape({
    account: PropTypes.string,
    identifier: PropTypes.string,
  }),
  supplierReservationIdentifier: PropTypes.string,
  tenantTimezone: PropTypes.string,
  ticketIssuance: PropTypes.shape({
    possibleUntilInMinutes: PropTypes.number,
    possibleUntilInTenantTz: PropTypes.string,
    possibleUntilInUtc: PropTypes.string,
    required: PropTypes.bool,
    stillPossible: PropTypes.bool,
  }),
  ticketVoidAvailable: PropTypes.bool,
  ticketVoidPossibleUntil: nullOrStringPropType,
  ticketVoidPossibleUntilInMinutes: nullOrStringPropType,
  ticketVoidSupported: PropTypes.bool,
  title: PropTypes.string,
  totalAmount: PropTypes.string,
  totalMargin: PropTypes.string,
  travelersCount: PropTypes.number,
  urls: PropTypes.shape({
    [PropTypes.string]: nullOrStringPropType,
  }),
  showConfirmationAndFetchItems: PropTypes.func,
  emdIndividualIssuancePossible: PropTypes.bool.isRequired,
};

export default itemShape;
