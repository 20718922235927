import PropTypes from 'prop-types';
import creditCardShape from './creditCardShape';

export * from './byTokenShapes';

const isFormOfPaymentSupportedShape = PropTypes.shape({ supported: PropTypes.bool });

export const availableFormOfPaymentShape = {
  cash: isFormOfPaymentSupportedShape.isRequired,
  creditCard: PropTypes.shape(creditCardShape).isRequired,
  iata: isFormOfPaymentSupportedShape.isRequired,
  none: PropTypes.shape({ supported: PropTypes.bool, supportedBySupplier: PropTypes.bool })
    .isRequired,
  welltravelWallet: isFormOfPaymentSupportedShape,
};

export const creditCardItemShape = {
  cardType: PropTypes.string,
  city: PropTypes.string,
  countryCode: PropTypes.string,
  fullName: PropTypes.string,
  maskedNumber: PropTypes.string,
  personId: PropTypes.string,
  state: PropTypes.string,
  storeInCcv: PropTypes.bool,
  street: PropTypes.string,
  token: PropTypes.string,
  zipCode: PropTypes.string,
};
