import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FilterAction = ({ handleCheckAll, handleUncheckAll, allOptionsChecked, checkedItems }) => (
  <div className="flight-filter-checkbox-wrapper__selector">
    <span
      role="presentation"
      className={classNames('flight-filter-checkbox-wrapper__selector-selected', {
        'flight-filter-checkbox-wrapper__selector-deselect--disabled': allOptionsChecked,
      })}
      onClick={handleCheckAll}
    >
      {I18n.t('components.ibe.search_results.filters.select_all')}
    </span>
    <span className="flight-filter-checkbox-wrapper__divider"> | </span>
    <span
      className={classNames('flight-filter-checkbox-wrapper__selector-deselect', {
        'flight-filter-checkbox-wrapper__selector-deselect--disabled': checkedItems.length === 0,
      })}
      role="presentation"
      onClick={handleUncheckAll}
    >
      {I18n.t('components.ibe.search_results.filters.reset_flight')}
    </span>
  </div>
);

export default FilterAction;

FilterAction.propTypes = {
  handleCheckAll: PropTypes.func.isRequired,
  handleUncheckAll: PropTypes.func.isRequired,
  allOptionsChecked: PropTypes.bool.isRequired,
  checkedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
