const AvailableForAll = 'all';
const AvailableForSome = 'some';
const Included = 'included';
const IncludedButChargeable = 'included_but_chargeable';
const NotIncluded = 'not_included';
const NoInfoFound = 'not_found';

const mapMealAvailbility = meal => {
  const segments = Object.keys(meal);
  const segmentWithAvailableMeal = [];
  const segmentWithNoInfo = [];
  const segmentWithUnavailableMeal = [];

  for (let i = 0; i < segments.length; i += 1) {
    if (meal[segments[i]][0] === 'no_meal_service') {
      segmentWithUnavailableMeal.push(segments[i]);
    } else if (meal[segments[i]].length > 0) {
      segmentWithAvailableMeal.push(segments[i]);
    } else {
      segmentWithNoInfo.push(segments[i]);
    }
  }

  if (segmentWithAvailableMeal.length === segments.length) {
    return AvailableForAll;
  }

  // If there is unavailable meal for all segments
  // or there is unavailable meal for some segment and other have empty array
  if (
    segmentWithUnavailableMeal.length === segments.length ||
    [...segmentWithUnavailableMeal, ...segmentWithNoInfo].length === segments.length
  ) {
    return NotIncluded;
  }

  if (segmentWithNoInfo.length === segments.length) {
    return NoInfoFound;
  }

  return AvailableForSome;
};

const mapWifiAvailbility = wifi => {
  const segments = Object.keys(wifi);
  const segmentWithAvailableWifi = [];
  const segmentWithNoInfo = [];
  const segmentWithUnavailableWifi = [];

  for (let i = 0; i < segments.length; i += 1) {
    if (wifi[segments[i]].available) {
      segmentWithAvailableWifi.push(segments[i]);
    } else if (wifi[segments[i]].available === false) {
      segmentWithUnavailableWifi.push(segments[i]);
    } else {
      segmentWithNoInfo.push(segments[i]);
    }
  }

  if (segmentWithAvailableWifi.length === segments.length) {
    return AvailableForAll;
  }

  if (segmentWithUnavailableWifi.length === segments.length) {
    return NotIncluded;
  }

  if (segmentWithNoInfo.length === segments.length) {
    return NoInfoFound;
  }

  return AvailableForSome;
};

const getBaggageFacility = baggage => {
  const { count, weight } = baggage;
  let status = Included;
  let description = null;

  if (count !== null && weight !== null) {
    description = I18n.t('components.ibe.search_result.baggage_with_weight_and_count', {
      count,
      weight,
    });
  } else if (weight !== null) {
    description = I18n.t('components.ibe.search_result.baggage_with_weight', {
      weight,
    });
  } else {
    description = I18n.t('components.ibe.search_result.baggage_not_include');
    status = NotIncluded;
  }

  return { status, description };
};

const getHandLuggageFacility = luggage => {
  const { count, weight, supported } = luggage;
  let status = Included;
  let description = null;

  if (supported === false) {
    description = I18n.t('components.ibe.search_result.luggage_not_include');
    status = NotIncluded;
  } else if (supported === true) {
    if (count !== null && weight !== null) {
      description = I18n.t('components.ibe.search_result.luggage_with_weight_and_count', {
        count,
        weight,
      });
    } else if (count === null && weight !== null) {
      description = I18n.t('components.ibe.search_result.luggage_with_weight', {
        weight,
      });
    } else if (count !== null && weight === null) {
      description = I18n.t('components.ibe.search_result.luggage_with_count', {
        count,
      });
    } else {
      description = I18n.t('components.ibe.search_result.no_luggage_information');
      status = NoInfoFound;
    }
  } else {
    description = I18n.t('components.ibe.search_result.no_luggage_information');
    status = NoInfoFound;
  }

  return { status, description };
};

const getCancellationFacility = penalty => {
  const { amount, currency, supported } = penalty;
  let status = Included;
  let description = null;

  if (supported === false) {
    description = I18n.t('components.ibe.search_result.non_refundable');
    status = NotIncluded;
  } else if (supported === true) {
    if (parseInt(amount, 10) === 0) {
      description = I18n.t('components.ibe.search_result.refundable_for_free');
    } else if (amount !== null) {
      description = I18n.t('components.ibe.search_result.refundable_with_charge', {
        currency,
        amount: parseFloat(amount, 10).toFixed(2),
      });
      status = IncludedButChargeable;
    } else if (amount === null) {
      description = I18n.t('components.ibe.search_result.refundable_may_charge');
      status = IncludedButChargeable;
    }
  } else {
    description = I18n.t('components.ibe.search_result.no_refundable_information');
    status = NoInfoFound;
  }

  return { status, description };
};

const getChangeFacility = penalty => {
  const { amount, currency, supported } = penalty;
  let status = Included;
  let description = null;

  if (supported === false) {
    description = I18n.t('components.ibe.search_result.non_changeable');
    status = NotIncluded;
  } else if (supported === true) {
    if (parseInt(amount, 10) === 0) {
      description = I18n.t('components.ibe.search_result.changeable_for_free');
    } else if (amount !== null) {
      description = I18n.t('components.ibe.search_result.changeable_with_charge', {
        currency,
        amount: parseFloat(amount, 10).toFixed(2),
      });
      status = IncludedButChargeable;
    } else if (amount === null) {
      description = I18n.t('components.ibe.search_result.changeable_may_charge');
      status = IncludedButChargeable;
    }
  } else {
    description = I18n.t('components.ibe.search_result.no_changeable_information');
    status = NoInfoFound;
  }

  return { status, description };
};

const getMealFacility = mealStatus => {
  let description = '';

  if (mealStatus === AvailableForAll) {
    description = I18n.t('components.ibe.search_result.meal_for_all');
  } else if (mealStatus === AvailableForSome) {
    description = I18n.t('components.ibe.search_result.meal_for_some');
  } else if (mealStatus === NotIncluded) {
    description = I18n.t('components.ibe.search_result.meal_not_included');
  } else {
    description = I18n.t('components.ibe.search_result.no_meal_information');
  }

  return {
    status: mealStatus,
    description,
  };
};

const getWifiFacility = wifiStatus => {
  let description = '';

  if (wifiStatus === AvailableForAll) {
    description = I18n.t('components.ibe.search_result.wifi_for_all');
  } else if (wifiStatus === AvailableForSome) {
    description = I18n.t('components.ibe.search_result.wifi_for_some');
  } else if (wifiStatus === NotIncluded) {
    description = I18n.t('components.ibe.search_result.wifi_not_included');
  } else {
    description = I18n.t('components.ibe.search_result.no_wifi_information');
  }

  return {
    status: wifiStatus,
    description,
  };
};

const getNoShowFacility = penalty => {
  const { amount, currency, supported } = penalty;
  let status = Included;
  let description = null;

  if (supported === false) {
    description = I18n.t('components.ibe.search_result.noshow_not_supported');
    status = NotIncluded;
  } else if (supported === true) {
    if (parseInt(amount, 10) === 0) {
      description = I18n.t('components.ibe.search_result.noshow_for_free');
    } else if (amount !== null) {
      description = I18n.t('components.ibe.search_result.noshow_with_charge', {
        currency,
        amount: parseFloat(amount, 10).toFixed(2),
      });
      status = IncludedButChargeable;
    } else if (amount === null) {
      description = I18n.t('components.ibe.search_result.noshow_may_charge');
      status = IncludedButChargeable;
    }
  } else {
    description = I18n.t('components.ibe.search_result.no_noshow_information');
    status = NoInfoFound;
  }

  return { status, description };
};

const getThreeStateFacility = (facilityName, state) => {
  let status = null;
  let description = '';

  if (state === true) {
    description = I18n.t('components.ibe.search_result.facility_available', {
      facilityName,
    });
    status = Included;
  } else if (state === false) {
    description = I18n.t('components.ibe.search_result.facility_not_available', {
      facilityName,
    });
    status = NotIncluded;
  } else {
    description = I18n.t('components.ibe.search_result.no_facility_information', {
      facilityName,
    });
    status = NoInfoFound;
  }

  return {
    status,
    description,
  };
};

const getFacilityWithPoints = (facilityName, facility) => {
  const { amount, supported } = facility;
  let status = Included;
  let description = null;

  if (supported === false) {
    description = I18n.t('components.ibe.search_result.facility_not_available', {
      facilityName,
    });
    status = NotIncluded;
  } else if (supported === true) {
    if (amount === null || parseInt(amount, 10) === 0) {
      description = I18n.t('components.ibe.search_result.facility_for_anyone', {
        facilityName,
      });
    } else {
      description = I18n.t('components.ibe.search_result.facility_for_point', {
        facilityName,
        amount,
      });
    }
  } else {
    description = I18n.t('components.ibe.search_result.no_facility_information', {
      facilityName,
    });
    status = NoInfoFound;
  }

  return {
    status,
    description,
  };
};

const mapFareBrands = fareBrands => {
  const modifiedFareBrands = [...fareBrands];
  const fareBrandFacilityKeys = Object.keys(modifiedFareBrands[0].fareBrandFacilities);
  const facilityModifierFunctionMappers = {
    baggageInformation: getBaggageFacility,
    handLuggage: getHandLuggageFacility,
    cancellationPenalty: getCancellationFacility,
    changePenalty: getChangeFacility,
    meal: getMealFacility,
    wifi: getWifiFacility,
    noShow: getNoShowFacility,
    milesAward: facility => getFacilityWithPoints('', facility),
    milesStatus: facility => getFacilityWithPoints('', facility),
    others: facility => getThreeStateFacility('', facility),
  };

  fareBrandFacilityKeys.forEach(key => {
    const modifierFunction =
      facilityModifierFunctionMappers[key] || facilityModifierFunctionMappers.others;

    // if a facility has no info for any fare brand then this facility will be deleted from all the fare brands
    if (
      modifiedFareBrands.every(
        fareBrand => modifierFunction(fareBrand.fareBrandFacilities[key]).status === NoInfoFound,
      )
    ) {
      modifiedFareBrands.map(fareBrand => {
        const modifiedFareBrand = { ...fareBrand };

        delete modifiedFareBrand.fareBrandFacilities[key];

        return modifiedFareBrand;
      });
    }
  });

  return modifiedFareBrands;
};

export {
  AvailableForAll,
  AvailableForSome,
  Included,
  IncludedButChargeable,
  NotIncluded,
  NoInfoFound,
  mapMealAvailbility,
  mapWifiAvailbility,
  getBaggageFacility,
  getHandLuggageFacility,
  getCancellationFacility,
  getChangeFacility,
  getMealFacility,
  getWifiFacility,
  getNoShowFacility,
  getThreeStateFacility,
  getFacilityWithPoints,
  mapFareBrands,
};
