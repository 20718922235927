import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import ProductIcon from 'sharedWebpack/ProductIcon';

const FeedItem = ({ type, time, details, initial }) => {
  const itemTypeTranslations = {
    hotel: I18n.t('public.components.mid_section.live_feed.items.hotel'),
    car: I18n.t('public.components.mid_section.live_feed.items.car'),
    flight: I18n.t('public.components.mid_section.live_feed.items.flight'),
  };

  return (
    <div className="col-grid col-bleed live-feed-item">
      <div className="live-feed-item__time">{time}</div>
      <div className="live-feed-item__content">
        <div className="live-feed-item__content-icon">
          <ProductIcon
            productType={type}
            {...{
              showBGColor: true,
              size: 'small',
              color: 'default',
            }}
          />
        </div>
        <div className="live-feed-item__content-data">
          <div className="live-feed-item__content-data-title">
            {I18n.t('public.components.mid_section.live_feed.item_title', {
              name_initial: initial,
              booked_item: itemTypeTranslations[type],
            })}
          </div>
          <div className="live-feed-item__content-data-details">{details}</div>
        </div>
      </div>
    </div>
  );
};

FeedItem.propTypes = {
  type: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  initial: PropTypes.string.isRequired,
};

export default hot(module)(FeedItem);
