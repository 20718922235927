import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';

class QueuePlacement extends React.Component {
  onChangeAccount = value => {
    const val = value.toUpperCase();
    this.props.setQueuePlacement(
      this.props.queue,
      val,
      this.props.resultId,
      this.context.callbackParams,
    );
  };

  onChangeQueue = value => {
    this.props.setQueuePlacement(
      value,
      this.props.account,
      this.props.resultId,
      this.context.callbackParams,
    );
  };

  render() {
    return (
      <div className="queue-placement col-12 col-bleed">
        <h4>
          <em>
            <I18nText id="ibe.queue_placement.subtitle" returnStringOnly={true} />
          </em>
        </h4>
        <div className="col-12 col-bleed queue-placement__input">
          <Input
            label={I18n.t('components.ibe.queue_placement.account')}
            onChange={this.onChangeAccount}
            value={this.props.account || ''}
            disabled={true}
          />
          <Input
            label={I18n.t('components.ibe.queue_placement.queue')}
            onChange={this.onChangeQueue}
            value={this.props.queue || ''}
          />
        </div>
      </div>
    );
  }
}

QueuePlacement.defaultProps = {
  account: null,
  queue: null,
};

QueuePlacement.propTypes = {
  setQueuePlacement: PropTypes.func.isRequired,
  account: PropTypes.string,
  queue: PropTypes.string,
  resultId: PropTypes.string.isRequired,
};

export default QueuePlacement;
