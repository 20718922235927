export const FULL_NAME_MAX_LENGTH = 45;
export const NAME_MAX_LENGTH = 29;

export const isLengthExceeded = (value, maxLength) => value.length > maxLength;

export const isFullNameLengthExceeded = (prefix, firstName, middleName, lastName) => {
  const errorMessage = I18n.t('components.ibe.validations.presence');
  if (!firstName) {
    return { firstNameError: true, firstNameErrorMessage: errorMessage };
  } else if (!lastName) {
    return { lastNameError: true, lastNameErrorMessage: errorMessage };
  }

  const noInputGiven = '';
  const fullName = `${prefix ? `${prefix} ` : noInputGiven}${firstName} ${
    middleName ? `${middleName} ` : noInputGiven
  }${lastName}`;

  const nameErrorMessage = I18n.t('components.ibe.validations.too_long', {
    length: NAME_MAX_LENGTH,
  });
  if (isLengthExceeded(firstName, NAME_MAX_LENGTH)) {
    return {
      firstNameError: true,
      firstNameErrorMessage: nameErrorMessage,
    };
  } else if (isLengthExceeded(lastName, NAME_MAX_LENGTH)) {
    return {
      lastNameError: true,
      lastNameErrorMessage: nameErrorMessage,
    };
  } else if (isLengthExceeded(fullName, FULL_NAME_MAX_LENGTH)) {
    return {
      fullNameError: true,
      fullNameErrorMessage: I18n.t('components.ibe.validations.combined_name', {
        length: FULL_NAME_MAX_LENGTH,
      }),
    };
  }

  return null;
};
