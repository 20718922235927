import React from 'react';
import PropTypes from 'prop-types';

const GenericFacility = props => (
  <React.Fragment>{I18n.t(`components.ibe.car_generic_facilities.${props.type}`)}</React.Fragment>
);

GenericFacility.propTypes = {
  type: PropTypes.string.isRequired,
};

export default GenericFacility;
