import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18nText } from '@wtag/react-comp-lib';
import { injectIntl } from 'react-intl';
import TopBar from './TopBar';
import { resetApp } from '../actions/common';

const messages = {
  '/trains': 'components.ibe.title.trains',
  '/cruises': 'components.ibe.title.cruises',
  '/activities': 'components.ibe.title.activities',
  '/tours': 'components.ibe.title.tours',
};

const NotAvailable = props => {
  const path = props.match.path;
  const messageKey = messages[path];
  let appTitle = '';
  if (messageKey) {
    appTitle = I18n.t(messageKey);
  }
  return (
    <div>
      <TopBar
        resetApp={props.resetApp}
        title={appTitle}
        cartId={props.cartId}
        hasStaticProducts={props.hasStaticProducts}
      />
      <div className="container-full">
        <div className="not-available">
          <h2>
            <I18nText id="ibe.not_available.title" returnStringOnly={true} />
          </h2>
          <p>
            <I18nText id="ibe.not_available.subtitle" returnStringOnly={true} />
          </p>
        </div>
      </div>
    </div>
  );
};

NotAvailable.defaultProps = {
  cartId: null,
};

NotAvailable.propTypes = {
  resetApp: PropTypes.func.isRequired,
  cartId: PropTypes.string,
  hasStaticProducts: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  displayResults: ownProps.children !== null,
  searchParams: state.flights.searchParamsBySearchId[ownProps.match.params.searchId],
  cartId: state.common.cart.id,
  hasStaticProducts:
    state.common.staticProducts && Object.keys(state.common.staticProducts).length > 0,
});

const mapDispatchToProps = dispatch => ({
  resetApp: () => dispatch(resetApp()),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NotAvailable));
