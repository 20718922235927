import { fetchPlaceDetails } from '../../actions/common';

export const CAR_SET_DEFAULT_SEARCH_PARAMS = 'CAR_SET_DEFAULT_SEARCH_PARAMS';
export function carSetDefaultSearchParams(params) {
  return dispatch => {
    dispatch({
      type: CAR_SET_DEFAULT_SEARCH_PARAMS,
      params,
    });
    return Promise.all([
      dispatch(fetchPlaceDetails(params.pickUpLocationId)),
      dispatch(fetchPlaceDetails(params.dropOffLocationId)),
    ]);
  };
}
