import React, { useState, Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ChooseFile from 'sharedWebpack/ChooseFile';
import Button from '@wtag/rcl-button';
import Card from '@wtag/rcl-card';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import { CheckBox, Link, Spinner } from '@wtag/react-comp-lib';
import { v4 as uuidv4 } from 'uuid';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import DatePickerWithFocusedStateWrapper from 'sharedWebpack/DatePickerWithFocusedStateWrapper';
import DropDown from '../../../admin/DropDown';
import '../styles.scss';

const Form = ({
  availableCurrencies,
  urls,
  isEditing,
  submitText,
  submitAction,
  travelExpense,
  setDate,
  date,
  orderId,
  travellerId,
  scope,
  forOrderType,
  forTravellerType,
}) => {
  const [name, setName] = useState('');
  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTraveller, setSelectedTraveller] = useState(null);
  const [vatIncluded, setVatIncluded] = useState(false);
  const [showVatField, setShowVatField] = useState(false);
  const [vatPercentage, setVatPecentage] = useState('');
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const travelExpenseId = travelExpense && travelExpense.id;
  const layman = scope === 'public';
  const isOrderFieldDisabled = forOrderType;
  const isTravellerFieldDisabled = forTravellerType;

  const currencyMenu = availableCurrencies.map(currencyItem => ({
    label: currencyItem[0],
    value: currencyItem[1],
  }));

  const mapTraveller = travellerObj => ({
    label: `${travellerObj.id}: ${travellerObj.name}`,
    value: travellerObj.id,
  });

  const mapOrder = orderObj => ({
    label: `${orderObj.id}: ${orderObj.title}`,
    value: orderObj.id,
  });

  const fetchTraveller = async () => {
    const { data } = await httpClient.get(routes[scope].people.show({ personId: travellerId }));

    if (data && data.person) {
      const fetchedTraveller = mapTraveller(data.person);
      setSelectedTraveller(fetchedTraveller);
    }
  };

  const fetchOrder = async () => {
    const { data } = await httpClient.get(routes[scope].order({ id: orderId }));

    if (data && data.order) {
      const fetchedOrder = mapOrder(data.order);
      setSelectedOrder(fetchedOrder);
    }
  };

  const findSelectBoxContent = (options, value) => {
    const selectedOption = options.find(option => value === option.value);

    return selectedOption;
  };

  const selectFile = selectedFile => {
    setFileName(selectedFile.name);
    setFile(selectedFile);
  };

  const onSubmit = () => {
    const formData = new FormData();
    if (file) {
      formData.append('travel_expense[file]', file);
    }
    formData.append('travel_expense[name]', name);
    formData.append('travel_expense[date]', date);
    formData.append('travel_expense[amount]', amount);
    formData.append('travel_expense[currency]', currency);
    formData.append('travel_expense[vat_percentage]', vatPercentage);
    formData.append('travel_expense[vat_included]', vatIncluded);
    formData.append(
      'travel_expense[person_id]',
      (selectedTraveller && selectedTraveller.value) || '',
    );
    formData.append('travel_expense[order_id]', (selectedOrder && selectedOrder.value) || '');

    submitAction(formData).catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });
  };

  const setInitialValues = async () => {
    setName(travelExpense.name);
    setDate(travelExpense.date);
    setAmount(travelExpense.amount);
    setCurrency(travelExpense.currency);
    setVatIncluded(travelExpense.vatIncluded);
    setShowVatField(travelExpense.vatIncluded);
    setVatPecentage(travelExpense.vatPercentage);
    setFileName(travelExpense.fileName);
  };

  useEffect(() => {
    const fetchFormData = async () => {
      if (travelExpenseId) {
        await setInitialValues();
      }

      if (orderId) {
        await fetchOrder();
      }

      if (travellerId) {
        await fetchTraveller();
      }
      setIsLoading(false);
    };

    fetchFormData();
  }, [travelExpenseId, orderId, travellerId]);

  const memoizedUuid = useMemo(() => uuidv4(), []);

  return (
    <Fragment>
      {!isLoading ? (
        <div className="travel-expense-form">
          <div className="travel-expense-form__title">
            {isEditing
              ? I18n.t('admin.components.travel_expenses.actions.edit')
              : I18n.t('admin.components.travel_expenses.title')}
          </div>
          <Card version="v2" className="travel-expense-form__card">
            <div className="travel-expense-form__rows">
              <div className="col-6 col-bleed travel-expense-form__right-fields">
                <DatePickerWithFocusedStateWrapper
                  placeholder={I18n.t('admin.components.travel_expenses.fields.date.placeholder')}
                  label={I18n.t('admin.components.travel_expenses.date')}
                  size="tiny"
                  id={memoizedUuid}
                  fullWidth={true}
                  enablePastDates={true}
                  disableFutureDates={false}
                  date={date}
                  required={true}
                  onChange={value => value && setDate(moment(value).format('YYYY-MM-DD'))}
                  error={error.date}
                  hideNavButtons={false}
                />
              </div>
              <div className="col-6 col-bleed">
                <Input
                  size="tiny"
                  placeholder={I18n.t('admin.components.travel_expenses.fields.name.placeholder')}
                  label={I18n.t('admin.components.travel_expenses.table.name')}
                  value={name}
                  onChange={value => setName(value)}
                  error={error.name}
                  required={true}
                  touched={!!error.name}
                />
              </div>
            </div>
            <div className="travel-expense-form__rows">
              <ChooseFile
                errorMsg={error.file}
                fileName={fileName}
                selectFile={value => selectFile(value)}
                required={true}
              />
            </div>
            <div className="travel-expense-form__rows">
              <div className="col-6 col-bleed travel-expense-form__right-fields">
                <SelectBox
                  size="tiny"
                  placeholderText={I18n.t('shared.placeholder.please_select')}
                  label={I18n.t('admin.components.travel_expenses.fields.currency.label')}
                  width="full"
                  onChange={selectedOption => setCurrency(selectedOption && selectedOption.value)}
                  options={currencyMenu}
                  required={true}
                  value={findSelectBoxContent(currencyMenu, currency)}
                  errorMsg={error.currency}
                />
              </div>
              <div className="col-6 col-bleed">
                <Input
                  size="tiny"
                  placeholder={I18n.t('admin.components.travel_expenses.fields.amount.placeholder')}
                  label={I18n.t('admin.components.travel_expenses.amount')}
                  value={amount}
                  onChange={value => setAmount(value)}
                  error={error.amount}
                  required={true}
                  touched={!!error.amount}
                />
              </div>
            </div>
            {!layman && (
              <div className="travel-expense-form__rows">
                <div className="col-6 col-bleed travel-expense-form__right-fields">
                  <DropDown
                    label={I18n.t('admin.components.travel_expenses.order')}
                    type="orders"
                    placeholderText={I18n.t(
                      'admin.components.travel_expenses.fields.order.placeholder',
                    )}
                    size="tiny"
                    width="full"
                    required={false}
                    onChange={setSelectedOrder}
                    value={selectedOrder}
                    isClearable={true}
                    isDisabled={isOrderFieldDisabled}
                    filterParams={{ person_id: travellerId }}
                    errorMsg={error.order}
                  />
                </div>
                <div className="col-6 col-bleed">
                  <DropDown
                    type="order_people"
                    label={I18n.t('admin.components.travel_expenses.traveller')}
                    placeholderText={I18n.t(
                      'admin.components.travel_expenses.fields.traveller.placeholder',
                    )}
                    size="tiny"
                    width="full"
                    required={true}
                    onChange={setSelectedTraveller}
                    value={selectedTraveller}
                    isClearable={true}
                    isDisabled={isTravellerFieldDisabled}
                    filterParams={{ order_id: orderId }}
                    errorMsg={error.person}
                  />
                </div>
              </div>
            )}
            <div className="travel-expense-form__rows">
              <CheckBox
                label={I18n.t('admin.components.travel_expenses.fields.vat')}
                checked={showVatField}
                onChange={() => setShowVatField(!showVatField)}
              />
            </div>
            {showVatField && (
              <div className="col-grid travel-expense-form__vat-field col-bleed direction-row align-end travel-expense-form__vat-field">
                <div className="col-bleed col-6 travel-expense-form__vat-percentage">
                  <Input
                    size="tiny"
                    placeholder={I18n.t(
                      'admin.components.travel_expenses.fields.vat_percentage.placeholder',
                    )}
                    label={I18n.t('admin.components.travel_expenses.vat_percentage')}
                    value={vatPercentage}
                    onChange={value => setVatPecentage(value)}
                    error={error.vatPercentage}
                    touched={!!error.vatPercentage}
                  />
                </div>
                <div className="col-bleed col-6 travel-expense-form__vat-include-field">
                  <CheckBox
                    label={I18n.t('admin.components.travel_expenses.fields.include_vat')}
                    checked={vatIncluded}
                    onChange={() => setVatIncluded(!vatIncluded)}
                  />
                </div>
              </div>
            )}
          </Card>
          <div>
            <Button
              label={submitText}
              version="v2"
              type="primary"
              onClick={onSubmit}
              size="small"
              className="travel-expense-form__submit-button"
            />
            <Link type="button" size="small" modifier="default" href={urls.index}>
              {I18n.t('admin.components.shared.action.cancel')}
            </Link>
          </div>
        </div>
      ) : (
        <div className="travel-expense-form__spinner">
          <Spinner size="huge" bgColor="neutral" />
        </div>
      )}
    </Fragment>
  );
};

Form.defaultProps = {
  travelExpense: {},
  orderId: null,
  scope: 'public',
  isEditing: false,
  forOrderType: false,
  forTravellerType: false,
};

Form.propTypes = {
  availableCurrencies: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  urls: PropTypes.shape({
    index: PropTypes.string.isRequired,
  }).isRequired,
  isEditing: PropTypes.bool,
  submitAction: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  travelExpense: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
    date: PropTypes.string,
    fileName: PropTypes.string,
    name: PropTypes.string,
    vatIncluded: PropTypes.bool,
    vatPercentage: PropTypes.string,
    id: PropTypes.number,
  }),
  date: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  orderId: PropTypes.number,
  travellerId: PropTypes.number.isRequired,
  scope: PropTypes.oneOf(['admin', 'public']),
  forOrderType: PropTypes.bool,
  forTravellerType: PropTypes.bool,
};

export default Form;
