import { fetchPlaceDetails } from '../common';

export const HOTEL_SET_DEFAULT_SEARCH_PARAMS = 'HOTEL_SET_DEFAULT_SEARCH_PARAMS';
export function hotelSetDefaultSearchParams(params) {
  return dispatch => {
    dispatch({
      type: HOTEL_SET_DEFAULT_SEARCH_PARAMS,
      params,
    });
    return Promise.all([dispatch(fetchPlaceDetails(params.placeId))]);
  };
}
