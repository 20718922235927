import React from 'react';
import PropTypes from 'prop-types';

const FuelType = props => (
  <React.Fragment>
    {I18n.t(`components.ibe.cars_facilities.fuel_type.${props.type}`)}
  </React.Fragment>
);

FuelType.defaultProps = {
  type: null,
};

FuelType.propTypes = {
  type: PropTypes.string,
};

export default FuelType;
