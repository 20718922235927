import showdown from 'showdown';
import xss from 'xss';
import routes from 'agentRoutes';

const options = {
  whiteList: {
    ...xss.whiteList,
    span: ['class'],
  },
};
const FilterXSS = new xss.FilterXSS(options);
export default function MarkdownConverter(isLaymanMode) {
  const chatFrontendExtension = () => {
    const orderRelativePath = orderId => {
      const params = {
        id: orderId,
        format: 'html',
      };
      const path = isLaymanMode ? routes.public.orders.show(params) : routes.admin.order(params);
      return path;
    };

    const orderPath = (matchedString, orderId) => {
      const path = orderRelativePath(orderId);

      return `(${path})`;
    };

    const orderHtmlWrapper = (matchedString, orderId) => {
      const path = orderRelativePath(orderId);
      return `<a href=${path} target="_blank">${matchedString}</a>`;
    };

    const approvalConfirmationPath = (_matchedString, confirmationId) => {
      const params = {
        id: confirmationId,
        format: 'html',
      };

      const path = isLaymanMode
        ? routes.public.approvals.confirmations.show(params)
        : routes.admin.approvals.confirmations.redirectToShowPage(params);

      return `(${path})`;
    };

    const approvalRequestPath = (_matchedString, requestId) => {
      const params = {
        id: requestId,
        format: 'html',
      };

      const path = isLaymanMode
        ? routes.public.approvals.requests.show(params)
        : routes.admin.approvals.requests.redirectToShowPage(params);

      return `(${path})`;
    };

    const cartPagePath = (_matchedString, cartId) => {
      const params = {
        id: cartId,
      };

      const path = isLaymanMode ? routes.public.cartPage(params) : routes.admin.cartPage(params);

      return `(${path})`;
    };

    const bookingPath = () => {
      const path = isLaymanMode ? '/' : '/admin';

      return `(${path})`;
    };

    const orderReplacer = {
      type: 'lang',
      regex: /\(order#(\d+)\)/g,
      replace: orderPath,
    };

    const approvalConfirmationReplacer = {
      type: 'lang',
      regex: /\(approval_confirmation#([A-Za-z0-9-]*)\)/g,
      replace: approvalConfirmationPath,
    };

    const approvalRequestReplacer = {
      type: 'lang',
      regex: /\(approval_request#(\d+)\)/g,
      replace: approvalRequestPath,
    };

    const cartPageRedirectionReplacer = {
      type: 'lang',
      regex: /\(cart#([A-Za-z0-9-]*)\)/g,
      replace: cartPagePath,
    };

    const bookingPageRedirectionReplacer = {
      type: 'lang',
      regex: /\(new_booking#\)/g,
      replace: bookingPath,
    };

    const orderReplacerFrontend = {
      type: 'lang',
      regex: /order#(\d+)(?=[\s\n])|order#(\d+)$/gi,
      replace: orderHtmlWrapper,
    };

    const xssFilter = {
      type: 'output',
      filter: text => FilterXSS.process(text),
    };

    const mentionReplacer = {
      type: 'lang',
      regex: /@\(([a-z\sA-Z\d]+)([^)]+)\)/g,
      replace: `<span class="chat-message__mention">@$1</span>`,
    };

    return [
      orderReplacer,
      approvalRequestReplacer,
      approvalConfirmationReplacer,
      cartPageRedirectionReplacer,
      bookingPageRedirectionReplacer,
      xssFilter,
      mentionReplacer,
      orderReplacerFrontend,
    ];
  };

  const converter = new showdown.Converter({
    extensions: [chatFrontendExtension],
    openLinksInNewWindow: true,
    simpleLineBreaks: true,
    emoji: true,
    ghMentions: false,
  });

  function convert(text) {
    return converter.makeHtml(text);
  }

  return {
    convert,
  };
}
