import { CAR_SEARCH_FETCHED } from '../../actions/car';

export default function searchResultsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case CAR_SEARCH_FETCHED:
      newState[action.searchId] = action.results;
      return newState;

    default:
      return state;
  }
}
