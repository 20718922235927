import { TYPE_FLIGHT, TYPE_HOTEL, TYPE_CAR, TYPE_STATIC_PRODUCT } from '../../../helpers/itemTypes';
import generateFlightContent from './generateFlightContent';
import generateHotelContent from './generateHotelContent';
import generateCarContent from './generateCarContent';
import generateStaticContent from './generateStaticContent';

const prepareCartContent = (item, journeyElement, travelers, flightServicesByResultId) => {
  switch (item.type) {
    case TYPE_FLIGHT:
      return generateFlightContent(item, travelers, journeyElement, flightServicesByResultId);
    case TYPE_HOTEL:
      return generateHotelContent(item, travelers, journeyElement);
    case TYPE_CAR:
      return generateCarContent(item, travelers, journeyElement);
    case TYPE_STATIC_PRODUCT:
      return generateStaticContent(item);
    default:
      return '';
  }
};

export default prepareCartContent;
