import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const VISA_ADVISORY_FETCHED = 'VISA_ADVISORY_FETCHED';
function visaAdvisoryFetched(data, citizenshipCountryCode) {
  return {
    type: VISA_ADVISORY_FETCHED,
    data,
    citizenshipCountryCode,
  };
}

export const FETCHING_VISA_ADVISORY = 'FETCHING_VISA_ADVISORY';
function fetchingVisaAdvisory(citizenshipCountryCode) {
  return {
    type: FETCHING_VISA_ADVISORY,
    citizenshipCountryCode,
  };
}
export function fetchVisaAdvisory(citizenshipCountryCode) {
  return (dispatch, getState) => {
    dispatch(fetchingVisaAdvisory(citizenshipCountryCode));

    const cartId = getState().common.cart.id;
    const url = routes.api.carts.fetchVisaAdvisory({
      cartId,
      citizenship_country_code: citizenshipCountryCode,
    });
    return httpClient
      .get(url)
      .then(({ data }) => dispatch(visaAdvisoryFetched(data, citizenshipCountryCode)));
  };
}
