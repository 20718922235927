import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'sharedWebpack/Alert';
import Button from '@wtag/rcl-button';
import httpClient from 'agentHTTPV3Client';
import routes from 'agentRoutes';
import { SidePanel } from '@wtag/react-comp-lib';
import ChangeApproversSidePanelBody from '../ChangeApproversSidePanelBody';
import './style.scss';

const ApprovalRequestBar = ({ orderID, startApprovalProcess, order }) => {
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [mandatoryApprovers, setMandatoryApprovers] = useState([]);
  const [optionalApprovers, setOptionalApprovers] = useState([]);
  const [potentialApprovers, setPotentialApprovers] = useState([]);
  const [selectedOptionalApprovers, setSelectedOptionalApprovers] = useState([]);
  const [minimumApproverCount, setMinimumApproverCount] = useState(1);

  const fetchApprovers = async () => {
    const { data } = await httpClient.get(
      routes.api.orders.expectedApprovers({
        id: orderID,
      }),
    );
    setMandatoryApprovers(data.approvers.mandatory);
    setOptionalApprovers(data.approvers.optional);
    setPotentialApprovers(data.approvers.potential);
    setSelectedOptionalApprovers(data.approvers.optional);
    setMinimumApproverCount(data.minimumApprovals);
  };

  useEffect(() => {
    fetchApprovers();
  }, []);

  const hideSidePanel = () => {
    setOpenSidePanel(false);
  };

  const selectedApproverIds = selectedOptionalApprovers.map(approver => approver.id);
  const optionalApproverIds = optionalApprovers.map(approver => approver.id);
  const optionalApproverUnchanged = () => {
    if (selectedApproverIds.length !== optionalApproverIds.length) {
      return false;
    }

    const newApproverIds = selectedApproverIds.filter(
      selectedApproverId => !optionalApproverIds.includes(selectedApproverId),
    );

    return newApproverIds.length === 0;
  };

  const startApprovalProcessWithOptionalCheck = () => {
    let optionalApproverIdsParams = null;
    if (!optionalApproverUnchanged()) {
      optionalApproverIdsParams = selectedApproverIds;
    }
    startApprovalProcess(optionalApproverIdsParams);
    hideSidePanel();
  };

  const approvalRequestStatusMapper = status => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      case 'denied':
        return 'danger';
      default:
        return 'warning';
    }
  };

  return (
    <Alert
      className="order-approval-request-bar"
      hideClose={true}
      isVisible={order.requiresApproval}
      type={approvalRequestStatusMapper(order.approvalRequestStatus)}
    >
      <div className="col-12 col-bleed col-grid direction-row justify-space-between align-center">
        <span>
          {order.approvalRequestStatus
            ? I18n.t(order.approvalRequestStatus, {
              scope: 'public.orders.show.approval.status',
            })
            : I18n.t('public.orders.show.approval.intro')}
        </span>
        <SidePanel
          title={I18n.t('public.orders.show.approval.start_process')}
          body={
            <ChangeApproversSidePanelBody
              mandatoryApprovers={mandatoryApprovers}
              optionalApprovers={optionalApprovers}
              potentialApprovers={potentialApprovers}
              minimumApproverCount={minimumApproverCount}
              selectedOptionalApprovers={selectedOptionalApprovers}
              setSelectedOptionalApprovers={setSelectedOptionalApprovers}
              startApprovalProcess={startApprovalProcessWithOptionalCheck}
              hideSidePanel={hideSidePanel}
            />
          }
          isOpened={openSidePanel}
          onClick={() => hideSidePanel()}
        />
        {!order.approvalRequestStatus && (
          <Button
            label={I18n.t('public.orders.show.approval.start_process')}
            size="small"
            version="v2"
            onClick={() => setOpenSidePanel(true)}
          />
        )}
      </div>
    </Alert>
  );
};

ApprovalRequestBar.propTypes = {
  orderID: PropTypes.number.isRequired,
  order: PropTypes.shape({
    requiresApproval: PropTypes.bool,
    approvalRequestStatus: PropTypes.string,
  }).isRequired,
  startApprovalProcess: PropTypes.func.isRequired,
};

export default ApprovalRequestBar;
