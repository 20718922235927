import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const FARE_BASIS_INFO_FETCHED = 'FARE_BASIS_INFO_FETCHED';
function fareBasisInfoFetched(resultId, data) {
  return {
    type: FARE_BASIS_INFO_FETCHED,
    resultId,
    data,
  };
}

export const FETCHING_FARE_BASIS_INFO = 'FETCHING_FARE_BASIS_INFO';
export function fetchFareBasisInfo(resultId) {
  return dispatch => {
    dispatch({
      type: FETCHING_FARE_BASIS_INFO,
      resultId,
    });

    return httpClient.get(routes.api.flights.fareBasisInfo({ resultId }))
      .then(({ data }) => dispatch(fareBasisInfoFetched(resultId, data)));
  };
}
