import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import './style.scss';

const GraphicElement = ({ stopoverAirportCodes, changedAirportCodes }) => {
  const changedAirportCodesSet = new Set(changedAirportCodes);

  const renderStopoverGraphic = stopoverAirportCode => {
    if (changedAirportCodesSet.has(stopoverAirportCode)) {
      return (
        <div className="stopover-graphic__airport-change-icon">
          <Icon name="airportChange" size="normal" />
        </div>
      );
    }

    return <div className="stopover-graphic__circle" />;
  };

  return (
    <div className="stopover-graphic">
      {stopoverAirportCodes.map(code => renderStopoverGraphic(code))}
    </div>
  );
};

GraphicElement.propTypes = {
  stopoverAirportCodes: PropTypes.shape([PropTypes.string]).isRequired,
  changedAirportCodes: PropTypes.shape([PropTypes.string]).isRequired,
};

export default GraphicElement;
