import {
  FLIGHT_SEARCH_FETCHED,
  UPDATE_FLIGHT_SEARCH_FILTERS,
  RESET_FLIGHT_SEARCH_FILTERS,
  RESET_FLIGHT_SEARCH_FILTERS_BY_KEY,
} from '../../actions/flight';

const initialState = () => ({
  price: { min: null, max: null },
  prevPrice: { min: null, max: null },
  stopoverCounts: [],
  prevStopoverCounts: [],
  airlines: [],
  prevAirlines: [],
  caterings: [],
  prevCaterings: [],
  baggage: [],
  prevBaggage: [],
  multiTicket: [],
  prevMultiTicket: [],
  tripSpecific: [],
  prevTripSpecific: [],
  sortBy: null,
  filters: {
    airlines: null,
    caterings: null,
    baggage: null,
    stopoverCounts: null,
    multiTicket: null,
    total_price: null,
  },
  isAllChecked: {
    stopoverCounts: false,
    baggage: false,
    caterings: false,
    airlines: false,
    multiTicket: false,
  },
});

function setFilterParams(state, action) {
  const newState = Object.assign({}, state);
  const searchId = action.searchId;

  if (!newState[searchId]) {
    newState[searchId] = initialState();
  }

  let {
    airlines,
    stopoverCounts,
    caterings,
    baggage,
    multiTicket,
    tripSpecific,
    prevTripSpecific,
    sortBy,
    prevStopoverCounts,
    prevAirlines,
    prevCaterings,
    prevBaggage,
    prevMultiTicket,
  } = newState[searchId];
  const { price, prevPrice, filters, isAllChecked } = newState[searchId];

  if (!airlines.length) {
    airlines = action.stats.airline.map(airline => airline.code);
    prevAirlines = action.stats.airline.map(airline => airline.code);
  }

  if (!stopoverCounts.length) {
    stopoverCounts = action.stats.stopoverCount;
    prevStopoverCounts = action.stats.stopoverCount;
  }

  if (price.min === null) {
    price.min = action.stats.price.min;
    prevPrice.min = action.stats.price.min;
  }

  if (price.max === null) {
    price.max = action.stats.price.max;
    prevPrice.max = action.stats.price.max;
  }

  if (!caterings.length) {
    caterings = action.stats.catering;
    prevCaterings = action.stats.catering;
  }

  if (!baggage.length) {
    baggage = action.stats.baggage;
    prevBaggage = action.stats.baggage;
  }

  if (!multiTicket.length) {
    multiTicket = action.stats.multiTicket;
    prevMultiTicket = action.stats.multiTicket;
  }

  if (!tripSpecific.length) {
    tripSpecific = action.stats.tripSpecific.map(specific => ({
      key: specific.key,
      departureTimes: specific.departureTime,
      arrivalTimes: specific.arrivalTime,
      tripDurationMinutes: specific.tripDurationMinutes,
      prices: specific.price,
    }));
    prevTripSpecific = action.stats.tripSpecific.map(specific => ({
      key: specific.key,
      departureTimes: specific.departureTime,
      arrivalTimes: specific.arrivalTime,
      tripDurationMinutes: specific.tripDurationMinutes,
      prices: specific.price,
    }));
  }

  if (sortBy === null) {
    sortBy = 'price_asc';
  }

  newState[searchId] = {
    price,
    prevPrice,
    stopoverCounts,
    prevStopoverCounts,
    airlines,
    prevAirlines,
    caterings,
    prevCaterings,
    baggage,
    prevBaggage,
    multiTicket,
    prevMultiTicket,
    tripSpecific,
    prevTripSpecific,
    sortBy,
    filters,
    isAllChecked,
  };

  return newState;
}

function searchFilterParamsByKey(state, action) {
  const initialData = action.stats.initialData;
  const dataToreset = action.stats.changedData;
  const searchId = action.searchId;
  const key = action.stats.key;

  if (key) {
    const price = {
      min: initialData.min,
      max: initialData.max,
    };
    const filteredState = { ...state[searchId], price };
    const newFilterParams = {};
    newFilterParams[searchId] = filteredState;
    return newFilterParams;
  }

  const matchedData = initialData.filter(data => data.key === dataToreset[0].key);
  const newChangedDataResetValue = [
    {
      arrivalTimes: matchedData[0].arrivalTime,
      departureTimes: matchedData[0].departureTime,
      key: matchedData[0].key,
      prices: matchedData[0].price,
      tripDurationMinutes: matchedData[0].tripDurationMinutes,
    },
  ];
  const tripSpecific = state[searchId].tripSpecific.map(
    trip => newChangedDataResetValue.find(data => data.key === trip.key) || trip,
  );
  const filteredState = { ...state[searchId], tripSpecific };
  const newFilterParams = {};
  newFilterParams[searchId] = filteredState;

  return newFilterParams;
}

function updateFilterParams(state, action) {
  const newState = Object.assign({}, state);
  const { key, filter, value, isAllChecked } = action;
  const searchId = action.searchId;

  const filterText = {
    price: I18n.t('components.ibe.search_results.filters.total_price'),
    airlines: I18n.t('components.ibe.search_results.filters.airlines'),
    caterings: I18n.t('components.ibe.search_results.filters.catering'),
    baggage: I18n.t('components.ibe.search_results.filters.baggages'),
    stopoverCounts: I18n.t('components.ibe.search_results.filters.stops'),
    multiTicket: I18n.t('components.ibe.search_results.filters.tickets'),
    arrivalTimes: I18n.t('components.ibe.search_results.filters.arrival_time'),
    departureTimes: I18n.t('components.ibe.search_results.filters.departure_time'),
    prices: I18n.t('components.ibe.search_results.filters.price'),
    tripDurationMinutes: I18n.t('components.ibe.search_results.filters.duration'),
  };

  const prevFilter = filter && `prev${filter.charAt(0).toUpperCase() + filter.slice(1)}`;

  if (key) {
    const prevValue = newState[searchId].prevTripSpecific.find(specific => specific.key === key)[
      filter
    ];

    let specificFilter = newState[searchId].filters[`trip${key}`] || [];

    // These conditions are used to control the filter.

    if (
      Math.floor(value.min) !== Math.floor(prevValue.min) ||
      Math.ceil(value.max) !== Math.ceil(prevValue.max)
    ) {
      newState[searchId].filters[`trip${key}`] = !specificFilter.includes(filterText[filter])
        ? specificFilter.concat(filterText[filter])
        : specificFilter;
    } else {
      specificFilter = specificFilter.filter(option => option !== filterText[filter]);
      newState[searchId].filters[`trip${key}`] = specificFilter;

      if (newState[searchId].filters[`trip${key}`].length === 0) {
        newState[searchId].filters[`trip${key}`] = null;
      }
    }

    newState[searchId].tripSpecific.find(specific => specific.key === key)[filter] = value;
  } else {
    newState[searchId][filter] = value;
    newState[searchId].isAllChecked[`${filter}`] = isAllChecked;
    if (filter === 'price') {
      newState[searchId].filters[filter] =
        Math.ceil(newState[searchId][filter].max) !==
          Math.ceil(newState[searchId][prevFilter].max) ||
        Math.floor(newState[searchId][filter].min) !==
          Math.floor(newState[searchId][prevFilter].min)
          ? filterText.price
          : null;
    } else if (filter === 'sortBy') {
      newState[searchId][filter] = value;
    } else {
      const isAllResultsVisible =
        newState[searchId][filter].length === 0 ||
        newState[searchId][filter].length === newState[searchId][prevFilter].length;
      newState[searchId].filters[filter] = isAllResultsVisible ? null : filterText[filter];
    }
  }

  return newState;
}

export default function searchFilterParamsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case FLIGHT_SEARCH_FETCHED:
      return setFilterParams(newState, action);

    case UPDATE_FLIGHT_SEARCH_FILTERS:
      return updateFilterParams(newState, action);

    case RESET_FLIGHT_SEARCH_FILTERS:
      return setFilterParams({}, action);

    case RESET_FLIGHT_SEARCH_FILTERS_BY_KEY:
      return searchFilterParamsByKey(newState, action);

    default:
      return state;
  }
}
