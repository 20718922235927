import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'throttle-debounce/debounce';

import './styles.scss';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: '' };
  }

  onSubmit = event => {
    event.preventDefault();
    this.props.handleSearch(this.state.value);
  };

  onChange = event => {
    this.setState({ value: event.target.value }, () => this.handleSearch(this.state.value));
  };

  handleSearch = debounce(300, query => this.props.handleSearch(query));

  render() {
    return (
      <form
        className={classNames('search-bar', { 'search-bar--full-width': this.props.fullWidth })}
        onSubmit={this.onSubmit}
      >
        <input
          className="input-field search-bar__input"
          type="search"
          value={this.state.value}
          onChange={this.onChange}
          placeholder={this.props.placeholder}
        />
        <input
          className="search-bar__button button"
          type="submit"
          value={I18n.t('shared.actions.search')}
        />
      </form>
    );
  }
}

SearchBar.defaultProps = {
  fullWidth: false,
  placeholder: I18n.t('simple_form.placeholders.order_list.search'),
};

SearchBar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default SearchBar;
