import React, { useState } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { Header, Text } from '@wtag/rcl-typography';
import Button from '@wtag/rcl-button';
import { Link } from '@wtag/react-comp-lib';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import Alert from 'sharedWebpack/Alert';
import Input from '@wtag/rcl-input';
import LevelCards from '../LevelCards';

import '../styles.scss';

const Create = ({ urls, levels }) => {
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sendInvitation = async () => {
    const params = {
      travel_arranger_invitations: {
        email,
        level: selectedLevel,
      },
    };
    const { data } = await httpClient.post(
      routes.public.travelArrangerSettings.arrangers.create(),
      params,
    );
    if (data.error === null) {
      setIsModalOpen(true);
    }
  };

  const onSendInvitation = () =>
    sendInvitation().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  return (
    <div className="grid">
      <ConfirmationModal
        confirmationHeader={
          <div className="col-12 col-bleed">
            {I18n.t(
              'public.components.travel_arrangement_settings.confirmation.send_invitation.header',
            )}
          </div>
        }
        subHeader={
          <div className="col-12 col-bleed">
            {I18n.t(
              'public.components.travel_arrangement_settings.confirmation.send_invitation.sub_header',
              {
                email,
              },
            )}
          </div>
        }
        confirmationText={I18n.t(
          'public.components.travel_arrangement_settings.confirmation.send_invitation.label.finish',
        )}
        rejectionText={I18n.t(
          'public.components.travel_arrangement_settings.confirmation.send_invitation.label.send_another',
        )}
        withAction={true}
        type="success"
        isModalOpen={isModalOpen}
        onReject={() => window.location.reload()}
        onConfirm={() => window.location.replace(urls.list)}
        onConfirmationModalOpen={() => setIsModalOpen(true)}
        onConfirmationModalClose={() => setIsModalOpen(false)}
      />
      <div className="col-12 col-bleed-x col-grid direction-row justify-space-between travel-arranger__header">
        <Header level={5} weight="medium">
          {I18n.t('public.components.travel_arrangement_settings.send_invitation.new_invite')}
        </Header>
        <div>
          <Link href={urls.list} type="button" size="small" modifier="default">
            {I18n.t('public.components.travel_arrangement_settings.send_invitation.label.cancel')}
          </Link>
          <span className="send-invitation__actions--with-margin-left">
            <Button
              label={I18n.t('public.components.travel_arrangement_settings.label.send_invitation')}
              size="small"
              version="v2"
              onClick={onSendInvitation}
              padding="left"
            />
          </span>
        </div>
      </div>
      <Alert
        className="col-12 send-invitation__alert"
        isVisible={!!error}
        type="danger"
        hideClose={true}
      >
        {error}
      </Alert>
      <div className="col-12 col-grid align-center send-invitation__container">
        <div className="col-4">
          <Text level={2} weight="medium">
            {I18n.t(
              'public.components.travel_arrangement_settings.send_invitation.explanation.email',
            )}
          </Text>
        </div>
        <br />
        <div className="col-4 col-bleed-y travel-arranger__input-container">
          <Input
            size="tiny"
            placeholder={I18n.t(
              'public.components.travel_arrangement_settings.send_invitation.placeholder',
            )}
            onChange={value => setEmail(value)}
            value={email}
          />
        </div>
        <br />
        <br />
        <div className="col-4">
          <Text level={3} weight="medium">
            {I18n.t(
              'public.components.travel_arrangement_settings.send_invitation.explanation.level',
            )}
          </Text>
        </div>
        <div className="grid justify-center">
          <LevelCards
            levels={levels}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
          />
        </div>
        <div className="col-12">
          {selectedLevel && (
            <Text level={3} weight="light" className="send-invitation__explanation">
              <span className="send-invitation__explanation--with-padding-right">
                {I18n.t(selectedLevel, {
                  scope: 'public.components.travel_arrangement_settings.send_invitation.level',
                })}
              </span>
              {I18n.t(selectedLevel, {
                scope: 'public.travel_arrangers.level_description',
              })}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

Create.propTypes = {
  urls: PropTypes.shape({
    list: PropTypes.string.isRequired,
  }).isRequired,
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Create;
