import { connect } from 'react-redux';
import { setVoucherInCart } from '../../actions/common';

import Voucher from './';

const mapStateToProps = state => ({
  voucher: state.common.cart.voucher,
});

const mapDispatchToProps = dispatch => ({
  setVoucherInCart: number => dispatch(setVoucherInCart(number)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
