import { MAX_STOPOVER_TIME } from './stopoverConstants';

const calculateStopoverStops = segments => {
  const validSegments = segments || [];
  let stopCount = 0;
  let stopoverCount = 0;

  validSegments.forEach(({ transitDurationsInMinutes }) => {
    if (transitDurationsInMinutes <= MAX_STOPOVER_TIME) {
      stopCount += 1;
    } else {
      stopoverCount += 1;
    }
  });

  return { stopCount, stopoverCount };
};

const getFlightStopsCountLabel = segments => {
  const { stopCount, stopoverCount } = calculateStopoverStops(segments);

  if (stopCount > 0 && stopoverCount > 0) {
    return I18n.t('components.flight.stopovers.count.both_stop_stopover', {
      stopCount,
      stopoverCount,
    });
  }

  if (stopoverCount > 0) {
    return I18n.t('components.flight.stopovers.count.only_stopover', {
      stopoverCount,
    });
  }

  if (stopCount > 0) {
    return I18n.t('components.flight.stopovers.count.only_stop', {
      stopCount,
    });
  }

  return I18n.t('components.flight.stopovers.count.non_stop');
};

export default getFlightStopsCountLabel;
