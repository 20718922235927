import { AIRCRAFT_TYPE_FETCHED } from '../../actions/flight';

export default function aircraftTypes(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case AIRCRAFT_TYPE_FETCHED:
      newState[action.code] = action.aircraftType;
      return newState;

    default:
      return state;
  }
}
