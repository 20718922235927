import {
  TRAVELERS_SAVING_FAILED,
  RESET_TRAVELERS_ERROR,
  UPDATE_TRAVELERS_SAVING_STATUS,
} from '../../actions/common';

const initialState = { status: false, errorList: [] };
export default function travelersError(state = initialState, action) {
  const filterErrors = state.errorList.filter(error => error.travelerIndex !== action.index);
  switch (action.type) {
    case TRAVELERS_SAVING_FAILED:
      return { ...state, errorList: [...filterErrors, action.error] };

    case UPDATE_TRAVELERS_SAVING_STATUS:
      return { ...state, status: action.status };

    case RESET_TRAVELERS_ERROR:
      return { ...state, errorList: [] };

    default:
      return state;
  }
}
