import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hot } from 'react-hot-loader';
import { SidePanel } from '@wtag/react-comp-lib';
import { TYPE_SERVICE, TYPE_SEAT } from 'sharedWebpack/helpers/itemTypes';
import { AccordionItem } from '@wtag/rcl-accordion';
import FlightDetails from 'sharedWebpack/IBE/SearchResult/flight/FlightDetails';
import SearchFlightInfo from 'sharedWebpack/IBE/SearchResult/flight/SearchFlightInfo';
import AirlineStandardLogos from 'sharedWebpack/IBE/SearchResult/flight/AirlineStandardLogos';
import SeatCountIconWithLabel from 'sharedWebpack/IBE/SearchResult/flight/SeatCountIconWithLabel';
import AncillaryServices from 'sharedWebpack/IBE/Cart/Items/flight/AncillaryServices';
import FlightSeatMap from 'sharedWebpack/IBE/Cart/Items/flight/SeatDetails/FlightSeatMapContainer';
import getServices from 'sharedWebpack/IBE/Cart/helpers/getServices';
import getSegmentServicesByTripWithQuantities from 'sharedWebpack/IBE/Cart/helpers/getSegmentServicesByTripWithQuantities';
import getTripServicesWithQuantities from 'sharedWebpack/IBE/Cart/helpers/getTripServicesWithQuantities';
import getItemServicesWithQuantities from 'sharedWebpack/IBE/Cart/helpers/getItemServicesWithQuantities';
import getSegmentSeatsBySeatId from 'sharedWebpack/IBE/Cart/helpers/getSegmentSeatsBySeatId';
import TripDetailsHeader from '../TripDetailsHeader';
import getServiceList from './helpers/getServiceList';
import getSeatList from './helpers/getSeatList';
import AncillaryItemWrapper from './AncillaryItemWrapper';
import './styles.scss';

const getUniqueFlightServicesItems = flightServices => {
  if (!Array.isArray(flightServices)) {
    return [];
  }

  const flightServicesMapItems = {};
  flightServices.forEach(({ id, description }) => {
    flightServicesMapItems[id] = description;
  });

  return Object.values(flightServicesMapItems);
};

const Trip = ({
  item,
  flightServicesByResultId,
  tripList,
  trip,
  flightServices,
  showFlightSeatsStatus,
  totalTravelerCount,
  payLater,
  shouldLockScroll,
  setShouldLockScroll,
  showMinimalInfo,
  laymanMode,
  isPostBooking,
}) => {
  const { subItems, bookingAttributes, id: itemId } = item || {};
  const [activeAccordionKey, setActiveAccordionKey] = useState(false);
  const handleAccordionClick = () => {
    setActiveAccordionKey(prev => !prev);
  };

  const {
    id,
    originCode,
    startsAtInUserTz,
    endsAtInUserTz,
    originTerminal,
    segments,
    stopovers,
    durationInMinutes,
    destinationCode,
    destinationTerminal,
    availableSeatCount,
    cabinClass: cabinClasses,
    bookingClasses,
    overnight,
    fareType: fareTypes,
  } = trip;
  const [sidePanelTitle, setSidePanelTitle] = useState(null);
  const [sidePanelBody, setSidePanelBody] = useState(null);
  const [openSidePanel, setOpenSidePanel] = useState(false);

  const hideSidePanel = () => {
    setOpenSidePanel(false);
  };

  const bookingClassesText = bookingClasses.join(', ');
  const tripAirlineLogos = segments.map(({ marketingAirlineInfo }) => marketingAirlineInfo);
  const tripAirlineCarriers = tripAirlineLogos.map(({ code }) => code);
  const marketingAirlineCodes = segments.map(({ marketingFlightNumber, marketingCarrierCode }) => ({
    code: `${marketingCarrierCode}${marketingFlightNumber}`,
    logo: marketingCarrierCode,
  }));

  const allSeatsIDsBySegment = segments.flatMap(({ seats }) =>
    seats.map(({ id: seatId }) => seatId),
  );
  const services = flightServicesByResultId && flightServicesByResultId[bookingAttributes.resultId];
  const seatMaps = services && services.seatMaps;
  const allSeats = getSegmentSeatsBySeatId(subItems, allSeatsIDsBySegment);
  const availableFlightServices = services && getServices(services);

  const tripSegmentServices = getSegmentServicesByTripWithQuantities(
    availableFlightServices,
    subItems,
    originCode,
    destinationCode,
  );

  const wholeTripServices = getTripServicesWithQuantities(
    availableFlightServices,
    subItems,
    originCode,
    destinationCode,
  );

  const flightItemServices = getItemServicesWithQuantities(availableFlightServices, subItems);
  const allItemServices = [...tripSegmentServices, ...wholeTripServices, ...flightItemServices];
  const isSeatAvailable =
    seatMaps &&
    seatMaps.length > 0 &&
    seatMaps.filter(seatmap => seatmap.sections.length > 0).length > 0;

  const baggagesPiecesListFromSegments = segments
    .filter(({ baggagePiecesIncluded: { count, kgPerPiece } }) => count && kgPerPiece)
    .map(({ baggagePiecesIncluded: { count, kgPerPiece } }) =>
      I18n.t(
        'activerecord.attributes.journey_element/flight_segment.baggage_pieces_included_count_with_weight',
        { count, unit_weight: kgPerPiece },
      ),
    );

  const uniqueBaggagePiecesTextList = baggagesPiecesListFromSegments.filter(
    (text, index) => baggagesPiecesListFromSegments.indexOf(text) === index,
  );

  const flightServicesItems = getUniqueFlightServicesItems(flightServices);
  const eTicketsPresent = segments.some(segment => segment.tickets.length > 0);

  const isServiceAvailable = !!(
    uniqueBaggagePiecesTextList.length ||
    flightServicesItems?.length ||
    availableFlightServices?.length
  );

  const seatAmountsAndTitles = allSeats.map(({ grossAmount, itemTitle }) => ({
    grossAmount,
    itemTitle,
  }));

  return (
    <AccordionItem
      className={classNames('w-100', {
        'flight-item-accordion--open': activeAccordionKey,
      })}
      id={id}
      header={<TripDetailsHeader airlineLogos={tripAirlineLogos} tripDetails={trip} />}
      isActive={activeAccordionKey}
      onClick={handleAccordionClick}
    >
      <div className="d-flex justify-between flight-logos-seats__container">
        <div className="col-lg-7 col-12 col-bleed col flight-logos-qr-codes">
          <AirlineStandardLogos
            className="flight__airline-summary"
            carriers={tripAirlineCarriers}
            codes={marketingAirlineCodes}
          />
        </div>
        {showFlightSeatsStatus && (
          <div className="d-flex justify-center">
            <SeatCountIconWithLabel
              className="justify-end"
              seatCount={availableSeatCount}
              travellerCount={totalTravelerCount}
            />
          </div>
        )}
      </div>
      <FlightDetails
        group={{
          destinationTerminal,
          arrivalTime: endsAtInUserTz,
          destinationCode,
          tripDurationInMinutes: durationInMinutes,
          id,
          stopovers,
          segments,
          originTerminal,
          departureTime: startsAtInUserTz,
          originCode,
        }}
        setSidePanelTitle={setSidePanelTitle}
        setSidePanelBody={setSidePanelBody}
        setOpenSidePanel={setOpenSidePanel}
      />
      <SidePanel
        className="deal-item__side-panel"
        title={sidePanelTitle}
        body={sidePanelBody}
        isOpened={openSidePanel}
        onClick={hideSidePanel}
      />
      <SearchFlightInfo
        group={{
          resultId: id,
          cabinClasses,
          bookingClasses: bookingClassesText,
          overnight,
          fareTypes,
          payLater,
        }}
        laymanMode={laymanMode}
        showMinimalInfo={showMinimalInfo}
        eTicketsPresent={eTicketsPresent}
        tripId={id}
        tripSegments={segments}
      />
      {!isPostBooking && Object.keys(item).length > 0 && (
        <div className="d-flex-col flight-services__actions">
          <div className="d-flex justify-between w-100 flight-services__actions__content">
            <AncillaryItemWrapper
              ancillaries={getSeatList(segments, seatAmountsAndTitles)}
              isItemAvailable={isSeatAvailable}
              type={TYPE_SEAT}
            />

            <FlightSeatMap
              trips={tripList}
              seatMaps={services && services.seatMaps}
              resultId={bookingAttributes && bookingAttributes.resultId}
              itemId={itemId}
              shouldLockScroll={shouldLockScroll}
            />
          </div>
          <div className="d-flex justify-between w-100 flight-services__actions__content">
            <span className="d-flex align-center flight-tags wrap flight-services__actions__content">
              <AncillaryItemWrapper
                ancillaries={getServiceList(
                  isServiceAvailable,
                  uniqueBaggagePiecesTextList,
                  allItemServices,
                )}
                isItemAvailable={isServiceAvailable}
                type={TYPE_SERVICE}
              />
            </span>

            <AncillaryServices
              services={services}
              item={item}
              trips={tripList}
              setShouldLockScroll={setShouldLockScroll}
            />
          </div>
        </div>
      )}
    </AccordionItem>
  );
};

Trip.defaultProps = { laymanMode: false, showMinimalInfo: false, item: {}, isPostBooking: false };

Trip.propTypes = {
  item: PropTypes.shape({
    subItems: PropTypes.arrayOf(PropTypes.shape({})),
    bookingAttributes: PropTypes.shape({ resultId: PropTypes.string }),
    id: PropTypes.number,
  }),
  flightServicesByResultId: PropTypes.shape().isRequired,
  tripList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  trip: PropTypes.shape().isRequired,
  flightServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
    }),
  ).isRequired,
  showFlightSeatsStatus: PropTypes.bool.isRequired,
  totalTravelerCount: PropTypes.number.isRequired,
  payLater: PropTypes.bool.isRequired,
  shouldLockScroll: PropTypes.bool.isRequired,
  setShouldLockScroll: PropTypes.func.isRequired,
  laymanMode: PropTypes.bool,
  showMinimalInfo: PropTypes.bool,
  isPostBooking: PropTypes.bool,
};

export default hot(module)(Trip);
