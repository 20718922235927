import { ONEWAY, ROUNDTRIP, MULTICITY } from 'sharedWebpack/IBE/lib/helpers/tripTypes';

const getDestinationByTripType = (tripType, destinations) => {
  if (!Array.isArray(destinations) || destinations.length === 0 || !tripType) {
    return '';
  }

  if ([ONEWAY, ROUNDTRIP].includes(tripType)) {
    return destinations[0];
  }

  if (tripType === MULTICITY) {
    return destinations[destinations.length - 1];
  }

  return '';
};

export default getDestinationByTripType;
