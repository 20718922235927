import React, { Fragment, useState } from 'react';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import '../styles.scss';

const PasswordResetNew = () => {
  const [email, setEmail] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const resetPassword = async () => {
    const resetPasswordParams = {
      email,
    };

    const { data } = await httpClient.post(routes.public.authentication.passwords.create(), {
      password: resetPasswordParams,
    });

    setConfirmationMessage(data.confirmationMessage);
  };

  const submitForm = event => {
    event.preventDefault();
    resetPassword();
  };

  return (
    <form onSubmit={submitForm}>
      <div className="authentication">
        <div className="authentication-form grid">
          <div className="col-12">
            {confirmationMessage ? (
              <Fragment>{confirmationMessage}</Fragment>
            ) : (
              <Fragment>
                <div className="authentication-form__title">
                  {I18n.t('public.components.authentication.passwords.new.title')}
                </div>
                <div className="authentication-form__description--with-bottom-padding">
                  {I18n.t('public.components.authentication.passwords.new.description')}
                </div>
                <div className="col-12 col-bleed authentication-form__field--with-bottom-padding">
                  <Input
                    required={true}
                    type="email"
                    size="small"
                    placeholder={I18n.t(
                      'public.components.authentication.passwords.placeholders.email',
                    )}
                    label={I18n.t('public.components.authentication.passwords.labels.email')}
                    onChange={value => setEmail(value)}
                    value={email}
                  />
                </div>
                <div className="col-12 col-bleed col-grid direction-row authentication-form__button--with-top-padding">
                  <Button
                    submitType="submit"
                    version="v2"
                    size="normal"
                    label={I18n.t('public.components.authentication.passwords.form.submit_email')}
                    onClick={submitForm}
                    fullWidth={true}
                    primary={true}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default PasswordResetNew;
