import io from 'socket.io-client';

export default function socket(url, toggleReconnectingStatus, authToken) {
  const socketIO = io.connect(url);

  socketIO.on('disconnect', reason => {
    toggleReconnectingStatus(true);
    // Available error messages can be find in here https://socket.io/docs/v3/client-socket-instance/#disconnect
    console.info(`The connection between client and server has been dropped because of ${reason}`);
    if (['io server disconnect', 'io client disconnect'].indexOf(reason) !== -1) {
      socketIO.connect(url);
    }
    // else the socket will automatically try to reconnect
  });

  socketIO.on('reconnect', () => {
    toggleReconnectingStatus(false);
    socketIO.emit('authenticate', { token: authToken });
  });

  function authenticate(token) {
    return new Promise((resolve, reject) => {
      socketIO.on('authenticated', () => {
        resolve(this);
      });
      socketIO.on('unauthorized', (msg, callback) => {
        reject(Error(msg.message));
        callback();
      });
      socketIO.emit('authenticate', { token });
    });
  }

  function getChatRooms() {
    return new Promise(resolve => {
      socketIO.emit('chat.getRooms', '', rooms => {
        resolve(rooms);
      });
    });
  }

  function joinRoom(roomId) {
    return new Promise(resolve => {
      socketIO.emit('chat.join', roomId, () => {
        resolve();
      });
    });
  }

  function getChatHistory(roomId, timestamp, maxMessageTimestamp = null) {
    return new Promise(resolve => {
      socketIO.emit(
        'chat.history',
        roomId,
        { timestampUpperBound: timestamp, timestampLowerBound: maxMessageTimestamp },
        messages => {
          resolve(messages);
        },
      );
    });
  }

  function getRoomUsers(roomId, customer) {
    return new Promise(resolve => {
      socketIO.emit('chat.getRoomUsers', roomId, customer, users => {
        resolve(users);
      });
    });
  }

  function leaveRoom(roomId) {
    return new Promise(resolve => {
      socketIO.emit('chat.leave', roomId, () => {
        resolve();
      });
    });
  }

  function sendMessage(roomId, message, callback) {
    if (socketIO.connected) {
      socketIO.emit('chat.message', roomId, message, callback);
    } else {
      callback({ sent: false });
    }
  }

  function onMessageReceived(callback) {
    socketIO.on('chat.message', callback);
  }

  function disableOnMessageReceived(callback) {
    socketIO.off('chat.message', callback);
  }

  function sendTyping(roomId) {
    socketIO.emit('chat.writing', roomId);
  }

  function receiveTyping(callback) {
    socketIO.on('chat.writing', callback);
  }

  function userStatusReceived(callback) {
    socketIO.on('chat.userstatus', callback);
  }

  function removeUnseenInRoom(roomId, userId, messageId, callback) {
    // Have to provide null as unseenId because its not being used
    // in backend and getting is not efficient work.
    socketIO.emit('chat.removeunseeninroom', null, roomId, userId, messageId, callback);
  }

  function removeUnseen(messageUnseenId, callback) {
    socketIO.emit('chat.removeunseen', messageUnseenId, callback);
  }

  function removeMentionInRoom(roomId, userId, messageId, callback) {
    // Have to provide null as mendtionId because its not being used
    // in backend and getting is not efficient work.
    socketIO.emit('chat.removementioninroom', null, roomId, userId, messageId, callback);
  }

  function markMessageSeen(messageId, userId, isCustomer, callback) {
    socketIO.emit('chat.markMessageAsSeen', messageId, userId, isCustomer, callback);
  }

  function removeMention(mentionId, callback) {
    socketIO.emit('chat.removemention', mentionId, callback);
  }

  return {
    authenticate,
    getChatHistory,
    getChatRooms,
    joinRoom,
    leaveRoom,
    onMessageReceived,
    receiveTyping,
    removeUnseenInRoom,
    removeMentionInRoom,
    removeUnseen,
    removeMention,
    sendMessage,
    sendTyping,
    markMessageSeen,
    userStatusReceived,
    getRoomUsers,
    disableOnMessageReceived,
  };
}
