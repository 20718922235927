import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Seat from './SeatMapSeatContainer';
import seatShape from '../shapes/seatShape';

const SeatMapRow = props => {
  const seats = props.seats.map(seat => (
    <Seat
      key={seat.name}
      resultId={props.resultId}
      row={props.number}
      exit={props.exit}
      wing={props.wing}
      flight={props.flight}
      {...seat}
    />
  ));

  const classes = classNames({
    row: true,
    'seat-group': props.wing || props.exit,
    'seat-group-margin': props.groupMap[props.rowIndex],
  });

  return (
    <div className={classes}>
      <div className="seats">
        <div className="row-number">{props.number}</div>
        {props.groupMap[props.rowIndex] && (
          <span className="group-name">{props.groupMap[props.rowIndex]}</span>
        )}
        {seats}
      </div>
    </div>
  );
};

SeatMapRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  groupMap: PropTypes.shape({}).isRequired,
  resultId: PropTypes.string.isRequired,
  seats: PropTypes.arrayOf(seatShape).isRequired,
  exit: PropTypes.bool.isRequired,
  wing: PropTypes.bool.isRequired,
  number: PropTypes.string.isRequired,
  flight: PropTypes.string.isRequired,
};

export default SeatMapRow;
