import snakeCaseKeys from 'snakecase-keys';

export function form2api(formParams, callbackParams) {
  const childrenData = formParams.childrenData || formParams.children;
  const searchParams = {
    check_in: formParams.checkIn,
    check_out: formParams.checkOut,
    number_of_rooms: formParams.numberOfRooms,
    adults: formParams.adults,
    children: childrenData,
    currency: formParams.currency,
    callback_params: callbackParams,
    accounts: formParams.accountSettings,
    preselect_travelers: formParams.preselectTravelers,
  };

  if (formParams.latitude && formParams.longitude) {
    searchParams.geo_location = {
      address: formParams.address,
      latitude: formParams.latitude,
      longitude: formParams.longitude,
    };
  } else {
    searchParams.place_id = formParams.placeId;
  }

  return searchParams;
}

export function api2form(apiParams) {
  const newParams = { ...apiParams };
  if (newParams.geoLocation) {
    newParams.address = newParams.geoLocation.address;
    newParams.latitude = newParams.geoLocation.latitude;
    newParams.longitude = newParams.geoLocation.longitude;
    delete newParams.geoLocation;
  }

  if (newParams.accounts) {
    newParams.accountSettings = snakeCaseKeys(newParams.accounts);
    delete newParams.accounts;
  }

  newParams.childrenData = newParams.children;
  newParams.children = newParams.childrenData.length;

  return newParams;
}
