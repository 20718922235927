import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { hot } from 'react-hot-loader';
import Card from '@wtag/rcl-card';
import AirlineStandardLogos from './AirlineStandardLogos';
import SeatCountIconWithLabel from './SeatCountIconWithLabel';
import { priceItemShape } from './shapes';
import FareBrandList from './FareBrandList';
import FareBrandItem from './FareBrandItem';
import MoreFareBrandsLabel from './MoreFareBrandsLabel';
import FlightDetails from './FlightDetails';
import FlightSelectButton from './FlightSelectButton';
import SearchFlightInfo from './SearchFlightInfo';
import { TYPE_ADULT } from '../../../helpers/passengerTypes';
import FlightPricePerAdult from '../../../ItemPrice/Flight/FlightPricePerAdult';
import ChannelListTag from '../../helpers/ChannelListTag';
import './style.scss';

const GroupedFlightStandard = ({
  group,
  disableCard,
  laymanMode,
  totalTravelerCount,
  isItemSelected,
  setOpenSidePanel,
  setSidePanelBody,
  setSidePanelTitle,
  price,
  totalFilteredFareBrands,
  filteredFareBrands,
  lowestFareBrandTitle,
  disableSelect,
  onDeselect,
  disableItem,
  selectCallback,
  marketingAirlineQCodes,
  people,
}) => {
  const { id, marketingAirlineLogos, availableSeatCount, fareCalculations } = group;

  const updatedArray = group.channelTitle.filter(cahnnel => cahnnel !== 'Welltravel');
  const wellTravelChannel = group.channelTitle.find(cahnnel => cahnnel === 'Welltravel');

  const numberOfAdult = people && people.adults;
  const numberOfChildren = people && people.children;
  const numberOfInfant = people && people.infants;

  const adultPrice =
    fareCalculations &&
    fareCalculations.find(fareCalculationItem => fareCalculationItem.passengerType === TYPE_ADULT)
      .legPrice;

  return (
    <div
      className={ClassNames('flight-standard-item-wrapper', {
        'flight-standard-item-wrapper--disable': disableCard,
      })}
      key={id}
    >
      {isItemSelected && (
        <span className="flight-selected-tag">{I18n.t('components.ibe.results.selected')}</span>
      )}
      <Card
        version="v2"
        className={ClassNames('flight', {
          'flight--selected': isItemSelected,
        })}
      >
        <div className="grid grid-gap-20">
          <div className="col-12 col-grid direction-row justify-between">
            <div className="col-lg-7 col-grid justify-center col-6 col-bleed col flight-logos-qr-codes">
              <AirlineStandardLogos
                className="flight__airline-summary"
                carriers={marketingAirlineLogos}
                codes={marketingAirlineQCodes}
              />
            </div>
            <div className="col-lg-5 col-6 col-bleed col-grid direction-row justify-end flight__seat-actions-container">
              <div className="col-grid col-bleed justify-center flight__seat-status-container">
                <SeatCountIconWithLabel
                  className="justify-end"
                  seatCount={availableSeatCount}
                  travellerCount={totalTravelerCount}
                />
              </div>
              <div className="col-grid col-bleed justify-center hidden-sm-down flight__actions">
                <FlightSelectButton
                  isItemSelected={isItemSelected}
                  disableSelect={disableSelect}
                  onDeselect={onDeselect}
                  group={group}
                  disableItem={disableItem}
                  onCallbackSelect={selectCallback}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="grid grid-gap-12">
              <div className="col-md-9">
                <FlightDetails
                  setOpenSidePanel={setOpenSidePanel}
                  setSidePanelBody={setSidePanelBody}
                  setSidePanelTitle={setSidePanelTitle}
                  group={group}
                />
              </div>
              <div className="col-md-3 hidden-sm-down flight__prices">
                <div className="grid grid-gap-12">
                  <div className="col-12 col-grid justify-end flight__prices-farebrands">
                    <div className="col-grid col-bleed align-end flight__prices-content">
                      <FlightPricePerAdult
                        className="flight__prices--gross-price"
                        marginBreakdown={price.legMarginBreakdown}
                        currency={price.currencyCode}
                        grossAmount={price.grossLegPrice}
                        grossTaxes={price.pricing && price.pricing.grossLegTaxes}
                        laymanMode={laymanMode}
                        fareCalculations={fareCalculations}
                        fromResult={true}
                        numberOfAdult={numberOfAdult}
                        numberOfChildren={numberOfChildren}
                        numberOfInfant={numberOfInfant}
                        adultPrice={adultPrice}
                        fareBrandTitle={lowestFareBrandTitle}
                      />
                    </div>
                    {totalFilteredFareBrands > 0 && (
                      <FareBrandList className="col-grid col-bleed align-end">
                        {filteredFareBrands.slice(0, 3).map(fareBrandItem => (
                          <FareBrandItem
                            className="col-12 col-grid direction-row justify-end col-bleed flex-wrap"
                            id={fareBrandItem.id}
                            label={fareBrandItem.fareBrands}
                            currency={fareBrandItem.price.currencyCode}
                            price={fareBrandItem.price.grossLegPrice}
                          />
                        ))}
                        {totalFilteredFareBrands > 3 && (
                          <MoreFareBrandsLabel count={totalFilteredFareBrands - 3} />
                        )}
                      </FareBrandList>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SearchFlightInfo
            className="flight__search-info col-12"
            group={group}
            laymanMode={laymanMode}
          />
          <div className="col-xlg-3 hidden-xlg hidden-lg hidden-md">
            <div className="grid grid-gap-12">
              <div className="col-12 col-grid direction-row justify-between">
                {totalFilteredFareBrands > 0 && (
                  <FareBrandList className="col-grid col-bleed flight__prices__fare-packages--with-row-gap flight__prices__fare-packages--text-left">
                    {filteredFareBrands
                      .slice(0, 2)
                      .map(({ id: fareBrandId, fareBrands, price: fareBrandPrice }) => (
                        <FareBrandItem
                          key={fareBrandId}
                          id={fareBrandId}
                          className="col-12 col-grid col-bleed flight__prices__fare-package-item flex-wrap"
                          label={fareBrands}
                          currency={fareBrandPrice.currencyCode}
                          price={fareBrandPrice.grossLegPrice}
                        />
                      ))}
                    {totalFilteredFareBrands > 2 && (
                      <MoreFareBrandsLabel
                        className="col-grid col-bleed justify-center flight__prices__fare-packages--text-left"
                        count={totalFilteredFareBrands - 2}
                      />
                    )}
                  </FareBrandList>
                )}
                <div className="col-grid align-end col-bleed justify-between flight__prices-farebrands">
                  <div className="col-grid align-end col-bleed flight__prices-content">
                    <FlightPricePerAdult
                      className="flight__prices--gross-price"
                      marginBreakdown={price.legMarginBreakdown}
                      currency={price.currencyCode}
                      grossAmount={price.grossLegPrice}
                      grossTaxes={price.pricing && price.pricing.grossLegTaxes}
                      laymanMode={laymanMode}
                      numberOfAdult={numberOfAdult}
                      numberOfChildren={numberOfChildren}
                      numberOfInfant={numberOfInfant}
                      fareCalculations={fareCalculations}
                      fromResult={true}
                      adultPrice={adultPrice}
                      fareBrandTitle={lowestFareBrandTitle}
                    />
                  </div>
                  <div className="col-12 col-bleed-x col-grid direction-row justify-end">
                    <FlightSelectButton
                      isItemSelected={isItemSelected}
                      disableSelect={disableSelect}
                      onDeselect={onDeselect}
                      group={group}
                      disableItem={disableItem}
                      onCallbackSelect={selectCallback}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!laymanMode && (
          <div className="d-flex justify-end flight__channels flight__welltravel-tickets">
            {updatedArray.length > 0 && <ChannelListTag channels={updatedArray} />}

            {wellTravelChannel && <ChannelListTag channels={[wellTravelChannel]} type="accent" />}
            {/* <EmphasisTag size="tiny" type="accent" text="Welltravel Ticket - Earn USD 60.00" /> */}
          </div>
        )}
      </Card>
    </div>
  );
};

GroupedFlightStandard.propTypes = {
  disableCard: PropTypes.bool.isRequired,
  selectedTripMap: PropTypes.shape({
    [PropTypes.number]: PropTypes.string,
  }).isRequired,
  group: PropTypes.shape({
    fareCalculations: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        legPrice: PropTypes.string,
        passengerType: PropTypes.oneOf(['adult', 'child', 'infant']),
        price: PropTypes.string,
      }),
    ).isRequired,
    price: PropTypes.shape(priceItemShape),
    id: PropTypes.string.isRequired,
    channelTitle: PropTypes.arrayOf(PropTypes.string).isRequired,
    marketingAirlineLogos: PropTypes.arrayOf(PropTypes.string).isRequired,
    availableSeatCount: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.number]),
  }).isRequired,
  laymanMode: PropTypes.bool.isRequired,
  totalTravelerCount: PropTypes.number.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  setOpenSidePanel: PropTypes.func.isRequired,
  setSidePanelBody: PropTypes.func.isRequired,
  setSidePanelTitle: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  totalFilteredFareBrands: PropTypes.number.isRequired,
  filteredFareBrands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fareBrands: PropTypes.string.isRequired,
      price: PropTypes.shape({
        currencyCode: PropTypes.string,
        grossLegPrice: PropTypes.string,
      }),
    }),
  ).isRequired,
  lowestFareBrandTitle: PropTypes.string.isRequired,
  disableSelect: PropTypes.bool.isRequired,
  onDeselect: PropTypes.func.isRequired,
  disableItem: PropTypes.bool.isRequired,
  selectCallback: PropTypes.func.isRequired,
  marketingAirlineQCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  people: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
  }).isRequired,
};

export default hot(module)(GroupedFlightStandard);
