const copyTextToClipboard = text => {
  const textField = document.createElement('textarea');
  textField.textContent = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export default copyTextToClipboard;
