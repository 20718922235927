/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import HumanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import ProductIcon from 'sharedWebpack/ProductIcon';
import DateTime from 'sharedWebpack/DateTime';
import Card from '@wtag/rcl-card';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import StackedItems from '@wtag/rcl-stacked-items';
import '../styles.scss';

const Cruise = ({ data }) => {
  const [isTravellerPopoverVisible, setIsTravellerPopoverVisible] = useState(false);
  return (
    <Fragment>
      <div className="col-12 col-sm-3 col-xlg-2 travel-plan__timeline-item-section-product">
        <div className="travel-plan__timeline-item-section-product-info">
          <div className="travel-plan__timeline-item-section-product-icon">
            <ProductIcon productType={data.type} showBGColor={true} size="medium" />
          </div>
          <div className="travel-plan__timeline-item-section-product-name">
            {I18n.t('admin.components.orders.overview.travel_plan.cruise.title')}
          </div>
          <div className="travel-plan__timeline-item-section-product-duration">
            {HumanReadableDuration(data.durationInMinutes)}
          </div>
          <div className="travel-plan__timeline-item-section-product-travellers">
            {data.travelers && data.travelers.length > 0 && (
              <StackedItems
                itemSize="tiny"
                isPopoverVisible={isTravellerPopoverVisible}
                onClick={() =>
                  setIsTravellerPopoverVisible(
                    prevTravellerPopoverState => !prevTravellerPopoverState,
                  )
                }
                onOutsideClick={() => setIsTravellerPopoverVisible(false)}
              >
                {data.travelers.map(traveler => (
                  <Avatar
                    firstName={traveler.firstName}
                    lastName={traveler.lastName}
                    src={traveler.avatarUrl}
                  />
                ))}
              </StackedItems>
            )}
          </div>
        </div>
        <div className="travel-plan__timeline-item-indicator">
          <div className="travel-plan__timeline-item-indicator-line" />
        </div>
      </div>
      <div className="col-12 col-sm-9 col-xlg-10">
        <div className="grid grid-gap-20">
          <div className="col-12">
            <Card className="travel-plan__timeline-item-section-card" version="v2">
              <div className="travel-plan__timeline-item-section-card-header">
                {data && data.shipName}
              </div>
              <div className="travel-plan__section-divider travel-plan__timeline-item-section-divider" />
              <div className="travel-plan__timeline-item-section-card-body">
                <div className="travel-plan__timeline-item-section-card-body-travel-info">
                  <div className="travel-plan__timeline-item-section-card-body-travel-time">
                    {data.boardingTime && (
                      <DateTime dateTime={data.boardingTime} format="shortWithTime" />
                    )}
                    {data.offBoardingTime && (
                      <DateTime dateTime={data.offBoardingTime} format="shortWithTime" />
                    )}
                  </div>
                  {data.boardingTime && (
                    <div className="travel-plan__timeline-item-section-card-body--muted-text">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.cruise.header.boarding.label',
                      )}
                    </div>
                  )}
                  {data.offBoardingTime && (
                    <div className="travel-plan__timeline-item-section-card-body--muted-text">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.cruise.header.off_boarding.label',
                      )}
                    </div>
                  )}
                  {data.boardingLocation ? (
                    <div className="travel-plan__timeline-item-section-card-body-travel-address">
                      {`${data.boardingLocation ? `${data.boardingLocation}, ` : ''}${
                        data.boardingAddress.city ? `${data.boardingAddress.city}, ` : ''
                      }${data.boardingAddress.country ? `${data.boardingAddress.country}` : ''}`}
                    </div>
                  ) : (
                    <div className="travel-plan__timeline-item-section-card-body-travel-address">
                      {`${data.offBoardingLocation ? `${data.offBoardingLocation}, ` : ''}${
                        data.offBoardingAddress && data.offBoardingAddress.city
                          ? `${data.offBoardingAddress.city}, `
                          : ''
                      }${
                        data.offBoardingAddress && data.offBoardingAddress.country
                          ? `${data.offBoardingAddress.country}`
                          : ''
                      }`}
                    </div>
                  )}
                  {data.food && (
                    <EmphasisTag
                      className="travel-plan__timeline-item-section-card-body-travel-tag"
                      text="Food"
                      size="tiny"
                      type="success"
                    />
                  )}
                </div>
                <div className="travel-plan__timeline-item-section-card-body-image">
                  {/* Cruise Image */}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Cruise.propTypes = {
  data: PropTypes.shape({
    boardingTime: PropTypes.string,
    boardingLocation: PropTypes.string,
    offBoardingTime: PropTypes.string,
    offBoardingLocation: PropTypes.string,
    boardingAddress: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
    }),
    offBoardingAddress: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
    }),
    type: PropTypes.string,
    durationInMinutes: PropTypes.number,
    name: PropTypes.string,
    shipName: PropTypes.string,
    food: PropTypes.string,
    travelers: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  }).isRequired,
};

export default Cruise;
