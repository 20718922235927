import { ADD_CAR } from '../../actions/car';

const initialState = {
  isCarAdded: false,
};

export default function addCar(state = initialState, action) {
  switch (action.type) {
    case ADD_CAR:
      return {
        ...state,
        isCarAdded: action.isCarAdded,
      };
    default:
      return state;
  }
}
