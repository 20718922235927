import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  updatePreselectTravelers,
  updatePreselectDecision,
} from '../../actions/common/preselectTraveler';
import PreselectTravelersSidePanel from './PreselectTravelersSidePanel';

const mapStateToProps = (state, props) => {
  let params;
  const flightSearchForm = state.form && state.form.flightSearchForm;
  const hotelSearchForm = state.form && state.form.hotelSearchForm;
  switch (props.type) {
    case 'flight':
      params = {
        adults: flightSearchForm && flightSearchForm.adults && flightSearchForm.adults.value,
        children: flightSearchForm && flightSearchForm.children && flightSearchForm.children.value,
        infants: flightSearchForm && flightSearchForm.infants && flightSearchForm.infants.value,
      };
      break;
    case 'hotel':
      params = {
        adults: hotelSearchForm && hotelSearchForm.adults && hotelSearchForm.adults.value,
        children: hotelSearchForm && hotelSearchForm.children && hotelSearchForm.children.value,
      };
      break;
    case 'car':
      // since car does not require adults, children and infant to search we will be taking 1 adult traveler just for driver.
      params = { adults: 1 };
      break;
    default:
  }

  return {
    ...params,
    preselectTravelers: state.common.preselectTravelers.travelers,
    preselectDecision: state.common.preselectTravelers.decision,
    cartHasItems: state.common.cart.items.length > 0,
  };
};

const mapDispatchToProps = dispatch => ({
  updatePreselectTravelers: travelers => dispatch(updatePreselectTravelers(travelers)),
  updatePreselectDecision: travelers => dispatch(updatePreselectDecision(travelers)),
});

const PreselectTravelersSidePanelWithContext = (props, context) => (
  <PreselectTravelersSidePanel {...props} {...context} />
);

PreselectTravelersSidePanelWithContext.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PreselectTravelersSidePanelWithContext));
