import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { cartFetched } from '../common';

export const VOUCHER_INVALID = 'VOUCHER_INVALID';
function voucherInvalid() {
  return { type: VOUCHER_INVALID };
}

export const REFRESH_VOUCHER_CONTENT_STATUS = 'REFRESH_VOUCHER_CONTENT_STATUS';
function refreshVoucherContentStatus() {
  return { type: REFRESH_VOUCHER_CONTENT_STATUS };
}

export const SET_VOUCHER_CONTENT_STATUS = 'SET_VOUCHER_CONTENT_STATUS';
function setVoucherContentStatus(status) {
  return { type: SET_VOUCHER_CONTENT_STATUS, status };
}

const setVoucherInCartWithoutDebounce = (dispatch, getState, params) => {
  const cartId = getState().common.cart.id;

  return httpClient
    .get(routes.api.carts.voucher.contentStatus({ cartId, number: params.voucher.number }))
    .then(({ data }) => {
      if (data) return dispatch(setVoucherContentStatus(data));
      return dispatch(refreshVoucherContentStatus());
    })
    .then(() => httpClient.patch(routes.api.carts.updateCart({ cartId }), params))
    .then(({ data }) => {
      if (params.voucher.number && params.voucher.number.length > 0 && !data.voucher) {
        return dispatch(voucherInvalid());
      }
      return dispatch(cartFetched(data));
    });
};

export const SET_VOUCHER_IN_CART = 'SET_VOUCHER_IN_CART';
export function setVoucherInCart(number) {
  return (dispatch, getState) => {
    dispatch({ type: SET_VOUCHER_IN_CART, number });
    return setVoucherInCartWithoutDebounce(dispatch, getState, { voucher: { number } });
  };
}
