import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, EmphasisTag } from '@wtag/react-comp-lib';
import { Text } from '@wtag/rcl-typography';
import Icon from '@wtag/rcl-icon';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import TripSegmentSidePanel from 'sharedWebpack/IBE/SearchDetails/flight/TripSegmentSidePanel';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import MatrixContext from '../contexts/MatrixContext';

const SegmentsInformation = ({
  group,
  airlineInformation,
  airports,
  fetchAirlineInformation,
  fetchAirportDetails,
}) => {
  const { aircraftInformation, fetchAircraftType } = useContext(MatrixContext);
  const findAirport = airportCode =>
    (airports && Object.values(airports).find(airport => airport.code === airportCode)) ||
    fetchAirportDetails(airportCode);
  const findAirline = airlineCode =>
    (airlineInformation &&
      Object.values(airlineInformation).find(airline => airline.code === airlineCode)) ||
    fetchAirlineInformation(airlineCode);
  const [query, setQuery] = useQueryParams({
    openedSidePanel: withDefault(StringParam, ''),
  });
  const sidePanelKeyFromQuery = `segment-${group.id}`;
  const segmentSidePanelVisible = query.openedSidePanel === sidePanelKeyFromQuery;

  const showSegmentSidePanel = () => {
    setQuery({ openedSidePanel: `segment-${group.id}` }, 'replaceIn');
  };

  const hideSidePanel = () => {
    setQuery({ openedSidePanel: '' }, 'replaceIn');
  };

  return (
    <Fragment>
      <div className="matrix-accordion__item-body-segments-codes">
        {group.marketingAirlineCodes.map(code => (
          <EmphasisTag
            key={code}
            text={
              <Text level={3} weight="light">
                {code}
              </Text>
            }
            size="tiny"
            type="neutral"
            icon={<Icon name="aeroplane" />}
          />
        ))}
      </div>
      <Link
        className="matrix-accordion__item-body-segments-itinerary-link"
        onClick={showSegmentSidePanel}
        size="tiny"
        modifier="tertiary"
      >
        <Text level={2} weight="bold">
          {I18n.t('admin.components.orders.items_tab.placeholder.show_itinerary')}
        </Text>
      </Link>
      <TripSegmentSidePanel
        title={I18n.t('components.flight_info.header')}
        group={group}
        findAirport={findAirport}
        findAirline={findAirline}
        showTripSegment={segmentSidePanelVisible}
        aircraftInformation={aircraftInformation}
        fetchAircraftType={fetchAircraftType}
        onClick={() => hideSidePanel()}
      />
    </Fragment>
  );
};

SegmentsInformation.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    destinationCode: PropTypes.string.isRequired,
    arrivalTime: PropTypes.string.isRequired,
    destinationTerminal: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(PropTypes.shape({})),
    operatingAirlineCodes: PropTypes.arrayOf(PropTypes.shape({})),
    marketingAirlineCodes: PropTypes.arrayOf(PropTypes.shape({})),
    stopovers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  fetchAirportDetails: PropTypes.func.isRequired,
  airports: PropTypes.objectOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      placeName: PropTypes.string.isRequired,
      countryName: PropTypes.string.isRequired,
      countryFlag: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fetchAirlineInformation: PropTypes.func.isRequired,
  airlineInformation: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default withQueryParamsProvider(SegmentsInformation);
