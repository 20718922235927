import { FORM_OF_PAYMENT_MULTIPLE } from '../../lib/helpers/fop_options';

function creditCardIconName(cardType, laymanMode = true) {
  switch (cardType) {
    case 'cash':
      return laymanMode ? 'invoiceWord' : 'cash';

    case 'iata':
      return 'iata';

    case 'none':
      return 'receipt';

    case FORM_OF_PAYMENT_MULTIPLE:
      return 'multipleFOP';

    case 'visa':
      return 'visa';

    case 'visa_corporate':
      return 'visa';

    case 'visa_electron':
      return 'visaElectron';

    case 'visa_debit':
      return 'visa';

    case 'visa_corporate_debit':
      return 'visa';

    case 'visa_electron_debit':
      return 'visaElectron';

    case 'master_card':
      return 'mastercard';

    case 'mastercard':
      return 'mastercard';

    case 'master_card_debit':
      return 'mastercard';

    case 'master_card_corporate':
      return 'mastercard';

    case 'master_card_corporate_debit':
      return 'mastercard';

    case 'uatp':
      return 'uatp';

    case 'uatp_debit':
      return 'uatp';

    case 'amex':
      return 'amex';

    case 'amex_debit':
      return 'amex';

    case 'amex_corporate':
      return 'amex';

    case 'amex_corporate_debit':
      return 'amex';

    case 'diners_club':
      return 'dinersClub';

    case 'diners_club_debit':
      return 'dinersClub';

    case 'diners_club_corporate':
      return 'dinersClub';

    case 'diners_club_corporate_debit':
      return 'dinersClub';

    case 'discover':
      return 'discover';

    case 'discover_debit':
      return 'discover';

    case 'discover_corporate':
      return 'discover';

    case 'discover_corporate_debit':
      return 'discover';

    case 'airplus':
      return 'uatp';

    case 'jcb':
      return 'jcb';

    case 'jcb_debit':
      return 'jcb';

    case 'twint':
      return 'twint';

    case 'post_finance':
      return 'postFinance';

    case 'american_express':
      return 'amex';

    case 'boncard':
      return 'boncard';

    case 'china_union_pay':
      return 'chinaUnionPay';

    case 'cb':
      return 'visaCB';

    case 'dankort':
      return 'dankort';

    case 'rupay':
      return 'rupay';

    case 'visa_v_pay':
      return 'visaVPay';

    case 'mastercard_maestro':
      return 'mastercardMaestro';

    case 'elo_master_card':
      return 'elo';

    case 'elo_visa':
      return 'elo';

    case 'mastercard_bancontact':
      return 'mastercardBancontact';

    case 'lunch_check':
      return 'lunchCheck';

    case 'welltravel_wallet':
    case 'welltravelWallet':
      return 'walletBalance';

    default:
      return 'visa';
  }
}

export default creditCardIconName;
