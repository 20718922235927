import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import ItemWrapper from 'sharedWebpack/ItemWrapper/new';
import ChannelListTag from 'sharedWebpack/IBE/helpers/ChannelListTag';
import getCarChannelIdentifier from 'sharedWebpack/IBE/lib/helpers/getCarChannelIdentifier';
import WELLTRAVEL from 'sharedWebpack/IBE/lib/helpers/welltravelChannel';
import maskedNumberNormalizer from 'sharedWebpack/CreditCardVaultPayment/helpers/maskedNumberNormalizer';
import { TYPE_CAR } from 'sharedWebpack/helpers/itemTypes';
import QueuesAndRemarks from './QueuesAndRemarks';
import withJourneyElement from '../../lib/decorators/withJourneyElement';
import paymentMethodName from '../helpers/paymentMethodName';
import paymentMethodLocaleName from '../helpers/paymentMethodLocaleName';
import { CREDIT_CARD_FORM_OF_PAYMENT } from '../../lib/helpers/fop_options';
import './style.scss';

const ItemDetails = ({
  item,
  laymanMode,
  channelsWithQueuesAndRemarks,
  journeyElement,
  cardType,
  paymentDisabled,
  paymentOptions,
  selectedItemId,
  isFopLoading,
}) => {
  const {
    id,
    formOfPayment,
    hasJourneyElement,
    channel,
    journeyElementId,
    journeyElementType,
    productId,
    itemTitle,
  } = item;
  const { type, cardDetails } = formOfPayment;
  const fopName = () => {
    if (!paymentDisabled && cardType && Object.keys(paymentOptions).length > 0) {
      return I18n.t(paymentOptions[cardType].gateway, {
        scope: 'activerecord.attributes.payment_settings.payment_gateways',
      });
    }
    if (type === CREDIT_CARD_FORM_OF_PAYMENT) {
      return cardDetails && maskedNumberNormalizer(cardDetails.maskedNumber);
    }

    return paymentMethodLocaleName(type, laymanMode);
  };

  const iconName = () => {
    let fopType;

    if (!paymentDisabled && cardType) {
      fopType = cardType;
    } else if (cardDetails) {
      fopType = cardDetails.cardType;
    } else {
      fopType = type;
    }

    return paymentMethodName(fopType, laymanMode);
  };

  const getFopContent = () => {
    if (hasJourneyElement) {
      if ((!selectedItemId && isFopLoading) || selectedItemId === id) {
        return (
          <ContentLoaderPlaceholder
            numberOfLines={1}
            showBackground={false}
            contentHeight="20px"
            contentWidth="90px"
          />
        );
      }

      if (type) {
        return (
          <Fragment>
            <Icon className="payment__item-details--tick-icon" name="check" size="tiny" />
            <Icon
              className={classNames('payment__item-details--fop-icon', {
                'payment__item-details--fop-icon-pay-later': iconName() === 'receipt',
              })}
              name={iconName()}
              size="medium"
              color="tertiary"
              showLabel={true}
              iconLabel={fopName()}
            />
          </Fragment>
        );
      }
    }

    return <Fragment />;
  };

  const getCarItemTitle = title => {
    if (!title) {
      return null;
    }

    return <div className="payment__item-details--car-title">{title}</div>;
  };

  const channelIdentifier = getCarChannelIdentifier(channel);
  const isWellTravelChannel = channel.identifier === WELLTRAVEL;
  const isJourneyElementOfCarType = journeyElementType === TYPE_CAR;

  return (
    <div
      className={classNames('payment__item-details', {
        'payment__item-details-accordion': !laymanMode,
        'payment__item-details-accordion-rooms': laymanMode,
      })}
    >
      <Card version="v2">
        <div className="d-flex justify-between align-center wrap payment__item-details--options">
          <span className="d-flex align-center column-gap-8">{getFopContent()}</span>
          <QueuesAndRemarks
            item={item}
            channelsWithQueuesAndRemarks={channelsWithQueuesAndRemarks}
          />
        </div>
        {isJourneyElementOfCarType ? (
          getCarItemTitle(itemTitle)
        ) : (
          <div className="payment__item-title">{itemTitle}</div>
        )}
        <ItemWrapper
          journeyElementId={journeyElementId}
          journeyElementType={journeyElementType}
          fetchedJourneyElement={journeyElement}
          productId={productId}
          showMinimalInfo={true}
        />
      </Card>
      {!laymanMode && !!channelIdentifier && (
        <div className="d-flex justify-end payment__item-channels payment__item-channels--welltravel-tickets">
          <ChannelListTag
            identifier={channelIdentifier}
            type={isWellTravelChannel ? 'accent' : null}
          />
        </div>
      )}
    </div>
  );
};

ItemDetails.propTypes = {
  item: PropTypes.shape({
    bookingAttributes: PropTypes.shape({ resultId: PropTypes.string }),
    journeyElementType: PropTypes.string,
    journeyElementId: PropTypes.number,
    formOfPayment: PropTypes.shape(),
    productId: PropTypes.number,
    id: PropTypes.number,
    hasJourneyElement: PropTypes.bool,
    channel: PropTypes.shape({
      identifier: PropTypes.string,
      account: PropTypes.string,
    }),
    itemTitle: PropTypes.string,
  }).isRequired,
  journeyElement: PropTypes.shape({
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        segments: PropTypes.arrayOf(
          PropTypes.shape({
            aircraftType: PropTypes.string.isRequired,
            destinationCode: PropTypes.string.isRequired,
            operatingCarrierCode: PropTypes.string.isRequired,
            operatingFlightNumber: PropTypes.string.isRequired,
            originCode: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }).isRequired,
    ).isRequired,
    fareRulePresent: PropTypes.bool.isRequired,
    fareBasisInfo: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  channelsWithQueuesAndRemarks: PropTypes.arrayOf(PropTypes.string).isRequired,
  laymanMode: PropTypes.bool.isRequired,
  cardType: PropTypes.string.isRequired,
  paymentDisabled: PropTypes.bool.isRequired,
  paymentOptions: PropTypes.shape({}).isRequired,
  selectedItemId: PropTypes.string.isRequired,
  isFopLoading: PropTypes.bool.isRequired,
};

export default withJourneyElement(withQueryParamsProvider(ItemDetails));
