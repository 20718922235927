import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { Header } from '@wtag/rcl-typography';
import ItemWrapper from 'sharedWebpack/ItemWrapper';
import ProductIcon from 'sharedWebpack/ProductIcon';
import OutOfPolicyJustificationEdit from '../../../../IBE/Cart/Items/OutOfPolicyJustificationEdit';

const ItemCard = ({ item }) => (
  <Fragment>
    <Card version="v2" size="full" className="items-tab__container">
      <div className="items-tab__header">
        <div className="items-tab-title__container">
          <ProductIcon
            productType={item.journeyElementType || item.productType}
            showBGColor={true}
          />
          <span className="items-tab-title">{item.title}</span>
        </div>
        <Header level={5} weight="bold">
          {item.totalAmount}
        </Header>
      </div>
      <div>
        {(item.journeyElementId || item.productId) && (
          <ItemWrapper
            journeyElementId={item.journeyElementId}
            journeyElementType={item.journeyElementType}
            productId={item.productId}
          />
        )}
      </div>
      {item.outOfPolicyJustification && (
        <OutOfPolicyJustificationEdit
          itemId={item.id}
          justificationText={item.outOfPolicyJustification}
          title={I18n.t('components.ibe.out_of_policy_reason.message')}
        />
      )}
    </Card>
  </Fragment>
);

ItemCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    journeyElementType: PropTypes.string,
    productType: PropTypes.string,
    journeyElementId: PropTypes.number,
    productId: PropTypes.number,
    title: PropTypes.string,
    totalAmount: PropTypes.string,
    outOfPolicyJustification: PropTypes.string,
  }).isRequired,
};

export default ItemCard;
