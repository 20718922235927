import calculateAge from './calculateAge';

const getCarTravelersInfo = travelers => {
  const travelersInfo = travelers
    .map(({ firstName, lastName, birthday }) =>
      I18n.t('components.ibe.search_results.car.content.traveler', {
        firstName,
        lastName,
        age: calculateAge(birthday),
      }),
    )
    .join(', ');

  return travelersInfo ? `${travelersInfo}\n` : '';
};

export default getCarTravelersInfo;
