import React, { useState, useRef } from 'react';
import debounce from 'throttle-debounce/debounce';
import SelectBox from '@wtag/rcl-select-box';

import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

const VendorSelectBox = props => {
  const [vendorOptions, setVendorOptions] = useState([]);
  const fetchVendorOptions = async query => {
    if (query && query.length >= 1) {
      const { data } = await httpClient.get(routes.api.cars.vendors({ q: query }));
      setVendorOptions(
        data.map(vendor => ({ label: `${vendor.name} (${vendor.code})`, value: vendor.code })),
      );
    }
  };

  const debouncedSearchVendorCode = useRef(debounce(300, query => fetchVendorOptions(query)))
    .current;
  return <SelectBox onInputChange={debouncedSearchVendorCode} options={vendorOptions} {...props} />;
};

export default VendorSelectBox;
