import React from 'react';
import PropTypes from 'prop-types';
import { I18nText, Pagination } from '@wtag/react-comp-lib';

const PaginationWithTranslation = props => {
  const { currentPage, totalPages, onPaginationClick } = props;

  return (
    <Pagination
      firstText={<I18nText id="pagination.first" returnStringOnly={true} />}
      lastText={<I18nText id="pagination.last" returnStringOnly={true} />}
      nextText={<I18nText id="pagination.next" returnStringOnly={true} />}
      previousText={<I18nText id="pagination.previous" returnStringOnly={true} />}
      currentPage={currentPage}
      totalPages={totalPages}
      onPaginationClick={onPaginationClick}
      windowSize={2}
    />
  );
};

PaginationWithTranslation.defaultProps = {
  currentPage: 1,
  totalPages: 1,
};

PaginationWithTranslation.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPaginationClick: PropTypes.func.isRequired,
};

export default PaginationWithTranslation;
