import React, { useState } from 'react';
import Button from '@wtag/rcl-button';
import { Textarea } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Modal from 'sharedWebpack/Modal';
import PropTypes from 'prop-types';
import './styles.scss';

const OutOfPolicyJustificationModal = ({
  modalOpen,
  setModalOpen,
  onConfirm,
  multipleApplicability,
}) => {
  const [value, setValue] = useState('');

  const onModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      className="policy-justification-modal"
      title={
        <div className="policy-justification-modal--title">
          {I18n.t('components.ibe.out_of_policy_reason.header')}
          <div onClick={onModalClose} onKeyDown={onModalClose} role="presentation">
            <Icon name="close" />
          </div>
        </div>
      }
      isModalVisible={modalOpen}
      onOutsideClick={onModalClose}
      footer={
        <Button
          version="v2"
          size="small"
          label={I18n.t('components.ibe.results.continue_to_cart')}
          type="primary"
          disabled={!value}
          onClick={() => onConfirm(value)}
        />
      }
      alignFooter="left"
    >
      <div className="col-12 col-bleed">
        <div className="policy-justification-modal__hint">
          {I18n.t('components.ibe.out_of_policy_reason.modal_hint')}
        </div>
        {multipleApplicability && (
          <div className="policy-justification-modal__hint--disabled">
            {I18n.t('components.ibe.out_of_policy_reason.multiple_out_of_policy')}
          </div>
        )}
        <Textarea
          placeholder={I18n.t('components.ibe.out_of_policy_reason.placeholder')}
          size="small"
          value={value}
          onChange={event => setValue(event.target.value)}
        />
      </div>
    </Modal>
  );
};

OutOfPolicyJustificationModal.defaultProps = {
  multipleApplicability: false,
};

OutOfPolicyJustificationModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  multipleApplicability: PropTypes.bool,
};

export default OutOfPolicyJustificationModal;
