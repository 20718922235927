import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hot } from 'react-hot-loader';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import Link from '@wtag/rcl-link';
import { Text } from '@wtag/rcl-typography';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import TripSegmentSidePanel from 'sharedWebpack/IBE/SearchDetails/flight/TripSegmentSidePanel';
import humanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import AirportChangeIndicator from 'sharedWebpack/AirportChangeIndicator';
import getFlightStopsCountLabel from 'sharedWebpack/helpers/calculateStopoverStops';
import { LONG_TRANSIT_TIME } from 'sharedWebpack/helpers/stopoverConstants';
import { QUERY_UPDATE_TYPE } from 'sharedWebpack/CreditCardVaultPayment/helpers/ccvPayment';
import { airportItemShape, segmentItemShape, airlineDataShape } from '../shapes';
import StopoverDetails from './StopoverDetails';
import './style.scss';

const FlightStopover = ({ className, airportCode, duration, transitLabel }) => (
  <p className={className}>
    <span className="hidden-lg hidden-sm-down duration-stopover-standard__indicator" />
    <span className="duration-stopover-standard__duration-text">{duration}</span>
    <span className="duration-stopover-standard__airport-code">{airportCode}</span>
    {transitLabel}
  </p>
);

FlightStopover.defaultProps = {
  className: null,
};

FlightStopover.propTypes = {
  className: PropTypes.string,
  airportCode: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  transitLabel: PropTypes.number.isRequired,
};

const FlightDurationAndStopOvers = ({
  id,
  airlineInformation,
  aircraftInformation,
  fetchAirportDetails,
  segments,
  stopovers,
  airports,
  durationInMinutes,
  fetchAircraftType,
  fetchAirlineInformation,
  setSidePanelBody,
  setSidePanelTitle,
  setOpenSidePanel,
}) => {
  const [query, setQuery] = useQueryParams({
    openedSidePanel: withDefault(StringParam, ''),
  });
  const [airportChangeMap, setAirportChangeMap] = useState(new Map());

  const transitGapCount = getFlightStopsCountLabel(stopovers);

  const findAirport = airportCode =>
    (airports && airports[airportCode]) || fetchAirportDetails(airportCode);

  const findAirline = airlineCode =>
    (airlineInformation &&
      Object.values(airlineInformation).find(airline => airline.code === airlineCode)) ||
    fetchAirlineInformation(airlineCode);

  const sidePanelKeyFromQuery = `segment-${id}`;
  const segmentSidePanelVisible = query.openedSidePanel === sidePanelKeyFromQuery;

  const showSegmentSidePanel = () => {
    setQuery({ openedSidePanel: sidePanelKeyFromQuery }, QUERY_UPDATE_TYPE);
  };

  const hideSidePanel = () => {
    setQuery({ openedSidePanel: '' }, QUERY_UPDATE_TYPE);
  };

  const stopoversWithAirportChanges = stopovers
    .filter(stopover => stopover.airportChange)
    .map(stopover => stopover.airportChange);

  const changedAirportCodeText = stopoversWithAirportChanges
    .map(airportItem => airportItem.arrivalAirportCode)
    .join(', ');

  const getTransitLabel = transitDuration => {
    const isTransitDurationLong = transitDuration >= LONG_TRANSIT_TIME;
    const transitLabel = isTransitDurationLong
      ? I18n.t('components.flight.transit.long')
      : I18n.t('components.flight.transit.short');

    return (
      <span
        className={classNames('duration-stopover-standard__transit-text', {
          'duration-stopover-standard__transit-text--long': isTransitDurationLong,
        })}
      >
        ({transitLabel})
      </span>
    );
  };

  const stopoversDetails = () =>
    stopovers.map(stopover => (
      <StopoverDetails
        findAirport={findAirport}
        stopover={stopover}
        airportChangeMap={airportChangeMap}
      />
    ));

  const showStopoverSidePanel = () => {
    setSidePanelTitle(
      I18n.t('components.flight.stopovers.title', {
        count: stopovers.length,
      }),
    );
    setSidePanelBody(stopoversDetails());
    setOpenSidePanel(prev => !prev);
  };

  useEffect(() => {
    const newAirportChangeMap = new Map();

    stopoversWithAirportChanges.forEach(stopover => {
      newAirportChangeMap.set(stopover.departureAirportCode, {
        arrival: stopover.arrivalAirportCode,
        departure: stopover.departureAirportCode,
      });
    });

    setAirportChangeMap(newAirportChangeMap);
  }, [stopovers]);

  return (
    <div className="col-grid col-bleed align-start duration-stopover-standard__content">
      <div className="d-flex-col duration-stopover-standard__duration-transit">
        <h3>{humanReadableDuration(durationInMinutes)}</h3>
        {stopovers.length > 0 ? (
          <Link onClick={showStopoverSidePanel} modifier="tertiary" size="tiny">
            <Text className="duration-stopover-standard__itinerary-link" level={1} weight="normal">
              {transitGapCount}
            </Text>
          </Link>
        ) : (
          <Text className="duration-stopover-standard__itinerary-link" level={1} weight="normal">
            {transitGapCount}
          </Text>
        )}
      </div>
      <div className="duration-stopover-standard__stopovers-itinerary">
        {stopovers.map(({ airportCode, transitDurationsInMinutes }) => (
          <FlightStopover
            key={airportCode}
            className="col-grid col-bleed direction-row align-center duration-stopover-standard__stopover-item wrap"
            airportCode={airportCode}
            duration={humanReadableDuration(transitDurationsInMinutes)}
            transitLabel={getTransitLabel(transitDurationsInMinutes)}
          />
        ))}
        {!!changedAirportCodeText && (
          <AirportChangeIndicator
            className="duration-stopover-standard__airport-change-indicator"
            changedAirportCodeText={changedAirportCodeText}
          />
        )}
        <Link onClick={showSegmentSidePanel} size="tiny" modifier="tertiary">
          <Text className="duration-stopover-standard__itinerary-link" level={1} weight="normal">
            {I18n.t('admin.components.orders.items_tab.placeholder.show_itinerary')}
          </Text>
        </Link>
        <TripSegmentSidePanel
          title={I18n.t('components.flight_info.header')}
          group={{
            segments,
            stopovers,
          }}
          findAirport={findAirport}
          findAirline={findAirline}
          showTripSegment={segmentSidePanelVisible}
          aircraftInformation={aircraftInformation}
          fetchAircraftType={fetchAircraftType}
          onClick={hideSidePanel}
        />
      </div>
    </div>
  );
};

FlightDurationAndStopOvers.defaultProps = {
  setSidePanelBody: () => {},
  setSidePanelTitle: () => {},
  setOpenSidePanel: () => {},
};

FlightDurationAndStopOvers.propTypes = {
  setSidePanelBody: PropTypes.func,
  setSidePanelTitle: PropTypes.func,
  setOpenSidePanel: PropTypes.func,
  id: PropTypes.number.isRequired,
  airlineInformation: PropTypes.shape(airlineDataShape).isRequired,
  aircraftInformation: PropTypes.shape(airlineDataShape).isRequired,
  segments: PropTypes.arrayOf(PropTypes.shape(segmentItemShape)).isRequired,
  airports: PropTypes.arrayOf(PropTypes.shape(airportItemShape)).isRequired,
  stopovers: PropTypes.arrayOf(
    PropTypes.shape({
      airportCode: PropTypes.string,
      arrivalLocalTime: PropTypes.string,
      transitDurationsInMinutes: PropTypes.number,
    }),
  ).isRequired,
  durationInMinutes: PropTypes.string.isRequired,
  fetchAirportDetails: PropTypes.func.isRequired,
  fetchAircraftType: PropTypes.func.isRequired,
  fetchAirlineInformation: PropTypes.func.isRequired,
};

export default hot(module)(withQueryParamsProvider(FlightDurationAndStopOvers));
