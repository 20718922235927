import { UPDATE_SUCCESS_ALERT_TOKEN, CLEAR_SUCCESS_ALERT_TOKEN } from '../actions';

const initialState = {};
export default function successAlertByToken(state = initialState, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case UPDATE_SUCCESS_ALERT_TOKEN:
      newState[action.ccToken] = true;
      return newState;

    case CLEAR_SUCCESS_ALERT_TOKEN:
      return initialState;

    default:
      return newState;
  }
}
