/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Car from './Car';
import Hotel from './Hotel';
import Seat from './Seat';
import StaticProduct from './StaticProduct';
import Service from './Service';
import Fee from './Fee';
import Voucher from './Voucher';

function ChildRow(props) {
  const {
    isVisible,
    journeyElementType,
    type,
    category,
    acrissCode,
    price,
    duration,
    currency,
    rooms,
    quantity,
    unitPrice,
    seatList,
    segmentsWithSeatId,
    number,
  } = props;

  return (
    <div
      className={classNames('cart-summary__accordion-sub-item-details', {
        'cart-summary__accordion-sub-item-details--is-visible': isVisible,
      })}
    >
      {journeyElementType === 'car' && (
        <Car
          category={category}
          acrissCode={acrissCode}
          price={price}
          duration={duration}
          currency={currency}
        />
      )}
      {journeyElementType === 'hotel' && (
        <Hotel rooms={rooms} price={price} duration={duration} currency={currency} />
      )}
      {journeyElementType === 'static_product' && (
        <StaticProduct currency={currency} quantity={quantity} unitPrice={unitPrice} />
      )}
      {journeyElementType === 'seat' && (
        <Seat currency={currency} seatList={seatList} segmentsWithSeatId={segmentsWithSeatId} />
      )}
      {journeyElementType === 'service' && (
        <Service currency={currency} quantity={quantity} unitPrice={unitPrice} />
      )}
      {type === 'fee' && <Fee currency={currency} quantity={quantity} unitPrice={unitPrice} />}
      {type === 'voucher' && <Voucher number={number} />}
    </div>
  );
}

ChildRow.defaultProps = {
  unitPrice: '0.0',
  isVisible: false,
  category: null,
  acrissCode: null,
  duration: 1,
  quantity: '0.0',
  seatList: [],
  rooms: [],
  segmentsWithSeatId: null,
  journeyElementType: null,
  type: null,
};

ChildRow.propTypes = {
  currency: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  unitPrice: PropTypes.string,
  journeyElementType: PropTypes.string,
  type: PropTypes.string,
  isVisible: PropTypes.bool,
  category: PropTypes.string,
  acrissCode: PropTypes.string,
  duration: PropTypes.number,
  quantity: PropTypes.string,
  seatList: PropTypes.arrayOf(PropTypes.shape()),
  rooms: PropTypes.arrayOf(PropTypes.shape()),
  segmentsWithSeatId: PropTypes.shape(),
};

export default ChildRow;
