import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'sharedWebpack/DateTime';
import './style.scss'

class AirportDetails extends React.Component {
  componentDidMount() {
    if (!this.airport()) {
      this.props.fetchAirportDetails(this.props.code);
    }
  }

  airport = () => this.props.airports[this.props.code];

  render() {
    let name;
    let country;
    let flag;
    let placeName;
    const code = this.props.code;
    const terminal = this.props.terminal

    if (this.airport()) {
      name = this.airport().name
      placeName = this.airport().placeName
      country = this.airport().countryName
      flag = this.airport().countryFlag
    }

    return (
  <div className="airport-details">
    <div className="col-grid col-bleed direction-row align-center justify-center">
      <span className="airport-details__airport-code">{code}</span>
      <img
        className="airport-details__country-flag"
        src={flag}
        alt={country}
      />
    </div>
    <div className="col-grid col-bleed">
      <div className="airport-details__airport-name">{name}</div>
      <div className="airport-details__place-country">
        {placeName}, {country}
      </div>
      <div className="airport-details__date">
        <DateTime dateTime={this.props.localTime} format="shortWithTime" />
      </div>
      {terminal && (
        <div className="airport-details__terminal">
          {I18n.t('components.flight.terminal', {
            number: terminal,
          })}
        </div>
      )}
    </div>
  </div>
    );
  }
}

AirportDetails.defaultProps = {
  airports: {},
  terminal: null,
};

AirportDetails.propTypes = {
  terminal: PropTypes.string,
  code: PropTypes.string.isRequired,
  localTime: PropTypes.string.isRequired,
  airports: PropTypes.objectOf(
    PropTypes.shape({
      placeName: PropTypes.string.isRequired,
    }),
  ),
  fetchAirportDetails: PropTypes.func.isRequired,
};

export default AirportDetails;
