import React from 'react';
import { connect } from 'react-redux';
import history from '../../../lib/history';

import { addStaticProductToCart, resetApp } from '../../../actions/common';

import StaticProducts from './StaticProducts';

const mapStateToProps = state => ({
  isFetchingProducts: state.common.staticProducts.isFetchingProducts,
  products: state.common.staticProducts.fetchedStaticProducts,
  cartId: state.common.cart.id,
  hasStaticProducts:
    state.common.staticProducts.fetchedStaticProducts &&
    Object.keys(state.common.staticProducts.fetchedStaticProducts).length > 0,
});

const mapDispatchToProps = dispatch => ({
  addToCart: (product, callbackParams, callback = () => {}) =>
    dispatch(addStaticProductToCart(product, callbackParams)).then(cart => {
      callback();
      history.push(`/carts/${cart.id}`);
    }),
  resetApp: () => dispatch(resetApp('/static')),
});

const StaticProductsWithContext = (props, context) => <StaticProducts {...props} {...context} />;

export default connect(mapStateToProps, mapDispatchToProps)(StaticProductsWithContext);
