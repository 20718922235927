import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { Text } from '@wtag/rcl-typography';
import MatrixContext from '../../contexts/MatrixContext';
import useSliderScroll from './useSliderScroll';
import './matrix-content-slider.styles.scss';

const MatrixContentSlider = props => {
  const { children, onLoad, scrollSliderToLeftBy, hideShowMoreButton, noHorizontalScroll } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [sliderContainerRef, setSliderContainerRef] = useState(null);
  const { laymanMode, setScrollSliderToLeftBy } = useContext(MatrixContext);
  const windowWidthForMobileView = 991;

  useSliderScroll(noHorizontalScroll, scrollSliderToLeftBy, sliderContainerRef);

  useEffect(() => {
    if (sliderContainerRef)
      sliderContainerRef.scrollTo({
        top: 0,
        left: scrollSliderToLeftBy,
        behavior: 'smooth',
      });
  }, [scrollSliderToLeftBy]);

  useEffect(() => {
    if (laymanMode && window.innerWidth > windowWidthForMobileView) {
      setIsCollapsed(false);
    } else if (laymanMode && window.innerWidth < windowWidthForMobileView) {
      setIsCollapsed(true);
    }
  }, [laymanMode, window.innerWidth]);

  return (
    <div
      className={classNames('matrix-content-slider', {
        'matrix-content-slider--collapsed': !hideShowMoreButton && isCollapsed,
        'matrix-content-slider--hidden-show-more-button': hideShowMoreButton,
        'matrix-content-slider--laymanmode': laymanMode,
      })}
    >
      <div
        className={classNames('matrix-content-slider__container', {
          'matrix-content-slider__container--no-horizontal-scroll': noHorizontalScroll,
        })}
        ref={node => {
          if (node !== null) {
            setSliderContainerRef(node);
            onLoad({
              clientWidth: node.clientWidth,
              scrollWidth: node.scrollWidth,
            });
          }
        }}
        onTouchMove={() => {
          setTimeout(() => {
            if (sliderContainerRef) setScrollSliderToLeftBy(sliderContainerRef.scrollLeft);
          }, 500);
        }}
      >
        {children}
      </div>
      {!hideShowMoreButton && (
        <div
          className="matrix-content-slider__expand-button"
          role="button"
          tabIndex={0}
          onClick={() => setIsCollapsed(prevState => !prevState)}
          onKeyDown={() => setIsCollapsed(prevState => !prevState)}
        >
          <Text level={3} weight="bold">
            {isCollapsed
              ? I18n.t('components.ibe.results.show_all_facilities')
              : I18n.t('components.ibe.results.hide_facilities')}
          </Text>{' '}
          <Icon name="iconDownChevron" rotate={isCollapsed ? 0 : 180} color="tertiary" />
        </div>
      )}
    </div>
  );
};

MatrixContentSlider.defaultProps = {
  onLoad: () => {},
  scrollSliderToLeftBy: 0,
  hideShowMoreButton: false,
  noHorizontalScroll: false,
};

MatrixContentSlider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onLoad: PropTypes.func,
  scrollSliderToLeftBy: PropTypes.number,
  hideShowMoreButton: PropTypes.bool,
  noHorizontalScroll: PropTypes.bool,
};

export default MatrixContentSlider;
