import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Modal from 'sharedWebpack/Modal';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import Approvers from './Approvers';
import OrdersLeftPanel from '../../../public/shared/OrdersLeftPanel';
import ExchangeForm from './ExchangeForm';

const LeftPanel = ({
  requestId,
  confirmations,
  executiveConfirmations,
  owner,
  arranger,
  billingContact,
  tenant,
  ordersLeftPanelLoading,
  approversLoading,
  approvers,
  potentialApprovers,
  approverSwappable,
  fetchRequestDetails,
  minApprovals,
  isApprovalRequest,
  fetchingRequest,
  layman,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const modalTitleContent = (
    <div
      onClick={() => setIsModalVisible(false)}
      onKeyDown={() => setIsModalVisible(false)}
      role="presentation"
      className="top-nav__account-switch-modal-title"
    >
      <div>{I18n.t('public.components.approvals.requests.exchange_approvers.title')}</div>
      <div>
        <Icon name="close" />
      </div>
    </div>
  );
  return (
    <div className="grid grid-gap-20">
      <div className="left-panel__approvers-list col-12 col-md-4">
        <div>
          <Card version="v2" size="full">
            {approversLoading ? (
              <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
            ) : (
              <React.Fragment>
                <Approvers
                  confirmations={confirmations}
                  confirmationLabel="initial_approvers"
                  minApprovals={minApprovals}
                  fetchingRequest={fetchingRequest}
                />
                <div className="request">
                  <hr />
                </div>
                <Approvers
                  confirmations={executiveConfirmations}
                  confirmationLabel="executive_approvers"
                  minApprovals={minApprovals}
                  fetchingRequest={fetchingRequest}
                />
              </React.Fragment>
            )}

            {layman && approverSwappable && isApprovalRequest && (
              <div className="left-panel__exchange-approver">
                <div className="request">
                  <hr />
                </div>
                <div className="left-panel__title">
                  {I18n.t('public.components.approvals.requests.exchange_approvers.title')}
                </div>
                <div className="left-panel__title-sub-title">
                  {I18n.t('public.components.approvals.requests.exchange_approvers.details')}
                </div>
                <Button
                  label={I18n.t('public.components.approvals.requests.exchange_approvers.action')}
                  version="v2"
                  size="small"
                  type="default"
                  onClick={() => setIsModalVisible(true)}
                />
                <Modal
                  title={modalTitleContent}
                  className="left-panel__exchange-approver-modal"
                  isModalVisible={isModalVisible}
                  onOutsideClick={() => setIsModalVisible(false)}
                >
                  <div className="col-grid col-bleed">
                    <ExchangeForm
                      approvers={approvers}
                      potentialApprovers={potentialApprovers}
                      requestId={requestId}
                      setModalOpen={setIsModalVisible}
                      fetchRequestDetails={fetchRequestDetails}
                    />
                  </div>
                </Modal>
              </div>
            )}
          </Card>
        </div>
      </div>
      <div className="col-12 col-md-8">
        {owner && (
          <OrdersLeftPanel
            arranger={arranger}
            billingContact={billingContact}
            owner={owner}
            tenant={tenant}
            loading={ordersLeftPanelLoading || fetchingRequest}
          />
        )}
      </div>
    </div>
  );
};

LeftPanel.defaultProps = {
  confirmations: [],
  executiveConfirmations: [],
  arranger: null,
  potentialApprovers: [],
  minApprovals: null,
  layman: false,
};

LeftPanel.propTypes = {
  requestId: PropTypes.number.isRequired,
  confirmations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string.isRequired,
      mandatory: PropTypes.bool.isRequired,
    }),
  ),
  executiveConfirmations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string.isRequired,
      mandatory: PropTypes.bool.isRequired,
    }),
  ),
  owner: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  arranger: PropTypes.shape({
    avatarUrl: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  billingContact: PropTypes.number.isRequired,
  tenant: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  }).isRequired,
  ordersLeftPanelLoading: PropTypes.bool.isRequired,
  approversLoading: PropTypes.bool.isRequired,
  approvers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  potentialApprovers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
  ),
  approverSwappable: PropTypes.bool.isRequired,
  fetchRequestDetails: PropTypes.func.isRequired,
  minApprovals: PropTypes.number,
  isApprovalRequest: PropTypes.bool.isRequired,
  fetchingRequest: PropTypes.bool.isRequired,
  layman: PropTypes.bool,
};

export default hot(module)(withQueryParamsProvider(LeftPanel));
