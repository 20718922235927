import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';

const HotelServices = ({ airportShuttle, wifi }) => {
  const services = [
    {
      serviceName: <I18nText id="ibe.hotel.services.airport_shuttle.pick_up" />,
      serviceInfo: airportShuttle.pickUp
        ? I18n.t(airportShuttle.pickUp, {
            scope: 'components.ibe.hotel.services.facility_info',
          })
        : I18n.t('components.ibe.hotel.services.facility_info.other'),
    },
    {
      serviceName: <I18nText id="ibe.hotel.services.airport_shuttle.drop_off" />,
      serviceInfo: airportShuttle.dropOff
        ? I18n.t(airportShuttle.dropOff, {
            scope: 'components.ibe.hotel.services.facility_info',
          })
        : I18n.t('components.ibe.hotel.services.facility_info.other'),
    },
    {
      serviceName: <I18nText id="ibe.hotel.services.wifi" />,
      serviceInfo: wifi
        ? I18n.t(wifi, {
            scope: 'components.ibe.hotel.services.facility_info',
          })
        : I18n.t('components.ibe.hotel.services.facility_info.other'),
    },
  ];

  return (
    <div className="hotel-information__services">
      <div className="hotel-information__services-title">
        <I18nText id="ibe.hotel.details.services" />
      </div>
      <ul className="hotel-information__services-elements">
        {services.map(({ serviceName, serviceInfo }) => (
          <li key={serviceName}>
            {serviceName} (<span>{serviceInfo}</span>)
          </li>
        ))}
      </ul>
    </div>
  );
};

const HotelConditions = ({ conditions }) => (
  <div className="hotel-information__conditions">
    <div className="hotel-information__conditions-title">
      <I18nText id="ibe.hotel.details.conditions" />
    </div>
    <ul className="hotel-information__conditions-elements">
      {conditions.map(condition => (
        <li key={condition}>
          {I18n.t(condition, {
            scope: 'components.ibe.hotel.conditions',
          })}
        </li>
      ))}
    </ul>
  </div>
);

const HotelAdditionalInformation = ({ hotelPolicies }) => (
  <div className="hotel-information__additional-info">
    <div className="hotel-information__additional-info-title">
      {I18n.t('components.ibe.search_results.hotel.additional_information')}
    </div>
    <ul className="hotel-information__additional-info-elements">
      <li>{hotelPolicies}</li>
    </ul>
  </div>
);

const HotelInformation = ({ facilities, hotelPolicies }) => {
  const { conditions, airportShuttle, wifi } = facilities;

  return (
    <div className="hotel-information">
      <HotelServices airportShuttle={airportShuttle} wifi={wifi} />
      {conditions && conditions.length > 0 && <HotelConditions conditions={conditions} />}
      {hotelPolicies && <HotelAdditionalInformation hotelPolicies={hotelPolicies} />}
    </div>
  );
};

HotelInformation.propTypes = {
  facilities: PropTypes.shape({
    conditions: PropTypes.arrayOf(PropTypes.string),
    airportShuttle: PropTypes.shape({
      pickUp: PropTypes.string,
      dropOff: PropTypes.string,
    }),
    wifi: PropTypes.string,
  }).isRequired,
  hotelPolicies: PropTypes.string,
};

HotelInformation.defaultProps = { hotelPolicies: null };

HotelConditions.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

HotelServices.propTypes = {
  airportShuttle: PropTypes.shape({
    pickUp: PropTypes.string,
    dropOff: PropTypes.string,
  }),
  wifi: PropTypes.string,
};

HotelServices.defaultProps = { airportShuttle: null, wifi: null };

HotelAdditionalInformation.propTypes = {
  hotelPolicies: PropTypes.string,
};

HotelAdditionalInformation.defaultProps = { hotelPolicies: null };

export default HotelInformation;
