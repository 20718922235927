const getCarChannelIdentifier = source => {
  if (source) {
    const { supplier, identifier, account } = source;
    const channelSupplier = supplier || identifier;

    return `${channelSupplier}: ${account}`;
  }

  return null;
};

export default getCarChannelIdentifier;
