import { FARE_OPTION_SELECTED, FLIGHT_GROUP_SELECTED } from '../../actions/flight';

export default function tabKeyWithSelectedFareOption(state = null, action) {
  switch (action.type) {
    case FARE_OPTION_SELECTED:
      return action.tabKeyOfSelectedFairOption;

    case FLIGHT_GROUP_SELECTED:
      return null;

    default:
      return state;
  }
}
