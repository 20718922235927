import React from 'react';
import PropTypes from 'prop-types';
import Facility from '../Facility';

const FacilityTypeList = ({ list, type, listItemComponent: ListItemComponent }) =>
  list.map(({ type: facilityTypeItem, amount, currency, modifier }) => (
    <li key={facilityTypeItem} className="car-result-card__facility">
      <Facility
        facility={<ListItemComponent type={facilityTypeItem} />}
        amount={amount}
        type={type}
        currency={currency}
        modifier={modifier}
      />
    </li>
  ));

FacilityTypeList.defaultProps = {
  list: [],
  type: 'facilities',
};

FacilityTypeList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      amount: PropTypes.string,
      currency: PropTypes.string,
      modifier: PropTypes.string,
    }),
  ),
  type: PropTypes.string,
  listItemComponent: PropTypes.node.isRequired,
};

export default FacilityTypeList;
