import { DEFAULT_PAGE_NUMBER } from 'sharedWebpack/helpers/constants/common';

const useFilterQueryHandler = setQuery => {
  const handleListFilter = (filterName, filterQuery) =>
    setQuery({ page: DEFAULT_PAGE_NUMBER, [filterName]: filterQuery });

  return handleListFilter;
};

export default useFilterQueryHandler;
