import React from 'react';
import PropTypes from 'prop-types';
import FacilityListItem from 'sharedWebpack/IBE/SearchResult/car/FacilityListItem';
import FuelType from 'sharedWebpack/IBE/SearchResult/car/FuelType';

const CarFacilitiesItems = ({ list }) => {
  const { seatCount, doors, airConditioning, fuelType, airBag } = list;

  return (
    <div className="car-item__facilities d-flex align-center column-gap-4 row-gap-12 wrap w-100">
      {seatCount > 0 && (
        <FacilityListItem
          className="car-item__facilities-item"
          label={I18n.t('components.ibe.cars_facilities.seats', {
            seats: seatCount,
          })}
        />
      )}
      {Number(doors) > 0 && (
        <FacilityListItem
          className="car-item__facilities-item"
          label={I18n.t('components.ibe.cars_facilities.doors', {
            count: doors,
          })}
        />
      )}
      {airConditioning && (
        <FacilityListItem
          className="car-item__facilities-item"
          label={I18n.t('components.ibe.cars_facilities.air_conditioned')}
        />
      )}
      {fuelType && (
        <FacilityListItem
          className="car-item__facilities-item"
          label={<FuelType type={fuelType} />}
        />
      )}
      {airBag && (
        <FacilityListItem
          className="car-item__facilities-item"
          label={I18n.t('components.ibe.cars_facilities.air_bag')}
        />
      )}
    </div>
  );
};

CarFacilitiesItems.propTypes = {
  list: PropTypes.shape({
    seatCount: PropTypes.number,
    doors: PropTypes.string,
    airConditioning: PropTypes.bool,
    fuelType: PropTypes.string,
    airBag: PropTypes.bool,
  }).isRequired,
};

export default CarFacilitiesItems;
