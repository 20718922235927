import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Header } from '@wtag/rcl-typography';
import { PillTabs, Link } from '@wtag/react-comp-lib';
import { Tabs as RTabs } from '@wtag/rcl-rtabs';
import TabContent from './TabContent';
import InvitationTable from './InvitationTable';

import '../styles.scss';

const List = ({ urls }) => {
  const invitationState = useMemo(
    () => [
      {
        key: 0,
        title: I18n.t('public.components.travel_arrangement_settings.tab_title.received'),
        getContent: () => <InvitationTable type="clients" state="pending" key="clients" />,
      },
      {
        key: 1,
        title: I18n.t('public.components.travel_arrangement_settings.tab_title.sent'),
        getContent: () => <InvitationTable type="arrangers" state="pending" key="arrangers" />,
      },
    ],
    [],
  );

  const travelArrangerState = useCallback(
    type => [
      {
        key: 0,
        title: I18n.t('public.components.travel_arrangement_settings.tab_title.active'),
        getContent: () => <TabContent type={type} state="active" key="active" />,
      },
      {
        key: 1,
        title: I18n.t('public.components.travel_arrangement_settings.tab_title.inactive'),
        getContent: () => <TabContent type={type} state="inactive" key="inactive" />,
      },
    ],
    [],
  );

  const travelArrangerTypes = useMemo(
    () => [
      {
        key: 0,
        title: I18n.t('public.components.travel_arrangement_settings.tab_title.arrangers'),
        getContent: () => (
          <PillTabs
            panelClass="rtabs__panel-arrangers"
            items={travelArrangerState('arrangers')}
            showMore={false}
          />
        ),
      },
      {
        key: 1,
        title: I18n.t('public.components.travel_arrangement_settings.tab_title.clients'),
        getContent: () => (
          <PillTabs
            panelClass="rtabs__panel-clients"
            items={travelArrangerState('clients')}
            showMore={false}
          />
        ),
      },
      {
        key: 2,
        title: I18n.t('public.components.travel_arrangement_settings.tab_title.invitations'),
        getContent: () => <PillTabs items={invitationState} showMore={false} />,
      },
    ],
    [travelArrangerState, invitationState],
  );

  return (
    <div className="col-grid">
      <div className="col-grid col-bleed direction-row justify-space-between">
        <Header level={5} weight="medium">
          {I18n.t('public.components.travel_arrangement_settings.title')}
        </Header>
        <Link href={urls.new} type="button" size="small" modifier="default">
          {I18n.t('public.components.travel_arrangement_settings.label.send_invitation')}
        </Link>
      </div>

      <div className="col-12 col-bleed-x travel-arranger__rtabs travel-arranger__rtabs-container">
        <RTabs items={travelArrangerTypes} />
      </div>
    </div>
  );
};

List.propTypes = {
  urls: PropTypes.shape({
    new: PropTypes.string.isRequired,
  }).isRequired,
};

export default List;
