import React from 'react';
import { node, string } from 'prop-types';
import classnames from 'classnames';
import SanitizedHTML from 'sharedWebpack/SanitizedHTML';
import './style.scss';

const DateTimeLocaleInterpolationWrapper = ({ time, localeKey, timezone, className }) => {
  const currentTimezone = timezone || '';

  return (
    <span className={classnames('locale-interpolation', className)}>
      <SanitizedHTML
        html={I18n.t(localeKey, {
          time,
          timezone: currentTimezone,
        })}
      />
    </span>
  );
};

DateTimeLocaleInterpolationWrapper.defaultProps = {
  className: null,
  timezone: '',
};

DateTimeLocaleInterpolationWrapper.propTypes = {
  time: node.isRequired,
  localeKey: string.isRequired,
  timezone: string,
  className: string,
};

export default DateTimeLocaleInterpolationWrapper;
