import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import '../../admin/Travellers/styles.scss';

const FormatText = props => {
  const { frontIcon, label, itemUrl } = props;
  return (
    <div className="travellers-format-text">
      <div className="travellers-format-text__items">
        {frontIcon}
        <div className="travellers-format-text__items-description">{label}</div>
      </div>
      <Fragment>
        <a href={itemUrl}>
          <IconButton
            icon={<Icon size="normal" name="download" color="tertiary" />}
            version="v2"
            color="tertiary"
          />
        </a>
      </Fragment>
    </div>
  );
};

FormatText.propTypes = {
  frontIcon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  itemUrl: PropTypes.string.isRequired,
};

export default FormatText;
