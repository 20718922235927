import React from 'react';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Card from '@wtag/rcl-card';

const TravelerInformationLoader = () => (
  <Card version="v2" size="full" className="col-12 travel-information-card">
    <div className="grid grid-gap-12">
      <div className="col-6">
        <ContentLoaderPlaceholder numberOfLines={5} isRounded={true} showBackground={false} />
      </div>
      <div className="col-6">
        <ContentLoaderPlaceholder numberOfLines={5} isRounded={true} showBackground={false} />
      </div>
    </div>
  </Card>
);

export default TravelerInformationLoader;
