import PropTypes from 'prop-types';
import {
  byTokenShape,
  bookingComponentRefByTokenShape,
  bookingRequestsEncodedByTokenShape,
} from '../shapes';

const ccvPaymentProps = {
  requestsToAuthenticateByToken: PropTypes.arrayOf(PropTypes.string).isRequired,
  bookingRequestsEncodedByToken: PropTypes.shape(bookingRequestsEncodedByTokenShape).isRequired,
  bookingComponentRefByToken: PropTypes.shape(bookingComponentRefByTokenShape).isRequired,
  stringifiedBookingReq: PropTypes.string.isRequired,
  currentAuthenticationRequest: PropTypes.string.isRequired,
  stringifiedThreeDsStatuses: PropTypes.string.isRequired,
  skipAuthenticationStatusesByToken: PropTypes.shape(byTokenShape).isRequired,
  errorAlertByToken: PropTypes.shape(byTokenShape).isRequired,
  successAlertByToken: PropTypes.shape(byTokenShape).isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  primaryButtonDisabled: PropTypes.bool.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  triggerResetCardAddressForm: PropTypes.bool.isRequired,
  setSkipAuthenticationStatusesByToken: PropTypes.func.isRequired,
  updateTriggerResetCardAddressForm: PropTypes.func.isRequired,
  updateCurrentAuthenticationRequest: PropTypes.func.isRequired,
  updateRequestsToAuthenticateByToken: PropTypes.func.isRequired,
  updateBookingRequestsEncodedByToken: PropTypes.func.isRequired,
  updateBookingComponentRefByToken: PropTypes.func.isRequired,
  stringifyNewBookingRequest: PropTypes.func.isRequired,
  updateStringifiedThreeDsStatuses: PropTypes.func.isRequired,
  updateErrorAlertByToken: PropTypes.func.isRequired,
  updateSuccessAlertByToken: PropTypes.func.isRequired,
  updatePrimaryButtonText: PropTypes.func.isRequired,
  togglePrimaryButtonDisabled: PropTypes.func.isRequired,
  updateOnPrimaryButtonClickCallback: PropTypes.func.isRequired,
  resetButtonAndAlertStates: PropTypes.func.isRequired,
};

export default ccvPaymentProps;
