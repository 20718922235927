import { PLACES_SEARCHED, PLACE_DETAILS_FETCHED } from '../../actions/common';

export function places(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case PLACES_SEARCHED:
      action.places.forEach(place => {
        newState[place.id] = place;
      });
      return newState;

    case PLACE_DETAILS_FETCHED:
      newState[action.data.id] = action.data;
      return newState;

    default:
      return state;
  }
}

function placesSearched(state, placesToSearch) {
  const newState = new Map(state);
  placesToSearch.forEach(place => newState.set(place.id, place));
  return newState;
}

export function placeSuggestions(state = new Map(), action) {
  switch (action.type) {
    case PLACES_SEARCHED:
      return placesSearched(state, action.places);

    case PLACE_DETAILS_FETCHED:
      return placesSearched(state, [action.data]);

    default:
      return state;
  }
}
