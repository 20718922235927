import React from 'react';
import PropTypes from 'prop-types';
import routes from 'agentRoutes';
import OrdersList from 'sharedWebpack/Orders/List';
import '../styles.scss';

const List = props => {
  const {
    actionsRenderer,
    hideArchived,
    hideCreateOrder,
    personId,
    displaySortingPills,
    additionalQueryParams,
    ordersPath,
    displayColumns,
    urls,
    archived,
    layman,
  } = props;

  return (
    <OrdersList
      actionsRenderer={actionsRenderer}
      hideArchived={hideArchived}
      hideCreateOrder={hideCreateOrder}
      personId={personId}
      displaySortingPills={displaySortingPills}
      additionalQueryParams={additionalQueryParams}
      ordersPath={archived ? routes.admin.orderMuseum() : ordersPath}
      displayColumns={displayColumns}
      urls={urls}
      layman={layman}
    />
  );
};

List.defaultProps = {
  actionsRenderer: null,
  hideArchived: false,
  archived: false,
  hideCreateOrder: false,
  personId: null,
  tableProps: undefined,
  urls: {},
  displaySortingPills: {
    id: true,
    destination: true,
    lastUpdated: true,
    startDate: false,
  },
  additionalQueryParams: null,
  displayColumns: {
    id: true,
    title: true,
    tasks: true,
    destination: true,
    travellers: true,
    products: true,
    state: true,
    total: true,
    startDate: true,
    endDate: true,
    actions: true,
  },
  layman: false,
};

List.propTypes = {
  urls: PropTypes.shape({
    archived: PropTypes.string,
    new: PropTypes.string,
  }),
  hideArchived: PropTypes.bool,
  actionsRenderer: PropTypes.func,
  hideCreateOrder: PropTypes.bool,
  personId: PropTypes.number,
  displaySortingPills: PropTypes.shape({
    id: PropTypes.bool,
    destination: PropTypes.bool,
    lastUpdated: PropTypes.bool,
    startDate: PropTypes.bool,
  }),
  tableProps: PropTypes.shape({
    id: PropTypes.bool,
    title: PropTypes.bool,
    tasks: {
      totalCount: PropTypes.bool,
      high: PropTypes.bool,
      medium: PropTypes.bool,
      low: PropTypes.bool,
    },
    destination: PropTypes.bool,
    owner: PropTypes.bool,
    products: PropTypes.bool,
    state: PropTypes.bool,
    total: PropTypes.bool,
    lastUpdate: PropTypes.bool,
    startDate: PropTypes.bool,
  }),
  additionalQueryParams: PropTypes.shape({
    scope: PropTypes.func,
  }),
  displayColumns: PropTypes.shape({
    id: PropTypes.bool,
    orderId: PropTypes.bool,
    tasks: PropTypes.bool,
    travellers: PropTypes.bool,
    invoiceDate: PropTypes.bool,
    dueDate: PropTypes.bool,
    amount: PropTypes.bool,
    actions: PropTypes.bool,
  }),
  ordersPath: PropTypes.string.isRequired,
  archived: PropTypes.bool,
  layman: PropTypes.bool,
};

export default List;
