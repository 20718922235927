/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import Row from '../Row';

function SeatWithPriceBreakdown(props) {
  const { seats, totalFairOfSeats, currency, segmentsWithSeatId } = props;

  return (
    seats.length > 0 && (
      <Row
        name={
          <span>
            {`${seats.length} x `}
            <I18nText id="ibe.summary.seat" returnStringOnly={true} />
          </span>
        }
        quantity={seats.length}
        price={totalFairOfSeats}
        currency={currency}
        journeyElementType="seat"
        seatList={seats}
        segmentsWithSeatId={segmentsWithSeatId}
      />
    )
  );
}

SeatWithPriceBreakdown.propTypes = {
  seats: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  totalFairOfSeats: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  segmentsWithSeatId: PropTypes.shape().isRequired,
};

export default SeatWithPriceBreakdown;
