/* global I18n */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'sharedWebpack/TimeAgo';
import moment from 'moment';
import Markdown from '../MarkDown';
import ChatMessage from './ChatMessage';
import AppContext from '../AppContext';
import { senderInformation } from '../SenderInformation';

const MessageList = props => {
  const {
    roomUsers,
    context,
    messages,
    currentUserId,
    replyHandler,
    focusToQuotedMessage,
    disableFocusingQuotedMessage,
    sendChatMessage,
  } = props;
  const focusRef = useRef([]);

  const areMessagesOnSameDate = (currentMessageTimestamp, previousMessageTimestamp) =>
    moment(currentMessageTimestamp).isSame(previousMessageTimestamp, 'day');

  const messageCount = messages.length;
  const fetchedMessages = [];

  const focusToTheQuotedMessage = async (quotedMessageId, time = 0) =>
    new Promise(resolve => {
      focusRef.current[quotedMessageId].scrollIntoView();
      setTimeout(() => {
        resolve();
      }, time);
    }).then(() => {
      // needs to scroll to the expected element before adding className
      const textDiv = focusRef.current[quotedMessageId].getElementsByClassName(
        'chat-message__body-text',
      )[0];
      textDiv.classList.add('chat-message__focus');

      setTimeout(() => {
        if (focusRef.current[quotedMessageId]) {
          textDiv.classList.remove('chat-message__focus');
        }
      }, 1200);
      disableFocusingQuotedMessage();
    });

  const focusToMessageOnFetching = quotedMessageId => {
    if (focusRef.current[quotedMessageId]) {
      focusToTheQuotedMessage(quotedMessageId, 500);
      return;
    }

    setTimeout(focusToMessageOnFetching, 300, quotedMessageId);
  };

  const onFocus = quotedMessage => {
    if (focusRef.current[quotedMessage.id]) {
      focusToTheQuotedMessage(quotedMessage.id);
      return;
    }

    focusToQuotedMessage(quotedMessage);
    focusToMessageOnFetching(quotedMessage.id);
  };

  for (let index = messageCount - 1; index >= 0; index -= 1) {
    const message = messages[index];
    fetchedMessages.push(
      <div className="chat__messages-container" key={message.id}>
        <ChatMessage
          messageIndex={index}
          sender={senderInformation(roomUsers, message, context)}
          markdown={Markdown(context.laymanMode)}
          currentUserId={currentUserId}
          laymanMode={context.laymanMode}
          replyHandler={replyHandler}
          roomUsers={roomUsers}
          tenant={message.tenant}
          focusToQuotedMessage={onFocus}
          ref={focusRef}
          sendChatMessage={sendChatMessage}
          {...message}
        />
        {index < messageCount - 1 &&
          !areMessagesOnSameDate(message.timestamp, messages[index + 1].timestamp) && (
            <div className="chat__messages-border">
              <span className="chat__messages-border--with-dash-content" />
              <div className="chat__messages-border__time">
                <TimeAgo datetime={message.timestamp} withRclDateComponent={true} />
              </div>
              <span className="chat__messages-border--with-dash-content" />
            </div>
          )}
      </div>,
    );
  }

  return fetchedMessages;
};

MessageList.defaultProps = {
  tenant: null,
};

MessageList.propTypes = {
  tenant: PropTypes.string,
  fetchMoreHistory: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      timestamp: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      tenant: PropTypes.string,
      roomId: PropTypes.string,
      senderId: PropTypes.number,
      senderName: PropTypes.string,
    }),
  ).isRequired,
  roomUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      tenant: PropTypes.string,
    }),
  ).isRequired,
  context: PropTypes.shape({
    connected: PropTypes.bool,
    currentUserAvatar: PropTypes.string,
    currentUserId: PropTypes.number,
    currentUserName: PropTypes.string,
    laymanMode: PropTypes.bool,
    tenant: PropTypes.string,
    messageInputRef: PropTypes.shape({}),
    focusMessageInput: PropTypes.func,
  }).isRequired,
};

const MessageListWithContext = props => (
  <AppContext.Consumer>
    {context => <MessageList context={context} {...props} />}
  </AppContext.Consumer>
);

export default MessageListWithContext;
