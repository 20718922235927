import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import SelectBox from '@wtag/rcl-select-box';

const anyTime = <I18nText id="departure_times.anytime" returnStringOnly={true} />;
const timeIntervalOptions = [{ label: anyTime, value: 'anytime' }];

for (let hour = 0; hour < 24; hour += 1) {
  ['00', '30'].forEach(minutes => {
    const hourString = hour < 10 ? `0${hour}` : hour;
    const value = `${hourString}:${minutes}`;
    timeIntervalOptions.push({ label: value, value });
  });
}

const TimeSelectBox = props => {
  const timeIntervalOptionsProp = [...timeIntervalOptions];
  if (!props.addAnytimeOption) {
    timeIntervalOptionsProp.shift();
  }

  return <SelectBox options={timeIntervalOptionsProp} {...props} />;
};

TimeSelectBox.defaultProps = {
  addAnytimeOption: false,
};

TimeSelectBox.propTypes = {
  addAnytimeOption: PropTypes.bool,
};

export default TimeSelectBox;
