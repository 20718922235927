import mapAvailableChannels from './availableChannelsMapping';
import mapTravelArranger from './travelArrangerMapping';

const mapBookingAppContext = ibeProps => ({
  allowBookingOfUnapprovedItems: ibeProps.allowBookingOfUnapprovedItems,
  availableChannels: mapAvailableChannels(ibeProps.searchAccounts, ibeProps.availableChannels),
  callbackParams: ibeProps.callbackParams,
  currency: ibeProps.currency,
  laymanMode: ibeProps.laymanMode,
  iframeMode: ibeProps.forceAdvancedSearch,
  creditCardEnabled: ibeProps.creditCardEnabled,
  paymentDisabled: ibeProps.paymentDisabled,
  allowPaymentViaInvoice: ibeProps.allowPaymentViaInvoice,
  paymentOptions: ibeProps.paymentOptions,
  searchAccounts: ibeProps.searchAccounts,
  travelArranger: mapTravelArranger(ibeProps.travelArrangerPermissionLevel),
  travelArrangerDefaultEmail: ibeProps.travelArrangerDefaultEmail,
  travelArrangerPresent: ibeProps.travelArrangerPresent,
  confirmationMailForTravelApp: ibeProps.confirmationMailForTravelApp,
  confirmationMailForAgent: ibeProps.confirmationMailForAgent,
  currentUserAge: ibeProps.currentUserAge,
  travelerLoggedIn: ibeProps.travelerLoggedIn,
  travelerHasOrganization: ibeProps.travelerHasOrganization,
  citizenshipCountryCode: ibeProps.citizenshipCountryCode,
  enableVisaAdvisory: ibeProps.enableVisaAdvisory,
  enableIdDocOptional: ibeProps.enableIdDocOptional,
  exchange: ibeProps.exchange,
  initialSearchParams: ibeProps.initialSearchParams,
  countryCodes: ibeProps.countryCodes,
  searchInputConfiguration: {
    showDepartureTime: ibeProps.searchInputConfiguration.showDepartureTime,
    showPlusMinusThreeDays: ibeProps.searchInputConfiguration.showPlusMinusThreeDays,
    showNonStop: ibeProps.searchInputConfiguration.showNonStop,
    showAirlinePreference: ibeProps.searchInputConfiguration.showAirlinePreference,
    showOnlyFlexibleFlight: ibeProps.searchInputConfiguration.showOnlyFlexibleFlight,
    showBookingClass: ibeProps.searchInputConfiguration.showBookingClass,
    showAlliancePreferences: ibeProps.searchInputConfiguration.showAlliancePreferences,
    showOnlyBagIncludedFlight: ibeProps.searchInputConfiguration.showOnlyBagIncludedFlight,
    showExcludeCodeShareFlights: ibeProps.searchInputConfiguration.showExcludeCodeShareFlights,
    enableSearchItineraryOnly: ibeProps.searchInputConfiguration.enableSearchItineraryOnly,
    whitelistedAirports: ibeProps.searchInputConfiguration.whitelistedAirports,
  },
  ccvPublicToken: ibeProps.ccvPublicToken,
  ccvURL: ibeProps.ccvURL,
  minHoursInFuture: ibeProps.minHoursInFuture,
  tenantIdentifier: ibeProps.tenantIdentifier,
  offerOnly: ibeProps.offerOnly,
  globalPolicyPreferences: ibeProps.globalPolicyPreferences,
  individualAccountingTypeEnabled: ibeProps.individualAccountingTypeEnabled,
  frozen: ibeProps.frozen,
  wtsEnabled: ibeProps.wtsEnabled,
  viewMode: ibeProps.viewMode,
  isShowFilters: ibeProps.isShowFilters,
  canExcludeWtsContent: ibeProps.canExcludeWtsContent,
});

export default mapBookingAppContext;
