const FARE_EXPIRED_STATUS = 422;
const SSR_STATUS = 447;
const HAS_UNCONFIRMED_CHANGES_STATUS = 409;
const MODAL_CLOSE_TIMEOUT = 5000;
const DELAY_500 = 500;
const OLD_STATE = 'before';
const NEW_STATE = 'after';

export {
  FARE_EXPIRED_STATUS,
  SSR_STATUS,
  HAS_UNCONFIRMED_CHANGES_STATUS,
  MODAL_CLOSE_TIMEOUT,
  DELAY_500,
  OLD_STATE,
  NEW_STATE,
};
