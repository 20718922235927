import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'throttle-debounce/debounce';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Card from '@wtag/rcl-card';
import Input from '@wtag/rcl-input';

import { searchCustomer } from '../../actions/common';
import { mapCustomerData } from '../../lib/helpers/mapCustomerData';
import CustomerTable from './CustomerTable';

class TravelersSearchAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  onUpdateInput = query => {
    this.setState({ value: query });
    this.debouncedSearchCustomers(query);
  };

  customerSelected = customer => {
    this.props.onChange(customer);
  };

  searchCustomer = query => {
    this.props.searchCustomer(this.props.index, query, this.props.callbackParams);
  };

  debouncedSearchCustomers = debounce(300, query =>
    this.searchCustomer(query, this.props.callbackParams),
  );

  render() {
    const { customers, setNewTravelerFormPage, selectedCustomers } = this.props;

    return (
      <div className="traveler-search-form">
        <Card version="v2" size="full">
          <div className="traveler-search-form__search-traveler-header">
            <div className="traveler-search-form__search-traveler-header-text">
              {I18n.t('components.ibe.traveler_details.hints.search_existing')}
            </div>
            <div className="traveler-search-form__search-traveler-header-search-bar">
              <Input
                size="tiny"
                placeholder={I18n.t(
                  'components.ibe.traveler_details.form.placeholders.search_traveler',
                )}
                onChange={value => this.onUpdateInput(value)}
                preIcon={<Icon name="search" />}
                value={this.state.value}
              />
            </div>
          </div>

          {customers.length > 0 && (
            <CustomerTable
              onChange={this.props.onChange}
              customers={customers}
              selectedCustomers={selectedCustomers}
            />
          )}
        </Card>
        <Card version="v2" size="full" className="traveler-search-form__add-new-card">
          <div className="col-grid col-bleed direction-row align-center justify-space-between wrap">
            <div className="traveler-search-form__add-new-card-hint">
              {I18n.t('components.ibe.traveler_details.hints.add_new_traveler')}
            </div>
            <div className="traveler-search-form__add-new-card-add-new">
              <Button
                version="v2"
                onClick={() => setNewTravelerFormPage('traveler_information')}
                size="small"
                label={I18n.t('components.ibe.traveler_details.buttons.add_new_traveler')}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

TravelersSearchAutoComplete.propTypes = {
  customers: PropTypes.shape([]).isRequired,
  selectedCustomers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  index: PropTypes.number.isRequired,
  searchCustomer: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setNewTravelerFormPage: PropTypes.func.isRequired,
  callbackParams: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  customers: mapCustomerData(state.common.customerSearchResults[ownProps.index]),
  selectedCustomers: state.common.selectedCustomers,
});

const mapDispatchToProps = dispatch => ({
  searchCustomer: (index, query, callbackParams) =>
    dispatch(searchCustomer(index, query, callbackParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TravelersSearchAutoComplete);
