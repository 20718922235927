import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SeatMapUnavailableCard from 'sharedWebpack/SeatMapUnavailableCard';
import Row from './SeatMapRow';
import seatMapRowShape from '../shapes/seatMapRowShape';

const SeatMap = ({ rows, columns, aisleColumns, flight, resultId }) => {
  const groupMap = {};
  const flatGroupMap = {};

  const handleGroup = (previousRow, rowIndex, property, groupName) => {
    if (previousRow === undefined || groupMap[property] === undefined) {
      groupMap[property] = {
        postions: [rowIndex],
        groupName,
      };
    } else if (previousRow && previousRow[property] === false) {
      groupMap[property].postions.push(rowIndex);
    }
  };

  rows.forEach((row, index) => {
    const previousRow = rows[index - 1];
    const { wing, exit } = row;

    if (wing) {
      handleGroup(previousRow, index, wing, I18n.t('components.ibe.seat_map.wing_rows'));
    }
    if (exit) {
      handleGroup(previousRow, index, exit, I18n.t('components.ibe.seat_map.exit'));
    }
  });

  Object.values(groupMap).forEach(item => {
    item.postions.forEach(position => {
      flatGroupMap[position] = item.groupName;
    });
  });

  const seatRows = rows.map((row, index) => (
    <Row
      groupMap={flatGroupMap}
      rowIndex={index}
      resultId={resultId}
      key={row.number}
      flight={flight}
      {...row}
    />
  ));

  const seatNumbers = columns.map(columnKey => (
    <div
      key={columnKey}
      className={classNames('seat-number', 'seat-number--margin-left', {
        aisle: aisleColumns.includes(columnKey),
      })}
    >
      {columnKey && columnKey.toUpperCase()}
    </div>
  ));

  if (seatRows.length === 0 && seatNumbers.length === 0) {
    return <SeatMapUnavailableCard />;
  }

  return (
    <div className="seat-map">
      <div className="row">
        <div className="row-number">&nbsp;</div>
        <div className="seat-numbers">{seatNumbers}</div>
      </div>
      {seatRows}
    </div>
  );
};

SeatMap.propTypes = {
  resultId: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  aisleColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(seatMapRowShape).isRequired,
  flight: PropTypes.string.isRequired,
};

export default SeatMap;
