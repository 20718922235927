import React from 'react';
import DateTime from 'sharedWebpack/DateTime';
import PropTypes from 'prop-types';

const AirportInformations = ({ airport, date, terminal }) => (
  <div className="flight-item-trip-airport-info">
    <div className="col-grid col-bleed direction-row align-center justify-center">
      <span className="flight-item-trip-airport-info__airport-code">{airport.code}</span>
      <img
        className="flight-item-trip-airport-info__country-flag"
        src={airport.countryFlag}
        alt={airport.countryName}
      />
    </div>
    <div className="col-grid col-bleed">
      <div className="flight-item-trip-airport-info__airport-name">{airport.name}</div>
      <div className="flight-item-trip-airport-info__place-country">
        {airport.placeName}, {airport.countryName}
      </div>
      <div className="flight-item-trip-airport-info__date">
        <DateTime dateTime={date} format="shortWithTime" />
      </div>
      {terminal && (
        <div className="flight-item-trip-airport-info__terminal">
          {I18n.t('components.flight.terminal', {
            number: terminal,
          })}
        </div>
      )}
    </div>
  </div>
);

AirportInformations.propTypes = {
  airport: PropTypes.shape({
    code: PropTypes.string,
    countryFlag: PropTypes.string,
    countryName: PropTypes.string,
    placeName: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
  terminal: PropTypes.string.isRequired,
};

export default AirportInformations;
