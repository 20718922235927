import { AIRLINE_INFORMATIONS_FETCHED, AIRLINES_SEARCHED } from '../../actions/flight';

export default function airlineInformations(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case AIRLINE_INFORMATIONS_FETCHED:
      newState[action.data.code] = action.data;
      return newState;

    case AIRLINES_SEARCHED:
      action.airlines.forEach(airline => {
        newState[airline.code] = airline;
      });
      return newState;

    default:
      return state;
  }
}
