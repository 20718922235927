import React from 'react';
import PropTypes from 'prop-types';
import Link from '@wtag/rcl-link';
import FlightServiceSelection from './FlightServiceSelection/FlightServiceSelectionContainer';
import getServices from '../../helpers/getServices';

class AncillaryServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  showAncillaryServicesLinkButton = () => (
    <Link
      modifier="tertiary"
      size="tiny"
      disabled={!this.props.services}
      onClick={this.state.modalOpen}
    >
      {I18n.t('components.ibe.flight.select_ancillaries')}
    </Link>
  );

  render() {
    if (this.props.services) {
      return (
        <FlightServiceSelection
          services={getServices(this.props.services)}
          item={this.props.item}
          button={this.showAncillaryServicesLinkButton()}
          title={I18n.t('components.ibe.cart_item.flight.ancillary_title')}
          trips={this.props.trips}
          setShouldLockScroll={this.props.setShouldLockScroll}
          callbackParams={this.context.callbackParams}
        />
      );
    }

    return this.showAncillaryServicesLinkButton();
  }
}

AncillaryServices.contextTypes = {
  frozen: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
};

AncillaryServices.propTypes = {
  services: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  trips: PropTypes.shape({}).isRequired,
  setShouldLockScroll: PropTypes.func.isRequired,
};

export default AncillaryServices;
