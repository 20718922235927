import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { Radio, CheckBox, I18nText } from '@wtag/react-comp-lib';
import { connect } from 'react-redux';
import Button from '@wtag/rcl-button';

import AssistanceRequests from './AssistanceRequests';
import IdentificationDocument from './IdentificationDocument';
import IdentificationDocumentLists from './IdentificationDocument/Lists';
import FrequentFlyerNumber from './FrequentFlyerNumber';
import FrequentFlyerNumberLists from './FrequentFlyerNumber/Lists';
import MealRequestsForm from './MealRequests';
import { customerSelected } from '../../../actions/common';

const TravelInformation = props => {
  const {
    selectedCustomer,
    fields,
    enableIdDocOptional,
    internationalFlight,
    identificationDocumentsRequired,
    frequentFlyerNumber,
    index,
    disabled,
    setNewTravelerFormPage,
    touched,
    flightResults,
    isEditForm,
    setSingleTravelerShowPage,
    reset,
    mealRequests,
    assistanceRequests,
    onCustomerSelected,
    setIsGuestTraveler,
  } = props;

  const {
    mealRequests: { value: mealRequestsValue },
    assistanceRequests: { value: assistanceRequestsValue },
  } = fields;
  const identificationDocumentsLength =
    selectedCustomer &&
    selectedCustomer.identificationDocuments &&
    selectedCustomer.identificationDocuments.length > 0;
  const ffnListLength =
    selectedCustomer &&
    selectedCustomer.frequentFlyerNumbersList &&
    selectedCustomer.frequentFlyerNumbersList.length > 0;

  const identificationDocumentFormInitialState = !!(
    identificationDocumentsLength ||
    (!enableIdDocOptional && internationalFlight) ||
    identificationDocumentsRequired
  );
  const frequentFlyerNumberFormInitialState =
    ffnListLength || (frequentFlyerNumber.carrier.value && frequentFlyerNumber.number.value);
  const mealRequestFormInitialState = mealRequestsValue && mealRequestsValue.length > 0;
  const assistanceRequestFormInitialState =
    assistanceRequestsValue && assistanceRequestsValue.length > 0;

  const [addNewFrequentFlyerNumber, setAddNewFrequentFlyerNumber] = useState(false);
  const [addNewIdentificationDocument, setAddNewIdentificationDocument] = useState(false);
  const [openIdentificationDocumentForm, setOpenIdentificationDocumentForm] = useState(
    identificationDocumentFormInitialState,
  );
  const [openFrequentFlyerNumberForm, setOpenFrequentFlyerNumberForm] = useState(
    frequentFlyerNumberFormInitialState,
  );
  const [openMealRequestForm, setOpenMealRequestForm] = useState(mealRequestFormInitialState);
  const [openAssistanceRequestForm, setOpenAssistanceRequestForm] = useState(
    assistanceRequestFormInitialState,
  );

  const [previousFormData] = useState({
    frequentFlyerNumber: { ...props.fields.frequentFlyerNumber },
    document: { ...props.fields.document },
    mealRequests: { ...props.fields.mealRequests, value: [...props.fields.mealRequests.value] },
    assistanceRequests: {
      ...props.fields.assistanceRequests,
      value: [...props.fields.assistanceRequests.value],
    },
  });

  const resetFrequentFlyerField = frequentFlyerField =>
    Object.values(frequentFlyerField).forEach(field => field.onChange(''));

  const resetMealAndAssistanceField = formFields => formFields.onChange([]);

  useEffect(() => {
    if (!openMealRequestForm) {
      resetMealAndAssistanceField(fields.mealRequests);
    }

    if (!openFrequentFlyerNumberForm) {
      resetFrequentFlyerField(frequentFlyerNumber);
    }

    if (!openAssistanceRequestForm) {
      resetMealAndAssistanceField(fields.assistanceRequests);
    }
  }, [openFrequentFlyerNumberForm, openAssistanceRequestForm, openMealRequestForm]);

  const addFrequentFlyerNumber = ffn => {
    ffn.number.onChange('');
    ffn.carrier.onChange('');
    setAddNewFrequentFlyerNumber(prevState => !prevState);
  };

  const toggleIdentificationDocumentForm = () => {
    setOpenIdentificationDocumentForm(prevState => !prevState);
  };

  const toggleFrequentFlyerNumberForm = () => {
    setOpenFrequentFlyerNumberForm(prevState => !prevState);
  };

  const toggleAssistanceRequestForm = () => {
    setOpenAssistanceRequestForm(prevState => !prevState);
  };

  const toggleMealRequestForm = () => {
    setOpenMealRequestForm(prevState => !prevState);
  };

  const addIdentificationDocument = document => {
    document.id.onChange('');
    document.type.onChange('');
    document.number.onChange('');
    document.country.onChange('');
    document.nationality.onChange('');
    document.validity.onChange('');

    const updatedSelectedCustomer = {
      ...selectedCustomer,

      document: {
        id: '',
        type: '',
        number: '',
        country: '',
        nationality: '',
        validity: '',
      },
    };

    onCustomerSelected(index, updatedSelectedCustomer);
    setAddNewIdentificationDocument(prevState => !prevState);
  };

  const frequentFlyerNumberEditAction = () => {
    setAddNewFrequentFlyerNumber(prevState => !prevState);
  };

  const identificationDocumentEditAction = () => {
    setAddNewIdentificationDocument(prevState => !prevState);
  };

  const saveTraveler = () => {
    let updatedSelectedCustomer;
    if (
      (typeof fields.guestUser.value === 'string' && fields.guestUser.value.length > 0) ||
      (typeof fields.guestUser.value === 'boolean' && fields.guestUser.value)
    ) {
      fields.guestUser.onChange('yes');
      updatedSelectedCustomer = {
        ...selectedCustomer,
        guestUser: 'yes',
      };
    } else {
      fields.newUser.onChange('yes');
      updatedSelectedCustomer = {
        ...selectedCustomer,
        newUser: 'yes',
      };
    }

    onCustomerSelected(index, updatedSelectedCustomer);

    if (isEditForm) {
      setSingleTravelerShowPage('show');
    }
    setIsGuestTraveler();
  };

  const removeData = () => {
    Object.keys(fields).forEach(key => {
      if (
        fields[key] &&
        key !== 'guestUser' &&
        key !== 'type' &&
        key !== 'itemChannels' &&
        typeof fields[key].value !== 'undefined'
      ) {
        fields[key].onChange('');
      }
    });
    reset(index);
  };

  const resetFormChange = () => {
    const {
      document,
      mealRequests: fleldMealRequest,
      assistanceRequests: fieldsAssistanceRequests,
      frequentFlyerNumber: fieldsFrequentFlyerNumber,
    } = props.fields;

    if (document) {
      document.id.onChange(previousFormData.document.id.value);
      document.type.onChange(previousFormData.document.type.value);
      document.number.onChange(previousFormData.document.number.value);
      document.country.onChange(previousFormData.document.country.value);
      document.nationality.onChange(previousFormData.document.nationality.value);
      document.validity.onChange(previousFormData.document.validity.value);
    }

    if (frequentFlyerNumber) {
      fieldsFrequentFlyerNumber.carrier.onChange(
        previousFormData.frequentFlyerNumber.carrier.value,
      );
      fieldsFrequentFlyerNumber.number.onChange(previousFormData.frequentFlyerNumber.number.value);
    }

    if (fleldMealRequest) {
      fleldMealRequest.onChange(previousFormData.mealRequests.value);
    }

    if (fieldsAssistanceRequests) {
      fieldsAssistanceRequests.onChange(previousFormData.assistanceRequests.value);
    }
  };

  const resetTraveler = () => {
    if (isEditForm) {
      resetFormChange();
      setSingleTravelerShowPage('show');
    } else {
      removeData();
      setNewTravelerFormPage('search');
    }
  };

  const isEditAction = () => !!(isEditForm && selectedCustomer);

  const hasFrequentFlyerNumbersList = () => isEditAction() && ffnListLength;

  const hasIdentificationDocumentsList = () => isEditAction() && identificationDocumentsLength;

  const renderFrequentFlyerForm = () => {
    if (!hasFrequentFlyerNumbersList() || addNewFrequentFlyerNumber) {
      return (
        <FrequentFlyerNumber
          frequentFlyerNumber={frequentFlyerNumber}
          touched={touched}
          disabled={disabled}
          onCustomerSelected={onCustomerSelected}
          selectedCustomer={selectedCustomer}
          travelerIndex={index}
        />
      );
    }

    return (
      <FrequentFlyerNumberLists
        fields={frequentFlyerNumber}
        frequentFlyerNumbersList={selectedCustomer.frequentFlyerNumbersList}
        frequentFlyerEditAction={frequentFlyerNumberEditAction}
        selectedCustomer={selectedCustomer}
        travelerIndex={index}
        onCustomerSelected={onCustomerSelected}
      />
    );
  };

  const renderIdentificationForm = () => {
    if (!hasIdentificationDocumentsList() || addNewIdentificationDocument) {
      return (
        <IdentificationDocument
          fields={fields.document}
          touched={touched}
          disabled={disabled}
          selectedCustomer={selectedCustomer}
          travelerIndex={index}
          onCustomerSelected={onCustomerSelected}
        />
      );
    }

    return (
      <IdentificationDocumentLists
        fields={fields.document}
        identificationDocumentsList={selectedCustomer.identificationDocumentsList}
        identificationDocumentEditAction={identificationDocumentEditAction}
        travelerIndex={index}
        selectedCustomer={selectedCustomer}
        onCustomerSelected={onCustomerSelected}
      />
    );
  };

  return (
    <Card version="v2" size="full" className="col-12 travel-information">
      {isEditForm ? (
        <div className="travel-information__header">
          <div className="travel-information__header-text">
            {I18n.t('components.ibe.traveler_details.updating_travel_information')}
          </div>
          <div className="travel-information__header-sub-text">
            {I18n.t('components.ibe.traveler_details.hints.update_travel_information')}
          </div>
        </div>
      ) : (
        <div className="travel-information__header">
          <div className="col-grid col-bleed direction-row align-center travel-information__header-text">
            <div className="travel-information__header-text-traveler">
              {I18n.t('components.ibe.traveler_details.traveler', {
                value: index + 1,
              })}
            </div>
            <div className="travel-information__header-text-page">
              {I18n.t('components.ibe.traveler_details.create_new_traveler_page', {
                page: 2,
                total: 2,
              })}
            </div>
          </div>
          <div className="travel-information__header-sub-text">
            {I18n.t('components.ibe.traveler_details.hints.create_form_text')}
          </div>
        </div>
      )}

      <div className="travel-information__content">
        <div className="travel-information__content-header">
          <CheckBox
            label={
              <I18nText id="ibe.traveler_details.identification_document" returnStringOnly={true} />
            }
            checked={openIdentificationDocumentForm}
            onChange={toggleIdentificationDocumentForm}
            size="large"
          />
          <div className="travel-information__content-header-hint">
            {I18n.t('components.ibe.traveler_details.hints.identification_document_optional', {
              count: index + 1,
            })}
          </div>
        </div>
        {openIdentificationDocumentForm && (
          <Fragment>
            {hasIdentificationDocumentsList() && (
              <div className="col-grid col-bleed direction-row align-center travel-information__content-controller">
                <Radio
                  size="large"
                  name="document_selection"
                  checked={!addNewIdentificationDocument}
                  onChange={() => setAddNewIdentificationDocument(prevState => !prevState)}
                  label={I18n.t('components.ibe.traveler_details.buttons.select_from_existing')}
                />
                <Radio
                  size="large"
                  name="document_selection"
                  checked={addNewIdentificationDocument}
                  onChange={() => addIdentificationDocument(fields.document)}
                  label={I18n.t('components.ibe.traveler_details.buttons.add_a_new')}
                />
              </div>
            )}
            <div className="travel-information__identification-document">
              {renderIdentificationForm()}
            </div>
          </Fragment>
        )}
      </div>

      <div className="travel-information__content">
        <div className="travel-information__content-header">
          <CheckBox
            label={
              <I18nText id="ibe.traveler_details.frequent_flyer_program" returnStringOnly={true} />
            }
            checked={openFrequentFlyerNumberForm}
            onChange={toggleFrequentFlyerNumberForm}
            size="large"
          />

          <div className="travel-information__content-header-hint">
            {I18n.t('components.ibe.traveler_details.hints.frequent_flyer_program', {
              count: index + 1,
            })}
          </div>
        </div>
        {openFrequentFlyerNumberForm && (
          <Fragment>
            {hasFrequentFlyerNumbersList() && (
              <div className="col-grid col-bleed direction-row align-center travel-information__content-controller">
                <Radio
                  size="large"
                  name="flyer_number"
                  checked={!addNewFrequentFlyerNumber}
                  onChange={() => setAddNewFrequentFlyerNumber(prevState => !prevState)}
                  label={I18n.t('components.ibe.traveler_details.buttons.select_from_existing')}
                />
                <Radio
                  size="large"
                  name="flyer_number"
                  checked={addNewFrequentFlyerNumber}
                  onChange={() => addFrequentFlyerNumber(frequentFlyerNumber)}
                  label={I18n.t('components.ibe.traveler_details.buttons.add_a_new')}
                />
              </div>
            )}
            <div className="travel-information__frequent-flyer-number">
              {renderFrequentFlyerForm()}
            </div>
          </Fragment>
        )}
      </div>

      <div className="travel-information__content">
        <div className="travel-information__content-header">
          <CheckBox
            label={
              <I18nText id="ibe.traveler_details.dietary_meal_requests" returnStringOnly={true} />
            }
            checked={openMealRequestForm}
            onChange={toggleMealRequestForm}
            size="large"
          />
          <div className="travel-information__content-header-hint">
            {I18n.t('components.ibe.traveler_details.hints.dietary_meal_requests', {
              count: index + 1,
            })}
          </div>
        </div>
        {openMealRequestForm && (
          <div className="travel-information__meal-request-form">
            <MealRequestsForm
              fields={fields}
              flightResults={flightResults}
              mealRequests={mealRequests}
              disabled={disabled}
              travelerIndex={index}
              selectedCustomer={selectedCustomer}
              onCustomerSelected={onCustomerSelected}
            />
          </div>
        )}
      </div>

      <div className="travel-information__content">
        <div className="travel-information__content-header">
          <CheckBox
            label={
              <I18nText id="ibe.traveler_details.assistance_request" returnStringOnly={true} />
            }
            checked={openAssistanceRequestForm}
            onChange={toggleAssistanceRequestForm}
            size="large"
          />
          <div className="travel-information__content-header-hint">
            {I18n.t('components.ibe.traveler_details.hints.assistance_request', {
              count: index + 1,
            })}
          </div>
        </div>
        {openAssistanceRequestForm && (
          <div className="travel-information__assistance-requests">
            <AssistanceRequests
              fields={fields}
              assistanceRequests={assistanceRequests}
              travelerIndex={index}
              selectedCustomer={selectedCustomer}
              onCustomerSelected={onCustomerSelected}
            />
          </div>
        )}
      </div>

      <div className="col-grid col-bleed direction-row align-center travel-information__actions">
        <div className="travel-information__actions-confirm">
          {isEditForm ? (
            <Button
              version="v2"
              onClick={saveTraveler}
              size="small"
              type="primary"
              label={I18n.t('components.ibe.traveler_details.buttons.update')}
            />
          ) : (
            <Button
              version="v2"
              onClick={saveTraveler}
              size="small"
              type="primary"
              label={I18n.t('components.ibe.traveler_details.buttons.create')}
            />
          )}
        </div>
        <div className="travel-information__actions-cancel">
          <Button
            version="v2"
            onClick={resetTraveler}
            size="small"
            label={I18n.t('components.ibe.traveler_details.buttons.cancel')}
          />
        </div>
      </div>
    </Card>
  );
};

const mapDispatchToProps = dispatch => ({
  onCustomerSelected: (index, query) => dispatch(customerSelected(index, query)),
});

TravelInformation.defaultProps = {
  disabled: false,
  touched: true,
  flightResults: [],
  isEditForm: false,
  setSingleTravelerShowPage: () => {},
  setNewTravelerFormPage: () => {},
  selectedCustomer: {
    identificationDocuments: [],
    frequentFlyerNumbersList: [],
    identificationDocumentsList: [],
  },
  setIsGuestTraveler: () => {},
};

TravelInformation.propTypes = {
  index: PropTypes.number.isRequired,
  fields: PropTypes.shape().isRequired,
  frequentFlyerNumber: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  internationalFlight: PropTypes.bool.isRequired,
  setNewTravelerFormPage: PropTypes.func,
  touched: PropTypes.bool,
  flightResults: PropTypes.arrayOf(PropTypes.shape),
  mealRequests: PropTypes.shape().isRequired,
  isEditForm: PropTypes.bool,
  setSingleTravelerShowPage: PropTypes.func,
  reset: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({
    frequentFlyerNumbersList: PropTypes.shape([]),
    identificationDocuments: PropTypes.shape([]),
    identificationDocumentsList: PropTypes.shape([]),
  }),
  enableIdDocOptional: PropTypes.bool.isRequired,
  identificationDocumentsRequired: PropTypes.bool.isRequired,
  assistanceRequests: PropTypes.shape().isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
  setIsGuestTraveler: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(TravelInformation);
