import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const SET_DEAL_NAME = 'SET_DEAL_NAME';
export function setDealName(name) {
  return {
    type: SET_DEAL_NAME,
    name,
  };
}

export const SET_DEAL_TEASER = 'SET_DEAL_TEASER';
export function setDealTeaser(teaser) {
  return {
    type: SET_DEAL_TEASER,
    teaser,
  };
}

export const DEAL_SAVED = 'DEAL_SAVED';
function dealSaved(data) {
  return {
    type: DEAL_SAVED,
    id: data.id,
  };
}

export const SAVING_DEAL = 'SAVING_DEAL';
function savingDeal(deal) {
  return {
    type: SAVING_DEAL,
    deal,
  };
}

export function saveDeal(callbackParams) {
  return (dispatch, getState) => {
    const state = getState();
    const cart = state.common.cart;
    const name = state.common.deal.name;
    const teaser = state.common.deal.teaser;

    const deal = {
      name,
      teaser,
      cart_id: cart.id,
      callback_params: callbackParams,
    };
    dispatch(savingDeal(deal));

    return httpClient.post(routes.api.deals.create(), deal).then(({ data }) => {
      dispatch(dealSaved(data));
      window.location.href = routes.api.deals.redirectUrl(data);
    });
  };
}

export const FETCHING_DEAL = 'FETCHING_DEAL';
function fetchingDeal(id) {
  return {
    type: FETCHING_DEAL,
    id,
  };
}

export const DEAL_FETCHED = 'DEAL_FETCHED';
function dealFetched(deal) {
  return {
    type: DEAL_FETCHED,
    deal,
  };
}

export function fetchDeal(id) {
  return dispatch => {
    dispatch(fetchingDeal(id));
    return httpClient.get(routes.api.deals.fetch({ dealId: id })).then(({ data }) => {
      dispatch(dealFetched(data));
      return data;
    });
  };
}

export function markFavouriteDeal(id) {
  return dispatch => {
    dispatch(fetchingDeal(id));
    return httpClient.put(routes.api.deals.favourite({ dealId: id })).then(({ data }) => {
      dispatch(dealFetched(data));
      return data;
    });
  };
}

export function unmarkFavouriteDeal(id) {
  return dispatch => {
    dispatch(fetchingDeal(id));
    return httpClient.delete(routes.api.deals.favourite({ dealId: id })).then(({ data }) => {
      dispatch(dealFetched(data));
      return data;
    });
  };
}
