import React, { useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Text } from '@wtag/rcl-typography';
import Icon from '@wtag/rcl-icon';
import Modal from 'sharedWebpack/Modal';
import { I18nText, Spinner } from '@wtag/react-comp-lib';
import MatrixFareRulesList from '../MatrixFareRulesList';
import MatrixContext from '../contexts/MatrixContext';

const MatrixFareRulesContainer = props => {
  const { resultIds, fareBasisInfoList } = props;
  const { laymanMode } = useContext(MatrixContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onModalOpen = () => {
    setIsModalVisible(true);
  };

  const onModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <div onClick={onModalOpen} onKeyDown={onModalOpen} role="button" tabIndex={0}>
        <Text
          className={classNames('matrix-fare-brand-card__fare-rules', {
            'matrix-fare-brand-card__fare-rules--laymanmode': laymanMode,
          })}
          level={3}
          weight="bold"
        >
          {I18n.t('components.ibe.flight.fare_rules')}
        </Text>
      </div>
      <Modal
        className="matrix-row__fare-brand--fare-rules-modal"
        isModalVisible={isModalVisible}
        onOutsideClick={onModalClose}
        title={
          <div className="item__labels-buttons-fare-rules--modal-title">
            {I18n.t('components.ibe.flight.fare_rules')}
            <div onClick={onModalClose} onKeyDown={onModalClose} role="presentation">
              <Icon name="close" />
            </div>
          </div>
        }
      >
        {Object.keys(resultIds).map(id => (
          <MatrixFareRulesList id={id} />
        ))}
        {fareBasisInfoList.some(info => !info) && (
          <div className="col-grid col-bleed-x align-center">
            <Spinner size="huge" bgColor="neutral" />
            <I18nText id="ibe.fare_basis_info.loading.text" />
          </div>
        )}
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  fareBasisInfoList: Object.keys(props.resultIds).map(
    id => state.flights.fareBasisInfoByResultId[id],
  ),
});

MatrixFareRulesContainer.defaultProps = {
  fareBasisInfoList: null,
};

MatrixFareRulesContainer.propTypes = {
  resultIds: PropTypes.shape({}).isRequired,
  fareBasisInfoList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
};

export default connect(mapStateToProps)(MatrixFareRulesContainer);
