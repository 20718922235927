import { connect } from 'react-redux';
import FlightDurationAndStopOvers from '.';
import {
  fetchAircraftType,
  fetchAirlineInformations,
  fetchAirportDetails,
} from '../../../actions/flight';

const mapStateToProps = state => ({
  airports: state.common.airports,
  aircraftInformation: state.flights.aircraftTypes,
  airlineInformation: state.flights.airlineInformations,
});

const mapDispatchToProps = dispatch => ({
  fetchAirportDetails: code => dispatch(fetchAirportDetails(code)),
  fetchAircraftType: code => dispatch(fetchAircraftType(code)),
  fetchAirlineInformation: code => dispatch(fetchAirlineInformations(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightDurationAndStopOvers);
