import formatPrice from './formatPrice';

const generateStaticContent = item => {
  const { itemTitle, quantity, grossTotal, currency } = item;
  const title = I18n.t('components.ibe.search_results.other.content.title', {
    itemTitle,
  });

  const itemQuantity = I18n.t('components.ibe.search_results.other.content.quantity', {
    quantity,
  });

  const amount = formatPrice(grossTotal, currency);

  return `${title}\n---\n${itemQuantity}\n${amount}`;
};

export default generateStaticContent;
