import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { setAccountingRemarks } from '../../../actions/common';

import accountingRemarkTypes from '../../helpers/accountingRemarkTypes';
import Remarks from './Remarks';

const AccountingRemarks = ({ resultId, setRemarks, value, type, channel }) => (
  <Remarks
    remarkTypes={accountingRemarkTypes(channel)}
    resultId={resultId}
    setRemarks={setRemarks}
    value={value}
    selectedType={type}
  />
);

AccountingRemarks.defaultProps = {
  value: null,
  type: null,
};

AccountingRemarks.propTypes = {
  channel: PropTypes.string.isRequired,
  resultId: PropTypes.string.isRequired,
  setRemarks: PropTypes.func.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  value:
    state.common.accountingRemarks[ownProps.resultId] &&
    state.common.accountingRemarks[ownProps.resultId].content,
  type:
    state.common.accountingRemarks[ownProps.resultId] &&
    state.common.accountingRemarks[ownProps.resultId].type,
});

const mapDispatchToProps = dispatch => ({
  setRemarks: (type, remarks, resultId, callbackParams) =>
    dispatch(setAccountingRemarks(type, remarks, resultId, callbackParams)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountingRemarks));
