import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { RESET_APP } from './actions/common';
import commonReducer from './reducers/common';
import flightsReducer from './reducers/flight';
import hotelsReducer from './reducers/hotel';
import carsReducer from './reducers/car';
import ccvPaymentReducer from '../CreditCardVaultPayment/reducers';

const appReducer = combineReducers({
  common: commonReducer,
  flights: flightsReducer,
  form: formReducer,
  hotels: hotelsReducer,
  cars: carsReducer,
  ccvPayment: ccvPaymentReducer,
});

const rootReducer = (state, action) => {
  let oldState = state;
  if (action.type === RESET_APP) {
    oldState = undefined;
  }

  return appReducer(oldState, action);
};

export default rootReducer;
