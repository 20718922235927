import React, { Fragment } from 'react';
import { Link } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProductIcon from 'sharedWebpack/ProductIcon';
import Amount from 'sharedWebpack/Amount';

const DealsCard = props => {
  const {
    id,
    currency,
    name,
    amount,
    favourite,
    deleteFromFavourite,
    addToFavourite,
    imageUrl,
    detailsUrl,
    itemTypes,
    fullWidth,
    currentUserId,
    favouriteCount,
    scope,
  } = props;

  return (
    <div key={id} className="deals-card__details-info">
      <div
        className={classNames('deals-card__details-info-deal', {
          'deals-card__details-info-deal--full-width': fullWidth,
        })}
      >
        <div className="deals-card__details-info-deal-text">
          <div className="deals-card__details-info-deal-text--small">
            {I18n.t('public.components.dashboard.deals.start_from')}
          </div>
          <div className="deals-card__details-info-deal-text--large">
            <Amount currency={currency} value={amount} />
          </div>
        </div>
        <div className="deals-card__details-info-deal-icon deals-card__details-info-deal-icon--left-aligned">
          {itemTypes.map(type => (
            <ProductIcon
              productType={type}
              {...{
                showBGColor: true,
                size: 'tiny',
                className:
                  'deals-card__details-info-deal-icon-custom deals-card__details-info-deal-icon--margin-right',
                color: 'default',
              }}
            />
          ))}
        </div>
        <div className="deals-card__details-info-deal-icon deals-card__details-info-deal-icon--right-aligned">
          {currentUserId && (
            <Fragment>
              {favourite ? (
                <Link
                  type="icon-button"
                  size="small"
                  modifier="danger"
                  onClick={() => deleteFromFavourite(id)}
                  className="deals-card__details-info-deal-icon-link"
                >
                  <Icon name="heart" showBGColor={true} size="tiny" color="danger" />
                </Link>
              ) : (
                <Link
                  type="icon-button"
                  size="small"
                  modifier="default"
                  onClick={() => addToFavourite(id)}
                  className="deals-card__details-info-deal-icon-link"
                >
                  <Icon
                    name="heart"
                    showBGColor={true}
                    size="tiny"
                    className="deals-card__details-info-deal-icon-custom"
                  />
                </Link>
              )}
            </Fragment>
          )}
        </div>
        <a href={detailsUrl}>
          <img src={imageUrl} alt={name} className="deals-card__details-info-deal-image" />
        </a>
      </div>
      <div className="deals-card__details-info-name">{name}</div>
      {scope.showFavouriteCount && (
        <div className="deals-card__details-info-favourite-count">
          {I18n.t('public.deals.favourite_count', { count: favouriteCount })}
        </div>
      )}
    </div>
  );
};

DealsCard.defaultProps = {
  imageUrl: '#',
  fullWidth: false,
  favouriteCount: 0,
  scope: { showFavouriteCount: false },
};

DealsCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.node.isRequired,
  amount: PropTypes.node.isRequired,
  currency: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  favourite: PropTypes.bool.isRequired,
  deleteFromFavourite: PropTypes.func.isRequired,
  addToFavourite: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  detailsUrl: PropTypes.string.isRequired,
  teaser: PropTypes.string.isRequired,
  itemTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fullWidth: PropTypes.bool,
  favouriteCount: PropTypes.number,
  scope: PropTypes.shape({
    showFavouriteCount: PropTypes.bool,
  }),
};

export default DealsCard;
