import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { fetchCart } from './';

export const ACCOUNTING_TYPES_FETCHED = 'ACCOUNTING_TYPES_FETCHED';
function accountingTypeFetched(accountingTypes) {
  return { type: ACCOUNTING_TYPES_FETCHED, accountingTypes };
}

export function fetchAccountingTypesForCart(callbackParams) {
  return (dispatch, getState) => {
    const cart = getState().common.cart;
    const cartId = cart.id;
    return httpClient
      .get(routes.api.customers.accountingTypes.all({ customerId: cart.travelers[0].id }))
      .then(({ data }) => {
        dispatch(fetchCart(cartId, callbackParams));
        dispatch(accountingTypeFetched(data));
        return data;
      });
  };
}
