import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { startFromCart, endFromCart } from '../../lib/datesFromCart';

import { validateAllSearchParams } from './helpers/validate';

import SearchForm from './SearchForm';

import deepClone from '../../lib/deepClone';
import searchAccountValues from '../../helpers/searchAccountValues';
import generateCartOverrides from '../../helpers/generateCartOverrides';
import { searchHotels } from '../../actions/hotel';
import { resetApp } from '../../actions/common';

const initialFields = [
  'checkIn',
  'checkOut',
  'numberOfRooms',
  'adults',
  'children',
  'childrenData[].age',
  'latitude',
  'longitude',
  'address',
];

const reduxFormConfig = {
  form: 'hotelSearchForm',
  destroyOnUnmount: false,
  fields: [],
};

const mapStateToProps = (state, ownProps) => {
  const infants = state.common.travelerCount.infants;
  const infantCount = typeof infants !== 'undefined' && infants ? infants.length : 0;
  const allTravelerIds =
    ownProps.callbackParams && ownProps.callbackParams.all_traveler_ids
      ? ownProps.callbackParams.all_traveler_ids
      : [];
  const fields = deepClone(initialFields);
  const cartOverrides = generateCartOverrides(
    state.common.cart.items.length,
    state.common.travelerCount,
    'hotel',
  );
  if (ownProps.searchAccounts && ownProps.searchAccounts.hotels) {
    Object.keys(ownProps.searchAccounts.hotels).forEach(key =>
      fields.push(`accountSettings.${key}`),
    );
  }
  const startDateFromCart = startFromCart(state);
  const endDateFromCart = endFromCart(state);

  const defaultSearchParams = {
    accountSettings: searchAccountValues(ownProps.searchAccounts.hotels),
    adults: allTravelerIds.length > 0 ? allTravelerIds.length : 1,
    children: 0,
    childrenData: [],
    numberOfRooms: allTravelerIds.length > 0 ? allTravelerIds.length : 1,
    checkIn:
      startDateFromCart ||
      state.hotels.defaultHotelSearchParams.checkIn ||
      moment()
        .add(7, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
    checkOut: endDateFromCart || state.hotels.defaultHotelSearchParams.checkOut,
  };

  const searchForm = state.form.hotelSearchForm;
  const preselectTravelers = state.common.preselectTravelers.travelers;

  return {
    fields,
    cartHasItems: state.common.cart.items.length > 0,
    initialValues: {
      ...defaultSearchParams,
      ...ownProps.searchParams,
      ...state.hotels.defaultHotelSearchParams,
      ...cartOverrides,
    },
    validate: validateAllSearchParams(
      ownProps.availableChannels.hotels,
      ownProps.minHoursInFuture,
      infantCount,
    ),
    defaultSearchParams,
    cartOverrides,
    searchForm,
    preselectTravelers,
  };
};

const mapDispatchToProps = dispatch => ({
  searchHotels: (params, callbacks) => dispatch(searchHotels(params, callbacks)),
  resetApp: () => dispatch(resetApp('/hotels')),
});

const ReduxForm = reduxForm(reduxFormConfig, mapStateToProps, mapDispatchToProps)(SearchForm);

const SearchFormWithContext = (props, context) => <ReduxForm {...props} {...context} />;

SearchFormWithContext.contextTypes = {
  availableChannels: PropTypes.shape(),
  searchAccounts: PropTypes.shape(),
  laymanMode: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  intl: PropTypes.shape().isRequired,
  callbackParams: PropTypes.shape().isRequired,
  minHoursInFuture: PropTypes.number.isRequired,
};

export default injectIntl(SearchFormWithContext);
