import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import CommentPlaceholder from 'sharedWebpack/Placeholder/CommentPlaceholder';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import ItemCard from './ItemCard';

const ItemsTab = ({ requestId, confirmationId, scope }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, ''),
  });

  const { tab } = query;

  const fetchItemsUrls = requestId
    ? routes[scope].approvals.requests.items({ requestId })
    : routes[scope].approvals.confirmations.items({ confirmationId });

  const fetchItems = async () => {
    const { data } = await httpClient.get(fetchItemsUrls);
    setItems(data.items);
    setIsLoading(false);
  };

  let itemsCard = (
    <Card
      version="v2"
      className="items-tab-list"
      color="tertiary"
      emptyCardImageSrc={<Icon name="add" />}
      emptyCardText={I18n.t('admin.shared.no_result')}
    />
  );

  if (isLoading) {
    itemsCard = (
      <CommentPlaceholder
        numberOfCards={2}
        numberOfRows={1}
        removeContentPadding={true}
        removeBoxShadow={true}
      />
    );
  } else if (items.length > 0) {
    itemsCard = items.map(item => <ItemCard key={item.id} item={item} />);
  }

  useEffect(() => {
    fetchItems();
    setQuery({ tab: 'items' }, 'replaceIn');
  }, [tab]);

  return <Fragment>{itemsCard}</Fragment>;
};

ItemsTab.defaultProps = {
  requestId: null,
  confirmationId: null,
  scope: 'admin',
};

ItemsTab.propTypes = {
  requestId: PropTypes.number,
  confirmationId: PropTypes.string,
  scope: PropTypes.string,
};

export default withQueryParamsProvider(ItemsTab);
