import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';

const CopyLinkLabel = ({ isCopiedToClipboard, isCopying, text, isVisible }) => (
  <div className="d-flex align-center justify-space-between flex-grow">
    <span>{text}</span>
    {isVisible && (
      <Fragment>
        {isCopying && <Spinner size="xs" bgColor="neutral" />}
        {isCopiedToClipboard && (
          <Icon className="copy-link-label__icon--checked" name="checkCircle" />
        )}
      </Fragment>
    )}
  </div>
);

CopyLinkLabel.defaultProps = {
  isCopying: false,
  isCopiedToClipboard: false,
  isVisible: false,
};

CopyLinkLabel.propTypes = {
  isCopying: PropTypes.bool,
  isCopiedToClipboard: PropTypes.bool,
  isVisible: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default CopyLinkLabel;
