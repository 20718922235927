import React from 'react';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

const LoadingScreen = () => (
  <div className="col-grid col-bleed hotel-details">
    <div className="hotel-details__header">
      <ContentLoaderPlaceholder numberOfLines={2} showBackground={true} />
    </div>

    <div className="col-12 col-grid col-bleed hotel-details__graphic-contents">
      <div className="col-xlg-6 col-lg-6 col-md-6 col-12 col-bleed hotel-details__graphic-contents-images">
        <Card version="v2" size="full">
          <ContentLoaderPlaceholder numberOfLines={8} showBackground={false} />
        </Card>
      </div>
      <div className="col-xlg-6 col-lg-6 col-md-6 col-12 col-bleed hotel-details__graphic-contents-map">
        <Card className="col-12" version="v2" size="full">
          <ContentLoaderPlaceholder numberOfLines={8} showBackground={false} />
        </Card>
      </div>
    </div>

    <Card version="v2" size="full" className="col-12 hotel-details__search-information">
      <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} />
    </Card>

    <Card version="v2" size="full" className="hotel-details__description">
      <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
    </Card>

    <Card version="v2" size="full" className="col-12 hotel-details__rooms">
      <ContentLoaderPlaceholder numberOfLines={8} showBackground={false} />
    </Card>
  </div>
);

export default LoadingScreen;
