import React from 'react';
import PropTypes from 'prop-types';
import ProductIcon from 'sharedWebpack/ProductIcon';
import { MenuItem } from '@wtag/rcl-menu';
import DateTime from 'sharedWebpack/DateTime';

const CarSearchName = props => {
  const { dropOffLocationAirportCode, dropOffGeoLocationAddress } = props;
  const pickUpDate = props.pickUpDate;
  const hasAirportCodeOnly =
    props.name === null && props.placeName === null && props.countryName === null;

  let destination = dropOffGeoLocationAddress;
  if (props.airportSearch) {
    destination = !hasAirportCodeOnly
      ? `${props.name} (${dropOffLocationAirportCode}), ${props.placeName}, ${props.countryName}`
      : (destination = `${dropOffLocationAirportCode}`);
  }

  const carData = (
    <div className="col-grid col-bleed direction-row align-center">
      <ProductIcon
        productType="car"
        showBGColor={true}
        size={props.isDealItem ? 'medium' : 'normal'}
        color={props.isDealItem ? 'success' : 'tertiary'}
      />
      <div className="col-grid col-bleed">
        <div className="search-name__primary">{destination}</div>
        <div className="search-name__secondary">
          <DateTime dateTime={pickUpDate} format="short" />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {props.isDealItem ? (
        <div className="deal__group-item">{carData}</div>
      ) : (
        <MenuItem onClick={props.onMenuItemClick}>{carData}</MenuItem>
      )}
    </div>
  );
};

CarSearchName.defaultProps = {
  dropOffLocationAirportCode: null,
  dropOffGeoLocationAddress: null,
  name: null,
  countryName: null,
  placeName: null,
  isDealItem: false,
};

CarSearchName.propTypes = {
  airportSearch: PropTypes.bool.isRequired,
  dropOffLocationAirportCode: PropTypes.string,
  dropOffGeoLocationAddress: PropTypes.string,
  pickUpDate: PropTypes.string.isRequired,
  name: PropTypes.string,
  countryName: PropTypes.string,
  placeName: PropTypes.string,
  onMenuItemClick: PropTypes.func.isRequired,
  isDealItem: PropTypes.bool,
};

export default CarSearchName;
