import React from 'react';
import PropTypes from 'prop-types';
import { PillTabs } from '@wtag/react-comp-lib';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import TabContent from './TabContent';

const List = ({ createDocumentUrl, orderId }) => {
  const getItems = documentTypes =>
    documentTypes.map(({ title, content }, index) => ({
      key: index,
      title,
      getContent: () => content,
    }));

  const documentTypes = [
    {
      title: I18n.t('admin.components.attachments.tabs.general'),
      content: (
        <TabContent
          key="general"
          type="general"
          orderId={orderId}
          createDocumentUrl={createDocumentUrl}
        />
      ),
    },
    {
      title: I18n.t('admin.components.attachments.tabs.invoice'),
      content: (
        <TabContent
          key="invoice"
          type="invoice"
          orderId={orderId}
          createDocumentUrl={createDocumentUrl}
        />
      ),
    },
    {
      title: I18n.t('admin.components.attachments.tabs.voucher'),
      content: (
        <TabContent
          key="voucher"
          type="voucher"
          orderId={orderId}
          createDocumentUrl={createDocumentUrl}
        />
      ),
    },
  ];

  return <PillTabs items={getItems(documentTypes)} showMore={false} />;
};

List.propTypes = {
  orderId: PropTypes.number.isRequired,
  createDocumentUrl: PropTypes.string.isRequired,
};

export default withQueryParamsProvider(List);
