import React from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import SelectBox from '@wtag/rcl-select-box';
import Amount from 'sharedWebpack/Amount';
import Tooltip from '@wtag/rcl-tooltip';
import Icon from '@wtag/rcl-icon';
import { humanReadableDuration } from '../../../lib/time_helpers';
import { STATS, FILTER_PARAMS } from '../../commonProps';
import FilterWrapper from '../../FilterWrapper';
import RangeFilter from '../../../components/RangeFilter';
import FilterSection from '../../FilterSection';
import FilterTags from './FilterTags';
import '../style.scss';

class FilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterResetId: Math.random(),
      currentFilters: { ...this.props.filterParams.filters },
      isAllChecked: { ...this.props.filterParams.isAllChecked },
      filterParams: {
        stopoverCounts: [],
        baggage: [],
        caterings: [],
        airlines: [],
        multiTicket: [],
      }
    };
  }

  resetFilters = () => {
    this.props.resetFilters(this.props.stats);
    this.setState({ filterResetId: Math.random() });
    this.setState({
      currentFilters: {
        airlines: null,
        caterings: null,
        baggage: null,
        stopoverCounts: null,
        multiTicket: null,
        price: null,
      },
    });
    this.setState({
      isAllChecked: {
        stopoverCounts: false,
        baggage: false,
        caterings: false,
        airlines: false,
        multiTicket: false,
      },
    });
    this.setState({
      filterParams: {
        stopoverCounts: [],
        baggage: [],
        caterings: [],
        airlines: [],
        multiTicket: [],
      }
    });
  };

  handleFilterTripIndividualTag = (key, values, searchParam, stats, tagName) => {
    if (
      values.max.toFixed(3) === stats.max.toFixed(3) &&
      values.min.toFixed(3) === stats.min.toFixed(3)
    ) {
      this.resetTripFilter(key, tagName);
    } else {
      this.handleTripTag(tagName, key);
    }

    this.props.filterFlightSearch(searchParam, values, key);
  };

  resetTripFilter = (key, value) => {
    let changedTrips = this.state.currentFilters[`trip${key}`];

    if (!changedTrips) {
      return;
    }
    changedTrips = changedTrips.filter(option => option !== value);
    const newTrips = changedTrips;

    if (newTrips.length !== 0) {
      this.setState({
        currentFilters: {
          ...this.state.currentFilters,
          [`trip${key}`]: newTrips,
        },
      });
    } else {
      this.setState({
        currentFilters: {
          ...this.state.currentFilters,
          [`trip${key}`]: null,
        },
      });
    }
  };

  resetTrip = key => {
    if (key === 'price') {
      const newState = this.props.filterParams.price;

      const objectData = {
        changedData: newState,
        initialData: this.props.stats.price,
        key: 'price',
      };

      this.props.resetFiltersByKey(objectData);
      this.setState({ filterResetId: Math.random() });
    } else {
      const newState = this.props.filterParams.tripSpecific.filter(param => param.key === key);

      const objectData = {
        changedData: newState,
        initialData: this.props.stats.tripSpecific,
      };

      this.props.resetFiltersByKey(objectData);
      this.setState({ filterResetId: Math.random() });
    }
  };

  resetData = section => {
    if (section.includes('trip')) {
      const tripNumber = parseInt(section.charAt(section.length - 1), 10);
      this.resetTrip(tripNumber);
    } else if (section === 'price') {
      this.resetTrip('price');
      this.setState({ filterResetId: Math.random() });
      this.setState({
        currentFilters: { ...this.state.currentFilters, [section]: null },
      });
    }

    this.setState({
      currentFilters: { ...this.state.currentFilters, [section]: null },
    });
    this.props.filterParams.filters[section] = null;
    this.state.filterParams[section] = [];
  };

  formatLabel = value => {
    const hour = Math.floor(value / 60);
    const minutes = `0${value % 60}`.slice(-2);
    return `${hour}:${minutes}`;
  };

  handleFilterResults = (filter, value, ...args) => {
    this.state.filterParams[filter] = value
    this.props.filterFlightSearch(filter, value, ...args)
  }

  handleTripTag = (keyData, key) => {
    const selectedTrips = this.state.currentFilters[`trip${key}`];
    const isTripExist = selectedTrips && selectedTrips.includes(keyData);

    if (selectedTrips && !isTripExist) {
      const newSelectedTrips = selectedTrips.concat(keyData);

      this.setState({
        currentFilters: {
          ...this.state.currentFilters,
          [`trip${key}`]: newSelectedTrips,
        },
      });
    } else if (!selectedTrips) {
      this.setState({
        currentFilters: {
          ...this.state.currentFilters,
          [`trip${key}`]: [keyData],
        },
      });
    }
  };

  render() {
    let totalPriceFilter;

    if (this.props.trips.length > 1) {
      totalPriceFilter = (
        <div className="flight-results__total-price">
          <div className="flight-filter__total-price">
            {I18n.t('components.ibe.search_results.filters.total_price')}
            <div className="flight-filter__icon">
              <Tooltip
                content={I18n.t('components.ibe.search_results.filters.total_price_text')}
                position="right-top"
                type="inverse"
              >
                <Icon name="invalidOutline" />
              </Tooltip>
            </div>
          </div>

          <RangeFilter
            key={this.state.filterResetId}
            className="price"
            min={this.props.stats.price.min}
            max={this.props.stats.price.max}
            values={this.props.filterParams.price}
            step={0.001}
            onChange={values => {
              if (
                Math.ceil(values.max) === Math.ceil(this.props.stats.price.max) &&
                Math.floor(values.min) === Math.floor(this.props.stats.price.min)
              ) {
                this.setState({
                  currentFilters: { ...this.state.currentFilters, price: null },
                });
              } else {
                this.setState({
                  currentFilters: {
                    ...this.state.currentFilters,
                    price: I18n.t('components.ibe.search_results.filters.total_price'),
                  },
                });
              }

              this.props.filterFlightSearch('price', values);
            }}
            formatLabel={value => <Amount currency={this.props.currency} value={value} />}
          />

          <div className="flight-results__horizontal-line-main--total-price">
            <hr />
          </div>
        </div>
      );
    }

    const filters = (
      <div className="flight-results__holder">
        <Card>
          <div className="flight-results__title">
            <div className="flight-results__title--name">
              {I18n.t('components.ibe.search_results.filters.title')}
            </div>
            <Button
              label={I18n.t('components.ibe.search_results.filters.reset_flight')}
              size="small"
              onClick={this.resetFilters}
            />
          </div>
          <div>
            <FilterTags
              currentFilters={this.state.currentFilters}
              onRemoveFilter={section => {
                this.resetData(section);
              }}
            />
          </div>

          <div className="flight-results__sort">
            {I18n.t('components.ibe.search_results.filters.sort_by')}
            <SelectBox
              onChange={event => this.props.filterFlightSearch('sortBy', event.value)}
              key={this.state.filterResetId}
              options={this.props.stats.availableSortOptions}
              value={this.props.stats.availableSortOptions.find(
                option => option.value === this.props.filterParams.sortBy,
              )}
              width="full"
              isClearable={false}
            />
            <div className="flight-results__horizontal-line--main">
              <hr />
            </div>
          </div>
          <div className="results__filters">
            {totalPriceFilter}
            {this.props.trips.map((trip, tripIndex) => {
              const key = trip.identifier;
              const filterParams = this.props.filterParams.tripSpecific.find(
                params => params.key === key,
              );
              const stats = this.props.stats.tripSpecific.find(params => params.key === key);

              if (!stats) {
                return null;
              }

              const selectedTripIndex = parseInt(this.props.match.params.tripId, 10) - 1;

              return (
                <div data-testid="filterWrapperRenderTest">
                  <div key={key}>
                    <FilterWrapper
                      isInitiallyCollapsed={selectedTripIndex !== tripIndex}
                      type="secondary"
                      title={I18n.t('components.ibe.search_results.filters.trip', {
                        count: tripIndex + 1,
                        origin: trip.origin,
                        destination: trip.destination,
                      })}
                    >
                      <div>
                        {I18n.t('components.ibe.search_results.filters.price')}
                        <RangeFilter
                          key={this.state.filterResetId}
                          className="price"
                          min={stats.price.min}
                          max={stats.price.max}
                          values={filterParams.prices}
                          step={0.001}
                          onChange={values =>
                            this.handleFilterTripIndividualTag(
                              key,
                              values,
                              'prices',
                              stats.price,
                              I18n.t('components.ibe.search_results.filters.price'),
                            )
                          }
                          formatLabel={value => (
                            <Amount currency={this.props.currency} value={value} />
                          )}
                        />
                      </div>
                      <div>
                        {I18n.t('components.ibe.search_results.filters.duration')}
                        <RangeFilter
                          key={this.state.filterResetId}
                          className="trip-duration"
                          min={stats.tripDurationMinutes.min}
                          max={stats.tripDurationMinutes.max}
                          values={filterParams.tripDurationMinutes || stats.tripDurationMinutes}
                          step={1}
                          onChange={values =>
                            this.handleFilterTripIndividualTag(
                              key,
                              values,
                              'tripDurationMinutes',
                              stats.tripDurationMinutes,
                              I18n.t('components.ibe.search_results.filters.duration'),
                            )
                          }
                          formatLabel={value => humanReadableDuration(value)}
                        />
                      </div>
                      <div>
                        {I18n.t('components.ibe.search_results.filters.departure')}
                        <RangeFilter
                          key={this.state.filterResetId}
                          className="departure-time"
                          min={stats.departureTime.min}
                          max={stats.departureTime.max}
                          values={filterParams.departureTimes}
                          step={1}
                          onChange={values =>
                            this.handleFilterTripIndividualTag(
                              key,
                              values,
                              'departureTimes',
                              stats.departureTime,
                              I18n.t('components.ibe.search_results.filters.departure_time'),
                            )
                          }
                          formatLabel={value => this.formatLabel(value)}
                        />
                      </div>
                      <div>
                        {I18n.t('components.ibe.search_results.filters.arrival')}
                        <RangeFilter
                          key={this.state.filterResetId}
                          className="arrival-time"
                          min={stats.arrivalTime.min}
                          max={stats.arrivalTime.max}
                          step={1}
                          values={filterParams.arrivalTimes}
                          onChange={values =>
                            this.handleFilterTripIndividualTag(
                              key,
                              values,
                              'arrivalTimes',
                              stats.arrivalTime,
                              I18n.t('components.ibe.search_results.filters.arrival_time'),
                            )
                          }
                          formatLabel={value => this.formatLabel(value)}
                        />
                      </div>
                    </FilterWrapper>
                    <div className="flight-results__horizontal-line">
                      <hr />
                    </div>
                  </div>
                </div>
              );
            })}

            <FilterSection
              filterTitle={I18n.t('components.ibe.search_results.filters.stops')}
              allOptions={this.props.stats.stopoverCount.sort().map(stopoverCount => ({
                identifier: stopoverCount,
                name: I18n.t('components.ibe.results.filters.stopover_counts.values', {
                  count: stopoverCount,
                }),
              }))}
              currentSelection={this.state.currentFilters.stopoverCounts}
              isAllChecked={this.state.isAllChecked.stopoverCounts}
              filterResults={this.handleFilterResults}
              searchParameter="stopoverCounts"
              selectedOptions={this.state.filterParams.stopoverCounts}
              onSelect={values =>
                this.setState({
                  currentFilters: { ...this.state.currentFilters, stopoverCounts: values },
                })
              }
              onAllChecked={values =>
                this.setState({
                  isAllChecked: { ...this.state.isAllChecked, stopoverCounts: values },
                })
              }
            />

            <FilterSection
              filterTitle={I18n.t('components.ibe.search_results.filters.baggages')}
              allOptions={this.props.stats.baggage.sort().map(baggage => ({
                identifier: baggage,
                name: I18n.t('components.ibe.results.filters.included_baggages.values', {
                  count: baggage,
                }),
              }))}
              currentSelection={this.state.currentFilters.baggage}
              isAllChecked={this.state.isAllChecked.baggage}
              filterResults={this.handleFilterResults}
              searchParameter="baggage"
              selectedOptions={this.state.filterParams.baggage}
              onSelect={values =>
                this.setState({
                  currentFilters: { ...this.state.currentFilters, baggage: values },
                })
              }
              onAllChecked={values =>
                this.setState({
                  isAllChecked: { ...this.state.isAllChecked, baggage: values },
                })
              }
            />

            {this.props.stats.catering && (
              <FilterSection
                filterTitle={I18n.t('components.ibe.search_results.filters.catering')}
                allOptions={this.props.stats.catering}
                currentSelection={this.state.currentFilters.caterings}
                isAllChecked={this.state.isAllChecked.caterings}
                filterResults={this.handleFilterResults}
                searchParameter="caterings"
                selectedOptions={this.state.filterParams.caterings}
                onSelect={values =>
                  this.setState({
                    currentFilters: { ...this.state.currentFilters, caterings: values },
                  })
                }
                onAllChecked={values =>
                  this.setState({
                    isAllChecked: { ...this.state.isAllChecked, caterings: values },
                  })
                }
              />
            )}

            {this.props.stats.airline && this.props.airlineInformations && (
              <FilterSection
                filterTitle={I18n.t('components.ibe.search_results.filters.airlines')}
                allOptions={this.props.stats.airline}
                airlinesInformations={this.props.airlineInformations}
                tenantCurrency={this.props.currency}
                currentSelection={this.state.currentFilters.airlines}
                isAllChecked={this.state.isAllChecked.airlines}
                selectedOptions={this.state.filterParams.airlines}
                filterResults={this.handleFilterResults}
                searchParameter="airlines"
                onSelect={values =>
                  this.setState({
                    currentFilters: { ...this.state.currentFilters, airlines: values },
                  })
                }
                onAllChecked={values =>
                  this.setState({
                    isAllChecked: { ...this.state.isAllChecked, airlines: values },
                  })
                }
                fetchAirlineInformations={this.props.fetchAirlineInformations}
              />
            )}

            {this.props.trips.length > 1 && (
              <FilterSection
                filterTitle={I18n.t('components.ibe.search_results.filters.tickets')}
                allOptions={this.props.stats.multiTicket.sort().map(multiTicket => ({
                  identifier: multiTicket,
                  name: I18n.t(multiTicket, { scope: 'components.ibe.multi_ticket.values' }),
                }))}
                currentSelection={this.state.currentFilters.multiTicket}
                isAllChecked={this.state.isAllChecked.multiTicket}
                filterResults={this.handleFilterResults}
                searchParameter="multiTicket"
                selectedOptions={this.state.filterParams.multiTicket}
                onSelect={values =>
                  this.setState({
                    currentFilters: { ...this.state.currentFilters, multiTicket: values },
                  })
                }
                onAllChecked={values =>
                  this.setState({
                    isAllChecked: { ...this.state.isAllChecked, multiTicket: values },
                  })
                }
                isDividerVisible={false}
              />
            )}
          </div>
        </Card>
      </div>
    );

    return <div>{filters}</div>;
  }
}

FilterComponent.defaultProps = {
  stats: null,
  filterParams: null,
};

FilterComponent.propTypes = {
  selectedFlightGroups: PropTypes.shape({}).isRequired,
  filterFlightSearch: PropTypes.func.isRequired,
  fetchAirlineInformations: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  resetFiltersByKey: PropTypes.func.isRequired,
  airlineInformations: PropTypes.shape().isRequired,
  stats: PropTypes.shape(STATS),
  filterParams: PropTypes.shape(FILTER_PARAMS),

  trips: PropTypes.arrayOf(
    PropTypes.shape({
      origin: PropTypes.string,
      destination: PropTypes.string,
      date: PropTypes.string,
    }),
  ).isRequired,
  params: PropTypes.shape({
    searchId: PropTypes.string,
    tripId: PropTypes.string,
  }).isRequired,
  currency: PropTypes.string.isRequired,
  appliedFilters: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tripId: PropTypes.string,
    }),
  }).isRequired,
};

export default FilterComponent;
