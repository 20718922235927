import React from 'react';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { CountryItem } from '@wtag/react-comp-lib';

const fetchPhoneCountryCodes = async () => {
  const { data } = await httpClient.get(routes.api.phoneCountryCodes());
  const phoneCountryCodes = data.map(({ name, phoneCode }) => ({
    label: <CountryItem countryName={name} countryCode={`+${phoneCode}`} />,
    value: phoneCode,
  }));

  return phoneCountryCodes;
};

export default fetchPhoneCountryCodes;
