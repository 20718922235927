import React from 'react';
import PropTypes from 'prop-types';
import SelectBox from '@wtag/rcl-select-box';
import Input from '@wtag/rcl-input';
import moment from 'moment';
import DatePickerWithFocusedStateWrapper from 'sharedWebpack/DatePickerWithFocusedStateWrapper';

const GeneralInformationForm = props => {
  const {
    salutations,
    titlePrefixes,
    preferredCommunications,
    spokenLanguages,
    correspondenceLanguageOptions,
    salutation,
    setSalutation,
    titlePrefix,
    setTitlePrefix,
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    nickName,
    setNickName,
    dateOfBirth,
    setDateOfBirth,
    preferredCommunication,
    setPreferredCommunication,
    personalId,
    setPersonalId,
    shortCode,
    setShortCode,
    correspondenceLanguage,
    setCorrespondenceLanguage,
    spokenLanguage,
    setSpokenLanguage,
    error,
    layman,
  } = props;

  const salutationOptions = Object.keys(salutations).map(salutationKey => ({
    value: salutations[salutationKey],
    label: I18n.t(salutationKey, { scope: 'salutation' }),
  }));

  const titlePrefixesOptions = Object.keys(titlePrefixes).map(titlePrefixKey => ({
    value: titlePrefixes[titlePrefixKey],
    label: I18n.t(titlePrefixKey, { scope: 'title_prefix' }),
  }));

  const preferredCommunicationsOptions = Object.keys(preferredCommunications).map(
    preferredCommunicationKey => ({
      value: preferredCommunications[preferredCommunicationKey],
      label: I18n.t(preferredCommunicationKey, {
        scope: 'simple_form.options.person.preferred_communication',
      }),
    }),
  );

  const spokenLanguagesOptions = spokenLanguages.map(spokenLanguageKey => ({
    value: spokenLanguageKey,
    label: I18n.t(`languages_list.${spokenLanguageKey}`),
  }));

  const findLabelForSelectBox = (options, value) => {
    const selectedOption = options.filter(option => value === option.value)[0];
    if (selectedOption) {
      return selectedOption.label;
    }

    return null;
  };

  return (
    <div className="col-12 traveller-edit-general-information-form">
      <div className="grid">
        <div className="col-sm-6">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t('admin.components.travellers.form.placeholders.salutation')}
            label={I18n.t('admin.components.travellers.form.labels.salutation')}
            width="full"
            options={salutationOptions}
            value={{
              value: salutation,
              label: findLabelForSelectBox(salutationOptions, salutation),
            }}
            onChange={selectedOption => setSalutation(selectedOption ? selectedOption.value : '')}
          />
        </div>
        <div className="col-sm-6">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t('admin.components.travellers.form.placeholders.title_prefix')}
            label={I18n.t('admin.components.travellers.form.labels.title_prefix')}
            width="full"
            options={titlePrefixesOptions}
            value={{
              value: titlePrefix,
              label: findLabelForSelectBox(titlePrefixesOptions, titlePrefix),
            }}
            onChange={selectedOption => setTitlePrefix(selectedOption && selectedOption.value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            size="tiny"
            placeholder={I18n.t('admin.components.travellers.form.placeholders.first_name')}
            label={I18n.t('admin.components.travellers.form.labels.first_name')}
            required={true}
            touched={true}
            error={error.firstName}
            value={firstName}
            onChange={value => setFirstName(value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            size="tiny"
            placeholder={I18n.t('admin.components.travellers.form.placeholders.middle_name')}
            label={I18n.t('admin.components.travellers.form.labels.middle_name')}
            value={middleName}
            onChange={value => setMiddleName(value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            size="tiny"
            placeholder={I18n.t('admin.components.travellers.form.placeholders.last_name')}
            label={I18n.t('admin.components.travellers.form.labels.last_name')}
            required={true}
            touched={true}
            error={error.lastName}
            value={lastName}
            onChange={value => setLastName(value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            size="tiny"
            placeholder={I18n.t('admin.components.travellers.form.placeholders.nickname')}
            label={I18n.t('admin.components.travellers.form.labels.nickname')}
            value={nickName}
            onChange={value => setNickName(value)}
          />
        </div>
        <div className="col-sm-6">
          <DatePickerWithFocusedStateWrapper
            placeholder={I18n.t('admin.components.travellers.form.placeholders.date_of_birth')}
            label={I18n.t('admin.components.travellers.form.labels.date_of_birth')}
            size="tiny"
            fullWidth={true}
            pastYearsCount={100}
            futureYearsCount={0}
            id={Math.random()}
            enablePastDates={true}
            disableFutureDates={true}
            maxDate={moment()}
            date={dateOfBirth}
            locale={moment().locale()}
            onChange={value => value && setDateOfBirth(moment(value).format('YYYY-MM-DD'))}
            hideNavButtons={false}
          />
        </div>
        <div className="col-sm-6">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.travellers.form.placeholders.preferred_communication',
            )}
            label={I18n.t('simple_form.labels.person.preferred_communication')}
            width="full"
            options={preferredCommunicationsOptions}
            value={{
              value: preferredCommunication,
              label: findLabelForSelectBox(preferredCommunicationsOptions, preferredCommunication),
            }}
            onChange={selectedOption =>
              setPreferredCommunication(selectedOption && selectedOption.value)
            }
          />
        </div>
        <div className="col-12">
          <Input
            size="tiny"
            placeholder={I18n.t('admin.components.travellers.form.placeholders.personal_id')}
            label={I18n.t('admin.components.travellers.form.labels.personal_id')}
            value={personalId}
            error={error.personalId}
            touched={true}
            onChange={value => setPersonalId(value)}
          />

          <div className="traveller-edit-general-information-form__explanation">
            {I18n.t(
              'admin.components.travellers.edit.general.information.placeholder.personal_id_explanation',
            )}
          </div>
        </div>
        <div className="col-12">
          <Input
            size="tiny"
            placeholder={I18n.t('admin.components.travellers.form.placeholders.short_code')}
            label={I18n.t('admin.components.travellers.form.labels.short_code')}
            value={shortCode}
            error={error.shortCode}
            touched={true}
            onChange={value => setShortCode(value)}
          />
          <div className="traveller-edit-general-information-form__explanation">
            {I18n.t(
              'admin.components.travellers.edit.general.information.placeholder.short_code_explanation',
            )}
          </div>
        </div>
        <div className="col-md-6">
          <SelectBox
            size="tiny"
            label={I18n.t(
              'admin.components.travellers.edit.general.information.label.correspondence_language',
            )}
            width="full"
            options={correspondenceLanguageOptions}
            value={{
              label: findLabelForSelectBox(correspondenceLanguageOptions, correspondenceLanguage),
              value: correspondenceLanguage,
            }}
            onChange={selectedOption =>
              setCorrespondenceLanguage(selectedOption && selectedOption.value)
            }
            errorMsg={error.correspondenceLanguage}
          />
        </div>
        {!layman && (
          <div className="col-md-6">
            <SelectBox
              size="tiny"
              label={I18n.t(
                'admin.components.travellers.edit.general.information.label.spoken_language',
              )}
              width="full"
              isMulti={true}
              options={spokenLanguagesOptions}
              value={
                spokenLanguage &&
                spokenLanguage.map(language => ({
                  value: language.toLowerCase(),
                  label: language,
                }))
              }
              onChange={selectedOption =>
                setSpokenLanguage(selectedOption && selectedOption.map(status => status.value))
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

GeneralInformationForm.defaultProps = {
  salutation: null,
  titlePrefix: null,
  firstName: '',
  middleName: '',
  lastName: '',
  nickName: '',
  dateOfBirth: null,
  preferredCommunication: null,
  personalId: '',
  shortCode: '',
  correspondenceLanguage: null,
  layman: false,
};

GeneralInformationForm.propTypes = {
  salutations: PropTypes.shape({
    mr: PropTypes.number,
    mrs: PropTypes.number,
    ms: PropTypes.number,
  }).isRequired,
  titlePrefixes: PropTypes.shape({
    dr: PropTypes.number,
    dr_prof: PropTypes.number,
    prof: PropTypes.number,
  }).isRequired,
  preferredCommunications: PropTypes.shape({
    email: PropTypes.number,
    phone: PropTypes.number,
  }).isRequired,
  spokenLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  correspondenceLanguageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  salutation: PropTypes.number,
  setSalutation: PropTypes.func.isRequired,
  titlePrefix: PropTypes.number,
  setTitlePrefix: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  setFirstName: PropTypes.func.isRequired,
  middleName: PropTypes.string,
  setMiddleName: PropTypes.func.isRequired,
  lastName: PropTypes.string,
  setLastName: PropTypes.func.isRequired,
  nickName: PropTypes.string,
  setNickName: PropTypes.func.isRequired,
  dateOfBirth: PropTypes.string,
  setDateOfBirth: PropTypes.func.isRequired,
  preferredCommunication: PropTypes.number,
  setPreferredCommunication: PropTypes.func.isRequired,
  personalId: PropTypes.string,
  setPersonalId: PropTypes.func.isRequired,
  shortCode: PropTypes.string,
  setShortCode: PropTypes.func.isRequired,
  correspondenceLanguage: PropTypes.string,
  setCorrespondenceLanguage: PropTypes.func.isRequired,
  spokenLanguage: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSpokenLanguage: PropTypes.func.isRequired,
  error: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    shortCode: PropTypes.string,
    personalId: PropTypes.string,
    correspondenceLanguage: PropTypes.string,
  }).isRequired,
  layman: PropTypes.bool,
};

export default GeneralInformationForm;
