import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Category from 'sharedWebpack/IBE/SearchResult/car/Category';

const CarType = ({ category, acrissCode }) => (
  <Fragment>
    <Category category={category} />
    &nbsp;({acrissCode})
  </Fragment>
);

CarType.propTypes = {
  category: PropTypes.string.isRequired,
  acrissCode: PropTypes.string.isRequired,
};

export default CarType;
