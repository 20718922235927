import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';

import { Link } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import Card from '@wtag/rcl-card';
import CommentPlaceholder from '../../../Placeholder/CommentPlaceholder';
import CarLoyaltyProgramForm from './CarLoyaltyProgramForm';

const CarLoyaltyProgramCard = props => {
  const { personId, fetchCarLoyaltyPrograms, carCompanies, carLoyaltyProgram, carRoutes } = props;

  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});
  const [number, setNumber] = useState('');
  const [program, setProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const updateCarLoyaltyProgram = async Id => {
    const carLoyaltyProgramParams = {
      car_loyalty_number: number,
      car_loyalty_program: program,
      person_id: personId,
    };

    const { data } = await httpClient.patch(carRoutes.update({ personId, id: Id }), {
      car_loyalty_program: carLoyaltyProgramParams,
    });

    if (data.error === null) {
      fetchCarLoyaltyPrograms();
      setEditing(false);
    }
  };

  const onUpdateCarLoyaltyProgram = Id => {
    setIsLoading(true);
    updateCarLoyaltyProgram(Id)
      .catch(({ response }) => {
        if (response.status === 422) {
          setError(response.data.error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteCarLoyaltyProgram = async Id => {
    setIsLoading(true);
    const { data } = await httpClient.delete(carRoutes.delete({ personId, id: Id }));

    if (data.error === null) {
      fetchCarLoyaltyPrograms();
      setIsLoading(false);
      setEditing(false);
    }
  };

  const onDeleteCarLoyaltyProgram = Id =>
    deleteCarLoyaltyProgram(Id).catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  useEffect(() => {
    setNumber(carLoyaltyProgram.number);
    setProgram(carLoyaltyProgram.program);
  }, [carLoyaltyProgram]);

  const renderLoaders = component => {
    if (isLoading) {
      return (
        <CommentPlaceholder
          numberOfCards={1}
          numberOfRows={1}
          removeContentPadding={true}
          removeBoxShadow={true}
        />
      );
    }
    return (
      <Card
        className="traveller-edit-loyalty-program__container"
        key={carLoyaltyProgram.id}
        version="v2"
      >
        {component}
      </Card>
    );
  };

  return (
    <Fragment>
      {editing
        ? renderLoaders(
            <Fragment>
              <div className="col-grid col-bleed direction-row align-center justify-space-between traveller-edit-loyalty-program-section__update">
                <span className="traveller-edit-loyalty-program-section__update--header">
                  {I18n.t(
                    'admin.components.travellers.edit.loyalty_programs.car_loyalty.update.header',
                  )}
                </span>
                <span className="traveller-edit-loyalty-program__actions">
                  <IconButton
                    icon={<Icon name="check" />}
                    color="tertiary"
                    size="small"
                    onClick={() => onUpdateCarLoyaltyProgram(carLoyaltyProgram.id)}
                  />
                  <IconButton
                    icon={<Icon name="close" />}
                    color="tertiary"
                    size="small"
                    onClick={() => setEditing(false)}
                  />
                </span>
              </div>
              <div className="col-12 col-bleed traveller-edit-loyalty-program-section__update">
                <div className="grid">
                  <div className="col-lg-12">
                    <CarLoyaltyProgramForm
                      carCompanies={carCompanies}
                      number={number}
                      setNumber={setNumber}
                      program={program}
                      setProgram={setProgram}
                      carError={error}
                    />
                  </div>
                </div>
              </div>
            </Fragment>,
          )
        : renderLoaders(
            <Fragment>
              <div className="col-grid col-bleed direction-row traveller-edit-loyalty-program">
                <div className="traveller-edit-loyalty-program__summary">
                  <span className="traveller-edit-loyalty-program__content--disabled">
                    {I18n.t(carLoyaltyProgram.program, { scope: 'rental_car_agencies' })}
                  </span>
                  <span className="traveller-edit-loyalty-program__content">
                    {carLoyaltyProgram.number}
                  </span>
                </div>
                <div className="traveller-edit-loyalty-program__actions">
                  <span className="traveller-edit-loyalty-program__content">
                    <Link
                      type="icon-button"
                      modifier="tertiary"
                      onClick={() => onDeleteCarLoyaltyProgram(carLoyaltyProgram.id)}
                    >
                      <Icon name="delete" size="small" />
                    </Link>
                  </span>
                  <span className="traveller-edit-loyalty-program__content">
                    <Link
                      labelHint={I18n.t('admin.shared.action.edit')}
                      type="icon-button"
                      modifier="tertiary"
                      onClick={() => setEditing(true)}
                    >
                      <Icon name="edit" size="small" />
                    </Link>
                  </span>
                </div>
              </div>
            </Fragment>,
          )}
    </Fragment>
  );
};

CarLoyaltyProgramCard.defaultProps = {
  carLoyaltyProgram: {},
};

CarLoyaltyProgramCard.propTypes = {
  personId: PropTypes.number.isRequired,
  fetchCarLoyaltyPrograms: PropTypes.func.isRequired,
  carCompanies: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  carLoyaltyProgram: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    program: PropTypes.string,
  }),
  carRoutes: PropTypes.shape({
    update: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
  }).isRequired,
};

export default CarLoyaltyProgramCard;
