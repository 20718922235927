import {
  SAVING_TRAVELER_DETAILS,
  TRAVELER_DETAILS_SAVED,
  CUSTOMER_SELECTED,
  RESET_TRAVELER_DETAILS,
  SAVING_TRAVELER_DETAILS_FAILED,
  CUSTOMER_DATA_READ,
  CART_FETCHED,
} from '../../actions/common';
import { FLIGHT_SEARCH_RESULT_SELECTED } from '../../actions/flight';
import deepClone from '../../lib/deepClone';

export default function travelerDetailsList(state = [], action) {
  const newState = deepClone(state);

  switch (action.type) {
    case CART_FETCHED:
      if (newState.length > 0) return newState;
      return action.cart.people.map(person => person.data);

    case SAVING_TRAVELER_DETAILS:
      newState[action.index] = { ...action.data };
      return newState;

    case TRAVELER_DETAILS_SAVED:
      newState[action.index].id = action.id;
      return newState;

    case SAVING_TRAVELER_DETAILS_FAILED:
      newState.splice(action.index, 1);
      return newState;

    case CUSTOMER_SELECTED:
      newState[action.index] = {};
      return newState;

    case RESET_TRAVELER_DETAILS:
      newState[action.index] = null;
      return newState;

    case FLIGHT_SEARCH_RESULT_SELECTED:
      newState.forEach((travelerDetails, index) => {
        newState[index].mealRequests = [];
      });
      return newState;

    case CUSTOMER_DATA_READ:
      return action.data;

    default:
      return state;
  }
}
