import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Tooltip from '@wtag/rcl-tooltip';
import classNames from 'classnames';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import { NEW_CC_FORM_QUERY, QUERY_UPDATE_TYPE } from '../helpers/ccvPayment';
import './style.scss';

const AddNewCreditCardPanel = ({
  triggerResetCardAddressForm,
  resetCreditCardBillingAddressForm,
  setTriggerResetCardAddressForm,
  maximumNumberOfCardsAdded,
  setQuery,
  currentTab,
}) => {
  const showCreditCardPanel = () => {
    if (triggerResetCardAddressForm) {
      resetCreditCardBillingAddressForm();
      setTriggerResetCardAddressForm(false);
    }

    if (currentTab) {
      setQuery({ openCreditCardPanel: NEW_CC_FORM_QUERY, currentTab }, QUERY_UPDATE_TYPE);
    } else {
      setQuery({ openCreditCardPanel: NEW_CC_FORM_QUERY }, QUERY_UPDATE_TYPE);
    }
  };

  const content = (
    <Fragment>
      <Icon
        name="creditCard"
        className={classNames({ 'credit-card__add-cc--disabled': maximumNumberOfCardsAdded })}
        size="normal"
        color={maximumNumberOfCardsAdded ? 'default' : 'tertiary'}
        showLabel={true}
        iconLabel={I18n.t('admin.components.orders.items_tab.label.add_card_text')}
        showBGColor={true}
      />
      <Button
        size="small"
        type="tertiary"
        onClick={showCreditCardPanel}
        label={I18n.t('admin.components.orders.items_tab.label.add_card')}
        disabled={maximumNumberOfCardsAdded}
      />
    </Fragment>
  );

  return (
    <div className="credit-card__add-cc">
      {maximumNumberOfCardsAdded ? (
        <Tooltip
          content={I18n.t('ibe.payment.credit_card.add_disabled')}
          type="disabled"
          position="bottom"
          gap={10}
        >
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </div>
  );
};
AddNewCreditCardPanel.defaultProps = {
  currentTab: null,
};

AddNewCreditCardPanel.propTypes = {
  maximumNumberOfCardsAdded: PropTypes.bool.isRequired,
  triggerResetCardAddressForm: PropTypes.bool.isRequired,
  setTriggerResetCardAddressForm: PropTypes.func.isRequired,
  resetCreditCardBillingAddressForm: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  currentTab: PropTypes.number,
};

export default withQueryParamsProvider(AddNewCreditCardPanel);
