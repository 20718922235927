import React from 'react';
import PropTypes from 'prop-types';
import LoadingInformationCard from './LoadingInformationCardContainer';

const SearchLoadingScreen = ({ searchInformation, searchCompleted, expand }) => (
  <div className="col-grid col-bleed car-search-loading-screen">
    <LoadingInformationCard
      searchInformation={searchInformation}
      searchCompleted={searchCompleted}
      expand={expand}
    />
  </div>
);

SearchLoadingScreen.propTypes = {
  searchInformation: PropTypes.shape({
    airportSearch: PropTypes.bool,
    driverAge: PropTypes.number,
    dropOffDate: PropTypes.string,
    dropOffGeoLocationAddress: PropTypes.string,
    dropOffGeoLocationLatitude: PropTypes.number,
    dropOffGeoLocationLongitude: PropTypes.number,
    dropOffLocationAirportCode: PropTypes.string,
    dropOffTime: PropTypes.string,
    pickUpDate: PropTypes.string,
    pickUpGeoLocationAddress: PropTypes.string,
    pickUpGeoLocationLatitude: PropTypes.number,
    pickUpGeoLocationLongitude: PropTypes.number,
    pickUpLocationAirportCode: PropTypes.string,
    pickUpTime: PropTypes.string,
  }).isRequired,
  searchCompleted: PropTypes.bool.isRequired,
  expand: PropTypes.bool.isRequired,
};

export default SearchLoadingScreen;
