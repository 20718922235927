import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@wtag/rcl-avatar';
import StackedItems from '@wtag/rcl-stacked-items';
import HumanReadableDuration from 'sharedWebpack/HumanReadableDuration';
import ProductIcon from 'sharedWebpack/ProductIcon';

const FlightTimeLine = ({ type, wholeDurationInMinutes, travelers }) => {
  const [isTravellerPopoverVisible, setIsTravellerPopoverVisible] = useState(false);

  const togglePopoverVisibility = () =>
    setIsTravellerPopoverVisible(prevTravellerPopoverState => !prevTravellerPopoverState);

  const onOutsideClick = () => setIsTravellerPopoverVisible(false);

  return (
    <div className="col-12 col-sm-3 col-xlg-2 travel-plan__timeline-flight-section-product">
      <div className="travel-plan__timeline-flight-section-product-info">
        <div className="travel-plan__timeline-flight-section-product-icon">
          <ProductIcon productType={type} showBGColor={true} size="medium" />
        </div>
        <div className="travel-plan__timeline-flight-section-product-name">
          {I18n.t('admin.components.orders.overview.travel_plan.flight.title')}
        </div>
        <div className="travel-plan__timeline-flight-section-product-duration">
          {HumanReadableDuration(wholeDurationInMinutes)}
        </div>
        <div className="travel-plan__timeline-flight-section-product-travellers">
          <StackedItems
            itemSize="tiny"
            isPopoverVisible={isTravellerPopoverVisible}
            onClick={togglePopoverVisibility}
            onOutsideClick={onOutsideClick}
          >
            {travelers.map(({ firstName, lastName, avatarUrl }) => (
              <Avatar key={uuidv4()} firstName={firstName} lastName={lastName} src={avatarUrl} />
            ))}
          </StackedItems>
        </div>
      </div>
      <div className="travel-plan__timeline-item-indicator">
        <div className="travel-plan__timeline-item-indicator-line" />
      </div>
    </div>
  );
};

FlightTimeLine.propTypes = {
  type: PropTypes.string.isRequired,
  wholeDurationInMinutes: PropTypes.number.isRequired,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
      seatNumber: PropTypes.string,
      ticketNumber: PropTypes.string,
      services: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};

export default hot(module)(FlightTimeLine);
