import { FLIGHT_SET_DEFAULT_SEARCH_PARAMS, CREATE_FLIGHT_SEARCH } from '../../actions/flight';

export default function defaultFlightSearchParams(state = {}, action) {
  switch (action.type) {
    case CREATE_FLIGHT_SEARCH:
      return {};

    case FLIGHT_SET_DEFAULT_SEARCH_PARAMS:
      return action.params;

    default:
      return state;
  }
}
