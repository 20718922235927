import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import { injectIntl } from 'react-intl';
import { reduxForm, reset } from 'redux-form';

import EmailShare from './EmailShare';
import { updateConfirmationMailRecipients } from '../../actions/common';
import {
  validate,
  emailValidation,
  duplicateValidation,
  transliterateValidation,
} from '../../lib/validations';
import { nameCommonValidation } from '../../lib/validations/commonBookingValidations';

const mapConfirmationMailRecipient = recipients => {
  const mappedRecipient = [];
  if (recipients && recipients.length > 0) {
    recipients.forEach(recipient => {
      if (recipient && recipient.email) {
        mappedRecipient.push({
          name: `${recipient.firstName ? recipient.firstName : recipient.name } ${recipient.lastName ? recipient.lastName : ''}`,
          email: recipient.email,
        });
      }
      return mappedRecipient;
    });
  }
  return mappedRecipient;
};

const validateFields = (values, list) => {
  let recipients = list.map(item => item.email);
  let recipientSearch;
  if (values.recipientSearch) {
    recipientSearch = JSON.parse(values.recipientSearch).email;
    recipients = recipients.concat(recipientSearch);
  }

  if (values.email) {
    recipients = recipients.concat(values.email);
  }
  const message = <I18nText id="ibe.validations.duplicate_email" />;

  const constraints = {
    name: { ...nameCommonValidation, ...transliterateValidation },
    email: {
      ...emailValidation,
      ...duplicateValidation(values.email, recipients, message),
    },
  };
  return {
    ...validate(values, constraints),
  };
};

const validateAllFields = list => params => validateFields(params, list);

const reduxFormConfig = {
  form: 'emailShareForm',
  fields: ['name', 'email'],
};

const mapStateToProps = state => ({
    recipients: mapConfirmationMailRecipient(
      state.common.confirmationMail.recipients,
    ),
    validate: validateAllFields(state.common.confirmationMail.recipients),
  });

const mapDispatchToProps = dispatch => ({
  updateConfirmationMailRecipients: recipients =>
    dispatch(updateConfirmationMailRecipients(recipients)),
  resetForm: form => dispatch(reset(form)),
});

const ReduxForm = reduxForm(reduxFormConfig, mapStateToProps, mapDispatchToProps)(EmailShare);
export default injectIntl(ReduxForm);
