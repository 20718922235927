import React, { useState, useEffect } from 'react';
import Card from '@wtag/rcl-card';
import routes from 'agentRoutes';
import PropTypes from 'prop-types';
import { Link, Spinner } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import StackedItems from '@wtag/rcl-stacked-items';
import Amount from 'sharedWebpack/Amount';
import DateTime from 'sharedWebpack/DateTime';
import { FormatDateTime, DATE_FORMAT_SHORT } from 'sharedWebpack/helpers/dateTime';
import DateTimeLocaleInterpolationWrapper from 'sharedWebpack/DateTimeLocaleInterpolationWrapper';
import httpClient from 'agentHTTPClient';
import { Header } from '@wtag/rcl-typography';
import StateEmphasisTag from '../../../shared/Orders/StateEmphasisTag';
import productIcon from '../../../shared/ProductIcon';

const OrderCard = props => {
  const { order } = props;
  const [travellers, setTravellers] = useState([]);
  const [isTravellersLoading, setIsTravellersLoading] = useState(true);
  const [isProductPopoverVisible, setIsProductPopoverVisible] = useState(false);
  const [isTravellerPopoverVisible, setIsTravellerPopoverVisible] = useState(false);

  const onFetchTravllers = async () => {
    const { data } = await httpClient.get(routes.public.orders.travellers({ orderId: order.id }));
    setTravellers(data.travellers);
    setIsTravellersLoading(false);
  };

  useEffect(() => {
    onFetchTravllers();
  }, []);

  return (
    <div className="orders-overview__card">
      <div className="orders-overview__card-order">
        <Card version="v2" shadow="dark">
          <div className="col-grid direction-row col-bleed align-center justify-space-between">
            <div className="orders-overview__card-order-travellers">
              {isTravellersLoading ? (
                <Spinner size="tiny" bgColor="neutral" />
              ) : (
                <StackedItems
                  itemSize="tiny"
                  emptyPlaceholder={I18n.t('public.components.orders.no_travellers')}
                  itemName={
                    travellers.length > 3
                      ? I18n.t('public.components.orders.travellers')
                      : `${travellers.length} ${I18n.t('public.components.orders.travellers')}`
                  }
                  isPopoverVisible={isTravellerPopoverVisible}
                  onClick={() =>
                    setIsTravellerPopoverVisible(
                      prevTravellerPopoverState => !prevTravellerPopoverState,
                    )
                  }
                  onOutsideClick={() => setIsTravellerPopoverVisible(false)}
                >
                  {travellers.length
                    ? travellers.map(traveller => (
                        <Avatar
                          key={traveller.id}
                          firstName={traveller.firstName}
                          lastName={traveller.lastName}
                          src={traveller.avatarUrl}
                        />
                      ))
                    : null}
                </StackedItems>
              )}
            </div>
            <div className="orders-overview__card-order-products">
              <StackedItems
                itemSize="tiny"
                popOverDirection="bottom-right"
                emptyPlaceholder={I18n.t('public.components.orders.no_products')}
                isPopoverVisible={isProductPopoverVisible}
                onClick={() =>
                  setIsProductPopoverVisible(prevProductPopoverState => !prevProductPopoverState)
                }
                onOutsideClick={() => setIsProductPopoverVisible(false)}
              >
                {order.products.length
                  ? order.products.map(product => productIcon({ productType: product }))
                  : null}
              </StackedItems>
            </div>
          </div>
          <span className="orders-overview__card-order-destination">
            {order.origin && order.destination
              ? `${order.origin}-${order.destination}`
              : I18n.t('public.components.orders.no_destination')}
          </span>
          <Header weight="medium" level={5}>
            <div className="col-grid direction-row col-bleed align-center orders-overview__card-order-title">
              <div className="orders-overview__card-order-title-value">
                {`${order.id} | ${order.title}`}
              </div>
              <StateEmphasisTag currentState={order.currentState} stateSize="tiny" />
            </div>
          </Header>
          <div className="col-grid direction-row col-bleed align-center orders-overview__card-order-owners wrap">
            <Avatar
              src={order.person.avatarUrl}
              firstName={order.person.firstName}
              middleName={order.person.middleName || ''}
              lastName={order.person.lastName}
              size="tiny"
              showName={true}
            />
            <span className="orders-overview__card-order-owners-label">
              {I18n.t('public.components.orders.booked_by')}
            </span>
          </div>
          <div className="orders-overview__card-order-footer-wrapper">
            <div className="col-grid col-bleed align-end orders-overview__card-order-starting-date">
              <span className="orders-overview__card-order-starting-date-label">
                {I18n.t('public.components.orders.starting_in')}
              </span>
              <span className="orders-overview__card-order-starting-date-value">
                {order.startDate ? (
                  <DateTime dateTime={order.startDate} format="short" />
                ) : (
                  I18n.t('public.components.orders.none')
                )}
              </span>
            </div>
            <div className="col-grid col-bleed direction-row orders-overview__card-order-footer">
              <div className="col-grid col-bleed orders-overview__card-order-footer-booking-details">
                <span className="orders-overview__card-order-footer-booking-details-amount">
                  <Amount currency={order.currency} value={order.total} />
                </span>
                <span className="orders-overview__card-order-footer-booking-details-booking-date">
                  <DateTimeLocaleInterpolationWrapper
                    localeKey="admin.components.orders.table.booked_on"
                    time={FormatDateTime({
                      dateTime: order.bookingDate,
                      format: DATE_FORMAT_SHORT,
                    })}
                  />
                </span>
              </div>
              <Link type="button" modifier="tertiary" size="tiny" href={order.urls.view}>
                {I18n.t('public.components.orders.actions.view_order')}
              </Link>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

OrderCard.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(PropTypes.string).isRequired,
    currency: PropTypes.string.isRequired,
    bookingDate: PropTypes.string.isRequired,
    urls: PropTypes.shape({
      view: PropTypes.string.isRequired,
    }),
    total: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    title: PropTypes.string.isRequired,
    currentState: PropTypes.string.isRequired,
    origin: PropTypes.string,
    destination: PropTypes.string.isRequired,
    person: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      lastName: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
    }),
  }).isRequired,
};

export default OrderCard;
