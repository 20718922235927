import PayFast from 'agentSyncedImages/fallback_images/payment_gateway/payfast.png';
import PayGate from 'agentSyncedImages/fallback_images/payment_gateway/paygate.png';
import PostFinance from 'agentSyncedImages/fallback_images/payment_gateway/postfinance.png';

function paymentGatewayType(paymentGateways) {
  switch (paymentGateways) {
    case 'pay_fast':
      return PayFast;

    case 'pay_gate':
      return PayGate;

    case 'post_finance':
      return PostFinance;

    default:
      return null;
  }
}

export default paymentGatewayType;
