import { useState, useEffect } from 'react';

const useWindowWidth = () => {
  const [windowWidth, detectWidth] = useState({
    winWidth: window.innerWidth,
  });

  const detectSize = () => {
    detectWidth({
      winWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowWidth]);

  return windowWidth.winWidth;
};

export default useWindowWidth;
