import { TYPE_FLIGHT } from 'sharedWebpack/helpers/itemTypes';

/**
 * Gets contiguous group of flight
 *
 * @param {Array} travelPlan - Array of travel plan objects. Each object contains a type and some of them contains a tripId.
 * @returns {Object} An object where keys are trip IDs and values are objects containing the
 *                   group starting index and the group of travel plans for each trip ID.
 */
const getContiguousFlightGroup = travelPlan => {
  if (!travelPlan || !Array.isArray(travelPlan)) {
    return {};
  }

  const contiguousFlightGroup = {};
  let group = [];
  let prevTripId = null;
  let groupStartIndex = null;
  const lastTravelPlanIndex = travelPlan.length - 1;

  // Initializes a new group with the given tripId and index
  const startNewGroup = (currentTripId, index) => {
    prevTripId = currentTripId;
    groupStartIndex = index;
    group = [];
  };

  // Adds the current group to the allGroups object if there's an ongoing group and the length is greater than 1
  const addCurrentGroupToGroupMap = () => {
    if (prevTripId !== null && group.length > 1) {
      contiguousFlightGroup[prevTripId] = {
        groupStartIndex,
        group: [...group],
      };
    }
  };

  // Resets group variables to start a new group
  const resetGroupVariables = () => {
    prevTripId = null;
    groupStartIndex = null;
    group = [];
  };

  travelPlan.forEach((plan, index) => {
    const { type, tripId: currentTripId } = plan || {};

    // If the current plan is not a flight, reset the group variables
    if (type !== TYPE_FLIGHT) {
      addCurrentGroupToGroupMap();
      resetGroupVariables();
      return;
    }

    // If the tripId has changed, finalize the current group and start a new one
    if (prevTripId !== currentTripId) {
      addCurrentGroupToGroupMap();
      startNewGroup(currentTripId, index);
    }

    // Add the current plan to the ongoing group
    group.push(plan);

    // If this is the last plan in the list, add the final group to allGroups
    if (index === lastTravelPlanIndex) {
      addCurrentGroupToGroupMap();
    }
  });

  return contiguousFlightGroup;
};

export default getContiguousFlightGroup;
