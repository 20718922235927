import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';

const RoomListEmptyPlaceholder = props => {
  const { header, message } = props;

  return (
    <div className="chat__empty-card">
      <Icon
        className="chat__empty-card--emptyChat"
        name="emptyChat"
        size="xxl"
        showBGColor={true}
        color="tertiary"
      />
      <div className="chat__empty-card-placeholder">
        <div className="chat__empty-card-placeholder-header">{header}</div>
        <div className="chat__empty-card-placeholder-text">{message}</div>
      </div>
    </div>
  );
};

RoomListEmptyPlaceholder.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default RoomListEmptyPlaceholder;
