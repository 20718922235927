/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Row from '../Row';

function Fee(props) {
  const { item } = props;

  return (
    <div className="cart-summary__accordion-sub-item">
      <Row
        name={item.name}
        price={item.grossAmount}
        currency={item.currency}
        type={item.type}
        quantity={item.quantity}
        unitPrice={item.grossPrice}
      />
      <Row
        name={I18n.t('components.ibe.summary.taxes_and_charges')}
        price={item.grossVat}
        currency={item.currency}
        hasChildRow={false}
      />
    </div>
  );
}

Fee.propTypes = {
  item: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    grossAmount: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
    grossPrice: PropTypes.string.isRequired,
  }).isRequired,
};
export default Fee;
