import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@wtag/rcl-typography';
import InstantTicketWrapper from 'sharedWebpack/InstantTicketWrapper';
import FlexiblePaymentTag from 'sharedWebpack/FlexiblePaymentTag';
import MatrixCard from '../MatrixCard';
import MatrixContext from '../../contexts/MatrixContext';
import './matrix-supplier-card.styles.scss';
import { WELLTRAVEL } from '../../../../lib/helpers/welltravel_channel';

const MatrixSupplierCard = props => {
  const { channelName, channelIdentifier, instantTicketSupported, payLaterSupported } = props;
  const { matrixRowHeaderCardWidth, suppliers } = useContext(MatrixContext);
  const showChannelName = channelName !== WELLTRAVEL;
  const SupplierLogo = suppliers[channelName].logo;
  const {
    supportManageBooking,
    supportNdcBooking,
    supportCreateQuote,
    supportFlexibleCancelation,
    supportIssueTicket,
    supportVoidTicket,
    supportRefundTicket,
    supportChangeBooking,
    supportAncillaryServices,
    supportFlightBooking,
  } = suppliers[channelName];

  return (
    <MatrixCard
      className="matrix-supplier-card"
      width={matrixRowHeaderCardWidth}
      isClickable={false}
    >
      <div className="matrix-supplier-card__channel-logo">
        <SupplierLogo />
      </div>
      {showChannelName && (
        <Text
          className="matrix-supplier-card__channel"
          level={3}
          weight="bold"
        >{`${I18n.t(channelName, { scope: 'channels' })} ${channelIdentifier}`}</Text>
      )}
      {payLaterSupported && <FlexiblePaymentTag matrixCard={true} />}
      <div className="matrix-supplier-card__instant-ticket-tag">
        {instantTicketSupported && <InstantTicketWrapper />}
      </div>
      <ul className="matrix-supplier-card__facilities">
        {supportManageBooking && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.manage_booking')}
            </Text>
          </li>
        )}
        {supportNdcBooking && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.ndc_booking')}
            </Text>
          </li>
        )}
        {supportCreateQuote && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.create_quote')}
            </Text>
          </li>
        )}
        {supportFlexibleCancelation && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.flexible_cancellation')}
            </Text>
          </li>
        )}
        {supportIssueTicket && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.issue_ticket')}
            </Text>
          </li>
        )}
        {supportVoidTicket && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.void_ticket')}
            </Text>
          </li>
        )}
        {supportRefundTicket && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.refund_ticket')}
            </Text>
          </li>
        )}
        {supportChangeBooking && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.change_booking')}
            </Text>
          </li>
        )}
        {supportFlightBooking && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.flight_booking')}
            </Text>
          </li>
        )}
        {supportAncillaryServices && (
          <li className="matrix-supplier-card__facilities-item">
            <span className="matrix-supplier-card__facilities-item-bullet">
              <span className="matrix-supplier-card__facilities-item-bullet-dot" />
            </span>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.results.supplier_features.ancillary_services')}
            </Text>
          </li>
        )}
      </ul>
    </MatrixCard>
  );
};

MatrixSupplierCard.propTypes = {
  channelName: PropTypes.string.isRequired,
  channelIdentifier: PropTypes.string.isRequired,
  instantTicketSupported: PropTypes.string.isRequired,
  payLaterSupported: PropTypes.string.isRequired,
};

export default MatrixSupplierCard;
