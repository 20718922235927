import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import Link from '@wtag/rcl-link';
import Modal from 'sharedWebpack/Modal';
import Amount from 'sharedWebpack/Amount';
import getTripOriginDetails from 'sharedWebpack/IBE/helpers/getTripOriginDetails';
import getTripDestinationDetails from 'sharedWebpack/IBE/helpers/getTripDestinationDetails';
import JourneySummaryIndicatorWrapper from 'sharedWebpack/JourneySummaryIndicator/JourneySummaryIndicatorWrapper';
import useBodyScrollLock from '../../../../../../shared/useBodyScrollLock';
import TripServices from './TripServices';
import ItemServices from './ItemServices';
import getTripType from '../../../../lib/helpers/getTripType';
import './styles.scss';

const FlightServiceSelection = ({
  services,
  travelers,
  trips,
  ensureServiceInCart,
  title,
  item,
  setShouldLockScroll,
  callbackParams,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();

  const getSelectedServices = () => {
    const mapped = {};

    services
      .filter(service => service.quantity > 0)
      .forEach(service => {
        mapped[service.id] = {
          price: service.price,
          quantity: service.quantity,
          travelerIndices: service.travelerIndices,
          limitationGroup: service.limitations.group,
        };
      });

    return mapped;
  };

  const onModalOpen = () => {
    setShouldLockScroll(true);
    setModalOpen(true);
    setSelectedServices(getSelectedServices());
  };

  const onModalClose = () => {
    setShouldLockScroll(false);
    setModalOpen(false);
  };

  const quantitiesOfGroup = group =>
    Object.values(selectedServices)
      .filter(service => group === service.limitationGroup)
      .reduce((memo, service) => memo + service.quantity, 0);

  const listOfAssignedTravelers = group => {
    const travelerList = Object.values(selectedServices)
      .filter(service => group === service.limitationGroup)
      .map(service => service.travelerIndices);

    return [].concat(...travelerList); // Array flating to support for old browsers
  };

  const getServices = () => {
    if (!services) return [];

    return services.map(service => ({
      ...service,
      ...selectedServices[service.id],
      quantityInGroup: quantitiesOfGroup(service.limitations.group),
      assignedTravelersOfGroup: listOfAssignedTravelers(service.limitations.group),
    }));
  };

  const onChange = (serviceId, quantity, price, travelerIndices) => {
    const newSelectedServices = { ...selectedServices };
    const changedService = getServices().find(service => service.id === serviceId);
    const modifiedService = services.find(service => service.id === serviceId);
    const isQuantityChanged = modifiedService.quantity !== quantity;
    const isTravelerChanged =
      modifiedService.travelerIndices.length !== travelerIndices.length ||
      !modifiedService.travelerIndices.every(value => travelerIndices.includes(value));

    newSelectedServices[serviceId] = {
      quantity,
      travelerIndices,
      price,
      changed: isQuantityChanged || isTravelerChanged,
      limitationGroup: changedService.limitations.group,
    };

    setSelectedServices(newSelectedServices);
  };

  const ensureServiceWithProperQuantity = serviceId => {
    const { quantity, travelerIndices } = selectedServices[serviceId];
    let quantityValue = quantity;

    if (travelerIndices.length < quantity) {
      quantityValue = travelerIndices.length;
    }

    return ensureServiceInCart(serviceId, quantityValue, travelerIndices, callbackParams);
  };

  const confirmSelection = () => {
    setIsProcessing(true);

    Promise.all(
      Object.keys(selectedServices).map(serviceId => ensureServiceWithProperQuantity(serviceId)),
    ).then(() => {
      setIsProcessing(false);
      onModalClose();
    });
  };

  const resetSelection = () => {
    if (services.filter(service => service.quantity > 0).length === 0) setSelectedServices({});
    else {
      const prevSelectedServices = {};
      services.forEach(service => {
        prevSelectedServices[service.id] = { ...service };
      });

      setSelectedServices({ ...prevSelectedServices });
    }
  };

  const getItemServices = () => getServices().filter(service => !service.trip && !service.segment);

  const totalPrice = () => {
    let total = 0.0;

    Object.values(selectedServices).forEach(service => {
      total += service.quantity * service.price;
    });

    return total;
  };

  const totalCurrency = (services && services.length > 0 && services[0].currency) || item.currency;
  const airportDetails = trips
    .flatMap(trip => trip.airports)
    .reduce((acc, airport) => {
      acc[airport.code] = airport;

      return acc;
    }, {});
  const tripType = getTripType(trips);

  const originDetails = getTripOriginDetails(trips, airportDetails);
  const destinationDetails = getTripDestinationDetails(trips, airportDetails);
  const isButtonDisabled =
    Object.values(selectedServices).filter(service => service.changed).length === 0 || isProcessing;

  useEffect(() => {
    if (modalOpen) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }

    return () => {
      unlockBodyScroll();
    };
  }, [modalOpen]);

  return (
    <Fragment>
      <Link
        modifier="tertiary"
        size="tiny"
        onClick={onModalOpen}
        disabled={!services || services.length === 0}
      >
        {I18n.t('components.ibe.flight.ancillary')}
      </Link>
      <Modal
        className="service-selection-modal"
        isModalVisible={modalOpen}
        onOutsideClick={onModalClose}
        alignContent="left"
        title={
          <div className="service-selection-modal__header">
            <div className="service-selection-modal__header--title">{title}</div>
            <div className="service-selection-modal__header--actions">
              <Button
                className="service-selection-modal__header--actions-reset"
                version="v2"
                label={I18n.t('components.ibe.flight_service_selection.reset')}
                size="small"
                onClick={resetSelection}
                disabled={isButtonDisabled}
              />
              <Button
                className="service-selection-modal__header--actions-confirm"
                version="v2"
                label={I18n.t('components.ibe.flight_service_selection.confirm')}
                size="small"
                onClick={confirmSelection}
                disabled={isButtonDisabled}
                type="primary"
              />
              <IconButton
                className="service-selection-modal__close-btn"
                icon={<Icon name="close" />}
                onClick={onModalClose}
                isIconOnly={true}
                color="primary-text"
                disabled={isProcessing}
              />
            </div>
          </div>
        }
      >
        <div>
          <div className="service-selection-modal__fee-container">
            <div className="service-selection-modal__fee-container--left">
              <JourneySummaryIndicatorWrapper trips={trips} airports={airportDetails} />
            </div>
            <div className="total-service-fee">
              <span className="total-service-fee--text">
                {I18n.t('components.ibe.flight_service_selection.total')}
              </span>
              <Amount
                className="total-service-fee--amount"
                currency={totalCurrency}
                value={totalPrice()}
                currencyDisplay="narrowSymbol"
              />
            </div>
          </div>
          <TripServices
            trips={trips}
            services={getServices()}
            airportDetails={airportDetails}
            onChange={onChange}
            travelers={travelers}
          />
          <ItemServices
            originDetails={originDetails}
            destinationDetails={destinationDetails}
            tripType={tripType}
            services={getItemServices()}
            onChange={onChange}
            travelers={travelers}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

FlightServiceSelection.defaultProps = {
  ensureServiceInCart: () => {},
};

FlightServiceSelection.propTypes = {
  ensureServiceInCart: PropTypes.func,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      price: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      travelerIndices: PropTypes.arrayOf(PropTypes.number),
    }),
  ).isRequired,
  trips: PropTypes.arrayOf(
    PropTypes.shape({ originCode: PropTypes.string, destinationCode: PropTypes.string }),
  ).isRequired,
  title: PropTypes.node.isRequired,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      name: PropTypes.node.isRequired,
    }),
  ).isRequired,
  item: PropTypes.shape({
    currency: PropTypes.string.isRequired,
  }).isRequired,
  setShouldLockScroll: PropTypes.func.isRequired,
  callbackParams: PropTypes.shape().isRequired,
};

export default FlightServiceSelection;
