import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import MatrixChannelContentRow from './MatrixChannelContentRow';
import MatrixFareBrandsContentRow from './MatrixFareBrandsContentRow';
import MatrixContext from '../contexts/MatrixContext';
import './matrix-content.styles.scss';

const MatrixContent = props => {
  const { selectFareBrandOption, windowWidth, people } = props;

  const { activeTrip, laymanMode } = useContext(MatrixContext);
  return (
    <div className="matrix-content">
      {activeTrip && (
        <Fragment>
          <MatrixFareBrandsContentRow
            fareBrands={activeTrip.fareBrands}
            selectFareBrandOption={selectFareBrandOption}
            windowWidth={windowWidth}
            people={people}
          />
          {!laymanMode &&
            activeTrip.channels.map(channel => (
              <MatrixChannelContentRow
                key={`${channel.name}-${channel.identifier}`}
                channel={channel}
                selectFareBrandOption={selectFareBrandOption}
                people={people}
              />
            ))}
        </Fragment>
      )}
    </div>
  );
};

MatrixContent.propTypes = {
  selectFareBrandOption: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  people: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default MatrixContent;
