import { HOTEL_DETAILS_FETCHED } from '../../actions/hotel';

export default function hotelDetailsByResultId(state = {}, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case HOTEL_DETAILS_FETCHED:
      if (action.details === null) {
        return newState;
      }
      newState[action.details.id] = action.details;
      if (action.details.location) {
        newState[action.details.id].location = {
          lat: parseFloat(action.details.location.lat, 10),
          lon: parseFloat(action.details.location.lon, 10),
        };
      }
      return newState;

    default:
      return state;
  }
}
