import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Icon from '@wtag/rcl-icon';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Tooltip from '@wtag/rcl-tooltip';

const deniedIcons = reason => {
  if (reason) {
    return (
      <div className="approver__status">
        <div className="approver__status-doc">
          <Tooltip content={reason} type="inverse" position="bottom-right">
            <Icon name="orders" color="warning" />
          </Tooltip>
        </div>
        <Icon name="closeCircle" color="danger" />
      </div>
    );
  }

  return <Icon name="closeCircle" color="danger" />;
};

const renderStatus = confirmation => {
  switch (confirmation.status) {
    case 'pending':
      return <Icon name="loading" size="tiny" color="warning" />;
    case 'approved':
      return <Icon name="preference" size="tiny" color="success" />;
    case 'denied':
      return deniedIcons(confirmation.declineReason);
    default:
      return null;
  }
};

const Approver = ({ confirmation }) => (
  <div className="approver">
    <div className="approver__title">
      <div className="approver__avatar">
        <Avatar
          size="tiny"
          firstName={confirmation.firstName}
          lastName={confirmation.lastName}
          src={confirmation.profilePictureUrl}
          tooltip={<Tooltip content={confirmation.name} type="inverse" />}
        />
      </div>
      <div className="approver__name">{confirmation.name}</div>

      {confirmation.mandatory && (
        <EmphasisTag
          type="warning"
          text={I18n.t('public.components.approvals.confirmations.mandatory')}
          size="tiny"
        />
      )}
    </div>

    <div className="approver__status">{renderStatus(confirmation)}</div>
  </div>
);

Approver.propTypes = {
  confirmation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    profilePictureUrl: PropTypes.string,
    mandatory: PropTypes.bool.isRequired,
  }).isRequired,
};

const renderLoader = (confirmations, fetchingRequest) => {
  if (confirmations === 0 && !fetchingRequest) {
    return (
      <div className="approver__executive-approver">
        {I18n.t('public.components.approvals.requests.no_executive_approver')}
      </div>
    );
  }
  return <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} />;
};

const Approvers = ({ confirmations, confirmationLabel, minApprovals, fetchingRequest }) => (
  <Fragment>
    <div className="left-panel__title">
      {I18n.t(confirmationLabel, {
        scope: 'public.components.approvals.approvers',
        count: confirmations.length,
      })}
    </div>

    {minApprovals && (
      <div className="left-panel__title-sub-title">
        {I18n.t('public.components.approvals.requests.min_approvals', { count: minApprovals })}
      </div>
    )}

    {confirmations.length > 0 && !fetchingRequest
      ? confirmations.map(confirmation => <Approver confirmation={confirmation} />)
      : renderLoader(confirmations.length, fetchingRequest)}
  </Fragment>
);

Approvers.defaultProps = {
  minApprovals: null,
};

Approvers.propTypes = {
  confirmations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  confirmationLabel: PropTypes.string.isRequired,
  minApprovals: PropTypes.number,
  fetchingRequest: PropTypes.bool.isRequired,
};

export default hot(module)(Approvers);
