import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';

const Step = props => {
  const { stepName, stepIconType, isActive, isDone, hasError } = props;

  const renderAppropriateIcon = () => {
    let stepIconName = null;
    let iconClassName = null;
    let stepIconColor = null;
    const stepIconFillColor = isActive || isDone ? '#FFFFFF' : null;

    if (hasError) {
      iconClassName = 'stepper__body-step-item--error-icon';
      stepIconName = 'highPriority';
      stepIconColor = 'danger';
    } else if (isDone) {
      iconClassName = 'stepper__body-step-item--check-icon';
      stepIconName = 'check';
      stepIconColor = 'success';
    } else {
      iconClassName = 'stepper__body-step-item--step-icon';
      stepIconName = stepIconType;
      stepIconColor = isActive ? 'tertiary' : 'default';
    }

    return (
      <Icon
        className={iconClassName}
        showLabel={true}
        iconLabel={stepName}
        name={stepIconName}
        showBGColor={true}
        color={stepIconColor}
        fill={stepIconFillColor}
      />
    );
  };

  return (
    <div
      role="presentation"
      className={classNames('stepper__body-step-item', {
        'stepper__body-step-item--active': isActive,
      })}
    >
      <span />
      {renderAppropriateIcon()}
    </div>
  );
};

Step.defaultProps = {
  isActive: false,
  isDone: false,
  hasError: false,
};

Step.propTypes = {
  stepIconType: PropTypes.string.isRequired,
  stepName: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isDone: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default Step;
