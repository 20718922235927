const senderBasicInformation = (name, avatarUrl, isOnline) => {
  const nameInArrayFormat = name.split(' ');
  const splitNameLength = nameInArrayFormat.length;

  return {
    name,
    firstName: nameInArrayFormat[0],
    lastName: splitNameLength > 1 ? nameInArrayFormat[splitNameLength - 1] : '',
    avatarUrl: avatarUrl && avatarUrl.trim(),
    isOnline,
  };
};

const senderInformation = (roomUsers, message, context) => {
  if (['automated', 'announcement'].indexOf(message.messageType) !== -1) {
    return senderBasicInformation(context.tenant, context.currentTenantLogoUrl, false);
  }

  const user = message.isCustomer
    ? roomUsers.customers[message.senderId]
    : roomUsers.agency[message.senderId];

  if (user) {
    return senderBasicInformation(
      user.name,
      user.avatarURL && user.avatarURL.trim(),
      user.isOnline,
    );
  }

  if (message.senderId === context.currentUserId && message.isCustomer === context.laymanMode) {
    return senderBasicInformation(context.currentUserName, context.currentUserAvatar, true);
  }

  return senderBasicInformation(message.senderName, '', false);
};

const quoteMessageSenderInformation = (roomUsers, quotedMessage, tenant) => {
  const user = quotedMessage.isCustomer
    ? roomUsers.customers[quotedMessage.senderId]
    : roomUsers.agency[quotedMessage.senderId];

  if (user) {
    return user.name;
  }

  return tenant;
};

const quoteMessageHeaderText = ({ roomUsers, tenant, quotedMessage, sender, currentUser }) => {
  const isMyQuoteMessage =
    quotedMessage.isCustomer === currentUser.isCustomer &&
    parseInt(quotedMessage.senderId, 10) === currentUser.id;
  const isMyMessage = sender.isCustomer === currentUser.isCustomer && sender.id === currentUser.id;

  if (isMyQuoteMessage && isMyMessage) {
    return I18n.t('chat.quote_message.yourself');
  }

  if (isMyQuoteMessage && !isMyMessage) {
    return I18n.t('chat.quote_message.you');
  }

  return I18n.t('chat.quote_message.owner', {
    sender: quoteMessageSenderInformation(roomUsers, quotedMessage, tenant),
  });
};

export {
  quoteMessageHeaderText,
  senderBasicInformation,
  senderInformation,
  quoteMessageSenderInformation,
};
