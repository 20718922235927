import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const NATIONALITIES_FETCHED = 'NATIONALITIES_FETCHED';
function nationalityFetched(data) {
  return {
    type: NATIONALITIES_FETCHED,
    data,
  };
}

export const FETCHING_NATIONALITIES = 'FETCHING_NATIONALITIES';
export function fetchNationalities() {
  return dispatch => {
    dispatch({
      type: FETCHING_NATIONALITIES,
    });

    return httpClient
      .get(routes.api.nationalities())
      .then(response => dispatch(nationalityFetched(response.data)));
  };
}
