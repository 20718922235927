import React from 'react';
import PropTypes from 'prop-types';
import StaticProductItem from './StaticProduct';
import CarItem from './CarItem';
import HotelItem from './HotelItem';
import FlightItem from './Flight';
import TransferItem from './TransferItem';
import CruiseItem from './CruiseItem';
import EventItem from './EventItem';
import { TYPE_FLIGHT } from '../helpers/itemTypes';

const ItemWrapper = ({
  journeyElementId,
  journeyElementType,
  fetchedJourneyElement,
  productId,
  showMinimalInfo,
  refetchJourneyElement,
  showFlightSeatsStatus,
  totalTravelerCount,
  payLater,
  laymanMode,
  item,
}) => {
  const availableItem = {
    flight: FlightItem,
    hotel: HotelItem,
    car: CarItem,
    transfer: TransferItem,
    cruise: CruiseItem,
    event: EventItem,
  };
  const ItemBasedOnType = availableItem[journeyElementType];

  if (journeyElementType === TYPE_FLIGHT && showFlightSeatsStatus) {
    return (
      <FlightItem
        showFlightSeatsStatus={true}
        journeyElementId={journeyElementId}
        journeyElementType={TYPE_FLIGHT}
        showMinimalInfo={showMinimalInfo}
        fetchedJourneyElement={fetchedJourneyElement}
        refetchJourneyElement={refetchJourneyElement}
        totalTravelerCount={totalTravelerCount}
        payLater={payLater}
        laymanMode={laymanMode}
      />
    );
  }

  if (journeyElementType in availableItem) {
    return (
      <ItemBasedOnType
        journeyElementId={journeyElementId}
        journeyElementType={journeyElementType}
        showMinimalInfo={showMinimalInfo}
        fetchedJourneyElement={fetchedJourneyElement}
        refetchJourneyElement={refetchJourneyElement}
        payLater={payLater}
        laymanMode={laymanMode}
        item={item}
      />
    );
  } else if (productId) {
    return <StaticProductItem productId={productId} />;
  }

  return null;
};

ItemWrapper.defaultProps = {
  journeyElementId: null,
  journeyElementType: '',
  productId: '',
  showMinimalInfo: false,
  fetchedJourneyElement: null,
  refetchJourneyElement: false,
  showFlightSeatsStatus: false,
  payLater: false,
};

ItemWrapper.propTypes = {
  journeyElementId: PropTypes.number,
  journeyElementType: PropTypes.string,
  productId: PropTypes.string,
  showMinimalInfo: PropTypes.bool,
  fetchedJourneyElement: PropTypes.shape({}),
  refetchJourneyElement: PropTypes.bool,
  showFlightSeatsStatus: PropTypes.bool,
  totalTravelerCount: PropTypes.number.isRequired,
  payLater: PropTypes.bool,
  laymanMode: PropTypes.bool.isRequired,
  item: PropTypes.shape().isRequired,
};

export default ItemWrapper;
