import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'sharedWebpack/Modal';
import Icon from '@wtag/rcl-icon';

import csv from 'agentSyncedImages/png/csv.png';
import FormatText from '../../admin/FormatText';
import sanitizeURL from '../helpers/sanitizeURL';

import './styles.scss';

const ExportModal = props => {
  const { isModalVisible, setIsModalVisible, urls, queryParams } = props;

  const { sortColumn, sortDirection, startDate, endDate } = queryParams;
  const filterParams = `column=${sortColumn}&direction=${sortDirection}&start_date=${startDate}&end_date=${endDate}`;

  const modalTitleContent = (
    <div
      onClick={() => setIsModalVisible(false)}
      onKeyDown={() => setIsModalVisible(false)}
      role="presentation"
      className="export-modal__title"
    >
      <div>{I18n.t('admin.components.travel_expenses.export.header')}</div>
      <div>
        <Icon name="close" />
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Modal
        className="export-modal__wrapper"
        isModalVisible={isModalVisible}
        title={modalTitleContent}
        onOutsideClick={() => setIsModalVisible(false)}
      >
        <div className="col-12 export-modal col-bleed">
          <div className="export-modal__explanation">
            {I18n.t('admin.components.travel_expenses.export.explanation')}
          </div>
          <div className="col-12 direction-row export-modal__download">
            <div className="export-modal__download-simple-format">
              <FormatText
                frontIcon={<img src={csv} alt="excel-logo" />}
                label={I18n.t('admin.components.travel_expenses.export.csv_format')}
                itemUrl={sanitizeURL(urls.csvReportWithoutSlip, filterParams)}
              />
            </div>
          </div>
          <div className="col-12 direction-row export-modal__download">
            <div className="export-modal__download-simple-format">
              <FormatText
                frontIcon={<Icon size="normal" name="pdf" color="danger" />}
                label={I18n.t('admin.components.travel_expenses.export.pdf_with_slip')}
                itemUrl={sanitizeURL(urls.pdfWithSlips, filterParams)}
              />
            </div>
          </div>
          <div className="col-12 direction-row export-modal__download">
            <div className="export-modal__download-simple-format">
              <FormatText
                frontIcon={<Icon size="normal" name="pdf" color="danger" />}
                label={I18n.t('admin.components.travel_expenses.export.pdf_without_slip')}
                itemUrl={sanitizeURL(urls.pdfWithoutSlips, filterParams)}
              />
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

ExportModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  urls: PropTypes.shape({
    csvReportWithoutSlip: PropTypes.string.isRequired,
    pdfWithSlips: PropTypes.string.isRequired,
    pdfWithoutSlips: PropTypes.string.isRequired,
  }).isRequired,
  queryParams: PropTypes.shape().isRequired,
};

export default ExportModal;
