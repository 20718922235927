import { TYPE_SEAT } from 'sharedWebpack/helpers/itemTypes';

const getSegmentSeatsBySeatId = (items, seatIds) => {
  if (!Array.isArray(items)) {
    return [];
  }

  return items.filter(
    ({ type, journeyElementId }) => type === TYPE_SEAT && seatIds.includes(journeyElementId),
  );
};

export default getSegmentSeatsBySeatId;
