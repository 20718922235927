import React from 'react';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import SelectBox from '@wtag/rcl-select-box';
import RangeFilter from '../../../components/RangeFilter';
import FilterSection from '../../FilterSection';
import FilterTags from './FilterTags';

import './../styles.scss';

class FilterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterResetId: Math.random(),
      currentFilters: {
        categories: null,
        vendors: null,
        total_price: null,
      },
      isAllChecked: {
        categories: false,
        vendors: false,
      },
    };
  }

  resetFilters = () => {
    this.props.resetFilters(this.props.stats);
    this.setState({ filterResetId: Math.random() });
    this.setState({
      currentFilters: {
        categories: null,
        vendors: null,
      },
    });
    this.setState({
      isAllChecked: {
        categories: false,
        vendors: false,
      },
    });
  };

  resetTrip = key => {
    const newState = this.props.filterParams.price;

    const objectData = {
      changedData: newState,
      initialData: this.props.stats.price,
      key,
    };

    this.props.resetFiltersByKey(objectData);
    this.setState({ filterResetId: Math.random() });
  };

  resetData = section => {
    if (section === 'total_price') {
      this.resetTrip('total_price');
      this.setState({
        filterResetId: Math.random(),
        currentFilters: { ...this.state.currentFilters, [section]: null },
      });
    }

    this.setState({
      currentFilters: { ...this.state.currentFilters, [section]: null },
    });
  };

  selectGroup = group => {
    this.props.selectGroup(group).then(nextStep => this.doNextStep(nextStep));
  };

  resetSelectGroup = group => {
    this.props.resetSelectGroup(group).then(nextStep => this.doNextStep(nextStep));
  };

  render() {
    let totalPriceFilter;
    const stepValue = 0.001;

    if (this.props.results.length > 0) {
      totalPriceFilter = (
        <div className="car-results__total-price">
          <div className="car-filter__total-price">
            {I18n.t('components.ibe.search_results.filters.price')}
          </div>

          <RangeFilter
            key={this.state.filterResetId}
            className="price"
            min={this.props.stats.price.min}
            max={this.props.stats.price.max + stepValue}
            values={this.props.filterParams.price}
            step={stepValue}
            onChange={values => {
              const isPriceEqual =
                values.max.toFixed(2) === this.props.stats.price.max.toFixed(2) &&
                values.min.toFixed(2) === this.props.stats.price.min.toFixed(2);

              this.setState({
                currentFilters: {
                  ...this.state.currentFilters,
                  total_price: isPriceEqual ? null : 'Total Price',
                },
              });

              this.props.filterResults('price', values);
            }}
            formatLabel={value => <Amount currency={this.props.currency} value={value} />}
          />

          <div className="car-results__horizontal-line-main--total-price">
            <hr />
          </div>
        </div>
      );
    }

    const filters = (
      <div className="flight-results__holder">
        <Card>
          <div className="flight-results__title">
            <div className="flight-results__title--name">
              {I18n.t('components.ibe.search_results.filters.title')}
            </div>
            <Button
              label={I18n.t('components.ibe.search_results.filters.reset_flight')}
              size="small"
              onClick={this.resetFilters}
            />
          </div>
          <div>
            <FilterTags
              currentFilters={this.state.currentFilters}
              onRemoveFilter={section => {
                this.resetData(section);
              }}
            />
          </div>

          <div className="flight-results__sort">
            {I18n.t('components.ibe.search_results.filters.sort_by')}
            <SelectBox
              onChange={event => this.props.filterResults('sortBy', event.value)}
              key={this.state.filterResetId}
              options={this.props.stats.availableSortOptions}
              value={this.props.stats.availableSortOptions.find(
                option => option.value === this.props.filterParams.sortBy,
              )}
              width="full"
              isClearable={false}
            />
            <div className="flight-results__horizontal-line--main">
              <hr />
            </div>
          </div>

          <div className="results__filters">
            {totalPriceFilter}

            {this.props.stats.categories && (
              <FilterSection
                filterTitle={I18n.t('components.ibe.search_results.filters.categories')}
                allOptions={this.props.stats.categories}
                currentSelection={this.state.currentFilters.categories}
                isAllChecked={this.state.isAllChecked.categories}
                filterResults={this.props.filterResults}
                searchParameter="categories"
                selectedOptions={this.props.filterParams.categories}
                onSelect={values =>
                  this.setState({
                    currentFilters: { ...this.state.currentFilters, categories: values },
                  })
                }
                onAllChecked={values =>
                  this.setState({
                    isAllChecked: { ...this.state.isAllChecked, categories: values },
                  })
                }
              />
            )}

            {this.props.stats.vendors && (
              <FilterSection
                filterTitle={I18n.t('components.ibe.search_results.filters.vendors')}
                allOptions={this.props.stats.vendors}
                currentSelection={this.state.currentFilters.vendors}
                isAllChecked={this.state.isAllChecked.vendors}
                filterResults={this.props.filterResults}
                searchParameter="vendors"
                selectedOptions={this.props.filterParams.vendors}
                onSelect={values =>
                  this.setState({
                    currentFilters: { ...this.state.currentFilters, vendors: values },
                  })
                }
                onAllChecked={values =>
                  this.setState({
                    isAllChecked: { ...this.state.isAllChecked, vendors: values },
                  })
                }
                isDividerVisible={false}
              />
            )}
          </div>
        </Card>
      </div>
    );

    return <div>{filters}</div>;
  }
}

FilterComponent.defaultProps = {
  stats: null,
  filterParams: null,
};

FilterComponent.propTypes = {
  selectedFlightGroups: PropTypes.shape({}).isRequired,
  selectGroup: PropTypes.func.isRequired,
  resetSelectGroup: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  resetFiltersByKey: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    price: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    vendors: PropTypes.arrayOf(PropTypes.number).isRequired,
    availableSortOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  results: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filterParams: PropTypes.shape({
    price: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    vendors: PropTypes.arrayOf(PropTypes.number).isRequired,
    sortBy: PropTypes.string.isRequired,
  }),
  params: PropTypes.shape({
    searchId: PropTypes.string,
    tripId: PropTypes.string,
  }).isRequired,
  filterResults: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};

export default FilterComponent;
