import React from 'react';
import PropTypes from 'prop-types';

const ServiceLineItem = props => <div className={props.className}>{props.description}</div>;

ServiceLineItem.defaultProps = {
  className: null,
};

ServiceLineItem.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
};

export default ServiceLineItem;
