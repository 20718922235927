import React from 'react';
import PropTypes from 'prop-types';
import SelectBox from '@wtag/rcl-select-box';
import domOnlyProps from '../lib/domOnlyProps';
import findLabelValue from '../helpers/findLabelValue';

class NationalitySelectorContainer extends React.Component {
  componentDidMount() {
    this.props.fetchNationalities();
  }

  onNationalityChange = nationality => {
    const { onDocumentNationalityChange, ...other } = this.props;

    if (onDocumentNationalityChange) onDocumentNationalityChange(nationality);
    other.onChange(nationality);
  };

  mapNationality = () => {
    const { nationalities } = this.props;
    return (
      nationalities &&
      nationalities.map(country => ({
        value: country.value,
        label: country.nationality,
      }))
    );
  };

  render() {
    const { disabled, error, placeholder, required, title, ...other } = this.props;
    const errorText = (error && error[0]) || undefined;

    return (
      <SelectBox
        errorMsg={errorText}
        options={this.mapNationality()}
        label={title}
        disabled={disabled}
        {...domOnlyProps(other)}
        onChange={selectedOption =>
          selectedOption && this.onNationalityChange(selectedOption.value)
        }
        value={this.mapNationality() && findLabelValue(this.mapNationality(), other.value)}
        size="tiny"
        width="full"
        placeholderText={placeholder}
        required={required}
      />
    );
  }
}

NationalitySelectorContainer.defaultProps = {
  error: null,
  value: null,
  disabled: false,
  nationalities: null,
  required: true,
  placeholder: I18n.t('shared.placeholder.please_select'),
  onDocumentNationalityChange: () => {},
};

NationalitySelectorContainer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  fetchNationalities: PropTypes.func.isRequired,
  nationalities: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onCustomerSelected: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({}).isRequired,
  travelerIndex: PropTypes.number.isRequired,
  onDocumentNationalityChange: PropTypes.func,
};

export default NationalitySelectorContainer;
