import { hashHistory, createMemoryHistory } from 'react-router';

function evaluateHistory() {
  if (!hashHistory) {
    return createMemoryHistory();
  }
  return hashHistory;
}

export default evaluateHistory();
