import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../AppContext';
import RoomWithContext from './ActiveRoom';
import { AGENCY_NOTIFICATIONS } from './categories';

const AgencyNotificationsRoom = props => {
  const { handleRoomClick, isUserOnline, room, rooms } = props;

  useEffect(() => {
    const agencyRooms = rooms.filter(user => user.type === AGENCY_NOTIFICATIONS);
    handleRoomClick(agencyRooms[0]);
  }, []);

  return (
    <React.Fragment>
      {room && <RoomWithContext isUserOnline={isUserOnline(room)} room={room} />}
    </React.Fragment>
  );
};

AgencyNotificationsRoom.defaultProps = {
  room: {},
};

AgencyNotificationsRoom.propTypes = {
  isUserOnline: PropTypes.func.isRequired,
  room: PropTypes.shape(),
  handleRoomClick: PropTypes.func.isRequired,
  rooms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const AgencyNotificationsRoomWithContext = props => (
  <AppContext.Consumer>
    {context => <AgencyNotificationsRoom context={context} {...props} />}
  </AppContext.Consumer>
);

export default AgencyNotificationsRoomWithContext;
