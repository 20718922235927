/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Map from '@wtag/rcl-map';

const PNRMap = ({ products }) => {
  // geographical centre of all land surfaces on Earth: İskilip, Turkey
  const mapCenter = { latitude: 40.73528, longitude: 34.47389 };

  const hotelsCoordinates =
    products.hotels &&
    products.hotels.map(hotel => ({
      checkIn: hotel.checkIn,
      checkOut: hotel.checkOut,
      title: hotel.title,
      latitude: hotel.latitude,
      longitude: hotel.longitude,
    }));

  const carsCoordinates =
    products.cars &&
    products.cars.map(car => ({
      pickUp: {
        date: car.pick_up.localtime,
        latitude: car.pick_up.latitude,
        longitude: car.pick_up.longitude,
        title: car.pick_up.location,
      },
      dropOff: {
        date: car.drop_off.localtime,
        latitude: car.drop_off.latitude,
        longitude: car.drop_off.longitude,
        title: car.drop_off.location,
      },
    }));

  const flightsCoordinates =
    products.flights &&
    products.flights.map(flight => ({
      start: {
        date: flight.startDate,
        latitude: flight.startLatitude.latitude,
        longitude: flight.startLongitude.longitude,
        title: flight.startTitle.name,
      },
      stop: {
        date: flight.stopDate,
        latitude: flight.stopLatitude.latitude,
        longitude: flight.stopLongitude.longitude,
        title: flight.stopTitle.name,
      },
    }));

  return (
    <Map
      uniqueKey="multiple-items-map"
      mapCenter={mapCenter}
      cruises={null}
      cars={carsCoordinates.length > 0 ? carsCoordinates : null}
      hotels={hotelsCoordinates.length > 0 ? hotelsCoordinates : null}
      events={null}
      transfers={null}
      flights={flightsCoordinates.length > 0 ? flightsCoordinates : null}
      zoomLevel={1}
    />
  );
};

PNRMap.propTypes = {
  pnr: PropTypes.shape({
    products: PropTypes.shape({
      flights: PropTypes.arrayOf(),
      hotels: PropTypes.arrayOf(),
      cars: PropTypes.arrayOf(),
    }),
  }).isRequired,
};

export default PNRMap;
