import toCamelCase from 'to-camel-case';
import {
  CASH_FORM_OF_PAYMENT,
  CREDIT_CARD_FORM_OF_PAYMENT,
  IATA_FORM_OF_PAYMENT,
  NONE_FORM_OF_PAYMENT,
  WALLET_FORM_OF_PAYMENT,
} from '../../lib/helpers/fop_options';

const getFopItems = (availableFormOfPayment, laymanMode, creditCards) => {
  const supportedPayments = [];
  const CamelCasedCreditCard = toCamelCase(CREDIT_CARD_FORM_OF_PAYMENT);
  const CamelCasedWallet = toCamelCase(WALLET_FORM_OF_PAYMENT);

  const pushSupportedCreditCards = fop => {
    if (availableFormOfPayment[fop].supported) {
      const supportedCards = availableFormOfPayment.creditCard.supportedCards.map(
        supportedCard => supportedCard.cardType,
      );

      Object.values(creditCards).forEach(creditCard => {
        if (supportedCards.includes(creditCard.cardType)) {
          supportedPayments.push({
            type: creditCard.cardType,
            card: creditCard,
          });
        }
      });
    }
  };

  const pushSupportedFops = fop => {
    if (availableFormOfPayment[fop].supported) {
      supportedPayments.push({
        type: fop === CamelCasedWallet ? WALLET_FORM_OF_PAYMENT : fop,
        card: null,
      });
    }
  };

  Object.keys(availableFormOfPayment).forEach(fop => {
    if (laymanMode) {
      switch (fop) {
        case CASH_FORM_OF_PAYMENT:
          supportedPayments.push({ type: CASH_FORM_OF_PAYMENT, card: null });
          break;

        case CamelCasedCreditCard:
          pushSupportedCreditCards(fop);
          break;

        default:
      }
    } else {
      switch (fop) {
        case CASH_FORM_OF_PAYMENT:
          pushSupportedFops(fop);
          break;

        case IATA_FORM_OF_PAYMENT:
          pushSupportedFops(fop);
          break;
        case NONE_FORM_OF_PAYMENT:
          pushSupportedFops(fop);
          break;
        case CamelCasedCreditCard:
          pushSupportedCreditCards(fop);
          break;
        case CamelCasedWallet:
          pushSupportedFops(fop);
          break;
        default:
      }
    }
  });

  return supportedPayments;
};

export default getFopItems;
