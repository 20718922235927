import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { AnimatedProgressbar, DateTime } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Map from '@wtag/rcl-map';
import calculateCenterCoordinates from 'sharedWebpack/helpers/calculateCenterCoordinates';
import calculateZoomLevel from 'sharedWebpack/helpers/calculateZoomLevel';
import getTripType from '../../../../lib/helpers/getTripType';
import { MULTICITY, ROUNDTRIP } from '../../../../lib/helpers/tripTypes';
import './style.scss';

const LoadingInformationCard = ({ searchInformation, searchCompleted, airports, expand }) => {
  const { trips, adults, children, infants } = searchInformation;
  const firstAirport = trips[0] && airports[trips[0].origin];
  const [firstTrip] = trips;
  const lastTrip = trips[trips.length - 1];
  const updatedTrips = firstTrip && [
    { originCode: firstTrip.origin, destinationCode: firstTrip.destination },
  ];
  if (trips.length > 1) {
    updatedTrips.push({
      originCode: lastTrip.origin,
      destinationCode: lastTrip.destination,
    });
  }
  const tripType = trips.length > 2 ? MULTICITY : getTripType(updatedTrips);
  const lastAirport =
    tripType && airports[tripType === ROUNDTRIP ? lastTrip.origin : lastTrip.destination];
  const departureDate = trips[0] && trips[0].date;
  const cabinClass = trips[0] && trips[0].cabinClass;

  const mapCoordinates =
    airports &&
    trips &&
    trips.map(trip => {
      const segmentOriginCoordinations = airports[trip.origin];
      const segmentDestinationCoordinations = airports[trip.destination];
      if (segmentOriginCoordinations && segmentDestinationCoordinations) {
        return {
          start: {
            date: trip.date,
            latitude: segmentOriginCoordinations.latitude,
            longitude: segmentOriginCoordinations.longitude,
            title: segmentOriginCoordinations.name,
          },
          stop: {
            date: trip.date,
            latitude: segmentDestinationCoordinations.latitude,
            longitude: segmentDestinationCoordinations.longitude,
            title: segmentDestinationCoordinations.name,
          },
        };
      }
      return null;
    });

  const numberOfTravelers = adults + children + infants;

  return (
    <div className="col-12 col-bleed loading-information-card">
      <Card version="v2" size="full">
        {expand && (
          <Fragment>
            {!searchCompleted && (
              <div className="loading-information-card__animation-bar">
                <div className="loading-information-card__animation-bar-text">
                  {I18n.t('components.ibe.search_loading.hotel.searching_for_best_options')}
                </div>
                <AnimatedProgressbar
                  size="small"
                  bgColor="success"
                  animationSpeed={450000}
                  initialyCompleted={95}
                  textMessages={[
                    I18n.t('components.ibe.search_loading.search_loading_text.request_flight'),
                    I18n.t('components.ibe.search_loading.search_loading_text.check'),
                    I18n.t('components.ibe.search_loading.search_loading_text.fetch'),
                    I18n.t('components.ibe.search_loading.search_loading_text.prepare'),
                  ]}
                  completedMessage="Completed"
                />
              </div>
            )}
            <div className="col-12 col-bleed col-grid align-center loading-information-card__content">
              <div className="col-lg-6 col-md-6 col-12 col-bleed loading-information-card__content-info">
                <div className="loading-information-card__content-info-text">
                  {I18n.t(tripType, {
                    scope: 'components.ibe.search_loading.flight.searched_for',
                  })}
                </div>
                {firstAirport && lastAirport && (
                  <div className="loading-information-card__content-info-address">
                    <span>
                      {I18n.t('components.ibe.search_loading.flight.journey_title', {
                        origin: `${firstAirport.placeName}, ${firstAirport.countryName}`,
                        destination: `${lastAirport.placeName}, ${lastAirport.countryName}`,
                      })}
                    </span>
                  </div>
                )}
                <div className="loading-information-card__content-info-duration">
                  <div className="loading-information-card__content-info-duration-time">
                    <div className="loading-information-card__content-info-duration-time-text">
                      {I18n.t('components.ibe.search_loading.flight.departure')}:
                    </div>
                    <div className="loading-information-card__content-info-duration-time-value">
                      <DateTime dateTime={departureDate} format="short" />
                    </div>
                  </div>
                </div>
                <div className="loading-information-card__content-info-room-details">
                  {I18n.t('components.ibe.search_loading.hotel.travelers_count', {
                    count: numberOfTravelers,
                  })}
                  {`  |  ${I18n.t(cabinClass, {
                    scope: 'cabin_classes',
                  })}`}
                </div>
                <div className="loading-information-card__content-info-message">
                  <div className="loading-information-card__header-info-icon">
                    <Icon name="loading" showBGColor={true} size="normal" color="success" />
                  </div>
                  <div className="loading-information-card__content-info-message-text">
                    <div className="loading-information-card__content-info-message-text-title">
                      {I18n.t('components.ibe.search_loading.search_loading_text.hold_tight')}
                    </div>
                    <div className="loading-information-card__content-info-message-text-data">
                      {I18n.t('components.ibe.search_loading.search_loading_text.superior_search')}
                    </div>
                  </div>
                </div>
              </div>
              {mapCoordinates[0] && (
                <div className="col-lg-6 col-md-6 col-12 col-bleed loading-information-card__content-map">
                  <Map
                    uniqueKey={`flight-${departureDate}-${cabinClass}`}
                    markerSize={{ height: 50, width: 50 }}
                    mapCenter={calculateCenterCoordinates(mapCoordinates)}
                    flights={mapCoordinates}
                    zoomLevel={calculateZoomLevel(mapCoordinates)}
                  />
                </div>
              )}
            </div>
          </Fragment>
        )}
      </Card>
    </div>
  );
};

LoadingInformationCard.defaultProps = {
  airports: [],
};

LoadingInformationCard.propTypes = {
  searchInformation: PropTypes.shape({
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        origin: PropTypes.string.isRequired,
        destination: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        cabinClass: PropTypes.string.isRequired,
      }),
    ).isRequired,
    adults: PropTypes.number.isRequired,
    children: PropTypes.number.isRequired,
    infants: PropTypes.number.isRequired,
  }).isRequired,
  searchCompleted: PropTypes.bool.isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      placeName: PropTypes.string.isRequired,
      countryFlag: PropTypes.string.isRequired,
      countryName: PropTypes.string.isRequired,
    }),
  ),
  expand: PropTypes.bool.isRequired,
};

export default LoadingInformationCard;
