import React from 'react';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';
import Modal from 'sharedWebpack/Modal';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Alert from 'sharedWebpack/Alert';
import Progress from '../../components/Progress';

const ExchangeQuote = props => {
  const fetchingExchangeQuote = () => (
    <Progress title={I18n.t('components.ibe.exchange_quote.fetching.title')} withCard={false}>
      {I18n.t('components.ibe.exchange_quote.fetching.text')}
    </Progress>
  );

  const exchangeQuoteFetched = (quote, currentPrice, confirmExchangeQuote) => {
    const noPenaltyPrice = quote.pricing.totalPriceWithoutPenalty.recommendedSellingPrice;
    const penalty = quote.pricing.penalty;
    const withPenaltyPrice = quote.pricing.totalPriceWithPenalty.recommendedSellingPrice;

    return (
      <div className="col-12">
        <table className="exchange-quote-table">
          <tbody>
            <tr>
              <th>{I18n.t('components.ibe.exchange_quote.old_price')}</th>
              <td>
                <Amount currency={currentPrice.net.currency} value={currentPrice.net.amount} />
              </td>
            </tr>
            <tr>
              <th>{I18n.t('components.ibe.exchange_quote.price.no_penalty')}</th>
              <td>
                <Amount currency={noPenaltyPrice.currency} value={noPenaltyPrice.amount} />
              </td>
            </tr>
            <tr>
              <th>{I18n.t('components.ibe.exchange_quote.price.penalty')}</th>
              <td>
                <Amount currency={penalty.currency} value={penalty.amount} />
              </td>
            </tr>
            <tr>
              <th>{I18n.t('components.ibe.exchange_quote.price.with_penalty')}</th>
              <td>
                <Amount currency={withPenaltyPrice.currency} value={withPenaltyPrice.amount} />
              </td>
            </tr>
          </tbody>
        </table>
        <Button
          size="small"
          label={I18n.t('components.ibe.exchange_quote.confirm')}
          primary={true}
          onClick={confirmExchangeQuote}
        />
      </div>
    );
  };

  const fetchingFailed = () => (
    <div className="col-12">
      <Alert hideClose={true} type="danger" isVisible={true}>
        {I18n.t('components.ibe.exchange_quote.fetching_failed.text')}
      </Alert>
    </div>
  );

  const confirmingExchangeQuote = () => (
    <Progress title={I18n.t('components.ibe.exchange_quote.confirming.title')} withCard={false}>
      {I18n.t('components.ibe.exchange_quote.confirming.text')}
    </Progress>
  );

  const exchangeQuoteConfirmed = () => (
    <Progress title={I18n.t('components.ibe.exchange_quote.confirmed.title')} withCard={false}>
      {I18n.t('components.ibe.exchange_quote.confirmed.text')}
    </Progress>
  );

  const confirmationFailed = () => (
    <Alert hideClose={true} type="danger" isVisible={true}>
      {I18n.t('components.ibe.exchange_quote.confirmation_failed.text')}
    </Alert>
  );

  let content;
  switch (props.status) {
    case 'fetching':
      content = fetchingExchangeQuote();
      break;

    case 'fetched':
      content = exchangeQuoteFetched(props.quote, props.currentPrice, props.confirmExchangeQuote);
      break;

    case 'fetchingFailed':
      content = fetchingFailed();
      break;

    case 'confirming':
      content = confirmingExchangeQuote();
      break;

    case 'confirmed':
      content = exchangeQuoteConfirmed();
      break;

    case 'confirmationFailed':
      content = confirmationFailed();
      break;

    default:
      content = null;
      break;
  }

  return (
    <Modal
      key={props.open}
      isModalVisible={props.open}
      onOutsideClick={props.onModalClose}
      title={
        <div className="exchange-quote-modal__title">
          <div className="exchange-quote-modal__header">
            {I18n.t('components.ibe.exchange_quote.title')}
            <span className="exchange-quote-modal__subheader">
              {I18n.t('components.ibe.exchange_quote.intro')}
            </span>
          </div>
          <div
            className="exchange-quote-modal__title--close-icon"
            onClick={props.onModalClose}
            onKeyDown={props.onModalClose}
            role="button"
            tabIndex={0}
          >
            <Icon name="close" />
          </div>
        </div>
      }
      alignContent="left"
    >
      {content}
    </Modal>
  );
};

ExchangeQuote.defaultProps = {
  quote: null,
  currentPrice: null,
  status: null,
};

ExchangeQuote.propTypes = {
  open: PropTypes.bool.isRequired,
  quote: PropTypes.shape({
    pricing: PropTypes.shape({
      totalPriceWithoutPenalty: PropTypes.shape({
        recommendedSellingPrice: PropTypes.shape({
          amount: PropTypes.string.isRequired,
          currency: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      penalty: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
      totalPriceWithPenalty: PropTypes.shape({
        recommendedSellingPrice: PropTypes.shape({
          amount: PropTypes.string.isRequired,
          currency: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }),
  currentPrice: PropTypes.shape({
    net: PropTypes.shape({
      amount: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
  }),
  confirmExchangeQuote: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  status: PropTypes.oneOf([
    null,
    'fetching',
    'fetched',
    'fetchingFailed',
    'confirming',
    'confirmed',
    'confirmationFailed',
  ]),
};

export default ExchangeQuote;
