import PayFastForm from '../gateway/PayFastForm';
import PayGateForm from '../gateway/PayGateForm';
import PostFinanceForm from '../gateway/PostFinance';

export const PAYMENT_PROVIDER_FORM_MAPPING = {
  pay_fast: PayFastForm,
  pay_gate: PayGateForm,
  post_finance: PostFinanceForm,
};

export const ORDER_STYLES = {
  NEW: 'new',
  EXISTING: 'existing',
};
export const BOOKING_STYLES = {
  QUOTE: 'quote',
  OPTION: 'option',
  REAL: 'real',
};

export const PROCEDURE_MAPPING = {
  [ORDER_STYLES.NEW]: {
    [BOOKING_STYLES.QUOTE]: 'createOrderAsQuote',
    [BOOKING_STYLES.OPTION]: 'createOption',
    [BOOKING_STYLES.REAL]: 'createOrder',
  },
  [ORDER_STYLES.EXISTING]: {
    [BOOKING_STYLES.QUOTE]: 'attachToOrderAsQuote',
    [BOOKING_STYLES.OPTION]: 'attachToOrderAsOption',
    [BOOKING_STYLES.REAL]: 'attachToOrder',
  },
};

const CREDIT_CARD_FOP = 'credit_card';

export const cartItemsWithCreditCard = cartItems =>
  cartItems.filter(
    cartItem => cartItem.formOfPayment && cartItem.formOfPayment.type === CREDIT_CARD_FOP,
  );

export const matchCCTokenWithItems = (cartItems, ccToken) =>
  cartItems.filter(cartItem => cartItem.formOfPayment.cardDetails.token === ccToken);

export const cartHasItemWithCreditCard = cartItems => {
  if (cartItems.some(cartItem => Object.keys(cartItem).length === 0)) {
    return false;
  }

  return cartItemsWithCreditCard(cartItems).length > 0;
};

export const getEncodedBookingRequests = itemsFromCCToken => {
  const encodedBookingRequests = itemsFromCCToken.map(item => item.encodedPaymentRequestPayload);

  return encodedBookingRequests;
};
