import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link as ButtonLink } from '@wtag/react-comp-lib';
import StackedItems from '@wtag/rcl-stacked-items';
import Button from '@wtag/rcl-button';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import { fetchFlightSearchResults, filterFlightSearch } from 'sharedWebpack/IBE/actions/flight';
import Badge from '@wtag/rcl-badge';
import { Header } from '@wtag/rcl-typography';
import { Link } from 'react-router-dom';
import Tooltip from '@wtag/rcl-tooltip';
import memoizeLocationQuery from '../helpers/memoizeLocationQuery';
import { fetchAirportDetails, resetApp } from '../actions/common';
import HotelForm from './hotel/SearchFormContainer';
import CarForm from './car/SearchFormContainer';
import FlightForm from './flight';
import StaticProductContainer from './static/products/StaticProductContainer';
import ImageSlider from '../../../public/shared/ImageSlider';
import history from '../lib/history';
import searchURL from '../lib/helpers/searchURL';
import { stringifyQueryString } from '../helpers/qsMethods';
import accountTypeAvailable from '../lib/accountTypeAvailable';
import SearchFormTab from './SearchFormTab';
import SearchFormTabTitle from './SearchFormTabTitle';
import usePropState from '../../usePropState';
import { MULTICITY, ROUNDTRIP } from '../lib/helpers/tripTypes';
import FlightSearchLoadingScreen from '../SearchResult/flight/SearchLoadingScreen';
import CarSearchLoadingScreen from '../SearchResult/car/new/SearchLoadingScreen';
import HotelSearchLoadingScreen from '../SearchResult/hotel/SearchLoadingScreen';
import { TYPE_STANDARD } from '../../helpers/viewType';
import SearchSubHeader from './searchSubHeader';
import CarSearchSubHeader from './car/CarSearchSubHeader';
import { SORT_BY_PRICE_LOWEST } from '../../helpers/flightSortTypes';
import FlightSearchHeader from './SearchHeader';
import CarSearchHeader from './car/CarSearchHeader';
import HotelSearchHeader from './HotelSearchHeader';
import { updatePreselectTravelers as updatePreselectTravelersStore } from '../actions/common/preselectTraveler';
import {
  FLIGHT_SEARCH_TAB,
  CAR_SEARCH_TAB,
  HOTEL_SEARCH_TAB,
  STATIC_PRODUCT_SEARCH_TAB,
} from '../helpers/searchTabIndices';
import AirportShape from './shapes/AirportShape';
import './styles.scss';

const Search = props => {
  const {
    affiliatesUrl,
    searchAccounts,
    resetAppForm,
    flightSearchParams,
    hotelSearchParams,
    carSearchParams,
    carLastSearchedParams,
    totalResultsCount,
    totalResultsPerSupplier,
    cartId,
    cartItemsCount,
    setShowBottomComponents,
    searchParamsId,
    resetBookingAppContext,
    exchangeEnabled,
    match,
    laymanMode,
    location,
    forceAdvancedSearch,
    travelArrangerPresent,
    travelerLoggedIn,
    isSearchCompleted,
    groupOfSelectedFlights,
    showContinueButton,
    isMatrixPage,
    wtsEnabled,
    canExcludeWtsContent,
    lastSearchedParams,
    viewMode,
    updateViewMode,
    sortBy,
    sortFlightSearch,
    callbackParams,
    preselectDecision,
    updatePreselectTravelers,
    hotelLastSearchedParams,
    isHotelDetailsPage,
    airports,
    onFetchAirportDetails,
  } = props;
  const [expanded, setExpanded] = useState(isSearchCompleted);
  const [selectAll, setSelectAll] = useState(true);
  const [selectGds, setSelectGds] = useState(true);
  const [selectDirect, setSelectDirect] = useState(true);
  const [currentTab, setCurrentTab] = useState(FLIGHT_SEARCH_TAB);
  const [advancedForm, setAdvancedForm] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isMultiCitySelected, setIsMultiCitySelected] = useState(false);
  const [isFromLastSearch, setIsFromLastSearch] = useState(false);
  const [tripType, setTripType] = usePropState(ROUNDTRIP);

  const currentPath = `/${match.path.split('/')[1]}`;

  const tabToFormMapper = ['/flights', '/hotels', '/cars', '/static'];

  const tabToLastSearchMapper = ['flight', 'hotel', 'car'];

  const formType = tabToLastSearchMapper[currentTab];

  const formWithoutChildren = props.children === null;

  const isSearchSubHeaderDisplayed =
    currentTab === FLIGHT_SEARCH_TAB || (currentTab === HOTEL_SEARCH_TAB && !isHotelDetailsPage);

  const openCorrectForm = tab => {
    history.push(tabToFormMapper[tab]);
  };

  const toggleSelectAll = () => {
    setSelectAll(prevState => !prevState);
  };

  const toggleSelectGds = () => {
    setSelectGds(prevState => !prevState);
  };

  const toggleSelectDirect = () => {
    setSelectDirect(prevState => !prevState);
  };

  const isFlightSearchParamsAvailable =
    flightSearchParams && flightSearchParams.trips && flightSearchParams.trips.length > 0;
  const isHotelSearchParamsAvailable = Object.keys(hotelSearchParams).length > 0;

  const isProductEnabled = type => {
    if (exchangeEnabled) return true; // This is done in case we need to exchange an order item and want the search forms to be available.
    return !props.laymanMode || accountTypeAvailable(props.availableChannels, type);
  };

  const flightChannelsAvailable = isProductEnabled('flights');
  const hotelChannelsAvailable = isProductEnabled('hotels');
  const carChannelsAvailable = isProductEnabled('cars');

  const productsAvailableToSearch =
    (!props.laymanMode && wtsEnabled) ||
    accountTypeAvailable(props.availableChannels, 'flights') ||
    accountTypeAvailable(props.availableChannels, 'hotels') ||
    accountTypeAvailable(props.availableChannels, 'cars') ||
    props.hasStaticProducts;

  const noProductsAvailableCard = (
    <div
      className={classnames('col-grid align-center justify-center no-products__container', {
        'no-products__container--not-layman-mode': !laymanMode,
      })}
    >
      <StackedItems itemSize="large">
        <Icon name="aeroplane" showBGColor={true} color="tertiary" />
        <Icon name="hotelRoom" showBGColor={true} color="tertiary" />
        <Icon name="car" showBGColor={true} color="tertiary" />
      </StackedItems>
      <Header level={5} weight="bold" className="no-products__container-header">
        {exchangeEnabled
          ? I18n.t('components.ibe.search_form.exchange_unavailable_header')
          : I18n.t('components.ibe.search_form.search_unavailable_header')}
      </Header>
      {!laymanMode && (
        <div className="no-products__container-message">
          {exchangeEnabled
            ? I18n.t('components.ibe.search_form.exchange_unavailable_subheader')
            : I18n.t('components.ibe.search_form.search_unavailable_subheader')}
        </div>
      )}
      {!laymanMode && (
        <ButtonLink
          href={affiliatesUrl}
          type="button"
          modifier="primary"
          size="small"
          className="no-products__container-button"
          openNewTab={true}
        >
          {I18n.t('components.ibe.search_form.add_credentials')}
        </ButtonLink>
      )}
    </div>
  );

  const totalOptionsPerSupplier = totalResultsPerSupplier.map(supplier => (
    <span className="col-grid col-bleed direction-column">
      <div className="col-grid col-bleed direction-row search-bar__total-supplier">
        <span className="search-bar__total-supplier-name">
          {I18n.t(supplier.key, { scope: 'channels' })}
        </span>
        <span className="search-bar__total-supplier-count">
          {`: ${supplier.docCount} ${I18n.t('components.ibe.search_form.label.options')}`}
        </span>
      </div>
    </span>
  ));

  const totalOptionsFound = I18n.t('components.ibe.search_form.label.total_results', {
    count: totalResultsCount,
  });

  const flightForm = {
    key: 0,
    tabNum: 0,
    url: '/flights',
    title: (
      <SearchFormTabTitle
        iconName="aeroplane"
        title={I18n.t('components.ibe.search_form.flight.label.flights')}
        laymanMode={laymanMode}
      />
    ),
    getContent: () => (
      <div className="search-container__form">
        <FlightForm
          forceAdvancedSearch={forceAdvancedSearch}
          searchParams={flightSearchParams}
          selectAll={selectAll}
          toggleSelectAll={toggleSelectAll}
          selectGds={selectGds}
          toggleSelectGds={toggleSelectGds}
          selectDirect={selectDirect}
          toggleSelectDirect={toggleSelectDirect}
          setAdvancedForm={setAdvancedForm}
          advancedForm={advancedForm}
          resetApp={resetAppForm}
          setIsCollapsed={() => setIsCollapsed(true)}
          formWithoutChildren={formWithoutChildren}
          resetBookingAppContext={resetBookingAppContext}
          travelArrangerPresent={travelArrangerPresent}
          travelerLoggedIn={travelerLoggedIn}
          laymanMode={laymanMode}
          isMultiCitySelected={isMultiCitySelected}
          setIsMultiCitySelected={setIsMultiCitySelected}
          wtsEnabled={wtsEnabled}
          tripType={tripType}
          setTripType={setTripType}
          isFromLastSearch={isFromLastSearch}
          setIsFromLastSearch={setIsFromLastSearch}
          canExcludeWtsContent={canExcludeWtsContent}
          callbackParams={callbackParams}
          preselectDecision={preselectDecision}
          updatePreselectTravelers={updatePreselectTravelers}
        />
      </div>
    ),
  };

  const hotelForm = {
    key: 1,
    tabNum: 1,
    url: '/hotels',
    title: (
      <SearchFormTabTitle
        iconName="hotelRoom"
        title={I18n.t('components.ibe.search_form.flight.label.hotels')}
        laymanMode={laymanMode}
      />
    ),
    getContent: () => (
      <div className="search-container__form">
        <HotelForm
          forceAdvancedSearch={forceAdvancedSearch}
          selectAll={selectAll}
          toggleSelectAll={toggleSelectAll}
          selectGds={selectGds}
          toggleSelectGds={toggleSelectGds}
          selectDirect={selectDirect}
          toggleSelectDirect={toggleSelectDirect}
          searchParams={hotelSearchParams}
          setIsCollapsed={() => setIsCollapsed(true)}
          formWithoutChildren={formWithoutChildren}
          resetBookingAppContext={resetBookingAppContext}
          travelArrangerPresent={travelArrangerPresent}
          travelerLoggedIn={travelerLoggedIn}
          callbackParams={callbackParams}
          preselectDecision={preselectDecision}
          updatePreselectTravelers={updatePreselectTravelers}
        />
      </div>
    ),
  };

  const carForm = {
    key: 2,
    tabNum: 2,
    url: '/cars',
    title: (
      <SearchFormTabTitle
        iconName="car"
        title={I18n.t('components.ibe.search_form.car.label.cars')}
        laymanMode={laymanMode}
      />
    ),
    getContent: () => (
      <div className="search-container__form">
        <CarForm
          forceAdvancedSearch={forceAdvancedSearch}
          selectAll={selectAll}
          toggleSelectAll={toggleSelectAll}
          selectGds={selectGds}
          toggleSelectGds={toggleSelectGds}
          selectDirect={selectDirect}
          toggleSelectDirect={toggleSelectDirect}
          searchParams={carSearchParams}
          setIsCollapsed={() => setIsCollapsed(true)}
          formWithoutChildren={formWithoutChildren}
          resetBookingAppContext={resetBookingAppContext}
          travelArrangerPresent={travelArrangerPresent}
          travelerLoggedIn={travelerLoggedIn}
          callbackParams={callbackParams}
          preselectDecision={preselectDecision}
          updatePreselectTravelers={updatePreselectTravelers}
        />
      </div>
    ),
  };

  const staticProductsForm = {
    key: 3,
    tabNum: 3,
    url: '/static',
    title: (
      <SearchFormTabTitle
        iconName="equipment"
        title={I18n.t('components.ibe.search_form.others')}
        laymanMode={laymanMode}
      />
    ),
    getContent: () => (
      <div className="search-container__form">
        <StaticProductContainer
          setAdvancedForm={setAdvancedForm}
          advancedForm={advancedForm}
          laymanMode={laymanMode}
          setOnSearchStarted={props.setOnSearchStarted}
          callbackParams={props.callbackParams}
        />
      </div>
    ),
  };

  const tabs = [];

  if (flightChannelsAvailable) {
    tabs.push(flightForm);
  }
  if (hotelChannelsAvailable) {
    tabs.push(hotelForm);
  }
  if (carChannelsAvailable) {
    tabs.push(carForm);
  }
  if (!exchangeEnabled && props.hasStaticProducts) {
    tabs.push(staticProductsForm);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const currentTabIndex = tabToFormMapper.indexOf(currentPath);

    // Checking if static product is missing or not after page refresh. If missing it will render to the flight form.
    setCurrentTab(
      !props.hasStaticProducts && currentTabIndex === STATIC_PRODUCT_SEARCH_TAB
        ? FLIGHT_SEARCH_TAB
        : currentTabIndex,
    );
    setShowBottomComponents(true);
  }, [props.hasStaticProducts]);

  useEffect(() => {
    setAdvancedForm(!props.laymanMode || props.forceAdvancedSearch);
  }, [props.laymanMode, props.forceAdvancedSearch]);

  useEffect(() => {
    if (tripType === MULTICITY) setAdvancedForm(true);
  }, [tripType]);

  useEffect(() => {
    if (!advancedForm) setIsMultiCitySelected(false);
  }, [advancedForm]);

  useEffect(() => {
    if (formWithoutChildren) {
      props.setOnSearchStarted(false);
    } else {
      props.setOnSearchStarted(true);
    }
  }, [props.children]);

  useEffect(() => {
    setExpanded(!isSearchCompleted);
  }, [isSearchCompleted]);

  const onCartClick = () => {
    setShowBottomComponents(false);
    history.push(`/carts/${cartId}`);
  };

  const groupOfSelectedFlightsKeys = groupOfSelectedFlights && Object.keys(groupOfSelectedFlights);

  const goToMatrixPage = () => {
    const selectedFlights = {};
    groupOfSelectedFlightsKeys.forEach(flightGroupKey => {
      selectedFlights[flightGroupKey] = groupOfSelectedFlights[flightGroupKey].id;
    });

    history.push(
      `/flights/searches/${searchParamsId}/matrix${stringifyQueryString(
        {
          flightGroups: selectedFlights,
        },
        { arrayFormat: 'repeat' },
      )}`,
    );
  };

  let searchResultHeader;

  switch (currentTab) {
    case FLIGHT_SEARCH_TAB:
      searchResultHeader = (
        <div className="flight-search">
          {isFlightSearchParamsAvailable && (
            <FlightSearchHeader
              isCollapsed={isCollapsed}
              onModifySearch={setIsCollapsed}
              flightSearchParams={flightSearchParams}
              expand={expanded}
              onExpand={setExpanded}
              cartId={cartId}
              cartItemsCount={cartItemsCount}
              onCartClick={onCartClick}
              showContinueButton={showContinueButton}
              onMatrixPageRender={goToMatrixPage}
            />
          )}
        </div>
      );
      break;

    case HOTEL_SEARCH_TAB:
      searchResultHeader = (
        <div className="flight-search">
          {isHotelSearchParamsAvailable && (
            <HotelSearchHeader
              isCollapsed={isCollapsed}
              onModifySearch={setIsCollapsed}
              hotelSearchParams={hotelSearchParams}
              expand={expanded}
              onExpand={setExpanded}
              cartId={cartId}
              cartItemsCount={cartItemsCount}
              onCartClick={onCartClick}
              isHotelDetailsPage={isHotelDetailsPage}
            />
          )}
        </div>
      );
      break;

    case CAR_SEARCH_TAB:
      searchResultHeader = (
        <div className="car-search">
          <CarSearchHeader
            isCollapsed={isCollapsed}
            onModifySearch={setIsCollapsed}
            searchParams={carSearchParams}
            expand={expanded}
            onExpand={setExpanded}
            cartId={cartId}
            cartItemsCount={cartItemsCount}
            onCartClick={onCartClick}
            showContinueButton={showContinueButton}
            onMatrixPageRender={goToMatrixPage}
            airports={airports}
            onFetchAirportDetails={onFetchAirportDetails}
          />
        </div>
      );
      break;

    default:
      searchResultHeader = (
        <div className="search-bar__container">
          {isSearchCompleted ? (
            <div className="search-bar__total">
              <span className="search-bar__total-results">
                {totalResultsCount > 0 && (
                  <Link to={searchURL(formType, searchParamsId)}>{totalOptionsFound}</Link>
                )}
              </span>
              <span className="search-bar__total-icon">
                {!laymanMode && totalOptionsPerSupplier.length > 0 && (
                  <Tooltip content={totalOptionsPerSupplier} position="bottom-left" type="inverse">
                    <Icon name="invalidOutline" showBGColor={false} size="tiny" />
                  </Tooltip>
                )}
              </span>
            </div>
          ) : (
            <span className="search-bar__searching">
              {I18n.t('components.ibe.search_form.label.searching')}
            </span>
          )}
          <div className="search__action">
            <Button
              onClick={() => setIsCollapsed(prev => !prev)}
              version="v2"
              size="tiny"
              label={
                isCollapsed
                  ? I18n.t('components.ibe.search_form.label.modify_search')
                  : I18n.t('components.ibe.search_form.label.hide_search')
              }
            />
            {showContinueButton && (
              <Button
                className="search__action--add-margin-left-margin-only"
                version="v2"
                size="tiny"
                label={I18n.t('components.ibe.search_form.label.continue')}
                type="primary"
                onClick={goToMatrixPage}
              />
            )}
            <div className="search__action--add-margin-left">
              <IconButton
                icon={
                  <Fragment>
                    <Icon name="shoppingCart" size="normal" />
                    {cartId && cartItemsCount > 0 && (
                      <Badge
                        label={cartItemsCount}
                        size="small"
                        type="active"
                        classNameForBadge="search__cart-button"
                      />
                    )}
                  </Fragment>
                }
                label={I18n.t('components.ibe.search_form.label.cart')}
                onClick={onCartClick}
                disabled={!cartId}
                version="v2"
              />
            </div>
          </div>
        </div>
      );
  }

  let form;
  if (formWithoutChildren) {
    form = (
      <div
        className={classnames('grid search-panel', {
          container: laymanMode,
        })}
      >
        {!laymanMode && (
          <div className="container-full">
            <div className="container-full col-12 col-bleed-x search-container__title">
              <SearchFormTab
                tabs={tabs}
                currentTab={currentTab}
                openCorrectForm={openCorrectForm}
                showInkBar={true}
                setCurrentTab={setCurrentTab}
                setShowBottomComponents={setShowBottomComponents}
                match={match}
                laymanMode={laymanMode}
                forceAdvancedSearch={forceAdvancedSearch}
                setAdvancedForm={setAdvancedForm}
                formType={formType}
                searchAccounts={searchAccounts}
                callbackParams={props.callbackParams}
                iframeMode={props.iframeMode}
                travelerLoggedIn={props.travelerLoggedIn}
                cartId={cartId}
                cartItemsCount={cartItemsCount}
                setIsMultiCitySelected={setIsMultiCitySelected}
                setIsFromLastSearch={setIsFromLastSearch}
              />
            </div>
          </div>
        )}
        <div
          className={classnames('search', {
            'search-traveler': laymanMode,
            'container-full': !laymanMode,
            'container container--sm-full-width': laymanMode,
          })}
        >
          <div
            className={classnames('col-bleed', {
              'col-12 col-md-8 col-lg-6 animated-container': laymanMode,
            })}
          >
            <div
              className={classnames({
                'search-container': currentTab === STATIC_PRODUCT_SEARCH_TAB || laymanMode,
                'search-container__travelapp': laymanMode,
                'search-container--simple': !advancedForm,
                'search-container--advanced': advancedForm,
                'col-grid align-center justify-center': !productsAvailableToSearch,
              })}
            >
              {productsAvailableToSearch ? (
                <React.Fragment>
                  {laymanMode && (
                    <SearchFormTab
                      tabs={tabs}
                      currentTab={currentTab}
                      openCorrectForm={openCorrectForm}
                      setCurrentTab={setCurrentTab}
                      setShowBottomComponents={setShowBottomComponents}
                      laymanMode={laymanMode}
                      formType={formType}
                      searchAccounts={searchAccounts}
                      callbackParams={props.callbackParams}
                      iframeMode={props.iframeMode}
                      travelerLoggedIn={props.travelerLoggedIn}
                      advancedForm={advancedForm}
                      cartId={cartId}
                      cartItemsCount={cartItemsCount}
                      setIsMultiCitySelected={setIsMultiCitySelected}
                      setIsFromLastSearch={setIsFromLastSearch}
                    />
                  )}
                  {tabs[currentTab] && tabs[currentTab].getContent()}
                </React.Fragment>
              ) : (
                noProductsAvailableCard
              )}
            </div>
          </div>
          {laymanMode && !forceAdvancedSearch && (
            <div className="col-5 col-lg-6 col-md-4 col-bleed hidden-xxs hidden-xs hidden-sm slider-carousel image__container">
              <ImageSlider images={props.images} />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    form = !isMatrixPage ? (
      <div
        className={classnames('search', {
          'container-full': !laymanMode,
          'grid container container--sm-full-width': laymanMode,
        })}
      >
        <div className="col-12 col-bleed">
          {searchResultHeader}
          {!isCollapsed && tabs[currentTab] && (
            <div
              className={classnames('search-drawer__common', {
                'search-drawer__travelapp': laymanMode,
              })}
            >
              {tabs[currentTab].getContent()}
            </div>
          )}
          {lastSearchedParams && expanded && currentTab === FLIGHT_SEARCH_TAB && (
            <FlightSearchLoadingScreen
              searchInformation={lastSearchedParams}
              searchCompleted={isSearchCompleted}
              expand={expanded}
            />
          )}
          {hotelLastSearchedParams && expanded && currentTab === HOTEL_SEARCH_TAB && (
            <HotelSearchLoadingScreen
              searchInformation={hotelLastSearchedParams}
              searchCompleted={isSearchCompleted}
            />
          )}
          {carLastSearchedParams && expanded && currentTab === CAR_SEARCH_TAB && (
            <CarSearchLoadingScreen
              searchInformation={carLastSearchedParams}
              searchCompleted={isSearchCompleted}
              expand={expanded}
            />
          )}
          {isSearchSubHeaderDisplayed && (
            <SearchSubHeader
              totalResultsCount={totalResultsCount}
              totalOptionsPerSupplier={totalOptionsPerSupplier}
              laymanMode={laymanMode}
              match={match}
              searchParamsId={searchParamsId}
              sortFlightSearch={sortFlightSearch}
              sortBy={sortBy}
              isSearchCompleted={isSearchCompleted}
              viewMode={viewMode}
              updateViewMode={updateViewMode}
            />
          )}
          {currentTab === CAR_SEARCH_TAB && (
            <CarSearchSubHeader
              totalResultsCount={totalResultsCount}
              totalOptionsPerSupplier={totalOptionsPerSupplier}
              laymanMode={laymanMode}
              match={match}
              searchParamsId={searchParamsId}
              sortFlightSearch={sortFlightSearch}
              sortBy={sortBy}
              isSearchCompleted={isSearchCompleted}
              viewMode={viewMode}
              onUpdateViewMode={updateViewMode}
            />
          )}
        </div>
      </div>
    ) : null;
  }

  return (
    <Fragment>
      {form}
      <div
        className={classnames('search-container__backdrop', {
          'search-container__backdrop--active': laymanMode && advancedForm,
        })}
      />

      <div
        className={classnames('search-result', {
          'container-full': !laymanMode,
          'container container--sm-full-width': laymanMode,
        })}
      >
        {props.children}
      </div>
    </Fragment>
  );
};

Search.defaultProps = {
  affiliatesUrl: null,
  flightSearchParams: {},
  hotelSearchParams: {},
  carSearchParams: {},
  children: null,
  totalResultsCount: 0,
  cartItemsCount: 0,
  cartId: null,
  setShowBottomComponents: () => {},
  searchParamsId: null,
  totalResultsPerSupplier: [],
  isSearchCompleted: false,
  location: '',
  matrixBySearchID: null,
  groupOfSelectedFlights: null,
  showContinueButton: false,
  callbackParams: {},
  lastSearchedParams: {},
  carLastSearchedParams: {},
  viewMode: TYPE_STANDARD,
  hotelLastSearchedParams: {},
  isHotelDetailsPage: false,
};

Search.propTypes = {
  availableChannels: PropTypes.shape({
    cars: PropTypes.arrayOf(PropTypes.string),
    flights: PropTypes.arrayOf(PropTypes.string),
    hotels: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  hasStaticProducts: PropTypes.bool.isRequired,
  affiliatesUrl: PropTypes.string,
  searchAccounts: PropTypes.shape().isRequired,
  setShowBottomComponents: PropTypes.func,
  setOnSearchStarted: PropTypes.func.isRequired,
  flightSearchParams: PropTypes.shape(),
  hotelSearchParams: PropTypes.shape(),
  carSearchParams: PropTypes.shape(),
  carLastSearchedParams: PropTypes.shape(),
  children: PropTypes.node,
  laymanMode: PropTypes.bool.isRequired,
  iframeMode: PropTypes.bool.isRequired,
  location: PropTypes.string,
  forceAdvancedSearch: PropTypes.bool.isRequired,
  route: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  resetAppForm: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      imageUrl: PropTypes.string,
    }),
  ).isRequired,
  totalResultsCount: PropTypes.number,
  totalResultsPerSupplier: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      docCount: PropTypes.string.isRequired,
    }),
  ),
  cartItemsCount: PropTypes.number,
  cartId: PropTypes.string,
  searchParamsId: PropTypes.string,
  // updatePreselectedTravelersBookingAppContext: PropTypes.func.isRequired,
  resetBookingAppContext: PropTypes.func.isRequired,
  exchangeEnabled: PropTypes.bool.isRequired,
  travelArrangerPresent: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  isSearchCompleted: PropTypes.bool,
  matrixBySearchID: PropTypes.shape({
    id: PropTypes.shape({
      all: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          content: PropTypes.arrayOf(
            PropTypes.shape({
              channel: PropTypes.string,
              options: PropTypes.arrayOf(
                PropTypes.shape({
                  data: PropTypes.shape({}),
                  isSelected: PropTypes.bool,
                }),
              ),
              channelInformations: PropTypes.shape({}),
            }),
          ),
          headers: PropTypes.shape({}),
        }),
      ),
      customize: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  groupOfSelectedFlights: PropTypes.shape({}),
  showContinueButton: PropTypes.bool,
  isMatrixPage: PropTypes.bool.isRequired,
  wtsEnabled: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape({}),
  canExcludeWtsContent: PropTypes.bool.isRequired,
  lastSearchedParams: PropTypes.shape({}),
  hotelLastSearchedParams: PropTypes.shape({}),
  isHotelDetailsPage: PropTypes.bool,
  viewMode: PropTypes.string,
  updateViewMode: PropTypes.func.isRequired,
  sortFlightSearch: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  preselectDecision: PropTypes.string.isRequired,
  updatePreselectTravelers: PropTypes.func.isRequired,
  airports: PropTypes.shape({
    [PropTypes.string]: AirportShape,
  }).isRequired,
  onFetchAirportDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { searchId } = ownProps.match.params;
  let searchParamsId = 'lastSearch';
  let searchCompleted = false;
  const locationQuery = memoizeLocationQuery(ownProps.location.search);
  const isHotelDetailsPage = !!ownProps.match.params.resultId;

  if (ownProps.match.params.searchId && !locationQuery.cached) {
    searchParamsId = ownProps.match.params.searchId;

    if (
      !!state.flights.searchResultGroupsBySearchId[searchParamsId] ||
      !!state.hotels.searchResultsBySearchId[searchParamsId] ||
      !!state.cars.searchResultsBySearchId[searchParamsId]
    ) {
      searchCompleted = true;
    }
  } else if (isHotelDetailsPage) {
    searchCompleted = true;
  }

  const totalResultsCount =
    state.flights.totalResultsBySearchId[searchParamsId] ||
    state.hotels.totalResultsBySearchId[searchParamsId] ||
    state.cars.totalResultsBySearchId[searchParamsId];

  const totalResultsPerSupplier =
    state.flights.totalResultsPerSupplier[searchParamsId] ||
    state.hotels.totalResultsPerSupplier[searchParamsId] ||
    state.cars.totalResultsPerSupplier[searchParamsId];

  const totalTripsCount =
    state.flights &&
    state.flights.searchParamsBySearchId &&
    state.flights.searchParamsBySearchId[searchParamsId] &&
    state.flights.searchParamsBySearchId[searchParamsId].tripCount;

  const matrixBySearchID = state.flights.matrixesBySearchId;

  const groupOfSelectedFlights = state.flights.selectedFlightGroups;

  const hasMatrixID = matrixBySearchID && Object.keys(matrixBySearchID).length > 0;

  const allTripsSelected =
    (groupOfSelectedFlights && Object.keys(groupOfSelectedFlights).length) === totalTripsCount;

  const isMatrixPage = ownProps.location.pathname.indexOf('matrix') !== -1;

  const showContinueButton = !isMatrixPage && hasMatrixID && allTripsSelected;

  return {
    airports: state.common.airports,
    displayResults: ownProps.children !== null,
    flightSearchParams: state.flights.searchParamsBySearchId[searchParamsId],
    lastSearchedParams: searchId
      ? state.flights.searchParamsBySearchId[searchId]
      : state.flights.searchParamsBySearchId.lastSearch,
    hotelLastSearchedParams: searchId
      ? state.hotels.searchParamsBySearchId[searchId]
      : state.hotels.searchParamsBySearchId.lastSearch,
    hotelSearchParams: state.hotels.searchParamsBySearchId[searchParamsId],
    carSearchParams: state.cars.searchParamsBySearchId[searchParamsId],
    carLastSearchedParams: state.cars.searchParamsBySearchId.lastSearch,
    cartId: state.common.cart.id,
    hasStaticProducts:
      !ownProps.exchangeEnabled &&
      state.common.staticProducts.fetchedStaticProducts &&
      Object.keys(state.common.staticProducts.fetchedStaticProducts).length > 0,
    totalResultsCount,
    cartItemsCount: state.common.cart.items.length,
    searchParamsId,
    totalResultsPerSupplier,
    matrixBySearchID,
    groupOfSelectedFlights,
    totalTripsCount,
    showContinueButton,
    travelerLoggedIn: ownProps.travelerLoggedIn,
    isMatrixPage,
    isSearchCompleted: searchCompleted,
    sortBy:
      searchParamsId !== 'lastSearch' && state.flights.searchFilterParamsBySearchId[searchParamsId]
        ? state.flights.searchFilterParamsBySearchId[searchParamsId].sortBy
        : SORT_BY_PRICE_LOWEST,
    preselectTravelers: state.common.preselectTravelers.travelers,
    preselectDecision: state.common.preselectTravelers.decision,
    isHotelDetailsPage,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetAppForm: () => dispatch(resetApp()),
  sortFlightSearch: (filter, value, key, isAllChecked) => {
    const { searchId, tripId } = ownProps.match.params;
    dispatch(filterFlightSearch(filter, value, key, searchId, tripId, isAllChecked));
    dispatch(fetchFlightSearchResults({ searchId, tripId }));
  },
  updatePreselectTravelers: travelers => dispatch(updatePreselectTravelersStore(travelers)),
  onFetchAirportDetails: code => dispatch(fetchAirportDetails(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
