import React from 'react';
import PropTypes from 'prop-types';

const PostFinance = props => {
  const attributes = props.attributes;

  return (
    <form
      style={{ display: 'none' }}
      action={attributes.merchantUrl}
      method="post"
      {...(props.iframeMode && { target: '_blank' })}
    >
      <input ref={props.setButtonRef} type="submit" name="submit" />
    </form>
  );
};

PostFinance.propTypes = {
  iframeMode: PropTypes.bool.isRequired,
  attributes: PropTypes.shape({
    merchantUrl: PropTypes.string.isRequired,
  }).isRequired,
  setButtonRef: PropTypes.func.isRequired,
};

export default PostFinance;
