import React from 'react';
import PropTypes from 'prop-types';

const Voucher = props => (
  <div className="cart-summary__accordion-sub-item-details-category">
    <span>{props.number}</span>
  </div>
);

Voucher.propTypes = {
  number: PropTypes.string.isRequired,
};

export default Voucher;
