import { connect } from 'react-redux';

import { ensureServiceInCart } from '../../../../actions/flight';

import FlightServiceSelection from './';

const mapStateToProps = (state, ownProps) => {
  const travelers = [];
  let travelerIndex = 0;
  Object.keys(state.common.travelerCount).forEach(key => {
    // restricting service selection for infants
    if (key !== 'infants') {
      const keyValue = state.common.travelerCount[key];
      const count = keyValue.length;
      for (let index = 0; index < count; index += 1) {
        travelerIndex += 1;
        travelers.push({
          name: I18n.t('components.ibe.flight_service_selection.traveler.long', {
            index: travelerIndex,
          }),
          index: travelerIndex - 1,
        });
      }
    }
  });

  const services = ownProps.services.map(service => {
    let quantity = 0;
    let itemId;
    let travelerIndices = [];
    const item = ownProps.item.subItems.find(
      subItem => subItem.bookingAttributes.serviceId === service.id,
    );
    if (item) {
      quantity = Number(item.quantity);
      itemId = item.id;
      travelerIndices = item.bookingAttributes.travelerIndices || [];
    }

    return {
      ...service,
      quantity,
      itemId,
      travelerIndices,
    };
  });

  return { services, travelers };
};

const mapDispatchToProps = dispatch => ({
  ensureServiceInCart: (id, quantity, travelerIndices, callbackParams) =>
    dispatch(ensureServiceInCart(id, quantity, travelerIndices, callbackParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightServiceSelection);
