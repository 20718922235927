export const UPDATE_ERROR_ALERT_TOKEN = 'UPDATE_ERROR_ALERT_TOKEN';
export const UPDATE_SUCCESS_ALERT_TOKEN = 'UPDATE_SUCCESS_ALERT_TOKEN';

export const updateErrorAlertByToken = ccToken => ({
  type: UPDATE_ERROR_ALERT_TOKEN,
  ccToken,
});

export const updateSuccessAlertByToken = ccToken => ({
  type: UPDATE_SUCCESS_ALERT_TOKEN,
  ccToken,
});
