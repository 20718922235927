import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';
import SelectBox from '@wtag/rcl-select-box';
import Icon from '@wtag/rcl-icon';
import IconButton from '@wtag/rcl-icon-button';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { TYPE_CAR, TYPE_FLIGHT, TYPE_HOTEL } from '../../../helpers/itemTypes';
import { TYPE_ADULT, TYPE_CHILD, TYPE_INFANT } from '../../../helpers/passengerTypes';

const PreselectTravelersForm = ({
  onDeleteClick,
  onChangeTraveler,
  onTypeChange,
  traveler,
  type,
  count,
  isDeleteDisabled,
  callbackParams,
  formType,
}) => {
  const [travelerOptions, setTravelerOptions] = useState([]);
  const fetchTravelerOptions = async q => {
    if (q && q.length >= 1) {
      const { data } = await httpClient.get(routes.api.customers.search({ q, callbackParams }));
      setTravelerOptions(
        data.map(fetchedTraveler => ({
          label: fetchedTraveler.infoDropdownTitle,
          value: fetchedTraveler,
        })),
      );
    }
  };

  const debouncedSearchTraveler = useRef(debounce(600, q => fetchTravelerOptions(q))).current;

  const adultType = {
    label: I18n.t('components.ibe.preselect_traveler.label.type.adult'),
    value: TYPE_ADULT,
  };

  const childType = {
    label: I18n.t('components.ibe.preselect_traveler.label.type.child'),
    value: TYPE_CHILD,
  };

  const infantType = {
    label: I18n.t('components.ibe.preselect_traveler.label.type.infant'),
    value: TYPE_INFANT,
  };

  const onChangeTravelerData = value => {
    onChangeTraveler(value);
    if (isDeleteDisabled) {
      onTypeChange(adultType);
    }
  };

  const typeOption = typeOfForm => {
    switch (typeOfForm) {
      case TYPE_FLIGHT:
        return [adultType, childType, infantType];
      case TYPE_HOTEL:
        return [adultType, childType];
      case TYPE_CAR:
        return [adultType];
      default:
        return [];
    }
  };

  return (
    <div>
      <div className="preselect-travelers-form__header">
        <div className="preselect-travelers-form__header--counter">
          {I18n.t('components.ibe.preselect_traveler.label.travelers_counter', {
            count,
          })}
        </div>
        <IconButton
          icon={<Icon name="delete" />}
          color="default"
          size="small"
          onClick={onDeleteClick}
          disabled={isDeleteDisabled}
        />
      </div>
      <div className="preselect-travelers-form__field">
        <SelectBox
          onInputChange={debouncedSearchTraveler}
          options={travelerOptions}
          onChange={onChangeTravelerData}
          width="full"
          value={traveler}
          placeholderText={I18n.t('components.ibe.preselect_traveler.placeholder.traveler')}
        />
      </div>
      <SelectBox
        options={typeOption(formType)}
        onChange={onTypeChange}
        width="full"
        value={type}
        placeholderText={I18n.t('components.ibe.preselect_traveler.placeholder.type')}
        isDisabled={isDeleteDisabled}
      />
    </div>
  );
};

PreselectTravelersForm.defaultProps = {
  type: null,
  traveler: null,
  formType: TYPE_FLIGHT,
};

PreselectTravelersForm.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  onChangeTraveler: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  traveler: PropTypes.shape({}),
  type: PropTypes.shape({}),
  count: PropTypes.string.isRequired,
  isDeleteDisabled: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape({
    laymanMode: PropTypes.bool,
    order_id: PropTypes.string,
  }).isRequired,
  formType: PropTypes.string,
};

export default PreselectTravelersForm;
