import PropTypes from 'prop-types';

const travelerShape = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  email: PropTypes.string,
  avatarUrl: PropTypes.string,
  url: PropTypes.string,
};

const countryShape = {
  code: PropTypes.string,
  flag: PropTypes.string,
  name: PropTypes.string,
};

const segmentShape = {
  originCountryInformation: PropTypes.shape(countryShape),
  destinationCountryInformation: PropTypes.shape(countryShape),
};

export { travelerShape, countryShape, segmentShape };
