import { FETCHED_NEW_CARD_DETAILS } from '../../actions/common/fop';

export default function newCreditCard(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case FETCHED_NEW_CARD_DETAILS:
      newState[action.cardDetails.token] = action.cardDetails;
      return newState;

    default:
      return state;
  }
}
