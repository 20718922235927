import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import { NONE_FORM_OF_PAYMENT } from '../IBE/lib/helpers/fop_options';
import './style.scss';

const InstantTicketWrapper = ({ fop, size }) => {
  let tooltipContent = I18n.t('components.instant_ticket.description');
  let emphasisTagColor = 'inverse';
  let emphasisTagType = 'warning';

  if (fop && fop.type === NONE_FORM_OF_PAYMENT) {
    emphasisTagColor = 'default';
    emphasisTagType = 'default';
    tooltipContent = <div>{I18n.t('ibe.payment.instant_ticket_warning')}</div>;
  }

  return (
    <Tooltip
      className="instant-ticket-wrapper"
      content={tooltipContent}
      type="inverse"
      position="bottom-right"
      showArrow={false}
    >
      <EmphasisTag
        text={I18n.t('components.instant_ticket.tag_name')}
        size={size}
        icon={<Icon name="neutralOutline" size="tiny" />}
        type={emphasisTagType}
        iconColor={emphasisTagColor}
      />
    </Tooltip>
  );
};

InstantTicketWrapper.defaultProps = {
  size: 'small',
};

InstantTicketWrapper.propTypes = {
  fop: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default InstantTicketWrapper;
