import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import './styles.scss';

const AddOnService = ({ linkTo, iconName, title }) => (
  <Link
    to={linkTo}
    className="add-on-service d-flex direction-column justify-center align-center flex-gap-12"
  >
    <Icon
      className="add-on-service__icon"
      name={iconName}
      size="large"
      showBGColor={true}
      color="tertiary"
    />
    <p className="add-on-service__title">{title}</p>
  </Link>
);

AddOnService.propTypes = {
  linkTo: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddOnService;
