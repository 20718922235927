import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { I18nText } from '@wtag/react-comp-lib';
import SelectBox from '@wtag/rcl-select-box';
import mealRequestsBuilder from '../../../../Search/flight/helpers/mealRequestsBuilder';
import reduxFieldShape from '../../../helpers/reduxField';
import findLabelValue from '../../../../helpers/findLabelValue';

class MealRequests extends React.Component {
  setMealRequest = (carrier, flightNumber, code) => {
    let currentMealRequests = this.props.fields.mealRequests.value;
    if (!currentMealRequests.push) {
      currentMealRequests = [];
    }

    const currentMealRequestIndex = currentMealRequests.findIndex(
      request => request.carrier === carrier && request.flightNumber === flightNumber,
    );

    if (currentMealRequestIndex >= 0) {
      currentMealRequests.splice(currentMealRequestIndex, 1);
    }

    if (typeof code !== 'undefined' && code !== null) {
      currentMealRequests.push({ resultId: this.props.resultId, carrier, flightNumber, code });
    }
    const updatedSelectedCustomer = {
      ...this.props.selectedCustomer,
      mealRequests: currentMealRequests,
    };
    this.props.onCustomerSelected(this.props.travelerIndex, updatedSelectedCustomer);
    this.props.fields.mealRequests.onChange(currentMealRequests);
  };

  mealRequestValueForSegment = (carrier, flightNumber) => {
    if (!this.props.fields.mealRequests.value.find) {
      return '';
    }

    const currentMealRequest = this.props.fields.mealRequests.value.find(
      request => request.carrier === carrier && request.flightNumber === flightNumber,
    );

    if (!currentMealRequest) {
      return '';
    }

    return currentMealRequest.code;
  };

  render() {
    const { segments, mealRequestOption } = this.props;

    const mealRequestSelectors = [];
    let mealRequestSupported = false;
    let MEAL_REQUESTS = [];
    const mealRequestsAvailable =
      typeof mealRequestOption !== 'undefined' &&
      mealRequestOption !== null &&
      Object.keys(mealRequestOption).length > 0 &&
      mealRequestOption.supported;

    if (mealRequestsAvailable) {
      mealRequestSupported = true;
      const mealCodes = mealRequestOption.availableCodes;
      MEAL_REQUESTS = mealRequestsBuilder(mealCodes);
    }

    segments.forEach(segment => {
      const flightNumber = `${segment.carrierCode}${segment.flightNumber}`;
      mealRequestSelectors.push(
        <div
          key={flightNumber}
          className="col-12 col-sm-6 col-md-4 col-bleed meal-request__container-fields"
        >
          <div className="meal-request__container-fields-input">
            {mealRequestSupported ? (
              <div className="meal-request__container-fields-input-meal-type">
                <SelectBox
                  options={MEAL_REQUESTS}
                  label={flightNumber}
                  key={flightNumber}
                  name={flightNumber}
                  touched={false}
                  value={findLabelValue(
                    MEAL_REQUESTS,
                    this.mealRequestValueForSegment(segment.carrierCode, segment.flightNumber),
                  )}
                  onChange={selectedOption => {
                    this.setMealRequest(
                      segment.carrierCode,
                      segment.flightNumber,
                      selectedOption && selectedOption.value,
                    );
                  }}
                  size="tiny"
                  width="full"
                />
              </div>
            ) : (
              <div className="meal-request__container-fields-input-meal-type">
                <div className="meal-request__container-fields-input-label">{flightNumber}</div>
                <I18nText id="meal_requests.not_supported" />
              </div>
            )}
          </div>
        </div>,
      );
    });

    return (
      <div className="col-12 col-bleed meal-request">
        <div className="meal-request__container">{mealRequestSelectors}</div>
      </div>
    );
  }
}

MealRequests.propTypes = {
  fields: intlShape.isRequired, // eslint-disable-line react/no-typos
  resultId: PropTypes.string.isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      carrierCode: PropTypes.string.isRequired,
      flightNumber: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  mealRequests: reduxFieldShape.isRequired,
  mealRequestOption: PropTypes.shape({
    availableCodes: PropTypes.arrayOf(PropTypes.string),
    supported: PropTypes.bool,
  }).isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({}).isRequired,
  travelerIndex: PropTypes.number.isRequired,
};

export default injectIntl(MealRequests);
