export const humanReadableDuration = durationInMinutes => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  if (hours && minutes) {
    return `${hours}h ${minutes}min`;
  }
  if (hours && !minutes) {
    return `${hours}h`;
  }
  return `${minutes}min`;
};

export default humanReadableDuration;
