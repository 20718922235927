export default function cartNeedsApproval(state) {
  const searchIdsWithManualSelection = state.common.multiSearch.searchIdsWithManualSelection;
  const hasBeenApproved = state.common.approvalRequest.status === 'approved';

  let approvalRequired = state.common.cart.approvalRequired;

  if (approvalRequired && hasBeenApproved) {
    approvalRequired = searchIdsWithManualSelection.length > 0;
  }

  return approvalRequired;
}
