import { CAR_SEARCH_FETCHED } from '../../actions/car';

export default function searchStatsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case CAR_SEARCH_FETCHED:
      newState[action.searchId] = {
        price: action.stats.price,
        categories: action.stats.categories,
        vendors: action.stats.vendors,
        availableSortOptions: action.stats.availableSortOptions,
      };
      return newState;

    default:
      return state;
  }
}
