import React from 'react';
import PropTypes from 'prop-types';
import Link from '@wtag/rcl-link';
import Icon from '@wtag/rcl-icon';
import classNames from 'classnames';

const FacilityItemLink = ({
  className,
  label,
  onClick,
  sidePanelTitle,
  sidePanelBody,
  showIcon,
  iconLabel,
}) => {
  const handleOnClick = () => {
    onClick(sidePanelTitle, sidePanelBody);
  };

  return (
    <div className={classNames('car__facility-link', className)}>
      {showIcon && (
        <Icon
          name="checkCircle"
          color="success"
          size="small"
          showLabel={true}
          iconLabel={iconLabel}
        />
      )}
      <Link onClick={handleOnClick} modifier="tertiary" size="tiny">
        {label}
      </Link>
    </div>
  );
};

FacilityItemLink.defaultProps = {
  className: null,
  sidePanelTitle: null,
  sidePanelBody: null,
  onClick: () => {},
  showIcon: false,
  iconLabel: null,
};

FacilityItemLink.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  sidePanelTitle: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
  sidePanelBody: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
  showIcon: PropTypes.bool,
  iconLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default FacilityItemLink;
