import React, { useEffect, Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@wtag/rcl-tooltip';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import { connect } from 'react-redux';
import useViewMode from 'sharedWebpack/useViewMode';
import { fetchAirportDetails } from '../IBE/actions/flight';
import { DESKTOP_DEVICE } from '../old/libraries/responsiveHelpers';
import './styles.scss';

const CountryDetails = ({ airports, fetchAirportDetail, code }) => {
  const [isShowTooltipOfAirportCountry, setIsShowTooltipOfAirportCountry] = useState(false);
  const airport = airports[code];
  const placeName = airport && airport.placeName;
  const country = airport && airport.countryName;
  const flag = airport && airport.countryFlag;

  const airportCountry = airport && `${placeName}, ${country}`;
  const isLargeScreen = useViewMode() === DESKTOP_DEVICE;

  const titleRef = useRef(null);

  useEffect(() => {
    setIsShowTooltipOfAirportCountry(
      titleRef.current && titleRef.current.scrollWidth > titleRef.current.offsetWidth,
    );
  }, [titleRef]);

  useEffect(() => {
    if (!airport) {
      fetchAirportDetail(code);
    }
  }, [airport]);

  return (
    <Fragment>
      {!airport ? (
        <ContentLoaderPlaceholder
          numberOfLines={isLargeScreen ? 2 : 1}
          showBackground={false}
          contentWidth={isLargeScreen ? '144px' : '80px'}
        />
      ) : (
        <div className="country-details d-flex-column">
          <div className="country-details__flag d-flex">
            <img className="country-details__flag--image" src={flag} alt={placeName} />
            <span className="country-details__flag--code">{code}</span>
          </div>
          {isShowTooltipOfAirportCountry ? (
            <Tooltip content={airportCountry} size="tiny">
              <div className="country-details__country">{airportCountry}</div>
            </Tooltip>
          ) : (
            <div className="country-details__country" ref={titleRef}>
              {airportCountry}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  airports: state && state.common && state.common.airports,
});

const mapDispatchToProps = dispatch => ({
  fetchAirportDetail: code => dispatch(fetchAirportDetails(code)),
});

CountryDetails.defaultProps = {
  airports: {},
};

CountryDetails.propTypes = {
  airports: PropTypes.shape({
    countryFlag: PropTypes.string,
    placeName: PropTypes.string,
    code: PropTypes.string,
    countryName: PropTypes.string,
  }),
  code: PropTypes.string.isRequired,
  fetchAirportDetail: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryDetails);
