import getTripServices from './getTripServices';
import getServicesWithQuantities from './getServicesWithQuantities';

const getTripServicesWithQuantities = (services, subItems, origin, destination) => {
  if (!Array.isArray(services)) {
    return [];
  }

  const tripServices = getTripServices(services, origin, destination);

  return getServicesWithQuantities(tripServices, subItems);
};

export default getTripServicesWithQuantities;
