import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'sharedWebpack/Alert';

const PaymentError = ({ messageKey }) => {
  let errorMessage;
  switch (messageKey) {
    case 'invalid_transaction':
      errorMessage = I18n.t('public.shared.paygate_payment.invalid_transaction');
      break;
    case 'unprocessed_transaction':
      errorMessage = I18n.t('public.shared.paygate_payment.unprocessed_transaction');
      break;
    case 'cancelled':
      errorMessage = I18n.t('public.shared.paygate_payment.cancelled');
      break;
    case 'failed':
      errorMessage = I18n.t('public.shared.postfinance_payment.failed');
      break;
    case 'declined':
      errorMessage = I18n.t('public.shared.postfinance_payment.declined');
      break;
    case 'unsaved_card':
      errorMessage = I18n.t('public.shared.payment_gateway.unsaved_card');
      break;
    default:
      errorMessage = I18n.t('public.shared.payment_gateway.unknown_error');
  }
  return (
    <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 payment-error">
      <Alert hideClose={false} type="warning" isVisible={true}>
        {errorMessage}
      </Alert>
    </div>
  );
};
PaymentError.propTypes = {
  messageKey: PropTypes.string.isRequired,
};

export default PaymentError;
