const calculateAndAddTaxWithPrice = (price, grossTaxes, numberOfAdult, numberOfChildren) => {
  const adultAndChildrenCount = numberOfAdult + numberOfChildren;
  const perTravelerTax =
    adultAndChildrenCount > 0
      ? parseFloat(grossTaxes) / (numberOfAdult + numberOfChildren)
      : parseFloat(grossTaxes);
  return parseFloat(price) + perTravelerTax;
};

export default calculateAndAddTaxWithPrice;
