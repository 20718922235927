/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'sharedWebpack/DateTime';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import { TYPE_FLIGHT } from 'sharedWebpack/helpers/itemTypes';
import formatAddress from './formatAddress';
import BookHotel from './BookHotel';
import isSingleDayStayAtleast from '../helpers/isSingleDayStayAtleast';
import './styles.scss';

const TravelplanItemFooter = ({ data, stayingPlaceInfo, hotelTimings }) => {
  const dateTimeFormat = data.type === 'hotel' ? 'short' : 'shortWithTime';
  const dates = [];
  let isHotelRequired = false;

  if (data.type === TYPE_FLIGHT && stayingPlaceInfo) {
    const { currentFlightArrivalTime, nextFlightDepartureTime } = stayingPlaceInfo;

    const isInsideFlightDuration = (startTime, endTime) =>
      startTime.isBetween(currentFlightArrivalTime, nextFlightDepartureTime, null, '[]') &&
      endTime.isBetween(currentFlightArrivalTime, nextFlightDepartureTime, null, '[]');

    const addHotelDate = (checkIn, checkOut) => {
      if (isSingleDayStayAtleast(checkIn, checkOut)) {
        isHotelRequired = true;

        dates.push({ checkIn, checkOut });
      }
    };

    if (hotelTimings.length === 0) {
      addHotelDate(currentFlightArrivalTime, nextFlightDepartureTime);
    } else if (hotelTimings.length === 1) {
      const { checkIn, checkOut } = hotelTimings[0];

      if (isInsideFlightDuration(checkIn, checkOut)) {
        addHotelDate(currentFlightArrivalTime, checkIn);
        addHotelDate(checkOut, nextFlightDepartureTime);
      }
    } else {
      // assuming one won't checkIn in a hotel before arrival and wont' checkOut after departure
      hotelTimings.forEach(({ checkIn, checkOut }, index) => {
        if (isInsideFlightDuration(checkIn, checkOut)) {
          if (index === 0) {
            addHotelDate(currentFlightArrivalTime, checkIn);
          }

          if (index === hotelTimings.length - 1) {
            addHotelDate(checkOut, nextFlightDepartureTime);
          }
        }

        if (index > 0) {
          const { checkOut: currentHotelCheckOut } = hotelTimings[index - 1];

          if (isInsideFlightDuration(currentHotelCheckOut, checkIn)) {
            addHotelDate(currentHotelCheckOut, checkIn);
          }
        }
      });
    }
  }

  const footerData = {
    flight: {
      dateTime: data.arrivalLocaltime,
      subTitle: I18n.t('admin.components.orders.overview.travel_plan.flight.footer.text', {
        code: data.destinationDetails && data.destinationDetails.code,
        name: data.destinationDetails && data.destinationDetails.name,
        city: data.destinationDetails && data.destinationDetails.cityName,
        country: data.destinationDetails && data.destinationDetails.countryName,
      }),
      cardMainTitle: I18n.t('admin.components.orders.overview.travel_plan.flight.footer.card', {
        city: data.destinationDetails && data.destinationDetails.cityName,
        country: data.destinationDetails && data.destinationDetails.countryName,
      }),
    },
    car: {
      dateTime: data.dropOffTime,
      subTitle: I18n.t('admin.components.orders.overview.travel_plan.car.footer.text', {
        location: data.dropOffLocation,
      }),
      cardMainTitle: I18n.t('admin.components.orders.overview.travel_plan.car.footer.card', {
        location: data.dropOffLocation,
      }),
    },
    cruise: {
      dateTime: data.offBoardingTime,
      subTitle: formatAddress(
        `${data.offBoardingLocation},${data.offBoardingAddress &&
          data.offBoardingAddress.city},${data.offBoardingAddress &&
          data.offBoardingAddress.country}`,
      ),
      cardMainTitle: `${I18n.t(
        'admin.components.orders.overview.travel_plan.cruise.footer.card',
      )} ${formatAddress(
        `${data.offBoardingLocation},${data.offBoardingAddress &&
          data.offBoardingAddress.city},${data.offBoardingAddress &&
          data.offBoardingAddress.country}`,
      )}`,
    },
    event: {
      dateTime: data.endTime,
      subTitle: formatAddress(
        `${data.address && data.address.street},${data.address &&
          data.address.city},${data.address && data.address.country}`,
      ),
      cardMainTitle: `${I18n.t(
        'admin.components.orders.overview.travel_plan.event.footer.card',
      )} ${formatAddress(
        `${data.address && data.address.street},${data.address &&
          data.address.city},${data.address && data.address.country}`,
      )}`,
    },
    hotel: {
      dateTime: data.checkOut,
      subTitle: `${data.hotelName}, ${data.address}`,
      cardMainTitle: I18n.t('admin.components.orders.overview.travel_plan.hotel.footer.card', {
        hotel_name: data.hotelName,
        address: data.address,
      }),
    },
    transfer: {
      dateTime: data.dropOffTime,
      subTitle: I18n.t('admin.components.orders.overview.travel_plan.transfer.footer.text', {
        address: data.dropOffAddress,
      }),
      cardMainTitle: I18n.t('admin.components.orders.overview.travel_plan.transfer.footer.card', {
        address: data.dropOffAddress,
      }),
    },
  };

  return (
    <div className="grid grid-gap-20 travel-plan__timeline-item travel-plan__timeline-item--footer">
      <div className="col-12 col-sm-3 col-xlg-2">
        <div className="travel-plan__timeline-item-indicator">
          <div className="travel-plan__timeline-item-indicator-dot" />
          <div className="travel-plan__timeline-item-indicator-line" />
        </div>
      </div>
      <div className="col-12 col-sm-9 col-xlg-10">
        <div className="travel-plan__timeline-item-title">
          <Icon
            className="travel-plan__timeline-item-title-icon"
            name="time"
            color="danger"
            size="tiny"
          />
          <div className="travel-plan__timeline-item-title-text">
            <DateTime
              dateTime={footerData[data.type].dateTime}
              format={dateTimeFormat}
              displayWithoutConversion={true}
            />
          </div>
        </div>
        <div className="travel-plan__timeline-item-sub-title">{footerData[data.type].subTitle}</div>
        <div className="travel-plan__timeline-item-suggestion-card travel-plan__timeline-item-suggestion-card--success travel-plan__timeline-item-suggestion-card--align-center">
          <Card version="v2" size="full">
            <div className="travel-plan__timeline-item-suggestion-card-info">
              <div className="travel-plan__timeline-item-suggestion-card-title">
                <div className="travel-plan__timeline-item-suggestion-card-main-title">
                  {footerData[data.type].cardMainTitle}
                </div>
              </div>
            </div>
          </Card>
        </div>
        {isHotelRequired && <BookHotel stayingPlaceInfo={stayingPlaceInfo} dates={dates} />}
      </div>
    </div>
  );
};

TravelplanItemFooter.propTypes = {
  data: PropTypes.shape({
    tripDestinationDetails: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
    departureLocaltime: PropTypes.string,
    arrivalLocaltime: PropTypes.string,
    originDetails: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
    destinationDetails: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      cityName: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
  }).isRequired,
};

export default TravelplanItemFooter;
