import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const LEGAL_PAPER_FETCHED = 'LEGAL_PAPER_FETCHED';
function legalPaperFetched(cartId, legalPapers) {
  return {
    type: LEGAL_PAPER_FETCHED,
    cartId,
    legalPapers,
  };
}

export const FETCHING_LEGAL_PAPERS = 'FETCHING_LEGAL_PAPERS';
function fetchingLegalPapers() {
  return { type: FETCHING_LEGAL_PAPERS };
}

export function fetchLegalPapers(callbackParams) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    dispatch(fetchingLegalPapers());
    return httpClient
      .get(routes.api.carts.legalPapers.fetch({ cartId, callbackParams }))
      .then(({ data }) => {
        dispatch(legalPaperFetched(cartId, data));
        return data;
      });
  };
}
