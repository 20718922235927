import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';

import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import { Spinner } from '@wtag/react-comp-lib';
import classNames from 'classnames';
import Modal from 'sharedWebpack/Modal';
import MatchTraveler from '../MatchTraveler';
import ConfirmAttributeChanges from './ConfirmAttributeChanges';

const initialState = {
  processing: false,
  isModalVisible: false,
  refreshInProgress: false,
  confirmableChanges: [],
  decisions: [],
  displayedValues: {},
  journeyElementId: '',
  journeyElementType: '',
  trips: [],
};

class RefreshBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.state.isModalVisible = !this.props.useButtonToStart;

    this.callAPI = this.callAPI.bind(this);
    this.fetchJourneyElement = this.fetchJourneyElement.bind(this);
  }

  componentDidMount() {
    if (!this.props.useButtonToStart) {
      this.refreshBooking();
    }

    if (this.props.journeyElementId) {
      this.fetchJourneyElement();
    }
  }

  onDecision = decision => {
    const decisions = this.state.decisions;
    decisions.push(decision);

    if (decisions.length === this.state.confirmableChanges.length) {
      this.callAPI(decisions);
    } else {
      this.setState({
        decisions,
        displayedValues: this.state.confirmableChanges[decisions.length],
      });
    }
  };

  callAPI(decisions) {
    this.setState({ processing: true, refreshInProgress: true });
    const params = { id: this.props.id };
    return httpClient
      .put(routes.admin.bookings.refresh(params), { decisions })
      .then(() => {
        if (this.props.redirectTo) {
          window.location.href = this.props.redirectTo;
        } else {
          window.location.reload(false);
        }
        this.setState({ refreshInProgress: false });
      })
      .catch(error => {
        const { status, data } = error.response;
        if (status === 409) {
          this.setState({
            refreshInProgress: false,
            decisions: [],
            confirmableChanges: data,
            displayedValues: data[0],
            processing: false,
            isModalVisible: true,
          });
        } else {
          this.setState({ processing: false, refreshInProgress: false });
          throw error;
        }
      });
  }

  fetchJourneyElement = async () => {
    const { data } = await httpClient.get(
      routes.api.fetchJourneyElement({
        type: this.props.journeyElementType,
        id: this.props.journeyElementId,
      }),
    );

    if (data && Array.isArray(data.trips)) {
      this.setState({ trips: data.trips });
    }
  };

  reset = () => {
    this.setState(initialState);
  };

  refreshBooking = () => {
    this.callAPI();
  };

  render() {
    const buttonProps = {
      onClick: this.refreshBooking,
      disabled: this.props.disabled || this.state.processing || this.state.isModalVisible,
    };

    let button = (
      <div className="refresh-booking__refresh-icon">
        <IconButton
          icon={<Icon name="refresh" />}
          color="tertiary"
          {...buttonProps}
          label={I18n.t('admin.components.refresh_booking.button')}
        />
        <Modal
          className="refresh-booking__modal refresh-booking__modal--loading"
          isModalVisible={this.state.refreshInProgress}
          onOutsideClick={this.reset}
        >
          <div className="col-12 col-bleed-y refresh-booking__spinner">
            <Spinner bgColor="neutral" size="small" color="success" />
            {I18n.t('admin.components.refresh_booking.loading_text')}
          </div>
        </Modal>
      </div>
    );

    if (this.props.textButton) {
      button = (
        <Button label={I18n.t('admin.components.refresh_booking.button')} {...buttonProps} />
      );
    }

    if (!this.state.confirmableChanges.length) return button;

    let decisionComponent = null;

    switch (this.state.displayedValues.type) {
      case 'confirm_attribute_changes':
        decisionComponent = (
          <ConfirmAttributeChanges
            key={this.state.displayedValues.id}
            id={this.state.displayedValues.id}
            values={this.state.displayedValues}
            disabled={this.state.processing}
            onDecision={this.onDecision}
            trips={this.state.trips}
          />
        );
        break;
      case 'match_traveler':
        decisionComponent = (
          <MatchTraveler
            key={this.state.displayedValues.id}
            id={this.state.displayedValues.id}
            traveler={this.state.displayedValues.traveler}
            onDecision={this.onDecision}
            disabled={this.state.processing}
          />
        );
        break;
      default:
        throw new Error(`Unknown change type [${this.state.displayedValues.type}]`);
    }

    const modalTitleContent = (
      <div className="refresh-booking__header--section">
        <div className="refresh-booking__header">
          {I18n.t('admin.components.refresh_booking.title')}
        </div>
        {this.state.decisions.length !== this.state.confirmableChanges.length && (
          <div className="refresh-booking__title">
            {I18n.t('admin.components.confirm_changes.step', {
              current: this.state.decisions.length + 1,
              total: this.state.confirmableChanges.length,
            })}
          </div>
        )}
      </div>
    );

    return (
      <React.Fragment>
        {button}
        {!this.state.processing && (
          <Modal
            className={classNames('refresh-booking__modal', {
              'refresh-booking__modal--match-traveler':
                this.state.displayedValues.type === 'match_traveler',
            })}
            isModalVisible={this.state.isModalVisible}
            title={modalTitleContent}
            onOutsideClick={this.reset}
          >
            {decisionComponent}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

RefreshBooking.defaultProps = {
  useButtonToStart: true,
  redirectTo: null,
  textButton: false,
  journeyElementId: '',
  journeyElementType: '',
};

RefreshBooking.propTypes = {
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  useButtonToStart: PropTypes.bool,
  redirectTo: PropTypes.string,
  textButton: PropTypes.bool,
  journeyElementId: PropTypes.string,
  journeyElementType: PropTypes.string,
};

export default hot(module)(RefreshBooking);
