import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { connect } from 'react-redux';
import { fetchAirlineInformations } from '../../actions/flight';
import LogoChip from './LogoChip';
import AirlinesPopup from './AirlinesPopup';
import { airlineCodeItemShape, airlineInformationItemShape } from './shapes';

const AirlineStandardLogos = ({
  className,
  airlineInformations,
  carriers,
  codes,
  onFetchAirlineInformations,
  maximumVisibleLogosCount,
}) => {
  const availableCarriers = [...new Set(carriers)];

  const visibleLogos = availableCarriers
    .slice(0, maximumVisibleLogosCount)
    .filter(code => airlineInformations[code])
    .map(code => airlineInformations[code]);

  const visibleCodes = codes.slice(0, maximumVisibleLogosCount);

  const hiddenLogos = availableCarriers.length - maximumVisibleLogosCount;
  const hasHiddenLogos = hiddenLogos > 0;

  const hiddenCodes = codes.length - maximumVisibleLogosCount;
  const hasHiddenCodes = hiddenCodes > 0;

  useEffect(() => {
    availableCarriers.forEach(carrierCode => {
      if (!airlineInformations[carrierCode]) {
        onFetchAirlineInformations(carrierCode);
      }
    });
  }, []);

  return (
    <div className={className}>
      {visibleLogos.map(item => (
        <LogoChip key={item.logo} logo={item.logo} />
      ))}
      {hasHiddenLogos && visibleLogos.length > 0 && (
        <AirlinesPopup title={hiddenLogos} codes={codes} />
      )}
      {visibleLogos.length > 0 && (
        <div className="d-flex align-center">
          <div className="flight__airline-summary--separator">|</div>
          {visibleCodes.map(item => (
            <div key={item.code} className="d-flex align-center flight__airline-summary--segment">
              <Icon name="aeroplane" />
              <span className="code">{item.code}</span>
            </div>
          ))}
          {hasHiddenCodes && visibleCodes.length > 0 && (
            <AirlinesPopup title={hiddenCodes} showAirlineCode={true} codes={codes} />
          )}
        </div>
      )}
    </div>
  );
};

AirlineStandardLogos.defaultProps = {
  className: null,
  maximumVisibleLogosCount: 3,
};

AirlineStandardLogos.propTypes = {
  className: PropTypes.string,
  carriers: PropTypes.arrayOf(PropTypes.string).isRequired,
  codes: PropTypes.arrayOf(PropTypes.shape(airlineCodeItemShape)).isRequired,
  airlineInformations: PropTypes.shape(airlineInformationItemShape).isRequired,
  onFetchAirlineInformations: PropTypes.func.isRequired,
  maximumVisibleLogosCount: PropTypes.number,
};

const mapStateToProps = state => ({
  airlineInformations: state.flights.airlineInformations,
});

const mapDispatchToProps = dispatch => ({
  onFetchAirlineInformations: code => dispatch(fetchAirlineInformations(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AirlineStandardLogos);
