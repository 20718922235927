/* global I18n */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@wtag/rcl-icon';
import { Spinner } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Input from '@wtag/rcl-input';
import Badge from '@wtag/rcl-badge';
import AppContext from '../AppContext';
import SanitizedHTML from '../../SanitizedHTML';
import MarkDown from '../MarkDown';
import RoomListEmptyPlaceholder from '../RoomListEmptyPlaceholder';

import {
  AGENCY,
  TRAVEL_ARRANGER,
  TRAVEL_ARRANGEMENT_CLIENTS,
  AGENCY_NOTIFICATIONS,
} from './categories';
import TimeAgo from '../../TimeAgo';

class RoomList extends Component {
  constructor(props) {
    super(props);

    this.laymanMode = this.props.context.laymanMode;

    this.state = {
      searchText: null,
    };
  }

  getAgencyCategoryRooms = () => {
    const customerRooms = this.filterByRoomType(AGENCY, this.props.rooms);
    const notificationChannel = this.filterByRoomType(AGENCY_NOTIFICATIONS, this.props.rooms);
    return [...customerRooms, ...notificationChannel];
  };

  getTravelArrangingCategoryRooms = () => {
    if (this.laymanMode) {
      const travelArrangerCategory = this.filterByRoomType(TRAVEL_ARRANGER, this.props.rooms);
      const travelArrangementClientCategory = this.filterByRoomType(
        TRAVEL_ARRANGEMENT_CLIENTS,
        this.props.rooms,
      );

      return [...travelArrangerCategory, ...travelArrangementClientCategory];
    }

    return [];
  };

  getNotifications = room => {
    if (
      room.messageUnseen.length + room.messageMentions.length === 0 ||
      !this.props.showNotifications
    ) {
      return null;
    }
    const count = this.countNotifications(room);
    if (count === 0) return null;
    let showNotifications = count;
    if (count > 9) {
      showNotifications = '9+';
    }
    return <span className="chat-list__notification">{showNotifications}</span>;
  };

  countNotifications = room => {
    const context = this.props.context;
    if (this.laymanMode) {
      const unseenMessageIds = new Set();
      room.messageUnseen.forEach(message => unseenMessageIds.add(message.messageId));
      room.messageMentions.forEach(message => unseenMessageIds.add(message.messageId));
      return unseenMessageIds.size;
    }

    return room.messageMentions.filter(mention => mention.userId === context.currentUserId).length;
  };

  agencyRooms = () => this.sortRooms(this.getAgencyCategoryRooms());

  travelArrangingRooms = () => this.sortRooms(this.getTravelArrangingCategoryRooms());

  searchHandler(query) {
    this.setState({
      searchText: query && query.toLowerCase(),
    });
  }

  matchingRooms = rooms => {
    if (this.state.searchText) {
      return rooms.filter(room => {
        const searchValue = room.title.toLowerCase();
        return searchValue.indexOf(this.state.searchText) !== -1;
      });
    }
    return rooms;
  };

  filterByRoomType = category => this.props.rooms.filter(room => room.type === category);

  handleRoomClick = room => {
    this.props.handleRoomClick(room);
  };

  listingRooms = rooms => {
    const roomSet = new Set();
    const duplicateRoomIds = [];

    const filterRooms = rooms.filter(room => {
      const duplicateRoom = roomSet.has(room.uid);
      roomSet.add(room.uid);
      if (duplicateRoom) duplicateRoomIds.push(room.uid);
      if (!this.laymanMode && room.type === AGENCY_NOTIFICATIONS) {
        return false;
      }
      return !duplicateRoom;
    });

    const addTagTextIntoAnElement = message => {
      let messageWithoutHtmlTag = message.replace(/[\s]+ | <[^>]+>/g, '');
      messageWithoutHtmlTag = messageWithoutHtmlTag.replace(/[\n]/g, ' ');
      const updatedString = MarkDown(this.props.context.laymanMode).convert(messageWithoutHtmlTag);
      return updatedString;
    };

    const roomList = filterRooms.map(room => {
      const activeClass = this.props.activeRoom && this.props.activeRoom.uid === room.uid;

      const roomBasicInformation = this.props.context.roomBasicInformation(room);

      const isAllMessagesSeen = room.messageUnseen.length + room.messageMentions.length === 0;

      return (
        <li
          key={room.uid}
          onClick={() => this.handleRoomClick(room)}
          onKeyPress={() => this.handleRoomClick(room)}
          role="presentation"
        >
          <div
            className={classnames('chat-list__room', {
              'chat-list__room--with-unseen-messages':
                !isAllMessagesSeen && !this.props.context.isAlien,
            })}
          >
            <div className="chat-list__room-avatar">
              <Avatar
                firstName={roomBasicInformation.firstName}
                lastName={roomBasicInformation.lastName}
                showName={false}
                src={roomBasicInformation.avatarUrl}
                size="normal"
                badge={
                  <Badge
                    type={this.props.isUserOnline(room) ? 'active' : 'inactive'}
                    size="small"
                  />
                }
              />
            </div>
            <div className="chat-list__room-details">
              <div className="chat-list__room-details-header">
                <span className="chat-list__room-details-header-name">{room.title}</span>
                {room.lastMessage && (
                  <span className="chat-list__room-details-header-date">
                    <TimeAgo
                      datetime={new Date(parseInt(room.lastMessageTimestamp, 10))}
                      withRclDateComponent={true}
                      withShortFormat={true}
                    />
                  </span>
                )}
              </div>
              <div className="chat-list__room-details-message-box">
                {room.lastMessage && (
                  <span
                    className={classnames('chat-list__room-details-message-box-text', {
                      'chat-list__room-details-message-box-text--seen': isAllMessagesSeen,
                      'chat-list__room-details-message-box-text--unseen': !isAllMessagesSeen,
                    })}
                  >
                    <SanitizedHTML html={addTagTextIntoAnElement(room.lastMessage)} />
                  </span>
                )}
              </div>

              {!activeClass && this.getNotifications(room)}
            </div>
          </div>
        </li>
      );
    });
    return roomList;
  };

  sortRooms = rooms =>
    rooms.sort((roomA, roomB) => {
      if (roomA.lastMessageTimestamp.length === 0) {
        return 0;
      }
      return Number(roomA.lastMessageTimestamp) < Number(roomB.lastMessageTimestamp) ? 1 : -1;
    });

  roomSearchField = () => {
    const placeholder = this.laymanMode
      ? I18n.t('chat.search_people')
      : I18n.t('chat.search_travellers');
    return (
      <div className="chat__search-box">
        <Input
          className="chat__search chat__search--public input-field"
          type="text"
          value={this.state.searchText || ''}
          placeholder={placeholder}
          onChange={value => this.searchHandler(value)}
          preIcon={<Icon name="search" size="small" />}
          size="tiny"
        />
      </div>
    );
  };

  render() {
    const { context } = this.props;
    const agencyRoomlist = this.listingRooms(
      this.laymanMode ? this.agencyRooms() : this.matchingRooms(this.agencyRooms()),
    );
    const travelArrangingRoomList = this.listingRooms(
      this.matchingRooms(this.travelArrangingRooms()),
    );

    const isLoading = this.props.isLoading;

    const roomListEmptyPlaceholderContent = (
      <RoomListEmptyPlaceholder
        header={
          this.laymanMode
            ? I18n.t('chat.empty_card.contacts.header')
            : I18n.t('chat.empty_card.travellers.header')
        }
        message={
          this.laymanMode
            ? I18n.t('chat.empty_card.contacts.text')
            : I18n.t('chat.empty_card.travellers.text')
        }
      />
    );

    const agencyRoomListChatsSection = !this.laymanMode && agencyRoomlist.length > 0 && (
      <ul
        className={classnames('chat-list-holder', {
          'chat-channels-list-holder': this.laymanMode,
        })}
      >
        {agencyRoomlist}
      </ul>
    );

    const travelArrangingRoomListChatsSection = travelArrangingRoomList.length > 0 && (
      <ul className="chat-list-holder">{travelArrangingRoomList}</ul>
    );

    return (
      <Fragment>
        {isLoading ? (
          <div className="chat__rooms-loader">
            <Spinner size="huge" bgColor="neutral" />
          </div>
        ) : (
          <div className="chat-channels__box">
            <div className="chat__search-panel">
              {this.laymanMode && this.roomSearchField()}
              {!context.laymanMode && this.roomSearchField()}
            </div>
            <div className="chat__list-panel">
              {agencyRoomListChatsSection || travelArrangingRoomListChatsSection ? (
                <Fragment>
                  {agencyRoomListChatsSection}
                  {travelArrangingRoomListChatsSection}
                </Fragment>
              ) : (
                roomListEmptyPlaceholderContent
              )}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

RoomList.defaultProps = {
  activeRoom: null,
};

RoomList.propTypes = {
  context: PropTypes.shape().isRequired,
  activeRoom: PropTypes.shape(),
  handleRoomClick: PropTypes.func.isRequired,
  showNotifications: PropTypes.bool.isRequired,
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
      tenant: PropTypes.string,
      lastMessage: PropTypes.string,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          tenant: PropTypes.string,
          avatarURL: PropTypes.string,
          isOnline: PropTypes.bool,
          tenent: PropTypes.string,
        }),
      ),
      messageUnseen: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          messageId: PropTypes.number.isRequired,
          tenant: PropTypes.string.isRequired,
          roomUid: PropTypes.string.isRequired,
          userId: PropTypes.number.isRequired,
        }),
      ),
      messageMentions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          messageId: PropTypes.number.isRequired,
          tenant: PropTypes.string.isRequired,
          roomUid: PropTypes.string.isRequired,
          userId: PropTypes.number.isRequired,
        }),
      ),
    }),
  ).isRequired,
  isUserOnline: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const ListWithContext = props => (
  <AppContext.Consumer>{context => <RoomList context={context} {...props} />}</AppContext.Consumer>
);

export default ListWithContext;
