import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Input from '@wtag/rcl-input';
import Amount from 'sharedWebpack/Amount';
import ClassNames from 'classnames';
import debounce from 'throttle-debounce/debounce';

import './style.scss';

class Voucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucher: props.voucher.numberFormField,
    };
  }

  async componentDidMount() {
    const voucher = this.props.voucher.numberFormField;

    if (voucher) {
      await this.props.setVoucherInCart('');
      this.props.setVoucherInCart(voucher);
    }
  }

  setVoucherInCart = debounce(600, voucher => this.props.setVoucherInCart(voucher));

  setNumber = voucher => {
    this.setState({ voucher });
    this.setVoucherInCart(voucher);
  };

  getErrorMessage = () => {
    if (this.props.voucher.valid || this.props.voucher.contentInvalid === false) {
      return '';
    }

    return this.props.voucher.contentMessage;
  };

  text = () => {
    if (this.props.voucher.numberFormField && this.props.voucher.valid) {
      return (
        <Amount currency={this.props.voucher.currency} value={this.props.voucher.reductionAmount} />
      );
    }

    return '0.00';
  };

  discountAvailable = () => {
    if (this.props.voucher.numberFormField && this.props.voucher.valid) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div className="voucher">
        <div className="col-sm-7 col-bleed">
          <div className="voucher__title">
            <Icon name="voucher" showBGColor={true} color="success" size="tiny" />
            <span className="voucher__title-text">{I18n.t('ibe.payment.voucher.redeem')}</span>
          </div>
        </div>
        <div
          className={ClassNames('col-sm-5 col-bleed voucher__discount', {
            'voucher__discount--available': this.discountAvailable(),
          })}
        >
          {I18n.t('ibe.payment.voucher.discount')}{' '}
          <span className="voucher__discount-amount">{this.text()}</span>
        </div>
        <div className="col-12 col-bleed">
          <div className="col-4 col-bleed voucher__discount-input">
            <Input
              placeholder={I18n.t('ibe.payment.voucher.add_voucher')}
              size="tiny"
              value={this.state.voucher}
              onChange={this.setNumber}
              isClearable={false}
              touched={!!this.getErrorMessage()}
              error={this.getErrorMessage()}
            />
          </div>
        </div>
      </div>
    );
  }
}

Voucher.propTypes = {
  voucher: PropTypes.shape({
    number: PropTypes.string.isRequired,
    reductionType: PropTypes.oneOf(['amount', 'percentage']),
    amount: PropTypes.string,
    currency: PropTypes.string,
    percentage: PropTypes.number,
    reductionAmount: PropTypes.string,
    reductionCurrency: PropTypes.string,
    valid: PropTypes.bool,
    numberFormField: PropTypes.string,
    contentInvalid: PropTypes.bool,
    contentMessage: PropTypes.string,
  }).isRequired,
  setVoucherInCart: PropTypes.func.isRequired,
};

export default Voucher;
