/* global I18n */

import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

let csrfToken;
const csrfTokenElement = document.getElementsByName('csrf-token')[0];
if (csrfTokenElement) {
  csrfToken = csrfTokenElement.content;
}

export const axiosInstance = version => {
  const instance = axios.create({
    withCredentials: false,
    headers: {
      'Accept-Version': version,
      'X-CSRF-Token': csrfToken,
    },
  });

  // Enable to see AJAX requests (e.g. to be used in tests)
  // instance.interceptors.request.use(request => {
  //   console.log('Starting Request', request.url);
  //   return request;
  // });

  instance.defaults.transformRequest.push((data, headers) => {
    // This is the only way to inject the I18n locale to the header dynamically.
    // If we do it in headers: {} when creating axios instance it will fail sometimes
    // because of the JS loading order
    // eslint-disable-next-line no-param-reassign
    headers['X-User-Locale'] = I18n.locale;
    return data;
  });

  instance.defaults.transformResponse.push(data => {
    if (typeof data !== 'object' || data === null) {
      return data;
    }
    return camelcaseKeys(data, { deep: true });
  });

  return instance;
};

const myAxios = axiosInstance('v2');

export function generateSourceToken() {
  myAxios.cancelToken = axios.CancelToken;
  const CancelToken = myAxios.cancelToken;
  return CancelToken.source();
}

export function isCancelError(error) {
  return axios.isCancel(error);
}

export default myAxios;
