import { FETCHING_FLIGHT_MATRICES } from '../../actions/flight';

export default function isFetchingMatrices(state = false, action) {
  switch (action.type) {
    case FETCHING_FLIGHT_MATRICES:
      return action.isFetching;

    default:
      return state;
  }
}
