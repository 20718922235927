import React, { useState, useEffect, Fragment } from 'react';
import SelectBox from '@wtag/rcl-select-box';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import SocialMediaCard from './SocialMediaCard';
import './style.scss';

const SocialMediaSection = ({ type, id, socialMediaOptions, layman }) => {
  const [mediaName, setMediaName] = useState(null);
  const [mediaUrl, setMediaUrl] = useState('');
  const [error, setError] = useState({});
  const [socialMedia, setSocialMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const ownerKey = `${type}_id`;

  const fetchSocialMediaUrl = layman
    ? routes.public.socialMedia.list()
    : routes.admin.socialMedia.list({ [ownerKey]: id });

  const createSocialMediumUrl = layman
    ? routes.public.socialMedia.create()
    : routes.admin.socialMedia.create({ [ownerKey]: id });

  const mediaOptions = socialMediaOptions
    ? socialMediaOptions.map(medium => ({
        value: medium,
        label: I18n.t(medium, {
          scope: 'social_media',
        }),
      }))
    : [];

  const fetchSocialMedia = async () => {
    const { data } = await httpClient.get(fetchSocialMediaUrl);

    setSocialMedia(data.socialMedia);
    setIsLoading(false);
  };

  const createSocialMedium = async () => {
    setError({});
    const socialMediumParams = {
      media: mediaName,
      url: mediaUrl,
    };

    const { data } = await httpClient.post(createSocialMediumUrl, {
      social_medium: socialMediumParams,
    });

    if (data.error === null) {
      setMediaName(null);
      setMediaUrl('');
      fetchSocialMedia();
    }
  };

  const onCreateSocialMedium = () =>
    createSocialMedium().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  const onMediaNameChange = selectedOption => {
    setMediaName(selectedOption && selectedOption.value);
    setError({});
    setMediaUrl('');
  };

  useEffect(() => {
    if (type && id) {
      fetchSocialMedia();
    }
  }, [type, id]);

  return (
    <div className="col-grid col-bleed align-center">
      <div className="col-12 traveller-edit-social-section traveller-edit-social">
        <div className="col-grid direction-row col-bleed align-center justify-space-between">
          <div className="traveller-edit-social-section__header">
            {I18n.t('admin.components.travellers.edit.social.header')}
          </div>
        </div>
        <div className="col-12 col-bleed traveller-edit-social__form">
          <div className="grid">
            <div className="col-sm-10 col-12">
              <div className="grid">
                <div className="col-sm-6">
                  <SelectBox
                    className="social-media-section__select-box"
                    label={I18n.t('admin.components.travellers.edit.social.label.social_media')}
                    placeholderText={I18n.t(
                      'admin.components.travellers.edit.social.placeholder.social_media',
                    )}
                    required={true}
                    options={mediaOptions}
                    size="tiny"
                    onChange={selectedOption => onMediaNameChange(selectedOption)}
                    width="full"
                    errorMsg={error.media}
                    isClearable={false}
                    value={
                      mediaName
                        ? {
                            label: mediaOptions.find(
                              selectedMedia => selectedMedia.value === mediaName,
                            ).label,
                            value: mediaName,
                          }
                        : ''
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <Input
                    size="tiny"
                    label={I18n.t('admin.components.travellers.edit.social.label.url')}
                    placeholder={I18n.t(
                      'admin.components.organizations.edit.social.placeholder.url',
                    )}
                    width="full"
                    value={mediaUrl}
                    onChange={value => setMediaUrl(value)}
                    error={error.url}
                    required={true}
                    touched={!!error.url}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-sm-2 col-grid justify-end traveller-edit-social__button-add">
              <Button
                label={I18n.t('admin.shared.action.add')}
                version="v2"
                size="small"
                type={layman ? 'primary' : 'tertiary'}
                onClick={onCreateSocialMedium}
                disabled={!(mediaName && mediaUrl)}
              />
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="col-12 traveller-edit-social-section traveller-edit-social">
          <ContentLoaderPlaceholder numberOfLines={3} isRounded={true} showBackground={false} />
        </div>
      ) : (
        <Fragment>
          {socialMedia.length > 0 ? (
            socialMedia.map(medium => (
              <SocialMediaCard
                key={medium.id}
                socialMediaID={medium.id}
                ownerKey={ownerKey}
                ownerId={id}
                socialMediaName={medium.media}
                url={medium.url}
                socialMediaOptions={mediaOptions}
                fetchSocialMedia={fetchSocialMedia}
                layman={layman}
              />
            ))
          ) : (
            <div className="col-12 direction-row traveller-edit-social-section traveller-edit-social traveller-edit-social__placeholder">
              <Icon name="web" size="large" color="tertiary" showBGColor={true} />
              <div className="traveller-edit-social__explanation traveller-edit-social__explanation">
                {I18n.t('admin.components.travellers.edit.social.no_url')}
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

SocialMediaSection.defaultProps = {
  layman: false,
};

SocialMediaSection.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  socialMediaOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  layman: PropTypes.bool,
};

export default SocialMediaSection;
