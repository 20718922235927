import {
  REMOVE_FEE_FROM_CART,
  CART_FETCHED,
  DEAL_FETCHED,
  APPROVAL_REQUEST_FETCHED,
} from '../../actions/common';

export default function fees(state = [], action) {
  const newState = new Map();

  const addFees = feesToAdd => feesToAdd.forEach(fee => newState.set(fee.id, fee));

  addFees(state);

  switch (action.type) {
    case REMOVE_FEE_FROM_CART:
      newState.delete(action.id);
      break;

    case CART_FETCHED:
      newState.clear();
      addFees(action.cart.fees);
      break;

    case DEAL_FETCHED:
      addFees(action.deal.fees);
      break;

    case APPROVAL_REQUEST_FETCHED:
      addFees(action.approvalRequest.fees);
      break;

    default:
      break;
  }

  const returnedState = [];
  newState.forEach((fee, id) => returnedState.push({ id, ...fee }));

  return returnedState;
}
