import { connect } from 'react-redux';
import memoizeLocationQuery from '../../helpers/memoizeLocationQuery';
import fetchOnUpdate from '../../lib/decorators/fetchOnUpdate';
import {
  fetchHotelSearchResults,
  filterHotelSearch,
  resetHotelSearchFilters,
  resetHotelSearchFiltersByKey,
} from '../../actions/hotel';

import Results from './Results';

const mapStateToProps = (state, ownProps) => {
  const searchId = ownProps.match.params.searchId;
  const locationQuery = memoizeLocationQuery(ownProps.location.search);
  const results = state.hotels.searchResultsBySearchId[searchId] || [];
  const currency =
    (results.length && results[0].currency) ||
    (state.hotels.defaultHotelSearchParams && state.hotels.defaultHotelSearchParams.currency) ||
    '';
  const selectedItemResultIds = state.common.cart.items.map(
    item => item.bookingAttributes.resultId,
  );

  let searchParamsId = 'lastSearch';

  if (searchId && !locationQuery.cached && state.hotels.searchParamsBySearchId[searchId]) {
    searchParamsId = searchId;
  }

  const searchInformation = state.hotels.searchParamsBySearchId[searchParamsId];

  return {
    results,
    stats: state.hotels.searchStatsBySearchId[searchId],
    filterParams: state.hotels.searchFilterParamsBySearchId[searchId],
    searchYieldedResults: state.hotels.totalResultsBySearchId[searchId] > 0,
    searchCompleted: !!state.hotels.searchResultsBySearchId[searchId],
    currency,
    searchInformation,
    selectedItemResultIds,
    laymanMode: ownProps.laymanMode,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchResults: searchId => dispatch(fetchHotelSearchResults({ searchId })),
  filterResults: (filter, value) =>
    dispatch(filterHotelSearch(filter, value, ownProps.match.params.searchId)),
  resetFilters: stats => dispatch(resetHotelSearchFilters(ownProps.match.params.searchId, stats)),
  resetFiltersByKey: stats => {
    dispatch(resetHotelSearchFiltersByKey(ownProps.match.params.searchId, stats));
  },
});

const ResultsWithFetchOnUpdate = fetchOnUpdate(['searchId'], (params, props) => {
  if (params.searchId) {
    props.fetchResults(params.searchId);
  }
})(Results);

export default connect(mapStateToProps, mapDispatchToProps)(ResultsWithFetchOnUpdate);
