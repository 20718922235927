import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { I18nText, AnimatedProgressbar } from '@wtag/react-comp-lib';

const BookingProgressBar = props => {
  const { isBookingPendingByThirdParty } = props;

  const [isTakingMoreThanThreeMinutes, setIsTakingMoreThanThreeMinutes] = useState(false);

  const bookingProgressBarHeader = () => {
    if (!isBookingPendingByThirdParty) {
      return <I18nText id="ibe.booking.in_progress.title" returnStringOnly={true} />;
    } else if (isTakingMoreThanThreeMinutes) {
      return (
        <I18nText
          id="ibe.booking.in_progress.more_than_three_minutes.title"
          returnStringOnly={true}
        />
      );
    }

    return (
      <I18nText
        id="ibe.booking.in_progress.in_between_three_minutes.title"
        returnStringOnly={true}
      />
    );
  };

  const bookingProgressBarText = () => {
    if (!isBookingPendingByThirdParty) {
      return <I18nText id="ibe.booking.in_progress.text" returnStringOnly={true} />;
    } else if (isTakingMoreThanThreeMinutes) {
      return (
        <I18nText
          id="ibe.booking.in_progress.more_than_three_minutes.text"
          returnStringOnly={true}
        />
      );
    }

    return (
      <I18nText
        id="ibe.booking.in_progress.in_between_three_minutes.text"
        returnStringOnly={true}
      />
    );
  };

  const initiallyCompleted = () => {
    if (!isBookingPendingByThirdParty || isTakingMoreThanThreeMinutes) {
      return 90;
    }

    return 70;
  };

  const bgColor = () => {
    if (!isBookingPendingByThirdParty || !isTakingMoreThanThreeMinutes) {
      return 'success';
    }

    return 'warning';
  };

  useEffect(() => {
    let timer;

    if (isBookingPendingByThirdParty) {
      timer = setTimeout(() => {
        setIsTakingMoreThanThreeMinutes(true);
      }, 3 * 60 * 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="booking-progress-indicator">
      <Card version="v2" className="booking-progress-indicator__card">
        <p className="booking-progress-indicator__card-header">{bookingProgressBarHeader()}</p>
        <p className="booking-progress-indicator__card-body">{bookingProgressBarText()}</p>
        <AnimatedProgressbar
          size="small"
          bgColor={bgColor()}
          initialyCompleted={initiallyCompleted()}
          animationSpeed={15000}
        />
      </Card>
    </div>
  );
};

BookingProgressBar.propTypes = {
  isBookingPendingByThirdParty: PropTypes.bool.isRequired,
};

export default BookingProgressBar;
