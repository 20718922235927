import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoizeLocationQuery from '../../helpers/memoizeLocationQuery';
import {
  selectFlightResult,
  fetchFlightMatrixes,
  addFlightsFromResultToCart,
  fetchFlightSearchResults,
  fetchExchangeQuote,
  confirmExchangeQuote,
  fetchAirportDetails,
  fetchAirlineInformations,
  fetchFlightSearchParams,
  fetchAircraftType,
} from '../../actions/flight';
import MatrixComponent from './Matrix';

const mapStateToProps = (state, ownProps) => {
  const { searchId } = ownProps.match.params;
  const searchParams = state.flights.searchParamsBySearchId[searchId];
  const lastSearchParams = state.flights.searchParamsBySearchId.lastSearch;
  const currentSearchParams = searchParams || lastSearchParams;

  const props = {
    selectedResults: state.flights.selectedResults,
    matrixesByType: state.flights.matrixesBySearchId[searchId],
    airports: state.common.airports,
    exchangeQuote: state.flights.currentExchangeQuote,
    exchangeQuoteStatus: state.flights.exchangeQuoteStatus,
    airlineInformations: state.flights.airlineInformations,
    aircraftInformation: state.flights.aircraftTypes,
    fetchingFlightMatrices: state.flights.fetchingFlightMatrices,
    isFetchingMatrices: state.flights.isFetchingMatrices,
    tabKeyWithSelectedFareOption: state.flights.tabKeyWithSelectedFareOption,
    people: state.common.people,
  };

  if (currentSearchParams && Object.keys(currentSearchParams).length > 0) {
    const { adults, children, infants, totalTravelerCount } = currentSearchParams;
    props.people = { adults, children, infants };
    props.totalTravelerCount = totalTravelerCount;
  } else {
    props.people = { adults: 1, children: 0, infants: 0 };
    props.totalTravelerCount = 1;
  }

  if (searchParams && searchParams.trips) {
    props.trips = searchParams.trips;
  } else {
    props.trips = [];
  }
  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectResult: (searchId, matrix, result, tabKeyOfSelectedFairOption) =>
    dispatch(
      selectFlightResult(
        searchId,
        matrix,
        result,
        tabKeyOfSelectedFairOption,
        ownProps.exchange.bookingIdentifier,
      ),
    ),
  addSelectedFlightsToCart: justification => {
    const locationQuery = memoizeLocationQuery(ownProps.location.search);

    return dispatch(
      addFlightsFromResultToCart(
        ownProps.match.params.searchId,
        locationQuery.deal,
        locationQuery.dealGroup,
        ownProps.callbackParams,
        justification,
      ),
    );
  },
  fetchAirportDetails: code => dispatch(fetchAirportDetails(code)),
  fetchAirlineInformations: code => dispatch(fetchAirlineInformations(code)),
  fetchAircraftType: code => dispatch(fetchAircraftType(code)),
  fetchMatrixes: (searchId, flightGroups, selectedResults) =>
    dispatch(fetchFlightMatrixes({ searchId, flightGroups, selectedResults })),
  fetchResults: (searchId, tripId) => dispatch(fetchFlightSearchResults({ searchId, tripId })),
  fetchExchangeQuote: (exchangeIdentifer, resultId) =>
    dispatch(fetchExchangeQuote(exchangeIdentifer, resultId)),
  confirmExchangeQuote: () => dispatch(confirmExchangeQuote()),
  fetchSearchParams: searchId => dispatch(fetchFlightSearchParams({ searchId })),
});

const ConnectedMatrix = connect(mapStateToProps, mapDispatchToProps)(MatrixComponent);

const MatrixWithContext = (props, context) => <ConnectedMatrix {...props} {...context} />;

MatrixWithContext.contextTypes = {
  currency: PropTypes.string.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  exchange: PropTypes.shape().isRequired,
  laymanMode: PropTypes.bool.isRequired,
};

export default MatrixWithContext;
