import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MatrixContext from '../../contexts/MatrixContext';
import './matrix-card.styles.scss';

const MatrixCard = props => {
  const {
    width,
    children,
    className,
    onClick,
    isSelected,
    isCheapest,
    isClickable,
    matrixId,
  } = props;
  const {
    relatedTripsHashMap,
    activeTripId,
    activeTrip,
    activeTabKey,
    setRelatedTripsHashMap,
    setTemporarySelectedTrips,
    setTtripsWithSelectedFareBrand,
    laymanMode,
    autoSelectionMode,
    setAutoSelectionMode,
  } = useContext(MatrixContext);

  const resetTripIdsForInactiveTabs = prevTrips => {
    const newTrips = { ...prevTrips };

    Object.keys(newTrips).forEach(key => {
      if (key !== activeTabKey) {
        newTrips[key] = [];
      }
    });

    return newTrips;
  };

  // this function will be invoked when an option gets selected in a trip
  // Will set the trip id as firstSelectedTripId of all the related trips if there is no value has set in firstSelectedTripId
  // It's for tracking which trips is selected first among a group of related trips (semi)
  const setFirstSelectedTripId = selectedTripId => {
    setRelatedTripsHashMap(prevMap => {
      const newMap = { ...prevMap };

      if (!newMap[activeTrip.id].firstSelectedTripId) {
        newMap[activeTrip.id].firstSelectedTripId = selectedTripId;

        newMap[activeTrip.id].relatedTrips.forEach(tripId => {
          newMap[tripId].firstSelectedTripId = selectedTripId;
        });
      }

      return newMap;
    });
  };

  const resetSelectionInRelatedTripsHashMap = () => {
    setRelatedTripsHashMap(prevMap => {
      const newMap = { ...prevMap };

      Object.keys(newMap).forEach(tripId => {
        newMap[tripId].firstSelectedTripId = undefined;
      });

      return newMap;
    });
  };

  const selectFareOption = () => {
    onClick();
    setTemporarySelectedTrips(resetTripIdsForInactiveTabs);
    setTtripsWithSelectedFareBrand(resetTripIdsForInactiveTabs);

    if (activeTabKey === 'all') {
      resetSelectionInRelatedTripsHashMap();
    } else {
      setFirstSelectedTripId(activeTrip.id);
    }
  };

  const onClickHandler = () => {
    if (isClickable && !isSelected) {
      selectFareOption();
      // As user clicked a result of his/her choice
      // we are disabling the autoSelectionMode
      setAutoSelectionMode(false);
    }
  };

  useEffect(() => {
    if (
      matrixId === activeTrip.matrixId &&
      isClickable &&
      activeTrip.hasOneFareBrandOption &&
      autoSelectionMode
    ) {
      selectFareOption();
    }
  }, [
    isClickable,
    activeTabKey,
    activeTripId,
    activeTrip.hasOneFareBrandOption,
    activeTrip.matrixId,
    activeTrip.fareBrands,
  ]);

  useEffect(() => {
    if (
      matrixId === activeTrip.matrixId &&
      isClickable &&
      laymanMode &&
      isCheapest &&
      autoSelectionMode
    ) {
      selectFareOption();
    }
  }, [
    isClickable,
    isCheapest,
    activeTabKey,
    activeTripId,
    activeTrip.matrixId,
    activeTrip.fareBrands,
  ]);

  useEffect(() => {
    // If firstSelectedTripId of all the related trips is set to trip 1 but somehow the api gets failed to select the trip as active
    // Then to keep sync with the api response, firstSelectedTripId of all the related trips will be set to undefined forcefully
    if (relatedTripsHashMap.firstSelectedTripId === activeTrip.id && !isSelected) {
      setFirstSelectedTripId(undefined);
    }
  }, [isSelected]);

  return (
    <div
      className={classNames('matrix-card', className)}
      role="button"
      tabIndex={0}
      style={{ width: `${width}px` }}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
    >
      {children}
    </div>
  );
};

MatrixCard.defaultProps = {
  className: null,
  width: 280,
  children: null,
  onClick: () => {},
  isSelected: false,
  isCheapest: false,
  isClickable: false,
};

MatrixCard.propTypes = {
  width: PropTypes.number,
  matrixId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isCheapest: PropTypes.bool,
  isClickable: PropTypes.bool,
};

export default MatrixCard;
