import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Tooltip from '@wtag/rcl-tooltip';

const SocialMediaShow = ({ socialMediaLinks }) => {
  const socialMediaIcon = media => {
    const icons = {
      facebook: 'facebookRectangular',
      youtube: 'youtubeRectangular',
      twitter: 'twitterRectangular',
      linkedin: 'linkedinRectangular',
      pinterest: 'pinterestRectangular',
      instagram: 'instagramRectangular'
    };

    return icons[media] || 'web';
  };
  return (
    <React.Fragment>
      {socialMediaLinks.map(socialMedium => (
        <div className="traveller-overview-left-panel__link-icon">
          <a href={socialMedium.url} target="_blank" rel="noreferrer">
            <Tooltip
              content={
                <div className="col-grid col-bleed">
                  <div className="traveller-overview-left-panel__links-header">
                    {I18n.t(`admin.components.travellers.overview.left_panel.links.${socialMedium.media}`)}
                  </div>
                  <div className="traveller-overview-left-panel__link">{socialMedium.url}</div>
                </div>
              }
              position="bottom-left"
              type="inverse"
              arrowWidth={0}
              gap={5}
            >
              <Icon
                name={socialMediaIcon(socialMedium.media)}
                iconLabel={socialMedium.url}
                size="medium"
              />
            </Tooltip>
          </a>
        </div>
      ))}
    </React.Fragment>
  );
};

SocialMediaShow.defaultProps = {
  socialMediaLinks: [],
};

SocialMediaShow.propTypes = {
  socialMediaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

export default SocialMediaShow;
