import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@wtag/rcl-avatar';
import { hot } from 'react-hot-loader';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './styles.scss';

const Testimonial = ({ testimonialData, primaryColor }) => {
  const data = testimonialData.map(testimonialInformation => (
    <div
      className="col-grid col-bleed testimonial-carousel-card"
      key={testimonialInformation.customerImage}
    >
      <div className="col-lg-2 col-md-2 col-12 col-bleed testimonial-carousel-card__author">
        <div
          className="testimonial-carousel-card__author-avatar-arc"
          style={{ borderTopColor: primaryColor }}
        >
          <Avatar
            className= "testimonial-carousel-card__author-avatar"
            size="xxl"
            firstName={testimonialInformation.customerFirstName}
            lastName={testimonialInformation.customerLastName}
            src={testimonialInformation.customerImage}
          />
        </div>
      </div>
      <div className="col-lg-8 col-md-8 col-12 testimonial-carousel-card__data">
        {testimonialInformation.testimonialTitle && (
          <div className="testimonial-carousel-card__data-title">
            {testimonialInformation.testimonialTitle}
          </div>
        )}
        <div className="testimonial-carousel-card__data-content">
          {testimonialInformation.testimonialText}
        </div>
        <div className="testimonial-carousel-card__data-customer">
          <div className="testimonial-carousel-card__data-customer-name">
            {`${testimonialInformation.customerFirstName} ${testimonialInformation.customerLastName}`}
          </div>
          {testimonialInformation.customerOrganization && (
            <div className="testimonial-carousel-card__data-customer-organization">
              {testimonialInformation.customerOrganization}
            </div>
          )}
        </div>
      </div>
    </div>
  ));

  const settings = {
    infiniteLoop: true,
    stopOnHover: false,
    transitionTime: 1000,
    interval: 5000,
    autoPlay: true,
    emulateTouch: true,
    showThumbs: false,
    showArrows: false,
    showStatus: false,
  };

  return (
    <div className="testimonial">
      <div className="col-grid col-bleed direction-row justify-space-between align-center testimonial-heading">
        <div className="testimonial-heading__title"> {I18n.t('testimonial.title')} </div>
        <hr className="testimonial-heading__hr" />
      </div>
      <div className="testimonial-carousel">
        <Carousel {...settings}>{data}</Carousel>
      </div>
    </div>
  );
};

Testimonial.defaultProps = {
  testimonialData: null,
};

Testimonial.propTypes = {
  testimonialData: PropTypes.arrayOf(PropTypes.shape),
  primaryColor: PropTypes.string.isRequired,
};

export default hot(module)(Testimonial);
