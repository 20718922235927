import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import {
  availableFormOfPaymentShape,
  creditCardItemShape,
} from 'sharedWebpack/CreditCardVaultPayment/shapes';

const accountShape = {
  account: PropTypes.string,
  identifier: PropTypes.string,
};

const amountShape = {
  amount: PropTypes.string,
  currency: PropTypes.string,
};

export const availableChannelsShape = {
  car: PropTypes.arrayOf(PropTypes.string),
  flights: PropTypes.arrayOf(PropTypes.string),
  hotels: PropTypes.arrayOf(PropTypes.string),
};

export const countryCodeShape = PropTypes.shape([PropTypes.string, PropTypes.string]);

export const callbackParamsShape = {
  all_traveler_ids: PropTypes.arrayOf(PropTypes.string),
  layman: PropTypes.bool,
  order_id: PropTypes.string,
  person_id: PropTypes.string,
};

export const searchAccountsShape = {
  cars: PropTypes.shape({
    [PropTypes.string]: {
      credentials: PropTypes.arrayOf({
        allowance: PropTypes.number,
        code: PropTypes.arrayOf(PropTypes.string),
        identifier: PropTypes.string,
      }),
      ndc: PropTypes.bool,
    },
  }),
};

export const globalPolicyPreferenceShape = {
  airlines: PropTypes.arrayOf(PropTypes.shape()),
  carRentalCompanies: PropTypes.arrayOf(PropTypes.shape()),
};

export const initialSearchParamsShape = {
  flights: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        origin: PropTypes.string,
        destination: PropTypes.string,
        date: PropTypes.string,
        cabinClass: PropTypes.string,
      }),
    ),
  }),
};

export const searchInputConfigurationShape = {
  showDepartureTime: PropTypes.bool,
  showPlusMinusThreeDays: PropTypes.bool,
  showNonStop: PropTypes.bool,
  showAirlinePreference: PropTypes.bool,
  showOnlyFlexibleFlight: PropTypes.bool,
  showBookingClass: PropTypes.bool,
  showAlliancePreferences: PropTypes.bool,
  showOnlyBagIncludedFlight: PropTypes.bool,
  showExcludeCodeShareFlights: PropTypes.bool,
  enableSearchItineraryOnly: PropTypes.bool,
  whitelistedAirports: PropTypes.arrayOf(PropTypes.string),
};

export const exchangeShape = {
  enabled: PropTypes.bool.isRequired,
  type: requiredIf(PropTypes.string, props => props.exchange && props.exchange.enabled),
  bookingId: requiredIf(PropTypes.number, props => props.exchange && props.exchange.enabled),
  bookingIdentifier: requiredIf(
    PropTypes.string,
    props => props.exchange && props.exchange.enabled,
  ),
  currentPrice: requiredIf(
    PropTypes.shape({
      net: PropTypes.shape(amountShape).isRequired,
      gross: PropTypes.shape(amountShape).isRequired,
      margin: PropTypes.shape(amountShape).isRequired,
    }),
    props => props.exchange && props.exchange.enabled,
  ),
};

export const cartItemShape = {
  approvalRequired: PropTypes.bool,
  available: PropTypes.bool,
  availableFormOfPayment: PropTypes.shape(availableFormOfPaymentShape),
  bookingAttributes: PropTypes.shape({
    resultId: PropTypes.string,
  }),
  cancelPenalty: PropTypes.shape(amountShape),
  cartId: PropTypes.string,
  changePenalty: PropTypes.shape(amountShape),
  channel: PropTypes.shape(accountShape),
  conversionInfo: PropTypes.shape({
    converted: PropTypes.bool,
    sourceAmount: PropTypes.string,
  }),
  currency: PropTypes.string,
  endsAt: PropTypes.string,
  formOfPayment: {
    cardDetails: PropTypes.shape(creditCardItemShape),
    type: PropTypes.string,
  },
  grossAmount: PropTypes.string,
  grossTaxes: PropTypes.string,
  grossTaxesIncludingSubItems: PropTypes.string,
  grossTotal: PropTypes.string,
  grossTotalIncludingSubItems: PropTypes.string,
  grossTotalMinorUnit: PropTypes.string,
  hasJourneyElement: PropTypes.bool,
  id: PropTypes.number,
  itemTitle: PropTypes.string,
  journeyElementId: PropTypes.number,
  journeyElementType: PropTypes.string,
  journeySummaryHuman: PropTypes.string,
  mainItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      currency: PropTypes.string,
      id: PropTypes.number,
      marginId: PropTypes.number,
      name: PropTypes.string,
      taxes: PropTypes.string,
      total: PropTypes.string,
    }),
  ),
  oldGrossAmount: PropTypes.string,
  oldGrossTaxes: PropTypes.string,
  oldGrossTotal: PropTypes.string,
  outOfPolicy: PropTypes.bool,
  outOfPolicyJustification: PropTypes.string,
  overAllGrossAmount: PropTypes.string,
  overAllGrossTax: PropTypes.string,
  pendingConfirmation: PropTypes.bool,
  preferred: PropTypes.bool,
  priceChanged: PropTypes.bool,
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quantity: PropTypes.string,
  search: PropTypes.shape({
    id: PropTypes.number,
    identifier: PropTypes.string,
  }),
  startsAt: PropTypes.string,
  subItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  supplier: PropTypes.shape(accountShape),
  totalMargin: PropTypes.string,
  type: PropTypes.string,
};

const facilityItemShape = PropTypes.shape({
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  currency: PropTypes.string,
  modifier: PropTypes.string,
  type: PropTypes.string,
});

const carShape = {
  acrissCode: PropTypes.string,
  category: PropTypes.string,
  condition: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  drivenWheel: PropTypes.string,
  imageUrl: PropTypes.shape({
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  }),
  make: PropTypes.string,
  name: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  type: PropTypes.string,
};

const carLocationShape = {
  localtime: PropTypes.string,
  localtimeHour: PropTypes.string,
  location: PropTypes.string,
};

export const carDetailsShape = {
  id: PropTypes.string,
  facilities: PropTypes.shape({
    airbag: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
    airConditioned: PropTypes.bool,
    doors: PropTypes.string,
    fuelPolicy: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
    fuelType: PropTypes.string,
    insurances: PropTypes.arrayOf(facilityItemShape),
    liability: PropTypes.arrayOf(facilityItemShape),
    otherFacilities: PropTypes.arrayOf(facilityItemShape),
    mileage: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
        currency: PropTypes.string,
        modifier: PropTypes.string,
        packageSize: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    seats: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
    vendor: PropTypes.shape({
      imageUrl: PropTypes.shape({
        url: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
      }),
    }),
    laymanMode: PropTypes.bool,
    car: PropTypes.shape(carShape),
    pickUp: PropTypes.shape(carLocationShape),
    dropOff: PropTypes.shape(carLocationShape),
    currency: PropTypes.string,
    ratePerDay: PropTypes.string,
    source: PropTypes.shape({
      account: PropTypes.string,
      supplier: PropTypes.string,
    }),
    corporateDeal: PropTypes.bool,
    labels: PropTypes.shape({
      outOfPolicy: PropTypes.bool,
      preferred: PropTypes.bool,
    }),
    policiesReasons: PropTypes.shape({}),
    approvalRequired: PropTypes.bool,
    payLater: PropTypes.bool,
  }),
};
