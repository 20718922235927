import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hot } from 'react-hot-loader';
import ItemWrapper from 'sharedWebpack/ItemWrapper/new';
import { Link } from 'react-router-dom';
import { I18nText } from '@wtag/react-comp-lib';
import QuantitySelector from '@wtag/rcl-quantity-selector';
import withJourneyElement from '../../lib/decorators/withJourneyElement';
import CarItemLabels from './car/CarItemLabels';
import ItemLabels from './ItemLabels';
import ItemPricing from './ItemPricing';
import ItemRemoveAndSearch from './ItemRemoveAndSearch';
import TripSummaryDetails from '../../TripSummaryDetails';
import CarDetails from '../../CarDetails';
import CartItemLabels from './CartItemLabels';
import OutOfPolicyJustificationEdit from './OutOfPolicyJustificationEdit';
import staticProductShape from '../../Search/static/shapes/staticProduct';
import { TYPE_CAR, TYPE_FLIGHT, TYPE_HOTEL } from '../../../helpers/itemTypes';
import getTripType from '../../lib/helpers/getTripType';
import { ROUNDTRIP } from '../../lib/helpers/tripTypes';
import BookingLabelWithHint from '../../SearchResult/flight/BookingLabelWithHint';
import searchURL from '../../lib/helpers/searchURL';
import ChannelListTag from '../../helpers/ChannelListTag';
import { WELLTRAVEL } from '../../lib/helpers/welltravel_channel';
import HotelCartItemHeader from './HotelCartItemHeader';
import HotelSummaryDetails from '../../HotelSummaryDetails';
import './styles.scss';

const PRODUCT_TYPE_INSURANCE = 'insurance';

const RemoveItem = ({
  journeyElementType,
  item,
  isCartLocked,
  available,
  status,
  removeFromCart,
  journeyElement,
  pendingConfirmation,
  confirmAddition,
  staticProduct,
  setStaticProductQuantity,
  id,
  quantity,
  frozen,
}) => {
  const { type, search } = item;

  if ([TYPE_FLIGHT, TYPE_HOTEL, TYPE_CAR].includes(journeyElementType)) {
    return (
      <span
        className={classNames(
          {
            'item__wrapper-result-link--car': journeyElementType === TYPE_CAR,
          },
          'item__wrapper-result-link col-grid',
        )}
      >
        <Link to={searchURL(type, search.identifier)}>
          {I18n.t('components.ibe.results.back_to_result')}
        </Link>
      </span>
    );
  }

  return (
    <ItemRemoveAndSearch
      isCartLocked={isCartLocked}
      available={available}
      item={item}
      itemStatus={status}
      removeFromCart={removeFromCart}
      confirmAddition={confirmAddition}
      journeyElement={journeyElement}
      showConfirmButton={pendingConfirmation}
      leftSide={
        staticProduct &&
        staticProduct.productType === PRODUCT_TYPE_INSURANCE && (
          <div className="col-grid col-bleed direction-row item__wrapper-selector">
            <p>
              <I18nText id="ibe.summary.travelers" returnStringOnly={true} />
            </p>
            <QuantitySelector
              onChange={value => setStaticProductQuantity(id, value)}
              value={item && parseInt(quantity, 10)}
              disabled={frozen}
              size="tiny"
              min={1}
            />
          </div>
        )
      }
    />
  );
};

const getFareBrandTitle = tripWithSegments => {
  if (!Array.isArray(tripWithSegments)) {
    return null;
  }

  const { segments } = tripWithSegments;

  if (segments.length === 0) {
    return null;
  }

  return segments[0].fareBrand;
};

const getAirportMapFromArray = trips => {
  if (!Array.isArray(trips)) {
    return null;
  }

  const airportsMap = {};
  const airportsArrayList = trips.map(tripItem => tripItem.airports);

  airportsArrayList
    .flatMap(airportArrayItem => airportArrayItem)
    .forEach(airportItem => {
      airportsMap[airportItem.code] = airportItem;
    });

  return airportsMap;
};

const getLocationByAirportCode = (airportsList, airportCode) => {
  if (!airportsList && !airportCode) {
    return null;
  }

  const airportDetails = airportsList[airportCode];

  return `${airportDetails.placeName}, ${airportDetails.countryName}`;
};

const getTripFinalDestinationCode = (trips, tripType) => {
  if (!tripType && !Array.isArray(trips)) {
    return null;
  }

  if (trips.length === 2 && tripType === ROUNDTRIP) {
    return trips[0].destinationCode;
  }

  return trips[trips.length - 1].destinationCode;
};

const CartItemWrapper = (props, context) => {
  const {
    isCartLocked,
    item,
    journeyElement,
    itemNo,
    flightServicesByResultId,
    flightResultDetailsById,
    showServices,
    showFlightSeatsStatus,
    removeFromCart,
    confirmAddition,
    staticProducts,
    setStaticProductQuantity,
    cartStatus,
    setOutOfPolicyJustification,
    travelerCount,
    shouldLockScroll,
    setShouldLockScroll,
    travelers,
  } = props;

  const {
    bookingAttributes,
    productId,
    pendingConfirmation,
    journeyElementType,
    id,
    type,
    available,
    approvalRequired,
    quantity,
    journeyElementId,
    availableFormOfPayment,
    outOfPolicy,
    outOfPolicyJustification,
    channel,
  } = item;

  const carJourneyElementItem =
    journeyElementType === TYPE_CAR && journeyElement ? journeyElement : {};
  const { street, city, countryCode, stars } = journeyElement || {};
  const { identifier, account } = channel;
  const { trips, originCode } = journeyElement || {};
  const { vendorName, pickUp } = carJourneyElementItem;
  const { location: pickUpLocation } = pickUp || {};
  const isWeltravelChannel = identifier && identifier.toLowerCase() === WELLTRAVEL;
  const supplierChannelName = I18n.t(identifier, { scope: 'channels' });

  const channelIdentifier = isWeltravelChannel
    ? identifier
    : [supplierChannelName, account].filter(value => value).join(' ');

  const staticProduct = staticProducts.fetchedStaticProducts
    ? staticProducts.fetchedStaticProducts.find(product => product.id === productId)
    : null;

  const status =
    cartStatus && cartStatus.cartItems && cartStatus.cartItems.find(cartItem => cartItem.id === id);

  const travelerCountEntries = travelerCount && Object.values(travelerCount);
  const totalTravelerCount = travelerCountEntries
    ? travelerCountEntries
        .map(currentItem => currentItem.length)
        .reduce((previousItem, currentItem) => previousItem + currentItem)
    : 1;

  const tripType = getTripType(trips);
  const tripFinalDestinationCode = getTripFinalDestinationCode(trips, tripType);
  const airportsList = getAirportMapFromArray(trips);
  const originCountryDetails = getLocationByAirportCode(airportsList, originCode);
  const destinationCountryDetails = getLocationByAirportCode(
    airportsList,
    tripFinalDestinationCode,
  );
  const tripItemWithSegments = trips && trips.find(({ segments }) => segments.length > 0);
  const fareBrandTitle = getFareBrandTitle(tripItemWithSegments);

  const itemDetails =
    bookingAttributes &&
    flightResultDetailsById &&
    flightResultDetailsById[bookingAttributes.resultId];

  const isRefundable = journeyElement && journeyElement.refundable;
  const hasInstantTicket = itemDetails && itemDetails.instantTicket;
  const hasBookingLabelHints = approvalRequired || isRefundable || hasInstantTicket;

  const getItemLabelContent = () => {
    if (journeyElementType === TYPE_FLIGHT) {
      return (
        <div className="d-flex justify-between align-center">
          <CartItemLabels
            isCartLocked={isCartLocked}
            item={item}
            itemNo={itemNo}
            journeyElement={journeyElement}
            removeFromCart={removeFromCart}
            itemDetails={itemDetails}
            flightServicesByResultId={flightServicesByResultId}
            travelers={travelers}
          />
          {trips && (
            <div className="item__wrapper-price-sm">
              <ItemPricing
                type={journeyElementType || type}
                available={itemDetails && itemDetails.available}
                item={item}
                journeyElement={journeyElement}
                itemStatus={status}
                pendingConfirmation={pendingConfirmation}
              />
            </div>
          )}
        </div>
      );
    }

    if (journeyElementType === TYPE_HOTEL) {
      return (
        <HotelCartItemHeader
          isCartLocked={isCartLocked}
          item={item}
          itemNo={itemNo}
          journeyElement={journeyElement}
          removeFromCart={removeFromCart}
          itemDetails={itemDetails}
          fareBrandTitle={fareBrandTitle}
          status={status}
        />
      );
    }

    if (journeyElementType === TYPE_CAR) {
      return (
        <div className="d-flex">
          <CarItemLabels
            isCartLocked={isCartLocked}
            item={item}
            itemNo={itemNo}
            journeyElement={journeyElement}
            removeFromCart={removeFromCart}
            itemDetails={itemDetails}
            flightServicesByResultId={flightServicesByResultId}
            travelers={travelers}
          />
          <div className="car-item__wrapper-price-sm">
            <ItemPricing
              fareBrandTitle={fareBrandTitle}
              removePrefixText={true}
              type={journeyElementType || type}
              available={available}
              item={item}
              itemStatus={status}
              pendingConfirmation={pendingConfirmation}
              travelerCount={travelerCount}
            />
          </div>
        </div>
      );
    }

    return (
      <ItemLabels
        item={item}
        itemNo={itemNo}
        available={available}
        cartStatus={cartStatus}
        journeyElement={journeyElement}
        itemStatus={status}
        showServices={showServices}
        flightServicesByResultId={flightServicesByResultId}
        flightResultDetailsById={flightResultDetailsById}
        staticProduct={staticProduct}
        pendingConfirmation={pendingConfirmation}
        travelerCount={travelerCount}
        shouldLockScroll={shouldLockScroll}
        setShouldLockScroll={setShouldLockScroll}
        travelers={travelers}
      />
    );
  };

  const itemLabelContent = getItemLabelContent();

  return (
    <Fragment>
      <div
        className={classNames('col-12', 'item__wrapper', {
          'item__wrapper--highlighted': pendingConfirmation,
        })}
      >
        {itemLabelContent}
        <div className="item__wrapper--separator" />
        {trips && (
          <div className="d-flex align-start justify-between item__wrapper--trip-summary-details">
            <TripSummaryDetails
              trips={trips}
              airports={airportsList}
              text={I18n.t('components.flight_info.origin_to_destination', {
                origin: originCountryDetails,
                destination: destinationCountryDetails,
              })}
            />
            <div className="item__wrapper-price-lg">
              <ItemPricing
                fareBrandTitle={fareBrandTitle}
                type={journeyElementType || type}
                available={itemDetails && itemDetails.available}
                item={item}
                journeyElement={journeyElement}
                itemStatus={status}
                pendingConfirmation={pendingConfirmation}
                travelerCount={travelerCount}
              />
            </div>
          </div>
        )}
        {journeyElementType === TYPE_CAR && (
          <div className="d-flex align-center justify-between item__wrapper--trip-summary-details">
            <CarDetails vendorName={vendorName} location={pickUpLocation} />
            <div className="car-item__wrapper-price">
              <ItemPricing
                fareBrandTitle={fareBrandTitle}
                removePrefixText={true}
                type={journeyElementType || type}
                available={available}
                item={item}
                journeyElement={journeyElement}
                itemStatus={status}
                pendingConfirmation={pendingConfirmation}
                travelerCount={travelerCount}
              />
            </div>
          </div>
        )}
        {journeyElementType === TYPE_HOTEL && (
          <div className="d-flex justify-between align-end item__wrapper-hotel-summary">
            <HotelSummaryDetails
              street={street}
              city={city}
              countryCode={countryCode}
              stars={stars}
              item={item}
              journeyElement={journeyElement}
              status={status}
            />
          </div>
        )}
        {hasBookingLabelHints && (
          <div className="column-gap-12 flex-wrap item__wrapper__booking-label-hint item__wrapper-price-sm">
            {approvalRequired && (
              <BookingLabelWithHint
                iconName="singlePolicy"
                iconColor="danger"
                text={<I18nText id="ibe.search_result.approval_required" />}
              />
            )}
            {isRefundable && (
              <BookingLabelWithHint
                iconName="checkCircleOutline"
                iconColor="success"
                text={<I18nText id="ibe.cart_item_pricing.refundable" />}
              />
            )}
            {hasInstantTicket && (
              <BookingLabelWithHint
                iconName="eTicket"
                iconColor="warning"
                text={<I18nText id="instant_issue" />}
                hint={<I18nText id="instant_ticket.description" />}
              />
            )}
          </div>
        )}
        {showServices && staticProduct && staticProduct.productType !== PRODUCT_TYPE_INSURANCE && (
          <div className="col-grid col-bleed-x direction-row justify-end item__wrapper-selector">
            <p>
              <I18nText id="ibe.summary.quantity" returnStringOnly={true} />
            </p>
            <QuantitySelector
              onChange={value => setStaticProductQuantity(id, value)}
              value={item && parseInt(quantity, 10)}
              disabled={context.frozen}
              size="tiny"
              min={1}
            />
          </div>
        )}
        {(journeyElementId || productId) && (
          <ItemWrapper
            item={item}
            flightServicesByResultId={flightServicesByResultId}
            showFlightSeatsStatus={showFlightSeatsStatus}
            trips={trips}
            journeyElementId={journeyElementId}
            journeyElementType={journeyElementType}
            fetchedJourneyElement={journeyElement}
            productId={productId}
            totalTravelerCount={totalTravelerCount}
            payLater={availableFormOfPayment && availableFormOfPayment.none.supported}
            laymanMode={context.laymanMode}
            shouldLockScroll={shouldLockScroll}
            setShouldLockScroll={setShouldLockScroll}
            flightResultDetailsById={flightResultDetailsById}
            displayCarFacilitiesItems={true}
          />
        )}
        {outOfPolicy && (
          <OutOfPolicyJustificationEdit
            itemId={id}
            justificationText={outOfPolicyJustification}
            setJustification={setOutOfPolicyJustification}
            editable={true}
            title={I18n.t('components.ibe.out_of_policy_reason.header')}
          />
        )}
        {showServices && (
          <RemoveItem
            journeyElementType={journeyElementType}
            item={item}
            isCartLocked={isCartLocked}
            available={available}
            status={status}
            removeFromCart={removeFromCart}
            journeyElement={journeyElement}
            pendingConfirmation={pendingConfirmation}
            confirmAddition={confirmAddition}
            staticProduct={staticProduct}
            setStaticProductQuantity={setStaticProductQuantity}
            id={id}
            quantity={quantity}
            frozen={context.frozen}
          />
        )}
        {!context.laymanMode && identifier && (
          <div
            className={classNames('d-flex justify-end flight__channel-tag', {
              'flight__channel-tag--welltravel': isWeltravelChannel,
            })}
          >
            <ChannelListTag identifier={channelIdentifier} type={isWeltravelChannel && 'accent'} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

CartItemWrapper.contextTypes = {
  allowBookingOfUnapprovedItems: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  offerOnly: PropTypes.bool.isRequired,
  travelerHasOrganization: PropTypes.bool.isRequired,
  frozen: PropTypes.bool.isRequired,
};

CartItemWrapper.defaultProps = {
  isCartLocked: false,
  showFlightSeatsStatus: false,
};

CartItemWrapper.propTypes = {
  isCartLocked: PropTypes.bool,
  cartStatus: PropTypes.shape({
    cartItems: PropTypes.arrayOf({}),
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    approvalRequired: PropTypes.string,
    refundable: PropTypes.bool,
    type: PropTypes.string,
    identifier: PropTypes.string,
    title: PropTypes.string,
    channel: PropTypes.string,
    journeyElementId: PropTypes.number,
    journeyElementType: PropTypes.string,
    journeySummaryHuman: PropTypes.string,
    productType: PropTypes.string,
    hasBooking: PropTypes.bool,
    bookingId: PropTypes.string,
    bookingHasMultipleItem: PropTypes.bool,
    flightTypeItem: PropTypes.bool,
    productId: PropTypes.string,
    journeyElement: PropTypes.shape({}),
    status: PropTypes.string,
    available: PropTypes.bool,
    outOfPolicy: PropTypes.bool,
    outOfPolicyJustification: PropTypes.string,
    quantity: PropTypes.string,
    availableFormOfPayment: PropTypes.shape({
      none: PropTypes.shape({
        supported: PropTypes.bool.isRequired,
        supportedBySupplier: PropTypes.bool.isRequired,
      }),
    }),
    bookingAttributes: PropTypes.shape({
      resultId: PropTypes.number.isRequired,
    }),
    pendingConfirmation: PropTypes.bool.isRequired,
    search: PropTypes.shape({
      identifier: PropTypes.string,
    }),
    itemTitle: PropTypes.string,
  }).isRequired,
  itemNo: PropTypes.number.isRequired,
  journeyElement: PropTypes.shape({
    originCode: PropTypes.string,
    destinationCode: PropTypes.string,
    productType: PropTypes.string,
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        segments: PropTypes.arrayOf(
          PropTypes.shape({
            aircraftType: PropTypes.string.isRequired,
            destinationCode: PropTypes.string.isRequired,
            operatingCarrierCode: PropTypes.string.isRequired,
            operatingFlightNumber: PropTypes.string.isRequired,
            originCode: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }).isRequired,
    ).isRequired,
    fareRulePresent: PropTypes.bool.isRequired,
    fareBasisInfo: PropTypes.arrayOf(PropTypes.shape({})),
    refundable: PropTypes.bool,
  }).isRequired,
  available: PropTypes.bool.isRequired,
  setStaticProductQuantity: PropTypes.func.isRequired,
  showServices: PropTypes.bool.isRequired,
  flightServicesByResultId: PropTypes.shape({}).isRequired,
  flightResultDetailsById: PropTypes.shape([]).isRequired,
  removeFromCart: PropTypes.func.isRequired,
  staticProducts: PropTypes.arrayOf(staticProductShape).isRequired,
  confirmAddition: PropTypes.func.isRequired,
  setOutOfPolicyJustification: PropTypes.func.isRequired,
  showFlightSeatsStatus: PropTypes.bool,
  travelerCount: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  shouldLockScroll: PropTypes.bool.isRequired,
  setShouldLockScroll: PropTypes.func.isRequired,
  travelers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

RemoveItem.propTypes = {
  journeyElementType: PropTypes.string.isRequired,
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    search: PropTypes.shape({
      identifier: PropTypes.string,
    }),
  }).isRequired,
  isCartLocked: PropTypes.bool.isRequired,
  available: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  journeyElement: PropTypes.shape({}).isRequired,
  pendingConfirmation: PropTypes.bool.isRequired,
  confirmAddition: PropTypes.bool.isRequired,
  staticProduct: PropTypes.shape({
    productType: PropTypes.string.isRequired,
  }).isRequired,
  setStaticProductQuantity: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  quantity: PropTypes.string.isRequired,
  frozen: PropTypes.bool.isRequired,
};

export default withJourneyElement(hot(module)(CartItemWrapper));
