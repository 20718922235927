import React, { useState, Fragment } from 'react';
import SelectBox from '@wtag/rcl-select-box';
import Button from '@wtag/rcl-button';
import { CheckBox, PhoneNumber } from '@wtag/react-comp-lib';
import PropTypes from 'prop-types';

import './styles.scss';

const PhoneNumberForm = ({
  phoneCountryCodes,
  fetchData,
  createPhoneNumber,
  phoneNumberTypeOptions,
  onCloseForm,
}) => {
  const [numberType, setNumberType] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [number, setNumber] = useState(null);
  const [numberPrimary, setNumberPrimary] = useState(false);
  const [numberError, setNumberError] = useState({});

  const resetPhoneFields = () => {
    setNumberType(null);
    setCountryCode(null);
    setNumber(null);
    setNumberPrimary(false);
    setNumberError({});
  };

  const onCreatePhoneNumber = async () => {
    const params = {
      number_type: numberType,
      number: countryCode && countryCode.value + number,
      primary: numberPrimary,
    };

    createPhoneNumber(params)
      .then(data => {
        if (data.error === null) {
          resetPhoneFields();
          fetchData();
        }
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setNumberError(response.data.error);
        }
      });
  };

  return (
    <Fragment>
      <div className="col-12 col-bleed-x">
        <div className="grid">
          <div className="col-md-6 edit-email-and-phone__no-left-padding">
            <SelectBox
              label={I18n.t(
                'admin.components.organizations.edit.email_and_phone.phone.label.phone_type',
              )}
              options={phoneNumberTypeOptions}
              isClearable={false}
              size="tiny"
              onChange={selectedNumberType => setNumberType(selectedNumberType.value)}
              width="full"
              value={
                numberType && {
                  label: I18n.t(numberType, {
                    scope: 'phone_numbers.types',
                  }),
                  value: numberType,
                }
              }
              errorMsg={numberError.numberType}
              required={true}
            />
          </div>
          <div className="col-md-6">
            <PhoneNumber
              options={phoneCountryCodes}
              size="tiny"
              label={
                <span className="required-field">
                  {I18n.t(
                    'admin.components.organizations.edit.email_and_phone.phone.label.phone_number',
                  )}
                </span>
              }
              onChange={setNumber}
              onCountryChange={option => setCountryCode(option)}
              error={numberError.number}
              selectedCountry={countryCode}
              inputValue={number || ''}
              isInputTouched={numberError.number !== null}
              countryPlaceholder="(+41)"
            />
          </div>
          <div className="col-12 edit-email-and-phone__no-left-padding">
            <CheckBox
              name="edit-email-and-phone__field--checkbox-phone"
              label={I18n.t(
                'admin.components.organizations.edit.email_and_phone.phone.label.primary',
              )}
              checked={numberPrimary}
              onChange={event => setNumberPrimary(event.target.checked)}
            />
          </div>
        </div>
      </div>
      <div className="edit-section__btn-group">
        <Button
          version="v2"
          size="small"
          label={I18n.t('admin.components.organizations.edit.email_and_phone.phone.label.add')}
          type="primary"
          onClick={onCreatePhoneNumber}
        />
        <Button
          version="v2"
          size="small"
          label={I18n.t('admin.components.shared.action.cancel')}
          type="default"
          onClick={onCloseForm}
        />
      </div>
    </Fragment>
  );
};

PhoneNumberForm.propTypes = {
  phoneNumberTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  phoneCountryCodes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  createPhoneNumber: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  onCloseForm: PropTypes.func.isRequired,
};

export default PhoneNumberForm;
