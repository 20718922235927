import React, { useState, useEffect, Fragment } from 'react';
import SelectBox from '@wtag/rcl-select-box';
import IconButton from '@wtag/rcl-icon-button';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import PropTypes from 'prop-types';

const SocialMediaCard = ({
  socialMediaName,
  url,
  socialMediaOptions,
  socialMediaID,
  fetchSocialMedia,
  ownerKey,
  ownerId,
  layman,
}) => {
  const [selectedSocialMedia, setSelectedSocialMedia] = useState(null);
  const [mediaUrl, setMediaUrl] = useState('');
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState({});

  const ICON_MAPPER = {
    twitter: 'twitterRectangular',
    facebook: 'facebookRectangular',
    linkedin: 'linkedinRectangular',
    youtube: 'youtubeRectangular',
    pinterest: 'pinterestRectangular',
    instagram: 'instagramRectangular'
  };

  const updateSocialMediaUrl = layman
    ? routes.public.socialMedia.update({ socialMediaID })
    : routes.admin.socialMedia.update({ [ownerKey]: ownerId, socialMediaID });
  const deleteSocialMediaUrl = layman
    ? routes.public.socialMedia.delete({ socialMediaID })
    : routes.admin.socialMedia.delete({ [ownerKey]: ownerId, socialMediaID });

  const updateSocialMedium = async () => {
    setError({});
    const socialMediumParams = {
      media: selectedSocialMedia,
      url: mediaUrl,
    };
    const { data } = await httpClient.patch(updateSocialMediaUrl, {
      social_medium: socialMediumParams,
    });

    if (data.error === null) {
      fetchSocialMedia();
      setEditing(false);
    }
  };

  const onUpdateSocialMedium = () =>
    updateSocialMedium().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  const deleteSocialMedium = async () => {
    const { data } = await httpClient.delete(deleteSocialMediaUrl);
    if (data.error === null) {
      fetchSocialMedia();
      setEditing(false);
    }
  };

  const onDeleteSocialMedium = () =>
    deleteSocialMedium().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  const renderEditForm = () => {
    let form;
    if (editing) {
      form = (
        <div className="col-12 col-bleed traveller-edit-social">
          <div className="col-6 col-bleed traveller-edit-social__field--with-margin-right">
            <SelectBox
              label={I18n.t('admin.components.travellers.edit.social.label.social_media')}
              options={socialMediaOptions}
              isClearable={false}
              size="tiny"
              width="full"
              value={
                selectedSocialMedia
                  ? {
                      label: socialMediaOptions.find(media => media.value === selectedSocialMedia)
                        .label,
                      value: selectedSocialMedia,
                    }
                  : ''
              }
              onChange={selectedOption =>
                selectedOption && setSelectedSocialMedia(selectedOption.value)
              }
              errorMsg={error.media}
              required={true}
            />
          </div>
          <div className="col-6 col-bleed">
            <Input
              label={I18n.t('admin.components.travellers.edit.social.label.url')}
              size="tiny"
              width="full"
              value={mediaUrl}
              onChange={value => setMediaUrl(value || '')}
              error={error.url}
              touched={!!error.url}
              required={true}
            />
          </div>
        </div>
      );
    }
    return form;
  };

  const setInitialValues = () => {
    setSelectedSocialMedia(socialMediaName);
    setMediaUrl(url);
    setEditing(false);
  };

  useEffect(() => {
    setInitialValues();
  }, []);

  return (
    <div className="col-12 traveller-edit-social-section traveller-edit-social">
      <div className="col-grid direction-row col-bleed align-center justify-space-between">
        {editing ? (
          <div className="traveller-edit-section__sub-header traveller-edit-social__confirm-update">
            {I18n.t('admin.components.travellers.edit.social.sub_header')}
          </div>
        ) : (
          <div className="col-grid col-bleed direction-row align-center traveller-edit-social__information">
            <Icon
              name={ICON_MAPPER[socialMediaName] || 'web'}
              size="normal"
              color="tertiary"
              showBGColor={false}
            />
            <span className="traveller-edit-social__media-name">{socialMediaName}</span>
            <span className="traveller-edit-social__url">{url}</span>
          </div>
        )}
        <div className="col-2 col-bleed traveller-edit-social__actions">
          {editing ? (
            <div className="traveller-edit-social__confirm-update">
              <IconButton
                icon={<Icon name="check" />}
                color="tertiary"
                size="small"
                onClick={onUpdateSocialMedium}
              />
              <IconButton
                icon={<Icon name="close" />}
                color="tertiary"
                size="small"
                onClick={() => setInitialValues()}
              />
            </div>
          ) : (
            <Fragment>
              <IconButton
                icon={<Icon name="delete" />}
                color="tertiary"
                size="small"
                onClick={onDeleteSocialMedium}
              />
              <IconButton
                icon={<Icon name="edit" />}
                color="tertiary"
                size="small"
                onClick={() => setEditing(true)}
              />
            </Fragment>
          )}
        </div>
      </div>
      {renderEditForm()}
    </div>
  );
};

SocialMediaCard.defaultProps = {
  layman: false,
};

SocialMediaCard.propTypes = {
  socialMediaName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  fetchSocialMedia: PropTypes.func.isRequired,
  ownerKey: PropTypes.string.isRequired,
  ownerId: PropTypes.number.isRequired,
  socialMediaID: PropTypes.number.isRequired,
  socialMediaOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
  layman: PropTypes.bool,
};

export default SocialMediaCard;
