import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

export const JOURNEY_ELEMENT_FETCHED = 'JOURNEY_ELEMENT_FETCHED';
function journeyElementFetched(type, id, data) {
  return {
    type: JOURNEY_ELEMENT_FETCHED,
    element: { type, id, data },
  };
}

const fetchProcesses = {};
export function fetchJourneyElement(type, id, refresh = false) {
  return (dispatch, getState) => {
    const fetchedJourneyElement = getState().common.journeyElements[type][id];
    if (fetchedJourneyElement && !refresh) {
      return Promise.resolve(fetchedJourneyElement);
    }

    const key = `${type}-${id}`;
    if (!fetchProcesses[key]) {
      fetchProcesses[key] = httpClient
        .get(routes.api.fetchJourneyElement({ type, id }))
        .then(({ data }) => {
          delete fetchProcesses[key];
          dispatch(journeyElementFetched(type, id, data));
          return data;
        });
    }

    return fetchProcesses[key];
  };
}
