import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';

const CarPriceWithLabel = ({ label, currency, price }) => (
  <Fragment>
    {label && <span className="item__pricing-from-text">{label}</span>}
    <Amount className="item__pricing-unit-price-value" currency={currency} value={price} />
  </Fragment>
);

CarPriceWithLabel.defaultProps = {
  label: null,
};

CarPriceWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  currency: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default CarPriceWithLabel;
