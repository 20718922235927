import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { reportToRollbar } from '../common';

export const AIRCRAFT_TYPE_FETCHED = 'AIRCRAFT_TYPE_FETCHED';
function aircraftTypeFetched(code, aircraftType) {
  return {
    type: AIRCRAFT_TYPE_FETCHED,
    code,
    aircraftType,
  };
}

const fetchedAircrafts = {};
export const FETCHING_AIRCRAFT_TYPE = 'FETCHING_AIRCRAFT_TYPE';
export function fetchAircraftType(code) {
  return dispatch => {
    if (fetchedAircrafts[code]) {
      return null;
    }
    fetchedAircrafts[code] = true;

    dispatch({
      type: FETCHING_AIRCRAFT_TYPE,
      code,
    });

    const url = routes.api.aircraftType({ code });
    return httpClient
      .get(url)
      .then(({ data }) => dispatch(aircraftTypeFetched(code, data)))
      .catch(error => {
        if (error.response.status === 404) {
          return null;
        }
        return dispatch(reportToRollbar("Aircraft's information couldn't be fetched", error));
      });
  };
}
