import { CONFIRMATION_MAIL_RECIPIENT_SEARCH_COMPLETE, UPDATE_CONFIRMATION_MAIL_RECIPIENTS} from '../../actions/common';

const initialState = { recipients: [], recipientSearchResults: [] };
export default function confirmationMailRecipients(state = initialState, action) {
  switch (action.type) {
    case CONFIRMATION_MAIL_RECIPIENT_SEARCH_COMPLETE:
      return { ...state, recipientSearchResults: [...action.customersWithMail] };
    case UPDATE_CONFIRMATION_MAIL_RECIPIENTS:
      return { ...state, recipients: [...action.recipients] };
    default:
      return state;
  }
}
