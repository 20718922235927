const NEXT_BUTTON_LABEL_KEY = 'next';
const TRY_AGAIN_BUTTON_LABEL_KEY = 'try_again';
const AUTHENTICATE_NEXT_CARD_BUTTON_LABEL_KEY = 'authenticate_next_card';
const NEW_TOKEN_KEY = 'new';

export {
  NEXT_BUTTON_LABEL_KEY,
  TRY_AGAIN_BUTTON_LABEL_KEY,
  AUTHENTICATE_NEXT_CARD_BUTTON_LABEL_KEY,
  NEW_TOKEN_KEY,
};
