import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import { Link } from 'react-router-dom';
import { Header, Text } from '@wtag/rcl-typography';
import Button from '@wtag/rcl-button';
import Amount from 'sharedWebpack/Amount';
import MatrixContext from '../contexts/MatrixContext';
import searchURL from '../../../../IBE/lib/helpers/searchURL';
import './stepper-control-bar.styles.scss';

const StepperControllerBar = props => {
  const {
    setActiveStepIndex,
    activeTabKey,
    allResultLink,
    currency,
    totalTrips,
    activeStepIndex,
    activeTripId,
    continueToCartButton,
    price,
    withStyles,
    windowWidth,
    searchId,
  } = props;
  const { isFetchingMatrices } = useContext(MatrixContext);
  const showPreviousButton = activeTabKey === 'customize';
  const showNextButton = activeTabKey !== 'all' && activeStepIndex <= totalTrips - 2;
  const nextTripId = activeTripId + 1;
  const notLastTrip = activeStepIndex !== totalTrips - 1;
  const notFirstTrip = activeStepIndex !== 0;
  const nextStepIndex = activeStepIndex + 1;
  const previousStepIndex = activeStepIndex - 1;
  const windowSizeForMobileView = 991;

  return (
    <div>
      {windowWidth > windowSizeForMobileView ? (
        <div
          className={classNames('matrix-stepper-control-bar', {
            'matrix-stepper-control-bar--with-styles': withStyles,
          })}
        >
          <Header level={5} weight="bold">
            {activeTabKey === 'all'
              ? I18n.t('components.ibe.results.step_title_for_package', {
                  step_index: nextStepIndex,
                })
              : I18n.t('components.ibe.results.step_title', {
                  step_index: nextStepIndex,
                  trip_id: activeTripId,
                })}
          </Header>
          <Text className="matrix-stepper-control-bar__price" level={3} weight="bold">
            {I18n.t('components.ibe.results.total_price')}
            <span
              className={classNames('matrix-stepper-control-bar__price-value', {
                'matrix-stepper-control-bar__price-value--zero': !price,
              })}
            >
              <Amount currency={currency} value={price} />
            </span>
          </Text>
          <div className="matrix-stepper-control-bar__divider" />
          {allResultLink}
          {showPreviousButton && (
            <Button
              className="matrix-stepper-control-bar__prev-button"
              version="v2"
              size="small"
              label={I18n.t('components.ibe.results.previous')}
              type="neutral"
              disabled={activeStepIndex === 0 || isFetchingMatrices}
              onClick={() => notFirstTrip && setActiveStepIndex(previousStepIndex)}
            />
          )}
          {/* As "all" tab only 2 steps (incl. "Continue to Cart"), so there is no need to render next button */}
          {/* steps.length = totalTrips + 1, and we want to hide the next button and show the "continue to cart" button in the last trip */}
          {showNextButton ? (
            <Button
              className="matrix-stepper-control-bar__next-button"
              version="v2"
              size="small"
              label={I18n.t('components.ibe.results.continue_to_trip', { trip_id: nextTripId })}
              type="primary"
              disabled={isFetchingMatrices}
              onClick={() => notLastTrip && setActiveStepIndex(nextStepIndex)}
            />
          ) : (
            continueToCartButton
          )}
        </div>
      ) : (
        <div>
          <div
            className={classNames('matrix-stepper-control-bar', {
              'matrix-stepper-control-bar--with-styles': withStyles,
            })}
          >
            <Text level={1} weight="bold">
              {activeTabKey === 'all'
                ? I18n.t('components.ibe.results.step_title_for_package', {
                    step_index: nextStepIndex,
                  })
                : I18n.t('components.ibe.results.step_title', {
                    step_index: nextStepIndex,
                    trip_id: activeTripId,
                  })}
            </Text>
            <div className="matrix-mobile-stepper__arrow-button">
              <Button
                className="matrix-stepper-control-bar__prev-button"
                version="v2"
                size="small"
                label={<Icon size="normal" name="arrowBackAlt" />}
                type="neutral"
                disabled={activeStepIndex === 0}
                onClick={() => notFirstTrip && setActiveStepIndex(previousStepIndex)}
              />
              {/* As "all" tab only 2 steps (incl. "Continue to Cart"), so there is no need to render next button */}
              {/* steps.length = totalTrips + 1, and we want to hide the next button and show the "continue to cart" button in the last trip */}
              {showNextButton ? (
                <Button
                  className="matrix-stepper-control-bar__next-button"
                  version="v2"
                  size="small"
                  label={<Icon size="normal" name="arrowForwardAlt" />}
                  type="primary"
                  onClick={() => notLastTrip && setActiveStepIndex(nextStepIndex)}
                />
              ) : (
                continueToCartButton
              )}
            </div>
          </div>
          <div className="matrix-mobile__price-container">
            <Link to={searchURL('flight', searchId)}>
              {' '}
              <Button
                className="matrix-mobile__Back-btn"
                size="small"
                label="Back to Results"
              />{' '}
            </Link>
            <div>
              <div className="matrix-mobile__total-price"> Total price </div>
              <div
                className={classNames('matrix-mobile__total-amount', {
                  'matrix-mobile__total-amount--zero': !price,
                })}
              >
                {' '}
                <Amount className="ueue" currency={currency} value={price} />{' '}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

StepperControllerBar.defaultProps = {
  withStyles: true,
};

StepperControllerBar.propTypes = {
  setActiveStepIndex: PropTypes.func.isRequired,
  allResultLink: PropTypes.node.isRequired,
  activeTabKey: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.string]).isRequired,
  currency: PropTypes.string.isRequired,
  totalTrips: PropTypes.number.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  activeTripId: PropTypes.number.isRequired,
  continueToCartButton: PropTypes.node.isRequired,
  withStyles: PropTypes.bool,
  windowWidth: PropTypes.number.isRequired,
  searchId: PropTypes.string.isRequired,
};

export default StepperControllerBar;
