import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import ModifierDetails from './ModifierDetails';

import './styles.scss';

const Facility = props => {
  const typeToIconMapper = {
    insurance: 'fareBrandIcon',
    facilities: 'bookingClass',
    liabilities: 'singlePolicy',
  };

  let iconColor;

  if (props.type === 'liabilities') {
    iconColor = 'warning';
  } else if (props.amount === '0.0') {
    iconColor = 'success';
  } else {
    iconColor = 'default';
  }

  return (
    <div className="col-grid direction-row align-center car-results__facility-row">
      <div className="car-result-card__facility-icon">
        <Icon
          name={typeToIconMapper[props.type]}
          size="normal"
          showBGColor={true}
          color={iconColor}
        />
      </div>
      <div className="col-grid col-bleed">
        <span className="car-result-card__facility-name">{props.facility}</span>
        <span className="car-result-card__facility-amount">
          <ModifierDetails amount={props.amount} currency={props.currency} modifierType={props.modifier}/>
        </span>
      </div>
    </div>
  );
};

Facility.defaultProps = {
  modifier: null,
  type: 'facilities',
};

Facility.propTypes = {
  facility: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  modifier: PropTypes.string,
  type: PropTypes.string,
};

export default Facility;
