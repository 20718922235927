import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const HotelLocation = ({ address }) => (
  <div className="hotel-location d-flex direction-column">
    <div className="hotel-location__text">
      {I18n.t('admin.components.orders.overview.travel_plan.hotel.header.location.label')}
    </div>
    <div className="hotel-location__address">{address}</div>
  </div>
);

HotelLocation.propTypes = { address: PropTypes.string.isRequired };

export default HotelLocation;
