import { useState, useEffect } from 'react';

const useElementSize = elementRef => {
  const [elementSize, setElementSize] = useState({
    width: elementRef && elementRef.current ? elementRef.current.offsetWidth : 0,
    height: elementRef && elementRef.current ? elementRef.current.offsetHeight : 0,
  });

  const listener = () => {
    if (elementRef && elementRef.current) {
      setElementSize({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    listener();
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return elementSize;
};

export default useElementSize;
