import React from 'react';
import { Link } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import PropTypes from 'prop-types';

import './styles.scss';

const renderNode = (buttonUrl, buttonText, node) => {
  if (buttonUrl) {
    return (
      <div className="heading__content-button">
        <Link type="anchor" size="small" modifier="default" href={buttonUrl}>
          <Button label={buttonText} version="v2" type="primary" size="small" />
        </Link>
      </div>
    );
  }
  return node;
};

const Heading = ({ buttonUrl, buttonText, currentNavigation, rightNode, leftNode, border }) => (
  <div className="heading">
    <div className="heading__content">
      <div className="heading__content-section">
        {leftNode || I18n.t(`public.application.account_navigation.${currentNavigation}`)}
      </div>
      <div className="heading__content-section">{renderNode(buttonUrl, buttonText, rightNode)}</div>
    </div>
    {border && <hr />}
  </div>
);

Heading.defaultProps = {
  buttonUrl: '',
  buttonText: '',
  leftNode: null,
  rightNode: null,
  border: true,
};

Heading.propTypes = {
  currentNavigation: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  leftNode: PropTypes.node,
  rightNode: PropTypes.node,
  border: PropTypes.bool,
};

export default Heading;
