const calculateSurcharge = charges => {
  const surcharge = 0.0;

  if (Array.isArray(charges)) {
    return charges.reduce((total, amount) => total + (parseFloat(amount) || surcharge), surcharge);
  }

  return surcharge;
};

export default calculateSurcharge;
