import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectBox from '@wtag/rcl-select-box';
import Input from '@wtag/rcl-input';
import { CheckBox } from '@wtag/react-comp-lib';
import moment from 'moment';
import ChooseFile from 'sharedWebpack/ChooseFile';
import DatePickerWithFocusedStateWrapper from 'sharedWebpack/DatePickerWithFocusedStateWrapper';

const IdentificationInformationForm = props => {
  const {
    documentId,
    documentTypes,
    countryOptions,
    nationalityOptions,
    type,
    country,
    nationality,
    number,
    validity,
    primary,
    primaryCheckDisabled,
    setType,
    setCountry,
    setNationality,
    setNumber,
    setValidity,
    setPrimary,
    identificationFileName,
    setIdentificationFileName,
    identificationFileType,
    setIdentificationFileType,
    identificationFileUploadPreviewUrl,
    setIdentificationFileUploadPreviewUrl,
    setIdentificationFile,
    setError,
    error,
  } = props;

  const typesWithRequiredValidity = ['passport', 'permanent_resident_card'];

  const idForCheckbox = useMemo(() => `checkbox-${documentId || new Date().toISOString()}`, [
    documentId,
  ]);
  const supportedFileTypes = /(image|application)\/(png|jpg|jpeg|gif|webp|pdf)/i;
  const documentTypeOptions = Object.keys(documentTypes).map(documentTypeKey => ({
    value: documentTypeKey,
    label: I18n.t(documentTypeKey, {
      scope: 'simple_form.options.person.identification_documents.document_type',
    }),
  }));
  const isValidityRequiredForType = typesWithRequiredValidity.includes(type);

  const findSelectBoxContent = (options, value) => {
    const selectedOption = options.filter(option => value === option.value)[0];
    if (selectedOption) {
      return {
        value: selectedOption.value,
        label: selectedOption.label,
      };
    }
    return null;
  };

  const handleIdentificationDocumentType = selectedOption => {
    setError({ identificationDocumentsFile: '' });
    setType(selectedOption ? selectedOption.value : '');
  };

  const handleSelectedFileStates = selectedFile => {
    setIdentificationFileType(selectedFile.type);
    setIdentificationFileName(selectedFile.name);
    setIdentificationFile(selectedFile);
    setIdentificationFileUploadPreviewUrl(URL.createObjectURL(selectedFile));
  };

  const clearSelectedFileStates = () => {
    setIdentificationFileName('');
    setIdentificationFileType('');
    setIdentificationFile(null);
    setIdentificationFileUploadPreviewUrl(null);
  };

  const selectFile = selectedFile => {
    if (selectedFile) {
      if (!selectedFile.type.match(supportedFileTypes)) {
        setError({
          identificationDocumentsFile: I18n.t('admin.identification_document.file_type_error_log'),
        });
        clearSelectedFileStates();
      } else {
        setError({ identificationDocumentsFile: '' });
        handleSelectedFileStates(selectedFile);
      }
    }
  };

  const removeFile = () => {
    setError({ identificationDocumentsFile: '' });
    clearSelectedFileStates();
  };

  return (
    <div className="col-12 col-bleed traveller-edit-identification-document-form traveller-edit-identification-document-form--with-no-top-bottom-margin">
      {documentId && (
        <div className="traveller-edit-identification-document-form__sub-header">
          {I18n.t('admin.identification_document.action.edit')}
        </div>
      )}
      <div className="grid">
        <div className="col-sm-6 col-lg-4">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.travellers.edit.identification_documents.placeholders.document_type',
            )}
            label={I18n.t('public.people.edit.document_type')}
            width="full"
            options={documentTypeOptions}
            value={findSelectBoxContent(documentTypeOptions, type)}
            onChange={handleIdentificationDocumentType}
            errorMsg={error.documentType}
            required={true}
          />
        </div>
        <div className="col-sm-6 col-lg-4">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.travellers.edit.identification_documents.placeholders.country',
            )}
            label={I18n.t('public.people.edit.country')}
            width="full"
            options={countryOptions}
            value={findSelectBoxContent(countryOptions, country)}
            onChange={selectedOption => setCountry(selectedOption ? selectedOption.value : '')}
            errorMsg={error.country}
            required={true}
          />
        </div>
        <div className="col-sm-6 col-lg-4">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.travellers.edit.identification_documents.placeholders.nationality',
            )}
            label={I18n.t('public.people.edit.nationality')}
            width="full"
            options={nationalityOptions}
            value={findSelectBoxContent(nationalityOptions, nationality)}
            onChange={selectedOption => setNationality(selectedOption ? selectedOption.value : '')}
            errorMsg={error.nationality}
            required={true}
          />
        </div>
        <div className="col-sm-6 col-lg-4">
          <Input
            size="tiny"
            placeholder={I18n.t(
              'admin.components.travellers.edit.identification_documents.placeholders.number',
            )}
            label={I18n.t('public.people.edit.number')}
            touched={!!error}
            error={error.number}
            value={number}
            onChange={value => setNumber(value)}
            required={true}
          />
        </div>
        <div className="col-lg-4">
          <DatePickerWithFocusedStateWrapper
            placeholder={I18n.t(
              'admin.components.travellers.edit.identification_documents.placeholders.validity',
            )}
            label={I18n.t('public.people.edit.validity')}
            size="tiny"
            futureYearsCount={100}
            required={isValidityRequiredForType}
            pastYearsCount={0}
            enablePastDates={false}
            fullWidth={true}
            id={Math.random()}
            date={validity}
            locale={moment().locale()}
            onChange={value => value && setValidity(moment(value).format('YYYY-MM-DD'))}
            error={error.validity}
            hideNavButtons={false}
          />
        </div>
        <div className="col-12 traveller-edit-identification-document-form__field traveller-edit-general-information-form__field--with-top-bottom-margin">
          <ChooseFile
            errorMsg={error.identificationDocumentsFile}
            showFileUploadPreviewUrl={true}
            fileUploadPreviewUrl={identificationFileUploadPreviewUrl}
            fileUploadPreviewModalTitle={I18n.t('admin.identification_document.modal.title')}
            fileName={identificationFileName}
            fileType={identificationFileType}
            selectFile={value => selectFile(value)}
            removeFile={removeFile}
            showRemoveFileButton={true}
          />
        </div>
        <div className="col-12 col-grid col-bleed-x direction-row">
          <CheckBox
            size="small"
            name={idForCheckbox}
            checked={primary}
            onChange={event => setPrimary(event.target.checked)}
            disabled={primaryCheckDisabled}
          />
          <span className="traveller-edit-identification-document-form__checkbox-label">
            {I18n.t('admin.components.travellers.edit.identification_documents.primary_label')}
          </span>
        </div>
      </div>
    </div>
  );
};

IdentificationInformationForm.defaultProps = {
  error: {},
  identificationFileName: null,
  identificationFileType: '',
  identificationFileUploadPreviewUrl: '',
  documentId: null,
};

IdentificationInformationForm.propTypes = {
  documentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  documentTypes: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  nationalityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  nationality: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  validity: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  primaryCheckDisabled: PropTypes.bool.isRequired,
  setType: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  setNationality: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  setValidity: PropTypes.func.isRequired,
  setPrimary: PropTypes.func.isRequired,
  identificationFileUploadPreviewUrl: PropTypes.string,
  setIdentificationFileUploadPreviewUrl: PropTypes.func.isRequired,
  identificationFileName: PropTypes.string,
  setIdentificationFileName: PropTypes.func.isRequired,
  identificationFileType: PropTypes.string,
  setIdentificationFileType: PropTypes.func.isRequired,
  setIdentificationFile: PropTypes.func.isRequired,
  error: PropTypes.shape({
    documentType: PropTypes.string,
    country: PropTypes.string,
    nationality: PropTypes.string,
    number: PropTypes.string,
    validity: PropTypes.string,
    identificationDocumentsFile: PropTypes.string,
  }),
  setError: PropTypes.func.isRequired,
};

export default IdentificationInformationForm;
