import React, { Fragment } from 'react';
import Card from '@wtag/rcl-card';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import { Link } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import IconButton from '@wtag/rcl-icon-button';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import DealsCard from '../../Deals/DealsCard';

const Deals = props => {
  const {
    deals,
    loading,
    fetchFavouritesDeals,
    fetchFriendsFavouriteDeals,
    fetchTopDeals,
    headerText,
    urls,
    buttonText,
  } = props;

  const settings = {
    infiniteLoop: false,
    stopOnHover: false,
    autoPlay: false,
    transitionTime: 500,
    emulateTouch: true,
    showThumbs: false,
    showIndicators: false,
    showArrows: true,
    showStatus: false,
    renderArrowNext: (clickHandler, hasNext) =>
      hasNext && (
        <IconButton
          className="deals-slider__arrow deals-slider__arrow--next"
          icon={<Icon name="arrowForward" />}
          color="tertiary"
          size="tiny"
          onClick={clickHandler}
        />
      ),
    renderArrowPrev: (clickHandler, hasPrev) =>
      hasPrev && (
        <IconButton
          className="deals-slider__arrow deals-slider__arrow--prev"
          icon={<Icon name="arrowBack" />}
          color="tertiary"
          size="tiny"
          onClick={clickHandler}
        />
      ),
  };

  const addToFavourite = async id => {
    const { data } = await httpClient.put(routes.api.deals.favourite({ dealId: id }));

    if (data.id) {
      fetchFavouritesDeals();
      fetchTopDeals();
      fetchFriendsFavouriteDeals();
    }
  };

  const deleteFromFavourite = async id => {
    const { data } = await httpClient.delete(routes.api.deals.favourite({ dealId: id }));

    if (data.id) {
      fetchFavouritesDeals();
      fetchTopDeals();
      fetchFriendsFavouriteDeals();
    }
  };

  const dealShowPageUrl = id => {
    const url = routes.public.deal;
    return url({
      id,
    });
  };

  const renderDeals = () => {
    let startIndex = 0;
    const dealSegments = [];
    while (startIndex < deals.length) {
      const row = deals.slice(startIndex, startIndex + 3).map(deal => (
        <div className="col-lg-4 deals-item" key={deal.id}>
          <DealsCard
            deleteFromFavourite={value => deleteFromFavourite(value)}
            id={deal.id}
            name={deal.name}
            amount={deal.adAmount}
            currency={deal.currency}
            imageUrl={deal.imageUrl}
            detailsUrl={dealShowPageUrl(deal.id)}
            addToFavourite={value => addToFavourite(value)}
            favourite={deal.favourite}
            teaser={deal.teaser}
            itemTypes={deal.itemTypes}
            currentUserId={deal.currentUserId}
          />
        </div>
      ));

      dealSegments.push(
        <div className="grid grid-gap-12 deals-row" key={startIndex}>
          {row}
        </div>,
      );
      startIndex += 3;
    }

    return dealSegments;
  };

  let dealsContent = (
    <Card
      version="v2"
      className="deals-card"
      title={headerText}
      emptyCardImageSrc={<Icon name="add" />}
      emptyCardText={I18n.t('public.components.dashboard.deals.no_deals')}
    />
  );

  if (loading) {
    dealsContent = (
      <Card version="v2" className="deals-card">
        <div className="deals-card__header deals-card__header--bottom-padding ">{headerText}</div>
        <div className="deals-card__content-loader">
          <ContentLoaderPlaceholder numberOfLines={8} showBackground={false} />
        </div>
      </Card>
    );
  } else if (deals.length > 0) {
    dealsContent = (
      <Card version="v2" className="deals-card">
        <div className="deals-card__header">{headerText}</div>
        <div className="grid">
          <div className="col-12 col-grid deals-slider">
            <Carousel {...settings}>{renderDeals()}</Carousel>
          </div>
        </div>
        <div className="deals-card__action">
          <Link type="button" size="small" modifier="default" href={urls.deals}>
            {buttonText}
          </Link>
        </div>
      </Card>
    );
  }

  return <Fragment>{dealsContent}</Fragment>;
};

Deals.defaultProps = {
  fetchFriendsFavouriteDeals: () => {},
};

Deals.propTypes = {
  deals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.node.isRequired,
      adAmount: PropTypes.node.isRequired,
      currency: PropTypes.string,
      imageUrl: PropTypes.string,
      favourite: PropTypes.bool.isRequired,
      urls: PropTypes.shape({
        details: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  fetchFavouritesDeals: PropTypes.func.isRequired,
  fetchFriendsFavouriteDeals: PropTypes.func,
  fetchTopDeals: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  urls: PropTypes.shape({
    deals: PropTypes.string,
  }).isRequired,
};

export default hot(module)(Deals);
