const addToCollection = setCollection => setCollection(prevState => [...prevState, {}]);

const updateCollection = (setCollection, index, field, value) => {
  setCollection(prevState => {
    const newCollection = [...prevState];
    const updateCollectionPrams = newCollection[index];
    updateCollectionPrams[field] = value;
    return newCollection;
  });
};

const setPrimaryItem = (setCollection, index, value) => {
  setCollection(prevState => {
    const newCollection = [...prevState];
    newCollection.forEach((item, i) => {
      if (item.primary !== undefined && i !== index) newCollection[i].primary = false;
    });
    newCollection[index].primary = value;
    return newCollection;
  });
};

const removeFromCollection = (setCollection, index) => {
  setCollection(prevState => {
    const newCollection = [...prevState];
    const removeCollectionPrams = newCollection[index];
    if (removeCollectionPrams.id) {
      removeCollectionPrams.destroy = true;
    } else {
      newCollection.splice(index, 1);
    }
    return newCollection;
  });
};

const itemCounter = index => {
  const newIndex = index + 1;

  return newIndex.toLocaleString(I18n.locale, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export { addToCollection, updateCollection, setPrimaryItem, removeFromCollection, itemCounter };
