import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Stars from '../../components/Stars';

export default function HotelName(props) {
  return (
    <div className={classNames('hotel-name', props.className)}>
      <div className="hotel-name__name" dangerouslySetInnerHTML={{ __html: props.name }} />
      <Stars stars={props.stars} className="hotel-name__stars" />
    </div>
  );
}

HotelName.defaultProps = {
  stars: 0,
  className: null,
  name: null,
};

HotelName.propTypes = {
  stars: PropTypes.number,
  name: PropTypes.string,
  className: PropTypes.string,
};
