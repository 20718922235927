import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import classNames from 'classnames';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { I18nText, LabelHint, Radio, Spinner } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';

import SearchBar from '../SearchBar';

const Person = props => {
  const address = props.addresses[0] || {};
  let emails = null;
  if (props.emailAddresses.length) {
    emails = props.emailAddresses
      .map(email => (
        <span className="match-traveler__email-container" key={email.email}>
          <span
            className={classNames('match-traveler__email', {
              'match-traveler__email--ignored': email.alreadyExists,
            })}
          >
            {email.email}
          </span>
          {email.alreadyExists && (
            <LabelHint
              hint={<I18nText id="match_traveler.will_be_ignored_html" returnStringOnly={true} />}
              size="tiny"
              noMargin={true}
            />
          )}
        </span>
      ))
      .reduce((prev, curr) => [prev, ', ', curr]);
  }
  let phoneNumbers = null;
  if (props.phoneNumbers.length) {
    phoneNumbers = props.phoneNumbers
      .map(phoneNumber => <span key={phoneNumber.number}>{phoneNumber.number}</span>)
      .reduce((prev, curr) => [prev, ', ', curr]);
  }

  return (
    <div className="match-traveler__traveler-details col-12">
      <div className="grid">
        <div className="match-traveler__selector col-grid col-2 align-center justify-center">
          <Radio
            name="traveler-selection"
            size="small"
            value={props.id || 'new'}
            onChange={event => props.onSelect(event.target.value)}
          />
        </div>
        <div className="col-10">
          <div className="grid grid-gap-12">
            <div className="col-12">
              <div className="match-traveler__person-name">
                {props.firstName} {props.middleName} {props.lastName}
              </div>
              {props.showConfidencePercentage && (
                <div className="match-traveler__match-confidence">
                  <I18nText id="match_traveler.match_confidence" />:{' '}
                  <span
                    className={classNames({
                      'match-traveler__match-confidence--good': props.confidencePercentage >= 90,
                      'match-traveler__match-confidence--neutral':
                        props.confidencePercentage < 90 && props.confidencePercentage >= 50,
                      'match-traveler__match-confidence--bad': props.confidencePercentage < 50,
                    })}
                  >
                    {props.confidencePercentage}%
                  </span>
                </div>
              )}
            </div>
            <div className="col-12">
              <div>{props.birthday}</div>
              <div className="match-traveler__email-container">{emails}</div>
              <div>{phoneNumbers}</div>
            </div>
            <div className="col-12">
              <div>{address.line1}</div>
              <div>
                {address.zipCode} {address.city}
              </div>
              <div>{address.country}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Person.defaultProps = {
  id: null,
  firstName: null,
  middleName: null,
  lastName: null,
  birthday: null,
  confidencePercentage: null,
  showConfidencePercentage: false,
};

Person.propTypes = {
  id: PropTypes.number,
  confidencePercentage: PropTypes.string,
  showConfidencePercentage: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  birthday: PropTypes.string,
  emailAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      alreadyExists: PropTypes.bool,
      emailType: PropTypes.string,
    }),
  ).isRequired,
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({ number: PropTypes.string, numberType: PropTypes.string }),
  ).isRequired,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      line1: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      countryCode: PropTypes.string,
      country: PropTypes.string,
    }),
  ).isRequired,
};

class MatchTraveler extends React.Component {
  constructor(props) {
    super(props);

    const people = [];
    if (props.traveler.person) {
      people.push(props.traveler.person);
    }

    this.state = { searching: false, people, decision: null };
  }

  componentDidMount() {
    if (this.state.people.length === 0) {
      return this.searchPeople('', true);
    }
    return Promise.resolve();
  }

  onDecision = () => {
    this.props.onDecision({
      ...this.state.decision,
      id: this.props.id,
      context: 'refresh_booking',
    });
  };

  searchPeople = (query, force) => {
    if (query.length < 3 && !force) return Promise.resolve();

    this.setState({ searching: true });

    const attributes = this.props.traveler.personAttributes;
    const params = {
      q: query,
      first_name: attributes.firstName,
      middle_name: attributes.middleName,
      last_name: attributes.lastName,
      birthday: attributes.birthday,
      salutation: attributes.salutation,
      'emails[]': attributes.emailAddresses.map(address => address.email),
    };
    return httpClient.get(routes.admin.people.list(params)).then(({ data }) => {
      this.setState({ people: data.people, searching: false });
    });
  };

  decideForNewCustomer = () =>
    this.setState({
      decision: {
        action: 'create',
        attributes: this.props.traveler.personAttributes,
      },
    });

  decideForExistingCustomer = id =>
    this.setState({
      decision: {
        action: 'link',
        person_id: id,
      },
    });

  render() {
    const { personAttributes } = this.props.traveler;

    return (
      <div className="match-traveler col-12 col-bleed-y">
        <div className="grid">
          <div className="match-traveler__explanation col-12 col-bleed-y">
            <I18nText id="match_traveler.explanation" returnStringOnly={true} />
          </div>
          <div className="match-traveler__subtitle col-12">
            <I18nText id="match_traveler.create_new_customer" returnStringOnly={true} />
          </div>
          <div className="col-sm-6 col-xs-12 col-bleed-y">
            <Person {...personAttributes} onSelect={this.decideForNewCustomer} />
          </div>
          <div className="match-traveler__subtitle col-12">
            <I18nText id="match_traveler.or" returnStringOnly={true} />
          </div>
          <div className="match-traveler__subtitle col-12 col-bleed-y">
            <I18nText id="match_traveler.confirm_from_customer_directory" returnStringOnly={true} />
          </div>
          <div className="match-traveler__search col-12 col-bleed-y">
            <SearchBar
              handleSearch={this.searchPeople}
              fullWidth={true}
              placeholder={I18n.t('components.match_traveler.search_placeholder')}
            />
          </div>

          <div className="match-traveler__search-results col-12 col-bleed">
            {this.state.searching && (
              <div className="col-12 circular-progress-on-white-background-fix-me-asap">
                <Spinner size="tiny" bgColor="neutral" />
              </div>
            )}
            {!this.state.searching &&
              this.state.people.map(person => (
                <div key={person.id} className="col-sm-6 col-xs-12">
                  <div className="match-traveler__search-result">
                    <Person
                      {...person}
                      onSelect={this.decideForExistingCustomer}
                      showConfidencePercentage={true}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className="match-traveler__actions col-12 col-bleed-y">
            <Button
              version="v2"
              onClick={this.onDecision}
              disabled={!this.state.decision || this.props.disabled}
              label={<I18nText id="match_traveler.next" returnStringOnly={true} />}
              primary={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

MatchTraveler.propTypes = {
  id: PropTypes.string.isRequired,
  onDecision: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  traveler: PropTypes.shape({
    person: PropTypes.shape({
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      birthday: PropTypes.string,
      salutation: PropTypes.string,
      emailAddresses: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string,
          alreadyExists: PropTypes.bool,
          emailType: PropTypes.string,
        }),
      ).isRequired,
      phoneNumbers: PropTypes.arrayOf(
        PropTypes.shape({ number: PropTypes.string, numberType: PropTypes.string }),
      ).isRequired,
      addresses: PropTypes.arrayOf(
        PropTypes.shape({
          line1: PropTypes.string,
          zipCode: PropTypes.string,
          city: PropTypes.string,
          countryCode: PropTypes.string,
          country: PropTypes.string,
        }),
      ).isRequired,
    }),
    personAttributes: PropTypes.shape({
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      birthday: PropTypes.string,
      salutation: PropTypes.string,
      emailAddresses: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string,
          alreadyExists: PropTypes.bool,
          emailType: PropTypes.string,
        }),
      ).isRequired,
      phoneNumbers: PropTypes.arrayOf(
        PropTypes.shape({ number: PropTypes.string, numberType: PropTypes.string }),
      ).isRequired,
      addresses: PropTypes.arrayOf(
        PropTypes.shape({
          line1: PropTypes.string,
          zipCode: PropTypes.string,
          city: PropTypes.string,
          countryCode: PropTypes.string,
          country: PropTypes.string,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default hot(module)(MatchTraveler);
