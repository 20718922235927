import PropTypes from 'prop-types';

export default {
  id: PropTypes.string.isRequired,
  source: PropTypes.shape({
    supplier: PropTypes.string.isRequired,
    account: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.shape({
    street: PropTypes.string.isRequired,
    zip: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }),
  rating: PropTypes.number,
  ratingSource: PropTypes.string,
  grossTotal: PropTypes.string.isRequired,
  perNightPrice: PropTypes.string.isRequired,
  marginAmount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  picture: PropTypes.string,
  refundable: PropTypes.bool,
  stars: PropTypes.number,
  corporateDeal: PropTypes.bool,
};
