import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import { CASH_FORM_OF_PAYMENT } from '../../lib/helpers/fop_options';

import { fetchCart } from './cart';

export const THIRD_PARTY_PAYMENT_METHOD_SET = 'THIRD_PARTY_PAYMENT_METHOD_SET';
function thirdPartyPaymentMethodSet(method) {
  return {
    type: THIRD_PARTY_PAYMENT_METHOD_SET,
    method,
  };
}

export const REMOVE_PAYMENT_GATEWAY = 'REMOVE_PAYMENT_GATEWAY';

function removePaymentGateway() {
  return {
    type: REMOVE_PAYMENT_GATEWAY,
    paymentGateway: null,
  };
}

export function set3rdPartyPaymentMethod(method, callbackParams) {
  return (dispatch, getState) => {
    const cartId = getState().common.cart.id;
    return httpClient
      .put(routes.api.carts.paymentMethod({ cartId, callbackParams }), {
        method,
      })
      .then(() => {
        if (method === CASH_FORM_OF_PAYMENT) {
          dispatch(removePaymentGateway());
        } else {
          dispatch(thirdPartyPaymentMethodSet(method));
        }
        return dispatch(fetchCart(cartId, callbackParams));
      });
  };
}
