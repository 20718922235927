import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchApprovalRequest } from '../actions/common';

import fetchOnUpdate from '../lib/decorators/fetchOnUpdate';
import MultiSearch from './MultiSearchContainer';

const mapStateToProps = state => ({
  id: state.common.approvalRequest.id,
  title: `Approval Request #${state.common.approvalRequest.id}`,
  groups: state.common.approvalRequest.groups,
  disableForm: true,
  continuePossible: state.common.approvalRequest.status === 'approved',
});

const mapDispatchToProps = dispatch => ({
  fetchApprovalRequest: (id, callbackParams) => dispatch(fetchApprovalRequest(id, callbackParams)),
});

const ApprovalRequest = fetchOnUpdate(['id'], (params, props) => {
  if (props.id !== params.id) {
    props.fetchApprovalRequest(params.id, props.callbackParams);
  }
})(MultiSearch);

const ApprovalRequestWithContext = (props, context) => <ApprovalRequest {...props} {...context} />;

ApprovalRequestWithContext.contextTypes = {
  callbackParams: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalRequestWithContext);
