import React from 'react';
import useViewMode from 'sharedWebpack/useViewMode';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { DESKTOP_DEVICE, MOBILE_DEVICE } from '../../../old/libraries/responsiveHelpers';

const SearchFormTabTitle = ({ iconName, title, laymanMode }) => {
  const isShowLargeIcon = [DESKTOP_DEVICE, MOBILE_DEVICE].includes(useViewMode()) || !laymanMode;

  return (
    <div className="d-flex column-gap-8">
      <Icon name={iconName} size={isShowLargeIcon ? 'normal' : 'small'} />
      {!laymanMode && <span>{title}</span>}
    </div>
  );
};

SearchFormTabTitle.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  laymanMode: PropTypes.bool.isRequired,
};

export default SearchFormTabTitle;
