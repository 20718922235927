import { combineReducers } from 'redux';

import {
  MSEARCH_RESULT_CONFIRMED,
  MSEARCH_RESULT_CONFIRM_FAILED,
  MSEARCH_RESULT_SEARCH_COMPLETE,
  MSEARCH_RESULT_SELECTED,
  MSEARCH_RESULT_DESELECTED,
  MSEARCH_RESULT_SEARCHING,
  APPROVAL_REQUEST_FETCHED,
  DEAL_FETCHED,
} from '../../actions/common';

function searchIdsWithManualSelection(state = [], action) {
  const newState = Object.assign([], state);

  switch (action.type) {
    case MSEARCH_RESULT_CONFIRM_FAILED:
      newState.push(action.groupKey.toString());
      return newState;

    case MSEARCH_RESULT_CONFIRMED:
      // don't remove it from the state as it's needed by cartNeedsApproval to check if
      // an approval request with manual selection needs another approval request
      return newState;

    default:
      return state;
  }
}

// possible state values are:
// <undefined>: Results haven't been chosen.
// searching: Search is running
// confirming: The result is being confirmed
// available: The selected result is available
// unavailable: The selected result is not available anymore

function status(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case MSEARCH_RESULT_SELECTED:
      newState[action.groupKey.toString()] = 'selected';
      return newState;

    case MSEARCH_RESULT_DESELECTED:
      delete newState[action.groupKey.toString()];
      return newState;

    case APPROVAL_REQUEST_FETCHED:
      Object.keys(action.approvalRequest.groups).forEach((groupKey, index) => {
        newState[index.toString()] = 'selected';
      });
      return newState;

    case DEAL_FETCHED:
      Object.keys(action.deal.groups).forEach((groupKey, index) => {
        const group = action.deal.groups[groupKey];
        if (group.options.length === 1) {
          newState[index.toString()] = 'selected';
        }
      });
      return newState;

    case MSEARCH_RESULT_SEARCHING:
      newState[action.groupKey.toString()] = 'searching';
      return newState;

    case MSEARCH_RESULT_SEARCH_COMPLETE:
      newState[action.groupKey.toString()] = 'confirming';
      return newState;

    case MSEARCH_RESULT_CONFIRMED:
      newState[action.groupKey.toString()] = 'available';
      return newState;

    case MSEARCH_RESULT_CONFIRM_FAILED:
      newState[action.groupKey.toString()] = 'unavailable';
      return newState;

    default:
      return state;
  }
}

function searchIds(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case MSEARCH_RESULT_SEARCH_COMPLETE:
      newState[action.groupKey] = action.searchId;
      return newState;

    default:
      return state;
  }
}

function selectedResults(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case MSEARCH_RESULT_SELECTED:
      newState[action.groupKey.toString()] = action.id.toString();
      return newState;

    case MSEARCH_RESULT_DESELECTED:
      delete newState[action.groupKey.toString()];
      return newState;

    case MSEARCH_RESULT_CONFIRMED:
      newState[action.groupKey.toString()] = action.cartItem.id.toString();
      return newState;

    case APPROVAL_REQUEST_FETCHED:
      Object.keys(action.approvalRequest.groups).forEach((groupKey, index) => {
        const group = action.approvalRequest.groups[groupKey];
        if (group.options.length === 1) {
          newState[index.toString()] = group.options[0].id.toString();
        }
      });
      return newState;

    case DEAL_FETCHED:
      Object.keys(action.deal.groups).forEach((groupKey, index) => {
        const group = action.deal.groups[groupKey];
        if (group.options.length === 1) {
          newState[index.toString()] = group.options[0].id.toString();
        }
      });
      return newState;

    default:
      return state;
  }
}

const multiSearch = combineReducers({
  searchIds,
  searchIdsWithManualSelection,
  selectedResults,
  status,
});

export default multiSearch;
