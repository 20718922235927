/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ProductIcon from 'sharedWebpack/ProductIcon';
import DateTime from 'sharedWebpack/DateTime';
import Card from '@wtag/rcl-card';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import StackedItems from '@wtag/rcl-stacked-items';
import Icon from '@wtag/rcl-icon';
import HotelRoomFallbackImage from '../../../../assets/images/synced/fallback_images/product/product_xxl_hotel.png';
import '../styles.scss';

const Hotel = ({ data }) => {
  const [isTravellerPopoverVisible, setIsTravellerPopoverVisible] = useState(false);

  return (
    <Fragment>
      <div className="col-12 col-sm-3 col-xlg-2 travel-plan__timeline-item-section-product">
        <div className="travel-plan__timeline-item-section-product-info">
          <div className="travel-plan__timeline-item-section-product-icon">
            <ProductIcon productType={data.type} showBGColor={true} size="large" />
          </div>
          <div className="travel-plan__timeline-item-section-product-name">
            {I18n.t('admin.components.orders.overview.travel_plan.hotel.title')}
          </div>
          <div className="travel-plan__timeline-item-section-product-duration">
            {I18n.t('admin.components.orders.overview.travel_plan.hotel.night_count', {
              night_count: data.totalNightDuration,
            })}
          </div>
          <div className="travel-plan__timeline-item-section-product-travellers">
            {data.travelers && data.travelers.length > 0 && (
              <StackedItems
                itemSize="tiny"
                isPopoverVisible={isTravellerPopoverVisible}
                onClick={() =>
                  setIsTravellerPopoverVisible(
                    prevTravellerPopoverState => !prevTravellerPopoverState,
                  )
                }
                onOutsideClick={() => setIsTravellerPopoverVisible(false)}
              >
                {data.travelers.map(traveler => (
                  <Avatar
                    firstName={traveler.firstName}
                    lastName={traveler.lastName}
                    src={traveler.avatarUrl}
                  />
                ))}
              </StackedItems>
            )}
          </div>
        </div>
        <div className="travel-plan__timeline-item-indicator">
          <div className="travel-plan__timeline-item-indicator-line" />
        </div>
      </div>
      <div className="col-12 col-sm-9 col-xlg-10">
        <div className="grid grid-gap-20">
          <div className="col-12">
            <Card className="travel-plan__timeline-item-section-card" version="v2">
              <div className="travel-plan__timeline-item-section-card-header">
                {data && data.hotelName}
                <div className="travel-plan__timeline-item-section-card-header-service">
                  <Icon
                    className={`travel-plan__timeline-item-section-card-header-service-icon ${
                      data && !data.wifiAvailable
                        ? 'travel-plan__timeline-item-section-card-header-service-icon--disabled'
                        : ''
                    }`}
                    name="wifi"
                    showBGColor={true}
                    color={data && data.wifiAvailable ? 'success' : 'tertiary'}
                    iconLabel={I18n.t('components.flight.wifi.label')}
                    size="small"
                    showTooltip={true}
                  />
                  <Icon
                    className={`travel-plan__timeline-item-section-card-header-service-icon ${
                      data && !data.boardType
                        ? 'travel-plan__timeline-item-section-card-header-service-icon--disabled'
                        : ''
                    }`}
                    name="meal"
                    showBGColor={true}
                    color={data && data.boardType ? 'success' : 'tertiary'}
                    iconLabel={I18n.t('components.flight.meal.label')}
                    size="small"
                    showTooltip={true}
                  />
                </div>
              </div>
              <div className="travel-plan__section-divider travel-plan__timeline-item-section-divider" />
              <div className="travel-plan__timeline-item-section-card-body">
                <div className="travel-plan__timeline-item-section-card-body-travel-info">
                  <div className="travel-plan__timeline-item-section-card-body-travel-time">
                    {data.checkIn && <DateTime dateTime={data.checkIn} format="short" />}
                    {data.checkOut && <DateTime dateTime={data.checkOut} format="short" />}
                  </div>
                  {data.checkIn && (
                    <div className="travel-plan__timeline-item-section-card-body--muted-text">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.hotel.header.check_in.label',
                      )}
                    </div>
                  )}
                  {data.checkOut && (
                    <div className="travel-plan__timeline-item-section-card-body--muted-text">
                      {I18n.t(
                        'admin.components.orders.overview.travel_plan.hotel.header.check_out.label',
                      )}
                    </div>
                  )}
                  <div className="travel-plan__timeline-item-section-card-body-travel-address">
                    {`${data.hotelName}${data.address ? `, ${data.address}` : ''}`}
                  </div>
                  {data.boardType && (
                    <EmphasisTag
                      className="travel-plan__timeline-item-section-card-body-travel-tag"
                      text={data.boardType}
                      size="tiny"
                      type="success"
                    />
                  )}
                </div>
                <div className="travel-plan__timeline-item-section-card-body-image">
                  {data && data.pictures && data.pictures.length > 0 ? (
                    <img
                      className="travel-plan__timeline__hotel-card__hotel-info-image-hotel"
                      src={data.pictures[0]}
                      alt={data && data.hotelName}
                    />
                  ) : (
                    <img src={HotelRoomFallbackImage} alt="hotel" />
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Hotel.propTypes = {
  data: PropTypes.shape({
    checkIn: PropTypes.string,
    checkOut: PropTypes.string,
    totalNightDuration: PropTypes.number,
    roomName: PropTypes.string,
    hotelName: PropTypes.string,
    wifiAvailability: PropTypes.string,
    boardType: PropTypes.string,
    address: PropTypes.string,
    pictures: PropTypes.arrayOf(),
    travelers: PropTypes.shape({
      firstName: PropTypes.string,
      type: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
    }),
  }).isRequired,
};

export default Hotel;
