import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Amount from 'sharedWebpack/Amount';
import { Menu } from '@wtag/rcl-menu';
import getPriceDetails from './helpers/getPriceDetails';

const HotelPriceMenu = props => {
  const {
    currency,
    durationOfStaying,
    grossTaxes,
    grossTotal,
    laymanMode,
    marginBreakdown,
    netRoomRate,
    perNightPrice,
    requiredRooms,
  } = props;

  const [isHotelMenuVisible, setIsHotelMenuVisible] = useState(false);

  const onHotelMenuOutsideClick = () => {
    setIsHotelMenuVisible(false);
  };
  const toggleHotelMenuVisibility = () => {
    setIsHotelMenuVisible(prevState => !prevState);
  };

  const priceDetailsProps = {
    currency,
    durationOfStaying,
    grossTaxes,
    grossTotal,
    laymanMode,
    marginBreakdown,
    netRoomRate,
    perNightPrice,
    requiredRooms,
  };

  const priceDetails = getPriceDetails(priceDetailsProps);

  return (
    <div className="hotel-price-menu d-flex direction-column">
      <Menu
        isVisible={isHotelMenuVisible}
        size="medium"
        popOverDirection="bottom-right"
        onOutsideClick={onHotelMenuOutsideClick}
        label={
          <div
            onClick={toggleHotelMenuVisibility}
            role="button"
            tabIndex={0}
            onKeyDown={toggleHotelMenuVisibility}
          >
            <div className="hotel-price-menu__wrapper">
              <span className="hotel-price-menu__label-text">
                {I18n.t('components.ibe.results.from')}
              </span>
              <Amount
                className="hotel-price-menu__label-price"
                currency={currency}
                value={perNightPrice}
              />
            </div>
          </div>
        }
      >
        <div className="hotel-price-menu__breakdown d-flex flex-wrap justify-between">
          {priceDetails.map(
            ({ amount, labelClassName, label, isPriceBold, currency: itemCurrency }) => (
              <div
                key={label}
                className="hotel-price-menu__breakdown-item d-flex flex-wrap justify-between"
              >
                <span className={labelClassName}>{label}</span>

                <Amount
                  className={classNames({
                    'hotel-price-menu__breakdown-item--bold': isPriceBold,
                  })}
                  currency={itemCurrency}
                  value={amount}
                />
              </div>
            ),
          )}
        </div>
      </Menu>
      <div className="hotel-price-menu__label-text">
        ({I18n.t('components.ibe.search_form.hotel.label.per_night')})
      </div>
    </div>
  );
};

HotelPriceMenu.defaultProps = {
  grossTaxes: 0,
  marginBreakdown: [],
};

HotelPriceMenu.propTypes = {
  currency: PropTypes.string.isRequired,
  durationOfStaying: PropTypes.number.isRequired,
  grossTaxes: PropTypes.string,
  grossTotal: PropTypes.string.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  marginBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      total: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }),
  ),
  netRoomRate: PropTypes.string.isRequired,
  perNightPrice: PropTypes.string.isRequired,
  requiredRooms: PropTypes.number.isRequired,
};

export default HotelPriceMenu;
