const getPriceDetails = params => {
  const {
    currency,
    durationOfStaying,
    grossTaxes,
    grossTotal,
    laymanMode,
    marginBreakdown,
    perNightPrice,
    requiredRooms,
  } = params;

  const marginBreakdownInfo = !laymanMode
    ? marginBreakdown.map(({ name, total, currency: marginCurrency }) => ({
        label: name,
        amount: total,
        currency: marginCurrency,
      }))
    : [];

  const grossTax = grossTaxes || I18n.t('components.ibe.summary.free');
  const numberOfRoomLabel = I18n.t(
    'components.ibe.search_form.hotel.label.number_of_rooms_per_night',
    {
      count: requiredRooms,
    },
  );
  const numberOfNightLabel = I18n.t('components.ibe.search_form.hotel.label.number_of_nights', {
    count: durationOfStaying,
  });

  const priceDetails = [
    {
      label: numberOfRoomLabel,
      amount: perNightPrice,
      currency,
      labelClassName: 'hotel-price-menu__breakdown-item--lower-case',
    },
    {
      label: numberOfNightLabel,
      amount: grossTotal,
      currency,
      isPriceBold: true,
    },
    {
      label: I18n.t('activerecord.attributes.order_item.taxes_charges'),
      amount: grossTax,
      currency,
    },
    ...marginBreakdownInfo,
    {
      label: I18n.t('components.ibe.search_form.hotel.label.total'),
      amount: grossTotal,
      currency,
      isPriceBold: true,
    },
  ];

  return priceDetails;
};

export default getPriceDetails;
