import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import classNames from 'classnames';
import { AccordionItem } from '@wtag/rcl-accordion';
import CarImage from '../CarImage';
import CarAccordionItemHeader from '../CarAccordionItemHeader';
import CarLocationDetails from '../CarLocationDetails';
import InsurancesAndLiabilities from '../InsurancesAndLiabilities';
import SearchCarInfo from '../SearchCarInfo';
import FacilityItemLinks from '../FacilityItemLinks';
import Mileage from '../Mileage';
import OutOfPolicyJustificationModal from '../../OutOfPolicyJustificationModal';
import { carDetailsShape } from '../../../config/shapes';
import './styles.scss';

const CarCompactView = ({
  details,
  onCarSelect,
  onSelect,
  setSidePanelTitle,
  setSidePanelBody,
  setOpenSidePanel,
  laymanMode,
  isAlreadySelectedInCart,
}) => {
  const {
    id,
    facilities,
    vendor,
    car,
    pickUp,
    dropOff,
    currency,
    ratePerDay,
    source,
    corporateDeal,
    labels,
    policiesReasons,
    approvalRequired,
    payLater,
  } = details;

  const {
    insurances,
    liability,
    otherFacilities,
    mileage,
    seats,
    doors,
    airConditioned,
    fuelType,
    airBag,
  } = facilities;

  const carInformationGroup = {
    approvalRequired,
    payLater,
    policiesReasons,
    labels,
    corporateDeal,
  };

  const carFacilities = {
    seats,
    doors,
    airConditioned,
    fuelType,
    airBag,
  };

  const [isAccordionActive, setIsAccordionActive] = useState(false);
  const [isPolicyJustificationModalOpen, setIsPolicyJustificationModalOpen] = useState(false);

  const isOutOfPolicy = labels.outOfPolicy;
  const hasMileage = mileage && mileage.length > 0;

  const handleAccordionClick = () => {
    setIsAccordionActive(previousState => !previousState);
  };

  const handleContinueToCart = (justification = null) => {
    onCarSelect(true);
    onSelect(details, justification);
  };

  const handleContinueButtonClick = event => {
    event.stopPropagation();

    if (isOutOfPolicy) {
      setIsPolicyJustificationModalOpen(true);
    } else {
      handleContinueToCart();
    }
  };

  const handleShowSidePanel = (sidePanelTitle, sidePanelBody) => {
    setSidePanelTitle(sidePanelTitle);
    setSidePanelBody(sidePanelBody);
    setOpenSidePanel();
  };

  const facilityItemLinksInMobileView = (
    <Fragment>
      <FacilityItemLinks
        className="d-flex direction-row-reverse column-gap-8 car__information-labels--mobile"
        facilities={facilities}
        otherFacilities={otherFacilities}
        onShowSidePanel={handleShowSidePanel}
      />
      {hasMileage && (
        <div className="d-flex-col align-end row-gap-4 car__mileage car__mileage--mobile">
          <Mileage mileages={mileage} />
        </div>
      )}
    </Fragment>
  );

  return (
    <AccordionItem
      className={classNames('car-compact-view', {
        'car-compact-view--not-layman-mode': !laymanMode,
        'car-compact-view--disable': isAlreadySelectedInCart,
        'car-compact-view--accordion-opened': isAccordionActive,
      })}
      id={id}
      header={
        <CarAccordionItemHeader
          onContinueButtonClick={handleContinueButtonClick}
          isAlreadySelectedInCart={isAlreadySelectedInCart}
          carInformation={car}
          pickUpDate={pickUp.localtime}
          dropOffDate={dropOff.localtime}
          vendorName={vendor.vendorName}
          source={source}
          laymanMode={laymanMode}
          priceDetails={{ currency, ratePerDay }}
          isAccordionActive={isAccordionActive}
        />
      }
      onClick={handleAccordionClick}
      isActive={isAccordionActive}
    >
      <div className="grid grid-gap-20">
        <div className="col-12">
          <div className="grid grid-gap-12">
            <div className="col-3 car__image">
              <CarImage className="flex-img" url={car.imageUrl.url} />
            </div>
            <div className="col-xlg-6 col-lg-7 d-flex direction-column row-gap-12 car__details">
              <div className="col-12 col-bleed">
                <CarLocationDetails pickUpDetails={pickUp} dropOffDetails={dropOff} />
              </div>
              <div className="col-lg-8 d-flex column-gap-8 col-bleed justify-between car__insurances-container">
                <InsurancesAndLiabilities
                  insurances={insurances}
                  liability={liability}
                  onShowSidePanel={handleShowSidePanel}
                />
              </div>
            </div>
            {hasMileage && (
              <div className="col-xlg-3 col-lg-2 col-grid align-end row-gap-4 car__mileage car__mileage--desktop">
                <Mileage mileages={mileage} />
              </div>
            )}
          </div>
        </div>
        <SearchCarInfo
          className="col-12 car__search-info car__search-info--desktop"
          group={carInformationGroup}
          laymanMode={laymanMode}
          facilities={carFacilities}
          otherFacilities={otherFacilities}
          onShowSidePanel={handleShowSidePanel}
        />
        <SearchCarInfo
          className="col-12 car__search-info car__search-info--mobile"
          group={carInformationGroup}
          laymanMode={laymanMode}
        />
        <div className="col-12 car__facility-items--mobile">{facilityItemLinksInMobileView}</div>
        <OutOfPolicyJustificationModal
          modalOpen={isPolicyJustificationModalOpen}
          setModalOpen={setIsPolicyJustificationModalOpen}
          onConfirm={handleContinueToCart}
        />
      </div>
    </AccordionItem>
  );
};

CarCompactView.propTypes = {
  details: PropTypes.shape(carDetailsShape).isRequired,
  isAlreadySelectedInCart: PropTypes.bool.isRequired,
  onCarSelect: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  setOpenSidePanel: PropTypes.func.isRequired,
  setSidePanelBody: PropTypes.func.isRequired,
  setSidePanelTitle: PropTypes.func.isRequired,
};

const CarCompactViewWithContext = (props, context) => <CarCompactView {...props} {...context} />;

CarCompactViewWithContext.contextTypes = {
  laymanMode: PropTypes.bool,
};

export default hot(module)(CarCompactViewWithContext);
