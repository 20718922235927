import { CART_FETCHED } from '../../actions/common';

export default function cartState(state = null, action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.state;

    default:
      return state;
  }
}
