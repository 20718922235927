import { connect } from 'react-redux';
import FlightDurationAndStopOvers from './';
import { fetchAirportDetails } from '../../../actions/flight';

const mapStateToProps = state => ({
  airports: state.common.airports,
});

const mapDispatchToProps = dispatch => ({
  fetchAirportDetails: code => dispatch(fetchAirportDetails(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightDurationAndStopOvers);
