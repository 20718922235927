const domOnlyProps = (
  {
    error,
    initialValue,
    autofill,
    onUpdate,
    valid,
    invalid,
    dirty,
    pristine,
    active,
    touched,
    visited,
    autofilled,
    ...domProps
  },
) => domProps;

export default domOnlyProps;
