import React from 'react';
import Alert from 'sharedWebpack/Alert';
import { Spinner } from '@wtag/react-comp-lib';

const Loader = () => (
  <div className="flight__spinner">
    <Alert type="warning" hideClose={true} isVisible={true} isIconVisible={false}>
      <div className="progress-spinner">
        {I18n.t('components.ibe.results.loader_message')}
        <div className="progress-spinner__circular">
          <Spinner size="tiny" bgColor="neutral" />
        </div>
      </div>
    </Alert>
  </div>
);

export default Loader;
