import PropTypes from 'prop-types';

export const itemShape = {
  id: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  formOfPayment: PropTypes.shape().isRequired,
  currency: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  available: PropTypes.bool,
  journeyElementType: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  outOfPolicy: PropTypes.bool.isRequired,
  approvalRequired: PropTypes.bool.isRequired,
  instantTicket: PropTypes.bool.isRequired,
  itemStatus: PropTypes.arrayOf(),
  bookingAttributes: PropTypes.shape({
    resultId: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
  }),
  cancelPenalty: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.number.isRequired,
  }),
  changePenalty: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
};

const facilityItemShape = PropTypes.shape({
  type: PropTypes.string,
  amount: PropTypes.string,
  currency: PropTypes.string,
  modifier: PropTypes.string,
});

const locationDetailsShape = PropTypes.shape({
  localtime: PropTypes.string,
  location: PropTypes.string,
  localtimeHourInUserTz: PropTypes.string,
  localtimeInUserTz: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  city: PropTypes.string,
});

export const journeyElementShape = {
  id: PropTypes.number,
  make: PropTypes.oneOfType([PropTypes.string, null]),
  name: PropTypes.string,
  doors: PropTypes.string,
  seatCount: PropTypes.number,
  airConditioning: PropTypes.bool,
  airbag: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  acrissCode: PropTypes.string,
  rateType: PropTypes.string,
  rateCode: PropTypes.string,
  ratePerDay: PropTypes.string,
  durationInDays: PropTypes.number,
  vendorName: PropTypes.string,
  vendorImageUrl: PropTypes.string,
  carImageUrl: PropTypes.string,
  insurances: PropTypes.arrayOf(facilityItemShape),
  corporateDeal: PropTypes.bool,
  status: PropTypes.string,
  supplierReservationIdentifier: PropTypes.string,
  rating: PropTypes.string,
  facilities: PropTypes.arrayOf(facilityItemShape),
  mileage: PropTypes.arrayOf(facilityItemShape),
  liabilities: PropTypes.arrayOf(facilityItemShape),
  fuelType: PropTypes.string,
  pickUp: locationDetailsShape,
  dropOff: locationDetailsShape,
};
