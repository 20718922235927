const generateCartOverrides = (itemCount, travelerCount, type) => {
  const { adults, children, infants } = travelerCount;
  const cartHasItems = itemCount > 0;

  if (cartHasItems && typeof adults !== 'undefined') {
    let cartOverrides;
    if (type === 'flight') {
      cartOverrides = {
        adults: adults.length,
        children: children.length,
        infants: infants.length,
      };
    } else {
      const childrenCount = children.length + infants.length;
      cartOverrides = {
        adults: adults.length,
        children: childrenCount,
        childrenData: new Array(childrenCount).fill({}),
      };
    }

    return cartOverrides;
  }

  return {};
};

export default generateCartOverrides;
