import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Link from '@wtag/rcl-link';
import { I18nText } from '@wtag/react-comp-lib';
import { Tabs as Rtabs } from '@wtag/rcl-rtabs';
import Modal from 'sharedWebpack/Modal';
import SeatMapUnavailableCard from 'sharedWebpack/SeatMapUnavailableCard';
import FlightInfoSummary from './FlightInfoSummary';
import FlightJourneyIndicator from '../Indicators/FlightJourneyIndicator';
import FlightSeatIndicator from '../Indicators/FlightSeatIndicator';
import SeatMapList from './SeatMapList';
import FlightBookedSeatSummary from './FlightBookedSeatSummary';
import FlightInfoSummaryPopover from './FlightInfoSummaryPopover';
import '../../styles.scss';

class FlightSeatMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      selectedTabKey: 0,
    };
    this.computed = {
      airlineMap: {},
      airportMap: {},
      segmentMap: {},
      bookedSeatMap: {},
    };
  }

  componentDidMount() {
    if (this.state.isModalVisible || this.props.shouldLockScroll) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  componentDidUpdate() {
    if (this.state.isModalVisible || this.props.shouldLockScroll) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  onModalOpen = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  onModalClose = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  setSelectedTabKeyOnClick = key => {
    this.setState({
      selectedTabKey: key,
    });
  };

  mapPropsData = propsData => {
    const airlineMap = {};
    const airportMap = {};
    const segmentMap = {};
    const bookedSeatMap = {};

    propsData.trips.forEach(trip => {
      trip.airlines.forEach(airline => {
        if (airline && airline.code) airlineMap[airline.code] = airline;
      });

      trip.airports.forEach(airport => {
        if (airport && airport.code) airportMap[airport.code] = airport;
      });
      trip.segments.forEach(segment => {
        segmentMap[`${segment.originCode}-${segment.destinationCode}`] = segment;
      });
    });
    propsData.cart.items.forEach(item => {
      if (Array.isArray(item.subItems))
        item.subItems.forEach(subItem => {
          if (subItem.journeyElementId && subItem.type === 'seat') {
            bookedSeatMap[subItem.journeyElementId] = {
              travelerIndex: subItem.bookingAttributes.travelerIndex,
              price: Number(subItem.grossTotal),
            };
          }
        });
    });

    this.computed = {
      airlineMap,
      airportMap,
      segmentMap,
      bookedSeatMap,
    };
  };

  seatInfo = seatMaps => {
    const { layman } = this.context.callbackParams;

    const sectionWithSeat = seatMaps.find(seatmap => seatmap.sections.length > 0).sections[0];
    return <FlightSeatIndicator sectionWithSeat={sectionWithSeat} layman={layman} />;
  };

  flightInfo = () => {
    const { airlineMap, airportMap, segmentMap } = this.computed;
    const seatMap = this.props.seatMaps[this.state.selectedTabKey];

    if (!seatMap) return null;

    const segment = segmentMap[`${seatMap.origin}-${seatMap.destination}`];

    return (
      <div className="col-grid col-bleed flight-item-trip-airport-info">
        <FlightInfoSummary
          airlineMap={airlineMap}
          airportMap={airportMap}
          flightNumber={seatMap.flightNumber}
          segment={segment}
        />
      </div>
    );
  };

  seatServiceAvailable = () =>
    this.props.seatMaps.length > 0 &&
    this.props.seatMaps.filter(seatmap => seatmap.sections.length > 0).length > 0;

  confirmSelection = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  deleteSelections = () => {
    const seatItemIDs = this.props.seatItemIDs;
    if (seatItemIDs.length) {
      this.props.removeSeatsFromCart(seatItemIDs);
    }
  };

  showSeatButtonLink = () => (
    <Link
      modifier="tertiary"
      size="tiny"
      onClick={this.onModalOpen}
      disabled={!this.seatServiceAvailable()}
    >
      {I18n.t('components.ibe.flight.seat')}
    </Link>
  );

  showAirportMapInformation = (airportMap, seatMapOriginType) => {
    const airportMapKeysLength = Object.keys(airportMap).length;

    if (airportMapKeysLength > 0) {
      return {
        code: airportMap[seatMapOriginType].code,
        countryFlag: airportMap[seatMapOriginType].countryFlag,
        countryName: airportMap[seatMapOriginType].countryName,
        placeName: airportMap[seatMapOriginType].placeName,
      };
    }

    return null;
  };

  render() {
    this.mapPropsData(this.props);

    const { trips, seatMaps, resultId, seatItemIDs } = this.props;
    const { airportMap, segmentMap, bookedSeatMap } = this.computed;

    const tabs = seatMaps.map(seatMap => {
      const originDetails = this.showAirportMapInformation(airportMap, seatMap.origin);
      const destinationDetails = this.showAirportMapInformation(airportMap, seatMap.destination);

      return {
        key: seatMap.flightNumber,
        title: (
          <div className="flight-journey-indicator__popover">
            <FlightJourneyIndicator
              flightNumber={seatMap.flightNumber}
              origin={seatMap.origin}
              destination={seatMap.destination}
            />
            <FlightInfoSummaryPopover
              originDetails={originDetails}
              destinationDetails={destinationDetails}
            />
          </div>
        ),
        getContent: () =>
          seatMap.sections.length > 0 ? (
            <div className="grid">
              <div className="col-2 col-bleed">{this.seatInfo(seatMaps)}</div>
              <div className="col-7 col-bleed-y">
                <div className="seat-section box-shadow">
                  <SeatMapList
                    resultId={resultId}
                    seatMaps={seatMap.sections}
                    flightNumber={seatMap.flightNumber}
                  />
                </div>
              </div>
              <div className="col-3 col-bleed flight-summary">
                <div className="col-alignment box-shadow">{this.flightInfo()}</div>
              </div>
            </div>
          ) : (
            <SeatMapUnavailableCard />
          ),
      };
    });

    if (this.seatServiceAvailable() && !this.context.frozen) {
      return (
        <Fragment>
          {this.showSeatButtonLink()}
          <Modal
            className="flight-seat-map__modal"
            title={
              <Fragment>
                <I18nText id="flight_seat_map.header" />
                <div className="flight-seat-map__modal--header-button">
                  <Button
                    version="v2"
                    label={I18n.t('components.ibe.cart_item.flight.seat_selection_reset_all')}
                    size="small"
                    disabled={!seatItemIDs.length}
                    onClick={this.deleteSelections}
                  />
                  <Button
                    version="v2"
                    label={I18n.t('components.ibe.flight_service_selection.confirm')}
                    size="small"
                    type="primary"
                    primary={true}
                    onClick={this.confirmSelection}
                  />
                  <div
                    onClick={this.onModalClose}
                    onKeyDown={this.onModalClose}
                    role="presentation"
                  >
                    <Icon name="close" />
                  </div>
                </div>
              </Fragment>
            }
            isModalVisible={this.state.isModalVisible}
            onOutsideClick={this.onModalClose}
            alignContent="left"
          >
            <div className="flight-address-summary">
              {trips.length && trips[0].originCode && airportMap[trips[0].originCode] && (
                <FlightBookedSeatSummary
                  trips={trips}
                  segmentMap={segmentMap}
                  airportMap={airportMap}
                  seatMaps={seatMaps}
                  bookedSeatMap={bookedSeatMap}
                  currency={this.props.cart.currency}
                />
              )}
            </div>
            <Rtabs
              items={tabs}
              selectedTabKey={this.state.selectedTabKey}
              onChange={this.setSelectedTabKeyOnClick}
            />
          </Modal>
        </Fragment>
      );
    }

    return this.showSeatButtonLink();
  }
}

FlightSeatMap.defaultProps = {
  seatMaps: [],
  seatItemIDs: [],
};

FlightSeatMap.contextTypes = {
  frozen: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape({
    layman: PropTypes.bool.isRequired,
  }).isRequired,
};

FlightSeatMap.propTypes = {
  cart: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        subItems: PropTypes.arrayOf({
          journeyElementId: PropTypes.number,
          grossAmount: PropTypes.string,
          grossTotal: PropTypes.string,
        }),
      }),
    ),
    currency: PropTypes.string.isRequired,
  }).isRequired,
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      originCode: PropTypes.string.isRequired,
      destinationCode: PropTypes.string.isRequired,
      segments: PropTypes.arrayOf(
        PropTypes.shape({
          aircraftType: PropTypes.string.isRequired,
          destinationCode: PropTypes.string.isRequired,
          operatingCarrierCode: PropTypes.string.isRequired,
          operatingFlightNumber: PropTypes.string.isRequired,
          originCode: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  seatMaps: PropTypes.arrayOf(PropTypes.shape({})),
  resultId: PropTypes.string.isRequired,
  seatItemIDs: PropTypes.arrayOf(PropTypes.number),
  removeSeatsFromCart: PropTypes.func.isRequired,
  shouldLockScroll: PropTypes.bool.isRequired,
};

export default FlightSeatMap;
