import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';

import { cartFetched, fetchCart } from './';

export const COST_CENTERS_FETCHED = 'COST_CENTERS_FETCHED';
function costCenterFetched(costCenters) {
  return { type: COST_CENTERS_FETCHED, costCenters };
}

export function fetchCostCenterOfAccountingType(accountingTypeId) {
  return (dispatch, getState) => {
    const cart = getState().common.cart
    const cartId = cart.id;
    return httpClient
      .get(routes.api.costCenters.accountingTypeValues({accountingTypeId}))
      .then(({ data }) => {
        if(cartId) {
          dispatch(fetchCart(cartId));
        }
        dispatch(costCenterFetched(data));
        return data;
      });
  };
}


export const REMOVE_COST_CENTER_FROM_CART = 'REMOVE_COST_CENTER_FROM_CART';
export function removeCostCenterFromCart(id, accountingTypeId) {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_COST_CENTER_FROM_CART, id });
    const cartId = getState().common.cart.id;
    return httpClient
      .put(routes.api.carts.costCenters.remove({ cartId}), {
        accounting_type_id: accountingTypeId,
        cost_center_id: id,
      })
      .then(({ data }) => dispatch(cartFetched(data)));
  };
}

export const ADD_COST_CENTER_TO_CART = 'ADD_COST_CENTER_TO_CART';
export function addCostCenterToCart(newCostCenter) {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_COST_CENTER_TO_CART,
      costCenter: newCostCenter,
    });
    const cartId = getState().common.cart.id;
    return httpClient
      .post(routes.api.carts.costCenters.add({ cartId }), {
        cost_center_id: newCostCenter.id,
        accounting_type_id: newCostCenter.accountingTypeId,
        allocated_percentage: newCostCenter.allocatedPercentage,
        custom: newCostCenter.custom,
      })
      .then(({ data }) => dispatch(cartFetched(data)));
  };
}

export const UPDATE_CART_COST_CENTER = 'UPDATE_CART_COST_CENTER';
export function updateCartCostCenter(givenCostCenter) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_CART_COST_CENTER,
      costCenter: givenCostCenter,
    });
    const cartId = getState().common.cart.id;
    return httpClient
      .patch(routes.api.carts.costCenters.add({ cartId }), {
        cost_center_id: givenCostCenter.id,
        prev_cost_center_id: givenCostCenter.prevId,
        allocated_percentage: givenCostCenter.percentage,
        accounting_type_id: givenCostCenter.accountingTypeId,
        custom: givenCostCenter.custom,
      })
      .then(({ data }) => dispatch(cartFetched(data)));
  };
}
