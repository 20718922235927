import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag, I18nText } from '@wtag/react-comp-lib';
import ProductIcon from 'sharedWebpack/ProductIcon';
import RemoveItemButton from './RemoveItemButton';
import FlightServicesFareRules from './flight/FlightServicesFareRules';
import BookingLabelWithHint from '../../SearchResult/flight/BookingLabelWithHint';
import { itemShape } from '../shapes';
import CopyButton from './CopyButton';
import { TYPE_FLIGHT } from '../../../helpers/itemTypes';
import './styles.scss';

const CartItemLabels = (props, context) => {
  const {
    isCartLocked,
    item,
    journeyElement,
    itemNo,
    removeFromCart,
    itemDetails,
    flightServicesByResultId,
    travelers,
  } = props;

  const {
    pendingConfirmation,
    journeyElementType,
    type,
    approvalRequired,
    id,
    bookingAttributes,
  } = item;
  const journeyType = journeyElementType || type;
  const disableCopyButton =
    journeyElementType === TYPE_FLIGHT && !(itemDetails && itemDetails.available);
  const showFareRules = journeyElement && journeyElement.fareRulePresent;
  const { instantTicket } = itemDetails || {};
  const { refundable } = journeyElement || {};
  const { resultId } = bookingAttributes;

  const handleRemoveItemFromCart = cartItemId => {
    removeFromCart(cartItemId);
  };

  return (
    <div className="col-grid col-bleed align-center direction-row justify-space-between item__labels-price wrap">
      <div className="d-flex-col align-center">
        <div className="d-flex align-center column-gap-8">
          <ProductIcon
            className="item__labels--product-icon"
            productType={journeyType}
            size="tiny"
            showBGColor={true}
          />
          <div className="d-flex-col item__labels-price-title">
            <div className="d-flex align-center column-gap-8">
              <span>
                {I18n.t('components.ibe.cart.item', {
                  number: String(itemNo),
                })}
              </span>
              {!!removeFromCart && (
                <RemoveItemButton
                  className="item__labels-remove-button"
                  isCartLocked={isCartLocked}
                  itemId={id}
                  onConfirm={handleRemoveItemFromCart}
                  frozen={context.frozen}
                />
              )}
              <CopyButton
                item={item}
                journeyElement={journeyElement}
                travelers={travelers}
                flightServicesByResultId={flightServicesByResultId}
                isDisabled={disableCopyButton}
              />
            </div>
            {showFareRules && (
              <span className="item__fare-rules item__wrapper-price-sm">
                <FlightServicesFareRules
                  displayAsLink={true}
                  item={item}
                  resultId={resultId}
                  fareRulePresent={showFareRules}
                />
              </span>
            )}
          </div>
        </div>
        {pendingConfirmation && (
          <div className="item__labels-price-header--with-instant-ticket">
            <EmphasisTag
              text={<I18nText id="ibe.automatic_products.auto_added" returnStringOnly={true} />}
              type="warning"
              size="small"
            />
          </div>
        )}
      </div>
      <div className="align-center item__booking-status-labels item__wrapper-price-lg">
        {showFareRules && (
          <span className="align-center item__fare-rules flight__information-label-item item__wrapper-price-lg">
            <FlightServicesFareRules
              displayAsLink={true}
              item={item}
              resultId={resultId}
              fareRulePresent={showFareRules}
            />
          </span>
        )}
        <Fragment>
          {instantTicket && (
            <BookingLabelWithHint
              iconName="eTicket"
              iconColor="warning"
              text={<I18nText id="instant_issue" />}
              hint={<I18nText id="instant_ticket.description" />}
            />
          )}
          {approvalRequired && (
            <BookingLabelWithHint
              iconName="singlePolicy"
              iconColor="danger"
              text={<I18nText id="ibe.search_result.approval_required" />}
            />
          )}
          {refundable && (
            <BookingLabelWithHint
              iconName="checkCircleOutline"
              iconColor="success"
              text={<I18nText id="ibe.cart_item_pricing.refundable" />}
            />
          )}
        </Fragment>
      </div>
    </div>
  );
};

CartItemLabels.defaultProps = {
  journeyElement: null,
  item: {
    cancelPenalty: null,
    changePenalty: null,
  },
  itemDetails: { available: false },
  flightServicesByResultId: null,
  travelers: null,
};

CartItemLabels.contextTypes = {
  frozen: PropTypes.bool.isRequired,
};

CartItemLabels.propTypes = {
  isCartLocked: PropTypes.bool.isRequired,
  itemNo: PropTypes.number.isRequired,
  item: PropTypes.shape(itemShape),
  journeyElement: PropTypes.shape({
    name: PropTypes.string,
    vendorName: PropTypes.string,
    destinationCode: PropTypes.string,
    fareRulePresent: PropTypes.bool,
    id: PropTypes.number,
    originCode: PropTypes.string,
    refundable: PropTypes.bool,
  }),
  removeFromCart: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({
    instantTicket: PropTypes.bool.isRequired,
    available: PropTypes.bool.isRequired,
  }),
  flightServicesByResultId: PropTypes.shape({}),
  travelers: PropTypes.arrayOf(PropTypes.shape({})),
  journeyElementType: PropTypes.string.isRequired,
};

export default CartItemLabels;
