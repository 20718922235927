import moment from 'moment';

const calculateAge = birthday => {
  const birthdate = moment(birthday);
  const today = moment();

  return today.diff(birthdate, 'years');
};

export default calculateAge;
