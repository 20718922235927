import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Icon from '@wtag/rcl-icon';
import InstantTicketWrapper from '../../../InstantTicketWrapper';
import { NONE_FORM_OF_PAYMENT } from '../../lib/helpers/fop_options';

const InstantTicketItemCard = ({ items, payLater }) => {
  if (items.length === 0) {
    return null;
  }

  let fop;

  if (payLater) {
    fop = { type: NONE_FORM_OF_PAYMENT };
  }

  const headerText = payLater ? (
    <div>{I18n.t('ibe.payment.instant_ticket_card.header.item_without_fop')}</div>
  ) : (
    <div>{I18n.t('ibe.payment.instant_ticket_card.header.item_with_fop')}</div>
  );

  return (
    <Fragment>
      <div className="payment__instant-ticket-warning-card__header">{headerText}</div>
      <Card size="tiny">
        {items.map(item => (
          <div className="payment__instant-ticket-warning-card">
            <div className="col-8 col-bleed align-center direction-row justify-space-between payment__instant-ticket-warning-card__item-details">
              <Icon
                color="tertiary"
                name="aeroplane"
                showLabel={false}
                showTooltip={false}
                size="tiny"
                showBGColor={true}
              />

              <div className="payment__instant-ticket-warning-card__item-details-route">
                {item.itemTitle}
              </div>

              <div>
                <InstantTicketWrapper fop={fop} size="tiny" />
              </div>
            </div>

            <div className="col-4 col-bleed justify-end payment__instant-ticket-warning-card__item-details-fop">
              {I18n.t(item.formOfPayment.type, { scope: 'components.order_item.fop' })}
            </div>
          </div>
        ))}
      </Card>
    </Fragment>
  );
};

InstantTicketItemCard.defaultProps = {
  items: [],
  payLater: false,
};

InstantTicketItemCard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  payLater: PropTypes.bool,
};

const InstantTicketCard = ({ items, flightResultDetailsById }) => {
  const itemsWithInstantTicket = items.filter(
    item =>
      flightResultDetailsById[item.bookingAttributes.resultId] &&
      flightResultDetailsById[item.bookingAttributes.resultId].instantTicket,
  );

  const instantTicketWithoutFOP = itemsWithInstantTicket.filter(
    item => item.formOfPayment.type === NONE_FORM_OF_PAYMENT,
  );

  const instantTicketWithFOP = itemsWithInstantTicket.filter(
    item => item.formOfPayment.type !== NONE_FORM_OF_PAYMENT,
  );

  return (
    <Fragment>
      <div className="payment__instant-ticket-warning-message">
        {I18n.t('ibe.payment.confirmation_message.instant_ticket.sub_header')}
      </div>
      {instantTicketWithFOP.length > 0 && (
        <InstantTicketItemCard items={instantTicketWithFOP} payLater={false} />
      )}
      {instantTicketWithoutFOP.length > 0 && (
        <InstantTicketItemCard items={instantTicketWithoutFOP} payLater={true} />
      )}
    </Fragment>
  );
};

InstantTicketCard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  flightResultDetailsById: PropTypes.objectOf(
    PropTypes.shape({
      available: PropTypes.oneOf([true, false, null]),
    }),
  ).isRequired,
};

export default hot(module)(InstantTicketCard);
