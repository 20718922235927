import React from 'react';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';

const EmptyFilterAndResult = () => (
  <div className="col-12 col-grid col-bleed direction-row search-loading-screen__loaders">
    <div className="col-4 col-bleed search-loading-screen__loaders-filter">
      <Card version="v2" size="full">
        <ContentLoaderPlaceholder numberOfLines={8} showBackground={false} />
      </Card>
    </div>
    <div className="col-8 col-bleed">
      <div className="col-12 col-bleed search-loading-screen__loaders-result">
        <Card version="v2" size="full">
          <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
        </Card>
      </div>
      <div className="col-12 col-bleed search-loading-screen__loaders-result">
        <Card version="v2" size="full">
          <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
        </Card>
      </div>
    </div>
  </div>
);

export default EmptyFilterAndResult;
