import { connect } from 'react-redux';
import FinalBookingSteps from './FinalBookingSteps';

const mapStateToProps = (state, ownProps) => {
  const laymanMode = ownProps.laymanMode;
  const cart = state.common.cart;
  const cardStoredStatusByToken = {};
  const allCreditCards = Object.values(state.common.newCreditCard);
  const allCCTokens = allCreditCards.map(creditCard => {
    cardStoredStatusByToken[creditCard.token] = creditCard.storedInCcv;
    return creditCard.token;
  });

  const uniqueCCTokens = [...new Set(allCCTokens)];
  const cartItems = cart.items;
  const totalCartItems = cartItems && cartItems.length;
  const cartExpiredAt = cart.expiredAt;
  return {
    totalCartItems,
    uniqueCCTokens,
    cartItems,
    cartExpiredAt,
    cardStoredStatusByToken,
    allCreditCards,
    laymanMode,
  };
};

export default connect(mapStateToProps)(FinalBookingSteps);
