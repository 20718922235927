import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@wtag/rcl-icon';
import { Text } from '@wtag/rcl-typography';

import { fetchFareBasisInfo } from '../../../actions/flight';

import FareBasisInfo from '../../../Cart/Items/flight/FareBasisInfo';

const filterFareBasisInfo = (fareBasisInfo, filterSegments) => {
  if (!filterSegments) {
    return fareBasisInfo;
  }

  const filteredFareBasisInfo = [];

  fareBasisInfo.forEach(fareBasis => {
    const eligibleSegments = fareBasis.segments.filter(
      segment =>
        filterSegments.indexOf(`${segment.marketingCarrierCode}${segment.marketingFlightNumber}`) >=
        0,
    );
    if (eligibleSegments.length) {
      filteredFareBasisInfo.push({ ...fareBasis, segments: eligibleSegments });
    }
  });

  return filteredFareBasisInfo;
};

class MatrixFareRulesList extends React.Component {
  componentDidMount() {
    this.props.fetchFareBasisInfo(this.props.id);
  }

  render() {
    if (!this.props.fareBasisInfo) {
      return null;
    }

    if (this.props.fareBasisInfo && this.props.fareBasisInfo.length <= 0) {
      return (
        <div className="col-grid col-bleed">
          <div className="col-12 col-grid col-bleed-x align-center matrix-row__fare-brand--fare-rules-modal--no-fare-rules-icon">
            <Icon name="highPriority" size="huge" color="tertiary" showBGColor={true} />
          </div>
          <div className="col-12 col-grid col-bleed align-center">
            <Text level={1} weight="medium">
              {I18n.t('components.ibe.fare_basis_info.failed.header')}
            </Text>
            <Text level={2} weight="normal">
              {I18n.t('components.ibe.fare_basis_info.failed.subheader')}
            </Text>
          </div>
        </div>
      );
    }

    return (
      <Fragment>
        {filterFareBasisInfo(this.props.fareBasisInfo, this.props.filterSegments).map(
          fareBasisInfo => (
            <FareBasisInfo key={fareBasisInfo.info.code} {...fareBasisInfo} />
          ),
        )}
      </Fragment>
    );
  }
}

MatrixFareRulesList.defaultProps = {
  fareBasisInfo: null,
  filterSegments: null,
};

MatrixFareRulesList.propTypes = {
  id: PropTypes.string.isRequired,
  fareBasisInfo: PropTypes.shape([]),
  fetchFareBasisInfo: PropTypes.func.isRequired,
  filterSegments: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state, props) => ({
  fareBasisInfo: state.flights.fareBasisInfoByResultId[props.id],
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchFareBasisInfo: () => dispatch(fetchFareBasisInfo(props.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatrixFareRulesList);
