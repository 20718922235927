/* eslint-disable react/prop-types */
import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Card from '@wtag/rcl-card';
import { EmphasisTag } from '@wtag/react-comp-lib';
import OrderMapWrapper from 'sharedWebpack/OrderMapWrapper';
import PNRMap from 'sharedWebpack/PNRMap';
import DateTime from 'sharedWebpack/DateTime';
import {
  TYPE_FLIGHT,
  TYPE_HOTEL,
  TYPE_CAR,
  TYPE_CRUISE,
  TYPE_EVENT,
  TYPE_TRANSFER,
} from 'sharedWebpack/helpers/itemTypes';
import TravelplanItemFooter from './TravelplanItemFooter';
import Hotel from './Hotel';
import TravelplanItemHeader from './TravelplanItemHeader';
import Car from './Car';
import Cruise from './Cruise';
import Event from './Event';
import Transfer from './Transfer';
import getStayInfo from '../helpers/getStayInfo';
import getHotelTimings from '../helpers/getHotelTimings';
import FlightContent from './FlightContent';
import getFlightPlanMap from './getFlightPlanMap';
import './styles.scss';

const TravelPlan = ({ content, layman }) => {
  const {
    id,
    items,
    travelStartsAtHuman,
    startDate,
    endDate,
    travelPlan,
    title,
    identifier,
    products,
  } = content;
  const formattedDate = date => <DateTime dateTime={date} format="short" />;
  const flights = travelPlan.filter(plan => plan.type === TYPE_FLIGHT);
  const hotels = travelPlan.filter(plan => plan.type === TYPE_HOTEL);
  const stayInfo = getStayInfo(flights);

  const mapContent = useMemo(
    () => <OrderMapWrapper orderId={id} orderItems={items} layman={layman} />,
    [JSON.stringify(content)],
  );

  const flightPlanMap = useMemo(() => getFlightPlanMap(travelPlan), [travelPlan]);

  const getTravelPlanBody = (data, index) => {
    const { type } = data;
    const travelPlanBody = {
      [TYPE_HOTEL]: <Hotel data={data} />,
      [TYPE_CAR]: <Car data={data} />,
      [TYPE_CRUISE]: <Cruise data={data} />,
      [TYPE_EVENT]: <Event data={data} />,
      [TYPE_TRANSFER]: <Transfer data={data} />,
    };

    if (type === TYPE_FLIGHT) {
      return (
        <FlightContent key={uuidv4()} index={index} plan={data} flightPlanMap={flightPlanMap} />
      );
    }

    return (
      <div className="grid grid-gap-20 travel-plan__timeline-item travel-plan__timeline-item--with-background">
        {travelPlanBody[type]}
      </div>
    );
  };

  return (
    <Card version="v2">
      <div className="grid grid-gap-20 travel-plan">
        <div className="col-12 travel-plan__header">
          <div className="travel-plan__header-title">
            {I18n.t('admin.components.orders.overview.travel_plan.title')}
          </div>
          {travelStartsAtHuman && (
            <EmphasisTag
              className="travel-plan__header-tag"
              type="success"
              text={travelStartsAtHuman}
              size="small"
            />
          )}
        </div>
        <div className="col-12">
          <div className="travel-plan__map">
            {id && mapContent}
            {identifier && !id && <PNRMap products={content && products} />}
          </div>
        </div>
        {title && (
          <Fragment>
            <div className="col-12 travel-plan__sub-header">
              <div className="travel-plan__sub-header-sub-title">
                {I18n.t('admin.components.orders.overview.travel_plan.itinerary_of')}
              </div>
              <div className="travel-plan__sub-header-title">{title}</div>
            </div>
            <div className="col-12">
              <div className="travel-plan__section-divider" />
            </div>
          </Fragment>
        )}
        {startDate && (
          <Fragment>
            <div className="col-12">
              <div className="travel-plan__duration">
                <div className="travel-plan__duration-label">
                  {I18n.t('admin.components.orders.overview.travel_plan.on_travel_date')}
                </div>
                <div className="travel-plan__duration-dates">
                  {formattedDate(startDate)} - {formattedDate(endDate)}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="travel-plan__section-divider travel-plan__section-divider--dashed" />
            </div>
          </Fragment>
        )}
        <div className="col-12 travel-plan__timeline">
          {travelPlan &&
            travelPlan.map((data, index) => (
              <Fragment>
                {/* Headers */}
                {data.type === 'flight' &&
                  (data && data.tripOriginDetails.code) === (data && data.originDetails.code) && (
                    <TravelplanItemHeader firstItem={index === 0} data={data} />
                  )}
                {data.type === 'hotel' && ((data && data.checkIn) || (data && data.checkOut)) && (
                  <TravelplanItemHeader firstItem={index === 0} data={data} />
                )}
                {data.type === 'car' &&
                  ((data && data.pickUpTime) || (data && data.dropOffTime)) && (
                    <TravelplanItemHeader firstItem={index === 0} data={data} />
                  )}
                {data.type === 'cruise' &&
                  ((data && data.boardingTime) || (data && data.offBoardingTime)) && (
                    <TravelplanItemHeader firstItem={index === 0} data={data} />
                  )}
                {data.type === 'event' && ((data && data.startTime) || (data && data.endTime)) && (
                  <TravelplanItemHeader firstItem={index === 0} data={data} />
                )}
                {data.type === 'transfer' &&
                  ((data && data.pickUpTime) || (data && data.dropOffTime)) && (
                    <TravelplanItemHeader firstItem={index === 0} data={data} />
                  )}
                {/* Main Body */}
                {getTravelPlanBody(data, index)}
                {/* Footers */}
                {data.type === 'flight' &&
                  data.tripDestinationDetails.code === data.destinationDetails.code && (
                    <TravelplanItemFooter
                      data={data}
                      stayingPlaceInfo={stayInfo[data.destinationDetails.code]}
                      hotelTimings={getHotelTimings(hotels)}
                    />
                  )}
                {data.type === 'hotel' && data.checkOut && <TravelplanItemFooter data={data} />}
                {data.type === 'car' && data.dropOffTime && <TravelplanItemFooter data={data} />}
                {data.type === 'cruise' && data.offBoardingTime && (
                  <TravelplanItemFooter data={data} />
                )}
                {data.type === 'event' && data.endTime && <TravelplanItemFooter data={data} />}
                {data.type === 'transfer' && data.dropOffTime && (
                  <TravelplanItemFooter data={data} />
                )}
              </Fragment>
            ))}
        </div>
      </div>
    </Card>
  );
};

TravelPlan.defaultProps = {
  layman: false,
};

TravelPlan.propTypes = {
  content: PropTypes.shape({
    travelStartsAtHuman: PropTypes.number.isRequired,
    items: PropTypes.shape({}).isRequired,
  }).isRequired,
  layman: PropTypes.bool,
};

export default TravelPlan;
