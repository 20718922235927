import localStorage from '../lib/localStorage';

const getUniqueFlightResults = results => {
  const uniqueResults = [];
  const trips = [];

  results.forEach(result => {
    const uniqueString = [
      result.trips.map(trip => trip.origin).join('|'),
      result.trips.map(trip => trip.destination).join('|'),
      result.trips.map(trip => trip.date).join('|'),
    ].join('|');
    if (result.trips.length > 0 && !trips.includes(uniqueString)) {
      uniqueResults.push(result);
      trips.push(uniqueString);
    }
  });

  return uniqueResults;
};

const getUniqueHotelResults = results => {
  const uniqueResults = [];
  const hotels = [];

  results.forEach(result => {
    const uniqueString = [result.address, result.checkIn, result.longitude, result.latitude].join(
      '|',
    );

    if (!hotels.includes(uniqueString)) {
      uniqueResults.push(result);
      hotels.push(uniqueString);
    }
  });

  return uniqueResults;
};

const getUniqueCarResults = results => {
  const uniqueResults = [];
  const cars = [];

  results
    .filter(result => result.airportSearch === true || result.airportSearch === false)
    .forEach(result => {
      const uniqueString = [
        result.airportSearch,
        result.pickUpLocationAirportCode,
        result.pickUpGeoLocationAddress,
        result.dropOffLocationAirportCode,
        result.dropOffGeoLocationAddress,
        result.driverAge,
        result.pickUpDate || result.pickUpTime,
      ].join('|');

      if (!cars.includes(uniqueString)) {
        uniqueResults.push(result);
        cars.push(uniqueString);
      }
    });

  return uniqueResults;
};

const getUniqueResults = (searches, type) => {
  switch (type) {
    case 'flights':
      return getUniqueFlightResults(searches);
    case 'hotels':
      return getUniqueHotelResults(searches);
    case 'cars':
      return getUniqueCarResults(searches);
    default:
      return searches;
  }
};

export const saveSearch = (type, data) => {
  const generatedId = () => Math.floor(Math.random() * 100000);
  const randomId = generatedId();
  let searches = [];

  if (localStorage.getItem('lastSearches') === undefined) {
    localStorage.setItem('lastSearches', {});
  }

  let storageContent = JSON.parse(localStorage.getItem('lastSearches'));

  if (localStorage.getItem('lastSearches')) {
    storageContent = JSON.parse(localStorage.getItem('lastSearches'));
    if (storageContent[type] === undefined) {
      searches = [];
    } else {
      searches = storageContent[type];
    }
  }

  searches.unshift({ id: randomId, ...data });

  searches = getUniqueResults(searches, type);

  if (searches.length > 5) {
    searches.pop();
  }

  const newStorageContent = { ...storageContent, [type]: searches };

  localStorage.setItem('lastSearches', JSON.stringify(newStorageContent));
};

export default saveSearch;
