import remarkTypeBuilder from './remarkTypeBuilder';

const remarkTypeMapping = {
  galileo: ['fare', 'canned', 'ticket', 'agent_sign', 'other'],
  amadeus: ['other', 'accounting'],
  sabre: [
    'general',
    'historical',
    'invoice',
    'itinerary',
    'alpha',
    'client_address',
    'corporate',
    'delivery_address',
    'group_name',
    'hidden',
  ],
  worldspan: ['account', 'divider_card', 'other'],
  apollo: [
    'fare',
    'canned',
    'ticket',
    'agent_sign',
    'other',
    'service_fee',
    'invoice_layout',
    'purchase_order',
    'itinerary_with_fare',
    'itinerary_without_fare',
    'itinerary_with_amount',
  ],
  lh_group: [
    'hidden',
    'general',
    'historical',
    'invoice',
    'ticket',
    'air',
    'car',
    'hotel',
    'free_flow',
  ],
  american_airlines: [],
};

const accountingRemarkTypes = channel => remarkTypeBuilder(remarkTypeMapping[channel]);

export default accountingRemarkTypes;
