import { CUSTOMER_SEARCH_COMPLETE } from '../../actions/common/customer';

export default function customerSearchResults(state = [], action) {
  const newState = Object.assign([], state);

  switch (action.type) {
    case CUSTOMER_SEARCH_COMPLETE:
      newState[action.index] = {
        customers: action.customers,
      };
      return newState;

    default:
      return state;
  }
}
