import { parseQueryString } from './qsMethods';

const cache = {};
const memoizeLocationQuery = (queryString, queryStringConfigParams) => {
  if (queryString in cache) {
    return cache[queryString];
  }

  cache[queryString] = parseQueryString(queryString.replace(/^\?/, ''), queryStringConfigParams);
  return cache[queryString];
};

export default memoizeLocationQuery;
