import React from 'react';
import staticInformationShape from './staticInformationShape';
import StaticLinks from './StaticLinks';
import MidSectionContent from './Content';
import LiveFeed from './LiveFeed';

import './styles.scss';

const MidSection = ({ staticInformation }) => (
  <div className="col-grid col-bleed mid-section">
    <div className="col-lg-2 col-md-2 col-12 col-bleed mid-section__static-links">
      <StaticLinks staticInformation={staticInformation} />
    </div>
    <div className="col-lg-6 col-md-6 col-12 col-bleed mid-section__content">
      <MidSectionContent />
    </div>
    <div className="col-lg-4 col-md-4 col-12 col-bleed mid-section__live-feed">
      <LiveFeed />
    </div>
  </div>
);

MidSection.propTypes = {
  staticInformation: staticInformationShape.isRequired,
};

export default MidSection;
