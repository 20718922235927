import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';

const FacilityListItem = ({ className, label }) => (
  <Icon
    className={className}
    name="checkCircleOutline"
    color="success"
    size="tiny"
    showLabel={true}
    iconLabel={label}
  />
);

FacilityListItem.defaultProps = {
  className: null,
};

FacilityListItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default FacilityListItem;
