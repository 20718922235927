import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import Amount from 'sharedWebpack/Amount';

const Modifier = props =>
  I18n.t(props.modifier, {
    scope: 'components.ibe.car.facilities.modifiers',
  });

Modifier.propTypes = {
  modifier: PropTypes.oneOf([
    'per_day',
    'per_week',
    'per_month',
    'per_rental',
    'per_hour',
    'per_minute',
    'self_insured',
    'full_value',
  ]).isRequired,
};

const ModifierDetails = props => {
  const checkModifier = (amount, currency, modifierType) => {
    if (amount !== null) {
      if (amount === '0.0') {
        return (
          <div>
            <I18nText id="ibe.car.facilities.amount_included" />
            <Modifier modifier={modifierType} />
          </div>
        );
      } else if (amount !== '0.0' && modifierType === null) {
        return (
          <div>
            <Amount currency={currency} value={amount} />
          </div>
        );
      }
      else if(modifierType === 'percentage'){
        return (
          <div>
            {I18n.t('components.ibe.car.facilities.modifiers.percentage', {amount})} 
          </div>
        ); 
      }
      return (
        <div>
          <Amount currency={currency} value={amount} />
          <Modifier modifier={modifierType} />
        </div>
      );
    } else if (amount === null && modifierType === 'self_insured') {
      return (
        <div>
          <I18nText id="ibe.car.facilities.amount_excluded" />
          <Modifier modifier={modifierType} />
        </div>
      );
    } else if (amount === null && modifierType === 'full_value') {
      return I18n.t(modifierType, { scope: 'components.ibe.car.facilities.modifiers' });
    }
    return I18n.t('components.ibe.search_results.car.label.price_not_provided');
  };

  return checkModifier(props.amount, props.currency, props.modifierType);
};

ModifierDetails.propTypes = {
  currency: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  modifierType: PropTypes.string.isRequired,
};

export default ModifierDetails;
