import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import Popover from '@wtag/rcl-popover';
import './styles.scss';

const LabelWithHint = ({ text, hint }) => {
  const [isVisible, setIsVisible] = useState(false);

  const openPopover = () => {
    setIsVisible(true);
  };

  return (
    <div className="d-flex align-center">
      <span>{text}</span>
      <Popover
        className="label-with-hint__popover"
        isVisible={isVisible && !!hint}
        onOutsideClick={() => {
          setIsVisible(false);
        }}
        size="large"
        content={
          <div>
            <h3 className="label-with-hint__content-title">{text}</h3>
            <p className="label-with-hint__content-hint">{hint}</p>
          </div>
        }
        direction="top-right"
      >
        <span role="button" onKeyDown={openPopover} onClick={openPopover} tabIndex={-1}>
          <Icon className="label-with-hint__hint-icon" name="info" size="tiny" color="default" />
        </span>
      </Popover>
    </div>
  );
};

LabelWithHint.propTypes = {
  text: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
};

export default LabelWithHint;
