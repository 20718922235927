import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';
import { Menu } from '@wtag/rcl-menu';
import Icon from '@wtag/rcl-icon';
import TotalMarginBreakdown from './TotalMarginBreakdown';

import '../styles.scss';

const TotalMargin = ({ items, journeyElements, currency, marginTotal }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const onOutsideClick = () => {
    setIsMenuVisible(false);
  };

  const amountContent = <Amount currency={currency} value={marginTotal} />;

  return (
    <div className="margin__menu">
      <Menu
        isVisible={isMenuVisible}
        onOutsideClick={onOutsideClick}
        label={
          <div
            className="margin__menu-label"
            aria-hidden="true"
            role="button"
            onClick={() => setIsMenuVisible(prevState => !prevState)}
            onKeyPress={() => setIsMenuVisible(prevState => !prevState)}
          >
            {I18n.t('activerecord.attributes.order_item.margin')}
            {amountContent}
            <Icon name="info" size="small" color="tertiary" />
          </div>
        }
      >
        {items.map(item => (
          <TotalMarginBreakdown
            marginBreakdown={item.marginBreakdown}
            journeyElement={journeyElements[item.journeyElementType][item.journeyElementId]}
            journeyElementType={item.journeyElementType}
          />
        ))}
        <div className="margin__price-breakdown margin__item-body-row margin__trip-title">
          <Fragment>{I18n.t('activerecord.attributes.order_item.total')}</Fragment>
          {amountContent}
        </div>
      </Menu>
    </div>
  );
};

TotalMargin.propTypes = {
  items: PropTypes.shape({
    map: PropTypes.shape({}),
  }).isRequired,
  journeyElements: PropTypes.shape({}).isRequired,
  currency: PropTypes.string.isRequired,
  marginTotal: PropTypes.string.isRequired,
};

export default TotalMargin;
