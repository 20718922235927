import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const FilterButtons = ({ onAllSelectReset, isAllSelectDisabled, isResetDisabled }) => (
  <Fragment>
    <button
      className="search-accounts__filter-buttons"
      onClick={e => {
        onAllSelectReset(e, true);
      }}
      disabled={isAllSelectDisabled}
    >
      {I18n.t('components.ibe.search_form.search_accounts.select_all')}
    </button>
    <span className="search-accounts__divider-span"> | </span>
    <button
      className="search-accounts__filter-buttons"
      onClick={onAllSelectReset}
      disabled={isResetDisabled}
    >
      {I18n.t('components.ibe.search_results.filters.reset_flight')}
    </button>
  </Fragment>
);

FilterButtons.propTypes = {
  onAllSelectReset: PropTypes.func.isRequired,
  isAllSelectDisabled: PropTypes.bool.isRequired,
  isResetDisabled: PropTypes.bool.isRequired,
};

export default FilterButtons;
