import {
  CART_FETCHED,
  SET_CART_TEXT,
  DEAL_FETCHED,
  APPROVAL_REQUEST_FETCHED,
 } from '../../actions/common';

export default function cartId(state = '', action) {
  switch (action.type) {
    case CART_FETCHED:
      return action.cart.text || '';

    case DEAL_FETCHED:
      return action.deal.text || '';

    case APPROVAL_REQUEST_FETCHED:
      return action.approvalRequest.text || '';

    case SET_CART_TEXT:
      return action.text;

    default:
      return state;
  }
}
