import React from 'react';
import PropTypes from 'prop-types';

import FlightPreferencesInformation from './FlightPreferencesInformation';
import HotelPreferencesInformation from './HotelPreferencesInformation';
import CarPreferencesInformation from './CarPreferencesInformation';

const TravelPreferencesSection = props => {
  const {
    personId,
    bookingPreferences,
    fetchTraveller,
    cabinTypes,
    seatTypes,
    mealTypes,
    smokingTypes,
    carTypes,
    carCategories,
    carDrives,
    layman,
  } = props;

  return (
    <div className="traveller-edit-travel-preferences">
      <FlightPreferencesInformation
        personId={personId}
        flightPreferences={bookingPreferences.flight}
        fetchTraveller={fetchTraveller}
        cabinTypes={cabinTypes}
        seatTypes={seatTypes}
        mealTypes={mealTypes}
        layman={layman}
      />
      <HotelPreferencesInformation
        personId={personId}
        hotelPreferences={bookingPreferences.hotel}
        fetchTraveller={fetchTraveller}
        smokingTypes={smokingTypes}
        layman={layman}
      />
      <CarPreferencesInformation
        personId={personId}
        carPreferences={bookingPreferences.car}
        fetchTraveller={fetchTraveller}
        carTypes={carTypes}
        carCategories={carCategories}
        carDrives={carDrives}
        layman={layman}
      />
    </div>
  );
};

TravelPreferencesSection.defaultProps = {
  layman: false,
  bookingPreferences: {
    flight: {
      meal: '',
      preferredCabin: '',
      preferredSeating: '',
    },
    hotel: {
      smokingType: '',
    },
    car: {
      preferredCarTypes: [],
      preferredCarCategories: [],
      preferredCarDrives: [],
    },
  },
};

TravelPreferencesSection.propTypes = {
  layman: PropTypes.bool,
  personId: PropTypes.number.isRequired,
  fetchTraveller: PropTypes.func.isRequired,
  mealTypes: PropTypes.shape({}).isRequired,
  cabinTypes: PropTypes.shape({}).isRequired,
  seatTypes: PropTypes.shape({}).isRequired,
  smokingTypes: PropTypes.shape({}).isRequired,
  carTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  carDrives: PropTypes.arrayOf(PropTypes.string).isRequired,
  carCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  bookingPreferences: PropTypes.shape({
    flight: PropTypes.shape({
      meal: PropTypes.string,
      preferredCabin: PropTypes.string,
      preferredSeating: PropTypes.string,
    }),
    hotel: PropTypes.shape({
      smokingType: PropTypes.string,
    }),
    car: PropTypes.shape({
      preferredCarTypes: PropTypes.arrayOf(PropTypes.string),
      preferredCarCategories: PropTypes.arrayOf(PropTypes.string),
      preferredCarDrives: PropTypes.arrayOf(PropTypes.string),
      preferredCarAirConditioning: PropTypes.string,
    }),
  }),
};

export default TravelPreferencesSection;
