import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import { Link } from '@wtag/react-comp-lib';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import AccountingForm from '../Form';
import '../../../styles.scss';

const AccountingCreate = ({ orderId, urls, accountingTypes, accountingValues, scope }) => {
  const [accountingType, setAccountingType] = useState(null);
  const [accountingTypeValue, setAccountingTypeValue] = useState(null);
  const [allocatedPercentage, setAllocatedPercentage] = useState('');
  const [error, setError] = useState('');

  const createAccounting = async () => {
    const accountingParams = {
      accounting_type_id: accountingType,
      accounting_value: accountingTypeValue,
      allocated_percentage: allocatedPercentage,
      accounting_value_source: 1,
    };

    const { data } = await httpClient.post(routes[scope].orderAccountings.create({ orderId }), {
      order_cost_center: accountingParams,
    });

    if (data.error === null) {
      window.location.replace(urls.index);
    }
  };

  const onCreateAccounting = () =>
    createAccounting().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });

  return (
    <div className="col-grid align-center order-accounting__create-section">
      <div className="col-10">
        <div className="order-accounting__title">
          {I18n.t('admin.components.orders.accounting_tab.create.add_new')}
        </div>
        <div className="col-grid col-bleed-x">
          <Card version="v2">
            <AccountingForm
              accountingType={accountingType}
              setAccountingType={setAccountingType}
              accountingTypeValue={accountingTypeValue}
              setAccountingTypeValue={setAccountingTypeValue}
              allocatedPercentage={allocatedPercentage}
              setAllocatedPercentage={setAllocatedPercentage}
              accountingTypes={accountingTypes}
              accountingValues={accountingValues}
              error={error}
            />
          </Card>
        </div>
        <Button
          version="v2"
          size="small"
          type="primary"
          label={I18n.t('admin.components.orders.accounting_tab.create.add')}
          className="order-accounting-tab__submit"
          onClick={onCreateAccounting}
        />
        <Link type="button" modifier="default" size="small" href={urls.index}>
          {I18n.t('admin.shared.action.cancel')}
        </Link>
      </div>
    </div>
  );
};

AccountingCreate.defaultProps = {
  scope: 'admin',
};

AccountingCreate.propTypes = {
  orderId: PropTypes.number.isRequired,
  accountingTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  accountingValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  urls: PropTypes.shape({
    index: PropTypes.string,
  }).isRequired,
  scope: PropTypes.string,
};

export default AccountingCreate;
