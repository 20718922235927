import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  setDealName,
  setDealTeaser,
  saveDeal,
  removeFromCart,
  confirmCartItemAddition,
  fetchCart,
  fetchStaticProducts,
  addStaticProductToCart,
  setQuantity,
  fetchFees,
  addFeeToCart,
  setCartText,
  fetchApprovalRequest,
  setOutOfPolicyJustification,
} from '../actions/common';
import Cart from './index';
import fetchOnUpdate from '../lib/decorators/fetchOnUpdate';
import fetchServices from '../actions/common/fetchServices';

const forceDeal = state =>
  state.common.cart.items.length > 0 &&
  state.common.cart.items.every(item => item.type === 'static_product');

const mapStateToProps = state => {
  const { name, teaser } = state.common.deal;
  const { items } = state.common.cart;
  const travelerCount = state.common.travelerCount;
  const staticProducts = state.common.staticProducts;
  return {
    allItemsAvailable: state.common.cart.allItemsAvailable,
    approvalRequestId: state.common.approvalRequest.id,
    flightResultDetailsById: state.flights.resultDetailsById,
    flightServicesByResultId: state.flights.servicesByResultId,
    flightServicesFetched: state.common.cart.flightServicesFetched,
    items,
    cartState: state.common.cart.state,
    cartId: state.common.cart.id,
    continuePossible: state.common.cart.bookingPossible,
    voucher: state.common.cart.voucher,
    grossTotalsByGroup: state.common.cart.grossTotalsByGroup,
    isFetchingFop: state.common.cart.isFetchingFop,
    dealName: name,
    dealTeaser: teaser,
    dealSaveAvailable: name.length > 0 && teaser.length > 0 && items.length > 0,
    forceDeal: forceDeal(state),
    fees: state.common.fees,
    cartUrl: state.common.cart.cartUrl,
    isShared: state.common.cart.isShared,
    sharedByCurrentPerson: state.common.cart.sharedByCurrentPerson,
    staticProducts,
    cartText: state.common.cart.text,
    cartFees: state.common.cart.fees,
    journeyElements: state.common.journeyElements,
    total: state.common.cart.total,
    taxes: state.common.cart.taxes,
    title: state.common.cart.title,
    sharedTitle: state.common.cart.sharedTitle,
    currency: state.common.cart.currency,
    travelerCount,
    hasStaticProducts: staticProducts && Object.keys(staticProducts).length > 0,
    canBookQuoteItems: state.common.cart.canBookQuoteItems,
    fareBasisInfo: state.flights.fareBasisInfoByResultId,
    paymentMethodSurcharge: state.common.cart.paymentMethodSurcharge,
    hasItemsWithPendingConfirmation: state.common.cart.hasItemsWithPendingConfirmation,
    hasAutomaticallyAddedStaticProducts: state.common.cart.hasAutomaticallyAddedStaticProducts,
    totalWithoutVoucherReduction: state.common.cart.totalWithoutVoucherReduction,
    onlyOneItemFromSearch: state.common.cart.onlyOneItemFromSearch,
    travelers: state.common.cart.travelers,
  };
};

const mapDispatchToProps = dispatch => ({
  setDealName: name => dispatch(setDealName(name)),
  setDealTeaser: teaser => dispatch(setDealTeaser(teaser)),
  saveDeal: callbackParams => dispatch(saveDeal(callbackParams)),
  removeFromCart: (itemId, callbackParams) => dispatch(removeFromCart(itemId, callbackParams)),
  fetchCart: (cartId, callbackParams) => dispatch(fetchCart(cartId, callbackParams)),
  fetchApprovalRequest: (requestId, callbackParams) =>
    dispatch(fetchApprovalRequest(requestId, callbackParams)),
  fetchStaticProducts: () => dispatch(fetchStaticProducts()),
  fetchFees: callbackParams => dispatch(fetchFees(callbackParams)),
  addStaticProductToCart: (product, callbackParams) =>
    dispatch(addStaticProductToCart(product, callbackParams)),
  addFeeToCart: fee => dispatch(addFeeToCart(fee)),
  setQuantity: (itemId, quantity, callbackParams) =>
    dispatch(setQuantity(itemId, quantity, callbackParams)),
  setCartText: text => dispatch(setCartText(text)),
  fetchServices: (cart, callbackParams) => dispatch(fetchServices(cart, callbackParams)),
  confirmAddition: (itemId, callbackParams) =>
    dispatch(confirmCartItemAddition(itemId, callbackParams)),
  setOutOfPolicyJustification: (itemId, justification, callbackParams) =>
    dispatch(setOutOfPolicyJustification(itemId, justification, callbackParams)),
});

const CartWithFetchOnUpdate = fetchOnUpdate(['id'], (params, props) => {
  if (params.id) {
    props.fetchCart(params.id, props.callbackParams).then(fetchedCart => {
      if (fetchedCart.approvalRequestId !== props.approvalRequestId) {
        props.fetchApprovalRequest(fetchedCart.approvalRequestId, props.callbackParams);
      }

      props.fetchServices(fetchedCart, props.callbackParams);
    });
  }
})(Cart);

const CartWithContext = (props, context) => <CartWithFetchOnUpdate {...props} {...context} />;

CartWithContext.contextTypes = {
  callbackParams: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CartWithContext));
