import React from 'react';
import PropTypes from 'prop-types';
import App from './App';
import RootErrorBoundry from './RootErrorBoundary';

const Chat = props => (
  <RootErrorBoundry>
    <App {...props} />
  </RootErrorBoundry>
);

Chat.defaultProps = {
  focusToNotificationsTab: false,
  setFocusToNotificationsTab: () => {},
};

Chat.propTypes = {
  tenant: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentUserName: PropTypes.string.isRequired,
  currentUserAvatar: PropTypes.string.isRequired,
  isAlien: PropTypes.bool.isRequired,
  backendURL: PropTypes.string.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  focusToNotificationsTab: PropTypes.bool,
  setFocusToNotificationsTab: PropTypes.func,
  colors: PropTypes.shape({
    primaryColor: PropTypes.string,
    textColor: PropTypes.string,
  }).isRequired,
  setNotificationCount: PropTypes.func.isRequired,
};

export default Chat;
