import React from 'react';
import PropTypes from 'prop-types';

const PayFastForm = props => {
  const attributes = props.attributes;
  const inputFields = Object.keys(attributes).map(attribute => (
    <input type="hidden" name={attribute} key={attribute} value={attributes[attribute]} />
  ));

  return (
    <form
      style={{ display: 'none' }}
      action={props.url}
      method="post"
      {...(props.iframeMode && { target: '_blank' })}
    >
      {inputFields}
      <input ref={props.setButtonRef} type="submit" name="submit" />
    </form>
  );
};

PayFastForm.propTypes = {
  url: PropTypes.string.isRequired,
  iframeMode: PropTypes.bool.isRequired,
  attributes: PropTypes.shape().isRequired,
  setButtonRef: PropTypes.func.isRequired,
};

export default PayFastForm;
