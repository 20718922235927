import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { Route } from 'react-router-dom';
import withQueryParamsProvider from 'sharedWebpack/withQueryParamsProvider';
import withCCVPaymentHandle from 'sharedWebpack/CreditCardVaultPayment/withCCVPaymentHandle';
import UniqueCCTokenPanels from 'sharedWebpack/CreditCardVaultPayment/UniqueCCTokenPanels';
import ccvPaymentProps from 'sharedWebpack/CreditCardVaultPayment/helpers/ccvPaymentProps';
import {
  QUERY_UPDATE_TYPE,
  generateCCPanelKey,
  getCCTokensThatNeedToBook,
  ccPanelVisible as fopPanelVisible,
  setRequestsThatNeedAuthentication,
  triggerPerRequestThreeDs,
  skipThreeDsAuthenticationPerRequests,
  threeDsAuthenticationSkippedForAllItems,
  needToRunThreeDsAuthentication,
  checkIfNeededtoRunChecks,
} from 'sharedWebpack/CreditCardVaultPayment/helpers/ccvPayment';
import { byTokenShape, creditCardItemShape } from 'sharedWebpack/CreditCardVaultPayment/shapes';
import Cart from '../Cart/CartContainer';
import TravelerDetailsWrapper from '../TravelerDetails/TravelerDetailsWrapperContainer';
import Payment from '../Payment/PaymentContainer';
import Booking from '../ReviewBooking/BookingContainer';
import {
  cartItemsWithCreditCard,
  getEncodedBookingRequests,
  matchCCTokenWithItems,
} from '../ReviewBooking/helpers/booking';
import { callbackParamsShape, cartItemShape } from './shapes';

const FinalBookingSteps = props => {
  const [usableUniqueCCTokens, setUsableUniqueCCTokens] = useState([]);
  const [cardDataAvailableByToken, setCardDataAvailableByToken] = useState({});
  const [showCardAddedConfirmation, setShowCardAddedConfirmation] = useState(false);
  const [showTestPassedConfirmation, setShowTestPassedConfirmation] = useState(false);

  const {
    allCreditCards,
    requestsToAuthenticateByToken,
    bookingRequestsEncodedByToken,
    bookingComponentRefByToken,
    cardStoredStatusByToken,
    stringifiedBookingReq,
    currentAuthenticationRequest,
    threeDsStatusesByToken,
    stringifiedThreeDsStatuses,
    skipAuthenticationStatusesByToken,
    errorAlertByToken,
    successAlertByToken,
    primaryButtonText,
    primaryButtonDisabled,
    onPrimaryButtonClick,
    willResetCardAddressForm,
    setSkipAuthenticationStatusesByToken,
    toggleCardAddressFormState,
    updateCurrentAuthenticationRequest,
    updateRequestsToAuthenticateByToken,
    updateBookingRequestsEncodedByToken,
    updateBookingComponentRefByToken,
    stringifyNewBookingRequest,
    onUpdateThreeDsStatusesByToken,
    resetThreeDsStatusesByToken,
    resetSkipAuthenticationStatusesByToken,
    resetButtonAndAlertStates,
    totalCartItems,
    cartExpiredAt,
    cartItems,
    uniqueCCTokens,
    callbackParams,
    laymanMode,
    affiliatesBillingUrl,
  } = props;

  const [query, setQuery] = useQueryParams({
    openCreditCardPanel: withDefault(StringParam, ''),
    currentTab: withDefault(NumberParam),
  });

  const { openCreditCardPanel, currentTab } = query;

  const isThreeDsAuthenticationSkippedForAllItems = threeDsAuthenticationSkippedForAllItems(
    bookingRequestsEncodedByToken,
    skipAuthenticationStatusesByToken,
  );

  const hideSidePanel = () => {
    setQuery({ openCreditCardPanel: '' }, QUERY_UPDATE_TYPE);
  };

  const showCreditCardPanel = queryText => {
    setQuery({ openCreditCardPanel: queryText }, QUERY_UPDATE_TYPE);
  };

  const togglePanel = (ccToken, index) => {
    if (fopPanelVisible(ccToken, index, openCreditCardPanel)) {
      hideSidePanel();
      toggleCardAddressFormState(true);
    } else {
      const queryText = generateCCPanelKey(index);
      showCreditCardPanel(queryText);
    }
  };

  const triggerCreditCardItemsThreeDs = () => {
    resetSkipAuthenticationStatusesByToken();
    const needToRunChecks = checkIfNeededtoRunChecks(requestsToAuthenticateByToken);
    if (needToRunChecks) {
      resetButtonAndAlertStates();
      triggerPerRequestThreeDs({
        requestsToAuthenticateByToken,
        updateCurrentAuthenticationRequest,
        bookingComponentRefByToken,
        usableUniqueCCTokens,
        showCreditCardPanel,
      });
    }
  };

  const skipAuthenticationForAllItems = () => {
    resetThreeDsStatusesByToken();
    setRequestsThatNeedAuthentication(
      bookingRequestsEncodedByToken,
      threeDsStatusesByToken,
      updateRequestsToAuthenticateByToken,
    );
    skipThreeDsAuthenticationPerRequests({
      bookingRequestsEncodedByToken,
      skipAuthenticationStatusesByToken,
      bookingComponentRefByToken,
      setSkipAuthenticationStatusesByToken,
    });
  };

  const onThreeDsAuthenticationCompletion = () => {
    hideSidePanel();
    setShowTestPassedConfirmation(true);
  };

  const threeDsStatusCallback = (ccToken, encodedRequest, hasPassed) => {
    // we get the ccToken here from individual card components so we can know the current card index
    // of the three ds status callback
    const currentTokensRequestsToAuthenticate = requestsToAuthenticateByToken[ccToken];
    const indexOfCurrentStatus = currentTokensRequestsToAuthenticate
      ? currentTokensRequestsToAuthenticate.indexOf(encodedRequest)
      : -1;
    if (indexOfCurrentStatus > -1) {
      const encodedData = { ccToken, encodedRequest, hasPassed };
      onUpdateThreeDsStatusesByToken({
        usableUniqueCCTokens,
        requestsToAuthenticateByToken,
        bookingComponentRefByToken,
        indexOfCurrentStatus,
        showCreditCardPanel,
        encodedData,
        triggerCreditCardItemsThreeDs,
        onThreeDsAuthenticationCompletion,
      });
    }
  };

  const extraProps = {
    bookingComponentRefByToken,
    bookingRequestsEncodedByToken,
    ccTokensThatNeedToBook: getCCTokensThatNeedToBook(bookingRequestsEncodedByToken),
    triggerCreditCardItemsThreeDs,
    threeDsStatusesByToken,
    needToRunThreeDsChecks: needToRunThreeDsAuthentication(
      isThreeDsAuthenticationSkippedForAllItems,
      requestsToAuthenticateByToken,
    ),
    maximumNumberOfCardsAdded: usableUniqueCCTokens.length > 3,
    cardDataAvailableByToken,
    showCardAddedConfirmation,
    setShowCardAddedConfirmation,
    showTestPassedConfirmation,
    threeDsAuthenticationSkipped: isThreeDsAuthenticationSkippedForAllItems,
    skipAuthenticationForAllItems,
    setShowTestPassedConfirmation,
    triggerResetCardAddressForm: willResetCardAddressForm,
    setTriggerResetCardAddressForm: toggleCardAddressFormState,
    setQuery,
    totalCartItems,
  };

  const setNecessaryStatesPerToken = () => {
    const newBookingReqState = Object.assign(bookingRequestsEncodedByToken);
    const newBookingRefState = Object.assign(bookingComponentRefByToken);
    usableUniqueCCTokens.forEach(ccToken => {
      let ccTokenMatchedItems = [];
      if (ccToken) {
        const creditCartItems = cartItemsWithCreditCard(cartItems);
        ccTokenMatchedItems = matchCCTokenWithItems(creditCartItems, ccToken);

        newBookingReqState[ccToken] = getEncodedBookingRequests(ccTokenMatchedItems);

        if (!newBookingRefState[ccToken]) {
          newBookingRefState[ccToken] = React.createRef();
        }
      }
    });

    updateBookingRequestsEncodedByToken(newBookingReqState);
    updateBookingComponentRefByToken(newBookingRefState);
    stringifyNewBookingRequest(newBookingReqState);
    resetSkipAuthenticationStatusesByToken();
  };

  const setCardDataAvailableAndAlert = data => {
    setCardDataAvailableByToken(prevState => {
      const newState = Object.assign({}, prevState);
      newState[data.token] = true;

      return newState;
    });

    if (data.personId) {
      setShowCardAddedConfirmation(true);
    }
  };

  useEffect(() => {
    setNecessaryStatesPerToken();
  }, [JSON.stringify(usableUniqueCCTokens), cartItems]);

  useEffect(() => {
    const tokensThatAreUsable = uniqueCCTokens.filter(
      token => cardStoredStatusByToken[token] || cardDataAvailableByToken[token],
    );
    // we push null to this so we can track the add new card iframe through this null
    // this means at all times we have at least 1 value which is null token in the iframe array
    // that iframe will refer to the add new card iframe
    tokensThatAreUsable.push(null);
    setUsableUniqueCCTokens(tokensThatAreUsable);
  }, [JSON.stringify(uniqueCCTokens)]);

  useEffect(() => {
    setRequestsThatNeedAuthentication(
      bookingRequestsEncodedByToken,
      threeDsStatusesByToken,
      updateRequestsToAuthenticateByToken,
    );
    // we do the compare on stringified objects because deep nested
    // object comparing is not reliable all the time. stringified object
    // ensures that this runs every time there is a change in any of the objects
  }, [stringifiedBookingReq, stringifiedThreeDsStatuses]);

  return (
    <div>
      <Route
        path="/carts/:id"
        render={routeProps => <Cart {...routeProps} {...extraProps} expiredAt={cartExpiredAt} />}
      />
      <Route
        path="/orders/:id/traveler-details"
        render={routeProps => (
          <TravelerDetailsWrapper {...routeProps} {...extraProps} callbackParams={callbackParams} />
        )}
      />
      <Route
        path="/orders/:id/payment"
        render={routeProps => (
          <Payment
            {...routeProps}
            {...extraProps}
            laymanMode={laymanMode}
            affiliatesBillingUrl={affiliatesBillingUrl}
          />
        )}
      />
      <Route
        path="/orders/:id/booking"
        render={routeProps => <Booking {...routeProps} {...extraProps} />}
      />
      <UniqueCCTokenPanels
        uniqueCCTokens={usableUniqueCCTokens}
        allCreditCards={allCreditCards}
        cardStoredStatusByToken={cardStoredStatusByToken}
        bookingComponentRefByToken={bookingComponentRefByToken}
        bookingRequestsEncodedByToken={bookingRequestsEncodedByToken}
        currentAuthenticationRequest={currentAuthenticationRequest}
        threeDsStatusCallback={threeDsStatusCallback}
        setCardDataAvailableByToken={setCardDataAvailableAndAlert}
        errorAlertByToken={errorAlertByToken}
        successAlertByToken={successAlertByToken}
        primaryButtonText={primaryButtonText}
        primaryButtonDisabled={primaryButtonDisabled}
        onPrimaryButtonClick={onPrimaryButtonClick}
        togglePanel={togglePanel}
        openPanelParam={openCreditCardPanel}
        onSidePanelClick={hideSidePanel}
        onSidePanelClose={() => {
          hideSidePanel();
          toggleCardAddressFormState(true);
        }}
        currentTab={currentTab}
      />
    </div>
  );
};

FinalBookingSteps.defaultProps = {
  cartExpiredAt: null,
  uniqueCCTokens: [],
};

FinalBookingSteps.propTypes = {
  ...ccvPaymentProps,
  cartExpiredAt: PropTypes.string,
  children: PropTypes.node.isRequired,
  allCreditCards: PropTypes.arrayOf(creditCardItemShape).isRequired,
  uniqueCCTokens: PropTypes.arrayOf(PropTypes.string),
  cartItems: PropTypes.arrayOf(cartItemShape).isRequired,
  cardStoredStatusByToken: PropTypes.shape(byTokenShape).isRequired,
  laymanMode: PropTypes.bool.isRequired,
  affiliatesBillingUrl: PropTypes.string.isRequired,
  callbackParams: PropTypes.shape(callbackParamsShape).isRequired,
};

const withQueryParams = withQueryParamsProvider(FinalBookingSteps);

export default withCCVPaymentHandle(withQueryParams);
