import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NotificationEscalation from '../../../NotificationEscalation';
import ApprovalRequestReminder from './ApprovalRequestReminder';
import DocumentExpiryReminder from './DocumentExpiryReminder';

import './styles.scss';

const NotificationPreference = props => {
  const { onSaveTraveller, traveller } = props;
  const isLoading = !(Object.keys(traveller).length > 0);
  return (
    <Fragment>
      <ApprovalRequestReminder
        traveller={traveller}
        saveReminder={onSaveTraveller}
        isLoading={isLoading}
      />
      <DocumentExpiryReminder
        traveller={traveller}
        saveReminder={onSaveTraveller}
        isLoading={isLoading}
      />
      <NotificationEscalation
        saveChatConfiguration={onSaveTraveller}
        escalationPeriod={traveller.escalationPeriod}
        enableChatEmailNotification={traveller.chatEmailNotificationEnabled}
        isLoading={isLoading}
      />
    </Fragment>
  );
};

NotificationPreference.propTypes = {
  onSaveTraveller: PropTypes.func.isRequired,
  traveller: PropTypes.shape().isRequired,
};

export default NotificationPreference;
