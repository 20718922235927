import React, { Fragment } from 'react';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableData } from '@wtag/rcl-table';
import IconButton from '@wtag/rcl-icon-button';
import Icon from '@wtag/rcl-icon';
import { Link } from '@wtag/react-comp-lib';
import Avatar from '@wtag/rcl-avatar';
import Tooltip from '@wtag/rcl-tooltip';
import PropTypes from 'prop-types';
import Amount from 'sharedWebpack/Amount';
import TimeAgo from 'sharedWebpack/TimeAgo';
import EmptyTableContent from 'sharedWebpack/EmptyTableContent';
import TablePlaceholder from '../../../shared/Placeholder/TablePlaceholder';

const ApprovalRequestTableRow = ({ request, hideColumns }) => {
  const approvalRequestStatus = (status, declineRason) => {
    switch (status) {
      case 'approved':
        return (
          <Icon
            name="checkCircle"
            iconLabel={I18n.t('public.components.approval_request.status.approved')}
            showLabel={true}
            showBGColor={false}
            color="success"
            size="tiny"
          />
        );
      case 'denied':
        return (
          <div className="col-grid direction-row col-bleed align-center">
            <Icon
              className = "request-list__items-icon--with-top-align"
              name="closeCircle"
              iconLabel={I18n.t('public.components.approval_request.status.denied')}
              showLabel={true}
              showBGColor={false}
              color="danger"
              size="tiny"
            />
            {declineRason && (
              <div className="request-list__items-icon--with-left-top-margin">
                <Tooltip content={declineRason} type="disabled" position="top">
                  <Icon name="neutralOutline" color="default" size="tiny"/>
                </Tooltip>
              </div>
            )}
          </div>
        );
      case 'pending':
        return (
          <Icon
            name="checkCircle"
            iconLabel={I18n.t('public.components.approval_request.status.pending')}
            showLabel={true}
            showBGColor={false}
            size="tiny"
          />
        );
      default:
        return status;
    }
  };

  return (
    <TableRow key={request.id}>
      <TableData>{request.id}</TableData>
      {!hideColumns.requestor && request.requestor && (
        <TableData>
          <Avatar
            firstName={request.requestor.firstName}
            lastName={request.requestor.lastName}
            middleName={request.requestor.middleName}
            src={request.requestor.avatarUrl}
            showName={true}
            size="small"
          />
        </TableData>
      )}
      <TableData>
        {request.startsAt ? (
          <TimeAgo datetime={request.startsAt} />
        ) : (
          I18n.t('public.components.approval_request.table.data_unavailable')
        )}
      </TableData>
      <TableData>
        <Amount currency={request.currency} value={request.amount} />
      </TableData>
      <TableData>
        <TimeAgo datetime={request.createdAt} />
      </TableData>
      <TableData>{approvalRequestStatus(request.status, request.declineReason)}</TableData>
      <TableData align="center">
        <Link
          className="request-list__collection--action "
          href={request.urls.view}
          modifier="tertiary"
        >
          <IconButton
            version="v2"
            color="tertiary"
            icon={<Icon name="review" />}
            standalone={true}
            size="small"
            label={I18n.t('public.components.approval_request.action.review')}
          />
        </Link>
      </TableData>
    </TableRow>
  );
};

ApprovalRequestTableRow.defaultProps = {
  hideColumns: {
    requestor: false,
  },
};

ApprovalRequestTableRow.propTypes = {
  request: PropTypes.shape().isRequired,
  hideColumns: PropTypes.shape({
    requestor: PropTypes.bool,
  }),
};

const ApprovalRequestTable = props => {
  const { requests, isLoading, hideColumns } = props;

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>
              {I18n.t('public.components.approval_request.attributes.request_id')}
            </TableHeader>
            {!hideColumns.requestor && (
              <TableHeader>
                {I18n.t('public.components.approval_request.attributes.requestor')}
              </TableHeader>
            )}
            <TableHeader>
              {I18n.t('public.components.approval_request.attributes.departure_date')}
            </TableHeader>
            <TableHeader>
              {I18n.t('public.components.approval_request.attributes.amount')}
            </TableHeader>
            <TableHeader>
              {I18n.t('public.components.approval_request.attributes.created')}
            </TableHeader>
            <TableHeader>
              {I18n.t('public.components.approval_request.attributes.status')}
            </TableHeader>
            <TableHeader align="center">
              {I18n.t('public.components.approval_request.attributes.action')}
            </TableHeader>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <TablePlaceholder
              numberOfColumn={7 - Object.values(hideColumns).filter(data => data).length}
              numberOfRows={10}
              withoutRclTable={true}
            />
          ) : (
            requests.map(request => (
              <ApprovalRequestTableRow
                key={request.id}
                request={request}
                hideColumns={hideColumns}
              />
            ))
          )}
        </TableBody>
      </Table>
      {!isLoading && requests.length === 0 && <EmptyTableContent />}
    </Fragment>
  );
};

ApprovalRequestTable.defaultProps = {
  hideColumns: {
    requestor: false,
  },
  isLoading: true,
  requests: [],
};

ApprovalRequestTable.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.shape()),
  hideColumns: PropTypes.shape({
    requestor: PropTypes.bool,
  }),
  isLoading: PropTypes.bool,
};

export default ApprovalRequestTable;
