import routes from 'agentRoutes';
import httpV3Client from 'agentHTTPV3Client';

export const FLIGHT_MATRIXES_FETCHED = 'FLIGHT_MATRIXES_FETCHED';
export const FETCHING_FLIGHT_MATRICES = 'FETCHING_FLIGHT_MATRICES';

export function fetchFlightMatrixes(params) {
  const myParams = {
    searchId: params.searchId,
    'selected[]': params.selectedResults,
  };
  Object.keys(params.flightGroups).forEach(tripId => {
    myParams[`flight_groups[${tripId}]`] = params.flightGroups[tripId];
  });

  return dispatch => {
    dispatch({
      type: FETCHING_FLIGHT_MATRICES,
      resultIds: params.selectedResults,
      isFetching: true,
    });

    return httpV3Client.get(routes.api.flights.matrixes(myParams)).then(({ data }) => {
      dispatch({
        type: FLIGHT_MATRIXES_FETCHED,
        searchId: params.searchId,
        data,
      });
      dispatch({
        type: FETCHING_FLIGHT_MATRICES,
        resultIds: params.selectedResults,
        isFetching: false,
      });
      return data;
    });
  };
}
