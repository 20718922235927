import { ROUNDTRIP, MULTICITY } from '../IBE/lib/helpers/tripTypes';

const tripTypeIconMapper = tripType => {
  switch (tripType) {
    case ROUNDTRIP:
      return 'roundTrip';
    case MULTICITY:
      return 'multiCity';
    default:
      return 'arrowRight';
  }
};

export default tripTypeIconMapper;
