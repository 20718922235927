export const AEROPLANE_ICON = 'aeroplane';
export const HOTELROOM_ICON = 'hotelRoom';
export const CAR_ICON = 'car';
export const CRUISE_ICON = 'cruise';
export const EVENT_ICON = 'events';
export const TRANSFER_ICON = 'transfer';
export const EQUIPMENT_ICON = 'equipment';
export const SEAT_ICON = 'seat';
export const HELP_ICON = 'help';
export const SERVICE_ICON = 'equipment';
export const ETICKET_ICON = 'eTicket';
export const BAGGAGE_ICON = 'baggage';
