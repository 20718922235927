import React from 'react';
import PropTypes from 'prop-types';
import { Amount } from '@wtag/react-comp-lib';

const FormattedAmount = ({ currency, value, className, notation, locale, currencyDisplay }) => (
  <Amount
    className={className}
    currency={currency}
    currencyDisplay={currencyDisplay}
    value={value}
    notation={notation}
    minFractionDigit={2}
    locale={locale}
  />
);

FormattedAmount.defaultProps = {
  className: null,
  notation: 'standard', // The default notation for the amount in the agent is 'standard'.
  locale: 'de-CH', // The default locale for the amount in the agent is 'de-CH'.
  currencyDisplay: 'code',
};

FormattedAmount.propTypes = {
  currency: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  notation: PropTypes.oneOf(['standard', 'scientific', 'engineering', 'compact']),
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  currencyDisplay: PropTypes.string,
};

export default FormattedAmount;
