import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag, I18nText } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Amount from 'sharedWebpack/Amount';
import ProductIcon from 'sharedWebpack/ProductIcon';
import InstantTicketWrapper from 'sharedWebpack/InstantTicketWrapper';
import ItemPricing from '../Items/ItemPricing';
import FlightServicesFareRules from './flight/FlightServicesFareRules';
import AncillaryServices from './flight/AncillaryServices';
import FlightSeatMap from '../Items/flight/SeatDetails/FlightSeatMapContainer';
import staticProductShape from '../../../IBE/Search/static/shapes/staticProduct';
import { WELLTRAVEL } from '../../lib/helpers/welltravel_channel';
import {
  TYPE_FLIGHT,
  TYPE_HOTEL,
  TYPE_CAR,
  TYPE_STATIC_PRODUCT,
} from '../../../../shared/helpers/itemTypes';
import CopyButton from './CopyButton';
import './styles.scss';

const ItemLabels = (props, context) => {
  const {
    item,
    journeyElement,
    itemNo,
    flightServicesByResultId,
    flightResultDetailsById,
    showServices,
    staticProduct,
    itemStatus,
    pendingConfirmation,
    travelerCount,
    shouldLockScroll,
    setShouldLockScroll,
    travelers,
  } = props;
  const services =
    flightServicesByResultId && flightServicesByResultId[item.bookingAttributes.resultId];
  const itemDetails =
    item.bookingAttributes &&
    flightResultDetailsById &&
    flightResultDetailsById[item.bookingAttributes.resultId];
  const productId = item.bookingAttributes && item.bookingAttributes.productId;
  const identifier = item.channel && item.channel.identifier;
  const account = item.channel && item.channel.account;

  let available;
  if (item.journeyElementType === TYPE_FLIGHT) {
    if (itemDetails) {
      available = itemDetails.available;
    }
  } else {
    available = item.available;
  }

  const itemWithNumber = () =>
    I18n.t('components.ibe.cart.item', {
      number: String(itemNo),
    });

  const itemName = journeyElementType => {
    if (journeyElementType === TYPE_FLIGHT) {
      if (props.journeyElement) {
        const originCode = props.journeyElement.trips[0].originCode;
        const allCodes = new Set([originCode]);
        props.journeyElement.trips.forEach(trip => {
          allCodes.add(trip.destinationCode);
        });

        return [...allCodes].join(' - ');
      }
      return null;
    }

    if (journeyElementType === TYPE_HOTEL) {
      return props.journeyElement && props.journeyElement.name;
    }

    if (journeyElementType === TYPE_CAR) {
      return props.journeyElement && props.journeyElement.vendorName;
    }

    if (journeyElementType === TYPE_STATIC_PRODUCT) {
      return staticProduct && staticProduct.name;
    }

    return null;
  };

  const penaltyMessage = penalty => {
    let message;

    if (penalty.amount > 0 && penalty.currency) {
      message = (
        <span>
          <Amount currency={penalty.currency} value={penalty.amount} /> (
          {I18n.t('components.ibe.apx')})
        </span>
      );
    } else if (penalty.amount === '0.0') {
      message = I18n.t('components.ibe.fare_basis_info.penalty.free');
    } else {
      message = I18n.t('components.ibe.fare_basis_info.penalty.not_provided');
    }

    return message;
  };

  const trips = journeyElement && journeyElement.trips ? journeyElement.trips : [];

  return (
    <div className="col-12 col-grid col-bleed item__labels">
      <div className="col-12 col-grid col-bleed align-start direction-row justify-space-between item__labels-price wrap">
        <div className="item__labels-price-header">
          <ProductIcon
            productType={item.journeyElementType || item.type}
            size="tiny"
            showBGColor={true}
          />
          <div className="item__labels-price-title">
            {itemWithNumber()} | {itemName(item.journeyElementType || item.type)}
          </div>
          {pendingConfirmation && (
            <div className="item__labels-price-header--with-instant-ticket">
              <EmphasisTag
                text={<I18nText id="ibe.automatic_products.auto_added" returnStringOnly={true} />}
                type="warning"
                size="small"
              />
            </div>
          )}
          {itemDetails && itemDetails.instantTicket && (
            <div className="item__labels-price-header--with-instant-ticket">
              <InstantTicketWrapper fop={item.formOfPayment} />
            </div>
          )}
          <CopyButton
            item={item}
            journeyElement={journeyElement}
            travelers={travelers}
            flightServicesByResultId={flightServicesByResultId}
          />
        </div>
        <div className="item__labels-price-label">
          <ItemPricing
            type={item.journeyElementType || item.type}
            available={available}
            item={item}
            journeyElement={journeyElement}
            itemStatus={itemStatus}
            pendingConfirmation={pendingConfirmation}
            travelerCount={travelerCount}
          />
        </div>
      </div>
      <div className="col-12 col-bleed align-center direction-row justify-space-between item__labels-tags">
        <div className="col-8 col-bleed direction-row item__labels-tags">
          {!context.laymanMode && !productId && identifier !== WELLTRAVEL && (
            <div className="item__labels-tags-account">
              <EmphasisTag
                category={I18n.t(identifier, { scope: 'channels' })}
                text={account}
                type="default"
                size="small"
              />
            </div>
          )}
          <div className="item__labels-tags-policy">
            {(!context.laymanMode || context.travelerHasOrganization) && item.outOfPolicy && (
              <EmphasisTag
                text={I18n.t('components.ibe.search_result.labels.out_of_policy')}
                type="warning"
                size="small"
              />
            )}
          </div>
          <div className="item__labels-tags-approval-required">
            {(!context.laymanMode || context.travelerHasOrganization) && item.approvalRequired && (
              <EmphasisTag
                text={I18n.t('components.ibe.search_result.approval_required')}
                type="success"
                size="small"
              />
            )}
          </div>
        </div>
        {item.type !== TYPE_STATIC_PRODUCT && (
          <div className="item__labels-penalties">
            {item.changePenalty && (
              <div className="item__labels-penalties-change">
                {I18n.t('components.ibe.fare_basis_info.penalty.change_penalty')}:{' '}
                {penaltyMessage(item.changePenalty)}
              </div>
            )}
            {item.cancelPenalty && (
              <div className="item__labels-penalties-cancellation">
                {I18n.t('components.ibe.fare_basis_info.penalty.cancel_penalty')}:{' '}
                {penaltyMessage(item.cancelPenalty)}
              </div>
            )}
          </div>
        )}
      </div>
      {showServices && item.journeyElementType === TYPE_FLIGHT && (
        <div className="col-12 col-grid col-bleed align-center direction-row justify-space-between item__labels-buttons">
          <div className="col-8 col-bleed-x item__labels-buttons">
            <div className="item__labels-buttons-fare-rules">
              {journeyElement && journeyElement.fareRulePresent && (
                <FlightServicesFareRules
                  item={item}
                  resultId={item.bookingAttributes.resultId}
                  fareRulePresent={journeyElement && journeyElement.fareRulePresent}
                />
              )}
            </div>
            <div className="item__labels-buttons-seats">
              <FlightSeatMap
                trips={trips}
                seatMaps={services && services.seatMaps}
                resultId={item.bookingAttributes && item.bookingAttributes.resultId}
                itemId={item.id}
                shouldLockScroll={shouldLockScroll}
              />
            </div>
            {trips.length > 0 && (
              <div className="item__labels-buttons-misc">
                <AncillaryServices
                  services={services}
                  item={item}
                  trips={trips}
                  setShouldLockScroll={setShouldLockScroll}
                />
              </div>
            )}
          </div>
          <div className="col-4 col-bleed justify-end item__labels-buttons-tick">
            <div className="item__labels-buttons-tick-refund">
              {journeyElement && journeyElement.refundable && (
                <Icon
                  name="check"
                  showLabel={true}
                  size="tiny"
                  color="success"
                  showBGColor={true}
                  iconLabel={
                    <I18nText id="ibe.cart_item_pricing.refundable" returnStringOnly={true} />
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ItemLabels.defaultProps = {
  journeyElement: null,
  itemStatus: null,
  showServices: true,
  item: {
    cancelPenalty: null,
    changePenalty: null,
  },
  travelerCount: {
    adults: [],
    children: [],
    infants: [],
  },
};

ItemLabels.contextTypes = {
  allowBookingOfUnapprovedItems: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  offerOnly: PropTypes.bool.isRequired,
  travelerHasOrganization: PropTypes.bool.isRequired,
  frozen: PropTypes.bool.isRequired,
};

ItemLabels.propTypes = {
  showServices: PropTypes.bool,
  itemStatus: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }),
  services: PropTypes.shape({
    seatMaps: PropTypes.arrayOf(),
  }).isRequired,
  itemNo: PropTypes.number.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    formOfPayment: PropTypes.shape().isRequired,
    currency: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    available: PropTypes.bool,
    journeyElementType: PropTypes.string.isRequired,
    account: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    outOfPolicy: PropTypes.bool.isRequired,
    approvalRequired: PropTypes.bool.isRequired,
    instantTicket: PropTypes.bool.isRequired,
    itemStatus: PropTypes.arrayOf(),
    bookingAttributes: PropTypes.shape({
      resultId: PropTypes.number.isRequired,
      productId: PropTypes.number.isRequired,
    }),
    cancelPenalty: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.number.isRequired,
    }),
    changePenalty: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }),
  }),
  journeyElement: PropTypes.shape({
    name: PropTypes.string,
    vendorName: PropTypes.string,
    destinationCode: PropTypes.string,
    fareRulePresent: PropTypes.bool,
    id: PropTypes.number,
    originCode: PropTypes.string,
    refundable: PropTypes.bool,
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        originCode: PropTypes.string.isRequired,
        segments: PropTypes.arrayOf(
          PropTypes.shape({
            aircraftType: PropTypes.string.isRequired,
            destinationCode: PropTypes.string.isRequired,
            operatingCarrierCode: PropTypes.string.isRequired,
            operatingFlightNumber: PropTypes.string.isRequired,
            originCode: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }),
  staticProduct: staticProductShape.isRequired,
  flightServicesByResultId: PropTypes.shape({}).isRequired,
  flightResultDetailsById: PropTypes.arrayOf().isRequired,
  pendingConfirmation: PropTypes.bool.isRequired,
  travelerCount: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  shouldLockScroll: PropTypes.bool.isRequired,
  setShouldLockScroll: PropTypes.func.isRequired,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
    }),
  ).isRequired,
};

export default ItemLabels;
