import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Card from '@wtag/rcl-card';
import { Link, PillTabs } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Alert from 'sharedWebpack/Alert';
import SanitizedHTML from 'sharedWebpack/SanitizedHTML';
import Button from '@wtag/rcl-button';
import { Accordion, AccordionItem } from '@wtag/rcl-accordion';
import classNames from 'classnames';
import dividerContent from 'sharedWebpack/dividerContent';
import Amount from 'sharedWebpack/Amount';
import ProductIcon from 'sharedWebpack/ProductIcon';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import AddNewCreditCardPanel from 'sharedWebpack/CreditCardVaultPayment/AddNewCreditCard/AddNewCreditCardPanel';
import maskedNumberNormalizer from 'sharedWebpack/CreditCardVaultPayment/helpers/maskedNumberNormalizer';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import PayIndividually from './PayIndividually';
import PayAllAtOnceOption from './PayAllAtOnceOption';
import AcceptableFOP from './AcceptableFOP';
import paymentMethodLocaleName from '../helpers/paymentMethodLocaleName';
import getFopItems from '../helpers/getFopItems';
import supportedFopIcons from '../helpers/supportedFopIcons';
import {
  CASH_FORM_OF_PAYMENT,
  CREDIT_CARD_FORM_OF_PAYMENT,
  WALLET_FORM_OF_PAYMENT,
} from '../../lib/helpers/fop_options';
import useElementSize from '../helpers/customHooks/useElementSize';
import { WARNING, DANGER } from '../../lib/helpers/walletConditions';
import './styles.scss';

const PaymentSidebar = ({
  currency,
  callbackParams,
  creditCards,
  setFormOfPaymentForAllItems,
  set3rdPartyPaymentMethod,
  setFormOfPaymentToCreditCard,
  setFormOfPaymentToCash,
  setFormOfPaymentToWelltravelWallet,
  setFormOfPaymentToIATA,
  setFormOfPaymentToNone,
  paymentDisabled,
  allowPaymentViaInvoice,
  paymentOptions,
  configurePaymentGateway,
  paymentGateway,
  laymanMode,
  maximumNumberOfCardsAdded,
  triggerResetCardAddressForm,
  setTriggerResetCardAddressForm,
  resetCreditCardBillingAddressForm,
  availableFOPsForAll,
  items,
  resetFormOfPayment,
  setQuery,
  isFopLoading,
  setIsFopLoading,
  selectedItemId,
  setSelectedItemId,
  cardDataAvailableByToken,
  walletType,
  refreshBalance,
  isLoading,
  affiliatesBillingUrl,
  welltravelWalletDetails,
}) => {
  const [selectedPilTabKey, setSelectedPilTabKey] = useState(0);
  const [activeAccordionId, setActiveAccordionId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prevTab, setPrevTab] = useState(1);
  const containerRef = useRef(null);
  const containerWidth = useElementSize(containerRef).width;
  let paymentTabs;

  const contentLoader = <ContentLoaderPlaceholder numberOfLines={3} showBackground={false} />;

  const remainingBalance = welltravelWalletDetails && welltravelWalletDetails.remainingBalance;
  const balance = welltravelWalletDetails && welltravelWalletDetails.balance;
  const requiredBalance = welltravelWalletDetails && welltravelWalletDetails.requiredBalance;
  const welltravelWalletCurrency = welltravelWalletDetails && welltravelWalletDetails.currency;
  const exceedingBalance = welltravelWalletDetails && welltravelWalletDetails.exceedingBalance;

  const getFopSetSize = () => {
    const selectedFopSet = new Set();

    items
      .filter(item => item.formOfPayment.type)
      .forEach(item => {
        const { type, cardDetails } = item.formOfPayment;

        const conditionToReset =
          type === CREDIT_CARD_FORM_OF_PAYMENT &&
          cardDataAvailableByToken[cardDetails.token] !== true &&
          cardDetails.storedInCcv !== true;

        if (conditionToReset) {
          selectedFopSet.clear();
          return;
        }
        if (type === CREDIT_CARD_FORM_OF_PAYMENT) {
          selectedFopSet.add(cardDetails.token);
        } else {
          selectedFopSet.add(type);
        }
      });

    return selectedFopSet.size;
  };

  const handleTabChange = value => {
    setSelectedPilTabKey(prev => {
      setPrevTab(prev);
      return value;
    });
  };

  const onConfirm = () => {
    setPrevTab(0);
    setIsModalOpen(false);
    setIsFopLoading(true);

    resetFormOfPayment().then(() => {
      setIsFopLoading(false);
    });
  };

  const onReject = () => {
    setSelectedPilTabKey(1);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isFopLoading && prevTab === 1 && selectedPilTabKey === 0 && getFopSetSize() > 1) {
      const allItemHasFop = items.every(item => item.formOfPayment.type);
      if (allItemHasFop) {
        setIsModalOpen(true);
      }
    }
  }, [items, selectedPilTabKey]);

  const onClick = id => {
    setActiveAccordionId(activeAccordionId === id ? '' : id);
  };

  const selectForAll = (fop, is3rdPartyPayment = false) => {
    setIsFopLoading(true);

    const fopPromiseWithout3rdParty = Promise.resolve(
      setFormOfPaymentForAllItems(
        fop.card,
        items.map(item => {
          const itemResultId = {
            cardType: fop.type,
            itemId: item.id,
          };
          return itemResultId;
        }),
      ),
    );

    let fopPromiseWith3rdParty;

    if (is3rdPartyPayment) {
      configurePaymentGateway({
        ...paymentOptions[fop.paymentType],
        cardType: fop.paymentType,
      });

      fopPromiseWith3rdParty = Promise.resolve(
        set3rdPartyPaymentMethod(fop.paymentType, callbackParams),
      );
    }

    Promise.all([fopPromiseWithout3rdParty, fopPromiseWith3rdParty]).then(() => {
      setIsFopLoading(false);
    });
  };

  const selectedForAll = fop => {
    const allCartFOPs = items.map(item => item.formOfPayment);

    if (allCartFOPs.length === 0) return false;

    const card = fop && fop.card;
    let allItemHasFop;

    if (card) {
      allItemHasFop = allCartFOPs.every(
        selectedFop =>
          selectedFop.type === CREDIT_CARD_FORM_OF_PAYMENT &&
          selectedFop.cardDetails &&
          selectedFop.cardDetails.token === card.token,
      );
    } else {
      allItemHasFop = fop && allCartFOPs.every(selectedFop => selectedFop.type === fop.type);
    }
    return !isFopLoading && allItemHasFop;
  };

  const allowInvoiceAlongWith3rdParty = () => {
    const invoiceFop = {
      type: CASH_FORM_OF_PAYMENT,
      paymentType: CASH_FORM_OF_PAYMENT,
      card: null,
    };

    const availablePaymentMethods = Object.keys(paymentOptions).map(paymentType => (
      <PayAllAtOnceOption
        key={paymentType}
        type={paymentType}
        onSelect={() =>
          selectForAll(
            {
              ...invoiceFop,
              paymentType,
            },
            true,
          )
        }
        isSelectedForAll={
          !isFopLoading &&
          paymentGateway &&
          paymentGateway.gateway === paymentOptions[paymentType].gateway &&
          paymentGateway.cardType === paymentType
        }
        gateWayDetails={paymentOptions[paymentType]}
        laymanMode={laymanMode}
        surcharge={paymentOptions[paymentType].surcharge}
        isFopLoading={isFopLoading}
        currency={currency}
      />
    ));

    if (allowPaymentViaInvoice) {
      const isCashSupported = availableFOPsForAll.cash.supported;

      if (isCashSupported) {
        availablePaymentMethods.push(
          <PayAllAtOnceOption
            key="invoice"
            onSelect={() => selectForAll(invoiceFop, true)}
            isSelectedForAll={!paymentGateway && selectedForAll(invoiceFop)}
            type={invoiceFop.type}
            laymanMode={laymanMode}
            isFopLoading={isFopLoading}
            currency={currency}
          />,
        );
      }
    }

    return availablePaymentMethods;
  };

  const availableWallet = getFopItems(availableFOPsForAll, laymanMode, creditCards).filter(
    fop => fop.type === WALLET_FORM_OF_PAYMENT,
  );

  const payAllAtOnceOptionWalletDescription = () => {
    if (parseFloat(requiredBalance) > balance) {
      return (
        <SanitizedHTML
          html={I18n.t('admin.components.orders.items_tab.form_of_payment.text.short_wallet', {
            currency: welltravelWalletCurrency,
            amount: parseFloat(exceedingBalance).toFixed(2),
          })}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (selectedForAll(availableWallet[0]) && Number(requiredBalance) > balance) {
      refreshBalance();
    }
  }, []);

  const payIndividuallyWalletDescription = () => {
    switch (walletType) {
      case WARNING:
        return I18n.t('admin.components.orders.items_tab.form_of_payment.text.insufficient.some');
      case DANGER:
        return I18n.t('admin.components.orders.items_tab.form_of_payment.text.insufficient.all');
      default:
        return null;
    }
  };

  const walletActionContent = descriptionText => (
    <div className="payment-sidebar__button-action">
      <Alert
        className="payment-sidebar__alert"
        type="warning-light"
        isBackgroundVisible={false}
        isIconVisible={true}
        isVisible={descriptionText}
        hideClose={true}
      >
        {descriptionText}
      </Alert>
      <div className="payment-sidebar__button-action__wrapper">
        <Button
          icon={<Icon name="refresh" />}
          label={I18n.t('admin.components.orders.items_tab.label.refresh_balance')}
          size="small"
          onClick={refreshBalance}
        />
        <Link
          href={affiliatesBillingUrl}
          openNewTab={true}
          type="button"
          modifier="default"
          size="small"
        >
          <div className="payment-sidebar__top-up">
            <Icon name="topUp" />
            {I18n.t('admin.components.orders.items_tab.label.top_up')}
          </div>
        </Link>
      </div>
    </div>
  );

  const payOnceContent = () => (
    <Fragment>
      {availableWallet.length > 0 && (
        <Fragment>
          {isLoading ? (
            <div className="payment-sidebar__wallet payment-sidebar__wallet__loader">
              {contentLoader}
            </div>
          ) : (
            <Fragment>
              <div className="payment-sidebar__wallet-balance">
                <PayAllAtOnceOption
                  key={availableWallet[0].type}
                  onSelect={() => selectForAll(availableWallet[0])}
                  isSelectedForAll={selectedForAll(availableWallet[0])}
                  type={availableWallet[0].type}
                  card={availableWallet[0].card}
                  laymanMode={laymanMode}
                  isFopLoading={isFopLoading}
                  walletBalance={balance}
                  walletCurrency={welltravelWalletCurrency}
                  isDeactivated={!requiredBalance || parseFloat(requiredBalance) > balance}
                />
              </div>
              {walletActionContent(payAllAtOnceOptionWalletDescription())}
            </Fragment>
          )}
          <div className="payment-sidebar__divider-wrapper">
            {dividerContent()}
            <span className="payment-sidebar__divider-wrapper__content">
              {I18n.t('components.match_traveler.or')}
            </span>
            {dividerContent()}
          </div>
        </Fragment>
      )}
      <div className="payment-sidebar__hint">
        {I18n.t('admin.components.orders.items_tab.label.pay_once_hint_text')}
      </div>
      {!paymentDisabled ? (
        allowInvoiceAlongWith3rdParty()
      ) : (
        <Fragment>
          {getFopItems(availableFOPsForAll, laymanMode, creditCards).map(
            fop =>
              fop.type !== WALLET_FORM_OF_PAYMENT && (
                <PayAllAtOnceOption
                  items={items}
                  key={fop.type}
                  onSelect={() => selectForAll(fop)}
                  isSelectedForAll={selectedForAll(fop)}
                  type={fop.type}
                  card={fop.card}
                  laymanMode={laymanMode}
                  isFopLoading={isFopLoading}
                  currency={currency}
                />
              ),
          )}
          <AcceptableFOP
            fops={supportedFopIcons(availableFOPsForAll, laymanMode)}
            selectedPilTabKey={selectedPilTabKey}
            containerWidth={containerWidth}
          />
        </Fragment>
      )}
    </Fragment>
  );

  const payIndividuallyIconLabelContent = (
    <div className="payment-sidebar__icon-label">
      <Fragment>
        {I18n.t('admin.components.orders.items_tab.label.wallet')}
        <Amount
          className={`payment-sidebar__individual-amount payment-sidebar__individual-amount--${walletType}`}
          currency={welltravelWalletCurrency}
          value={balance}
          notation="compact"
        />
      </Fragment>
      <Fragment>
        {I18n.t('admin.components.orders.items_tab.label.remaining_wallet')}
        <Amount
          className="payment-sidebar__individual-amount"
          currency={welltravelWalletCurrency}
          value={remainingBalance}
        />
      </Fragment>
    </div>
  );

  const payIndividuallyWalletContent = isLoading ? (
    <div className="payment-sidebar__wallet payment-sidebar__wallet__loader">{contentLoader}</div>
  ) : (
    <Fragment>
      <div className="payment-sidebar__wallet">
        <Icon
          className="payment-sidebar__icon"
          name="walletBalance"
          size="normal"
          color="tertiary"
          showLabel={true}
          iconLabel={payIndividuallyIconLabelContent}
          showBGColor={true}
        />
      </div>
      {walletActionContent(payIndividuallyWalletDescription())}
    </Fragment>
  );

  const payIndividuallyContent = (
    <Fragment>
      <div className="payment-sidebar__hint">
        {I18n.t('admin.components.orders.items_tab.label.pay_individually_hint_text')}
      </div>
      {availableWallet.length > 0 && payIndividuallyWalletContent}

      <Accordion className="pay-individually">
        {items.map(item => {
          const {
            id,
            journeyElementType,
            itemTitle,
            formOfPayment,
            grossTotal,
            availableFormOfPayment,
          } = {
            ...item,
          };

          const header = () => {
            const paymentMethod = () => {
              if ((!selectedItemId && isFopLoading) || selectedItemId === item.id) {
                return (
                  <ContentLoaderPlaceholder
                    numberOfLines={1}
                    showBackground={false}
                    contentHeight="15px"
                    contentWidth="80px"
                  />
                );
              }

              if (!item.formOfPayment.type) {
                return I18n.t(
                  'admin.components.orders.items_tab.form_of_payment.text.payment_missing',
                );
              }

              if (formOfPayment.cardDetails) {
                return `${paymentMethodLocaleName(
                  formOfPayment.cardDetails.cardType,
                )} ${maskedNumberNormalizer(formOfPayment.cardDetails.maskedNumber)}`;
              }

              return paymentMethodLocaleName(item.formOfPayment.type, laymanMode);
            };

            return (
              <div className="pay-individually__header">
                <ProductIcon
                  productType={journeyElementType}
                  showBGColor={true}
                  color="tertiary"
                  size="small"
                  className="pay-individually__header--product-icon"
                />
                <div className="pay-individually__header--left">
                  <div className="pay-individually__header--type">{journeyElementType}</div>
                  <div className="pay-individually__header--title">{itemTitle}</div>
                </div>
                <div className="pay-individually__header--right">
                  <Amount
                    className="pay-individually__header--price"
                    currency={currency}
                    value={grossTotal}
                  />
                  <div className="pay-individually__header--payment-status">{paymentMethod()}</div>
                </div>
              </div>
            );
          };

          return (
            <AccordionItem
              className={classNames({
                'pay-individually__background': id === activeAccordionId,
              })}
              key={id}
              id={id}
              isActive={id === activeAccordionId}
              onClick={onClick}
              header={header()}
            >
              <PayIndividually
                id={id}
                availableFormOfPayment={availableFormOfPayment}
                creditCards={creditCards}
                laymanMode={laymanMode}
                selectedPilTabKey={selectedPilTabKey}
                setFormOfPaymentToCreditCard={setFormOfPaymentToCreditCard}
                setFormOfPaymentToCash={setFormOfPaymentToCash}
                setFormOfPaymentToWelltravelWallet={setFormOfPaymentToWelltravelWallet}
                setFormOfPaymentToIATA={setFormOfPaymentToIATA}
                setFormOfPaymentToNone={setFormOfPaymentToNone}
                selectedCardToken={
                  formOfPayment.cardDetails ? formOfPayment.cardDetails.token : formOfPayment.type
                }
                setIsFopLoading={setIsFopLoading}
                isFopLoading={isFopLoading}
                setSelectedItemId={setSelectedItemId}
                containerWidth={containerWidth}
                walletBalance={remainingBalance}
                grossTotal={grossTotal}
                currency={currency}
              />
            </AccordionItem>
          );
        })}
      </Accordion>
    </Fragment>
  );

  if (!paymentDisabled) {
    paymentTabs = [
      {
        tabNum: 0,
        title: I18n.t('admin.components.orders.items_tab.label.pay_once_for_all'),
        content: payOnceContent,
      },
    ];
  } else {
    paymentTabs = [
      {
        tabNum: 0,
        title: I18n.t('admin.components.orders.items_tab.label.pay_once_for_all'),
        disabled: isFopLoading && selectedPilTabKey === 1,
        content: payOnceContent,
      },
      {
        tabNum: 1,
        title: I18n.t('admin.components.orders.items_tab.label.pay_individually'),
        disabled: isFopLoading && selectedPilTabKey === 0,
        content: payIndividuallyContent,
      },
    ];
  }

  return (
    <Card version="v2">
      <div
        className="payment-sidebar"
        ref={containerRef}
        style={{ '--payment-container-width': containerWidth }}
      >
        <div className="payment-sidebar__title">{I18n.t('ibe.payment.left_panel_header')}</div>
        <PillTabs
          controlled={true}
          selectedTabKey={selectedPilTabKey}
          items={paymentTabs.map(({ title, content, disabled }, index) => ({
            key: index,
            title,
            disabled,
            getContent: () => content,
          }))}
          onChange={handleTabChange}
        />

        <ConfirmationModal
          className="payment-sidebar__reset-fop-modal"
          isModalOpen={isModalOpen}
          confirmationHeader={I18n.t(
            'admin.components.orders.items_tab.form_of_payment.text.payment_selection',
          )}
          subHeader={I18n.t(
            'admin.components.orders.items_tab.form_of_payment.text.discard_selection',
          )}
          confirmationText={I18n.t(
            'admin.components.orders.items_tab.form_of_payment.text.confirm',
          )}
          rejectionText={I18n.t('admin.components.orders.items_tab.form_of_payment.text.close')}
          onConfirm={onConfirm}
          onReject={onReject}
          withAction={true}
          type="warning"
        />

        {paymentDisabled && availableFOPsForAll.creditCard.supported && (
          <AddNewCreditCardPanel
            maximumNumberOfCardsAdded={maximumNumberOfCardsAdded}
            triggerResetCardAddressForm={triggerResetCardAddressForm}
            setTriggerResetCardAddressForm={setTriggerResetCardAddressForm}
            resetCreditCardBillingAddressForm={resetCreditCardBillingAddressForm}
            setQuery={setQuery}
            currentTab={selectedPilTabKey}
          />
        )}
      </div>
    </Card>
  );
};

PaymentSidebar.defaultProps = {
  paymentGateway: null,
  creditCards: null,
  paymentOptions: null,
  walletType: null,
  isLoading: false,
  refreshBalance: () => {},
  affiliatesBillingUrl: null,
};

PaymentSidebar.defaultProps = {
  welltravelWalletDetails: null,
};

PaymentSidebar.propTypes = {
  currency: PropTypes.string.isRequired,
  creditCards: PropTypes.shape({}),
  setFormOfPaymentForAllItems: PropTypes.func.isRequired,
  setFormOfPaymentToCreditCard: PropTypes.func.isRequired,
  setFormOfPaymentToCash: PropTypes.func.isRequired,
  setFormOfPaymentToWelltravelWallet: PropTypes.func.isRequired,
  setFormOfPaymentToIATA: PropTypes.func.isRequired,
  setFormOfPaymentToNone: PropTypes.func.isRequired,
  paymentDisabled: PropTypes.bool.isRequired,
  allowPaymentViaInvoice: PropTypes.bool.isRequired,
  paymentOptions: PropTypes.shape({ gateway: PropTypes.string, surcharge: PropTypes.string }),
  configurePaymentGateway: PropTypes.func.isRequired,
  paymentGateway: PropTypes.string,
  laymanMode: PropTypes.bool.isRequired,
  set3rdPartyPaymentMethod: PropTypes.func.isRequired,
  maximumNumberOfCardsAdded: PropTypes.bool.isRequired,
  triggerResetCardAddressForm: PropTypes.bool.isRequired,
  setTriggerResetCardAddressForm: PropTypes.func.isRequired,
  resetFormOfPayment: PropTypes.func.isRequired,
  resetCreditCardBillingAddressForm: PropTypes.func.isRequired,
  callbackParams: PropTypes.shape({
    layman: PropTypes.bool.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  availableFOPsForAll: PropTypes.shape().isRequired,
  welltravelWalletDetails: PropTypes.shape({
    balance: PropTypes.string,
    currency: PropTypes.string,
    exceedingBalance: PropTypes.string,
    remainingBalance: PropTypes.string,
    requiredBalance: PropTypes.string,
  }),
  setQuery: PropTypes.func.isRequired,
  isFopLoading: PropTypes.bool.isRequired,
  setIsFopLoading: PropTypes.func.isRequired,
  selectedItemId: PropTypes.number.isRequired,
  setSelectedItemId: PropTypes.func.isRequired,
  cardDataAvailableByToken: PropTypes.arrayOf(PropTypes.string).isRequired,
  walletType: PropTypes.string,
  refreshBalance: PropTypes.func,
  isLoading: PropTypes.bool,
  affiliatesBillingUrl: PropTypes.string,
};

export default PaymentSidebar;
