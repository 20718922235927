import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SidePanel from '@wtag/rcl-sidepanel';
import FacilityItemLinks from '../../../../IBE/SearchResult/car/FacilityItemLinks';
import useCarFacilitiesItemWithSidePanel from './useCarFacilitiesItemWithSidePanel';

const CarFacilitiesItemWithSidePanel = ({ facilities }) => {
  const {
    isFacilitiesSidePanelOpen,
    facilitiesSidePanelTitle,
    facilitiesSidePanelBody,
    handleOpenFacilitiesSidePanel,
    handleCloseFacilitiesSidePanel,
  } = useCarFacilitiesItemWithSidePanel();

  return (
    <Fragment>
      <FacilityItemLinks
        className="d-flex align-center wrap car__information-labels"
        otherFacilities={facilities}
        onShowSidePanel={handleOpenFacilitiesSidePanel}
      />
      <SidePanel
        title={facilitiesSidePanelTitle}
        isOpened={isFacilitiesSidePanelOpen}
        onClose={handleCloseFacilitiesSidePanel}
      >
        {facilitiesSidePanelBody}
      </SidePanel>
    </Fragment>
  );
};

CarFacilitiesItemWithSidePanel.defaultProps = {
  facilities: null,
};

CarFacilitiesItemWithSidePanel.propTypes = {
  facilities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      amount: PropTypes.string,
      currency: PropTypes.string,
      modifier: PropTypes.string,
    }),
  ),
};

export default CarFacilitiesItemWithSidePanel;
