import AirportOption from '../AirportOption';

const fetchDataSource = (suggestions, whitelistedAirports) => {
  const dataSource = [];
  suggestions.forEach(suggestion => {
    if (whitelistedAirports !== undefined && whitelistedAirports.length > 0) {
      if (!whitelistedAirports.includes(suggestion.code)) {
        return false;
      }
    }
    const countryFlag = suggestion.countryFlag;
    const text = suggestion.name;
    const secondaryText = `(${suggestion.code}), ${suggestion.placeName}, ${suggestion.countryName}`;

    dataSource.push({
      value: suggestion.code,
      label: AirportOption(countryFlag, text, secondaryText),
    });

    return null;
  });
  return dataSource;
};

export default fetchDataSource;
