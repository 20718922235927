import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import { sourceMaxes, sourceNames } from '../../../helpers/hotelRatingData';

const Rating = ({ rating, source, className }) => {
  const maxRating = sourceMaxes[source];
  const ratingText = `${rating}/${maxRating}`;

  return (
    <div className={className}>
      {rating && <EmphasisTag type="accent" text={ratingText} size="tiny" radius="oval" />}
      {source && (
        <span>
          {I18n.t('components.ibe.search_results.hotel.rated_by', {
            source: sourceNames[source],
          })}
        </span>
      )}
    </div>
  );
};

Rating.defaultProps = {
  className: null,
  rating: null,
  source: null,
};

Rating.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number,
  source: PropTypes.oneOf(['travelport_rooms_and_more', 'travelbox', 'aaa', 'ntm', 'Mobil']),
};

export default Rating;
