import PropTypes from 'prop-types';

export const STATS = {
  price: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  airline: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      stats: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  stopoverCount: PropTypes.arrayOf(PropTypes.number).isRequired,
  baggage: PropTypes.arrayOf(PropTypes.number).isRequired,
  catering: PropTypes.arrayOf(PropTypes.string).isRequired,
  multiTicket: PropTypes.arrayOf(PropTypes.number).isRequired,
  availableSortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  tripSpecific: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      tripDurationMinutes: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
      price: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
      departureTime: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
      arrivalTime: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export const FILTER_PARAMS = {
  price: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  airlines: PropTypes.arrayOf(PropTypes.string).isRequired,
  stopoverCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  caterings: PropTypes.arrayOf(PropTypes.string).isRequired,
  baggage: PropTypes.arrayOf(PropTypes.number).isRequired,
  multiTicket: PropTypes.arrayOf(PropTypes.number).isRequired,
  tripSpecific: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      tripDurationMinutes: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
      prices: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
      departureTimes: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
      arrivalTimes: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  sortBy: PropTypes.string.isRequired,
};
