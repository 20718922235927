import { UPDATE_BOOKING_COMPONENT_REF_BY_TOKEN } from '../actions';

export default function bookingComponentRefByToken(state = {}, action) {
  switch (action.type) {
    case UPDATE_BOOKING_COMPONENT_REF_BY_TOKEN:
      return action.newBookingRefState;
    default:
      return state;
  }
}
