/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import withJourneyElement from '../../../lib/decorators/withJourneyElement';
import Row from '../Row';
import PriceBreakdown from '../PriceBreakdown';
import SeatWithPriceBreakdown from '../SubComponents/SeatWithPriceBreakdown';
import ServicesWithPriceBreakdown from '../SubComponents/ServicesWithPriceBreakdown';

function Flight(props) {
  const { journeyElement, item, travelerCount } = props;
  const allSeats = item.subItems.filter(subItem => subItem.type === 'seat');
  let totalFairOfSeats = 0;
  const allServices = item.subItems.filter(subItem => subItem.type === 'service');
  const segmentsWithSeatId = {};

  if (journeyElement && journeyElement.trips) {
    /**
     * This block is building an object where key is the seat id and value is an another object
     * which holds the originCode and destinationCode of the segment of that seat
     * After executing this block of code, the output will be like
     *
     * {
     *    seatId_1: {destinationCode: "CDG", originCode: "JFK"}
     *    seatId_2: {destinationCode: "CDG", originCode: "JFK"}
     * }
     */
    journeyElement.trips.forEach(trip => {
      trip.segments.forEach(segment => {
        segment.seats.forEach(seat => {
          segmentsWithSeatId[seat.id] = {
            originCode: segment.originCode,
            destinationCode: segment.destinationCode,
          };
        });
      });
    });
  }

  allSeats.forEach(seat => {
    totalFairOfSeats += parseFloat(seat.grossTotal);
  });

  if (!journeyElement) return null;
  return (
    <div className="cart-summary__accordion-sub-item">
      <Row
        className="cart-summary__accordion-item-body-row--bold"
        name={journeyElement.trips.map(trip => (
          <span className="cart-summary__accordion-item-body-row-flight-info" key={trip.id}>
            {trip.originCode} <Icon name="arrowUp" rotate={90} />
            {trip.destinationCode}
          </span>
        ))}
        price={item.grossTotalIncludingSubItems}
        currency={item.currency}
        placeHolder={<Icon name="adult" />}
        conversionInfo={item.conversionInfo}
      />
      {journeyElement.fareCalculations.length > 0 && Object.keys(travelerCount).length > 0 && (
        <PriceBreakdown
          fareCalculations={journeyElement.fareCalculations}
          travelerCount={travelerCount}
        />
      )}
      {item.subItems && item.subItems.length > 0 && (
        <Fragment>
          <SeatWithPriceBreakdown
            seats={allSeats}
            totalFairOfSeats={totalFairOfSeats}
            currency={item.currency}
            segmentsWithSeatId={segmentsWithSeatId}
          />
          <ServicesWithPriceBreakdown services={allServices} />
        </Fragment>
      )}
      <Row
        name={I18n.t('components.ibe.summary.taxes_and_charges')}
        price={item.grossTaxesIncludingSubItems}
        currency={item.currency}
        hasChildRow={false}
      />
    </div>
  );
}

Flight.defaultProps = {
  travelerCount: { adults: [{}], children: [], infants: [] },
};

Flight.propTypes = {
  travelerCount: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  item: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    available: PropTypes.bool,
  }).isRequired,
  journeyElement: PropTypes.shape({
    trips: PropTypes.arrayOf(
      PropTypes.shape({
        segments: PropTypes.arrayOf(
          PropTypes.shape({
            aircraftType: PropTypes.string.isRequired,
            destinationCode: PropTypes.string.isRequired,
            operatingCarrierCode: PropTypes.string.isRequired,
            operatingFlightNumber: PropTypes.string.isRequired,
            originCode: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ).isRequired,
    fareCalculations: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        passengerType: PropTypes.string,
        price: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};
export default withJourneyElement(Flight);
