import getTripType from '../lib/helpers/getTripType';
import { MULTICITY } from '../lib/helpers/tripTypes';

const getDestinationCode = (trips, tripType) => {
  const firstTrip = trips[0];
  const lastTrip = trips[trips.length - 1];

  const { destinationCode, destination } = tripType === MULTICITY ? lastTrip : firstTrip;

  return destinationCode || destination;
};

const getTripDestinationDetails = (trips, airports) => {
  const hasTrips = Array.isArray(trips) && trips.length > 0;

  if (!hasTrips || !airports) {
    return {};
  }

  const tripType = getTripType(trips);
  const destinationCode = getDestinationCode(trips, tripType);

  return airports[destinationCode] || {};
};

export default getTripDestinationDetails;
