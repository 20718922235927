import { fetchHotelSearchResults } from './index';

export const HOTEL_SEARCH_FILTERS_UPDATED = 'HOTEL_SEARCH_FILTERS_UPDATED';
export function hotelSearchFiltersUpdated(filters, searchId) {
  return {
    type: HOTEL_SEARCH_FILTERS_UPDATED,
    filters,
    searchId,
  };
}

export const RESET_HOTEL_SEARCH_FILTERS = 'RESET_HOTEL_SEARCH_FILTERS';
export function resetHotelSearchFilters(searchId, stats) {
  return dispatch => {
    dispatch({ type: RESET_HOTEL_SEARCH_FILTERS, searchId, stats });
    return dispatch(fetchHotelSearchResults({ searchId }));
  };
}

export const RESET_HOTEL_SEARCH_FILTERS_BY_KEY = 'RESET_HOTEL_SEARCH_FILTERS_BY_KEY';
export function resetHotelSearchFiltersByKey(searchId,stats) {
  return dispatch => {
    dispatch({ type: RESET_HOTEL_SEARCH_FILTERS_BY_KEY, searchId,stats });
    return dispatch(fetchHotelSearchResults({ searchId }))
  };
  
}

export function filterHotelSearch(filter, value, searchId) {
  return (dispatch, getState) => {
    const state = getState();
    const data = {
      ...state.hotels.searchFilterParamsBySearchId[searchId],
      [filter]: value,
    };

    dispatch(hotelSearchFiltersUpdated(data, searchId));

    const params = {
      searchId,
      'price[max]': data.price.max,
      'price[min]': data.price.min,
      'price_ranges': JSON.stringify(data.priceRanges),
      'rating[max]': data.rating.max,
      'rating[min]': data.rating.min,
      'stars[]': data.stars,
      'refundable[]': data.refundable,
      'sort_by': data.sortBy,
    };
    return dispatch(fetchHotelSearchResults(params));
  };
}

export default filterHotelSearch;
