import React from 'react';
import PropTypes from 'prop-types';
import { I18nText } from '@wtag/react-comp-lib';
import DateTime from 'sharedWebpack/DateTime';
import { DATE_FORMAT_SHORT_WITH_TIME } from 'sharedWebpack/helpers/dateTime';

const DateTimeWithLabel = ({ label, dateTime }) => (
  <div className="loading-information-card__content-info-duration-time">
    <div className="loading-information-card__content-info-duration-time-text">{label}:</div>
    <div className="loading-information-card__content-info-duration-time-value">
      <DateTime dateTime={dateTime} format={DATE_FORMAT_SHORT_WITH_TIME} />
    </div>
  </div>
);

DateTimeWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dateTime: PropTypes.string.isRequired,
};

const CarRentalDuration = ({ pickUpDateTime, dropOffDateTime }) => (
  <div className="loading-information-card__content-info-duration">
    <DateTimeWithLabel
      label={<I18nText id="ibe.search_loading.car.pick_up" />}
      dateTime={pickUpDateTime}
    />
    <DateTimeWithLabel
      label={<I18nText id="ibe.search_loading.car.drop_off" />}
      dateTime={dropOffDateTime}
    />
  </div>
);

CarRentalDuration.propTypes = {
  pickUpDateTime: PropTypes.string.isRequired,
  dropOffDateTime: PropTypes.string.isRequired,
};

export default CarRentalDuration;
