import React from 'react';
import PropTypes from 'prop-types';
import SelectBox from '@wtag/rcl-select-box';

const CarPreferencesForm = props => {
  const {
    preferredCarTypes,
    setPreferredCarTypes,
    preferredCarCategories,
    setPreferredCarCategories,
    preferredCarDrives,
    setPreferredCarDrives,
    error,
    carTypes,
    carDrives,
    carCategories,
  } = props;

  const carTypeOptions = carTypes.map(carTypeKey => ({
    value: carTypeKey,
    label: I18n.t(carTypeKey, {
      scope: 'simple_form.options.person.preferred_car_types',
    }),
  }));

  const carTypesValue =
    (preferredCarTypes &&
      preferredCarTypes.map(carType => ({
        value: carType,
        label: I18n.t(carType, {
          scope: 'simple_form.options.person.preferred_car_types',
        }),
      }))) ||
    null;

  const carCategoryOptions = carCategories.map(carCategoryKey => ({
    value: carCategoryKey,
    label: I18n.t(carCategoryKey, {
      scope: 'simple_form.options.person.preferred_car_categories',
    }),
  }));

  const carCategoryValue =
    (preferredCarCategories &&
      preferredCarCategories.map(carCategory => ({
        value: carCategory,
        label: I18n.t(carCategory, {
          scope: 'simple_form.options.person.preferred_car_categories',
        }),
      }))) ||
    null;

  const carDriveOptions = carDrives.map(carDriveKey => ({
    value: carDriveKey,
    label: I18n.t(carDriveKey, {
      scope: 'simple_form.options.person.preferred_car_drives',
    }),
  }));

  const carDriveValue =
    (preferredCarDrives &&
      preferredCarDrives.map(carDrive => ({
        value: carDrive,
        label: I18n.t(carDrive, {
          scope: 'simple_form.options.person.preferred_car_drives',
        }),
      }))) ||
    null;

  return (
    <div className="grid grid-gap-12">
      <div className="col-12">
        <SelectBox
          size="tiny"
          label={I18n.t('simple_form.labels.person.preferred_car_types')}
          width="full"
          options={carTypeOptions}
          value={carTypesValue}
          onChange={selectedOption => {
            if (selectedOption && selectedOption.length) {
              setPreferredCarTypes(selectedOption && selectedOption.map(option => option.value));
            } else {
              setPreferredCarTypes([]);
            }
          }}
          isMulti={true}
          errorMsg={error.preferredCarTypes}
        />
      </div>
      <div className="col-12">
        <SelectBox
          size="tiny"
          label={I18n.t('simple_form.labels.person.preferred_car_categories')}
          width="full"
          options={carCategoryOptions}
          value={carCategoryValue}
          onChange={selectedOption => {
            if (selectedOption && selectedOption.length) {
              setPreferredCarCategories(
                selectedOption && selectedOption.map(option => option.value),
              );
            } else {
              setPreferredCarCategories([]);
            }
          }}
          isMulti={true}
          errorMsg={error.preferredCarCategories}
        />
      </div>
      <div className="col-12">
        <SelectBox
          size="tiny"
          label={I18n.t('simple_form.labels.person.preferred_car_drives')}
          width="full"
          options={carDriveOptions}
          value={carDriveValue}
          onChange={selectedOption => {
            if (selectedOption && selectedOption.length) {
              setPreferredCarDrives(selectedOption && selectedOption.map(option => option.value));
            } else {
              setPreferredCarDrives([]);
            }
          }}
          isMulti={true}
          errorMsg={error.preferredCarDrives}
        />
      </div>
    </div>
  );
};

CarPreferencesForm.defaultProps = {
  preferredCarTypes: [],
  preferredCarCategories: [],
  preferredCarDrives: [],
  error: {},
};

CarPreferencesForm.propTypes = {
  preferredCarTypes: PropTypes.arrayOf(PropTypes.string),
  setPreferredCarTypes: PropTypes.func.isRequired,
  preferredCarCategories: PropTypes.arrayOf(PropTypes.string),
  setPreferredCarCategories: PropTypes.func.isRequired,
  preferredCarDrives: PropTypes.arrayOf(PropTypes.string),
  setPreferredCarDrives: PropTypes.func.isRequired,
  carTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  carDrives: PropTypes.arrayOf(PropTypes.string).isRequired,
  carCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.shape({
    preferredCarTypes: PropTypes.string,
    preferredCarDrives: PropTypes.string,
    preferredCarCategories: PropTypes.string,
  }),
};

export default CarPreferencesForm;
