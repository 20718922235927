import { ADD_SEAT_TO_CART } from '../../actions/flight';
import { CART_FETCHED } from '../../actions/common';

export default function flightSeatMap(state = { isUpdating: false }, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case ADD_SEAT_TO_CART:
      newState.isUpdating = true;
      newState.seatId = action.seatId;
      return newState;

    case CART_FETCHED:
      newState.isUpdating = false;
      return newState;

    default:
      return state;
  }
}
