import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import Popover from '@wtag/rcl-popover';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import Amount from 'sharedWebpack/Amount';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import FlightPriceBreakdown from './FlightPriceBreakdownStandard';
import calculateAndAddTaxWithPrice from '../../IBE/helpers/calculateAndAddTaxWithPrice';
import { TYPE_ADULT } from '../../helpers/passengerTypes';

const FlightPricePerAdult = ({
  className,
  availabilityIcon,
  currency,
  numberOfAdult,
  numberOfChildren,
  numberOfInfant,
  grossAmount,
  grossTaxes,
  marginBreakdown,
  laymanMode,
  fareCalculations,
  fromResult,
  adultPrice,
  journeyElementType,
  journeyElementId,
  fareBrandTitle,
  onPriceLabelClick,
  removePrefixText,
}) => {
  const [flightJourneyElement, setflightJourneyElement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const grossAmountZero = grossAmount && parseFloat(grossAmount) === 0;

  const fareCalculationsPresent =
    (fareCalculations && fareCalculations.length > 0) ||
    (flightJourneyElement &&
      flightJourneyElement.fareCalculations &&
      flightJourneyElement.fareCalculations.length > 0);

  const adultPriceInOrder =
    flightJourneyElement &&
    flightJourneyElement.fareCalculations &&
    flightJourneyElement.fareCalculations.find(traveler => traveler.passengerType === TYPE_ADULT) &&
    flightJourneyElement.fareCalculations.find(traveler => traveler.passengerType === TYPE_ADULT)
      .price;

  const price =
    grossAmountZero || !fareCalculationsPresent
      ? grossAmount
      : calculateAndAddTaxWithPrice(
          adultPrice || adultPriceInOrder,
          grossTaxes,
          numberOfAdult,
          numberOfChildren,
        );

  const openPriceBreakdownPopover = () => {
    setIsPopoverVisible(true);
    onPriceLabelClick();
  };

  const fetchJourneyElement = async () => {
    setIsLoading(true);

    const { data } = await httpClient.get(
      routes.api.fetchJourneyElement({
        type: journeyElementType,
        id: journeyElementId,
      }),
    );

    setflightJourneyElement(data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (journeyElementId) {
      fetchJourneyElement();
    }
  }, [journeyElementId, journeyElementType]);

  const travelerCount = {
    adults: numberOfAdult,
    children: numberOfChildren,
    infants: numberOfInfant,
  };

  const currentFareCalculations =
    (fareCalculations && fareCalculations.length > 0 && fareCalculations) ||
    (flightJourneyElement && flightJourneyElement.fareCalculations);

  return (
    <React.Fragment>
      <div className={classNames('item-pricing', className)}>
        <div className="item__pricing-value">
          {isLoading ? (
            <div className="item__pricing-unit-price">
              <ContentLoaderPlaceholder numberOfLines={1} showBackground={false} />
            </div>
          ) : (
            <div className="item__pricing-unit-price-container">
              <div className="grid grid-gap-12 item__pricing-unit-price">
                <div className="col-12 col-grid direction-row align-center justify-end item__pricing-unit-price-content">
                  {!removePrefixText && (
                    <span className="item__pricing-from-text">
                      {I18n.t('components.ibe.results.from')}
                    </span>
                  )}
                  {currentFareCalculations ? (
                    <Popover
                      className="item__pricing-breakdown"
                      isVisible={isPopoverVisible}
                      onOutsideClick={() => {
                        setIsPopoverVisible(false);
                      }}
                      direction="bottom-right"
                      content={
                        <FlightPriceBreakdown
                          fareCalculations={currentFareCalculations}
                          travelerCount={travelerCount}
                          grossTaxes={grossTaxes}
                          currency={currency}
                          marginBreakdown={marginBreakdown}
                          laymanMode={laymanMode}
                          fromResult={fromResult}
                          grossAmount={grossAmount}
                          fareBrandTitle={fareBrandTitle}
                        />
                      }
                    >
                      <div className="d-flex align-center">
                        {availabilityIcon}
                        <div
                          role="button"
                          tabIndex="0"
                          onKeyDown={openPriceBreakdownPopover}
                          onClick={openPriceBreakdownPopover}
                        >
                          <Amount
                            className="item__pricing-unit-price-value"
                            currency={currency}
                            value={price}
                          />
                        </div>
                      </div>
                    </Popover>
                  ) : (
                    <Amount
                      className="item__pricing-unit-price-value"
                      currency={currency}
                      value={price}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flight__prices--farebrand-label w-100">
        {fareBrandTitle}&nbsp;
        <span className="flight__prices--adult-label">
          ({I18n.t('admin.components.products.options.automation_style.per_adult.label')})
        </span>
      </div>
    </React.Fragment>
  );
};

FlightPricePerAdult.defaultProps = {
  className: null,
  fareBrandTitle: null,
  numberOfChildren: null,
  numberOfAdult: null,
  numberOfInfant: null,
  grossTaxes: null,
  marginBreakdown: null,
  fromResult: false,
  journeyElementType: null,
  journeyElementId: null,
  fareCalculations: [],
  adultPrice: null,
  availabilityIcon: null,
  removePrefixText: false,
  onPriceLabelClick: () => {},
};

FlightPricePerAdult.propTypes = {
  className: PropTypes.string,
  availabilityIcon: PropTypes.node,
  currency: PropTypes.string.isRequired,
  grossAmount: PropTypes.string.isRequired,
  grossTaxes: PropTypes.string,
  fareBrandTitle: PropTypes.string,
  numberOfAdult: PropTypes.number,
  numberOfChildren: PropTypes.number,
  numberOfInfant: PropTypes.number,
  marginBreakdown: PropTypes.node,
  laymanMode: PropTypes.bool.isRequired,
  fareCalculations: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      passengerType: PropTypes.string,
      price: PropTypes.string,
      legPrice: PropTypes.string,
    }),
  ),
  fromResult: PropTypes.bool,
  adultPrice: PropTypes.string,
  journeyElementType: PropTypes.string,
  journeyElementId: PropTypes.number,
  removePrefixText: PropTypes.bool,
  onPriceLabelClick: PropTypes.func,
};
export default hot(module)(FlightPricePerAdult);
