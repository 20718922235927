import toCapitializeForm from './toCapitializeForm';

const getModelName = (make, name) => {
  let modelName;

  if (make && name) {
    modelName = `${make} ${name}`;
  } else {
    modelName = make || name;
  }

  return I18n.t('components.ibe.search_results.car.content.model', {
    name: toCapitializeForm(modelName),
  });
};

export default getModelName;
