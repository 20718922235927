import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Avatar from '@wtag/rcl-avatar';
import Input from '@wtag/rcl-input';
import Icon from '@wtag/rcl-icon';
import { CheckBox } from '@wtag/react-comp-lib';
import './style.scss';

const ChangeApproversSidePanelBody = ({
  mandatoryApprovers,
  optionalApprovers,
  potentialApprovers,
  minimumApproverCount,
  selectedOptionalApprovers,
  setSelectedOptionalApprovers,
  startApprovalProcess,
  hideSidePanel,
}) => {
  const NUMBER_OF_APPROVER_TO_SHOW = 5;

  const [approverSearch, setApproverSearch] = useState('');

  const nameCompare = (a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  const filteredApprovalList = () => {
    let approvers = optionalApprovers
      .sort(nameCompare)
      .concat(potentialApprovers.sort(nameCompare));
    if (approverSearch !== null) {
      const searchText = approverSearch.toLowerCase();
      approvers = approvers.filter(
        approver =>
          approver.name.toLowerCase().includes(searchText) ||
          (approver.email !== null && approver.email.toLowerCase().includes(searchText)),
      );
    }

    return approvers.slice(0, NUMBER_OF_APPROVER_TO_SHOW);
  };

  const selectedApproverIds = selectedOptionalApprovers.map(approver => approver.id);

  const isSelected = approver => selectedApproverIds.includes(approver.id);

  const selectAllApprovers = selectAll => {
    let allApprovers = [];
    if (selectAll) {
      allApprovers = optionalApprovers.concat(potentialApprovers);
    }
    setSelectedOptionalApprovers(allApprovers);
  };

  const isAllApproverSelected = () => {
    const allApprovers = optionalApprovers.concat(potentialApprovers);
    return allApprovers.length === selectedOptionalApprovers.length;
  };

  const onCheckBoxClick = approver => {
    let newSelectedApprovers;

    if (isSelected(approver)) {
      newSelectedApprovers = selectedOptionalApprovers.filter(
        selectedApprover => approver.id !== selectedApprover.id,
      );
    } else {
      newSelectedApprovers = selectedOptionalApprovers.concat([approver]);
    }

    setSelectedOptionalApprovers(newSelectedApprovers);
  };

  const optionalApproverCount = () => {
    if (minimumApproverCount > mandatoryApprovers.length) {
      return minimumApproverCount - mandatoryApprovers.length
    }
    return 0
  }

  const filteredApprovals = filteredApprovalList();
  return (
    <div>
      <span className="change-approver-sidepanel__subtitle">
        {I18n.t('approval_process.side_panel.subtitle')}
      </span>

      {mandatoryApprovers.length > 0 && (
        <div>
          <div className="change-approver-sidepanel__instruction-text">
            {I18n.t('approval_process.side_panel.mandatory', { count: mandatoryApprovers.length })}
          </div>
          {mandatoryApprovers.map(person => (
            <div className="change-approver-sidepanel__approvers" key={person.id}>
              <Avatar
                src={person.avatarUrl}
                firstName={person.firstName}
                middleName={person.middleName || ''}
                lastName={person.lastName}
                size="tiny"
                showName={true}
                description={person.email}
              />
            </div>
          ))}
        </div>
      )}

      {(optionalApprovers.length > 0 || potentialApprovers.length > 0) && (
        <div>
          <div className="change-approver-sidepanel__instruction-text">
            {I18n.t('approval_process.side_panel.optional', {
              count: optionalApproverCount(),
            })}
          </div>
          <div className="change-approver-sidepanel__all-approver">
            <CheckBox
              size="small"
              name = 'all-approver'
              disabled={false}
              checked={isAllApproverSelected()}
              onChange={() => selectAllApprovers(!isAllApproverSelected())}
              label={I18n.t('approval_process.side_panel.all_approvers')}
            />
          </div>
          <div className="change-approver-sidepanel__search">
            <Input
              size="tiny"
              placeholder={I18n.t('shared.actions.search')}
              value={approverSearch}
              onChange={setApproverSearch}
              preIcon={<Icon name="search" />}
              isClearable={true}
            />
          </div>
          <div>
            {filteredApprovals.map(optionalApprover => (
              <div className="change-approver-sidepanel__approvers" key={optionalApprover.id}>
                <CheckBox
                  size="small"
                  name = {`${optionalApprover.id}-${optionalApprover.name}`}
                  disabled={false}
                  checked={isSelected(optionalApprover)}
                  onChange={() => onCheckBoxClick(optionalApprover)}
                />
                <Avatar
                  src={optionalApprover.avatarUrl}
                  firstName={optionalApprover.firstName}
                  middleName={optionalApprover.middleName || ''}
                  lastName={optionalApprover.lastName}
                  size="tiny"
                  showName={true}
                  description={optionalApprover.email}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="change-approver-sidepanel__buttons">
        <Button
          label={I18n.t('admin.shared.action.confirm')}
          size="small"
          version="v2"
          onClick={() => startApprovalProcess()}
          primary={true}
          disabled={(mandatoryApprovers.length + selectedOptionalApprovers.length) < minimumApproverCount}
        />
        <Button
          label={I18n.t('admin.shared.action.cancel')}
          size="small"
          version="v2"
          onClick={() => hideSidePanel()}
        />
      </div>
    </div>
  );
};

ChangeApproversSidePanelBody.propTypes = {
  minimumApproverCount: PropTypes.number.isRequired,
  mandatoryApprovers: PropTypes.arrayOf(
    PropTypes.shape({
      avatarUrl: PropTypes.string,
      middleName: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  optionalApprovers: PropTypes.arrayOf(
    PropTypes.shape({
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      lastName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  potentialApprovers: PropTypes.arrayOf(
    PropTypes.shape({
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      lastName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedOptionalApprovers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideSidePanel: PropTypes.func.isRequired,
  setSelectedOptionalApprovers: PropTypes.func.isRequired,
  startApprovalProcess: PropTypes.func.isRequired,
};

export default ChangeApproversSidePanelBody;
