import deepClone from '../../lib/deepClone';

import { HOTEL_SEARCH_FETCHED } from '../../actions/hotel';

export default function searchResultsBySearchId(state = {}, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case HOTEL_SEARCH_FETCHED:
      newState[action.searchId] = action.results.map(result => {
        const myResult = deepClone(result);
        if (myResult.location) {
          myResult.location = {
            lat: parseFloat(myResult.location.lat, 10),
            lon: parseFloat(myResult.location.lon, 10),
          };
        }
        return myResult;
      });
      return newState;

    default:
      return state;
  }
}
