export const humanReadableDuration = durationInMinutes => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  if (hours > 24) {
    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;
    const daysRemainingString =
      days < 2 ? I18n.t('duration.long.days.one') : I18n.t('duration.long.days.other');

    if (remainingHours) {
      return `${days} ${daysRemainingString} ${remainingHours}h`;
    }

    return `${days} ${daysRemainingString}`;
  }

  if (hours && minutes) {
    return `${hours}h ${minutes}m`;
  }
  if (hours && !minutes) {
    return `${hours}h`;
  }
  return `${minutes}m`;
};

export default humanReadableDuration;
