import React from 'react';
import Icon from '@wtag/rcl-icon';
import './styles.scss';

const SeatMapUnavailableCard = () => (
  <div className="grid grid-gap-12 align-center justify-center row-gap-16 seatmap-unavailable-card">
    <h3 className="col-12 col-grid justify-center align-center seatmap-unavailable-card__header">
      {I18n.t('components.flight_seat_map.no_seat_header')}
    </h3>
    <Icon
      className="col-12 col-grid justify-center align-center"
      name="seat"
      showBGColor={true}
      color="tertiary"
      size="huge"
    />
    <p className="col-12 col-grid justify-center align-center seatmap-unavailable-card__subheader">
      {I18n.t('components.flight_seat_map.no_seat_info')}
    </p>
  </div>
);

export default SeatMapUnavailableCard;
