import React, { Fragment } from 'react';
import Card from '@wtag/rcl-card';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { Link, EmphasisTag } from '@wtag/react-comp-lib';

const PendingApprovalRequests = props => {
  const { approvalRequests, loading, urls } = props;
  const maxRequestsToShow = 4;

  const notShownRequests = approvalRequests.length - maxRequestsToShow;

  let pendingApprovalRequestsContent = (
    <Card
      version="v2"
      className="pending-card pending-card__approvals"
      title={I18n.t('public.components.dashboard.approval_requests.pending_requests')}
      emptyCardImageSrc={<Icon name="approvalRequired" />}
      emptyCardText={I18n.t('public.components.dashboard.approval_requests.no_pending')}
    />
  );

  if (loading) {
    pendingApprovalRequestsContent = (
      <Card version="v2" className="pending-card pending-card__approvals">
        <div className="pending-card__approvals-header">
          {I18n.t('public.components.dashboard.approval_requests.pending_requests')}
        </div>
        <div className="pending-card__approvals-content">
          <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
        </div>
      </Card>
    );
  } else if (approvalRequests.length > 0) {
    pendingApprovalRequestsContent = (
      <Card version="v2" className="pending-card pending-card__approvals">
        <div className="pending-card__approvals-header">
          {I18n.t('public.components.dashboard.approval_requests.pending_requests')}
        </div>
        {approvalRequests.slice(0, maxRequestsToShow).map(request => (
          <div
            key={request.id}
            className="col-grid col-bleed direction-row align-center pending-card__approvals-info"
          >
            <div className="pending-card__approvals-info-details-number">
              <EmphasisTag number={request.id} size="small" radius="oval" />
            </div>
            {request.title ? (
              <div className="col-grid col-bleed direction-row pending-card__approvals-info-details-text">
                {request.title}
              </div>
            ) : (
              <div className="col-grid col-bleed pending-card__approvals-info-details-unavailable">
                {I18n.t('admin.shared.no_information')}
              </div>
            )}
            <div className="pending-card__approvals-info-actions">
              <Link
                href={request.urls.view}
                size="tiny"
                type="icon-button"
                labelHint={I18n.t('public.components.dashboard.approval_requests.review_request')}
              >
                <Icon name="review" size="small" color="tertiary"/>
              </Link>
            </div>
          </div>
        ))}
        {notShownRequests > 0 && (
          <Link href={urls.index} size="tiny" type="anchor">
            <div className="pending-card__approvals-footer">
              {I18n.t('public.shared.action.view_more', {
                count: notShownRequests,
              })}
            </div>
          </Link>
        )}
      </Card>
    );
  }

  return <Fragment>{pendingApprovalRequestsContent}</Fragment>;
};

PendingApprovalRequests.propTypes = {
  approvalRequests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      amount: PropTypes.string,
      startsAt: PropTypes.string,
      urls: PropTypes.shape({
        view: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  urls: PropTypes.shape({
    index: PropTypes.string,
  }).isRequired,
};

export default PendingApprovalRequests;
