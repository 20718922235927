import React, { Fragment, useState } from 'react';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import ClassNames from 'classnames';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import PropTypes from 'prop-types';
import Input from '@wtag/rcl-input';
import Button from '@wtag/rcl-button';
import SocialMediaShow from 'sharedWebpack/SocialMediaSection/SocialMediaShow';
import subscriptionImageSrc from '../../../../apps/public/app/assets/images/public/subscription-image.png';

import './styles.scss';

const Footer = ({ tenantName, urls, tenantLogo }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [errors, setErrors] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const isMobileView = window.innerWidth < 480;
  const subscriptionFormInputSize = isMobileView ? 'tiny' : 'small';
  const subscriptionButtonSize = isMobileView ? 'small' : 'normal';

  const createSubscription = async () => {
    const formData = new FormData();
    formData.append('mailing_list[first_name]', firstName);
    formData.append('mailing_list[last_name]', lastName);
    formData.append('mailing_list[email]', emailAddress);

    const { data } = await httpClient.post(routes.public.subscription.create(), formData);

    if (data.error === null) {
      setConfirmationModalOpen(true);
      setErrors({});
      setFirstName('');
      setLastName('');
      setEmailAddress('');
    }
  };

  const onCreateSubscription = () =>
    createSubscription().catch(({ response }) => {
      if (response.status === 422) {
        setErrors(response.data.error);
      }
    });

  const scrollTop = () => {
    // this is being done to reset the page to top after a link is clicked,
    // as it does not refresh the page.
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <div className="subscription grid container">
        <div className="subscription__title col-12 col-grid col-bleed align-center">
          <div className=" subscription__title-text">
            {I18n.t('public.shared.mailing_list.subscription_form.labels.title')}
          </div>
        </div>

        <div className="col-12 col-md-6 offset-0 offset-md-3 subscription__title-details">
          {I18n.t('public.shared.mailing_list.subscription_form.labels.details')}
        </div>

        <div className="col-12 col-md-6">
          <img src={subscriptionImageSrc} className="subscription__form-image" alt="subscription" />
        </div>

        <div className="subscription__form col-12 col-md-6 col-grid direction-row wrap justify-space-between">
          <div className="col-grid direction-row col-bleed wrap">
            <div className="col-12 col-lg-6 subscription__form-fields col-bleed">
              <Input
                label={I18n.t('public.shared.mailing_list.subscription_form.labels.first_name')}
                value={firstName}
                onChange={value => setFirstName(value)}
                touched={!!errors}
                error={errors.firstName}
                placeholder={I18n.t(
                  'public.shared.mailing_list.subscription_form.placeholders.first_name',
                )}
                required={true}
                size={subscriptionFormInputSize}
              />
            </div>
            <div className="col-12 col-lg-6 subscription__form-fields col-bleed">
              <Input
                label={I18n.t('public.shared.mailing_list.subscription_form.labels.last_name')}
                value={lastName}
                onChange={value => setLastName(value)}
                touched={!!errors}
                error={errors.lastName}
                placeholder={I18n.t(
                  'public.shared.mailing_list.subscription_form.placeholders.last_name',
                )}
                required={true}
                size={subscriptionFormInputSize}
              />
            </div>

            <div className="col-12 col-bleed subscription__form-fields">
              <Input
                label={I18n.t('public.shared.mailing_list.subscription_form.labels.email')}
                value={emailAddress}
                onChange={value => setEmailAddress(value)}
                error={errors.email}
                touched={!!errors}
                placeholder={I18n.t(
                  'public.shared.mailing_list.subscription_form.placeholders.email',
                )}
                required={true}
                size={subscriptionFormInputSize}
              />
            </div>
          </div>

          <ConfirmationModal
            isModalOpen={confirmationModalOpen}
            confirmationHeader={I18n.t('public.shared.mailing_list.success_msg')}
            confirmationText={I18n.t('public.shared.mailing_list.subscription_form.labels.close')}
            onConfirm={() => setConfirmationModalOpen(false)}
            isConfirmationOnly={true}
            withAction={true}
            type="success"
          />

          <div className="subscription__form-button col-4 col-md-3 col-bleed col-grid justify-end">
            <Button
              version="v2"
              size={subscriptionButtonSize}
              type="primary"
              label={I18n.t('public.shared.mailing_list.subscribe')}
              onClick={onCreateSubscription}
            />
          </div>
        </div>
      </div>
      <div className="footer grid">
        <div className="col-bleed col-lg-3 col-md-3 col-sm-12 col-xs-12 footer-top__title">
          <span className="col-12 col-bleed-y footer__logo">
            <img src={tenantLogo} alt="tenantLogo" className="footer__logo-image" />
          </span>
        </div>
        <div className="grid col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div className="col-bleed col-lg-3 col-md-3 col-sm-6 col-xs-6 footer__journey-element">
            <div className="footer-top__title">
              <a href={urls.bookNow}>{I18n.t('public.components.top_nav.label.book_now')}</a>
            </div>
            <ul>
              <li className="footer-top__list">
                <a href={`${urls.bookNow}#/flights`} onClick={scrollTop}>
                  {I18n.t('public.footer.journey_elements.flight')}
                </a>
              </li>
              <li className="footer-top__list">
                <a href={`${urls.bookNow}#/hotels`} onClick={scrollTop}>
                  {I18n.t('public.footer.journey_elements.hotel')}
                </a>
              </li>
              <li className="footer-top__list">
                <a href={`${urls.bookNow}#/cars`} onClick={scrollTop}>
                  {I18n.t('public.footer.journey_elements.car')}
                </a>
              </li>
              <li className="footer-top__list--disabled">
                {/* TODO: Add link when ready */}
                <a>{I18n.t('public.footer.journey_elements.cruise')}</a>
              </li>
              <li className="footer-top__list--disabled">
                {/* TODO: Add link when ready */}
                <a>{I18n.t('public.footer.journey_elements.event')}</a>
              </li>
              <li className="footer-top__list--disabled">
                {/* TODO: Add link when ready */}
                <a>{I18n.t('public.footer.journey_elements.transfer')}</a>
              </li>
            </ul>
          </div>
          <div className="col-bleed col-lg-3 col-md-3 col-sm-6 col-xs-6 footer-top__deals">
            <div className="footer-top__title">{I18n.t('public.footer.explore')}</div>
            <ul className="footer__journey-element">
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.topDeals.present,
                })}
              >
                <a href={urls.topDeals.present && urls.topDeals.value}>
                  {I18n.t('public.homepage.top_deals')}
                </a>
              </li>
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.stories.present,
                })}
              >
                <a href={urls.stories.present && urls.stories.value}>
                  {I18n.t('public.footer.stories')}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-bleed col-lg-3 col-md-3 col-sm-6 col-xs-6 footer-top__subtitle">
            <div className="footer-top__title">{I18n.t('public.footer.resources')}</div>
            <ul className="footer__journey-element">
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.about.present,
                })}
              >
                <a href={urls.about.present && urls.about.value}>
                  {I18n.t('public.static_pages.about', { tenant: tenantName })}
                </a>
              </li>
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.media.present,
                })}
              >
                <a href={urls.media.present && urls.media.value}>{I18n.t('public.footer.media')}</a>
              </li>
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.picks.present,
                })}
              >
                <a href={urls.picks.present && urls.picks.value}>
                  {I18n.t('public.static_pages.picks')}
                </a>
              </li>
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.helpTopics.present,
                })}
              >
                <a href={urls.helpTopics.present && urls.helpTopics.value}>
                  {I18n.t('public.static_pages.help_topics')}
                </a>
              </li>
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.career.present,
                })}
              >
                <a href={urls.career.present && urls.career.value}>
                  {I18n.t('public.footer.career')}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-bleed col-lg-3 col-md-3 col-sm-6 col-xs-6 footer-top__subtitle">
            <div className="footer-top__title">{I18n.t('public.static_pages.policies')}</div>
            <ul className="footer__journey-element">
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.safety.present,
                })}
              >
                <a href={urls.safety.present && urls.safety.value}>
                  {I18n.t('public.static_pages.safety')}
                </a>
              </li>
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.privacyPolicy.present,
                })}
              >
                <a href={urls.privacyPolicy.present && urls.privacyPolicy.value}>
                  {I18n.t('public.static_pages.privacy_policy')}
                </a>
              </li>
              <li
                className={ClassNames('footer-top__list', {
                  'footer-top__list--disabled': !urls.terms.present,
                })}
              >
                <a href={urls.terms.present && urls.terms.value}>
                  {I18n.t('public.static_pages.terms_and_conditions')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        {urls.social && (
          <div className="col-bleed-y col-lg-3 col-md-3 col-sm-12 col-xs-12 footer-top__social">
            <div className="footer-top__title">{I18n.t('public.footer.social')}</div>
            <div className="footer-top__list">
              <SocialMediaShow socialMediaLinks={urls.social} />
            </div>
          </div>
        )}
      </div>
      <div className="footer__bottom col-grid col-bleed justify-center">
        <span className="footer__bottom-text">
          &copy; {tenantName} &amp; {I18n.t('public.welltravel.title')}
        </span>
      </div>
    </Fragment>
  );
};

Footer.propTypes = {
  urls: PropTypes.shape({
    bookNow: PropTypes.string.isRequired,
    topDeals: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    privacyPolicy: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    terms: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    safety: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    media: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    about: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    picks: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    helpTopics: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    career: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    policies: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    stories: PropTypes.shape({
      present: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    social: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
  tenantName: PropTypes.string.isRequired,
  tenantLogo: PropTypes.string.isRequired,
};

export default Footer;
