import React from 'react';
import PropTypes from 'prop-types';
import { I18nText, Spinner } from '@wtag/react-comp-lib';
import Tooltip from '@wtag/rcl-tooltip';

const BookingProgressSpinner = ({ isProcessing, noMessage, component }) => {
  const spinner = (
    <div className="progress-spinner__circular">
      <Spinner size="tiny" bgColor="neutral" />
    </div>
  );

  if (isProcessing) {
    if (!noMessage) {
      return (
        <Tooltip
          className="progress-spinner"
          content={<I18nText id="ibe.bookings.processing" returnStringOnly={true} />}
          gap={2}
          type="inverse"
          showArrow={false}
          position="bottom-right"
        >
          {spinner}
        </Tooltip>
      );
    }

    return spinner;
  }

  return component;
};

BookingProgressSpinner.defaultProps = {
  noMessage: false,
};

BookingProgressSpinner.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  component: PropTypes.node.isRequired,
  noMessage: PropTypes.bool,
};

export default BookingProgressSpinner;
