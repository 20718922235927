function getMiddle(values) {
  const min = Math.min(...values);
  const max = Math.max(...values);

  const result = (min + max) / 2;

  return result;
}

function findCenter(markers) {
  const allLats = markers.map(m => m.lat);
  const allLngs = markers.map(m => m.lng);
  return {
    lat: getMiddle(allLats),
    lng: getMiddle(allLngs),
  };
}

const calculateCenterCoordinates = cordinates => {
  const locations = [];
  cordinates.forEach(cordinate => {
    locations.push({
      lng: cordinate.start.longitude,
      lat: cordinate.start.latitude,
    });
    locations.push({
      lng: cordinate.stop.longitude,
      lat: cordinate.stop.latitude,
    });
  });
  const center = findCenter(locations);
  return {
    latitude: center.lat,
    longitude: center.lng,
  };
};

export default calculateCenterCoordinates;
