import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import debounce from 'throttle-debounce/debounce';
import routes from 'agentRoutes';
import httpClient from 'agentHTTPClient';
import SelectBox from '@wtag/rcl-select-box';
import Input from '@wtag/rcl-input';

import findLabelValue from '../../../../helpers/findLabelValue';
import DefaultCarriers from '../../../../lib/helpers/defaultCarrierList';

class FrequentFlyerNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carriers:
        this.debouncedSearchCarrier(props.frequentFlyerNumber.carrier.value) || DefaultCarriers,
    };
  }

  searchCarrier = value => {
    const url = routes.api.airlines({ q: value });
    httpClient.get(url).then(({ data }) => this.setState({ carriers: data }));
  };

  debouncedSearchCarrier = debounce(300, query => this.searchCarrier(query));

  carrierSelected = carrier => {
    const { frequentFlyerNumber, selectedCustomer, onCustomerSelected, travelerIndex } = this.props;

    frequentFlyerNumber.carrier.onChange(carrier);
    const updatedSelectedCustomer = {
      ...selectedCustomer,
      frequentFlyerNumber: {
        ...selectedCustomer.frequentFlyerNumber,
        carrier,
      },
    };

    onCustomerSelected(travelerIndex, updatedSelectedCustomer);
  };

  numberSelected = number => {
    const { frequentFlyerNumber, selectedCustomer, onCustomerSelected, travelerIndex } = this.props;

    frequentFlyerNumber.number.onChange(number);
    const updatedSelectedCustomer = {
      ...selectedCustomer,
      frequentFlyerNumber: {
        ...selectedCustomer.frequentFlyerNumber,
        number,
      },
    };

    onCustomerSelected(travelerIndex, updatedSelectedCustomer);
  };

  carriers = () =>
    this.state.carriers.map(airline => ({
      label: airline.name,
      value: airline.code,
    }));

  render() {
    const {
      frequentFlyerNumber: { carrier, number },
      disabled,
      touched,
    } = this.props;

    return (
      <div className="col-12 col-bleed frequent-flyer-number">
        <div className="frequent-flyer-number__container">
          <div className="col-12 col-sm-6 col-md-4 col-bleed frequent-flyer-number__container-field">
            <SelectBox
              {...carrier}
              options={this.carriers()}
              placeholderText={I18n.t(
                'components.ibe.traveler_details.form.placeholders.carrier_code',
              )}
              label={I18n.t('components.ibe.traveler_details.form.fields.carrier_code')}
              isDisabled={disabled}
              touched={touched}
              onInputChange={value => this.debouncedSearchCarrier(value)}
              onChange={selectedOption =>
                selectedOption && this.carrierSelected(selectedOption.value)
              }
              errorMsg={carrier.error}
              size="tiny"
              width="full"
              value={findLabelValue(this.carriers(), carrier.value)}
              required={true}
              isClearable={false}
              onBlur={() => {}}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-bleed frequent-flyer-number__container-field">
            <Input
              {...number}
              label={I18n.t('components.ibe.traveler_details.form.fields.number')}
              placeholder="E.g. XXXXXXXX"
              disabled={disabled}
              touched={touched}
              size="tiny"
              required={true}
              onChange={value => {
                this.numberSelected(value);
              }}
              onBlur={() => {}}
            />
          </div>
        </div>
      </div>
    );
  }
}

FrequentFlyerNumber.defaultProps = {
  disabled: false,
  touched: false,
};

FrequentFlyerNumber.propTypes = {
  intl: intlShape.isRequired, // eslint-disable-line react/no-typos
  frequentFlyerNumber: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  onCustomerSelected: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({ frequentFlyerNumber: PropTypes.shape({}) }).isRequired,
  travelerIndex: PropTypes.number.isRequired,
};

export default injectIntl(FrequentFlyerNumber);
