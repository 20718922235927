import React, { Fragment, useState } from 'react';
import Card from '@wtag/rcl-card';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AccordionItem } from '@wtag/rcl-accordion';
import FlightDetails from './FlightDetails';
import { TYPE_ADULT } from '../../../helpers/passengerTypes';
import SearchFlightInfo from './SearchFlightInfo';
import FlightPricePerAdult from '../../../ItemPrice/Flight/FlightPricePerAdult';
import FareBrandList from '../flight/FareBrandList';
import FareBrandItem from '../flight/FareBrandItem';
import MoreFareBrandsLabel from '../flight/MoreFareBrandsLabel';
import FlightCompactAccordionItemHeader from './FlightCompactAccordionItemHeader';
import { priceItemShape, segmentItemShape } from './shapes';

import './style.scss';

const GroupedFlightCompact = ({
  group,
  totalTravelerCount,
  marketingAirlineQCodes,
  lowestFareBrandTitle,
  price,
  setOpenSidePanel,
  setSidePanelBody,
  setSidePanelTitle,
  laymanMode,
  isItemSelected,
  disableSelect,
  onDeselect,
  disableItem,
  selectCallback,
  lowestFareBrand,
  totalFilteredFareBrands,
  disableCard,
  people,
}) => {
  const { id, options, fareCalculations } = group;

  const { legMarginBreakdown, currencyCode, grossLegPrice, pricing } = price;

  const [isActiveAccordion, setIsActiveAccordion] = useState(false);

  const onAccordionClick = () => {
    setIsActiveAccordion(prev => !prev);
  };

  const adultPrice =
    fareCalculations &&
    fareCalculations.find(fareCalculationItem => fareCalculationItem.passengerType === TYPE_ADULT)
      .legPrice;

  const currency = fareCalculations && fareCalculations[0].currency;
  const filteredFareBrands = options && options.filter(option => option.fareBrands !== 'none');
  const numberOfAdult = people && people.adults;
  const numberOfChildren = people && people.children;
  const numberOfInfant = people && people.infants;

  return (
    <Fragment>
      <AccordionItem
        className={classNames('flight-compact__accordion-item', {
          'flight-standard-item-wrapper--disable': disableCard,
        })}
        id={id}
        header={
          <FlightCompactAccordionItemHeader
            visibleCodes={marketingAirlineQCodes}
            totalTravelerCount={totalTravelerCount}
            totalFilteredFareBrands={totalFilteredFareBrands}
            currency={currency}
            lowestFareBrand={lowestFareBrand}
            adultPrice={adultPrice}
            lowestFareBrandTitle={lowestFareBrandTitle}
            isItemSelected={isItemSelected}
            disableSelect={disableSelect}
            onDeselect={onDeselect}
            group={group}
            disableItem={disableItem}
            selectCallback={selectCallback}
            laymanMode={laymanMode}
          />
        }
        onClick={onAccordionClick}
        isActive={isActiveAccordion}
      >
        <Card>
          <div className="grid grid-gap-12">
            <div className="col-md-9">
              <FlightDetails
                setOpenSidePanel={setOpenSidePanel}
                setSidePanelBody={setSidePanelBody}
                setSidePanelTitle={setSidePanelTitle}
                group={group}
              />
            </div>
            <div className="col-md-3 hidden-sm-down flight__prices">
              <div className="grid grid-gap-12">
                <div className="col-12 col-grid justify-end flight__prices-farebrands">
                  <div className="col-grid col-bleed align-end flight__prices-content">
                    <FlightPricePerAdult
                      className="flight__prices--gross-price"
                      marginBreakdown={legMarginBreakdown}
                      currency={currencyCode}
                      grossAmount={grossLegPrice}
                      grossTaxes={pricing && pricing.grossLegTaxes}
                      laymanMode={laymanMode}
                      numberOfAdult={numberOfAdult}
                      numberOfChildren={numberOfChildren}
                      numberOfInfant={numberOfInfant}
                      fareCalculations={fareCalculations}
                      fromResult={true}
                      adultPrice={adultPrice}
                      fareBrandTitle={lowestFareBrandTitle}
                      people={people}
                    />
                  </div>
                  {totalFilteredFareBrands > 0 && (
                    <FareBrandList className="col-grid col-bleed align-end">
                      {filteredFareBrands.slice(0, 3).map(fareBrandItem => (
                        <FareBrandItem
                          key={fareBrandItem.id}
                          className="col-12 col-grid direction-row justify-end col-bleed flex-wrap"
                          id={fareBrandItem.id}
                          label={fareBrandItem.fareBrands}
                          currency={fareBrandItem.price.currencyCode}
                          price={fareBrandItem.price.grossLegPrice}
                        />
                      ))}
                      {totalFilteredFareBrands > 3 && (
                        <MoreFareBrandsLabel count={totalFilteredFareBrands - 3} />
                      )}
                    </FareBrandList>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
        <SearchFlightInfo className="col-12" group={group} laymanMode={laymanMode} />
      </AccordionItem>
    </Fragment>
  );
};

GroupedFlightCompact.defaultProps = { lowestFareBrand: null };

GroupedFlightCompact.propTypes = {
  group: PropTypes.shape({
    fareCalculations: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        legPrice: PropTypes.string,
        passengerType: PropTypes.oneOf(['adult', 'child', 'infant']),
        price: PropTypes.string,
      }),
    ).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({ fareBrands: PropTypes.string })).isRequired,
    id: PropTypes.string.isRequired,
    originCode: PropTypes.string.isRequired,
    destinationCode: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(PropTypes.shape(segmentItemShape)).isRequired,
    marketingAirlineCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    arrivalTime: PropTypes.string.isRequired,
    departureTime: PropTypes.string.isRequired,
    originTerminal: PropTypes.string.isRequired,
    tripDurationInMinutes: PropTypes.string.isRequired,
  }).isRequired,
  laymanMode: PropTypes.bool.isRequired,
  totalTravelerCount: PropTypes.number.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  setOpenSidePanel: PropTypes.func.isRequired,
  setSidePanelBody: PropTypes.func.isRequired,
  setSidePanelTitle: PropTypes.func.isRequired,
  price: PropTypes.shape(priceItemShape).isRequired,
  lowestFareBrandTitle: PropTypes.string.isRequired,
  disableSelect: PropTypes.bool.isRequired,
  onDeselect: PropTypes.func.isRequired,
  disableItem: PropTypes.bool.isRequired,
  selectCallback: PropTypes.func.isRequired,
  marketingAirlineQCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lowestFareBrand: PropTypes.string,
  disableCard: PropTypes.bool.isRequired,
  people: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
  }).isRequired,
  totalFilteredFareBrands: PropTypes.number.isRequired,
};

export default GroupedFlightCompact;
