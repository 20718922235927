import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import Card from '@wtag/rcl-card';
import { I18nText } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import ChannelListTag from 'sharedWebpack/IBE/helpers/ChannelListTag';
import WELLTRAVEL from 'sharedWebpack/IBE/lib/helpers/welltravelChannel';
import getCarImageUrl from 'sharedWebpack/IBE/lib/helpers/getCarImageUrl';
import getCarChannelIdentifier from 'sharedWebpack/IBE/lib/helpers/getCarChannelIdentifier';
import FacilityItemLinks from '../FacilityItemLinks';
import CarImage from '../CarImage';
import CarPriceWithBreakdown from '../CarPriceWithBreakdown';
import OutOfPolicyJustificationModal from '../../OutOfPolicyJustificationModal';
import CarStandardDetails from '../CarStandardDetails';
import Mileage from '../Mileage';
import SearchCarInfo from '../SearchCarInfo';
import CarType from '../CarType';
import { carDetailsShape } from '../../../config/shapes';
import InsurancesAndLiabilities from '../InsurancesAndLiabilities';
import './styles.scss';

const CarStandardView = ({
  details,
  onCarSelect,
  onSelect,
  setSidePanelTitle,
  setSidePanelBody,
  setOpenSidePanel,
  laymanMode,
  isAlreadySelectedInCart,
}) => {
  const {
    id,
    facilities,
    vendor,
    car,
    pickUp,
    dropOff,
    currency,
    ratePerDay,
    durationInDays,
    grossRate,
    grossTaxes,
    marginBreakdown,
    grossTotal,
    source,
    corporateDeal,
    labels,
    policiesReasons,
    approvalRequired,
    payLater,
  } = details;

  const { category, acrissCode } = car;

  const {
    insurances,
    liability,
    otherFacilities,
    mileage,
    seats,
    doors,
    airConditioned,
    fuelType,
    airBag,
  } = facilities;

  const breakdownDetails = {
    currency,
    ratePerDay,
    durationInDays,
    grossRate,
    grossTaxes,
    marginBreakdown,
    grossTotal,
    car,
  };

  const informationLabelGroup = {
    approvalRequired,
    payLater,
    policiesReasons,
    labels,
    corporateDeal,
  };

  const [isPolicyJustificationModalOpen, setIsPolicyJustificationModalOpen] = useState(false);

  const isOutOfPolicy = labels.outOfPolicy;
  const isWellTravelChannel = source.supplier === WELLTRAVEL;
  const channelIdentifier = getCarChannelIdentifier(source);
  const hasMileage = mileage && mileage.length > 0;

  const continueToCart = (justification = null) => {
    onCarSelect(true);
    onSelect(details, justification);
  };

  const buttonText = () => {
    if (isAlreadySelectedInCart) {
      return <I18nText id="ibe.results.already_selected" />;
    }

    return <I18nText id="ibe.car_results.add_to_cart" />;
  };

  const handleContinueButtonClick = () => {
    if (isOutOfPolicy) {
      setIsPolicyJustificationModalOpen(true);
    } else {
      continueToCart();
    }
  };

  const handleShowSidePanel = (sidePanelTitle, sidePanelBody) => {
    setSidePanelTitle(sidePanelTitle);
    setSidePanelBody(sidePanelBody);
    setOpenSidePanel();
  };

  const priceBreakdownInDesktopView = (
    <div className="grid grid-gap-12">
      <div className="col-12 col-grid row-gap-4">
        <CarPriceWithBreakdown
          className="car__prices--gross-price car__prices--gross-price-desktop"
          laymanMode={laymanMode}
          breakdownDetails={breakdownDetails}
        />
        <div className="d-flex justify-end column-gap-4 car__prices--per-day-label wrap w-100">
          <CarType category={category} acrissCode={acrissCode} />(
          {I18n.t('admin.components.products.options.automation_style.per_day.label')})
        </div>
      </div>
      {hasMileage && (
        <div className="col-12 col-grid align-end car__mileage">
          <Mileage mileages={mileage} />
        </div>
      )}
    </div>
  );

  const priceBreakdownWithActionsInMobileView = (
    <Fragment>
      <div className="d-flex justify-between align-start w-100 car__prices-breakdown-wrapper--mobile">
        <div className="d-flex-col row-gap-12">
          <FacilityItemLinks
            className="d-flex direction-row-reverse column-gap-8"
            facilities={facilities}
            otherFacilities={otherFacilities}
            onShowSidePanel={handleShowSidePanel}
          />
          {hasMileage && (
            <div className="d-flex-col row-gap-4 car__mileage car__mileage--mobile">
              <Mileage mileages={mileage} />
            </div>
          )}
        </div>
        <div className="d-flex-col align-end justify-end row-gap-8">
          <CarPriceWithBreakdown
            className="car__prices--gross-price car__prices--gross-price-mobile"
            laymanMode={laymanMode}
            breakdownDetails={breakdownDetails}
          />
          <div className="d-flex-col align-end row-gap-4 car__prices--per-day-label w-100">
            ({I18n.t('admin.components.products.options.automation_style.per_day.label')})
          </div>
        </div>
      </div>
      <div className="d-flex justify-end car__actions">
        <Button
          size="small"
          label={buttonText()}
          primary={true}
          version="v2"
          onClick={handleContinueButtonClick}
          disabled={isAlreadySelectedInCart}
        />
      </div>
    </Fragment>
  );

  return (
    <div key={id} className="car-standard-view">
      <Card version="v2">
        <div className="grid grid-gap-20">
          <div className="col-12 col-grid direction-row justify-between">
            <span className="car__logo-chip">
              <img src={getCarImageUrl(vendor.imageUrl.url)} alt="car" />
            </span>
            <span className="car__actions car__actions--desktop">
              <Button
                size="small"
                label={buttonText()}
                primary={true}
                version="v2"
                onClick={handleContinueButtonClick}
                disabled={isAlreadySelectedInCart}
              />
            </span>
          </div>
          <div className="col-12">
            <div className="grid grid-gap-12">
              <div className="col-3 car__image">
                <CarImage className="flex-img" url={car.imageUrl.url} />
              </div>
              <div className="col-lg-6 d-flex-col row-gap-12 car__details">
                <CarStandardDetails
                  carInformation={car}
                  pickUpDetails={pickUp}
                  dropOffDetails={dropOff}
                />
                <div className="col-lg-8 d-flex column-gap-8 col-bleed car__insurances-container">
                  <InsurancesAndLiabilities
                    insurances={insurances}
                    liability={liability}
                    onShowSidePanel={handleShowSidePanel}
                  />
                </div>
              </div>
              <div className="col-md-3 car__facility-items--desktop">
                {priceBreakdownInDesktopView}
              </div>
            </div>
          </div>
          <SearchCarInfo
            className="col-12 car__search-info car__search-info--desktop"
            group={informationLabelGroup}
            laymanMode={laymanMode}
            facilities={{ seats, doors, airConditioned, fuelType, airBag }}
            otherFacilities={otherFacilities}
            onShowSidePanel={handleShowSidePanel}
          />
          <SearchCarInfo
            className="col-12 car__search-info car__search-info--mobile"
            group={informationLabelGroup}
            laymanMode={laymanMode}
          />
          <OutOfPolicyJustificationModal
            modalOpen={isPolicyJustificationModalOpen}
            setModalOpen={setIsPolicyJustificationModalOpen}
            onConfirm={continueToCart}
          />
        </div>
        <div className="car__facility-items--mobile">{priceBreakdownWithActionsInMobileView}</div>
        {!laymanMode && !!channelIdentifier && (
          <div className="d-flex justify-end car__channels car__welltravel-tickets">
            <ChannelListTag
              identifier={channelIdentifier}
              type={isWellTravelChannel ? 'accent' : null}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

CarStandardView.propTypes = {
  details: PropTypes.shape(carDetailsShape).isRequired,
  isAlreadySelectedInCart: PropTypes.bool.isRequired,
  onCarSelect: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  setOpenSidePanel: PropTypes.func.isRequired,
  setSidePanelBody: PropTypes.func.isRequired,
  setSidePanelTitle: PropTypes.func.isRequired,
};

const CarStandardViewWithContext = (props, context) => <CarStandardView {...props} {...context} />;

CarStandardViewWithContext.contextTypes = {
  laymanMode: PropTypes.bool,
};

export default hot(module)(CarStandardViewWithContext);
