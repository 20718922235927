import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import BookingApp from '../../shared/IBE/IBEWrappedComponents';
import MidSection from '../MidSection';
import TopDeals from '../Deals/TopDeals';
import Testimonial from '../Testimonial';
import TopStories from '../shared/BlogPosts/TopStories';

const FrontPage = props => {
  const [onSearchStarted, setOnSearchStarted] = useState(false);
  const [showBottomComponents, setShowBottomComponents] = useState(false);

  const memoizeBookingApp = useMemo(
    () => (
      <BookingApp
        {...props}
        setOnSearchStarted={setOnSearchStarted}
        setShowBottomComponents={setShowBottomComponents}
      />
    ),
    [props, setOnSearchStarted, setShowBottomComponents],
  );

  return (
    <React.Fragment>
      {memoizeBookingApp}
      {!onSearchStarted && showBottomComponents && (
        <div className="container container--sm-full-width old-layout deals-testimonials">
          <div className="grid">
            <div className="col-12">
              <MidSection staticInformation={props.staticInformation} />
            </div>
          </div>
          {props.showTopDeals && (
            <div className="grid">
              <div className="col-12">
                <TopDeals />
              </div>
            </div>
          )}
          {props.showTestimonial && (
            <div className="grid">
              <div className="col-12">
                <Testimonial
                  testimonialData={props.testimonialData}
                  primaryColor={props.primaryColor}
                />
              </div>
            </div>
          )}
          {props.showTopStories && (
            <div className="grid">
              <div className="col-12">
                <TopStories {...props.blogPostProps} />
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

FrontPage.propTypes = {
  staticInformation: PropTypes.shape().isRequired,
  topDeals: PropTypes.shape().isRequired,
  showTopDeals: PropTypes.bool.isRequired,
  testimonialData: PropTypes.shape().isRequired,
  primaryColor: PropTypes.string.isRequired,
  showTestimonial: PropTypes.bool.isRequired,
  blogPostProps: PropTypes.shape().isRequired,
  showTopStories: PropTypes.bool.isRequired,
};

export default FrontPage;
