import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fetchOnUpdate from '../../lib/decorators/fetchOnUpdate';
import { fetchHotelDetails, addHotelToCart, fetchHotelSearchResults } from '../../actions/hotel';
import { selectHotelResultInMultiSearch, showError } from '../../actions/common';

import Details from './Details';

const mapStateToProps = (state, ownProps) => {
  const details = state.hotels.hotelDetailsByResultId[ownProps.match.params.resultId] || {};
  const stats = state.hotels.searchStatsBySearchId[details.searchId] || {};
  const results = state.hotels.searchResultsBySearchId[details.searchId] || [];
  return {
    dataFetched: !!Object.keys(details).length,
    details,
    stats,
    results,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDetails: id => dispatch(fetchHotelDetails(id, ownProps.callbackParams)),
  addToCart: (details, roomId, justification) =>
    dispatch(addHotelToCart(details, roomId, ownProps.callbackParams, justification)),
  selectInMultiSearch: (groupKey, result, roomId) =>
    dispatch(selectHotelResultInMultiSearch(groupKey, result, roomId, ownProps.callbackParams)),
  fetchHotelSearch: searchId => dispatch(fetchHotelSearchResults({ searchId })),
  showFetchError: () => dispatch(showError(I18n.t('components.ibe.hotel.detail_fetching_failed'))),
});

const DetailsWithFetchOnUpdate = fetchOnUpdate(['resultId'], (params, props) => {
  props.fetchDetails(params.resultId).then(details => {
    if (details === null) {
      props.showFetchError();
    } else {
      props.fetchHotelSearch(details.searchId);
    }
  });
})(Details);

const ConnectedDetails = connect(mapStateToProps, mapDispatchToProps)(DetailsWithFetchOnUpdate);

const DetailsWithContext = (props, context) => <ConnectedDetails {...props} {...context} />;

DetailsWithContext.contextTypes = {
  callbackParams: PropTypes.shape().isRequired,
};

export default DetailsWithContext;
